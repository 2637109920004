import React, { useMemo } from 'react';
import theme from 'resources/theme';
import _ from 'lodash';
import Text from 'modules/Text';
import IconButton from 'modules/IconButton';
import cross from 'assets/images/icons/cross-black.svg';
import ContainedButton from 'modules/ContainedButton';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { hide_loader, show_loader } from 'actions/app';
import api_requests from 'resources/api_requests';
import Button from 'modules/Button';

const useStyles = makeStyles({
	wrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	sub_wrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 550,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 50,
		zIndex: 1200,
		overflowY: 'auto',
	},
	header_wrapper: { borderBottom: '1px solid lightgrey', paddingBottom: 4, display: 'flex', justifyContent: 'space-between' },
	create_rider_wrap: { display: 'flex', alignItems: 'center' },
	create_user_text: { fontSize: 20, color: theme.colors.primary },
	user_identity_text: { fontSize: 16, marginTop: 16, color: theme.colors.darkGrey2 },
	input_wrapper: { display: 'flex', justifyContent: 'space-between', margin: '0px 2px', marginTop: 12 },
	contact_text: { fontSize: 16, width: '45%', marginTop: 32, color: theme.colors.darkGrey2 },
	width45: { width: '45%' },
	add_user_btn: { borderRadius: 25, height: 45, width: 110 },
	select_label: { marginTop: 12, marginBottom: 4, color: theme.colors.darkGrey2, fontSize: 12 },
	footer: {
		position: 'fixed',
		bottom: 0,
		height: 100,
		width: 550,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingRight: 40,
	},
});

const CreateUser = ({ accepted_users, close }) => {
	const dispatch = useDispatch();
	const [user_details, set_user_details] = React.useState({
		first_name: '',
		last_name: '',
		mobile: '',
		email: '',
	});

	const classes = useStyles();

	const handle_change = (e) => {
		set_user_details({
			...user_details,
			[e.target.name]: e.target.value,
		});
	};

	const handle_submit = async () => {
		const existing_user = _.find(accepted_users, { mobile: user_details.mobile });
		if (existing_user) {
			toast.warning('User has already accepted invite', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}

		try {
			dispatch(show_loader());
			const response = await api_requests.send_user_invite({
				name: `${user_details.first_name} ${user_details.last_name}`,
				email: user_details.email,
				mobile: user_details.mobile,
			});
			if (response.success) {
				close(true);
				dispatch(hide_loader());
				toast.success('Invite sent successfully', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		if (
			!_.isEmpty(user_details.first_name.trim()) &&
			!_.isEmpty(user_details.last_name.trim()) &&
			!_.isEmpty(user_details.mobile.trim()) &&
			user_details.mobile.trim().length === 10 &&
			!_.isEmpty(user_details.email.trim()) &&
			user_details.email.match(
				/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			)
		)
			value = false;
		return value;
	}, [user_details]);

	return (
		<>
			<div className={classes.wrapper} onClick={() => close(false)} />
			<div className={classes.sub_wrapper}>
				<div className={classes.header_wrapper}>
					<div className={classes.create_rider_wrap}>
						<Text bold className={classes.create_user_text}>
							Invite User
						</Text>
					</div>
					<IconButton onClick={() => close(false)} style={{ border: 'none' }}>
						<img src={cross} width={16} height={16} />
					</IconButton>
				</div>
				<Text bold className={classes.user_identity_text}>
					User Identity
				</Text>
				<div className={classes.input_wrapper}>
					<UndlerlineTextField
						label='First Name*'
						value={user_details.first_name}
						onChange={handle_change}
						name='first_name'
						className={classes.width45}
						textStyle={{ fontSize: 16 }}
						labelStyle={{ fontSize: 12, color: theme.colors.darkGrey2 }}
					/>
					<UndlerlineTextField
						label='Last Name*'
						value={user_details.last_name}
						onChange={handle_change}
						name='last_name'
						className={classes.width45}
						textStyle={{ fontSize: 16 }}
						labelStyle={{ fontSize: 12, color: theme.colors.darkGrey2 }}
					/>
				</div>
				<Text bold className={classes.contact_text}>
					Contact
				</Text>
				<div className={classes.input_wrapper}>
					<UndlerlineTextField
						type='number'
						label='Mobile Number*'
						value={user_details.mobile}
						onChange={handle_change}
						name='mobile'
						className={classes.width45}
						textStyle={{ fontSize: 16 }}
						labelStyle={{ fontSize: 12, color: theme.colors.darkGrey2 }}
					/>
					<UndlerlineTextField
						label='Email Id*'
						value={user_details.email}
						onChange={handle_change}
						name='email'
						className={classes.width45}
						textStyle={{ fontSize: 16 }}
						labelStyle={{ fontSize: 12, color: theme.colors.darkGrey2 }}
					/>
				</div>

				<div className={classes.footer}>
					<Button type='round' text='Invite' size='large' onClick={handle_submit} disabled={btn_disabled} style={{ width: 110 }} />
				</div>
			</div>
		</>
	);
};

export default CreateUser;
