import { makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'fixed',
		display: 'flex',
		justifyContent: 'center',
		top: '30px',
		width: '25%',
		left: '45%',
		margin: 'auto',
		borderRadius: '4px',
		zIndex: '2',
	},
}));

function InfiniteToast({ children, error }) {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div className={classes.container}>
			<div
				style={{
					padding: '7px',
					backgroundColor: error ? theme.colors.lightRed : theme.colors.lightGrey4,
					borderRadius: '4px',
				}}>
				{children}
			</div>
		</div>
	);
}

export default InfiniteToast;
