import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import { renderToString } from 'react-dom/server';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import theme from 'resources/theme';
import { CREATE_OPTIONS } from '../../helper';
import ContainedButton from 'modules/ContainedButton';
import Papa from 'papaparse';
import utils from 'resources/utils';
import constant from 'resources/constant';
import PincodesOutput from './PincodesOutput';
import PincodesInput from './PincodesInput';
import PolygonOutput from './PolygonOutput';
import CreateNetworkContext from '../../../context';
import clsx from 'clsx';
import SectionCta from '../SectionCta';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Switch from 'modules/Switch';

const tabs = ['Pickup', 'Delivery'];

const useStyles = makeStyles(() => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column' },
	content_wrap: { marginLeft: 16, gap: 20, display: 'flex', flex: 1 },
	type_btn: { borderRadius: 60, marginRight: 8, height: 34 },
	tab_item: {
		borderRadius: 8,
		minWidth: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		height: 33,
	},
	input_wrap: { display: 'flex', flexDirection: 'column', flex: 0.45, overflowX: 'hidden' },
	output_wrap: { display: 'flex', flexDirection: 'column', flex: 0.55, marginTop: -60 },
	input_section: {
		position: 'relative',
		marginTop: 8,
		padding: 16,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey7}`,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		flex: 1,
	},
	draw_btn: {
		border: `1px solid ${theme.colors.primary}`,
		borderRadius: 30,
		padding: '5px 7px',
		boxShadow: '0px 0px 16px rgba(102, 102, 102, 0.1)',
		width: 140,
	},
	added_btn: { width: 67, height: 31, borderRadius: 30, backgroundColor: theme.colors.green, padding: '5px 12px', marginLeft: 7 },
	map_btn_wrap: { position: 'absolute', zIndex: 100, top: 10, left: 20 },
	map_empty_overlay: {
		position: 'absolute',
		zIndex: 1000,
		top: 0,
		left: 0,
		borderRadius: 20,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple6, 40),
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	save_coord_btn: {
		border: `1px solid ${theme.colors.green}`,
		borderRadius: 30,
		boxShadow: '0px 0px 16px rgba(102, 102, 102, 0.1)',
		marginLeft: 12,
		width: 120,
		height: 31,
	},
}));

let drawingManager;
let currentInfoWindow = null;

const ServicibilityAreas = ({ handle_data_updates }) => {
	const {
		serviciblity_config,
		valid_pincodes,
		set_valid_pincodes,
		serviciblity_area_tab,
		set_serviciblity_area_tab,
		handle_individual_pincode_delete,
		handle_all_pincodes_delete,
		handle_serviciblity_config,
		flush_global_service_config,
		set_confirm_close_modal,
		invite_network_id,
		edit_network_id,
		invite_network_config,
		set_serviciblity_config,
		convert_to_google_maps_polygon_object,
		handle_serviciblity_api,
	} = useContext(CreateNetworkContext);

	const [flow_type, set_flow_type] = useState('pincode');
	const [in_draw_mode, set_in_draw_mode] = useState(false);
	const [in_edit_mode, set_in_edit_mode] = useState(false);
	const [recently_drawn, set_recently_drawn] = useState(false);
	const [polygons, set_polygons] = useState([]);
	const [can_change_serviceability, set_can_change_serviceability] = useState(_.get(invite_network_config, 'ownership.drop_serviceability', '') == 'user');
	const [show_change_modal, set_show_change_modal] = useState(false);
	const polygon_count = useRef(0);

	const map = useRef(null);
	const classes = useStyles();

	useEffect(() => {
		if (invite_network_id || edit_network_id) {
			const init_config = serviciblity_area_tab === 0 ? serviciblity_config?.pickup : serviciblity_config?.delivery;
			if (_.isEmpty(init_config?.polygon)) {
				set_valid_pincodes(init_config?.pincodes ? init_config?.pincodes : []);
				set_flow_type('pincode');
			} else {
				set_flow_type('draw');
				map.current = null;
				setTimeout(() => {
					initialize();
				}, 300);
			}
			return;
		}
		const existing_data = serviciblity_area_tab === 0 ? serviciblity_config?.pickup : serviciblity_config?.delivery;
		const exist_polys = existing_data?.polygon || [];
		if (exist_polys.length > 0 && map.current) {
			map.current = null;
			setTimeout(() => {
				initialize();
			}, 800);
		}
		set_flow_type(exist_polys.length > 0 ? 'draw' : 'pincode');
	}, [serviciblity_area_tab, serviciblity_config]);

	useEffect(() => {
		if (invite_network_id || edit_network_id) return;
		const existing_data = serviciblity_area_tab === 0 ? serviciblity_config?.pickup : serviciblity_config?.delivery;
		set_in_draw_mode(false);
		set_in_edit_mode(false);
		set_recently_drawn(false);
		map.current = null;
		set_polygons([]);
		polygon_count.current = 0;
		set_valid_pincodes(existing_data?.pincodes ? existing_data?.pincodes : []);
	}, [serviciblity_area_tab, flow_type]);

	useEffect(() => {
		if (flow_type === 'draw') {
			setTimeout(() => {
				initialize();
			}, 800);
		} else map.current = null;
	}, [flow_type]);

	useEffect(() => {
		if (recently_drawn) {
			setTimeout(() => {
				set_recently_drawn(false);
			}, 2000);
		}
	}, [recently_drawn]);

	useEffect(() => {
		if (polygons.length === 0) return;
		polygons.forEach((polygon) => {
			polygon.setOptions({
				strokeOpacity: in_draw_mode ? 0.2 : 1,
				fillOpacity: in_draw_mode ? 0.2 : 0.35,
			});
		});
	}, [in_draw_mode]);

	const initialize = () => {
		if (!map.current) {
			map.current = new window.google.maps.Map(document.getElementById('map_canvas'), {
				center: constant.INDIA_MAP_CENTER,
				zoom: 5,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});
			const existing_poly_data = serviciblity_area_tab === 0 ? serviciblity_config?.pickup?.polygon : serviciblity_config?.delivery?.polygon;

			if (!existing_poly_data || existing_poly_data.length === 0) return;
			polygon_count.current = existing_poly_data.length;
			const all_poly = [];
			for (let i = 0; i < existing_poly_data.length; i++) {
				const element = existing_poly_data[i];
				const poly_path = element.getPath();
				const coordinates = [];

				for (let j = 0; j < poly_path.length; j++) {
					coordinates.push({ lat: poly_path.getAt(j).lat(), lng: poly_path.getAt(j).lng() });
				}

				const exist_poly = new window.google.maps.Polygon({
					paths: coordinates,
					strokeWeight: 2,
					fillOpacity: 0.35,
					strokeColor: theme.colors.primary,
					fillColor: theme.colors.primary,
					name: element.name,
					polygon_index: element.polygon_index,
					id: element.id,
				});

				exist_poly.setMap(map.current);
				all_poly.push(exist_poly);
				window.google.maps.event.addListener(exist_poly, 'click', (event) => {
					togglePolygonPopup(exist_poly);
				});
			}
			set_polygons(all_poly);
		}
	};

	const clear_drawing_manager = () => {
		if (drawingManager) {
			drawingManager.setMap(null);
			window.google.maps.event.clearListeners(map.current, 'polygoncomplete');
		}
	};

	const enable_drawing_manager = () => {
		if (drawingManager) {
			drawingManager.setMap(null);
			window.google.maps.event.clearListeners(map.current, 'polygoncomplete');
		}

		drawingManager = new window.google.maps.drawing.DrawingManager({
			drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
			drawingControl: false,
			polygonOptions: {
				strokeWeight: 2,
				fillOpacity: 0.35,
				strokeColor: theme.colors.primary,
				fillColor: theme.colors.primary,
			},
		});

		// Set the map to the Drawing Manager
		drawingManager.setMap(map.current);

		// Add an event listener for polygon complete event
		window.google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
			set_in_draw_mode(false);
			set_recently_drawn(true);
			drawingManager.setMap(null);
			// Push the polygon to the polygons array
			const polygon_name = `Polygon ${polygon_count.current + 1}`;
			polygon.name = polygon_name;
			polygon.polygon_index = polygon_count.current;
			polygon_count.current += 1;

			polygon.setOptions({
				strokeWeight: 2,
				fillOpacity: 0.35,
				strokeColor: theme.colors.primary,
				fillColor: theme.colors.primary,
			});

			// Push the polygon to the polygons array
			set_polygons((prev) => [...prev, polygon]);

			window.google.maps.event.addListener(polygon, 'click', (event) => {
				// togglePolygonPopup(event.latLng, polygon);
				togglePolygonPopup(polygon);
			});
		});
	};

	function togglePolygonPopup(polygon) {
		if (in_edit_mode) return;
		const infoWindow = new window.google.maps.InfoWindow();

		const content = (
			<div>
				<Text semi style={{ fontSize: 12, marginBottom: 8 }} className='text-align-center'>
					{polygon.name}
				</Text>
				<div className={'align-items-center'}>
					<img className='cursor-pointer edit_polygon_btn' src={ImageLinks.edit_purple} width={16} height={16} />
					<Text style={{ marginLeft: 15, marginRight: 15 }}>|</Text>
					<img className='cursor-pointer delete_polygon_btn' src={ImageLinks.delete} width={16} height={16} />
				</div>
			</div>
		);

		const infoWindowContent = renderToString(content);

		infoWindow.setContent(infoWindowContent);

		if (currentInfoWindow !== null) {
			// Close the currently opened info window if any
			currentInfoWindow.close();
		}
		infoWindow.setPosition(polygon.getPath().getAt(0));
		infoWindow.open(map.current);

		currentInfoWindow = infoWindow;

		window.google.maps.event.addListenerOnce(infoWindow, 'domready', function () {
			const editButton = document.querySelector('.edit_polygon_btn');
			const deleteButton = document.querySelector('.delete_polygon_btn');

			editButton.addEventListener('click', function () {
				// Edit button click logic
				set_in_edit_mode(true);
				currentInfoWindow.close();
				currentInfoWindow = null;
				polygon.setEditable(true);
			});

			deleteButton.addEventListener('click', function () {
				currentInfoWindow.close();
				currentInfoWindow = null;
				// Remove the polygon from the map and the polygons array
				polygon.setMap(null);
				set_polygons((prev) => prev.filter((p) => p.polygon_index !== polygon.polygon_index));
				handle_individual_polygon_delete(polygon.polygon_index);
			});
		});
	}

	const on_draw_press = () => {
		set_in_draw_mode((prev) => !prev);
		if (in_draw_mode) clear_drawing_manager();
		else enable_drawing_manager();
		return;
	};

	const on_edit_press = () => {
		set_in_edit_mode((prev) => !prev);
		if (in_edit_mode) {
			set_recently_drawn(true);
			polygons.forEach((polygon) => {
				polygon.setEditable(false);
			});
		}
	};

	const handle_csv = (fileInfo, inputRef) => {
		Papa.parse(fileInfo.file, {
			header: false,
			skipEmptyLines: true,
			complete: (results) => {
				const data = results.data;
				let valid_pincodes_from_csv = [];
				_.map(data, (pincodes, key) => {
					if (key === 0) return;
					const pincode = pincodes[0];
					let regex = new RegExp(/^\d{6}$/);
					if (regex.test(pincode)) valid_pincodes_from_csv.push(pincode);
				});
				const _valid_pin = [...valid_pincodes, ...valid_pincodes_from_csv];
				set_valid_pincodes(Array.from(new Set(_valid_pin)));
			},
		});
	};

	const handle_pincode_submit = (pincodes) => {
		const _valid_pin = valid_pincodes ? [...valid_pincodes, ...pincodes] : [...pincodes];
		set_valid_pincodes(Array.from(new Set(_valid_pin)));
	};

	const handle_individual_polygon_delete = (index) => {
		const clicked_polygon = polygons[index];
		clicked_polygon?.setMap(null);

		const remaining_pincodes = _.filter(polygons, (p, p_index) => p_index !== index);
		if (remaining_pincodes.length === 0) {
			flush_global_service_config();
			set_in_draw_mode(false);
			set_in_edit_mode(false);
			set_recently_drawn(false);
		}
		set_polygons(remaining_pincodes);
	};

	const handle_all_polygon_delete = () => {
		set_confirm_close_modal({
			open: true,
			header_title: `Delete all polygons`,
			title: 'Are you sure you want to delete all polygons? All progress will be lost',
			positive_text: 'Delete',
			negative_text: 'Cancel',
			is_error_msg: true,
			confirm_acion: delete_all_poly,
			modal_icon: null,
		});
	};

	const delete_all_poly = () => {
		for (let i = 0; i < polygons.length; i++) {
			const element = polygons[i];
			element.setMap(null);
		}
		currentInfoWindow?.close();
		currentInfoWindow = null;
		set_polygons([]);
		flush_global_service_config();
		set_in_draw_mode(false);
		set_in_edit_mode(false);
		set_recently_drawn(false);
		polygon_count.current = 0;
	};

	const handle_save_info = () => {
		if (in_edit_mode) {
			set_in_edit_mode(false);
			polygons.forEach((polygon) => {
				polygon.setEditable(false);
			});
		}
		handle_serviciblity_config(serviciblity_area_tab === 0 ? 'pickup' : 'delivery', {
			pincodes: valid_pincodes,
			polygon: polygons,
		});
	};

	const handle_service_tab_switch = (index) => {
		if (invite_network_id || (edit_network_id && flow_type != 'pincode')) {
			set_serviciblity_area_tab(index);
			return;
		}
		let is_data_empty = false;
		if (serviciblity_area_tab === 0) is_data_empty = _.isEmpty(serviciblity_config?.pickup);
		if (serviciblity_area_tab === 1) is_data_empty = _.isEmpty(serviciblity_config?.delivery);

		const show_alert = is_data_empty ? !btn_disabled : is_data_updated;
		if (!show_alert) {
			set_serviciblity_area_tab(index);
			return;
		}
		set_confirm_close_modal({
			open: true,
			header_title: 'Discard Changes',
			title: `You have made changes but you have not saved them. Are you sure you want to discard these changes?`,
			positive_text: 'Discard',
			negative_text: 'Cancel',
			is_error_msg: true,
			confirm_acion: () => {
				set_serviciblity_area_tab(index);
			},
		});
	};

	const handle_custom_service = (e) => {
		if (e) {
			set_show_change_modal(true);
			return;
		}
		if (!e) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Exit Custom Rule Setup',
				title: `Are you sure you want to abandon custom rules setup for servicibility? Rules will reset to default.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				is_error_msg: false,
				show_negative_btn: true,
				confirm_acion: () => {
					set_values_to_default();
				},
			});
		}
	};

	const set_values_to_default = () => {
		set_can_change_serviceability(false);
		const pickup_config =
			invite_network_config.pickup_serviceability.rule == 'POLYGON'
				? { polygon: convert_to_google_maps_polygon_object(invite_network_config.pickup_serviceability.values), pincodes: [] }
				: {
						polygon: [],
						pincodes: _.map(invite_network_config.pickup_serviceability.values, (value) => {
							return value.pincode;
						}),
				  };
		const drop_config =
			invite_network_config.drop_serviceability.rule == 'POLYGON'
				? { polygon: convert_to_google_maps_polygon_object(invite_network_config.drop_serviceability.values), pincodes: [] }
				: {
						polygon: [],
						pincodes: _.map(invite_network_config.drop_serviceability.values, (value) => {
							return value.pincode;
						}),
				  };
		set_serviciblity_config({ pickup: pickup_config, delivery: drop_config });
	};

	const btn_disabled = useMemo(() => {
		if (invite_network_id || (edit_network_id && flow_type != 'pincode')) return false;
		if (polygons.length > 0) return false;
		if (valid_pincodes?.length > 0) return false;
		return true;
	}, [polygons, valid_pincodes]);

	const is_data_updated = useMemo(() => {
		let value = false;
		// if (_.isEmpty(serviciblity_config?.pickup) || _.isEmpty(serviciblity_config?.delivery)) return true;
		if (serviciblity_area_tab === 0 && _.isEmpty(serviciblity_config?.pickup)) {
			handle_data_updates(0, true);
			return true;
		}
		if (serviciblity_area_tab === 1 && _.isEmpty(serviciblity_config?.delivery)) {
			handle_data_updates(0, true);
			return true;
		}

		let old_pincode = [],
			new_pincode = [];
		let old_poly = [],
			new_poly = [];
		const is_pickup_pincode = _.isEmpty(_.get(serviciblity_config, 'pickup.polygon', []));
		const is_drop_pincode = _.isEmpty(_.get(serviciblity_config, 'delivery.polygon', []));

		if (serviciblity_area_tab === 0) {
			if (is_pickup_pincode) {
				old_pincode = _.cloneDeep(valid_pincodes);
				new_pincode = _.cloneDeep(serviciblity_config?.pickup.pincodes);
			} else {
				old_poly = _.cloneDeep(polygons);
				new_poly = _.cloneDeep(serviciblity_config?.pickup?.polygon);
			}
		}

		if (serviciblity_area_tab === 1) {
			if (is_drop_pincode) {
				old_pincode = _.cloneDeep(valid_pincodes);
				new_pincode = _.cloneDeep(serviciblity_config?.delivery.pincodes);
			} else {
				old_poly = _.cloneDeep(polygons);
				new_poly = _.cloneDeep(serviciblity_config?.delivery?.polygon);
			}
		}

		if ((serviciblity_area_tab === 0 && is_pickup_pincode) || (serviciblity_area_tab === 1 && is_drop_pincode)) {
			if (old_pincode?.length != new_pincode?.length) {
				handle_data_updates(0, true);
				return true;
			}

			for (let i = 0; i < old_pincode?.length; i++) {
				if (old_pincode[i] != new_pincode[i]) {
					value = true;
					break;
				}
			}
		} else {
			if (in_edit_mode) {
				handle_data_updates(0, true);
				return true;
			}

			if (old_poly.length != new_poly.length) {
				handle_data_updates(0, true);
				return true;
			}
			let coords_updated = false;
			for (let i = 0; i < old_poly.length; i++) {
				const old_coords = old_poly[i].getPath();
				const new_coords = new_poly[i].getPath();
				if (old_coords.length != new_coords.length) {
					coords_updated = true;
					break;
				}
				for (let j = 0; j < old_coords.length; j++) {
					if (old_coords.getAt(j).lat() != new_coords.getAt(j).lat() || old_coords.getAt(j).lng() != new_coords.getAt(j).lng()) {
						coords_updated = true;
						break;
					}
				}
				if (coords_updated) break;
			}
			handle_data_updates(0, coords_updated);
			return coords_updated;
		}
		handle_data_updates(0, value);
		return value;
	}, [serviciblity_area_tab, valid_pincodes, serviciblity_config, polygons, in_edit_mode, edit_network_id, flow_type]);
	const is_pickup_config_pincode = _.isEmpty(_.get(serviciblity_config, 'pickup.polygon', []));
	const is_delivery_config_pincode = _.isEmpty(_.get(serviciblity_config, 'delivery.polygon', []));

	return (
		<div className={classes.wrapper}>
			<div className={classes.content_wrap}>
				<div className={classes.input_wrap}>
					<div className='d-flex justify-content-between'>
						<div className='d-flex'>
							{_.map(tabs, (tab, index) => (
								<div
									key={`servicibility_tab_${index}`}
									onClick={() => handle_service_tab_switch(index)}
									className={classes.tab_item}
									style={{
										backgroundColor: serviciblity_area_tab === index ? theme.colors.primary : 'transparent',
									}}>
									<Text
										bold
										style={{ fontSize: 14, color: serviciblity_area_tab === index ? theme.colors.white : theme.colors.lightPurple6 }}>
										{tab}
									</Text>

									{((index === 0 && !_.isEmpty(serviciblity_config?.pickup)) ||
										(index === 1 && !_.isEmpty(serviciblity_config?.delivery))) && (
										<img
											src={serviciblity_area_tab === index ? ImageLinks.tick_circle_white_solid : ImageLinks.tick_circle_light_pink}
											width={16}
											height={16}
											style={{ marginLeft: 9 }}
										/>
									)}
								</div>
							))}
						</div>
						<div>
							{invite_network_id && (
								<div className='d-flex align-items-center' style={{ justifyContent: 'flex-end' }}>
									<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Custom Rule
									</Text>
									<Switch
										disabled={_.get(invite_network_config, 'ownership.drop_serviceability', '') == 'user'}
										checked={can_change_serviceability}
										onChange={(e) => {
											handle_custom_service(e.target.checked);
										}}
									/>
								</div>
							)}
						</div>
					</div>

					<div className={classes.input_section}>
						<Text semi style={{ fontSize: 14 }}>
							Define Areas
						</Text>

						<div style={{ marginTop: 8 }}>
							{_.map(CREATE_OPTIONS, (item, index) => {
								let is_option_disabled = false;
								if (serviciblity_area_tab === 0) {
									is_option_disabled = item.key === 'pincode' ? !is_pickup_config_pincode : is_pickup_config_pincode;
									is_option_disabled = _.isEmpty(serviciblity_config?.pickup) ? false : is_option_disabled;
								} else {
									is_option_disabled = item.key === 'pincode' ? !is_delivery_config_pincode : is_delivery_config_pincode;
									is_option_disabled = _.isEmpty(serviciblity_config?.delivery) ? false : is_option_disabled;
								}

								return (
									<ContainedButton
										disabled={is_option_disabled}
										disableElevation
										onClick={() => set_flow_type(item.key)}
										key={`create_type_item_${index}`}
										className={classes.type_btn}
										style={{
											backgroundColor: flow_type === item.key ? theme.colors.lightPurple : theme.colors.white,
											border: `1px solid ${
												is_option_disabled
													? theme.colors.lightGrey2
													: flow_type === item.key
													? theme.colors.primary
													: theme.colors.lightPurple6
											}`,
										}}>
										<Text
											semi
											style={{
												fontSize: 14,
												color: is_option_disabled
													? theme.colors.lightGrey2
													: flow_type === item.key
													? theme.colors.primary
													: theme.colors.lightPurple6,
											}}>
											{item.title}
										</Text>
									</ContainedButton>
								);
							})}
						</div>
						{flow_type === 'pincode' && (
							<PincodesInput
								disabled={invite_network_id && !can_change_serviceability}
								handle_pincode_submit={handle_pincode_submit}
								handle_csv={handle_csv}
							/>
						)}

						{flow_type === 'draw' && (
							<PolygonOutput
								polygons={polygons}
								handle_individual_polygon_delete={handle_individual_polygon_delete}
								handle_all_polygon_delete={handle_all_polygon_delete}
							/>
						)}
					</div>
				</div>

				<div className={classes.output_wrap}>
					{flow_type === 'pincode' && (
						<PincodesOutput
							disabled={invite_network_id && !can_change_serviceability}
							valid_pincodes={valid_pincodes}
							handle_all_pincodes_delete={handle_all_pincodes_delete}
							handle_individual_pincode_delete={handle_individual_pincode_delete}
						/>
					)}
					{flow_type === 'draw' && (
						<div style={{ flex: 1, position: 'relative' }}>
							{(polygons.length > 0 || in_draw_mode) && (
								<div className={clsx('align-items-center', classes.map_btn_wrap)}>
									{!in_edit_mode && (
										<ContainedButton onClick={on_draw_press} className={classes.draw_btn} style={{ background: theme.colors.white }}>
											<img src={ImageLinks.polygon_purple} width={16} height={16} alt='edit_map_pencil' />
											<Text semi style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 14 }}>
												{polygons.length === 0 ? 'Draw Polygon' : 'Add Another'}
											</Text>
											{in_draw_mode && <img src={ImageLinks.crossBlack} width={12} height={12} style={{ marginLeft: 4 }} />}
										</ContainedButton>
									)}

									{in_edit_mode && (
										<ContainedButton
											onClick={on_edit_press}
											className={classes.draw_btn}
											style={{ background: theme.colors.white, width: 150 }}>
											<img src={ImageLinks.edit_purple} width={16} height={16} alt='edit_map_pencil' />
											<Text semi style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 14 }}>
												Save Changes
											</Text>
										</ContainedButton>
									)}

									{recently_drawn && (
										<div className={classes.added_btn}>
											<Text semi style={{ fontSize: 14, color: theme.colors.white }}>
												Added
											</Text>
										</div>
									)}
								</div>
							)}
							{polygons.length === 0 && !in_draw_mode && !invite_network_id && (
								<div className={classes.map_empty_overlay}>
									<ContainedButton
										disableElevation
										onClick={on_draw_press}
										className={classes.draw_btn}
										style={{ background: theme.colors.white }}>
										<img src={ImageLinks.polygon_purple} width={16} height={16} alt='edit_map_pencil' />
										<Text semi style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 14 }}>
											Draw Polygon
										</Text>
									</ContainedButton>
								</div>
							)}
							<div
								id='map_canvas'
								className='map-canvas-wrapper card'
								style={{ height: '100%', borderRadius: 20, background: theme.colors.lightGrey3 }}></div>
						</div>
					)}
				</div>
			</div>
			<SectionCta
				title={serviciblity_area_tab === 0 ? 'Save Pickup Areas' : 'Save Delivery Areas'}
				is_data_updated={is_data_updated}
				is_disabled={btn_disabled}
				handle_click={handle_save_info}
				total_steps={2}
				lower_section_index={1}
				handle_api_save={handle_serviciblity_api}
				complete_btn_title={'Setup Serviceability'}
				handle_data_updates={handle_data_updates}
			/>
			{show_change_modal && (
				<ConfirmClose
					headerTitle='Set Custom serviceability'
					title='Are you sure you want to set custom serviceability rules for this participant? All default rules will not be considered.'
					show_negative_btn={true}
					close={() => set_show_change_modal(false)}
					on_confirm={() => set_can_change_serviceability(true)}
				/>
			)}
		</div>
	);
};

export default ServicibilityAreas;
