import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import React from 'react';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	img_wrap: {
		marginRight: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1.6px solid ${theme.colors.lightPurple6}`,
		backgroundColor: theme.colors.lightPink4,
	},
	edit_icon: {
		position: 'absolute',
		bottom: 0,
		right: 25,
		cursor: 'pointer',
	},
}));

const NetworkAvatar = ({ diameter = 80, image = '', show_edit = false, on_upload_press = () => {}, on_edit_press = () => {} }) => {
	const classes = useStyles();
	return (
		<div style={{ position: 'relative' }}>
			<div
				onClick={() => {
					if (show_edit) on_upload_press();
				}}
				className={classes.img_wrap}
				style={{
					width: diameter,
					height: diameter,
					borderRadius: diameter / 2,
					...(show_edit && { cursor: 'pointer' }),
				}}>
				{_.isEmpty(image) ? (
					<img src={ImageLinks.upload_purple} width={25} height={25} />
				) : (
					<img src={image} width={diameter} height={diameter} style={{ borderRadius: diameter / 2 }} />
				)}
			</div>
			{show_edit && !_.isEmpty(image) && (
				<div onClick={on_edit_press} className={classes.edit_icon}>
					<img src={ImageLinks.edit_filled_light_purple} width={24} height={24} />
				</div>
			)}
		</div>
	);
};

export default NetworkAvatar;
