import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between' },
	roster_text: { fontSize: 22, marginRight: 50 },
	filter_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderBottom: `1px solid ${theme.colors.lightGrey}`,
		paddingBottom: 10,
		marginTop: 32,
	},
	date_arrow: {
		height: 20,
		width: 20,
		background: theme.colors.lightPurple,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	date_text: { color: theme.colors.black, fontSize: 22, margin: '0 20px' },
	filter_row: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	delete_item: { width: 140, marginRight: 20, borderRadius: 10 },
	delete_text: { color: theme.colors.red, marginLeft: 10 },
	table_header: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
		marginRight: 5,
	},
	row_wrapper: {
		overflowY: 'scroll',
		flex: 1,
		marginTop: 10,
		marginBottom: 10,
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple4,
			borderRadius: 5,
		},
	},
	row_item: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: theme.colors.lightPurple9,
			'& $action_items': {
				visibility: 'visible !important',
			},
		},
	},
	time_slot_header: { color: theme.colors.lightPurple7, fontSize: 14, textAlign: 'center' },
	time_line: { position: 'absolute', width: '2px', backgroundColor: theme.colors.primary, height: '70vh', top: 'calc(100% + 18px)', zIndex: 2 },
	purple_dot: { position: 'absolute', width: 8, height: 8, borderRadius: 4, top: -4, left: -3, backgroundColor: theme.colors.primary },
	time_tag: {
		position: 'absolute',
		width: 100,
		top: -10,
		left: 10,
	},
	time_tag_text: { padding: 4, backgroundColor: 'rgba(73, 40, 151, 0.7)', color: theme.colors.white, borderRadius: 4 },
	delete_container: {
		backgroundColor: theme.colors.lightRed,
		cursor: 'pointer',
		height: 25,
		width: 25,
		borderRadius: 12,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	time_item_row: {
		'&:hover': {
			'& $action_items': {
				visibility: 'visible !important',
			},
		},
	},
	shift_time_wrap: {
		right: 0,
		borderRadius: 30,
		zIndex: 2,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		padding: '4px 8px',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		backgroundColor: theme.colors.white,
		width: 155,
		overflow: 'hidden',
	},

	action_items: {
		position: 'absolute',
		right: 24,
		visibility: 'hidden',
		display: 'flex',
		alignItems: 'center',
		zIndex: 2,
		gap: 8,
	},
	filter_btn: {
		backgroundColor: theme.colors.white,
		width: 35,
		height: 35,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	wrapper_empty_state: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 30 },
	wrapper_help_center: { position: 'fixed', bottom: 30, left: '50%', zIndex: 100 },
	filter_btn: {
		backgroundColor: theme.colors.primary,
		width: 40,
		height: 40,
		marginLeft: 10,
		borderRadius: '100%',
	},
	wrapper_empty_state_no_result: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 30 },
	rider_name: {
		cursor: 'pointer',
		textDecoration: 'underline',
		color: theme.colors.primary,
		fontSize: 16,
		maxWidth: 95,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	rider_id: {
		cursor: 'pointer',
		textDecoration: 'underline',
		color: theme.colors.primary,
		fontSize: 16,
	},
	roster_count: {
		display: 'flex',
		justifyContent: 'center',
		padding: '6px 8px',
		alignItems: 'center',
		gap: 6,
		borderRadius: 30,
		background: '#E4DCF3',
		height: 21,
		cursor: 'pointer',
	},
	slideText: {
		whiteSpace: 'nowrap',
		display: 'inline-block',
		animation: '$slideLeftToRight 5s linear infinite', // Adjust duration and timing function as needed
	},
	'@keyframes slideLeftToRight': {
		'0%': {
			transform: 'translateX(40%)',
		},
		'100%': {
			transform: 'translateX(-80%)',
		},
	},
}));

export default useStyles;
