import React from 'react';
import Text from './Text';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from './ContainedButton';
import OutlinedButton from './OutlinedButton';
import _ from 'lodash';
import utils from 'resources/utils';
import { useDispatch } from 'react-redux';
import { toggle_support_modal } from 'actions/uiUpdates';
import Routes from 'resources/Routes';
import { useHistory } from 'react-router-dom';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	outer_wrap: {
		height: '100vh',
		width: '100vw',
		top: 0,
		left: 0,
		position: 'fixed',
		background: 'rgba(255, 255, 255, 0.70)',
		zIndex: 1500,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0 150px',
	},
	wrapper: {
		padding: 10,
		borderRadius: 16,
		border: `1px solid ${theme.colors.primary}`,
		backgroundColor: theme.colors.white,
		boxShadow: '0px 1.0728px 3.21839px rgba(13, 10, 44, 0.08)',
		zIndex: 1000,
		height: 'auto',
		top: '8%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		overflowY: 'hidden !important',
	},
	upper_half: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.lightPurple9,
		borderRadius: 12,
		padding: 20,
		boxShadow: '0px 1.0728px 3.21839px rgba(13, 10, 44, 0.08)',
	},
	upper_half_grid: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 20,
		width: '90%',
		marginLeft: '5%',
	},

	lower_half: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		padding: 20,
	},
	lower_half_grid: { display: 'grid', gridTemplateColumns: '1fr 2fr', gridGap: 18 },
	grid_item: {
		borderRadius: 12,
		backgroundColor: theme.colors.white,
		boxShadow: '0px 1.5px 4.5px rgba(13, 10, 44, 0.08)',
		padding: '1.2vw',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		width: '32%',
	},
	feature_item_wrap: {
		borderRadius: 12,
		border: `1px solid ${theme.colors.lightPurple}`,
		backgroundColor: theme.colors.white,
		boxShadow: '0px 1.0728px 3.21839px rgba(13, 10, 44, 0.08)',
		padding: '16px 5px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flex: 1,
		marginRight: 11,
	},
	list_item_dot: { width: 3, height: 3, backgroundColor: theme.colors.primary, borderRadius: 3, marginRight: 10 },
	list_item_wrap: { display: 'flex', alignItems: 'center', height: 25 },
	onboard_text_wrap: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', cursor: 'pointer' },
	onboard_text: { fontSize: '0.8vw', color: theme.colors.primary, textDecoration: 'underline', cursor: 'pointer' },
	onboard_desc_text: { fontSize: '0.8vw', marginTop: 7, color: theme.colors.lightPurple6 },
	card_title_text: { fontSize: '1.2vw', marginTop: 12 },
	grid_img: { width: '100%', height: 'auto' },
	grid_text: { fontSize: '0.75vw', marginTop: 11, width: '75%', textAlign: 'center' },
}));

const FirstTimeOverlay = ({ onClose }) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const start_kyc = (is_kyc = false) => {
		const support_data = {
			show_modal: true,
			...(is_kyc && { mode: 'kyc' }),
		};
		dispatch(toggle_support_modal(support_data));
		onClose();
	};

	return (
		<div className={classes.outer_wrap}>
			<div className={classes.wrapper}>
				<div className={classes.upper_half}>
					<div className='d-flex justify-content-between'>
						<div>
							<Text semi style={{ fontSize: '1.2vw', color: theme.colors.primary }}>
								Learn how Pidge can make logistics easier for you!
							</Text>
							<Text semi style={{ fontSize: '0.8vw' }}>
								Kickstart your dashboard with one of these journeys.
							</Text>
						</div>
						<div className={classes.onboard_text_wrap} onClick={onClose}>
							<img src={ImageLinks.crossBlack}></img>
						</div>
					</div>

					<div className={classes.upper_half_grid}>
						<div className={classes.grid_item}>
							<img src={ImageLinks.first_time_img1} className={classes.grid_img} />
							<Text semi className={classes.card_title_text}>
								Create Orders
							</Text>
							<Text semi className={classes.grid_text}>
								Upload and create your orders with accuracy on mobile and web.
							</Text>
							<ContainedButton
								onClick={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.CREATE_ORDER_CLICKED_FROM_GUIDE);
									history.push(Routes.CREATE_ORDER.path);
								}}
								style={{ height: 39, borderRadius: 30, marginTop: 30 }}>
								<img src={ImageLinks.addWhite} width={16} height={16} />
								<Text semi style={{ fontSize: '1vw', marginLeft: 8 }}>
									Create Order
								</Text>
							</ContainedButton>
						</div>
						<div className={classes.grid_item}>
							<img src={ImageLinks.first_time_img2} className={classes.grid_img} />
							<Text semi className={classes.card_title_text}>
								Integrate Channels
							</Text>
							<Text semi className={classes.grid_text}>
								Connect multiple sales channels and manage them on a single dashboard
							</Text>
							<ContainedButton
								onClick={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.INTEGRATE_CHANNELS_FROM_GUIDE);
									history.push(Routes.INTEGRATION_MANAGEMENT.path);
								}}
								style={{ height: 39, borderRadius: 30, marginTop: 30 }}>
								<img src={ImageLinks.addWhite} width={16} height={16} />
								<Text semi style={{ fontSize: '1vw', marginLeft: 8 }}>
									Connect
								</Text>
							</ContainedButton>
						</div>
						<div className={classes.grid_item}>
							<img src={ImageLinks.first_time_img3} className={classes.grid_img} />
							<Text semi className={classes.card_title_text}>
								Create Riders
							</Text>
							<Text semi className={classes.grid_text}>
								Take control of deliveries with your own fleet.
							</Text>
							<ContainedButton
								onClick={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.CREATE_RIDERS_FROM_GUIDE);
									history.push(Routes.ALL_RIDERS.path);
								}}
								style={{ height: 39, borderRadius: 30, marginTop: 30 }}>
								<img src={ImageLinks.addWhite} width={16} height={16} />
								<Text semi style={{ fontSize: '1vw', marginLeft: 8 }}>
									New Rider
								</Text>
							</ContainedButton>
						</div>
					</div>
				</div>
				<div className={classes.lower_half}>
					<div className={classes.lower_half_grid}>
						<div>
							<div>
								<Text semi style={{ fontSize: '1.2vw', color: theme.colors.primary }}>
									Learn More
								</Text>
								<Text semi style={{ fontSize: '0.8vw' }}>
									Get extra assistance to setup your dashboard
								</Text>
							</div>
							<div style={{ display: 'flex', flex: 1, marginTop: 20 }}>
								<div className={classes.feature_item_wrap}>
									<img src={ImageLinks.overlay_doc} width={40} height={40} />
									<Text medium style={{ fontSize: '0.8vw', marginTop: 16 }}>
										Explore features in depth
									</Text>
									<OutlinedButton style={{ height: 32, marginTop: 6 }} onClick={() => window.open('https://pidge.in/helpcenter', '_blank')}>
										<Text semi style={{ fontSize: '0.8vw', color: theme.colors.primary, marginRight: 8 }}>
											Help Centre
										</Text>
										<img src={ImageLinks.arrow_right_purple} width={16} height={16} />
									</OutlinedButton>
								</div>
								<div className={classes.feature_item_wrap}>
									<img src={ImageLinks.overlay_chat} width={40} height={40} />
									<Text medium style={{ fontSize: '0.8vw', marginTop: 16 }}>
										Have a question?
									</Text>
									<OutlinedButton onClick={() => start_kyc(false)} style={{ height: 32, marginTop: 6 }}>
										<Text semi style={{ fontSize: '0.8vw', color: theme.colors.primary }}>
											Request Support
										</Text>
									</OutlinedButton>
								</div>
							</div>
						</div>
						<div style={{ backgroundColor: theme.colors.lightPurple9, borderRadius: 6, display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ paddingTop: 26, paddingLeft: 20 }}>
								<Text bold style={{ fontSize: '2vw', color: theme.colors.primary }}>
									Give more power to your dashboard
								</Text>
								<Text style={{ fontSize: '1vw', color: theme.colors.primary }}>Access features seamlessly across product</Text>

								<ContainedButton onClick={() => start_kyc(true)} style={{ borderRadius: 30, marginTop: 12 }}>
									<Text semi style={{ fontSize: '1.2vw' }}>
										Start KYC
									</Text>
								</ContainedButton>
							</div>
							<img src={ImageLinks.overlay_kyc} style={{ height: '14.5vw', width: 'auto' }} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FirstTimeOverlay;
