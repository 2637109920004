import Text from 'modules/Text';
import React from 'react';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import useStyles from '../styles';
import Select from 'modules/Select';
import theme from 'resources/theme';
import OutlinedTextField from 'modules/OutlinedTextField';
import moment from 'moment';
import Upload from 'modules/Upload';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const RiderPersonalInfo = ({ rider_data, handle_data, handle_dob, rider_id }) => {
	const classes = useStyles();
	return (
		<div>
			<div className='align-items-center'>
				<img src={ImageLinks.phone_solid_circle} width={16} height={16} />
				<Text semi style={{ fontSize: 16, marginLeft: 8 }}>
					Rider Profile
				</Text>
				<div className={classes.section_header_line}></div>
			</div>

			<div style={{ marginTop: 20, display: 'flex' }}>
				<div className={classes.avatar_wrap} style={_.isEmpty(rider_data?.profile_pic) ? { border: `2px solid ${theme.colors.primary}` } : {}}>
					<img
						width={_.isEmpty(rider_data?.profile_pic) ? 50 : 100}
						height={_.isEmpty(rider_data?.profile_pic) ? 50 : 100}
						style={!_.isEmpty(rider_data?.profile_pic) ? { borderRadius: 50 } : {}}
						src={_.isEmpty(rider_data?.profile_pic) ? ImageLinks.user_filled_purple : rider_data.profile_pic}
					/>
				</div>
				<Upload
					style={{
						marginTop: 70,
						marginLeft: -30,
						zIndex: 1200,
					}}
					accepted_extensions='.jpg,.jpeg,.png'
					path={`/rider/${rider_id}/profile`}
					add_image={(data) => {
						handle_data('profile_pic', data.url);
					}}
					is_image_crop={true}>
					<img src={ImageLinks.edit_filled_light_purple} width={32} height={32} />
				</Upload>

				<div style={{ marginLeft: 32 }}>
					<div className='align-items-center'>
						<OutlinedTextField
							disabled
							value={rider_data.first_name}
							name='first_name'
							onChange={(e) => {
								handle_data('first_name', e.target.value);
							}}
							label='First Name*'
							style={{ width: 235, marginRight: 20 }}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								style: { fontSize: 14, color: theme.colors.darkGrey2, fontFamily: 'HK Grotesk SemiBold' },
							}}
						/>

						<OutlinedTextField
							disabled
							value={rider_data.last_name}
							name='last_name'
							onChange={(e) => {
								handle_data('last_name', e.target.value);
							}}
							label='Last Name*'
							style={{ width: 235 }}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								style: { fontSize: 14, color: theme.colors.darkGrey2, fontFamily: 'HK Grotesk SemiBold' },
							}}
						/>
					</div>
					<div className='align-items-center' style={{ marginTop: 20 }}>
						<div style={{ width: '53%', zIndex: 2 }}>
							<DatePicker
								dateFormat='dd-MMM-yyyy'
								selected={
									_.isEmpty(_.get(rider_data, 'date_of_birth', '')) ||
									!moment(_.get(rider_data, 'date_of_birth', ''), 'YYYY-MM-DD', true).isValid()
										? ''
										: new Date(rider_data.date_of_birth)
								}
								onChange={(date) => handle_dob('date_of_birth', date)}
								maxDate={new Date()}
								customInput={
									<OutlinedTextField
										style={{ width: '90%' }}
										textStyle={{ color: theme.colors.black, fontSize: 14 }}
										label='Date of Birth*'
										InputLabelProps={{
											shrink: true,
											style: { fontSize: 14, color: theme.colors.darkGrey2, fontFamily: 'HK Grotesk SemiBold' },
										}}
										value={_.get(rider_data, 'date_of_birth', '')}
									/>
								}
							/>
						</div>

						<div>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2, marginLeft: 10 }}>
								Blood Group*
							</Text>
							<Select
								value={rider_data.blood_group}
								defaultOption={{
									title: `Blood Group*`,
									value: '',
								}}
								options={[
									{ title: 'A+', value: 'A+' },
									{ title: 'A-', value: 'A-' },
									{ title: 'B+', value: 'B+' },
									{ title: 'B-', value: 'B-' },
									{ title: 'AB+', value: 'AB+' },
									{ title: 'AB-', value: 'AB-' },
									{ title: 'O+', value: 'O+' },
									{ title: 'O-', value: 'O-' },
								]}
								handleChange={(v) => {
									handle_data('blood_group', v);
								}}
								containerStyles={{ height: 40, width: 180, marginTop: 5 }}
								text_props={{ semi: true }}
								menu_styles={{
									width: 180,
									height: 200,
								}}
							/>
						</div>
					</div>
					<div className='align-items-center' style={{ marginTop: 20 }}>
						<OutlinedTextField
							disabled
							type='number'
							value={rider_data.mobile}
							name='mobile'
							onChange={(e) => {
								handle_data('mobile', e.target.value);
							}}
							label='Mobile Number*'
							style={{ width: 235, marginRight: 20 }}
							InputLabelProps={{
								style: { fontSize: 14, color: theme.colors.darkGrey2, fontFamily: 'HK Grotesk SemiBold' },
							}}
							InputProps={{
								readOnly: true,
								startAdornment: (
									<Text medium className={classes.mobiel_91_text}>
										+91
									</Text>
								),
							}}
						/>
						<OutlinedTextField
							value={rider_data.aadhar_number}
							name='aadhar_number'
							onChange={(e) => {
								const value = e.target.value;
								const pattern = /^\d+$/;
								if (value.length <= 12 && (pattern.test(value) || value == '')) handle_data('aadhar_number', value);
							}}
							label='Aadhar*'
							style={{ width: 235 }}
							InputLabelProps={{
								style: { fontSize: 14, color: theme.colors.darkGrey2, fontFamily: 'HK Grotesk SemiBold' },
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RiderPersonalInfo;
