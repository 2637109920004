import React, { useEffect, useState, useMemo } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import VehicleOverview from './VehicleOverview';
import VehicleLogsModal from './VehicleLogsModal';
import OdometerModels from './OdometerModels';
import { logs_type } from '../helper';
import utils from 'resources/utils';
import constant from 'resources/constant';
import Button from 'modules/Button';
import RouteStats from './RouteStats';

const useStyles = makeStyles((theme) => ({
	container: {
		borderRadius: 12,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		flex: 1,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		width: '100%',
		minHeight: '70vh',
	},
	action_bar_wrap: {
		backgroundColor: 'rgba(242, 238, 248, 0.30)',
		padding: 12,
		borderBottom: `1px solid ${theme.colors.lightPink4}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	action_text: {
		width: 'auto',
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	header_text: {
		fontSize: 16,
		color: theme.colors.darkGrey7,
	},
	details_wrap: {
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		gap: 10,
		padding: 16,
		borderRadius: 6,
		marginTop: 10,
	},
	number_wrap: {
		padding: '2px 8px',
		borderRadius: 11,
		backgroundColor: theme.colors.lightPurple,
	},
	assignment_period: {
		display: 'flex',
		padding: '0.5rem 0.75rem',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '0.5rem',
		borderRadius: '0rem 0rem 0.5rem 0.5rem',
		background: 'rgba(242, 238, 248, 0.60)',
	},
	arrow: {
		'&:before': {
			border: '1px solid #E6E8ED',
		},
		color: theme.palette.common.white,
	},
	tooltip: {
		backgroundColor: theme.palette.common.white,
		border: '1px solid #E6E8ED',
		color: '#4A4A4A',
		boxShadow: '0px 0px 4px 0px rgba(102, 102, 102, 0.30)',
		padding: 6,
	},
}));

function FleetDetails({ selected_vehicle, set_assignment_data, set_edit_vehicle, set_edit_assignment_detail, vehicle_list, get_vehicle_list }) {
	const classes = useStyles();
	const [assignment_details, set_assignment_details] = useState({ ongoing: [] });
	const [show_logs, set_show_logs] = useState(null);
	const [show_odometer, set_show_odometer] = useState(false);
	const [start_km, set_start_km] = useState(true);
	const assignments_unavailable =
		assignment_details.ongoing.length == 0 && assignment_details.upcoming == 0 && assignment_details.scheduled == 0 && assignment_details.past == 0;

	useEffect(() => {
		if (selected_vehicle.id) get_vehicle_details();
	}, [selected_vehicle, vehicle_list]);

	const get_vehicle_details = async () => {
		try {
			const res = await api_requests.get_fleet_details(selected_vehicle.id);
			set_assignment_details(res.data);
		} catch (err) {}
	};

	const fleet_status = useMemo(() => {
		let title = '';
		if (!_.isEmpty(assignment_details?.ongoing[0]?.start_time) && !_.isEmpty(assignment_details?.ongoing[0]?.end_time)) {
			title =
				_.isEmpty(assignment_details.ongoing[0].checkin_time) &&
				moment(moment().format('YYYY-MM-DD HH:mm')).isBefore(moment(assignment_details.ongoing[0].start_time).format('YYYY-MM-DD HH:mm')) &&
				(moment(moment().format('YYYY-MM-DD')).add(1, 'day').isSame(moment(assignment_details.ongoing[0].start_time).format('YYYY-MM-DD')) ||
					moment(moment().format('YYYY-MM-DD HH:mm'))
						.add(1, 'day')
						.isAfter(moment(assignment_details.ongoing[0].start_time).format('YYYY-MM-DD HH:mm')))
					? 'Upcoming'
					: _.isEmpty(assignment_details.ongoing[0].checkin_time) &&
					  moment(moment().format('YYYY-MM-DD HH:mm')).isAfter(moment(assignment_details.ongoing[0].start_time).format('YYYY-MM-DD HH:mm'))
					? 'Not Started'
					: !_.isEmpty(assignment_details.ongoing[0].checkin_time) &&
					  moment(moment(assignment_details.ongoing[0].start_time).format('YYYY-MM-DD HH:mm'))
							.add(15, 'minutes')
							.isSameOrAfter(moment(assignment_details.ongoing[0].checkin_time).format('YYYY-MM-DD HH:mm'))
					? 'Started Ontime'
					: !_.isEmpty(assignment_details.ongoing[0].checkin_time) &&
					  moment(moment(assignment_details.ongoing[0].start_time).format('YYYY-MM-DD HH:mm'))
							.add(15, 'minutes')
							.isSameOrBefore(moment(assignment_details.ongoing[0].checkin_time).format('YYYY-MM-DD HH:mm'))
					? 'Delayed'
					: '';
		}
		return title;
	}, [assignment_details]);

	const handle_trace_nav = () => {
		const endpoint = constant.TRACE_FE_URL;
		const owner_id = utils._retrieveData(constant.OWNER_ID);
		const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER).id;
		const token = utils._retrieveData(constant.AUTH_TOKEN);
		const obj = {
			entity_id: selected_vehicle.id,
			entity_type: 'fleet',
			owner_id,
			owner_type,
			token,
		};
		const url = `${endpoint}?${qs.stringify(obj)}`;
		window.open(url, '_blank');
	};

	return (
		<div style={{ flex: 1 }}>
			<div className={classes.container}>
				<div className={classes.action_bar_wrap}>
					<Text medium>Quick Action</Text>
					<div className='d-flex' style={{ gap: 10 }}>
						{selected_vehicle.trace_entity_id !== null && (
							<div className='d-flex align-items-center' onClick={handle_trace_nav}>
								<img src={ImageLinks.track_filled_purple} height={14} width={14} />
								<Text semi className={classes.action_text}>
									Trace
								</Text>
							</div>
						)}
						{!assignments_unavailable && (
							<Button
								type='round'
								text='Assignment'
								left_icon={ImageLinks.addWhite}
								onClick={() => {
									set_edit_assignment_detail({});
									set_assignment_data(selected_vehicle);
								}}
								size='extra_small'
							/>
						)}
						<img
							src={ImageLinks.edit_filled_light_purple}
							height={26}
							width={26}
							style={{ cursor: 'pointer' }}
							onClick={() => set_edit_vehicle(selected_vehicle)}
						/>
					</div>
				</div>
				<div style={{ padding: 16 }}>
					<VehicleOverview selected_vehicle={selected_vehicle} />
					<Text semi className={classes.header_text}>
						Assignment Status
					</Text>
					{assignments_unavailable && (
						<div
							className='d-flex justify-content-center align-items-center'
							style={{ marginTop: 60, width: '100%', flexDirection: 'column', gap: 10 }}>
							<img src={ImageLinks.no_assignment} width={100} height={100} />
							<Text bold>No assignment yet!</Text>
							<Button
								type='round'
								text='Assignment'
								size='small'
								onClick={() => {
									set_edit_assignment_detail({});
									set_assignment_data(selected_vehicle);
								}}
								left_icon={ImageLinks.addWhite}
							/>
						</div>
					)}
					{assignment_details.ongoing.length > 0 && (
						<>
							<div className={classes.details_wrap}>
								<div className='d-flex align-items-center justify-content-between'>
									<div className='d-flex align-items-center' style={{ gap: 5 }}>
										<Text semi>Ongoing</Text>
										<Tooltip title={fleet_status} arrow={true} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} placement='top'>
											<img
												src={
													fleet_status == 'Not Started'
														? ImageLinks.exclamation_error
														: fleet_status == 'Started Ontime'
														? ImageLinks.green_tick
														: fleet_status == 'Delayed'
														? ImageLinks.tick_circle_solid_yellow
														: fleet_status == 'Upcoming'
														? ImageLinks.clock
														: ''
												}
											/>
										</Tooltip>
									</div>
									<div className='d-flex align-items-center' style={{ gap: 4 }}>
										<img
											src={assignment_details.ongoing[0].rider_id == '-1' ? ImageLinks.no_rider_icon : ImageLinks.rider_light_purple2}
											height={16}
											width={16}
										/>
										<Text semi style={{ textDecoration: 'underline' }}>
											{_.get(assignment_details, 'ongoing.0.rider_name', '-')}
										</Text>
										<Text semi>
											{assignment_details.ongoing[0].rider_id == '-1' ? 'No Rider' : _.get(assignment_details, 'ongoing.0.rider_id', '-')}
										</Text>
										|
										<img
											src={ImageLinks.edit_filled_light_purple}
											height={16}
											width={16}
											style={{ cursor: 'pointer' }}
											onClick={() => {
												set_assignment_data(selected_vehicle);
												set_edit_assignment_detail(assignment_details.ongoing[0]);
											}}
										/>
									</div>
								</div>
								<Divider style={{ margin: '10px 0' }} />
								<div>
									<div className='d-flex align-items-center justify-content-between'>
										<div className='d-flex align-items-center' style={{ gap: 4, flex: 0.58 }}>
											<img src={ImageLinks.rider_pickup} height={24} width={24} />
											<Text semi>{_.get(assignment_details, 'ongoing.0.pickup_address', '-')}</Text>
										</div>
										<div className='d-flex align-items-center' style={{ gap: 4, flex: 0.42, justifyContent: 'flex-end' }}>
											<img src={ImageLinks.wheel} height={14} width={14} />
											<Text
												bold
												className={classes.action_text}
												onClick={() => {
													set_show_odometer(true);
													set_start_km(true);
												}}>
												{_.isEmpty(assignment_details.ongoing[0].checkin_time)
													? 'Check In'
													: assignment_details.ongoing[0]?.odometer_start == null
													? 'Add KM'
													: assignment_details.ongoing[0]?.odometer_start}
											</Text>
											{!_.isEmpty(assignment_details.ongoing[0].checkin_time) && (
												<>
													<Text semi>|</Text>
													<Text medium>{moment(assignment_details.ongoing[0].checkin_time).format("DD MMM 'YY, hh:mmA")}</Text>
												</>
											)}
										</div>
									</div>
									<div className='d-flex align-items-center justify-content-between' style={{ marginTop: 20 }}>
										<div className='d-flex align-items-center' style={{ gap: 4 }}>
											<img src={ImageLinks.marker_down_arrow} height={24} width={24} />
											<Text semi>{_.get(assignment_details, 'ongoing.0.drop_address', '-')}</Text>
										</div>
										<div className='d-flex align-items-center' style={{ gap: 4 }}>
											<img src={ImageLinks.wheel} height={14} width={14} />
											<Text
												bold
												className={classes.action_text}
												onClick={() => {
													set_show_odometer(true);
													set_start_km(false);
												}}>
												{_.isEmpty(assignment_details.ongoing[0].checkout_time)
													? 'Check Out'
													: assignment_details.ongoing[0]?.odometer_end == null
													? 'Add KM'
													: assignment_details.ongoing[0]?.odometer_end}
											</Text>
											{!_.isEmpty(assignment_details.ongoing[0].checkout_time) && (
												<>
													<Text semi>|</Text>
													<Text medium>{moment(assignment_details.ongoing[0].checkout_time).format("DD MMM 'YY, hh:mmA")}</Text>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className={classes.assignment_period}>
								<div className='d-flex' style={{ gap: 8 }}>
									<Text medium style={{ color: theme.colors.lightPurple6, fontSize: 12 }}>
										Assignment Period:
									</Text>
									<Text medium style={{ color: theme.colors.darkGrey7, fontSize: 12 }}>
										{moment(assignment_details.ongoing[0].start_time).format("DD MMM 'YY, hh:mmA")} -{' '}
										{moment(assignment_details.ongoing[0].end_time).format("DD MMM 'YY, hh:mmA")}
									</Text>
								</div>
							</div>

							{!_.isEmpty(_.get(assignment_details, 'ongoing.0.route_meta', {})) && (
								<RouteStats
									trace_entity_id={selected_vehicle.trace_entity_id}
									route_data={{ ...assignment_details.ongoing[0].route_meta, route_id: assignment_details.ongoing[0].route_id }}
								/>
							)}
						</>
					)}
					{_.map(logs_type, (item) => {
						if (assignment_details[item.key] > 0) {
							return (
								<div className={classes.details_wrap}>
									<div className='d-flex justify-content-between'>
										<div className='d-flex align-items-center' style={{ gap: 5 }}>
											<Text semi>{item.title} | </Text>
											<div className={classes.number_wrap}>
												<Text semi style={{ color: theme.colors.primary }}>
													{assignment_details[item.key]}
												</Text>
											</div>
										</div>
										<div
											onClick={() => {
												set_show_logs(item.key);
											}}>
											<img
												src={ImageLinks.arrow_up_circle_solid}
												height={20}
												width={20}
												style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
											/>
										</div>
									</div>
								</div>
							);
						}
					})}
				</div>
			</div>
			{show_logs && (
				<VehicleLogsModal
					initial_tab={show_logs}
					selected_vehicle={selected_vehicle}
					close={() => set_show_logs(null)}
					set_assignment_data={set_assignment_data}
					set_edit_assignment_detail={set_edit_assignment_detail}
					vehicle_list={vehicle_list}
					get_vehicle_details={get_vehicle_details}
					get_vehicle_list={get_vehicle_list}
				/>
			)}
			{show_odometer && (
				<OdometerModels
					close={() => set_show_odometer(null)}
					start_km={start_km}
					assingment_id={_.get(assignment_details, `ongoing.0.assingment_id`)}
					odometer_distance={_.get(assignment_details, `${start_km ? 'ongoing.0.odometer_start' : 'ongoing.0.odometer_end'}`)}
					odometer_image={_.get(assignment_details, `${start_km ? 'ongoing.0.checkin_images.0' : 'ongoing.0.checkout_images.0'}`)}
					updated_at={_.get(assignment_details, `ongoing.0.updated_at`)}
					updated_by={_.get(assignment_details, `ongoing.0.updated_by`)}
					update_data={get_vehicle_details}
					rider_name={_.get(assignment_details, `ongoing.0.rider_name`)}
					checkin_time={_.get(assignment_details, `ongoing.0.checkin_time`)}
					checkout_time={_.get(assignment_details, `ongoing.0.checkout_time`)}
					odometer_start={_.get(assignment_details, `ongoing.0.odometer_start`)}
					odometer_end={_.get(assignment_details, `ongoing.0.odometer_end`)}
					get_vehicle_list={get_vehicle_list}
				/>
			)}
		</div>
	);
}

export default FleetDetails;
