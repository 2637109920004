import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React from 'react';

function Steps() {
	return (
		<div style={{ background: 'rgba(243, 229, 242, 0.2)', padding: 20, borderRadius: 6, marginTop: 50 }}>
			<div style={{ display: 'flex', gap: 8 }}>
				<img src={ImageLinks.exclamation_purple}></img>
				<Text bold style={{ fontSize: 18 }}>
					Ensuring a successful integration
				</Text>
			</div>
			<div style={{ padding: 20 }}>
				<ol style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
					<li>
						<Text style={{ fontSize: 14 }}>
							Integrations are ideal for making sure that order details are accurate, and there is no human error involved
						</Text>
					</li>
					<li>
						<Text style={{ fontSize: 14 }}>
							Pidge integration documents support exhaustive or minimal payloads depending on information available with the demand channel or
							order management system (OMS)
						</Text>
					</li>
					<li>
						<Text style={{ fontSize: 14 }}>
							When minimal information is used for creating orders, Pidge uses proprietary technology to bridge the gaps. This includes our
							geocoding engine, package management software, and more
						</Text>
					</li>
					<li>
						<Text style={{ fontSize: 14 }}>
							Webhooks are a great way to get real-time updates about your order. Check with your integration partner before you input the
							information here.
						</Text>
					</li>
					<li>
						<Text style={{ fontSize: 14 }}>
							View the Pidge API documentation here -{' '}
							<a target={'_blank'} style={{ fontSize: 14 }} href='https://documenter.getpostman.com/view/13758726/2s93RKzFtk'>
								https://documenter.getpostman.com/view/13758726/2s93RKzFtk
							</a>
						</Text>
					</li>
				</ol>
			</div>
		</div>
	);
}

export default Steps;
