import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import qs from 'qs';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { hide_loader, show_loader } from 'actions/app';
import api_requests from 'resources/api_requests';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import CreateRider from './components/CreateRider';
import Select from 'modules/Select';
import Filters from 'modules/Filters/Filters';
import { makeStyles } from '@material-ui/styles';
import Routes from 'resources/Routes';
import Amount from 'modules/Amount';
import { Link } from 'react-router-dom';
import utils from 'resources/utils';
import FilterButton from 'modules/FilterButton';
import BulkRiderUploadModal from './components/BulkRiderUpload/BulkRiderUploadModal';
import Button from 'modules/Button';
import EmptyState from 'modules/EmptyState';
import Helpcenter from 'modules/HelpCenter';
import RiderOverviewModal from 'modules/modals/RiderOverviewModal';
import NoResult from 'modules/NoResult';
import constant from 'resources/constant';

const useStyles = makeStyles({
	wrapper: {
		height: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		background: theme.colors.white,
	},
	header: { display: 'flex', justifyContent: 'space-between' },
	header_title: { fontSize: 22, marginRight: 50 },
	rider_name: {
		fontSize: 16,
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
	filter_wrapper: {
		display: 'flex',
		marginTop: 30,
		paddingBottom: 10,
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderBottom: `1px solid ${theme.colors.lightGrey}`,
	},
	total_riders_text: { fontSize: 22, color: theme.colors.darkGrey2 },
	rider_count_text: { fontSize: 22, color: theme.colors.black, paddingLeft: 2 },
	table_header_wrapper: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
	},
	table_header_3: {
		flex: 3,
		color: theme.colors.darkGrey2,
		fontSize: 14,
	},
	header_ongoing_text: { color: theme.colors.darkGrey2, backgroundColor: theme.colors.lightYellow, padding: 8, borderRadius: 5 },
	header_complete_text: { color: theme.colors.darkGrey2, backgroundColor: theme.colors.lightGreen, padding: 8, borderRadius: 5 },
	header_failed_text: { color: theme.colors.darkGrey2, backgroundColor: theme.colors.lightRed, padding: 8, borderRadius: 5 },
	header_today_text: { fontSize: 10, width: 50, color: theme.colors.darkGrey2 },
	row_wrapper: { overflowY: 'auto', flex: 1, marginTop: 10, marginBottom: 10 },
	rider_dot: {
		width: 8,
		height: 8,
		borderRadius: 4,
		marginRight: 12,
	},
	riderRow: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'auto',
	},
	rider_row_arrow: {
		flex: 3,
		display: 'flex',
		alignItems: 'center',
		gap: 4,
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
			'& > .arrow_right': {
				visibility: 'visible !important',
			},
		},
	},
	filter_btn: {
		backgroundColor: theme.colors.primary,
		width: 40,
		height: 40,
		marginLeft: 10,
		borderRadius: '100%',
	},
	rider_analytics_text: {
		fontSize: 18,
		textDecoration: 'underline',
		color: theme.colors.primary,
		marginLeft: 8,
	},
	bulk_rider_button: {
		height: 36,
		width: 36,
		marginRight: 5,
		'& .button-text': {
			display: 'none !important',
			marginLeft: 5,
		},
		'&:hover': {
			width: 'auto',
			'& .button-text': {
				display: 'inline !important',
			},
		},
	},
	wrapper_empty_state: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 30 },
	wrapper_empty_state_no_result: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 30 },
	wrapper_help_center: { position: 'fixed', bottom: 30, left: '50%' },
	total_btn: {
		padding: '8px 16px',
		backgroundColor: theme.colors.primary,
		color: theme.colors.white,
		borderRadius: 8,
	},
});

const report_filters = [
	{
		multi: true,
		param: 'date',
		title: 'Transaction Date',
		type: 'date',
	},
];

const AllRiders = ({ history }) => {
	const { is_loading } = useSelector((state) => state.app, shallowEqual);
	const dispatch = useDispatch();

	const query_object = qs.parse(history.location.search.slice(1)) || {};
	const filter_object = query_object.filter || { is_active: 1 };

	const [show_create_rider, set_show_create_rider] = useState(false);
	const [riders, set_riders] = useState([]);
	const [refresh_riders, set_refresh_riders] = useState(false);

	const [show_filters, set_show_filters] = useState(false);
	const [all_filters, set_all_filters] = useState([]);
	const [report_items, set_report_items] = useState([]);
	const [show_bulk_upload_modal, set_show_bulk_upload_modal] = useState(false);
	const [rider_info_details, set_rider_info_details] = useState(null);

	const classes = useStyles();

	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);

	const [selected_master_zone, set_selected_master_zone] = useState(zones[0].id);

	const get_riders = () => {
		dispatch(show_loader());
		api_requests
			.get_riders(selected_master_zone, filter_object)
			.then((res) => {
				set_riders(res.data.records);
				dispatch(hide_loader());
			})
			.catch((err) => {
				dispatch(hide_loader());
			});
	};

	useEffect(() => {
		api_requests
			.get_all_riders_filters(selected_master_zone)
			.then((res) => {
				set_all_filters(res.data.filters);
			})
			.catch((err) => {
				dispatch(hide_loader());
			});
		get_filter_items();
	}, [selected_master_zone]);

	useEffect(() => {
		set_riders([]);
		get_riders();
	}, [selected_master_zone, refresh_riders, JSON.stringify(filter_object)]);

	const get_dot_color = (item) => {
		if (item.is_online && item.ongoing === 0) return theme.colors.green;
		if (item.ongoing >= 1) return theme.colors.darkYellow;
		if (!item.is_online && item.shift !== '-') return theme.colors.red;
		if (item.shift === '-') return theme.colors.lightGrey2;
	};

	const get_rider_info_detail = async (rider_id) => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_NAME, { rider_id });
		try {
			const res = await api_requests.get_rider_details(rider_id);
			if (res.success) {
				set_rider_info_details(res.data);
			}
		} catch (err) {}
	};

	const get_filter_items = async () => {
		try {
			const response = await api_requests.get_rider_export_items();
			if (response.success) set_report_items(response.data);
		} catch (error) {}
	};

	const handle_export = async (selected_filters, callback) => {
		try {
			const response = await api_requests.export_rider(selected_filters);
			utils.downloadBlob(response, 'rider.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	const handle_trace_nav = (item) => {
		const endpoint = constant.TRACE_FE_URL;
		const owner_id = utils._retrieveData(constant.OWNER_ID);
		const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER).id;
		const token = utils._retrieveData(constant.AUTH_TOKEN);
		const obj = {
			entity_id: item.trace_entity_id,
			entity_type: 'rider',
			owner_id,
			owner_type,
			token,
		};
		const url = `${endpoint}?${qs.stringify(obj)}`;
		window.open(url, '_blank');
	};

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<Text semi className={'global_header_text'}>
					My Riders
				</Text>

				{(riders.length !== 0 || Object.keys(filter_object).length != 0) && (
					<div className='d-flex'>
						<Button
							text='Bulk Upload'
							type='outlined'
							size='large'
							className={classes.bulk_rider_button}
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.BULK_RIDER);
								set_show_bulk_upload_modal(true);
							}}
							left_icon={ImageLinks.upload_purple}
							left_image_styles={{ marginLeft: 4 }}
						/>

						<Button
							type='round'
							text='New Rider'
							size='large'
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.NEW_RIDER);
								set_show_create_rider(true);
							}}
							left_icon={ImageLinks.addWhite}
						/>
					</div>
				)}
			</div>
			{(riders.length !== 0 || Object.keys(filter_object).length != 0) && (
				<div className={classes.filter_wrapper}>
					<div className='align-items-center'>
						<div className='d-flex' style={{ height: 35 }}>
							<div className={classes.total_btn}>
								<Text bold>Total ({riders.length})</Text>
							</div>
						</div>
						<Link to={Routes.ADVANCED_RIDER_ANALYTICS.path} style={{ textDecoration: 'none' }}>
							<Button
								onClick={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.ADVANCED_RIDER_ANALYTICS);
								}}
								text='Advanced Rider Analytics'
								type='text'
								size='large'
								right_icon={ImageLinks.arrow_right_purple}
								style={{ marginLeft: 8 }}
							/>
						</Link>
					</div>
					<div style={{ display: 'flex' }}>
						<FilterButton
							handle_click={() => set_show_filters(true)}
							total_filters={Object.keys(filter_object).length}
							wrapper_styles={{ marginLeft: 10 }}
							track_event={{ event: constant.TRACKING_EVENTS.ALL_RIDER_FILTER, properties: filter_object }}
						/>
					</div>
				</div>
			)}
			{(riders.length !== 0 || Object.keys(filter_object).length != 0) && (
				<div className={classes.table_header_wrapper}>
					<Text bold className={classes.table_header_3}>
						Rider Name (ID)
					</Text>

					<Text bold className={classes.table_header_3}>
						Outstanding
					</Text>

					<div style={{ flex: 2 }}>
						<Text bold component={'span'} className={classes.header_ongoing_text}>
							Ongoing
						</Text>
					</div>
					<div style={{ flex: 1 }}>
						<Text bold className={classes.header_today_text}>
							Today’s Deliveries:
						</Text>
					</div>
					<div style={{ flex: 2 }}>
						<Text bold component={'span'} className={classes.header_complete_text}>
							Complete
						</Text>
					</div>
					<div style={{ flex: 2 }}>
						<Text bold component={'span'} className={classes.header_failed_text}>
							Failed
						</Text>
					</div>

					<Text bold className={classes.table_header_3}>
						Shift Time
					</Text>

					<div style={{ flex: 1 }}></div>
				</div>
			)}
			{riders.length !== 0 && (
				<div className={classes.row_wrapper}>
					{_.map(riders, (rider, key) => {
						return (
							<div key={`rider${key}`} className={classes.riderRow}>
								<div style={{ flex: 3, display: 'flex', alignItems: 'center' }} onClick={() => get_rider_info_detail(rider.rider_id)}>
									<div
										className={classes.rider_dot}
										style={{
											backgroundColor: get_dot_color(rider),
										}}></div>
									<Text medium className={classes.rider_name} style={{}}>
										{rider.rider_name} ({rider.rider_id})
									</Text>
								</div>
								<div
									onClick={() => history.push(Routes.CASH_MANAGEMENT_RIDER_DETAIL.path.replace(':id', rider.rider_id))}
									className={classes.rider_row_arrow}>
									<Amount
										amount={rider.outstanding}
										main_text_styles={{
											fontSize: 14,
											color: theme.colors.black,
										}}
										sub_text_styles={{
											fontSize: 10,
											color: theme.colors.black,
										}}
									/>
									<div style={{ visibility: 'hidden' }} className='arrow_right'>
										<img src={ImageLinks.chevron_right_black} width='10' height={10} />
									</div>
								</div>
								<div
									className={classes.rider_row_arrow}
									style={{ flex: 2 }}
									onClick={() => history.push(Routes.ALL_ORDERS_BY_RIDER.path.replace(':id', rider.rider_id))}>
									<Text medium style={{ fontSize: 16 }}>
										{rider.ongoing}
									</Text>
									<div style={{ visibility: 'hidden' }} className='arrow_right'>
										<img src={ImageLinks.chevron_right_black} width='10' height={10} />
									</div>
								</div>
								<Text medium style={{ flex: 1, fontSize: 16 }}></Text>
								<Text medium style={{ flex: 2, fontSize: 16 }}>
									{rider.completed}
								</Text>
								<Text medium style={{ flex: 2, fontSize: 16 }}>
									{rider.failed}
								</Text>
								<div
									className={classes.rider_row_arrow}
									style={{ flex: 4 }}
									onClick={() => history.push(Routes.ROSTER.path + `/?rider_id=${rider.rider_id}&rider_name=${rider.rider_name}`)}>
									<Text medium style={{ fontSize: 16 }}>
										{rider.shift}
									</Text>
									<div style={{ visibility: 'hidden' }} className='arrow_right'>
										<img src={ImageLinks.chevron_right_black} width='10' height={10} />
									</div>
								</div>
								{rider?.trace_entity_id && (
									<div className='align-items-center cursor-pointer' style={{ flex: 1 }} onClick={() => handle_trace_nav(rider)}>
										<img src={ImageLinks.tracking_selected} width={12} height={12} />
										<Text semi style={{ fontSize: 12, color: theme.colors.primary, textDecoration: 'underline' }}>
											Trace
										</Text>
									</div>
								)}
							</div>
						);
					})}
					{riders.length < 8 && (
						<div className={classes.wrapper_help_center}>
							<Helpcenter text='How do I create a rider?' url='https://pidge.in/helpcenter-rider-createrider/' />
						</div>
					)}
				</div>
			)}
			{riders.length == 0 && !is_loading && Object.keys(filter_object).length == 0 && (
				<div className={classes.wrapper_empty_state}>
					<EmptyState
						button_text='New Rider'
						left_icon={ImageLinks.addWhite}
						empty_img={ImageLinks.empty_state_roster}
						onClick={() => {
							utils.track_event_for_analytics(constant.TRACKING_EVENTS.NEW_RIDER);
							set_show_create_rider(true);
						}}
					/>
					<Helpcenter text='How do I create a rider?' url='https://pidge.in/helpcenter-rider-createrider/' />
				</div>
			)}
			{riders.length == 0 && !is_loading && Object.keys(filter_object).length != 0 && (
				<div className={classes.wrapper_empty_state_no_result}>
					<NoResult text='No Rider found' btn_onclick={() => history.replace({ search: '' })} />
				</div>
			)}
			{show_filters && (
				<Filters
					all_filters={all_filters}
					existing_filters={filter_object}
					set_selected_filters={(filters) => {
						history.push(Routes.ALL_RIDERS.path + '/?' + qs.stringify({ filter: filters }));
					}}
					close={() => set_show_filters(false)}
					title=''
					handle_export={handle_export}
					report_items={report_items}
					report_filters={report_filters}
					is_report_enabled={true}
					skip_date_check={true}
					date_check_array={['performance', 'rider_task']}
					is_report_item_single_select={true}
				/>
			)}
			{show_create_rider && (
				<CreateRider
					zone_id={selected_master_zone}
					close={(isCreated) => {
						if (isCreated) {
							set_refresh_riders(!refresh_riders);
						}
						set_show_create_rider(false);
					}}
				/>
			)}
			{show_bulk_upload_modal && <BulkRiderUploadModal set_refresh_riders={set_refresh_riders} close={() => set_show_bulk_upload_modal(false)} />}
			{!_.isEmpty(rider_info_details) && (
				<RiderOverviewModal rider_data={rider_info_details} set_rider_data={set_rider_info_details} close={() => set_rider_info_details(null)} />
			)}
		</div>
	);
};

export default AllRiders;
