export const LOGIN_DETAILS_RECEIVED = 'LOGIN_DETAILS_RECEIVED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const USER_DETAILS_RECEIVED = 'USER_DETAILS_RECEIVED';
export const REFRESH_MASTER_ZONES = 'REFRESH_MASTER_ZONES';

export const MASTER_ZONES_RECEIVED = 'MASTER_ZONES_RECEIVED';
export const BUSINESS_PROPERTIES_RECEIVED = 'BUSINESS_PROPERTIES_RECEIVED';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const ADD_USER_TEAMS = 'ADD_USER_TEAMS';
export const ADD_NEW_ALERT = 'ADD_NEW_ALERT';
export const REMOVE_ALL_ALERTS = 'REMOVE_ALL_ALERTS';

export const SUPPORT_MODAL_TOGGLE = 'SUPPORT_MODAL_TOGGLE';
export const TOGGLE_NOTIF_MODAL = 'TOGGLE_NOTIF_MODAL';
export const UPDATE_BIZ_LIST = 'UPDATE_BIZ_LIST';
