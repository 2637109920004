import { makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
	count_wrapper: { border: `1px solid ${theme.colors.lightPink4}`, borderRadius: 8, padding: '8px 16px' },
	id_wrapper: { borderRadius: 8, padding: '8px 16px', display: 'flex', cursor: 'pointer', '&:active': { boxShadow: 'none' } },
	marker_text: { color: theme.colors.white, zIndex: 3, fontSize: 12 },
	list_icon: {
		backgroundColor: theme.colors.lightPurple5,
		borderRadius: '50%',
		width: 20,
		height: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

function IdList({ selected_ids_list_data, handle_pbid_select, set_active_pbid_list, active_pbid_list = [], count, set_count, set_active_pickup, history }) {
	const classes = useStyles();
	const theme = useTheme();
	const [time, set_time] = useState(3);
	let interval_id = useRef();
	useEffect(() => {
		set_active_pickup({
			latitude: _.get(selected_ids_list_data[0], 'pickup_latitude', ''),
			longitude: _.get(selected_ids_list_data[0], 'pickup_longitude', ''),
		});
	}, []);
	useEffect(() => {
		if (selected_ids_list_data.length == 0 && time > 0) {
			interval_id.current = setTimeout(() => set_time(time - 1), 1000);
		}
		if (time == 0) {
			set_active_pickup(null);
			history.push('/grouping-and-allocation?type=unallocated');
		}
		return () => clearTimeout(interval_id.current);
	}, [selected_ids_list_data, time]);
	return (
		<div className='d-flex align-items-center' style={{ gap: 10 }}>
			<div className={classes.count_wrapper}>
				<Text bold style={{ fontSize: 14, color: theme.colors.darkPurple }}>
					{selected_ids_list_data.length > 0 ? `${count * 5 + 1}-${Math.min(count * 5 + 5, selected_ids_list_data.length)} ` : '0 '}
					<Text bold style={{ fontSize: 14, color: theme.colors.lightPink5 }} component={'span'}>{`of ${selected_ids_list_data.length}`}</Text>
				</Text>
			</div>
			<img
				src={ImageLinks.chevronLeft}
				width={7}
				height={12}
				onClick={() => {
					set_active_pbid_list([]);
					set_count(Math.max(count - 1, 0));
				}}></img>
			{_.map(_.slice(selected_ids_list_data, count * 5, count * 5 + 5), (data, index) => {
				return (
					<div
						draggable={_.includes(active_pbid_list, data.mcp_id)}
						onDragStart={(e) => {}}
						onDragEnter={(e) => {}}
						onDragEnd={() => {}}
						className={classes.id_wrapper}
						style={{
							border: `1px solid ${_.includes(active_pbid_list, data.mcp_id) ? theme.colors.primary : theme.colors.lightPurple}`,
							backgroundColor: `${_.includes(active_pbid_list, data.mcp_id) ? theme.colors.lightPink4 : 'transparent'}`,
						}}
						onClick={() => handle_pbid_select(data.mcp_id)}
						key={`id_list_${index}`}>
						<div className={classes.list_icon}>
							<Text bold className={classes.marker_text}>
								{count * 5 + index + 1}
							</Text>
						</div>
						<Text component={'span'} style={{ marginLeft: 5 }}>
							{data.mcp_id}
						</Text>
					</div>
				);
			})}
			{selected_ids_list_data.length === 0 && (
				<Text semi style={{ fontSize: 18 }}>
					All orders have been routed.
					<Text component='span' semi style={{ color: theme.colors.primary, fontSize: 18 }}>
						Redirecting to{' '}
						<Text component='span' semi style={{ color: theme.colors.primary, fontSize: 18, textDecoration: 'underline' }}>
							Routes
						</Text>{' '}
						in {time}
					</Text>
				</Text>
			)}
			<img
				width={7}
				height={12}
				src={ImageLinks.chevronLeft}
				style={{ transform: 'rotate(180deg)' }}
				onClick={() => {
					set_active_pbid_list([]);
					set_count(Math.min(count + 1, Math.floor((selected_ids_list_data.length - 1) / 5)));
				}}></img>
		</div>
	);
}

export default IdList;
