import React, { useContext } from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import SubjectItem from 'views/Alerts/CreateAlert/components/SubjectItem';
import CreateCommunicationContext from '../context';
import { makeStyles } from '@material-ui/core';
import { subject_options } from '../helper';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	subject_wrap: {
		display: 'flex',
		marginTop: 12,
		marginBottom: 30,
	},
}));

const SubjectList = () => {
	const { selected_subject, comm_id, handle_selected_subject } = useContext(CreateCommunicationContext);
	const classes = useStyles();
	return (
		<>
			<Text bold style={{ fontSize: 18 }}>
				Subject
			</Text>
			<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
				Select the entity highlighted in the alert
			</Text>

			<div className={classes.subject_wrap}>
				{_.map(subject_options, (subject, key) => (
					<SubjectItem
						key={`subject_${key}`}
						title={subject.title}
						icon={subject.icon}
						is_selected={subject.key === selected_subject}
						onClick={() => {
							utils.track_event_for_analytics(`communication_${subject.key}`);
							handle_selected_subject(subject.key, subject.is_disabled);
						}}
						is_disabled={subject.is_disabled || comm_id != null}
					/>
				))}
			</div>
		</>
	);
};

export default SubjectList;
