import theme from 'resources/theme';
import _ from 'lodash';
import { DateRangePicker } from 'materialui-daterange-picker';
import Text from 'modules/Text';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import moment from 'moment';
import React, { useEffect } from 'react';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';

const DepositHistory = ({ user_id }) => {
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);
	const [filters, set_filters] = React.useState({
		from: moment(moment().subtract('15', 'days').format('MM-DD-YYYY')),
		to: moment(moment().format('MM-DD-YYYY')),
	});

	const [transactions, set_transactions] = React.useState([]);

	useEffect(() => {
		api_requests
			.get_cm_history({
				from: filters.from.format(),
				to: filters.to.endOf('day').format(),
				type: 100,
				user_type: 'user',
				id: user_id,
			})
			.then((res) => {
				set_transactions(res.data.records);
			});
	}, [filters]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: 20,
				}}>
				<div
					style={{
						display: 'flex',
						alignItems: 'flex-end',
					}}>
					<Text bold style={{ fontSize: 24 }}>
						Deposited Today
					</Text>
					<Text bold style={{ marginLeft: 20, fontSize: 16, color: theme.colors.darkGrey2, marginTop: 10 }}>
						Total POCs: {transactions.length}
					</Text>
				</div>
				<div style={{ position: 'relative' }}>
					<UndlerlineTextField
						value={`${filters.from.format('DD/MM/YY')} - ${filters.to.format('DD/MM/YY')}`}
						label='Date Range'
						onClick={() => setDatePickerOpen(true)}
						style={{ width: 150, alignItems: 'center' }}
					/>
					<div style={{ position: 'absolute', right: 0, width: 700 }}>
						<DateRangePicker
							open={datePickerOpen}
							toggle={() => setDatePickerOpen(!datePickerOpen)}
							style={{ width: 200 }}
							initialDateRange={{
								startDate: filters.from,
								endDate: filters.to,
							}}
							maxDate={new Date()}
							onChange={(range) => {
								const newDateRange = {
									from: moment(range.startDate),
									to: moment(range.endDate),
								};

								if (Math.abs(newDateRange.from.diff(newDateRange.to, 'days')) > 15) {
									toast.error('Only 15 days filter allowed', {
										position: toast.POSITION.BOTTOM_RIGHT,
									});
									return;
								}
								set_filters(newDateRange);
								setDatePickerOpen(!datePickerOpen);
							}}
						/>
					</div>
				</div>
			</div>

			<div
				style={{
					background: theme.colors.white,
					boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
					height: 41,
					borderRadius: 40,
					width: '100%',
					padding: '12px 30px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: 10,
					marginTop: 20,
				}}>
				<Text bold style={{ flex: 2 }}>
					Collected at
				</Text>
				<Text bold style={{ flex: 3 }}>
					Deposited to
				</Text>
				<Text bold style={{ flex: 3 }}>
					User Type
				</Text>
				<Text bold style={{ flex: 2 }}>
					Amount
				</Text>
				<Text bold style={{ flex: 2 }}>
					Transaction ID
				</Text>
				<Text bold style={{ flex: 2 }}>
					Method
				</Text>
				<Text bold style={{ flex: 2 }}>
					Remarks
				</Text>
			</div>

			<div style={{ overflowY: 'auto', flex: 1, marginTop: 10, marginBottom: 10 }}>
				{_.map(transactions, (transaction, key) => {
					return (
						<div
							key={`transaction${key}`}
							style={{
								width: '100%',
								padding: '12px 30px',
								borderBottom: `1px solid ${theme.colors.lightGrey5}`,
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{moment(transaction.timestamp).format('DD-MM-YYYY hh:mm:ss')}
							</Text>
							<Text medium style={{ flex: 3, fontSize: 16 }}>
								{_.get(transaction, 'meta_info.to.name', '-')} ({_.get(transaction, 'meta_info.to.id', '-')})
							</Text>

							<Text medium style={{ flex: 3, fontSize: 16 }}>
								{_.get(transaction, 'meta_info.to.type', '-')}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								₹{transaction.amount}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{transaction.id}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{transaction.payment_method}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{transaction.remark ?? '-'}
							</Text>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default DepositHistory;
