import React, { useEffect, useMemo, useState } from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import Switch from 'modules/Switch';
import { useSelector } from 'react-redux';
import api_requests from 'resources/api_requests';
import ContainedButton from 'modules/ContainedButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PreviewDisabled from 'views/SettingsFlow/WhitelabellingSetting/components/PreviewDisabled';
import utils from 'resources/utils';
import Button from 'modules/Button';
import constant from 'resources/constant';

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 18, marginBottom: 10 },
	sub_heading: { color: theme.colors.darkGrey2 },
	info_container: {
		width: 340,
		backgroundColor: theme.colors.lightPurple10,
		borderRadius: 20,
		padding: 20,
		minHeight: '70vh',
		flex: 0.8,
		position: 'relative',
	},
	geo_fence: { marginTop: 30, fontSize: 16, marginBottom: 5 },
	tab_section: { marginTop: 20, marginBottom: 20, display: 'flex' },
	mapContainer: { height: '360px', width: '100%', marginTop: 30, paddingBottom: 10 },
	width_url: { width: 100, marginLeft: 10, marginTop: 5 },
	update: { borderRadius: 30, fontSize: 16, marginTop: 10, width: 100, position: 'absolute', bottom: 30, left: 10 },
	switch_flex: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	list_item: { border: '1px solid #492897', marginRight: 10, width: 90, borderRadius: 30, display: 'flex', justifyContent: 'center' },
	tips: {
		backgroundColor: theme.colors.lightPurple,
		marginRight: 0,
		width: 80,
		borderRadius: 5,
		display: 'flex',
		justifyContent: 'center',
		padding: '8px 8px',
	},
	save_button: { position: 'absolute', bottom: 20, right: 30, width: 110 },
	radio_subtitle: { fontSize: 12, marginLeft: 28, marginTop: -10, color: theme.colors.darkGrey2 },
}));

const AppClick = () => {
	const { app_configs, selected_business_id: business_id_information } = useSelector(({ app }) => app);
	const history = useHistory();
	const classes = useStyles();
	const [auto_to_sfp, set_auto_to_sfp] = useState(false);
	const [auto_reached_pickup, set_auto_reached_pickup] = useState(false);
	const [auto_reached_drop, set_auto_reached_drop] = useState(false);
	const [package_confirm, set_package_confirm] = useState(false);
	const [auto_to_ofd, set_auto_to_ofd] = useState(false);
	const [auto_close_admin_task, set_auto_close_admin_task] = useState(false);
	const [show_past_km, set_show_past_km] = useState(false);
	const [quick_scan_code, set_quick_scan_code] = useState(null);

	const rider_config = useMemo(() => {
		return { ..._.get(app_configs, `business_configs.${business_id_information}.rider_app_configs`, {}), is_editable: true };
	}, []);

	useEffect(() => {
		if (!_.isEmpty(rider_config)) {
			set_auto_to_sfp(rider_config.auto_to_sfp);
			set_auto_reached_pickup(rider_config.auto_reached_pickup);
			set_auto_reached_drop(rider_config.auto_reached_drop);
			set_package_confirm(rider_config.confirm_all_drop && rider_config.confirm_all_pickup);
			set_auto_to_ofd(rider_config.auto_to_ofd);
			set_auto_close_admin_task(rider_config.auto_close_admin_task);
			set_show_past_km(rider_config.show_past_km);
		}
	}, [rider_config]);

	useEffect(() => {
		get_quick_scan_code();
	}, []);

	const get_quick_scan_code = async () => {
		try {
			const res = await api_requests.get_business_settings();
			set_quick_scan_code(res.data.quick_scan_code);
		} catch (err) {}
	};

	const handle_save_configs = async () => {
		try {
			const res = await api_requests.update_common_configs({
				rider_app_configs: utils.remove_null_and_empty_from_obj({
					...rider_config,
					auto_to_sfp,
					auto_reached_pickup,
					auto_reached_drop,
					confirm_all_drop: package_confirm,
					confirm_all_pickup: package_confirm,
					auto_to_ofd,
					auto_close_admin_task,
					show_past_km,
					is_editable: null,
				}),
			});
			window.location.reload(true);
		} catch (err) {}
	};

	return (
		<div>
			<div className={classes.heading_container}>
				<Text bold className={classes.heading}>
					App Click-Automate
				</Text>
				<ListItem key={`tips`} button onClick={() => {}} className={classes.tips}>
					<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
						Tips & Info
					</Text>
				</ListItem>
			</div>

			<div style={{ display: 'flex', position: 'relative' }}>
				<div style={{ marginRight: 20, flex: '1', opacity: rider_config.is_editable ? 1 : 0.3 }}>
					<Text medium className={classes.sub_heading} style={{ fontSize: 14 }}>
						This feature allows your riders to reorganize their drop and pickup tasks themselves through the delivery executive application. Use
						this feature to give your riders the ability to choose the sequence of their tasks.
					</Text>

					<div className='d-flex' style={{ marginTop: 30 }}>
						<div>
							<Text medium>Auto Accept Task</Text>
							<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
								If rider has no tasks, and accepted tasks are of only 1 route, on task accept hit start for pickup of next task
							</Text>
						</div>
						<div>
							<Switch
								disabled={!rider_config.is_editable}
								checked={auto_to_sfp}
								onChange={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.APPCLICK_AUTO_ACCEPT);
									set_auto_to_sfp((prev) => !prev);
								}}
							/>
						</div>
					</div>

					<div className='d-flex' style={{ marginTop: 30 }}>
						<div>
							<Text medium>By-pass Reached Pickup </Text>
							<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
								If rider is within geofence of pickup location, speed is less than 10km/h, when status is out for pickup, then automatically
								trigger reached pickup location
							</Text>
						</div>
						<div>
							<Switch
								disabled={!rider_config.is_editable}
								checked={auto_reached_pickup}
								onChange={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.APPCLICK_REACH_PICKUP);
									set_auto_reached_pickup((prev) => !prev);
								}}
							/>
						</div>
					</div>

					<div className='d-flex' style={{ marginTop: 30 }}>
						<div>
							<Text medium>By-pass Reached Drop</Text>
							<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
								If rider is within geofence of drop location, when status is out for delivery, then automatically trigger reached delivery
								location
							</Text>
						</div>
						<div>
							<Switch
								disabled={!rider_config.is_editable}
								checked={auto_reached_drop}
								onChange={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.APPCLICK_REACH_DROP);
									set_auto_reached_drop((prev) => !prev);
								}}
							/>
						</div>
					</div>

					<div className='d-flex' style={{ marginTop: 30 }}>
						<div>
							<Text medium>By-pass Package Confirmation</Text>
							<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
								Confirm multiple packages with single click. This can only be used if quick scan code (or no barcode enabled).{' '}
								<Text
									semi
									component={'span'}
									style={{ color: theme.colors.primary, textDecoration: 'underline', cursor: 'pointer' }}
									onClick={() => history.push('/settings/order-settings?type=barcodes')}>
									Enable Now
								</Text>
							</Text>
						</div>
						<div>
							<Switch
								disabled={!quick_scan_code || !rider_config.is_editable}
								checked={package_confirm}
								onChange={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.APPCLICK_PACKAGE_INFO);
									set_package_confirm((prev) => !prev);
								}}
							/>
						</div>
					</div>

					<div className='d-flex' style={{ marginTop: 30 }}>
						<div>
							<Text medium>Auto Start New Task</Text>
							<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
								If next task is start for drop of the same route (or only 1 routes orders remain), then automatically trigger Out for Delivery
								status
							</Text>
						</div>
						<div>
							<Switch disabled={!rider_config.is_editable} checked={auto_to_ofd} onChange={() => set_auto_to_ofd((prev) => !prev)} />
						</div>
					</div>
					<div className='d-flex justify-content-between' style={{ marginTop: 30 }}>
						<div>
							<Text medium>Auto Close Admin Task</Text>
							<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
								If rider is within geofence of admin task location, admin task will be closed
							</Text>
						</div>
						<div>
							<Switch
								disabled={!rider_config.is_editable}
								checked={auto_close_admin_task}
								onChange={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.APPCLICK_ADMIN_TASK);
									set_auto_close_admin_task((prev) => !prev);
								}}
							/>
						</div>
					</div>
					<div className='d-flex justify-content-between' style={{ marginTop: 30 }}>
						<div>
							<Text medium>Show KM per Order</Text>
							<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
								Changes the view of past orders
							</Text>
						</div>
						<div>
							<Switch
								disabled={!rider_config.is_editable}
								checked={show_past_km}
								onChange={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.APPCLICK_KM_ORDER);
									set_show_past_km((prev) => !prev);
								}}
							/>
						</div>
					</div>
				</div>
				<div className={classes.info_container}>
					<Text bold style={{ fontSize: 16, color: theme.colors.lightPurple7 }}>
						How does task automation improve performance?
					</Text>

					<div className='d-flex justify-content-center' style={{ marginTop: 50 }}>
						<img src={ImageLinks.rider_app_click_tip} width={274} height={287} style={{ margin: 'auto' }}></img>
					</div>
					{!rider_config.is_editable && <PreviewDisabled heading='Unlock Automations' />}
				</div>
			</div>
			<Button
				disabled={!rider_config.is_editable}
				className={classes.save_button}
				onClick={() => handle_save_configs(true)}
				type='round'
				text='Save'
				size='large'
			/>
		</div>
	);
};

export default AppClick;
