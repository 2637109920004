import React from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import constant from 'resources/constant';
import utils from 'resources/utils';
import BootstrapTooltip from 'modules/BootstrapTooltip';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		height: 50, //56
		alignItems: 'center',
		paddingRight: 20,
		width: '100%',
	},
	wrapper_container: {
		borderRadius: 8,
		marginBottom: 15,
		padding: 0,
		flexDirection: 'column',
	},
	title: { marginLeft: 8, fontSize: 17 },
	sub_title: { marginLeft: 8, fontSize: 14, alignSelf: 'center' },
	border_left: { borderLeft: `6px solid ${theme.colors.lightGreen3}`, height: '100%', borderRadius: '13px 0px 0px 13px' },
	sub_border_left: { borderLeft: `4px solid ${theme.colors.lightGreen3}`, height: '100%', borderRadius: '6px 0px 0px 6px' },
	bundle_list_item: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white,
		borderRadius: 6,
		marginTop: 8,
		width: '90%',
		padding: 0,
		marginLeft: 15,
		border: `1px solid transparent`,
		opacity: '1 !important',
	},
	sub_container: { paddingBottom: 20, width: '100%' },
	list_item: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-between',
		paddingRight: 10,
		height: 36,
	},
	image: { display: 'flex', alignItems: 'center', paddingLeft: 13 },
	displayFlex: { display: 'flex', flex: 1, gap: '0.75rem' },
	child_business_container: {
		padding: '0.75rem',
		alignItems: 'flex-start',
		gap: '0.75rem',
		borderRadius: '0.375rem',
		background: `${theme.colors.lightPurple9}`,
	},
	enterprise_count: {
		borderRadius: '0.125rem',
		background: theme.colors.lightPink4,
		padding: '0.125rem 0.75rem',
		marginBottom: 'auto',
		marginTop: 'auto',
	},
}));

const SettingsCard = ({ item, business_list = [] }) => {
	const { name, url, locked, children, index } = item;
	const classes = useStyles();
	const history = useHistory();

	const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER);

	const detailed_settings = (_item) => {
		if (_item.path && !_item.is_absolute_path) history.push(_item.path);
		if (_item.is_absolute_path) {
			window.open(_item.path, '_blank');
		}
	};
	return (
		<ListItem
			button={(children || []).length === 0}
			onClick={() => detailed_settings(item)}
			className={classes.wrapper_container}
			key={index}
			disabled={locked}
			style={{
				backgroundColor: locked ? theme.colors.lightGrey10 : theme.colors.white,
				border: `1px solid ${locked ? theme.colors.lightGrey5 : theme.colors.lightPurple}`,
			}}>
			<div className={classes.container}>
				<div className={classes.displayFlex}>
					<div className={classes.image}>
						<img src={ImageLinks[url]} width={20} height={18} alt='' />
					</div>
					<Text bold className={classes.title} style={{ color: locked ? theme.colors.darkGrey2 : theme.colors.black }}>
						{name}
					</Text>
				</div>
				{locked ? (
					<img style={{ alignSelf: 'center' }} src={ImageLinks.lock} width={15} height={15} alt='' />
				) : (
					(children || []).length === 0 && <img style={{ alignSelf: 'center' }} src={ImageLinks.chevron_right_pink} width={10} height={10} alt='' />
				)}
			</div>
			{children && (
				<div className={classes.sub_container}>
					{_.map(children, (child_item, index) => {
						if (
							child_item.key == 'enterprise' &&
							(owner_type.id == constant.OWNER_TYPE.BUSINESS.id ||
								(owner_type.id == constant.OWNER_TYPE.AGGREGATOR.id && business_list.length == 0))
						) {
							return (
								<ListItem
									// button
									onClick={(e) => {
										utils.track_event_for_analytics(`setting_${child_item.name.toLowerCase().split(' ').join('_')}`);
										detailed_settings(child_item);
									}}
									className={`${classes.bundle_list_item}`}
									key={index}
									style={{ ...(child_item.locked && { background: theme.colors.lightGrey10 }) }}>
									<div className={classes.child_business_container}>
										<div>
											<Text bold style={{ color: theme.colors.primary, fontSize: 16 }}>
												Expand your horizon with Linked Businesses!
											</Text>
											<Text semi style={{ color: theme.colors.primary, fontSize: 12 }}>
												Boost collaboration, control, and overall performance with subsidiary businesses.
											</Text>
										</div>
										<div className='d-flex' style={{ marginTop: '10px', gap: '0.75rem', cursor: 'pointer' }}>
											<Text semi style={{ color: theme.colors.primary, fontSize: 12, textDecoration: 'underline' }}>
												Learn about Enterprises
											</Text>
											<img src={ImageLinks.arrow_right_purple} width={10} />
										</div>
									</div>
								</ListItem>
							);
						} else {
							return (
								<ListItem
									button
									className={`${classes.bundle_list_item}`}
									key={index}
									// disabled={child_item.locked}
									onClick={(e) => {
										utils.track_event_for_analytics(`setting_${child_item.name.toLowerCase().split(' ').join('_')}`);
										detailed_settings(child_item);
									}}
									style={{ ...(child_item.locked && { background: theme.colors.lightGrey10 }) }}>
									<BootstrapTooltip position='top' title={child_item.locked ? 'This feature is coming soon. Stay tuned for updates!' : ''}>
										<div className={classes.list_item} style={{ borderRadius: 5 }}>
											<div className={classes.displayFlex}>
												<Text
													bold
													className={classes.sub_title}
													style={{ color: child_item.locked ? theme.colors.darkGrey2 : theme.colors.black }}>
													{child_item.name}
												</Text>
												{child_item.key == 'enterprise' &&
													owner_type.id == constant.OWNER_TYPE.AGGREGATOR.id &&
													business_list?.length > 0 && (
														<div className={`d-flex justify-content-center align-items-center ${classes.enterprise_count}`}>
															<Text semi style={{ color: theme.colors.primary }}>
																{('0' + business_list.length).slice(-2)}
															</Text>
														</div>
													)}
											</div>
											{child_item.locked ? (
												<img style={{ alignSelf: 'center' }} src={ImageLinks.lock} width={15} height={15} alt='' />
											) : (
												<img style={{ alignSelf: 'center' }} src={ImageLinks.chevron_right_pink} width={10} height={10} alt='' />
											)}
										</div>
									</BootstrapTooltip>
								</ListItem>
							);
						}
					})}
				</div>
			)}
		</ListItem>
	);
};

export default SettingsCard;
