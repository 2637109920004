import React from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';

const useStyles = makeStyles({
	section_desc_text: { fontSize: 12, color: theme.colors.darkGrey2 },
});

const SectionHeader = ({ title, description }) => {
	const classes = useStyles();
	return (
		<>
			<Text bold style={{ fontSize: 18 }}>
				{title}
			</Text>
			<Text medium className={classes.section_desc_text}>
				{description}
			</Text>
		</>
	);
};

export default SectionHeader;
