import React from 'react';
import Text from 'modules/Text';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles({
	table_header: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
	},
	table_row_wrapper: { overflowY: 'auto', flex: 1, marginTop: 10, marginBottom: 10 },
	table_row_item: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		height: 64,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	table_header_item: { fontSize: 14, color: theme.colors.darkGrey2 },
});

const UserListing = ({ users }) => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.table_header}>
				<Text bold className={classes.table_header_item} style={{ flex: 3 }}>
					User Name (ID)
				</Text>
				<Text bold className={classes.table_header_item} style={{ flex: 1 }}>
					Added on
				</Text>
				<Text bold className={classes.table_header_item} style={{ flex: 1 }}>
					Role
				</Text>
				<Text bold className={classes.table_header_item} style={{ flex: 2 }}>
					Zone
				</Text>
				<Text bold className={classes.table_header_item} style={{ flex: 3 }}>
					Teams
				</Text>
			</div>

			<div className={classes.table_row_wrapper}>
				{_.map(users, (item, key) => {
					return (
						<div key={`rider${key}`} className={classes.table_row_item}>
							<div style={{ flex: 3 }}>
								<div className='d-flex'>
									<Text medium style={{ fontSize: 16 }}>{`${item.name} (${item.id})`}</Text>
								</div>
								<div style={{ display: 'flex', marginTop: 2 }}>
									<Text medium style={{ fontSize: 12, color: theme.colors.lightGrey6 }}>{`${item.email ? item.email + ' |' : ''} ${
										item.mobile
									}`}</Text>
								</div>
							</div>
							<Text medium style={{ flex: 1, fontSize: 16 }}>
								{item.added_on ? moment(item.added_on).format('DD MMM` YY') : '--'}
							</Text>
							<Text medium style={{ flex: 1, fontSize: 16 }}>
								--
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								--
							</Text>
							<div style={{ display: 'flex', flex: 3 }}>--</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default UserListing;
