import React, { useMemo, useRef, useState } from 'react';
import { ClickAwayListener, Popper, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import { setting_option } from '../helper';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: '8px 12px',
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		marginTop: 8,
		borderRadius: 6,
	},
	active_wrapper: {
		padding: '8px 12px',
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		marginTop: 8,
		border: `1px solid ${theme.colors.primary}`,
		borderRadius: 6,
	},

	status_pill: {
		padding: 6,
		borderRadius: 30,
		display: 'flex',
		justifyContent: 'space-between',
		gap: 5,
		alignItems: 'center',
		boxShadow: `0px 4px 4px 0px rgba(202, 202, 202, 0.25)`,
		cursor: 'pointer',
	},
	menu_item: {
		backgroundColor: theme.colors.white,
		display: 'flex',
		paddingLeft: 12,
		paddingRight: 12,
		alignItems: 'center',
		boxShadow: `0px 4px 4px 0px rgba(202, 202, 202, 0.25)`,
		cursor: 'pointer',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		height: 33,
	},
	rank_circle_wrap: {
		width: 16,
		height: 16,
		borderRadius: 8,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

function NetworkBox({ handle_update_allocation_setting, allocation_settings, image, title, network, selected_card_id, set_selected_card_id }) {
	const classes = useStyles();
	const [show_menu, set_show_menu] = useState(false);

	const menu_ref = useRef(null);

	const update_network_priority = (priority) => {
		let _allocations_settings = _.cloneDeep(allocation_settings);
		let _config = _.cloneDeep(allocation_settings.config);
		while (_config.length < 6) {
			_config.push([]);
		}

		for (let i = 0; i < _config.length; i++) {
			for (let j = 0; j < _config[i].length; j++) {
				if (_config[i][j].network_id == network.id) {
					_config[i].splice(j, 1);
				}
			}
		}

		if (priority > 0) _config[priority - 1].push({ network_id: parseInt(network.id) });

		_allocations_settings.config = _config;
		handle_update_allocation_setting(_allocations_settings);
		set_show_menu(false);
		return;
	};

	const value = useMemo(() => {
		const config = allocation_settings.config;
		for (let i = 0; i < config.length; i++) {
			for (let j = 0; j < config[i].length; j++) {
				if (config[i][j].network_id == network.id) {
					return i;
				}
			}
		}
		return -1;
	}, [allocation_settings]);

	return (
		<div className={network.id == selected_card_id ? classes.active_wrapper : classes.wrapper} onClick={() => set_selected_card_id(network.id)}>
			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center' style={{ gap: 10 }}>
					<div>{image}</div>
					<Text semi>{title}</Text>
				</div>
				<div
					className={classes.status_pill}
					ref={menu_ref}
					onClick={(e) => {
						e.stopPropagation();
						set_show_menu(true);
					}}>
					{value === -1 ? (
						<img src={ImageLinks.red_cross_filled_circle} height={12} width={12} />
					) : (
						<div className={classes.rank_circle_wrap} style={{ width: 12, height: 12, borderRadius: 6 }}>
							<Text bold style={{ fontSize: 9, color: theme.colors.primary }}>
								{value + 1}
							</Text>
						</div>
					)}
					<img src={ImageLinks.chevron_right_black} height={16} width={16} style={{ transform: `rotate(${show_menu ? 270 : 90}deg)` }} />
				</div>
				<Popper
					open={show_menu}
					anchorEl={menu_ref.current}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					style={{ zIndex: 1500 }}
					onClose={() => set_show_menu(false)}>
					<ClickAwayListener onClickAway={() => set_show_menu(false)}>
						<div>
							{_.map(setting_option, (setting) => {
								return (
									<div
										className={classes.menu_item}
										onClick={(e) => {
											update_network_priority(setting.value);
											e.stopPropagation();
										}}>
										{setting.value > 0 ? (
											<>
												<Text semi style={{ fontSize: 12, marginRight: 8 }}>
													Rank
												</Text>
												<div className={classes.rank_circle_wrap}>
													<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
														{setting.title}
													</Text>
												</div>
											</>
										) : (
											<>
												<img src={ImageLinks.red_cross_filled_circle} height={12} width={12} />
												<Text semi style={{ fontSize: 12, marginLeft: 8 }}>
													{setting.title}
												</Text>
											</>
										)}
									</div>
								);
							})}
						</div>
					</ClickAwayListener>
				</Popper>
			</div>
		</div>
	);
}

export default NetworkBox;
