import Text from 'modules/Text';
import React from 'react';

import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import OutlinedButton from 'modules/OutlinedButton';
import Upload from 'modules/Upload';
import utils from 'resources/utils';
import _ from 'lodash';

const useStyles = makeStyles({
	upload_container: {
		marginTop: 13,
		width: 200,
		minHeight: 118,
		border: `2px dashed ${theme.colors.primary}`,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 60),
		borderRadius: 13.36,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '12px 0px',
	},
	drag_csv_text: { fontSize: 14, color: theme.colors.lightPurple2, marginTop: 9.4, marginBottom: 8 },
	upload_success_arc: {
		backgroundColor: theme.colors.lightGreen7,
		height: 40,
		width: '100%',
		borderRadius: '50% / 100%',
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	upload_success_text: { fontSize: 10, marginTop: 6.5, marginBottom: 5, textAlign: 'center' },
	file_name_wrap: {
		minHeight: 29,
		bordeRadius: 2,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		alignItems: 'center',
		padding: 6,
		marginBottom: 10,
		cursor: 'pointer',
	},
});

const UploadBox = ({ file_name, handle_csv, remove_uploaded_file }) => {
	const classes = useStyles();
	return (
		<div className={classes.upload_container} style={_.isEmpty(file_name) ? {} : { backgroundColor: theme.colors.white, padding: 0, overflow: 'hidden' }}>
			{_.isEmpty(file_name) ? (
				<>
					<img src={ImageLinks.upload_light_purple} width={20} height={20} alt='upload_coords' />
					<Text bold className={classes.drag_csv_text}>
						Drag .csv file or
					</Text>
					<Upload accepted_extensions='.csv' add_image={handle_csv}>
						<OutlinedButton style={{ border: `1px solid ${theme.colors.primary}`, backgroundColor: theme.colors.white }}>
							<Text bold style={{ fontSize: 14, color: theme.colors.primary }}>
								Upload Maps CSV
							</Text>
						</OutlinedButton>
					</Upload>
				</>
			) : (
				<>
					<div className={classes.upload_success_arc}>
						<img src={ImageLinks.tickCircleSolid} width={22} height={22} />
					</div>
					<div style={{ padding: '0px 20px' }}>
						<Text bold className={classes.upload_success_text}>
							Coordinates uploaded successfully!
						</Text>
						<div onClick={remove_uploaded_file} className={classes.file_name_wrap}>
							<Text medium style={{ fontSize: 14, color: theme.colors.primary }}>
								{file_name}
							</Text>
							<img src={ImageLinks.delete} width={12} height={12} />
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default UploadBox;
