import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import StoreBox from './StoreBox';
import EmptyState from 'modules/EmptyState';
import Helpcenter from 'modules/HelpCenter';

const useStyles = makeStyles((theme) => ({
	main_text: { fontSize: 24, textAlign: 'center', width: '40%', margin: 'auto' },
	flex_box: { display: 'flex', flexWrap: 'wrap', gap: '5%', marginTop: 20 },
	empty_content: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

function SyncOrders() {
	const history = useHistory();
	const classes = useStyles();
	const stores = [
		ImageLinks.shopify_logo,
		ImageLinks.woocommerce_logo,
		ImageLinks.dotpe_logo,
		ImageLinks.unicommerce_logo,
		ImageLinks.easyecom_logo,
		ImageLinks.cred_logo,
		ImageLinks.magento_logo,
		ImageLinks.instamojo_logo,
		ImageLinks.wix_logo,
	];
	return (
		<div style={{ margin: 'auto' }}>
			<div className={classes.empty_content}>
				<EmptyState
					button_text='Generate Token'
					left_icon={ImageLinks.addWhite}
					empty_img={ImageLinks.empty_state_inte_management}
					onClick={() => history.push('/integration-management/generate-token')}
				/>
				{/* <Helpcenter text={'How can I customize tokens?'} style={{ marginTop: 40 }} /> */}
			</div>
		</div>
	);
}

export default SyncOrders;
