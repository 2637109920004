import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from 'resources/theme';
import Text from 'modules/Text';
import { Divider } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import { nFormatter } from '../helper';
import EmptyState from '../EmptyState';

const useStyles = makeStyles({
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
	analytics_container: {
		padding: 12,
		borderRadius: 12,
		background: theme.colors.white,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
	},
	dot_content: {
		width: 8,
		height: 8,
		borderRadius: '50%',
	},
});

const ExpectedDeliveryPerformance = ({ delivery_performance }) => {
	const classes = useStyles();

	const labels = _.map(delivery_performance, (item_value) => {
		return moment(item_value._id).format('DD-MMM');
	});
	const data = {
		labels: labels,
		datasets: [
			{
				label: 'After Expected',
				data: _.map(delivery_performance, (item_value) => {
					return item_value.late;
				}),
				backgroundColor: theme.colors.lightPurple5,
				hoverBackgroundColor: theme.colors.lightPurple12,
			},
			{
				label: 'Before Expected',
				data: _.map(delivery_performance, (item_value) => {
					return item_value.early;
				}),
				backgroundColor: theme.colors.lightPurple6,
				borderWidth: 2,
				borderRadius: { topLeft: 4, topRight: 4, bottomLeft: 0, bottomRight: 0 },
				borderSkipped: false,
				borderColor: theme.colors.lightPurple6,
			},
		],
	};

	const config = {
		type: 'bar',
		data: data,
		options: {
			plugins: {
				legend: false,
				datalabels: {
					display: false,
				},
				tooltip: {
					enabled: true,
				},
			},
			responsive: true,
			scales: {
				x: {
					grid: {
						display: false,
						color: 'red',
					},
					ticks: {
						color: theme.colors.darkGrey2,
						font: {
							size: 10,
							family: 'HK Grotesk Medium',
							weight: 'bold',
						},
					},
					stacked: true,
				},
				y: {
					beginAtZero: true,
					grid: {
						color: theme.colors.lightGrey12,
					},
					ticks: {
						font: {
							color: theme.colors.darkGrey2,
							size: 8,
							family: 'HK Grotesk Medium',
							weight: 'bold',
						},
						callback: nFormatter,
					},
					stacked: true,
				},
			},
		},
	};
	return (
		<div className={classes.analytics_container}>
			<div className='align-items-center justify-content-between'>
				<Text bold style={{ fontSize: 14 }}>
					Expected Delivery Performance
				</Text>
				<div className='d-flex'>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.lightPurple6 }} />
						<Text style={{ fontSize: 12 }}>Before Expected</Text>
					</div>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.lightPurple5 }} />
						<Text style={{ fontSize: 12 }}>After Expected</Text>
					</div>
				</div>
			</div>
			<Divider className={classes.divider} />
			<div style={{ width: '100%', height: '100%' }}>{!_.isEmpty(delivery_performance) ? <Bar {...config} /> : <EmptyState />}</div>
		</div>
	);
};

export default ExpectedDeliveryPerformance;
