import React, { useContext, useMemo } from 'react';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import OutlinedTextField from 'modules/OutlinedTextField';
import theme from 'resources/theme';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ten_digit_regex, valid_email_regex } from 'resources/regex_patterns';
import CreateNetworkContext from 'views/NetworksFlow/context';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiInputLabel-outlined.Mui-error': {
			color: theme.colors.red,
		},
		'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.red,
		},
	},
}));

const OfficerForm = ({ item, handle_item_change, disabled = false }) => {
	const { invite_network_id } = useContext(CreateNetworkContext);
	const [mobile_error, set_mobile_error] = useState(false);
	const [email_error, set_email_error] = useState(false);
	const classes = useStyles();

	const on_mobile_blur = (val) => {
		let regex = new RegExp(ten_digit_regex);
		if (_.isEmpty(val)) {
			set_mobile_error(false);
			return;
		}
		if (!regex.test(val)) set_mobile_error(true);
		else set_mobile_error(false);
	};

	const on_email_blur = (val) => {
		let regex = new RegExp(valid_email_regex);
		if (_.isEmpty(val)) {
			set_email_error(false);
			return;
		}
		if (!regex.test(val)) set_email_error(true);
		else set_email_error(false);
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		const { name, mobile, email } = item;
		const email_valid = new RegExp(valid_email_regex).test(email);
		const mobile_valid = new RegExp(ten_digit_regex).test(mobile);
		if (!_.isEmpty(name) && !_.isEmpty(mobile) && !_.isEmpty(email) && mobile_valid && email_valid) value = false;
		return value;
	}, [item]);

	return (
		<div className='align-items-center justify-content-between'>
			<OutlinedTextField
				disabled={disabled}
				value={item.name}
				name='name'
				textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
				onChange={handle_item_change}
				label='Officer Name*'
				placeholder={`Type name..`}
				style={{ flex: 1, marginRight: 20 }}
			/>
			<OutlinedTextField
				disabled={disabled}
				value={item.mobile}
				name='mobile'
				error={mobile_error}
				textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
				onChange={handle_item_change}
				onBlur={(e) => {
					on_mobile_blur(e.target.value);
				}}
				label='Officer Contact*'
				placeholder={`Type contact..`}
				className={classes.root}
				style={{ flex: 1, marginRight: 20 }}
			/>
			<OutlinedTextField
				disabled={disabled}
				value={item.email}
				name='email'
				textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
				onChange={handle_item_change}
				label='Officer Email*'
				placeholder={`Type Email..`}
				style={{ flex: 1, marginRight: 20 }}
				error={email_error}
				onBlur={(e) => {
					on_email_blur(e.target.value);
				}}
				className={classes.root}
			/>

			<img src={ImageLinks.tickCircleSolid} width={16} height={16} style={{ opacity: btn_disabled ? 0.3 : 1 }} />
		</div>
	);
};

export default OfficerForm;
