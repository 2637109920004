import { Divider, Popover, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import ColorPicker from 'modules/ColorPicker';
import SidebarPreview from './SidebarPreview';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import ConfirmClose from 'modules/modals/ConfirmClose';
import utils from 'resources/utils';
import Button from 'modules/Button';
import ImageCropModal from 'modules/ImageCropModal';
import { UploadS3Document } from 'modules/UploadS3Document';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	image_upload_wrap: {
		border: `1px dashed ${theme.colors.lightGrey2}`,
		height: 120,
		width: 172,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		gap: 6,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 8,
		cursor: 'pointer',
	},
	bottom_banner_container: {
		display: 'flex',
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 5,
		marginTop: 20,
	},
	save_button: { position: 'absolute', bottom: 20, right: 30, width: 110 },
	image_wrap: {
		flex: 1,
		cursor: 'pointer',
		position: 'relative',
		minHeight: 140,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 8,
	},
}));

function BrandColorLogo({ app_configs, query_object }) {
	const { selected_business_id: business_id_information } = useSelector(({ app }) => app);

	const classes = useStyles();

	const [image, set_image] = useState(null);
	const [show_crop_modal, set_show_crop_modal] = useState(false);
	const [primary_color, set_primary_color] = useState(theme.colors.primary);
	const [secondary_color, set_secondary_color] = useState(theme.colors.lightPurple);
	const [text_color, set_text_color] = useState(theme.colors.white);
	const [hover_color, set_hover_color] = useState(theme.colors.lightPurple + '80');
	const [show_confirm_close, set_show_confirm_close] = useState(false);
	const [error, set_error] = useState(false);
	const upload_img_ref = useRef(null);

	const white_label_configs = _.get(app_configs, `whitelabeling`, {});

	useEffect(() => {
		set_primary_color(_.get(app_configs, `whitelabeling.primary_color`, theme.colors.primary));
		set_secondary_color(_.get(app_configs, `whitelabeling.secondary_color`, theme.colors.lightPurple));
		set_hover_color(_.get(app_configs, `whitelabeling.hover_color`, theme.colors.lightPurple + '80'));
		set_text_color(_.get(app_configs, `whitelabeling.text_color`, theme.colors.white));
		set_image(_.get(app_configs, `whitelabeling.brand_logo`, null));
	}, [app_configs]);

	const handle_img_upload = async (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.src = e.target.result;
				img.onload = async () => {
					set_image(reader.result.toString());
					set_show_crop_modal(true);
				};
			};
			reader.readAsDataURL(file);
		}
	};

	const on_save_image = (file) => {
		if (file) {
			let extension = '';
			extension = _.split(file.name, '.');
			extension = extension[extension.length - 1];
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.src = e.target.result;
				img.onload = async () => {
					try {
						let path = `/bussines/${business_id_information}/whitelabelling/branding/logo_image_${Date.now()}.${extension}`;
						const res = await UploadS3Document(path, file);
						if (res.success) {
							set_image(res.data.url);
							set_error(false);
							set_show_crop_modal(false);
						}
					} catch (err) {}
				};
			};
			reader.readAsDataURL(file);
		}
	};

	const handle_remove_image = (e) => {
		set_image(null);
		e.stopPropagation();
	};

	const handle_update_config_event = () => {
		if (query_object.vendor_id) {
			handle_save_configs();
		} else {
			set_show_confirm_close(true);
		}
	};

	const handle_save_configs = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.WHITELABEL_SAVE_BRAND_COLOR);
		const _tracking = _.get(app_configs, `whitelabeling.tracking`, {});
		try {
			const res = await api_requests.update_common_configs(
				{
					whitelabeling: {
						tracking: utils.remove_null_and_empty_from_obj({
							..._tracking,
							marketing_content: _.filter(_.get(_tracking, `marketing_content`, {}), (item) => {
								return item.image != '';
							}),
							is_editable: null,
						}),
						...(image && { brand_logo: image }),
						primary_color,
						hover_color,
						secondary_color,
						text_color,
					},
				},
				{ ...(query_object?.vendor_id && { vendor_id: query_object?.vendor_id }) },
			);
			window.location.reload(true);
		} catch (err) {}
	};

	const is_data_updated = useMemo(() => {
		if (primary_color != _.get(app_configs, `whitelabeling.primary_color`, theme.colors.primary)) return true;
		if (primary_color != _.get(app_configs, `whitelabeling.primary_color`, theme.colors.primary)) return true;
		if (secondary_color != _.get(app_configs, `whitelabeling.secondary_color`, theme.colors.lightPurple)) return true;
		if (hover_color != _.get(app_configs, `whitelabeling.hover_color`, theme.colors.lightPurple + '80')) return true;
		if (text_color != _.get(app_configs, `whitelabeling.text_color`, theme.colors.white)) return true;
		if (image != _.get(app_configs, `whitelabeling.brand_logo`, null)) return true;
	}, [primary_color, secondary_color, hover_color, text_color, image]);

	const is_whitelabelling = useMemo(() => {
		if (white_label_configs.is_editable || query_object?.vendor_id) {
			return true;
		} else {
			return false;
		}
	}, []);
	return (
		<div className='d-flex' style={{ gap: 20 }}>
			<div style={{ flex: 1.2, opacity: is_whitelabelling ? 1 : 0.4 }}>
				<Text bold style={{ color: theme.colors.primary, fontSize: 16 }}>
					Brand Logo & Colour
				</Text>
				<Text medium style={{ color: theme.colors.darkGrey2 }}>
					Customise your dashboard with your brand colours.
				</Text>
				<Divider style={{ margin: '10px 0' }} />
				<div className='d-flex'>
					<div style={{ flex: 1.5 }}>
						<Text semi>Upload Business Logo </Text>
						<Text medium style={{ color: theme.colors.darkGrey2, marginRight: 30 }}>
							For best brand representation please upload image (png, jpg, svg) of height 30px and width upto 150px
						</Text>
					</div>
					{!image && (
						<div className={classes.image_upload_wrap} onClick={() => upload_img_ref.current.click()}>
							<img src={error ? ImageLinks.exclamation_error : ImageLinks.upload_light_purple} />
							<Text style={{ textAlign: 'center', fontSize: 12, lineHeight: '13px', padding: '0px 15px', marginBottom: 5 }}>
								{error ? 'Please upload correct format & dimensions.' : 'Upload Image'}
							</Text>
							<Button size='extra_small' text={error ? 'Upload again' : 'Select File'}></Button>
						</div>
					)}
					{image && (
						<div className={classes.image_wrap} onClick={() => upload_img_ref.current.click()}>
							<img src={image} style={{ width: '100%', height: 'auto' }} />
							{is_whitelabelling && (
								<div style={{ position: 'absolute', top: 10, right: 10 }} onClick={handle_remove_image}>
									<img src={ImageLinks.red_cross_filled_circle} height={16} width={16} />
								</div>
							)}
						</div>
					)}
					<input
						style={{ display: 'none' }}
						type='file'
						disabled={!is_whitelabelling}
						ref={upload_img_ref}
						onClick={(event) => {
							event.target.value = null;
						}}
						onChange={(e) => {
							handle_img_upload(e);
						}}
					/>
				</div>
				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1.5 }}>
						<Text semi>Primary Colour</Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							Colour for the selected components
						</Text>
					</div>
					<div style={{ flex: 1 }}>
						<ColorPicker disabled={!is_whitelabelling} color={primary_color} set_color={set_primary_color} />
					</div>
				</div>

				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1.5 }}>
						<Text semi>Secondary Colour</Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							This colour will apply on selection
						</Text>
					</div>
					<div style={{ flex: 1 }}>
						<ColorPicker disabled={!is_whitelabelling} color={secondary_color} set_color={set_secondary_color} />
					</div>
				</div>

				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1.5 }}>
						<Text semi>Hover Colour</Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							This colour will apply on sidebar background
						</Text>
					</div>
					<div style={{ flex: 1 }}>
						<ColorPicker disabled={!is_whitelabelling} color={hover_color} set_color={set_hover_color} />
					</div>
				</div>

				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1.5 }}>
						<Text semi>Text Colour</Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							This colour will apply on sidebar text
						</Text>
					</div>
					<div style={{ flex: 1 }}>
						<ColorPicker disabled={!is_whitelabelling} color={text_color} set_color={set_text_color} />
					</div>
				</div>
			</div>
			<div style={{ flex: 1 }}>
				<SidebarPreview
					primary_color={primary_color}
					secondary_color={secondary_color}
					text_color={text_color}
					hover_color={hover_color}
					image={image}
					is_editable={is_whitelabelling}
				/>
			</div>
			{show_crop_modal && (
				<ImageCropModal
					img_src={image}
					close={() => {
						set_show_crop_modal(false);
						set_image('');
					}}
					aspect={5}
					on_replace={() => {
						set_show_crop_modal(false);
						upload_img_ref.current.click();
					}}
					on_save={on_save_image}
				/>
			)}
			{show_confirm_close && (
				<ConfirmClose
					headerTitle='Save Brand Logo & Colour'
					modal_icon={ImageLinks.exclamation_purple_ripple}
					title={
						<>
							<Text medium>Brand logo and colors will be updated across product where applicable</Text>
							<br />
							<Text medium style={{ color: theme.colors.darkGrey2 }}>
								Disclaimer: Changes will be applied to all users in this enterprise
							</Text>
						</>
					}
					close={() => set_show_confirm_close(false)}
					show_negative_btn={true}
					negative_btn_text='Cancel'
					handle_negative_action={() => set_show_confirm_close(false)}
					on_confirm={handle_save_configs}
				/>
			)}
			<Button disabled={!is_data_updated} className={classes.save_button} onClick={handle_update_config_event} type='round' text='Save' size='large' />
		</div>
	);
}

export default BrandColorLogo;
