import _ from 'lodash';
import React, { useContext, useRef } from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import { Link } from 'react-router-dom';
import Routes from 'resources/Routes';
import CreateOrderContext from 'views/CreateOrderFlow/CreateOrder/context';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
	modalContainer: { display: 'flex', outline: 'none' },
	componentContainer: {
		padding: 24,
		paddingBottom: 0,
		background: theme.colors.white,
		width: 600,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	headerTitle: { fontSize: 18, width: '100%', paddingTop: 5 },
	textContainer: { fontSize: 14, marginTop: 8 },
	containedButtonsContainer: { marginTop: 32, marginBottom: 24, display: 'flex', alignSelf: 'flex-end' },
	button: { borderRadius: 30, height: 46, minWidth: `100px !important` },
}));

const Successful = ({ title = 'Are you sure you want to close', style = {}, titleStyles = {}, headerTitle = '', type, is_success_change = false }) => {
	const { close_success_modal, on_order_success } = useContext(CreateOrderContext);
	const [timer, set_timer] = useState(3);
	const timer_ref = useRef(null);

	useEffect(() => {
		timer_ref.current = setInterval(() => {
			set_timer((prev) => prev - 1);
		}, 1000);

		return () => {
			clearInterval(timer_ref.current);
		};
	}, []);

	useEffect(() => {
		if (timer === 0) {
			clearInterval(timer_ref.current);
			on_order_success();
		}
	}, [timer]);

	const handle_close = (event, reason) => {
		if (reason !== 'backdropClick') {
			clearInterval(timer_ref.current);
			close_success_modal();
		}
	};

	const classes = useStyles();

	return (
		<Modal open={true} onClose={handle_close} className={classes.modalContainer}>
			<div className={classes.componentContainer} style={style}>
				<div className='d-flex'>
					<img src={is_success_change ? ImageLinks.green_tick_ripple : ImageLinks.excalamation_yellow_ripple} style={{ width: 48, height: 48 }} />
					<div style={{ marginLeft: 16, width: '100%' }}>
						<div style={{ display: 'flex' }}>
							{headerTitle && (
								<Text semi className={classes.headerTitle}>
									{headerTitle}
								</Text>
							)}
						</div>
						<Text medium className={classes.textContainer} style={titleStyles}>
							{title}
						</Text>
					</div>
				</div>

				<div className={classes.containedButtonsContainer}>
					<Text medium style={{ fontSize: 16, paddingRight: 4 }}>
						Redirecting to{' '}
					</Text>
					<Link onClick={on_order_success}>
						<Text bold style={{ fontSize: 16, textDecoration: 'underline', color: theme.colors.primary }}>
							All Orders
						</Text>
					</Link>
					<Text medium style={{ fontSize: 16, color: theme.colors.primary, paddingLeft: 4 }}>
						{timer}...
					</Text>
				</div>
			</div>
		</Modal>
	);
};

export default Successful;
