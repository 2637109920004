import React, { useEffect, useState } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import moment from 'moment';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import utils from 'resources/utils';
import SelectWithInput from 'modules/SelectWithInput';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.colors.white,
		width: 500,
		height: '100vh',
		position: 'absolute',
		right: 0,
		top: 0,
		zIndex: 1200,
		borderTopLeftRadius: 50,
		padding: 30,
	},
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	search_wrap: { display: 'flex', justifyContent: 'space-between', flex: 2 },
	search_input_wrap: {
		border: `1px solid ${theme.colors.lightPurple3}`,
		width: '100%',
		padding: '10px 20px',
		height: 40,
		borderRadius: 20,
		display: 'flex',
	},
	search_input: {
		outline: 'none',
		border: 'none',
		marginLeft: 10,
		fontSize: 16,
		width: '100%',
	},
	log_list_wrap: {
		maxHeight: '80vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	log_item_wrap: {
		display: 'flex',
		padding: '12px 0',
		gap: 8,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	date_filter_wrap: {
		borderRadius: 30,
		height: 40,
		border: `1px solid ${theme.colors.lightPurple}`,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: 14,
		paddingRight: 14,
		gap: 5,
	},
}));

function TraceAlertLogs({
	close,
	title,
	logs,
	set_start_date,
	start_date,
	search_function,
	search_key,
	set_search_key,
	search_placeholder,
	query = {},
	show_rider_list = false,
	set_selected_rider_id,
}) {
	const classes = useStyles();
	const [search_rider_text, set_search_rider_text] = useState('');
	const [search_riders, set_search_riders] = useState([]);

	useEffect(() => {
		if (_.isEmpty(search_rider_text)) {
			set_search_riders([]);
			return;
		}
		api_requests
			.search_rider_without_zone({
				search: search_rider_text,
				is_active: true,
			})
			.then((res) => {
				set_search_riders(res.data);
			});
	}, [search_rider_text]);

	const handle_get_rider_details = (rider_id) => {
		set_selected_rider_id(rider_id);
	};

	const handle_export = () => {
		api_requests
			.download_trace_alert_logs({
				from: moment(start_date).startOf('day').toDate(),
				to: moment(start_date).endOf('day').toDate(),
				...query,
			})
			.then((res) => {
				utils.downloadBlob(res, 'alerts_logs.csv', 'text/csv');
			});
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div className='d-flex justify-content-between'>
					<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
						{title}
					</Text>
					<div className='d-flex align-items-center' style={{ gap: 5 }}>
						<img className='cursor-pointer' src={ImageLinks.download_filled_purple} height={20} width={20} onClick={handle_export} />
						<img src={ImageLinks.crossBlack} height={16} width={16} onClick={close} />
					</div>
				</div>
				<Divider style={{ margin: '8px 0 8px 0' }} />
				<div className='d-flex' style={{ gap: 10 }}>
					{!show_rider_list && (
						<div className={classes.search_wrap}>
							<div className={classes.search_input_wrap}>
								<img src={ImageLinks.search_light_pink} alt='' />
								<input
									placeholder={search_placeholder}
									className={classes.search_input}
									value={search_key}
									onChange={(e) => {
										set_search_key(e.target.value);
									}}
								/>
							</div>
						</div>
					)}
					{show_rider_list && (
						<div className={classes.search_wrap}>
							<div className={classes.search_input_wrap}>
								<img src={ImageLinks.search_light_pink} alt='' />
								<SelectWithInput
									value={search_placeholder}
									inputValue={search_rider_text}
									onChange={(text) => set_search_rider_text(text)}
									options={_.map(search_riders, (rider) => {
										return {
											title: `${rider.rider_name} (${rider.rider_id})`,
											value: rider,
										};
									})}
									handleChange={(val) => {
										handle_get_rider_details(val.rider_id);
									}}
									style={{ border: 'unset' }}
									mainContainerStyles={{ height: 20, width: '100%' }}
									containerStyles={{ border: 'unset', width: '168px', height: 20, padding: 0, paddingLeft: 10 }}
									show_arrow={false}
								/>
							</div>
						</div>
					)}
					<div style={{ flex: 1 }}>
						<DatePicker
							dateFormat='dd-MMM-yyyy'
							selected={moment(start_date).toDate()}
							onChange={(date) => set_start_date(date)}
							maxDate={new Date()}
							customInput={
								<div className={classes.date_filter_wrap}>
									<Text semi className={classes.date_text}>
										{`${moment(start_date).format('DD-MMM-YY')}`}
									</Text>
									<img src={ImageLinks.calendar_solid_circle} alt='calendar_filter' />
								</div>
							}
						/>
					</div>
				</div>
				<div className={classes.log_list_wrap}>
					{_.map(_.filter(logs, search_function), (log, index) => {
						return (
							<div key={`logs_${index}`} className={classes.log_item_wrap}>
								<img src={ImageLinks.rider_marker} height={16} width={16} />
								<div>
									<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
										{moment(log.timestamp).format('DD MMM `YY')} {'at'} {moment(log.timestamp).format('h:mm A')}
									</Text>
									<Text medium style={{ fontSize: 12 }}>
										{log.description ? log.description : '-'}
									</Text>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default TraceAlertLogs;
