import Text from 'modules/Text';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import useStyles from '../styles';
import clsx from 'clsx';
import theme from 'resources/theme';

const RiderTags = ({ rider_tags, set_rider_tags, show_success }) => {
	const [show_add_tags, set_show_add_tags] = useState(false);
	const [in_edit_mode, set_in_edit_mode] = useState(false);
	const [new_tag_values, set_new_tag_values] = useState({
		key: '',
		value: '',
		edit: false,
	});
	const classes = useStyles();

	useEffect(() => {
		if (show_success) set_in_edit_mode(false);
	}, [show_success]);

	const on_tag_cta_press = () => {
		if (in_edit_mode && save_disabled) return;
		if (in_edit_mode) {
			const _rider_tags = _.map(rider_tags, (item) => ({ ...item, edit: false }));
			set_rider_tags(_rider_tags);
			set_in_edit_mode(false);
			return;
		}
		if (!show_add_tags) {
			set_show_add_tags(true);
			return;
		}
		if (_.isEmpty(new_tag_values.key) || _.isEmpty(new_tag_values.value)) return;
		const _rider_tags = _.cloneDeep(rider_tags);
		_rider_tags.push(_.cloneDeep(new_tag_values));
		set_rider_tags(_rider_tags);
		set_new_tag_values({
			...new_tag_values,
			key: '',
			value: '',
		});
		set_show_add_tags(false);
	};

	const handle_tags_edit = (e, index) => {
		const _rider_tags = _.cloneDeep(rider_tags);
		_rider_tags[index][e.target.name] = e.target.value;
		set_rider_tags(_rider_tags);
	};

	const on_tag_edit_icon_press = (index) => {
		if (show_add_tags) return;
		const _rider_tags = _.cloneDeep(rider_tags);
		_rider_tags[index].edit = true;
		set_in_edit_mode(true);
		set_rider_tags(_rider_tags);
	};

	const save_disabled = useMemo(() => {
		let value = false;
		for (let i = 0; i < rider_tags.length; i++) {
			const tag = rider_tags[i];
			if (_.isEmpty(tag.key) || _.isEmpty(tag.value)) {
				value = true;
				break;
			}
		}
		return value;
	}, [rider_tags]);

	let is_diabled = true;
	if (in_edit_mode) is_diabled = save_disabled;
	else is_diabled = _.isEmpty(new_tag_values.key) || _.isEmpty(new_tag_values.value);

	return (
		<div style={{ marginTop: 40 }}>
			<div className='align-items-center'>
				<img src={ImageLinks.marker_purple} width={16} height={16} />
				<Text semi style={{ fontSize: 16, marginLeft: 8 }}>
					Rider Tags
				</Text>
				<div className={classes.section_header_line}></div>
			</div>
			<div className='d-flex' style={{ marginTop: 27 }}>
				<div
					className={clsx(classes.tags_wrap, 'box-shadow')}
					style={{
						...(rider_tags.length === 0 && !show_add_tags && { height: 82 }),
					}}>
					{rider_tags.length === 0 && !show_add_tags ? (
						<Text medium className={classes.tags_empty_text}>
							Categorize your fleet of riders for better organisation
						</Text>
					) : (
						<>
							{_.map(rider_tags, (item, index) => (
								<div
									key={`rider_tags_item_${index}`}
									className={classes.input_wrapper}
									style={{ marginTop: 0, marginTop: index === 0 ? 0 : 6 }}>
									<UndlerlineTextField
										value={item.key}
										placeholder='Title (key)'
										onChange={(e) => handle_tags_edit(e, index)}
										name='key'
										textStyle={{ fontSize: 14 }}
										InputProps={{
											readOnly: !item.edit,
											disableUnderline: !item.edit,
										}}
										style={{ width: '30%', marginRight: 10 }}
									/>
									<UndlerlineTextField
										value={item.value}
										placeholder='Description (key)'
										onChange={(e) => handle_tags_edit(e, index)}
										name='value'
										textStyle={{ fontSize: 14 }}
										InputProps={{
											readOnly: !item.edit,
											disableUnderline: !item.edit,
											endAdornment: (
												<div onClick={() => on_tag_edit_icon_press(index)}>
													<img
														width={16}
														height={20}
														src={ImageLinks.edit}
														style={{
															opacity: show_add_tags ? 0.2 : 1,
															cursor: show_add_tags ? 'unset' : 'pointer',
														}}
													/>
												</div>
											),
										}}
										style={{ width: '70%' }}
									/>
								</div>
							))}

							{show_add_tags && (
								<div className={classes.input_wrapper} style={{ marginTop: rider_tags.length === 0 ? 0 : 6 }}>
									<UndlerlineTextField
										placeholder='Title (key)'
										value={new_tag_values.key}
										onChange={(e) => {
											set_new_tag_values({
												...new_tag_values,
												[e.target.name]: e.target.value,
											});
										}}
										name='key'
										style={{ width: '30%', marginRight: 10 }}
									/>
									<UndlerlineTextField
										placeholder='Description (key)'
										value={new_tag_values.value}
										onChange={(e) => {
											set_new_tag_values({
												...new_tag_values,
												[e.target.name]: e.target.value,
											});
										}}
										name='value'
										style={{ width: '70%' }}
									/>
								</div>
							)}
						</>
					)}
				</div>

				<div
					onClick={on_tag_cta_press}
					className={classes.add_save_btn_wrap}
					style={{
						backgroundColor: show_add_tags || in_edit_mode ? (is_diabled ? theme.colors.lightGrey5 : theme.colors.white) : theme.colors.lightPurple,
						...((show_add_tags || in_edit_mode) && {
							border: `1px solid ${is_diabled ? theme.colors.darkGrey2 : theme.colors.green}`,
						}),
					}}>
					<img
						src={show_add_tags || in_edit_mode ? (is_diabled ? ImageLinks.check_gray : ImageLinks.check_green) : ImageLinks.add_black}
						width={8}
						height={8}
					/>
					<Text
						bold
						style={{
							fontSize: 10,
							marginLeft: 4,
							color: show_add_tags || in_edit_mode ? (is_diabled ? theme.colors.darkGrey2 : theme.colors.green) : theme.colors.black,
						}}>
						{show_add_tags || in_edit_mode ? 'Save' : 'Add'}
					</Text>
				</div>
			</div>
		</div>
	);
};

export default RiderTags;
