import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles({
	wrapper: {
		minHeight: 29,
		width: '100%',
		padding: 8,
		backgroundColor: theme.colors.white,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
		},
	},
	desc_text: { fontSize: 10, color: theme.colors.darkGrey2, marginTop: 2 },
});

const UsecaseListItem = ({ title, handle_usecase_select = () => {}, show_forward, is_activated }) => {
	const classes = useStyles();

	return (
		<div onClick={handle_usecase_select} className={clsx('box-shadow', classes.wrapper)}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Text medium style={{ fontSize: 14 }}>
					{title}
				</Text>
				{show_forward && <img src={ImageLinks.chevron_right_black} width={16} height={16} />}
			</div>
		</div>
	);
};

export default UsecaseListItem;
