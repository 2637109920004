import { ClickAwayListener, Menu, MenuItem, Popover, Popper, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import OutlinedTextField from 'modules/OutlinedTextField';
import Text from 'modules/Text';
import React, { useMemo, useRef, useState } from 'react';
import theme from 'resources/theme';
import { setting_option } from '../helper';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: '8px 12px',
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		marginTop: 8,
		borderRadius: 6,
	},
	active_wrapper: {
		padding: '8px 12px',
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		marginTop: 8,
		border: `1px solid ${theme.colors.primary}`,
		borderRadius: 6,
	},

	status_pill: {
		padding: 6,
		borderRadius: 30,
		display: 'flex',
		justifyContent: 'space-between',
		gap: 5,
		alignItems: 'center',
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		cursor: 'pointer',
	},
	menu_item: {
		backgroundColor: theme.colors.white,
		padding: 6,
		display: 'flex',
		gap: 5,
		alignItems: 'center',
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		cursor: 'pointer',
	},
}));

function NetworkBox({ handle_update_allocation_setting, allocation_settings, image, title, network, selected_card_id, set_selected_card_id }) {
	const classes = useStyles();
	const [show_menu, set_show_menu] = useState(false);

	const menu_ref = useRef(null);

	const update_network_constraints = (value, key) => {
		let _allocations_settings = _.cloneDeep(allocation_settings);
		let _config = _.cloneDeep(allocation_settings.config);
		for (let i = 0; i < _config.length; i++) {
			for (let j = 0; j < _config[i].length; j++) {
				if (_config[i][j].network_id == network.id) {
					let network_config = _.cloneDeep(_config[i][j]);
					network_config[key] = parseInt(value);
					_config[i][j] = network_config;
					_allocations_settings.config = _.cloneDeep(_config);
					handle_update_allocation_setting(_allocations_settings);
					set_show_menu(false);
					return;
				}
			}
		}
	};

	const update_network_priority = (priority) => {
		let _allocations_settings = _.cloneDeep(allocation_settings);
		let _config = _.cloneDeep(allocation_settings.config);
		while (_config.length < 3) {
			_config.push([]);
		}
		for (let i = 0; i < _config.length; i++) {
			for (let j = 0; j < _config[i].length; j++) {
				if (_config[i][j].network_id == network.id) {
					let network_config = _.cloneDeep(_config[i][j]);
					_config[i] = _.cloneDeep(
						_.filter(_config[i], (item) => {
							return item.network_id != network.id;
						}),
					);
					if (priority == 'High') {
						_config[0].push(network_config);
					}
					if (priority == 'Medium') {
						_config[1].push(network_config);
					}
					if (priority == 'Low') {
						_config[2].push(network_config);
					}
					_allocations_settings.config = _.cloneDeep(_config);
					handle_update_allocation_setting(_allocations_settings);
					set_show_menu(false);
					return;
				}
			}
		}
		if (priority == 'High') {
			_config[0].push({ network_id: parseInt(network.id) });
		}
		if (priority == 'Medium') {
			_config[1].push({ network_id: parseInt(network.id) });
		}
		if (priority == 'Low') {
			_config[2].push({ network_id: parseInt(network.id) });
		}
		_allocations_settings.config = _.cloneDeep(_config);
		handle_update_allocation_setting(_allocations_settings);
		set_show_menu(false);
		return;
	};

	const value = useMemo(() => {
		const config = allocation_settings.config;
		for (let i = 0; i < config.length; i++) {
			for (let j = 0; j < config[i].length; j++) {
				if (config[i][j].network_id == network.id) {
					if (i == 0) {
						return {
							...setting_option[2],
							eta_max: config[i][j].eta_max ? config[i][j].eta_max : '',
							eta_min: config[i][j].eta_min ? config[i][j].eta_min : '',
							cost_max: config[i][j].cost_max ? config[i][j].cost_max : '',
							cost_min: config[i][j].cost_min ? config[i][j].cost_min : '',
						};
					}
					if (i == 1) {
						return {
							...setting_option[1],
							eta_max: config[i][j].eta_max ? config[i][j].eta_max : '',
							eta_min: config[i][j].eta_min ? config[i][j].eta_min : '',
							cost_max: config[i][j].cost_max ? config[i][j].cost_max : '',
							cost_min: config[i][j].cost_min ? config[i][j].cost_min : '',
						};
					}
					if (i == 2) {
						return {
							...setting_option[0],
							eta_max: config[i][j].eta_max ? config[i][j].eta_max : '',
							eta_min: config[i][j].eta_min ? config[i][j].eta_min : '',
							cost_max: config[i][j].cost_max ? config[i][j].cost_max : '',
							cost_min: config[i][j].cost_min ? config[i][j].cost_min : '',
						};
					}
				}
			}
		}
		return setting_option[3];
	}, [allocation_settings]);
	return (
		<div className={network.id == selected_card_id ? classes.active_wrapper : classes.wrapper} onClick={() => set_selected_card_id(network.id)}>
			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center' style={{ gap: 10 }}>
					<div>{image}</div>
					<Text semi>{title}</Text>
				</div>
				<div
					className={classes.status_pill}
					ref={menu_ref}
					onClick={(e) => {
						e.stopPropagation();
						set_show_menu(true);
					}}>
					<img src={value.img} height={12} width={12} />
					<Text semi style={{ fontSize: 12 }}>
						{value.title}
					</Text>
				</div>
				<Popper
					open={show_menu}
					anchorEl={menu_ref.current}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					style={{ zIndex: 1500 }}
					onClose={() => set_show_menu(false)}>
					<ClickAwayListener onClickAway={() => set_show_menu(false)}>
						<div>
							{_.map(setting_option, (setting) => {
								return (
									<div
										className={classes.menu_item}
										onClick={(e) => {
											update_network_priority(setting.title);
											e.stopPropagation();
										}}>
										<img src={setting.img} height={12} width={12} />
										<Text semi style={{ fontSize: 12 }}>
											{setting.title}
										</Text>
									</div>
								);
							})}
						</div>
					</ClickAwayListener>
				</Popper>
			</div>

			{network.id == selected_card_id && value.title != 'Exclude' && (
				<div style={{ marginTop: 20 }}>
					<Text semi>Conditions (optional)</Text>
					<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
						Disclaimer text over here, for how these conditions effect
					</Text>
					<div className='d-flex' style={{ gap: 10, marginTop: 10 }}>
						<div style={{ flex: 1 }}>
							<OutlinedTextField
								label='Max. ETA'
								value={value.eta_max}
								onChange={(e) => update_network_constraints(e.target.value, 'eta_max')}
								size={'small'}
							/>
						</div>
						<div style={{ flex: 1 }}>
							<OutlinedTextField
								label='Min. ETA'
								value={value.eta_min}
								onChange={(e) => update_network_constraints(e.target.value, 'eta_min')}
								size={'small'}
							/>
						</div>
						<div style={{ flex: 1 }}>
							<OutlinedTextField
								label='Max. Cost'
								value={value.cost_max}
								onChange={(e) => update_network_constraints(e.target.value, 'cost_max')}
								size={'small'}
							/>
						</div>
						<div style={{ flex: 1 }}>
							<OutlinedTextField
								label='Min. Cost'
								value={value.cost_min}
								onChange={(e) => update_network_constraints(e.target.value, 'cost_min')}
								size={'small'}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default NetworkBox;
