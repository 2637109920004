import Text from 'modules/Text';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import { PRICING_RULE } from '../../helper';

const useStyles = makeStyles(() => ({
	circle_dot: {
		width: 10,
		height: 6,
		backgroundColor: theme.colors.primary,
		borderRadius: 30,
		marginRight: 10,
		marginTop: 5,
	},
}));

const InfoContent = ({ pricing_criteria, selected_pricing_section = '' }) => {
	const classes = useStyles();
	return (
		<div style={{ padding: '20px 10px' }}>
			{pricing_criteria === PRICING_RULE.DISTANCE && (
				<>
					<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
						Tips & Info
					</Text>

					<div className='d-flex' style={{ marginTop: 10 }}>
						<div className={classes.circle_dot}></div>
						<Text style={{ fontSize: 12, color: theme.colors.primary }}>
							{`Distance more than {last slab} is charged at {2 x last distance slab}`}
						</Text>
					</div>

					<div className='d-flex' style={{ marginTop: 20, marginBottom: 20 }}>
						<div className={classes.circle_dot} style={{ width: 8 }}></div>
						<Text style={{ fontSize: 12, color: theme.colors.primary }}>{`Packages larger than {last volume slab} are charged at 2x`}</Text>
					</div>

					<div className='d-flex'>
						<div className={classes.circle_dot}></div>
						<Text style={{ fontSize: 12, color: theme.colors.primary }}>
							{`Excess Distance over {last distance slab} is charged at {2 x price of last distance slab}`}
						</Text>
					</div>
				</>
			)}

			{pricing_criteria === PRICING_RULE.PINCODE &&
				(selected_pricing_section === 'rates' ? (
					<>
						<Text style={{ fontSize: 14, color: theme.colors.primary }}>Slab End: This affects rounded up number of blocks to be charged.</Text>
						<Text style={{ fontSize: 12, color: theme.colors.lightPurple5, marginTop: 4 }}>(e.g. 750g weight is 2 slabs of 500g)</Text>
					</>
				) : (
					<>
						<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
							Sample Pincode
						</Text>

						<div className='d-flex' style={{ marginTop: 10 }}>
							<div className={classes.circle_dot}></div>
							<Text style={{ fontSize: 12, color: theme.colors.primary }}>
								Enter pincodes separated by a comma.
								<Text component={'span'} bold style={{ fontSize: 12 }}>
									(122001,122002, 122003, 122004)
								</Text>
							</Text>
						</div>

						<div className='d-flex' style={{ marginTop: 20, marginBottom: 20 }}>
							<div className={classes.circle_dot} style={{ width: 16 }}></div>
							<Text style={{ fontSize: 12, color: theme.colors.primary }}>
								To include a range of pincodes with partial match, add an asterisk (*) after the characters that begin the range.
								<Text component={'span'} bold style={{ fontSize: 12 }}>
									(12200*)
								</Text>
							</Text>
						</div>

						<div className='d-flex'>
							<div className={classes.circle_dot}></div>
							<Text style={{ fontSize: 12, color: theme.colors.primary }}>
								To include add range `:` in between start and end pincode.
								<Text component={'span'} bold style={{ fontSize: 12 }}>
									(122001:122009)
								</Text>
							</Text>
						</div>
					</>
				))}
		</div>
	);
};

export default InfoContent;
