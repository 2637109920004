import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Divider, ListItem } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import DatePicker from 'react-datepicker';

import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import OutlinedButton from 'modules/OutlinedButton';
import Text from 'modules/Text';
import TimeInput from 'modules/TimeInput';
import 'react-datepicker/dist/react-datepicker.css';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import { useStyles } from './style';
import SavedAddresses from 'modules/modals/SavedAddresses';
import RiderAddress from 'modules/modals/RiderAddress';
import utils from 'resources/utils';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Switch from 'modules/Switch';
import Button from 'modules/Button';
import RouteUploadModal from '../RouteUploadModal';
import RouteStats from '../RouteStats';

const ADDRESS_TYPE = {
	saved: 'saved',
	new: 'new',
	recent: 'recent',
};

function AssignmentModal({ assignment_data, close, get_vehicle_list, edit_assignment_detail = {}, set_show_toast }) {
	const classes = useStyles();
	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);
	const [pick_date, set_pick_date] = useState(null);
	const [pick_time, set_pick_time] = useState(moment().format('00:00'));
	const [drop_date, set_drop_date] = useState(null);
	const [drop_time, set_drop_time] = useState(moment().format('00:00'));
	const [address_type, set_address_type] = useState('');
	const [pickup_address, set_pickup_address] = useState({});
	const [drop_address, set_drop_address] = useState({});
	const [selected_riders, set_selected_riders] = useState({});
	const [search_rider_id, set_search_rider_id] = useState('');
	const [search_riders, set_search_riders] = useState([]);
	const [selected_master_zone_id, set_selected_master_zone_id] = useState(zones[0].id);
	const [show_saved_address_modal, set_show_saved_address_modal] = useState(false);
	const [show_address_modal, set_show_address_modal] = useState(false);
	const [show_exit_popup, set_show_exit_popup] = useState(false);
	const [no_rider, set_no_rider] = useState(false);
	const [show_upload_modal, set_show_upload_modal] = useState(false);
	const [route_data, set_route_data] = useState({});
	const [show_delete_modal, set_show_delete_modal] = useState(false);

	useEffect(() => {
		if (!_.isEmpty(search_rider_id)) {
			api_requests
				.search_rider_without_zone({
					search: search_rider_id,
					is_active: true,
				})
				.then((res) => {
					set_search_riders(res.data);
				});
		} else {
			set_search_riders([]);
		}
	}, [search_rider_id]);

	useEffect(() => {
		if (!_.isEmpty(edit_assignment_detail)) {
			set_pickup_address({ address: edit_assignment_detail.pickup_address });
			set_drop_address({ address: edit_assignment_detail.drop_address });
			set_pick_date(moment(edit_assignment_detail.start_time).toDate());
			set_drop_date(moment(edit_assignment_detail.end_time).toDate());
			set_pick_time(moment(edit_assignment_detail.start_time).format('HH:mm'));
			set_drop_time(moment(edit_assignment_detail.end_time).format('HH:mm'));
			set_selected_riders({
				rider_id: edit_assignment_detail.rider_id,
				rider_name: edit_assignment_detail.rider_name,
			});
			if (edit_assignment_detail.route_meta) {
				set_route_data({
					...edit_assignment_detail.route_meta,
					route_id: edit_assignment_detail.route_id,
				});
			}
		}
	}, [edit_assignment_detail]);

	const handle_select_rider = (rider, is_delete) => {
		if (is_delete) {
			set_selected_riders({});
		} else {
			set_selected_riders(rider);
		}
		set_search_rider_id('');
		set_search_riders([]);
	};

	const open_address_modal = (address_type) => {
		set_address_type(address_type);
		set_show_saved_address_modal(true);
	};

	const on_address_select = (address, type) => {
		if (address_type == 'pickup')
			set_pickup_address({
				address: address.address_line1,
				location: address.exact_location
					? {
							...address.exact_location,
					  }
					: {
							...address.coordinates,
					  },
			});
		else {
			set_drop_address({
				address: address.address_line1,
				location: address.exact_location
					? {
							...address.exact_location,
					  }
					: {
							...address.coordinates,
					  },
			});
		}
	};

	const create_assignment = async () => {
		const data = utils.remove_null_and_empty_from_obj({
			...(_.isEmpty(edit_assignment_detail) && {
				vehicle_id: assignment_data.id,
			}),
			pickup_address: pickup_address.address,
			pickup_location: pickup_address.location,
			start_time: moment(moment(pick_date).format('DD-MM-YYYY') + ' ' + pick_time, 'DD-MM-YYYY HH:mm').toISOString(),
			rider_id: no_rider ? '-1' : selected_riders.rider_id,
			end_time: moment(moment(drop_date).format('DD-MM-YYYY') + ' ' + drop_time, 'DD-MM-YYYY HH:mm').toISOString(),
			drop_address: drop_address.address,
			drop_location: drop_address.location,
			...(!_.isEmpty(route_data) && {
				route: route_data,
			}),
		});
		try {
			if (_.isEmpty(edit_assignment_detail)) {
				const res = await api_requests.assign_rider_to_fleet(data);
				if (res.success) {
					get_vehicle_list({});
					close();
				}
			} else {
				const res = await api_requests.update_rider_to_fleet(data, edit_assignment_detail.assingment_id);
				if (res.success) {
					get_vehicle_list({});
					set_show_toast('assignment');
					close();
				}
			}
		} catch (err) {}
	};

	const handle_close_click = () => {
		if (!_.isEmpty(edit_assignment_detail)) {
			close();
			return;
		}
		if (drop_date != null || pick_date != null || !_.isEmpty(pickup_address) || !_.isEmpty(pickup_address) || !_.isEmpty(selected_riders)) {
			set_show_exit_popup(true);
			return;
		}
		close();
	};

	const handle_route_data = (data) => {
		try {
			const routes = _.cloneDeep(data.points);
			routes.sort((a, b) => a.sequence - b.sequence);
			const pickup_data = _.filter(routes, (item) => item.sequence == 1)[0];
			const drop_tasks = _.filter(routes, (item) => item.sequence == -1);
			const drop_data = drop_tasks.length > 0 ? drop_tasks[0] : routes[routes.length - 1];

			const pick_date_time = routes[0].date;
			const total_time = routes.length * data.wait_time * 60 + parseFloat(data.time);
			const drop_date_time = moment(pick_date_time).add(total_time, 'seconds');

			set_pickup_address({ address: pickup_data?.address, location: { latitude: pickup_data?.latitude, longitude: pickup_data?.longitude } });
			set_drop_address({ address: drop_data?.address, location: { latitude: drop_data?.latitude, longitude: drop_data?.longitude } });
			set_pick_date(moment(pick_date_time).toDate());
			set_drop_date(moment(drop_date_time).toDate());
			set_pick_time(moment(pick_date_time).format('HH:mm'));
			set_drop_time(moment(drop_date_time).format('HH:mm'));
			set_route_data(data);
		} catch (error) {
			console.log(error);
		}
	};

	const handle_route_delete = () => {
		set_show_delete_modal(true);
	};

	const remove_all_states = () => {
		set_route_data({});
		set_pick_date(null);
		set_pick_time(moment().format('00:00'));
		set_drop_date(null);
		set_drop_time(moment().format('00:00'));
		set_address_type('');
		set_pickup_address({});
		set_drop_address({});
	};

	const check_btn_disabled = useMemo(() => {
		if (_.isEmpty(edit_assignment_detail)) {
			if (
				(!selected_riders.rider_id && !no_rider) ||
				!pickup_address.address ||
				!drop_address.address ||
				_.isEmpty(drop_address) ||
				_.isEmpty(pickup_address) ||
				!pick_date ||
				!drop_date
			) {
				return true;
			}
		}
		return false;
	}, [selected_riders, pickup_address, drop_address, pick_date, drop_date, no_rider]);

	const is_ongoing = useMemo(() => {
		if (!_.isEmpty(edit_assignment_detail) && moment(edit_assignment_detail.start_time).isBefore(moment())) {
			return true;
		}
		return false;
	}, [edit_assignment_detail]);

	const disable_upload =
		_.isEmpty(route_data) &&
		(pickup_address.address || drop_address.address || !_.isEmpty(drop_address) || !_.isEmpty(pickup_address) || pick_date || drop_date);

	return (
		<>
			<div className={classes.close_container} onClick={handle_close_click} />
			<div className={classes.container}>
				<div className='d-flex align-items-center justify-content-between'>
					<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
						{_.isEmpty(edit_assignment_detail) ? 'Create Assignment' : 'Edit Assignment'}
					</Text>
					<img src={ImageLinks.crossBlack} onClick={handle_close_click} style={{ cursor: 'pointer' }} />
				</div>
				<Divider />
				<div className={classes.card_wrap}>
					<div className='d-flex justify-content-between'>
						<div className='d-flex' style={{ gap: 5 }}>
							<img src={ImageLinks.truck_purple} height={16} width={16} />
							<Text bold>{assignment_data.registration_number}</Text>
						</div>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							{`${assignment_data.vehicle_type} ${assignment_data.capacity ? '| ' + assignment_data.capacity + ' m³' : ''}  ${
								assignment_data.owner_name && !assignment_data.lease_to ? '| ' + assignment_data.owner_name : ''
							}`}
						</Text>
					</div>
					<Divider style={{ margin: '8px 0' }} />
					<div className='d-flex justify-content-between'>
						<div className='d-flex' style={{ gap: 5 }}>
							<img src={assignment_data.rider_id == '-1' ? ImageLinks.no_rider_icon : ImageLinks.rider_light_purple2} height={16} width={16} />
							<div className='d-flex' style={{ gap: 5 }}>
								{assignment_data.rider_id && (
									<>
										<Text
											medium
											style={{
												fontSize: 12,
												color: theme.colors.darkGrey7,
											}}>
											{assignment_data.rider_id == '-1' ? 'No Rider' : `${assignment_data.rider_name} (${assignment_data.rider_id})`}
										</Text>
									</>
								)}
							</div>
							{assignment_data.start_time && assignment_data.end_time && (
								<div>
									<Text
										medium
										style={{
											fontSize: 12,
											color: theme.colors.darkGrey7,
										}}>{`| ${moment(assignment_data.start_time).format(`DD MMM 'YY, h:mmA`)} - ${moment(assignment_data.end_time).format(
										`DD MMM 'YY, h:mmA`,
									)}`}</Text>
								</div>
							)}
						</div>
					</div>
				</div>
				{/* <div className={classes.bulk_assignment_wrap}>
					<Text semi style={{ color: theme.colors.primary }}>
						Book vehicle for multiple assignments.
					</Text>
					<OutlinedButton style={{ backgroundColor: theme.colors.white }}>
						<img src={ImageLinks.add_pruple_no_fill} height={12} width={12} style={{ marginRight: 10 }} />
						<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
							Bulk Assignment
						</Text>
					</OutlinedButton>
				</div> */}

				{_.isEmpty(route_data) && assignment_data?.trace_entity_id && (
					<div className={classes.upload_wrap} style={{ backgroundColor: disable_upload ? theme.colors.lightGrey5 : theme.colors.lightPink4 }}>
						<Text semi style={{ fontSize: 14, color: disable_upload ? theme.colors.darkGrey2 : theme.colors.primary }}>
							Create a reference route for Trace
						</Text>
						<OutlinedButton
							disabled={disable_upload}
							className={classes.upload_btn}
							onClick={() => set_show_upload_modal(true)}
							style={{
								backgroundColor: disable_upload ? theme.colors.lightGrey5 : theme.colors.white,
								border: `1px solid ${disable_upload ? theme.colors.darkGrey2 : theme.colors.primary}`,
							}}>
							<img src={disable_upload ? ImageLinks.upload_black : ImageLinks.upload_purple} width={12} height={12} />
							<Text semi className={classes.upload_text} style={{ color: disable_upload ? theme.colors.darkGrey2 : theme.colors.primary }}>
								Upload Route
							</Text>
						</OutlinedButton>
					</div>
				)}

				{!_.isEmpty(route_data) && (
					<RouteStats route_data={route_data} show_delete={_.isEmpty(edit_assignment_detail)} handle_route_delete={handle_route_delete} />
				)}

				<Text semi className={classes.header_text}>
					Pickup Address*
				</Text>
				<div className={classes.vehicle_detail_wrap} style={{ opacity: is_ongoing || !_.isEmpty(route_data) ? 0.3 : 1 }}>
					<div className='d-flex align-items-center' style={{ gap: 10 }}>
						<img src={ImageLinks.rider_pickup} height={24} width={24} />
						{_.isEmpty(pickup_address) && (
							<ContainedButton
								style={{ backgroundColor: theme.colors.lightPink4 }}
								className={classes.add_address_btn}
								onClick={() => open_address_modal('pickup')}>
								<img src={ImageLinks.add_pruple_no_fill} height={12} width={12} style={{ marginRight: 5 }} />
								<Text semi style={{ color: theme.colors.primary, fontSize: 12 }}>
									Add address
								</Text>
							</ContainedButton>
						)}
						{!_.isEmpty(pickup_address) && (
							<div className='d-flex' style={{ gap: 10 }}>
								<Text medium style={{ fontSize: 12 }}>
									{pickup_address.address}
								</Text>
								<img
									src={ImageLinks.edit_filled_light_purple}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										if (is_ongoing || !_.isEmpty(route_data)) return;
										open_address_modal('pickup');
										// if (!is_ongoing) open_address_modal('pickup');
									}}
								/>
							</div>
						)}
					</div>
					<div className='d-flex' style={{ gap: 60 }}>
						<div>
							<Text semi className={classes.header_text}>
								Start Date*
							</Text>
							<DatePicker
								dateFormat='yyyy-MM-dd'
								selected={pick_date}
								onChange={(date) => {
									set_pick_date(date);
								}}
								disabled={is_ongoing || !_.isEmpty(route_data)}
								minDate={moment().toDate()}
								customInput={
									<div className={classes.date_wrapper}>
										<Text semi style={{ fontSize: 12, color: pick_date ? theme.colors.primary : theme.colors.lightPurple6, width: '80%' }}>
											{pick_date ? moment(pick_date).format('DD MMM `YY') : 'Select Date'}
										</Text>
										<img src={ImageLinks.calendar_solid_circle} alt='date' className='cursor-pointer' width={22} height={22} />
									</div>
								}
							/>
						</div>
						<div>
							<Text semi className={classes.header_text}>
								Start Time*
							</Text>
							<TimeInput disabled={is_ongoing || !_.isEmpty(route_data)} value={pick_time} handleChange={(val) => set_pick_time(val)} />
						</div>
					</div>
				</div>
				<Text semi className={classes.header_text}>
					Drop Address*
				</Text>
				<div className={classes.vehicle_detail_wrap} style={{ opacity: !_.isEmpty(route_data) ? 0.3 : 1 }}>
					<div className='d-flex align-items-center' style={{ gap: 10 }}>
						<img src={ImageLinks.marker_down_arrow} height={24} width={24} />
						{_.isEmpty(drop_address) && (
							<ContainedButton
								style={{ backgroundColor: theme.colors.lightPink4 }}
								className={classes.add_address_btn}
								onClick={() => open_address_modal('drop')}>
								<img src={ImageLinks.add_pruple_no_fill} height={12} width={12} style={{ marginRight: 5 }} />
								<Text semi style={{ color: theme.colors.primary, fontSize: 12 }}>
									Add address
								</Text>
							</ContainedButton>
						)}
						{!_.isEmpty(drop_address) && (
							<div className='d-flex' style={{ gap: 10 }}>
								<Text medium style={{ fontSize: 12 }}>
									{drop_address.address}
								</Text>
								<img
									src={ImageLinks.edit_filled_light_purple}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										if (!_.isEmpty(route_data)) return;
										open_address_modal('drop');
									}}
								/>
							</div>
						)}
					</div>
					<div className='d-flex' style={{ gap: 60 }}>
						<div>
							<Text semi className={classes.header_text}>
								End Date*
							</Text>
							<DatePicker
								dateFormat='yyyy-MM-dd'
								selected={drop_date}
								onChange={(date) => {
									set_drop_date(date);
								}}
								minDate={moment().toDate()}
								disabled={!_.isEmpty(route_data)}
								customInput={
									<div className={classes.date_wrapper}>
										<Text semi style={{ fontSize: 12, color: drop_date ? theme.colors.primary : theme.colors.lightPurple6, width: '80%' }}>
											{drop_date ? moment(drop_date).format('DD MMM `YY') : 'Select Date'}
										</Text>
										<img src={ImageLinks.calendar_solid_circle} alt='date' className='cursor-pointer' width={22} height={22} />
									</div>
								}
							/>
						</div>
						<div>
							<Text semi className={classes.header_text}>
								End Time*
							</Text>
							<TimeInput disabled={!_.isEmpty(route_data)} value={drop_time} handleChange={(val) => set_drop_time(val)} />
						</div>
					</div>
				</div>
				<div style={{ marginTop: 30 }}>
					<Text semi className={classes.header_text}>
						Assign Rider
					</Text>
					{_.isEmpty(selected_riders) && !is_ongoing && (
						<div className={classes.add_rider_wrap}>
							<div className={classes.add_rider_input_wrap}>
								<img src={ImageLinks.search_light_pink} alt='' />
								<input
									placeholder='Search Rider Name or ID'
									className={classes.add_rider_input}
									value={search_rider_id}
									onChange={(e) => set_search_rider_id(e.target.value)}
									disabled={no_rider}
								/>
							</div>
							<div className={classes.no_rider}>
								<Tooltip
									title='Do not assign to rider'
									arrow={true}
									classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
									placement='top'>
									<div className='d-flex align-items-center' style={{ gap: 4 }}>
										<img src={ImageLinks.no_rider_icon} />
										<Text style={{ color: theme.colors.lightPurple7, fontSize: '0.75rem' }}>No Rider</Text>
									</div>
								</Tooltip>
								<Switch checked={no_rider} onChange={() => set_no_rider((prev) => !prev)} />
							</div>
						</div>
					)}
					{!_.isEmpty(search_riders) && (
						<div className={classes.search_rider_wrap}>
							{_.map(search_riders, (rider, index) => {
								return (
									<ListItem
										button
										key={`menu${index}`}
										onClick={() => handle_select_rider(rider)}
										tabIndex={index}
										style={{ padding: '12px 20px', ...(index !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }) }}>
										<Text>{rider.rider_id}</Text>
										<Text style={{ marginLeft: 20 }}>{rider.rider_name}</Text>
									</ListItem>
								);
							})}
						</div>
					)}

					{!_.isEmpty(selected_riders) && (
						<div className={classes.rider_list_wrap}>
							<div style={{ position: 'relative' }}>
								<ListItem button className={classes.rider_list_item} onClick={() => {}}>
									<div className={classes.rider_info}>
										<Text bold className={classes.rider_id_text}>
											{selected_riders.rider_id}
										</Text>
										<Text bold className={classes.rider_name_text}>
											{_.capitalize(selected_riders.rider_name)}
										</Text>
									</div>
								</ListItem>
								<div className={classes.delete_btn} onClick={() => handle_select_rider(selected_riders, true)}>
									{!is_ongoing && <img src={ImageLinks.delete} height={16} style={{ marginRight: 10 }} />}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className={classes.create_button_wrap}>
				<Button
					type='round'
					text={_.isEmpty(edit_assignment_detail) ? 'Assign' : 'Update'}
					size='large'
					disabled={check_btn_disabled}
					onClick={create_assignment}
					style={{ width: 110 }}
				/>
			</div>
			{show_saved_address_modal && (
				<SavedAddresses
					title={`Select ${address_type} Address`}
					on_address_select={on_address_select}
					close={() => set_show_saved_address_modal(false)}
					address_type={ADDRESS_TYPE.saved}
					edit_details={{}}
					on_new_addr_tab_press={() => {
						set_show_address_modal(true);
						set_show_saved_address_modal(false);
					}}
				/>
			)}
			{show_address_modal && (
				<RiderAddress
					title={`Select ${address_type} address`}
					zone_id={selected_master_zone_id}
					close={() => set_show_address_modal(false)}
					on_address_select={on_address_select}
				/>
			)}
			{show_exit_popup && (
				<ConfirmClose
					headerTitle='Discard Changes'
					title='You have made changes but you have not saved them. Are you sure you want to discard these changes?'
					on_confirm={close}
					close={close}
					show_negative_btn={true}
					handle_negative_action={() => set_show_exit_popup(false)}
				/>
			)}
			{show_delete_modal && (
				<ConfirmClose
					headerTitle='Delete Route'
					title='Are you sure you want to delete route {filename.csv}?'
					on_confirm={remove_all_states}
					is_error_msg
					close={() => set_show_delete_modal(false)}
					show_negative_btn={false}
				/>
			)}
			{show_upload_modal && (
				<RouteUploadModal assignment_data={assignment_data} close={() => set_show_upload_modal(false)} handle_route_data={handle_route_data} />
			)}
		</>
	);
}

export default AssignmentModal;
