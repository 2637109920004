/**
 * constant file for whole app
 */

import _ from 'lodash';

const env = {
	STAGING: 'staging',
	PRODUCTION: 'production',
};

let ENVIRONMENT = env.STAGING;
// const ENVIRONMENT = env.PRODUCTION;

const ORDER_STATUS = {
	AWB_Registered: {
		id: 1,
		text: 'Registered',
	},
	Out_For_Pickup: {
		id: 3,
		text: 'Out For Pickup',
	},
	Picked_Up: {
		id: 4,
		text: 'Picked Up',
	},
	In_Transit: {
		id: 5,
		text: 'In Transit',
	},
	Out_For_Delivery: {
		id: 10,
		text: 'Out For Delivery',
	},
	Undelivered: {
		id: 12,
		text: 'Undelivered',
	},
	Delivered: {
		id: 11,
		text: 'Delivered',
	},
	RTO_OFD: {
		id: 20,
		text: 'RTO OFD',
	},
	RTO_Delivered: {
		id: 22,
		text: 'RTO Delivered',
	},
	RTO_Undelivered: {
		id: 21,
		text: 'RTO Undelivered',
	},
	LOST: {
		id: 40,
		text: 'LOST',
	},
	DAMAGED: {
		id: 41,
		text: 'DAMAGED',
	},
	Cancelled: {
		id: 0,
		text: 'Cancelled',
	},
	Disposed_off: {
		id: 42,
		text: 'Disposed off',
	},
	Pick_Up_Exception: {
		id: 13,
		text: 'Pick Up Exception',
	},
	RTO_Initiated: {
		id: 19,
		text: 'RTO Initiated',
	},
	//Third Party CDR statuses
	CANCELLED: {
		id: 0,
		text: 'Cancelled',
	},
	CREATED: {
		id: 1,
		text: 'Registered',
	},
	OUT_FOR_PICKUP: {
		id: 3,
		text: 'Out For Pickup',
	},
	REACHED_PICKUP: {
		id: 3.5,
		text: 'Reached Pickup',
	},
	PICKED_UP: {
		id: 4,
		text: 'Picked Up',
	},
	IN_TRANSIT: {
		id: 5,
		text: 'In Transit',
	},
	OUT_FOR_DELIVERY: {
		id: 10,
		text: 'Out For Delivery',
	},
	REACHED_DELIVERY: {
		id: 10.5,
		text: 'Reached Delivery',
	},
	DELIVERED: {
		id: 11,
		text: 'Delivered',
	},
	UNDELIVERED: {
		id: 12,
		text: 'Undelivered',
	},
	RTO_OUT_FOR_DELIVERY: {
		id: 20,
		text: 'RTO Out for delivery',
	},
	RTO_UNDELIVERED: {
		id: 21,
		text: 'RTO Undelivered',
	},
	RTO_DELIVERED: {
		id: 22,
		text: 'RTO Delivered',
	},
};

const TRIP_STATUS = {
	DELIVERED: {
		id: 190,
		text: 'Delivered',
	},
	SCHEDULED: {
		id: 30,
		text: 'Scheduled',
	},
	CANCELLED: {
		id: 0,
		text: 'Cancelled',
	},
	ALLOCATED: {
		id: 80,
		text: 'Allocated',
	},
	RIDER_CONFIRMED: {
		id: 90,
		text: 'Rider Confirmed',
	},
	STARTED_FOR_PICKUP: {
		id: 100,
		text: 'Started for pickup',
	},
	REACHED_PICKUP: {
		id: 120,
		text: 'Reached Pickup',
	},
	PACKAGE_SCANNED: {
		id: 130,
		text: 'Package Scanned',
	},
	STARTED_FOR_DELIVERY: {
		id: 140,
		text: 'Started for delivery',
	},
	DELIVERY_START: {
		id: 150,
		text: 'Delivery Start',
	},
	REACHED_DROP_LOCATION: {
		id: 170,
		text: 'Reached Drop Location',
	},
	SCANNED_DROP: {
		id: 180,
		text: 'Scanned at drop',
	},
	POD_UPLOADED: {
		id: 185,
		text: 'POD Uploaded',
	},
	FAILED_DELIVERY: {
		id: 5,
		text: 'Failed Delivery',
	},
};

const ADMIN_TRIP_TYPE = {
	ALLOCATED: {
		id: 80,
		text: 'Allocated',
	},
	RIDER_CONFIRMED: {
		id: 90,
		text: 'Rider Confirmed',
	},
	STARTED_FOR_HUB: {
		id: 150,
		text: 'Started for hub',
	},
	REACHED_HUB: {
		id: 170,
		text: 'Reached Hub',
	},
};

const ATTEMPT_TYPE = {
	First_Attempt: {
		id: 10,
		text: 'First Attempt',
	},
	RTPW: {
		id: 20,
		text: 'RTPW',
	},
	RTPW_DRTO: {
		id: 21,
		text: 'RTPW - DRTO',
	},
	RTPW_NA: {
		id: 22,
		text: 'RTPW - NA',
	},
	RTO: {
		id: 30,
		text: 'RTO',
	},
	Reattempt: {
		id: 40,
		text: 'Reattempt',
	},
	RTO_HP: {
		id: 50,
		text: 'RTO - HP',
	},
	Middle_Mile: {
		id: 70,
		text: 'Middle Mile',
	},
};

const USER_TYPE = {
	Admin: {
		id: 0,
		type: 'admin',
		text: 'Admin',
	},
	User: {
		id: 1,
		type: 'user',
		text: 'User',
	},
	Rider: {
		id: 2,
		type: 'rider',
		text: 'Rider',
	},
	Shopify: {
		id: 3,
		type: 'shopify',
		text: 'Shopify',
	},
	Vendor: {
		id: 4,
		type: 'vendor',
		text: 'Vendor',
	},
};

const OWNER_TYPE = {
	PIDGE: {
		id: 1,
		text: 'Pidge',
	},
	MANPOWER: {
		id: 2,
		text: 'Manpower',
	},
	VENDOR: {
		id: 3,
		text: 'Vendor',
	},
	BUSINESS: {
		id: 4,
		text: 'Business',
	},
	GIG: {
		id: 5,
		text: 'Gig',
	},
	AGGREGATOR: {
		id: 6,
		text: 'Aggregator',
	},
};

const TRIP_TYPE = {
	NORMAL: {
		id: 1,
		text: 'Normal',
	},
	SCHEDULED: {
		id: 2,
		text: 'Scheduled',
	},
	MULTI: {
		id: 3,
		text: 'Multi',
	},
	ADMIN: {
		id: 4,
		text: 'Admin',
	},
	REVERSE: {
		id: 5,
		text: 'Reverse',
	},
	MIDDLE: {
		id: 6,
		text: 'Middle',
	},
};

const TASK_TYPES = {
	PICKUP: {
		id: 10,
	},
	DROP: {
		id: 20,
	},
};

const COMM_CHANNEL = {
	RAVEN: 'raven',
	PUSH: 'push',
	WEBHOOK: 'webhook',
	MOCK: 'mock',
	WHATSAPP: 'whatsapp',
};

const COMM_CHANNEL_NAME_MAP = {
	raven: 'SMS',
	push: 'Web Dashboard',
	webhook: 'Webhook',
	mock: 'Mock',
	whatsapp: 'Whatsapp',
};

const SOCKET_EVENTS = {
	JOIN: 'join',
	NOTIFICATION: 'notification',
	TEAM: 'team',
	LEAVE: 'leave',
	ALERT: 'alert',
};

const BTN_STATE = {
	DEFAULT: 'default',
	SUCCESS: 'success',
	FAILED: 'failed',
	LOADING: 'loading',
};

const DELIVERY_DETAIL_MODAL_FILEDS = {
	name: 'name',
	mobile: 'mobile',
	ref_id: 'ref_id',
	cod: 'cod',
	bill: 'bill',
	date: 'date',
	slot: 'slot',
};

const LOCAL_STORAGE_KEYS = {
	AGG_BIZ_LIST: 'agg_biz_list',
	AGGREGATOR_INFO: 'aggregator_info',
};

const TRACKING_EVENTS = {
	ALLOCATE: 'allocate',
	HELP_CENTER: 'help_center',
	BULK_CANCEL: 'bulk_cancel',
	BULK_UNALLOCATE: 'bulk_unallocate',
	BULK_UNROUTE: 'bulk_unroute',
	CREATE_ORDER_PACKAGE_SIZE: 'create_order_package_size',
	CREATE_ORDER_PACKAGE_CATEGORY: 'create_order_package_category',
	ORDERMODAL_TRACKING: 'ordermodal_tracking',
	PAYMENT_LINK: 'payment_link',
	CANCEL_ORDER: 'cancel_order',
	ORDER_ALLOCATE_BTN: 'order_allocate_btn',
	ORDERMODAL_UNROUTE: 'ordermodal_unroute',
	EDIT_ORDER_NOTES: 'edit_order_notes',
	EDIT_COD: 'edit_cod',
	EDIT_PACKAGE_DETAILS: 'edit_package_details',
	ALL_ORDER_EMPTY_CREATE_ORDER: 'all_order_empty_create_order',
	ORDER_ANALYTICS: 'order_analytics',
	OVERVIEW_ORDER_CLICK: 'overview_order_click',
	PARTNER_CLICK: 'partner_click',
	SELF_ROUTE: 'self_route',
	CONFIRM_SELF_ROUTE_MOVE: 'confirm_self_route_move',
	NEW_RIDER: 'new_rider',
	BULK_RIDER: 'bulk_rider',
	REPEAT_ORDER: 'repeat_order',
	CLICK_FREQUENT_DELIVERY: 'click_frequent_delivery',
	CLICK_NEW_DELIVERY: 'click_new_delivery',
	CREATE_ORDER_SAVED_CLICKED: 'create_order_saved_clicked',
	CREATE_ORDER_NEW_CLICKED: 'create_order_new_clicked',
	CLICK_GET_STARTED: 'click_get_started',
	ROUTE_COMP_ALLOCATE: 'route_comp_allocate',
	ROUTE_REPORT: 'route_report',
	ROUTE_ADV_COMPARE: 'route_adv_compare',
	ROUTE_ADV_SIMUL: 'route_adv_simul',
	ROUTE_BULK: 'route_bulk',
	ROUTE_UNASSIGN: 'route_unassign',
	ROUTE_CHANGE_RIDER: 'route_change_rider',
	ROUTE_NAME_EDIT: 'route_name_edit',
	ROUTE_SEQUENCE: 'route_sequence',
	ROUTE_UNLINK: 'route_unlink',
	ROUTE_UNROUTE: 'route_unroute',
	ROUTE_ALLOCATE_RIDER: 'route_allocate_rider',
	LINK_RIDER: 'link_rider',
	SEARCH_RIDERS: 'search_riders',
	PP_WAREHOUSE_ORDER: 'pp_warehouse_order',
	PP_TRACKORDERS: 'pp_trackorders',
	PP_OG_ORDER_CLICK: 'pp_og_order_click',
	PP_ORDERID: 'pp_orderid',
	PP_EMPTY_CREATE_ORDER: 'pp_empty_create_order',
	RTS_QUICKSHIP: 'rts_quickship',
	PP_RTS_ORDERID: 'pp_rts_orderid',
	ROSTER_ASSIGNLEAVE: 'roster_assignleave',
	ROSTER_CREATEROSTER: 'roster_createroster',
	ROSTER_EDIT: 'roster_edit',
	ROSTER_DELETE: 'roster_delete',
	ROSTER_SETROSTER: 'roster_setroster',
	ROSTER_NEWROSTER: 'roster_newroster',
	RIDER_TYPE: 'rider_type',
	ADDRESS_UPDATE: 'address_update',
	CONTACT_UPDATE: 'contact_update',
	PP_WAREHOUSE: 'pp_warehouse',
	PP_RTS_CREATE_ROUTE: 'pp_rts_create_route',
	ZONE_NEW_ZONE: 'zone_new_zone',
	SAVED_NEW_PICKUP: 'saved_new_pickup',
	SAVED_NEW_WAREHOUSE: 'saved_new_warehouse',
	SAVED_ADDRESS_FILTER: 'saved_address_filter',
	SAVED_ADDRESS_ADD_SENDER: 'saved_address_add_sender',
	HUB_NEW_HUB: 'hub_new_hub',
	HUB_DELETE_HUB: 'hub_delete_hub',
	CREATE_ALERT: 'create_alert',
	ALL_RIDER_FILTER: 'all_rider_filter',
	RIDER_NAME: 'rider_name',
	ADVANCED_RIDER_ANALYTICS: 'advanced_rider_analytics',
	USER_INVITE_USER: 'user_invite_user',
	USER_PENDING_INVITES: 'user_pending_invites',
	USER_NEW_TEAM: 'user_new_team',
	WHITELABEL_SAVE_BRAND_COLOR: 'whitelabel_save_brand_color',
	WHITELABEL_SAVE_END_USER: 'whitelabel_save_end_user',
	RIDER_HANDOVER_TOGGLE: 'rider_handover_toggle',
	APPCLICK_AUTO_ACCEPT: 'appclick_auto_accept',
	APPCLICK_REACH_PICKUP: 'appclick_reach_pickup',
	APPCLICK_REACH_DROP: 'appclick_reach_drop',
	APPCLICK_PACKAGE_INFO: 'appclick_package_info',
	APPCLICK_START_NEW_TASK: 'appclick_start_new_task',
	APPCLICK_ADMIN_TASK: 'appclick_admin_task',
	APPCLICK_KM_ORDER: 'appclick_km_order',
	SMART_ALLOCATION_CREATED: 'smart_allocation_created',
	AUTO_MANIFEST_CREATED: 'auto_manifest_created',
	SAVE_GEOLOCATION: 'save_geolocation',
	SAVE_POP_POD: 'save_pop_pod',
	NAV_NOTIFICATION: 'nav_notification',
	NAV_SEARCH: 'nav_search',
	NAV_HELP_CENTER: 'nav_help_center',
	NAV_SUPPORT: 'nav_support',
	NAV_SETTING: 'nav_setting',
	NAV_WALLET: 'nav_wallet',
	NAV_COLLAPSE: 'nav_collapse',
	TOGGLE_MASKED_CALL: 'toggle_masked_call',
	SETTING_BARCODES: 'setting_barcodes',
	RIDER_OVERVIEW_NAME: 'rider_overview_name',
	RIDER_OVERVIEW_ONGOING: 'rider_overview_ongoing',
	RIDER_OVERVIEW_DELIVERED: 'rider_overview_name',
	RIDER_OVERVIEW_FAILED: 'rider_overview_failed',
	RIDER_LOGIN_TOGGLE: 'rider_login_toggle',
	RIDER_LOCATION_REFRESH: 'rider_location_refresh',
	EDIT_RIDER_DETAILS: 'edit_rider_details',
	CALL_RIDER: 'call_rider',
	TRACKING_NETWORK: 'tracking_network',
	TRACKING_LOGGED_IN: 'tracking_logged_in',
	TRACKING_ON_TASK: 'tracking_on_task',
	GLOBAL_FILTER: 'global_filter',
	PRE_LOGIN: 'pre_login',
	PRE_FIND_PARTNER: 'pre_find_partner',
	PRE_OPT_ROUTES: 'pre_opt_routes',
	PRE_SINGUP: 'pre_signup',
	FIND_PARTNER_SINGUP: 'find_partner_signup',
	OPT_DELIVERIES_COUNT: 'opt_deliveries_count',
	OPT_RIDERS_COUNT: 'opt_riders_count',
	OPT_CREATE_ROUTES: 'opt_create_routes',
	OPT_ROUTES_SINGUP: 'opt_routes_signup',
	OPT_MODIY_ROUTES: 'opt_modify_routes',
	SEARCH_PARTNERS: 'search_partners',
	UTM_SOURCE: 'utm_source',
	UTM_MEDIUM: 'utm_medium',
	UTM_CAMPAIGN: 'utm_campaign',
	ON_BORDING_GUIDE: 'on_boarding_guide',
	REQUEST_SUPPORT: 'request_support',
	START_KYC: 'start_kyc',
	CREATE_ORDER_CLICKED_FROM_GUIDE: 'click_order_from_guide',
	INTEGRATE_CHANNELS_FROM_GUIDE: 'integrate_channels_from_guide',
	CREATE_RIDERS_FROM_GUIDE: 'create_riders_from_guide',
	UNDELIVERED_ORDER_SETTING: 'undelivered_order_setting',
};
const ALLOWED_ORIGINS = ['https://dashboard.dev.pidge.in', 'https://dashboard.stg.pidge.in', 'https://dashboard.pidge.in'];

const constant = {
	env,
	ENVIRONMENT,
	BASE_URL: {
		DEFAULT: 'API_URL',
		STORE: 'API_STORE_URL',
		TRACE: 'API_TRACE_URL',
	},
	API_URL: '',
	API_STORE_URL: '',
	API_TRACE_URL: '',
	// API_URL: {
	// 	DEV: 'https://uat-api.pidge.in/',
	// 	PROD: 'https://dev-release-v1.pidge.in/',
	// },
	// API_STORE_URL: {
	// 	DEV: 'https://uat-store.pidge.in/',
	// 	PROD: 'https://api.pidge.in/',
	// },
	// API_TRACE_URL: {
	// 	DEV: 'https://uat-trace.pidge.in/',
	// 	PROD: 'https://trace-api.pidge.in/',
	// },
	CHAT_BOT_URL: 'https://pidge.limechat.ai/',
	// WEBSITE_TOKEN: ENVIRONMENT === env.PRODUCTION ? 'wLmJKe1CH6NaZQEkem4ygybh' : '2vdBAMFDQRC1zNfMa3DbsPQe',
	WEBSITE_TOKEN: '',
	// GOOGLE_MAP_KEY: ENVIRONMENT === env.PRODUCTION ? 'AIzaSyASjGKlFafGpHWa37_3MQcpsxaSFBFRSTU' : 'AIzaSyDHgbNwCshqEti3E1bDvPsIYB-27bWxYvA',
	GOOGLE_MAP_KEY: '',
	CONSOLE_LOG: true,
	ORDER_STATUS,
	ORDER_STATUS_TEXT: _.reduce(
		ORDER_STATUS,
		(map, status) => {
			map[status.id] = status.text;
			return map;
		},
		{},
	),
	TRIP_STATUS,
	TRIP_STATUS_TEXT: _.reduce(
		TRIP_STATUS,
		(map, status) => {
			map[status.id] = status.text;
			return map;
		},
		{},
	),
	ATTEMPT_TYPE,
	ATTEMPT_TYPE_TEXT: _.reduce(
		ATTEMPT_TYPE,
		(map, status) => {
			map[status.id] = status.text;
			return map;
		},
		{},
	),
	USER_TYPE,
	USER_TYPE_TEXT: _.reduce(
		USER_TYPE,
		(map, status) => {
			map[status.id] = status.text;
			return map;
		},
		{},
	),
	OWNER_TYPE,
	OWNER_TYPE_TEXT: _.reduce(
		OWNER_TYPE,
		(map, status) => {
			map[status.id] = status.text;
			return map;
		},
		{},
	),
	TRIP_TYPE,
	TRIP_TYPE_TEXT: _.reduce(
		TRIP_TYPE,
		(map, status) => {
			map[status.id] = status.text;
			return map;
		},
		{},
	),
	ADMIN_TRIP_TYPE,
	ADMIN_TRIP_TYPE_TEXT: _.reduce(
		ADMIN_TRIP_TYPE,
		(map, status) => {
			map[status.id] = status.text;
			return map;
		},
		{},
	),
	ORDER_TYPE: {
		APP_BASED: '1,2',
		WEB_BASED: '3,4',
	},
	REDUX_STATE: {
		IDLE: 'idle',
		SUCCEEDED: 'succeeded',
		FAILED: 'failed',
	},
	BTN_STATE,
	TASK_TYPES,
	AUTH_TOKEN: 'auth_token',
	TYPE_OF_OWNER: 'type_of_owner',
	OWNER_ID: 'owner_id',
	PARENT_ID: 'parent_id',
	NAME: 'name',
	MOBILE: 'mobile',
	USER_ID: 'user_id',
	USER_EMAIL: 'user_email',
	TYPE_OF_USER: 'user_type',
	LOGIN_USER_TYPE: 'login_user_type',
	SELECTED_BUSINESS: 'selected_business',
	DEFAULT_ZONE_ID: 6,
	MAP_DESIGN_ID: 'd4fa8af386e6edc8',
	// PAYTM_BASE_URL: ENVIRONMENT === env.PRODUCTION ? 'https://securegw.paytm.in' : 'https://securegw-stage.paytm.in',
	PAYTM_BASE_URL: '',
	COMM_CHANNEL,
	SOCKET_EVENTS,
	COMM_CHANNEL_NAME_MAP,
	INDIA_MAP_CENTER: { lat: Number(20.5937), lng: Number(78.9629) },
	PKG_VOLUME: {
		225: 'XSMALL',
		450: 'SMALL',
		900: 'MEDIUM',
		1800: 'LARGE',
		3600: 'XLARGE',
	},
	DELIVERY_DETAIL_MODAL_FILEDS,
	LOCAL_STORAGE_KEYS,
	SENTRY_DSN: 'https://704a45723761f636be10b38cf23eb106@o274231.ingest.sentry.io/4506551158308864',
	TRACE_FE_URL: '',
	GA4_KEY_PROD: 'G-ZR8GVZP9DD',
	GA4_KEY_DEV: 'G-4XEBQZMFG4',
	MIX_PANEL_KEY_DEV: 'd492fd594d4c3302c15ed1fbc3c1fc63',
	MIX_PANEL_KEY_PROD: 'a4e6fcc61c05bd932a697486c7a68188',
	TRACKING_EVENTS,
	ALLOWED_ORIGINS,
};

export default constant;
