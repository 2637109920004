import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import NetworkAvatar from 'modules/NetworkAvatar';
import NetworkTypeSearch from './NetworkTypeSearch';
import NetworkConfigAvatar from 'modules/NetworkConfigAvatar';

const useStyles = makeStyles(() => ({
	container: {
		position: 'absolute',
		top: 10,
		left: 10,
		height: '100%',
		width: 300,
		backgroundColor: theme.colors.white,
		borderRadius: 20,
		borderTopLeftRadius: 50,
		zIndex: 999,
		display: 'flex',
		flexDirection: 'column',
		padding: 16,
	},
	item_wrap: {
		borderRadius: 6,
		padding: 12,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		border: `1px solid ${theme.colors.lightPink9}`,
		cursor: 'pointer',
		marginBottom: 8,
	},
	pickup_name: {
		fontSize: 12,
		color: theme.colors.primary,
		textDecoration: 'underline',
	},
	separator: { fontSize: 12, marginLeft: 4, marginRight: 4, color: theme.colors.lightGrey5 },
}));

const NetworkRiderModal = ({ network_orders, close, fetch_network_riders, fetch_three_pl_riders }) => {
	const [data, set_data] = useState(network_orders);
	const [open_id, set_open_id] = useState(null);
	const classes = useStyles();

	const handle_filter_search = (search_by, text) => {
		if (_.isEmpty(text)) {
			set_data(network_orders);
			return;
		}
		const filtered = {};
		for (const key in network_orders) {
			if (
				network_orders[key].config.name.toLowerCase().includes(text.toLowerCase()) &&
				(search_by === 'all'
					? true
					: search_by === 'owned'
					? network_orders[key].is_owner
					: search_by === 'ppn'
					? !network_orders[key].is_owner && network_orders[key].config.implementation == 'ppn-private'
					: network_orders[key].config.implementation != 'ppn-private')
			) {
				filtered[key] = network_orders[key];
			}
		}

		set_data(filtered);
	};

	const handle_loc_click = async (e, item, id) => {
		e.stopPropagation();
		if (network_orders[id].config.implementation != 'ppn-private') {
			fetch_three_pl_riders(item);
		} else {
			const coords = _.get(item, 'orders.0.sender_detail', null);
			if (_.isEmpty(coords)) return;
			fetch_network_riders(coords, item);
		}
	};

	return (
		<div className={classes.container}>
			<div className='align-items-center justify-content-between'>
				<Text semi style={{ fontSize: 14 }}>
					Network Partners
				</Text>
				<img className='cursor-pointer' src={ImageLinks.cross_purple} width={16} height={16} style={{ marginTop: 2 }} onClick={close} />
			</div>
			<NetworkTypeSearch handle_filter_search={handle_filter_search} />

			{_.map(data, (network_item, network_index) => {
				return (
					<div
						key={`network_item_${network_index}`}
						onClick={() => set_open_id(network_index === open_id ? null : network_index)}
						className={classes.item_wrap}>
						<div className='d-flex justify-content-between'>
							<div className='align-items-center'>
								<NetworkConfigAvatar id={network_index} />
								{/* {network_item.config.implementation != 'ppn-private' ? (
									_.get(network_item, `config.avatar.image`, '') ? (
										<img src={network_item.config.avatar.image} width={60} height={20} />
									) : (
										<Text bold style={{ fontSize: 12, marginLeft: 8 }}>
											{_.get(network_item, `config.name`, [])}
										</Text>
									)
								) : (
									<NetworkAvatar configs={_.get(network_item, `config.avatar.configs`, [])} height={32} width={32} />
								)}
								<Text bold style={{ fontSize: 12, marginLeft: 8 }}>
									{_.get(network_item, `config.name`, [])} ({network_index})
								</Text> */}
							</div>
							<img src={ImageLinks.chevronLeft} style={{ transform: `rotate(${open_id === network_index ? 90 : 270}deg)` }} />
						</div>
						{open_id === network_index && (
							<div style={{ marginTop: 8, marginLeft: 4 }}>
								{_.map(network_item.list, (addr_item, addr_index) => (
									<div
										onClick={(e) => {
											handle_loc_click(e, addr_item, network_index);
										}}
										key={`addr_item_${addr_index}`}
										className='d-flex align-items-center'
										style={{ minHeight: 22, marginBottom: 8 }}>
										<img src={ImageLinks.box2_pink} width={12} height={12} style={{}} />
										<Text semi style={{ fontSize: 12, marginLeft: 2 }}>
											{addr_item.orders.length}
										</Text>
										<Text semi className={classes.separator}>
											|
										</Text>
										<Text semi className={classes.pickup_name}>
											{addr_item._id.pickup}
										</Text>
									</div>
								))}
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default NetworkRiderModal;
