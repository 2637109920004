import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Text from 'modules/Text';
import theme from 'resources/theme';
import moment from 'moment';
import ImageLinks from 'assets/images/ImageLinks';
import { DateRangePicker } from 'materialui-daterange-picker';
import { Divider, Grid } from '@material-ui/core';
import _ from 'lodash';
import DeliverySlotPerformance from './components/DeliverySlotPerformace';
import ExpectedDeliveryPerformance from './components/ExpectedDeliveryPerformance';
import TrackingLinkPerformance from './components/TrackingLinkPerformance';
import DeliveryFeedback from './components/DeliveryFeedback';
import { Link } from 'react-router-dom';
import Routes from 'resources/Routes';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
	container: {
		padding: '32px 40px 32px 30px',
	},
	delivery_content: {
		display: 'inline-block',
		background: theme.colors.lightPurple9,
		color: theme.colors.primary,
		padding: '4px 8px',
		borderRadius: '16px',
		marginLeft: 4,
	},
	date_text: { color: theme.colors.primary, fontSize: 16 },
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
});

const AdvancedOrderAnalytics = ({ history }) => {
	const classes = useStyles();
	const [get_started_clicked, set_get_started_clicked] = useState(false);
	const [value, set_value] = useState(30);
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [filters, set_filters] = useState({
		from: moment(moment().subtract('6', 'days').format('MM-DD-YYYY')),
		to: moment(moment().format('MM-DD-YYYY')),
	});

	const [data, set_data] = useState(null);

	const days_diff = filters.to.diff(filters.from, 'days');

	useEffect(() => {
		if (days_diff <= 6) {
			get_order_analytics_data();
		} else {
			toast.error('You cannot select date more than 7 days', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		}
	}, [filters, days_diff]);

	const get_order_analytics_data = async () => {
		try {
			const res = await api_requests.get_advanced_order_analytics({
				created_at: `${filters.from.format('YYYY-MM-DD')},${filters.to.format('YYYY-MM-DD')}`,
			});
			if (!_.isEmpty(res.data)) {
				set_data(res.data);
			}
		} catch (err) {}
	};

	return (
		<div className={classes.container}>
			<div className='align-items-center'>
				<Link to={Routes.ALL_ORDERS.path} style={{ color: 'inherit', textDecoration: 'unset' }}>
					<Text medium className='cursor-pointer' style={{ fontSize: 22 }}>
						All Orders
					</Text>
				</Link>
				<div style={{ paddingLeft: 8, paddingRight: 8, fontWeight: 'bold' }}>|</div>
				<Text component={'span'} bold style={{ fontSize: 22, borderBottom: `2px solid ${theme.colors.lightPurple6}` }}>
					Advanced Order Analytics
				</Text>
			</div>
			<div className='justify-content-between align-items-center'>
				<div className='align-items-center' style={{ paddingTop: 16 }}>
					<Text bold style={{ fontSize: 16 }}>
						{filters.to.diff(filters.from, 'days') + 1} Days Overview <span style={{ fontWeight: 'normal', fontSize: 16 }}>as per</span>
					</Text>
					<div className={classes.delivery_content}>
						<Text bold style={{ fontSize: 14 }}>
							Delivery Date
						</Text>
					</div>
				</div>
				<div>
					<div style={{ position: 'relative' }}>
						<div className='d-flex' style={{ gap: 10 }}>
							<div>
								<Text bold className={classes.date_text}>{`${filters.from.format('DD MMM `YY')} - ${filters.to.format('DD MMM `YY')}`}</Text>
							</div>
							<img
								src={ImageLinks.calendar_solid_circle}
								alt='date'
								className='cursor-pointer'
								width={22}
								height={22}
								onClick={() => set_date_picker_open(true)}
							/>
						</div>
						<div style={{ position: 'absolute', right: 0, width: 700 }}>
							<DateRangePicker
								open={date_picker_open}
								toggle={() => set_date_picker_open((prev) => !prev)}
								style={{ width: 200 }}
								initialDateRange={{
									startDate: filters.from,
									endDate: filters.to,
								}}
								maxDate={new Date()}
								onChange={(range) => {
									const newDateRange = {
										from: moment(range.startDate),
										to: moment(range.endDate),
									};
									set_filters(newDateRange);
									set_date_picker_open(!date_picker_open);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<Divider className={classes.divider} />
			<Grid container spacing={3} style={{ marginTop: 8 }}>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<TrackingLinkPerformance tracking_data={_.get(data, 'stats.tracking', null)} />
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<DeliveryFeedback feedback={_.get(data, 'stats.feedback', null)} />
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<DeliverySlotPerformance slot_performance={_.get(data, 'stats.slot_performance', null)} />
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<ExpectedDeliveryPerformance delivery_performance={_.get(data, 'stats.date_performance', null)} />
				</Grid>
			</Grid>
			<Text style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 8 }}>
				<Text medium component={'span'} style={{ fontSize: 12 }}>
					Note:{' '}
				</Text>
				Orders without delivery date and/or delivery slot are not included for analysis
			</Text>
		</div>
	);
};
export default AdvancedOrderAnalytics;
