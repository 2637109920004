import React, { useEffect } from 'react';

import CreateOrderSection from './components/CreateOrderSection/CreateOrderSection';
import useCreateOrder from './useCreateOrder';
import CreateOrderContext from './context';
import Header from './components/Header';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	container: {
		height: '100%',
	},
}));

const CreateOrder = ({ set_key }) => {
	const value = {
		...useCreateOrder(),
		set_key,
	};

	const { zone_id } = value;

	const classes = useStyles();

	useEffect(() => {
		window.onbeforeunload = function () {
			return true;
		};

		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	return (
		<CreateOrderContext.Provider value={value}>
			<div className={`container ${classes.container}`}>
				<Header />
				<CreateOrderSection
				// key={zone_id}
				/>
			</div>
		</CreateOrderContext.Provider>
	);
};

const CreateOrderCont = () => {
	const [key, set_key] = React.useState(_.uniqueId());

	return <CreateOrder set_key={set_key} key={'create_order' + key} />;
};

export default CreateOrderCont;
