import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Select from 'modules/Select';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import { useStyles } from './styles';
import SubjectItem from './components/SubjectItem';
import SectionHeading from './components/SectionHeading';
import { subject_options } from '../helper';
import ActiveInactiveDropdown from 'modules/ActiveInactiveDropdown';
import OutlinedTextField from 'modules/OutlinedTextField';
import ConfigureAction from './components/ConfigureAction';
import Switch from 'modules/Switch';
import ConfirmClose from 'modules/modals/ConfirmClose';
import useCreateAlert from './useCreateAlert';
import clsx from 'clsx';
import UseCase from './components/UseCase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Button from 'modules/Button';

const today_date = moment().format('YYYY-MM-DD');

const CreateAlert = () => {
	const {
		selected_subject,
		usecases,
		alert_detail,
		selected_usecase,
		show_usecase,
		selected_trigger,
		is_active,
		is_deleted,
		alert_frequency,
		confirm_close_modal,
		alias,
		teams,
		alert_id,
		is_saved_press,
		timer,
		is_alias_valid,
		is_updated,
		set_selected_trigger,
		set_show_usecase,
		set_alert_detail,
		on_usecase_select,
		on_subject_select,
		handle_action_select,
		create_alert,
		close_confirm_modal,
		handle_status_change,
		handle_action_toggle,
		on_saved_tab_clicked,
		navigate_to_comm,
		fetch_alises,
		handle_back,
		handle_alert_freq,
		handle_action_team_change,
	} = useCreateAlert();
	const classes = useStyles();
	const history = useHistory();
	const query_object = qs.parse(history.location.search.slice(1));

	useEffect(() => {
		if (query_object?.key) {
			on_subject_select(query_object?.key);
		}
	}, [history]);

	const btn_disabled = useMemo(() => {
		let value = true;
		let is_any_action_active = false;
		const actions = alert_detail?.actions || [];
		for (let i = 0; i < actions.length; i++) {
			const action = actions[i];
			if (action.active) {
				is_any_action_active = true;
				break;
			}
		}

		let all_triggers_filled = true;
		let input_triggers_exists = false;

		const input_triggers = _.get(alert_detail, 'triggers', []);
		for (let i = 0; i < input_triggers.length; i++) {
			const input = input_triggers[i];
			if (input.type != 'label') input_triggers_exists = true;
			if (input.type != 'label' && !selected_trigger[input.param]) {
				all_triggers_filled = false;
				break;
			}
		}

		let is_trigger_valid = input_triggers_exists ? !_.isEmpty(selected_trigger) && all_triggers_filled : true;

		if (alert_id) {
			if (is_trigger_valid && alert_frequency != '' && is_alias_valid && !_.isEmpty(alias) && is_updated) return false;
			return true;
		}

		if (is_trigger_valid && alert_frequency != '' && is_alias_valid && !_.isEmpty(alias) && (alert_detail?.communication.enabled || is_any_action_active))
			value = false;
		return value;
	}, [selected_trigger, alert_frequency, alias, is_alias_valid, alert_detail, alert_id, is_updated]);

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<div className='align-items-center'>
					<div className='page_back_btn' onClick={handle_back}>
						<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
					</div>
					<Text className='cursor-pointer' style={{ fontSize: 22 }} onClick={handle_back}>
						Alerts
					</Text>
					<div className='header_separator'></div>
					<Text semi className='header_active_title'>
						{alert_id ? 'Edit Alert' : 'Create Alert'}
					</Text>
				</div>
				{!_.isEmpty(alert_id) && (
					<ActiveInactiveDropdown
						is_active={is_active}
						set_is_active={handle_status_change}
						inactive_text={'Inactive'}
						show_delete
						is_deleted={is_deleted}
					/>
				)}
			</div>

			<div className={classes.content} style={{ overflow: 'auto', height: '85vh' }}>
				<div className='d-flex'>
					<div style={{ flex: 0.5 }}>
						<div>
							<SectionHeading title={'Select Subject*'} description={'Select the entity highlighted in the alert'} />

							<div className={classes.subject_wrap}>
								{_.map(subject_options, (subject, key) => (
									<SubjectItem
										key={`subject_${key}`}
										title={subject.title}
										icon={subject.icon}
										is_selected={subject.key === selected_subject}
										is_disabled={alert_id != null || subject.locked}
										onClick={() => {
											on_subject_select(subject.key);
										}}
									/>
								))}
							</div>
							{selected_subject !== null && (
								<div style={{ width: 570 }}>
									<SectionHeading title={'Select Usecase*'} description={'Select the scenario addressed in the alert'} />
									<UseCase
										is_edit_mode={!_.isEmpty(alert_id)}
										selected_usecase={selected_usecase}
										show_usecase={show_usecase}
										usecases={usecases}
										on_usecase_select={on_usecase_select}
										set_show_usecase={set_show_usecase}
										onClick={() => {
											if (alert_id) return;
											set_show_usecase((prev) => !prev);
										}}
									/>
								</div>
							)}
						</div>
					</div>

					<div style={{ flex: 0.5 }}>
						{selected_usecase !== null && (
							<div style={{ marginLeft: 30, marginRight: 30 }}>
								<SectionHeading
									title={'Set Alias*'}
									description={'Identify multiple alerts of same kind easily by setting unique alert names (only 30 characters)'}
								/>
								<div className='align-items-center' style={{ marginTop: 10 }}>
									<OutlinedTextField
										disabled={!_.isEmpty(alert_id)}
										value={alias}
										name='trigger_alias'
										textStyle={{ fontSize: 16, color: _.isEmpty(alert_id) ? theme.colors.darkGrey2 : theme.colors.primary }}
										onChange={(e) => fetch_alises(e.target.value)}
										placeholder='Alert Name..'
										style={{
											width: 235,
											backgroundColor: _.isEmpty(alert_id) ? theme.colors.white : theme.colors.lightPink4,
											border: `1px solid ${_.isEmpty(alert_id) ? theme.colors.black : theme.colors.lightPurple}`,
											borderRadius: 4,
										}}
									/>
									{_.isEmpty(alert_id) && !_.isEmpty(alias) && typeof is_alias_valid === 'boolean' && (
										<div className='align-items-center' style={{ marginLeft: 10 }}>
											<img src={is_alias_valid ? ImageLinks.check_green : ImageLinks.crossRed} width={16} height={16} />
											<Text semi style={{ fontSize: 14, marginLeft: 4 }}>
												{is_alias_valid ? 'Alias Available' : 'Alias Unavailable'}
											</Text>
										</div>
									)}
								</div>
								{_.isEmpty(alert_id) && (
									<div className='align-items-center' style={{ marginTop: 10 }}>
										<img src={ImageLinks.exclamation_purple} width={12} height={12} />
										<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginLeft: 3 }}>
											Once set, alias name cannot be edited in the future.
										</Text>
									</div>
								)}
							</div>
						)}
					</div>
				</div>

				<div className={classes.separator}></div>

				<div className='d-flex'>
					<div style={{ flex: 0.5 }}>
						<div style={{ marginRight: 200 }}>
							{!_.isEmpty(alert_detail) && (
								<div style={{ marginBottom: 40 }}>
									<SectionHeading
										title={'Trigger Conditions*'}
										description={
											<>
												<Text medium style={{ fontSize: 12 }}>
													Define the parameters to activate the alert.
												</Text>
												<Text bold style={{ fontSize: 12 }}>
													Please note:
													<Text medium component={'span'} style={{ fontSize: 12 }}>
														{' '}
														In case of multiple similar alerts, the most critical one will be used
													</Text>
												</Text>
											</>
										}
									/>

									<div className={classes.trigger_action_wrap}>
										{_.map(alert_detail.triggers, (trigger, key) =>
											trigger.type === 'label' ? (
												<div style={{ display: 'inline-flex', marginTop: 5 }}>
													<Text medium style={{ fontSize: 16 }}>
														{trigger.title}
													</Text>
												</div>
											) : trigger.type === 'text' ? (
												<div style={{ display: 'inline-flex', marginTop: 5 }}>
													<OutlinedTextField
														label={trigger.title}
														value={_.get(selected_trigger, `${trigger.param}`, '')}
														onChange={(e) => {
															set_selected_trigger({
																...selected_trigger,
																[trigger.param]:
																	trigger.validation === 'number'
																		? _.isNaN(Number(e.target.value))
																			? e.target.value // Value is saved as it is becuase on button click we already have added check and are showing error message
																			: Number(e.target.value)
																		: e.target.value,
															});
														}}
														style={{ marginLeft: 7, marginBottom: 15, marginRight: 7 }}
													/>
												</div>
											) : trigger.type === 'dropdown' ? (
												<div style={{ display: 'inline-flex', marginTop: 5 }}>
													<Select
														value={_.get(selected_trigger, `${trigger.param}`, trigger.multi ? [] : null)}
														defaultOption={{
															title: trigger.title,
															value: null,
														}}
														options={trigger.values}
														handleChange={(v) => handle_action_select(trigger.param, v, trigger.multi)}
														containerStyles={{
															width: 130,
															height: 36,
															border: `1px solid ${theme.colors.lightPurple}`,
															marginLeft: 7,
															marginRight: 7,
														}}
														text_props={{ medium: true }}
														selected_title_styles={{ color: theme.colors.primary }}
														menu_styles={{ width: 130 }}
														multiple={trigger.multi}
													/>
												</div>
											) : trigger.type === 'time' ? (
												<div style={{ display: 'inline-flex', marginTop: 5 }}>
													<DatePicker
														selected={
															_.isEmpty(_.get(selected_trigger, `${trigger.param}`, ''))
																? ''
																: new Date(moment.utc(`${today_date} ${selected_trigger[trigger.param]}`).local())
														}
														onChange={(date) => {
															set_selected_trigger({
																...selected_trigger,
																[trigger.param]: moment.utc(date).format(trigger.format),
															});
														}}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={15}
														timeCaption='Time'
														dateFormat='hh:mm aa'
														customInput={
															<OutlinedTextField
																label={trigger.title}
																style={{ color: theme.colors.black, opacity: 1, width: '100%' }}
																className={classes.inputs}
																onChange={(e) => {}}
																value={_.get(selected_trigger, `${trigger.param}`, '')}
															/>
														}
													/>
												</div>
											) : (
												<></>
											),
										)}
									</div>
								</div>
							)}
							{!_.isEmpty(alert_detail) && (
								<div>
									<SectionHeading title={'Configure Alert Frequency*'} description={'Set time gap for repeat alert notification'} />

									<div className={classes.action_wrap}>
										<Text medium style={{ fontSize: 16 }}>
											Maximum 1 alert every
										</Text>
										<OutlinedTextField
											placeholder={'1..'}
											value={alert_frequency}
											onChange={handle_alert_freq}
											style={{ marginLeft: 7, marginRight: 7, width: 70 }}
										/>
										<Text medium style={{ fontSize: 16 }}>
											minutes
										</Text>
									</div>
									{!_.isEmpty(alert_id) && (
										<div className='align-items-center' style={{ marginTop: 10 }}>
											<img src={ImageLinks.exclamation_purple} width={12} height={12} />
											<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginLeft: 3 }}>
												New frequency will reflect after completion of old frequency
											</Text>
										</div>
									)}
								</div>
							)}

							{!_.isEmpty(alert_detail) && (
								<>
									<div className={classes.notif_toggle_wrap}>
										<div>
											<SectionHeading
												title={'Default Notification Setup'}
												description={'Activate to receive notifications for this alert'}
											/>
										</div>
										<Switch
											checked={alert_detail.communication.enabled}
											onChange={() => {
												const copied_alert = _.cloneDeep(alert_detail);
												const old_value = copied_alert.communication.enabled;
												copied_alert.communication.enabled = !old_value;
												set_alert_detail(copied_alert);
											}}
										/>
									</div>
									{alert_detail.communication.enabled &&
										(_.isEmpty(alert_id) ? (
											<Text bold style={{ fontSize: 12, color: theme.colors.primary, marginTop: 12 }}>
												Please note:
												<Text component={'span'} style={{ fontSize: 12 }}>
													{' '}
													Save alert to setup notification settings.
												</Text>
											</Text>
										) : (
											<Text medium onClick={navigate_to_comm} className={clsx(classes.edit_comm_text, 'cursor-pointer')}>
												Edit in Communications
											</Text>
										))}
								</>
							)}
						</div>
					</div>

					<div style={{ flex: 0.5 }}>
						{!_.isEmpty(alert_detail) && _.get(alert_detail, 'actions', []).length > 0 && (
							<div style={{ marginLeft: 30, marginRight: 30 }}>
								<SectionHeading title={'Configure Action'} description={'Define what action needs to be taken against the alert'} />

								{_.map(alert_detail.actions, (item, key) => (
									<ConfigureAction
										key={`alert_action_${key}`}
										item={item}
										teams={teams}
										handle_action_toggle={() => handle_action_toggle(key)}
										is_last_item={alert_detail.actions.length - 1 === key}
										handle_action_team_change={(type, team) => {
											handle_action_team_change(type, team, key);
										}}
									/>
								))}
							</div>
						)}
					</div>
				</div>
				<div className={classes.footer_wrap}>
					{is_saved_press ? (
						<div className='align-items-center'>
							<div className='align-items-center' style={{ marginRight: 28 }}>
								<img width={16} height={16} src={ImageLinks.tickCircleSolid} />
								<Text semi className={classes.saved_btn_text}>
									Saved
								</Text>
							</div>
							<Text semi style={{ fontSize: 18 }}>
								{`Redirecting to `}
								<Text onClick={on_saved_tab_clicked} component={'span'} semi className={classes.saved_link_text}>
									{`${alert_detail.communication.enabled ? 'Communication' : 'Alerts'} `}
								</Text>
								in {timer}..
							</Text>
						</div>
					) : (
						<Button
							type='round'
							size='large'
							text={_.isEmpty(alert_id) ? 'Save Alert' : 'Update'}
							onClick={create_alert}
							disabled={btn_disabled}
							style={{ width: 110 }}
						/>
					)}
				</div>
				{/* It will be added later */}
				{/* <div style={{ flex: 0.6, marginLeft: 16 }}>{selected_trigger && <AlertPreview />}</div> */}
			</div>
			{confirm_close_modal.open && (
				<ConfirmClose
					headerTitle={confirm_close_modal.header_title}
					title={confirm_close_modal.title}
					close={close_confirm_modal}
					positive_btn_text={confirm_close_modal.positive_text}
					negative_btn_text={confirm_close_modal.negative_text}
					titleStyles={{ fontSize: 16, textAlign: 'left' }}
					on_confirm={confirm_close_modal.confirm_acion}
					is_error_msg={confirm_close_modal.is_error_msg}
					modal_icon={confirm_close_modal.modal_icon}
				/>
			)}
		</div>
	);
};

export default CreateAlert;
