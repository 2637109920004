import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useContext, useState } from 'react';
import theme from 'resources/theme';

import OutlinedTextField from 'modules/OutlinedTextField';
import CreateNetworkContext from '../../context';

import utils from 'resources/utils';

import SectionHeader from 'views/NetworksFlow/CreateNetwork/components/SectionHeader';
import AddRoleModal from './AddRoleModal';
import { UPPER_SECTION_INVITE_STEPS, UPPER_SECTION_INVITE_STEPS_2 } from 'views/NetworksFlow/CreateNetwork/helper';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	wrapper: { display: 'flex', justifyContent: 'space-between' },
	char_count_wrap: { position: 'absolute', right: 8, bottom: 8 },
	biz_search_select_section: {
		display: 'flex',
		flex: 0.5,
	},

	display_section: {
		display: 'flex',
		flex: 0.5,
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	proceed_btn_wrap: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},
	steps_wrap: {
		borderRadius: 4,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		padding: 10,
		flex: 1,
		width: 300,
	},
	steps_counter_wrap: {
		width: 18,
		height: 18,
		borderRadius: 9,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 4,
	},
	steps_item_wrap: {
		display: 'flex',
		marginBottom: 16,
		alignItems: 'center',
	},
	steps_desc: { fontSize: 8, fontSize: 10, color: theme.colors.primary, marginBottom: 12 },
	searched_wrrapper: {
		boxShadow: `0px 4px 4px rgba(202, 202, 202, 0.25)`,
		width: 235,
		position: 'absolute',
		top: 60,
		backgroundColor: theme.colors.white,
	},
	search_biz_item: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: 12,
		paddingRight: 12,
		padding: 12,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		minHeight: 41,
		cursor: 'pointer',
	},
	separator: {
		border: `1px solid ${theme.colors.lightGrey2}`,
		marginLeft: 20,
		marginRight: 40,
	},
	biz_info_wrap: {
		border: `1px solid ${theme.colors.lightPurple}`,
		backgroundColor: theme.colors.white,
		borderRadius: 8,
		width: 300,
		height: 130,
		display: 'flex',
		flexDirection: 'column',
	},
	biz_img_wrap: {
		width: 56,
		height: 56,
		borderRadius: 28,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 12.1081px rgba(102, 102, 102, 0.1)`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	biz_id_text: {
		fontSize: 12,
		color: theme.colors.darkGrey2,
		marginTop: 4,
	},
	roles_wrap: { background: theme.colors.lightPurple9, display: 'flex', justifyContent: 'space-between', flex: 0.4, paddingLeft: 12, paddingRight: 12 },
	roles_count: {
		width: 22,
		height: 22,
		borderRadius: 4,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 2,
	},
	add_role_text: { fontSize: 12, color: theme.colors.primary, textDecoration: 'underline', marginLeft: 6 },
}));

const InviteParticipantUpperSection = () => {
	const {
		invite_code,
		handle_biz_select,
		handle_invite_code,
		searched_biz,
		selected_biz,
		invite_roles_count,
		handle_add_participant,
		is_business_searched,
		show_lower_section,
		is_participant_added,
		handle_remove_access,
		query_object,
		is_invite_allocation_updated,
		is_invite_pricing_updated,
		is_invite_service_updated,
		is_invite_support_updated,
		is_invite_roles_updated,
		handle_add_participant_role,
		is_invite_surge_price,
		is_invite_cancellation_price,
		is_invite_reattempt_config,
	} = useContext(CreateNetworkContext);
	const classes = useStyles();

	const [show_role_modal, set_show_role_modal] = useState(false);
	const [show_confirm_revoke, set_show_confirm_revoke] = useState(false);

	return (
		<div className={classes.wrapper}>
			<div className={classes.biz_search_select_section}>
				<div>
					<SectionHeader title={'Participant Detail'} description={'Invite businesses to become a part of the network'} />
					<div style={{ marginTop: 20, position: 'relative' }}>
						<OutlinedTextField
							type='text'
							value={invite_code}
							textStyle={{ fontSize: 16, color: theme.colors.black }}
							name='invite_code'
							InputProps={{
								endAdornment:
									(!_.isEmpty(selected_biz) || !is_business_searched) && searched_biz.length != 0 ? (
										<img
											width={18}
											height={18}
											src={!_.isEmpty(selected_biz) ? ImageLinks.tickCircleSolid : ImageLinks.exclamation_error}
										/>
									) : null,
							}}
							disabled={!_.isEmpty(selected_biz)}
							error={!is_business_searched && invite_code != ''}
							onChange={handle_invite_code}
							label='Invite Code*'
							style={{ width: 235, opacity: _.isEmpty(selected_biz) ? 1 : 0.4 }}
							helperText={is_business_searched && invite_code != '' ? '' : 'Invite Code does not exist'}
						/>
						{searched_biz.length > 0 && (
							<div className={classes.searched_wrrapper}>
								{_.map(searched_biz, (biz_item, biz_index) => (
									<div onClick={() => handle_biz_select(biz_item)} key={`search_biz_${biz_index}`} className={classes.search_biz_item}>
										<Text medium style={{ fontSize: 14, flex: 0.85 }}>
											{biz_item.brand_name}
										</Text>
										<Text medium style={{ fontSize: 14, flex: 0.15 }}>
											{biz_item.id}
										</Text>
									</div>
								))}
							</div>
						)}
					</div>
					{is_business_searched ||
						(invite_code == '' && (
							<div className='align-items-center' style={{ marginLeft: 12, marginTop: 4 }}>
								<img src={ImageLinks.exclamation_purple} width={14} height={14} style={{ marginRight: 6 }} />
								<Text medium style={{ fontSize: 12, color: theme.colors.black }}>
									Ask business for their unique code
								</Text>
							</div>
						))}
				</div>

				{!_.isEmpty(selected_biz) && <div className={classes.separator}></div>}

				{!_.isEmpty(selected_biz) && (
					<div className={classes.biz_info_wrap}>
						<div className='align-items-center' style={{ padding: 12, flex: 0.6 }}>
							<div style={{ marginLeft: 10 }}>
								<Text medium style={{ fontSize: 16 }}>
									{selected_biz?.brand_name}
								</Text>
								<Text semi className={classes.biz_id_text}>
									{selected_biz?.id}
								</Text>
							</div>
						</div>

						<div className={classes.roles_wrap}>
							<div className='align-items-center'>
								<Text semi style={{ fontSize: 12, color: theme.colors.lightPurple12 }}>
									Roles:
								</Text>
								<div className={classes.roles_count}>
									<Text semi style={{ fontSize: 12, color: theme.colors.lightPurple12 }}>
										{invite_roles_count}
									</Text>
								</div>
							</div>

							<div className='align-items-center' style={{ cursor: 'pointer' }}>
								<Button
									type='text'
									size='extra_small'
									text={invite_roles_count > 0 ? 'Manage' : 'Add Role'}
									onClick={() => set_show_role_modal(true)}
									left_icon={invite_roles_count == 0 && ImageLinks.add_pruple_no_fill}
								/>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className={classes.display_section}>
				<div className={classes.steps_wrap}>
					<Text bold style={{ fontSize: 14, color: theme.colors.primary }}>
						Invite Participant
					</Text>
					<Text medium className={classes.steps_desc}>
						Complete the following steps to set participant rules
					</Text>

					{_.map(show_lower_section ? UPPER_SECTION_INVITE_STEPS_2 : UPPER_SECTION_INVITE_STEPS, (item, index) => (
						<div key={`steps_item_${index}`} className={classes.steps_item_wrap}>
							<div
								className={classes.steps_counter_wrap}
								style={{
									backgroundColor:
										index === 0
											? !_.isEmpty(selected_biz)
												? theme.colors.primary
												: theme.colors.lightPurple
											: index === 1
											? invite_roles_count != 0
												? theme.colors.primary
												: theme.colors.lightPurple
											: index === 2
											? invite_roles_count != 0
												? theme.colors.primary
												: theme.colors.lightPurple
											: theme.colors.lightPurple,
								}}>
								<Text
									bold
									style={{
										fontSize: 12,
										color:
											index === 0
												? !_.isEmpty(selected_biz)
													? theme.colors.white
													: theme.colors.lightPurple7
												: index === 1
												? invite_roles_count != 0
													? theme.colors.white
													: theme.colors.lightPurple7
												: index === 2
												? invite_roles_count != 0
													? theme.colors.white
													: theme.colors.lightPurple7
												: theme.colors.lightPurple7,
									}}>
									{index + 1}
								</Text>
							</div>
							{item.type === 'label' && (
								<Text medium style={{ fontSize: 12, color: theme.colors.primary }}>
									{item.title}
								</Text>
							)}
							{item.type === 'button' && (
								<Button
									type='round'
									size='extra_small'
									text={is_participant_added ? 'Update' : item.title}
									onClick={show_lower_section ? handle_add_participant : handle_add_participant_role}
									disabled={
										_.isEmpty(selected_biz) ||
										invite_roles_count == 0 ||
										(is_participant_added &&
											!(
												is_invite_allocation_updated ||
												is_invite_pricing_updated ||
												is_invite_service_updated ||
												is_invite_support_updated ||
												is_invite_roles_updated ||
												is_invite_surge_price ||
												is_invite_cancellation_price ||
												is_invite_reattempt_config
											))
									}
								/>
							)}
						</div>
					))}
				</div>
			</div>
			{show_role_modal && (
				<AddRoleModal
					close={() => set_show_role_modal(false)}
					show_remove_access={query_object.brand_name}
					on_revoke_click={() => {
						set_show_confirm_revoke(true);
						set_show_role_modal(false);
					}}
				/>
			)}
			{show_confirm_revoke && (
				<ConfirmClose
					is_error_msg={true}
					headerTitle='Remove Participant for Network'
					title={
						<Text medium>
							Are you sure you want to remove{' '}
							<Text bold component={'span'}>
								{query_object.brand_name} {query_object.business_id}
							</Text>{' '}
							from{' '}
							<Text bold component={'span'}>
								{query_object.network_name} ({query_object.network_id})
							</Text>
							? All configs will be removed and will have to be set again.
							<br />
							<br /> Please note: Past data with participant will not change
						</Text>
					}
					close_on_confirm={false}
					positive_btn_text='Remove'
					show_negative_btn={true}
					negative_btn_text='Cancel'
					on_confirm={handle_remove_access}
					handle_negative_action={() => set_show_confirm_revoke(false)}
					close={() => set_show_confirm_revoke(false)}
				/>
			)}
		</div>
	);
};

export default InviteParticipantUpperSection;
