import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import theme from 'resources/theme';
import PreviewDisabled from './PreviewDisabled';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.colors.lightPurple10,
		borderRadius: 20,
		height: '75vh',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		display: 'flex',
		position: 'relative',
	},
	active_dot: {
		height: 12,
		width: 12,
		borderRadius: 6,
		border: `1px solid ${theme.colors.lightPurple6}`,
		backgroundColor: theme.colors.primary,
	},
	inactive_dot: {
		height: 12,
		width: 12,
		borderRadius: 6,
		border: `1px solid ${theme.colors.lightPurple6}`,
	},
	bottom_nav_wrap: {
		position: 'absolute',
		bottom: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		flexDirection: 'column',
		gap: 10,
	},
	desktop_wrap: {
		width: '90%',
		backgroundColor: theme.colors.white,
	},
	mobile_wrap: {
		height: '50vh',
		width: '60%',
		overflowY: 'scroll',
		backgroundColor: theme.colors.white,
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
}));

function EndUserPreview({ is_banner, logo_image, banner_color, banner_content, carousel_images, is_editable, banner_text_color }) {
	const { business_accounts, selected_business_id: business_id_information } = useSelector(({ app }) => app);
	const selected_business_information = business_accounts.filter((business_account_info) => business_account_info.id === business_id_information)[0];

	const classes = useStyles();
	const [view, set_view] = useState('Desktop');
	const images = _.filter(carousel_images, (item) => {
		return item.image != '';
	});

	return (
		<div className={classes.container}>
			{view == 'Desktop' && (
				<div className={classes.desktop_wrap}>
					{is_banner == '1' && (
						<>
							<div style={{ backgroundColor: banner_color, height: 18 }}>
								{banner_content.length < 230 && (
									<h5 style={{ margin: 0, color: banner_text_color, fontSize: 10, textAlign: 'center' }}>{banner_content}</h5>
								)}
								{banner_content.length >= 230 && (
									<marquee scrollAmount={4}>
										<h5 style={{ margin: 0, color: banner_text_color, fontSize: 10 }}>{banner_content}</h5>
									</marquee>
								)}
							</div>
						</>
					)}
					<div style={{ padding: '10px 20px' }}>
						<div>{logo_image && <img src={logo_image} style={{ width: '20%', height: 'auto' }} />}</div>
						<div>
							{!logo_image && (
								<Text semi style={{ fontSize: 12 }}>
									{selected_business_information.brand_name}
								</Text>
							)}
						</div>
						<div className='d-flex'>
							<div className='d-flex' style={{ flexDirection: 'column', flex: 1 }}>
								<Text bold style={{ fontSize: 13, color: theme.colors.primary }}>
									Dispatched
								</Text>
								<Text semi style={{ fontSize: 5, color: theme.colors.darkGrey2 }}>
									Order status
								</Text>
								<div className='d-flex'>
									<div style={{ flex: 1 }}>
										<img src={images.length > 0 ? ImageLinks.partner_preview_desktop_small : ImageLinks.partner_preview_desktop_long} />
									</div>
									{images.length > 0 && (
										<div style={{ flex: 1 }}>
											<img src={images[0].image} width={'100%'} height={'auto'} />
										</div>
									)}
								</div>
								<img src={ImageLinks.delivery_preview_desktop} />
								<img src={ImageLinks.experience_preview_desktop} />
							</div>
							<div style={{ flex: 1 }}>
								<div className='d-flex align-items-center' style={{ justifyContent: 'flex-end', gap: 2 }}>
									<Text medium style={{ fontSize: 5 }}>
										Powered by
									</Text>
									<img src={ImageLinks.logo} style={{ height: 5 }} />
								</div>
								<img src={ImageLinks.map_preview_desktop} width={'100%'} height={'auto'} />
							</div>
						</div>
					</div>
				</div>
			)}
			{view == 'Mobile' && (
				<div className={classes.mobile_wrap}>
					{is_banner == '1' && (
						<>
							<div style={{ backgroundColor: banner_color, height: 18 }}>
								{banner_content.length < 230 && (
									<h5 style={{ margin: 0, color: banner_text_color, fontSize: 10, textAlign: 'center' }}>{banner_content}</h5>
								)}
								{banner_content.length >= 230 && (
									<marquee scrollAmount={4}>
										<h5 style={{ margin: 0, color: banner_text_color, fontSize: 10 }}>{banner_content}</h5>
									</marquee>
								)}
							</div>
						</>
					)}
					<div style={{ padding: '10px 10px' }}>
						<div>{logo_image && <img src={logo_image} style={{ width: '40%', height: 'auto' }} />}</div>
						<div>
							{!logo_image && (
								<Text semi style={{ fontSize: 12 }}>
									{selected_business_information.brand_name}
								</Text>
							)}
						</div>
						<img src={ImageLinks.map_preview_desktop} width={'100%'} height={'auto'} />
						<Text bold style={{ color: theme.colors.primary, fontSize: 22 }}>
							Dispatched
						</Text>
						<img src={ImageLinks.partner_preview_desktop_small} width={'100%'} height={'auto'} />
						{images.length > 0 && (
							<div style={{ flex: 1 }}>
								<img src={images[0].image} width={'100%'} height={'auto'} />
							</div>
						)}
						<img src={ImageLinks.preview_tracking_mobile} width={'100%'} height={'auto'} />
					</div>
				</div>
			)}
			<div className={classes.bottom_nav_wrap}>
				<Text semi style={{ color: theme.colors.lightPurple7 }}>{`${view} View`}</Text>
				<div className='d-flex' style={{ gap: 10 }}>
					<div className={view == 'Desktop' ? classes.active_dot : classes.inactive_dot} onClick={() => set_view('Desktop')} />
					<div className={view == 'Mobile' ? classes.active_dot : classes.inactive_dot} onClick={() => set_view('Mobile')} />
				</div>
			</div>
			{!is_editable && <PreviewDisabled />}
		</div>
	);
}

export default EndUserPreview;
