import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { DateRangePicker } from 'materialui-daterange-picker';
import Text from 'modules/Text';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import api_requests from 'resources/api_requests';
import ChannelMix from './components/ChannelMix';
import DeliveryPartner from './components/DeliveryPartner';
import OrderZones from './components/OrderZones';
import Overview from './components/Overview';
import Revenue from './components/Revenue';
import TotalOrders from './components/TotalOrders';
import ImageLinks from 'assets/images/ImageLinks';
import utils from 'resources/utils';
import FirstTimeOverlay from 'modules/FirstTimeOverlay';
import theme from 'resources/theme';
import Button from 'modules/Button';
import constant from 'resources/constant';
import NotificationService from 'resources/NotificationService';

const dropdown_menu = {
	created_at: { value: 'created_at', label: 'Creation Date' },
	pickup_at: { value: 'pickup_at', label: 'Pickup Date' },
	delivery_at: { value: 'delivery_at', label: 'Delivery Date' },
};

const useStyles = makeStyles((theme) => ({
	main_container: { padding: 32 },
	flex_box: { display: 'flex', gap: 20 },
	title_box: { display: 'flex', justifyContent: 'space-between', marginTop: 30 },
	divider: { height: 1, width: '100%', backgroundColor: theme.colors.lightGrey5 },
	calender_icon: { height: 20, width: 20, backgroundColor: theme.colors.lightPurple, borderRadius: 10 },
	date_text: { color: theme.colors.primary, fontSize: 16 },
	get_strt_btn: {
		width: 90,
		height: 32,
		borderRadius: 30,
		padding: '8px 12px',
		cursor: 'pointer',
	},
	list_text: { backgroundColor: theme.colors.lightPurple9, borderRadius: 50, padding: '2px 8px', display: 'inline-flex', gap: 5, cursor: 'pointer' },
}));

function Dashboard() {
	const classes = useStyles();
	const [data, set_data] = useState({});
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [is_first_time_user, set_is_first_time_user] = useState(false);
	const [get_started_clicked, set_get_started_clicked] = useState(false);
	const [selected_menu, set_selected_menu] = useState(dropdown_menu.created_at.value);
	const [show_dropdown, set_show_dropdown] = useState(false);
	const menu_ref = useRef(null);

	const [filters, set_filters] = React.useState({
		from: moment(moment().subtract('29', 'days').format('MM-DD-YYYY')),
		to: moment(moment().format('MM-DD-YYYY')),
	});

	useEffect(() => {
		const new_user = localStorage.getItem('new_user');
		if (new_user === 'true') {
			set_is_first_time_user(true);
			set_get_started_clicked(true);
		}
		NotificationService.request_permission();
	}, []);

	const get_data = async () => {
		try {
			const res = await api_requests.get_dashboard_analytics({
				...(selected_menu === 'created_at' && { created_at: `${filters.from.format('YYYY-MM-DD')},${filters.to.format('YYYY-MM-DD')}` }),
				...(selected_menu === 'pickup_at' && { pickup_at: `${filters.from.format('YYYY-MM-DD')},${filters.to.format('YYYY-MM-DD')}` }),
				...(selected_menu === 'delivery_at' && { delivery_at: `${filters.from.format('YYYY-MM-DD')},${filters.to.format('YYYY-MM-DD')}` }),
				group: 'all',
			});
			if (!_.isEmpty(res.data)) {
				set_data(res.data);
			}
		} catch (err) {}
	};

	const get_start_tap = () => {
		localStorage.setItem('new_user', false);
		set_is_first_time_user(false);
		set_get_started_clicked((prev) => !prev);
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.CLICK_GET_STARTED);
	};

	useEffect(() => {
		get_data();
	}, [filters, selected_menu]);

	return (
		<>
			{(is_first_time_user || get_started_clicked) && (
				<FirstTimeOverlay
					onClose={() => {
						localStorage.setItem('new_user', false);
						set_get_started_clicked(false);
						set_is_first_time_user(false);
					}}
				/>
			)}
			<div className={classes.main_container}>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 32 }}>
					<Text className='global_header_text' semi>
						Hi, {utils._retrieveData('name')}!
					</Text>
					<Button type='text' text='Get Started' onClick={get_start_tap} />
				</div>

				<div style={{ overflow: 'auto', height: '85vh' }}>
					<Overview selected_menu={selected_menu} />
					<div className={classes.title_box}>
						<Text>
							<Text bold component={'span'}>
								{filters.to.diff(filters.from, 'days') + 1} Days Overview
							</Text>{' '}
							as per{' '}
							<div ref={menu_ref} className={classes.list_text} onClick={() => set_show_dropdown(!show_dropdown)}>
								<Text component={'span'} semi style={{ color: theme.colors.primary, fontSize: 14 }}>
									{_.get(dropdown_menu, `${selected_menu}.label`, '')}{' '}
								</Text>
								<img src={ImageLinks.chevronLeft} style={{ transform: 'rotate(270deg)' }}></img>
							</div>
						</Text>
						<Menu
							anchorEl={menu_ref.current}
							open={show_dropdown}
							PaperProps={{
								style: {
									borderRadius: 10,
									marginTop: 50,
									maxHeight: 400,
								},
							}}
							onClose={() => set_show_dropdown(false)}>
							{_.map(dropdown_menu, (item, index) => {
								return (
									<MenuItem
										sx={{ width: 100 }}
										value={item.value}
										onClick={(e) => {
											set_selected_menu(item.value);
											set_show_dropdown(false);
										}}
										key={`hour_menu_${index}`}>
										{item.label}
									</MenuItem>
								);
							})}
						</Menu>
						<div style={{ position: 'relative' }}>
							<div style={{ display: 'flex', gap: 10 }}>
								<div>
									<Text bold className={classes.date_text}>{`${filters.from.format('DD MMM `YY')} - ${filters.to.format(
										'DD MMM `YY',
									)}`}</Text>
								</div>
								<img src={ImageLinks.calendar_solid_circle} alt='date' onClick={() => set_date_picker_open(true)}></img>
							</div>
							<div style={{ position: 'absolute', right: 0, width: 700 }}>
								<DateRangePicker
									open={date_picker_open}
									toggle={() => set_date_picker_open(!date_picker_open)}
									style={{ width: 200 }}
									initialDateRange={{
										startDate: filters.from,
										endDate: filters.to,
									}}
									maxDate={new Date()}
									onChange={(range) => {
										const newDateRange = {
											from: moment(range.startDate),
											to: moment(range.endDate),
										};
										set_filters(newDateRange);
										set_date_picker_open(!date_picker_open);
									}}
								/>
							</div>
						</div>
					</div>
					<div className={classes.divider} style={{ margin: '10px 0' }}></div>
					<div className={classes.flex_box}>
						<div style={{ flex: 1 }}>
							<TotalOrders orders_data={_.get(data, 'stats.order', [])} />
						</div>
						<div style={{ flex: 1 }}>
							<ChannelMix channel_data={_.sortBy(_.get(data, 'stats.dd_channel', {}), ['_id'])} />
						</div>
						<div style={{ flex: 2 }}>
							<Revenue orders_data={_.get(data, 'stats.order', [])} revenue_data={_.get(data, 'stats.date', {})} />
						</div>
					</div>
					{!(is_first_time_user || get_started_clicked) && (
						<div className={classes.flex_box} style={{ marginTop: 20 }}>
							<div style={{ flex: 3 }}>
								<OrderZones master_zone_data={_.get(data, 'stats.masterZone', {})} business_zone_data={_.get(data, 'stats.businessZone', {})} />
							</div>
							<div style={{ flex: 1 }}>
								<DeliveryPartner partner_data={_.sortBy(_.get(data, 'stats.fulfillment', []), ['_id'])} />{' '}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default Dashboard;
