import { Button, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	outer_wrap: {
		position: 'absolute',
		width: '100vw',
		height: '100vh',
		top: 0,
		right: 0,
		backgroundColor: 'rgba(102, 102, 102, 0.75)',
		zIndex: 3,
	},
	wrapper: {
		position: 'absolute',
		width: 600,
		height: '100vh',
		top: 0,
		right: 0,
		backgroundColor: 'white',
		zIndex: 3,
		borderRadius: '50px 0px 0px 0px',
		padding: 30,
	},
	filter_wrap: {
		display: 'flex',
		flexDirection: 'column',
		gap: 30,
	},
	header_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 30,
	},
}));

function RosterFilters({ children, close_callback }) {
	const classes = useStyles();

	return (
		<div className={classes.outer_wrap}>
			<div className={classes.wrapper}>
				<div className={classes.header_wrap}>
					<Text semi style={{ fontSize: 22 }}>
						Filter
					</Text>
					<img src={ImageLinks.crossBlack} style={{ cursor: 'pointer' }} onClick={close_callback}></img>
				</div>
				<div className={classes.filter_wrap}>{children}</div>
			</div>
		</div>
	);
}

export default RosterFilters;
