import { makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import React from 'react';
import EmptyState from './EmptyState';

const useStyles = makeStyles((theme) => ({
	main_container: {
		backgroundColor: theme.colors.white,
		padding: 12,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		borderRadius: 12,
		height: '100%',
		position: 'relative',
	},
	divider: { height: 1, width: '100%', backgroundColor: theme.colors.lightGrey5 },
	vertical_divider: { height: 'auto', width: 1, backgroundColor: theme.colors.lightGrey5 },
	total_order_box: { backgroundColor: theme.colors.lightGrey7, textAlign: 'center', padding: '17px 0', borderRadius: 6, margin: '12px 0' },
	order_stats_outer: { marginTop: 14, display: 'flex', flexWrap: 'wrap' },
	stat_value: { fontSize: 20, marginBottom: 5 },
	stat_box: { width: '49%', textAlign: 'center', padding: '14px 0' },
	heading_text: { color: theme.colors.darkGrey4, fontSize: 14 },
	pills: { padding: '3px 6px', borderRadius: 25, marginTop: '10px', fontSize: 10 },
}));

function TotalOrders({ orders_data }) {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div className={classes.main_container}>
			<Text style={{ marginBottom: 12 }} bold className={classes.heading_text}>
				Total Orders
			</Text>
			<div className={classes.divider}></div>
			<div className={classes.total_order_box}>
				<Text semi style={{ fontSize: 30 }}>
					{_.get(orders_data[0], 'total_order', 0)}
				</Text>
				<Text semi style={{ fontSize: 14 }}>
					Total Orders
				</Text>
			</div>
			<div className={classes.order_stats_outer}>
				<div className={classes.stat_box}>
					<Text semi className={classes.stat_value}>
						{_.get(orders_data[0], 'pending', 0) + _.get(orders_data[0], 'processing', 0)}
					</Text>
					<Text semi component={'span'} className={classes.pills} style={{ color: theme.colors.darkPurple, backgroundColor: theme.colors.lightPink }}>
						Pending
					</Text>
				</div>
				<div className={classes.vertical_divider}></div>
				<div className={classes.stat_box}>
					<Text semi className={classes.stat_value}>
						{_.get(orders_data[0], 'ready_to_ship', 0)}
					</Text>
					<Text semi component={'span'} className={classes.pills} style={{ color: theme.colors.primary, backgroundColor: theme.colors.lightPurple }}>
						AWB Manifested
					</Text>
				</div>
				<div className={classes.divider}></div>
				<div className={classes.stat_box}>
					<Text semi className={classes.stat_value}>
						{_.get(orders_data[0], 'shipped', 0)}
					</Text>
					<Text
						semi
						className={classes.pills}
						component={'span'}
						style={{ color: theme.colors.lightGreen3, backgroundColor: theme.colors.lightGreen5 }}>
						Shipped
					</Text>
				</div>
				<div className={classes.vertical_divider}></div>
				<div className={classes.stat_box}>
					<Text semi className={classes.stat_value}>
						{_.get(orders_data[0], 'completed', 0)}
					</Text>
					<Text semi component={'span'} className={classes.pills} style={{ color: theme.colors.green, backgroundColor: theme.colors.lightGreen }}>
						Completed
					</Text>
				</div>
			</div>
			{_.get(orders_data[0], 'total_order', 0) === 0 && <EmptyState />}
		</div>
	);
}

export default TotalOrders;
