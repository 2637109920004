import React, { useEffect } from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Amount from '../../../modules/Amount';
import moment from 'moment';
import CollectionHistory from './components/CollectionHistory';
import { OVERVIEW_TYPES } from '../helper';
import SettleHistory from './components/SettleHistory';
import DepositHistory from './components/DepositHistory';
import { ListItem } from '@material-ui/core';
import api_requests from 'resources/api_requests';
import TransactionHistory from '../components/TransactionHistory';
import Routes from 'resources/Routes';
import constant from 'resources/constant';
import RiderOverviewModal from 'modules/modals/RiderOverviewModal';

const CM_RiderDetail = ({ history, match }) => {
	const rider_id = match.params.id;

	const [account_info, set_account_info] = React.useState({});
	const [rider_details, set_rider_details] = React.useState({});

	const [show_transaction_history, set_show_transaction_history] = React.useState(false);
	const [type, set_type] = React.useState(OVERVIEW_TYPES.COLLECT);

	const [rider_data, set_rider_data] = React.useState(null);

	useEffect(() => {
		api_requests
			.get_cm_account_info({
				id: rider_id,
				user_type: 'rider',
			})
			.then((res) => set_account_info(res.data));

		api_requests.get_rider_details(rider_id).then((res) => set_rider_details(res.data));
	}, []);

	const change_type = (type) => {
		set_type(type);
	};

	if (_.isEmpty(account_info)) {
		return null;
	}

	return (
		<div className='container' style={{ height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex' }}>
					<Text
						regular
						className={'global_header_text'}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							history.push(Routes.CASH_MANAGEMENT.path);
						}}>
						Cash Management
					</Text>
					<div className='header_separator' style={{ marginTop: 6 }}></div>
					<Text
						regular
						className={'global_header_text'}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							history.push(Routes.CASH_MANAGEMENT_ALL_RIDERS.path);
						}}>
						All Riders
					</Text>
					<div className='header_separator' style={{ marginTop: 6 }}></div>
					<Text
						semi
						className={'header_active_title'}
						onClick={() => {
							api_requests.get_rider_details(rider_id).then((res) => {
								set_rider_data(res.data);
							});
						}}>
						{_.get(rider_details, 'details.rider_name', '')}
					</Text>
				</div>
			</div>
			<Text bold style={{ fontSize: 24, marginTop: 32 }}>
				Rider Cash Overview
			</Text>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
				<div>
					<Amount
						amount={_.get(account_info, 'balance', 0)}
						main_text_styles={{ color: account_info.balance < 0 ? theme.colors.green : theme.colors.red, fontSize: 24 }}
						sub_text_styles={{ color: account_info.balance < 0 ? theme.colors.green : theme.colors.red, fontSize: 12 }}
					/>
					<Text bold style={{ fontSize: 12 }}>
						Total Outstanding
					</Text>
				</div>
				<div style={{ display: 'flex' }}>
					<ListItem
						button
						className='box-shadow'
						onClick={() => change_type(OVERVIEW_TYPES.COLLECT)}
						style={{
							background: type === OVERVIEW_TYPES.COLLECT ? theme.colors.lightPurple : theme.colors.white,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '10px 15px',
							borderRadius: 10,
							marginLeft: 10,
							width: 190,
							alignItems: 'flex-start',
						}}>
						<Amount
							amount={_.get(account_info, 'stats.today.credit', 0)}
							main_text_styles={{ color: theme.colors.black, fontSize: 20 }}
							sub_text_styles={{ color: theme.colors.black, fontSize: 12 }}
						/>
						<Text semi style={{ fontSize: 10, marginTop: 5 }}>
							Collected {moment().format('DD/MM/YY')}
						</Text>
					</ListItem>
					<ListItem
						button
						className='box-shadow'
						onClick={() => change_type(OVERVIEW_TYPES.DEPOSIT)}
						style={{
							background: type === OVERVIEW_TYPES.DEPOSIT ? theme.colors.lightPurple : theme.colors.white,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '10px 15px',
							borderRadius: 10,
							marginLeft: 10,
							width: 190,
							alignItems: 'flex-start',
						}}>
						<Amount
							amount={_.get(account_info, 'stats.today.debit', 0)}
							main_text_styles={{ color: theme.colors.black, fontSize: 20 }}
							sub_text_styles={{ color: theme.colors.black, fontSize: 12 }}
						/>
						<Text semi style={{ fontSize: 10, marginTop: 5 }}>
							Deposited {moment().format('DD/MM/YY')}
						</Text>
					</ListItem>
					<ListItem
						button
						className='box-shadow'
						onClick={() => change_type(OVERVIEW_TYPES.SETTLE)}
						style={{
							background: type === OVERVIEW_TYPES.SETTLE ? theme.colors.lightPurple : theme.colors.white,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '10px 15px',
							borderRadius: 10,
							marginLeft: 10,
							width: 190,
							alignItems: 'flex-start',
						}}>
						<Amount
							amount={_.get(account_info, 'stats.today.settle', 0)}
							main_text_styles={{ color: theme.colors.black, fontSize: 20 }}
							sub_text_styles={{ color: theme.colors.black, fontSize: 12 }}
						/>
						<Text semi style={{ fontSize: 10, marginTop: 5 }}>
							Other
						</Text>
					</ListItem>
					<ListItem
						button
						className='box-shadow'
						onClick={() => set_show_transaction_history(true)}
						style={{
							background: theme.colors.white,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '10px 15px',
							borderRadius: 10,
							marginLeft: 10,
							width: 190,
							alignItems: 'flex-start',
						}}>
						<Text>Transaction History</Text>
					</ListItem>
				</div>
			</div>
			{type === OVERVIEW_TYPES.COLLECT && <CollectionHistory rider_id={rider_id} />}
			{type === OVERVIEW_TYPES.DEPOSIT && <DepositHistory rider_id={rider_id} />}
			{type === OVERVIEW_TYPES.SETTLE && <SettleHistory rider_id={rider_id} />}

			{show_transaction_history && (
				<TransactionHistory close={() => set_show_transaction_history(false)} user_type={constant.USER_TYPE.Rider.type} id={rider_id} />
			)}
			{!_.isEmpty(rider_data) && <RiderOverviewModal rider_data={rider_data} set_rider_data={set_rider_data} close={() => set_rider_data(null)} />}
		</div>
	);
};

export default CM_RiderDetail;
