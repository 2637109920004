import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';

const useStyles = makeStyles({
	map_btn_wrap: {
		position: 'absolute',
		zIndex: 100,
		top: 50,
		justifyContent: 'center',
		left: '50%',
		transform: 'translate(-45%)',
	},
	edit_coord_btn: {
		border: `1px solid ${theme.colors.primary}`,
		borderRadius: 30,
		padding: '5px 7px',
		boxShadow: '0px 0px 16px rgba(102, 102, 102, 0.1)',
		minWidth: 149,
	},
	save_coord_btn: {
		border: `1px solid ${theme.colors.green}`,
		borderRadius: 30,
		padding: '5px 7px',
		boxShadow: '0px 0px 16px rgba(102, 102, 102, 0.1)',
		marginLeft: 12,
		width: 80,
	},
});

const MapButtons = ({ show_edit, on_edit_press, in_edit_mode, show_draw, on_draw_press, in_draw_mode, show_save, on_save_press, saved_press }) => {
	const classes = useStyles();
	return (
		<div className={classes.map_btn_wrap}>
			{show_edit && (
				<ContainedButton
					onClick={on_edit_press}
					className={classes.edit_coord_btn}
					style={{
						background: in_edit_mode ? theme.colors.white : theme.colors.lightPurple,
						alignSelf: 'center',
					}}>
					<img src={ImageLinks.edit_purple} width={16} height={16} alt='edit_map_pencil' />
					<Text semi style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 14 }}>
						Edit Coordinates
					</Text>
					{in_edit_mode && <img src={ImageLinks.crossBlack} width={12} height={12} style={{ marginLeft: 4 }} />}
				</ContainedButton>
			)}
			{show_draw && (
				<ContainedButton
					onClick={on_draw_press}
					className={classes.edit_coord_btn}
					style={{
						background: in_draw_mode ? theme.colors.white : theme.colors.lightPurple,
					}}>
					<img src={ImageLinks.polygon_purple} width={16} height={16} alt='edit_map_pencil' />
					<Text semi style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 14 }}>
						Draw Polygon
					</Text>
					{in_draw_mode && <img src={ImageLinks.crossBlack} width={12} height={12} style={{ marginLeft: 4 }} />}
				</ContainedButton>
			)}
			{show_save && (
				<ContainedButton
					onClick={on_save_press}
					className={classes.save_coord_btn}
					style={{ backgroundColor: saved_press ? theme.colors.green : theme.colors.lightGreen6 }}>
					<img src={saved_press ? ImageLinks.check : ImageLinks.tickCircleSolid} width={16} height={16} alt='tick_solid_green' />
					<Text semi style={{ marginLeft: 4, fontSize: 14, color: saved_press ? theme.colors.white : theme.colors.black }}>
						{saved_press ? 'Saved' : 'Save'}
					</Text>
				</ContainedButton>
			)}
		</div>
	);
};

export default MapButtons;
