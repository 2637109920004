import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { ListItem } from '@material-ui/core';

import api_requests from 'resources/api_requests';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Routes from 'resources/Routes';
import Amount from '../../../modules/Amount';
import OutlinedButton from 'modules/OutlinedButton';
import DetailedTransactionHistory from '../components/DetailedTransactionHistory';
import ImageLinks from 'assets/images/ImageLinks';

const CM_AllAdmins = ({ history }) => {
	const [admins, set_admins] = React.useState([]);
	const [search_admins, set_search_admins] = React.useState([]);

	const [selected_transaction_history_id, set_selected_transaction_history_id] = React.useState(null);
	const [is_outstanding_filter_applied, set_is_outstanding_filter_applied] = React.useState(false);
	const [search_text, set_search_text] = React.useState('');

	const get_admins = useCallback(() => {
		api_requests.get_cm_type_list('user').then((res) => {
			set_admins(res.data.records);
		});
	}, []);

	useEffect(() => {
		set_admins([]);
		get_admins();
	}, [get_admins]);

	const admins_with_outstanding = _.filter(admins, (admin) => {
		return admin.balance > 0;
	});

	useEffect(() => {
		if (_.isEmpty(search_text)) {
			set_search_admins(is_outstanding_filter_applied ? admins_with_outstanding : admins);
			return;
		}

		const _search_admins = [];

		_.map(is_outstanding_filter_applied ? admins_with_outstanding : admins, (admin) => {
			console.log(admin);
			if (admin?.name?.toLowerCase().includes(search_text) || (admin.user_id || '').toString().includes(search_text)) {
				_search_admins.push(admin);
			}
		});

		set_search_admins(_search_admins);
	}, [search_text, admins, is_outstanding_filter_applied]);

	return (
		<div className='container' style={{ height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex' }}>
					<Text
						regular
						className={'global_header_text'}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							history.push(Routes.CASH_MANAGEMENT.path);
						}}>
						Cash Management
					</Text>
					<div className='header_separator' style={{ marginTop: 6 }}></div>
					<Text semi className={'header_active_title'}>
						All Admins
					</Text>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					marginTop: 32,
					borderBottom: `1px solid ${theme.colors.lightGrey}`,
					justifyContent: 'space-between',
					alignItems: 'flex-end',
				}}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						width: '100%',
					}}>
					<div style={{ display: 'flex' }}>
						{_.map(
							[
								{
									text: 'Admin with Outstanding',
									details: admins_with_outstanding.length,
									active: is_outstanding_filter_applied,
									onClick: () => {
										set_is_outstanding_filter_applied(true);
									},
								},
								{
									text: 'All Admins',
									details: admins.length,
									active: !is_outstanding_filter_applied,
									onClick: () => {
										set_is_outstanding_filter_applied(false);
									},
								},
							],
							(filter, key) => {
								return (
									<ListItem
										key={`status${key}`}
										button
										onClick={filter.onClick}
										style={{
											height: 80,
											width: 220,
											backgroundColor: filter.active ? theme.palette.primary.main : theme.colors.lightPurple,
											borderTopRightRadius: 20,
											borderTopLeftRadius: 20,
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
										}}>
										<Text bold style={{ color: filter.active ? 'white' : theme.colors.lightPurple2, fontSize: 28 }}>
											{filter.details}
										</Text>
										<Text style={{ color: filter.active ? 'white' : theme.colors.lightPurple2, marginTop: 10 }}>{filter.text}</Text>
									</ListItem>
								);
							},
						)}
					</div>
					<div style={{ marginBottom: 10 }}>
						<div
							style={{
								backgroundColor: theme.colors.white,
								borderRadius: 40,
								display: 'flex',
								marginRight: 10,
								height: 40,
								width: 280,
								paddingLeft: 16,
								paddingRight: 16,
								border: `1px solid ${theme.colors.lightGrey}`,
							}}>
							<input
								value={search_text}
								placeholder='Search by admin name or id'
								style={{ flex: 1, border: 'none', outline: 'none' }}
								onChange={(e) => {
									set_search_text(e.target.value);
								}}
							/>
							<img src={ImageLinks.search_light_grey} width='15' alt='' />
						</div>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
				<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
					<div
						style={{
							background: theme.colors.white,
							boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
							height: 41,
							borderRadius: 40,
							width: '100%',
							padding: '12px 30px',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: 10,
							marginTop: 20,
						}}>
						<Text bold style={{ flex: 2 }}>
							ID
						</Text>
						<Text bold style={{ flex: 3 }}>
							Admin Name
						</Text>
						<Text bold style={{ flex: 2 }}>
							Amount
						</Text>
						<Text bold style={{ flex: 2 }}>
							Aging
						</Text>
						<Text style={{ flex: 2 }}></Text>
					</div>

					<div style={{ overflowY: 'auto', flex: 1, marginTop: 10, marginBottom: 10 }}>
						{_.map(!_.isEmpty(search_text) ? search_admins : is_outstanding_filter_applied ? admins_with_outstanding : admins, (admin, key) => {
							return (
								<div
									key={`admin${key}`}
									style={{
										width: '100%',
										padding: '12px 30px',
										borderBottom: `1px solid ${theme.colors.lightGrey5}`,
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}>
									<Text medium style={{ flex: 2, fontSize: 16 }}>
										{admin.user_id || '-'}
									</Text>
									<Text medium style={{ flex: 3, fontSize: 16 }}>
										{admin.name || '-'}
									</Text>
									<Text
										component='a'
										href='#'
										semi
										style={{ flex: 2, fontSize: 16 }}
										onClick={(e) => {
											e.preventDefault();
											set_selected_transaction_history_id(admin.user_id);
										}}>
										<Amount
											amount={admin.balance}
											main_text_styles={{ fontSize: 14, color: theme.colors.primary }}
											sub_text_styles={{ fontSize: 10, color: theme.colors.primary }}
										/>
									</Text>
									<Text medium style={{ flex: 2, fontSize: 16 }}>
										-
									</Text>
									<Text medium style={{ flex: 2, fontSize: 16 }}>
										<OutlinedButton
											onClick={() => {
												history.push(Routes.CASH_MANAGEMENT_ADMiN_DETAIL.path.replace(':id', admin.user_id));
											}}>
											<Text>Details</Text>
										</OutlinedButton>
									</Text>
								</div>
							);
						})}
					</div>
				</div>
				<div style={{ flex: 1, margin: '10px 40px 40px 40px' }}>
					<div style={{ height: '100%', width: '100%', background: theme.colors.white, borderRadius: 10 }}>
						<DetailedTransactionHistory user_type={'user'} id={selected_transaction_history_id} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CM_AllAdmins;
