import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backdropFilter: 'blur(3px)',
		top: 0,
		right: 0,
	},
	detail_box: { backgroundColor: theme.colors.white, width: '90%', display: 'flex', borderRadius: 8, border: `1px solid ${theme.colors.lightGrey5}` },
}));

function PreviewDisabled({ heading = 'Unlock Customizations' }) {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div className={classes.detail_box}>
				<div style={{ margin: 20 }}>
					<Text semi style={{ fontSize: 16 }}>
						{heading}
					</Text>
					<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
						Contact support@pidge.in to activate this feature!
					</Text>
				</div>
				<img src={ImageLinks.newspaper_lock} style={{ marginTop: 30 }} height={100} width={130} />
			</div>
		</div>
	);
}

export default PreviewDisabled;
