import React, { useState, useEffect, useRef } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, Divider } from '@material-ui/core';
import _ from 'lodash';

import Text from 'modules/Text';
import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Toast from 'modules/Toast';
import { toggle_support_modal } from 'actions/uiUpdates';
import AppSocket from 'resources/AppSocket';
import utils from 'resources/utils';
import constant from 'resources/constant';
import { get_user_details } from 'actions/app';
import { login_failed } from 'actions/app';
import { empty_alerts } from 'actions/alerts';
import AddBusinessModal from './components/AddBusinessModal';
import BusinessListing from './components/BusinessListing';
import BusinessDetails from './components/BusinessDetails';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 30,
		backgroundColor: theme.colors.white,
		minHeight: '100vh',
	},
	new_button: {
		borderRadius: 30,
	},
	img_btn_wrap: {
		position: 'absolute',
		bottom: 20,
		left: 20,
		zIndex: 1000,
	},
	active_button: {
		padding: '8px 16px',
		backgroundColor: theme.colors.primary,
		color: theme.colors.white,
		borderRadius: 8,
		cursor: 'pointer',
	},
	inactive_button: {
		padding: '8px 16px',
		color: theme.colors.lightPurple6,
		borderRadius: 8,
		cursor: 'pointer',
	},
	list_wrap: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		width: '48%',
		maxHeight: '80vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}));

const ChildBusinessList = ({}) => {
	const dispatch = useDispatch();
	const { user_teams } = useSelector((state) => ({ user_teams: state.app.user_teams }), shallowEqual);

	const history = useHistory();

	const [show_add_business, set_show_add_business] = useState(false);
	const [trace, set_trace] = useState({});
	const [show_toast, set_show_toast] = useState(false);
	const [business_list, set_business_list] = useState([]);
	const [selected_business, set_selected_business] = useState({});
	const [business_stats, set_business_stats] = useState([]);

	const slider_ref = useRef();
	const loading_scroll = useRef(false);

	const classes = useStyles();

	const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER);

	useEffect(() => {
		get_business_list();
	}, []);

	const support_chat = () => {
		const support_data = {
			show_modal: true,
		};
		dispatch(toggle_support_modal(support_data));
	};

	const get_business_list = async () => {
		try {
			const res = await api_requests.get_child_business();
			if (res.success) set_business_list(res.data);
		} catch (err) {}
	};

	const get_business_stats = async (id) => {
		try {
			const res = await api_requests.get_child_business_stats(id);
			if (res.stats) {
				set_business_stats(res.stats);
			}
		} catch (err) {}
	};

	const create_business = async (data) => {
		try {
			const res = await api_requests.add_child_business(data);
			if (res.success) {
				set_show_add_business(false);
				get_business_list();
				set_show_toast(true);
			}
		} catch (err) {}
	};

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && trace.next && !loading_scroll.current) {
			loading_scroll.current = true;
		}
	};

	const handle_business_select = (business) => {
		get_business_stats(business.id);
		set_selected_business(business);
	};

	const handle_dashbord = async () => {
		const agg_biz_list = localStorage.getItem(constant.LOCAL_STORAGE_KEYS.AGG_BIZ_LIST);
		const parsed_agg_biz_list = JSON.parse(agg_biz_list);
		if (selected_business.id == utils._retrieveData(constant.OWNER_ID)) {
			history.push('/');
			return;
		}
		if (_.isEmpty(agg_biz_list)) return;
		const is_exites_business_user = _.filter(parsed_agg_biz_list, (account) => {
			return account.business_id === selected_business.id;
		});
		if (is_exites_business_user.length > 0) {
			is_exites_business_user[0].id = is_exites_business_user[0].business_id;
			for (let i = 0; i < user_teams.length; i++) {
				const element = user_teams[i];
				let team_channel = `team_ch_${element.team_id}`;
				AppSocket.leave_room(team_channel);
			}
			utils._storeData(constant.SELECTED_BUSINESS, is_exites_business_user[0]);
			utils.setRequestAuthHeaderBusinessId(is_exites_business_user[0].business_id, is_exites_business_user[0].parent_id);
			const owner_text = constant.OWNER_TYPE_TEXT[is_exites_business_user[0].type_of_owner];
			utils._storeData(constant.TYPE_OF_OWNER, { title: owner_text, id: is_exites_business_user[0].type_of_owner });
			dispatch(get_user_details(true));
			history.push('/');
		} else {
			try {
				const owner_text = constant.OWNER_TYPE_TEXT[4];
				const owner_id = selected_business.id;
				const parent_id = utils._retrieveData(constant.OWNER_ID);
				await api_requests.create_child_business_access(selected_business.id);
				utils._storeData(constant.SELECTED_BUSINESS, {
					business_id: owner_id,
					brand_name: selected_business.brand_name,
					business_name: selected_business.business_name ? selected_business.business_name : selected_business.brand_name,
					type_of_owner: 4,
					parent_id: parent_id,
				});
				utils.setRequestAuthHeader(utils._retrieveData(constant.AUTH_TOKEN), { title: owner_text, id: 4 });
				utils.setRequestAuthHeaderBusinessId(owner_id, parent_id);
				dispatch(get_user_details()).then(() => {
					history.push('/');
				});
			} catch (err) {}
		}
	};

	return (
		<div className={`container ${classes.container}`}>
			<div className={classes.header}>
				<div className='align-items-center'>
					<div className='page_back_btn' onClick={() => history.goBack()}>
						<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
					</div>
					<Text semi style={{ fontSize: 20 }}>
						Enterprise
					</Text>
				</div>
				{business_list.length > 0 && owner_type.id == constant.OWNER_TYPE.AGGREGATOR.id && (
					<ContainedButton className={classes.new_button} onClick={() => set_show_add_business(true)}>
						<img src={ImageLinks.addWhite} style={{ marginRight: 5 }} width={16} height={16} />
						<Text semi style={{ fontSize: 16 }}>
							New Business
						</Text>
					</ContainedButton>
				)}
			</div>
			<div>
				{business_list.length == 0 && (
					<div className='d-flex align-items-center justify-content-center' style={{ height: '80vh' }}>
						<div style={{ position: 'relative' }}>
							<img src={ImageLinks.child_business_empty_state} height={328} width={514} />
							<div className={classes.img_btn_wrap}>
								{owner_type.id == constant.OWNER_TYPE.BUSINESS.id ? (
									<ContainedButton className={classes.new_button} onClick={support_chat}>
										<Text semi style={{ fontSize: 16 }}>
											Upgrade Now
										</Text>
										<img src={ImageLinks.right_arrow} style={{ marginLeft: 5 }} width={16} height={16} />
									</ContainedButton>
								) : (
									<ContainedButton className={classes.new_button} onClick={() => set_show_add_business(true)}>
										<img src={ImageLinks.addWhite} style={{ marginRight: 5 }} width={16} height={16} />
										<Text semi style={{ fontSize: 16 }}>
											New Business
										</Text>
									</ContainedButton>
								)}
							</div>
						</div>
					</div>
				)}
				{business_list.length > 0 && owner_type.id == constant.OWNER_TYPE.AGGREGATOR.id && (
					<div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
						<div className='d-flex' style={{ height: 35 }}>
							<div className={classes.active_button}>
								<Text bold style={{ fontSize: 14 }}>
									Total ({business_list.length})
								</Text>
							</div>
						</div>
					</div>
				)}
				<Divider style={{ margin: '10px 0 10px 0' }} />
				<div className='d-flex' style={{ gap: 30 }}>
					{business_list.length > 0 && owner_type.id == constant.OWNER_TYPE.AGGREGATOR.id && (
						<div className={classes.list_wrap} ref={slider_ref} onScroll={slider_scrolled}>
							<BusinessListing
								selected_business={selected_business}
								business_list={business_list}
								handle_business_select={handle_business_select}
							/>
						</div>
					)}
					{!_.isEmpty(selected_business) && (
						<BusinessDetails selected_business={selected_business} business_stats={business_stats} handle_dashbord={handle_dashbord} />
					)}
				</div>
			</div>

			{show_add_business && <AddBusinessModal close={() => set_show_add_business(false)} create_business={create_business} />}
			{show_toast && (
				<Toast close_callback={() => set_show_toast(false)}>
					<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='success' />
					<Text component={'span'} semi style={{ marginLeft: '10px', fontSize: 14 }}>
						{'New Sub-Business Created Sucessfully'}
					</Text>
				</Toast>
			)}
		</div>
	);
};

export default ChildBusinessList;
