import Text from 'modules/Text';
import React from 'react';

import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';

const useStyles = makeStyles({
	create_flow_type_item_wrap: {
		width: 200,
		height: 33,
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		boxShadow: '0px 0px 16px rgba(102, 102, 102, 0.1)',
		padding: 8,
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	outer_circle: {
		width: 14.22,
		height: 14.22,
		borderRadius: 7.11,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	inner_circle: { width: 5.34, height: 5.34, borderRadius: 2.67, backgroundColor: theme.colors.primary },
});

const DrawOrCsvOption = ({ on_item_click, is_selected, title }) => {
	const classes = useStyles();
	return (
		<div style={{ marginBottom: 10 }} onClick={on_item_click} className={classes.create_flow_type_item_wrap}>
			<div
				className={classes.outer_circle}
				style={{
					border: `1.71px solid ${is_selected ? theme.colors.primary : theme.colors.lightGrey2}`,
				}}>
				{is_selected && <div className={classes.inner_circle}></div>}
			</div>
			<Text
				semi
				style={{
					fontSize: 14,
					color: is_selected ? theme.colors.primary : theme.colors.lightGrey2,
					marginLeft: 4.7,
				}}>
				{title}
			</Text>
		</div>
	);
};

export default DrawOrCsvOption;
