import React, { useEffect } from 'react';
import qs from 'qs';
import Text from 'modules/Text';
import theme from 'resources/theme';
import _ from 'lodash';
import { ListItem, makeStyles } from '@material-ui/core';

import OngoingOrders from './components/OngoingOrders';
import Overview from './components/Overview';
import ReadyToShip from './components/ReadyToShip/ReadyToShip';
import AtWarehouse from './components/AtWarehouse';
import Terminal from './components/Terminal';
import Routes from 'resources/Routes';
import Select from 'modules/Select';
import { shallowEqual, useSelector } from 'react-redux';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from './helper';

const useStyles = makeStyles(() => ({
	container: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	ordersContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderBottom: `1px solid ${theme.colors.lightPurple}`,
	},
	header_text: { fontSize: 20, textDecoration: `underline 2px ${theme.colors.lightPurple}`, textUnderlineOffset: '5px' },
	list_wrapper: { display: 'flex', paddingBottom: 10 },
}));

const PoweredWithPidge = ({ history }) => {
	const query_object = qs.parse(history.location.search.slice(1));

	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);

	const [selected_menu, set_selected_menu] = React.useState(query_object.type || POWERED_WITH_PIDGE_MENU_ITEMS.Overview.id);
	const [selected_master_zone, set_selected_master_zone] = React.useState(zones[0].id);

	useEffect(() => {
		if (!query_object.type) {
			set_selected_menu(POWERED_WITH_PIDGE_MENU_ITEMS.Overview.id);
			return;
		}
		set_selected_menu(query_object.type);
	}, [query_object.type]);

	const classes = useStyles();

	return (
		<div className={`container ${classes.container}`}>
			<div style={{ display: 'flex', marginBottom: 32 }}>
				<Text component={'span'} className={'global_header_text'} style={{ cursor: 'pointer' }} onClick={() => history.push('/all-orders')}>
					All Orders |&nbsp;
				</Text>
				<Text semi component={'span'} className={'header_active_title'}>
					Pidge Powered
				</Text>
			</div>
			<div className={classes.ordersContainer}>
				<div className={classes.list_wrapper}>
					{_.map(POWERED_WITH_PIDGE_MENU_ITEMS, (menu_item, key) => {
						const active = selected_menu === menu_item.id;
						return (
							<ListItem
								key={`menuItem${key}`}
								button
								onClick={() => {
									history.push(Routes.POWERED_WITH_PIDGE.path + `?type=${menu_item.id}`);
								}}
								style={{
									backgroundColor: active ? theme.palette.primary.main : 'transparent',
									borderRadius: 8,
									display: 'flex',
									justifyContent: 'center',
									width: 'auto',
									...(menu_item.id !== POWERED_WITH_PIDGE_MENU_ITEMS.Overview.id && { marginLeft: 10 }),
								}}>
								<Text semi style={{ color: active ? 'white' : theme.colors.lightPurple2 }}>
									{menu_item.text}
								</Text>
							</ListItem>
						);
					})}
				</div>
			</div>
			{selected_menu === POWERED_WITH_PIDGE_MENU_ITEMS.Overview.id && <Overview />}
			{selected_menu === POWERED_WITH_PIDGE_MENU_ITEMS.ReadyToShip.id && <ReadyToShip selected_master_zone={selected_master_zone} />}
			{selected_menu === POWERED_WITH_PIDGE_MENU_ITEMS.OnGround.id && <OngoingOrders selected_master_zone={selected_master_zone} />}
			{selected_menu === POWERED_WITH_PIDGE_MENU_ITEMS.AtWarehouse.id && <AtWarehouse selected_master_zone={selected_master_zone} />}
			{selected_menu === POWERED_WITH_PIDGE_MENU_ITEMS.Terminal.id && <Terminal selected_master_zone={selected_master_zone} />}
		</div>
	);
};

export default PoweredWithPidge;
