import { makeStyles } from '@material-ui/core';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles(() => ({
	label_subtitle: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 },
}));

const CancellationCahngesInfo = () => {
	const classes = useStyles();
	return (
		<div className='d-flex' style={{ flexDirection: 'column', gap: 30 }}>
			<div>
				<Text semi style={{ fontSize: 14, color: theme.colors.lightPurple5 }}>
					Cancellation Charges
				</Text>
				<Text medium className={classes.label_subtitle} style={{ marginTop: 8 }}>
					Cancellation fees apply for delivery fulfilment cancellations after allocation confirmation. These fees can be deducted after a set
					timeframe.
				</Text>
			</div>
			<div className='d-flex' style={{ gap: 30 }}>
				<div>
					<Text semi style={{ fontSize: 14, color: theme.colors.lightPurple5 }}>
						How do the charges work?
					</Text>
					<Text medium className={classes.label_subtitle} style={{ marginTop: 8 }}>
						Choose a percentage increase or a flat fee for your surge duration. The higher amount will be charged to order placing party.
					</Text>
				</div>
				<div className='d-flex justify-content-center align-items-center'>
					<img src={ImageLinks.percentage_vs_rupees} width={78} height={41} />
				</div>
			</div>
		</div>
	);
};

export default CancellationCahngesInfo;
