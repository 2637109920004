import React from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import { Menu, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	menu_list: { padding: 0 },
	status_list_item_disabled: {
		'&:hover': {
			backgroundColor: theme.colors.lightGrey2,
		},
		cursor: 'auto',
	},
	status_list_item_enabled: {
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
		},
	},
	alert_status_wrap: {
		height: 36,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '8px 12px',
		borderRadius: 30,
		cursor: 'pointer',
		gap: 6,
		'&:hover': {
			background: ({ is_active }) =>
				is_active ? 'linear-gradient(178deg, #0F8560 10.13%, #003D2A 104.69%)' : 'linear-gradient(180deg, #F2C16B 0%, #DA8D07 100%)',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15)',
			border: 'none',
		},
	},
	status_text: { fontSize: 16, color: theme.colors.white, marginLeft: 5 },
	status_drop_text: {
		marginLeft: 5,
		fontSize: 12,
	},
	delete_drop_text: {
		marginLeft: 5,
		fontSize: 12,
	},
	delete_list_item_enabled: {
		'&:hover': {
			backgroundColor: theme.colors.lightRed,
			'& .delete-black': {
				display: 'none',
			},
			'& .delete-red': {
				display: 'block !important',
			},
			'& $delete_drop_text': {
				color: theme.colors.red,
			},
		},
	},
});

const ActiveInactiveDropdown = ({ is_active, set_is_active, inactive_text = 'Pause', show_delete = false, is_deleted, set_is_deleted }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const classes = useStyles({ is_active });
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<div
				onClick={handleClick}
				className={classes.alert_status_wrap}
				style={{ backgroundColor: is_deleted ? theme.colors.red : is_active ? theme.colors.green : theme.colors.darkYellow }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<img
						src={is_deleted ? ImageLinks.delete_white : is_active ? ImageLinks.play_white_circle : ImageLinks.pause_white_circle}
						width={16}
						height={16}
					/>
					<Text semi className={classes.status_text}>
						{is_deleted ? 'Deleted' : is_active ? 'Active' : inactive_text}
					</Text>
				</div>
				<img src={ImageLinks.chevron_left_white} width={16} height={16} style={{ transform: open ? 'rotate(90deg)' : 'rotate(270deg)' }} />
			</div>

			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				getContentAnchorEl={null}
				classes={{ list: classes.menu_list }}
				PaperProps={{
					style: {
						borderRadius: 6,
						marginTop: 4,
						width: 114,
					},
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<MenuItem
					onClick={() => {
						if (is_active) return;
						set_is_active(true);
					}}
					classes={{ root: is_active ? classes.status_list_item_disabled : classes.status_list_item_enabled }}>
					<img src={ImageLinks.play_black_circle} width={12} height={12} />
					<Text medium className={classes.status_drop_text} style={is_active ? { color: theme.colors.darkGrey2 } : {}}>
						Active
					</Text>
				</MenuItem>
				<MenuItem
					onClick={() => {
						if (!is_active) return;
						set_is_active(false);
					}}
					classes={{ root: !is_active ? classes.status_list_item_disabled : classes.status_list_item_enabled }}>
					<img src={ImageLinks.pause_black_circle} width={12} height={12} />
					<Text medium className={classes.status_drop_text} style={!is_active ? { color: theme.colors.darkGrey2 } : {}}>
						{inactive_text}
					</Text>
				</MenuItem>
				{show_delete && (
					<MenuItem
						onClick={() => {
							if (is_deleted) return;
							set_is_active('deleted');
						}}
						classes={{ root: !is_active ? classes.status_list_item_disabled : classes.delete_list_item_enabled }}>
						<img className='delete-black' src={ImageLinks.delete_black} width={12} height={12} />
						<img className='delete-red' style={{ display: 'none' }} src={ImageLinks.delete} width={12} height={12} />
						<Text medium className={classes.delete_drop_text} style={!is_active ? { color: theme.colors.darkGrey2 } : {}}>
							Delete
						</Text>
					</MenuItem>
				)}
			</Menu>
		</>
	);
};

export default ActiveInactiveDropdown;
