import { makeStyles, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import OrderModalContext from '../OrderModalContext';
import Text from 'modules/Text';
import Select from 'modules/Select';
import _ from 'lodash';
import { failed_options, get_allowable_status, status_options } from 'modules/modals/OrderDetail/constants';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.colors.white,
		height: 80,
		position: 'absolute',
		bottom: 0,
		width: '90%',
	},

	contained_button: { height: 36, borderRadius: 30 },
	reset_button_wrap: {
		height: 36,
		width: 36,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 16,
		cursor: 'pointer',
	},
	menu_item_class: {
		height: 50,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
}));

function RiderActions({ active_trip }) {
	const {
		pbid_data,
		trip_status,
		set_trip_status,
		failed_reason,
		set_failed_reason,
		show_rider_action_save,
		set_show_rider_action_save,
		on_status_update,
		show_rider_change_modal,
		set_show_rider_change_modal,
	} = useContext(OrderModalContext);
	const classes = useStyles();
	const theme = useTheme();

	const allowable_status = get_allowable_status(
		_.get(pbid_data, `trip_history[${active_trip}].trip_type`),
		_.get(pbid_data, `trip_history[${active_trip}].status_id`),
	);

	useEffect(() => {
		set_trip_status(_.get(pbid_data, `trip_history[${active_trip}].status_id`));
	}, [pbid_data, active_trip]);

	return (
		<div className={classes.container}>
			<Text bold style={{ color: theme.colors.darkGrey2 }}>
				RiderActions:
			</Text>
			<div className='d-flex align-items-center' style={{ gap: 10 }}>
				{trip_status != 5 && (
					<Select
						value={trip_status === 80 ? '' : trip_status}
						defaultOption={{
							title: 'Rider Allocated',
							value: '',
						}}
						options={_.map(
							_.filter(status_options, (item) => {
								return allowable_status.includes(item.value) || trip_status == item.value;
							}),
							(item) => {
								return {
									title: item.title,
									value: item.value,
									disabled: item.value == trip_status,
								};
							},
						)}
						handleChange={(v) => {
							set_trip_status(v);
							set_show_rider_action_save(true);
						}}
						containerStyles={{ height: 40, width: 180 }}
						text_props={{ semi: true }}
						menu_styles={{
							width: 180,
							position: 'absolute',
							top: 180,
							marginTop: -30,
						}}
					/>
				)}
				{trip_status == 5 && (
					<div>
						<Select
							value={failed_reason}
							defaultOption={{
								title: `Select Reason`,
								value: '',
							}}
							options={failed_options}
							handleChange={(v) => {
								set_failed_reason(v);
							}}
							containerStyles={{ height: 40, width: 180 }}
							text_props={{ semi: true }}
							menu_item_class={{ className: classes.menu_item_class }}
							menu_styles={{
								width: 200,
								height: 350,
								marginTop: -50,
							}}
						/>
					</div>
				)}
				{show_rider_action_save && (
					<div
						className={classes.reset_button_wrap}
						onClick={() => {
							set_trip_status(_.get(pbid_data, `trip_history[${active_trip}].status_id`));
							set_show_rider_action_save(false);
						}}>
						<img src={ImageLinks.reverse_arrow_purple} height={16} width={16} />
					</div>
				)}
				{!show_rider_change_modal && !show_rider_action_save && (
					<div style={{ cursor: 'pointer' }}>
						<img src={ImageLinks.change_rider_icon} onClick={() => set_show_rider_change_modal(true)} />
					</div>
				)}
				{}
				{show_rider_action_save && (
					<ContainedButton className={classes.contained_button} onClick={() => on_status_update()}>
						<Text semi style={{ fontSize: 16 }}>
							Change Status
						</Text>
					</ContainedButton>
				)}
			</div>
		</div>
	);
}

export default RiderActions;
