import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';

import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import Text from 'modules/Text';
import theme from 'resources/theme';
import NestedSelect from './NestedSelect/NestedSlect';
import SelectWithInput from './SelectWithInput';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		width: 220,
		height: 172,
		padding: 12,
		flexDirection: 'column',
		alignItems: 'center',
		gap: 12,
		borderRadius: 8,
		background: theme.colors.white,
		position: 'relative',
		zIndex: 999,
		boxShadow: '0px 6px 8px 0px rgba(30, 30, 30, 0.06), 0px 0px 4px 0px rgba(30, 30, 30, 0.04)',
	},
	filter_wrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: 12,
	},
	search_wrapper: {
		display: 'flex',
		width: '100%',
		padding: '8px 12px',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 46,
		border: `1px solid ${theme.colors.lightPurple}`,
		background: theme.colors.white,
	},
	input_filed: {
		border: 'none',
		outline: 'none',
		background: theme.colors.white,
		color: theme.colors.black,
		width: '100%',
		fontSize: 12,
	},
	select_wrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: 4,
	},
	button_wrapper: {
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		width: 220,
		height: 43,
		bottom: '-23px',
		borderRadius: '0px 0px 6px 6px',
		background: theme.colors.white,
		boxShadow: '0px -1.568px 5.488px 0px rgba(0, 0, 0, 0.05)',
	},
	buttons: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		gap: 12,
	},
}));

const MiniFilter = ({ show_searh_bar = false, close, existing_filters, set_selected_filters, container_style }) => {
	const classes = useStyles();
	const component_ref = useRef(null);
	const history = useHistory();

	const query_object = qs.parse(history.location.search.slice(1)) || {};

	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);

	const [search_rider_text, set_search_rider_text] = useState('');
	const [search_riders, set_search_riders] = useState(null);
	const [selected_rider, set_selected_rider] = useState(null);
	const [selected_master_zone, set_selected_master_zone] = useState(null);
	const [selected_business_zone, set_selected_business_zone] = useState(null);

	useEffect(() => {
		const handle_click_outside = (event) => {
			if (component_ref.current && !component_ref.current.contains(event.target)) {
				close();
			}
		};
		document.addEventListener('click', handle_click_outside, true);
		return () => {
			document.removeEventListener('click', handle_click_outside, false);
		};
	}, []);

	useEffect(() => {
		if (!_.isEmpty(existing_filters)) {
			set_selected_business_zone(existing_filters.selected_business_zone);
			set_selected_master_zone(existing_filters.selected_master_zone);
			set_selected_rider(existing_filters.selected_rider);
		}
	}, [existing_filters]);

	useEffect(() => {
		if (!_.isEmpty(search_rider_text)) {
			search_rider();
		}
	}, [search_rider_text]);

	const btn_disable = useMemo(() => {
		let value = true;
		if (!_.isEmpty(selected_rider) || !_.isEmpty(selected_master_zone) || !_.isEmpty(selected_business_zone)) {
			value = false;
		}
		return value;
	});

	const search_rider = async () => {
		try {
			const res = await api_requests.search_rider(zones[0]?.id, {
				search: search_rider_text,
				is_active: true,
			});
			if (res.success) {
				set_search_riders(res.data);
			}
		} catch (err) {}
	};

	const handle_search_click = () => {
		const filter = {
			...(selected_master_zone && { selected_master_zone: selected_master_zone }),
			...(selected_business_zone && { selected_business_zone: selected_business_zone }),
			...(selected_rider && { selected_rider: selected_rider }),
		};
		set_selected_filters(filter);
		close();
	};
	const handle_reset_click = () => {
		if (query_object?.rider_id) {
			history.replace({
				search: '',
			});
		}
		set_selected_filters({});
		set_selected_rider(null);
		set_search_rider_text(null);
		set_selected_business_zone(null);
		set_selected_master_zone(null);
	};

	const handle_change_click = (val) => {
		set_selected_rider(val);
	};

	const handle_cross_click = () => {
		if (query_object?.rider_id) {
			history.replace({
				search: '',
			});
			set_selected_filters({});
		}
		set_selected_rider(null);
	};
	return (
		<div className={classes.container} ref={component_ref} style={{ ...container_style }}>
			<div className={classes.filter_wrapper}>
				{show_searh_bar && (
					<SelectWithInput
						value={selected_rider ? `${selected_rider.rider_name} (${selected_rider.rider_id})` : 'Search Rider Name, ID'}
						inputValue={search_rider_text}
						onChange={(text) => set_search_rider_text(text)}
						options={_.map(search_riders, (rider) => {
							return {
								title: `${rider.rider_name} (${rider.rider_id})`,
								value: rider,
							};
						})}
						handleChange={(val) => handle_change_click(val)}
						className={classes.input_filed}
						mainContainerStyles={{ height: 36, width: '100%' }}
						containerStyles={{ borderRadius: 56, borderColor: theme.colors.lightPurple }}
						show_arrow={false}
						right_icon={
							!_.isEmpty(selected_rider) ? (
								<img src={ImageLinks.cross_filled_purple} width={20} height={20} className='cursor-pointer' onClick={handle_cross_click} />
							) : _.isEmpty(search_rider_text) ? (
								<img src={ImageLinks.search_light_grey} width={20} height={20} />
							) : (
								<img src={ImageLinks.arrow_right_light_purple} alt='purple-right-arrow' width={20} height={20} className='cursor-pointer' />
							)
						}
					/>
				)}
				<div className={classes.select_wrapper}>
					<div className={classes.select_text}>
						<Text>Select Zone</Text>
					</div>
					<NestedSelect
						zones={zones}
						selected_master_zone={selected_master_zone}
						set_selected_master_zone={set_selected_master_zone}
						selected_business_zone={selected_business_zone}
						set_selected_business_zone={set_selected_business_zone}
					/>
				</div>
			</div>
			<div className={classes.button_wrapper}>
				<div className={classes.buttons}>
					<Button
						size='extra_small'
						text='Reset'
						type='outlined'
						left_icon={btn_disable ? ImageLinks.refresh_single_arrow_grey : ImageLinks.refresh_single_arrow_purple}
						disabled={btn_disable}
						onClick={handle_reset_click}
					/>

					<Button size='extra_small' text='Search' type='round' disabled={btn_disable} onClick={handle_search_click} />
				</div>
			</div>
		</div>
	);
};

export default MiniFilter;
