import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import ContainedButton from 'modules/ContainedButton';
import OutlinedButton from 'modules/OutlinedButton';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import { TYPES } from '../helper';

const useStyles = makeStyles(() => ({
	cta_btn_schedule: {
		borderRadius: 30,
		height: 47,
		width: 136,
		padding: 0,
	},
	cta_btn_allocate: {
		borderRadius: 30,
		height: 47,
		width: 136,
		padding: 0,
	},
}));

const MENU = {
	unallocated: 'unallocated',
	active: 'active',
	compare: 'compare',
	add: 'add',
};

const Start = ({
	type,
	is_allocate_now_enabled,
	handle_allocate_rider,
	rider_id,
	handle_link_rider,
	handle_unlink_rider,
	selected_rider_captive,
	selected_menu,
}) => {
	const classes = useStyles();
	return (
		<div style={{ background: theme.colors.white, width: '100%' }}>
			<div
				style={{
					boxShadow: '0px -4px 14px rgba(0, 0, 0, 0.05)',
					display: 'flex',
					justifyContent: 'flex-end',
					background: theme.colors.white,
					padding: '20px 35px',
				}}>
				{selected_menu != MENU.active && type === TYPES.manual && (
					<div>
						{rider_id ? (
							<Button
								type='outlined'
								text='Unlink Rider'
								size='large'
								onClick={() => handle_unlink_rider()}
								left_icon={ImageLinks.unlink_icon_primary}
							/>
						) : (
							<Button type='outlined' text='Link Rider' size='large' onClick={handle_link_rider} disabled={!selected_rider_captive} />
						)}
					</div>
				)}
				<Button
					type='round'
					text='Allocate Now'
					size='large'
					onClick={handle_allocate_rider}
					disabled={!is_allocate_now_enabled}
					style={{ marginLeft: 8 }}
				/>
			</div>
		</div>
	);
};

export default Start;
