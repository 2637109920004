import { makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import Text from './Text';
import ImageLinks from 'assets/images/ImageLinks';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1200,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: '100%',
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1201,
	},
	content: {
		borderRadius: 20,
		padding: 20,
		backgroundColor: theme.colors.white,
		minWidth: 500,
	},
}));

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 100,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}

function ImageCropModal({ img_src, close, aspect = 1, on_replace = () => {}, on_save = () => {} }) {
	const classes = useStyles();
	const [completed_crop, set_completed_crop] = useState();
	const [image_render_ratio, set_image_render_ratio] = useState({ height: 1, width: 1 });
	const [crop, set_crop] = useState({});

	const on_image_load = (e) => {
		if (aspect) {
			const { width, height } = e.currentTarget;
			set_crop(centerAspectCrop(width, height, aspect));
			set_completed_crop(convertToPixelCrop(centerAspectCrop(width, height, aspect), width, height));
		}
		set_image_render_ratio({ height: e.target.naturalHeight / e.target.height, width: e.target.naturalWidth / e.target.width });
	};

	const download_crop = () => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		const img = new Image();

		canvas.width = completed_crop.width;
		canvas.height = completed_crop.height;

		img.onload = function () {
			ctx.drawImage(
				img,
				completed_crop.x * image_render_ratio.width,
				completed_crop.y * image_render_ratio.height,
				completed_crop.width * image_render_ratio.width,
				completed_crop.height * image_render_ratio.height,
				0,
				0,
				completed_crop.width,
				completed_crop.height,
			);

			const croppedBase64 = canvas.toDataURL();
			on_save(base64ToFile(croppedBase64, 'image_file.png'));
		};
		img.src = img_src;
	};

	function base64ToFile(base64String, filename) {
		const byteCharacters = atob(base64String.split(',')[1]);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: 'image/png' });
		return new File([blob], filename, { type: 'image/png' });
	}

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div className={classes.content}>
					<div className='d-flex justify-content-between'>
						<Text bold style={{ fontSize: 20 }}>
							Edit Image
						</Text>
						<img src={ImageLinks.crossBlack} onClick={close} />
					</div>
					<div className='d-flex justify-content-center' style={{ margin: '20px 0' }}>
						<ReactCrop
							crop={crop}
							onChange={(_, percentCrop) => set_crop(percentCrop)}
							onComplete={(c) => {
								set_completed_crop(c);
							}}
							aspect={aspect}>
							<img
								alt='Crop me'
								src={img_src}
								onLoad={on_image_load}
								style={{ maxHeight: '40vh', minHeight: '20vh', width: 'auto', maxWidth: '40vw' }}
							/>
						</ReactCrop>
					</div>
					<div className='d-flex justify-content-center'>
						<Button
							size='small'
							type='outlined'
							text='Replace'
							style={{ padding: '8px 32px' }}
							left_icon={ImageLinks.upload_purple}
							onClick={on_replace}
						/>
						<Button size='small' text='Save' style={{ marginLeft: 10, padding: '8px 32px' }} onClick={download_crop} />
					</div>
				</div>
			</div>
		</>
	);
}

export default ImageCropModal;
