import React from 'react';
import Text from 'modules/Text';
import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles(() => ({}));

const PincodePricingCalculator = () => {
	const classes = useStyles();
	return (
		<div style={{ flex: 1 }}>
			<div className='align-items-center justify-content-between'>
				<Text bold style={{ fontSize: 16 }}>
					Calculator
				</Text>
				<img src={ImageLinks.info_filled_purple} width={16} height={16} />
			</div>
			<div className={'dashed-line'}></div>
		</div>
	);
};

export default PincodePricingCalculator;
