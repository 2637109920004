import { makeStyles, useTheme } from '@material-ui/core';
import Text from 'modules/Text';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		width: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		height: 128,
	},
}));

function EmptyState() {
	const theme = useTheme();
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div>
				<Text bold style={{ color: theme.colors.primary, fontSize: 16 }}>
					No data available!
				</Text>
				<Text semi style={{ color: theme.colors.darkGrey2, fontSize: 10 }}>
					We'll analyse again.
				</Text>
			</div>
		</div>
	);
}

export default EmptyState;
