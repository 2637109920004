import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 500,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 30,
		zIndex: 1200,
		overflowY: 'auto',
		paddingBottom: 120,
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
	},
	divider: {
		height: 1,
		width: '100%',
		backgroundColor: theme.colors.lightGrey5,
	},
	stats: { display: 'flex', minHeight: 70, marginTop: 20 },
	stats_box: { backgroundColor: theme.colors.white, display: 'flex', flex: 1, borderRadius: 5, overflow: 'hidden' },
	stats_wrap: {
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.colors.lightPurple,
		padding: 8,
		gap: 10,
	},
	stats_label: { color: theme.colors.lightGrey6, fontSize: 12, lineHeight: 1 },
	edit_wrap: { padding: '20px 10px', width: 108 },
	box_titles: { color: theme.colors.darkGrey2, fontSize: 14, marginTop: 30 },
	criterion_box: { boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)', borderRadius: 6, padding: 12, marginTop: 10 },
	criterion_header: { fontSize: 14 },
	criterion_sub_title: { fontSize: 12, color: theme.colors.lightGrey2 },
	data_inputs: {
		width: 90,
		height: 28,
		'& .MuiInputBase-root': {
			height: 28,
		},
	},
	slider: {
		alignItems: 'center',
		padding: 2,
		'& :hover': {
			'& .MuiSlider-valueLabel': {
				display: 'block',
			},
		},
		'& .MuiSlider-track': {
			height: 4,
			borderRadius: 4,
		},
		'& .MuiSlider-thumb': {
			height: 20,
			width: 20,
			marginTop: -8,
		},
		'& .MuiSlider-rail': {
			height: 4,
			borderRadius: 4,
			backgroundColor: theme.colors.lightPurple,
		},
		'& .MuiSlider-mark': {
			marginTop: 1,
			opacity: 0.38,
		},
		'& .MuiSlider-valueLabel': {
			height: 34,
			width: 28,
			marginLeft: 7,
			display: 'none',
		},
	},
	process_wrapper: {
		position: 'absolute',
		right: 0,
		bottom: 0,
		height: 100,
		width: 500,
		boxShadow: '0px -4px 14px rgba(0, 0, 0, 0.05)',
		padding: '16px 28px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.colors.white,
	},
	action_button: { position: 'absolute', right: 30, bottom: 30, borderRadius: 30 },
	progress_bar: { width: '80%', marginTop: 10, height: 6, borderRadius: 3 },
	title_text: { color: theme.colors.green, fontSize: 16, display: 'flex', alignItems: 'center', gap: 5 },
	error_box: {
		height: 30,
		width: '100%',
		backgroundColor: theme.colors.lightRed,
		borderRadius: '0px 0px 8px 8px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: 5,
	},
	manage_link: { color: theme.colors.primary, fontSize: 12, cursor: 'pointer', textDecoration: 'underline' },
}));

export default useStyles;
