import _ from 'lodash';
import QueryString from 'qs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import utils from 'resources/utils';

const confirm_modal_details = {
	open: false,
	header_title: '',
	title: '',
	positive_text: '',
	negative_text: '',
	confirm_acion: () => {},
	is_error_msg: false,
	modal_icon: null,
};

const useCreateNetwork = () => {
	const history = useHistory();
	const query_object = QueryString.parse(history.location.search.slice(1));
	const [network_name, set_network_name] = useState('');
	// const [is_name_valid, set_is_name_valid] = useState(null); //will be added in public networks
	const [network_desc, set_network_desc] = useState('');
	const [network_image, set_network_image] = useState('');
	const [show_display_modal, set_show_display_modal] = useState(false);
	const [display_configs, set_display_configs] = useState(null);
	const [serviciblity_area_tab, set_serviciblity_area_tab] = useState(0);
	const [valid_pincodes, set_valid_pincodes] = useState([]);
	const [selected_menu, set_selected_menu] = useState('areas');
	const [show_lower_section, set_show_lower_section] = useState(false);
	const [serviciblity_config, set_serviciblity_config] = useState({});
	const [confirm_close_modal, set_confirm_close_modal] = useState(_.cloneDeep(confirm_modal_details));
	const [create_api_state_status, set_create_api_state_status] = useState({});
	const [invite_network_id, set_invite_network_id] = useState(null);
	const [edit_network_id, set_edit_network_id] = useState(null);
	const [edit_network_details, set_edit_network_details] = useState(null);
	const [is_participant_added, set_is_participant_added] = useState(false);
	const [can_choose_algo, set_can_choose_algo] = useState(false);
	const page_block_ref = useRef(null);
	const create_network_response_ref = useRef(null);
	const saved_press_ref = useRef(false);
	const [invite_network_config, set_invite_network_config] = useState({});
	const [edit_network_config, set_edit_network_config] = useState({});
	const [is_invite_allocation_updated, set_is_invite_allocation_updated] = useState(false);
	const [is_invite_pricing_updated, set_is_invite_pricing_updated] = useState(false);
	const [is_invite_service_updated, set_is_invite_service_updated] = useState(false);
	const [is_invite_support_updated, set_is_invite_support_updated] = useState(false);
	const [is_invite_surge_price, set_is_invite_surge_price] = useState(false);
	const [is_invite_cancellation_price, set_is_invite_cancellation_price] = useState(false);
	const [is_invite_reattempt_config, set_is_invite_reattempt_config] = useState(false);

	//invite participant screen state
	const [invite_code, set_invite_code] = useState('');
	const [searched_biz, set_searched_biz] = useState([]);
	const [selected_biz, set_selected_biz] = useState(null);
	const biz_search_ref = useRef(null);

	//invite participant roles
	const [can_lend_rider, set_can_lend_rider] = useState(false);
	const [can_place_order, set_can_place_order] = useState(false);
	const [can_manage, set_can_manage] = useState(false);

	//allocation states
	const [selected_allocation_tab, set_selected_allocation_tab] = useState(0);
	const [selected_surge_pricing_tab, set_selected_surge_pricing_tab] = useState(0);
	const [allocation_data, set_allocation_data] = useState({
		algo: '', // free, gig, best
		allow_on_demand: false, // immediate
		allow_schedule: false,
		max_pickup_tasks: 1,
		max_drop_tasks: '1', // 1 in case of toggle off
		max_cod_limit: null, //send null for now since no UI
		max_volume: null, //send null for now since no UI
		max_weight: null, //send null for now since no UI
		max_volume_per_order: null,
		max_weight_per_order: null,
		max_distance_per_order: null,
		allow_manifest: false,
	});

	//support function states
	const [selected_func_tab, set_selected_func_tab] = useState(0);
	const [grievance_officer_info, set_grievance_officer_info] = useState([]);
	const [customer_officer_info, set_customer_officer_info] = useState([]);
	const [rider_officer_info, set_rider_officer_info] = useState([]);

	//pricing state
	const [pricing, set_pricing] = useState({});
	const [is_business_searched, set_is_business_searched] = useState(true);
	const [surge_pricing, set_surge_pricing] = useState({});
	const [cancellation_charges, set_cancellation_charges] = useState({});
	const [is_surge_pricing_toggle, set_is_surge_pricing_toggle] = useState(!_.isEmpty(surge_pricing));
	const [is_cancellation_charges_toggle, set_is_cancellation_charges_toggle] = useState(!_.isEmpty(surge_pricing));

	const [show_success_modal, set_show_success_modal] = useState(false);
	const [is_service_api_done, set_is_service_api_done] = useState(false);
	const [is_pricing_api_done, set_is_pricing_api_done] = useState(false);
	const [is_allocate_api_done, set_is_allocate_api_done] = useState(false);
	const [is_support_api_done, set_is_support_api_done] = useState(false);
	const [is_surge_pricing_api_done, set_is_surge_pricing_api_done] = useState(false);
	const [is_cancellation_charges_api_done, set_is_cancellation_charges_api_done] = useState(false);
	const [is_reattempt_config_api_done, set_is_reattempt_config_api_done] = useState(false);

	//undelivered Order state
	// const [reattempt_config, set_reattempt_config] = useState({ return_to_sender: true, number_of_reattempts: 1 });
	const [reattempt_config_data, set_reattempt_config_data] = useState({});

	useEffect(() => {
		set_can_lend_rider(_.get(history, 'location.state.can_lend_rider', false));
		set_can_manage(_.get(history, 'location.state.can_manage', false));
		set_can_place_order(_.get(history, 'location.state.can_place_order', false));
		if (query_object.brand_name) {
			handle_biz_select({ brand_name: query_object.brand_name, id: query_object.business_id });
		}
	}, []);

	useEffect(() => {
		page_block_ref.current = history.block(handle_page_nav);
		return () => {
			page_block_ref.current();
		};
	}, [invite_network_id, edit_network_id]);

	const invite_roles_count = _.filter([can_lend_rider, can_place_order, can_manage], (item) => {
		return item;
	}).length;

	const handle_page_nav = (location) => {
		const show_confirmation = saved_press_ref.current ? false : true;
		if (show_confirmation) {
			set_confirm_close_modal({
				open: true,
				header_title: invite_network_id ? 'Exit participant invitation' : 'Exit Network Creation',
				title: invite_network_id
					? 'Are you sure you want abandon inviting participant? No progress will be saved.'
					: edit_network_id
					? 'Are you sure you want abandon editing network configs? No progress will be saved.'
					: 'Are you sure you want abandon creating a new network? No progress will be saved.',
				positive_text: 'Exit',
				negative_text: 'Cancel',
				is_error_msg: true,
				confirm_acion: () => {
					page_block_ref.current();
					history.push(location.pathname);
					return true;
				},
			});
			return false;
		}
		return true;
	};

	const handle_network_img = (img_str, configs) => {
		set_network_image(img_str);
		set_display_configs(configs);
	};

	const handle_network_name = (e) => {
		const value = e.target.value;
		if (value.length > 30) return;
		set_network_name(value);
		// clearTimeout(netowrk_name_search_ref.current);

		// if (!value) {
		// 	set_is_name_valid(null);
		// 	return;
		// }
		// if (_.get(display_configs, 'display_style', '') === 'avatar' && _.get(display_configs, 'avatar_style', '') === 'monogram') {
		// 	set_network_image('');
		// 	set_display_configs(null);
		// }

		// netowrk_name_search_ref.current = setTimeout(async () => {
		// 	const response = await api_requests.get_network_name_availablity({ name: value });
		// 	if (_.get(response, 'data.available', false)) set_is_name_valid(true);
		// 	else set_is_name_valid(false);
		// }, 500);
	};

	const handle_proceed = () => {
		set_show_lower_section(true);
	};

	const handle_all_pincodes_delete = () => {
		set_confirm_close_modal({
			open: true,
			header_title: `Delete all pincodes`,
			title: 'Are you sure you want to delete all pincodes? All progress will be lost',
			positive_text: 'Delete',
			negative_text: 'Cancel',
			is_error_msg: true,
			confirm_acion: () => {
				set_valid_pincodes([]);
				flush_global_service_config();
			},
			modal_icon: null,
		});
	};

	const handle_individual_pincode_delete = (pincode_index) => {
		let pincode_arr = _.cloneDeep(valid_pincodes);
		pincode_arr.splice(pincode_index, 1);
		if (pincode_arr.length === 0) flush_global_service_config();
		set_valid_pincodes(pincode_arr);
	};

	const handle_serviciblity_config = (flow, config) => {
		set_serviciblity_config({
			...serviciblity_config,
			[flow]: config,
		});
		set_is_invite_service_updated(true);
	};

	const flush_global_service_config = () => {
		const key_name = serviciblity_area_tab === 0 ? 'pickup' : 'delivery';
		const _serviciblity_config = _.cloneDeep(serviciblity_config);
		if (_serviciblity_config[key_name]) delete _serviciblity_config[key_name];
		set_serviciblity_config(_serviciblity_config);
	};

	//invite fucntions
	const handle_invite_code = async (e) => {
		const value = e.target.value;
		set_invite_code(value);
		set_selected_biz(null);
		clearTimeout(biz_search_ref.current);

		if (!value) return;

		biz_search_ref.current = setTimeout(async () => {
			let value_found = true;
			if (value_found)
				try {
					const res = await api_requests.invite_search_for_network(value);
					if (res.success) {
						if (res.data) {
							set_searched_biz([res.data]);
							set_is_business_searched(true);
						} else {
							set_searched_biz([]);
							set_is_business_searched(false);
						}
					}
				} catch (err) {
					set_searched_biz([]);
				}
			else {
				set_is_business_searched(false);
			}
		}, 500);
	};

	const handle_biz_select = (item) => {
		set_selected_biz(item);
		set_searched_biz([]);
	};

	const format_poly_data = (polygons_list, add_id = false, is_pickup) => {
		let result = [];

		for (let i = 0; i < polygons_list.length; i++) {
			const polys = polygons_list[i];
			const obj = {};

			const poly_name = polys.name;
			const poly_path = polys.getPath();
			const coordinates = [];

			for (let j = 0; j < poly_path.length; j++) {
				coordinates.push([poly_path.getAt(j).lng(), poly_path.getAt(j).lat()]);
			}
			const first_coordinate = coordinates[0];
			const last_coordinate = coordinates[coordinates.length - 1];
			if (first_coordinate[0] != last_coordinate[0] || first_coordinate[1] != last_coordinate[1]) coordinates.push(first_coordinate);
			obj.label = poly_name;
			obj.polygon = {
				type: 'Polygon',
				coordinates: [coordinates],
			};
			if (add_id && polys.id) obj.id = polys.id;
			result.push(obj);
		}
		const obj = {
			rule: 'POLYGON',
			type: is_pickup ? 'PICKUP' : 'DROP',
			values: result,
		};
		return obj;
	};

	const get_task_progress = (index, check_for_api_progress = false) => {
		let value = 0;
		if (index === 0) {
			const total_tabs = 3;
			let tabs_filled = 0;
			if (!_.isEmpty(display_configs) || network_image != '') tabs_filled += 1;
			if (!_.isEmpty(network_name.trim())) tabs_filled += 1;
			if (network_desc.length <= 100 && !_.isEmpty(network_desc)) tabs_filled += 1;
			if (tabs_filled > total_tabs) tabs_filled = total_tabs;
			value = (tabs_filled / total_tabs) * 100;
		}

		if (index === 1) {
			const total_tabs = check_for_api_progress ? 3 : 2;
			let tabs_filled = 0;
			if (!_.isEmpty(serviciblity_config?.pickup)) tabs_filled += 1;
			if (!_.isEmpty(serviciblity_config?.delivery)) tabs_filled += 1;
			if (check_for_api_progress && is_service_api_done) tabs_filled += 1;
			if (tabs_filled > total_tabs) tabs_filled = total_tabs;
			value = (tabs_filled / total_tabs) * 100;
		}
		if (index === 2) {
			const total_tabs = check_for_api_progress ? 2 : 1;
			let tabs_filled = 0;
			if (!_.isEmpty(pricing)) tabs_filled += 1;
			if (check_for_api_progress && is_pricing_api_done) tabs_filled += 1;
			if (tabs_filled > total_tabs) tabs_filled = total_tabs;
			value = (tabs_filled / total_tabs) * 100;

			// value = _.isEmpty(pricing) ? 0 : 100;
		}

		if (index === 3) {
			const total_tabs = check_for_api_progress ? 3 : 2;
			let tabs_filled = 0;
			if (
				(!_.isEmpty(surge_pricing) && surge_pricing?.rule == 1 && !is_surge_pricing_toggle && _.isEmpty(surge_pricing.slab)) ||
				(!_.isEmpty(surge_pricing) && surge_pricing?.rule == 1 && _.isEmpty(surge_pricing.slab))
			) {
				tabs_filled += 3;
			}

			if (!_.isEmpty(surge_pricing) && surge_pricing.rule != 1 && surge_pricing?.slab?.day_of_week?.length > 0) tabs_filled += 1;
			if (!_.isEmpty(surge_pricing) && surge_pricing.rule != 1 && surge_pricing?.slab?.slot_of_day?.length > 0) tabs_filled += 1;
			if (check_for_api_progress && is_surge_pricing_api_done) tabs_filled += 1;
			if (tabs_filled > total_tabs) tabs_filled = total_tabs;
			value = (tabs_filled / total_tabs) * 100;
		}

		if (index === 4) {
			const total_tabs = check_for_api_progress ? 2 : 1;
			let tabs_filled = 0;
			if (!_.isEmpty(cancellation_charges) && !is_cancellation_charges_toggle && cancellation_charges?.rule == 1 && _.isEmpty(cancellation_charges.slab))
				tabs_filled += 2;
			if (!_.isEmpty(cancellation_charges)) tabs_filled += 1;
			if (check_for_api_progress && is_cancellation_charges_api_done) tabs_filled += 1;
			if (tabs_filled > total_tabs) tabs_filled = total_tabs;
			value = (tabs_filled / total_tabs) * 100;
		}

		if (index === 5) {
			const total_tabs = check_for_api_progress ? 4 : 3;
			let tabs_filled = 1;
			if (!_.isEmpty(allocation_data.algo)) tabs_filled += 1;
			if (allocation_data.max_drop_tasks >= 1) tabs_filled += 1;
			if (check_for_api_progress && is_allocate_api_done) tabs_filled += 1;
			if (tabs_filled > total_tabs) tabs_filled = total_tabs;
			value = (tabs_filled / total_tabs) * 100;
		}
		if (index === 6) {
			const total_tabs = check_for_api_progress ? 4 : 3;
			let tabs_filled = 0;
			if (grievance_officer_info.length > 0) tabs_filled += 1;
			if (customer_officer_info.length > 0) tabs_filled += 1;
			if (rider_officer_info.length > 0) tabs_filled += 1;
			if (check_for_api_progress && is_support_api_done) tabs_filled += 1;
			if (tabs_filled > total_tabs) tabs_filled = total_tabs;
			value = (tabs_filled / total_tabs) * 100;
		}

		if (index === 7) {
			const total_tabs = check_for_api_progress ? 2 : 1;
			let tabs_filled = 0;
			if (!_.isEmpty(reattempt_config_data)) tabs_filled += 1;
			if (check_for_api_progress && is_reattempt_config_api_done) tabs_filled += 1;
			if (tabs_filled > total_tabs) tabs_filled = total_tabs;
			value = (tabs_filled / total_tabs) * 100;
		}
		return value;
	};

	const get_support_officer = (data, key) => {
		const list = _.filter(data, (item) => {
			return item.type == key;
		});
		if (list.length > 0) {
			return list[0];
		} else return {};
	};

	const convert_to_google_maps_polygon_object = (poly_list) => {
		const result = [];
		for (let i = 0; i < poly_list.length; i++) {
			const element = poly_list[i];
			const coords = element.polygon.coordinates;
			const flight_plan_coords = [
				..._.map(coords[0], (item) => {
					return { lat: item[1], lng: item[0] };
				}),
			];
			const flight_path = new window.google.maps.Polygon({
				path: flight_plan_coords,
				geodesic: true,
				strokeColor: theme.colors.primary,
				strokeOpacity: 1.0,
				strokeWeight: 2,
				fillColor: theme.colors.primary,
				name: element.label,
				polygon_index: i,
				id: element.id,
			});

			result.push(flight_path);
		}
		return result;
	};

	const format_sort_pricing_data = (data) => {
		const groups = _.get(data, 'groups', []);
		const updated_groups = [];
		for (let i = 0; i < groups.length; i++) {
			const element = groups[i];
			const slabs = element.slabs;
			slabs.sort((a, b) => a.volume - b.volume);
			const new_group = { ...element, slabs };
			updated_groups.push(new_group);
		}
		const formatted_pricing = {
			...data,
			groups: updated_groups,
		};
		return formatted_pricing;
	};

	const set_edit_network_flow = async (id) => {
		set_edit_network_id(id);
		try {
			const res = await api_requests.get_single_network_details(id);
			set_edit_network_details(res.data);
			set_network_name(res.data.name);
			set_network_desc(res.data.description);
			set_display_configs(res.data.avatar.configs);
			set_network_image(res.data.avatar.image);
			const network_config = await api_requests.get_network_configs(id);
			set_edit_network_config(network_config.data);
			const pickup_config =
				network_config.data.pickup_serviceability.rule == 'POLYGON'
					? { polygon: convert_to_google_maps_polygon_object(network_config.data.pickup_serviceability.values), pincodes: [] }
					: {
							polygon: [],
							pincodes: _.map(network_config.data.pickup_serviceability.values, (value) => {
								return value.pincode;
							}),
					  };
			const drop_config =
				network_config.data.drop_serviceability.rule == 'POLYGON'
					? { polygon: convert_to_google_maps_polygon_object(network_config.data.drop_serviceability.values), pincodes: [] }
					: {
							polygon: [],
							pincodes: _.map(network_config.data.drop_serviceability.values, (value) => {
								return value.pincode;
							}),
					  };
			set_serviciblity_config({ pickup: pickup_config, delivery: drop_config });
			set_pricing(network_config.data.pricing);
			set_surge_pricing(network_config?.data?.surge_pricing);
			set_cancellation_charges(network_config?.data?.cancel_pricing);
			set_reattempt_config_data(network_config?.data?.reattempt_config);

			set_grievance_officer_info([get_support_officer(network_config.data.support_officers, 'grievance')]);
			set_customer_officer_info([get_support_officer(network_config.data.support_officers, 'consumer')]);
			set_rider_officer_info([get_support_officer(network_config.data.support_officers, 'rider')]);
			set_allocation_data({
				...network_config.data.allocation,
				max_drop_tasks: network_config.data.allocation.max_drop_tasks + '',
				max_weight_per_order: network_config.data.allocation.max_weight_per_order
					? network_config.data.allocation.max_weight_per_order / 1000
					: network_config.data.allocation.max_weight_per_order,
				max_distance_per_order: network_config.data.allocation.max_distance_per_order
					? network_config.data.allocation.max_distance_per_order / 1000
					: network_config.data.allocation.max_distance_per_order,
			});
			set_is_service_api_done(true);
			set_is_pricing_api_done(true);
			set_is_allocate_api_done(true);
			set_is_support_api_done(true);
			set_is_surge_pricing_api_done(true);
			set_is_cancellation_charges_api_done(true);
			set_is_reattempt_config_api_done(true);
			setTimeout(handle_proceed, 200);
		} catch (err) {}
	};

	const set_invite_flow = async (id) => {
		set_invite_network_id(id);
		let _params = {};
		if (query_object.brand_name) {
			_params = { owner_id: query_object.business_id };
		}
		try {
			const res = await api_requests.get_network_configs(id, _params);
			set_invite_network_config(res.data);
			set_allocation_data({
				...res.data.allocation,
				max_drop_tasks: res.data.allocation.max_drop_tasks + '',
				max_weight_per_order: res.data.allocation.max_weight_per_order
					? res.data.allocation.max_weight_per_order / 1000
					: res.data.allocation.max_weight_per_order,
				max_distance_per_order: res.data.allocation.max_distance_per_order
					? res.data.allocation.max_distance_per_order / 1000
					: res.data.allocation.max_distance_per_order,
			});
			set_grievance_officer_info([get_support_officer(res.data.support_officers, 'grievance')]);
			set_customer_officer_info([get_support_officer(res.data.support_officers, 'consumer')]);
			set_rider_officer_info([get_support_officer(res.data.support_officers, 'rider')]);
			const pickup_config =
				res.data.pickup_serviceability.rule == 'POLYGON'
					? { polygon: convert_to_google_maps_polygon_object(res.data.pickup_serviceability.values), pincodes: [] }
					: {
							polygon: [],
							pincodes: _.map(res.data.pickup_serviceability.values, (value) => {
								return value.pincode;
							}),
					  };
			const drop_config =
				res.data.drop_serviceability.rule == 'POLYGON'
					? { polygon: convert_to_google_maps_polygon_object(res.data.drop_serviceability.values), pincodes: [] }
					: {
							polygon: [],
							pincodes: _.map(res.data.drop_serviceability.values, (value) => {
								return value.pincode;
							}),
					  };
			set_serviciblity_config({ pickup: pickup_config, delivery: drop_config });
			set_pricing(res.data.pricing);
			set_surge_pricing(res.data.surge_pricing);
			set_cancellation_charges(res.data.cancel_pricing);
			set_reattempt_config_data(res.data.reattempt_config);
			if (query_object.brand_name) {
				handle_proceed();
				set_is_participant_added(true);
			}
		} catch (err) {}
	};

	const handle_create_network = async () => {
		const create_payload = {
			name: network_name,
			description: network_desc,
			type: 1,
			avatar: utils.remove_null_and_empty_from_obj({
				configs: display_configs,
				image: network_image,
			}),
		};
		try {
			const create_response = await api_requests.create_network(create_payload);
			create_network_response_ref.current = create_response;
			handle_proceed();
		} catch (error) {}
	};

	const handle_go_live = async () => {
		set_show_success_modal(true);
		try {
			saved_press_ref.current = true;
			const id = _.get(create_network_response_ref.current, 'data.network_id', null);
			const update_config_response = await api_requests.update_network_active(id);
			if (update_config_response?.data?.success) {
				create_network_response_ref.current = null;
				set_create_api_state_status({ is_success: true, message: '' });
			} else {
				set_create_api_state_status({ is_success: false, message: 'Error occurred during account setup, please try again.' });
				saved_press_ref.current = false;
			}
		} catch (error) {
			const msg = _.get(error, 'response.data.error.details.0.message', 'Error occurred during account setup, please try again.');
			set_create_api_state_status({ is_success: false, message: msg });
			saved_press_ref.current = false;
		}
	};

	const handle_edit_network_config = async () => {
		page_block_ref.current();
		history.push('/networks');
		return;
	};

	const handle_add_participant_role = async () => {
		try {
			const res = await api_requests.add_participant(invite_network_id, {
				business_id: selected_biz.id,
				can_lend_rider,
				can_place_order,
				can_manage,
			});
			set_is_participant_added(true);
			handle_proceed();
		} catch (err) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Unable to invite participant',
				title: 'Error occurred during account setup, please try again.',
				positive_text: 'Try Again',
				is_error_msg: true,
				confirm_acion: () => {
					return true;
				},
			});
		}
	};

	const handle_add_participant = async () => {
		if (query_object.brand_name) {
			update_participant_role();
			return;
		}
		if (is_participant_added) {
			page_block_ref.current();
			history.push('/networks');
			return;
		}
	};

	const handle_success = () => {
		history.goBack();
	};

	const handle_confirm_modal_close = () => {
		set_confirm_close_modal(_.cloneDeep(confirm_modal_details));
	};

	const update_participant_role = async () => {
		try {
			const data = {
				business_id: parseInt(query_object.business_id),
				can_lend_rider: can_lend_rider,
				can_place_order: can_place_order,
				can_manage: can_manage,
			};
			const res = await api_requests.update_participant(query_object.network_id, query_object.id, data);
			set_is_participant_added(true);
			page_block_ref.current();
			history.push('/networks');
			return;
		} catch (err) {}
	};

	const handle_remove_access = async () => {
		try {
			const res = await api_requests.delete_participant(query_object.network_id, query_object.id);
			page_block_ref.current();
			history.push('/networks');
		} catch (err) {}
	};

	const handle_network_delete = () => {
		set_confirm_close_modal({
			open: true,
			header_title: `Delete Network`,
			title: `Are you sure you want to remove ${edit_network_details?.name} (${edit_network_id})? All information and settings will be lost.`,
			positive_text: 'Delete',
			negative_text: 'Cancel',
			is_error_msg: true,
			confirm_acion: delete_network,
			modal_icon: null,
		});
	};

	const delete_network = async () => {
		try {
			const response = await api_requests.delete_network(edit_network_id);
			saved_press_ref.current = true;
			history.goBack();
		} catch (error) {}
	};

	const handle_serviciblity_api = () => {
		try {
			let is_create_flow = true;
			let id = null;
			const pickup_pincodes = _.get(serviciblity_config, 'pickup.pincodes', []);
			const pickup_polys = _.get(serviciblity_config, 'pickup.polygon', []);
			const is_pickup_flow_pincode = pickup_pincodes.length > 0;

			const formatted_pickup_data = is_pickup_flow_pincode
				? _.map(pickup_pincodes, (item) => ({
						label: item,
						pincode: item,
				  }))
				: format_poly_data(pickup_polys, edit_network_id ? true : false, true);

			const delivery_pincodes = _.get(serviciblity_config, 'delivery.pincodes', []);
			const delivery_polys = _.get(serviciblity_config, 'delivery.polygon', []);
			const is_delivery_flow_pincode = delivery_pincodes.length > 0;

			const formatted_delivery_data = is_delivery_flow_pincode
				? _.map(delivery_pincodes, (item) => ({
						label: item,
						pincode: item,
				  }))
				: format_poly_data(delivery_polys, edit_network_id ? true : false, false);

			const pickup_serviceability = is_pickup_flow_pincode
				? {
						rule: 'PINCODE',
						type: 'PICKUP',
						values: formatted_pickup_data,
				  }
				: formatted_pickup_data;

			const drop_serviceability = is_delivery_flow_pincode
				? {
						rule: 'PINCODE',
						type: 'DROP',
						values: formatted_delivery_data,
				  }
				: formatted_delivery_data;

			const config_payload = {
				default: true,
				pickup_serviceability,
				drop_serviceability,
			};

			if (edit_network_id) {
				pickup_serviceability.id = edit_network_config.pickup_serviceability.id;
				drop_serviceability.id = edit_network_config.drop_serviceability.id;
				is_create_flow = false;
				id = edit_network_id;
			}
			if (invite_network_id) {
				config_payload.owner = { id: selected_biz.id, type: selected_biz.type };
				config_payload.default = false;

				const is_pickup_ownership_user = _.get(invite_network_config, 'ownership.pickup_serviceability', '') == 'user';
				const is_drop_ownership_user = _.get(invite_network_config, 'ownership.drop_serviceability', '') == 'user';

				if (is_pickup_ownership_user) pickup_serviceability.id = invite_network_config.pickup_serviceability.id;
				if (is_drop_ownership_user) drop_serviceability.id = invite_network_config.drop_serviceability.id;

				if (is_pickup_ownership_user || is_drop_ownership_user) is_create_flow = false;
				id = invite_network_id;
			}

			if (_.isEmpty(id)) id = _.get(create_network_response_ref.current, 'data.network_id', null);

			const response = is_create_flow
				? api_requests.create_network_servicibility(id, config_payload)
				: api_requests.update_network_servicibility(id, config_payload);
			set_is_service_api_done(true);
		} catch (error) {}
	};

	const handle_pricing_api = () => {
		try {
			let is_create_flow = true;
			let id = null;

			const config_payload = {
				default: true,
				pricing,
			};

			if (edit_network_id) {
				config_payload.pricing = {
					rule: pricing.rule,
					groups: _.map(pricing.groups, (item) => {
						return { group_name: item.group_name, slabs: item.slabs, raw_data: item.raw_data, id: item.id };
					}),
				};
				is_create_flow = false;
				id = edit_network_id;
			}
			if (invite_network_id) {
				config_payload.default = false;
				config_payload.owner = { id: selected_biz.id, type: selected_biz.type };

				const is_pricing_ownership_user = _.get(invite_network_config, 'ownership.pricing', '') == 'user';
				config_payload.pricing = {
					rule: pricing.rule,
					groups: _.map(pricing.groups, (item) => {
						return {
							group_name: item.group_name,
							slabs: item.slabs,
							raw_data: item.raw_data,
							...(is_pricing_ownership_user && { id: item.id }),
						};
					}),
				};
				if (is_pricing_ownership_user) {
					is_create_flow = false;
				}

				id = invite_network_id;
			}

			if (_.isEmpty(id)) id = _.get(create_network_response_ref.current, 'data.network_id', null);
			const response = is_create_flow ? api_requests.create_network_pricing(id, config_payload) : api_requests.update_network_pricing(id, config_payload);
			set_is_pricing_api_done(true);
		} catch (error) {}
	};

	const handle_allocation_api = () => {
		try {
			let is_create_flow = true;
			let id = null;

			const _allocation_data = _.cloneDeep(allocation_data);
			_allocation_data.max_weight_per_order =
				allocation_data.max_weight_per_order === '' || allocation_data.max_weight_per_order == null
					? null
					: allocation_data.max_weight_per_order * 1000;
			_allocation_data.max_distance_per_order =
				allocation_data.max_distance_per_order === '' || allocation_data.max_distance_per_order == null
					? null
					: allocation_data.max_distance_per_order * 1000;
			_allocation_data.max_cod_limit = allocation_data.max_cod_limit == '' ? null : allocation_data.max_cod_limit;

			const config_payload = {
				label: 'default',
				default: true,
				..._allocation_data,
			};

			if (edit_network_id) {
				is_create_flow = false;
				id = edit_network_id;
			}
			if (invite_network_id) {
				config_payload.can_choose_algo = can_choose_algo;
				config_payload.default = false;
				config_payload.owner = { id: selected_biz.id, type: selected_biz.type };

				const is_allocation_ownership_user = _.get(invite_network_config, 'ownership.allocation', '') == 'user';
				if (is_allocation_ownership_user) {
					is_create_flow = false;
				} else {
					delete config_payload.id;
				}
				id = invite_network_id;
			}

			if (_.isEmpty(id)) id = _.get(create_network_response_ref.current, 'data.network_id', null);
			const response = is_create_flow
				? api_requests.create_network_allocation(id, config_payload)
				: api_requests.update_network_allocation(id, config_payload, config_payload.id);
			set_is_allocate_api_done(true);
		} catch (error) {}
	};

	const handle_support_api = () => {
		try {
			let is_create_flow = true;
			let id = null;

			const support_officers = [...grievance_officer_info, ...customer_officer_info, ...rider_officer_info];

			const config_payload = {
				default: true,
				support_officers,
			};

			if (edit_network_id) {
				is_create_flow = false;
				id = edit_network_id;
			}
			if (invite_network_id) {
				config_payload.default = false;
				config_payload.owner = { id: selected_biz.id, type: selected_biz.type };

				const is_support_ownership_user = _.get(invite_network_config, 'ownership.support_officers', '') == 'user';
				if (is_support_ownership_user) {
					is_create_flow = false;
				} else {
					config_payload.support_officers = _.map([...grievance_officer_info, ...customer_officer_info, ...rider_officer_info], (item) => {
						let _item = item;
						delete _item.id;
						return _item;
					});
				}
				id = invite_network_id;
			}

			if (!id) id = _.get(create_network_response_ref.current, 'data.network_id', null);
			const response = is_create_flow ? api_requests.create_network_support(id, config_payload) : api_requests.update_network_support(id, config_payload);
			set_is_support_api_done(true);
		} catch (error) {}
	};

	const handle_surge_pricing_api = async () => {
		try {
			let is_create_flow = true;
			let id = null;
			const config_payload = {
				default: true,
				rule: 2,
				slabs: surge_pricing?.slab,
			};
			if (!_.isEmpty(surge_pricing) && surge_pricing.rule == 1) {
				config_payload.rule = 1;
				config_payload.slabs = {};
			}
			if (edit_network_id) {
				is_create_flow = false;
				id = edit_network_id;
			}
			if (invite_network_id) {
				config_payload.default = false;
				config_payload.owner = { id: selected_biz.id, type: selected_biz.type };

				const is_pricing_ownership_user = _.get(invite_network_config, 'ownership.surge_pricing', '') == 'user';

				if (is_pricing_ownership_user) {
					is_create_flow = false;
				}

				id = invite_network_id;
			}
			if (_.isEmpty(id)) id = _.get(create_network_response_ref.current, 'data.network_id', null);
			const response = is_create_flow
				? api_requests.create_network_surge_pricing(id, config_payload)
				: api_requests.update_network_surge_pricing(id, config_payload);
			set_is_surge_pricing_api_done(true);
		} catch {}
	};

	const handle_cancellation_charges_api = async () => {
		try {
			let is_create_flow = true;
			let id = null;
			const config_payload = {
				default: true,
				cancel_pricing: cancellation_charges,
			};
			if (edit_network_id) {
				is_create_flow = false;
				id = edit_network_id;
			}
			if (invite_network_id) {
				config_payload.default = false;
				config_payload.owner = { id: selected_biz.id, type: selected_biz.type };

				const is_pricing_ownership_user = _.get(invite_network_config, 'ownership.cancel_pricing', '') == 'user';

				if (is_pricing_ownership_user) {
					is_create_flow = false;
				}

				id = invite_network_id;
			}
			if (_.isEmpty(id)) id = _.get(create_network_response_ref.current, 'data.network_id', null);
			const response = is_create_flow
				? api_requests.create_network_cancellation_charges(id, config_payload)
				: api_requests.update_network_cancellation_charges(id, config_payload);
			set_is_cancellation_charges_api_done(true);
		} catch {}
	};

	const handle_reattempt_config_api = async () => {
		try {
			let is_create_flow = true;
			let id = null;
			const config_payload = {
				default: true,
				reattempt_config: reattempt_config_data,
			};
			if (edit_network_id) {
				is_create_flow = false;
				id = edit_network_id;
			}
			if (invite_network_id) {
				config_payload.default = false;
				config_payload.owner = { id: selected_biz.id, type: selected_biz.type };

				const is_pricing_ownership_user = _.get(invite_network_config, 'ownership.reattempt_config', '') == 'user';

				if (is_pricing_ownership_user) {
					is_create_flow = false;
				}

				id = invite_network_id;
			}
			if (_.isEmpty(id)) id = _.get(create_network_response_ref.current, 'data.network_id', null);
			const response = is_create_flow
				? api_requests.create_network_reattempt_config(id, config_payload)
				: api_requests.update_network_reattempt_config(id, config_payload);
			set_is_reattempt_config_api_done(true);
		} catch {}
	};
	const is_invite_roles_updated = useMemo(() => {
		if (_.get(history, 'location.state.can_lend_rider', false) != can_lend_rider) return true;
		if (_.get(history, 'location.state.can_manage', false) != can_manage) return true;
		if (_.get(history, 'location.state.can_place_order', false) != can_place_order) return true;
		return false;
	}, [can_lend_rider, can_place_order, can_manage]);

	return {
		is_participant_added,
		network_name,
		// is_name_valid,
		network_desc,
		show_display_modal,
		network_image,
		display_configs,
		show_lower_section,
		valid_pincodes,
		selected_menu,
		serviciblity_config,
		serviciblity_area_tab,
		set_selected_menu,
		set_valid_pincodes,
		set_display_configs,
		set_show_display_modal,
		handle_network_name,
		set_network_desc,
		set_network_image,
		handle_network_img,
		handle_individual_pincode_delete,
		handle_all_pincodes_delete,
		handle_serviciblity_config,
		flush_global_service_config,
		set_serviciblity_area_tab,

		//invite states and fucntions export
		invite_code,
		searched_biz,
		selected_biz,
		set_invite_code,
		handle_invite_code,
		set_selected_biz,
		handle_biz_select,
		invite_network_id,
		set_invite_network_id,
		can_lend_rider,
		set_can_lend_rider,
		can_place_order,
		set_can_place_order,
		can_manage,
		set_can_manage,
		invite_roles_count,
		set_invite_flow,

		invite_network_config,

		//allocation states and functions
		selected_allocation_tab,
		set_selected_allocation_tab,
		allocation_data,
		set_allocation_data,

		//support func states and functions
		selected_func_tab,
		grievance_officer_info,
		customer_officer_info,
		rider_officer_info,
		set_selected_func_tab,
		set_grievance_officer_info,
		set_customer_officer_info,
		set_rider_officer_info,
		// surge pricing
		selected_surge_pricing_tab,
		set_selected_surge_pricing_tab,

		handle_go_live,
		get_task_progress,
		show_success_modal,
		set_show_success_modal,
		handle_success,
		handle_add_participant,

		confirm_close_modal,
		set_confirm_close_modal,
		handle_confirm_modal_close,
		create_api_state_status,
		is_business_searched,
		pricing,
		set_pricing,
		set_edit_network_flow,
		edit_network_id,
		set_edit_network_id,
		edit_network_details,
		handle_edit_network_config,
		page_block_ref,
		can_choose_algo,
		set_can_choose_algo,
		is_invite_allocation_updated,
		set_is_invite_allocation_updated,
		is_invite_pricing_updated,
		set_is_invite_pricing_updated,
		handle_remove_access,
		query_object,
		is_invite_service_updated,
		is_invite_roles_updated,
		is_invite_support_updated,
		set_is_invite_support_updated,
		get_support_officer,
		set_serviciblity_config,
		convert_to_google_maps_polygon_object,
		handle_create_network,
		handle_network_delete,
		handle_serviciblity_api,
		handle_pricing_api,
		handle_allocation_api,
		handle_support_api,
		handle_add_participant_role,
		format_sort_pricing_data,
		surge_pricing,
		set_surge_pricing,
		handle_surge_pricing_api,
		cancellation_charges,
		set_cancellation_charges,
		handle_cancellation_charges_api,
		reattempt_config_data,
		set_reattempt_config_data,
		handle_reattempt_config_api,
		is_surge_pricing_toggle,
		set_is_surge_pricing_toggle,
		is_cancellation_charges_toggle,
		set_is_cancellation_charges_toggle,
		is_invite_surge_price,
		set_is_invite_surge_price,
		is_invite_cancellation_price,
		set_is_invite_cancellation_price,
		is_invite_reattempt_config,
		set_is_invite_reattempt_config,
	};
};

export default useCreateNetwork;
