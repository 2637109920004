import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { hide_loader, show_loader } from 'actions/app';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import Routes from 'resources/Routes';
import { useHistory } from 'react-router-dom';
import utils from 'resources/utils';
import NetworkRulesModalCont from './components/NetworkRulesModal';
import api_requests from 'resources/api_requests';
import AddRidersToNetworkModal from '../CreateNetwork/components/AddRidersToNetworkModal';
import { useRef } from 'react';
import RiderList from './components/RiderList';
import { ICON_STYLE } from '../CreateNetwork/helper';
import ParticipantList from './components/ParticipantList';
import useStyles from './styles';
import ConfirmClose from 'modules/modals/ConfirmClose';
import NetworkAnalytics from './components/NetworkAnalytics';
import FilterReportModal from 'modules/modals/FilterReportModal';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import Button from 'modules/Button';
import Helpcenter from 'modules/HelpCenter';
import EmptyState from 'modules/EmptyState';

const tabs = ['My Network', 'Invited Network'];
const report_filters = [
	{
		multi: true,
		param: 'date',
		title: 'Date',
		type: 'date',
	},
];

const NetworkListing = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [selected_tab, set_selected_tab] = useState(0);
	const [show_details_modal, set_show_details_modal] = useState(false);
	const [network_list, set_network_list] = useState([]);
	const [show_rider_add_modal, set_show_rider_add_modal] = useState(false);
	const [selected_network, set_selected_network] = useState({});
	const [list_type, set_list_type] = useState(null);
	const [refresh, set_refresh] = useState(false);
	const [selected_delete_id, set_selected_delete_id] = useState(null);
	const [selected_delete_network_id, set_selected_delete_network_id] = useState(null);
	const [selected_delete_network_name, set_selected_delete_network_name] = useState(null);
	const [show_report_modal, set_show_report_modal] = useState(false);
	const [report_items, set_report_items] = React.useState([]);
	const seleted_network_id = useRef(null);
	const SELF_NETWORK = network_list.length > 0;

	const classes = useStyles();

	const { is_loading } = useSelector((state) => state.app, shallowEqual);

	useEffect(() => {
		fetch_networks(true);
	}, [refresh]);

	useEffect(() => {
		fetch_filter_items();
	}, []);

	const fetch_networks = async (from_mount = false) => {
		dispatch(show_loader());
		try {
			const response = await api_requests.get_network_list();
			dispatch(hide_loader());

			const list = _.get(response, 'data', []);
			set_network_list(list);
			if (selected_network) {
				set_selected_network(
					_.filter(response.data, (network) => {
						return network.id == selected_network.id;
					})[0],
				);
			}

			if (list.length == 0) {
				set_selected_tab(0);
			} else {
				if (from_mount) {
					set_selected_network(list[0]);
					set_list_type('overview');
				}
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const handle_delete = async () => {
		try {
			const res = await api_requests.delete_participant(selected_delete_network_id, selected_delete_id);
			set_selected_delete_id(null);
			set_selected_delete_network_id(null);
			fetch_networks();
		} catch (err) {}
	};

	const open_add_rider = (id) => {
		seleted_network_id.current = id;
		set_show_rider_add_modal(true);
	};

	const handle_add_rider = async (payload) => {
		if (_.isEmpty(seleted_network_id.current)) return;
		const response = await api_requests.add_riders_to_network(seleted_network_id.current.id, payload);
		seleted_network_id.current = null;
		set_show_rider_add_modal(false);
		set_refresh((prev) => !prev);
	};

	const fetch_filter_items = async () => {
		try {
			const response = await api_requests.get_order_export_items({ categories: 'network' });
			if (response.data.items) {
				set_report_items(response.data.items);
			}
		} catch (error) {}
	};
	const handle_export = (filters, callback) => {
		api_requests
			.export_network_report({ created_at: `${filters.from},${filters.to}`, categories: 'network', fulfillment_network_id: selected_network.id })
			.then((res) => {
				callback(true);
				utils.downloadBlob(res, 'network_report.csv', 'text/csv');
			})
			.catch((err) => {
				callback(false);
			});
	};

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<Text semi className={'global_header_text'}>
					Networks
				</Text>

				{SELF_NETWORK && (
					<Button
						text='New Network'
						type='round'
						size='large'
						onClick={() => history.push(Routes.CREATE_NETWORK.path)}
						left_icon={ImageLinks.addWhite}
					/>
				)}
			</div>

			<div className={classes.content}>
				<div className={classes.left_wrap} style={{ flex: 1 }}>
					{SELF_NETWORK && (
						<div className={classes.tab_wrap}>
							<div className='d-flex'>
								{_.map(tabs, (tab, key) => (
									<div
										onClick={() => {
											set_selected_tab(key);
											set_selected_network(null);
										}}
										className={classes.subject_item_wrap}
										style={{
											backgroundColor: selected_tab === key ? theme.colors.primary : 'transparent',
										}}>
										<Text bold style={{ fontSize: 14, color: selected_tab === key ? theme.colors.white : theme.colors.lightPurple6 }}>
											{`${tab}`}
										</Text>
									</div>
								))}
							</div>
						</div>
					)}

					{SELF_NETWORK && (
						<div style={{ display: 'flex', marginTop: 20, width: '100%', gap: 70 }}>
							<div className={classes.list_wrap}>
								{_.map(
									_.filter(network_list, (network_item) => {
										return selected_tab === 0 ? network_item.is_owner : !network_item.is_owner;
									}),
									(network_item, network_index) => {
										const display_configs = _.get(network_item, 'avatar.configs', null);
										const network_image = _.get(network_item, 'avatar.image', null);
										const selected_icon_style_val = _.filter(ICON_STYLE, (item) => item.key === display_configs?.icon_style)[0];
										return (
											<div
												key={`network_item_${network_index}`}
												className={classes.network_item_wrap}
												style={{
													border: `1px solid ${
														selected_network?.id === network_item.id ? theme.colors.primary : theme.colors.lightGrey5
													}`,
												}}
												onClick={() => {
													set_selected_network(network_item);
													set_list_type('overview');
												}}>
												<div className='d-flex justify-content-between align-items-center'>
													<div className='d-flex justify-content-between align-items-center'>
														<div style={{ position: 'relative' }}>
															{!network_image && (
																<div
																	className={classes.monogram_wrap}
																	style={{
																		backgroundColor: display_configs?.avatar_color?.bg,
																	}}>
																	{display_configs?.avatar_style === 'monogram' ? (
																		<Text
																			semi
																			style={{
																				fontSize: 24,
																				color: theme.colors.white,
																			}}>
																			{display_configs?.initials_string}
																		</Text>
																	) : (
																		<img src={selected_icon_style_val?.white_img} width={24} height={24} />
																	)}
																</div>
															)}
															{network_image && <img src={network_image} className={classes.monogram_wrap} />}
															<div className={classes.edit_icon}>
																<img src={ImageLinks.lock_purple} width={10} height={10} />
															</div>
														</div>
														<Text bold style={{ fontSize: 14, marginLeft: 6 }}>
															{`${network_item.name} (${network_item.id})`}
														</Text>
													</div>
													<div>
														{selected_tab == 1 && (
															<BootstrapTooltip
																is_white={true}
																position='top'
																title={
																	<div>
																		<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.red }}>
																			Exit Network
																		</Text>
																	</div>
																}>
																<div
																	className={classes.exit_img}
																	onClick={() => {
																		set_selected_delete_network_id(network_item.id);
																		set_selected_delete_id(network_item.participant_id);
																		set_selected_delete_network_name(network_item.name);
																	}}>
																	<img src={ImageLinks.exit_icon} height={16} width={16} />
																</div>
															</BootstrapTooltip>
														)}
														{selected_tab == 0 && (
															<div style={{ cursor: 'pointer' }}>
																<img
																	src={ImageLinks.arrow_up_circle_solid}
																	style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
																	height={20}
																	width={20}
																/>
															</div>
														)}
													</div>
												</div>
												<div className={classes.divider} />
												<div className={'d-flex justify-content-between align-items-center'}>
													{selected_tab == 1 && (
														<div>
															{network_item.can_manage && (
																<Text component={'span'} className={classes.role_pill} semi>
																	Manage
																</Text>
															)}
															{network_item.can_lend_rider && (
																<Text component={'span'} className={classes.role_pill} semi>
																	Supply
																</Text>
															)}
															{network_item.can_place_order && (
																<Text component={'span'} className={classes.role_pill} semi>
																	Orders
																</Text>
															)}
														</div>
													)}

													<div className={'d-flex align-items-center'} style={{ width: selected_tab === 0 ? '100%' : 'auto' }}>
														<div className={classes.rider_text}>
															<img src={ImageLinks.rider_light_purple} width={14} height={14} />
															<Text medium component={'span'} style={{ fontSize: 12 }}>
																{_.get(network_item, 'rider_counts', 0)} Riders
															</Text>
														</div>
														<div className={classes.rider_text}>
															<img src={ImageLinks.user} width={14} height={14} />
															<Text medium component={'span'} style={{ fontSize: 12 }}>
																{_.get(network_item, 'participant_counts', 0)} Participants
															</Text>
														</div>
													</div>
												</div>
											</div>
										);
									},
								)}
							</div>
							<div style={{ flex: 1, marginBottom: 50 }}>
								{selected_network && (
									<div className={classes.details_wrap}>
										<div className={classes.action_bar_wrap}>
											<Text medium>Quick Action</Text>
											<div className='d-flex' style={{ gap: 5 }}>
												<BootstrapTooltip title={'Get Report'}>
													<div className={classes.bar_chart_icon_wrap}>
														<img className='purple-download' src={ImageLinks.download} width={16} height={16} />
														<img
															className='white-download'
															style={{ display: 'none' }}
															width={20}
															height={20}
															src={ImageLinks.download_white}
															alt=''
															onClick={() => {
																set_show_report_modal(true);
															}}
														/>
													</div>
												</BootstrapTooltip>

												{selected_network.is_owner && (
													<img
														src={ImageLinks.edit_filled_light_purple}
														onClick={() => {
															history.push(`/create-network?id=${selected_network.id}`);
														}}
														height={26}
														width={26}
														style={{ cursor: 'pointer' }}
													/>
												)}
											</div>
										</div>
										<div style={{ padding: 12 }}>
											<div className='d-flex align-items-center justify-content-between' style={{ gap: 12, marginBottom: 10 }}>
												<div
													className={list_type == 'overview' ? classes.action_item_active : classes.action_item}
													onClick={() => set_list_type('overview')}>
													<Text semi style={{ color: list_type == 'overview' ? theme.colors.primary : theme.colors.black }}>
														Overview
													</Text>
													<div>
														<img src={ImageLinks.chevronLeft} width={6} style={{ transform: 'rotate(180deg)' }} />
													</div>
												</div>
												<div
													className={list_type == 'rider' ? classes.action_item_active : classes.action_item}
													onClick={() => set_list_type('rider')}>
													<Text semi style={{ color: list_type == 'rider' ? theme.colors.primary : theme.colors.black }}>
														Riders
													</Text>
													<div className='d-flex align-items-center' style={{ gap: 5 }}>
														<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
															{_.get(selected_network, 'rider_counts', 0)}
														</Text>
														<img src={ImageLinks.chevronLeft} width={6} style={{ transform: 'rotate(180deg)' }} />
													</div>
												</div>
												<div
													className={list_type == 'participant' ? classes.action_item_active : classes.action_item}
													onClick={() => set_list_type('participant')}>
													<Text semi style={{ color: list_type == 'participant' ? theme.colors.primary : theme.colors.black }}>
														Participants
													</Text>
													<div className='d-flex align-items-center' style={{ gap: 5 }}>
														<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
															{_.get(selected_network, 'participant_counts', 0)}
														</Text>
														<img src={ImageLinks.chevronLeft} width={6} style={{ transform: 'rotate(180deg)' }} />
													</div>
												</div>
											</div>
											{list_type == 'overview' && <NetworkAnalytics selected_network={selected_network} />}
											{list_type == 'rider' && (
												<RiderList
													network_id={selected_network.id}
													open_rider_modal={() => open_add_rider(selected_network)}
													refresh={refresh}
													set_refresh={set_refresh}
													selected_network={selected_network}
												/>
											)}
											{list_type == 'participant' && (
												<ParticipantList
													selected_network={selected_network}
													network_name={selected_network.name}
													network_id={selected_network.id}
													refresh={refresh}
													set_refresh={set_refresh}
												/>
											)}
										</div>
									</div>
								)}
							</div>
						</div>
					)}
					{_.filter(network_list, (network_item) => {
						return selected_tab === 0 ? network_item.is_owner : !network_item.is_owner;
					}).length === 0 &&
						!is_loading &&
						(selected_tab === 0 ? (
							<div className={classes.empty_content}>
								<EmptyState
									button_text='New Network'
									left_icon={ImageLinks.addWhite}
									empty_img={ImageLinks.empty_state_network}
									onClick={() => history.push(Routes.CREATE_NETWORK.path)}
									button_style={{ left: 28 }}
								/>
								<Helpcenter
									text={'How do I create a Network ?'}
									url='https://pidge.in/helpcenter-enterprise-create_network/'
									style={{ marginTop: 40 }}
								/>
							</div>
						) : (
							<div className='d-flex justify-content-center align-items-center' style={{ flexDirection: 'column' }}>
								<EmptyState empty_img={ImageLinks.invite_empty_state} delay_time={0} />
								{/* <Helpcenter
							text={'How do I Invite a Network ?'}
							url='https://pidge.in/helpcenter-enterprise-create_network/'
							style={{ marginTop: 40 }}
						/> */}
							</div>
						))}
				</div>
			</div>
			{selected_delete_id && (
				<ConfirmClose
					headerTitle={`Are you sure you want to leave ${selected_delete_network_name}`}
					title='All custom pricing and configuration will be removed. Riders (if any) will be removed from the network.
					You will need to contact the Network Manager to be added again.'
					close={() => set_selected_delete_id(null)}
					is_error_msg={true}
					positive_btn_text='Leave'
					show_negative_btn={true}
					negative_btn_text='Cancel'
					on_confirm={handle_delete}
				/>
			)}
			{show_details_modal && (
				<NetworkRulesModalCont
					close={() => {
						set_show_details_modal(false);
					}}
				/>
			)}
			{show_report_modal && (
				<FilterReportModal
					report_items={report_items}
					close={() => set_show_report_modal(false)}
					report_filters={report_filters}
					handle_export={handle_export}
					filter_from={'network_listing'}
					wrapper_styles={{ paddingRight: 30 }}
				/>
			)}
			{show_rider_add_modal && <AddRidersToNetworkModal close={() => set_show_rider_add_modal(false)} handle_add_rider={handle_add_rider} />}
		</div>
	);
};

export default NetworkListing;
