import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import Select from 'modules/Select';
import RadioBox from 'modules/RadioBox';
import OutlinedTextField from 'modules/OutlinedTextField';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';
import { INDUSTURY_OPTIONS, RANGE_OPTIONS } from '../helper';
import Footer from './Footer';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.darkGrey2,
		},

		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.primary,
		},

		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			color: theme.colors.black,
		},

		'& .MuiInputLabel-outlined': {
			color: theme.colors.darkGrey2,
		},

		'& .MuiInputLabel-outlined.Mui-focused': {
			color: theme.colors.primary,
		},

		'& .MuiInputLabel-outlined.Mui-error': {
			color: theme.colors.red2,
		},
		'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.red2,
		},
	},
	title_text: { fontSize: 40 },
	content_wrap: { flex: 0.9, display: 'flex', flexDirection: 'column', justifyContent: 'center' },
	form_style: {
		width: '50%',
		height: '100%',
	},

	label_text: { color: theme.colors.darkGrey2, fontSize: 16, marginBottom: 5 },
	subtitle_text: { color: theme.colors.primary, fontSize: 18, marginTop: 10 },
	flex_center: { display: 'flex', alignItems: 'center' },

	cta_btn_wrapper: { marginTop: 30 },
	cta_btn: { borderRadius: 25, height: 39, width: 130 },

	menu_item_class: {
		height: 56,
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
		},
	},
}));

const RegisterBusiness = ({ token, on_request_success }) => {
	const classes = useStyles();
	const [form_data, set_form_data] = useState({
		business_type: '',
		order_range: '',
		business_email: '',
		brand_name: '',
		website: '',
		has_own_fleet: true,
	});
	const [disable_submit, set_disable_submit] = useState(false);

	const handle_data = (name, value) => {
		set_form_data({
			...form_data,
			[name]: value,
		});
	};

	const on_submit = async () => {
		set_disable_submit(true);
		let err_msg = '';
		const _form_data = _.cloneDeep(form_data);

		if (_.isEmpty(_form_data.business_type)) err_msg = "Industry can't be empty";
		else if (_.isEmpty(_form_data.order_range)) err_msg = "Range can't be empty";
		else if (_.isEmpty(_form_data.business_email)) err_msg = 'Invalid business email';
		else if (_.isEmpty(_form_data.brand_name)) err_msg = 'Invalid brand name';

		if (_.isEmpty(_form_data.website)) delete _form_data.website;

		if (err_msg) {
			toast.error(err_msg, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}

		try {
			const response = await api_requests.register_business_enquiry(token, _form_data);
			if (response.success) {
				on_request_success({
					brand_name: response.data.brand_name,
					business_id: response.data.business_id,
					type_of_owner: response.data.owner_type,
					business_name: response.data.business_name,
				});
			}
		} catch (error) {
			set_disable_submit(false);
		}
	};

	const btn_disabled = useMemo(() => {
		if (disable_submit) {
			return true;
		}
		let value = true;
		if (
			!_.isEmpty(form_data.business_type) &&
			!_.isEmpty(form_data.order_range) &&
			!_.isEmpty(form_data.business_email) &&
			!_.isEmpty(form_data.brand_name)
		)
			value = false;
		return value;
	}, [form_data, disable_submit]);

	return (
		<div className={classes.form_style}>
			<div style={{ paddingLeft: '15%', paddingTop: '15%' }}>
				<div className={classes.content_wrap}>
					<Text medium className={classes.title_text}>
						Business Registration
					</Text>
					<Text semi className={classes.subtitle_text}>
						Set up your business details
					</Text>

					<div className={classes.flex_center} style={{ marginTop: 50 }}>
						<div style={{ flex: 0.5 }}>
							<Text semi className={classes.label_text}>
								Business Type*
							</Text>
							<Select
								value={form_data.business_type}
								defaultOption={{
									title: `Select Industry`,
									value: '',
								}}
								options={INDUSTURY_OPTIONS}
								handleChange={(v) => {
									set_form_data({
										...form_data,
										business_type: v,
									});
								}}
								containerStyles={{ height: 56, width: 235, borderRadius: 4 }}
								ext_styles={{ color: theme.colors.darkGrey2 }}
								text_props={{ medium: true }}
								menu_styles={{ width: 235, marginTop: 56 }}
								menu_item_class={{ className: classes.menu_item_class }}
							/>
						</div>

						<div style={{ flex: 1 }}>
							<Text semi className={classes.label_text}>
								No. of orders per day*
							</Text>
							<Select
								value={form_data.order_range}
								defaultOption={{
									title: `Select Range`,
									value: '',
								}}
								options={RANGE_OPTIONS}
								handleChange={(v) => {
									set_form_data({
										...form_data,
										order_range: v,
									});
								}}
								containerStyles={{ height: 56, width: 235, borderRadius: 4 }}
								text_styles={{ color: theme.colors.darkGrey2 }}
								text_props={{ medium: true }}
								menu_styles={{ width: 235, marginTop: 56 }}
								menu_item_class={{ className: classes.menu_item_class }}
							/>
						</div>
					</div>

					<div className={classes.flex_center} style={{ marginTop: 30 }}>
						<OutlinedTextField
							value={form_data.brand_name}
							name='brand_name'
							onChange={(e) => {
								handle_data('brand_name', e.target.value);
							}}
							label='Brand Name*'
							className={classes.root}
							style={{ width: 235, marginRight: 20 }}
						/>

						<OutlinedTextField
							value={form_data.business_email}
							name='business_email'
							onChange={(e) => {
								handle_data('business_email', e.target.value);
							}}
							label='Business Email*'
							className={classes.root}
							style={{ width: 235 }}
						/>
					</div>

					<div style={{ display: 'flex', marginTop: 30 }}>
						<OutlinedTextField
							value={form_data.website}
							name='website'
							onChange={(e) => {
								handle_data('website', e.target.value);
							}}
							label='Website (optional)'
							className={classes.root}
							style={{ width: 235, marginRight: 20 }}
						/>

						<div style={{ flex: 1 }}>
							<Text semi className={classes.label_text}>
								Do you have your own fleet/ riders?*
							</Text>
							<RadioBox
								wrapper_styles={{ marginTop: 8 }}
								selected_item={form_data.has_own_fleet}
								on_item_press={(val) => {
									handle_data('has_own_fleet', val);
								}}
								options={[
									{
										label: 'Yes',
										value: true,
									},
									{
										label: 'No',
										value: false,
									},
								]}
							/>
						</div>
					</div>
					<div className={classes.cta_btn_wrapper}>
						<ContainedButton disabled={btn_disabled} onClick={on_submit} className={classes.cta_btn}>
							<Text semi style={{ fontSize: 18, color: btn_disabled ? theme.colors.darkGrey2 : theme.colors.white }}>
								Submit
							</Text>
						</ContainedButton>
					</div>
				</div>
				<Footer show_help />
			</div>
		</div>
	);
};

export default RegisterBusiness;
