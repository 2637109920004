import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import EmptyState from 'modules/EmptyState';
import Helpcenter from 'modules/HelpCenter';
import OutlinedButton from 'modules/OutlinedButton';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';

const useStyles = makeStyles({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 30 },
	title: { fontSize: 24, width: 560, textAlign: 'center', lineHeight: 1.2 },
	btn_text: { fontSize: 18, color: theme.colors.lightPurple7 },
});

const NoMasterZone = ({ open_create_master_zone, open_create_custom_master_zone }) => {
	const classes = useStyles();
	return (
		<div className={classes.wrapper}>
			<EmptyState button_text='Create Zone' left_icon={ImageLinks.addWhite} empty_img={ImageLinks.empty_state_zone} onClick={open_create_master_zone} />
			{/* <Helpcenter text='What are the benefit of zones?' /> */}
		</div>
	);
};

export default NoMasterZone;
