import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';

export const useStyles = makeStyles(() => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 30 },

	content: { flex: 1, marginTop: 18, paddingBottom: 50 },
	use_case_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: `1px solid ${theme.colors.lightPurple}`,
		padding: 10,
		borderRadius: 36,
		height: 36,
		width: '75%',
		cursor: 'pointer',
		marginTop: 12,
	},
	use_case_item_wrap: {
		position: 'relative',
		maxHeight: 285,
		width: '75%',
		borderRadius: 6,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.3)',
		overflowY: 'scroll',
		marginTop: 5,
	},
	cta_btn: {
		width: 142,
		height: 38,
		borderRadius: 30,
	},

	preview_cta: {
		height: 28,
		width: 104,
		borderRadius: 44,
		padding: '4px 12px',
		backgroundColor: `${theme.colors.lightRed} !important`,
		boxShadow: 'none',
		marginRight: 8,
		cursor: 'auto',
		'&:hover': {
			boxShadow: 'none',
		},
	},
	subject_wrap: {
		display: 'flex',
		marginTop: 12,
		marginBottom: 40,
	},
	trigger_action_wrap: {
		marginTop: 21,
	},
	action_wrap: { display: 'flex', alignItems: 'center', marginTop: 15 },
	notif_toggle_wrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 40,
	},
	separator: { border: `1px solid ${theme.colors.lightGrey2}`, marginTop: 20, marginBottom: 20 },
	footer_wrap: {
		position: 'fixed',
		bottom: 30,
		right: 60,
	},
	saved_btn_text: { fontSize: 18, color: theme.colors.green, marginLeft: 6 },
	saved_link_text: { textDecoration: 'underline', color: theme.colors.primary, fontSize: 18, cursor: 'pointer' },
	edit_comm_text: { fontSize: 14, color: theme.colors.primary, textDecoration: 'underline', marginTop: 20 },
	back_arrow: { backgroundColor: theme.colors.lightPurple, width: 20, height: 20, borderColor: theme.colors.lightPurple, marginRight: 10 },
}));
