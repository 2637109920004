import _ from 'lodash';
import notif_sound from '../assets/sounds/notif_sound.mp3';

class NotificationService {
	permission_status = Notification.permission;
	audio = new Audio(notif_sound);

	async request_permission() {
		if (this.permission_status !== 'granted' && this.permission_status !== 'denied') {
			this.permission_status = await Notification.requestPermission();
		}
		return this.permission_status;
	}

	play_sound() {
		this.audio.play();
	}

	show_notification(title, options) {
		if (this.permission_status === 'granted' && document.hidden) {
			new Notification(title, options);
			this.play_sound();
			this.set_favicon('/favicon_notif.ico');
		} else if (!document.hidden) {
			console.log('Tab is focused, notification will not be shown.');
		} else {
			console.log('Notification permission not granted.');
		}
	}

	set_favicon(iconURL) {
		const link = document.querySelector("link[rel~='icon']");
		if (link) {
			link.href = iconURL;
		} else {
			const newLink = document.createElement('link');
			newLink.rel = 'icon';
			newLink.href = iconURL;
			document.head.appendChild(newLink);
		}
	}

	reset_favicon() {
		this.set_favicon('/favicon.ico');
	}
}

export default new NotificationService();
