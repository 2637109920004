import React, { useEffect, useRef, useState } from 'react';
import theme from 'resources/theme';
import useStyles from './styles';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import clsx from 'clsx';
import { ICON_STYLE } from 'views/NetworksFlow/CreateNetwork/helper';
import ContainedButton from 'modules/ContainedButton';
import api_requests from 'resources/api_requests';
import ConfirmClose from '../ConfirmClose';

const TransferAllocationModal = ({ network_id, fulfillment_data, close, on_add_success = () => {}, on_remove_supplier_success = () => {} }) => {
	const [selected_network, set_selected_network] = useState(null);
	const [network_details, set_network_details] = useState(null);
	const [partners_list, set_partners_list] = useState([]);
	const [show_confirm_modal, set_show_confirm_modal] = useState(false);
	const [show_remove_modal, set_show_remove_modal] = useState(false);
	const [already_transferred, set_already_transferred] = useState([]);
	const [participant_shown, set_participant_shown] = useState(false);
	const selected_removed_item = useRef(null);
	const classes = useStyles();

	useEffect(() => {
		const filtered = _.filter(fulfillment_data, (item) => item.supplier_id > 0);
		set_already_transferred(filtered);
	}, []);

	useEffect(() => {
		if (!show_remove_modal) selected_removed_item.current = null;
	}, [show_remove_modal]);

	useEffect(() => {
		fetch_participants();
		fetch_network_details();
	}, [network_id]);

	const fetch_participants = async () => {
		try {
			const response = await api_requests.get_participants(network_id);
			const filtered = _.filter(response.data, (item) => item.can_lend_rider);
			set_partners_list(filtered);
		} catch (error) {}
	};

	const fetch_network_details = async () => {
		try {
			const response = await api_requests.get_single_network_details(network_id);
			set_network_details(response.data);
		} catch (error) {}
	};

	const handle_add_supplier = async () => {
		const filtered = _.filter(fulfillment_data, (item) => item.supplier_id <= 0);
		const ids = _.map(filtered, (item) => item.mcp_id);
		try {
			const payload = {
				mcp_ids: ids,
				supplier_id: selected_network.business_id,
			};
			const response = await api_requests.add_supplier(payload);
			close();
			on_add_success?.(`${ids.length} orders from ${network_details.name} transferred to ${selected_network.brand_name}`);
		} catch (error) {}
	};

	const handle_remove_supplier = async (ids) => {
		try {
			const payload = {
				mcp_ids: ids,
			};
			const response = await api_requests.remove_supplier(payload);
			on_remove_supplier_success?.();
			close();
		} catch (error) {}
	};

	const result = {};
	already_transferred.forEach((item) => {
		const { mcp_id, supplier_id, ...rest } = item;
		if (result[supplier_id]) {
			result[supplier_id].count++;
			result[supplier_id].mcp_ids.push(mcp_id);
		} else {
			result[supplier_id] = {
				count: 1,
				supplier_id,
				mcp_ids: [mcp_id],
				...rest,
			};
		}
	});
	const clubbed_arr = Object.values(result);

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div className={classes.header}>
					<div className={'align-items-center'}>
						<Text bold style={{ fontSize: 18, color: theme.colors.primary }}>
							Transfer to Network Lenders
						</Text>
					</div>

					<div className='cursor-pointer' onClick={close} style={{ marginTop: 3 }}>
						<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
					</div>
				</div>

				<div className={clsx(classes.scroll_content)}>
					<Text semi style={{ fontSize: 12, marginLeft: 2 }}>{`${network_details?.name} (${partners_list.length})`}</Text>
					<div>
						{_.map(partners_list, (network_item, network_index) => {
							const selected_icon_style_val = _.filter(
								ICON_STYLE,
								(item) => item.key === _.get(network_item, 'avatar.configs.icon_style', ''),
							)[0];
							return (
								<div
									key={`network_item_${network_index}`}
									onClick={() => {
										set_selected_network(network_item);
									}}
									className={classes.allocate_card}
									style={{
										border: `1px solid ${selected_network?.id === network_item.id ? theme.colors.primary : theme.colors.lightGrey7}`,
									}}>
									<div className='align-items-center justify-content-between'>
										<div className='align-items-center'>
											{/* <div
												className={classes.icon_wrap}
												style={{
													backgroundColor: _.get(network_item, 'avatar.configs.avatar_color.bg', theme.colors.white),
												}}>
												{_.get(network_item, 'avatar.configs.avatar_style', '') === 'monogram' ? (
													<Text
														semi
														style={{
															fontSize: 24,
															color: theme.colors.white,
														}}>
														{_.get(network_item, 'avatar.configs.initials_string', '')}
													</Text>
												) : (
													<img src={selected_icon_style_val?.white_img} width={20} height={20} />
												)}
											</div> */}

											<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
												{`${network_item.brand_name} (${network_item.business_id})`}
											</Text>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				{already_transferred.length > 0 && (
					<div className={classes.supplier_wrap}>
						<div className={clsx(participant_shown && classes.supplier_wrap_expanded, classes.supplier_wrapper)}>
							<div className={classes.supplier_header}>
								<Text semi style={{ fontSize: 12 }}>
									{already_transferred.length} orders transferred to {clubbed_arr.length} participants
								</Text>
								<div className='align-items-center' style={{ marginLeft: 6 }} onClick={() => set_participant_shown((prev) => !prev)}>
									<Text semi className={classes.show_hide_text}>
										{participant_shown ? 'Hide' : 'View'}
									</Text>
									<img
										src={ImageLinks.chevronLeft}
										width={12}
										height={12}
										style={{ marginLeft: 2, transform: `rotate(${participant_shown ? 270 : 90}deg)` }}
									/>
								</div>
							</div>
							<div style={{ paddingLeft: 30, paddingRight: 30 }}>
								{_.map(clubbed_arr, (item, index) => (
									<div className={classes.section_wrap} key={`section_item_${index}`}>
										<Text semi className={classes.section_item_wrap} style={{ fontSize: 12 }}>
											{`${_.filter(partners_list, (partner) => partner.business_id === item.supplier_id)[0]?.brand_name}(${
												item.supplier_id
											})`}
										</Text>
										<Text semi className={classes.section_item_wrap} style={{ fontSize: 12, justifyContent: 'center' }}>
											{item.count} orders
										</Text>
										<div className={classes.section_item_wrap} style={{ justifyContent: 'flex-end' }}>
											<div
												className={classes.revert_btn}
												onClick={() => {
													selected_removed_item.current = item;
													set_show_remove_modal(true);
												}}>
												<img src={ImageLinks.left_right_arrow_red} width={12} height={12} style={{ marginRight: 2 }} />
												<Text semi className={classes.revert_text}>
													Revert
												</Text>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}

				<div className={classes.footer}>
					<div className={classes.footer_content}>
						<div>
							{already_transferred.length > 0 && (
								<Text semi style={{ fontSize: 16, color: theme.colors.primary }}>{`Transfer ${
									fulfillment_data.length - already_transferred.length
								}  orders`}</Text>
							)}
						</div>
						<ContainedButton
							disabled={_.isEmpty(selected_network) || fulfillment_data.length - already_transferred.length === 0}
							style={{ borderRadius: 30, height: 36, width: 156, padding: 0, marginLeft: 16 }}
							onClick={() => set_show_confirm_modal(true)}>
							<Text semi style={{ fontSize: 18 }}>
								Transfer Orders
							</Text>
						</ContainedButton>
					</div>
				</div>
				{show_confirm_modal && (
					<ConfirmClose
						headerTitle={'Transfer Orders'}
						title={'Are you sure you want to transfer orders?'}
						titleStyles={{ textAlign: 'left' }}
						close={() => set_show_confirm_modal(false)}
						on_confirm={handle_add_supplier}
					/>
				)}
				{show_remove_modal && (
					<ConfirmClose
						modal_icon={ImageLinks.left_right_filled_arrow_pink}
						headerTitle={'Revert transferred orders'}
						title={
							<Text>
								Are you sure you want to revert{' '}
								<Text component={'span'} bold>
									{selected_removed_item.current?.count} orders
								</Text>{' '}
								from
								<Text component={'span'} bold>{` ${
									_.filter(partners_list, (partner) => partner.business_id === selected_removed_item.current?.supplier_id)[0]?.brand_name
								} (${selected_removed_item.current.supplier_id})`}</Text>
							</Text>
						}
						titleStyles={{ textAlign: 'left' }}
						close={() => set_show_remove_modal(false)}
						on_confirm={() => handle_remove_supplier(selected_removed_item.current.mcp_ids)}
						positive_btn_text='Revert Orders'
						show_negative_btn
					/>
				)}
			</div>
		</>
	);
};

export default TransferAllocationModal;
