import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Doughnut } from 'react-chartjs-2';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import EmptyState from '../EmptyState';

const useStyles = makeStyles({
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
	analytics_container: {
		padding: 12,
		borderRadius: 12,
		background: theme.colors.white,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
	},
	link_performance_content_container: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: 24,
	},
	link_info_content: {
		textAlign: 'center',
		padding: 14,
	},
	link_info_sub_text: {
		color: theme.colors.darkPurple,
		background: theme.colors.lightPink,
		borderRadius: 16,
		marginTop: 4,
		display: 'inline-block',
		padding: '4px 8px',
	},
	link_open_sub_text: {
		color: theme.colors.primary,
		background: theme.colors.lightPurple,
		borderRadius: 16,
		marginTop: 4,
		display: 'inline-block',
		padding: '4px 8px',
	},
	dot_content: {
		width: 8,
		height: 8,
		borderRadius: '50%',
	},
	half_donut_text_container: {
		position: 'absolute',
		bottom: '20px',
		left: '28%',
	},
});

const TrackingLinkPerformance = ({ tracking_data }) => {
	const classes = useStyles();
	const labels = ['Link Opened', 'Link Sent'];
	const data = {
		labels: labels,
		datasets: [
			{
				data: [Math.round(_.get(tracking_data, 'click_percentage', null)), 100 - Math.round(_.get(tracking_data, 'click_percentage', null))],
				backgroundColor: [theme.colors.primary, theme.colors.lightPurple6],
				borderColor: [theme.colors.primary, theme.colors.lightPurple6],
				borderWidth: 2,
			},
		],
	};

	const config = {
		type: 'doughnut',
		data: data,
		options: {
			responsive: true,
			rotation: -90,
			circumference: 180,
			cutout: 60,
			plugins: {
				legend: false,
				datalabels: {
					display: false,
				},
			},
			legend: {
				display: false,
			},
		},
	};

	return (
		<Grid container className={classes.analytics_container} spacing={2}>
			{!_.isEmpty(tracking_data) ? (
				<>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<Text bold style={{ fontSize: 14 }}>
							Tracking Link Performance
						</Text>
						<Divider className={classes.divider} />
						<div className={classes.link_performance_content_container}>
							<div className={classes.link_info_content}>
								<Text bold style={{ fontSize: 20 }}>
									{_.get(tracking_data, 'links_sent', null)}
								</Text>
								<div className={classes.link_info_sub_text}>
									<Text bold style={{ fontSize: 8 }}>
										Link Sent
									</Text>
								</div>
							</div>
							<Divider orientation='vertical' style={{ backgroundColor: theme.colors.lightGrey5 }} flexItem />
							<div className={classes.link_info_content}>
								<Text bold style={{ fontSize: 20 }}>
									{_.get(tracking_data, 'links_clicked', null)}
								</Text>
								<div className={classes.link_open_sub_text}>
									<Text bold style={{ fontSize: 8 }}>
										Link Opened
									</Text>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<div style={{ width: 150, height: 158, position: 'relative' }}>
							<Doughnut {...config} />
							<div className={classes.half_donut_text_container}>
								<Text className={'text-align-center'} bold style={{ fontSize: 32, color: theme.colors.primary }}>
									{Math.round(_.get(tracking_data, 'click_percentage', null))}%
								</Text>
								<Text className={'text-align-center'} medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Links Opened
								</Text>
							</div>
						</div>
					</Grid>
				</>
			) : (
				<div style={{ width: '100%', height: '100%' }}>
					<Text bold style={{ fontSize: 14 }}>
						Tracking Link Performance
					</Text>
					<Divider className={classes.divider} />
					<EmptyState />
				</div>
			)}
		</Grid>
	);
};

export default TrackingLinkPerformance;
