import { useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import IconButton from 'modules/IconButton';
import Text from 'modules/Text';
import React, { useEffect, useState } from 'react';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import ConfirmClose from 'modules/modals/ConfirmClose';
import useStyles from './styles';
import InfiniteToast from './components/InfiniteToast';
import OrderModal from 'modules/modals/OrderModal/OrderModal';

let map = null;

const initial_confirm_remove_squence_details = {
	open: false,
	trip_ids: [],
};

const detail_menu = {
	EMPTY: 'empty',
	MARKER: 'marker',
	BUNDLE: 'bundle',
};

function CompareRoutes({
	bundle_id,
	selected_bundle_list,
	set_drag_trip_id,
	refresh_trips,
	set_active_pickup,
	bundle_label,
	premium_features_blocked,
	handle_show_premium_feature,
	refresh,
	set_refresh,
}) {
	const [bundle_data, set_bundle_data] = useState([]);
	const [active_marker, set_active_marker] = useState(null);
	const [active_marker_index, set_active_marker_index] = useState(0);
	const [active_bundle_data, set_active_bundle_data] = useState({});
	const [refresh_map, set_refresh_map] = useState(false);
	const [show_detail, set_show_detail] = useState(detail_menu.EMPTY);
	const [confirm_remove_squence_details, set_confirm_remove_squence_details] = React.useState({ ...initial_confirm_remove_squence_details });
	const [open_pbid_modal, set_open_pbid_modal] = useState(null);
	const classes = useStyles();
	const theme = useTheme();

	useEffect(() => {
		if (bundle_id) {
			set_show_detail(detail_menu.BUNDLE);
			api_requests.get_bundles_data(bundle_id).then((res) => {
				set_active_bundle_data(res.data);
			});
		}
	}, [bundle_id]);

	useEffect(() => {
		let promises = [];
		set_active_marker(null);
		set_active_marker_index(0);
		_.forEach(
			_.filter(selected_bundle_list, (value) => {
				return value !== '';
			}),
			(bundle_id) => {
				promises.push(api_requests.get_bundles_data(bundle_id));
			},
		);
		Promise.all(promises).then((res) => {
			set_bundle_data(
				_.map(res, (bundle, key) => {
					return bundle.data;
				}),
			);
		});
	}, [selected_bundle_list, refresh_trips, refresh_map]);

	const handle_marker_click = (val, index) => {
		if (active_marker === val) {
			set_active_marker(null);
			return;
		} else {
			set_active_marker(val);
			set_active_marker_index(index);
		}
	};
	useEffect(() => {
		if (
			_.isEmpty(bundle_data) ||
			_.isEmpty(bundle_data[0].tripData[0]) ||
			_.filter(selected_bundle_list, (value) => {
				return value !== '';
			}).length === 0
		) {
			map = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
				center: { lat: 28, lng: 77 },
				zoom: 10,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});
			return;
		}

		map = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
			center: { lat: Number(bundle_data[0].tripData[0].pickup_address.latitude), lng: Number(bundle_data[0].tripData[0].pickup_address.longitude) },
			zoom: 11,
			clickableIcons: false,
			disableDefaultUI: true,
			scaleControl: true,
			zoomControl: true,
			mapId: constant.MAP_DESIGN_ID,
		});

		_.forEach(
			_.filter(bundle_data, (bundle) => {
				return _.get(bundle, 'tripData').length > 0;
			}),
			(bundle_data, key) => {
				const pick_up_icon = {
					url: ImageLinks.rider_pickup, // url
					scaledSize: new window.google.maps.Size(32, 32), // scaled size
				};

				const icon = {
					url: ImageLinks['marker_color_' + (_.indexOf(selected_bundle_list, bundle_data.bundle_id) + 1)], // url
					scaledSize: new window.google.maps.Size(32, 32), // scaled size
				};

				new window.google.maps.Marker({
					position: { lat: Number(bundle_data.tripData[0].pickup_address.latitude), lng: Number(bundle_data.tripData[0].pickup_address.longitude) },
					icon: pick_up_icon,
					map,
				});

				let marker;
				let is_active = null;
				_.forEach(bundle_data.tripData, (val, index) => {
					marker = new window.google.maps.Marker({
						position: { lat: Number(val.drop_address.latitude), lng: Number(val.drop_address.longitude) },
						label: { text: `${index + 1}`, color: 'white' },
						icon: icon,
						map,
					});
					marker.addListener('click', () => {
						if (is_active !== val) {
							handle_marker_click(val, index);
							is_active = val;
							set_show_detail(detail_menu.MARKER);
						} else {
							handle_marker_click(null, index);
							is_active = null;
							set_show_detail(detail_menu.BUNDLE);
						}
					});
				});

				const flightPlanCoordinates = [
					{ lat: Number(bundle_data.tripData[0].pickup_address.latitude), lng: Number(bundle_data.tripData[0].pickup_address.longitude) },
					..._.map(bundle_data.tripData, (val, key) => {
						return { lat: Number(val.drop_address.latitude), lng: Number(val.drop_address.longitude) };
					}),
				];

				const flightPath = new window.google.maps.Polyline({
					path: flightPlanCoordinates,
					geodesic: true,
					strokeColor: theme.colors['compareMapColor' + (_.indexOf(selected_bundle_list, bundle_data.bundle_id) + 1)],
					strokeOpacity: 1.0,
					strokeWeight: 2,
				});

				flightPath.setMap(map);
			},
		);
	}, [bundle_data]);

	const handle_confirm_close = () => {
		api_requests
			.ungroup_trip({
				trips: confirm_remove_squence_details.trip_ids,
			})
			.then((res) => {
				set_refresh_map(!refresh_map);
				set_refresh(!refresh);
			});
	};

	return (
		<div className={classes.map_wrap}>
			{show_detail === detail_menu.EMPTY &&
				_.filter(selected_bundle_list, (value) => {
					return value !== '';
				}).length === 0 && (
					<div
						className={classes.trip_wrap}
						style={{ display: 'block', top: 20, textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '8px' }}>
						<Text semi style={{ fontSize: 18 }}>
							Select up to 10 cards to view on map
						</Text>
						<Text style={{ fontSize: 14 }}>Double click a card to view details</Text>
					</div>
				)}
			{!_.isEmpty(active_marker) && show_detail === detail_menu.MARKER && (
				<>
					<div className={classes.table_head}>
						<Text bold style={{ flex: 1, color: theme.colors.darkGrey2 }}>
							#
						</Text>

						<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
							PBID
						</Text>

						<Text bold style={{ flex: 3, color: theme.colors.darkGrey2 }}>
							Receiver
						</Text>

						<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
							COD
						</Text>

						<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
							Volume
						</Text>

						<div style={{ flex: 1 }}></div>
					</div>
					<div
						className={classes.trip_wrap}
						draggable={true}
						onDragStart={(e) => {
							set_active_pickup(_.get(active_marker, 'pickup_address', ''));
							set_drag_trip_id(_.get(active_marker, 'pbid', ''));
						}}
						style={{ borderRadius: '4px' }}
						onDragEnter={(e) => {}}
						onDragEnd={() => {
							set_active_pickup(null);
						}}>
						<div className={classes.trip_row}>
							<div style={{ flex: 1 }}>
								<div className={classes.key_item_wrap}>
									<Text bold component={'span'} className={classes.key_item_text}>
										{active_marker_index + 1}
									</Text>
								</div>
							</div>

							<Text bold className={classes.pbid_text_highlighted} onClick={() => set_open_pbid_modal(active_marker.external_order_id)}>
								{active_marker.pbid}
							</Text>

							<Text bold style={{ flex: 3, color: theme.colors.darkGrey2 }}>
								{active_marker.receiver_details.name}
							</Text>

							<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
								{active_marker.amount}
							</Text>

							<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
								{active_marker.volume}
							</Text>
							<div style={{ flex: 1, visibility: 'hidden' }} className='cross'>
								<IconButton
									style={{ borderColor: 'transparent' }}
									onClick={() => {
										if (premium_features_blocked) {
											handle_show_premium_feature();
											return;
										}
										set_confirm_remove_squence_details({
											open: true,
											trip_ids: [active_marker.trip_id],
										});
									}}>
									<img src={ImageLinks.crossRed} width='10' style={{ cursor: 'pointer' }} />
								</IconButton>
							</div>
						</div>
					</div>
				</>
			)}

			{bundle_id && show_detail === detail_menu.BUNDLE && (
				<div className={`box-shadow ${classes.stats_box}`}>
					<div className={classes.edit_wrap}>
						<div className={classes.edit_input}>
							<Text medium style={{ fontSize: 16 }}>
								{bundle_label || '-'}
							</Text>
						</div>
						<Text bold className={classes.bid_text}>
							{bundle_id}
						</Text>
					</div>

					<div className={classes.stats_wrap}>
						<div style={{ flex: 0.5 }}>
							<Text semi style={{ fontSize: 16 }}>
								{active_bundle_data.trips || '-'}
							</Text>
							<Text semi className={classes.stats_label}>
								Trips
							</Text>
						</div>

						<div style={{ flex: 0.5 }}>
							<Text semi style={{ fontSize: 16 }}>
								{active_bundle_data.totalVolume || '-'}
							</Text>
							<Text semi className={classes.stats_label}>
								Vol.
							</Text>
						</div>

						<div style={{ flex: 1 }}>
							<Text semi style={{ fontSize: 16 }}>
								{active_bundle_data.distanceInMetres ? Math.round(active_bundle_data.distanceInMetres / 1000) + ' km' : '-'}
							</Text>
							<Text semi className={classes.stats_label}>
								Distance
							</Text>
						</div>

						<div style={{ flex: 1 }}>
							<Text semi style={{ fontSize: 16 }}>
								{active_bundle_data.timeInSeconds
									? active_bundle_data.timeInSeconds < 3600
										? Math.round(active_bundle_data.timeInSeconds / 60) + ' mins'
										: Math.round(active_bundle_data.timeInSeconds / 360) / 10 + ' hrs'
									: '-'}
							</Text>
							<Text semi className={classes.stats_label}>
								Time
							</Text>
						</div>

						<div style={{ flex: 1 }}>
							<Text semi style={{ fontSize: 16 }}>
								{active_bundle_data.totalAmount ? `₹${active_bundle_data.totalAmount}` : '-'}
							</Text>
							<Text semi className={classes.stats_label}>
								COD
							</Text>
						</div>
					</div>
				</div>
			)}
			<div id='map_canvas_address' className='map-canvas-wrapper rider-location card' style={{ height: '100%' }} />
			{confirm_remove_squence_details.open && (
				<ConfirmClose
					style={{ width: 600 }}
					close={() => set_confirm_remove_squence_details({ ...initial_confirm_remove_squence_details })}
					on_confirm={handle_confirm_close}
					headerTitle={'Delete Route'}
					title={
						<Text medium className={classes.modal_text}>
							<img src={ImageLinks.exclamation_error} width='20' style={{ marginRight: 10 }} />
							Are you sure you want to remove{' '}
							{confirm_remove_squence_details.trip_ids.length > 1 ? confirm_remove_squence_details.trip_ids.length : 'this'} order from the
							sequence?
						</Text>
					}
					titleStyles={{ width: '100%' }}
				/>
			)}
			{_.isEmpty(active_marker) &&
				show_detail !== detail_menu.BUNDLE &&
				_.filter(selected_bundle_list, (value) => {
					return value !== '';
				}).length > 0 && (
					<InfiniteToast>
						<img src={ImageLinks.double_click_icon} width={16} height={16} alt='' />
						<Text component={'span'} semi style={{ marginLeft: 4 }}>
							Double click a card to view details
						</Text>
					</InfiniteToast>
				)}
			{!_.isEmpty(active_marker) && (
				<InfiniteToast>
					<img src={ImageLinks.drag_icon} width={16} height={16} alt='' />
					<Text component={'span'} semi style={{ marginLeft: 4 }}>
						Drag card to move to another route
					</Text>
				</InfiniteToast>
			)}
			{open_pbid_modal && (
				<OrderModal
					id={open_pbid_modal}
					close={() => set_open_pbid_modal(null)}
					updater={() => {
						set_refresh((val) => !val);
					}}
					alt=''
				/>
			)}
		</div>
	);
}

export default CompareRoutes;
