import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'hidden',
	},
	header_btn_wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		marginTop: -120,
	},
	ongoingOrdersDiv: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: 40,
		marginTop: -40,
		paddingBottom: 10,
		gap: 10,
	},
	subtitle: { fontSize: 12, color: theme.colors.lightGrey6, marginTop: 2 },
	detailsTextContainer: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
		gap: 10,
	},
	bulk_action_wrapper: {
		position: 'absolute',
		right: 0,
		zIndex: 1198,
	},
	pickedUpContainer: {
		overflowY: 'auto',
		flex: 1,
		marginTop: 10,
		marginBottom: 10,
	},
	pickedUpItem: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
	},
	orderDetailsClickabletext: {
		flex: 1,
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
	pagesContainer: {
		height: 60,
		marginBottom: 10,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		borderTop: `1px solid ${theme.colors.lightPurple}`,
	},
	pageItemDiv: {
		marginLeft: 20,
		marginRight: 20,
		width: 24,
		height: 24,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 12,
	},
	pageText: { color: theme.colors.primary, fontSize: 12 },
	setPageContainer: { display: 'flex', alignItems: 'center' },
	truncate: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 16,
	},
	manualIcon: {
		width: 35,
		height: 35,
		marginRight: 10,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: '100%',
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
			'& .tooltip': {
				display: 'flex !important',
			},
		},
	},
	autoIcon: {
		width: 35,
		height: 35,
		marginRight: 10,
		backgroundColor: theme.colors.white,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
			'& .tooltip': {
				display: 'flex !important',
			},
		},
	},
	tooltip_text: {
		position: 'absolute',
		marginTop: -80,
		width: 60,
		height: 30,
		borderRadius: 5,
		fontSize: 12,
		display: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		color: theme.colors.lightPurple5,
		backgroundColor: theme.colors.lightPurple,
	},
	filter_btn: {
		backgroundColor: theme.colors.white,
		// marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	header_title_2: { flex: 2, color: theme.colors.darkGrey2, fontSize: 14 },
	table_item_wrap_2: { flex: 2, minWidth: 0 },
	page_nav_btn: {
		width: 24,
		height: 24,
		borderRadius: 30,
	},
	enabled_page_nav: { border: `1px solid ${theme.colors.primary}` },
	disabled_page_nav: { backgroundColor: theme.colors.lightGrey5, width: 26, height: 26 },
	wrapper_help_center: { position: 'fixed', bottom: 30, left: '50%', zIndex: 100 },
}));

export default useStyles;
