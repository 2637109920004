import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	bundle_list_item: {
		backgroundColor: theme.colors.white,
		marginTop: 10,
		borderRadius: 5,
		padding: '10px 12px',
		position: 'relative',
	},
	list_item_wrap: { display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' },
	list_item_stats_wrap: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', columnGap: 10, alignItems: 'flex-start' },
	list_item_stats_wrap_2: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' },
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 1000,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		zIndex: 1200,
		overflowY: 'hidden',
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
		display: 'flex',
		flexDirection: 'column',
	},
	pbidContainer: {
		fontSize: 12,
		color: theme.colors.primary,
	},
	rider_row: {
		padding: 10,
		borderBottom: `1px solid ${theme.colors.lightGrey3}`,
		display: 'flex',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.lightGrey4,
		},
	},
	header: {
		borderBottom: '1px solid lightgrey',
		paddingBottom: 10,
		display: 'flex',
		justifyContent: 'space-between',
	},
	header_text_wrap: { display: 'flex', alignItems: 'center' },
	header_text: { fontSize: 30, color: theme.colors.primary },
	options_Wrap: { flex: 1, marginTop: 20, marginBottom: 20, marginRight: -20, paddingRight: 20 },
	manual_wrap: {
		borderTop: `1px solid ${theme.colors.lightGrey5}`,
		paddingTop: 10,
		marginTop: 10,
		width: '100%',
	},
	pool_options: { marginTop: 20, display: 'flex', justifyContent: 'space-between', marginLeft: 45 },
	rider_list: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 20, paddingTop: 0 },
	search_list: { flex: 1, overflowY: 'auto', marginBottom: 20, margin: 20, marginTop: -10 },
	cta_btn: { borderRadius: 30, height: 45, width: 180 },
	map_wrap: { right: 580, zIndex: 1199, width: 450, padding: 0 },
	dialog_wrap: {
		height: 270,
		width: 250,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	allocate_text: { fontSize: 28, marginTop: 20 },
	ongoing_text: {
		color: theme.colors.primary,
		fontSize: 16,
		textDecoration: 'underline',
		cursor: 'pointer',
		textAlign: 'center',
		marginTop: 10,
	},
	rcmd_text_wrap: { padding: '5px 10px', backgroundColor: theme.colors.green, borderRadius: 5, width: 150, textAlign: 'center' },
	rcmd_text: { color: theme.colors.white, fontSize: 10 },
	smart_amount: { color: theme.colors.darkGrey2 },
	list_item_text: {
		fontSize: 10,
		color: theme.colors.darkGrey2,
		marginTop: 5,
	},
	unlink_text: {
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	header: {
		paddingBottom: 5,
		height: 62,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	map_wrap: { padding: 20, flex: 1, marginRight: 10, borderRadius: 20, height: '100%', overflowY: 'hidden' },

	content: {
		display: 'flex',
		flex: 1,
		overflow: 'hidden',
	},
	right_wrap: { flex: 1, overflow: 'hidden', paddingRight: 30 },

	footer: {
		height: 100,
		width: 520,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',

		display: 'flex',
		justifyContent: 'space-between',
	},
	footer_content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flex: 1,
	},
	allocate_card: {
		padding: 12,
		minHeight: 64,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		cursor: 'pointer',
	},
	icon_wrap: {
		width: 40,
		height: 40,
		borderRadius: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default useStyles;
