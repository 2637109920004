import { ListItem } from '@material-ui/core';
import React from 'react';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import { makeStyles } from '@material-ui/styles';
import theme from 'resources/theme';
import _ from 'lodash';
import Button from 'modules/Button';

const useStyles = makeStyles({
	wrapper: {
		padding: '10px 12px',
		borderRadius: 6,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		background: theme.colors.white,
		marginTop: 16,
		'&:hover': {
			background: theme.colors.white,
		},
	},
	subtitle_text: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 },
	separator: { width: '100%', border: `1px solid ${theme.colors.lightGrey5}`, marginTop: 8, marginBottom: 8 },
});

const AddressItem = ({
	id,
	poc,
	label,
	name = null,
	mobile = null,
	selected_address_id,
	title,
	subTitle,
	zone,
	on_select_marker,
	on_add_sender,
	is_warehouse,
}) => {
	const classes = useStyles();
	return (
		<ListItem
			button
			onClick={on_select_marker}
			className={classes.wrapper}
			style={{ border: `1px solid ${selected_address_id === id ? theme.colors.primary : theme.colors.lightGrey5}` }}>
			<div style={{ width: '100%' }} className='align-items-center justify-content-between'>
				<div className='align-items-center'>
					{!_.isEmpty(label) && (
						<Text bold style={{ fontSize: 14, marginRight: 4 }}>
							[{label}]-
						</Text>
					)}
					<Text bold style={{ fontSize: 14 }}>
						{title}
					</Text>
				</div>
				<img src={ImageLinks.arrow_up_circle_solid} style={{ transform: `rotate(90deg)` }} width={16} height={16} />
			</div>
			<Text medium className={classes.subtitle_text}>
				{subTitle}
			</Text>
			<div className={classes.separator}></div>
			<div style={{ width: '100%' }} className={'d-flex justify-content-between'}>
				<div className={'align-items-center'}>
					<div className={'align-items-center'}>
						<img src={ImageLinks.zone} width='16' height='16' alt='' />
						<Text style={{ fontSize: 12, marginLeft: 4 }}>{zone}</Text>
					</div>
					{is_warehouse && (
						<>
							<div className={'align-items-center'} style={{ marginLeft: 20, marginRight: 20 }}>
								<img src={ImageLinks.user} width='16' height='16' alt='' />
								<Text className={classes.content_text}>{name}</Text>
							</div>
							<div className={'align-items-center'}>
								<img src={ImageLinks.phone} width='16' height='16' alt='' />
								<Text className={classes.content_text}>{mobile}</Text>
							</div>
						</>
					)}
				</div>

				{!is_warehouse && (
					<Button
						type='text'
						size='extra_small'
						text={`${_.isEmpty(poc) ? 'Add' : poc.length} Sender`}
						onClick={on_add_sender}
						left_icon={_.isEmpty(poc) ? ImageLinks.add_pruple_no_fill : ImageLinks.user_purple}
					/>
				)}
			</div>
		</ListItem>
	);
};

export default AddressItem;
