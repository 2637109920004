import _ from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import utils from 'resources/utils';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { login_failed } from 'actions/app';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		height: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const ShopifyAuth = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const query_object = qs.parse(history.location.search.slice(1));

	useEffect(() => {
		const token = utils._retrieveData(constant.AUTH_TOKEN);
		if (_.isEmpty(token) || _.isEmpty(query_object.shopify_token)) return;
		if (query_object?.is_login == 'false') {
			dispatch(login_failed({ shopify_token: query_object?.shopify_token }));
			return;
		}

		start_shopify_auth();
	}, []);

	const start_shopify_auth = async () => {
		try {
			const response = await api_requests.shopify_register({
				shopify_token: query_object?.shopify_token,
			});
			toast.success('Authenticated', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			history.replace('/');
		} catch (error) {
			toast.error('Shopify Authentication Failed', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			history.replace('/');
		}
	};

	return (
		<div className={`container ${classes.wrapper}`}>
			<CircularProgress />
		</div>
	);
};

export default ShopifyAuth;
