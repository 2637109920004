import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Button from 'modules/Button';

import IconButton from 'modules/IconButton';
import ConfirmClose from 'modules/modals/ConfirmClose';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import Text from 'modules/Text';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import Routes from 'resources/Routes';
import theme from 'resources/theme';
import utils from 'resources/utils';

let map = null;
let marker;

const useStyles = makeStyles((theme) => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 20, overflowY: 'hidden' },
	stats: { display: 'flex', marginRight: 30, minHeight: 70 },
	stats_box: { backgroundColor: theme.colors.white, display: 'flex', flex: 1, borderRadius: 5, overflow: 'hidden' },
	trip_row: {
		width: '100%',
		padding: '5px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'&:hover': {
			'& > .cross': {
				visibility: 'visible !important',
			},
		},
	},
	edit_wrap: { padding: 16, width: 200 },
	edit_input: { display: 'flex', alignItems: 'center' },
	bid_text: { fontSize: 12, marginTop: 8 },
	stats_wrap: {
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.colors.lightPurple,
		padding: 12,
	},
	stats_label: { color: theme.colors.lightGrey6, fontSize: 12 },
	bundle_list_wrap: { flex: 1, display: 'flex', overflowY: 'hidden', marginTop: 20, marginBottom: 20 },
	table_wrap: { flex: 4, display: 'flex', flexDirection: 'column', overflowY: 'hidden', marginRight: 20 },
	table_header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	table_head: {
		background: theme.colors.white,
		border: `1px solid ${theme.colors.lightPurple}`,
		borderRadius: 50,
		width: '100%',
		padding: '10px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
	},
	key_item_wrap: {
		height: 20,
		width: 20,
		backgroundColor: theme.colors.lightPurple,
		borderRadius: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	key_item_text: {
		color: theme.colors.primary,
		fontSize: 12,
	},
	right_section: { flex: 3, display: 'flex', flexDirection: 'column', marginTop: 50 },
	map_wrap: { marginRight: 30, flex: 1, borderRadius: 20, overflow: 'hidden' },
	modal_text: { display: 'flex', alignItems: 'center', fontSize: 16 },
	btn_wrap: { display: 'flex', justifyContent: 'flex-end', marginTop: 16, marginBottom: 60, height: 40 },
	pbid_text_highlighted: {
		flex: 2,
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
}));

const initial_confirm_remove_squence_details = {
	open: false,
	trip_ids: [],
};

const initial_confirm_unassign_details = {
	open: false,
};

const ActiveRoutes = ({
	bundle_id,
	bundle_label,
	set_allocate_rider_details,
	set_refresh_bundles,
	selected_menu,
	premium_features_blocked,
	handle_show_premium_feature,
	set_active_bundle,
}) => {
	const history = useHistory();

	const [bundle_data, set_bundle_data] = React.useState({});
	const [confirm_remove_squence_details, set_confirm_remove_squence_details] = React.useState(_.cloneDeep(initial_confirm_remove_squence_details));

	const [confirm_unassign_details, set_confirm_unassign_details] = React.useState(_.cloneDeep(initial_confirm_unassign_details));
	const [open_pbid_modal, set_open_pbid_modal] = useState(false);
	const [refresh, set_refresh] = React.useState(false);

	const handle_unassign = () => {
		if (premium_features_blocked) {
			handle_show_premium_feature();
			return;
		}
		api_requests
			.unassign_rider({
				bundleId: bundle_id,
			})
			.then((res) => {
				toast.success(res.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				set_refresh_bundles((v) => !v);
				history.push(Routes.GROUPING_AND_ALLOCATION.path + `/?type=${selected_menu}`);
			});
	};

	const handle_confirm_close = () => {
		api_requests
			.ungroup_trip({
				trips: confirm_remove_squence_details.trip_ids,
			})
			.then((res) => {
				if (bundle_data.tripData.length === confirm_remove_squence_details.trip_ids.length) {
					set_refresh_bundles((v) => !v);
					set_active_bundle({});
					set_bundle_data({});
					history.replace({ search: '?type=' + selected_menu });
				}
				set_refresh(!refresh);
			});
	};

	useEffect(() => {
		if (!bundle_id) {
			return null;
		}
		api_requests.get_bundles_data(bundle_id).then((res) => {
			set_bundle_data(res.data);
		});
	}, [refresh]);

	useEffect(() => {
		setTimeout(() => {
			if (_.isEmpty(bundle_data.tripData)) {
				map = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
					center: { lat: 28, lng: 77 },
					zoom: 10,
					clickableIcons: false,
					disableDefaultUI: true,
					scaleControl: true,
					zoomControl: true,
					mapId: constant.MAP_DESIGN_ID,
				});
				return;
			}

			map = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
				center: { lat: Number(bundle_data.tripData[0].pickup_address.latitude), lng: Number(bundle_data.tripData[0].pickup_address.longitude) },
				zoom: 10,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});

			const icon = {
				url: ImageLinks.marker_purple, // url
				scaledSize: new window.google.maps.Size(32, 32), // scaled size
			};

			const pick_up_icon = {
				url: ImageLinks.rider_pickup, // url
				scaledSize: new window.google.maps.Size(32, 32), // scaled size
			};

			new window.google.maps.Marker({
				position: { lat: Number(bundle_data.tripData[0].pickup_address.latitude), lng: Number(bundle_data.tripData[0].pickup_address.longitude) },
				icon: pick_up_icon,
				map,
			});

			marker = _.map(bundle_data.tripData, (val, key) => {
				return new window.google.maps.Marker({
					position: { lat: Number(val.drop_address.latitude), lng: Number(val.drop_address.longitude) },
					label: { text: `${key + 1}`, color: 'white' },
					icon: icon,
					map,
				});
			});

			let bounds = new window.google.maps.LatLngBounds();
			let pickup_coord = new window.google.maps.LatLng(
				Number(bundle_data.tripData[0].pickup_address.latitude),
				Number(bundle_data.tripData[0].pickup_address.longitude),
			);
			bounds.extend(pickup_coord);
			_.map(bundle_data.tripData, (val) => {
				let drop_coord = new window.google.maps.LatLng(Number(val.drop_address.latitude), Number(val.drop_address.longitude));
				bounds.extend(drop_coord);
			});
			map.fitBounds(bounds);
		}, 800);
	}, [bundle_data.tripData]);

	const classes = useStyles();

	return (
		<>
			<div className={classes.wrapper}>
				<div className={classes.stats}>
					<div className={`box-shadow ${classes.stats_box}`}>
						<div className={classes.edit_wrap}>
							<div className={classes.edit_input}>
								<Text medium style={{ fontSize: 16 }}>
									{bundle_label || '-'}
								</Text>
							</div>
							<Text bold className={classes.bid_text}>
								{bundle_id}
							</Text>
						</div>

						<div className={classes.stats_wrap}>
							<div style={{ marginLeft: 10, flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.trips || '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									Trips
								</Text>
							</div>

							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.totalVolume || '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									Vol.
								</Text>
							</div>

							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.distanceInMetres ? Math.round(bundle_data.distanceInMetres / 100) / 10 + ' km' : '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									Distance
								</Text>
							</div>

							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.timeInSeconds
										? bundle_data.timeInSeconds < 3600
											? Math.round(bundle_data.timeInSeconds / 60) + ' mins'
											: Math.round(bundle_data.timeInSeconds / 360) / 10 + ' hrs'
										: '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									Time
								</Text>
							</div>

							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.totalAmount ? `₹${bundle_data.totalAmount}` : '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									COD
								</Text>
							</div>
						</div>
					</div>
					<div style={{ marginLeft: 30, width: 330 }}>
						{_.get(bundle_data, 'rider_data.id') && (
							<div
								className='box-shadow'
								style={{
									borderRadius: 10,
									display: 'flex',
									height: '100%',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '0 20px',
								}}>
								<div>
									<Text bold style={{ fontSize: 12 }}>
										{bundle_data.rider_data.id}
									</Text>
									<Text style={{ fontSize: 16 }}>
										{bundle_data.rider_data.first_name} {bundle_data.rider_data.last_name}
									</Text>
								</div>
								<Button
									type='round'
									text='Unassign'
									size='medium'
									onClick={() => {
										utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_UNASSIGN);
										set_confirm_unassign_details({ open: true });
									}}
								/>
							</div>
						)}
					</div>
				</div>
				<div className={classes.bundle_list_wrap}>
					<div className={classes.table_wrap}>
						<div className={classes.table_header}>
							<Text semi style={{ fontSize: 20 }}>
								Route Sequence
							</Text>
						</div>
						<div style={{ flex: 1, overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
							<div className={classes.table_head}>
								<Text bold style={{ flex: 1, color: theme.colors.darkGrey2 }}>
									#
								</Text>

								<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
									PBID
								</Text>

								<Text bold style={{ flex: 3, color: theme.colors.darkGrey2 }}>
									Receiver
								</Text>

								<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
									COD
								</Text>

								<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
									Volume
								</Text>

								<div style={{ flex: 1 }}></div>
							</div>

							<div style={{ overflowY: 'auto', flex: 1, marginTop: 10, marginBottom: 10 }}>
								{_.map(bundle_data.tripData, (trip, key) => {
									return (
										<div className={classes.trip_row} key={`trip${key}`}>
											<div style={{ flex: 1 }}>
												<div className={classes.key_item_wrap}>
													<Text bold component={'span'} className={classes.key_item_text}>
														{key + 1}
													</Text>
												</div>
											</div>

											<Text
												bold
												className={classes.pbid_text_highlighted}
												style={{ flex: 2 }}
												onClick={() => set_open_pbid_modal(trip.external_order_id)}>
												{trip.pbid}
											</Text>

											<Text bold style={{ flex: 3, color: theme.colors.darkGrey2 }}>
												{trip.receiver_details.name}
											</Text>

											<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
												{trip.amount}
											</Text>

											<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
												{trip.volume}
											</Text>

											<div style={{ flex: 1, visibility: 'hidden' }} className='cross'>
												<IconButton
													style={{ borderColor: 'transparent' }}
													onClick={() => {
														set_confirm_remove_squence_details({
															open: true,
															trip_ids: [trip.trip_id],
														});
													}}>
													<img src={ImageLinks.crossRed} width='10' style={{ cursor: 'pointer' }} alt='' />
												</IconButton>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<div className={classes.right_section}>
						<div className={classes.map_wrap}>
							<div id='map_canvas_address' className='map-canvas-wrapper rider-location card' style={{ height: '100%' }} />
						</div>
						{!_.isEmpty(bundle_data) && (
							<>
								<div className={classes.btn_wrap}>
									<IconButton
										onClick={() => {
											set_confirm_remove_squence_details({
												open: true,
												trip_ids: _.map(bundle_data.tripData, 'trip_id'),
											});
										}}
										style={{ backgroundColor: theme.colors.lightRed, borderColor: 'transparent', height: 40, width: 40, marginRight: 20 }}>
										<img src={ImageLinks.delete} alt='' width={20} height={20} />
									</IconButton>
									<Button
										size='large'
										type='half_round'
										text='Change Rider'
										right_icon={ImageLinks.arrow_right_white_no_fill}
										onClick={() => {
											utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_CHANGE_RIDER);
											if (premium_features_blocked) {
												handle_show_premium_feature();
												return;
											}
											set_allocate_rider_details({
												open: true,
												bundle_id,
												tripData: bundle_data.tripData,
											});
										}}
										style={{ color: theme.colors.white }}
									/>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{confirm_remove_squence_details.open && (
				<ConfirmClose
					style={{ width: 600 }}
					close={() => set_confirm_remove_squence_details(_.cloneDeep(initial_confirm_remove_squence_details))}
					on_confirm={handle_confirm_close}
					headerTitle={'Remove Order from Route'}
					title={'Are you sure you want to remove this order from the sequence?'}
					titleStyles={{ width: '100%' }}
				/>
			)}

			{confirm_unassign_details.open && (
				<ConfirmClose
					headerTitle={'Unassign Rider'}
					style={{ width: 600 }}
					close={() => set_confirm_unassign_details(_.cloneDeep(initial_confirm_unassign_details))}
					on_confirm={handle_unassign}
					title={`Are you sure you want to remove rider ${_.get(
						bundle_data,
						'rider_data.id',
					)} from ${bundle_label}? This will delay all orders in sequence.`}
					titleStyles={{ width: '100%', textAlign: 'left' }}
					negative_btn_text={'Cancel'}
					positive_btn_text={'Unassign'}
					is_error_msg={true}
				/>
			)}
			{open_pbid_modal && (
				<OrderModal
					id={open_pbid_modal}
					close={() => set_open_pbid_modal(null)}
					updater={() => {
						set_refresh((val) => !val);
					}}
				/>
			)}
		</>
	);
};

export default ActiveRoutes;
