const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: '45vw',
		backgroundColor: 'white',

		padding: 20,
		zIndex: 1200,
		overflowY: 'auto',
		display: 'flex',
		animationDelay: '0s',
		transitionTimingFunction: 'ease',
		animationDuration: '300ms',
	},
}));

export default useStyles;
