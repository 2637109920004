import React, { useEffect } from 'react';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/styles';
import QRCode from 'react-qr-code';

import Text from 'modules/Text';
import IconButton from 'modules/IconButton';

import cross from 'assets/images/icons/cross-black.svg';
import _ from 'lodash';
import Amount from '../../../../modules/Amount';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles(() => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 600,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 50,
		zIndex: 1200,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column'
	},
	pbidContainer: {
		fontSize: 12,
		color: theme.colors.primary
	}
}));

const PendingRequest = ({ close, requests, set_pending_request_refresh }) => {
	const classes = useStyles();
	const [qr_data, set_qr_data] = React.useState({});
	const [refresh, set_refresh] = React.useState(false);

	useEffect(() => {
		set_qr_data({});
		api_requests.get_qr_code_data().then((res) => {
			set_qr_data(res.data);
		});
	}, [refresh]);

	const handleRefresh = () => {
		set_refresh(!refresh);
		set_pending_request_refresh((value) => !value);
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div
					style={{
						borderBottom: '1px solid lightgrey',
						paddingBottom: 10,
						display: 'flex',
						justifyContent: 'space-between'
					}}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Text semi style={{ fontSize: 20, color: theme.colors.primary }}>
							Pending Request
						</Text>
					</div>
					<div style={{ display: 'flex' }}>
						<IconButton onClick={handleRefresh} style={{ border: 'none' }}>
							<img src={ImageLinks.refresh} width={25} height={25} />
						</IconButton>
						<IconButton onClick={close} style={{ border: 'none', marginLeft: 20 }}>
							<img src={cross} width={16} height={16} />
						</IconButton>
					</div>
				</div>
				<div style={{ flex: 1, marginTop: 20, marginBottom: 20, overflowY: 'auto', marginRight: -20, paddingRight: 20 }}>
					{_.map(requests, (request, key) => (
						<div key={`info${key}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 75 }}>
							<div>
								<Text semi style={{ fontSize: 16 }}>
									{_.get(request, 'data.meta_info.from.name')} (#{_.get(request, 'data.meta_info.from.id')})
								</Text>
								<Text bold style={{ marginTop: 5, fontSize: 12, color: theme.colors.darkGrey2 }}>
									{_.capitalize(_.get(request, 'data.meta_info.from.type'))}
								</Text>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Amount
									amount={_.get(request, 'data.amount')}
									main_text_styles={{ fontSize: 16, color: theme.colors.black }}
									sub_text_styles={{ fontSize: 12, color: theme.colors.black }}
								/>
								<div
									style={{
										background: theme.colors.lightPurple,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: 115,
										height: 40,
										borderRadius: 10,
										marginLeft: 10
									}}>
									<Text
										bold
										style={{
											color: theme.colors.primary,
											fontSize: 16
										}}>
										OTP: {request.otp}
									</Text>
								</div>
							</div>
						</div>
					))}
				</div>
				{!_.isEmpty(qr_data) && (
					<div
						style={{
							height: 160,
							width: '100%',
							position: 'relative',
							borderRadius: 15,
							border: `2px solid ${theme.colors.lightPurple}`
						}}>
						<div style={{ height: '50%', padding: 25, display: 'flex' }}>
							<div style={{ height: 35, width: 35, borderRadius: '100%', background: theme.colors.lightGrey2, border: '1px solid black' }}></div>
							<div style={{ marginLeft: 20 }}>
								<Text bold>{qr_data.id}</Text>
								<Text semi style={{ fontSize: 18 }}>
									{qr_data.first_name + qr_data.last_name}
								</Text>
							</div>
						</div>
						<div style={{ height: '50%', background: '#F3F0F9', padding: 25 }}>
							<div style={{ marginLeft: 55 }}>
								<Text bold>{constant.USER_TYPE_TEXT[qr_data.user_type]}</Text>
							</div>
						</div>
						<div style={{ position: 'absolute', top: 20, right: 30, height: 120, width: 120 }}>
							<QRCode
								value={JSON.stringify({
									id: qr_data.id,
									first_name: qr_data.first_name,
									last_name: qr_data.last_name,
									user_type: qr_data.user_type,
									owner_id: qr_data.owner_id,
									owner_type: qr_data.owner_type
								})}
								size='120'
							/>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default PendingRequest;
