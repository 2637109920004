import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import moment from 'moment';
import IconButton from 'modules/IconButton';
import NotificationService from 'resources/NotificationService';

const useStyles = makeStyles({
	alert_item_wrap: {
		width: 500,
		minHeight: 94,
		backgroundColor: theme.colors.white,
		boxShadow: '0px 1px 0px 0px rgba(228, 232, 238, 1)',
		paddingTop: 12,
		paddingLeft: 10,
		paddingRight: 14,
		marginBottom: 12,
		cursor: 'pointer',
		animation: '$myEffect 1000ms forwards',
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 0.3,
			transform: 'translateX(-100%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateX(0)',
		},
	},
	preview_cta: {
		height: 28,
		borderRadius: 44,
		padding: '4px 12px',
		boxShadow: 'none',
		marginRight: 8,
	},
	icon_wrap: {
		width: 16,
		height: 16,
		borderRadius: 8,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	action_btns: {
		height: 24,
		padding: '2px 12px',
		borderRadius: 44,
		marginRight: 4,
	},
});

const action_mapping = {
	approve: 'Approved',
	reject: 'Rejected',
};

const LiveAlerts = ({ item, handle_close = () => {}, open_notif_modal }) => {
	const [show, set_show] = useState(true);
	const classes = useStyles();
	const timer_ref = useRef(null);

	useEffect(() => {
		timer_ref.current = setTimeout(() => {
			set_show(false);
			handle_close(item);
		}, 5000);
		construct_notif_message();
		return () => {
			clearTimeout(timer_ref.current);
		};
	}, []);

	const on_close = (e) => {
		e.stopPropagation();
		handle_close(item);
		set_show(false);
		clearTimeout(timer_ref.current);
	};

	const format_data = (key, value) => {
		if ((key === 'start_ts' || key === 'end_ts') && value) return moment(value).format('DD-MM-YYYY hh:mm A');
		if ((key === 'speed' || key === 'distance') && value) return value.toFixed(3);
		return value;
	};

	const construct_notif_message = () => {
		if (!show) return;
		const title = `Today at ${moment().format('hh:mm A')}`;
		let body = '';
		_.map(
			output,
			(content, index) =>
				(body +=
					content.type === 'text'
						? content.value
						: item.type === 'TRACE_LOCATION'
						? format_data(content.value, item.variables?.[content.value])
						: item.variables?.[content.value]),
		);

		NotificationService.show_notification(title, { body });
	};

	let content = _.get(item, 'body.content', '');
	let actions = _.get(item, 'body.actions', []);
	let actor = _.get(item, 'body.actor', null);
	let selected_action = _.get(item, 'body.selectedAction', null);
	const pattern = /(\#\#.*?\#\#)/g;
	const split_string_by_pattern = content.split(pattern);
	const output = _.map(split_string_by_pattern, (item) =>
		item.match(pattern) ? { type: 'braces', value: item.replaceAll('#', '') } : { type: 'text', value: item },
	);

	return show ? (
		<div className={classes.alert_item_wrap} onClick={() => open_notif_modal(item)}>
			<div className='justify-content-between align-items-center' style={{ ...(actions.length === 0 && { height: '100%' }) }}>
				<div className='d-flex'>
					<div className={classes.icon_wrap}>
						<img width={8} height={8} src={ImageLinks.box2_purple} />
					</div>
					<div style={{ marginLeft: 10, flex: 1 }}>
						<Text semi style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
							Today at {moment().format('hh:mm A')}
						</Text>

						<div>
							{_.map(output, (content, index) => (
								<Text
									key={`prototype_text_${index}`}
									medium={content.type === 'text'}
									bold={content.type === 'braces'}
									component={'span'}
									style={{
										fontSize: 12,
										lineHeight: 1.5,
									}}>
									{content.type === 'text'
										? content.value
										: item.type === 'TRACE_LOCATION'
										? format_data(content.value, item.variables?.[content.value])
										: item.variables?.[content.value]}
								</Text>
							))}
						</div>
					</div>
				</div>
				<div className='align-items-center'>
					{!_.isEmpty(selected_action) && (
						<div style={{ marginLeft: 10 }}>
							<Text semi style={{ fontSize: 12, color: selected_action.value === 'approve' ? theme.colors.green : theme.colors.red }}>
								{`${action_mapping[selected_action.value]} by ${_.capitalize(actor.name)}`}
							</Text>
						</div>
					)}
					<IconButton onClick={on_close}>
						<img src={ImageLinks.cross_circle_solid_gray} />
					</IconButton>
				</div>
			</div>
			<div className='align-items-center' style={{ paddingLeft: 20, marginTop: 10, marginBottom: 10 }}>
				{_.map(actions, (btn_action, btn_index) => (
					<ContainedButton
						key={`action_btn_${btn_index}`}
						onClick={() => {}}
						className={classes.action_btns}
						style={{
							backgroundColor: btn_action.value === 'approve' ? theme.colors.green : theme.colors.red,
						}}>
						<Text semi style={{ fontSize: 12 }}>
							{btn_action.text}
						</Text>
					</ContainedButton>
				))}
			</div>
		</div>
	) : null;
};

export default LiveAlerts;
