import React from 'react';
import useStyles from './styles';
import Text from 'modules/Text';
import { CircularProgress, LinearProgress, useTheme } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

function ProcessingStates({ progress_data, selected_order_ids = [], reset_modal }) {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const processing_data = _.concat(
		..._.map(progress_data, (data) => {
			return data.processing;
		}),
	);

	const processed_data = _.concat(
		..._.map(progress_data, (data) => {
			return data.processed;
		}),
	);

	const unprocessed_data = _.concat(
		..._.map(progress_data, (data) => {
			return data.unprocessed;
		}),
	);

	return (
		<div className={classes.process_wrapper}>
			{processing_data && processing_data.length > 0 && (
				<div style={{ width: '100%' }} className='d-flex justify-content-between'>
					<div style={{ flex: 1 }}>
						<Text bold style={{ fontSize: 16 }}>
							<Text bold component={'span'} style={{ color: theme.colors.primary, fontSize: 16 }}>
								{processed_data.length} of {selected_order_ids.length}{' '}
							</Text>
							processed
						</Text>
						<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
							Please stay on the window to complete action.{' '}
						</Text>
						<LinearProgress
							className={classes.progress_bar}
							value={(processed_data.length * 100) / selected_order_ids.length}
							variant='determinate'
						/>
					</div>

					<div style={{ flex: 0.2 }}>{processed_data.length === 0 && <CircularProgress />}</div>
				</div>
			)}
			{processing_data && processing_data.length === 0 && (
				<>
					<div className='d-flex justify-content-between' style={{ width: '100%' }}>
						<div>
							<Text
								bold
								component={'span'}
								className={classes.title_text}
								style={{ color: processed_data.length > 0 ? theme.colors.green : theme.colors.red }}>
								<img src={processed_data.length > 0 ? ImageLinks.green_tick : ImageLinks.exclamation_error}></img>
								{processed_data.length > 0 ? `${processed_data.length} Orders Processed Successfully!` : 'Unable to process orders.'}
							</Text>
							{unprocessed_data.length === 0 && (
								<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
									Orders have been added to existing routes.
								</Text>
							)}
							{unprocessed_data.length > 0 && (
								<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
									{processed_data.length > 0 ? `Unable to process ${unprocessed_data.length} orders.` : 'Please Try again'}{' '}
								</Text>
							)}
						</div>
						<div>
							{processed_data.length > 0 && (
								<ContainedButton style={{ borderRadius: 30 }} onClick={() => history.push('/grouping-and-allocation?type=unallocated')}>
									<Text bold>View Routes</Text>
								</ContainedButton>
							)}
							{processed_data.length === 0 && (
								<ContainedButton style={{ borderRadius: 30 }} onClick={() => reset_modal()}>
									<Text bold>Try Again</Text>
								</ContainedButton>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default ProcessingStates;
