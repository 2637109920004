import React, { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { ListItem, Checkbox } from '@material-ui/core';

import { hide_loader, show_loader } from 'actions/app';
import Text from 'modules/Text';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import CreateRoster from './components/CreateRoster/CreateRoster';
import ConfirmClose from 'modules/modals/ConfirmClose';
import { useState } from 'react';
import AssignLeave from './components/AssignLeave/AssignLeave';
import useStyles from './styles';
import Toast from 'modules/Toast';
import { useHistory } from 'react-router-dom';
import RosterFilters from './components/RosterFilters';
import RiderOverviewModal from 'modules/modals/RiderOverviewModal';
import Button from 'modules/Button';
import EmptyState from 'modules/EmptyState';
import Helpcenter from 'modules/HelpCenter';
import MiniFilter from 'modules/MiniFilter';
import FilterButton from 'modules/FilterButton';
import NoResult from 'modules/NoResult';
import RosterBar from './components/RosterBar';
import utils from 'resources/utils';
import constant from 'resources/constant';

const Roster = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const interval_id = useRef(null);

	const query_object = qs.parse(history.location.search.slice(1)) || {};

	const [show_create_roster, set_show_create_roster] = useState(false);
	const [show_assign_leave, set_show_assign_leave] = useState(false);
	const [roster, set_roster] = useState([]);
	const [date, set_date] = useState(moment());
	const [refresh_roster, set_refresh_roster] = useState(false);
	const [selected_roster_ids, set_selected_roster_ids] = useState([]);
	const [confirm_close_modal, set_confirm_close_modal] = useState(false);
	const [current_time, set_current_time] = useState(moment());
	const [delete_leave, set_delete_leave] = useState(null);
	const [delete_roster, set_delete_roster] = useState(null);
	const [assign_single_leave, set_assign_single_leave] = useState(null);
	const [make_edit_single_roster, set_make_edit_single_roster] = useState(null);
	const [show_toast, set_show_toast] = useState(0);
	const [rider_info_details, set_rider_info_details] = useState(null);
	const [show_filters, set_show_filters] = useState();
	const [day_start_time, set_day_start_time] = useState(moment('12:00 AM', 'HH:mm A'));
	const [day_end_time, set_day_end_time] = useState(moment('11:00 AM', 'HH:mm A'));
	const time_am = ['12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM'];
	const time_pm = ['12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'];
	const [time_slots, set_time_slots] = useState(time_am);
	const [selected_filters, set_selected_filters] = React.useState({});
	const [uncompress_data, set_uncompress_data] = useState(null);
	const [open_row, set_open_row] = useState({
		id: null,
		open: null,
	});
	const leave_types = [
		{ title: 'Annual Leave', value: 'annual_leave' },
		{ title: 'Other Leave', value: 'casual_leave' },
		{ title: 'Week Off', value: 'earn_leave' },
		{ title: 'Sick Leave', value: 'casual_sick_leave' },
		{ title: 'Approve Absence', value: 'approve_absence' },
		{ title: 'Unapprove Approve Absence', value: 'unapprove_absence' },
	];
	const total_minutes = day_end_time.diff(day_start_time, 'minute') + 60;
	const days_diff = moment(date.format('DD/MM/YYYY'), 'DD/MM/YYYY').diff(moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY'), 'day');

	const classes = useStyles();

	const { is_loading } = useSelector((state) => state.app, shallowEqual);

	useEffect(() => {
		if (query_object?.rider_id) {
			setTimeout(() => {
				set_selected_filters({ selected_rider: { rider_id: query_object.rider_id, rider_name: query_object.rider_name } });
			}, 1000);
		}
		start_timer();
		return () => clearInterval(interval_id);
	}, []);

	useEffect(() => {
		set_roster([]);
		set_selected_roster_ids([]);
		get_roster();
	}, [refresh_roster]);

	useEffect(() => {
		get_roster();
	}, [selected_filters, date, rider_info_details]);

	const fun_conpress_data = (data) => {
		let _grouped_data = _.reduce(
			data,
			(acc, entry) => {
				acc[entry.id] = acc[entry.id] || [];
				acc[entry.id].push(entry);
				return acc;
			},
			{},
		);
		const compress_data = _.map(Object.keys(_grouped_data), (_val) => {
			return {
				..._grouped_data[_val][0],
				count: _grouped_data[_val].length,
				entries: _grouped_data[_val],
				open: false,
				show_name: true,
			};
		});

		return compress_data;
	};

	const get_roster = async () => {
		dispatch(show_loader());
		try {
			const res = await api_requests.get_bop_shift_by_date({
				start_date: moment.utc(date.startOf('day')).format(),
				end_date: moment.utc(date.endOf('day')).format(),
				...(selected_filters.selected_master_zone && { master_zone_id: selected_filters.selected_master_zone?.id }),
				...(selected_filters.selected_business_zone && { business_zone_id: selected_filters.selected_business_zone?.id }),
				...(selected_filters.selected_rider && { rider_id: selected_filters.selected_rider.rider_id }),
			});
			if (res.success) {
				const data = _.map(res.data.data, (item) => {
					return {
						entries: [item],
						...item,
					};
				});

				set_uncompress_data(data);
				const compress_data = fun_conpress_data(data);
				set_roster(_.reverse(compress_data));
				dispatch(hide_loader());
			}
		} catch (err) {
			dispatch(hide_loader());
		}
	};

	const handle_update_date = (type = 'next' /*next or prev*/) => {
		switch (type) {
			case 'next':
				set_date(date.clone().add('1', 'day'));
				break;

			case 'prev':
				set_date(date.clone().subtract('1', 'day'));
				break;

			default:
				break;
		}
	};

	const handle_time_scale = (type = 'am' /*am or pm*/) => {
		switch (type) {
			case 'am':
				set_time_slots(time_am);
				set_day_start_time(moment('12:00 AM', 'HH:mm A'));
				set_day_end_time(moment('11:00 AM', 'HH:mm A'));
				break;

			case 'pm':
				set_time_slots(time_pm);
				set_day_start_time(moment('12:00 PM', 'HH:mm A'));
				set_day_end_time(moment('11:00 PM', 'HH:mm A'));
				break;

			default:
				break;
		}
	};
	const handle_select_all_roster = () => {
		if (selected_roster_ids.length === roster.length) {
			set_selected_roster_ids([]);
		} else {
			set_selected_roster_ids(_.map(roster, 'shift_id'));
		}
	};

	const handle_select_roster = (rosterId) => {
		const index = _.indexOf(selected_roster_ids, rosterId);
		const _selected_roster_ids = [...selected_roster_ids];

		if (index === -1) {
			_selected_roster_ids.push(rosterId);
		} else {
			_selected_roster_ids.splice(index, 1);
		}

		set_selected_roster_ids(_selected_roster_ids);
	};

	const handle_delete = () => {
		api_requests
			.delete_roster({
				shift_id: selected_roster_ids,
			})
			.then((res) => {
				set_refresh_roster(!refresh_roster);
			})
			.catch((err) => {});
	};

	const handle_single_delete = async () => {
		try {
			const res = await api_requests.delete_roster({
				shift_id: [delete_roster.shift_id],
			});
			if (res.success) {
				set_refresh_roster((prev) => !prev);
			}
		} catch (err) {}
	};

	const procees_delete_leave = async () => {
		try {
			const res = await api_requests.delete_leave({ leave_id: delete_leave.leave_id });
			if (res.success) {
				set_refresh_roster(!refresh_roster);
			}
		} catch (err) {}
	};

	const start_timer = () => {
		interval_id.current = setInterval(() => {
			set_current_time(moment());
		}, 5000);
	};

	const get_rider_info_detail = async (rider_id) => {
		try {
			const res = await api_requests.get_rider_details(rider_id);
			if (res.success) {
				set_rider_info_details(res.data);
			}
		} catch (err) {}
	};

	const get_login_error = (days_diff, login_time, shift_start_time, is_roster_set) => {
		if (days_diff > 0) {
			return false;
		}
		if (!is_roster_set) {
			return false;
		}
		if (login_time) {
			if (moment(login_time).diff(moment(shift_start_time), 'minute') > 5) {
				return true;
			} else {
				return false;
			}
		}
		if (moment(shift_start_time).diff(moment(current_time), 'minute') > 0) {
			return false;
		}
		return true;
	};

	const expand_row_data = (compress_data, id) => {
		let result_data = [];
		_.map(compress_data, (item) => {
			if (item.id == id) {
				_.map(item.entries, (val, index) => {
					if (index == item.entries.length - 1) {
						_.assign(val, { count: item.entries.length });
						val.open = true;
						val.show_name = true;
					} else {
						_.assign(val, { count: 1 });
						val.show_name = false;
					}
					result_data.push(val);
				});
			} else {
				result_data.push(item);
			}
		});
		set_roster(_.reverse(result_data));
	};
	const expand_row = (id) => {
		if (open_row.id == id) {
			if (open_row.open) {
				const compress_data = fun_conpress_data(uncompress_data);
				set_roster(_.reverse(compress_data));
				set_open_row({ id: id, open: false });
			} else {
				const compress_data = fun_conpress_data(uncompress_data);
				expand_row_data(compress_data, id);
				set_open_row({ id: id, open: true });
			}
		} else {
			const compress_data = fun_conpress_data(uncompress_data);
			expand_row_data(compress_data, id);
			set_open_row({ id: id, open: true });
		}
	};

	const edit_roster = (r) => {
		clearInterval(interval_id.current);
		set_make_edit_single_roster(r);
	};

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<Text semi className={'global_header_text'}>
					Roster & Attendance
				</Text>

				{(roster.length !== 0 || Object.keys(selected_filters).length != 0) && (
					<div className='d-flex'>
						<Button
							text='Assign Leave'
							type='outlined'
							size='large'
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROSTER_ASSIGNLEAVE);
								set_show_assign_leave(true);
							}}
							style={{ marginRight: 12 }}
						/>
						<Button
							text='Create Roster'
							type='round'
							size='large'
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROSTER_CREATEROSTER);
								set_show_create_roster(true);
							}}
						/>
					</div>
				)}
			</div>
			{(roster.length !== 0 || Object.keys(selected_filters).length != 0) && (
				<div className={classes.filter_wrapper}>
					<div>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<ContainedButton circle onClick={() => handle_update_date('prev')} className={classes.date_arrow}>
								<img src={ImageLinks.chevronLeft} className='purple-arrow' width='10' />
								<img src={ImageLinks.chevron_left_white} style={{ display: 'none' }} className='white-arrow' width='10' />
							</ContainedButton>

							<Text medium className={classes.date_text}>
								{date.format('DD MMM `YY')}
							</Text>
							<ContainedButton circle onClick={() => handle_update_date('next')} className={classes.date_arrow}>
								<img src={ImageLinks.chevronLeft} className='purple-arrow' width='10' style={{ transform: 'rotate(180deg)' }} />
								<img
									src={ImageLinks.chevron_left_white}
									style={{ display: 'none', transform: 'rotate(180deg)' }}
									className='white-arrow'
									width='8'
								/>
							</ContainedButton>
							{days_diff !== 0 && (
								<Button type='text' text='Go to Today' onClick={() => set_date(moment())} size='extra_small' style={{ marginLeft: 8 }} />
							)}
						</div>
					</div>
					<div className={classes.filter_row}>
						{selected_roster_ids.length !== 0 && (
							<ListItem button className={classes.delete_item} onClick={() => set_confirm_close_modal(true)}>
								<img src={ImageLinks.delete} width='16' />
								<Text semi className={classes.delete_text}>
									Delete Shifts
								</Text>
							</ListItem>
						)}
						<FilterButton
							wrapper_styles={{ marginBottom: 0 }}
							handle_click={() => set_show_filters(true)}
							total_filters={Object.keys(selected_filters).length}
						/>

						{/* {show_filters && ( */}
						{/* <RosterFilters close_callback={() => set_show_filters(false)}> */}

						{/* </RosterFilters> */}
						{/* )} */}
					</div>
				</div>
			)}
			{(roster.length !== 0 || Object.keys(selected_filters).length != 0) && (
				<div className={classes.table_header}>
					<div style={{ flex: 1 }}></div>
					<Text bold style={{ flex: 5, fontSize: 14, color: theme.colors.darkGrey2 }}>
						Rider (ID)
					</Text>
					<Text bold style={{ flex: 4, fontSize: 14, color: theme.colors.darkGrey2 }}>
						Reporting Hub (ID)
					</Text>
					<Text bold style={{ flex: 3, fontSize: 14, color: theme.colors.darkGrey2 }}>
						Login Time
					</Text>
					<div style={{ flex: 16, fontSize: 14, color: theme.colors.darkGrey2, display: 'flex', position: 'relative', flexDirection: 'column' }}>
						<div style={{ display: 'flex', width: '100%' }}>
							<ContainedButton
								circle
								disabled={_.includes(time_slots, '12 AM')}
								onClick={() => handle_time_scale('am')}
								className={classes.date_arrow}>
								<img
									src={_.includes(time_slots, '12 AM') ? ImageLinks.chevron_up_gray : ImageLinks.chevron_right_purple}
									className='purple-arrow'
									width='14'
									height={'14'}
									style={{ transform: _.includes(time_slots, '12 AM') ? 'rotate(270deg)' : 'rotate(180deg)' }}
								/>
								<img src={ImageLinks.chevron_left_white} style={{ display: 'none' }} className='white-arrow' width='10' height={'10'} />
							</ContainedButton>
							{_.map(time_slots, (time_slot, index) => {
								return (
									<div style={{ flex: 1 }}>
										<Text className={classes.time_slot_header} semi key={`time_slot_${index}`}>
											{_.split(time_slot, ' ')[0]}
											<Text component={'span'} semi style={{ fontSize: 10 }}>
												{_.split(time_slot, ' ')[1]}
											</Text>
										</Text>
									</div>
								);
							})}
							<ContainedButton
								circle
								disabled={_.includes(time_slots, '12 PM')}
								onClick={() => handle_time_scale('pm')}
								className={classes.date_arrow}>
								<img
									src={_.includes(time_slots, '12 PM') ? ImageLinks.chevron_up_gray : ImageLinks.chevron_right_purple}
									className='purple-arrow'
									width='14'
									height={'14'}
									style={{ transform: _.includes(time_slots, '12 PM') ? 'rotate(90deg)' : 'rotate(360deg)' }}
								/>
								<img
									src={ImageLinks.chevron_left_white}
									style={{ display: 'none', transform: 'rotate(180deg)' }}
									className='white-arrow'
									width='10'
									height={'10'}
								/>
							</ContainedButton>
						</div>

						{((moment(day_start_time, 'H:mm').format('a') == 'am' && moment(current_time, 'h:mm').format('a') == 'am') ||
							(moment(day_start_time, 'H:mm').format('a') == 'pm' && moment(current_time, 'h:mm').format('a') == 'pm')) && (
							<div style={{ width: '95%', marginLeft: '28.5px', position: 'relative' }}>
								<div
									className={classes.time_line}
									style={{
										left: `${
											(moment(moment(current_time).format('HH:mm A'), 'HH:mm A').diff(
												moment(moment(day_start_time).format('HH:mm A'), 'HH:mm A'),
												'minute',
											) /
												total_minutes) *
											100
										}%`,
									}}>
									<div className={classes.purple_dot}></div>
									<div className={classes.time_tag}>
										<Text semi component={'span'} className={classes.time_tag_text}>
											{moment().format('hh:mm A')}
										</Text>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{roster.length !== 0 && (
				<div className={classes.row_wrapper}>
					{_.map(roster, (r, key) => {
						const is_roster_set = !_.isEmpty(_.get(r, 'time_slot_from_real', null));
						const is_on_leave = r.leave_type ? r.leave_type : false;
						const show_login_error = get_login_error(days_diff, _.get(r, 'login_data[0].login_time'), r.time_slot_from_real, is_roster_set);
						const is_logged_out =
							r.login_data?.length > 0 &&
							!_.includes(
								_.map(r.login_data, (data) => data.logout_time),
								null,
							);
						let shift_time_text = `${moment(r.entries[0].time_slot_from_real).format('h:mm A')} - ${moment(r.entries[0].time_slot_to_real).format(
							'h:mm A',
						)}`;
						let slider_text_class = '';
						let show_hover_text = true;
						if (r.entries.length > 1) {
							slider_text_class = classes.slideText;
							for (let i = 0; i < r.entries.length; i++) {
								if (
									(moment(day_start_time, 'H:mm').format('a') == 'am' &&
										moment(r.entries[i].time_slot_from_real).format('a') == 'am' &&
										moment(r.entries[i].time_slot_to_real).format('a') == 'am') ||
									(moment(day_start_time, 'H:mm').format('a') == 'pm' &&
										moment(r.entries[i].time_slot_from_real).format('a') == 'pm' &&
										moment(r.entries[i].time_slot_to_real).format('a') == 'pm')
								) {
									show_hover_text = false;
								} else {
									show_hover_text = true;
								}
								if (i > 0) {
									shift_time_text =
										shift_time_text +
										' | ' +
										`${moment(r.entries[i].time_slot_from_real).format('h:mm A')} - ${moment(r.entries[i].time_slot_to_real).format(
											'h:mm A',
										)}`;
								}
							}
						}
						return (
							<div key={`order${key}`} className={classes.row_item} style={{ overflow: 'hidden' }} id={`list_${r.id}`}>
								<div style={{ flex: 1 }}>
									{r.show_name && (
										<Checkbox
											disabled={_.get(r, 'login_data.0.login_time') || !r.shift_id}
											size='small'
											checked={_.includes(selected_roster_ids, r.shift_id)}
											onClick={() => handle_select_roster(r.shift_id)}
											style={{
												color: r.shift_id && !_.get(r, 'login_data.0.login_time') ? theme.colors.lightPurple2 : theme.colors.lightGrey5,
												padding: 0,
											}}
										/>
									)}
								</div>
								<div className='d-flex align-items-center' style={{ flex: 5, gap: 10 }}>
									{r.show_name && (
										<div className='d-flex align-items-center' onClick={() => get_rider_info_detail(r.id)}>
											<Text className={classes.rider_name}>{r.first_name}</Text>
											<Text className={classes.rider_id}>({r.id})</Text>
										</div>
									)}
									{r.count > 1 && (
										<div onClick={() => expand_row(r.id)} className={classes.roster_count}>
											<Text style={{ fontSize: 12 }}>{`0${r.count}`}</Text>
											<img src={ImageLinks.chevronLeft} style={{ transform: r.open ? 'rotate(90deg)' : 'rotate(270deg)' }} />
										</div>
									)}
								</div>

								<Text medium style={{ flex: 4, fontSize: 16 }}>
									{r.hub_id ? `${r.hub_title} (${r.hub_id})` : '-'}
								</Text>
								<div style={{ flex: 3, display: 'flex' }}>
									{(r.login_data || !show_login_error) && (
										<Text medium style={{ fontSize: 16, marginRight: '5px' }}>
											{r.login_data && days_diff <= 0
												? moment(r.login_data[0].login_time).format('hh:mm A')
												: days_diff > 0
												? '-'
												: show_login_error
												? ''
												: '-'}
										</Text>
									)}
									{show_login_error && <img src={ImageLinks.exclamation_error}></img>}
								</div>
								<div style={{ flex: '0.9' }}></div>
								<div className={classes.time_item_row} style={{ flex: 15, position: 'relative', display: 'flex', alignItems: 'center' }}>
									{is_on_leave ? (
										<div style={{ margin: 'auto', textTransform: 'capitalize' }}>
											{
												_.filter(leave_types, (value) => {
													return value.value === r.leave_type;
												})[0].title
											}
										</div>
									) : !is_roster_set ? (
										<div style={{ margin: 'auto', textTransform: 'capitalize' }}>
											<Text>Roster Not Set</Text>
										</div>
									) : (
										''
									)}
									<div className={classes.action_items}>
										{is_roster_set && (
											<div className={classes.shift_time_wrap}>
												<Text component={'span'} className={slider_text_class}>
													{shift_time_text}
												</Text>
											</div>
										)}

										{is_on_leave && days_diff >= 0 && (
											<div className={classes.delete_container} style={{ width: 'auto', padding: '6px 12px', gap: 5 }}>
												<img src={ImageLinks.delete} width='12' />
												<Text component={'span'} semi style={{ color: theme.colors.red }} onClick={() => set_delete_leave(r)}>
													Delete Leave
												</Text>
											</div>
										)}
										{is_roster_set && days_diff >= 0 && r.entries.length < 2 && !is_logged_out && (
											<img
												style={{ cursor: 'pointer' }}
												src={ImageLinks.edit_filled_light_purple}
												width='22'
												height={'22'}
												onClick={() => {
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROSTER_EDIT);
													edit_roster(r);
												}}
											/>
										)}
										{is_roster_set && days_diff >= 0 && r.entries.length < 2 && !_.get(r, 'login_data.0.login_time') && (
											<div
												className={classes.delete_container}
												onClick={() => {
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROSTER_DELETE);
													set_delete_roster(r);
												}}>
												<img src={ImageLinks.delete} width='16' />
											</div>
										)}
										{!is_roster_set && days_diff >= 0 && !is_on_leave && (
											<div
												onClick={() => {
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROSTER_SETROSTER);
													clearInterval(interval_id.current);
													set_make_edit_single_roster(r);
												}}
												className={classes.delete_container}
												style={{ width: 'auto', padding: '6px 12px', gap: 5, backgroundColor: theme.colors.lightPurple }}>
												<img src={ImageLinks.addPurple} width='12' />
												<Text component={'span'} semi style={{ color: theme.colors.primary }}>
													Set Roster
												</Text>
											</div>
										)}
										{!is_roster_set && days_diff >= 0 && !is_on_leave && (
											<div
												className={classes.delete_container}
												style={{ width: 'auto', padding: '6px 12px', gap: 5, backgroundColor: theme.colors.lightPurple }}>
												<Text
													component={'span'}
													semi
													style={{ color: theme.colors.primary }}
													onClick={() => {
														utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROSTER_ASSIGNLEAVE);
														set_assign_single_leave(r);
													}}>
													Assign Leave
												</Text>
											</div>
										)}
									</div>
									{!_.isEmpty(r.time_slot_to_real) && (
										<>
											{_.map(r.entries, (val, key) => {
												return (
													<RosterBar
														r={val}
														days_diff={days_diff}
														day_start_time={day_start_time}
														total_minutes={total_minutes}
														date={date}
														index={key}
														current_time={current_time}
														show_hover_text={show_hover_text}
													/>
												);
											})}
										</>
									)}
								</div>
							</div>
						);
					})}
				</div>
			)}

			{roster.length == 0 && !is_loading && Object.keys(selected_filters).length == 0 && (
				<div className={classes.wrapper_empty_state}>
					<EmptyState
						button_text='New Roster'
						left_icon={ImageLinks.addWhite}
						empty_img={ImageLinks.empty_state_roster}
						onClick={() => {
							utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROSTER_NEWROSTER);
							set_show_create_roster(true);
						}}
					/>
					<Helpcenter text='How do I manage riders?' url='https://pidge.in/helpcenter-rider-rosterattendance/' />
				</div>
			)}

			{roster.length == 0 && !is_loading && Object.keys(selected_filters).length != 0 && (
				<div className={classes.wrapper_empty_state_no_result}>
					<NoResult text='No Rider found' btn_onclick={() => set_selected_filters({})} />
				</div>
			)}

			{show_create_roster && (
				<CreateRoster
					close={(is_created) => {
						if (is_created) {
							set_refresh_roster(!refresh_roster);
						}
						set_show_create_roster(false);
					}}
					show_toast={set_show_toast}
				/>
			)}
			{make_edit_single_roster && (
				<CreateRoster
					is_rider_editable={false}
					rider_info={{
						mobile: _.get(make_edit_single_roster, 'mobile', null),
						rider_category: _.get(make_edit_single_roster, 'rider_category', null),
						rider_id: _.get(make_edit_single_roster, 'id'),
						rider_name: _.get(make_edit_single_roster, 'first_name'),
						shift_start_24_time: make_edit_single_roster.time_slot_from_real
							? moment(make_edit_single_roster.time_slot_from_real).format('HH:mm')
							: '08:00',
						shift_end_24_time: make_edit_single_roster.time_slot_to_real
							? moment(make_edit_single_roster.time_slot_to_real).format('HH:mm')
							: '20:00',
						selected_date: moment(make_edit_single_roster.time_slot_from_real ? make_edit_single_roster.time_slot_from_real : date).format(
							'YYYY-MM-DD',
						),
						selected_hub: make_edit_single_roster.hub_id,
						shift_id: _.get(make_edit_single_roster, 'shift_id'),
						overnigth: make_edit_single_roster.time_slot_from_real
							? moment(moment(make_edit_single_roster.time_slot_to_real).format('DD/MM/YYYY'), 'DD/MM/YYYY').diff(
									moment(moment(make_edit_single_roster.time_slot_from_real).format('DD/MM/YYYY'), 'DD/MM/YYYY'),
									'day',
							  ) > 0
							: false,
						master_zone_id: make_edit_single_roster.master_zone_id,
					}}
					close={(is_created) => {
						if (is_created) {
							set_refresh_roster(!refresh_roster);
						}
						start_timer();
						set_make_edit_single_roster(false);
					}}
					disabled_start_time={_.get(make_edit_single_roster, 'login_data.0.login_time', false)}
				/>
			)}
			{show_assign_leave && (
				<AssignLeave
					show_close_modal={() => {
						set_refresh_roster(!refresh_roster);
						set_show_assign_leave(false);
					}}
					edit_date={date}
				/>
			)}
			{confirm_close_modal && (
				<ConfirmClose
					headerTitle='Delete Roster'
					positive_btn_text='Delete'
					is_error_msg={true}
					title='Are you sure you want to delete roster'
					close={() => set_confirm_close_modal(false)}
					on_confirm={handle_delete}
				/>
			)}
			{delete_leave && (
				<ConfirmClose
					headerTitle='Delete Leave'
					title={
						<Text>
							Are you sure you want to delete{' '}
							<Text bold component={'span'}>
								{
									_.filter(leave_types, (value) => {
										return value.value === delete_leave.leave_type;
									})[0].title
								}
							</Text>{' '}
							for rider{' '}
							<Text bold component={'span'}>
								{delete_leave.first_name} ({delete_leave.id})
							</Text>{' '}
							on{' '}
							<Text bold component={'span'}>
								{moment(date).format('DD/MM/YYYY')}
							</Text>
							? This action cannot be undone.
						</Text>
					}
					is_error_msg={true}
					positive_btn_text='Delete'
					close={() => set_delete_leave(null)}
					on_confirm={procees_delete_leave}
				/>
			)}
			{assign_single_leave && (
				<AssignLeave
					is_rider_editable={false}
					rider_info={assign_single_leave}
					show_close_modal={() => {
						set_assign_single_leave(null);
						set_refresh_roster(!refresh_roster);
					}}
					edit_date={date}
				/>
			)}
			{delete_roster && (
				<ConfirmClose
					headerTitle='Delete Roster'
					positive_btn_text='Delete'
					is_error_msg={true}
					title='Are you sure you want to delete this roster? This action cannot be undone.'
					close={() => set_delete_roster(null)}
					on_confirm={handle_single_delete}
				/>
			)}
			{show_toast ? (
				<Toast>
					<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
					<Text component={'span'} semi style={{ marginLeft: '10px' }}>
						{`Roster set for ${show_toast} riders`}
					</Text>
				</Toast>
			) : (
				''
			)}
			{!_.isEmpty(rider_info_details) && (
				<RiderOverviewModal rider_data={rider_info_details} set_rider_data={set_rider_info_details} close={() => set_rider_info_details(null)} />
			)}
			{show_filters && (
				<div style={{ position: 'absolute', top: 127, right: 30 }}>
					<MiniFilter
						show_searh_bar={true}
						close={() => set_show_filters(false)}
						existing_filters={selected_filters}
						set_selected_filters={set_selected_filters}
					/>
				</div>
			)}
		</div>
	);
};

export default Roster;
