import React, { useEffect, useRef, useState, useMemo } from 'react';
import qs from 'qs';
import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import { Checkbox } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Text from 'modules/Text';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import Filters from 'modules/Filters/Filters';
import ContainedButton from 'modules/ContainedButton';
import Routes from 'resources/Routes';
import PremiumFeatureModal from 'modules/PremiumFeatureModal';
import { LOAD_ALL_STATES, POWERED_WITH_PIDGE_MENU_ITEMS } from '../../helper';
import useStyles from './styles';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Select from 'modules/Select';
import RouteCriteriaModal from './components/RouteCriterialModal';
import Toast from 'modules/Toast';
import utils from 'resources/utils';
import AutoAddModal from './components/AutoAddModal/AutoAddModal';
import { OPTIONS_MENU } from 'modules/BulkActions/helpers';
import BulksActions from 'modules/BulkActions/BulksActions';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import FilterButton from 'modules/FilterButton';
import TransferAllocationModal from 'modules/modals/TransferAllocationModal/TransferAllocationModal';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import { shallowEqual, useSelector } from 'react-redux';
import NetworkAvatar from 'modules/NetworkAvatar';
import AllocationModal from './components/AllocationModal/AllocationModal';
import Button from 'modules/Button';
import Helpcenter from 'modules/HelpCenter';
import NoResult from 'modules/NoResult';
import { hide_loader, show_loader } from 'actions/app';
import constant from 'resources/constant';

const initial_allocation_modal_details = {
	open: false,
	mcp_ids: [],
	network_id: null,
	is_multiple_network: false,
	pickup_coords: null,
	drop_coords: null,
};

const CREATE_ROUTE_OPTIONS = [
	{
		is_heading: true,
		disabled: true,
		title: 'Create New',
		value: '',
	},
	{
		option_left_icon: (
			<>
				<img className='black-arrow' src={ImageLinks.arrow_up_black} alt='manual_route_icon' width={16} height={16} style={{ marginRight: 10 }} />
				<img
					className='purple-arrow'
					src={ImageLinks.arrow_up}
					alt='manual_route_icon'
					width={16}
					height={16}
					style={{ marginRight: 10, display: 'none' }}
				/>
			</>
		),
		title: 'Single Route',
		value: 'manual_route',
	},
	{
		option_left_icon: (
			<>
				<img className='black-arrow' src={ImageLinks.multi_arrow_up_black} alt='auto_route_icon' width={16} height={16} style={{ marginRight: 10 }} />
				<img
					className='purple-arrow'
					src={ImageLinks.multi_arrow_up}
					alt='auto_route_icon'
					width={16}
					height={16}
					style={{ marginRight: 10, display: 'none' }}
				/>
			</>
		),
		title: 'Auto Route',
		value: 'auto_route',
	},
	{
		option_left_icon: (
			<>
				<img className='black-arrow' src={ImageLinks.up_down_black} alt='custom_route_icon' width={16} height={16} style={{ marginRight: 10 }} />
				<img
					className='purple-arrow'
					src={ImageLinks.up_down_purple}
					alt='custom_route_icon'
					width={16}
					height={16}
					style={{ marginRight: 10, display: 'none' }}
				/>
			</>
		),
		title: 'Custom Route',
		value: 'custom_route',
	},
	{
		is_heading: true,
		disabled: true,
		title: 'Add to Existing',
		value: '',
	},
	{
		option_left_icon: (
			<>
				<img className='black-arrow' src={ImageLinks.multi_arrow_up_black} alt='auto_route_icon' width={16} height={16} style={{ marginRight: 10 }} />
				<img
					className='purple-arrow'
					src={ImageLinks.multi_arrow_up}
					alt='auto_route_icon'
					width={16}
					height={16}
					style={{ marginRight: 10, display: 'none' }}
				/>
			</>
		),
		title: 'Auto',
		value: 'auto_route_new',
	},
	{
		option_left_icon: (
			<>
				<img className='black-arrow' src={ImageLinks.up_down_black} alt='custom_route_icon' width={16} height={16} style={{ marginRight: 10 }} />
				<img
					className='purple-arrow'
					src={ImageLinks.up_down_purple}
					alt='custom_route_icon'
					width={16}
					height={16}
					style={{ marginRight: 10, display: 'none' }}
				/>
			</>
		),
		title: 'Manually',
		value: 'manual_add_route',
	},
];

const ReadyToShip = ({ selected_master_zone }) => {
	const { app_configs, selected_business_id } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
			selected_business_id: state.app.selected_business_id,
		}),
		shallowEqual,
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const filter_object = qs.parse(history.location.search.slice(1)).filter || {};
	const [selected_pb_id, set_selected_pb_id] = React.useState('');
	const [show_order_details, set_show_order_details] = React.useState(false);
	const [orders, set_orders] = React.useState({});
	const [all_filters, set_all_filters] = React.useState([]);
	const [show_filters, set_show_filters] = React.useState(false);
	const [selected_order_ids, set_selected_order_ids] = React.useState([]);
	const [captive_riders, set_captive_riders] = React.useState(null);
	const [allocation_modal_details, set_allocation_modal_details] = React.useState(_.cloneDeep(initial_allocation_modal_details));
	const [show_multiple_pickup_error, set_show_multiple_pickup_error] = React.useState(false);
	const [order_list, set_order_list] = React.useState([]);
	const slider_ref = React.useRef();
	const loading_scroll = useRef(false);
	const [show_highlight, set_show_highlight] = useState(false);
	const [show_toast, set_show_toast] = useState(false);
	const [refresh, set_refresh] = React.useState(false);
	const [show_premium_feature, set_show_premium_feature] = React.useState(false);
	const [show_custom_group_modal, set_show_custom_group_modal] = React.useState(false);
	const [show_auto_add_to_route, set_show_auto_add_to_route] = useState(false);
	const [load_all_stage, set_load_all_stage] = useState(LOAD_ALL_STATES.INITIAL);
	const [show_auto_close_confirm, set_show_auto_close_confirm] = useState(false);
	const [count, set_count] = useState(0);
	const [show_transfer_modal, set_show_transfer_modal] = useState(false);
	const [show_transfer_success, set_show_transfer_success] = useState(false);
	const [transfer_success_msg, set_transfer_success_msg] = useState('');
	const classes = useStyles();

	const owner_id = utils._retrieveData('owner_id');
	useEffect(() => {
		set_all_filters([]);
		api_requests.get_ready_to_ship_filters(selected_master_zone).then((res) => set_all_filters(res.data.filters));
		api_requests.get_captive_riders().then((res) => set_captive_riders(res.data));
	}, [selected_master_zone]);

	const premium_features_blocked = _.isEmpty(captive_riders);

	const get_orders = ({ token }) => {
		dispatch(show_loader());
		api_requests.get_ready_to_ship_orders_search({ ...filter_object, ...(token && { token }) }).then((res) => {
			loading_scroll.current = false;
			set_orders(res.data);
			dispatch(hide_loader());
			if (token) {
				set_order_list(_.concat(order_list, res.data.data));
			} else {
				set_count(res.data.count);
				set_order_list(res.data.data);
			}
		});
	};

	useEffect(() => {
		set_selected_order_ids([]);
		set_order_list([]);
		get_orders({});
	}, [JSON.stringify(filter_object), refresh]);

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && orders.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_orders({ token: orders.next });
		}
	};

	const in_last_5_minutes = (date) => {
		let time_differece = new Date() - new Date(date);
		if (time_differece > 300000 || time_differece < 0) {
			return false;
		} else {
			return true;
		}
	};

	const handle_select_all_order = () => {
		if (selected_order_ids.length === order_list.length) {
			set_selected_order_ids([]);
		} else {
			set_selected_order_ids(_.map(order_list, 'mcp_id'));
		}
	};

	const handle_ship_now = (_order_ids = []) => {
		let _selected_order_ids = [];
		if (_order_ids.length > 0) _selected_order_ids = _.cloneDeep(_order_ids);
		else _selected_order_ids = _.cloneDeep(selected_order_ids);
		if (_.isEmpty(_selected_order_ids)) {
			return;
		}
		const filtered_list = _.filter(order_list, (order) => _.includes(_selected_order_ids, order.mcp_id));
		const network_ids_arr = _.map(filtered_list, (item) => item.fulfillment_network_id);

		if (
			_.filter(filtered_list, (order, key) => {
				return (
					!_.isNull(_.get(order, 'schedule_time', null)) ||
					!_.isNull(_.get(order, 'bundle_label', null)) ||
					(!_.isNull(_.get(order, 'supplier_id', null)) && _.get(order, 'supplier_id', null) != selected_business_id)
				);
			}).length > 0
		) {
			set_show_highlight(true);
			set_show_toast(true);
			return;
		} else {
			set_show_toast(false);
			set_show_highlight(false);
		}

		utils.track_event_for_analytics(constant.TRACKING_EVENTS.RTS_QUICKSHIP, { orders: _selected_order_ids });

		api_requests
			.get_serviceability({
				mcpIds: _selected_order_ids.join(','),
			})
			.then((res) => {
				const pickup_coords = _.get(res, 'data.data.0.pickup', null);
				const drop_coords = _.map(_.get(res, 'data.data', []), (item) => item.drop);
				const all_equal = network_ids_arr.every((val) => val === network_ids_arr[0]);
				set_allocation_modal_details({
					open: true,
					pickup_coords,
					drop_coords,
					mcp_ids: _selected_order_ids,
					is_multiple_network: !all_equal,
					network_id: all_equal && network_ids_arr[0] > 0 ? network_ids_arr[0] : null,
				});
			});
	};

	const handle_select_order = (order_id) => {
		const index = _.indexOf(selected_order_ids, order_id);
		const _selected_order_ids = [...selected_order_ids];

		if (index === -1) {
			_selected_order_ids.push(order_id);
		} else {
			_selected_order_ids.splice(index, 1);
		}

		set_selected_order_ids(_selected_order_ids);
	};

	const check_same_pick_up = (data_list = []) => {
		let flag = 0;
		_.forEach(data_list, (data) => {
			if (
				parseFloat(data.pickup_latitude).toFixed(4) != parseFloat(data_list[0].pickup_latitude).toFixed(4) ||
				parseFloat(data.pickup_longitude).toFixed(4) != parseFloat(data_list[0].pickup_longitude).toFixed(4)
			) {
				flag = 1;
			}
		});
		if (flag) return false;
		return true;
	};

	const handle_single_grouping = () => {
		if (premium_features_blocked) {
			set_show_premium_feature(true);
			return;
		}
		if (_.isEmpty(selected_order_ids)) {
			return;
		}
		if (
			_.filter(order_list, (order, key) => {
				return (
					_.includes(selected_order_ids, order.mcp_id) &&
					(!_.isNull(_.get(order, 'schedule_time', null)) ||
						!_.isNull(_.get(order, 'bundle_label', null)) ||
						(!_.isNull(_.get(order, 'supplier_id', null)) && _.get(order, 'supplier_id', null) != selected_business_id))
				);
			}).length > 0
		) {
			set_show_highlight(true);
			set_show_toast(true);
			return;
		} else {
			set_show_toast(false);
			set_show_highlight(false);
		}
		api_requests
			.create_manual_group({
				pbids: selected_order_ids,
			})
			.then((res) => {
				history.push(Routes.GROUPING_AND_ALLOCATION.path + `?type=unallocated&bundle_id=${res.data.bundles[0]}`);
			});
	};

	const handle_multi_grouping = (config = null) => {
		if (premium_features_blocked) {
			set_show_premium_feature(true);
			return;
		}
		if (_.isEmpty(selected_order_ids)) {
			return;
		}
		if (
			_.filter(order_list, (order, key) => {
				return (
					_.includes(selected_order_ids, order.mcp_id) &&
					(!_.isNull(_.get(order, 'schedule_time', null)) ||
						!_.isNull(_.get(order, 'bundle_label', null)) ||
						(!_.isNull(_.get(order, 'supplier_id', null)) && _.get(order, 'supplier_id', null) != selected_business_id))
				);
			}).length > 0
		) {
			set_show_highlight(true);
			set_show_toast(true);
			return;
		} else {
			set_show_toast(false);
			set_show_highlight(false);
		}
		api_requests
			.create_group_auto({
				pbids: selected_order_ids,
				numberOfRidersBig: 0,
				riderType: 1,
				...(config && { config }),
			})
			.then((res) => {
				history.push(Routes.GROUPING_AND_ALLOCATION.path + `?type=unallocated&bundle_id=${res.data.bundles[0]}`);
			});
	};

	const handle_custom_routing_click = () => {
		if (
			_.filter(order_list, (order, key) => {
				return (
					_.includes(selected_order_ids, order.mcp_id) &&
					(!_.isNull(_.get(order, 'schedule_time', null)) ||
						!_.isNull(_.get(order, 'bundle_label', null)) ||
						(!_.isNull(_.get(order, 'supplier_id', null)) && _.get(order, 'supplier_id', null) != selected_business_id))
				);
			}).length > 0
		) {
			set_show_highlight(true);
			set_show_toast(true);
			return;
		} else {
			set_show_toast(false);
			set_show_highlight(false);
		}
		set_show_custom_group_modal(true);
	};

	const handle_add_manual_routes = async () => {
		try {
			const res = await api_requests.get_order_details_from_ids({ mcp_ids: selected_order_ids.join(',') });
			if (res.success) {
				if (check_same_pick_up(res.data)) {
					history.push(`${Routes.GROUPING_AND_ALLOCATION.path}?type=add`, { selected_ids_list_data: res.data });
				} else {
					set_show_multiple_pickup_error(true);
				}
			}
		} catch (err) {}
	};

	const handle_auto_add_to_route = () => {
		if (
			_.filter(order_list, (order, key) => {
				return (
					_.includes(selected_order_ids, order.mcp_id) &&
					(!_.isNull(_.get(order, 'schedule_time', null)) ||
						!_.isNull(_.get(order, 'bundle_label', null)) ||
						(!_.isNull(_.get(order, 'supplier_id', null)) && _.get(order, 'supplier_id', null) != selected_business_id))
				);
			}).length > 0
		) {
			set_show_highlight(true);
			set_show_toast(true);
			return;
		}
		set_show_toast(false);
		set_show_highlight(false);
		set_show_auto_add_to_route(true);
	};

	const handle_close_multiple_pickup = () => set_show_multiple_pickup_error(false);

	const handle_export = async (filters, callback) => {
		try {
			const res = await api_requests.export_all_orders_pwp({ ...filters }, 'pending');
			utils.downloadBlob(res, 'orders.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	const handle_load_all = async () => {
		set_load_all_stage(LOAD_ALL_STATES.STARTED);
		let token = null;
		let _order_list = [];
		let next = true;
		while (next) {
			try {
				const res = await api_requests.get_ready_to_ship_orders_search({ ...filter_object, ...(token && { token }) });
				if (token) {
					_order_list = _.concat(_order_list, res.data.data);
				} else {
					_order_list = res.data.data;
				}
				if (res.data.next) {
					token = res.data.next;
				} else {
					next = false;
					set_load_all_stage(LOAD_ALL_STATES.COMPLETED);
					set_order_list(_order_list);
					set_orders(res.data);
				}
			} catch (err) {}
		}
	};

	const on_add_supplier_success = async (succes_msg) => {
		set_show_transfer_success(true);
		set_transfer_success_msg(succes_msg);
		set_refresh((prev) => !prev);
	};

	const on_remove_supplier_success = async (succes_msg) => {
		set_refresh((prev) => !prev);
	};

	const fulfillment_ids = useMemo(() => {
		let ids = [];
		for (let i = 0; i < selected_order_ids.length; i++) {
			let obj = {};
			const element = selected_order_ids[i];
			const item = _.find(order_list, ['mcp_id', element]);
			obj.mcp_id = item?.mcp_id;
			obj.network_id = item?.fulfillment_network_id;
			obj.supplier_id = item?.supplier_id;
			ids.push(obj);
		}
		const all_equal = ids.every((val) => val.network_id === ids[0].network_id);
		return all_equal ? ids : [];
	}, [order_list, selected_order_ids]);

	if (captive_riders === null) {
		return null;
	}

	return (
		<div className={classes.container}>
			<div className={classes.header_btn_wrapper}>
				<div style={{ display: 'flex', height: 35, alignItems: 'center' }}>
					{selected_order_ids.length > 0 && (
						<>
							<Text medium style={{ fontSize: 18, marginRight: 10 }}>
								Ship with:
							</Text>
							<BootstrapTooltip
								position='top'
								title={
									<Text bold style={{ fontSize: 12, color: theme.colors.lightPurple7 }}>
										{_.get(fulfillment_ids, '0.network_id', null) > 0
											? 'Transfer Orders'
											: 'Activates when single network orders are selected'}
									</Text>
								}>
								<div
									onClick={() => {
										if (_.get(fulfillment_ids, '0.network_id', null) > 0) set_show_transfer_modal(true);
									}}
									className={clsx(_.get(fulfillment_ids, '0.network_id', null) > 0 && classes.transfer_hover_wrap, classes.transfer_wrap)}
									style={{
										backgroundColor: _.get(fulfillment_ids, '0.network_id', null) > 0 ? theme.colors.lightPurple : theme.colors.lightGrey5,
										cursor: _.get(fulfillment_ids, '0.network_id', null) > 0 ? 'pointer' : ' auto',
									}}>
									<img
										className='left-right-purple'
										src={
											_.get(fulfillment_ids, '0.network_id', null) > 0
												? ImageLinks.left_right_arrow_purple
												: ImageLinks.left_right_arrow_gray
										}
										width={16}
										height={16}
									/>
									<img
										className='left-right-white'
										src={ImageLinks.left_right_arrow_white}
										style={{ display: 'none' }}
										width={16}
										height={16}
									/>
								</div>
							</BootstrapTooltip>
							<Button
								type='outlined'
								text='Quick Ship'
								size='large'
								disabled={_.isEmpty(selected_order_ids)}
								onClick={handle_ship_now}
								left_icon={_.isEmpty(selected_order_ids) ? ImageLinks.route_z_grey : ImageLinks.route_z_purple}
							/>
						</>
					)}
					{show_toast && (
						<Toast error={true} close_callback={() => set_show_toast(false)} wrapper_styles={{ width: 'auto', left: '-98%' }}>
							<img src={ImageLinks.exclamation_error} width={16} height={16} alt='' />
							<Text component={'span'} semi style={{ marginLeft: 10 }}>
								Please unselect routed orders to proceed
							</Text>
						</Toast>
					)}

					{captive_riders && captive_riders.length > 0 && (
						<div style={{ marginLeft: 10 }}>
							<Select
								menu_item_class={{ className: classes.menu_item_class }}
								value={null}
								defaultOption={{
									title: 'Create Route',
									value: null,
								}}
								options={CREATE_ROUTE_OPTIONS}
								handleChange={(val) => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.PP_RTS_CREATE_ROUTE + `_${val}`);
									switch (val) {
										case CREATE_ROUTE_OPTIONS[1].value:
											handle_single_grouping();
											break;

										case CREATE_ROUTE_OPTIONS[2].value:
											handle_multi_grouping();
											break;
										case CREATE_ROUTE_OPTIONS[3].value:
											handle_custom_routing_click(true);
											break;
										case CREATE_ROUTE_OPTIONS[5].value:
											handle_auto_add_to_route();
											break;
										case CREATE_ROUTE_OPTIONS[6].value:
											handle_add_manual_routes();
											break;
										default:
											break;
									}
								}}
								right_icon={selected_order_ids.length === 0 ? ImageLinks.chevron_up_gray : ImageLinks.chevron_up_white}
								disabled={selected_order_ids.length === 0}
								containerStyles={{
									background: selected_order_ids.length === 0 ? theme.colors.lightGrey5 : theme.colors.primary,
									borderColor: selected_order_ids.length === 0 ? theme.colors.lightGrey5 : theme.colors.primary,
									height: 36,
									borderRadius: 30,
									justifyContent: 'center',
									padding: '8px 12px 8px 16px',
									gap: 6,
								}}
								selected_title_styles={{
									color: selected_order_ids.length === 0 ? theme.colors.darkGrey2 : theme.colors.white,
									fontSize: 16,
								}}
								image_styles={{
									height: 16,
									width: 16,
								}}
								selected_title_props={{ medium: true }}
								text_props={{ medium: true, className: 'menu_item_text' }}
								menu_styles={{ marginTop: 55, width: 150 }}
								truncate_title={(title) => {
									return _.truncate(title, { length: 16 });
								}}
							/>
						</div>
					)}
				</div>
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<div className={classes.ongoingOrdersDiv} style={{ marginRight: 10 }}>
						<div className='d-flex align-items-center' style={{ gap: 5 }}>
							<Text semi component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
								Orders :{' '}
							</Text>
							<Text semi component={'span'} style={{ fontSize: 14 }}>
								{count}
							</Text>
							<BootstrapTooltip title='Load all Orders'>
								<div style={{ marginTop: 5 }}>
									{load_all_stage != LOAD_ALL_STATES.COMPLETED && (
										<img
											src={ImageLinks.refresh_purple}
											width={16}
											height={16}
											style={{
												cursor: 'pointer',
												...(load_all_stage === LOAD_ALL_STATES.STARTED && { animation: 'spin 4s linear infinite' }),
											}}
											onClick={() => handle_load_all()}
										/>
									)}
									{load_all_stage === LOAD_ALL_STATES.COMPLETED && <img src={ImageLinks.tickCirclePurpleSolid} width={16} height={16} />}
								</div>
							</BootstrapTooltip>
						</div>
					</div>
					<div className={classes.ongoingOrdersDiv} style={{ marginRight: 10 }}>
						{/* <ContainedButton className={classes.manualIcon} onClick={}>
								<img className='purple-arrow' src={ImageLinks.arrow_up} alt='' />
								<img className='white-arrow' style={{ display: 'none' }} height={20} src={ImageLinks.arrow_up_white} alt='' />
								<Text className={`tooltip ${classes.tooltip_text}`}>Manual</Text>
							</ContainedButton>
							<ContainedButton className={classes.autoIcon} onClick={}>
								<img className='purple-arrow' src={ImageLinks.multi_arrow_up} alt='' />
								<img className='white-arrow' style={{ display: 'none' }} width='18' src={ImageLinks.multi_arrow_up_white} alt='' />
								<Text className={`tooltip ${classes.tooltip_text}`}>Auto</Text>
							</ContainedButton> */}

						<FilterButton
							handle_click={() => set_show_filters(true)}
							total_filters={Object.keys(filter_object).length}
							wrapper_styles={{ marginBottom: 5 }}
							track_event={{ event: 'PP_RTS_filter', properties: { filter_object } }}
						/>
					</div>
				</div>
			</div>
			<div className={classes.detailsTextContainer}>
				<div style={{ width: 50 }}>
					{order_list && order_list.length !== 0 && (
						<Checkbox
							size='small'
							checked={selected_order_ids.length === order_list.length}
							onClick={handle_select_all_order}
							style={{ color: theme.colors.lightPurple2, padding: 0 }}
						/>
					)}
				</div>
				<Text bold style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
					Order ID
				</Text>

				<Text bold className={classes.header_title_2}>
					Customer Name
				</Text>
				<Text bold className={classes.header_title_2}>
					Pickup Address
				</Text>
				<Text bold className={classes.header_title_2}>
					Vol (in3) | Wt (g)
				</Text>
				<Text bold className={classes.header_title_2}>
					Allocation Details
				</Text>
				<Text bold className={classes.header_title_2}>
					Notes
				</Text>
				{selected_order_ids.length > 0 && (
					<div className={classes.bulk_action_wrapper}>
						<BulksActions
							external_order_id_list={_.map(
								_.filter(order_list, (order) => {
									return _.includes(selected_order_ids, order.mcp_id) && utils._retrieveData('owner_id') == order.business_id;
								}),
								(order) => {
									return order.external_order_id;
								},
							)}
							on_update={() => get_orders({})}
							option={[OPTIONS_MENU.unroute, OPTIONS_MENU.unallocate]}
							selected_order_ids={selected_order_ids}
							set_selected_order_ids={set_selected_order_ids}
						/>
					</div>
				)}
			</div>

			<div className={classes.pickedUpContainer} ref={slider_ref} onScroll={slider_scrolled}>
				{_.map(order_list, (order, key) => {
					const show_network_logo =
						order.supplier_id != null &&
						order.supplier_id != owner_id &&
						order.fulfillment_network_id != '-2' &&
						order.fulfillment_network_id != '-1';
					return (
						<div
							key={`order${key}`}
							className={classes.pickedUpItem}
							style={
								(order.schedule_time || order.bundle_label) && show_highlight && _.includes(selected_order_ids, order.mcp_id)
									? { backgroundColor: theme.colors.lightPurple }
									: {}
							}>
							<div style={{ width: 50 }}>
								<Checkbox
									size='small'
									checked={_.includes(selected_order_ids, order.mcp_id)}
									onClick={() => handle_select_order(order.mcp_id)}
									style={{ color: theme.colors.lightPurple2, padding: 0 }}
								/>
							</div>
							<div style={{ flex: 1, minWidth: 0, position: 'relative' }}>
								{order.business_id != selected_business_id && (
									<img src={ImageLinks.network_light_purple} width={20} height={20} style={{ position: 'absolute', left: -25, top: 1 }} />
								)}
								<Text
									component={'span'}
									semi
									className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
									onClick={() => {
										utils.track_event_for_analytics(constant.TRACKING_EVENTS.PP_RTS_ORDERID);
										set_selected_pb_id(order.external_order_id);
										set_show_order_details(true);
									}}>
									{order.mcp_id}
								</Text>
								{in_last_5_minutes(_.get(order, 'created_at')) && <div className={classes.purple_dot} />}
								<Text className={classes.subtitle}>{order.reference_id || order?.dd_channel?.order_id || order.id || '-'}</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{order.customer_name || '-'}
								</Text>
								<Text className={classes.subtitle}>{`${order.customer_mobile} | ${order.customer_pincode}`}</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{order.sender_address || '-'}
								</Text>
								<Text className={classes.subtitle}>{`${order.sender_name} | ${order.sender_pincode}`}</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium component={'span'} className={classes.truncate}>
									{order.volume}{' '}
									<Text medium component={'span'} style={{ fontSize: 12 }}>
										in<sup style={{ fontSize: 8 }}>3</sup>
									</Text>
								</Text>

								<Text medium component={'span'} className={classes.truncate}>
									&nbsp;| {order.weight}
									<Text medium component={'span'} style={{ fontSize: 12 }}>
										g
									</Text>
								</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								{show_network_logo ? (
									<div className='d-flex align-items-center' style={{ gap: 5 }}>
										<div style={{ minWidth: 24 }}>
											<NetworkAvatar
												configs={_.get(app_configs, `network_configs.${order.fulfillment_network_id}.avatar.configs`, [])}
												height={24}
												width={24}
												image={_.get(app_configs, `network_configs.${order.fulfillment_network_id}.avatar.image`, [])}
											/>
										</div>
										<div>
											<Text bold>
												{_.get(app_configs, `network_configs.${order.fulfillment_network_id}.name`, [])} ({order.fulfillment_network_id}
												)
											</Text>
										</div>
									</div>
								) : order.bundle_label ? (
									<Text
										semi
										onClick={() => history.push(Routes.GROUPING_AND_ALLOCATION.path + `?type=unallocated&bundle_id=${order.bundle_id}`)}
										className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
										style={{ color: theme.colors.primary, cursor: 'pointer' }}>
										{order.bundle_label}
									</Text>
								) : order.schedule_time ? (
									<div style={{ display: 'flex' }}>
										<img src={ImageLinks.clock_schedule_purple} alt='' />
										<Text semi style={{ color: theme.colors.primary, cursor: 'pointer', marginLeft: 5 }}>
											{moment(order.schedule_time).format('ddd, DD MMM, hh-')}{' '}
											{moment(order.schedule_time).add('60', 'minutes').format('hh a')}
										</Text>
									</div>
								) : (
									<Text>-</Text>
								)}
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{`${_.get(order, 'delivery_date', '-') || '-'}${
										_.isEmpty(_.get(order, 'delivery_slot', '')) ? '' : `;${order.delivery_slot}`
									}`}
								</Text>
							</div>
						</div>
					);
				})}
				{/* {order_list.length < 8 && (
					<div className={classes.wrapper_help_center}>
						<Helpcenter
							text='How can I power my orders with Pidge?'
							url='https://pidge.in/helpcenter-order-poweredwithpidge'
							style={{ marginTop: 40 }}
						/>
					</div>`
				)} */}
				{order_list.length == 0 && !_.isEmpty(filter_object) && (
					<div className='d-flex justify-content-center align-items-center' style={{ marginTop: '15%', flexDirection: 'column', gap: 10 }}>
						<NoResult
							text='No order found'
							btn_onclick={() => history.replace({ search: '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.ReadyToShip.id })}
						/>
					</div>
				)}
			</div>
			{show_order_details && (
				<OrderModal
					id={selected_pb_id}
					close={() => set_show_order_details(false)}
					updater={() => {
						set_refresh((val) => !val);
					}}
				/>
			)}
			{show_filters && (
				<Filters
					all_filters={all_filters}
					existing_filters={filter_object}
					set_selected_filters={(filters) => {
						history.push(
							Routes.POWERED_WITH_PIDGE.path + `?type=${POWERED_WITH_PIDGE_MENU_ITEMS.ReadyToShip.id}&` + qs.stringify({ filter: filters }),
						);
						set_selected_order_ids([]);
					}}
					close={() => set_show_filters(false)}
					title='Ready to Ship'
					handle_export={handle_export}
					source='pwp'
				/>
			)}
			{show_premium_feature && <PremiumFeatureModal close={() => set_show_premium_feature(false)} />}
			{allocation_modal_details.open && (
				<AllocationModal
					pickup_coords={allocation_modal_details.pickup_coords}
					drop_coords={allocation_modal_details.drop_coords}
					mcp_ids={allocation_modal_details.mcp_ids}
					is_multiple_network={allocation_modal_details.is_multiple_network}
					network_id={allocation_modal_details.network_id}
					on_success={() => {
						set_refresh((val) => !val);
					}}
					close={() => {
						set_allocation_modal_details(_.cloneDeep(initial_allocation_modal_details));
					}}
				/>
			)}
			{show_multiple_pickup_error && (
				<ConfirmClose
					headerTitle={'Multiple Pickups Not Allowed'}
					close={handle_close_multiple_pickup}
					on_confirm={handle_close_multiple_pickup}
					title={`Please selecte AWBs with the same Pickup Addresses to continue shipping orders.`}
					negative_btn_text={'Cancel'}
					positive_btn_text={'Ok'}
				/>
			)}
			{show_auto_add_to_route && (
				<AutoAddModal
					modal_close={() => set_show_auto_add_to_route(false)}
					selected_order_ids={selected_order_ids}
					show_auto_add_to_route={show_auto_add_to_route}
					set_show_auto_close_confirm={set_show_auto_close_confirm}
				/>
			)}
			{show_auto_close_confirm && (
				<ConfirmClose
					headerTitle={'Exit Routes Processing'}
					close={() => set_show_auto_close_confirm(false)}
					on_confirm={() => set_show_auto_add_to_route(false)}
					title={`Are you sure you want to close this window? Processing will continue in the background.`}
					negative_btn_text={'Cancel'}
					show_negative_btn={true}
					positive_btn_text={'Confirm'}
				/>
			)}
			{show_custom_group_modal && <RouteCriteriaModal close={() => set_show_custom_group_modal(false)} handle_custom_grouping={handle_multi_grouping} />}
			{show_transfer_modal && (
				<TransferAllocationModal
					close={() => set_show_transfer_modal(false)}
					network_id={_.get(fulfillment_ids, '0.network_id', null)}
					fulfillment_data={fulfillment_ids}
					on_add_success={on_add_supplier_success}
					on_remove_supplier_success={on_remove_supplier_success}
				/>
			)}

			{_.filter(order_list, (order, key) => {
				return in_last_5_minutes(_.get(order, 'created_at'));
			}).length > 0 && (
				<Toast>
					<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
					<Text component={'span'} semi style={{ marginLeft: '10px' }}>
						<Text component={'span'} semi style={{ color: theme.colors.primary }}>
							{
								_.filter(order_list, (order, key) => {
									return in_last_5_minutes(_.get(order, 'created_at'));
								}).length
							}{' '}
							orders
						</Text>{' '}
						moved successfully.{' '}
					</Text>
				</Toast>
			)}

			{show_transfer_success && (
				<Toast
					close_callback={() => {
						set_show_transfer_success(false);
						set_transfer_success_msg('');
					}}>
					<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
					<Text component={'span'} semi style={{ marginLeft: '10px' }}>
						{transfer_success_msg}
					</Text>
				</Toast>
			)}
		</div>
	);
};

export default ReadyToShip;
