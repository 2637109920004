import React, { useMemo, useState } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import _ from 'lodash';
import OutlinedTextField from 'modules/OutlinedTextField';
import moment from 'moment';
import TimeInput from 'modules/TimeInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import UploadRouteCSV from './UploadRouteCSV';
import ContainedButton from 'modules/ContainedButton';
import api_requests from 'resources/api_requests';

const useStyles = makeStyles((theme) => ({
	modal_wrap: { display: 'flex', outline: 'none' },
	container: {
		padding: 20,
		background: 'white',
		width: 580,
		height: 542,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_text: {
		color: theme.colors.darkGrey2,
		fontSize: 12,
		marginBottom: 6,
	},

	date_wrapper: {
		border: `1px solid ${theme.colors.lightPurple6}`,
		padding: '2px 8px',
		borderRadius: 36,
		display: 'flex',
		alignItems: 'center',
		gap: 3,
		marginRight: 10,
		height: 33,
		width: 120,
		marginBottom: 26,
	},
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: `1px solid ${theme.colors.darkGrey2}`,
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `1px solid ${theme.colors.darkGrey2}`,
		},
	},
}));

const RouteUploadModal = ({ assignment_data, handle_route_data, close }) => {
	const current_time = moment();
	const remainder = 15 - (current_time.minutes() % 15);
	const rounded_time = moment(current_time).add(remainder, 'minutes');
	const [pick_date, set_pick_date] = useState(new Date());
	const [pick_time, set_pick_time] = useState(rounded_time.format('HH:mm'));
	const [wait_time, set_wait_time] = useState(10);
	const [buffer, set_buffer] = useState(10);
	const [file_data, set_file_data] = useState([]);
	const [is_loading, set_is_loading] = useState(false);
	const [routing_failed, set_routing_failed] = useState(false);
	const classes = useStyles();

	const handle_confirm = async () => {
		set_is_loading(true);
		const points = _.map(file_data, (item) => ({
			ref_id: item.ref_id,
			...(item.latitude && item.longitude && { latitude: item.latitude, longitude: item.longitude }),
			...(!item.latitude && !item.longitude && { address: item.address, pincode: item.pincode }),
			sequence: item.sequence,
		}));

		const route_id = file_data[0].route_id;

		try {
			const response = await api_requests.get_assignment_route_stats(route_id, { points });
			if (response.success) {
				set_is_loading(false);
				const route_distance = _.get(response, 'data.distance', 0);
				const route_time = _.get(response, 'data.time', 0);

				if (route_distance == -1 || rounded_time == -1) {
					set_routing_failed(true);
					return;
				}
				set_routing_failed(false);
				const formatted_points_sequence = [];
				for (let i = 0; i < response.data.points.length; i++) {
					const element = response.data.points[i];
					const filtered_point_on_ref = _.filter(file_data, (item) => item.ref_id === element.ref_id)[0];
					const start_time = i === 0 ? `${moment(pick_date).format('YYYY-MM-DD')} ${pick_time}` : formatted_points_sequence[i - 1].eta;
					const total_time = i === 0 ? 0 : wait_time * 60 + response.data.points[i].time;
					const obj = {
						...filtered_point_on_ref,
						...element,
						eta: moment(start_time).add(total_time, 'seconds').toISOString(),
					};
					delete obj.distance;
					delete obj.time;
					delete obj.pincode;
					formatted_points_sequence.push(obj);
				}

				const payload = {
					wait_time,
					buffer,
					distance: route_distance?.toFixed(2),
					time: route_time?.toFixed(2),
					route_id: file_data[0].route_id,
					pickup_cnt: _.filter(file_data, (item) => item.point_type === 1).length,
					drop_cnt: _.filter(file_data, (item) => item.point_type === 2).length,
					points: formatted_points_sequence,
				};
				handle_route_data(payload);
				close();
			}
		} catch (error) {
			set_is_loading(false);
			set_routing_failed(true);
		}
	};

	const btn_disabled = useMemo(() => {
		let disabled = true;
		if (!is_loading && pick_date && !_.isEmpty(pick_time) && wait_time && buffer && file_data.length > 0) disabled = false;
		return disabled;
	}, [is_loading, pick_date, pick_time, wait_time, buffer, file_data]);

	return (
		<Modal open={true} onClose={close} className={classes.modal_wrap}>
			<div className={classes.container}>
				<div className={'d-flex justify-content-between'}>
					<div>
						<Text bold style={{ fontSize: 20 }}>
							Route Criteria
						</Text>
						<Text medium style={{ fontSize: 12 }}>
							Create a reference route for vehicle
						</Text>
					</div>

					<img src={ImageLinks.crossBlack} onClick={close} style={{ cursor: 'pointer' }} alt='close_modal' />
				</div>

				<div className='d-flex' style={{ gap: 20, marginTop: 26, opacity: is_loading ? 0.3 : 1, pointerEvents: is_loading ? 'none' : 'auto' }}>
					<div style={{ width: 185 }}>
						<div>
							<Text semi className={classes.header_text}>
								Start Date*
							</Text>
							<DatePicker
								dateFormat='yyyy-MM-dd'
								selected={pick_date}
								onChange={(date) => {
									set_pick_date(date);
								}}
								minDate={moment().toDate()}
								customInput={
									<div className={classes.date_wrapper}>
										<Text semi style={{ fontSize: 12, color: pick_date ? theme.colors.primary : theme.colors.lightPurple6, width: '80%' }}>
											{pick_date ? moment(pick_date).format('DD MMM `YY') : 'Select Date'}
										</Text>
										<img src={ImageLinks.calendar_solid_circle} alt='date' className='cursor-pointer' width={22} height={22} />
									</div>
								}
							/>
						</div>
						<div>
							<Text semi className={classes.header_text}>
								Start Time*
							</Text>
							<TimeInput value={pick_time} handleChange={(val) => set_pick_time(val)} />
						</div>
						<div style={{ marginTop: 26 }} className='align-items-center justify-content-between'>
							<div className='align-items-center'>
								<Text semi style={{ fontSize: 16, marginRight: 4 }}>
									Wait Time
								</Text>
								<BootstrapTooltip
									is_white={true}
									position='top'
									title={
										<div>
											<Text semi style={{ fontSize: 12, color: theme.colors.black }}>
												Wait time per stop
											</Text>
										</div>
									}>
									<img src={ImageLinks.info_circle} width={16} height={16} />
								</BootstrapTooltip>
							</div>
							<OutlinedTextField
								placeholder={0}
								className={classes.root}
								type={'number'}
								InputProps={{
									style: {
										height: 34,
										marginLeft: 12,
										fontSize: 14,
										padding: 0,
										paddingRight: 4,
										backgroundColor: theme.colors.white,
									},
									endAdornment: (
										<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, width: 50 }}>
											min
										</Text>
									),
								}}
								style={{ width: 90 }}
								value={wait_time}
								onChange={(e) => set_wait_time(e.target.value)}
							/>
						</div>

						<div style={{ marginTop: 26 }} className='align-items-center justify-content-between'>
							<div className='align-items-center'>
								<Text semi style={{ fontSize: 16, marginRight: 4 }}>
									Buffer
								</Text>
								<BootstrapTooltip
									is_white={true}
									position='top'
									title={
										<div>
											<Text semi style={{ fontSize: 12, color: theme.colors.black }}>
												% of route KM to be added
											</Text>
										</div>
									}>
									<img src={ImageLinks.info_circle} width={16} height={16} />
								</BootstrapTooltip>
							</div>
							<OutlinedTextField
								placeholder={0}
								className={classes.root}
								type={'number'}
								InputProps={{
									style: {
										height: 34,
										marginLeft: 12,
										fontSize: 14,
										padding: 0,
										paddingRight: 8,
										backgroundColor: theme.colors.white,
									},
									endAdornment: (
										<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
											%
										</Text>
									),
								}}
								style={{ width: 90 }}
								value={buffer}
								onChange={(e) => set_buffer(e.target.value)}
							/>
						</div>
					</div>
					<div style={{ width: 335 }}>
						<UploadRouteCSV
							assignment_data={assignment_data}
							pick_date={pick_date}
							pick_time={pick_time}
							file_data={file_data}
							routing_failed={routing_failed}
							set_file_data={set_file_data}
						/>
					</div>
				</div>

				<ContainedButton disabled={btn_disabled} onClick={handle_confirm} style={{ borderRadius: 30, width: 120, alignSelf: 'center', marginTop: 40 }}>
					{is_loading && <CircularProgress size={16} style={{ color: theme.colors.primary, marginRight: 6 }} />}
					<Text semi style={{ fontSize: 16 }}>
						Confirm
					</Text>
				</ContainedButton>
			</div>
		</Modal>
	);
};

export default RouteUploadModal;
