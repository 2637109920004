import Text from 'modules/Text';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { makeStyles, Modal, Grid } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import ConfirmClose from 'modules/modals/ConfirmClose';
import constant from 'resources/constant';
import ImageLinks from 'assets/images/ImageLinks';
import { useSelector } from 'react-redux';
import theme from 'resources/theme';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 20,
		background: theme.colors.white,
		width: 580,
		height: 600,
		margin: 'auto auto',
		borderRadius: 20,
		outline: 'none',
	},
	address_container: {
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 12,
		marginTop: 16,
	},
	map_canvas_wrap: { height: 140, borderRadius: 12 },
	address_wrap: { padding: 8 },
	addr_label: { color: theme.colors.darkGrey2, fontSize: 10, marginBottom: 4 },
	addr_text: { fontSize: 14, lineHeight: '22px' },
	input_row_wrap: { marginTop: 18 },
	suggestion_container: { marginTop: 16 },
	suggestion_user_container: {
		marginTop: 12,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 6,
		display: 'flex',
		padding: 12,
		cursor: 'pointer',
		width: 260,
		marginRight: 10,
	},
	cta_btn: {
		padding: '8px 32px',
		borderRadius: 30,
		margin: '0 auto',
		'&:disabled': {
			color: theme.colors.darkGrey2,
		},
	},
	address_and_button_container: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '94%' },
}));

let map = null;

const SuggestionCard = ({ name, mobile, is_selected, handle_suggestion_click }) => {
	const classes = useStyles();
	return (
		<div
			className={classes.suggestion_user_container}
			style={{ ...(is_selected && { border: `1px solid ${theme.colors.primary}` }) }}
			onClick={handle_suggestion_click}>
			<div style={{ marginRight: 8, marginTop: 2 }}>
				<img src={ImageLinks.user_black} width={16} height={16} alt='person' />
			</div>
			<div>
				<Text bold style={{ fontSize: 14, marginBottom: 4 }}>
					{name}
				</Text>
				<Text medium style={{ fontSize: 12, color: theme.colors.lightGrey9 }}>
					{mobile}
				</Text>
			</div>
		</div>
	);
};

const AddressContactDetailsModal = ({
	close,
	handle_pickup_details,
	pickup_address,
	clear_local_data,
	show_back_button = false,
	title,
	action_on_back_button,
	local_pickup_contact = null,
}) => {
	const { user_details } = useSelector(({ app }) => app);
	const [sender_name, set_sender_name] = React.useState(_.isEmpty(local_pickup_contact) ? '' : local_pickup_contact.sender_name);
	const [sender_contact, set_sender_contact] = React.useState(_.isEmpty(local_pickup_contact) ? '' : local_pickup_contact.sender_contact);
	const [is_suggestion_selected, set_is_suggestion_selected] = React.useState(false);
	const [confirm_close_modal, set_confirm_close_modal] = React.useState(false);
	const classes = useStyles();

	useEffect(() => {
		if (!_.isEmpty(local_pickup_contact)) {
			if (user_details.name === sender_name && user_details.mobile === sender_contact) set_is_suggestion_selected(true);
		}
		setTimeout(() => {
			initialize();
		}, 800);
	}, []);

	const initialize = () => {
		map = new window.google.maps.Map(document.getElementById('map_canvas'), {
			center: { lat: Number(pickup_address.coordinates.latitude), lng: Number(pickup_address.coordinates.longitude) },
			zoom: 15,
			clickableIcons: false,
			disableDefaultUI: true,
			scaleControl: true,
			zoomControl: true,
			mapId: constant.MAP_DESIGN_ID,
		});
		const icon_pickup = {
			url: ImageLinks.rider_pickup, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};
		new window.google.maps.Marker({
			position: { lat: Number(pickup_address.coordinates.latitude), lng: Number(pickup_address.coordinates.longitude) },
			icon: icon_pickup,
			map,
		});
	};

	const handleClose = () => {
		if (_.isEmpty(local_pickup_contact)) {
			set_confirm_close_modal(true);
			return;
		}
		if (btn_disabled) close();
		else set_confirm_close_modal(true);
	};

	const confirm_address = () => {
		handle_pickup_details(sender_name, sender_contact);
	};

	const handle_confirm_close = () => {
		close();
		clear_local_data();
	};

	const handle_suggestion_click = (item) => {
		const current_suggestion = is_suggestion_selected;
		set_sender_name(current_suggestion ? '' : !_.isEmpty(item) ? item.name : user_details.name);
		set_sender_contact(current_suggestion ? '' : !_.isEmpty(item) ? item.mobile : user_details.mobile);
		set_is_suggestion_selected((prev) => !prev);
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		if (_.isEmpty(local_pickup_contact) && !_.isEmpty(sender_name) && sender_contact.length == 10) {
			value = false;
		}

		if (
			!_.isEmpty(local_pickup_contact) &&
			!_.isEmpty(sender_name) &&
			sender_contact.length == 10 &&
			(sender_name != local_pickup_contact.sender_name || sender_contact != local_pickup_contact.sender_contact)
		) {
			value = false;
		}
		return value;
	}, [sender_name, sender_contact, local_pickup_contact]);

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.wrapper}>
				<div className='justify-content-between align-items-center'>
					<div className='align-items-center'>
						{show_back_button && (
							<img
								src={ImageLinks.left_arrow}
								alt='back'
								width={20}
								height={20}
								style={{ marginRight: 4 }}
								className='cursor-pointer'
								onClick={action_on_back_button}
							/>
						)}

						<Text bold style={{ fontSize: 20 }}>
							{title}
						</Text>
					</div>
					<img src={ImageLinks.crossBlack} width={20} height={20} onClick={handleClose} style={{ cursor: 'pointer' }} />
				</div>
				<div className={classes.address_and_button_container}>
					<div>
						<Grid container spacing={2} className={classes.address_container}>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<div className={classes.address_wrap}>
									<Text medium className={classes.addr_label}>
										Address
									</Text>
									<Text semi className={classes.addr_text}>
										{_.get(pickup_address, 'address_line1', '-')}
									</Text>
									<Text medium className={classes.addr_label} style={{ marginTop: 10 }}>
										Instructions to Reach
									</Text>
									<Text semi className={classes.addr_text}>
										{_.get(pickup_address, 'instructions_to_reach', '-')}
									</Text>
								</div>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<div className={classes.map_canvas_wrap}>
									<div id='map_canvas' className='map-canvas-wrapper rider-location card' style={{ height: '100%', borderRadius: 12 }} />
								</div>
							</Grid>
						</Grid>
						<Grid container className={classes.input_row_wrap} spacing={2}>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<UndlerlineTextField
									label='Name of Sender*'
									className='w-100'
									value={sender_name}
									onChange={(e) => set_sender_name(e.target.value)}
								/>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<UndlerlineTextField
									type='number'
									label='Contact of Sender*'
									value={sender_contact}
									className='w-100'
									onChange={(e) => set_sender_contact(e.target.value)}
								/>
							</Grid>
						</Grid>
						<div className={classes.suggestion_container}>
							<Text medium className={classes.addr_label}>
								Suggestions
							</Text>
							<div style={{ maxHeight: 160, overflowY: 'auto', display: 'flex', flexWrap: 'wrap' }}>
								{!_.isEmpty(pickup_address?.poc) &&
									Array.isArray(pickup_address?.poc) &&
									_.map(pickup_address.poc, (sugg_item, sugg_index) => {
										const is_sugg_selected = sender_name === sugg_item.name && sender_contact === sugg_item.mobile;
										return (
											<SuggestionCard
												key={`suggestion_item_${sugg_index}`}
												is_selected={is_sugg_selected}
												name={_.get(sugg_item, 'name', '')}
												mobile={_.get(sugg_item, 'mobile', '')}
												handle_suggestion_click={() => handle_suggestion_click(sugg_item)}
											/>
										);
									})}
								<SuggestionCard
									is_selected={is_suggestion_selected}
									name={_.get(user_details, 'name', '')}
									mobile={_.get(user_details, 'mobile', '')}
									handle_suggestion_click={() => handle_suggestion_click()}
								/>
							</div>
						</div>
					</div>
					<div className='d-flex justify-content-center align-items-center text-align-center'>
						<Button
							style={{ marginTop: 20, width: 110 }}
							type='round'
							text={_.isEmpty(local_pickup_contact) ? 'Next' : 'Update'}
							size='large'
							onClick={confirm_address}
							disabled={btn_disabled}
						/>
					</div>
				</div>
				{confirm_close_modal && (
					<ConfirmClose
						headerTitle='Discard Changes'
						title='You have made changes but you have not saved them. Are you sure you want to discard these changes?'
						close={() => set_confirm_close_modal(false)}
						on_confirm={handle_confirm_close}
					/>
				)}
			</div>
		</Modal>
	);
};

export default AddressContactDetailsModal;
