import { Modal, makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import Button from 'modules/Button';
import ContainedButton from 'modules/ContainedButton';
import OutlinedTextField from 'modules/OutlinedTextField';
import Text from 'modules/Text';
import TimeInput from 'modules/TimeInput';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import api_requests from 'resources/api_requests';

const useStyles = makeStyles((theme) => ({
	modal_wrap: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
	modal_sub_wrap: { backgroundColor: theme.colors.white, padding: 20, borderRadius: 20, width: 600 },
	date_pick_input: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
		border: `1px solid ${theme.colors.primary}`,
		padding: 10,
		borderRadius: 36,
		cursor: 'pointer',
		height: 36,
		width: 148,
		marginRight: 0,
	},
	red_dot_small: {
		width: '8px',
		height: '8px',
		backgroundColor: theme.colors.red,
		display: 'block',
		borderRadius: '50%',
		marginLeft: 5,
	},
	input_item: { display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 },
	info_text_wrap: {
		backgroundColor: theme.colors.lightPink4,
		padding: 20,
		marginTop: 30,
	},
}));

function DeliverySimulator({ close, set_simulator_configs, simulator_configs }) {
	const classes = useStyles();
	const theme = useTheme();
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [date, set_date] = useState(new Date());
	const [time, set_time] = useState(moment().format('12:00'));
	const [wait_time, set_wait_time] = useState('0');

	useEffect(() => {
		if (!_.isEmpty(simulator_configs)) {
			set_date(moment(simulator_configs.start_time, 'YYYY-MM-DD HH:mm:ss').toDate());
			set_time(moment(simulator_configs.start_time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'));
			set_wait_time(simulator_configs.wait_time);
		}
	}, []);

	return (
		<Modal open={true} className={classes.modal_wrap}>
			<div className={classes.modal_sub_wrap}>
				<div className='d-flex justify-content-between align-items-center'>
					<Text bold style={{ fontSize: 20 }}>
						Delivery Simulator
					</Text>
					<img src={ImageLinks.crossBlack} onClick={close} style={{ cursor: 'pointer' }}></img>
				</div>
				<Text medium style={{ fontSize: 12 }}>
					Please select dispatch time to identify which orders cannot be delivered on time
				</Text>
				<div className='d-flex'>
					<div style={{ flex: 2 }}>
						<div className={classes.input_item}>
							<div style={{ width: '40%' }}>
								<Text semi style={{ fontSize: 16, width: 110 }}>
									Dispatch Date*
								</Text>
							</div>
							<div style={{ width: '60%' }}>
								<DatePicker
									dateFormat='yyyy-MM-dd'
									selected={date}
									onChange={(date) => set_date(date)}
									customInput={
										<div className={classes.date_pick_input}>
											<Text style={{ color: theme.colors.primary }}>{moment(date).format('DD-MM-YYYY')}</Text>
											<img
												src={ImageLinks.calendar_solid_circle}
												alt='date'
												onClick={() => set_date_picker_open(!date_picker_open)}></img>
										</div>
									}
									minDate={moment().toDate()}
								/>
							</div>
						</div>
						<div className={classes.input_item}>
							<Text semi style={{ fontSize: 16, width: '40%' }}>
								Dispatch Time*
							</Text>
							<TimeInput value={time} handleChange={(val) => set_time(val)} />
						</div>
						<div className={classes.input_item}>
							<div className='d-flex align-items-center' style={{ width: '40%' }}>
								<Text semi component={'span'} style={{ fontSize: 16 }}>
									Wait Time*&nbsp;&nbsp;
								</Text>
								<BootstrapTooltip
									title={
										<div className='d-flex' style={{ padding: '20px 10px', gap: 12 }}>
											<img src={ImageLinks.info_light_purple} height={32} width={32} />
											<div>
												<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
													Wait Time Consideration
												</Text>
												<Text medium style={{ fontSize: 10, color: theme.colors.primary }}>
													Duration to be considered for calculating delivery time
												</Text>
											</div>
										</div>
									}>
									<img src={ImageLinks.info_circle} height={16} width={16} />
								</BootstrapTooltip>
							</div>
							<OutlinedTextField
								value={wait_time}
								textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
								onChange={(e) => set_wait_time(e.target.value)}
								placeholder='0'
								InputProps={{
									style: { height: 32 },
									endAdornment: (
										<Text medium style={{ width: 50 }}>
											Min
										</Text>
									),
								}}
								style={{ width: 100 }}
							/>
						</div>
					</div>
					<div style={{ flex: 1 }}>
						<div className={classes.info_text_wrap}>
							<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
								On an average, wait time for deliveries is 3.5 minutes.
							</Text>
						</div>
					</div>
				</div>
				<div className='d-flex align-items-center justify-content-center' style={{ marginTop: 20 }}>
					<Text medium style={{ fontSize: 14, textAlign: 'center' }}>
						<Text semi component={'span'} style={{ color: theme.colors.darkGrey2 }}>
							Please note
						</Text>
						: Routes and orders that breach the parameter are marked with
					</Text>
					<div className={classes.red_dot_small}></div>
				</div>
				<div className='d-flex justify-content-center' style={{ marginTop: 40 }}>
					<Button
						size='large'
						text='Simulate'
						type='round'
						disabled={wait_time == null || wait_time == ''}
						onClick={() =>
							set_simulator_configs({
								start_time: `${moment(date).format('YYYY-MM-DD')} ${moment(time, 'HH:mm').format('HH:mm:ss')}`,
								wait_time: wait_time,
							})
						}
					/>
				</div>
			</div>
		</Modal>
	);
}

export default DeliverySimulator;
