import ImageLinks from 'assets/images/ImageLinks';

export const priorities = [
	{ title: 'High', sub_title: 'Choose highest trust and performance partners', img: ImageLinks.arrow_up_dark_pink },
	{ title: 'Medium', sub_title: 'These are your next-best options', img: ImageLinks.hamburger_orange },
	{ title: 'Low', sub_title: 'These help ensure that at least the delivery gets done', img: ImageLinks.arrow_down_blue },
	{ title: 'Exclude', sub_title: 'Where you would rather not deliver...', img: ImageLinks.cross_grey },
];

export const setting_option = [
	{ title: 'Low', img: ImageLinks.arrow_down_blue },
	{ title: 'Medium', img: ImageLinks.hamburger_orange },
	{ title: 'High', img: ImageLinks.arrow_up_dark_pink },
	{ title: 'Exclude', img: ImageLinks.red_cross_filled_circle },
];
