import React, { useCallback, useEffect, useState } from 'react';
import qs from 'qs';
import _ from 'lodash';
import { ListItem } from '@material-ui/core';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import api_requests from 'resources/api_requests';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import AddressInput from 'modules/modals/AddressInput';
import AddressItem from './components/AddressItem';
import CreateWarehouse from 'modules/modals/CreateWarehouse';
import { makeStyles } from '@material-ui/styles';
import constant from 'resources/constant';
import Routes from 'resources/Routes';
import clsx from 'clsx';
import AddPocModal from './components/AddPocModal';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Button from 'modules/Button';
import EmptyState from 'modules/EmptyState';
import Helpcenter from 'modules/HelpCenter';
import { hide_loader, show_loader } from 'actions/app';
import MiniFilter from 'modules/MiniFilter';
import FilterButton from 'modules/FilterButton';
import NoResult from 'modules/NoResult';
import utils from 'resources/utils';

const useStyles = makeStyles({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', overflow: 'hidden' },
	header: { display: 'flex', justifyContent: 'space-between' },
	settings_text: {
		fontSize: 22,
		color: 'black',
	},
	screen_text_wrap: { width: 2, height: 30, background: '#B0B0B0', marginLeft: 20 },
	screen_text: {
		fontSize: 22,
		marginLeft: 20,
		borderBottom: '4px solid #E4DCF3',
		color: 'black',
	},
	new_pickup_text: { marginLeft: 10, fontSize: 16 },
	filter_wrapper: {
		display: 'flex',
		marginTop: 32,
		borderBottom: `1px solid ${theme.colors.lightGrey}`,
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		paddingBottom: 8,
	},
	filter_list_item: {
		height: 33,
		padding: '8px 12px',
		borderRadius: 8,
		minWidth: 120,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	content: { display: 'flex', flex: 1, overflow: 'hidden', marginBottom: 30 },
	address_list_wrapper: { flex: 1, width: '90%', overflowY: 'scroll' },
	type_heading: { fontSize: 20, marginTop: 30 },
	map_wrapper: { height: '100%', width: '100%', padding: 30 },
	map_style: { height: '100%', width: '100%', borderRadius: 20 },
	action_wrap: {
		height: 40,
		padding: 12,
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
		backgroundColor: `rgba(242, 238, 248, 100)`,
		position: 'absolute',
		top: 30,
		left: 30,
		zIndex: 999,
		width: '92%',
	},
	empty_content: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
});

export const PICKUP_ADDRESS_FILTERS = {
	pickup: 'pickup',
	warehouse: 'warehouse',
};

const inital_warehouse_details = {
	open: false,
	address_id: null,
	lat: null,
	lng: null,
	address_line: null,
	instructions: null,
};

const inital_add_poc_modal_details = {
	open: false,
	address: null,
};

let map = null;
let marker;

const ManagePickupAddress = ({ history }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const query_object = qs.parse(history.location.search.slice(1));
	const from_sidebar = history.location.state?.from_sidebar;

	const [selected_address_id, set_selected_address_id] = useState(null);
	const [show_confirm_modal, set_show_confirm_modal] = useState(false);
	const [show_add_poc_modal, set_show_add_poc_modal] = useState({ ...inital_add_poc_modal_details });
	const [show_create_address, set_show_create_address] = React.useState(false);
	const [create_warehouse_details, set_create_warehouse_details] = React.useState({ ...inital_warehouse_details });

	const [pickup_addresses, set_pickup_addresses] = React.useState([]);
	const [warehouses, set_warehouses] = React.useState([]);

	const [refresh_address, set_refresh_address] = React.useState(false);

	const [selected_filter, set_selected_filter] = React.useState(query_object.filter || PICKUP_ADDRESS_FILTERS.pickup);
	const [show_filters, set_show_filters] = React.useState(false);

	const { is_loading } = useSelector((state) => state.app, shallowEqual);

	const [filter_object, set_filter_object] = React.useState({
		selected_master_zone: { name: 'No Zone', id: 0 },
	});

	const get_data = async () => {
		dispatch(show_loader());
		api_requests
			.get_saved_address({
				recent_count: 0,
				saved_count: 20,
				...(filter_object.selected_master_zone && { master_zone_id: filter_object.selected_master_zone?.id }),
				...(filter_object.selected_business_zone && { business_zone_id: filter_object.selected_business_zone?.id }),
			})
			.then((res) => {
				set_pickup_addresses(res.data.saved);
				dispatch(hide_loader());
			})
			.catch(() => dispatch(hide_loader()));

		api_requests
			.get_warehouses({
				...(filter_object.selected_master_zone && { master_zone_id: filter_object.selected_master_zone?.id }),
				...(filter_object.selected_business_zone && { business_zone_id: filter_object.selected_business_zone?.id }),
			})
			.then((res) => {
				set_warehouses(res.data.data);
				dispatch(hide_loader());
			})
			.catch(() => dispatch(hide_loader()));
	};

	useEffect(() => {
		if (!query_object.filter) {
			return;
		}
		set_selected_filter(query_object.filter);
	}, [query_object.filter]);

	useEffect(() => {
		get_data();
	}, [refresh_address, filter_object]);

	useEffect(() => {
		setTimeout(() => {
			const zone = filter_object.selected_master_zone;
			if (document.getElementById('map_canvas_address_pickup_address')) {
				map = new window.google.maps.Map(document.getElementById('map_canvas_address_pickup_address'), {
					center:
						filter_object.selected_master_zone.id == 0
							? constant.INDIA_MAP_CENTER
							: { lat: zone.CenterPoint.latitude, lng: zone.CenterPoint.longitude },
					zoom: 8,
					clickableIcons: false,
					disableDefaultUI: true,
					scaleControl: true,
					zoomControl: true,
					mapId: constant.MAP_DESIGN_ID,
				});
			}
		}, 800);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter_object]);

	const handle_select_marker = (lat, lng, id) => {
		set_selected_address_id(id);
		if (marker) {
			marker.setMap(null);
		}
		map.setOptions({
			center: { lat: Number(lat), lng: Number(lng) },
			zoom: 15,
		});

		const icon = {
			url: ImageLinks.rider_pickup, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};

		marker = new window.google.maps.Marker({
			zoom: 9,
			position: { lat: Number(lat), lng: Number(lng) },
			map,
			icon,
		});
	};

	const on_unsave_address = async () => {
		try {
			const response = await api_requests.unsave_address(selected_address_id);
			if (response.success) set_refresh_address(!refresh_address);
		} catch (error) {}
	};

	const on_tab_select = (type) => {
		set_selected_address_id(null);
		const zone = filter_object.selected_master_zone;
		if (marker) marker.setMap(null);
		map.setOptions({
			center:
				filter_object.selected_master_zone.id == 0 ? constant.INDIA_MAP_CENTER : { lat: zone.CenterPoint.latitude, lng: zone.CenterPoint.longitude },
			zoom: 8,
		});
		history.push(Routes.MANGE_PICKUP_ADDRESS.path + `?filter=${type}`);
	};

	const handle_add_sender = async (data) => {
		try {
			const response = await api_requests.update_address(selected_address_id, { poc: data });
			set_show_add_poc_modal(false);
			set_refresh_address(!refresh_address);
		} catch (error) {}
	};

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<div className='align-items-center'>
					{!from_sidebar && (
						<div className='page_back_btn' onClick={() => history.goBack()}>
							<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
						</div>
					)}
					<Text semi className={'global_header_text'}>
						Saved Addresses
					</Text>
				</div>
				{(pickup_addresses.length > 0 || warehouses.length > 0 || filter_object.selected_master_zone.id != 0) && (
					<div>
						<Button
							type='round'
							size='large'
							text={selected_filter === PICKUP_ADDRESS_FILTERS.pickup ? 'New Pickup' : 'New Warehouse'}
							left_icon={ImageLinks.addWhite}
							onClick={() => {
								utils.track_event_for_analytics(
									selected_filter === PICKUP_ADDRESS_FILTERS.pickup
										? constant.TRACKING_EVENTS.SAVED_NEW_PICKUP
										: constant.TRACKING_EVENTS.SAVED_NEW_WAREHOUSE,
								);
								set_show_create_address(true);
							}}
						/>
					</div>
				)}
			</div>
			{(pickup_addresses.length > 0 || warehouses.length > 0 || filter_object.selected_master_zone.id != 0) && (
				<div className={classes.filter_wrapper}>
					<div className='d-flex'>
						{_.map(
							[
								{
									text: 'Pickup',
									details: pickup_addresses.length,
									type: PICKUP_ADDRESS_FILTERS.pickup,
								},
								{
									text: 'Warehouse',
									details: warehouses.length,
									type: PICKUP_ADDRESS_FILTERS.warehouse,
								},
							],
							(filter, key) => {
								const active = selected_filter === filter.type;
								return (
									<ListItem
										key={`status${key}`}
										button
										onClick={() => on_tab_select(filter.type)}
										className={clsx(classes.filter_list_item)}
										style={{
											backgroundColor: active ? theme.palette.primary.main : theme.colors.white,
										}}>
										<Text bold style={{ color: active ? theme.colors.white : theme.colors.lightPurple6, fontSize: 14 }}>
											{filter.text} ({filter.details})
										</Text>
									</ListItem>
								);
							},
						)}
					</div>
					<div className={'d-flex justify-content-between'}>
						<FilterButton
							wrapper_styles={{ marginBottom: 0 }}
							handle_click={() => set_show_filters(true)}
							total_filters={Object.keys(filter_object).length}
							track_event={{ event: constant.TRACKING_EVENTS.SAVED_ADDRESS_FILTER, properties: filter_object }}
						/>
					</div>
				</div>
			)}
			{(pickup_addresses.length > 0 || warehouses.length > 0 || filter_object.selected_master_zone.id != 0) && (
				<div
					className={classes.content}
					style={{
						display:
							selected_filter === PICKUP_ADDRESS_FILTERS.pickup && pickup_addresses.length > 0
								? ''
								: selected_filter === PICKUP_ADDRESS_FILTERS.warehouse && warehouses.length > 0
								? ''
								: 'none',
					}}>
					<div className={classes.address_list_wrapper}>
						{selected_filter === PICKUP_ADDRESS_FILTERS.pickup && (
							<div>
								{_.map(pickup_addresses, (address, key) => {
									return (
										<AddressItem
											key={`pickup${key}`}
											id={address.id}
											selected_address_id={selected_address_id}
											title={`${address.address_line1} ${address.state} ${address.pincode}`}
											subTitle={address.google_maps_address}
											zone={
												(_.find(filter_object?.selected_master_zone?.business_master_zones, { id: address.business_zone_id }) || {})
													.title || '-'
											}
											is_warehouse={false}
											poc={_.get(address, 'poc', [])}
											label={address.label}
											on_select_marker={() =>
												handle_select_marker(address.exact_location.latitude, address.exact_location.longitude, address.id)
											}
											on_add_sender={() => {
												utils.track_event_for_analytics(constant.TRACKING_EVENTS.SAVED_ADDRESS_ADD_SENDER);
												set_show_add_poc_modal({
													open: true,
													address,
												});
											}}
										/>
									);
								})}
							</div>
						)}
						{selected_filter !== PICKUP_ADDRESS_FILTERS.pickup && (
							<div>
								{_.map(warehouses, (address, key) => {
									return (
										<AddressItem
											key={`warehouse${key}`}
											id={address.id}
											title={address.name}
											subTitle={address.google_maps_address}
											zone={
												(_.find(filter_object?.selected_master_zone?.business_master_zones, { id: address.business_zone_id }) || {})
													.title || '-'
											}
											name={address.receiver_details.name}
											mobile={address.receiver_details.mobile}
											is_warehouse={true}
											label={''}
											poc={[]}
											selected_address_id={selected_address_id}
											on_select_marker={() => handle_select_marker(address.location.latitude, address.location.longitude, address.id)}
											on_add_sender={() => {}}
										/>
									);
								})}
							</div>
						)}
					</div>
					{(pickup_addresses.length > 0 || warehouses.length > 0) && (
						<div style={{ flex: 1, position: 'relative' }}>
							{selected_address_id && selected_filter === PICKUP_ADDRESS_FILTERS.pickup && (
								<div className={clsx(classes.action_wrap, 'align-items-center justify-content-between')}>
									<Text medium style={{ fontSize: 14 }}>
										Quick Action
									</Text>
									<img
										onClick={() => {
											set_show_confirm_modal(true);
										}}
										src={ImageLinks.delete}
										width='16'
										height='16'
										style={{ cursor: 'pointer' }}
									/>
								</div>
							)}
							<div
								className={classes.map_wrapper}
								style={{
									visibility:
										selected_filter === PICKUP_ADDRESS_FILTERS.pickup && pickup_addresses.length > 0 && !is_loading
											? ''
											: selected_filter === PICKUP_ADDRESS_FILTERS.warehouse && warehouses.length > 0 && !is_loading
											? ''
											: 'hidden',
								}}>
								<div id='map_canvas_address_pickup_address' className={`map-canvas-wrapper rider-location card ${classes.map_style}`} />
							</div>
						</div>
					)}
				</div>
			)}
			{pickup_addresses.length == 0 && warehouses.length == 0 && !is_loading && filter_object.selected_master_zone.id != 0 && (
				<div className={classes.empty_content}>
					<NoResult text='No address found' btn_onclick={() => set_filter_object({ selected_master_zone: { name: 'No Zone', id: 0 } })} />
				</div>
			)}
			{selected_filter === PICKUP_ADDRESS_FILTERS.pickup &&
				pickup_addresses.length == 0 &&
				warehouses.length !== 0 &&
				!is_loading &&
				filter_object.selected_master_zone.id == 0 && (
					<div className={classes.empty_content}>
						<EmptyState
							button_text='New Pickup'
							left_icon={ImageLinks.addWhite}
							empty_img={ImageLinks.empty_state_saveaddress}
							onClick={() => {
								set_selected_filter(PICKUP_ADDRESS_FILTERS.pickup);
								set_show_create_address(true);
							}}
						/>
						{/* <Helpcenter text={'How do I create a Vehicle ?'} style={{ marginTop: 40 }} /> */}
					</div>
				)}
			{selected_filter === PICKUP_ADDRESS_FILTERS.warehouse &&
				pickup_addresses.length !== 0 &&
				warehouses.length == 0 &&
				!is_loading &&
				filter_object.selected_master_zone.id == 0 && (
					<div className={classes.empty_content}>
						<EmptyState
							button_text='New Warehouse'
							left_icon={ImageLinks.addWhite}
							empty_img={ImageLinks.empty_state_saveaddress}
							onClick={() => {
								set_selected_filter(PICKUP_ADDRESS_FILTERS.warehouse);
								set_show_create_address(true);
							}}
						/>
						{/* <Helpcenter text={'How do I create a Vehicle ?'} style={{ marginTop: 40 }} /> */}
					</div>
				)}
			{pickup_addresses.length == 0 && warehouses.length == 0 && !is_loading && filter_object.selected_master_zone.id == 0 && (
				<div className={classes.empty_content}>
					<EmptyState
						button_text='New Pickup'
						left_icon={ImageLinks.addWhite}
						empty_img={ImageLinks.empty_state_saveaddress}
						onClick={() => {
							set_selected_filter(PICKUP_ADDRESS_FILTERS.pickup);
							set_show_create_address(true);
						}}
						second_button_text='New Warehouse'
						second_button_onclick={() => {
							set_selected_filter(PICKUP_ADDRESS_FILTERS.warehouse);
							set_show_create_address(true);
						}}
						second_button_left_icon={ImageLinks.add_pruple_no_fill}
					/>
					{/* <Helpcenter text={'How do I create a Vehicle ?'} style={{ marginTop: 40 }} /> */}
				</div>
			)}
			{show_create_address && (
				<AddressInput
					close={(isCreated) => {
						if (isCreated) {
							set_refresh_address(!refresh_address);
						}
						set_show_create_address(false);
					}}
					on_address_select={(address) => {
						if (selected_filter === PICKUP_ADDRESS_FILTERS.warehouse)
							set_create_warehouse_details({
								open: true,
								address_id: address.id,
								lat: address.coordinates.latitude,
								lng: address.coordinates.longitude,
								address_line: address.address_line1,
								instructions: address.instructions_to_reach,
							});
					}}
					title={selected_filter === PICKUP_ADDRESS_FILTERS.pickup ? 'New Pickup Address' : 'New Warehouse Address'}
					zone_id={filter_object.selected_master_zone.id}
					is_save_as_required={selected_filter === PICKUP_ADDRESS_FILTERS.pickup}
					show_save_as={selected_filter === PICKUP_ADDRESS_FILTERS.pickup}
				/>
			)}
			{create_warehouse_details.open && (
				<CreateWarehouse
					close={(isCreated) => {
						if (isCreated) {
							set_refresh_address(!refresh_address);
						}
						set_create_warehouse_details({ ...inital_warehouse_details });
					}}
					address_id={create_warehouse_details.address_id}
					lat={create_warehouse_details.lat}
					lng={create_warehouse_details.lng}
					address_line={create_warehouse_details.address_line}
					instructions={create_warehouse_details.instructions}
				/>
			)}
			{show_add_poc_modal.open && (
				<AddPocModal close={() => set_show_add_poc_modal(false)} address={show_add_poc_modal.address} handle_save={handle_add_sender} />
			)}
			{show_confirm_modal && (
				<ConfirmClose
					headerTitle='Delete Address'
					title={`Are you sure you want to delete this address? You can't undo this change`}
					close={() => set_show_confirm_modal(false)}
					on_confirm={on_unsave_address}
				/>
			)}
			{show_filters && (
				<div style={{ position: 'absolute', top: 132, right: 30 }}>
					<MiniFilter
						show_searh_bar={false}
						close={() => set_show_filters(false)}
						existing_filters={filter_object}
						set_selected_filters={set_filter_object}
						container_style={{ height: 130 }}
					/>
				</div>
			)}
		</div>
	);
};

export default ManagePickupAddress;
