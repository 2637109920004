import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme, status) => ({
	close_container: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
		width: '100%',
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 580,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 30,
		zIndex: 1200,
		overflowY: 'auto',
		paddingBottom: 120,
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
		overflowX: 'hidden',
	},
	header_text: {
		color: theme.colors.darkGrey7,
		fontSize: 12,
		marginTop: 12,
	},
	inputs: {
		width: '48%',
		'& .MuiInputLabel-outlined': {},
		'& .MuiInputBase-root': {
			padding: 5,
			'& .MuiInputBase-input': {
				height: 40,
				padding: 0,
				paddingLeft: 10,
			},
		},
	},
	vehicle_detail_wrap: {
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		flexWrap: 'wrap',
		display: 'flex',
		justifyContent: 'space-between',
		gap: 10,
		padding: 16,
		borderRadius: 6,
	},
	date_range_input: {
		border: `1px solid ${theme.colors.lightGrey2}`,
		width: '48%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 5,
		borderRadius: 4,
	},
	add_tag_text: {
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	create_button_wrap: {
		position: 'absolute',
		width: 580,
		backgroundColor: theme.colors.white,
		bottom: 0,
		zIndex: 1200,
		height: 80,
		display: 'flex',
		padding: 24,
		right: 0,
		justifyContent: 'flex-end',
		boxShadow: '0px -4px 14px 0px rgba(0, 0, 0, 0.05)',
	},
	image_wrap: {
		display: 'flex',
		gap: 20,
		marginTop: 10,
	},

	image_upload_wrap: {
		border: `1px dashed ${theme.colors.lightGrey2}`,
		height: 100,
		width: 172,
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 8,
		cursor: 'pointer',
	},
	cross_button: {
		position: 'absolute',
		top: -8,
		right: -8,
	},
	doc_desc: {
		width: '48%',
		padding: '4px 8px',
		backgroundColor: theme.colors.lightGrey7,
		borderRadius: 4,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	cross_wrap: {
		height: 16,
		width: 16,
		borderRadius: 8,
		backgroundColor: theme.colors.lightRed,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	status_pill_green: {
		borderRadius: 30,
		padding: '6px 12px',
		display: 'flex',
		alignItems: 'center',
		gap: 6,
		marginLeft: 5,
		cursor: 'pointer',
		'&:hover': {
			background: 'linear-gradient(178deg, #0F8560 10.13%, #003D2A 104.69%)',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15)',
			border: 'none',
		},
	},
	status_pill_yellow: {
		borderRadius: 30,
		padding: '6px 12px',
		display: 'flex',
		alignItems: 'center',
		gap: 6,
		marginLeft: 5,
		cursor: 'pointer',
		'&:hover': {
			background: 'linear-gradient(180deg, #F2C16B 0%, #DA8D07 100%)',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15)',
			border: 'none',
		},
	},
}));
