import { Divider, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	card_wrap: {
		border: `1px solid ${theme.colors.lightGrey5}`,
		padding: 12,
		borderRadius: 6,
		cursor: 'pointer',
	},
	card_wrap_active: {
		border: `1px solid ${theme.colors.primary}`,
		padding: 12,
		borderRadius: 6,
		cursor: 'pointer',
	},
	list_wrap: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		width: '48%',
		maxHeight: '80vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	add_tag_text: {
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
}));

function BusinessListing({ business_list, selected_business, handle_business_select }) {
	const classes = useStyles();

	return (
		<>
			{_.map(business_list, (business, index) => {
				return (
					<div
						className={business.id == selected_business.id ? classes.card_wrap_active : classes.card_wrap}
						key={`child_business_${index}`}
						onClick={() => handle_business_select(business)}>
						<div className='d-flex justify-content-between'>
							<div>
								<div className='d-flex' style={{ gap: 4 }}>
									<Text bold style={{ fontSize: 14 }}>
										{business.brand_name}
									</Text>
									<Text semi style={{ fontSize: 14 }}>{`(${business.id})`}</Text>
								</div>
								{business?.brand_code && (
									<div>
										<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
											Business Code: {business?.brand_code}
										</Text>
									</div>
								)}
							</div>
						</div>
						<Divider style={{ margin: '8px 0' }} />
						<div className='d-flex' style={{ gap: 32 }}>
							<div className='d-flex' style={{ gap: 5 }}>
								<img src={ImageLinks.user_purple} height={16} width={16} />
								<Text medium style={{ fontSize: 12 }}>
									{business.user_count}
								</Text>
							</div>
							<div className='d-flex' style={{ gap: 5 }}>
								<img src={ImageLinks.rider_purple} height={16} width={16} />
								<Text medium style={{ fontSize: 12 }}>
									{business.rider_count}
								</Text>
							</div>
							<div className='d-flex' style={{ gap: 5 }}>
								<img src={ImageLinks.wallet2_purple} height={16} width={16} />
								<Text medium style={{ fontSize: 12 }}>
									{business.balance}
								</Text>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
}

export default BusinessListing;
