import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormControl, FormControlLabel, ListItem, Radio, RadioGroup, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import utils from 'resources/utils';
import { useSelector } from 'react-redux';
import techno_alarm from '../../../../assets/sounds/techno_alarm_22_sec.mp3';
import air_horn from '../../../../assets/sounds/air_horn_15_sec.mp3';
import alarm_clock from '../../../../assets/sounds/alarm_clock_28_sec.mp3';
import mix_alarm from '../../../../assets/sounds/mix_alarm.mp3';
import api_requests from 'resources/api_requests';

const sound_keys = [
	{ name: 'Techno Alarm', key: 'techno_alarm', duration: 22 },
	{ name: 'Air Horn', key: 'air_horn', duration: 28 },
	{ name: 'Alarm Clock', key: 'alarm_clock', duration: 15 },
	{ name: 'Mix Alarm', key: 'mix_alarm', duration: 23 },
];

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 18, marginBottom: 10 },
	sub_heading: { color: theme.colors.darkGrey2 },
	info_container: { width: 340, backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, minHeight: '70vh', flex: 0.8 },
	update: { position: 'absolute', bottom: 20, right: 30, width: 110 },
	tips: {
		backgroundColor: theme.colors.lightPurple,
		marginRight: 0,
		width: 80,
		borderRadius: 5,
		display: 'flex',
		justifyContent: 'center',
		padding: '8px 8px',
	},
	radio_subtitle: { fontSize: 12, marginLeft: 28, color: theme.colors.darkGrey2 },
	duration_dot: { width: 4, height: 4, backgroundColor: theme.colors.primary, borderRadius: 2, marginLeft: 4, marginRight: 4 },
}));

const AppConfigurations = () => {
	const { app_configs, selected_business_id: business_id_information } = useSelector(({ app }) => app);
	const classes = useStyles();
	const [selected_menu_value, set_selected_menu_value] = useState(null);
	const [played_key, set_played_key] = useState(null);
	const [is_playing, set_is_playing] = useState(false);
	const audio_ref = useRef(null);

	const rider_config = useMemo(() => {
		return { ..._.get(app_configs, `business_configs.${business_id_information}.rider_app_configs`, {}), is_editable: true };
	}, []);

	useEffect(() => {
		if (!_.isEmpty(rider_config)) {
			set_selected_menu_value(_.get(rider_config, 'notification_sound', null));
		}
	}, [rider_config]);

	const play_sound = (key) => {
		if (key === played_key && audio_ref.current) {
			audio_ref.current.pause();
			audio_ref.current = null;
			set_is_playing(false);
			return;
		}
		if (is_playing) return;
		set_is_playing(true);
		let file = '';
		switch (key) {
			case 'techno_alarm':
				file = techno_alarm;
				break;

			case 'air_horn':
				file = air_horn;
				break;

			case 'alarm_clock':
				file = alarm_clock;
				break;

			case 'mix_alarm':
				file = mix_alarm;
				break;

			default:
				break;
		}
		audio_ref.current = new Audio(file);
		set_played_key(key);
		audio_ref.current.play();
	};

	const handle_save_configs = async () => {
		try {
			const res = await api_requests.update_common_configs({
				rider_app_configs: utils.remove_null_and_empty_from_obj({
					...rider_config,
					notification_sound: selected_menu_value,
					is_editable: null,
				}),
			});
			window.location.reload(true);
		} catch (err) {}
	};

	return (
		<div>
			<div className={classes.heading_container}>
				<Text bold className={classes.heading}>
					Rider App Notification Sound
				</Text>
				<ListItem key={`tips`} button onClick={() => {}} className={classes.tips}>
					<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
						Tips & Info
					</Text>
				</ListItem>
			</div>

			<div style={{ display: 'flex', position: 'relative' }}>
				<div style={{ marginRight: 20, flex: '1' }}>
					<Text semi className={classes.sub_heading} style={{ fontSize: 14 }}>
						This feature allows to select from the list of notification sound options to be played on your delivery executive's phone, make sure
						your executives never miss any Order update to attend.
					</Text>

					<FormControl style={{ width: '90%' }}>
						<RadioGroup
							value={selected_menu_value}
							onChange={(e) => {
								set_selected_menu_value(e.target.value);
							}}>
							{_.map(sound_keys, (item, index) => (
								<div className='align-items-center justify-content-between'>
									<div>
										<FormControlLabel
											key={`sound_item_${index}`}
											value={item.key}
											style={{ marginTop: 20 }}
											control={<Radio />}
											label={
												<Text style={{ fontSize: 14 }} semi>
													{item.name}
												</Text>
											}
										/>
										<div className='align-items-center'>
											<Text medium className={classes.radio_subtitle}>
												Song
											</Text>
											<div className={classes.duration_dot}></div>
											<Text medium style={{ fontSize: 12, color: theme.colors.primary }}>
												{`0.${item.duration}s`}
											</Text>
										</div>
									</div>

									<img
										onClick={() => play_sound(item.key)}
										className='cursor-pointer'
										src={
											item.key === played_key && is_playing
												? ImageLinks.play_filled_purple
												: is_playing
												? ImageLinks.pause_filled_gray
												: ImageLinks.pause_filled_purple
										}
										width={24}
										height={24}
									/>
								</div>
							))}
						</RadioGroup>
					</FormControl>
				</div>
				<div className={classes.info_container}>
					<Text bold style={{ fontSize: 18, color: theme.colors.lightPurple7 }}>
						What is Notification sound?
					</Text>
					<Text regular style={{ fontSize: 12 }}>
						This is the sound which rings on delivery executive's phone when any notification is received on Pidge Application.
					</Text>

					<Text bold style={{ fontSize: 18, color: theme.colors.lightPurple7, marginTop: 60 }}>
						How will this action work?
					</Text>
					<Text regular style={{ fontSize: 12 }}>
						After the selection of sound confirmed from settings all delivery executives will be notified everytime with that notification sound.
						Sound will play till the length of sound or if the notification is attended with task acceptance.
					</Text>
					<div className='d-flex justify-content-center' style={{ marginTop: 50 }}>
						<img src={ImageLinks.notif_tips} width={274} height={287} style={{ margin: 'auto' }}></img>
					</div>
				</div>
			</div>
			<Button
				disabled={selected_menu_value === null || selected_menu_value == _.get(rider_config, 'notification_sound', null)}
				className={classes.update}
				onClick={handle_save_configs}
				type='round'
				text='Save'
				size='large'
			/>
		</div>
	);
};

export default AppConfigurations;
