import React, { useEffect, useState } from 'react';
import theme from 'resources/theme';
import { ListItem, Radio, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import Text from 'modules/Text';
import IconButton from 'modules/IconButton';
import ImageLinks from 'assets/images/ImageLinks';
import InfoIcon from '@material-ui/icons/Info';
import constant from 'resources/constant';
import moment from 'moment';

const useStyles = makeStyles(() => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 600,
		zIndex: 1200,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	main_body_container: {
		backgroundColor: 'white',
		borderTopLeftRadius: 50,
		padding: '31px 31px 10px 30px',
		flex: 1,
		overflowY: 'hidden',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		borderBottom: '1px solid lightgrey',
		paddingBottom: 5,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	header_text: { fontSize: 20, color: theme.colors.primary },
	section_wrapper: {
		borderRadius: 8,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		padding: 12,
		marginTop: 12,
	},
	section_separator: { border: `1px solid ${theme.colors.lightGrey5}`, marginTop: 8, marginBottom: 10 },
	time_item_wrap: {
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey5}`,
		padding: 8,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		minHeight: 46,
		borderRadius: 4,
		marginBottom: 8,
		display: 'flex',
		alignItems: 'center',
	},
	timer_item_separator: {
		border: `1px solid ${theme.colors.black}`,
		height: 32,
		marginLeft: 8,
		marginRight: 8,
	},
}));

const NetworkRulesModal = ({ close }) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={clsx(classes.container, 'box-shadow')} style={{ borderTopLeftRadius: 20 }}>
				<div className={classes.main_body_container}>
					<div className={classes.header}>
						<Text bold className={classes.header_text}>
							Network Rules
						</Text>
						<IconButton onClick={close} style={{ border: 'none', marginLeft: 5 }}>
							<img src={ImageLinks.cross_light} width={16} height={16} />
						</IconButton>
					</div>

					<div className={classes.section_wrapper}>
						<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
							Rider Availability
						</Text>
						<div className={classes.section_separator}></div>

						{_.map(
							[
								{ day: 'Monday', values: [1, 2, 3, 4] },
								{ day: 'Saturday', values: [1] },
								{ day: 'Sunday', values: [1, 2, 3] },
							],
							(item, index) => (
								<div className={classes.time_item_wrap}>
									<Text semi style={{ fontSize: 14, minWidth: 80 }}>
										Wednesday
									</Text>
									<div className={classes.timer_item_separator}></div>
									<div style={{ display: 'flex', flexWrap: 'wrap' }}>
										{_.map(item.values, (time_item, time_index) => (
											<Text semi style={{ fontSize: 14 }}>
												{`${moment().format('hh:mm')}`}
												<Text semi component={'span'} style={{ fontSize: 12 }}>{`${moment().format('A')}`}</Text>
												<Text semi component={'span'} style={{ fontSize: 12 }}>{` - `}</Text>
												{`${moment().format('hh:mm')}`}
												<Text semi component={'span'} style={{ fontSize: 12 }}>{`${moment().format('A')}, `}</Text>
											</Text>
										))}
									</div>
								</div>
							),
						)}
					</div>

					<div className={classes.section_wrapper}>
						<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
							Rates
						</Text>
						<div className={classes.section_separator}></div>

						{_.map([1, 2, 3], (item, index) => (
							<div
								style={{
									borderRadius: 4,
									boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
									border: `1px solid ${theme.colors.lightGrey5}`,
									backgroundColor: theme.colors.white,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<Text semi style={{ fontSize: 14 }}>
									1-10 orders: ₹12
								</Text>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

const NetworkRulesModalCont = (props) => {
	const [key, set_key] = useState(_.uniqueId());
	return <NetworkRulesModal key={key} set_key={set_key} {...props} />;
};

export default NetworkRulesModalCont;
