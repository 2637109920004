import _ from 'lodash';
import { DateRangePicker } from 'materialui-daterange-picker';
import Text from 'modules/Text';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import moment from 'moment';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import api_requests from 'resources/api_requests';

const SettleHistory = () => {
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);
	const [filters, set_filters] = React.useState({
		from: moment(moment().subtract('15', 'days').format('MM-DD-YYYY')),
		to: moment(moment().format('MM-DD-YYYY')),
	});

	const [transactions, set_transactions] = React.useState([]);

	useEffect(() => {
		api_requests
			.get_cm_history({
				from: filters.from.format(),
				to: filters.to.endOf('day').format(),
				type: 300,
			})
			.then((res) => {
				set_transactions(res.data.records);
			});
	}, [filters]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: 20,
				}}>
				<div
					style={{
						display: 'flex',
						alignItems: 'flex-end',
					}}>
					<Text bold style={{ fontSize: 24 }}>
						Settled Today
					</Text>
				</div>
				<div style={{ position: 'relative' }}>
					<UndlerlineTextField
						value={`${filters.from.format('DD/MM/YY')} - ${filters.to.format('DD/MM/YY')}`}
						label='Date Range'
						onClick={() => setDatePickerOpen(true)}
						style={{ width: 150, alignItems: 'center' }}
					/>
					<div style={{ position: 'absolute', right: 0, width: 700 }}>
						<DateRangePicker
							open={datePickerOpen}
							toggle={() => setDatePickerOpen(!datePickerOpen)}
							style={{ width: 200 }}
							initialDateRange={{
								startDate: filters.from,
								endDate: filters.to,
							}}
							maxDate={new Date()}
							onChange={(range) => {
								const newDateRange = {
									from: moment(range.startDate),
									to: moment(range.endDate),
								};

								if (Math.abs(newDateRange.from.diff(newDateRange.to, 'days')) > 15) {
									toast.error('Only 15 days filter allowed', {
										position: toast.POSITION.BOTTOM_RIGHT,
									});
									return;
								}
								set_filters(newDateRange);
								setDatePickerOpen(!datePickerOpen);
							}}
						/>
					</div>
				</div>
			</div>

			<div
				style={{
					background: '#E5E5E5',
					borderRadius: 50,
					width: '100%',
					padding: '12px 30px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: 20,
				}}>
				<Text bold style={{ flex: 2 }}>
					Bank Transaction ID
				</Text>
				<Text bold style={{ flex: 2 }}>
					Amount
				</Text>
				<Text bold style={{ flex: 2 }}>
					Proof
				</Text>
				<Text bold style={{ flex: 2 }}>
					Status
				</Text>
				<Text bold style={{ flex: 2 }}>
					Remarks
				</Text>
			</div>

			<div style={{ overflowY: 'auto', flex: 1, marginTop: 10, marginBottom: 10 }}>
				{_.map(transactions, (transaction, key) => {
					return (
						<div
							key={`transaction${key}`}
							style={{
								width: '100%',
								padding: '10px 30px',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							<Text style={{ flex: 2 }}>
								{transaction.payment_method === 'cash'
									? _.get(transaction, 'meta_info.bank_txn_id', '-')
									: _.get(transaction, 'meta_info.paymentId', '-')}
							</Text>
							<Text style={{ flex: 2 }}>₹{transaction.amount}</Text>
							<div style={{ flex: 2 }}>
								{_.get(transaction, 'meta_info.proof') ? (
									<Text component='a' semi style={{ textDecoration: 'underline' }} target='blank' href={transaction.meta_info.proof}>
										View
									</Text>
								) : (
									<Text>-</Text>
								)}
							</div>
							<Text style={{ flex: 2 }}>{_.get(transaction, 'meta_info.from.type', '-')}</Text>
							<Text style={{ flex: 2 }}>{transaction.remark ?? '-'}</Text>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SettleHistory;
