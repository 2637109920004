import React, { useEffect } from 'react';
import qs from 'qs';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import clsx from 'clsx';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import Routes from 'resources/Routes';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';
import utils from 'resources/utils';
import BrandColorLogo from './components/BrandColorLogo';
import EndUserPage from './components/EndUserPage';
import { shallowEqual, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '32px 32px 0 32px',
		display: 'flex',
		height: '100%',
		position: 'relative',
		flexDirection: 'column',
	},
	header: {
		display: 'flex',
		height: 40,
		alignItems: 'center',
	},
	heading_text: {
		fontSize: 22,
		color: theme.colors.black,
		marginRight: 15,
	},
	all_settings: {
		color: theme.colors.primary,
		fontSize: 14,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	body_section: { display: 'flex', marginTop: 32, flex: 1, overflowY: 'hidden' },
	content_section: {
		backgroundColor: theme.colors.white,
		borderTopLeftRadius: 50,
		flex: 1,
		marginLeft: 20,
		padding: 30,
		// overflowY: 'auto',
	},
	sidebar: { width: '20%' },
	sub_container: { paddingBottom: 20, marginTop: 10, height: '100%', borderRight: `2px solid ${theme.colors.lightPurple}` },
	bundle_list_item: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white,
		borderRadius: 8,
		marginTop: 10,
		marginRight: 16,
		border: `1px solid transparent`,
		cursor: 'pointer',
		'&:hover': { backgroundColor: theme.colors.lightPurple, border: `1px solid ${theme.colors.primary}` },
	},
	bundle_list_item_inactive: {
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 8,
		marginTop: 10,
		marginRight: 16,
		display: 'flex',
		justifyContent: 'space-between',
	},
	list_item: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: 10,
		height: 40,
	},
	sub_title: { marginLeft: 8, fontSize: 14 },
	active_class: { backgroundColor: theme.colors.lightPurple, border: `1px solid ${theme.colors.primary}` },
}));

export const SETTINGS_MENU = {
	brand: {
		id: 'brand_color_logo',
		name: 'Brand Logo & Colour',
	},
	end_user: {
		id: 'end_user_tracking',
		name: 'End User Tracking',
	},
};

const WhitelabellingSettings = ({ history }) => {
	const { app_configs, selected_business_id: business_id_information } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
			selected_business_id: state.app.selected_business_id,
		}),
		shallowEqual,
	);
	const query_object = qs.parse(history.location.search.slice(1));
	const classes = useStyles();

	const [active_card_id, set_active_card_id] = React.useState(query_object.type || SETTINGS_MENU.geo_location.id);
	const [white_label_configs, set_white_label_configs] = React.useState(_.get(app_configs, `business_configs.${business_id_information}`, {}));

	const navigate = (id) => {
		if (query_object.vendor_id) {
			history.push(Routes.WHITELABELLING_SETTINGS.path + `?type=${id}&vendor_id=${query_object.vendor_id}`);
		} else {
			history.push(Routes.WHITELABELLING_SETTINGS.path + `?type=${id}`);
		}
	};

	useEffect(() => {
		if (!query_object.type) {
			return;
		}
		set_active_card_id(query_object.type);
	}, [query_object.type]);

	useEffect(() => {
		if (query_object.vendor_id) {
			get_vendor_config();
		}
	}, []);

	const get_vendor_config = async () => {
		if (query_object.vendor_id)
			try {
				const res = await api_requests.get_single_token(query_object.vendor_id);
				if (res.data) {
					set_white_label_configs(_.get(res, 'data.vendor[0].business_config', {}));
				}
			} catch (err) {}
	};
	let active_comp = null;

	switch (active_card_id) {
		case SETTINGS_MENU.brand.id:
			active_comp = <BrandColorLogo app_configs={white_label_configs} query_object={query_object} />;
			break;
		case SETTINGS_MENU.end_user.id:
			active_comp = <EndUserPage app_configs={white_label_configs} query_object={query_object} />;
			break;
		default:
			break;
	}

	// useEffect(() => {
	// 	get_configs();
	// }, []);

	// const get_configs = () => {}

	return (
		<div className={classes.container}>
			<div className='align-items-center'>
				<div className='page_back_btn' onClick={() => history.push(Routes.SETTINGS.path)}>
					<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
				</div>
				<Text semi className={'global_header_text'}>
					Whitelabelling
				</Text>
			</div>
			<div className={classes.body_section}>
				<div className={classes.sidebar}>
					<div className={classes.sub_container}>
						{_.map(SETTINGS_MENU, (item, key) => {
							const is_selected = active_card_id === item.id;
							return (
								<div
									className={clsx(
										`box-shadow ${item.locked ? classes.bundle_list_item_inactive : classes.bundle_list_item}`,
										is_selected && classes.active_class,
									)}
									key={`menu${key}`}
									onClick={(e) => {
										if (!item.locked) navigate(item.id);
									}}>
									<div className={classes.list_item}>
										<Text bold className={classes.sub_title} style={{ color: item.locked ? theme.colors.darkGrey2 : theme.colors.black }}>
											{item.name}
										</Text>
										<img
											style={{ alignSelf: 'center' }}
											src={is_selected ? ImageLinks.chevron_right_pink : item.locked ? ImageLinks.lock : ImageLinks.chevron_right_grey}
											width={item.locked ? 16 : 8}
											height={item.locked ? 16 : 8}
											alt='arrow'
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className={classes.content_section}>{active_comp}</div>
			</div>
		</div>
	);
};

export default WhitelabellingSettings;
