import { makeStyles } from '@material-ui/core';
import OutlinedTextField from 'modules/OutlinedTextField';
import Select from 'modules/Select';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import { template_options } from '../helper';
import _ from 'lodash';
import clsx from 'clsx';
import constant from 'resources/constant';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.darkGrey4,
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.darkGrey4,
		},
		'& .MuiInputLabel-outlined': {
			color: theme.colors.darkGrey5,
		},
	},
	prototype_wrap: {
		height: 56,
		width: 210,
		borderRadius: 4,
		border: `1.5px solid ${theme.colors.darkGrey4}`,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 16,
		marginTop: 20,
		position: 'relative',
	},
	prototype_label_text: { backgroundColor: theme.colors.white, top: -8, position: 'absolute', paddingLeft: 4, paddingRight: 4 },
	dynamic_content: { color: theme.colors.primary, backgroundColor: theme.colors.lightPurple },
}));

const RecipientContent = ({
	selected_template,
	event_detail,
	selected_action,
	selected_recipient,
	handle_selected_template,
	show_header = true,
	header_label = 'Header',
	show_team = false,
	teams = [],
	handle_team_change,
}) => {
	const classes = useStyles();

	let content = '';
	let header = '';
	const action_value = event_detail?.recipients[selected_recipient].channels[selected_action];
	if (action_value.channel === constant.COMM_CHANNEL.RAVEN || action_value.channel === constant.COMM_CHANNEL.WHATSAPP) {
		header = _.get(action_value, 'value.template.header', '');
		content = _.get(action_value, 'value.template.content', '');
	} else {
		header = _.get(action_value, 'value.data.title', '');
		content = _.get(action_value, 'value.data.body.content', '');
	}

	if (action_value.channel === constant.COMM_CHANNEL.PUSH) {
		content = content.replace(/##(.*?)##/g, '{{$1}}');
	}

	let content_arr = [],
		header_arr = [];

	const pattern = /(\{\{.*?\}\})/g;
	const split_content_by_space = content?.split(pattern);
	const split_header_by_space = header?.split(pattern);

	if (!_.isEmpty(content))
		content_arr = _.map(split_content_by_space, (item, index) =>
			item.match(pattern) ? { type: 'braces', value: item } : { type: 'text', value: ` ${item} ` },
		);
	if (!_.isEmpty(header))
		header_arr = _.map(split_header_by_space, (item, index) =>
			item.match(pattern) ? { type: 'braces', value: item } : { type: 'text', value: ` ${item} ` },
		);

	return (
		<>
			<div style={{ marginTop: 12, display: 'flex' }}>
				<div>
					<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
						Template*
					</Text>
					<Select
						value={selected_template}
						selected_title_props={{ semi: true }}
						defaultOption={{
							title: 'Select Template',
							value: null,
						}}
						options={template_options}
						handleChange={(val) => handle_selected_template(val)}
						containerStyles={{ height: 33, width: 140, marginTop: 4 }}
					/>
				</div>
				{show_team && (
					<div style={{ marginLeft: 40 }}>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Assign to Team(s)*
						</Text>
						<Select
							show_search
							multiple
							value={event_detail?.recipients[selected_recipient].channels[selected_action].value.to}
							selected_title_props={{ semi: true }}
							defaultOption={{
								title: 'Select Team',
								value: null,
							}}
							options={_.map(teams, (item) => ({ title: item.title, value: `team_ch_${item.id}` }))}
							handleChange={handle_team_change}
							containerStyles={{ height: 33, width: 210, marginTop: 4 }}
							menu_styles={{ width: 210, height: 200 }}
						/>
					</div>
				)}
			</div>

			{show_header && (
				<div className={classes.prototype_wrap}>
					<div className={classes.prototype_label_text}>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey5 }}>
							{header_label}
						</Text>
					</div>
					<div style={{ float: 'left' }}>
						{_.map(header_arr, (item, index) => (
							<Text
								key={`prototype_text_header_${index}`}
								medium
								component={'span'}
								className={clsx(item.type === 'braces' && classes.dynamic_content)}
								style={{ fontSize: 14, lineHeight: 1.5 }}>
								{item.value}
							</Text>
						))}
					</div>
				</div>
			)}

			<div className={classes.prototype_wrap} style={{ height: 100, width: '100%' }}>
				<div className={classes.prototype_label_text}>
					<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey5 }}>
						Message
					</Text>
				</div>
				<div style={{ float: 'left' }}>
					{_.map(content_arr, (item, index) => (
						<Text
							key={`prototype_text_${index}`}
							medium
							component={'span'}
							className={clsx(item.type === 'braces' && classes.dynamic_content)}
							style={{ fontSize: 14, lineHeight: 1.5 }}>
							{item.value}
						</Text>
					))}
				</div>
			</div>

			{/* <OutlinedTextField
				InputProps={{ style: { height: 56 } }}
				textStyle={{ fontSize: 14 }}
				style={{ width: 210, marginTop: 20 }}
				value={'JD-WPIDGE'}
				editable={false}
				label={'Header'}
				onChange={(e) => console.log(e.target.value)}
				className={classes.root}
			/> */}
			{/* <OutlinedTextField
				InputProps={{ style: { height: 100 } }}
				multiline
				textStyle={{ fontSize: 14 }}
				style={{ marginTop: 20 }}
				value={
					'Dear {POC Name}, your order {AWB-ID} is Out for Delivery. Please give code:{OTP} when agent is at your premise. Track order here: {tracking-id}. -{BusinessName}'
				}
				editable={false}
				label={'Message'}
				onChange={(e) => console.log(e.target.value)}
				className={classes.root}
			/> */}
		</>
	);
};

export default RecipientContent;
