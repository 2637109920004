import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, Modal, Grid } from '@material-ui/core';
import _ from 'lodash';
import clsx from 'clsx';
import Text from 'modules/Text';
import OutlinedTextField from 'modules/OutlinedTextField';
import ContainedButton from 'modules/ContainedButton';
import constant from 'resources/constant';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 20,
		background: theme.colors.white,
		width: 580,
		height: 660,
		margin: 'auto auto',
		borderRadius: 20,
		outline: 'none',
	},
	address_container: {
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 12,
		marginTop: 16,
	},
	map_canvas_wrap: { height: 140, borderRadius: 12 },
	address_wrap: { padding: 8 },
	addr_label: { color: theme.colors.darkGrey2, fontSize: 10, marginBottom: 4 },
	addr_text: { fontSize: 14, lineHeight: '22px' },
	input_row_wrap: { marginTop: 16 },
	suggestion_container: { marginTop: 16 },
	suggestion_user_container: {
		marginTop: 12,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 6,
		display: 'flex',
		padding: 12,
		cursor: 'pointer',
	},
	cta_btn: {
		padding: '8px 32px',
		borderRadius: 30,
		margin: '0 auto',
		'&:disabled': {
			color: theme.colors.darkGrey2,
		},
	},
	address_and_button_container: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '94%' },
}));

let map = null;

const AddPocModal = ({ address, close, handle_save }) => {
	const [poc_data, set_poc_data] = useState([{ name: '', mobile: '' }]);
	const classes = useStyles();

	useEffect(() => {
		if (!_.isEmpty(address.poc)) {
			set_poc_data(address.poc);
		}
		setTimeout(() => {
			initialize();
		}, 800);
	}, []);

	const initialize = () => {
		map = new window.google.maps.Map(document.getElementById('map_canvas'), {
			center: { lat: Number(address.exact_location.latitude), lng: Number(address.exact_location.longitude) },
			zoom: 15,
			clickableIcons: false,
			disableDefaultUI: true,
			scaleControl: true,
			zoomControl: true,
			mapId: constant.MAP_DESIGN_ID,
		});
		const icon_pickup = {
			url: ImageLinks.rider_pickup, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};
		new window.google.maps.Marker({
			position: { lat: Number(address.exact_location.latitude), lng: Number(address.exact_location.longitude) },
			icon: icon_pickup,
			map,
		});
	};

	const confirm_address = () => {
		handle_save(poc_data);
	};

	const handle_input_add = () => {
		const _poc_data = _.cloneDeep(poc_data);
		_poc_data.push({ name: '', mobile: '' });
		set_poc_data(_poc_data);
	};

	const handle_input_delete = (index) => {
		const _poc_data = _.cloneDeep(poc_data);
		_poc_data.splice(index, 1);
		set_poc_data(_poc_data);
	};

	const handle_input_change = (e, index) => {
		const _poc_data = _.cloneDeep(poc_data);
		_poc_data[index][e.target.name] = e.target.value;
		set_poc_data(_poc_data);
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		let all_fields_valid = true;
		const old_poc = _.isEmpty(address.poc) ? [] : address.poc;

		for (let i = 0; i < poc_data.length; i++) {
			const element = poc_data[i];
			if (!element.name || !element.mobile || element.mobile.length != 10) {
				all_fields_valid = false;
				break;
			}
		}
		if (!all_fields_valid) return value;

		if (old_poc.length != poc_data.length) value = false;
		else {
			const max_items_length = poc_data.length > old_poc.length ? poc_data.length : old_poc.length;
			let data_changed = false;
			for (let i = 0; i < max_items_length; i++) {
				if (poc_data[i]?.name != old_poc[i]?.name || poc_data[i]?.contact != old_poc[i]?.contact) {
					data_changed = true;
					break;
				}
			}
			value = !data_changed;
		}

		return value;
	}, [poc_data]);

	return (
		<Modal open={true} onClose={() => {}} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.wrapper}>
				<div className='justify-content-between align-items-center'>
					<div className='align-items-center'>
						<Text bold style={{ fontSize: 20 }}>
							Sender Details
						</Text>
					</div>
					<img src={ImageLinks.crossBlack} width={20} height={20} onClick={close} style={{ cursor: 'pointer' }} />
				</div>
				<div className={classes.address_and_button_container}>
					<div>
						<Grid container spacing={2} className={classes.address_container}>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<div className={classes.address_wrap}>
									<Text medium className={classes.addr_label}>
										Address
									</Text>
									<Text semi className={classes.addr_text}>
										{_.get(address, 'address_line1', '-')}
									</Text>
									<Text medium className={classes.addr_label} style={{ marginTop: 10 }}>
										Instructions to Reach
									</Text>
									<Text semi className={classes.addr_text}>
										{_.get(address, 'instructions_to_reach', '-')}
									</Text>
								</div>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<div className={classes.map_canvas_wrap}>
									<div id='map_canvas' className='map-canvas-wrapper rider-location card' style={{ height: '100%', borderRadius: 12 }} />
								</div>
							</Grid>
						</Grid>

						{_.map(poc_data, (item, index) => (
							<div className={clsx('align-items-center', classes.input_row_wrap)}>
								<OutlinedTextField
									name='name'
									label='Name of Sender*'
									className='w-100'
									value={item.name}
									onChange={(e) => handle_input_change(e, index)}
								/>
								<OutlinedTextField
									type='number'
									label='Contact of Sender*'
									value={item.mobile}
									className='w-100'
									name='mobile'
									style={{ marginLeft: 16, marginRight: 16 }}
									onChange={(e) => handle_input_change(e, index)}
								/>

								<img className='cursor-pointer' src={ImageLinks.delete_solid_light_red} onClick={() => handle_input_delete(index)} />
							</div>
						))}
						{poc_data.length < 5 && (
							<Button
								type='text'
								size='extra_small'
								text={`Add Another (${poc_data.length}/5)`}
								onClick={handle_input_add}
								left_icon={ImageLinks.add_pruple_no_fill}
								style={{ width: 'fit-content', marginTop: 4 }}
							/>
						)}
					</div>
					<div className='d-flex justify-content-center align-items-center text-align-center'>
						<Button type='round' size='large' text='Save' onClick={confirm_address} disabled={btn_disabled} style={{ width: 110 }} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default AddPocModal;
