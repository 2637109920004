export const OPTIONS_MENU = {
	update: 'update',
	cancel: 'cancel',
	unallocate: 'unallocate',
	unroute: 'unroute',
	report: 'report',
	chnges_rider: 'change_rider',
};
export const REPORT_MENU = {
	product: 'product',
	shipping: 'shipping',
	manifest: 'manifest',
};
