import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import Text from './Text';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 40,
		border: `1px solid ${theme.colors.lightPurple} `,
		background: theme.colors.white,
		boxShadow: '0px 0px 14px 0px rgba(102, 102, 102, 0.10)',
		gap: 12,
		padding: '16px 20px',
		cursor: 'pointer',
	},
}));

const Helpcenter = ({ text, style, url = 'https://pidge.in/helpcenter' }) => {
	const classes = useStyles();

	const [show, set_show] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			set_show(true);
		}, 100);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	const handle_redirect = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.HELP_CENTER, url);
		window.open(url);
	};

	if (!show) return null;
	return (
		<div className={classes.container} style={{ ...style }} onClick={handle_redirect}>
			<div className='d-flex justify-content-center align-items-center' style={{ gap: 8 }}>
				<img src={ImageLinks.overlay_chat} width={20} height={20} />
				<Text semi style={{ fontSize: 14, color: theme.colors.darkGrey7 }}>
					{text}
				</Text>
			</div>
			<img src={ImageLinks.share_puple} />
		</div>
	);
};

export default Helpcenter;
