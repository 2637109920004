import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React, { useContext, useEffect } from 'react';
import theme from 'resources/theme';
import CreateCommunicationContext from '../context';
import ImageLinks from 'assets/images/ImageLinks';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import RecipientContent from './RecipientContent';
import Switch from 'modules/Switch';
import constant from 'resources/constant';
import ConfirmClose from 'modules/modals/ConfirmClose';
import SMSPreview from './SMSPreview';
import EmailPreview from './EmailPreview';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	action_content_wrap: { background: theme.colors.white, display: 'flex', width: 700, borderRadius: 10, marginTop: 10, flex: 0.55 },
	action_wrap: { width: '30%', padding: '20px 16px' },
	action_item_wrap: {
		borderRadius: 6,
		height: 43,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 12,
		paddingRight: 12,
		justifyContent: 'space-between',
		cursor: 'pointer',
		marginBottom: 16,
	},
	selected_action_item: {
		border: `1px solid ${theme.colors.primary}`,
	},
	channel_wrap: {
		width: '70%',
		padding: '20px 16px',
		borderLeft: `1px solid ${theme.colors.lightGrey3}`,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	preview_wrap: {
		marginLeft: 20,
		width: '100%',
		display: 'flex',
		flex: 0.45,
		flexDirection: 'column',
		marginTop: 12,
	},
	save_poc_btn: { minWidth: 185, alignSelf: 'flex-end', marginTop: 10 },
	cost_warning_text: { fontSize: 10, color: theme.colors.darkGrey2, marginTop: 10 },
	dashed_border: {
		border: `1px dashed ${theme.colors.lightGrey5}`,
		minHeight: 300,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	cost_info_text: { fontSize: 12, color: theme.colors.lightGrey6, textAlign: 'center' },
	recipient_content_wrap: { flex: 1, display: 'flex', flexDirection: 'column' },
	empty_text: { flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' },
}));

const Recipients = () => {
	const {
		selected_usecase,
		event_detail,
		selected_recipient,
		handle_selected_recipient,
		selected_action,
		handle_action,
		selected_template,
		handle_selected_template,
		_original_event_detail,
		save_recipient_detail,
		value_changed,
		handle_value_changed,
		handle_action_toggle,
		confirm_close_modal,
		handle_confirm_close_modal,
		teams,
		handle_team_change,
	} = useContext(CreateCommunicationContext);

	const classes = useStyles();

	useEffect(() => {
		if (selected_recipient >= 0) {
			const total_channels = event_detail.recipients[selected_recipient]?.channels?.length;
			let arr = [];
			for (let i = 0; i < total_channels; i++) {
				arr[i] = false;
			}
			handle_value_changed(arr);
		}
	}, [selected_recipient]);

	if (selected_usecase === null || event_detail === null) return <></>;

	const available_channels = event_detail.recipients[selected_recipient]?.channels;
	const selected_channel = event_detail.recipients[selected_recipient]?.channels?.[selected_action];
	let any_changed_item = false;
	if (selected_action >= 0) {
		any_changed_item = _.filter(value_changed, (item) => item).length > 0;
	}
	let btn_disabled = !any_changed_item;
	if ((selected_channel?.channel === constant.COMM_CHANNEL.PUSH && _.get(selected_channel, 'value.to', []).length === 0) || !any_changed_item)
		btn_disabled = true;

	return (
		<div style={{ marginTop: 30 }}>
			<Text bold style={{ fontSize: 18 }}>
				Recipients
			</Text>
			<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
				Configure settings as per each receiver type
			</Text>

			<div style={{ marginTop: 12 }}>
				{_.map(event_detail.recipients, (recipient_option, key) => {
					const is_selected = key === selected_recipient;
					return (
						<ContainedButton
							onClick={() => handle_selected_recipient(key, any_changed_item)}
							variant={is_selected ? 'contained' : 'link'}
							key={`recipient_option${key}`}
							style={{ borderRadius: 8, marginRight: 8 }}>
							<Text bold style={{ fontSize: 14, color: is_selected ? theme.colors.white : theme.colors.lightPurple6 }}>
								{recipient_option.name}
							</Text>
							{recipient_option?.is_default && (
								<img
									src={is_selected ? ImageLinks.tick_circle_white : ImageLinks.tick_circle_light_purple_solid}
									width={16}
									height={16}
									style={{ marginLeft: 4 }}
								/>
							)}
						</ContainedButton>
					);
				})}
			</div>
			<div style={{ display: 'flex' }}>
				<div className={clsx('box-shadow', classes.action_content_wrap)}>
					<div className={classes.action_wrap}>
						<Text bold style={{ fontSize: 18 }}>
							Action
						</Text>
						<Text style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>Select multiple media</Text>
						<div style={{ marginTop: 12 }}>
							{_.map(available_channels, (item, index) => (
								<div
									onClick={() => handle_action(index)}
									key={`action_item_${index}`}
									className={clsx('box-shadow', classes.action_item_wrap, selected_action === index && classes.selected_action_item)}>
									<Text medium style={{ fontSize: 14 }}>
										{constant.COMM_CHANNEL_NAME_MAP[item.channel]}
									</Text>
									{(_original_event_detail.recipients[selected_recipient]?.channels[index].active || value_changed[index]) && (
										<img
											src={value_changed[index] ? ImageLinks.tick_circle_light_orange_solid : ImageLinks.tickCircleSolid}
											width={16}
											height={16}
										/>
									)}
								</div>
							))}
						</div>
					</div>
					<div className={classes.channel_wrap}>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
							<Text bold style={{ fontSize: 18 }}>
								Content
							</Text>
							{selected_action >= 0 && <Switch checked={available_channels[selected_action]?.active} onChange={handle_action_toggle} />}
						</div>
						<Text style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>The header & message displayed to the receiver</Text>
						{selected_action < 0 ? (
							<div className={classes.empty_text}>
								<Text semi style={{ fontSize: 14, color: theme.colors.lightGrey2 }}>
									Select a Channel to manage Content
								</Text>
							</div>
						) : (
							<div
								className={classes.recipient_content_wrap}
								style={!available_channels[selected_action]?.active ? { opacity: 0.2, pointerEvents: 'none' } : {}}>
								<RecipientContent
									teams={teams}
									selected_action={selected_action}
									event_detail={event_detail}
									selected_recipient={selected_recipient}
									show_header={
										selected_channel.channel === constant.COMM_CHANNEL.RAVEN ||
										selected_channel.channel === constant.COMM_CHANNEL.PUSH ||
										selected_channel.channel === constant.COMM_CHANNEL.WHATSAPP
									}
									show_team={selected_channel.channel === constant.COMM_CHANNEL.PUSH}
									selected_template={selected_template}
									handle_selected_template={handle_selected_template}
									handle_team_change={handle_team_change}
								/>
							</div>
						)}
					</div>
				</div>

				<div className={classes.preview_wrap}>
					{/* <Text medium className={classes.cost_info_text}>
						Cost per SMS per receiver:
						<Text bold component={'span'}>
							₹ 0.50*
						</Text>
					</Text> */}
					<div className={classes.dashed_border}>
						<Text semi style={{ fontSize: 14, color: theme.colors.lightGrey2 }}>
							Preview Mode Coming Soon
						</Text>
					</div>
					{/* <Text medium className={classes.cost_warning_text}>
						*All costs will be deducted from your wallet
					</Text> */}
					{selected_action >= 0 && (
						<Button
							type='round'
							text={`Save ${event_detail.recipients[selected_recipient].name} Settings`}
							onClick={save_recipient_detail}
							disabled={btn_disabled}
							className={classes.save_poc_btn}
							size='large'
						/>
					)}
				</div>
			</div>
			{confirm_close_modal.open && (
				<ConfirmClose
					headerTitle={confirm_close_modal.header_title}
					title={confirm_close_modal.title}
					close={handle_confirm_close_modal}
					positive_btn_text={confirm_close_modal.positive_text}
					negative_btn_text={confirm_close_modal.negative_text}
					on_confirm={confirm_close_modal.confirm_acion}
					is_error_msg={confirm_close_modal.is_error_msg}
				/>
			)}
		</div>
	);
};

export default Recipients;
