import { ClickAwayListener, Popover, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import Text from './Text';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	container: {
		border: `1px solid ${theme.colors.lightGrey5}`,
		width: 'fit-content',
		borderRadius: 36,
		padding: '4px 8px',
		display: 'flex',
	},
	color_dot: {
		height: 18,
		width: 18,
		borderRadius: 9,
	},
}));

function ColorPicker({ color, set_color, disabled }) {
	const classes = useStyles();
	const [show_picker, set_show_picker] = useState(false);
	const container_ref = useRef(null);

	function alpha_to_hex(alpha) {
		alpha = Math.min(1, Math.max(0, alpha));
		const alpha_hex = Math.round(alpha * 255)
			.toString(16)
			.toUpperCase();
		return alpha_hex.length === 1 ? '0' + alpha_hex : alpha_hex;
	}

	const handle_set_color = (color) => {
		const _color = color.hex + alpha_to_hex(color.rgb.a);
		set_color(_color.toUpperCase());
	};

	return (
		<>
			<div
				className={classes.container}
				ref={container_ref}
				onClick={() => {
					if (!disabled) set_show_picker(true);
				}}>
				<div
					className={classes.color_dot}
					style={{ backgroundColor: color, border: `${color === '#fff' || color === '#ffffff' ? 1 : 0}px solid ${theme.colors.lightGrey5}` }}></div>
				<Text bold>{color}</Text>
			</div>
			<Popover
				open={show_picker}
				anchorEl={container_ref.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<ClickAwayListener onClickAway={() => set_show_picker(false)}>
					<SketchPicker disabled={disabled} styles={{ width: 240 }} color={color} onChangeComplete={handle_set_color} />
				</ClickAwayListener>
			</Popover>
		</>
	);
}

export default ColorPicker;
