import { makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import Toast from 'modules/Toast';
import React, { useEffect, useRef, useState } from 'react';
import Routes from 'resources/Routes';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';

let map = null;
const MENU = {
	unallocated: 'unallocated',
	active: 'active',
	compare: 'compare',
	add: 'add',
};
const useStyles = makeStyles((theme) => ({
	map_wrap: { width: '100%', height: '100%', position: 'relative' },
	toast_wrap: { position: 'absolute', top: 10, zIndex: 2, display: 'flex', width: '70vw' },
	trip_wrap: {
		position: 'absolute',
		top: 60,
		flex: 1,
		zIndex: 2,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '50%',
		marginLeft: '25%',
		backgroundColor: theme.colors.white,
		padding: 12,
	},
}));

function AddRouteMap({ active_pbid_list, selected_bundle_list, refresh_trips, selected_ids_list_data, count, last_updated_bundle, selected_menu, history }) {
	const [bundle_data, set_bundle_data] = useState([]);
	const [refresh_map, set_refresh_map] = useState(false);
	const [timer, set_timer] = useState(30);
	const timer_id = useRef(null);
	const classes = useStyles();
	const theme = useTheme();

	useEffect(() => {
		timer_id.current = setInterval(() => {
			if (timer > 0) set_timer((prev) => prev - 1);
		}, 1000);
		if (timer === 0) {
			clearInterval(timer_id.current);
		}
		return () => clearInterval(timer_id.current);
	}, []);

	useEffect(() => {
		let promises = [];
		_.forEach(
			_.filter(selected_bundle_list, (value) => {
				return value !== '';
			}),
			(bundle_id) => {
				promises.push(api_requests.get_bundles_data(bundle_id));
			},
		);
		Promise.all(promises).then((res) => {
			set_bundle_data(
				_.map(res, (bundle, key) => {
					return bundle.data;
				}),
			);
		});
	}, [selected_bundle_list, refresh_trips, refresh_map]);

	useEffect(() => {
		if (_.isEmpty(selected_ids_list_data)) {
			map = new window.google.maps.Map(document.getElementById('map_add_canvas_address'), {
				center: { lat: 28.437111, lng: 77.074631 },
				zoom: 10,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});
			return;
		}

		map = new window.google.maps.Map(document.getElementById('map_add_canvas_address'), {
			center: {
				lat: Number(
					_.reduce(
						_.map(selected_ids_list_data, (data) => {
							return data.drop_latitude;
						}),
						(acc, itr) => {
							return acc + itr;
						},
					) / selected_ids_list_data.length,
				),
				lng:
					_.reduce(
						_.map(selected_ids_list_data, (data) => {
							return data.drop_longitude;
						}),
						(acc, itr) => {
							return acc + itr;
						},
					) / selected_ids_list_data.length,
			},
			zoom: 11,
			clickableIcons: false,
			disableDefaultUI: true,
			scaleControl: true,
			zoomControl: true,
			mapId: constant.MAP_DESIGN_ID,
		});

		_.forEach(
			_.filter(bundle_data, (bundle) => {
				return _.get(bundle, 'tripData').length > 0;
			}),
			(bundle_data, key) => {
				const pick_up_icon = {
					url: ImageLinks.rider_pickup, // url
					scaledSize: new window.google.maps.Size(32, 32), // scaled size
				};

				const icon = {
					url: ImageLinks['marker_color_' + (_.indexOf(selected_bundle_list, bundle_data.bundle_id) + 1)], // url
					scaledSize: new window.google.maps.Size(32, 32), // scaled size
				};

				new window.google.maps.Marker({
					position: { lat: Number(bundle_data.tripData[0].pickup_address.latitude), lng: Number(bundle_data.tripData[0].pickup_address.longitude) },
					icon: pick_up_icon,
					map,
				});

				let marker;
				_.forEach(bundle_data.tripData, (val, index) => {
					marker = new window.google.maps.Marker({
						position: { lat: Number(val.drop_address.latitude), lng: Number(val.drop_address.longitude) },
						label: { text: `${index + 1}`, color: 'white' },
						icon: icon,
						map,
					});
				});

				const flightPlanCoordinates = [
					{ lat: Number(bundle_data.tripData[0].pickup_address.latitude), lng: Number(bundle_data.tripData[0].pickup_address.longitude) },
					..._.map(bundle_data.tripData, (val, key) => {
						return { lat: Number(val.drop_address.latitude), lng: Number(val.drop_address.longitude) };
					}),
				];

				const flightPath = new window.google.maps.Polyline({
					path: flightPlanCoordinates,
					geodesic: true,
					strokeColor: theme.colors['compareMapColor' + (_.indexOf(selected_bundle_list, bundle_data.bundle_id) + 1)],
					strokeOpacity: 1.0,
					strokeWeight: 2,
				});

				flightPath.setMap(map);
			},
		);

		let marker_2;
		const icon_triangle = {
			url: ImageLinks.purple_circle, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};
		//render markers for the drop points of currently active list of points
		_.forEach(_.slice(selected_ids_list_data, count * 5, count * 5 + 5), (data, index) => {
			marker_2 = new window.google.maps.Marker({
				position: { lat: Number(data.drop_latitude), lng: Number(data.drop_longitude) },
				label: { text: `${index + 1}`, color: 'white' },
				icon: icon_triangle,
				map,
				opacity: _.includes(active_pbid_list, data.mcp_id) ? 1 : 0.5,
			});
		});
	}, [bundle_data, active_pbid_list]);
	return (
		<div className={classes.map_wrap}>
			{timer > 0 && active_pbid_list.length === 0 && selected_bundle_list.join('') === '' && (
				<div
					className={classes.trip_wrap}
					style={{ display: 'block', top: 20, textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '8px' }}>
					<Text semi style={{ fontSize: 18 }}>
						Select a route to view on map
					</Text>
					<Text style={{ fontSize: 14 }}>Select and drag order(s) to move to route</Text>
				</div>
			)}
			<div id='map_add_canvas_address' className='map-canvas-wrapper rider-location card' style={{ height: '100%' }} />
			{(!_.isEmpty(last_updated_bundle.count) || last_updated_bundle.count > 0) && (
				<div className={classes.toast_wrap}>
					<Toast>
						<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
						<Text component={'span'} semi style={{ marginLeft: '10px', fontSize: 14 }}>
							<Text component={'span'} semi style={{ color: theme.colors.primary, fontSize: 14 }}>
								{last_updated_bundle.count} order(s)
							</Text>{' '}
							moved successfully.{' '}
						</Text>
					</Toast>
				</div>
			)}
		</div>
	);
}

export default AddRouteMap;
