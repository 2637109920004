import Routes from 'resources/Routes';
import { PICKUP_ADDRESS_FILTERS } from 'views/ManagePickupAddress/ManagePickupAddress';
import { SETTINGS_MENU } from '../OrderSettings/OrderSettings';

export const cardDataCol1 = [
	{
		name: 'Business Setup',
		url: 'suitcase',
		children: [
			// {
			// 	name: 'KYC',
			// 	locked: true,
			// },
			// {
			// 	name: 'Billing Details',
			// 	locked: true,
			// },
			{
				name: 'Enterprise',
				path: Routes.CHILD_BUSINESS.path,
				key: 'enterprise',
			},
		],
	},
	// {
	// 	name: 'COD Setup',
	// 	url: 'rupee_circle_black',
	// 	children: [
	// 		{
	// 			name: 'Bank Setup',
	// 			locked: true,
	// 		},
	// 		{
	// 			name: 'Preferences',
	// 			locked: true,
	// 		},
	// 	],
	// },
	{
		name: 'Locations & Addresses',
		url: 'location_pin',
		children: [
			{
				name: 'Zones',
				path: Routes.ZONES.path,
			},
			{
				name: 'Hubs',
				path: Routes.MANGE_HUB.path,
			},
			{
				name: 'Saved Addresses',
				path: Routes.MANGE_PICKUP_ADDRESS.path + `?filter=${PICKUP_ADDRESS_FILTERS.pickup}`,
			},
		],
	},
];

export const cardDataCol2 = [
	{
		name: 'General',
		url: 'settings_selected_black',
		children: [
			{
				name: 'Order Settings',
				path: Routes.ORDER_SETTINGS.path + `?type=${SETTINGS_MENU.geo_location.id}`,
			},
			{
				name: 'Delivery Executive Settings',
				path: Routes.RIDER_SETTINGS.path + `?type=reprioritize`,
			},
			{
				name: 'Whitelabelling',
				path: Routes.WHITELABELLING_SETTINGS.path + `?type=brand_color_logo`,
			},

			// {
			// 	name: 'Customer Settings',
			// 	locked: true,
			// },
		],
	},
	{
		name: 'Smart Configurations',
		url: 'auto_manifest_no_circle',
		children: [
			{
				name: 'Smart Allocation',
				path: Routes.SMART_ALLOCATION.path,
			},
			{
				name: 'Auto Manifest',
				path: Routes.SMART_MANIFEST.path,
			},
		],
	},
	{
		name: 'Channel Integration',
		url: 'integration_icon',
		path: Routes.INTEGRATION_MANAGEMENT.path,
	},
	{
		name: 'Alerts',
		url: 'alert_triangle',
		path: Routes.MY_ALERTS.path,
	},
	{
		name: 'Communications',
		url: 'thought_bubble',
		path: Routes.CREATE_COMMUNICATION.path,
	},
];

export const cardDataCol3 = [
	{
		name: 'Users & Permissions',
		url: 'user_black',
		children: [
			{
				name: 'Users',
				path: Routes.USER_MANAGEMENT.path,
			},
			{
				name: 'Teams',
				path: `${Routes.USER_MANAGEMENT.path}?tab=teams`,
			},
			// {
			// 	name: 'Permissions',
			// 	locked: true,
			// },
		],
	},
	{
		name: 'Other',
		url: 'filter_black_2',
		children: [
			{
				name: 'Terms & Conditions',
				path: 'https://pidge.in/terms-of-use/',
				is_absolute_path: true,
			},
			{
				name: 'Privacy Policy',
				path: 'https://pidge.in/privacy-policy/',
				is_absolute_path: true,
			},
			{
				name: 'Delivery Terms',
				path: 'https://pidge.in/delivery-service-terms/',
				is_absolute_path: true,
			},
		],
	},
	// {
	// 	name: 'Product Tour',
	// 	url: 'filter_black_2',
	// 	children: [
	// 		{
	// 			name: 'Tutorial Videos',
	// 			locked: true,
	// 		},
	// 		{
	// 			name: 'Knowledge base',
	// 			locked: true,
	// 		},
	// 	],
	// },
];
