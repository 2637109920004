import Text from 'modules/Text';
import _ from 'lodash';
import React from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	container: {
		padding: 30,
		background: 'white',
		width: 600,
		height: 650,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_wrap: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	textInput: { fontSize: 24 },
	revoke_btn: {
		width: 96,
		height: 26,
		borderRadius: 100,
		padding: '6px 8px',
		backgroundColor: theme.colors.lightRed,
		cursor: 'pointer',
	},
	revoke_btn_text: {
		fontSize: 12,
		color: theme.colors.red,
		marginLeft: 4,
	},
	item_wrap: {
		height: 75,
		padding: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	revoke_btn_red: {
		color: theme.colors.red,
		borderColor: theme.colors.red,
		'&:hover': {
			background: `${theme.colors.lightRed} !important`,
			boxShadow: '0px 4px 20px 0px rgba(102, 102, 102, 0.20) !important',
		},
	},
}));

const SentInvitesModal = ({ sent_invites, revoke_invite, close }) => {
	const classes = useStyles();

	const handleClose = () => {
		close();
	};

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className={classes.header_wrap}>
					<Text bold className={classes.textInput}>
						Pending Invites
					</Text>
					<img width={16} height={16} src={ImageLinks.crossBlack} onClick={handleClose} style={{ cursor: 'pointer' }} />
				</div>

				<div style={{ marginTop: 23, overflow: 'scroll' }}>
					{_.map(sent_invites, (invite, key) => (
						<div key={`invites_${key}`} className={classes.item_wrap}>
							<div>
								<Text medium style={{ fontSize: 16 }}>
									{`${invite.name} (${invite.id})`}
								</Text>
								<Text medium style={{ fontSize: 12, color: theme.colors.lightGrey6, marginTop: 2 }}>
									{`${invite.email}| ${invite.mobile}`}
								</Text>
							</div>
							<Button
								size='medium'
								type='outlined'
								text='Revoke Invite'
								onClick={() => revoke_invite(invite)}
								className={classes.revoke_btn_red}
							/>
						</div>
					))}
				</div>
			</div>
		</Modal>
	);
};

export default SentInvitesModal;
