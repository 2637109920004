import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import utils from 'resources/utils';

const useRepeatOrders = () => {
	const history = useHistory();
	const [show_items_in_processing, set_show_items_in_processing] = useState(false);
	const [repeat_order_result_data, set_repeat_order_result_data] = useState({});
	const [csv_files_details, set_csv_files_details] = useState([]);
	// steps value
	// steps -> 2 this is used to give option to select delivery date
	// steps -> 3 this is used to give option to select Delivery Slot
	// steps -> 4 this is used to give option to select upload file
	// steps -> 5 this is used to show results
	const [steps, set_steps] = useState(2);
	const [data_search_key, set_data_search_key] = useState('customer_detail.mobile');
	const [delivery_slot, set_delivery_slot] = useState([]);
	const [delivery_date, set_delivery_date] = useState('');
	const [uploaded_csv_file, set_uploaded_csv_file] = useState({});
	const [selected_delivery_slot_value, set_selected_delivery_slot_value] = useState(null);
	const [upload_csv_status, set_upload_csv_status] = useState(constant.BTN_STATE.DEFAULT);
	const [show_full_button, set_show_full_button] = useState(false);
	const [progress, set_progress] = useState(0);
	const [copy_set_a_values, set_copy_set_a_values] = useState([]);
	const [copy_set_b_values, set_copy_set_b_values] = useState([]);
	const [in_locked_state, set_in_locked_state] = useState(false);
	const [is_set_a_moved, set_is_set_a_moved] = useState(false);
	const [is_set_b_moved, set_is_set_b_moved] = useState(false);
	const [no_of_orders, set_no_of_orders] = useState(null);
	const set_time_id = useRef(null);
	const repeat_order_id = useRef(null);

	const handle_item_processing_details = () => {
		set_show_full_button((prev) => !prev);
		set_show_items_in_processing((prev) => !prev);
	};

	useEffect(() => {
		if (!_.isEmpty(repeat_order_id.current)) {
			my_repeat_order_function();
		} else {
			clearTimeout(set_time_id.current);
		}
		return () => clearTimeout(set_time_id.current);
	}, [repeat_order_id.current]);

	const clear_data = () => {
		set_repeat_order_result_data({});
		set_uploaded_csv_file({});
		set_upload_csv_status(constant.BTN_STATE.DEFAULT);
		set_progress(0);
		set_copy_set_a_values([]);
		set_copy_set_b_values([]);
		set_is_set_a_moved(false);
		set_is_set_b_moved(false);
		repeat_order_id.current = null;
		clearTimeout(set_time_id.current);
	};

	const my_repeat_order_function = async () => {
		try {
			const res = await api_requests.repeat_order_details(repeat_order_id.current);
			set_repeat_order_result_data(res.data);
			if (res.data.status !== 3) {
				set_time_id.current = setTimeout(() => my_repeat_order_function(), 400);
			}
		} catch (err) {}
	};

	const handle_show_items = () => {
		set_show_items_in_processing((prev) => !prev);
	};

	const handle_processing_button = () => {
		set_show_full_button(true);
		handle_show_items();
		repeat_file_details();
	};

	const repeat_file_details = async () => {
		try {
			const response = await api_requests.get_repeat_order_file_data();
			if (response.data) {
				set_csv_files_details(response.data.files);
			}
		} catch (err) {}
	};

	const get_delivery_slots = async (val) => {
		if (!val) {
			set_delivery_slot([]);
			return;
		}
		const format_date = moment(val).format('YYYY-MM-DD');
		try {
			const url = 'store/bop/filter/delivery-slots';
			let base_url = constant.BASE_URL.STORE;
			const response = await api_requests.get_filter_delivery_slots(
				{
					delivery_date: format_date,
					group: 'completed',
				},
				url,
				base_url,
			);
			if (response.data) {
				set_delivery_slot(response.data.items);
				set_steps(3);
			} else {
				set_delivery_slot([]);
			}
		} catch (err) {}
	};

	const handle_repeat_order_csv = async (correct_num, incorrect_num, fileName, is_format_invalid) => {
		// repeat_order_id.current = null;
		// set_copy_set_a_values([]);
		// set_copy_set_b_values([]);
		// clearTimeout(set_time_id.current);
		set_in_locked_state(true);
		try {
			set_upload_csv_status(constant.BTN_STATE.LOADING);
			set_progress(0);
			setTimeout(() => {
				set_progress(20);
			}, 200);
			setTimeout(() => {
				set_progress(40);
			}, 400);
			setTimeout(() => {
				set_progress(60);
			}, 600);
			setTimeout(() => {
				set_progress(80);
			}, 800);
			setTimeout(() => {
				set_progress(100);
			}, 1000);

			if (is_format_invalid || (correct_num.length === 0 && incorrect_num.length === 0)) {
				set_upload_csv_status(constant.BTN_STATE.FAILED);
				return;
			}
			const data = {
				filename: fileName,
				search_key: data_search_key,
				delivery_date: moment(delivery_date).format('YYYY-MM-DD'),
				delivery_slot: selected_delivery_slot_value,
				data: correct_num,
				...(!_.isEmpty(incorrect_num) && { error_data: incorrect_num }),
			};
			const response = await api_requests.upload_repeat_order_details(data);
			if (response.data) {
				set_upload_csv_status(constant.BTN_STATE.SUCCESS);
				get_file_details(response.data._id);
			} else {
				set_upload_csv_status(constant.BTN_STATE.FAILED);
			}
		} catch (err) {
			set_upload_csv_status(constant.BTN_STATE.FAILED);
		}
	};

	const handle_selected_csv_file = (fileInfo) => {
		clear_data();
		set_show_items_in_processing(false);
		set_show_full_button(false);
		get_file_details(fileInfo._id, true);
	};

	const get_file_details = async (id, already_uploaded = false) => {
		try {
			const res = await api_requests.repeat_order_details(id);
			if (res.data) {
				set_repeat_order_result_data(res.data);
				set_steps(5);
				if (already_uploaded) {
					set_in_locked_state(true);
					set_delivery_slot([{ title: res.data.query.delivery_slot, value: res.data.query.delivery_slot }]);
					set_delivery_date(new Date(res.data.query.delivery_date));
					set_selected_delivery_slot_value(res.data.query.delivery_slot);
					set_upload_csv_status(constant.BTN_STATE.SUCCESS);
					set_progress(100);
					const total_count = _.get(res, 'data.total_cnt', 0) + _.get(res, 'data.error_data_cnt', 0);
					set_no_of_orders(total_count);
					set_uploaded_csv_file({
						name: res.data.filename,
					});
				}
				if (res.data.status != 3) repeat_order_id.current = id;
			}
		} catch (err) {}
	};

	const handle_download = async (set_value) => {
		const id = _.get(repeat_order_result_data, '_id', '');
		if (!id) return;
		let file_name = 'repeat-order.csv';
		switch (set_value) {
			case 'set_a':
				file_name = 'criteria entries.csv';
				break;
			case 'set_b':
				file_name = 'non criteria entries.csv';
				break;
			case 'set_c':
				file_name = 'fresh entries.csv';
				break;
			case 'error_data':
				file_name = 'error entries.csv';
				break;

			default:
				break;
		}
		try {
			const response = await api_requests.download_repeat_order_result_details(id, {
				set_type: set_value,
			});
			utils.downloadBlob(response, file_name, 'text/csv');
		} catch (err) {}
	};

	const remove_csv = () => {
		set_upload_csv_status(constant.BTN_STATE.DEFAULT);
		set_in_locked_state(false);
		set_uploaded_csv_file({});
		set_steps(2);
		set_delivery_slot([]);
		set_delivery_date('');
		set_selected_delivery_slot_value(null);
		set_repeat_order_result_data({});
		set_progress(0);
		set_copy_set_a_values([]);
		set_copy_set_b_values([]);
		set_is_set_a_moved(false);
		set_is_set_b_moved(false);
		repeat_order_id.current = null;
		clearTimeout(set_time_id.current);
	};

	const handle_order_move = (moved_data) => {
		if (_.isEmpty(moved_data?.copy_set_a)) set_is_set_b_moved(true);
		else set_is_set_a_moved(true);
	};

	const on_back_press = () => {
		history.goBack();
	};

	return {
		history,
		show_items_in_processing,
		show_full_button,
		repeat_order_result_data,
		csv_files_details,
		steps,
		data_search_key,
		delivery_slot,
		selected_delivery_slot_value,
		uploaded_csv_file,
		delivery_date,
		upload_csv_status,
		progress,
		copy_set_a_values,
		copy_set_b_values,
		in_locked_state,
		is_set_a_moved,
		is_set_b_moved,
		no_of_orders,
		set_no_of_orders,
		set_copy_set_a_values,
		set_copy_set_b_values,
		set_repeat_order_result_data,
		set_csv_files_details,
		set_steps,
		set_data_search_key,
		get_delivery_slots,
		set_delivery_slot,
		handle_repeat_order_csv,
		set_selected_delivery_slot_value,
		set_uploaded_csv_file,
		handle_selected_csv_file,
		set_delivery_date,
		set_upload_csv_status,
		set_progress,
		handle_item_processing_details,
		handle_processing_button,
		handle_show_items,
		repeat_file_details,
		handle_download,
		remove_csv,
		clear_data,
		handle_order_move,
		on_back_press,
	};
};

export default useRepeatOrders;
