import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from 'resources/theme';
import Text from 'modules/Text';
import { Divider } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import { nFormatter } from './helper';
import EmptyState from '../EmptyState';

const useStyles = makeStyles({
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
	analytics_container: {
		padding: 12,
		borderRadius: 12,
		background: theme.colors.white,
		boxShadow: '0px 1.0728px 3.21839px rgba(13, 10, 44, 0.08)',
	},
	dot_content: {
		width: 8,
		height: 8,
		borderRadius: '50%',
	},
});

const AttendencePerformance = ({ analytics_data = null }) => {
	const classes = useStyles();

	const labels = _.map(analytics_data, (item_value) => {
		return moment(item_value.date).format('DD-MMM');
	});

	const absent_data = _.map(analytics_data, (item_value) => {
		return item_value.absent;
	});
	const late_login_data = _.map(analytics_data, (item_value) => {
		return item_value.late_login;
	});

	const on_time_login_data = _.map(analytics_data, (item_value) => {
		return item_value.ontime_login;
	});

	const empty_data_state =
		_.reduce(absent_data, (prev, curr) => prev + curr, 0) +
		_.reduce(on_time_login_data, (prev, curr) => prev + curr, 0) +
		_.reduce(late_login_data, (prev, curr) => prev + curr, 0);

	const data = {
		labels: labels,
		datasets: [
			{
				label: 'Absent',
				data: absent_data,
				backgroundColor: theme.colors.lightPurple5,
			},
			{
				label: 'Late Login',
				data: late_login_data,
				backgroundColor: theme.colors.darkGreen1,
			},
			{
				label: 'On time login',
				data: on_time_login_data,
				backgroundColor: theme.colors.lightGreen9,
				borderWidth: 2,
				borderRadius: { topLeft: 4, topRight: 4, bottomLeft: 0, bottomRight: 0 },
				borderSkipped: false,
				borderColor: theme.colors.lightGreen9,
			},
		],
	};

	const config = {
		type: 'bar',
		data: data,
		options: {
			plugins: {
				legend: false,
				datalabels: {
					display: false,
				},
			},
			responsive: true,
			scales: {
				x: {
					grid: {
						display: false,
					},
					ticks: {
						color: theme.colors.darkGrey2,
						font: {
							size: 10,
							family: 'HK Grotesk Bold',
						},
					},
					stacked: true,
				},
				y: {
					beginAtZero: true,
					grid: {
						color: theme.colors.lightGrey12,
					},
					ticks: {
						font: {
							color: theme.colors.darkGrey2,
							size: 8,
							family: 'HK Grotesk Bold',
						},
						callback: nFormatter,
					},
					stacked: true,
				},
			},
		},
	};
	return (
		<div className={classes.analytics_container}>
			<div className='align-items-center justify-content-between'>
				<Text bold style={{ fontSize: 14 }}>
					Attendence Performance
				</Text>
				<div className='d-flex'>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.lightGreen9 }} />
						<Text style={{ fontSize: 12 }}>On Time Login</Text>
					</div>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.darkGreen1 }} />
						<Text style={{ fontSize: 12 }}>Late Login</Text>
					</div>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.lightPurple5 }} />
						<Text style={{ fontSize: 12 }}>Absent</Text>
					</div>
				</div>
			</div>
			<Divider className={classes.divider} />
			<div style={{ width: '100%', height: '100%' }}>{empty_data_state != 0 ? <Bar {...config} /> : <EmptyState />}</div>
		</div>
	);
};

export default AttendencePerformance;
