import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	header: {
		paddingBottom: 10,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: '1px solid lightgrey',
	},
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 600,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 50,
		zIndex: 1200,
		overflowY: 'auto',
		paddingBottom: 120,
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
	},
	ndr_accordion_details_root: {
		'&.MuiAccordionDetails-root': {
			paddingBottom: 12,
		},
	},
	ndr_accordion_summary_root: {
		'&.MuiAccordionSummary-root': {
			width: 486,
		},
	},
	edit_btn: {
		width: 60,
		height: 26,
		borderRadius: 20,
		border: 'none',
		marginLeft: 'auto',
	},
	edit_btn_content: {
		display: 'flex',
		gap: 7,
	},
	place_info_wrap: {
		borderRadius: 10,
		backgroundColor: theme.colors.lightGrey7,
		height: 80,
		marginTop: 10,
		padding: '16px 30px 16px 30px',
	},
	place_sub_info_wrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	date_time_text: { fontSize: 12, color: theme.colors.primary },
	am_pm_text: { fontSize: 8, color: theme.colors.primary, alignSelf: 'flex-end', paddingBottom: 1 },
	view_prod_wrap: {
		marginTop: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	view_prod_text: { fontSize: 12, color: theme.colors.primary, marginRight: 5 },
	section_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 18,
		marginBottom: 4,
		height: 20,
	},
	section_heading_wrap: {
		display: 'flex',
		alignItems: 'center',
	},
	section_heading_text: { fontSize: 14, color: theme.colors.darkGrey2, marginRight: 5 },
	volume_wrap: {
		height: 22,
		borderRadius: 6,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.colors.lightPink,
		marginLeft: 3,
		marginRight: 5,
		paddingLeft: 8,
		paddingRight: 8,
	},
	volume_text: { fontSize: 12, color: theme.colors.darkPurple },
	section_box: {
		height: 70,
		borderRadius: 8,
		padding: '12px 20px 12px 20px',
		display: 'flex',
		alignItems: 'center',
	},
	pkg_detail_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	lbh_wrap: {
		flex: 0.5,
		marginLeft: 25,
	},
	pkg_detail_item_label: { fontSize: 12, color: theme.colors.darkGrey2 },
	weight_wrap: {
		flex: 0.5,
		marginLeft: 25,
		maxWidth: 100,
	},
	lbh_input_wrap: {
		display: 'flex',
		alignItems: 'center',
	},
	weight_input_wrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	measure_select: {
		border: `1px solid ${theme.colors.lightPurple}`,
		borderRadius: 4,
		width: 47,
		height: 24,
		paddingLeft: 3,
		paddingRight: 3,
	},
	save_btn: {
		height: 18,
		borderRadius: 20,
		border: `1px solid ${theme.colors.green}`,
	},
	save_btn_text: { fontSize: 10, color: theme.colors.green, marginLeft: 4 },
	order_amnt_wrap: {
		display: 'flex',
		marginLeft: 25,
		marginRight: 40,
	},
	amount_label: { fontSize: 12, color: theme.colors.darkGrey2, marginBottom: 8 },
	header_text: { fontSize: 20, color: theme.colors.primary },
	state_name: { fontSize: 16, color: theme.colors.darkGrey2, marginLeft: 5, paddingBottom: 5 },
	flex_center: {
		display: 'flex',
		alignItems: 'center',
	},
	sender_header_wrap: {
		paddingRight: 0,
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 70,
		borderRadius: 8,
		padding: '12px 20px 12px 20px',
	},
	update_btn: {
		height: 28,
		borderRadius: 30,
		marginRight: 10,
		boxShadow: 'none',
	},
	update_btn_text: { fontSize: 12, color: theme.colors.white },
	payment_btn: {
		height: 26,
		borderRadius: 100,
		boxShadow: 'none',
	},

	wrapper: { marginTop: 15, flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' },
	header_btn_wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		position: 'absolute',
		top: 40,
		right: 30,
	},
	ongoingOrdersDiv: {
		display: 'flex',
		alignItems: 'center',
		height: 55,
		marginTop: 8,
	},
	detailsTextContainer: {
		position: 'relative',
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
	},
	pickedUpContainer: {
		overflowY: 'auto',
		flex: 1,
		marginTop: 10,
		marginBottom: 10,
	},
	pickedUpItem: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
		height: 55,
	},
	orderDetailsClickabletext: {
		flex: 1,
		maxWidth: '80%',
		display: 'inline-block',
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
	purple_dot: {
		width: '8px',
		height: '8px',
		margin: '5px',
		backgroundColor: theme.colors.primary,
		display: 'inline-block',
		borderRadius: '50%',
	},

	yellow_dot: {
		width: '8px',
		height: '8px',
		margin: '5px',
		backgroundColor: theme.colors.darkYellow,
		display: 'inline-block',
		borderRadius: '50%',
	},

	setPageContainer: { display: 'flex', alignItems: 'center' },
	truncate: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 16,
	},
	header_title: { flex: 1, color: theme.colors.darkGrey2, fontSize: 14 },
	table_item_wrap_2: { flex: 1, minWidth: 0 },

	autoIcon: {
		width: 35,
		height: 35,
		marginLeft: 10,
		backgroundColor: theme.colors.white,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
			'& .tooltip': {
				display: 'flex !important',
			},
		},
	},
	subtitle: { fontSize: 12, color: theme.colors.lightGrey6, marginTop: 2 },
	ship_order_btn: { marginTop: -8 },
	add_btn: {
		marginLeft: 7,
		marginTop: -8,
	},
	sync_order_text: {
		fontSize: 12,
		color: theme.colors.darkPurple,
		marginLeft: -5,
	},
	pkgs_detail_wrap: {
		borderRadius: 8,
		padding: '12px 20px 12px 20px',
	},
	all_pkgs_wrap: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 5,
		marginBottom: 5,
	},
	all_pkgs_text: { marginRight: 5, fontSize: 14, color: theme.colors.lightPurple7, textDecoration: 'underline' },
	pkg_item: { display: 'flex', alignItems: 'center', marginTop: 10 },
	footer: {
		height: 100,
		width: 600,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 40,
		paddingRight: 40,
		boxShadow: '0px -4px 14px 0px rgba(0, 0, 0, 0.05)',
		display: 'flex',
		justifyContent: 'space-between',
	},
	footer_content: {
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			'& .red-delete': {
				display: 'flex !important',
				alignItems: 'center',
				cursor: 'pointer',
			},
			'& .pink-delete': {
				display: 'none',
			},
		},
	},
	modal_text: {
		fontSize: 16,
		textAlign: 'left',
		color: theme.colors.red,
	},
	process_order_inactive: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	bulk_action_wrapper: {
		position: 'absolute',
		right: 0,
		zIndex: 1198,
	},
	wrapper_help_center: { position: 'fixed', bottom: 30, left: '50%', zIndex: 100 },
}));

export default useStyles;
