import { ListItem, makeStyles, Radio } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import OutlinedButton from 'modules/OutlinedButton';
import SelectWithInput from 'modules/SelectWithInput';
import Text from 'modules/Text';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import theme from 'resources/theme';
import { AVAILABLE_RIDER_TYPE, TYPES } from '../helper';

const useStyles = makeStyles(() => ({
	manual_wrap: {
		borderTop: `1px solid ${theme.colors.lightGrey5}`,
		paddingTop: 10,
		marginTop: 10,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	pool_options: { marginTop: 20, display: 'flex', justifyContent: 'space-between', marginLeft: 45 },
	rider_list: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 20, paddingTop: 0 },
	search_list: {
		height: '100%',
		overflowY: 'auto',
		paddingBottom: 10,
		marginTop: 5,
	},
	rider_row: {
		padding: 8,
		marginTop: 5,
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: 5,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.5)',
		'&:hover': {
			backgroundColor: theme.colors.lightGrey4,
		},
	},
	info_container: {
		'&:hover > $detailed_info_container': {
			display: 'block',
		},
	},
	detailed_info_container: {
		display: 'none',
		width: 290,
		height: 90,
		background: theme.colors.lightPurple8,
		position: 'absolute',
		top: 30,
		right: -30,
		borderRadius: 10,
		padding: 10,
		zIndex: 2000,
	},
	icon_wrap: {
		width: 40,
		height: 40,
		borderRadius: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const MENU = {
	unallocated: 'unallocated',
	active: 'active',
	compare: 'compare',
	add: 'add',
};

const BusinessRiders = ({
	type,
	handle_set_type,
	search_rider_text,
	set_search_rider_text,
	set_available_rider_type,
	search_riders_captive,
	set_search_riders_captive,
	selected_rider_captive,
	set_selected_rider_captive,
	available_rider_type,
	rider_id,
	selected_menu,
}) => {
	const classes = useStyles();
	const history = useHistory();
	useEffect(() => {
		if (search_riders_captive && search_rider_text) {
		}
	}, []);
	return (
		<ListItem
			className='box-shadow'
			button={type !== TYPES.manual}
			onClick={() => {
				if (type !== TYPES.manual) handle_set_type(TYPES.manual);
			}}
			style={{
				padding: '14px 12px',
				borderRadius: 10,
				alignItems: 'flex-start',
				border: `1px solid ${type === TYPES.manual ? theme.colors.primary : 'transparent'}`,
				flexDirection: 'column',
				marginTop: 20,
			}}>
			<div className={'align-items-center'}>
				<div className={classes.icon_wrap} style={{ border: `0.43px solid ${theme.colors.lightPurple6}` }}>
					<img src={ImageLinks.rider_light_purple3} width={20} height={20} />
				</div>
				<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
					Business Riders
				</Text>
			</div>

			{type === TYPES.manual && (
				<div style={{ height: 210, width: '100%', display: 'flex', flexDirection: 'column' }}>
					<div className={classes.manual_wrap}>
						<SelectWithInput
							disabled={rider_id && selected_menu != MENU.active ? true : false}
							value={search_rider_text ? search_rider_text : 'Search Rider Name, ID'}
							inputValue={search_rider_text}
							show_arrow={false}
							onChange={(text) => set_search_rider_text(text)}
							onClick={() => {
								if (!rider_id) {
									set_available_rider_type(null);
									set_search_riders_captive([]);
								}
							}}
							right_icon={
								search_rider_text && !rider_id ? (
									<img
										onClick={() => {
											if (!rider_id) {
												set_selected_rider_captive(null);
												set_search_rider_text(null);
											}
										}}
										src={ImageLinks.cross_filled_purple}
										style={{ width: 16, height: 16 }}
										alt=''
									/>
								) : (
									<img src={ImageLinks.search_light_purple} width={16} height={16} alt='' />
								)
							}
							mainContainerStyles={{ height: 40, width: 200, marginRight: 20 }}
							containerStyles={{ borderColor: theme.colors.lightPurple3, width: 200 }}
						/>

						<div style={{ display: 'flex' }}>
							<OutlinedButton
								onClick={() => {
									set_available_rider_type(AVAILABLE_RIDER_TYPE.available);
								}}
								disabled={rider_id && selected_menu != MENU.active}
								style={{
									height: 30,
									...(available_rider_type === AVAILABLE_RIDER_TYPE.available && {
										backgroundColor: theme.colors.primary,
										color: theme.colors.white,
									}),
								}}>
								<Text>Available</Text>
							</OutlinedButton>
							<OutlinedButton
								onClick={() => {
									set_available_rider_type(AVAILABLE_RIDER_TYPE.lowest_cost);
									set_search_riders_captive([]);
								}}
								disabled={rider_id && selected_menu != MENU.active}
								style={{
									height: 30,
									marginLeft: 10,
									...(available_rider_type === AVAILABLE_RIDER_TYPE.lowest_cost && {
										backgroundColor: theme.colors.primary,
										color: theme.colors.white,
									}),
								}}>
								<Text>Lowest Cost</Text>
							</OutlinedButton>
						</div>
					</div>
					<div style={{ flex: 1, overflowY: 'hidden' }}>
						{!_.isEmpty(search_riders_captive) && (
							<div className={classes.search_list}>
								{_.map(search_riders_captive, (rider, key) => {
									return (
										<ListItem
											button
											key={`rider${key}`}
											className={classes.rider_row}
											style={{
												...(selected_rider_captive === rider.rider_id && {
													border: `1px solid ${theme.colors.primary}`,
													background: theme.colors.lightPurple,
												}),
											}}
											onClick={() => {
												set_selected_rider_captive(rider.rider_id);
											}}>
											<Text semi style={{ flex: 3, fontSize: 12 }}>
												{rider.rider_name} ({rider.rider_id})
											</Text>
											<div style={{ marginLeft: 30 }}>
												<Text semi style={{ fontSize: 12 }}>
													-
												</Text>
												<Text medium style={{ fontSize: 8, color: theme.colors.lightGrey6 }}>
													Charges
												</Text>
											</div>
										</ListItem>
									);
								})}
							</div>
						)}
						{_.isEmpty(search_riders_captive) && available_rider_type !== AVAILABLE_RIDER_TYPE.available && _.isEmpty(search_rider_text) && (
							<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<div style={{ background: theme.colors.lightPurple8, width: 350, height: 90, borderRadius: 10, padding: 10, display: 'flex' }}>
									<img src={ImageLinks.rider_light_purple2} alt='' width={30} />
									<div style={{ marginLeft: 10 }}>
										<Text semi style={{ color: theme.colors.primary }}>
											Self Fulfill Orders with your Fleet
										</Text>
										<Text medium style={{ color: theme.colors.primary, marginTop: 5, fontSize: 10 }}>
											Allocate to your onboarded riders, choose from your nearest available executives, or use Pidge’s unique technology
											to automatically assign to the lowest cost delivery option!
										</Text>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</ListItem>
	);
};

export default BusinessRiders;
