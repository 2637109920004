import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import theme from 'resources/theme';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from 'views/PoweredWithPidge/helper';

const Success = () => {
	const history = useHistory();
	return (
		<div>
			<div
				style={{
					boxShadow: '0px -4px 14px rgba(0, 0, 0, 0.05)',
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
					background: theme.colors.white,
					padding: '20px 35px',
				}}>
				<img src={ImageLinks.tickCircleSolid} alt='' />
				<Text semi style={{ fontSize: 16, marginLeft: 20, color: theme.colors.green }}>
					Allocation Successful!
				</Text>
				<div style={{ flex: 1 }}>
					<ContainedButton
						style={{ marginLeft: 15, borderRadius: 40, height: 50, float: 'right' }}
						onClick={() => {
							history.push(Routes.POWERED_WITH_PIDGE.path + '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.OnGround.id);
						}}>
						<Text medium style={{ fontSize: 18 }}>
							Track Order(s)
						</Text>
					</ContainedButton>
				</div>
			</div>
		</div>
	);
};

export default Success;
