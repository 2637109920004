import _ from 'lodash';
import React from 'react';
import { makeStyles, Modal } from '@material-ui/core';

import Text from 'modules/Text';
import cross from 'assets/images/icons/cross.svg';
import ContainedButton from 'modules/ContainedButton';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import AddressModalMapComp from 'modules/AddressModalMapComp';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 20,
		background: 'white',
		width: 650,
		height: 680,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	innerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	editTextContainer: {
		display: 'flex',
		alignItems: 'center',
		height: 30,
	},
	textInput: {
		fontSize: 24,
	},
	mapContainer: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
	searchBoxContainer: {
		border: '1px solid #C4C4C4',
		borderRadius: 46,
		height: 42,
		display: 'flex',
		padding: '0px 20px',
		marginTop: 16,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	mapImageContainer: { flex: 1, marginTop: 16, borderRadius: 46 },
	addressContainer: { marginTop: 20, marginBottom: 35 },
	reachInstructionsContainer: { display: 'flex', justifyContent: 'space-between', marginTop: 30 },
	containedBtnContainer: { width: 110, margin: '0 auto' },
}));

const RiderAddress = ({ close, on_address_select, address_type, zone_id, edit_details, title = 'Rider Address' }) => {
	const zones = useSelector((state) => state.app.zones);
	const [selected_place, set_selected_place] = React.useState(_.get(edit_details, 'selected_place', {}));
	const [address_line1, set_address_line1] = React.useState(_.get(edit_details, 'address_line1', ''));
	const [pincode, set_pincode] = React.useState(_.get(edit_details, 'selected_place.pincode', ''));
	const [city, set_city] = React.useState(_.get(edit_details, 'selected_place.city', ''));
	const [state, set_state] = React.useState(_.get(edit_details, 'selected_place.state', ''));
	const zone_id_ref = useRef(zone_id);
	const isUpdate = !!edit_details;

	const handle_address_line = (e) => {
		set_address_line1(e.target.value);
	};

	const handleClose = () => {
		close();
	};

	const on_zone_update = (val) => {
		zone_id_ref.current = val;
		set_city('');
		set_state('');
		set_pincode('');
	};

	const confirm_address = async () => {
		const filtered_zone = _.filter(zones, (item) => item.id == zone_id_ref.current)[0];
		const data = {
			address_line1,
			landmark: 'N/A',
			coordinates: {
				latitude: selected_place.geometry.location.lat(),
				longitude: selected_place.geometry.location.lng(),
			},
			city: city,
			state: state,
			pincode,
			formatted_address: selected_place.formatted_address,
			zone: { id: filtered_zone?.id, title: filtered_zone?.name },
		};

		on_address_select(
			{
				selected_place,
				...data,
			},
			address_type,
			edit_details,
		);
		close();
	};

	const classes = useStyles();

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className={classes.innerContainer}>
					<div className={classes.editTextContainer}>
						<Text bold className={classes.textInput}>
							{title}
						</Text>
					</div>
					<img src={cross} onClick={handleClose} style={{ cursor: 'pointer' }} alt='' />
				</div>
				<AddressModalMapComp
					zone_id={zone_id}
					selected_place={selected_place}
					handle_zone_update={(val) => on_zone_update(val)}
					set_selected_place={(place) => {
						const address_components = place.address_components;

						const city = (
							_.find(address_components, (comp) => {
								if (_.includes(comp.types, 'locality')) {
									return true;
								}
							}) || {}
						).long_name;

						const state = (
							_.find(address_components, (comp) => {
								if (_.includes(comp.types, 'administrative_area_level_1')) {
									return true;
								}
							}) || {}
						).long_name;

						const pincode = (
							_.find(address_components, (comp) => {
								if (_.includes(comp.types, 'postal_code')) {
									return true;
								}
							}) || {}
						).long_name;

						set_city(city);
						set_state(state);
						set_pincode(pincode);

						set_selected_place(place);
					}}
				/>
				<div className={classes.addressContainer}>
					<UndlerlineTextField fullWidth={true} label='Address Line*' value={address_line1} onChange={handle_address_line} />
					<div className={classes.reachInstructionsContainer}>
						<UndlerlineTextField
							style={{ width: '30%' }}
							label='City*'
							placeholder='Entery city name'
							value={city}
							onChange={(e) => set_city(e.target.value)}
						/>
						<UndlerlineTextField style={{ width: '30%' }} label='State*' value={state} onChange={(e) => set_state(e.target.value)} />

						<UndlerlineTextField style={{ width: '30%' }} label='Pincode*' value={pincode} onChange={(e) => set_pincode(e.target.value)} />
					</div>
				</div>
				<Button
					type='round'
					text={isUpdate ? 'Update' : 'Next'}
					size='large'
					onClick={confirm_address}
					disabled={_.isEmpty(address_line1) || _.isEmpty(selected_place) || _.isEmpty(city) || _.isEmpty(state) || pincode == ''}
					className={classes.containedBtnContainer}
				/>
			</div>
		</Modal>
	);
};

export default RiderAddress;
