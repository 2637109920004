import Text from 'modules/Text';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import { useState } from 'react';
import OutlinedTextField from 'modules/OutlinedTextField';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	container: {
		padding: 20,
		background: 'white',
		width: 580,
		height: 400,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	header_text: { fontSize: 20 },
	cta_btn: {
		width: 130,
		height: 41,
		borderRadius: 30,
		margin: '0 auto',
		marginTop: 30,
	},
	section: {
		padding: 12,
		marginTop: 16,
		borderRadius: 8,
		backgroundColor: theme.colors.white,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
	},
	section_header: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
	section_separator: { border: `1px solid ${theme.colors.lightGrey5}`, marginTop: 9, marginBottom: 11 },
	keys_item_wrap: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		padding: 8,
		border: `1px solid ${theme.colors.lightPurple}`,
		cursor: 'pointer',
		height: 86,
	},
	checkbox_outer_circle: {
		width: 12,
		height: 12,
		border: `1.71px solid ${theme.colors.lightPurple}`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 6,
		marginTop: 3,
	},
	selected_key_item_wrap: {
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		border: `1px solid ${theme.colors.primary}`,
	},
	checkbox_inner_circle: { width: 6, height: 6, borderRadius: 3, backgroundColor: theme.colors.primary },
	section_title: { fontSize: 14, color: theme.colors.darkGrey2 },
	section_desc: { fontSize: 12, color: theme.colors.lightGrey2 },
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: `0.5px solid ${theme.colors.lightGrey2}`,
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `0.5px solid ${theme.colors.lightGrey2}`,
		},
	},
}));

const limit_keys_items = [
	{ label: 'Number of Routes', subtitle: '', key: 'numberOfRoutes', placeHolder: '..01' },
	{ label: 'Points per route', subtitle: '', key: 'numberOfTrips', placeHolder: '..02' },
	{ label: 'Distance between points', subtitle: 'm', key: 'distance', placeHolder: '..100' },
];

const RouteCriteriaModal = ({ close, handle_custom_grouping }) => {
	const [selected_key, set_selected_key] = useState(null);
	const [ignore_size, set_ignore_size] = useState(false);

	const [limit_keys_data, set_limit_keys_data] = useState({
		numberOfRoutes: '',
		numberOfTrips: '',
		distance: '',
	});
	const classes = useStyles();

	const handle_close = () => {
		close();
	};

	const handle_item_click = (key) => {
		if (key === 'numberOfRoutes') set_ignore_size(true);
		else set_ignore_size(false);
		set_selected_key(key);
		const _limit_keys_data = _.cloneDeep(limit_keys_data);
		for (const state_key in _limit_keys_data) {
			_limit_keys_data[state_key] = state_key === key ? _limit_keys_data[state_key] : '';
		}
		set_limit_keys_data(_limit_keys_data);
	};

	const handle_change = (key, value) => {
		set_limit_keys_data({
			...limit_keys_data,
			[key]: value,
		});
	};

	const handle_create_route = () => {
		if (selected_key === null || _.isEmpty(limit_keys_data[selected_key])) {
			toast.error('Limit key cannot be empty', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}

		const config = {
			[selected_key]: limit_keys_data[selected_key],
			...(ignore_size && { ignoreVolume: ignore_size }),
		};
		handle_custom_grouping(config);
		close();
	};

	const btn_disabled = useMemo(() => {
		let is_disabled = true;
		if (
			!_.isEmpty(selected_key) &&
			(!_.isEmpty(limit_keys_data.numberOfRoutes) || !_.isEmpty(limit_keys_data.numberOfTrips) || !_.isEmpty(limit_keys_data.distance))
		)
			is_disabled = false;
		return is_disabled;
	}, [selected_key, limit_keys_data]);

	return (
		<Modal open={true} onClose={handle_close} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className={classes.header_wrap}>
					<Text bold className={classes.header_text}>
						Route Criteria
					</Text>
					<img src={ImageLinks.crossBlack} onClick={handle_close} style={{ cursor: 'pointer' }} alt='' />
				</div>

				<div className={classes.section}>
					<div className={classes.section_header}>
						<Text bold className={classes.section_title}>
							Limit Keys
						</Text>
						<Text semi className={classes.section_desc}>
							(Max boundary limits for the routing rule)
						</Text>
					</div>

					<div className={classes.section_separator}></div>

					<div style={{ display: 'flex' }}>
						{_.map(limit_keys_items, (item, index) => (
							<div
								onClick={() => handle_item_click(item.key)}
								key={`limit-keys_${index}`}
								className={clsx(classes.keys_item_wrap, item.key === selected_key && classes.selected_key_item_wrap)}
								style={
									index === 1
										? {
												marginLeft: 10,
												marginRight: 10,
										  }
										: {}
								}>
								<div style={{ display: 'flex' }}>
									<div
										className={classes.checkbox_outer_circle}
										style={selected_key === item.key ? { border: `1.71px solid ${theme.colors.primary}` } : {}}>
										{selected_key === item.key && <div className={classes.checkbox_inner_circle}></div>}
									</div>
									<Text semi style={{ fontSize: 14, marginLeft: 4 }}>
										{item.label}
									</Text>
								</div>
								<OutlinedTextField
									placeholder={item.placeHolder}
									className={classes.root}
									InputProps={{
										style: {
											height: 28,
											marginLeft: 12,
											paddingLeft: 0,
											fontSize: 12,
											backgroundColor: theme.colors.white,
										},
										endAdornment: <Text>{item.subtitle}</Text>,
									}}
									style={{ width: 120 }}
									value={limit_keys_data[item.key]}
									onChange={(e) => handle_change(item.key, e.target.value)}
								/>
							</div>
						))}
					</div>
				</div>

				<div className={classes.section}>
					<div className={classes.section_header}>
						<Text bold className={classes.section_title}>
							Ignore Keys
						</Text>
						<Text semi className={classes.section_desc}>
							(Ignores the parameter while running the routing rule)
						</Text>
					</div>
					<div className={classes.section_separator}></div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div
							onClick={() => {
								set_ignore_size((prev) => !prev);
							}}
							style={{
								paddingTop: 4,
								pointerEvents: selected_key === 'numberOfRoutes' ? 'none' : 'auto',
								cursor: selected_key === 'numberOfRoutes' ? 'auto' : 'pointer',
								opacity: selected_key === 'numberOfRoutes' ? 0.4 : 1,
							}}>
							<img src={ignore_size ? ImageLinks.filledCheckbox : ImageLinks.emptyCheckbox} width='14' height='14' style={{ marginRight: 6 }} />
						</div>
						<Text semi style={{ fontSize: 14 }}>
							Size of Order
						</Text>
					</div>
				</div>

				<ContainedButton disabled={btn_disabled} className={classes.cta_btn} onClick={handle_create_route}>
					<Text semi style={{ fontSize: 16, ...(btn_disabled && { color: theme.colors.darkGrey2 }) }}>
						Create Route
					</Text>
				</ContainedButton>
			</div>
		</Modal>
	);
};

export default RouteCriteriaModal;
