import React, { useEffect, useRef, useState } from 'react';
import useStyles from './styles';
import IconButton from 'modules/IconButton';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import { InputAdornment, Slider, TextField, useTheme } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import api_requests from 'resources/api_requests';
import AutoAddMap from './AutoAddMap';
import ProcessingStates from './ProcessingStates';
import _ from 'lodash';
import utils from 'resources/utils';

function AutoAddModal({ modal_close, selected_order_ids, show_auto_add_to_route, set_show_auto_close_confirm }) {
	const [max_cluster_size, set_max_cluster_size] = useState(Math.ceil(Math.min(selected_order_ids.length / 2, 7)));
	const [intra_cluster_distance, set_intra_cluster_distance] = useState(2.5);
	const [centroid_to_route_distance, set_centroid_to_route_distance] = useState(5);
	const [max_size_of_routes, set_max_size_of_routes] = useState(25);
	const [available_route_data, set_available_route_data] = useState('');
	const [request_ids, set_request_ids] = useState([]);
	const [process_started, set_process_started] = useState(false);
	const [progress_data, set_progress_data] = useState([{ processing: [''], processed: [], unprocessed: [] }]);
	const timeout_id = useRef(null);
	const classes = useStyles();
	const theme = useTheme();

	useEffect(() => {
		if (request_ids.length > 0) get_process_order_counts();
	}, [request_ids]);

	useEffect(() => {
		get_route_data();
		return () => clearTimeout(timeout_id.current);
	}, []);

	const handle_add_route = async () => {
		set_process_started(true);
		try {
			const res = await api_requests.auto_add_point_to_existing_routes({
				pbids: selected_order_ids,
				config: {
					max_cluster_size,
					intra_cluster_distance: parseFloat(intra_cluster_distance) * 1000,
					centroid_to_route_distance: parseFloat(centroid_to_route_distance) * 1000,
					max_size_of_routes,
				},
			});
			if (res.success) {
				set_request_ids(res.data);
			}
		} catch (err) {}
	};

	const get_route_data = async () => {
		try {
			const res = await api_requests.get_available_routes_for_auto_add({ pbids: selected_order_ids });
			set_available_route_data(res.data);
		} catch (err) {}
	};

	const get_process_order_counts = async () => {
		try {
			const res = await api_requests.get_process_order_count_for_auto_add({ request_ids: request_ids.join(',') });
			set_progress_data(res.data);
			if (
				_.concat(
					..._.map(res.data, (data) => {
						return data.processing;
					}),
				).length > 0 &&
				show_auto_add_to_route === true
			) {
				timeout_id.current = setTimeout(() => {
					get_process_order_counts();
				}, 2000);
			}
		} catch (err) {}
	};

	const handle_update_input = (e, updater_function, max) => {
		const value = e.target.value;
		const pattern = /^\d+$/;
		if (value == '') updater_function(value);
		if (pattern.test(value)) updater_function(Math.min(parseInt(value), max));
	};

	const reset_modal = () => {
		clearTimeout(timeout_id.current);
		set_request_ids([]);
		set_process_started(false);
		set_progress_data([{ processing: [''], processed: [], unprocessed: [] }]);
	};

	const handle_update_input_one_decimal = (e, updater_function, max) => {
		const value = e.target.value;
		const pattern = /^\d+(\.\d{1})?$/;
		const pattern2 = /^\d+(\.\d{0})?$/;
		if (value == '') updater_function(value);
		if (pattern.test(value)) {
			updater_function(Math.min(parseFloat(value), max));
		} else {
			if (parseFloat(value) === max) {
				updater_function(max);
			} else if (pattern2.test(value)) updater_function(value);
		}
	};

	const handle_close = () => {
		clearTimeout(timeout_id.current);
		if (process_started) {
			set_show_auto_close_confirm(true);
		} else {
			modal_close();
		}
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={handle_close} />
			<div className={classes.container}>
				<div className='d-flex justify-content-between align-items-center'>
					<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
						Auto Add to Routes
					</Text>
					<IconButton onClick={handle_close} style={{ border: 'none' }}>
						<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
					</IconButton>
				</div>
				<div className={classes.divider}></div>
				<div className={classes.stats}>
					<div className={`box-shadow ${classes.stats_box}`}>
						<div className={classes.edit_wrap}>
							<div style={{ flex: 0.5 }}>
								<Text semi style={{ fontSize: 16 }}>
									{selected_order_ids.length}
								</Text>
								<Text semi className={classes.stats_label}>
									Unrouted Points
								</Text>
							</div>
						</div>

						<div className={classes.stats_wrap}>
							<div style={{ flex: 0.8 }}>
								<Text semi style={{ fontSize: 16 }}>
									{available_route_data.routes_available}
								</Text>
								<Text semi className={classes.stats_label}>
									Routes Available
								</Text>
							</div>

							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{`${available_route_data.average_route_size} points`}
								</Text>
								<Text semi className={classes.stats_label}>
									Avg. Points in Route
								</Text>
							</div>
							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{`${available_route_data.average_route_distance}`}
								</Text>
								<Text semi className={classes.stats_label}>
									Avg. Route Distance
								</Text>
							</div>
						</div>
					</div>
				</div>
				{available_route_data.routes_available == 0 && (
					<div className={classes.error_box}>
						<img src={ImageLinks.exclamation_outlined} height={16} width={16}></img>
						<Text semi style={{ color: theme.colors.red, fontSize: 12 }}>
							No existing routes available to complete process.{' '}
							<Text semi component={'span'} className={classes.manage_link} onClick={() => modal_close()}>
								Create Routes
							</Text>
						</Text>
					</div>
				)}

				<Text bold className={classes.box_titles}>
					Criteria for Unrouted Points
				</Text>
				<div className={classes.criterion_box}>
					<Text semi className={classes.criterion_header}>
						Points to Add Together
					</Text>
					<Text regular className={classes.criterion_sub_title}>
						No. of unrouted points to cluster together
					</Text>
					<div className='d-flex justify-content-between align-items-center' style={{ gap: 100 }}>
						<div style={{ flex: 5, padding: 10 }}>
							<Slider
								disabled={available_route_data.routes_available == 0 || process_started}
								className={classes.slider}
								value={max_cluster_size}
								step={Math.max(Math.min(Math.floor(selected_order_ids.length / 5), 3), 1)}
								valueLabelDisplay='on'
								valueLabelFormat={() => utils.nFormatter(max_cluster_size)}
								marks
								min={0}
								max={Math.min(selected_order_ids.length, 15)}
								onChange={(e, value) => set_max_cluster_size(value)}
							/>
						</div>
						<div style={{ flex: 1 }}>
							<TextField
								disabled={available_route_data.routes_available == 0 || process_started}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Text style={{ fontSize: '12', color: theme.colors.lightGrey2 }}>pts</Text>
										</InputAdornment>
									),
								}}
								placeholder='0'
								value={max_cluster_size}
								onChange={(e) => handle_update_input(e, set_max_cluster_size, Math.min(selected_order_ids.length, 15))}
								variant='outlined'
								className={classes.data_inputs}
							/>
						</div>
					</div>

					<Text semi className={classes.criterion_header}>
						Distance Bounds
					</Text>
					<Text regular className={classes.criterion_sub_title}>
						Maximum distance to cluster center
					</Text>
					<div className='d-flex justify-content-between align-items-center' style={{ gap: 100 }}>
						<div style={{ flex: 5, padding: 10 }}>
							<Slider
								disabled={available_route_data.routes_available == 0 || process_started}
								className={classes.slider}
								value={intra_cluster_distance}
								valueLabelDisplay='on'
								valueLabelFormat={() => utils.nFormatter(intra_cluster_distance)}
								step={1}
								marks
								min={0}
								max={5}
								onChange={(e, value) => set_intra_cluster_distance(value)}
							/>
						</div>
						<div style={{ flex: 1 }}>
							<TextField
								disabled={available_route_data.routes_available == 0 || process_started}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Text style={{ fontSize: '12', color: theme.colors.lightGrey2 }}>km</Text>
										</InputAdornment>
									),
								}}
								placeholder='0'
								value={intra_cluster_distance}
								onChange={(e) => handle_update_input_one_decimal(e, set_intra_cluster_distance, 5)}
								variant='outlined'
								className={classes.data_inputs}
							/>
						</div>
					</div>
				</div>

				<Text bold className={classes.box_titles}>
					Criteria for Existing Routes
				</Text>
				<div className={classes.criterion_box}>
					<Text semi className={classes.criterion_header}>
						Distance between Points & Route
					</Text>
					<Text regular className={classes.criterion_sub_title}>
						Average distance between new and existing points
					</Text>
					<div className='d-flex justify-content-between align-items-center' style={{ gap: 100 }}>
						<div style={{ flex: 5, padding: 10 }}>
							<Slider
								disabled={available_route_data.routes_available == 0 || process_started}
								className={classes.slider}
								value={centroid_to_route_distance}
								valueLabelDisplay='on'
								step={2}
								marks
								min={0}
								max={10}
								onChange={(e, value) => set_centroid_to_route_distance(value)}
							/>
						</div>
						<div style={{ flex: 1 }}>
							<TextField
								disabled={available_route_data.routes_available == 0 || process_started}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Text style={{ fontSize: '12', color: theme.colors.lightGrey2 }}>km</Text>
										</InputAdornment>
									),
								}}
								placeholder='0'
								value={centroid_to_route_distance}
								onChange={(e) => handle_update_input_one_decimal(e, set_centroid_to_route_distance, 10)}
								variant='outlined'
								className={classes.data_inputs}
							/>
						</div>
					</div>

					<Text semi className={classes.criterion_header}>
						Max. Drops in Route
					</Text>
					<Text regular className={classes.criterion_sub_title}>
						Maximum route size after addition
					</Text>
					<div className='d-flex justify-content-between align-items-center' style={{ gap: 100 }}>
						<div style={{ flex: 5, padding: 10 }}>
							<Slider
								disabled={available_route_data.routes_available == 0 || process_started}
								className={classes.slider}
								value={max_size_of_routes}
								valueLabelDisplay='on'
								valueLabelFormat={() => utils.nFormatter(max_size_of_routes)}
								step={8}
								marks
								min={1}
								max={45}
								onChange={(e, value) => set_max_size_of_routes(value)}
							/>
						</div>
						<div style={{ flex: 1 }}>
							<TextField
								disabled={available_route_data.routes_available == 0 || process_started}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Text style={{ fontSize: '12', color: theme.colors.lightGrey2 }}>pts</Text>
										</InputAdornment>
									),
								}}
								placeholder='0'
								value={max_size_of_routes}
								onChange={(e) => handle_update_input(e, set_max_size_of_routes, 45)}
								variant='outlined'
								className={classes.data_inputs}
							/>
						</div>
					</div>
				</div>
				{!process_started && (
					<ContainedButton
						className={classes.action_button}
						disabled={
							max_cluster_size === 0 ||
							max_cluster_size === '' ||
							intra_cluster_distance === 0 ||
							intra_cluster_distance === '' ||
							centroid_to_route_distance === 0 ||
							centroid_to_route_distance === '' ||
							max_size_of_routes === 0 ||
							max_size_of_routes === '' ||
							available_route_data.routes_available == 0 ||
							process_started
						}
						onClick={handle_add_route}>
						<Text semi style={{ fontSize: 16 }}>
							Add Points to Routes
						</Text>
					</ContainedButton>
				)}
				{process_started && (
					<ProcessingStates
						reset_modal={reset_modal}
						progress_data={progress_data}
						selected_order_ids={selected_order_ids}
						handle_add_route={handle_add_route}
					/>
				)}
			</div>
			{/* <AutoAddMap
				max_cluster_size={max_cluster_size}
				intra_cluster_distance={intra_cluster_distance}
				centroid_to_route_distance={centroid_to_route_distance}
			/> */}
		</>
	);
}

export default AutoAddModal;
