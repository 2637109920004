import _ from 'lodash';
import React from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import Text from 'modules/Text';
import ContainedButton from 'modules/ContainedButton';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import OutlinedButton from 'modules/OutlinedButton';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	modal_container: { display: 'flex', outline: 'none' },
	component_container: {
		padding: 24,
		paddingBottom: 0,
		background: theme.colors.white,
		width: 600,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_title: { fontSize: 18, width: '100%', paddingTop: 5 },
	contained_buttons_container: { marginTop: 32, marginBottom: 24, display: 'flex', justifyContent: 'center' },
	button: { borderRadius: 30, height: 46, minWidth: `100px !important` },
}));

const CopyDetailsModal = ({ username, password, on_confirm }) => {
	const handle_confirm = () => {
		on_confirm();
	};
	const classes = useStyles();
	return (
		<Modal open={true} className={classes.modal_container}>
			<div className={classes.component_container}>
				<div style={{ display: 'flex' }}>
					<div style={{ marginLeft: 16, width: '100%' }}>
						<div style={{ display: 'flex' }}>
							<Text semi className={classes.header_title}>
								Token Keys
							</Text>
						</div>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							Please copy and save token credentials for future reference
						</Text>
						<div style={{ display: 'flex', alignItems: 'center', marginTop: 30, gap: 10 }}>
							<Text semi style={{ fontSize: 16 }}>
								Username: {username}
							</Text>
							<img
								src={ImageLinks.copy_icon}
								onClick={() => navigator.clipboard.writeText(username)}
								style={{ height: 14, width: 14, cursor: 'pointer' }}
							/>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginTop: 16, gap: 10 }}>
							<Text semi style={{ fontSize: 16 }}>
								Password: {password}
							</Text>
							<img
								src={ImageLinks.copy_icon}
								onClick={() => navigator.clipboard.writeText(password)}
								style={{ height: 14, width: 14, cursor: 'pointer' }}
							/>
						</div>

						<div style={{ display: 'flex', marginTop: 10, gap: 4, alignItems: 'baseline' }}>
							<img src={ImageLinks.exclamation_purple} style={{ width: 12, height: 12 }} />
							<Text style={{ color: theme.colors.darkPurple }} semi>
								Passwords are not saved in system. Please copy and save password separately for future reference
							</Text>
						</div>
					</div>
				</div>

				<div className={classes.contained_buttons_container}>
					<Button type='round' text='OK' size='large' onClick={handle_confirm} style={{ width: 110 }} />
				</div>
			</div>
		</Modal>
	);
};

export default CopyDetailsModal;
