import Text from 'modules/Text';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import search from 'assets/images/icons/search.svg';
import { ListItem, makeStyles, Grid, Divider } from '@material-ui/core';
import AddressInput from 'modules/modals/AddressInput';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import api_requests from 'resources/api_requests';
import CreateOrderContext from 'views/CreateOrderFlow/CreateOrder/context';
import DeliveryDetailsModal from './modals/DeliveryDetailsModal';
import EditDeliveryDetails from './modals/EditDeliveryDetails';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Package from 'modules/modals/Package';
import theme from 'resources/theme';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import moment from 'moment';
import OutlinedButton from 'modules/OutlinedButton';
import DeliverySavedAddresses from './modals/DeliverySavedAddresses';
import Button from 'modules/Button';
import utils from 'resources/utils';
import constant from 'resources/constant';

const ENTRY_TYPE = {
	delivery_address: 'delivery_address',
	delivery_details: 'delivery_details',
	package_details: 'package_details',
};

const initial_edit_entry_details = {
	open: false,
	edit_index: null,
};

const useStyles = makeStyles((theme) => ({
	new_delivery_list_item: { background: 'white', padding: 14, marginTop: 20, borderRadius: 6, display: 'flex', justifyContent: 'space-between' },
	selected_drop_wrapper: {
		padding: 12,
		border: `1px solid ${theme.colors.lightGrey7}`,
		borderRadius: 6,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		backgroundColor: theme.colors.white,
	},
	address_text: { fontSize: 12, color: theme.colors.lightGrey9, marginTop: 4 },
	ref_text: { fontSize: 12 },
	pkg_info_wrap: { width: '100%', display: 'flex', alignItems: 'flex-start' },
	pkg_text: { fontSize: 12, marginTop: 8 },
	amount_info_wrap: { display: 'flex', alignItems: 'flex-start' },
	amount_text: { fontSize: 12, color: theme.colors.black },
	contact_wrap: { marginTop: 44 },
	contact_input_wrap: {
		background: theme.colors.white,
		padding: '8px 12px',
		marginTop: 8,
		borderRadius: 46,
		display: 'flex',
		justifyContent: 'space-between',
		border: `1px solid ${theme.colors.lightPurple6}`,
	},
	contact_input_disabled: {
		background: theme.colors.white,
		padding: '8px 12px',
		marginTop: 8,
		borderRadius: 46,
		display: 'flex',
		justifyContent: 'space-between',
		border: `1px solid ${theme.colors.lightGrey2}`,
	},
	csv_wrap: { flex: 1, marginLeft: 40, marginTop: 30 },
}));

const DeliveryDetails = ({ active }) => {
	const { zone_id, selected_drop_details, set_selected_drop_details, bop_packages, on_repeat_old_details } = React.useContext(CreateOrderContext);
	const [delivery_address_modal, set_delivery_address_modal] = React.useState(false);
	const [drop_details_modal, set_drop_details_modal] = React.useState(false);
	const [package_modal, set_package_modal] = React.useState(false);
	const [confirm_close_modal, set_confirm_close_modal] = React.useState(false);

	const [delete_drop_index, set_delete_drop_index] = React.useState();

	const [edit_entry_details, set_edit_entry_details] = React.useState({ ...initial_edit_entry_details });

	const [search_address, set_search_address] = React.useState([]);
	const [contact_number, set_contact_number] = React.useState('');

	const [local_drop_address, set_local_drop_address] = React.useState({});

	const [local_receiver_name, set_local_receiver_name] = React.useState('');
	const [local_receiver_contact, set_local_receiver_contact] = React.useState('');
	const [local_cod, set_local_cod] = React.useState(0);
	const [local_bill, set_local_bill] = useState(0);
	const [local_reference_id, set_local_reference_id] = React.useState('');

	const [local_delivery_date, set_local_delivery_date] = React.useState('');
	const [local_delivery_slot, set_local_delivery_slot] = React.useState('');

	const [edit_details, set_edit_details] = React.useState();

	const showFullWidthDeliveryInformation = _.isEmpty(selected_drop_details);
	const [show_error_message, set_show_error_message] = useState('');
	const [delivery_saved_address_modal, set_delivery_saved_address_modal] = useState(false);
	const [is_drop_info_in_edit_mode, set_is_drop_info_in_edit_mode] = useState(false);
	const [timer, set_timer] = useState(3);

	const timer_ref = useRef(null);
	const classes = useStyles();

	useEffect(() => {
		if (timer === 0) {
			clearInterval(timer_ref.current);
			set_contact_number('');
			set_show_error_message('');
			set_timer(3);
		}
	}, [timer]);

	useEffect(() => {
		if (!delivery_address_modal && !drop_details_modal && !package_modal && !delivery_saved_address_modal) clear_local_data();
	}, [delivery_address_modal, drop_details_modal, package_modal, delivery_saved_address_modal]);

	const handle_contact_number = async (e) => {
		e.preventDefault();
		set_search_address([]);
		set_show_error_message('');
		clearInterval(timer_ref.current);
		if (contact_number.length === 10) {
			try {
				const res = await api_requests.get_pending_orders_search({
					customer_mobile: contact_number,
					limit: 3,
					group: 'completed',
				});
				if (_.isEmpty(res.data.items)) {
					set_show_error_message('No customer/ addresses found');
					timer_ref.current = setInterval(() => {
						set_timer((prev) => prev - 1);
					}, 1000);
					return;
				}
				set_search_address(res.data.items);
				set_delivery_saved_address_modal(true);
				set_show_error_message('');
			} catch (err) {}
			return;
		} else {
			set_show_error_message('Please Enter correct contact number');
		}
		timer_ref.current = setInterval(() => {
			set_timer((prev) => prev - 1);
		}, 1000);
	};

	const on_address_select = (address, address_type, edit_details) => {
		if (edit_details && is_drop_info_in_edit_mode) {
			const _selected_drop_details = _.cloneDeep(selected_drop_details);
			_selected_drop_details[edit_details.index].drop_address = address;
			set_selected_drop_details(_selected_drop_details);
			set_edit_details({});
			if (is_drop_info_in_edit_mode) return;
		}
		set_edit_details({});
		set_local_drop_address(address);
		set_drop_details_modal(true);
	};

	const handle_drop_details = (edit_details, name, contact, cod, bill_amount, reference_id, delivery_date, delivery_slot) => {
		if (edit_details && is_drop_info_in_edit_mode) {
			const _selected_drop_details = _.cloneDeep(selected_drop_details);
			_selected_drop_details[edit_details.index].receiver_detail = {
				name,
				mobile: contact,
			};
			_selected_drop_details[edit_details.index].cod_amount = cod;
			_selected_drop_details[edit_details.index].bill_amount = bill_amount;

			if (!_.isEmpty(reference_id)) {
				_selected_drop_details[edit_details.index].reference_id = reference_id;
			}

			if (!_.isEmpty(delivery_date)) {
				_selected_drop_details[edit_details.index].delivery_date = delivery_date;
			}

			if (!_.isEmpty(delivery_slot)) {
				_selected_drop_details[edit_details.index].delivery_slot = delivery_slot;
			}

			set_selected_drop_details(_selected_drop_details);
			set_edit_details({});
			if (is_drop_info_in_edit_mode) return;
		}
		set_edit_details({});
		set_local_receiver_name(name);
		set_local_receiver_contact(contact);
		set_local_cod(cod);
		set_local_bill(bill_amount);
		set_local_reference_id(reference_id);
		set_local_delivery_date(delivery_date);
		set_local_delivery_slot(delivery_slot);
		set_package_modal(true);
	};

	const handle_packages = (packages) => {
		if (!_.isEmpty(edit_details) && is_drop_info_in_edit_mode) {
			const _selected_drop_details = _.cloneDeep(selected_drop_details);
			_selected_drop_details[edit_details.index].packages = packages;
			set_selected_drop_details(_selected_drop_details);
			set_edit_details({});
			return;
		}
		set_edit_details({});
		const _selected_drop_details = [...selected_drop_details];
		_selected_drop_details.push({
			source_order_id: uuidv4(),
			cod_amount: Number(local_cod),
			bill_amount: Number(local_bill),
			...(!_.isEmpty(local_reference_id) && { reference_id: local_reference_id }),
			...(!_.isEmpty(local_delivery_date) && { delivery_date: local_delivery_date }),
			...(!_.isEmpty(local_delivery_slot) && { delivery_slot: local_delivery_slot }),
			drop_address: local_drop_address,
			receiver_detail: {
				name: local_receiver_name,
				mobile: local_receiver_contact,
			},
			packages,
		});
		set_selected_drop_details(_selected_drop_details);
		set_package_modal(false);
		set_drop_details_modal(false);
		set_delivery_saved_address_modal(false);
		clear_local_data();
	};

	const on_edit_select = (edit_type, edit_index) => {
		set_is_drop_info_in_edit_mode(true);
		switch (edit_type) {
			case ENTRY_TYPE.delivery_address: {
				const drop_details = selected_drop_details[edit_index].drop_address;
				set_edit_details({
					index: edit_index,
					selected_place: drop_details.selected_place,
					address_line1: drop_details.address_line1,
					instructions: drop_details.instructions_to_reach,
					address_name: drop_details.label,
				});
				set_delivery_address_modal(true);
				break;
			}

			case ENTRY_TYPE.delivery_details: {
				const drop_details = selected_drop_details[edit_index];
				set_edit_details({
					index: edit_index,
					receiver_name: drop_details.receiver_detail.name,
					receiver_contact: drop_details.receiver_detail.mobile,
					reference_id: _.get(drop_details, 'reference_id', ''),
					cod_amount: Number(drop_details.cod_amount),
					bill_amount: Number(drop_details.bill_amount),
					delivery_date: _.get(drop_details, 'delivery_date', ''),
					delivery_slot: _.get(drop_details, 'delivery_slot', ''),
				});
				set_drop_details_modal(true);
				set_local_drop_address(drop_details.drop_address);
				break;
			}

			case ENTRY_TYPE.package_details: {
				const drop_details = selected_drop_details[edit_index];
				set_edit_details({
					index: edit_index,
					packages: drop_details.packages,
				});
				set_package_modal(true);
				break;
			}
			default:
				break;
		}
	};

	const clear_local_data = () => {
		set_contact_number('');
		set_local_drop_address({});
		set_local_receiver_name('');
		set_local_receiver_contact('');
		set_local_cod('');
		set_local_bill('');
		set_local_reference_id('');
		set_local_delivery_date('');
		set_local_delivery_slot('');
		set_is_drop_info_in_edit_mode(false);
		set_edit_details();
		set_search_address([]);
		set_delivery_saved_address_modal(false);
	};

	const delete_drop_detail = (index) => {
		set_delete_drop_index(index);
		set_confirm_close_modal(true);
	};

	const confirm_delete_drop_detail = () => {
		const _selected_drop_details = [...selected_drop_details];
		_selected_drop_details.splice(delete_drop_index, 1);
		set_selected_drop_details(_selected_drop_details);
	};

	const handle_delivery_contact_back_nav = () => {
		set_edit_details({
			index: 0,
			selected_place: local_drop_address.selected_place,
			address_line1: local_drop_address.address_line1,
			instructions: local_drop_address.instructions_to_reach,
			address_name: local_drop_address.label,
		});
		set_local_receiver_name('');
		set_local_receiver_contact('');
		set_local_reference_id('');
		set_local_cod('');
		set_local_bill('');
		set_local_delivery_date('');
		set_local_delivery_slot('');
		set_delivery_address_modal(delivery_saved_address_modal ? false : true);
		set_drop_details_modal(false);
	};

	const handle_pkg_back_nav = () => {
		set_edit_details({
			index: 0,
			receiver_name: local_receiver_name,
			receiver_contact: local_receiver_contact,
			reference_id: local_reference_id,
			cod_amount: local_cod,
			bill_amount: local_bill,
			delivery_date: local_delivery_date,
			delivery_slot: local_delivery_slot,
		});
		set_package_modal(false);
		set_drop_details_modal(true);
	};

	return (
		<div>
			{!active && <div className={classes.disabled} />}
			<Text semi style={{ fontSize: 18 }}>
				Delivery Details
			</Text>
			<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
				Create customer delivery and package details
			</Text>
			<Grid container spacing={2}>
				<Grid
					item
					xl={showFullWidthDeliveryInformation ? 12 : 4}
					lg={showFullWidthDeliveryInformation ? 12 : 4}
					md={showFullWidthDeliveryInformation ? 12 : 4}
					sm={12}
					xs={12}>
					<div className={classes.contact_wrap}>
						{!active ? (
							<>
								<Text semi style={{ fontSize: 14, marginBottom: 4, color: theme.colors.darkGrey2 }}>
									Quick search frequent customer
								</Text>
								<div className={classes.contact_input_disabled}>
									<input
										type='number'
										placeholder='Search by Customer Mobile'
										disabled
										className='input-number-field w-100'
										style={{ border: 'none', outline: 'none', flex: 1, background: 'unset' }}
									/>
									<img src={ImageLinks.search} alt='search' width={20} height={20} style={{ opacity: 0.3 }} />
								</div>
							</>
						) : (
							<>
								<Text semi style={{ fontSize: 14, marginBottom: 4 }}>
									Quick search frequent customer
								</Text>
								<div className={classes.contact_input_wrap}>
									<form onSubmit={handle_contact_number} className='w-100'>
										<input
											type='number'
											placeholder='Search by Customer Mobile'
											value={contact_number}
											onChange={(e) => {
												set_contact_number(e.target.value);
											}}
											onFocus={() => utils.track_event_for_analytics(constant.TRACKING_EVENTS.CLICK_FREQUENT_DELIVERY)}
											id='mobile-number-field'
											className='input-number-field w-100'
											style={{ border: 'none', outline: 'none', flex: 1 }}
										/>
									</form>
									{contact_number.length === 0 ? (
										<img src={ImageLinks.search_light_purple} alt='search' width={20} height={20} />
									) : (
										<img
											src={ImageLinks.arrow_right_light_purple}
											width={20}
											height={20}
											alt='arrow-right'
											className={contact_number.length !== 10 ? 'cursor-unset' : 'cursor-pointer'}
											disabled={contact_number.length !== 10}
											onClick={handle_contact_number}
											style={contact_number.length === 10 ? { opacity: 1 } : { opacity: 0.3 }}
										/>
									)}
								</div>
							</>
						)}
						{!_.isEmpty(show_error_message) && (
							<div className='d-flex align-items-center'>
								<img
									src={ImageLinks.information_reverse}
									width={12}
									height={12}
									alt='information'
									style={{ background: theme.colors.red, borderRadius: '50%', marginRight: 4 }}
								/>
								<Text style={{ marginTop: 4, color: theme.colors.red, fontSize: 12 }}>{show_error_message}</Text>
							</div>
						)}
					</div>
					<Text semi style={!active ? { fontSize: 14, marginTop: 34, color: theme.colors.darkGrey2 } : { fontSize: 14, marginTop: 34 }}>
						Create Delivery Detail
					</Text>
					<div style={{ width: 'fit-content', marginTop: 8 }}>
						<Button
							type='outlined'
							size='large'
							text='New Delivery Detail'
							left_icon={active ? ImageLinks.add_pruple_no_fill : ImageLinks.addGrey}
							disabled={!active}
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.CLICK_NEW_DELIVERY);
								set_delivery_address_modal(true);
							}}
						/>
					</div>
				</Grid>

				<Grid
					item
					xl={showFullWidthDeliveryInformation ? 12 : 8}
					lg={showFullWidthDeliveryInformation ? 12 : 8}
					md={showFullWidthDeliveryInformation ? 12 : 8}
					sm={12}
					xs={12}>
					<ResponsiveMasonry columnsCountBreakPoints={{ 900: 2 }}>
						<Masonry columnsCount={2}>
							{_.map(selected_drop_details, (details, key) => (
								<div key={`drop${key}`} style={{ margin: 4 }}>
									<div className={classes.selected_drop_wrapper}>
										<div className='d-flex justify-content-between'>
											<div>
												<Text bold style={{ fontSize: 14 }}>
													{_.get(details, 'receiver_detail.name', '-')}
												</Text>
												<Text className={classes.address_text}>
													{_.get(details, 'drop_address.state')} {_.get(details, 'drop_address.address_line1')}{' '}
													{_.get(details, 'drop_address.pincode')}
												</Text>
											</div>
											<div className='d-flex justify-content-center align-items-center'>
												<img
													src={ImageLinks.edit}
													className='cursor-pointer'
													height={14}
													width={14}
													onClick={() => set_edit_entry_details({ open: true, edit_index: key })}
												/>
												<img
													src={ImageLinks.delete}
													style={{ marginLeft: 10 }}
													onClick={() => delete_drop_detail(key)}
													className='cursor-pointer'
													height={16}
													width={16}
												/>
											</div>
										</div>
										<Divider style={{ marginTop: 8, marginBottom: 8, backgroundColor: theme.colors.lightPurple }} />
										<Grid container>
											<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
												<div className='d-flex'>
													<img src={ImageLinks.box2_pink} style={{ marginRight: 4 }} width={16} height={16} alt='box' />
													{_.isEmpty(_.get(details, 'reference_id', '')) ? (
														<Text bold style={{ fontSize: 12, color: theme.colors.lightGrey2 }}>
															Reference ID
														</Text>
													) : (
														<Text medium className={classes.ref_text}>
															{_.get(details, 'reference_id', '-').length <= 15
																? _.get(details, 'reference_id', '-')
																: `${_.get(details, 'reference_id', '').substring(0, 5)}...${_.get(
																		details,
																		'reference_id',
																		'',
																  ).substring(_.get(details, 'reference_id', '-').length - 5)}`}
														</Text>
													)}
												</div>
												<div style={{ marginTop: 4 }}>
													{_.map(details.packages, (_package, key) => {
														const package_details = _.find(bop_packages, { package_id: _package.package_id });
														return (
															<Text key={`package${key}`} className={classes.pkg_text}>
																{_package.quantity} {_.capitalize(package_details.package_size)} Bag ({package_details.title})
															</Text>
														);
													})}
												</div>
											</Grid>
											<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
												<div className='d-flex'>
													<img src={ImageLinks.inr_filled} width={16} height={16} alt='rupee' style={{ marginRight: 4 }} />
													<Text className={classes.amount_text}>{Number(details.cod_amount)}</Text>
												</div>
												<div className='d-flex' style={{ marginTop: 8 }}>
													<Text className={classes.amount_text}>
														<Text component={'span'} style={{ color: theme.colors.darkGrey2, fontSize: 11 }}>
															Delivery Date:{' '}
														</Text>
														{_.get(details, 'delivery_date', '') ? moment(details.delivery_date).format('DD-MM-YYYY') : '-'}
													</Text>
												</div>
												<div className='d-flex' style={{ marginTop: 8 }}>
													<Text className={classes.amount_text}>
														<Text component={'span'} style={{ color: theme.colors.darkGrey2, fontSize: 11 }}>
															Delivery Slot:{' '}
														</Text>
														{_.get(details, 'delivery_slot', '')
															? moment(_.get(details, 'delivery_slot').split('-')[0], 'hh:mm').format('LT') +
															  '-' +
															  moment(_.get(details, 'delivery_slot').split('-')[1], 'hh:mm').format('LT')
															: '-'}
													</Text>
												</div>
											</Grid>
										</Grid>
									</div>
								</div>
							))}
						</Masonry>
					</ResponsiveMasonry>
				</Grid>
			</Grid>

			{delivery_address_modal && (
				<AddressInput
					zone_id={zone_id}
					marker_img={ImageLinks.marker_down_arrow}
					close={() => set_delivery_address_modal(false)}
					on_address_select={on_address_select}
					title={!is_drop_info_in_edit_mode ? 'New Delivery Address' : 'Update Delivery Address'}
					clear_local_data={clear_local_data}
					edit_details={edit_details}
					show_save_as={false}
					track_data_changes={is_drop_info_in_edit_mode}
				/>
			)}

			{drop_details_modal && (
				<DeliveryDetailsModal
					zone_id={zone_id}
					title={is_drop_info_in_edit_mode ? 'Edit Delivery Details' : 'Delivery Details'}
					close={() => set_drop_details_modal(false)}
					handle_drop_details={handle_drop_details}
					drop_address={local_drop_address}
					local_receiver_name={local_receiver_name}
					local_receiver_contact={local_receiver_contact}
					clear_local_data={clear_local_data}
					edit_details={edit_details}
					show_back_button={!is_drop_info_in_edit_mode}
					action_on_back_button={handle_delivery_contact_back_nav}
					track_data_changes={is_drop_info_in_edit_mode}
				/>
			)}

			{package_modal && (
				<Package
					handle_packages={handle_packages}
					close={() => set_package_modal(false)}
					clear_local_data={clear_local_data}
					edit_packages={_.get(edit_details, 'packages')}
					show_back_button={!is_drop_info_in_edit_mode}
					action_on_back_button={handle_pkg_back_nav}
					track_data_changes={is_drop_info_in_edit_mode}
				/>
			)}

			{edit_entry_details.open && (
				<EditDeliveryDetails
					close={() => set_edit_entry_details({ ...initial_edit_entry_details })}
					ENTRY_TYPE={ENTRY_TYPE}
					on_edit_select={on_edit_select}
					edit_index={edit_entry_details.edit_index}
				/>
			)}
			{confirm_close_modal && (
				<ConfirmClose
					headerTitle='Delete Entry'
					title='Are you sure you want to remove this order?'
					close={() => set_confirm_close_modal(false)}
					on_confirm={confirm_delete_drop_detail}
					is_error_msg={true}
					positive_btn_text={'Delete'}
				/>
			)}
			{delivery_saved_address_modal && (
				<DeliverySavedAddresses
					is_item_added={selected_drop_details.length > 0}
					close={() => set_delivery_saved_address_modal(false)}
					search_address={search_address}
					contact_number={contact_number}
					on_address_select={on_address_select}
					set_local_receiver_name={set_local_receiver_name}
					set_local_receiver_contact={set_local_receiver_contact}
					on_place_order={on_repeat_old_details}
				/>
			)}
		</div>
	);
};

export default DeliveryDetails;
