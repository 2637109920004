import React, { useEffect, useMemo } from 'react';
import ContainedButton from 'modules/ContainedButton';
import useStyles from '../styles';
import Text from 'modules/Text';
import _ from 'lodash';
import Button from 'modules/Button';

const Footer = ({ rider_data, rider_tags, handle_update, data_diff, rider_details }) => {
	const classes = useStyles();

	const btn_disabled = useMemo(() => {
		let value = true;
		const updated_values = data_diff();
		if (
			!_.isEmpty(rider_data.current_location) &&
			!_.isEmpty(rider_data.date_of_birth) &&
			!_.isEmpty(rider_data.aadhar_number) &&
			!_.isEmpty(rider_data.blood_group) &&
			!_.isEmpty(updated_values)
		)
			value = false;
		return value;
	}, [rider_data, rider_tags, rider_details]);

	return (
		<div className={classes.footer}>
			<Button type='round' text='Update' size='large' disabled={btn_disabled} onClick={handle_update} className={classes.cta_btn} />
		</div>
	);
};

export default Footer;
