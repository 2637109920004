import _ from 'lodash';
import React from 'react';
import { ListItem, makeStyles, Modal, Radio } from '@material-ui/core';
import Text from 'modules/Text';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 20,
		background: theme.colors.white,
		width: 580,
		height: 300,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header: { display: 'flex', justifyContent: 'space-between' },
	header_text_wrap: { display: 'flex', alignItems: 'center', height: 30 },
	edit_section: { flex: 1, marginTop: 10 },
	list_item_wrap: { marginTop: 40, display: 'flex', justifyContent: 'space-between' },
	list_item: {
		padding: '10px 0px',
		border: '1px solid transparent',
		borderRadius: 5,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.8)',
		marginTop: 15,
		display: 'flex',
		alignItems: 'center',
		width: 170,
		height: 50,
	},
	delivery_addr_text: { fontSize: 16 },
	cta_btn: {
		padding: '8px 32px',
		borderRadius: 30,
		margin: '0 auto',
		'&:disabled': {
			color: theme.colors.darkGrey2,
		},
	},
}));

const EditDeliveryDetails = ({ close, ENTRY_TYPE, on_edit_select, edit_index }) => {
	const [entry_type, set_entry_type] = React.useState('');
	const classes = useStyles();
	const handle_edit_select = () => {
		on_edit_select(entry_type, edit_index);
		close();
	};
	return (
		<Modal open={true} onClose={close} className='d-flex' style={{ outline: 'none' }}>
			<div className={classes.wrapper}>
				<div className='d-flex justify-content-between'>
					<div className={classes.header_text_wrap}>
						<Text bold style={{ fontSize: 24 }}>
							Edit Entry
						</Text>
					</div>
					<img src={ImageLinks.crossBlack} width={20} height={20} onClick={close} style={{ cursor: 'pointer' }} />
				</div>
				<div className={classes.edit_section}>
					<Text>Which part of the entry would you like to edit?</Text>
					<div className={classes.list_item_wrap}>
						<ListItem button className={classes.list_item} onClick={() => set_entry_type(ENTRY_TYPE.delivery_address)}>
							<Radio size='small' checked={entry_type === ENTRY_TYPE.delivery_address} />
							<Text semi className={classes.delivery_addr_text}>
								Delivery Address
							</Text>
						</ListItem>
						<ListItem button className={classes.list_item} onClick={() => set_entry_type(ENTRY_TYPE.delivery_details)}>
							<Radio size='small' checked={entry_type === ENTRY_TYPE.delivery_details} />
							<Text semi className={classes.delivery_addr_text}>
								Delivery Details
							</Text>
						</ListItem>
						<ListItem button className={classes.list_item} onClick={() => set_entry_type(ENTRY_TYPE.package_details)}>
							<Radio size='small' checked={entry_type === ENTRY_TYPE.package_details} />
							<Text semi className={classes.delivery_addr_text}>
								Package Details
							</Text>
						</ListItem>
					</div>
				</div>
				<div className='d-flex justify-content-center align-items-center w-100 text-align-center'>
					<Button size='large' type='round' text='Edit Now' onClick={handle_edit_select} disabled={_.isEmpty(entry_type)} style={{ width: 110 }} />
					{/* <ContainedButton onClick={handle_edit_select} disabled={_.isEmpty(entry_type)} className={classes.cta_btn}>
						<Text bold style={{ fontSize: 18 }}>
							Edit Now
						</Text>
					</ContainedButton> */}
				</div>
			</div>
		</Modal>
	);
};

export default EditDeliveryDetails;
