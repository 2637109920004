import Text from 'modules/Text';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, makeStyles, Modal } from '@material-ui/core';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';

const useStyles = makeStyles(() => ({
	container: {
		padding: 20,
		background: 'white',
		width: 600,
		minHeight: 140,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
		display: 'flex',
		justifyContent: 'center',
	},
	progress: {
		color: theme.colors.green,
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 1,
	},
}));

const SuccessModal = ({ name, create_api_state_status, close, on_sucess }) => {
	const [is_loading, set_is_loading] = useState(true);
	const [timer, set_timer] = useState(0);
	const classes = useStyles();
	const timer_ref = useRef(null);

	useEffect(() => {
		start_anim();
		return () => {
			clearInterval(timer_ref.current);
		};
	}, []);

	useEffect(() => {
		if (timer === 7) {
			clearInterval(timer_ref.current);
			set_is_loading(false);

			if (create_api_state_status.is_success) {
				close();
				on_sucess();
			}
		}
	}, [timer]);

	const start_anim = () => {
		timer_ref.current = setInterval(() => {
			set_timer((prev) => prev + 1);
		}, 1000);
	};

	return (
		<Modal open={true} onClose={() => {}} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				{!create_api_state_status.is_success && (
					<div style={{ cursor: 'pointer', alignSelf: 'flex-end' }} onClick={close}>
						<img src={ImageLinks.crossBlack} style={{ width: 16, height: 16, paddingTop: 0 }} />
					</div>
				)}
				<div className='align-items-center' style={{ position: 'relative' }}>
					<img
						src={
							is_loading
								? ImageLinks.tick_light_green_solid
								: create_api_state_status.is_success
								? ImageLinks.green_tick_ripple
								: ImageLinks.excalamation_red_ripple
						}
						width={48}
						height={48}
					/>
					{is_loading && <CircularProgress size={52} className={classes.progress} />}
					<div style={{ marginLeft: 6 }}>
						<Text semi style={{ fontSize: 18 }}>
							{timer <= 3
								? 'Confirming network name..'
								: timer <= 6
								? 'Setting up network rules..'
								: create_api_state_status.is_success
								? `${name} is live!`
								: 'Unable to Publish Network'}
						</Text>
						<Text medium style={{ fontSize: 14 }}>
							{timer <= 3
								? 'This usually takes less than 10 seconds'
								: timer <= 6
								? 'This usually takes less than 10 seconds'
								: create_api_state_status.is_success
								? 'You can now invite participants & fulfill orders from your new network.'
								: create_api_state_status.message}
						</Text>
					</div>
				</div>
				{!create_api_state_status.is_success && timer > 6 && (
					<ContainedButton onClick={close} style={{ width: 130, height: 38, borderRadius: 30, marginTop: 30, alignSelf: 'flex-end' }}>
						<Text semi style={{ fontSize: 18 }}>
							Try Again
						</Text>
					</ContainedButton>
				)}
			</div>
		</Modal>
	);
};

export default SuccessModal;
