import React, { useEffect } from 'react';
import { ListItem } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';

import Text from 'modules/Text';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import theme from 'resources/theme';
import Marker from './Marker';
import ContainedButton from 'modules/ContainedButton';
import Button from 'modules/Button';

const SubZones = ({ selected_master_zone, refresh_business_zone, pidge_zones, on_edit_master_zone, open_pincodes }) => {
	const [sub_zones, set_sub_zones] = React.useState([]);

	const map = React.useRef(null);

	const initialize = () => {
		if (!map.current) {
			map.current = new window.google.maps.Map(document.getElementById('map_canvas'), {
				center: { lat: selected_master_zone.focal.coordinates[0], lng: selected_master_zone.focal.coordinates[1] },
				zoom: 8,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});
		}

		const polygon_coords = _.map(_.get(selected_master_zone, 'geometry.coordinates[0]', []), (coord) => {
			return { lat: coord[0], lng: coord[1] };
		});

		// Construct the polygon.
		const polygon = new window.google.maps.Polygon({
			paths: polygon_coords,
			strokeColor: theme.colors.primary,
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillOpacity: 0,
		});

		polygon.setMap(map.current);

		_.map(sub_zones, (zone) => {
			const polygon_coords = _.map(_.get(zone, 'geometry.coordinates[0]', []), (coord) => {
				return { lat: coord[0], lng: coord[1] };
			});

			// Construct the polygon.
			const polygon = new window.google.maps.Polygon({
				paths: polygon_coords,
				strokeOpacity: 0,
				strokeWeight: 2,
				fillColor: zone.color,
				fillOpacity: 0.35,
			});

			polygon.setMap(map.current);
		});
	};

	useEffect(() => {
		set_sub_zones([]);
		api_requests.get_sub_zones_geometry(selected_master_zone.id).then((res) => {
			set_sub_zones(
				_.map(res.data, (data) => {
					return {
						...data,
						color:
							'#' +
							Math.floor(Math.random() * 16777215)
								.toString(16)
								.padStart(6, '0'),
					};
				}),
			);
		});
	}, [refresh_business_zone]);

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, [sub_zones]);

	return (
		<div style={{ flex: 1, display: 'flex', overflowY: 'hidden' }}>
			<div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '20px 0px' }}>
				<div style={{ flex: 1, overflowY: 'auto', paddingBottom: 10 }}>
					<Text bold style={{ fontSize: 18, marginBottom: 12 }}>
						{selected_master_zone.title}
						<Text bold component={'span'} style={{ fontSize: 12 }}>
							{` (${sub_zones.length})`}
						</Text>
					</Text>
					{_.map(sub_zones, (zone, key) => {
						return (
							<ListItem
								key={`zone${key}`}
								button
								onClick={() => {
									open_pincodes(zone);
								}}
								className='box-shadow'
								style={{
									justifyContent: 'space-between',
									padding: '12px 15px',
									borderRadius: 6,
									height: 60,
									...(key !== 0 && { marginTop: 10 }),
								}}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Marker color={zone.color} />
									<Text bold style={{ marginLeft: 10, fontSize: 18 }}>
										{zone.title}
									</Text>
								</div>
								<div style={{ display: 'flex' }}>
									<img src={ImageLinks.chevron_right_black} width='8' style={{ marginLeft: 15 }} alt='' />
								</div>
							</ListItem>
						);
					})}
				</div>
				<Button
					onClick={on_edit_master_zone}
					left_icon={ImageLinks.edit_purple}
					text='Edit Master Zone'
					size='large'
					type='text'
					style={{ width: 'fit-content' }}
				/>
			</div>
			<div style={{ flex: 1, padding: 40, position: 'relative' }}>
				<div
					id='map_canvas'
					className='map-canvas-wrapper rider-location card'
					style={{ height: '100%', borderRadius: 20, background: theme.colors.lightGrey3 }}></div>
			</div>
		</div>
	);
};

export default SubZones;
