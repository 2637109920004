import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';

export const useStyles = makeStyles(() => ({
	wrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	sub_wrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 600,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 50,
		zIndex: 1200,
		overflowY: 'auto',
	},
	header: { borderBottom: '1px solid lightgrey', paddingBottom: 10, display: 'flex', justifyContent: 'space-between' },
	header_text_wrap: { display: 'flex', alignItems: 'center' },
	header_text: { fontSize: 20, color: theme.colors.primary },
	textDate: {
		marginTop: 8,
		fontSize: 16,
		background: theme.colors.white,
		borderRadius: 20,
		height: 28,
		width: 28,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	input_row: { display: 'flex', justifyContent: 'space-between', marginTop: 10 },
	shift_wrap: { display: 'flex', justifyContent: 'space-between' },
	shift_sub_wrap: { display: 'flex', justifyContent: 'space-between', width: 250, alignItems: 'center' },
	date_label: { fontSize: 16, color: theme.colors.darkGrey2, marginBottom: 10 },
	date_input_wrap: { display: 'flex', alignItems: 'center', marginTop: 20, height: 70 },
	date_list_item: {
		borderRadius: 50,
		width: 40,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		marginRight: 15,
		cursor: 'pointer',
	},
	select_all_text: { fontSize: 14, color: theme.colors.darkPurple, marginTop: 20 },
	add_rider_wrap: { display: 'flex', justifyContent: 'space-between', marginTop: 20 },
	add_rider_input_wrap: {
		border: `1px solid ${theme.colors.lightPurple3}`,
		width: '100%',
		padding: '10px 20px',
		height: 40,
		borderRadius: 20,
		display: 'flex',
	},
	add_rider_input: {
		outline: 'none',
		border: 'none',
		marginLeft: 10,
		fontSize: 16,
		width: '100%',
	},
	search_rider_wrap: {
		marginTop: 5,
		position: 'absolute',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		marginLeft: 30,
		width: 300,
		zIndex: 1000,
		border: `1px solid ${theme.colors.lightGrey3}`,
		borderRadius: 10,
		background: 'white',
		overflowY: 'auto',
		maxHeight: 200,
	},
	rider_list_wrap: { width: '100%', height: 270, overflowY: 'auto', marginTop: 20 },
	delete_btn: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 20,
		display: 'flex',
		alignItems: 'center',
	},
	create_btn: { borderRadius: 25, height: 45, width: 110 },
	create_btn_wrap: { position: 'absolute', bottom: 30, right: 30 },
	rider_name_text: { color: 'black', marginLeft: 40, fontSize: 16 },
	rider_info: { display: 'flex', alignItems: 'center' },
	rider_id_text: { color: 'black', fontSize: 16 },
	rider_list_item: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 15,
	},
	custom_date_wrap: {
		width: 110,
		height: 32,
		borderRadius: 20,
		border: '1px solid black',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: 8,
		paddingLeft: 8,
		marginTop: 10,
	},
	date_pick_input: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
		border: `1px solid ${theme.colors.primary}`,
		padding: 10,
		borderRadius: 36,
		width: '100%',
		cursor: 'pointer',
		height: 40,
		width: 210,
		marginRight: 20,
	},
	divider: {
		borderBottom: '1px solid ' + theme.colors.lightGrey5,
	},
	leaves_box: {
		padding: 12,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 8,
		marginTop: 20,
	},
}));
