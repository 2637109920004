import { ListItem, Popover, TextField, makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _, { filter } from 'lodash';
import Button from 'modules/Button';
import ContainedButton from 'modules/ContainedButton';
import OutlinedButton from 'modules/OutlinedButton';
import Select from 'modules/Select';
import Text from 'modules/Text';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import api_requests from 'resources/api_requests';

const useStyles = makeStyles((theme) => ({
	search_input: {
		width: '80%',
		height: 32,
		border: '1px solid ' + theme.colors.lightPurple,
		borderRadius: 36,
		padding: '8px 12px',
		outline: 'none',
		'&::focus': {
			border: '2px solid ' + theme.colors.primary,
			outline: 'none',
		},
		'&::placeholder': {
			color: theme.colors.lightPurple,
			fontSize: 14,
			fontWeight: '500',
		},
	},
	filter_btn: {
		position: 'relative',
		backgroundColor: theme.colors.lightPurple,
		width: 32,
		height: 32,
		borderRadius: '100%',
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	filter_badge: {
		position: 'absolute',
		top: -7,
		right: -7,
		height: 18,
		width: 18,
		borderRadius: '50%',
		backgroundColor: theme.colors.lightPurple5,
		display: 'flex',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
	},
	filter_Wrapper: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		gap: 10,
		position: 'relative',
	},
	search_box_container: {
		flex: 1,
		border: `1px solid ${theme.colors.lightPurple6}`,
		borderRadius: 45,
		height: 35,
		display: 'flex',
		padding: '0px 10px',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	input_text_style: {
		outline: 'none',
		width: '95%',
		borderColor: 'transparent',
		fontSize: 14,
		'&::placeholder': {
			color: theme.colors.lightPurple6,
			fontSize: 12,
		},
	},
	popover_container: { backgroundColor: theme.colors.white, padding: 12, borderRadius: 8 },
	divider: { height: 2, width: '100%', backgroundColor: theme.colors.lightGrey5, marginTop: 5 },
	action_button: { border: `1px solid ${theme.colors.lightPurple6}`, padding: '2px 8px', borderRadius: 12, cursor: 'pointer' },
	action_button_active: { border: `1px solid ${theme.colors.primary}`, padding: '2px 8px', borderRadius: 12, cursor: 'pointer' },
	date_wrapper: {
		border: `1px solid ${theme.colors.lightPurple6}`,
		padding: '2px 8px',
		borderRadius: 12,
		display: 'flex',
		alignItems: 'center',
		gap: 3,
		marginRight: 10,
	},
	date_wrapper_active: {
		border: `1px solid ${theme.colors.primary}`,
		padding: '2px 8px',
		borderRadius: 12,
		display: 'flex',
		alignItems: 'center',
		gap: 3,
		marginRight: 10,
	},
	rider_row: {
		display: 'flex',
		width: 165,
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.lightGrey4,
		},
	},
	action_box: { display: 'flex', justifyContent: 'center', gap: 5, marginTop: 20, padding: 6, boxShadow: '0px -1.568px 5.488px rgba(0, 0, 0, 0.05)' },
	reset_button: { border: `1px solid ${theme.colors.darkGrey2} !important`, padding: '2px 8px', borderRadius: 12, height: 20, width: 63 },
	search_button: { padding: '2px 8px', borderRadius: 12, height: 20, width: 63 },
}));

function BundleListFilter({
	search,
	set_search,
	set_search_rider,
	search_rider,
	search_rider_type,
	set_search_rider_type,
	search_date,
	set_search_date,
	SEARCH_BY_OPTIONS,
	check_disabled,
	bundles,
	selected_menu,
	MENU,
}) {
	const classes = useStyles();
	const theme = useTheme();
	const [show_filter, set_show_filters] = useState(false);
	const [search_by, set_search_by] = useState(SEARCH_BY_OPTIONS[0].value);
	const [search_rider_type_local, set_search_rider_type_local] = useState(null);
	const [local_date, set_local_date] = useState(search_date);
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [search_riders_captive, set_search_riders_captive] = useState([]);
	const [selected_rider_captive, set_selected_rider_captive] = useState(null);
	const [search_rider_text, set_search_rider_text] = useState('');
	const filter_button_ref = useRef();
	const created_at_ref = useRef();

	const handle_search_click = () => {
		set_search_rider_type(search_rider_type_local);
		set_search_date(local_date);
		set_show_filters(false);
	};

	useEffect(() => {
		if (_.isEmpty(search_rider_text)) {
			set_search_riders_captive([]);
			set_selected_rider_captive(null);
			return;
		}
		api_requests
			.search_rider_without_zone({
				search: search_rider_text,
				is_active: true,
			})
			.then((res) => {
				set_search_riders_captive(res.data);
			});
	}, [search_rider_text]);

	const handle_reset_click = () => {
		set_search_rider_type(null);
		set_search_rider_type_local(null);
		set_local_date(null);
		set_search_date(null);
		set_search_rider('');
		set_search_rider_text('');
		set_search('');
	};

	const handle_cross_click = () => {
		set_search_rider('');
		set_search_rider_text('');
		set_search('');
	};

	return (
		<div className={classes.filter_Wrapper}>
			<div className={classes.search_box_container} ref={filter_button_ref}>
				<Select
					value={search_by}
					options={SEARCH_BY_OPTIONS}
					handleChange={(val) => {
						set_search_by(val);
						set_search('');
						set_search_rider('');
					}}
					containerStyles={{ height: 17, width: 55, padding: 5 }}
					text_styles={{ fontSize: 12 }}
					text_props={{ bold: true }}
					right_icon={ImageLinks.arrow_up_pink}
					image_styles={{ width: 5, height: 3 }}
					menu_styles={{ marginTop: 30 }}
				/>
				<div style={{ flex: 1, marginLeft: 6 }}>
					{search_by === SEARCH_BY_OPTIONS[0].value ? (
						<input
							value={search_rider_text}
							disabled={
								_.filter(bundles, (bundle, index) => {
									if (check_disabled(bundle) && selected_menu === MENU.add) {
										return false;
									}
									return true;
								}).length === 0
							}
							onChange={(e) => set_search_rider_text(e.target.value)}
							className={classes.input_text_style}
							placeholder={'Rider ID'}
							onFocus={(e) => e.preventDefault()}
						/>
					) : (
						<input
							value={search}
							disabled={
								_.filter(bundles, (bundle, index) => {
									if (check_disabled(bundle) && selected_menu === MENU.add) {
										return false;
									}
									return true;
								}).length === 0
							}
							onChange={(e) => set_search(e.target.value)}
							className={classes.input_text_style}
							placeholder={'Group Name'}
							onFocus={(e) => e.preventDefault()}
						/>
					)}
				</div>
				{(search.length > 0 || search_rider_text.length > 0) && (
					<div style={{ flex: 0.2, height: 16, width: 16 }}>
						<img src={ImageLinks.cross_filled_purple} style={{ cursor: 'pointer' }} height={16} width={16} onClick={handle_cross_click}></img>
					</div>
				)}
			</div>
			<ContainedButton className={classes.filter_btn} onClick={() => set_show_filters((prev) => !prev)}>
				{(search_rider_type === null ? 0 : 1) + (search_date === null ? 0 : 1) > 0 && (
					<div className={classes.filter_badge}>
						<Text bold style={{ fontSize: 12 }}>
							{(search_rider_type === null ? 0 : 1) + (search_date === null ? 0 : 1)}
						</Text>
					</div>
				)}
				<img className='purple-arrow' width={17} height={12} src={ImageLinks.filter_pink} alt='' />
				<img className='white-arrow' style={{ display: 'none' }} width='18' src={ImageLinks.filter} alt='' />
			</ContainedButton>
			<div
				style={{
					flex: 1,
					overflowY: 'hidden',
					position: 'absolute',
					zIndex: 10,
					background: theme.colors.white,
					top: 40,
					left: 0,
					boxShadow: '0px 2.07353px 4.14706px rgba(196, 196, 196, 0.3)',
				}}>
				{!_.isEmpty(search_riders_captive) && !selected_rider_captive && (
					<div>
						{_.map(search_riders_captive, (rider, key) => {
							return (
								<ListItem
									button
									key={`rider${key}`}
									className={classes.rider_row}
									style={{
										...(selected_rider_captive === rider.rider_id && {
											border: `1px solid ${theme.colors.primary}`,
											background: theme.colors.lightPurple,
										}),
									}}
									onClick={() => {
										set_selected_rider_captive(rider.rider_id);
										set_search_rider(rider.rider_id);
									}}>
									<Text semi style={{ flex: 3, fontSize: 12 }}>
										{rider.rider_name} ({rider.rider_id})
									</Text>
								</ListItem>
							);
						})}
					</div>
				)}
			</div>
			<Popover
				open={show_filter}
				anchorEl={filter_button_ref.current}
				onClose={() => {
					set_show_filters(false);
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{ vertical: -10 }}>
				<div className={classes.popover_container} style={{ height: date_picker_open ? 280 : 110, width: date_picker_open ? 220 : 220 }}>
					<Text semi style={{ fontSize: 12 }}>
						Filter By
					</Text>
					<div className={classes.divider}></div>
					<div className='d-flex align-items-center' style={{ marginTop: 10, gap: 5 }}>
						<div style={{ width: '25%' }}>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Rider
							</Text>
						</div>
						<div className='d-flex' style={{ gap: 10 }}>
							<div
								className={search_rider_type_local === 'linked' ? classes.action_button_active : classes.action_button}
								onClick={() => set_search_rider_type_local('linked')}>
								<Text
									semi
									style={{ fontSize: 12, color: search_rider_type_local === 'linked' ? theme.colors.primary : theme.colors.lightPurple6 }}>
									Linked
								</Text>
							</div>
							<div
								className={search_rider_type_local === 'unlinked' ? classes.action_button_active : classes.action_button}
								onClick={() => set_search_rider_type_local('unlinked')}>
								<Text
									semi
									style={{ fontSize: 12, color: search_rider_type_local === 'unlinked' ? theme.colors.primary : theme.colors.lightPurple6 }}>
									Unlinked
								</Text>
							</div>
						</div>
					</div>
					<div className='d-flex align-items-center' style={{ marginTop: 10, gap: 5 }}>
						<div style={{ width: '25%' }} ref={created_at_ref}>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Created Date
							</Text>
						</div>
						<div style={{ gap: 10 }}>
							<div style={{ width: '100%' }}>
								<DatePicker
									className={classes.date_picker_padding}
									dateFormat='yyyy-MM-dd'
									selected={local_date}
									onChange={(date) => {
										set_local_date(date);
									}}
									onCalendarClose={() => set_date_picker_open(false)}
									onCalendarOpen={() => set_date_picker_open(true)}
									customInput={
										<div className={local_date === null ? classes.date_wrapper : classes.date_wrapper_active}>
											<Text
												semi
												style={{
													fontSize: 12,
													color: local_date === null ? theme.colors.lightPurple6 : theme.colors.primary,
													width: '80%',
												}}>
												{local_date ? moment(local_date).format('DD/MM/YYYY') : 'DD/MM/YYYY'}
											</Text>
											<img src={ImageLinks.calendar_solid_circle} alt='date' className='cursor-pointer' width={22} height={22} />
										</div>
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={classes.action_box}>
					<Button
						size='extra_small'
						text='Reset'
						type='outlined'
						left_icon={
							search_rider_type_local === null && local_date === null
								? ImageLinks.refresh_single_arrow_grey
								: ImageLinks.refresh_single_arrow_purple
						}
						disabled={search_rider_type_local === null && local_date === null}
						onClick={handle_reset_click}
					/>

					<Button
						size='extra_small'
						text='Search'
						type='round'
						disabled={search_rider_type_local === null && local_date === null}
						onClick={handle_search_click}
					/>
				</div>
			</Popover>
		</div>
	);
}

export default BundleListFilter;
