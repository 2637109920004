import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	wrapper: {
		padding: 20,
		display: 'flex',
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 6,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.white, 90),
		justifyContent: 'space-between',
	},
	text_wrap: {
		flex: 1,
		flexDirection: 'column',
	},
	add_rider_text: {
		fontSize: 12,
		color: theme.colors.primary,
		textDecoration: 'underline',
	},
}));

const NoNetwork = ({ has_partners, handle_network_nav }) => {
	const classes = useStyles();
	return (
		<div className={clsx(classes.wrapper, has_partners && 'align-items-center')}>
			<div className={clsx(`d-flex justify-content-between`, classes.text_wrap)}>
				<div>
					{has_partners ? (
						<Text semi style={{ fontSize: 16 }}>
							'Uh-oh, no network partners available!'
						</Text>
					) : (
						<>
							<Text semi style={{ fontSize: 16 }}>
								{`Looking for Pidge-powered`}
							</Text>
							<Text semi style={{ fontSize: 16 }}>
								{`local delivery partners?`}
							</Text>
						</>
					)}

					<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
						{has_partners ? 'Try again in some time or try changing order size and pickup locations.' : ''}
					</Text>
				</div>
				{!has_partners && (
					<div className='align-items-center cursor-pointer' onClick={handle_network_nav}>
						<Text semi className={classes.add_rider_text}>
							Discover Now
						</Text>
						<img src={ImageLinks.arrow_right_purple} style={{ marginLeft: 6 }} />
					</div>
				)}
			</div>

			<img src={ImageLinks.networks_web_light_purple} style={{ opacity: has_partners ? 0.5 : 1 }} width={100} height={100} />
		</div>
	);
};

export default NoNetwork;
