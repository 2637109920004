import { useState, useMemo, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Divider, makeStyles, Modal, ListItem, TextField, InputAdornment } from '@material-ui/core';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import Upload from 'modules/Upload';
import ContainedButton from 'modules/ContainedButton';
import api_requests from 'resources/api_requests';
import OutlinedTextField from 'modules/OutlinedTextField';
import constant from 'resources/constant';
import TimeInput from 'modules/TimeInput';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 20,
		background: 'white',
		width: 432,
		height: 'auto',
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	header_text: { fontSize: 20 },
	action_container: {
		display: 'flex',
		justifyContent: 'center',
		gap: 15,
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		height: 172,
		width: 262,
		borderRadius: 8,
	},
	action_container_active: {
		display: 'flex',
		justifyContent: 'center',
		gap: 15,
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		height: 172,
		width: 262,
		borderRadius: 8,
		border: `1px solid ${theme.colors.primary}`,
		backgroundColor: theme.colors.lightPink4,
	},
	action_container_disabled: {
		display: 'flex',
		justifyContent: 'center',
		gap: 15,
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		height: 172,
		width: 262,
		borderRadius: 8,
		backgroundColor: theme.colors.lightGrey7,
	},
	delete_container: {
		backgroundColor: theme.colors.lightRed,
		cursor: 'pointer',
		height: 33,
		width: 33,
		borderRadius: 16,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	header_title: { fontSize: 18, width: '100%', paddingTop: 5 },
	text_container: { fontSize: 14, marginTop: 8 },
	final_stage_container: {
		backgroundColor: theme.colors.lightGrey7,
		height: 218,
		width: 540,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 15,
		textAlign: 'center',
		marginTop: 16,
	},
	final_button_style: {
		borderRadius: 30,
		width: 130,
	},
	upload_csv_content_container: {
		width: '100%',
		minHeight: 174,
		minWidth: 196,
		borderRadius: 10,
		border: `1px dashed ${theme.colors.lightGrey2}`,
		marginTop: 20,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
	date_wrapper: {
		border: `1px solid ${theme.colors.lightPurple}`,
		padding: '2px 8px',
		borderRadius: 36,
		display: 'flex',
		alignItems: 'center',
		gap: 3,
		height: 36,
		width: 120,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		cursor: 'pointer',
	},
}));

function OdometerModels({
	close,
	start_km,
	assingment_id,
	odometer_distance,
	odometer_image,
	updated_at,
	updated_by,
	update_data,
	rider_name,
	checkin_time,
	odometer_start,
	checkout_time,
	odometer_end,
	get_vehicle_list,
}) {
	const classes = useStyles();
	const current_time = moment();
	const remainder = current_time.minutes() % 15;
	const rounded_time = moment(current_time).subtract(remainder, 'minutes');

	const { user_details } = useSelector((state) => state.app, shallowEqual);
	const [distance, set_distance] = useState(odometer_distance ? odometer_distance : '');
	const [images, set_images] = useState(odometer_image || '');
	const [edit_input, set_edit_input] = useState(false);
	const [check_time, set_check_time] = useState(rounded_time);
	const [check_date, set_check_date] = useState(moment().format('DD-MM-YYYY'));

	useEffect(() => {
		if (start_km && !_.isEmpty(checkin_time)) {
			set_check_time(moment(checkin_time).format('hh:mm a'));
			set_check_date(moment(checkin_time).format('DD-MM-YYYY'));
		}
		if (!start_km && !_.isEmpty(checkout_time)) {
			set_check_time(moment(checkout_time).format('hh:mm a'));
			set_check_date(moment(checkout_time).format('DD-MM-YYYY'));
		}
	}, []);

	const update_by_name = useMemo(() => {
		let owner_text = constant.USER_TYPE_TEXT[updated_by?.type];
		return owner_text;
	}, []);

	const heder_text = useMemo(() => {
		let text = '';
		text =
			start_km && !_.isEmpty(odometer_image)
				? `Edit Start`
				: start_km && _.isEmpty(odometer_image)
				? `Add Start`
				: !start_km && !_.isEmpty(odometer_image)
				? `Edit End`
				: `Add End`;
		return text;
	}, []);

	const btn_disabled = useMemo(() => {
		let value = true;
		if (!_.isEmpty(check_time)) {
			value = false;
		}
		return value;
	}, [distance, check_time]);

	const handle_update_input_valid_int = (e, updater_function) => {
		const value = e.target.value;
		const pattern = /^[-+]?\d+$/;
		if (value == '' || pattern.test(value)) updater_function(value);
	};

	const handle_update = async () => {
		let check_time_with_date = moment(check_date + ' ' + check_time, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') + '+5:30';
		var data = {
			updated_by: {
				id: user_details.user_id,
				type: user_details?.user_type || '1',
			},
			[start_km ? `checkin_time` : `checkout_time`]: check_time_with_date,
		};

		if (!_.isEmpty(images)) {
			_.assign(data, {
				[!_.isEmpty(images) ? (start_km ? `checkin_images` : `checkout_images`) : '']: [images],
			});
		}
		if (distance !== null) {
			_.assign(data, {
				[start_km ? `odometer_start` : `odometer_end`]: distance,
			});
		}
		try {
			if (!start_km && _.isEmpty(checkin_time)) {
				toast.error('Please Enter Check-In Details First', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			} else if (odometer_start !== null && !start_km && distance !== null && odometer_start > distance) {
				toast.error('Odometer Odometer End Reading > start Reading', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			} else if (odometer_end !== null && start_km && distance !== null && odometer_end < distance) {
				toast.error('Odometer start Reading < Odometer End Reading', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			} else if (!_.isEmpty(checkin_time) && !start_km && moment(moment(checkin_time).format()).isAfter(moment(check_time_with_date).format())) {
				toast.error('Check-In Time < Check-Out Time', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			} else if (!_.isEmpty(checkout_time) && start_km && moment(moment(checkout_time).format()).isBefore(moment(check_time_with_date).format())) {
				toast.error('Check-In Time < Check-Out Time', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			} else if (moment(check_time_with_date).isAfter(moment())) {
				toast.error(`Check-${start_km ? 'In' : 'Out'} Time > Current Time`, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			} else {
				const res = await api_requests.update_odometer_reading(data, assingment_id);
				if (res.success) {
					update_data();
					get_vehicle_list({});
					close();
				}
			}
		} catch (err) {}
	};

	const handle_img_upload = async (files) => {
		set_images(files);
	};

	return (
		<Modal open={true} onClose={() => close(false)} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className={classes.header_wrap}>
					<Text bold className={classes.header_text}>
						{heder_text} Odometer Reading
					</Text>
					<img src={ImageLinks.crossBlack} onClick={() => close(false)} style={{ cursor: 'pointer' }} alt='' />
				</div>
				<div className='d-flex' style={{ gap: 16, marginTop: 10 }}>
					<Upload
						accepted_extensions='..jpg,.jpeg,.png'
						add_image={(data) => handle_img_upload(data.url)}
						path={`/assignment/${assingment_id}/user_${user_details.user_id}/odometer_${Date.now()}`}
						is_image_crop={true}>
						<ListItem button className={classes.upload_csv_content_container}>
							<img
								width={_.isEmpty(images) ? 50 : 178}
								height={_.isEmpty(images) ? 50 : 162}
								src={_.isEmpty(images) ? ImageLinks.upload_light_purple : images}
							/>
							{_.isEmpty(images) && (
								<>
									<Text style={{ fontSize: 12, marginTop: 10, marginBottom: 12 }}>Upload Odometer Image</Text>
									<Button style={{ width: 110 }} type='round' text='Select File' size='small' />
								</>
							)}
						</ListItem>
					</Upload>
					<div className='d-flex justify-content-center' style={{ gap: 8 }}>
						<div style={{ marginTop: 25 }}>
							<div className='d-flex justify-content-center align-items-center' style={{ gap: 8 }}>
								<OutlinedTextField
									size='small'
									disabled={edit_input}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Text style={{ fontSize: '12', color: theme.colors.primary }}>KM</Text>
											</InputAdornment>
										),
									}}
									label='Odometer Reading'
									value={distance}
									onChange={(e) => handle_update_input_valid_int(e, set_distance)}
									variant='outlined'
								/>
							</div>
							<div style={{ marginTop: 12 }}>
								<Text style={{ marginTop: 4, fontSize: 12, marginBottom: 4, color: theme.colors.darkGrey2 }}>
									{start_km ? 'Check-In Date' : 'Check-Out Date'}
								</Text>
								<DatePicker
									dateFormat='yyyy-MM-dd'
									selected={moment(check_date, 'DD-MM-YYYY').toDate()}
									onChange={(date) => {
										set_check_date(moment(date).format('DD-MM-YYYY'));
									}}
									maxDate={new Date()}
									customInput={
										<div className={classes.date_wrapper}>
											<Text semi style={{ fontSize: 14, color: theme.colors.primary, width: '80%' }}>
												{moment(check_date, 'DD-MM-YYYY').format('DD MMM `YY')}
											</Text>
											<img src={ImageLinks.calendar_solid_circle} alt='date' className='cursor-pointer' width={16} height={16} />
										</div>
									}
								/>
							</div>
							<div style={{ marginTop: 12 }}>
								<Text style={{ marginTop: 4, fontSize: 12, marginBottom: 4, color: theme.colors.darkGrey2 }}>
									{start_km ? 'Check-In *' : 'Check-Out *'}
								</Text>
								<TimeInput value={check_time} handleChange={(val) => set_check_time(val)} style={{ marginTop: 4 }} />
							</div>
							<Divider className={classes.divider} />
							<div className='d-flex' style={{ gap: 12 }}>
								<div>
									<Text semi style={{ fontSize: 10 }}>
										Updated By
									</Text>
									<Text medium style={{ fontSize: 9 }}>
										{start_km
											? !_.isEmpty(checkin_time)
												? `${update_by_name} (${updated_by?.id})`
												: ''
											: !_.isEmpty(checkout_time)
											? `${update_by_name} (${updated_by?.id})`
											: ''}
									</Text>
								</div>
								<div>
									<Text semi style={{ fontSize: 10 }}>
										Updated On
									</Text>
									<Text medium style={{ fontSize: 9 }}>
										{start_km
											? !_.isEmpty(checkin_time)
												? moment(updated_at).format("DD MMM 'YY, hh:mmA")
												: ''
											: !_.isEmpty(checkout_time)
											? moment(updated_at).format("DD MMM 'YY, hh:mmA")
											: ''}
									</Text>
								</div>
							</div>
						</div>
					</div>
				</div>
				{start_km && (
					<div className='d-flex align-items-center' style={{ gap: '0.75rem', marginTop: 14 }}>
						<img src={ImageLinks.info_filled_yellow} width={20} height={20} />
						<Text medium style={{ fontSize: '0.75rem' }}>
							Please note: By adding start odometer reading you are also confirming rider checkin
						</Text>
					</div>
				)}
				<div className='d-flex justify-content-center align-items-center' style={{ marginTop: 30 }}>
					<Button style={{ width: 110 }} type='round' text='Update' size='large' disabled={btn_disabled} onClick={handle_update} />
				</div>
			</div>
		</Modal>
	);
}

export default OdometerModels;
