import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import _ from 'lodash';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import OutlinedButton from 'modules/OutlinedButton';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	pincode_scroll: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: 12,
		maxHeight: 175,
		overflow: 'auto',
		'&::-webkit-scrollbar': {
			background: theme.colors.lightGrey13,
			width: 8,
		},

		/* Track */
		'&::-webkit-scrollbar-track': {
			width: 4,
			background: theme.colors.lightGrey13,
			borderRadius: 60,
		},

		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: theme.colors.darkPurple,
			borderRadius: 60,
		},
	},
	pincode_header_delete: {
		'&:hover': {
			'& .pincode_header_delete_text': {
				display: 'block !important',
			},
		},
	},
	valid_pin_wrap: {
		flexBasis: '33.33333%',
		height: 41,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		display: 'flex',
		alignItems: 'center',
	},
	valid_pin_item: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 4,
		height: 25,
		paddingLeft: 4,
		paddingRight: 4,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.colors.lightPink4,
			'& .pincode_item_delete': {
				display: 'block !important',
			},
		},
	},
	empty_pin_content_wrap: {
		borderRadius: 4,
		height: 80,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 10,
		paddingRight: 40,
	},
	empty_wrap: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' },
	tooltip: {
		backgroundColor: theme.colors.lightPurple,
		color: theme.colors.lightPurple7,
		fontWeight: 500,
	},
	arrow: { color: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40) },
}));

const PolygonOutput = ({ polygons, handle_individual_polygon_delete, handle_all_polygon_delete }) => {
	const classes = useStyles();
	return (
		<>
			{polygons.length === 0 && (
				<div className={classes.empty_wrap}>
					<div className={classes.empty_pin_content_wrap} style={{ backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40) }}>
						<img src={ImageLinks.info_light_purple} width={32} height={32} />
						<div style={{ marginLeft: 12 }}>
							<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
								Mark multiple areas in one go
							</Text>
							<div className='align-items-center' style={{ marginTop: 4 }}>
								<Text medium style={{ fontSize: 10, color: theme.colors.primary }}>
									Click on
								</Text>
								<OutlinedButton style={{ borderRadius: 15, height: 15, marginLeft: 4, marginRight: 4 }}>
									<img src={ImageLinks.polygon_purple} width={8} height={8} style={{ marginRight: 2 }} />
									<Text semi style={{ fontSize: 7, color: theme.colors.primary }}>
										Draw Polygon
									</Text>
								</OutlinedButton>
								<Text medium style={{ fontSize: 10, color: theme.colors.primary }}>
									to add more areas to the map
								</Text>
							</div>
						</div>
					</div>
				</div>
			)}
			{polygons.length > 0 && (
				<div style={{ marginTop: 16 }}>
					<div className='align-items-center justify-content-between'>
						<div className='align-items-center'>
							<Text bold style={{ fontSize: 16, marginRight: 4 }}>
								{polygons.length} Area{polygons.length > 1 ? 's' : ''}
							</Text>
							<BootstrapTooltip
								placement='bottom-start'
								classes={{
									tooltip: classes.tooltip,
									arrow: classes.arrow,
								}}
								title={
									<div className={classes.empty_pin_content_wrap}>
										<img src={ImageLinks.info_light_purple} width={32} height={32} />
										<div style={{ marginLeft: 12 }}>
											<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
												Mark multiple areas in one go
											</Text>
											<div className='align-items-center' style={{ marginTop: 4, flexWrap: 'wrap' }}>
												<Text medium style={{ fontSize: 10, color: theme.colors.primary }}>
													Click on
												</Text>
												<OutlinedButton style={{ borderRadius: 15, height: 15, marginLeft: 4, marginRight: 4 }}>
													<img src={ImageLinks.polygon_purple} width={8} height={8} style={{ marginRight: 2 }} />
													<Text semi style={{ fontSize: 7, color: theme.colors.primary }}>
														Draw Polygon
													</Text>
												</OutlinedButton>
												<Text medium style={{ fontSize: 10, color: theme.colors.primary }}>
													to add more areas to the map.Polygon is clickable in map.
												</Text>
											</div>
										</div>
									</div>
								}>
								<img src={ImageLinks.info_light_purple} width={16} height={16} />
							</BootstrapTooltip>
						</div>
						<div onClick={handle_all_polygon_delete} className={clsx(classes.pincode_header_delete, 'align-items-center', 'cursor-pointer')}>
							<img src={ImageLinks.delete} width={18} height={18} />
							<Text semi className={'pincode_header_delete_text'} style={{ fontSize: 12, color: theme.colors.red, display: 'none' }}>
								Delete All
							</Text>
						</div>
					</div>
					<div className={'dashed-line'}></div>
					<div className={classes.pincode_scroll}>
						{_.map(polygons, (poly_item, poly_index) => (
							<div key={`valid_pin_${poly_index}`} className={classes.valid_pin_wrap} style={{ flexBasis: '50%' }}>
								<div className={classes.valid_pin_item}>
									<img src={ImageLinks.polygon_purple} width={16} height={16} />
									<Text semi style={{ fontSize: 14, marginRight: 30, marginLeft: 4 }}>
										{poly_item?.name}
									</Text>
									<img
										onClick={() => handle_individual_polygon_delete(poly_index)}
										className='pincode_item_delete'
										style={{ display: 'none' }}
										src={ImageLinks.delete}
										width={16}
										height={16}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default PolygonOutput;
