import { makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { hide_loader, show_loader } from 'actions/app';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api_requests from 'resources/api_requests';
import SyncOrders from './components/SyncOrders';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between' },
	header_title: { fontSize: 22, marginRight: 50 },
	active_tab: { backgroundColor: theme.colors.primary, color: theme.colors.white, padding: '8px 16px', borderRadius: 8, width: 'auto' },
	token_container: {
		padding: 12,
		backgroundColor: theme.colors.white,
		width: '60%',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 6,
		cursor: 'pointer',
	},
	divider: { height: '1px', width: '100%', backgroundColor: theme.colors.lightGrey2, margin: '8px 0' },
	tokens_wrapper: { display: 'flex', gap: 16, flexDirection: 'column', overflow: 'auto', height: '85vh' },
}));

function IntegrationManagement() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const [tokens, set_tokens] = useState([]);

	const { is_loading } = useSelector((state) => state.app, shallowEqual);

	const get_tokens = async () => {
		dispatch(show_loader());
		try {
			const res = await api_requests.get_tokens();
			if (res.data) {
				set_tokens(res.data.vendor);
				dispatch(hide_loader());
			}
		} catch (err) {
			dispatch(hide_loader());
		}
	};

	useEffect(() => {
		get_tokens();
	}, []);

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<div className='align-items-center'>
					<div className='page_back_btn' onClick={() => history.goBack()}>
						<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
					</div>
					<Text semi className={'global_header_text'}>
						Integration Management
					</Text>
				</div>

				{tokens.length > 0 && (
					<div>
						<Button
							type='round'
							size='large'
							text='Generate Token'
							left_icon={ImageLinks.addWhite}
							onClick={() => history.push('/integration-management/generate-token')}
						/>
					</div>
				)}
			</div>
			{tokens.length > 0 && (
				<div style={{ display: 'flex', marginTop: 32 }}>
					<Text bold className={classes.active_tab}>
						Tokens ({tokens.length})
					</Text>
				</div>
			)}
			<div className={classes.divider} />
			<div className={classes.tokens_wrapper}>
				{_.map(tokens, (token, key) => {
					return (
						<div
							key={`token_list_${key}`}
							className={classes.token_container}
							onClick={() => history.push('/integration-management/edit-token/' + token.id)}>
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<div>
									<Text bold style={{ fontSize: 18 }}>
										{token.channel}
									</Text>
								</div>
								<div>
									<Text semi style={{ fontSize: 14 }}>
										{moment(token.created_at).format('DD MMM hh:mm A')}
									</Text>
								</div>
							</div>
							<div className={classes.divider} />
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<div style={{ flex: 1 }}>
									<Text style={{ fontSize: 12 }}>Username:{token.username}</Text>
								</div>
								<div style={{ flex: 1 }}></div>
							</div>
						</div>
					);
				})}
			</div>
			{tokens.length === 0 && !is_loading && <SyncOrders />}
		</div>
	);
}

export default IntegrationManagement;
