import Text from 'modules/Text';
import _ from 'lodash';
import React, { useEffect } from 'react';
import cross from 'assets/images/icons/cross.svg';
import { Modal } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';

import UndlerlineTextField from 'modules/UndlerlineTextField';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import Button from 'modules/Button';

let map = null;

const initial_form_details = {
	name: '',
	receiver_details: {
		name: '',
		mobile: '',
	},
};

const CreateWarehouse = ({ close, address_id, lat, lng, address_line, instructions }) => {
	const [form_data, set_form_data] = React.useState({ ...initial_form_details, address_id });

	const handleClose = () => {
		close();
	};

	const initialize = () => {
		map = new window.google.maps.Map(document.getElementById('map_canvas'), {
			center: { lat: Number(lat), lng: Number(lng) },
			zoom: 15,
			clickableIcons: false,
			disableDefaultUI: true,
			scaleControl: true,
			zoomControl: true,
			mapId: constant.MAP_DESIGN_ID,
		});

		new window.google.maps.Marker({
			position: { lat: Number(lat), lng: Number(lng) },
			map,
		});
	};

	const create_warehouse = () => {
		api_requests.create_warehouse(form_data).then((res) => {
			close(true);
		});
	};

	const handle_change = (name, value) => {
		const _form_data = _.cloneDeep(form_data);
		_.set(_form_data, name, value);
		set_form_data(_form_data);
	};

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, []);

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div
				style={{
					padding: 30,
					background: 'white',
					width: 600,
					height: 650,
					margin: 'auto auto',
					borderRadius: 20,
					display: 'flex',
					flexDirection: 'column',
					outline: 'none',
				}}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', alignItems: 'center', height: 30 }}>
						<Text bold style={{ fontSize: 24 }}>
							Create Warehouse
						</Text>
					</div>
					<img src={cross} onClick={handleClose} style={{ cursor: 'pointer' }} />
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
					<div style={{ flex: 1, marginTop: 16, borderRadius: 46 }}>
						<div id='map_canvas' className='map-canvas-wrapper rider-location card' style={{ height: '100%' }} />
					</div>
				</div>

				<div style={{ display: 'flex', justifyContent: 'space-between', padding: 20, borderBottom: `1px dashed ${theme.colors.primary}` }}>
					<div style={{ width: '45%' }}>
						<Text style={{ color: theme.colors.primary, fontSize: 12 }}>Address</Text>
						<Text style={{ marginTop: 5, lineHeight: 1.4 }}>{address_line}</Text>
					</div>

					<div style={{ marginLeft: 20, width: '45%' }}>
						<Text style={{ color: theme.colors.primary, fontSize: 12 }}>Instructions to Reach</Text>
						<Text style={{ marginTop: 5, lineHeight: 1.4 }}>{instructions || '-'}</Text>
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 35 }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
						<UndlerlineTextField
							style={{ width: '45%' }}
							label='Name of POC*'
							value={form_data.receiver_details.name}
							onChange={(e) => handle_change('receiver_details.name', e.target.value)}
						/>
						<UndlerlineTextField
							style={{ width: '45%' }}
							type='number'
							label='Contact of POC*'
							value={form_data.receiver_details.mobile}
							onChange={(e) => handle_change('receiver_details.mobile', e.target.value)}
						/>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
						<UndlerlineTextField
							style={{ width: '45%' }}
							label='Warehouse Name*'
							value={form_data.name}
							onChange={(e) => handle_change('name', e.target.value)}
						/>
					</div>
				</div>
				<Button
					size='large'
					type='round'
					text='Next'
					onClick={create_warehouse}
					disabled={
						_.isEmpty(form_data.receiver_details.name) ||
						_.isEmpty(form_data.receiver_details.mobile) ||
						_.isEmpty(form_data.name) ||
						form_data.receiver_details.mobile.length !== 10
					}
					style={{ margin: '0 auto', width: 110 }}
				/>
			</div>
		</Modal>
	);
};

export default CreateWarehouse;
