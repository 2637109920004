import { CircularProgress, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useContext, useMemo, useState } from 'react';
import theme from 'resources/theme';
import SectionHeader from './SectionHeader';
import OutlinedTextField from 'modules/OutlinedTextField';
import ContainedButton from 'modules/ContainedButton';
import CreateNetworkContext from '../../context';
import { ICON_STYLE, LOWER_SECTION_STEPS, NETWORK_TYPE, UPPER_SECTION_STEPS } from '../helper';
import NetworkDisplayModal from './NetworkDisplayModal';
import NetworkAvatar from './NetworkAvatar';
import utils from 'resources/utils';
import StepItem from './StepItem';
import clsx from 'clsx';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	wrapper: { display: 'flex', justifyContent: 'space-between' },
	char_count_wrap: { position: 'absolute', right: 8, bottom: 8 },
	visibility_section: {
		display: 'flex',
		flex: 0.2,
		flexDirection: 'column',
	},
	visibility_item_wrap: {
		height: 80,
		borderRadius: 6,
		padding: 12,
		marginTop: 12,
		display: 'flex',
		alignItems: 'center',
		border: `1px solid ${theme.colors.primary}`,
	},
	identity_section: {
		display: 'flex',
		flex: 0.4,
		flexDirection: 'column',
	},
	display_section: {
		display: 'flex',
		flex: 0.2,
		flexDirection: 'column',
	},
	proceed_btn_wrap: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},
	steps_wrap: {
		borderRadius: 4,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		padding: 10,
		flex: 1,
		marginTop: 50,
	},
	steps_counter_wrap: {
		width: 18,
		height: 18,
		borderRadius: 9,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 4,
	},
	steps_item_wrap: {
		display: 'flex',
		marginBottom: 16,
		alignItems: 'center',
	},
	steps_desc: { fontSize: 8, fontSize: 10, color: theme.colors.primary },
	monogram_wrap: {
		width: 80,
		height: 80,
		borderRadius: 40,
		marginRight: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	edit_icon: {
		position: 'absolute',
		bottom: 0,
		right: 25,
		cursor: 'pointer',
	},
	steps_separator: { borderBottom: `1px solid ${theme.colors.lightPurple6}`, marginBottom: 14, paddingBottom: 8 },
	fix_cta_btn: {
		position: 'absolute',
		bottom: 0,
		backgroundColor: theme.colors.lightPink4,
		width: '100%',
		boxShadow: `0px -1px 4px 0px rgba(73, 40, 151, 0.08)`,
	},
}));

const CreateNetworkUpperSection = () => {
	const {
		network_name,
		// is_name_valid,
		network_desc,
		network_image,
		show_lower_section,
		handle_network_name,
		set_network_desc,
		show_display_modal,
		set_show_display_modal,
		display_configs,
		handle_network_img,
		handle_go_live,
		get_task_progress,
		edit_network_id,
		handle_edit_network_config,
		handle_create_network,
		set_confirm_close_modal,
		is_invite_allocation_updated,
		is_invite_pricing_updated,
		is_invite_service_updated,
		is_invite_support_updated,
		is_invite_roles_updated,
		is_invite_surge_price,
		is_invite_cancellation_price,
		is_invite_reattempt_config,
	} = useContext(CreateNetworkContext);
	const classes = useStyles();

	const handle_confirm = () => {
		set_confirm_close_modal({
			open: true,
			header_title: 'Network Creation',
			title: 'Are you sure you want to proceed? This cannot be changed later.',
			positive_text: 'Confirm',
			negative_text: 'Cancel',
			confirm_acion: handle_create_network,
		});
	};

	const proceed_disabled = useMemo(() => {
		let value = true;
		if (
			!_.isEmpty(network_name.trim()) &&
			(!_.isEmpty(network_image) || !_.isEmpty(display_configs)) &&
			!_.isEmpty(network_desc) &&
			// typeof is_name_valid === 'boolean' &&
			// is_name_valid &&
			network_desc?.length <= 100
		)
			value = false;
		return value;
	}, [network_name, network_image, network_desc, display_configs]);

	let toal_progess = 0;
	let all_steps_done = false;
	const selected_icon_style_val = _.filter(ICON_STYLE, (item) => item.key === display_configs?.icon_style)[0];

	let total = 0;
	let steps_done = 0;
	for (let i = 0; i < LOWER_SECTION_STEPS.length; i++) {
		const item_progress = get_task_progress(i, true);
		if (item_progress >= 100) steps_done += 1;
		total += item_progress;
		toal_progess += item_progress;
	}
	all_steps_done = toal_progess >= (LOWER_SECTION_STEPS.length - 1) * 100;

	const disable_fields = edit_network_id || show_lower_section;

	return (
		<div className={classes.wrapper}>
			<div className={classes.visibility_section}>
				<SectionHeader title={'Network Visiblity'} description={'Select how members can join the network'} />

				{_.map(NETWORK_TYPE, (type_item, type_index) => (
					<div
						key={`visibility_type_${type_index}`}
						className={classes.visibility_item_wrap}
						style={{
							boxShadow: type_item.is_disabled ? 'unset' : `0px 0px 6px 1px rgba(178, 157, 226, 0.5)`,
							backgroundColor: type_item.is_disabled ? theme.colors.lightGrey5 : theme.colors.white,
							cursor: type_item.is_disabled ? 'unset' : 'pointer',
							borderWidth: type_item.is_disabled ? 0 : 1,
						}}>
						<img src={type_item.icon} width={24} height={24} />
						<div style={{ marginLeft: 8 }}>
							<Text semi style={{ fontSize: 14, color: type_item.is_disabled ? theme.colors.darkGrey2 : theme.colors.black }}>
								{type_item.title}
							</Text>
							<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								{type_item.description}
							</Text>
						</div>
					</div>
				))}
			</div>

			<div className={classes.identity_section}>
				<SectionHeader title={'Network Identity'} description={'Tell us about your network'} />

				<div style={{ display: 'flex', marginTop: 20 }}>
					{!_.isEmpty(display_configs) ? (
						<div style={{ position: 'relative' }}>
							<div
								className={classes.monogram_wrap}
								style={{
									backgroundColor:
										_.isEmpty(network_name.trim()) && display_configs?.avatar_style === 'monogram'
											? theme.colors.lightGrey5
											: display_configs?.avatar_color?.bg,
								}}>
								{display_configs?.avatar_style === 'monogram' ? (
									<Text semi style={{ fontSize: 24, color: !_.isEmpty(network_name.trim()) ? theme.colors.white : theme.colors.lightGrey2 }}>
										{!_.isEmpty(network_name.trim()) ? display_configs?.initials_string : '!'}
									</Text>
								) : (
									<img src={selected_icon_style_val?.white_img} width={40} height={40} />
								)}
							</div>
							{!disable_fields && (
								<div onClick={() => set_show_display_modal(true)} className={classes.edit_icon}>
									<img src={ImageLinks.edit_filled_light_purple} width={24} height={24} />
								</div>
							)}
						</div>
					) : (
						<NetworkAvatar
							image={network_image}
							on_upload_press={() => set_show_display_modal(true)}
							show_edit={!disable_fields}
							on_edit_press={() => set_show_display_modal(true)}
						/>
					)}

					<div className='align-items-center'>
						<OutlinedTextField
							type='text'
							disabled={disable_fields}
							value={network_name}
							textStyle={{ fontSize: 16, color: theme.colors.black }}
							name='network_name'
							// InputProps={{
							// 	endAdornment:
							// 		!_.isEmpty(network_name) && typeof is_name_valid === 'boolean' ? (
							// 			<img
							// 				width={24}
							// 				height={24}
							// 				src={
							// 					typeof is_name_valid === 'boolean' && is_name_valid
							// 						? ImageLinks.tickCirclePurpleSolid
							// 						: ImageLinks.exclamation_error
							// 				}
							// 			/>
							// 		) : null,
							// }}
							onChange={handle_network_name}
							label='Network Name*'
							style={{ width: 235, opacity: disable_fields ? 0.4 : 1 }}
						/>
					</div>
				</div>

				<div className='align-items-center' style={{ marginLeft: 120, marginTop: 4 }}>
					<img src={ImageLinks.exclamation_purple} width={14} height={14} />
					<Text medium style={{ fontSize: 12, marginLeft: 6 }}>
						Once set, network name cannot be edited in the future
					</Text>
				</div>

				<div style={{ position: 'relative' }}>
					<OutlinedTextField
						type='text'
						disabled={disable_fields}
						multiline
						minRows={3}
						value={network_desc}
						textStyle={{ fontSize: 16, color: theme.colors.black }}
						name='network_desc'
						onChange={(e) => {
							set_network_desc(e.target.value);
						}}
						label='Short Description*'
						style={{ marginTop: 28, width: '100%', opacity: disable_fields ? 0.4 : 1 }}
					/>
					<div className={classes.char_count_wrap}>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							<Text
								medium
								component={'span'}
								style={{
									fontSize: 12,
									color:
										network_desc.length < 80
											? theme.colors.darkGrey2
											: network_desc.length >= 80 && network_desc.length <= 100
											? theme.colors.darkYellow
											: theme.colors.red,
								}}>
								{network_desc.length}
							</Text>
							/100
						</Text>
					</div>
				</div>
			</div>

			<div className={classes.display_section}>
				{show_lower_section ? (
					<div style={{ position: 'relative' }}>
						<div className={clsx(classes.steps_wrap)} style={{ maxHeight: 230, overflow: 'auto', position: 'relative', paddingBottom: 30 }}>
							<div className={classes.steps_separator}>
								{total >= (LOWER_SECTION_STEPS.length - 1) * 100 ? (
									<Text bold style={{ fontSize: 14, color: theme.colors.primary }}>
										You’re all set!
									</Text>
								) : (
									<Text bold style={{ fontSize: 14, color: theme.colors.primary }}>
										{`${steps_done}/${LOWER_SECTION_STEPS.length} `}
										<Text semi component={'span'}>
											steps to go live
										</Text>
									</Text>
								)}
								<Text medium className={classes.steps_desc}>
									{total >= (LOWER_SECTION_STEPS.length - 1) * 100
										? 'Network setup complete'
										: 'Complete setup for the following items to go live'}
								</Text>
							</div>
							{_.map(LOWER_SECTION_STEPS, (item, index) => {
								const item_progress = get_task_progress(index, true);
								if (item.type === 'button') return;
								return <StepItem key={`steps_item_${index}`} item={item} index={index} item_progress={item_progress} />;
							})}
						</div>
						<div className={classes.fix_cta_btn}>
							<Button
								type='round'
								size='small'
								text={edit_network_id ? 'Update' : 'Go Live'}
								disabled={
									edit_network_id
										? !(
												is_invite_allocation_updated ||
												is_invite_pricing_updated ||
												is_invite_service_updated ||
												is_invite_support_updated ||
												is_invite_roles_updated ||
												is_invite_surge_price ||
												is_invite_cancellation_price ||
												is_invite_reattempt_config
										  )
										: !all_steps_done
								}
								style={{ width: 80, marginLeft: 10, marginBottom: 5, marginTop: 5 }}
								onClick={edit_network_id ? handle_edit_network_config : handle_go_live}
							/>
						</div>
					</div>
				) : (
					<div className={clsx(classes.steps_wrap)} style={{ maxHeight: 230, overflow: 'auto' }}>
						<div className={classes.steps_separator}>
							<Text bold style={{ fontSize: 14, color: theme.colors.primary }}>
								Network Details
							</Text>
							<Text medium className={classes.steps_desc}>
								Setup network details to continue
							</Text>
						</div>
						{_.map(UPPER_SECTION_STEPS, (item, index) => {
							const item_progress =
								index === 0
									? !_.isEmpty(network_image) || !_.isEmpty(display_configs)
										? 100
										: 0
									: index === 1
									? !_.isEmpty(network_name.trim())
										? 100
										: 0
									: !_.isEmpty(network_desc) && network_desc?.length <= 100
									? 100
									: 0;
							return (
								<StepItem
									key={`steps_item_${index}`}
									item={item}
									index={index}
									item_progress={item_progress}
									is_disabled={proceed_disabled}
									handle_btn_click={handle_confirm}
								/>
							);
						})}
					</div>
				)}
			</div>
			{show_display_modal && (
				<NetworkDisplayModal
					close={() => set_show_display_modal(false)}
					// is_name_valid={typeof is_name_valid === 'boolean' && is_name_valid}
					is_name_valid={!_.isEmpty(network_name.trim())}
					network_name={network_name}
					on_save={handle_network_img}
					network_image={network_image}
					display_configs={display_configs}
				/>
			)}
		</div>
	);
};

export default CreateNetworkUpperSection;
