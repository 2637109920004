import { Avatar, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import theme from 'resources/theme';
import RemoveIcon from '@material-ui/icons/Remove';
import PreviewDisabled from './PreviewDisabled';
import './style.css';
import { ReactComponent as DashboardIcon } from '../../../../assets/images/icons/dashboard.svg';
import { ReactComponent as CreateOrderIcon } from '../../../../assets/images/icons/create-order.svg';
import { ReactComponent as Orders } from '../../../../assets/images/icons/orders.svg';
import { ReactComponent as RoutesIcon } from '../../../../assets/images/icons/route_fork_white.svg';
import { ReactComponent as RidersIcon } from '../../../../assets/images/icons/rider.svg';
import { ReactComponent as FleetIcon } from '../../../../assets/images/icons/truck_white.svg';
import { ReactComponent as NetworkIcon } from '../../../../assets/images/icons/network_purple.svg';
import { ReactComponent as LiveTrackingIcon } from '../../../../assets/images/icons/live_tracking_purple.svg';
import { ReactComponent as TraceIcon } from '../../../../assets/images/icons/tracking-selected.svg';
import { ReactComponent as RupeeIcon } from '../../../../assets/images/icons/rupee-selected.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/images/icons/settings.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/images/icons/wallet-selected.svg';
import { ReactComponent as BulbIcon } from '../../../../assets/images/icons/help_bulb.svg';
import { ReactComponent as SupportIcon } from '../../../../assets/images/icons/support_chat.svg';
import { ReactComponent as LogoutIcon } from '../../../../assets/images/icons/logout-purple.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/images/icons/search_outlined.svg';
import { ReactComponent as NotificationIcon } from '../../../../assets/images/icons/notification-bell.svg';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.colors.lightPurple10,
		borderRadius: 20,
		height: '75vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
	},
	sidebar_container: {
		width: 175,
		backgroundColor: theme.colors.primary,
		position: 'relative',
		paddingBottom: 14,
	},
	bottom_details: {
		background: 'rgba(202, 189, 233, 0.16)',
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		padding: '6px 14px',
	},
	item_wrap: { marginTop: 3, gap: 5, padding: 8, borderRadius: 2, display: 'flex' },
}));

function SidebarPreview({ primary_color, secondary_color, text_color, hover_color, image, is_editable }) {
	const sidebar_list = [
		{ title: 'Dashboard', img: () => <DashboardIcon className='fill_text' style={{ height: 14, width: 14 }} /> },
		{ title: 'Create Order', img: () => <CreateOrderIcon className='fill_text' style={{ height: 14, width: 14 }} /> },
		{ title: 'Orders', img: () => <Orders className='fill_text' style={{ height: 14, width: 14 }} /> },
		{ title: 'Routes', img: () => <RoutesIcon className='fill_text' style={{ height: 14, width: 14 }} /> },
		{ title: 'Riders', img: () => <RidersIcon className='fill_primary' style={{ height: 14, width: 14 }} />, is_selected: true },
		{
			title: 'Roster & Attendance',
			img: () => <RidersIcon className='fill_primary' style={{ height: 14, width: 14 }} />,
			is_sub_item: true,
			is_hover: true,
		},
		{ title: 'Fleets', img: () => <FleetIcon className='fill_text' style={{ height: 14, width: 14 }} />, show_down_arrow: true },
		{ title: 'Trace', img: () => <TraceIcon className='fill_text' style={{ height: 14, width: 14 }} />, show_down_arrow: true },
		{ title: 'Live Tracking', img: () => <LiveTrackingIcon className='fill_text' style={{ height: 14, width: 14 }} /> },
		{ title: 'Network', img: () => <NetworkIcon className='fill_text' style={{ height: 14, width: 14 }} /> },
		{ title: 'COD Management', img: () => <RupeeIcon className='fill_text' style={{ height: 14, width: 14 }} /> },
	];

	const classes = useStyles();
	const { user_details, business_accounts, selected_business_id: business_id_information } = useSelector(({ app }) => app);
	const selected_business_information = business_accounts.filter((business_account_info) => business_account_info.id === business_id_information)[0];

	useEffect(() => {
		window.document.documentElement.style.setProperty('--primary-color', primary_color);
		window.document.documentElement.style.setProperty('--secondary-color', secondary_color);
		window.document.documentElement.style.setProperty('--hover-color', hover_color);
		window.document.documentElement.style.setProperty('--text-color', text_color);
	}, [primary_color, text_color, secondary_color, hover_color]);

	return (
		<div className={classes.container}>
			<div className={classes.sidebar_container} style={{ backgroundColor: primary_color, overflow: 'hidden', height: '90%' }}>
				<div style={{ padding: '0px 7px', marginBottom: 40 }}>
					<div className='d-flex align-items-center justify-content-between'>
						<img src={image ? image : ImageLinks.logoFullWhite} width={'auto'} height={20} style={{ margin: 10, maxWidth: 120 }} />
						<div className='d-flex align-items-center justify-content-between' style={{ gap: 10 }}>
							<NotificationIcon className='fill_text' width={'16px'} height={'16px'} alt='search' />
							<SearchIcon className='fill_text' width={'16px'} height={'16px'} alt='search' />
						</div>
					</div>
					{_.map(sidebar_list, (item, index) => {
						return (
							<div
								key={`preview_item_${index}`}
								className={classes.item_wrap}
								style={{
									backgroundColor: item.is_hover
										? hover_color
										: item.is_selected
										? secondary_color
										: item.is_highlighted
										? hover_color
										: 'transparent',
								}}>
								{!item.is_sub_item && item.img()}
								{item.is_sub_item && (
									<RemoveIcon
										style={{
											color: text_color,
											width: 10,
											height: 15,
											marginLeft: 16,
										}}
									/>
								)}
								<Text semi style={{ color: item.is_selected ? primary_color : text_color, fontSize: 10 }}>
									{item.title}
								</Text>
							</div>
						);
					})}
				</div>
				<div className='d-flex justify-content-center' style={{ gap: 15, marginBottom: 10 }}>
					<SupportIcon className='fill_text' height={16} width={16} />
					<BulbIcon className='fill_text' height={16} width={16} />
					<div style={{ backgroundColor: text_color, width: 1, height: 16 }}></div>
					<SettingsIcon className='fill_text' height={16} width={16} />
					<WalletIcon className='fill_text' height={16} width={16} />
				</div>
				<div className={classes.bottom_details}>
					<div className='d-flex'>
						<Text semi style={{ color: text_color, fontSize: 8 }}>
							{selected_business_information.brand_name} ({selected_business_information.id})
						</Text>
					</div>
					<Divider style={{ margin: '6px 0' }} />
					<div className='d-flex align-items-center justify-content-between'>
						<div className='d-flex align-items-center' style={{ gap: 5 }}>
							<Avatar src={ImageLinks.user_profile} style={{ height: 20, width: 20 }} />
							<div>
								<Text semi style={{ fontSize: 8, color: text_color }}>
									{user_details.name}
								</Text>
								<Text semi style={{ fontSize: 8, color: text_color }}>
									({user_details.user_id})
								</Text>
							</div>
						</div>
						<LogoutIcon className='fill_text' />
					</div>
				</div>
				<div
					className='d-flex justify-content-center align-items-center'
					style={{ height: 14, width: '100%', backgroundColor: theme.colors.lightPurple6, gap: 5, position: 'absolute', bottom: 0 }}>
					<Text semi style={{ fontSize: 8, color: theme.colors.primary }}>
						powered by
					</Text>
					<img src={ImageLinks.logo} height={9} width={21} />
				</div>
			</div>
			{!is_editable && <PreviewDisabled />}
		</div>
	);
}

export default SidebarPreview;
