import Text from 'modules/Text';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';

import ImageLinks from 'assets/images/ImageLinks';
import StandaloneSearchBoxComp from 'modules/StandaloneSearchBoxComp';
import { useSelector } from 'react-redux';
import constant from 'resources/constant';
import clsx from 'clsx';
import Select from 'modules/Select';
import theme from 'resources/theme';
import { useCallback } from 'react';

let map = null;

const useStyles = makeStyles(() => ({
	mapContainer: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		padding: '4px 4px 10px 4px',
		marginTop: 10,
		borderRadius: 10,
	},
	searchBoxContainer: {
		flex: 1,
		border: '1px solid #E4DCF3',
		borderRadius: 45,
		height: 35,
		display: 'flex',
		padding: '0px 10px',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	mapImageContainer: {
		flex: 1,
		position: 'relative',
	},
	permission_denied_wrap: {
		backgroundColor: theme.colors.lightRed,
		padding: 5,
		fontSize: 12,
	},
}));

const SEARCH_BY_OPTIONS = [
	{
		title: 'Area name',
		value: 'area_name',
	},
	{
		title: 'Coordinates',
		value: 'coordinates',
	},
];

let listener;

const AddressModalMapComp = ({
	zone_id,
	selected_place,
	set_selected_place,
	custom_marker_location = null,
	saved_hub_flow = false,
	handle_zone_update = () => {},
	marker_img = ImageLinks.rider_home,
}) => {
	const [show_zone, set_show_zone] = useState(false);
	const [coordinates, set_coordinates] = useState('');
	const [refresh_listener, set_refresh_listener] = useState(false);
	const [is_permission_denied, set_is_permission_denied] = useState(false);

	const zones = useSelector((state) => state.app.zones);

	const marker = React.useRef(null);

	const [selected_master_zone_id, set_selected_master_zone_id] = React.useState(zone_id || zones[0].id);
	const [search_by, set_serach_by] = React.useState(SEARCH_BY_OPTIONS[0].value);
	const [current_location, set_current_location] = useState({});

	const zone = _.find(zones, { id: selected_master_zone_id });

	const polygon = React.useRef(null);

	const handleCoordinatesSelect = useCallback(
		(latitude, longitude, is_drag) => {
			(async () => {
				try {
					const geocoder = new window.google.maps.Geocoder();
					const latlng = new window.google.maps.LatLng(Number(latitude), Number(longitude));

					const address = await geocoder.geocode({ location: latlng });

					if (_.isEmpty(address.results)) {
						return;
					}

					if (!is_drag) {
						const place = address.results[0];
						set_selected_place(place);
					} else {
						set_selected_place({
							...selected_place,
							geometry: {
								location: latlng,
							},
						});
					}

					if (marker.current) {
						marker.current.setMap(null);
					}

					map.setOptions({
						center: { lat: latlng.lat(), lng: latlng.lng() },
						zoom: 15,
					});
					marker.current = new window.google.maps.Marker({
						zoom: 2,
						position: { lat: latlng.lat(), lng: latlng.lng() },
						map,
						draggable: true,
					});
					set_refresh_listener((v) => !v);
				} catch (err) {
					console.log(err, 'error');
				}
			})();
		},
		[selected_place],
	);

	const handle_marker_drag = useCallback(
		(e) => {
			handleCoordinatesSelect(e.latLng.lat(), e.latLng.lng(), true);
		},
		[handleCoordinatesSelect],
	);

	const initialize = () => {
		map = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
			center: zone ? { lat: zone.CenterPoint.latitude, lng: zone.CenterPoint.longitude } : constant.INDIA_MAP_CENTER,
			zoom: 9,
			clickableIcons: false,
			disableDefaultUI: true,
			scaleControl: true,
			zoomControl: true,
			mapId: constant.MAP_DESIGN_ID,
		});
		if (selected_master_zone_id == 'near_me' && !_.isEmpty(current_location)) {
			map.setOptions({
				center: { lat: current_location.latitude, lng: current_location.longitude },
				zoom: 12,
			});
		}
		if (!_.isEmpty(selected_place)) {
			const marker_icon = {
				url: marker_img, // url
				scaledSize: new window.google.maps.Size(32, 32), // scaled size
			};
			marker.current = new window.google.maps.Marker({
				position: { lat: selected_place.geometry.location.lat(), lng: selected_place.geometry.location.lng() },
				map,
				icon: marker_icon,
				draggable: true,
			});
		}
		if (custom_marker_location && saved_hub_flow) {
			const icon_home = {
				url: ImageLinks.rider_home, // url
				scaledSize: new window.google.maps.Size(32, 32), // scaled size
			};
			new window.google.maps.Marker({
				position: { lat: custom_marker_location.latitude, lng: custom_marker_location.longitude },
				map,
				icon: icon_home,
				draggable: false,
			});
		}
		set_refresh_listener((v) => !v);
	};

	const handlePlaceSelect = (place) => {
		set_selected_place(place);
		if (marker.current) {
			marker.current.setMap(null);
		}

		if (_.isEmpty(place)) {
			return;
		}

		map.setOptions({
			center: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
			zoom: 15,
		});
		const marker_icon = {
			url: marker_img, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};
		marker.current = new window.google.maps.Marker({
			zoom: 2,
			position: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
			map,
			icon: marker_icon,
			draggable: true,
		});
		set_refresh_listener((v) => !v);
	};

	useEffect(() => {
		if (marker.current) {
			window.google.maps.event.removeListener(listener);
			listener = marker.current.addListener('dragend', handle_marker_drag);
		}
	}, [handle_marker_drag, refresh_listener]);

	const show_hide_zone = () => {
		if (!map) {
			return;
		}

		if (!show_zone) {
			// Construct the polygon
			const polygon_coords = _.map(_.get(zone, 'geometry.coordinates[0]', []), (coord) => {
				return { lat: coord[0], lng: coord[1] };
			});

			polygon.current = new window.google.maps.Polygon({
				paths: polygon_coords,
				strokeColor: theme.colors.primary,
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: theme.colors.primary,
				fillOpacity: 0.35,
			});

			polygon.current.setMap(map);
		} else {
			polygon.current.setMap(null);
		}

		set_show_zone(!show_zone);
	};

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, [selected_master_zone_id]);

	const classes = useStyles();

	return (
		<div className={clsx(classes.mapContainer, 'box-shadow')}>
			<div className={classes.mapImageContainer}>
				<ContainedButton
					onClick={show_hide_zone}
					style={{
						background: theme.colors.lightPurple,
						position: 'absolute',
						right: 10,
						top: 10,
						zIndex: 100,
						padding: '5px 7px',
						borderRadius: 8,
					}}>
					<img src={show_zone ? ImageLinks.eye_close : ImageLinks.eye_open} width='14' alt='' />
					<Text bold style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 10 }}>
						{show_zone ? 'Hide' : 'Show'} Zone
					</Text>
				</ContainedButton>

				{is_permission_denied && selected_master_zone_id == 'near_me' && (
					<div style={{ position: 'absolute', zIndex: 100, top: '50%', textAlign: 'center', width: '100%' }}>
						<Text medium component={'span'} className={classes.permission_denied_wrap}>
							Location Permission Denied. Please enable location permission from settings.
						</Text>
					</div>
				)}

				<div id='map_canvas_address' className='map-canvas-wrapper rider-location card' style={{ height: '100%', borderRadius: 20 }}></div>
			</div>
			{!(custom_marker_location && saved_hub_flow) && (
				<div style={{ display: 'flex', marginTop: 16, columnGap: '3%', alignItems: 'center' }}>
					<Select
						value={selected_master_zone_id}
						defaultOption={{
							title: 'Select Master Zone',
							value: null,
						}}
						options={_.map([{ name: 'Near Me', id: 'near_me' }, ...zones, { name: 'No Zone', id: 'no_zone' }], (zone) => {
							return {
								title: zone.name,
								value: zone.id,
							};
						})}
						handleChange={(val) => {
							set_coordinates('');
							handlePlaceSelect({});
							handle_zone_update(val);
							set_selected_master_zone_id(val);
						}}
						containerStyles={{ height: 35, width: 160 }}
						menu_styles={{ marginTop: 40 }}
						text_props={{ semi: true }}
						truncate_title={(title) => {
							return _.truncate(title, { length: 16 });
						}}
					/>
					<div className={classes.searchBoxContainer}>
						<Select
							value={search_by}
							options={SEARCH_BY_OPTIONS}
							handleChange={(val) => {
								set_serach_by(val);
							}}
							containerStyles={{ height: 20, width: 100 }}
							text_styles={{ fontSize: 12 }}
							right_icon={ImageLinks.arrow_up_pink}
							image_styles={{ width: 10 }}
							menu_styles={{ marginTop: 30 }}
						/>
						<div style={{ flex: 1, marginLeft: 10 }}>
							{search_by === SEARCH_BY_OPTIONS[0].value ? (
								<StandaloneSearchBoxComp
									disabled={is_permission_denied && selected_master_zone_id == 'near_me'}
									onplaceChange={handlePlaceSelect}
									zone_id={selected_master_zone_id}
									placeHolder='Search for Area, Locality, Street..'
									style={{ width: '90%', fontSize: 12 }}
									onTextChange={(e) =>
										set_selected_place({
											formatted_address: e.target.value,
										})
									}
									value={
										!_.isEmpty(selected_place)
											? `${selected_place.name ? selected_place.name + ' ' : ''}${selected_place.formatted_address}`
											: ''
									}
									set_is_permission_denied={set_is_permission_denied}
									current_location={current_location}
									set_current_location={set_current_location}
								/>
							) : (
								<input
									placeholder='Search by coordinates'
									value={
										!_.isEmpty(selected_place.geometry)
											? selected_place.geometry.location.lat().toFixed(4) + ', ' + selected_place.geometry.location.lng().toFixed(4)
											: coordinates
									}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											const lat = coordinates.split(',')[0];
											const lng = coordinates.split(',')[1];

											if (!_.isEmpty(lat) && !_.isEmpty(lng)) {
												handleCoordinatesSelect(lat, lng);
											}
										}
									}}
									onChange={(e) => {
										if (!_.isEmpty(selected_place)) {
											handlePlaceSelect({});
										}

										set_coordinates(e.target.value);
									}}
									style={{ outline: 'none', width: '95%', borderColor: 'transparent' }}
								/>
							)}
						</div>
						<img src={ImageLinks.search_light_grey} width='14' alt='' />
					</div>
				</div>
			)}
		</div>
	);
};

export default AddressModalMapComp;
