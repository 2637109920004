import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import utils from 'resources/utils';
import { ALL_ORDERS_MENU_ITEMS } from 'views/AllOrders/helper';
import { hide_loader, show_loader } from 'actions/app';

const initial_success_modal_details = {
	show: false,
	title: '',
	type: '',
	total_order_creation_count: 0,
	show_error_modal: false,
	show_error_message: '',
};

const useCreateOrder = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { zones, name, mobile, bop_packages, business_accounts, selected_business_id } = useSelector(
		(state) => ({
			bop_packages: state.app.bop_packages,
			zones: state.app.zones,
			name: state.app.user_details.name,
			mobile: state.app.user_details.mobile,
			business_accounts: state.app.business_accounts,
			selected_business_id: state.app.selected_business_id,
		}),
		shallowEqual,
	);

	const [zone_id, set_zone_id] = React.useState('');
	const [step, set_step] = React.useState(1);
	const [selected_pickup_address, set_selected_pickup_address] = React.useState();
	const [selected_drop_details, set_selected_drop_details] = React.useState([]);
	const [sender_name, set_sender_name] = React.useState('');
	const [sender_contact, set_sender_contact] = React.useState('');
	const [order_created_details, set_order_created_details] = React.useState(_.cloneDeep(initial_success_modal_details));
	const repeeat_flow_cdr_id = useRef(null);

	const selected_zone = _.find(zones, { id: zone_id });

	useEffect(() => {
		get_recent_address_list();
	}, []);

	const get_recent_address_list = async () => {
		try {
			const response = await api_requests.get_pending_orders_search({
				poc_mobile: mobile,
				limit: 1,
				group: 'completed',
			});
			if (response.data.items.length > 0) {
				const sender_addr = response.data.items[0];
				const master_zone_id = _.get(sender_addr, 'sender_detail.address.master_zone.id', null);
				set_zone_id(master_zone_id == null || master_zone_id == undefined || master_zone_id == '' ? 'no_zone' : master_zone_id);
				set_sender_name(_.get(sender_addr, 'poc_detail.name', ''));
				set_sender_contact(_.get(sender_addr, 'poc_detail.mobile', ''));
				const address = _.get(sender_addr, 'sender_detail.address', null);
				if (!address) return;
				delete address.business_zone;
				delete address.master_zone;
				delete address.network_business_zone;
				delete address.network_master_zone;
				const addr_payload = {
					...address,
					address_line1: address.address_line_1,
					coordinates: { latitude: address.latitude, longitude: address.longitude },
				};
				delete addr_payload.address_line_1;
				set_selected_pickup_address(addr_payload);
				handle_step(2);
			} else {
				set_zone_id(6);
			}
		} catch {
			set_zone_id(6);
		}
	};

	const handle_zone_id = (_zone_id) => {
		set_zone_id(_zone_id);
	};

	const close_success_modal = () => {
		set_order_created_details(_.cloneDeep(initial_success_modal_details));
	};

	const handle_step = (_step) => {
		set_step(_step);
	};

	const handle_selected_pickup_address = (address) => {
		set_selected_pickup_address(address);
	};

	const handle_sender_name = (name) => {
		set_sender_name(name);
	};

	const handle_sender_contact = (contact) => {
		set_sender_contact(contact);
	};

	const handle_create = async () => {
		dispatch(show_loader());
		try {
			const drop_details = _.cloneDeep(selected_drop_details);
			_.map(drop_details, (details) => {
				details.receiver_detail.address = {
					...details.drop_address,
					address_line_1: details.drop_address.address_line1,
					pincode: String(details.drop_address.pincode),
					latitude: _.get(details.drop_address, 'coordinates.latitude', ''),
					longitude: _.get(details.drop_address, 'coordinates.longitude', ''),
				};
				delete details.drop_address;
				delete details.receiver_detail?.address?.selected_place;

				_.map(details.packages, (_package) => {
					const bop_package = _.find(bop_packages, { package_id: _package.package_id }) || {};
					_package.id = _package.package_id;
					_package.volumetric_weight = bop_package.volume;
					delete _package.package_id;
				});
			});
			delete selected_pickup_address?.selected_place;
			const brand_name = _.filter(business_accounts, (item) => item.id === selected_business_id)[0].brand_name;
			const response = await api_requests.bulk_trip_creation(
				{
					sender_detail: {
						name: sender_name,
						mobile: sender_contact,
						address: {
							...selected_pickup_address,
							address_line_1: selected_pickup_address.address_line1,
							pincode: String(selected_pickup_address.pincode),
						},
					},
					poc_detail: {
						name: name,
						mobile: mobile,
					},
					trips: drop_details,
				},
				brand_name,
			);
			if (response.data) {
				dispatch(hide_loader());
				set_order_created_details({
					show: true,
					title: 'Order created successfully',
					type: ALL_ORDERS_MENU_ITEMS.Pending.id,
					total_order_creation_count: `${_.keys(response.data).length} orders`,
					show_error_modal: false,
				});
			}
		} catch (err) {
			dispatch(hide_loader());
		}
	};

	const on_repeat_old_details = async (payload) => {
		try {
			dispatch(show_loader());
			const brand_name = _.filter(business_accounts, (item) => item.id === selected_business_id)[0].brand_name;
			const response = await api_requests.bulk_trip_creation(payload, brand_name);
			if (response.data) {
				dispatch(hide_loader());
				const source_order_id = _.get(payload, 'trips.0.source_order_id', null);
				repeeat_flow_cdr_id.current = response.data[source_order_id];
				set_order_created_details({
					show: true,
					title: 'Order created successfully',
					type: ALL_ORDERS_MENU_ITEMS.Pending.id,
					total_order_creation_count: `${_.keys(response.data).length} orders`,
					show_error_modal: false,
				});
			}
		} catch (err) {
			dispatch(hide_loader());
		}
	};

	const handle_create_csv_orders = async (items) => {
		try {
			dispatch(show_loader());
			const brand_name = _.filter(business_accounts, (item) => item.id === selected_business_id)[0].brand_name;
			const response = await api_requests.create_csv_channel_order(
				{
					sender_detail: {
						name: sender_name,
						mobile: sender_contact,
						address: {
							id: selected_pickup_address.id,
							address_line_1: selected_pickup_address.address_line1,
							latitude: selected_pickup_address.latitude,
							longitude: selected_pickup_address.longitude,
							label: selected_pickup_address.label,
							pincode: selected_pickup_address.pincode.toString(),
							state: selected_pickup_address.state,
							...(!_.isEmpty(selected_zone) && { city: selected_zone.name }),
						},
					},
					poc_detail: {
						name: name,
						mobile: mobile,
					},
					items,
				},
				brand_name,
			);
			dispatch(hide_loader());
			if (!_.isEmpty(response, 'data.error', null)) {
				set_order_created_details({
					show: false,
					title: `Create Order Unsuccessful`,
					show_error_modal: true,
					show_error_message: '"We found errors in the CSV uploaded. Please re-check and upload the CSV again.',
				});
			} else {
				set_order_created_details({
					show: true,
					title: `Orders uploaded successfully`,
					type: ALL_ORDERS_MENU_ITEMS.Pending.id,
					total_order_creation_count: `${items.length} orders`,
					show_error_modal: false,
				});
			}
		} catch (err) {
			dispatch(hide_loader());
			if (!_.isEmpty(err, 'response.data.error', null)) {
				set_order_created_details({
					show: false,
					title: `Create Order Unsuccessful`,
					show_error_message: '"We found errors in the CSV uploaded. Please re-check and upload the CSV again.',
					show_error_modal: true,
				});
			} else {
				set_order_created_details({
					show: false,
					title: `Create Order Unsuccessful`,
					show_error_modal: true,
					show_error_message: 'CSV file size is too large to process. Please upload file smaller than 3MB.',
				});
			}
		}
	};

	const handle_repeat_order_press = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.REPEAT_ORDER);
		history.push(`${Routes.REPEAT_ORDERS.path}`);
	};

	const on_order_success = () => {
		history.push(
			`${Routes.ALL_ORDERS.path + '?type=' + order_created_details.type}${
				!_.isEmpty(repeeat_flow_cdr_id.current) ? '&cdr_id=' + repeeat_flow_cdr_id.current : ''
			}`,
		);
		close_success_modal();
	};

	return {
		zone_id,
		step,
		selected_pickup_address,
		sender_name,
		sender_contact,
		selected_drop_details,
		bop_packages,
		order_created_details,
		zones,
		handle_zone_id,
		handle_step,
		handle_selected_pickup_address,
		handle_sender_name,
		handle_sender_contact,
		set_selected_drop_details,
		handle_create,
		handle_create_csv_orders,
		close_success_modal,
		handle_repeat_order_press,
		on_order_success,
		on_repeat_old_details,
	};
};

export default useCreateOrder;
