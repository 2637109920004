import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from './Button';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
	button: {
		position: 'absolute',
		zIndex: 1000,
		bottom: 30,
		left: 34,
	},
	second_button: {
		position: 'absolute',
		zIndex: 1000,
		bottom: 30,
		left: '35%',
	},
}));

const EmptyState = ({
	left_icon = null,
	right_icon = null,
	button_text = '',
	empty_img,
	onClick = () => {},
	button_style,
	second_button_text = '',
	second_button_onclick = () => {},
	second_button_left_icon = null,
	second_button_right_icon = null,
	second_button_style,
	delay_time = 100,
}) => {
	const classes = useStyles();

	const [show, set_show] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			set_show(true);
		}, delay_time);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	if (!show) return null;
	return (
		<div style={{ position: 'relative' }}>
			<img src={empty_img} width={514} height={328} />
			{!_.isEmpty(button_text) && (
				<Button
					size='large'
					type='round'
					text={button_text}
					left_icon={left_icon}
					style={{ ...button_style }}
					className={classes.button}
					onClick={onClick}
					right_icon={right_icon}
				/>
			)}
			{!_.isEmpty(second_button_text) && (
				<Button
					size='large'
					type='outlined'
					text={second_button_text}
					left_icon={second_button_left_icon}
					style={{ ...second_button_style }}
					className={classes.second_button}
					onClick={second_button_onclick}
					right_icon={second_button_right_icon}
				/>
			)}
		</div>
	);
};

export default EmptyState;
