import Text from 'modules/Text';
import _ from 'lodash';
import React from 'react';
import cross from 'assets/images/icons/cross.svg';
import { makeStyles, Modal } from '@material-ui/core';
import theme from 'resources/theme';

import ContainedButton from 'modules/ContainedButton';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import AddressModalMapComp from 'modules/AddressModalMapComp';
import ImageLinks from 'assets/images/ImageLinks';
import api_requests from 'resources/api_requests';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	container: {
		padding: 20,
		background: 'white',
		width: 650,
		height: 680,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	innerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	editTextContainer: {
		display: 'flex',
		alignItems: 'center',
		height: 30,
	},
	textInput: { fontSize: 24 },
	addressContainer: {
		marginBottom: 35,
	},
	reachInstructionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 20,
	},
	containedBtnContainer: {
		width: 90,
		margin: '0 auto',
	},
}));

const CreateHub = ({ close }) => {
	const [selected_place, set_selected_place] = React.useState({});
	const [hub_name, set_hub_name] = React.useState('');
	const [allowable_login_distance, set_allowable_login_distance] = React.useState('');

	const handleClose = () => {
		close();
	};

	const confirm_address = () => {
		api_requests
			.create_hub({
				title: hub_name,
				location: { latitude: selected_place.geometry.location.lat(), longitude: selected_place.geometry.location.lng() },
				allowable_login_distance,
			})
			.then((res) => {
				close(true);
			});
	};

	const classes = useStyles();

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className={classes.innerContainer}>
					<div className={classes.editTextContainer}>
						<Text bold className={classes.textInput}>
							Create Hub
						</Text>
					</div>
					<img src={cross} onClick={handleClose} style={{ cursor: 'pointer' }} alt='' />
				</div>
				<AddressModalMapComp zone_id={''} selected_place={selected_place} set_selected_place={set_selected_place} />
				<div className={classes.addressContainer}>
					<div className={classes.reachInstructionsContainer}>
						<UndlerlineTextField
							style={{ width: '47%' }}
							label='Hub Name*'
							placeholder='Enter hub name'
							value={hub_name}
							disabled={_.isEmpty(selected_place)}
							onChange={(e) => set_hub_name(e.target.value)}
						/>

						<UndlerlineTextField
							style={{ width: '47%' }}
							label='Allowed Distance*'
							placeholder='Enter distance'
							value={allowable_login_distance}
							disabled={_.isEmpty(selected_place)}
							onChange={(e) => set_allowable_login_distance(e.target.value)}
						/>

						{/* <div style={{ width: '47%' }}>
							<UndlerlineTextField
								style={{ width: '100%' }}
								label='Allowed Login Distance'
								placeholder='Enter Distance in kms'
								value={allowed_distance}
								disabled={_.isEmpty(selected_place)}
								onChange={(e) => set_allowed_distance(e.target.value)}
							/>
							<div style={{ display: 'flex', marginTop: 5 }}>
								<img src={ImageLinks.info_light_purple} alt='' width={10} />
								<Text style={{ fontSize: 10, color: theme.colors.lightGrey2, marginLeft: 5 }}>
									How far away (in km)from the hub the rider can log in
								</Text>
							</div>
						</div> */}
					</div>
				</div>
				<Button
					className={classes.containedBtnContainer}
					type='round'
					text='Next'
					size='large'
					onClick={confirm_address}
					disabled={_.isEmpty(hub_name) || _.isEmpty(selected_place.geometry)}
					style={{ width: 110 }}
				/>
			</div>
		</Modal>
	);
};

export default CreateHub;
