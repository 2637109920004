import Text from 'modules/Text';
import React from 'react';
import useStyles from '../styles';
import Amount from 'modules/Amount';
import theme from 'resources/theme';
import clsx from 'clsx';

const DayWiseRowHeader = ({ total_orders, credit, debit }) => {
	const classes = useStyles();
	return (
		<div className='align-items-center'>
			<div>
				<Text bold style={{ fontSize: 16 }}>
					{total_orders}
				</Text>
				<Text bold style={{ fontSize: 10 }}>
					Order
				</Text>
			</div>
			<div className={clsx('box-shadow', classes.amount_wrap)} style={{ marginLeft: 40, marginRight: 11 }}>
				<Amount
					amount={credit}
					main_text_styles={{
						color: theme.colors.green,
						fontSize: 20,
					}}
					sub_text_styles={{
						color: theme.colors.green,
						fontSize: 12,
					}}
				/>
				<Text bold style={{ fontSize: 10 }}>
					Credit
				</Text>
			</div>

			<div className={clsx('box-shadow', classes.amount_wrap)}>
				<Amount
					amount={debit}
					main_text_styles={{
						color: theme.colors.red,
						fontSize: 20,
					}}
					sub_text_styles={{
						color: theme.colors.red,
						fontSize: 12,
					}}
				/>
				<Text bold style={{ fontSize: 10 }}>
					Debit
				</Text>
			</div>
		</div>
	);
};

export default DayWiseRowHeader;
