import { useTheme } from '@material-ui/core';
import React from 'react';

function StoreBox({ children }) {
	const theme = useTheme();
	return (
		<div
			style={{
				width: 100,
				height: 100,
				backgroundColor: theme.colors.white,
				borderRadius: 6,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				boxShadow: '0px 4px 24px rgba(229, 229, 229, 0.8)',
			}}>
			{children}
		</div>
	);
}

export default StoreBox;
