import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 600,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		zIndex: 1200,
		overflowY: 'auto',
		paddingBottom: 150,
	},
	header_wrap: { paddingBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	header_text: { fontSize: 20, color: theme.colors.primary },
	recharge_btn: {
		height: 46,
		borderRadius: 30,
		padding: '12px 35px',
	},
	footer: {
		height: 100,
		width: 600,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		paddingRight: 40,
		boxShadow: '0px -4px 14px 0px rgba(0, 0, 0, 0.05)',
		justifyContent: 'flex-end',
	},

	upi_wrap: {
		marginTop: 16,
		width: '100%',
		padding: 16,
		height: 61,
		borderRadius: 8,
		border: `1.24px solid ${theme.colors.lightWhite}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.colors.white,
		cursor: 'pointer',
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.3)',
	},
	upi_label: {
		fontSize: 16,
		textTransform: 'capitalize',
	},
	subtitle: {
		fontSize: 12,
		color: theme.colors.darkGrey2,
		marginTop: 5,
	},
	select_wrap: {
		border: `1.24px solid ${theme.colors.darkPurple}`,
		width: 84,
		height: 21,
		borderRadius: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	select_text: {
		fontSize: 12,
		color: theme.colors.darkPurple,
	},
	label: { fontSize: 14, marginTop: 24, color: theme.colors.darkGrey2 },
	business_info: {
		height: 180,
		borderRadius: 20,
		padding: '24px 20px',
		background: 'linear-gradient(180deg, #492897 0%, #25144C 100%)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', overflow: 'hidden' },
	header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	heading_text: {
		fontSize: 22,
		color: 'black',
	},

	recharge_wallet_btn_text: { fontSize: 18, color: theme.colors.white },
	filter_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 32,
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	balance_text: { fontSize: 14, color: theme.colors.primary, marginRight: 4 },

	content: { display: 'flex', flex: 1, overflow: 'hidden', marginBottom: 30 },
	history_wrap: { flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column' },

	map_wrapper: { height: '100%', width: '100%', padding: 30 },
	map_style: { height: '100%', width: '100%', borderRadius: 20 },
	right_wrapper: {
		flex: 1,
		borderRadius: 6,
		background: theme.colors.white,
		padding: '8px 16px',
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		margin: '12px 4px 4px 0px',
	},
	details_text: { fontSize: 16, color: theme.colors.lightGrey2 },
	date_filter: {
		width: 120,
		height: 36,
		borderRadius: 36,
		border: `1px solid ${theme.colors.lightPurple}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: 14,
		paddingRight: 14,
	},
	table_wrap: { flex: 1, display: 'flex', flexDirection: 'column', width: '95%' },
	table_header_wrap: {
		backgroundColor: theme.colors.white,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 12,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
	},
	table_header_text: { fontSize: 14, color: theme.colors.darkGrey2, marginRight: 3 },
	row_wrap: { overflowY: 'hidden', flex: 1, marginTop: 10, marginBottom: 10 },
	row_item_wrap: {
		width: '100%',
		padding: '12px 30px',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	row_item_text: { flex: 1, fontSize: 14 },
	credit_wrap: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	date_filter_text: { fontSize: 14, color: theme.colors.primary },

	amount_input: {
		'&$cssFocused $notchedOutline': {
			borderWidth: 0,
			borderColor: `${theme.colors.lightPurple9} !important`,
		},
	},
	amount_outline: {
		borderWidth: 0,
	},

	remark_input: {
		'&$cssFocused $notchedOutline': {
			borderWidth: 0,
			borderColor: `${theme.colors.lightPurple9} !important`,
		},
		borderRadius: 42,
		height: 46,
		paddingLeft: 10,
		fontSize: 14,
	},

	remark_outline: {
		borderWidth: 0,
		// borderColor: 'green !important'
	},

	cssFocused: {},

	amount_cssOutlinedInput: {
		boxShadow: '0px 3px 6px 0px rgba(131, 102, 201, 0.1)',
		borderRadius: 42,
		height: 46,
		paddingLeft: 20,
		fontSize: 20,
		border: `1px solid ${theme.colors.lightPurple9}`,
	},

	amount_notchedOutline: {
		borderWidth: 1,
		border: `1px solid ${theme.colors.lightPurple9} !important`,
	},

	amount_label: { fontSize: 14, marginTop: 16, color: theme.colors.darkGrey2 },
	inr_input: { position: 'absolute', left: 15, top: 20, fontSize: 20, color: theme.colors.black },
	right_wrapper_header: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
	amount_wrap: {
		height: 57,
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		minWidth: 131,
		padding: '11px 8px 11px 8px',
	},
	day_filter_wrap: { display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 12 },
	day_filter_item: {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 22,
		padding: '4px 8px 4px 8px',
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		marginRight: 12,
	},
	separator: { borderBottom: '1px dashed #B29DE2', display: 'flex', width: '100%' },
	day_row_wrap: {
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
			'& $download_btn': {
				visibility: 'visible !important',
			},
		},
	},
	download_btn: {
		visibility: 'hidden',
		borderRadius: 50,
		height: 25,
	},
	detail_wrap: {
		height: '100%',
		overflowY: 'scroll',
		marginTop: 10,
		paddingBottom: 130,
	},
	day_wise_time: { fontSize: 10, color: theme.colors.darkGrey2, marginLeft: 4 },
	flex_center: { display: 'flex', alignItems: 'center' },
	header_left_wrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '46%',
		marginRight: 50,
		paddingBottom: 6,
		borderBottom: `1px solid ${theme.colors.lightPurple}`,
	},
	header_tab_item: {
		height: 33,
		marginRight: 10,
		borderRadius: 8,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: 16,
		paddingRight: 16,
		cursor: 'pointer',
	},
	graph_circle: {
		width: 36,
		height: 36,
		backgroundColor: theme.colors.lightPurple,
		borderRadius: 18,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			boxShadow: `0px 2px 16px rgba(102, 102, 102, 0.3)`,
			backgroundColor: theme.colors.primary,
			'& .purple-download': {
				display: 'none',
			},
			'& .white-download': {
				display: 'block !important',
			},
		},
	},
	switch_hover_wrap: {
		height: 22,
		backgroundColor: theme.colors.lightPink4,
		position: 'absolute',
		right: 0,
		bottom: -30,
		borderRadius: 4,
		paddingLeft: 4,
		paddingRight: 4,
		display: 'flex',
		alignItems: 'center',
	},
	triangel_wrap: {
		marginLeft: 0,
		marginTop: 0,
		position: 'absolute',
		top: -10,
		right: 10,
	},
	triangle: {
		width: 0,
		height: 0,
		borderLeft: '10px solid transparent',
		borderRight: '10px solid transparent',
		borderBottom: `10px solid ${theme.colors.lightPink4}`,
	},
	availabel_balance_wrap: {
		height: 38,
		borderRadius: 4,
		backgroundColor: theme.colors.lightPink4,
		paddingLeft: 12,
		paddingRight: 12,
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
	},
	pricing_detail_header: {
		height: 100,
		backgroundColor: theme.colors.white,
		borderRadius: 8,
		boxShadow: `0px 0px 4px ${theme.colors.lightPurple}`,
		display: 'flex',
		justifyContent: 'space-between',
	},
	pricing_header_text_wrap: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		paddingLeft: 23,
	},
	active_text_wrap: {
		width: 58,
		height: 25,
		borderRadius: 4,
		border: `1px solid ${theme.colors.green}`,
		backgroundColor: theme.colors.lightGreen10,
		padding: '2px 8px',
		marginTop: 8,
	},
	comm_price_item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 9,
		// borderBottom: `1px solid ${theme.colors.lightPurple13}`,
	},
	price_unit_text: { fontSize: 10, color: theme.colors.darkGrey2, marginLeft: 2 },
	price_section_header_desc_text: { fontSize: 12, color: theme.colors.darkGrey2, marginLeft: 18, marginTop: 4 },
	price_section_deduct_desc_text: { height: 23, borderRadius: 3, backgroundColor: theme.colors.lightPurple, padding: '2.5px 12px' },
	pricing_section: { borderBottom: `1px solid ${theme.colors.lightPurple13}`, paddingBottom: 15 },
	order_price_header: { fontSize: 12, color: theme.colors.darkGrey2, textDecoration: 'underline' },
}));

export default useStyles;
