const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles((theme) => ({
	close_container: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
		width: '100%',
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 580,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 30,
		zIndex: 1200,
		overflowY: 'auto',
		paddingBottom: 120,
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
		overflowX: 'hidden',
	},
	create_button_wrap: {
		position: 'absolute',
		width: 580,
		backgroundColor: theme.colors.white,
		bottom: 0,
		zIndex: 1201,
		height: 80,
		display: 'flex',
		padding: 24,
		right: 0,
		justifyContent: 'flex-end',
		boxShadow: '0px -4px 14px 0px rgba(0, 0, 0, 0.05)',
	},
	card_wrap: {
		border: `1px solid ${theme.colors.primary}`,
		padding: 12,
		borderRadius: 6,
		marginTop: 10,
	},
	bulk_assignment_wrap: {
		height: 70,
		backgroundColor: theme.colors.lightPink4,
		marginTop: 18,
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		gap: 5,
		borderRadius: 8,
	},
	header_text: {
		color: theme.colors.darkGrey7,
		fontSize: 12,
		marginTop: 12,
	},
	vehicle_detail_wrap: {
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		gap: 10,
		padding: 16,
		borderRadius: 6,
	},
	add_address_btn: {
		backgroundColor: theme.colors.lightPink4,
		borderRadius: 20,
	},
	date_wrapper: {
		border: `1px solid ${theme.colors.lightPurple6}`,
		padding: '2px 8px',
		borderRadius: 36,
		display: 'flex',
		alignItems: 'center',
		gap: 3,
		marginRight: 10,
		marginTop: 5,
		height: 42,
	},
	add_rider_wrap: { display: 'flex', justifyContent: 'space-between', marginTop: 20 },
	add_rider_input_wrap: {
		border: `1px solid ${theme.colors.lightPurple3}`,
		width: '64%',
		padding: '10px 20px',
		height: 40,
		borderRadius: 20,
		display: 'flex',
	},
	add_rider_input: {
		outline: 'none',
		border: 'none',
		marginLeft: 10,
		fontSize: 16,
		width: '100%',
	},
	rider_name_text: { color: 'black', marginLeft: 40, fontSize: 16 },
	rider_info: { display: 'flex', alignItems: 'center' },
	rider_id_text: { color: 'black', fontSize: 16 },
	rider_list_item: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 15,
	},
	delete_btn: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 20,
		display: 'flex',
		alignItems: 'center',
	},
	search_rider_wrap: {
		marginTop: 5,
		position: 'absolute',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		marginLeft: 30,
		width: 300,
		zIndex: 1000,
		border: `1px solid ${theme.colors.lightGrey3}`,
		borderRadius: 10,
		background: 'white',
		overflowY: 'auto',
		maxHeight: 200,
	},
	no_rider: {
		display: 'flex',
		width: '12.4375rem',
		padding: '0 0.5rem',
		alignItems: 'center',
		gap: '0.5rem',
		borderRadius: '0.375rem',
		background: 'rgba(242, 238, 248, 0.60)',
		justifyContent: 'space-between',
	},
	tooltip: {
		backgroundColor: theme.palette.common.white,
		border: '1px solid #E6E8ED',
		color: '#4A4A4A',
		boxShadow: '0px 0px 4px 0px rgba(102, 102, 102, 0.30)',
		padding: 6,
	},
	arrow: {
		'&:before': {
			border: '1px solid #E6E8ED',
		},
		color: theme.palette.common.white,
	},
	upload_wrap: {
		borderRadius: 8,
		padding: 12,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 17,
	},
	upload_btn: { height: 26, marginTop: 4 },
	upload_text: {
		fontSize: 12,

		marginLeft: 6,
	},
}));
