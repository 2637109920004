import { ClickAwayListener, Divider, Popper, Step, StepConnector, StepContent, StepLabel, Stepper, makeStyles, useTheme, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import constant from 'resources/constant';
import OrderModalContext from '../OrderModalContext';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import moment from 'moment';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { DivIcon, Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import 'leaflet-providers/leaflet-providers.js'; // Import Leaflet Providers
import RiderActions from './RiderActions';
import utils from 'resources/utils';
import OutlinedButton from 'modules/OutlinedButton';
import CustomAccordion from 'modules/CustomAccordion';
import { shallowEqual, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		top: 0,
		right: '45vw',
		height: '100%',
		width: '30vw',
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 20,
		zIndex: 1200,
		overflowY: 'auto',
	},
	stepper: {
		padding: 0,
		marginTop: 10,
		'&.MuiPaper-root': { backgroundColor: 'transparent' },
		'&.MuiStepConnector-root': { marginLeft: '6px !important' },
	},
	step_content: { marginLeft: 7, marginTop: -3, marginRight: -7 },
	step_remark: { fontSize: 12, color: theme.colors.lightGrey6, marginLeft: -5 },
	menu_wrap: { backgroundColor: theme.colors.white, boxShadow: '0px 1px 10px 1px rgba(202, 202, 202, 1)', borderRadius: 6, cursor: 'pointer', width: 154 },
	stepper_wrap: {
		height: '40vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	refresh_btn_wrap: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: 10,
		height: 30,
	},
	tnc_wrap: {
		borderRadius: 8,
		border: `1px solid ${theme.colors.lightGrey5}`,
		padding: 12,
		backgroundColor: theme.colors.white,
		position: 'absolute',
		width: '90%',
		bottom: 10,
	},
	tnc_wrap_open: {
		backgroundColor: theme.colors.white,
		width: '100%',
		bottom: 0,
		height: '40vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	active_tab: {
		backgroundColor: theme.colors.primary,
		padding: '4px 8px',
		borderRadius: 4,
		marginTop: 10,
	},
	active_text: {
		color: theme.colors.white,
	},
	inactive_tab: {
		padding: '4px 8px',
		borderRadius: 4,
		marginTop: 10,
		cursor: 'pointer',
	},
	inactive_text: {
		color: theme.colors.lightPurple6,
	},
	support_item_wrap: {
		padding: 10,
		display: 'flex',
		alignItems: 'center',
		gap: 20,
	},
}));

const CustomStepConnector = withStyles((theme) => ({
	alternativeLabel: {
		top: 10,
		left: 'calc(-50% + 20px)',
		right: 'calc(50% + 20px)',
	},
	active: {
		'& $line': {
			borderLeft: `1px solid ${theme.colors.primary}`,
		},
	},
	completed: {
		'& $line': {
			borderLeft: `1px solid ${theme.colors.primary}`,
		},
	},
	disabled: {
		'& $line': {
			borderLeft: `1px solid ${theme.colors.primary}`,
		},
	},
	line: {
		borderTopWidth: 3,
		borderTopColor: 'red',
		borderRadius: 1,
	},
	root: {
		height: 0,
		marginLeft: 7,
		padding: 0,
	},
}))(StepConnector);

function TripModal() {
	const { app } = useSelector((state) => state, shallowEqual);
	const classes = useStyles();
	const theme = useTheme();
	const { data, pbid_data, active_trip, set_active_trip, trip_data, get_rider_location, rider_location } = useContext(OrderModalContext);

	const [show_menu, set_show_menu] = useState(null);
	const [show_refresh_button, set_show_refresh_button] = useState(true);
	const [show_tnc, set_show_tnc] = useState(true);
	const network_configs = _.get(app, 'app_configs.network_configs', {});
	const menu_ref = useRef(null);
	const pickup_icon = new Icon({ iconUrl: ImageLinks.rider_pickup, iconSize: [30, 30] });
	const drop_icon = new Icon({ iconUrl: ImageLinks.marker_down_arrow, iconSize: [30, 30] });
	const rider_icon = new Icon({ iconUrl: ImageLinks.rider_yellow_circle, iconSize: [60, 60] });

	const show_rider_action =
		trip_data.status_id >= 80 &&
		trip_data.status_id < 190 &&
		_.get(trip_data, 'rider.owner_id', '') == utils._retrieveData('owner_id') &&
		_.get(trip_data, 'rider.owner_type', '') == utils._retrieveData('type_of_owner').id;

	useEffect(() => {
		get_rider_location();
	}, []);

	const update_rider_location = () => {
		get_rider_location();
		set_show_refresh_button(false);
		setTimeout(() => {
			set_show_refresh_button(true);
		}, 30000);
	};

	const timeline_arr = [];
	for (const property in trip_data.timeline) {
		if (trip_data.timeline[property].timestamp != null) timeline_arr.push({ ...trip_data.timeline[property], title: property });
	}

	return (
		<div className={clsx(classes.container)} style={{ paddingRight: 0 }}>
			{data.status === 3 && (
				<div className={classes.refresh_btn_wrap}>
					{show_refresh_button ? (
						<OutlinedButton style={{ border: 'unset' }} onClick={() => update_rider_location()}>
							<img
								src={ImageLinks.refresh_dark_purple}
								alt='address'
								width={12}
								height={12}
								style={{ marginRight: 8, color: theme.colors.primary }}
							/>
							<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
								Refresh Rider Location
							</Text>
						</OutlinedButton>
					) : (
						<div className='align-items-center'>
							<img src={ImageLinks.tick_circle_green} alt='tick' width={16} height={16} style={{ marginRight: 4 }} />
							<Text bold style={{ fontSize: 12, color: theme.colors.green }}>
								Updated!
							</Text>
						</div>
					)}
				</div>
			)}
			<div id='map_canvas_address' className='map-canvas-wrapper rider-location card' style={{ height: '40vh', width: '100%', borderRadius: 20 }}>
				<MapContainer
					center={
						data.sender_detail?.address?.latitude &&
						data.sender_detail?.address?.longitude &&
						data.customer_detail?.address?.latitude &&
						data.customer_detail?.address?.longitude
							? {
									lat: ((data.sender_detail.address.latitude + data.customer_detail.address.latitude) / 2).toFixed(7),
									lng: ((data.sender_detail.address.longitude + data.customer_detail.address.longitude) / 2).toFixed(7),
							  }
							: constant.INDIA_MAP_CENTER
					}
					zoom={10}
					scrollWheelZoom={true}>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
					/>
					<Marker
						key={'Pickup_marker'}
						position={{ lat: data.sender_detail.address.latitude, lng: data.sender_detail.address.longitude }}
						icon={pickup_icon}></Marker>
					<Marker
						key={'drop_marker'}
						position={{ lat: data.customer_detail.address.latitude, lng: data.customer_detail.address.longitude }}
						icon={drop_icon}></Marker>
					{rider_location.location?.latitude && rider_location.location?.longitude && (
						<Marker
							key={'rider_marker'}
							position={{ lat: rider_location.location?.latitude, lng: rider_location.location?.longitude }}
							icon={rider_icon}>
							{/* <Popup>
								<Text semi style={{ fontSize: 14 }} component={'span'}>
									{_.get(data, 'fulfillment.rider.name', '-')}
								</Text>
								<Text medium style={{ fontSize: 12, marginLeft: 5 }} component={'span'}>{`(${_.get(data, 'fulfillment.rider.id', '-')})`}</Text>
								<div className='d-flex' style={{ gap: 5 }}>
									<img src={ImageLinks.call_filled} />
									<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }} component={'span'}>
										{_.get(data, 'fulfillment.rider.mobile', '-')}
									</Text>
								</div>
							</Popup> */}
						</Marker>
					)}
				</MapContainer>
			</div>
			{(data.status == 3 || data.status == 4) && data.fulfillment?.rider?.name && (
				<div className='d-flex justify-content-between' style={{ padding: 5, backgroundColor: theme.colors.lightGrey7 }}>
					<Text semi style={{ fontSize: 14 }} component={'span'}>
						{_.get(data, 'fulfillment.rider.name', '-')}
						{data.fulfillment?.rider?.id && (
							<Text medium style={{ fontSize: 12, marginLeft: 5 }} component={'span'}>{`(${_.get(data, 'fulfillment.rider.id', '-')})`}</Text>
						)}
					</Text>

					<div className='d-flex align-items-center' style={{ width: '40%', justifyContent: 'flex-end', gap: 5 }}>
						<img src={ImageLinks.call_filled} />
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							{_.get(data, 'fulfillment.rider.mobile', '-')}
						</Text>
					</div>
				</div>
			)}
			<div className='d-flex' style={{ gap: 5, marginTop: 10 }}>
				{data.fulfillment.channel.id &&
					(network_configs[data?.fulfillment?.channel?.id]?.implementation == 'ppn-private' ||
						data?.fulfillment?.channel?.id == -1 ||
						data?.fulfillment?.channel?.id == -2) && (
						<>
							<Text component={'span'} semi style={{ color: theme.colors.darkGrey2 }}>
								Trip Info:
							</Text>
							<div
								ref={menu_ref}
								style={{ cursor: 'pointer' }}
								onClick={() => {
									if (pbid_data.trip_history?.length > 1) set_show_menu(true);
								}}>
								<Text semi component={'span'} style={{ color: theme.colors.primary }}>
									{_.get(pbid_data, `trip_history[${active_trip}].trip_id`, '-')}
								</Text>
								{pbid_data.trip_history?.length > 1 && (
									<img src={ImageLinks.chevronLeft} style={{ transform: 'rotate(270deg)', marginLeft: 5 }} />
								)}
							</div>
						</>
					)}

				<Popper
					anchorEl={menu_ref.current}
					open={show_menu}
					style={{ zIndex: 1202, marginRight: 30 }}
					onClose={() => set_show_menu(false)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}>
					<ClickAwayListener onClickAway={() => set_show_menu(false)}>
						<div className={classes.menu_wrap} style={{ backgroundColor: theme.colors.white }}>
							{_.map(pbid_data.trip_history, (trip, index) => {
								return (
									<div
										className='d-flex'
										style={{ padding: '8px 10px', gap: 5 }}
										onClick={() => {
											set_active_trip(index);
											set_show_menu(false);
										}}>
										<Text medium>{trip.trip_id}</Text>
									</div>
								);
							})}
						</div>
					</ClickAwayListener>
				</Popper>
			</div>
			<div className={classes.stepper_wrap}>
				<Stepper className={classes.stepper} orientation='vertical' connector={<CustomStepConnector></CustomStepConnector>}>
					{_.map(timeline_arr, (step, index) => {
						return (
							<Step key={`status_step_${index}`} active={index != pbid_data.trip_history[active_trip].timeline.length - 1}>
								<StepLabel
									StepIconComponent={() => {
										return <img src={ImageLinks.purple_double_circle} height={16} width={16} />;
									}}>
									<div className='d-flex justify-content-between'>
										<Text semi style={{ fontSize: 12, textTransform: 'capitalize' }}>
											{_.split(step.title, '_').join(' ')}
										</Text>
										<Text semi style={{ fontSize: 12 }}>
											{step.timestamp ? moment(step.timestamp).format('hh:mmA') : '-'}
										</Text>
									</div>
								</StepLabel>
								<StepContent
									className={classes.step_content}
									style={{ borderLeft: index == timeline_arr.length - 1 ? 'none' : `1px solid ${theme.colors.primary}` }}>
									<div className='d-flex justify-content-between'>
										<Text semi className={classes.step_remark}>
											{step.remark ? step.remark : '-'}
										</Text>
										<Text semi style={{ fontSize: 12 }} className={classes.step_remark}>
											{step.timestamp ? moment(step.timestamp).format('DD/MM/YY') : '-'}
										</Text>
									</div>
									<div style={{ margin: '10px 0' }}>
										<Divider />
									</div>
								</StepContent>
							</Step>
						);
					})}
				</Stepper>
			</div>
			{!show_rider_action && (
				<div className={classes.tnc_wrap}>
					<CustomAccordion
						header_icon_style={{ transform: 'rotate(180deg)' }}
						header={
							<Text semi style={{ fontSize: 14 }}>
								Partner Info
							</Text>
						}
						content={
							<div className={classes.tnc_wrap_open}>
								<Divider style={{ marginTop: 8 }} />
								<div className='d-flex align-items-center' style={{ gap: 10 }}>
									<div className={show_tnc ? classes.active_tab : classes.inactive_tab} onClick={() => set_show_tnc(true)}>
										<Text bold className={show_tnc ? classes.active_text : classes.inactive_text}>
											T&C
										</Text>
									</div>
									<div className={!show_tnc ? classes.active_tab : classes.inactive_tab} onClick={() => set_show_tnc(false)}>
										<Text bold className={!show_tnc ? classes.active_text : classes.inactive_text}>
											Support
										</Text>
									</div>
								</div>
								{show_tnc &&
									_.map(_.get(app, `app_configs.network_configs.${data.fulfillment?.channel?.id}.tnc`, []), (tnc, index) => {
										return (
											<div key={`tnc_${index}`} style={{ marginTop: 10 }}>
												<Text medium style={{ fontSize: 12 }}>
													{index + 1}. {tnc.heading}
												</Text>
												<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, paddingLeft: 12 }}>
													{tnc.description}
												</Text>
											</div>
										);
									})}
								{!show_tnc &&
									_.map(_.get(app, `app_configs.network_configs.${data.fulfillment?.channel?.id}.support`, []), (support, index) => {
										return (
											<>
												<div className={classes.support_item_wrap} key={`tnc_${index}`} style={{ marginTop: 10 }}>
													<div style={{ flex: 1 }}>
														<Text medium style={{ fontSize: 12, textTransform: 'capitalize', color: theme.colors.darkGrey2 }}>
															{support.type}
														</Text>
													</div>
													<div style={{ flex: 2 }}>
														<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey4 }}>
															{support.name}
															<br />
															{support.mobile}
															<br />
															{support.email}
														</Text>
													</div>
												</div>
												{index != 2 && <Divider />}
											</>
										);
									})}
							</div>
						}
					/>
				</div>
			)}
			{show_rider_action && <RiderActions active_trip={active_trip} />}
		</div>
	);
}

export default TripModal;
