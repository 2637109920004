import React, { useMemo } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import _ from 'lodash';
import clsx from 'clsx';

import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	card_wrap: {
		border: `1px solid ${theme.colors.lightGrey5}`,
		padding: 12,
		borderRadius: 6,
	},
	card_wrap_active: {
		border: `1px solid ${theme.colors.primary}`,
		padding: 12,
		borderRadius: 6,
	},
	list_wrap: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		width: '48%',
		maxHeight: '80vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	add_tag_text: {
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	arrow: {
		'&:before': {
			border: '1px solid #E6E8ED',
		},
		color: theme.palette.common.white,
	},
	tooltip: {
		backgroundColor: theme.palette.common.white,
		border: '1px solid #E6E8ED',
		color: '#4A4A4A',
		boxShadow: '0px 0px 4px 0px rgba(102, 102, 102, 0.30)',
		padding: 6,
	},
	scheduled_count: {
		borderRadius: 30,
		background: theme.colors.lightPink4,
		padding: '0 12px 0 12px',
		marginBottom: 'auto',
		marginTop: 'auto',
	},
}));

function FleetListing({ vehicle_list, set_assignment_data, selected_vehicle, set_edit_assignment_detail, handle_click_fleet }) {
	const classes = useStyles();

	return (
		<>
			{_.map(vehicle_list, (vehicle, index) => {
				let title = '';
				let vehicle_expiry_day = '';
				if (!_.isEmpty(vehicle.lease_to)) {
					vehicle_expiry_day = moment(moment(vehicle.lease_to).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days');
				}
				if (vehicle.start_time && vehicle.end_time) {
					title =
						_.isEmpty(vehicle.checkin_time) &&
						moment(moment().format('YYYY-MM-DD HH:mm')).isBefore(moment(vehicle.start_time).format('YYYY-MM-DD HH:mm')) &&
						(moment(moment().format('YYYY-MM-DD')).add(1, 'day').isSame(moment(vehicle.start_time).format('YYYY-MM-DD')) ||
							moment(moment().format('YYYY-MM-DD HH:mm')).add(1, 'day').isAfter(moment(vehicle.start_time).format('YYYY-MM-DD HH:mm')))
							? 'Upcoming'
							: _.isEmpty(vehicle.checkin_time) &&
							  moment(moment().format('YYYY-MM-DD HH:mm')).isAfter(moment(vehicle.start_time).format('YYYY-MM-DD HH:mm'))
							? 'Not Started'
							: !_.isEmpty(vehicle.checkin_time) &&
							  moment(moment(vehicle.start_time).format('YYYY-MM-DD HH:mm'))
									.add(15, 'minutes')
									.isSameOrAfter(moment(vehicle.checkin_time).format('YYYY-MM-DD HH:mm'))
							? 'Started Ontime'
							: !_.isEmpty(vehicle.checkin_time) &&
							  moment(moment(vehicle.start_time).format('YYYY-MM-DD HH:mm'))
									.add(15, 'minutes')
									.isSameOrBefore(moment(vehicle.checkin_time).format('YYYY-MM-DD HH:mm'))
							? 'Delayed'
							: '';
				}
				return (
					<div
						onClick={() => handle_click_fleet(vehicle, title)}
						className={clsx('cursor-pointer', vehicle.id == selected_vehicle.id ? classes.card_wrap_active : classes.card_wrap)}
						key={`vehicle_${index}`}>
						<div className='d-flex justify-content-between'>
							<div className='d-flex' style={{ gap: 5 }}>
								<img src={vehicle_expiry_day <= 2 ? ImageLinks.alert : ImageLinks.truck_purple} height={16} width={16} />
								<div>
									<Text bold>{vehicle.registration_number}</Text>
									<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>{`${vehicle.vehicle_type} ${
										vehicle.capacity ? '| ' + vehicle.capacity + ' m³' : ''
									}  ${vehicle.owner_name ? '| ' + vehicle.owner_name : ''} ${
										vehicle_expiry_day <= 0
											? '| ' + 'Expired'
											: vehicle_expiry_day <= 2
											? '| ' + `Expire in ${vehicle_expiry_day} ${vehicle_expiry_day < 2 ? 'day' : 'days'}`
											: ''
									}`}</Text>
								</div>
							</div>
							<img src={ImageLinks.arrow_up_circle_solid} style={{ transform: 'rotate(90deg)' }} />
						</div>
						{vehicle.assignment_id && <Divider style={{ margin: '8px 0' }} />}
						<div className='d-flex justify-content-between'>
							<div className='d-flex' style={{ gap: 5 }}>
								{!vehicle.assignment_id && !vehicle?.scheduled ? (
									<Button
										type='text'
										text='Assignment'
										size='extra_small'
										left_icon={ImageLinks.add_pruple_no_fill}
										onClick={() => {
											set_edit_assignment_detail({});
											set_assignment_data(vehicle);
										}}
									/>
								) : vehicle.rider_id ? (
									<>
										<img
											src={vehicle.rider_id == '-1' ? ImageLinks.no_rider_icon : ImageLinks.rider_light_purple2}
											height={16}
											width={16}
										/>
										<Text
											medium
											style={{
												fontSize: 12,
												color: theme.colors.darkGrey7,
											}}>
											{vehicle.rider_id == '-1' ? 'No Rider' : `${vehicle.rider_name} (${vehicle.rider_id})`}
										</Text>
									</>
								) : (
									<></>
								)}
							</div>
							{vehicle.assignment_id && (
								<div className='d-flex' style={{ gap: 4 }}>
									<Tooltip title={title} arrow={true} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} placement='top'>
										<img
											src={
												title == 'Not Started'
													? ImageLinks.exclamation_error
													: title == 'Started Ontime'
													? ImageLinks.green_tick
													: title == 'Delayed'
													? ImageLinks.tick_circle_solid_yellow
													: title == 'Upcoming'
													? ImageLinks.clock
													: ''
											}
										/>
									</Tooltip>
									<Text
										medium
										style={{
											fontSize: 12,
											color: theme.colors.darkGrey7,
										}}>{`${moment(vehicle.start_time).format(`DD MMM 'YY, h:mmA`)} - ${moment(vehicle.end_time).format(
										`DD MMM 'YY, h:mmA`,
									)}`}</Text>
								</div>
							)}
						</div>
						{vehicle?.scheduled > 0 && (
							<>
								<Divider style={{ margin: '8px 0' }} />
								<div className='d-flex' style={{ gap: 4 }}>
									<Text semi style={{ fontSize: 12 }}>
										Scheduled
									</Text>
									<div className={`d-flex justify-content-center align-items-center ${classes.scheduled_count}`}>
										<Text semi style={{ color: theme.colors.primary }}>
											{('0' + vehicle?.scheduled).slice(-2)}
										</Text>
									</div>
								</div>
							</>
						)}
					</div>
				);
			})}
		</>
	);
}

export default FleetListing;
