import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';

import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Papa from 'papaparse';
import Select from 'modules/Select';
import Text from 'modules/Text';
import constant from 'resources/constant';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import MapPolygonUtils from 'resources/MapPolygonUtils';
import { CREATE_OPTIONS, CUSTOM_FLOW_TYPE, FLOW_TYPE } from '../helper';
import { makeStyles } from '@material-ui/core';
import DrawOrCsvOption from './DrawOrCsvOption';
import UploadBox from './UploadBox';
import MapButtons from './MapButtons';
import Button from 'modules/Button';

let active_zone_markers;
let b_zone_markers;

const useStyles = makeStyles({
	step_number_wrap: {
		width: 30,
		height: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.colors.lightPink2,
		borderRadius: 20,
	},
	type_btn: { borderRadius: 20, marginRight: 8, height: 34 },
	right_half_wrap: { flex: 1, padding: 40, position: 'relative' },
	pidge_ntwk_btn: {
		position: 'absolute',
		right: 50,
		top: 50,
		zIndex: 100,
		padding: '5px 7px',
		borderRadius: 8,
	},

	zone_menu_item: {
		height: 56,
	},
});
const CreateBusinessZone = ({ prev_flow = FLOW_TYPE.TEMPLATE, selected_master_zone, pidge_zones, on_success }) => {
	const [selected_master_zone_id, set_master_selected_zone_id] = useState(null);
	const [selected_business_zone_id, set_business_selected_zone_id] = useState(null);
	const [business_zones, set_business_zones] = useState([]);
	const [active_zones, set_active_zones] = useState([]);
	const [show_pidge_network, set_show_pidge_network] = useState(false);
	const [flow_type, set_flow_type] = useState(prev_flow);
	const [show_edit, set_show_edit] = useState(false);
	const [show_draw, set_show_draw] = useState(false);
	const [in_edit_mode, set_in_edit_mode] = useState(false);
	const [name, set_name] = useState('');
	const [buffer_distance, set_buffer_distance] = useState('');
	const [is_poly_updated, set_is_poly_updated] = useState(false);
	const [custom_flow_type, set_custom_flow_type] = useState(null);
	const [in_draw_mode, set_in_draw_mode] = useState(false);
	const [upload_file_name, set_upload_file_name] = useState('');
	const [saved_press, set_saved_press] = useState(false);
	const [poly_coords_exist, set_poly_coords_exist] = useState(false);
	const map = useRef(null);
	const polygon_util_ref = useRef(null);
	const classes = useStyles();

	const initialize = () => {
		map.current = new window.google.maps.Map(document.getElementById('map_canvas'), {
			center: { lat: selected_master_zone.focal.coordinates[0], lng: selected_master_zone.focal.coordinates[1] },
			zoom: 10,
			clickableIcons: false,
			disableDefaultUI: true,
			scaleControl: true,
			zoomControl: true,
			mapId: constant.MAP_DESIGN_ID,
		});
		polygon_util_ref.current = new MapPolygonUtils(
			map.current,
			() => {
				set_is_poly_updated(true);
			},
			() => {
				set_in_draw_mode(false);
				set_is_poly_updated(true);
				set_show_draw(false);
				set_show_edit(true);
				set_poly_coords_exist(true);
			},
		);
		const polygon_coords = _.map(_.get(selected_master_zone, 'geometry.coordinates[0]', []), (coord) => {
			return { lat: coord[0], lng: coord[1] };
		});

		// Construct the polygon.
		const polygon = new window.google.maps.Polygon({
			paths: polygon_coords,
			strokeColor: theme.colors.primary,
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillOpacity: 0,
		});

		polygon.setMap(map.current);
	};

	useEffect(() => {
		if (selected_business_zone_id) {
			_.map(b_zone_markers, (marker) => {
				marker.setMap(null);
			});
			const selected_zone = _.find(business_zones, { id: selected_business_zone_id });
			const polygon_coords = _.map(_.get(selected_zone, 'geometry.coordinates[0]', []), (coord) => {
				return { lat: coord[0], lng: coord[1] };
			});
			polygon_util_ref.current.draw_polygon_from_existing_coords(polygon_coords, selected_zone, { zoom: 12 });
			set_show_edit(true);
			set_name(selected_zone.title);
		}
	}, [selected_business_zone_id]);

	useEffect(() => {
		if (active_zones.length === 0) {
			get_active_zones();
		}
		polygon_util_ref.current?.clear_polygon();
		_.map(b_zone_markers, (marker) => {
			marker.setMap(null);
		});
		set_master_selected_zone_id(null);
		set_business_selected_zone_id(null);
		set_name('');
		set_buffer_distance('');
	}, [flow_type]);

	useEffect(() => {
		if (_.isEmpty(custom_flow_type)) return;
		polygon_util_ref.current.deallocate_data_and_listeners();
		set_poly_coords_exist(false);
		set_show_edit(false);
		set_is_poly_updated(false);
		set_in_draw_mode(false);
		set_in_edit_mode(false);
		set_saved_press(false);
		set_upload_file_name('');
		if (custom_flow_type === CUSTOM_FLOW_TYPE.DRAW) {
			set_show_draw(true);
		} else {
			set_show_draw(false);
		}
	}, [custom_flow_type]);

	useEffect(() => {
		if (saved_press) {
			setTimeout(() => {
				set_saved_press(false);
				set_is_poly_updated(false);
			}, 1500);
		}
	}, [saved_press]);

	useEffect(() => {
		if (!selected_master_zone_id) {
			return;
		}
		set_business_zones([]);

		api_requests.get_sub_zones_geometry(selected_master_zone_id, { 'owner-id': -1, 'type-of-owner': 1 }).then((res) => {
			const _business_zones = _.map(res.data, (data) => {
				return {
					...data,
					color:
						'#' +
						Math.floor(Math.random() * 16777215)
							.toString(16)
							.padStart(6, '0'),
				};
			});
			set_business_zones(_business_zones);
			b_zone_markers = _.map(_business_zones, (zone) => {
				const polygon_coords = _.map(_.get(zone, 'geometry.coordinates[0]', []), (coord) => {
					return { lat: coord[0], lng: coord[1] };
				});

				// Construct the polygon.
				const polygon = new window.google.maps.Polygon({
					paths: polygon_coords,
					strokeColor: zone.color,
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: zone.color,
					fillOpacity: 0.35,
				});

				polygon.setMap(map.current);
				return polygon;
			});
		});
	}, [selected_master_zone_id]);

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, []);

	const get_active_zones = async () => {
		try {
			const response = await api_requests.get_sub_zones_geometry(selected_master_zone.id);
			if (response.success) {
				const _active_zones = _.map(response.data, (data) => {
					return {
						...data,
						color: theme.colors.darkGrey2,
					};
				});
				set_active_zones(_active_zones);
			}
		} catch (error) {}
	};

	const enable_draw_map = () => {
		set_in_draw_mode((prev) => !prev);
		if (in_draw_mode) polygon_util_ref.current.clear_drawing_manager();
		else polygon_util_ref.current.load_drawing_tools();
		return;
	};

	const enable_edit_map = () => {
		set_in_edit_mode((prev) => !prev);
		polygon_util_ref.current.update_polygon_options({
			editable: !in_edit_mode,
		});
	};

	const save_updated_poly = () => {
		if (saved_press) return;
		set_in_edit_mode(false);
		set_saved_press(true);
		const selected_zone = _.find(business_zones, { id: selected_business_zone_id });
		polygon_util_ref.current.handle_save_polygon(selected_zone);
		return;
	};

	const handle_csv = (fileInfo, inputRef) => {
		Papa.parse(fileInfo.file, {
			header: false,
			skipEmptyLines: true,
			complete: (results) => {
				inputRef.current.value = null;
				const data = results.data;
				const items = [];
				let invalid_message = '';
				let formatted_coords = [];
				_.map(data, (polygon, key) => {
					if (key === 0 || !_.isEmpty(invalid_message)) {
						return;
					}

					const coords = polygon[0];

					if (_.isEmpty(coords)) {
						invalid_message = 'Packages cannot be empty';
						return;
					}

					const regex = /^POLYGON\s*\(\((?:-?\d+(?:\.\d+)?\s+-?\d+(?:\.\d+)?(?:,\s*)?)+\)\)$/;

					if (!regex.test(coords)) {
						invalid_message = 'Invalid Format of coordinates';
						return;
					}

					const coords_string = coords.replace(/^POLYGON\s*\(\(/, '').replace(/\)\)$/, '');
					formatted_coords = coords_string.split(',').map((coord) => {
						const [lon, lat] = coord.trim().split(' ');
						return [parseFloat(lat), parseFloat(lon)];
					});

					items.push({
						formatted_coords,
					});
				});
				if (_.isEmpty(items)) {
					invalid_message = 'Polygons cannot be empty';
				}
				if (!_.isEmpty(invalid_message)) {
					toast.error(invalid_message, {
						position: toast.POSITION.BOTTOM_RIGHT,
					});
					return;
				}
				if (formatted_coords.length > 0) {
					set_upload_file_name(fileInfo.name);
					set_poly_coords_exist(true);
					polygon_util_ref.current.upload_poly_cooords(formatted_coords);
				}
			},
		});
	};

	const remove_uploaded_file = () => {
		set_upload_file_name('');
		polygon_util_ref.current.deallocate_data_and_listeners();
	};

	const handle_create = () => {
		const coordinates = [];
		const lat_lngs = polygon_util_ref.current.get_polygon_path();
		for (let i = 0; i < lat_lngs.length; i++) {
			coordinates.push([lat_lngs.getAt(i).lat(), lat_lngs.getAt(i).lng()]);
		}
		const first_coordinate = coordinates[0];
		const last_coordinate = coordinates[coordinates.length - 1];
		if (first_coordinate[0] != last_coordinate[0] || first_coordinate[1] != last_coordinate[1]) coordinates.push(first_coordinate);

		api_requests
			.create_business_zone(selected_master_zone.id, {
				title: name,
				geometry: {
					type: 'Polygon',
					coordinates: [coordinates],
				},
				buffer_distance: Number(buffer_distance),
			})
			.then((res) => {
				on_success();
				toast.success(res.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			});
	};

	const show_hide_active_zones = () => {
		if (!map.current) {
			return;
		}

		if (!show_pidge_network) {
			active_zone_markers = _.map(active_zones, (zone) => {
				const polygon_coords = _.map(_.get(zone, 'geometry.coordinates[0]', []), (coord) => {
					return { lat: coord[0], lng: coord[1] };
				});

				// Construct the polygon.
				const polygon = new window.google.maps.Polygon({
					paths: polygon_coords,
					strokeColor: zone.color,
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: zone.color,
					fillOpacity: 0.35,
				});

				polygon.setMap(map.current);

				return polygon;
			});
		} else {
			_.map(active_zone_markers, (marker) => {
				marker.setMap(null);
			});
		}

		set_show_pidge_network(!show_pidge_network);
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		let poly_drawn = true;
		if (flow_type === FLOW_TYPE.CUSTOM && poly_coords_exist === false) poly_drawn = false;
		let zone_cindition_valid = true;
		if (flow_type === FLOW_TYPE.TEMPLATE && selected_business_zone_id === null) zone_cindition_valid = false;
		if (poly_drawn && zone_cindition_valid && !_.isEmpty(name) && !_.isEmpty(buffer_distance)) value = false;
		return value;
	}, [flow_type, selected_business_zone_id, name, buffer_distance, poly_coords_exist]);

	return (
		<div style={{ flex: 1, display: 'flex', columnGap: '3%' }}>
			<div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '25px 0px' }}>
				<div style={{ flex: 1 }}>
					<Text bold style={{ fontSize: 18 }}>
						Create Business Zone in {selected_master_zone.title}
					</Text>
					<div style={{ marginTop: 20 }}>
						{_.map(CREATE_OPTIONS, (item, index) => (
							<ContainedButton
								onClick={() => set_flow_type(item.key)}
								key={`create_type_item_${index}`}
								className={classes.type_btn}
								style={{
									background: flow_type === item.key ? theme.colors.lightPurple : theme.colors.white,
									border: `1px solid ${flow_type === item.key ? theme.colors.primary : theme.colors.lightPurple6}`,
								}}>
								{/* <img src={flow_type === item.key ? item.enabled_icon : item.disbled_icon} width='12' alt='' /> */}
								<Text semi style={{ color: theme.colors.black }}>
									{item.title}
								</Text>
							</ContainedButton>
						))}
					</div>

					{flow_type === FLOW_TYPE.TEMPLATE && (
						<div style={{ marginTop: 24, display: 'flex' }}>
							<div className={classes.step_number_wrap}>
								<Text bold style={{ color: theme.colors.darkPurple }}>
									1
								</Text>
							</div>
							<div style={{ marginLeft: 10 }}>
								<Text bold style={{ fontSize: 18 }}>
									Select Zone
								</Text>
								<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 5 }}>
									Select the city where delivery will be enabled
								</Text>
								<div style={{ display: 'flex', columnGap: 20 }}>
									<Select
										value={selected_master_zone_id}
										defaultOption={{
											title: 'Search City',
											value: null,
										}}
										options={_.map(pidge_zones, (zone) => {
											return {
												title: zone.title,
												value: zone.id,
											};
										})}
										handleChange={(value) => {
											set_master_selected_zone_id(value);
											set_business_selected_zone_id(null);

											const selected_master_zone = _.find(pidge_zones, { id: value });

											map.current.setOptions({
												center: { lat: selected_master_zone.focal.coordinates[0], lng: selected_master_zone.focal.coordinates[1] },
												zoom: 10,
											});
										}}
										menu_styles={{ width: 210 }}
										text_styles={{ fontSize: 14, color: theme.colors.darkGrey2 }}
										text_props={{ medium: true }}
										containerStyles={{ width: 210, marginTop: 10, borderColor: theme.colors.lightPurple }}
									/>
									<Select
										value={selected_business_zone_id}
										defaultOption={{
											title: 'Select Area',
											value: null,
										}}
										options={_.map(business_zones, (zone) => {
											return {
												title: zone.title,
												value: zone.id,
											};
										})}
										handleChange={(value) => {
											set_business_selected_zone_id(value);
										}}
										menu_styles={{ width: 310 }}
										text_styles={{ fontSize: 14, color: theme.colors.darkGrey2 }}
										text_props={{ medium: true }}
										containerStyles={{ width: 310, marginTop: 10, borderColor: theme.colors.lightPurple }}
									/>
								</div>
							</div>
						</div>
					)}
					<div
						style={{
							marginTop: 24,
							display: 'flex',
							opacity: flow_type === FLOW_TYPE.TEMPLATE && (selected_master_zone_id === null || selected_business_zone_id === null) ? 0.2 : 1,
						}}>
						<div className={classes.step_number_wrap}>
							<Text bold style={{ color: theme.colors.darkPurple }}>
								{flow_type === FLOW_TYPE.TEMPLATE ? 2 : 1}
							</Text>
						</div>
						<div style={{ marginLeft: 10 }}>
							<Text bold style={{ fontSize: 18 }}>
								Business Zone Name
							</Text>
							<UndlerlineTextField
								style={{ width: 200, marginTop: 10 }}
								placeholder='Enter Name'
								value={name}
								onChange={(e) => set_name(e.target.value)}
								label='Business Zone Name*'
								disabled={flow_type === FLOW_TYPE.TEMPLATE && (selected_master_zone_id === null || selected_business_zone_id === null)}
							/>
						</div>
						<div style={{ marginLeft: 12 }}>
							<UndlerlineTextField
								style={{ width: 200, marginTop: 35.5 }}
								type='number'
								placeholder='Enter distance'
								value={buffer_distance}
								label='Buffer Distance (m)*'
								onChange={(e) => set_buffer_distance(e.target.value)}
								disabled={flow_type === FLOW_TYPE.TEMPLATE && (selected_master_zone_id === null || selected_business_zone_id === null)}
								helperText={
									<div className='d-flex'>
										<img src={ImageLinks.info_light_purple} width={10} height={10} style={{ marginTop: 4, marginRight: 4 }} />
										<Text medium style={{ fontSize: 10, color: theme.colors.lightGrey2 }}>
											Area that can be define outside the zone boundary
										</Text>
									</div>
								}
							/>
						</div>
					</div>
					{flow_type === FLOW_TYPE.CUSTOM && (
						<div style={{ marginTop: 40, display: 'flex' }}>
							<div className={classes.step_number_wrap}>
								<Text bold style={{ color: theme.colors.darkPurple }}>
									2
								</Text>
							</div>
							<div style={{ marginLeft: 12 }}>
								<Text bold style={{ fontSize: 18, marginBottom: 10 }}>
									Define Area
								</Text>
								<DrawOrCsvOption
									title={'Draw on Map'}
									is_selected={custom_flow_type === CUSTOM_FLOW_TYPE.DRAW}
									on_item_click={() => set_custom_flow_type(CUSTOM_FLOW_TYPE.DRAW)}
								/>

								<DrawOrCsvOption
									title={'Upload CSV'}
									is_selected={custom_flow_type === CUSTOM_FLOW_TYPE.UPLOAD}
									on_item_click={() => set_custom_flow_type(CUSTOM_FLOW_TYPE.UPLOAD)}
								/>

								{custom_flow_type === CUSTOM_FLOW_TYPE.UPLOAD && (
									<UploadBox file_name={upload_file_name} handle_csv={handle_csv} remove_uploaded_file={remove_uploaded_file} />
								)}
							</div>
						</div>
					)}
				</div>
				<Button type='round' size='large' text='Create' onClick={handle_create} disabled={btn_disabled} style={{ width: 110 }} />
			</div>
			<div className={classes.right_half_wrap}>
				<MapButtons
					show_edit={show_edit}
					on_edit_press={enable_edit_map}
					in_edit_mode={in_edit_mode}
					show_draw={show_draw}
					on_draw_press={enable_draw_map}
					in_draw_mode={in_draw_mode}
					show_save={is_poly_updated}
					on_save_press={save_updated_poly}
					saved_press={saved_press}
				/>

				<ContainedButton
					onClick={show_hide_active_zones}
					style={{
						background: theme.colors.lightPurple,
						position: 'absolute',
						right: 50,
						top: 50,
						zIndex: 100,
						padding: '5px 7px',
						borderRadius: 8,
					}}>
					<img src={show_pidge_network ? ImageLinks.eye_open : ImageLinks.eye_close} width='14' alt='' />
					<Text bold style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 10 }}>
						Active Zones
					</Text>
				</ContainedButton>

				<div
					id='map_canvas'
					className='map-canvas-wrapper rider-location card'
					style={{ height: '100%', borderRadius: 20, background: theme.colors.lightGrey3 }}></div>
			</div>
		</div>
	);
};

export default CreateBusinessZone;
