import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api_requests from 'resources/api_requests';

const useEditRider = (rider_id) => {
	const history = useHistory();
	const [rider_details, set_rider_details] = useState({});
	const [address_modal, set_address_modal] = useState(false);
	const [rider_data, set_rider_data] = React.useState({
		first_name: '',
		last_name: '',
		date_of_birth: '',
		blood_group: '',
		mobile: '',
		aadhar_number: '',
		profile_pic: '',
		current_location: {},
		enable_trace: false,
	});
	const [selected_section, set_selected_section] = useState(0);
	const [rider_tags, set_rider_tags] = useState([]);
	const [show_success, set_show_success] = useState(false);

	useEffect(() => {
		fetch_rider_info();
	}, []);

	const fetch_rider_info = async () => {
		try {
			const response = await api_requests.get_rider_details(rider_id);
			if (response.success) {
				update_rider_state(response.data);
				set_rider_details(response.data);
			}
		} catch (error) {}
	};

	const update_rider_state = (data) => {
		set_rider_data({
			first_name: _.get(data, 'details.rider_name', '').split(' ')[0],
			last_name: _.get(data, 'details.rider_name', '').split(' ')[1],
			date_of_birth: _.get(data, 'details.date_of_birth', '') ? moment(data.details.date_of_birth).format('YYYY-MM-DD') : '',
			blood_group: _.get(data, 'details.blood_group', ''),
			mobile: _.get(data, 'details.mobile', ''),
			aadhar_number: _.get(data, 'details.aadhar_number', ''),
			profile_pic: _.get(data, 'details.profile_pic', ''),
			current_location: _.cloneDeep(_.get(data, 'home_location', {})),
			enable_trace: _.get(data, 'details.trace_entity_id', null) == null ? false : true,
		});
		set_rider_tags(_.get(data, 'tags', []));
	};

	const handle_data = (name, value) => {
		set_rider_data({
			...rider_data,
			[name]: value,
		});
	};

	const handle_dob = (name, date) => {
		const today_date = moment().format('YYYY-MM-DD');
		const selected_date = moment(date).format('YYYY-MM-DD');

		const years_diff = moment(today_date).diff(selected_date, 'years');
		if (years_diff < 15) {
			toast.error(`Minimum 16 years of age is required.`, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}
		handle_data(name, selected_date);
	};

	const on_address_select = (address) => {
		set_rider_data({
			...rider_data,
			current_location: {
				address_line_1: address.address_line1,
				pin_code: address.pincode,
				city: address.city,
				state: address.state,
				latitude: address.coordinates.latitude,
				longitude: address.coordinates.longitude,
				zone: address.zone,
			},
		});
	};

	const edit_addr = () => {
		set_address_modal(true);
		if (_.isEmpty(rider_data.current_location)) return;
	};

	const data_diff = () => {
		if (_.isEmpty(rider_data) || _.isEmpty(rider_details)) return;
		const only_updated_values = {};
		const original_value = _.cloneDeep(rider_details);
		const new_value = _.cloneDeep(rider_data);

		if (new_value.profile_pic != original_value.details.profile_pic) only_updated_values.profile_pic_url = new_value.profile_pic;

		const original_enable_trace = _.get(original_value, 'details.trace_entity_id', null) == null ? false : true;
		if (new_value.enable_trace != original_enable_trace) only_updated_values.enable_trace = new_value.enable_trace;

		const personal_info = {};
		if (new_value.blood_group != original_value.details.blood_group) personal_info.blood_group = new_value.blood_group;

		const new_format_dob = moment(new_value.date_of_birth).format('YYYY-MM-DD');
		const old_format_dob = moment(original_value.details.date_of_birth).format('YYYY-MM-DD');

		if (new_format_dob != old_format_dob) personal_info.date_of_birth = new_value.date_of_birth;
		if (new_value.aadhar_number != original_value.details.aadhar_number) personal_info.aadhar_number = new_value.aadhar_number;

		if (
			new_value.current_location.latitude != original_value.home_location.latitude ||
			new_value.current_location.longitude != original_value.home_location.longitude ||
			new_value.current_location.address_line_1 != original_value.home_location.address_line_1 ||
			new_value.current_location.city != original_value.home_location.city ||
			new_value.current_location.state != original_value.home_location.state ||
			new_value.current_location.pin_code != original_value.home_location.pin_code
		) {
			personal_info.current_location = new_value.current_location;
			delete personal_info.current_location.zone;
		}

		let tags = [];
		if (original_value.tags.length != rider_tags.length) tags = _.cloneDeep(rider_tags);
		else {
			let tags_updated = false;
			for (let i = 0; i < rider_tags.length; i++) {
				const new_tag = rider_tags[i];
				const old_tag = original_value.tags[i];
				if (new_tag.key != old_tag.key || new_tag.value != old_tag.value) {
					tags_updated = true;
					break;
				}
			}
			if (tags_updated) tags = _.cloneDeep(rider_tags);
		}

		if (!_.isEmpty(personal_info)) only_updated_values.personal_information = personal_info;
		if (tags.length > 0) only_updated_values.tags = _.map(tags, (item) => ({ key: item.key, value: item.value }));
		return only_updated_values;
	};

	const handle_update = async () => {
		const only_updated_values = data_diff();
		if (_.isEmpty(only_updated_values)) return;

		try {
			const response = await api_requests.update_rider_details(rider_id, only_updated_values);
			if (response.success) {
				fetch_rider_info();
				set_show_success(true);
			}
		} catch (error) {}
	};

	return {
		history,
		rider_data,
		rider_details,
		rider_tags,
		selected_section,
		address_modal,
		show_success,
		set_rider_tags,
		set_selected_section,
		handle_data,
		set_address_modal,
		handle_dob,
		on_address_select,
		edit_addr,
		handle_update,
		data_diff,
		set_show_success,
	};
};

export default useEditRider;
