import { CircularProgress, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React, { useContext } from 'react';
import theme from 'resources/theme';
import CreateNetworkContext from 'views/NetworksFlow/context';

const useStyles = makeStyles(() => ({
	steps_counter_wrap: {
		width: 18,
		height: 18,
		borderRadius: 9,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 4,
	},
	steps_item_wrap: {
		display: 'flex',
		marginBottom: 16,
		alignItems: 'center',
	},
}));

const StepItem = ({ item, index, item_progress, is_disabled = false, handle_btn_click = () => {} }) => {
	const { edit_network_id } = useContext(CreateNetworkContext);
	const classes = useStyles();
	return (
		<div className={classes.steps_item_wrap}>
			{item_progress === 100 && item.type !== 'button' ? (
				<img src={ImageLinks.tickCirclePurpleSolid} width={18} height={18} style={{ marginRight: 4 }} />
			) : (
				<div style={{ position: 'relative', display: 'flex' }}>
					<CircularProgress variant='determinate' value={item.type === 'button' ? 0 : item_progress} size={22} style={{ marginRight: 4 }} />
					<div
						className={classes.steps_counter_wrap}
						style={{
							position: 'absolute',
							top: 2,
							left: 2,
							backgroundColor:
								item.type === 'button' ? theme.colors.lightPurple : item_progress === 100 ? theme.colors.primary : theme.colors.lightPurple,
						}}>
						<Text
							bold
							style={{
								fontSize: 12,
								color:
									item.type === 'button' ? theme.colors.lightPurple7 : item_progress === 100 ? theme.colors.white : theme.colors.lightPurple7,
							}}>
							{index + 1}
						</Text>
					</div>
				</div>
			)}
			{item.type === 'label' && (
				<Text medium style={{ fontSize: 12, color: theme.colors.primary }}>
					{item.title}
				</Text>
			)}
			{item.type === 'button' && (
				<Button type='round' size='small' text={edit_network_id ? item.edit_title : item.title} disabled={is_disabled} onClick={handle_btn_click} />
			)}
		</div>
	);
};

export default StepItem;
