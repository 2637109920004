import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Text from 'modules/Text';
import theme from 'resources/theme';

const useStyles = makeStyles({
	wrapper: {
		cursor: 'pointer',
		borderRadius: 6,
		padding: 16,
		background: theme.colors.white,
		marginTop: 10
	},
	desc_text: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 2 },
	separator: { border: `1px solid ${theme.colors.lightGrey5}`, marginTop: 8, marginBottom: 8 }
});

const RecommendedAlerts = ({ title, description, link, onClick }) => {
	const classes = useStyles();

	return (
		<div onClick={onClick} className={clsx('box-shadow', classes.wrapper)}>
			<Text medium style={{ fontSize: 16 }}>
				{title}
			</Text>
			<Text medium className={classes.desc_text}>
				{description}
			</Text>
			<div className={classes.separator}></div>
			<Text medium style={{ fontSize: 12, color: theme.colors.primary }}>
				{link}
			</Text>
		</div>
	);
};

export default RecommendedAlerts;
