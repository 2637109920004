import React, { useContext } from 'react';
import Text from 'modules/Text';
import _ from 'lodash';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/core';
import CreateNetworkContext from '../../context';
import Routes from 'resources/Routes';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	title: {
		fontSize: 22,
		paddingBottom: 5,
		color: theme.colors.black,
	},
	header: { display: 'flex', justifyContent: 'space-between', marginBottom: 32, alignItems: 'center' },
	all_riders_text: {
		fontSize: 22,
	},
	screen_text_wrap: {
		height: 22,
		width: 2,
		backgroundColor: theme.colors.black,
		marginLeft: 8,
		marginRight: 8,
	},
	delete_btn: {
		backgroundColor: theme.colors.red,
		border: `1px solid ${theme.colors.red}`,
		'&:hover': {
			background: 'linear-gradient(180deg, #BC0000 0%, #620101 112.5%) !important',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15) !important',
			border: 'none !important',
		},
	},
}));

const Header = () => {
	const { show_lower_section, invite_network_id, edit_network_id, handle_network_delete } = useContext(CreateNetworkContext);
	const history = useHistory();
	const classes = useStyles();
	const query_object = qs.parse(history.location.search.slice(1));

	const on_header_press = () => {
		if (show_lower_section) history.push(Routes.NETWORKS.path);
		else history.goBack();
	};

	return (
		<div className={classes.header}>
			<div className='align-items-center'>
				<Text onClick={on_header_press} className={classes.all_riders_text} style={{ cursor: 'pointer' }}>
					Networks
				</Text>
				<Text component={'span'} className={classes.all_riders_text}>
					{invite_network_id ? `  | ${query_object.name}` : ''}
				</Text>
				<div className={classes.screen_text_wrap} />
				<Text semi={!(show_lower_section && !edit_network_id)} className={'header_active_title'} style={show_lower_section ? { borderWidth: 0 } : {}}>
					{invite_network_id ? 'Invite Participant' : edit_network_id ? 'Edit' : 'Create'}
				</Text>
				{show_lower_section && !edit_network_id && (
					<>
						<div className={classes.screen_text_wrap} />
						<Text semi className={'header_active_title'}>
							Setup Network
						</Text>
					</>
				)}
			</div>
			{edit_network_id && <Button type='round' text={'Delete Network'} className={classes.delete_btn} onClick={handle_network_delete} />}
		</div>
	);
};

export default Header;
