import React, { useEffect, useRef } from 'react';
import { Route, Switch, Redirect, Router, Link, useHistory } from 'react-router-dom';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import qs from 'qs';
import * as Sentry from '@sentry/react';

import constant from 'resources/constant';
import Modal from 'react-modal';
import theme from 'resources/theme';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Login from 'views/Login/Login';
import Routes from 'resources/Routes';
import _ from 'lodash';
import store from 'store';
import utils from 'resources/utils';
import { get_user_details, get_business_properties, user_details_received, login_failed, master_zones_received } from 'actions/app';
import browserHistory from 'browserHistory';
import { MuiThemeProvider } from '@material-ui/core';
import loadjs from 'loadjs';
import Loader from 'modules/Loader';
import ErrorBoundary from 'modules/ErrorBoundary';
import RiderOverviewModal from 'modules/modals/RiderOverviewModal';
import api_requests from 'resources/api_requests';
import AllRiders from 'views/AllRiders/AllRiders';
import CashManagementDashboard from 'views/CashManagementFlow/CashManagementDashboard/CashManagementDashboard';
import CM_AdminDetail from 'views/CashManagementFlow/CM_AdminDetail/CM_AdminDetail';
import CM_AllAdmins from 'views/CashManagementFlow/CM_AllAdmins/CM_AllAdmins';
import CM_AllRiders from 'views/CashManagementFlow/CM_AllRiders/CM_AllRiders';
import CM_MyOverview from 'views/CashManagementFlow/CM_MyOverview/CM_MyOverview';
import CM_RiderDetail from 'views/CashManagementFlow/CM_RiderDetail/CM_RiderDetail';
import CreateOrder from 'views/CreateOrderFlow/CreateOrder/CreateOrder';
import ManageHub from 'views/ManageHub/ManageHub';
import ManagePickupAddress from 'views/ManagePickupAddress/ManagePickupAddress';
import AllOrders from 'views/AllOrders/AllOrders';
import Roster from 'views/Roster/Roster';
import Tracking from 'views/Tracking/Tracking';
import AllOrdersByRider from 'views/AllOrdersByRider/AllOrdersByRider';
import GroupingAndAllocation from 'views/GroupingAndAllocation/GroupingAndAllocation';
import MyWallet from 'views/MyWallet/MyWallet';
import Settings from 'views/SettingsFlow/Settings/Settings';
import Home from 'views/UserManagement/Home/Home';
import AlertsListing from 'views/Alerts/AlertsListing/AlertsListing';
import CreateAlert from 'views/Alerts/CreateAlert/CreateAlert';
import OrderSettings from 'views/SettingsFlow/OrderSettings/OrderSettings';
import ZonesDashboard from 'views/ZonesDashboard/ZonesDashboard';
import RiderProfile from 'views/AllRiders/components/RiderProfile/RiderProfile';
import PoweredWithPidge from 'views/PoweredWithPidge/PoweredWithPidge';
import Dashboard from 'views/Dashboard/Dashboard';
import CommManagement from 'views/CommManagementFlow/CommManagement/CommManagement';
import CreateCommunication from 'views/CommManagementFlow/CreateCommunication/CreateCommunication';
import AppSocket from 'resources/AppSocket';
import Sidebar from 'views/Sidebar/Sidebar';
import LiveAlerts from 'views/Alerts/LiveAlerts/LiveAlerts';
import IntegrationManagement from 'views/IntegrationManagement/IntegrationManagement';
import GenerateToken from 'views/IntegrationManagement/components/GenerateToken/GenerateToken';
import EditToken from 'views/IntegrationManagement/components/EditToken.jsx/EditToken';
import AdvancedOrderAnalytics from 'views/AdvancedOrderAnalytics/AdvancedOrderAnalytics';
import AdvancedRiderAnalytics from 'views/AdvancedRiderAnalytics/AdvancedRiderAnalytics';
import RiderSettings from 'views/SettingsFlow/RiderSettings/RiderSettings';
import { toggle_notif_modal } from 'actions/uiUpdates';
import RepeatOrders from 'views/RepeatOrdersFlow/RepeatOrders';
import EditRider from 'views/AllRiders/EditRider/EditRider';
import NetworkListing from 'views/NetworksFlow/NetworkListing/NetworksListing';
import CreateNetwork from 'views/NetworksFlow/CreateNetwork/CreateNetwork';
import NetworkContextProvider from 'views/NetworksFlow/NetworkContextProvider';
import InviteParticipant from 'views/NetworksFlow/InviteParticipant/InviteParticipant';
import FleetManagement from 'views/FleetManagement/FleetManagement';
import MobileView from 'modules/MobileView';
import TraceListing from 'views/TraceManagementFlow/TraceListing/TraceListing';
import WhitelabellingSettings from 'views/SettingsFlow/WhitelabellingSetting/WhitelabellingSetting';
import ChildBusinessList from 'views/ChildBusinessFlow/ChildBusinessList/ChildBusinessList';
import ShopifyAuth from 'views/ShopifyAuth/ShopifyAuth';
import ReactGa from 'react-ga4';
import Mixpanel from 'mixpanel-browser';
import PreLogin from 'views/PreLogin/PreLogin.js';
import SmartAllocationSettings from 'views/SettingsFlow/OrderSettings/components/SmartAllocationSettings/SmartAllocationSettings';
import AutoManifestSettings from 'views/SettingsFlow/OrderSettings/components/AutoManifestSettings/AutoManifestSettings';
import NotificationService from 'resources/NotificationService';

Modal.setAppElement('#root');

if (!constant.CONSOLE_LOG) console.log = () => {};
class App extends React.Component {
	componentDidMount() {
		ReactGa.initialize(constant.ENVIRONMENT == constant.env.PRODUCTION ? constant.GA4_KEY_PROD : constant.GA4_KEY_DEV, {
			debug: false,
		});
		Mixpanel.init(constant.ENVIRONMENT == constant.env.PRODUCTION ? constant.MIX_PANEL_KEY_PROD : constant.MIX_PANEL_KEY_DEV, {
			debug: false,
			track_pageview: false,
			persistence: 'localStorage',
		});
		loadjs(`https://maps.googleapis.com/maps/api/js?sensor=false&key=${constant.GOOGLE_MAP_KEY}&v=3.exp&libraries=places,drawing,marker`);
	}
	render() {
		return (
			<Sentry.ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
				<MuiThemeProvider theme={theme}>
					<ToastContainer />
					<Provider store={store}>
						<Router history={browserHistory}>
							<Switch>
								<Route path='/login' component={Login} />
								<Route path='/' component={PrimaryComponent} />
								<Redirect to='/' />
							</Switch>
						</Router>
					</Provider>
				</MuiThemeProvider>
			</Sentry.ErrorBoundary>
		);
	}
}

const PrimaryComponent = () => {
	const history = useHistory();
	const query_object = qs.parse(history.location.search.slice(1));
	const { app } = useSelector((state) => state, shallowEqual);
	const [is_mobile, set_is_mobile] = React.useState(window.innerWidth <= 768);
	const dispatch = useDispatch();
	useEffect(() => {
		if (query_object?.utm_source) {
			utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.UTM_SOURCE}=${query_object?.utm_source}`);
			utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.UTM_MEDIUM}=${query_object?.utm_medium}`);
			utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.UTM_CAMPAIGN}=${query_object?.utm_campaign}`);
		}
		dispatch(get_user_details());
		window.addEventListener('resize', handleResize);
		window.addEventListener('focus', handle_focus);

		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('focus', handle_focus);
		};
	}, []);

	// useEffect(() => {
	// 	if (app.selected_business_id) {
	// 		dispatch(get_business_properties());
	// 	}
	// }, [app.selected_business_id]);

	const handle_focus = () => {
		NotificationService.reset_favicon();
	};

	const handleResize = () => {
		set_is_mobile(window.innerWidth <= 768); // Adjust the threshold as needed
	};

	const is_mobile_user_agent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent);
	if (is_mobile || is_mobile_user_agent) return <MobileView />;

	if (app.status === constant.REDUX_STATE.IDLE) {
		return null;
	}

	if (app.status !== constant.REDUX_STATE.SUCCEEDED) {
		const is_login_flage = _.includes(constant.ALLOWED_ORIGINS, window?.location?.origin);
		return (
			<Switch>
				<Route path='/' exact component={is_login_flage ? PreLogin : Login} />
				<Route path={Routes.LOGIN.path} exact={Routes.LOGIN.exact} component={Login} />
				<Redirect to='/' />
			</Switch>
		);
	}
	return <MainComponent />;
};

const MainComponent = () => {
	const { app } = useSelector((state) => state, shallowEqual);
	const { alerts } = useSelector((state) => state.alerts);
	const [alert, setAlert] = React.useState([]);
	const [rider_data, set_rider_data] = React.useState(null);
	const [open, setOpen] = React.useState(true);
	const new_alert_ref = useRef([]);
	const history = useHistory();
	const dispatch = useDispatch();
	const query_object = qs.parse(history.location.search.slice(1));

	useEffect(() => {
		const biz_config = app.app_configs.business_configs[app.selected_business_id];
		if (!_.isEmpty(biz_config?.whitelabeling)) theme.colors.sideNavPrimary = biz_config?.whitelabeling?.primary_color;
		else theme.colors.sideNavPrimary = theme.colors.primary;
	}, [app.app_configs]);

	useEffect(() => {
		const name = utils._retrieveData(constant.NAME);
		const mobile = utils._retrieveData(constant.MOBILE);
		const user_id = utils._retrieveData(constant.USER_ID);
		const user_type = utils._retrieveData(constant.TYPE_OF_USER);
		const user_email = utils._retrieveData(constant.USER_EMAIL);
		const user_teams = app.user_teams;
		const auth_token = utils._retrieveData(constant.AUTH_TOKEN);
		const type_of_onwer = utils._retrieveData(constant.TYPE_OF_OWNER).id;

		if (auth_token) {
			AppSocket.initialize({
				params: { token: auth_token },
				on_socket_connected: () => {
					console.log('Socket connected');
					for (let i = 0; i < app.business_accounts.length; i++) {
						const element = app.business_accounts[i];
						let room = `${element.id}@${type_of_onwer}`;
						AppSocket.join_room(room);
					}
					for (let i = 0; i < user_teams.length; i++) {
						const element = user_teams[i];
						let team_channel = `team_ch_${element.team_id}`;
						AppSocket.join_room(team_channel);
					}
					AppSocket.join_room(`user_ch_${user_id}`);
				},
			});
		}
		if (name) {
			dispatch(user_details_received(name, mobile, user_id, user_type, user_email));
		} else {
			dispatch(login_failed({ logout: false }));
		}
		// if (!_.isEmpty(query_object.shopify_token))
		// 	history.push(Routes.SHOPIFY_AUTH.path + `?shopify_token=${query_object.shopify_token}&is_login=${query_object.is_login}`);
	}, []);

	useEffect(() => {
		if (alerts.length > 0) {
			const new_alert = alerts[alerts.length - 1];
			const temp = [...alert, new_alert];
			setAlert(temp);
			new_alert_ref.current = temp;
		}
	}, [alerts]);

	useEffect(() => {
		(async () => {
			try {
				dispatch(master_zones_received([]));
				const zones = (await api_requests.get_master_zones(app.selected_business_id)).data;
				dispatch(master_zones_received(zones));
			} catch (err) {}
		})();
	}, [app.selected_business_id, app.refresh_master_zones]);

	useEffect(() => {
		const event_string =
			'page_view_' + history.location.pathname.split('/')[1] + (history.location.search != '' ? '_' + history.location.search.split('=')[1] : '');
		utils.track_event_for_analytics(event_string.split('-').join('_'));
	}, [history.location]);

	if (_.isEmpty(app.zones)) {
		return null;
	}

	if (!app.user_details.name) {
		return null;
	}

	const handle_alert_close = (shown_alert) => {
		const filtered = _.filter(new_alert_ref.current, (item) => item.id != shown_alert.id);
		new_alert_ref.current = filtered;
		if (filtered.length === 0) setAlert(filtered);
	};

	const open_notif_modal = (item) => {
		dispatch(
			toggle_notif_modal({
				open: true,
				is_alert: item.notif_type === 'alert',
			}),
		);
	};

	return (
		<div style={{ flex: 1, display: 'flex', backgroundColor: theme.colors.sideNavPrimary, height: '100%' }}>
			<Loader visible={app.is_loading} />
			<div style={open ? { width: 240 } : { width: 72 }}>
				<Sidebar set_rider_data={set_rider_data} open={open} setOpen={setOpen} />
			</div>
			<div
				key={app.selected_business_id}
				style={{
					flex: 1,
					height: '100%',
					position: 'relative',
					background: '#FAFAFA',
					borderTopLeftRadius: 30,
					overflow: 'hidden',
					background: theme.colors.white,
				}}>
				<Switch>
					{utils._retrieveData(constant.AUTH_TOKEN) &&
						_.map(
							[
								{
									path: Routes.ALL_ORDERS.path,
									exact: Routes.ALL_ORDERS.exact,
									component: AllOrders,
								},
								{
									path: Routes.POWERED_WITH_PIDGE.path,
									exact: Routes.POWERED_WITH_PIDGE.exact,
									component: PoweredWithPidge,
								},
								{
									path: Routes.CREATE_ORDER.path,
									exact: Routes.CREATE_ORDER.exact,
									component: CreateOrder,
								},
								{
									path: Routes.TRACKING.path,
									exact: Routes.TRACKING.exact,
									component: Tracking,
								},
								{
									path: Routes.SETTINGS.path,
									exact: Routes.SETTINGS.exact,
									component: Settings,
								},
								{
									path: Routes.ORDER_SETTINGS.path,
									exact: Routes.ORDER_SETTINGS.exact,
									component: OrderSettings,
								},
								{
									path: Routes.RIDER_SETTINGS.path,
									exact: Routes.RIDER_SETTINGS.exact,
									component: RiderSettings,
								},
								{
									path: Routes.WHITELABELLING_SETTINGS.path,
									exact: Routes.WHITELABELLING_SETTINGS.exact,
									component: WhitelabellingSettings,
								},
								{
									path: Routes.ALL_RIDERS.path,
									exact: Routes.ALL_RIDERS.exact,
									component: AllRiders,
								},
								{
									path: Routes.ALL_ORDERS_BY_RIDER.path,
									exact: Routes.ALL_ORDERS_BY_RIDER.exact,
									component: AllOrdersByRider,
								},
								{
									path: Routes.GROUPING_AND_ALLOCATION.path,
									exact: Routes.GROUPING_AND_ALLOCATION.exact,
									component: GroupingAndAllocation,
								},
								{
									path: Routes.ROSTER.path,
									exact: Routes.ROSTER.exact,
									component: Roster,
								},
								{
									path: Routes.MANGE_HUB.path,
									exact: Routes.MANGE_HUB.exact,
									component: ManageHub,
								},
								{
									path: Routes.MANGE_PICKUP_ADDRESS.path,
									exact: Routes.MANGE_PICKUP_ADDRESS.exact,
									component: ManagePickupAddress,
								},
								{
									path: Routes.CASH_MANAGEMENT.path,
									exact: Routes.CASH_MANAGEMENT.exact,
									component: CashManagementDashboard,
								},
								{
									path: Routes.CASH_MANAGEMENT_MY_OVERVIEW.path,
									exact: Routes.CASH_MANAGEMENT_MY_OVERVIEW.exact,
									component: CM_MyOverview,
								},
								{
									path: Routes.CASH_MANAGEMENT_ALL_RIDERS.path,
									exact: Routes.CASH_MANAGEMENT_ALL_RIDERS.exact,
									component: CM_AllRiders,
								},
								{
									path: Routes.CASH_MANAGEMENT_RIDER_DETAIL.path,
									exact: Routes.CASH_MANAGEMENT_RIDER_DETAIL.exact,
									component: CM_RiderDetail,
								},
								{
									path: Routes.CASH_MANAGEMENT_ALL_ADMIN.path,
									exact: Routes.CASH_MANAGEMENT_ALL_ADMIN.exact,
									component: CM_AllAdmins,
								},
								{
									path: Routes.CASH_MANAGEMENT_ADMiN_DETAIL.path,
									exact: Routes.CASH_MANAGEMENT_ADMiN_DETAIL.exact,
									component: CM_AdminDetail,
								},
								{
									path: Routes.MY_WALLET.path,
									exact: Routes.MY_WALLET.exact,
									component: MyWallet,
								},
								{
									path: Routes.USER_MANAGEMENT.path,
									exact: Routes.USER_MANAGEMENT.exact,
									component: Home,
								},
								{
									path: Routes.HOME.path,
									exact: Routes.HOME.exact,
									component: Dashboard,
								},
								{
									path: Routes.MY_ALERTS.path,
									exact: Routes.MY_ALERTS.exact,
									component: AlertsListing,
								},
								{
									path: Routes.CREATE_ALERT.path,
									exact: Routes.CREATE_ALERT.exact,
									component: CreateAlert,
								},
								{
									path: Routes.COMMUNICATION_MANAGEMENT.path,
									exact: Routes.COMMUNICATION_MANAGEMENT.exact,
									component: CommManagement,
								},
								{
									path: Routes.CREATE_COMMUNICATION.path,
									exact: Routes.CREATE_COMMUNICATION.exact,
									component: CreateCommunication,
								},
								{
									path: Routes.RIDER_PROFILE.path,
									exact: Routes.RIDER_PROFILE.exact,
									component: RiderProfile,
								},
								{
									path: Routes.DASHBOARD.path,
									exact: Routes.DASHBOARD.exact,
									component: Dashboard,
								},
								{
									path: Routes.ZONES.path,
									exact: Routes.ZONES.exact,
									component: ZonesDashboard,
								},
								{
									path: Routes.INTEGRATION_MANAGEMENT.path,
									exact: Routes.INTEGRATION_MANAGEMENT.exact,
									component: IntegrationManagement,
								},
								{
									path: Routes.GENERATE_TOKEN.path,
									exact: Routes.GENERATE_TOKEN.exact,
									component: GenerateToken,
								},
								{
									path: Routes.EDIT_TOKEN.path,
									exact: Routes.EDIT_TOKEN.exact,
									component: EditToken,
								},
								{
									path: Routes.ADVANCED_ORDER_ANALYTICS.path,
									exact: Routes.ADVANCED_ORDER_ANALYTICS.exact,
									component: AdvancedOrderAnalytics,
								},
								{
									path: Routes.ADVANCED_RIDER_ANALYTICS.path,
									exact: Routes.ADVANCED_RIDER_ANALYTICS.exact,
									component: AdvancedRiderAnalytics,
								},
								{
									path: Routes.REPEAT_ORDERS.path,
									exact: Routes.REPEAT_ORDERS.exact,
									component: RepeatOrders,
								},
								{
									path: Routes.EDIT_RIDER.path,
									exact: Routes.EDIT_RIDER.exact,
									component: EditRider,
								},
								{
									path: Routes.NETWORKS.path,
									exact: Routes.NETWORKS.exact,
									component: NetworkListing,
								},
								{
									path: Routes.FLEET_MANAGEMENT.path,
									exact: Routes.FLEET_MANAGEMENT.exact,
									component: FleetManagement,
								},
								{
									path: Routes.TRACE_LISTING.path,
									exact: Routes.TRACE_LISTING.exact,
									component: TraceListing,
								},
								{
									path: Routes.CHILD_BUSINESS.path,
									exact: Routes.CHILD_BUSINESS.exact,
									component: ChildBusinessList,
								},
								{
									path: Routes.SHOPIFY_AUTH.path,
									exact: Routes.SHOPIFY_AUTH.exact,
									component: ShopifyAuth,
								},
								{
									path: Routes.SMART_ALLOCATION.path,
									exact: Routes.SMART_ALLOCATION.exact,
									component: SmartAllocationSettings,
								},
								{
									path: Routes.SMART_MANIFEST.path,
									exact: Routes.SMART_MANIFEST.exact,
									component: AutoManifestSettings,
								},
							],
							(route, idx) => {
								return <Route key={idx} path={route.path} exact={route.exact} component={route.component} />;
							},
						)}
					<NetworkContextProvider>
						<Route exact={Routes.CREATE_NETWORK.exact} path={Routes.CREATE_NETWORK.path} component={CreateNetwork} />
						<Route exact={Routes.INVITE_NETWORK.exact} path={Routes.INVITE_NETWORK.path} component={InviteParticipant} />
					</NetworkContextProvider>
					<Redirect to='/' />
				</Switch>
				<div
					style={{
						zIndex: 10000,
						height: '100%',
						position: 'absolute',
						top: '0%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}>
					{_.map(alert, (item, key) => (
						<LiveAlerts item={item} key={key} handle_close={handle_alert_close} open_notif_modal={open_notif_modal} />
					))}
				</div>
			</div>
			{!_.isEmpty(rider_data) && <RiderOverviewModal rider_data={rider_data} set_rider_data={set_rider_data} close={() => set_rider_data(null)} />}
		</div>
	);
};

export default App;
