export const ALL_MENU_ITEMS = {
	Overview: {
		id: 'overview',
		text: 'Total Orders',
	},
	Pending: {
		id: 'pending',
		text: 'Pending',
	},
	ReadyToShip: {
		id: 'ready_to_ship',
		text: 'AWB Manifested',
	},
	Shipped: {
		id: 'shipped',
		text: 'Shipped',
	},
	Completed: {
		id: 'completed',
		text: 'Completed',
	},
};