import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	map_wrap: { marginRight: 30, position: 'relative', flex: 1, borderRadius: 20, overflow: 'hidden', width: '100%', height: '100%' },
	trip_wrap: {
		position: 'absolute',
		top: 60,
		flex: 1,
		zIndex: 2,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '50%',
		marginLeft: '25%',
		backgroundColor: theme.colors.white,
		padding: 12,
	},
	stats_label: { color: theme.colors.lightGrey6, fontSize: 12 },
	key_item_text: {
		color: theme.colors.primary,
		fontSize: 12,
	},
	key_item_wrap: {
		height: 20,
		width: 20,
		backgroundColor: theme.colors.lightPurple,
		borderRadius: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	trip_row: {
		width: '100%',
		padding: '5px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'&:hover': {
			'& > .cross': {
				visibility: 'visible !important',
			},
		},
	},
	bid_text: { fontSize: 12, marginTop: 8 },
	stats_wrap: {
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.colors.lightPurple,
		padding: 12,
	},
	edit_wrap: { padding: 16, width: 200 },
	edit_input: { display: 'flex', alignItems: 'center' },
	stats_box: {
		backgroundColor: theme.colors.white,
		display: 'flex',
		width: '70%',
		top: 20,
		left: '15%',
		zIndex: '3',
		position: 'absolute',
		flex: 1,
		borderRadius: 5,
		overflow: 'hidden',
	},
	table_head: {
		position: 'absolute',
		top: 5,
		zIndex: 2,
		background: 'rgba(255, 255, 255, 0.8)',
		borderRadius: 40,
		width: '50%',
		marginLeft: '25%',
		padding: '10px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
	},
	pbid_text_highlighted: { flex: 2, color: theme.colors.primary, textDecoration: 'underline', cursor: 'pointer' },
}));
export default useStyles;
