import { Menu, MenuItem, makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { OPTIONS_MENU, REPORT_MENU } from './helpers';
import _ from 'lodash';
import ConfirmClose from 'modules/modals/ConfirmClose';
import api_requests from 'resources/api_requests';
import SavedAddresses from 'modules/modals/SavedAddresses';
import constant from 'resources/constant';
import AddressInput from 'modules/modals/AddressInput';
import utils from 'resources/utils';
import RiderChangeModal from 'modules/RiderChangeModal';
import BootstrapTooltip from 'modules/BootstrapTooltip';

const useStyles = makeStyles((theme) => ({
	container_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.colors.lightPink4,
		width: 350,
		height: 32,
		border: `1px solid ${theme.colors.lightPurple}`,
		boxShadow: '0px 0px 16px rgba(102, 102, 102, 0.1)',
		borderRadius: '30px 0px 0px 30px',
		marginTop: 4,
		padding: '7px 12px',
	},
	actions_wrapper: {
		display: 'flex',
		gap: 15,
	},
	action_items_wrapper: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		'& .hover-item': {
			marginLeft: 5,
			display: 'none',
		},
		'&:hover': {
			'& .hover-item': {
				display: 'block',
			},
		},
	},
	selected_text: { fontSize: 14, borderRight: `2px solid${theme.colors.lightPurple6}`, padding: '0 8px' },
	menu_item: {
		width: 130,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
			color: theme.colors.primary,
		},
	},
	change_rider: {
		display: 'flex',
		gap: 4,
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	change_rider_disabled: {
		display: 'flex',
		gap: 4,
		// pointerEvents: 'none',
		cursor: 'auto',
		textDecoration: 'none',
		color: theme.colors.darkGrey2,
	},
}));

const ADDRESS_TYPE = {
	saved: 'saved',
	new: 'new',
	recent: 'recent',
};

function BulksActions({
	selected_order_ids = [],
	option = [],
	set_selected_order_ids,
	report_menu = [],
	handle_product_list_download,
	handle_manifest_download,
	on_update,
	external_order_id_list = [],
	set_show_toast,
	set_toast_msg,
}) {
	const [action_type, set_action_type] = useState(null);
	const [open_new_address_modal, set_open_new_address_modal] = useState(false);
	const [pickup_address, set_pickup_address] = useState(null);
	const [show_confirm_address_update, set_show_confirm_address_update] = useState(false);
	const [is_async_done, set_is_async_done] = useState(false);
	const [count, set_count] = useState(0);
	const [error_count, set_error_count] = useState(0);
	const [show_rider_change_modal, set_show_rider_change_modal] = useState(false);
	const [tooltip_text, set_tooltip_text] = useState('');
	const anchorEl = useRef(null);
	const classes = useStyles();
	const theme = useTheme();

	const close_bulk_order = () => {
		set_action_type(null);
		set_count(0);
		set_is_async_done(true);
	};

	const handle_cancel_order = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.BULK_CANCEL, selected_order_ids);
		try {
			const res = await api_requests.bulk_cancel_order({ items: selected_order_ids });

			if (res) {
				on_update();
			}
		} catch (err) {}
	};

	const handle_unallocate_orders = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.BULK_UNALLOCATE, selected_order_ids);
		try {
			const res = await api_requests.cancel_fulfilment_order(external_order_id_list[count - 1], false);
			if (count < external_order_id_list.length) {
				set_count(count + 1);
			} else {
				set_count(0);
				set_is_async_done(true);
			}
		} catch (err) {
			set_error_count(error_count + 1);
			if (count < external_order_id_list.length) {
				set_count(count + 1);
			} else {
				set_count(0);
				set_is_async_done(true);
			}
		}
	};

	const handle_unroute_order = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.BULK_UNROUTE, selected_order_ids);
		try {
			const res = await api_requests.ungroup_trip({ mcp_ids: selected_order_ids });
			on_update();
			set_selected_order_ids([]);
		} catch (err) {}
	};

	const on_address_select = (address, address_type) => {
		let _address = null;
		if (address_type === 'new') _address = { ...address, address_line_1: address.address_line1, address_line1: undefined, pincode: address.pincode + '' };
		if (address_type === 'saved') {
			_address = {
				...address,
				address_line_1: address.address_line1 ? address.address_line1 : address.address_line_1,
				address_line1: undefined,
				pincode: address.pincode + '',
				country: address.google_maps_address.split(',').at(-1) ? address.google_maps_address.split(',').at(-1).trim() : '',
			};
		}
		const sanititize_addr = utils.remove_null_and_empty_from_obj(_address);
		set_pickup_address(sanititize_addr);
		set_show_confirm_address_update(true);
	};

	const handle_update_pickup = async () => {
		try {
			const res = await api_requests.handle_update_bulk_address({
				ids: selected_order_ids,
				address: pickup_address,
			});
			if (res) {
				on_update();
			}
		} catch (err) {}
	};

	useEffect(() => {
		if (count > 0 && !is_async_done) {
			handle_unallocate_orders();
		}
	}, [count]);

	const handle_change_rider = async (new_rider) => {
		const trip_Ids = _.map(selected_order_ids, (trip) => trip.trip_id.toString());
		let data = utils.remove_null_and_empty_from_obj({
			oldRiderId: selected_order_ids[0]?.rider.id,
			newRiderId: new_rider?.rider_id,
			tripIds: trip_Ids,
		});
		try {
			const res = await api_requests.handover_trip_rider(data);
			on_update();
			set_show_toast(true);
			set_toast_msg(`${selected_order_ids.length} orders have been handed over to ${new_rider.rider_name}`);

			set_show_rider_change_modal(false);
			_.map(selected_order_ids, (val) => {
				const dot_element = document.getElementById(`trip_id=${val.trip_id}`);
				dot_element.style.display = 'block';
				setTimeout(() => {
					dot_element.style.display = 'none';
				}, 5000);
			});
			set_selected_order_ids([]);
		} catch (err) {}
	};

	const btn_disabled = useMemo(() => {
		if (selected_order_ids.length > 0 && _.includes(option, OPTIONS_MENU.chnges_rider)) {
			const first_rider = selected_order_ids[0].rider.id;
			for (let i = 0; i < selected_order_ids.length; i++) {
				if (selected_order_ids[i].rider.id != first_rider) {
					set_tooltip_text('Please select single rider only');
					return true;
				} else if (selected_order_ids[i].rider.owner_id != utils._retrieveData('owner_id')) {
					set_tooltip_text('Can only change for own rider');
					return true;
				}
			}
			return false;
		}
	}, [selected_order_ids]);

	return (
		<div className={classes.container_wrapper}>
			<Text medium className={classes.selected_text}>
				<Text semi component={'span'} style={{ fontSize: 14, color: theme.colors.primary }}>
					{selected_order_ids.length}
				</Text>{' '}
				Selected
			</Text>
			<div className={classes.actions_wrapper}>
				{_.includes(option, OPTIONS_MENU.update) && (
					<div
						className={classes.action_items_wrapper}
						onClick={() => {
							set_action_type(OPTIONS_MENU.update);
							set_open_new_address_modal(false);
						}}>
						<img height={14} width={12} src={ImageLinks.rider_pickup} />
						<div className='hover-item'>
							<Text semi style={{ color: theme.colors.lightGreen3 }}>
								Update Pickup
							</Text>
						</div>
					</div>
				)}
				{_.includes(option, OPTIONS_MENU.report) && (
					<div className={classes.action_items_wrapper} ref={anchorEl} onClick={() => set_action_type(OPTIONS_MENU.report)}>
						<img height={14} width={12} src={ImageLinks.download} />
						<div className='hover-item'>
							<Text semi style={{ color: theme.colors.primary }}>
								Get Report
							</Text>
						</div>
					</div>
				)}
				<Menu
					anchorEl={anchorEl.current}
					open={action_type === OPTIONS_MENU.report}
					onClose={() => set_action_type(null)}
					style={{ zIndex: 1501 }}
					PaperProps={{
						style: {
							borderRadius: 10,
							marginTop: 50,
							maxHeight: 400,
						},
					}}>
					{_.map(report_menu, (item) => {
						return (
							<MenuItem className={classes.menu_item}>
								<Text
									medium
									style={{ fontSize: 14 }}
									className={classes.menu_item}
									onClick={() => {
										switch (item) {
											case REPORT_MENU.product:
												handle_product_list_download();
												break;
											case REPORT_MENU.manifest:
												handle_manifest_download();
												break;
											default:
												break;
										}
									}}>
									{item === REPORT_MENU.product ? 'Product List' : item === REPORT_MENU.shipping ? 'Shipping Label' : 'Manifest'}
								</Text>
							</MenuItem>
						);
					})}
				</Menu>
				{_.includes(option, OPTIONS_MENU.unroute) && (
					<div className={classes.action_items_wrapper} onClick={() => set_action_type(OPTIONS_MENU.unroute)}>
						<img height={20} width={16} src={ImageLinks.unroute} />
						<div className='hover-item'>
							<Text semi style={{ color: theme.colors.lightPink3 }}>
								Unroute
							</Text>
						</div>
					</div>
				)}
				{_.includes(option, OPTIONS_MENU.cancel) && (
					<div className={classes.action_items_wrapper} onClick={() => set_action_type(OPTIONS_MENU.cancel)}>
						<img height={20} width={16} src={ImageLinks.delete} />
						<div className='hover-item'>
							<Text semi style={{ color: theme.colors.red }}>
								Cancel
							</Text>
						</div>
					</div>
				)}
				{_.includes(option, OPTIONS_MENU.unallocate) && (
					<div
						className={classes.action_items_wrapper}
						onClick={() => {
							set_action_type(OPTIONS_MENU.unallocate);
							set_is_async_done(false);
						}}>
						<img height={20} width={16} src={ImageLinks.delete} />
						<div className='hover-item'>
							<Text semi style={{ color: theme.colors.red }}>
								Unallocate
							</Text>
						</div>
					</div>
				)}
				{_.includes(option, OPTIONS_MENU.chnges_rider) && (
					<div
						className={btn_disabled ? classes.change_rider_disabled : classes.change_rider}
						onClick={() => !btn_disabled && set_show_rider_change_modal(true)}>
						<img src={btn_disabled ? ImageLinks.change_rider_icon_gray : ImageLinks.change_rider_icon_without_circle} width={16} height={16} />
						<BootstrapTooltip
							title={
								btn_disabled ? (
									<div style={{ padding: 4 }}>
										<Text semi style={{ color: theme.colors.darkGrey7, fontSize: 12 }}>
											{tooltip_text}
										</Text>
									</div>
								) : (
									''
								)
							}
							is_white={true}>
							<div>
								<Text semi style={{ fontSize: 12 }}>
									Change Rider
								</Text>
							</div>
						</BootstrapTooltip>
					</div>
				)}
				<div className={classes.action_items_wrapper} onClick={() => set_selected_order_ids([])}>
					<img height={14} width={12} src={ImageLinks.crossBlack} />
				</div>
			</div>
			{action_type === OPTIONS_MENU.cancel && (
				<ConfirmClose
					headerTitle='Bulk Cancel Orders'
					title={`Are you sure you want to delete ${selected_order_ids.length} orders? This action cannot be undone.`}
					close={() => set_action_type(null)}
					on_confirm={handle_cancel_order}
					is_error_msg={true}
					positive_btn_text='Cancel Orders'
					show_negative_btn={false}
				/>
			)}
			{action_type === OPTIONS_MENU.unallocate && !is_async_done && (
				<ConfirmClose
					close_on_confirm={false}
					headerTitle='Bulk Unallocate Orders'
					title={
						<>
							<Text
								semi>{`Are you sure you want to unallocate ${external_order_id_list.length} orders? Order will move to Pending in All Orders.`}</Text>
							<Text>Please note: Network orders cannot be unallocated</Text>
						</>
					}
					close={close_bulk_order}
					on_confirm={() => set_count(count + 1)}
					count={count}
					async_length={external_order_id_list.length}
					is_async_done={is_async_done}
					is_error_msg={true}
					positive_btn_text='Unallocate'
				/>
			)}
			{action_type === OPTIONS_MENU.unallocate && is_async_done && (
				<ConfirmClose
					close_on_confirm={false}
					headerTitle={
						error_count == 0
							? 'Orders Unallocated Successfully'
							: error_count == external_order_id_list.length
							? 'Orders Unallocation Failed'
							: 'Orders Unallocated Partially'
					}
					title={
						error_count == 0
							? `${external_order_id_list.length} orders have been successfully unallocated. Orders have moved to Pending in All Orders.`
							: error_count == external_order_id_list.length
							? `Unable to allocate ${error_count} orders. Please try again.`
							: `${external_order_id_list.length - error_count} of ${
									external_order_id_list.length
							  } orders have been successfully unallocated.Orders have moved to Pending in All Orders.`
					}
					close={() => {
						set_action_type(null);
						set_selected_order_ids([]);
						on_update();
					}}
					on_confirm={() => set_count(count + 1)}
					async_length={external_order_id_list.length}
					is_async_done={is_async_done}
					modal_icon={
						error_count == 0
							? ImageLinks.green_tick_ripple
							: error_count == external_order_id_list.length
							? ImageLinks.excalamation_red_ripple
							: ImageLinks.excalamation_yellow_ripple
					}
					show_negative_btn={false}
					positive_btn_text={error_count == 0 ? 'Ok' : error_count == external_order_id_list.length ? 'Try Again' : `Retry ${error_count} orders`}
				/>
			)}
			{action_type === OPTIONS_MENU.unroute && (
				<ConfirmClose
					modal_icon={ImageLinks.unroute_ripple}
					headerTitle='Bulk Unroute Orders'
					title={`Are you sure you want to unroute ${selected_order_ids.length} orders? New routes will have to be created for these orders.`}
					close={() => set_action_type(null)}
					on_confirm={handle_unroute_order}
					is_error_msg={false}
					positive_btn_text='Unroute'
				/>
			)}
			{action_type === OPTIONS_MENU.update && (
				<SavedAddresses
					title='Change Pickup Address'
					on_address_select={on_address_select}
					close={() => set_action_type(null)}
					address_type={ADDRESS_TYPE.saved}
					clear_local_data={() => {}}
					zone_id={6}
					handle_zone_id={() => {}}
					on_new_addr_tab_press={() => {
						set_open_new_address_modal(true);
					}}
				/>
			)}
			{action_type === OPTIONS_MENU.update && open_new_address_modal && (
				<AddressInput
					zone_id={constant.DEFAULT_ZONE_ID}
					on_address_select={on_address_select}
					close={() => set_action_type(null)}
					address_type={'new'}
					title={'Pickup'}
				/>
			)}
			{show_confirm_address_update && (
				<ConfirmClose
					headerTitle='Bulk Update Pickup Address'
					title={`Are you sure you want to update pickup address for ${selected_order_ids.length} orders?`}
					close={() => set_show_confirm_address_update(null)}
					on_confirm={handle_update_pickup}
					positive_btn_text='Update'
				/>
			)}
			{show_rider_change_modal && (
				<RiderChangeModal
					close={() => set_show_rider_change_modal(false)}
					on_confirm={handle_change_rider}
					details={{ rider_id: selected_order_ids[0].rider.id, rider_name: selected_order_ids[0].rider.name, order_count: selected_order_ids.length }}
				/>
			)}
		</div>
	);
}

export default BulksActions;
