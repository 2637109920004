import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import _ from 'lodash';
import Text from 'modules/Text';
import Upload from 'modules/Upload';
import React, { useState } from 'react';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	tags_wrap: {
		margin: '7px 4px',
		marginRight: 2,
		whiteSpace: 'nowrap',
		height: 24,
		borderRadius: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '2px 8px',
	},
	add_pincode_wrap: { marginTop: 20, display: 'flex', flexDirection: 'column' },
	search_pincode_wrap: { display: 'flex', marginTop: 8, alignItems: 'center', justifyContent: 'space-between', position: 'relative' },

	upload_button: {
		height: 36,
		width: 36,
		borderRadius: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 8,
		cursor: 'pointer',
		'&:hover': {
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},

	input_container: {
		display: 'flex',
		overflow: 'scroll',
		width: '100%',
		maxWidth: '100%',
		border: `1px solid ${theme.colors.lightPurple6}`,
		fontSize: 14,
		color: theme.colors.black,
		height: 40,
		borderRadius: 46,
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},

	contain_inpit: {
		width: '100%',
		minWidth: '20%',
		borderRadius: 46,
		padding: 14,
		paddingLeft: 5,
		border: 'none',
		outline: 'none',
	},
}));

const PincodesInput = ({ handle_pincode_submit, handle_csv, disabled }) => {
	const [pincode_search, set_pincode_search] = useState('');
	const [added_pincodes, set_added_pincodes] = useState([]);
	const classes = useStyles();

	const handleDelete = (value) => {
		const _pincodes = _.cloneDeep(added_pincodes);
		const filtered_pin = _pincodes.filter((val) => val.pin !== value.pin);
		set_added_pincodes(filtered_pin);
	};

	const on_change_pincode = (e) => {
		const value = e.target.value;
		if (_.includes(value, ',')) {
			const comma_trimmed = value.slice(0, -1).trim();
			if (_.isEmpty(comma_trimmed)) return;
			let regex = new RegExp(/^\d{6}$/);
			const obj = {
				pin: comma_trimmed,
				is_valid: regex.test(comma_trimmed) && comma_trimmed != '000000',
			};
			const _added_pincodes = _.cloneDeep(added_pincodes);
			_added_pincodes.push(obj);
			set_added_pincodes(_added_pincodes);
			set_pincode_search('');
		} else {
			set_pincode_search(value);
		}
	};

	const on_submit_typed_pincodes = () => {
		set_pincode_search('');
		const _valid = _.filter(added_pincodes, (item) => item.is_valid);
		const only_pincode = _.map(_valid, (item) => item.pin);
		set_added_pincodes([]);
		handle_pincode_submit([...new Set(only_pincode)]);
	};

	const valid_pincode_count = _.filter(added_pincodes, (item) => item.is_valid).length;

	return (
		<div>
			<div className={classes.add_pincode_wrap}>
				<Text semi style={{ fontSize: 14 }}>
					Add Pincodes
				</Text>
				<div className={classes.search_pincode_wrap}>
					<div className={classes.input_container}>
						{added_pincodes.map((data, index) => (
							<div
								key={`input_tags_${index}`}
								className={classes.tags_wrap}
								style={{ backgroundColor: data.is_valid ? theme.colors.lightPink4 : theme.colors.lightRed }}>
								<Text medium style={{ fontSize: 14, marginRight: 4, color: data.is_valid ? theme.colors.black : theme.colors.red }}>
									{data.pin}
								</Text>
								<img
									src={ImageLinks.crossBlack}
									width={10}
									height={10}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										handleDelete(data);
									}}
								/>
							</div>
						))}

						<input
							value={pincode_search}
							disabled={disabled}
							onChange={on_change_pincode}
							className={clsx(classes.contain_inpit, 'purple_placeholder')}
							placeholder={added_pincodes.length === 0 ? 'Enter comma separated pincodes' : ''}
							style={{ paddingLeft: added_pincodes.length === 0 ? 10 : 1 }}
							onKeyUp={(e) => {
								if (e.key == 'Enter') {
									on_submit_typed_pincodes();
								}
							}}
						/>
					</div>
					<div style={{ position: 'absolute', top: 10, right: 50 }}>
						{added_pincodes.length === 0 ? (
							<img src={ImageLinks.search_light_purple} alt='search' width={20} height={20} />
						) : (
							<img
								src={ImageLinks.arrow_right_light_purple}
								width={20}
								height={20}
								alt='arrow-right'
								className={added_pincodes.length === 0 ? 'cursor-unset' : 'cursor-pointer'}
								disabled={added_pincodes.length === 0}
								onClick={on_submit_typed_pincodes}
							/>
						)}
					</div>
					<Upload accepted_extensions='.csv' add_image={handle_csv}>
						<div className={classes.upload_button}>
							<img src={ImageLinks.upload} className='purple-arrow' height={20} width={20} style={{ width: '100%', height: 'auto' }}></img>
							<img
								src={ImageLinks.upload_white}
								className='white-arrow'
								height={20}
								width={20}
								style={{ width: '100%', height: 'auto', display: 'none' }}></img>
						</div>
					</Upload>
				</div>
			</div>
			{valid_pincode_count > 0 && (
				<Text medium style={{ fontSize: 12, marginTop: 12 }}>
					{valid_pincode_count} pincodes
				</Text>
			)}
		</div>
	);
};

export default PincodesInput;
