import { makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import ImageLinks from 'assets/images/ImageLinks';
import EmptyState from './EmptyState';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const useStyles = makeStyles((theme) => ({
	main_container: {
		backgroundColor: theme.colors.white,
		padding: 12,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		borderRadius: 12,
		position: 'relative',
	},
	divider: { height: 1, width: '100%', backgroundColor: theme.colors.lightGrey5 },
	flex_box: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginTop: 5 },
	heading_text: { color: theme.colors.darkGrey4, fontSize: 14 },
	list_box: {
		height: '12vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '2px',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple4,
			borderRadius: 5,
		},
	},
}));

function ChannelMix({ channel_data }) {
	const classes = useStyles();
	const theme = useTheme();
	const colors = [
		theme.colors.lightPurple6,
		theme.colors.lightPurple4,
		theme.colors.lightYellow,
		theme.colors.lightGreen4,
		theme.colors.lightPurple7,
		theme.colors.lightOrange,
		theme.colors.cardBackgroundColor4,
		theme.colors.cardBackgroundColor5,
		theme.colors.lightGreen,
		theme.colors.lightRed,
	];
	const data = {
		labels: _.map(channel_data, (channel) => {
			return channel._id;
		}),
		datasets: [
			{
				data: _.map(channel_data, (channel) => {
					return channel.count;
				}),
				backgroundColor: colors,
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: true,
			},
			datalabels: {
				display: false,
			},
		},
		maintainAspectRatio: true,
	};

	return (
		<div className={classes.main_container}>
			<Text style={{ marginBottom: 12 }} bold className={classes.heading_text}>
				Channel Mix
			</Text>
			<div className={classes.divider}></div>
			<div style={{ width: '70%', margin: 'auto', marginTop: 8 }}>
				<Pie data={data} options={options} />
			</div>
			<div className={classes.list_box}>
				{_.map(channel_data, (channel, index) => {
					return (
						<div className={classes.flex_box} style={{ paddingRight: 10 }} key={`channel_item_${index}`}>
							<div className={classes.flex_box}>
								<div style={{ height: 8, width: 8, backgroundColor: colors[index], borderRadius: 4 }}></div>
								<div>
									{channel._id === 'pidge' ? (
										<img src={ImageLinks.logo} style={{ width: 'auto', maxHeight: 16 }} alt='pidge' />
									) : channel._id === 'dunzo' ? (
										<img src={ImageLinks.dunzo_logo} style={{ width: 'auto', maxHeight: 12 }} alt='dunzo' />
									) : channel._id === 'wefast' ? (
										<img src={ImageLinks.wefast_logo} style={{ width: 'auto', maxHeight: 12 }} alt='wefast' />
									) : channel._id === 'shadowfax' ? (
										<img src={ImageLinks.shadowfax_logo} style={{ width: 'auto', maxHeight: 12 }} alt='shadowfax' />
									) : channel._id === 'loadshare' ? (
										<img src={ImageLinks.loadshare_logo} style={{ width: 'auto', maxHeight: 12 }} alt='loadshare' />
									) : channel._id === 'zomato' ? (
										<img src={ImageLinks.zomato_logo} style={{ width: 'auto', maxHeight: 12 }} alt='zomato' />
									) : channel._id === 'porter' ? (
										<img src={ImageLinks.porter_logo} style={{ width: 'auto', maxHeight: 12 }} alt='porter' />
									) : channel._id ? (
										channel._id
									) : (
										'-'
									)}
								</div>
							</div>
							<div>{channel.count}</div>
						</div>
					);
				})}
			</div>
			{channel_data.length === 0 && <EmptyState />}
		</div>
	);
}

export default ChannelMix;
