import React, { useState, useMemo } from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import OutlinedTextField from 'modules/OutlinedTextField';
import Text from 'modules/Text';
import theme from 'resources/theme';
import { valid_email_regex } from 'resources/regex_patterns';

const useStyles = makeStyles((theme) => ({
	close_container: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: theme.colors.lightGrey14,
		zIndex: 1199,
		width: '100%',
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 580,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 30,
		zIndex: 1200,
		overflowY: 'auto',
		paddingBottom: 120,
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
		overflowX: 'hidden',
	},
	header_text: {
		color: theme.colors.darkGrey7,
		fontSize: 12,
		marginTop: 12,
	},
	inputs: {
		width: '48%',
		'& .MuiInputLabel-outlined': {},
		'& .MuiInputBase-root': {
			padding: 5,
			'& .MuiInputBase-input': {
				height: 40,
				padding: 0,
				paddingLeft: 10,
			},
		},
	},
	business_detail_wrap: {
		flexWrap: 'wrap',
		display: 'flex',
		justifyContent: 'space-between',
		gap: 10,
		padding: 16,
		borderRadius: 6,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
	},
	create_button_wrap: {
		position: 'absolute',
		width: 580,
		backgroundColor: theme.colors.white,
		bottom: 0,
		zIndex: 1201,
		height: 80,
		display: 'flex',
		padding: 24,
		right: 0,
		justifyContent: 'space-between',
	},
}));

function AddBusinessModal({ close, create_business }) {
	const [brand_name, set_brand_name] = useState('');
	const [business_contact_number, setbusiness_contact_number] = useState('');
	const [business_email, set_business_email] = useState('');
	const [brand_error, set_brand_error] = useState(false);
	const [email_error, set_email_error] = useState(false);

	const classes = useStyles();

	const on_email_blur = (email) => {
		if (_.isEmpty(email.trim())) {
			set_email_error(false);
			return;
		}
		const is_matched = email.match(valid_email_regex);
		if (is_matched) {
			set_email_error(false);
		} else {
			set_email_error(true);
		}
	};

	const handle_brand_name = (e) => {
		set_brand_name(e.target.value);
		if (e.target.value == '') {
			set_brand_error(true);
		} else {
			set_brand_error(false);
		}
	};

	const handle_business_number = (e) => {
		const value = e.target.value;
		if (value.length <= 10) {
			setbusiness_contact_number(e.target.value);
		}
	};

	const handle_business_email = (e) => {
		set_business_email(e.target.value);
		on_email_blur(e.target.value);
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		if (!_.isEmpty(brand_name) && !_.isEmpty(business_email) && business_contact_number.length == 10) {
			value = false;
		}
		return value;
	}, [brand_name, business_contact_number, business_email]);

	return (
		<>
			<div className={classes.close_container} />
			<div className={classes.container}>
				<div className='d-flex align-items-center justify-content-between'>
					<div className='d-flex align-items-center'>
						<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
							Create Business
						</Text>
					</div>
					<img src={ImageLinks.crossBlack} style={{ cursor: 'pointer' }} onClick={close} />
				</div>
				<Divider style={{ margin: '10px 0' }} />
				<img src={ImageLinks.create_business} width='100%' />
				<div className='d-flex align-items-between'>
					<Text bold className={classes.header_text}>
						Business Identity
					</Text>
				</div>
				<div className={clsx('d-flex justify-content-between', classes.business_detail_wrap)}>
					<OutlinedTextField
						value={brand_name}
						onChange={handle_brand_name}
						label='Brand Name*'
						className={classes.inputs}
						error={brand_error}
						helperText={brand_error ? 'Please enter Brand Name' : ''}
					/>
					<OutlinedTextField
						value={business_contact_number}
						onChange={handle_business_number}
						label='Business Contact Number *'
						type='number'
						className={classes.inputs}
					/>
					<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2, width: '100%' }}>
						Additional/Other
					</Text>
					<OutlinedTextField
						label='Business Email Id *'
						className={classes.inputs}
						value={business_email}
						onChange={handle_business_email}
						error={email_error}
						helperText={email_error ? 'Please enter a valid email' : ''}
					/>
				</div>
				<div className='d-flex'>
					<div className={classes.create_button_wrap}>
						<div className='d-flex align-items-center' style={{ gap: '0.75rem' }}>
							<img src={ImageLinks.info_filled_yellow} width={20} height={20} />
							<Text medium style={{ width: '295px' }}>
								Invite users to join after creating new business dashboard
							</Text>
						</div>
						<ContainedButton
							style={{ borderRadius: 30 }}
							disabled={btn_disabled}
							onClick={() => create_business({ brand_name, email_id: business_email, mobile: business_contact_number })}>
							<Text bold>Create Business</Text>
						</ContainedButton>{' '}
					</div>
				</div>
			</div>
		</>
	);
}

export default AddBusinessModal;
