import { Dialog } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import React from 'react';
import Text from './Text';

const PremiumFeatureModal = ({ close }) => {
	return (
		<Dialog open={true} PaperProps={{ style: { height: 250, width: 600, borderRadius: 16, display: 'flex' } }}>
			<div style={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>
				<img src={ImageLinks.preimum_feature_background} style={{ height: '100%' }} />
				<div style={{ flex: 1, height: '100%', marginLeft: -50 }}>
					<div style={{ width: 390, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
						<Text bold style={{ fontSize: 24 }}>
							You discovered a premium feature!
						</Text>
						<Text style={{ marginTop: 15 }}>
							Upgrade to Pidge LaMS to unlock Custom Routing and Allocation features. Contact Merchant Support to know more.
						</Text>
						<Text style={{ marginTop: 40 }}>Contact merchant support to know more</Text>
					</div>
				</div>
				<img src={ImageLinks.crossBlack} style={{ position: 'absolute', right: 20, top: 20, cursor: 'pointer' }} onClick={close} />
			</div>
		</Dialog>
	);
};

export default PremiumFeatureModal;
