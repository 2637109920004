import React, { useContext, useEffect, useRef, useState } from 'react';
import theme from 'resources/theme';
import Papa from 'papaparse';

import OutlinedButton from 'modules/OutlinedButton';
import Upload from 'modules/Upload';
import _ from 'lodash';
import Text from 'modules/Text';
import { ListItem, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import CreateOrderContext from 'views/CreateOrderFlow/CreateOrder/context';
import { v4 as uuidv4 } from 'uuid';
import resource from './sample-csv.csv';
import moment from 'moment';
import ContainedButton from 'modules/ContainedButton';
import constant from 'resources/constant';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	csv_wrap: {
		marginTop: 48,
	},
	disabled_upload_text: {
		fontSize: 12,
		marginTop: 30,
		marginBottom: 12,
		color: theme.colors.darkGrey2,
	},

	upload_csv_content_container: {
		width: '100%',
		minHeight: 250,
		borderRadius: 10,
		border: `1px dashed ${theme.colors.lightGrey2}`,
		marginTop: 20,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	upload_csv_file_information: {
		padding: '20px 16px',
		borderRadius: 4,
		backgroundColor: theme.colors.lightGrey8,
		marginTop: 24,
		width: '100%',
	},
	upload_pending_file_information: {
		padding: '20px 16px',
		borderRadius: 4,
		marginTop: 24,
		width: '100%',
	},
	cross_button: {
		background: theme.colors.lightRed,
		padding: '2px 8px',
		borderRadius: '50%',
		position: 'absolute',
		top: '-8px',
		right: '-8px',
		cursor: 'pointer',
	},
}));

const UploadCSV = ({ active, uploaded_csv_file, set_uploaded_csv_file, orders_count, set_orders_count, upload_list_item, set_upload_list_item }) => {
	const classes = useStyles();
	const [upload_csv, set_upload_csv] = useState(constant.BTN_STATE.DEFAULT);
	const [progress, set_progress] = useState(0);
	const [confirm_delete_modal, set_confirm_delete_modal] = useState(false);
	const [error_msg, set_error_msg] = useState('');

	const { bop_packages, handle_step } = useContext(CreateOrderContext);

	const formatBytes = (bytes, decimals = 2) => {
		if (!+bytes) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'Gb', 'Tb', 'PiB', 'EiB', 'ZiB', 'YiB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	};

	const handle_csv = (fileInfo, inputRef) => {
		set_uploaded_csv_file(fileInfo);
		set_upload_csv(constant.BTN_STATE.LOADING);
		set_progress(0);
		setTimeout(() => {
			set_progress(20);
		}, 200);
		setTimeout(() => {
			set_progress(40);
		}, 400);
		setTimeout(() => {
			set_progress(60);
		}, 600);
		setTimeout(() => {
			set_progress(80);
		}, 800);
		setTimeout(() => {
			set_progress(100);
		}, 1000);

		Papa.parse(fileInfo.file, {
			header: false,
			skipEmptyLines: true,
			complete: (results) => {
				inputRef.current = null;
				const current_date = new Date();
				const data = results.data;

				const items = [];

				let invalid_message = '';

				_.map(data, (order_detail, key) => {
					if (key === 0 || !_.isEmpty(invalid_message)) {
						return;
					}

					const packages = [];

					_.map(
						[
							{
								size: 'XSMALL',
								quantity: order_detail[10],
							},
							{
								size: 'SMALL',
								quantity: order_detail[11],
							},
							{
								size: 'MEDIUM',
								quantity: order_detail[12],
							},
							{
								size: 'LARGE',
								quantity: order_detail[13],
							},
							{
								size: 'XLARGE',
								quantity: order_detail[14],
							},
						],
						(package_details) => {
							if (!_.isEmpty(package_details.quantity) && package_details.quantity > 0) {
								const _p = _.find(bop_packages, { package_size: package_details.size });

								packages.push({
									sku: _p.package_size,
									quantity: Number(package_details.quantity),
									volumetric_weight: _p.volume,
								});
							}
						},
					);

					const customer_name = order_detail[3];
					const customer_mobile = order_detail[4];
					const address_line_1 = order_detail[5];
					const city = order_detail[6];
					const state = order_detail[7];
					const pincode = order_detail[8];
					const delivery_date = order_detail[15];
					const delivery_slot = order_detail[16];

					if (_.isEmpty(packages)) {
						invalid_message = `Packages cannot be empty at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(customer_name)) {
						invalid_message = `Customer name is mandatory at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(customer_mobile)) {
						invalid_message = `Customer mobile is mandatory at row ${key + 1}`;
						return;
					}

					if (customer_mobile.length !== 10) {
						invalid_message = `Customer mobile is not correct at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(address_line_1)) {
						invalid_message = `Address is mandatory at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(city)) {
						invalid_message = `City is mandatory at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(state)) {
						invalid_message = `State is mandatory at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(pincode)) {
						invalid_message = `Pincode is mandatory at row ${key + 1}`;
						return;
					}

					if (!_.isEmpty(delivery_date) && !moment(delivery_date, 'YYYY-MM-DD', true).isValid()) {
						invalid_message = `Delivery date format is wrong at row ${key + 1}`;
						return;
					}

					if (!_.isEmpty(delivery_slot)) {
						let regex = new RegExp(/^(([01]\d|2[0-3]):([0-5]\d))-(([01]\d|2[0-3]):([0-5]\d))$/);
						if (!regex.test(delivery_slot)) {
							invalid_message = `Delivery slot format is wrong at row ${key + 1}`;
							return;
						}
					}

					const coordinates = order_detail[9].split(',');

					items.push({
						source_order_id: uuidv4(),
						created_at: current_date,
						reference_id: order_detail[0],
						cod_amount: Number(order_detail[1]),
						bill_amount: Number(order_detail[2]),
						customer_detail: {
							name: customer_name,
							mobile: customer_mobile,
							address: {
								address_line_1,
								city,
								state,
								country: 'India',
								pincode,
								...(coordinates[0] && {
									latitude: (coordinates[0] || '').trim(),
									longitude: (coordinates[1] || '').trim(),
								}),
							},
						},
						...(order_detail[15] && { delivery_date: order_detail[15] }),
						...(order_detail[16] && { delivery_slot: order_detail[16] }),
						packages,
					});
				});

				if (!_.isEmpty(invalid_message)) {
					set_error_msg(invalid_message);
					set_upload_csv(constant.BTN_STATE.FAILED);
					set_uploaded_csv_file({});
					set_orders_count(0);
					set_upload_list_item([]);
					return;
				}

				if (_.isEmpty(items)) {
					invalid_message = 'Orders cannot be empty';
					set_error_msg(invalid_message);
					set_upload_csv(constant.BTN_STATE.FAILED);
					set_uploaded_csv_file({});
					set_orders_count(0);
					set_upload_list_item([]);
					return;
				}
				set_error_msg('');
				set_upload_csv(constant.BTN_STATE.SUCCESS);
				set_orders_count(items.length);
				set_upload_list_item(items);
			},
		});
	};

	const download_file = () => {
		const link = document.createElement('a');
		link.href = resource;
		let fileName = `sample-csv.csv`;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
	};

	const handle_order = () => {
		set_upload_csv(constant.BTN_STATE.DEFAULT);
		set_uploaded_csv_file({});
		set_orders_count(0);
		handle_step(3);
	};
	return (
		<>
			<div className={classes.csv_wrap}>
				<div>
					{!active ? (
						<ListItem className={classes.upload_csv_content_container}>
							<img src={ImageLinks.upload_light_grey} width={52} height={52} alt='upload-image' />
							<Text className={classes.disabled_upload_text}>Upload CSV file from computer</Text>
							<ContainedButton
								disabled
								style={{ borderRadius: 30, color: theme.colors.darkGrey2, background: theme.colors.lightGrey5, boxShadow: 'unset' }}>
								<Text bold style={{ fontSize: 14 }}>
									Select File
								</Text>
							</ContainedButton>
						</ListItem>
					) : (
						<>
							{upload_csv === 'success' && progress >= 100 ? (
								<div className={clsx(classes.upload_csv_content_container, 'text-align-center')} style={{ padding: 32 }}>
									<img src={ImageLinks.tickCircleSolid} width={52} height={52} alt='green-tick' />
									<Text medium style={{ marginTop: 24, fontSize: 14 }}>
										CSV uploaded successfully!
									</Text>
									<div className={classes.upload_csv_file_information} style={{ position: 'relative' }}>
										<div className={classes.cross_button} onClick={() => set_confirm_delete_modal(true)}>
											<img src={ImageLinks.crossRed} alt='cross' width={8} height={8} />
										</div>
										<div className='justify-content-between align-items-center'>
											<div className='d-flex align-items-center'>
												<img src={ImageLinks.folder_light_purple} width={24} height={24} />
												<Text component={'div'} style={{ fontSize: 12, paddingLeft: 20, paddingRight: 8 }}>
													{_.get(uploaded_csv_file, 'name', '')}
												</Text>
												<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
													{orders_count} Orders
												</Text>
											</div>
											<Text bold component={'div'} style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
												{formatBytes(_.get(uploaded_csv_file, 'file.size', ''))}
											</Text>
										</div>
									</div>
								</div>
							) : upload_csv === 'failed' && progress >= 100 ? (
								<Upload accepted_extensions='.csv' add_image={handle_csv}>
									<div className={clsx(classes.upload_csv_content_container, 'text-align-center')} style={{ padding: 32 }}>
										<img
											src={ImageLinks.information_reverse}
											width={52}
											height={52}
											style={{ background: theme.colors.red, borderRadius: '50%' }}
										/>
										<Text medium style={{ marginTop: 24, fontSize: 14 }}>
											{`Unable to upload CSV. ${_.isEmpty(error_msg) ? '' : error_msg}`}
										</Text>
										<Button type='round' text='Upload Again' size='medium' style={{ marginTop: 12 }} />
									</div>
								</Upload>
							) : progress < 100 && progress > 0 ? (
								<div className={clsx(classes.upload_csv_content_container, 'text-align-center')} style={{ padding: 32 }}>
									<img src={ImageLinks.cricle_with_dashed_light_purple} width={52} height={52} alt='green-tick' />
									<Text medium style={{ marginTop: 24, fontSize: 14 }}>
										Uploading CSV
									</Text>
									<div className={classes.upload_pending_file_information}>
										<div className='d-flex justify-content-between'>
											<img src={ImageLinks.folder_light_purple} width={24} height={24} />
											<Text style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>{progress}%</Text>
										</div>
										<LinearProgress variant='determinate' value={progress} />
									</div>
								</div>
							) : (
								<Upload accepted_extensions='.csv' add_image={handle_csv} track_event='bulk_order_upload'>
									<ListItem button className={classes.upload_csv_content_container}>
										<img src={ImageLinks.upload_light_purple} width={52} height={52} alt='upload-image' />
										<Text style={{ fontSize: 12, marginTop: 30, marginBottom: 12 }}>Upload CSV file from computer</Text>
										<Button type='round' text='Select File' size='medium' />
									</ListItem>
								</Upload>
							)}
						</>
					)}
					<div className='align-items-center justify-content-between' style={{ marginTop: 16 }}>
						<Text medium style={{ fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
							Please make sure all mandatory fields are filled.
						</Text>
						<Button type='outlined' size='medium' text='CSV Format' left_icon={ImageLinks.download} onClick={download_file} />
					</div>
				</div>
			</div>
			{confirm_delete_modal && (
				<ConfirmClose
					headerTitle='Delete Uploaded CSV'
					title={
						<>
							Are you sure you want to remove{' '}
							<Text bold component={'span'} style={{ fontSize: 14 }}>
								{_.get(uploaded_csv_file, 'name', '')}
							</Text>
						</>
					}
					close={() => set_confirm_delete_modal(false)}
					on_confirm={handle_order}
					is_error_msg={true}
					positive_btn_text={'Delete'}
				/>
			)}
		</>
	);
};

export default UploadCSV;
