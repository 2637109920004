import { useHistory } from 'react-router-dom';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import EmptyState from 'modules/EmptyState';
import Text from 'modules/Text';
import React from 'react';
import Routes from 'resources/Routes';

const NoHubs = ({ open_create_hub }) => {
	const history = useHistory();
	return (
		<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
			<EmptyState
				button_text='New Hub'
				left_icon={ImageLinks.addWhite}
				empty_img={ImageLinks.empty_state_hub}
				onClick={open_create_hub}
				button_style={{ bottom: 38 }}
			/>
		</div>
	);
};

export default NoHubs;
