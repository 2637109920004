import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Divider, InputAdornment, MenuItem, Popover, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import OutlinedTextField from 'modules/OutlinedTextField';
import Select from 'modules/Select';
import Text from 'modules/Text';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import { available_vehicle_types } from '../../helper';
import { useStyles } from './styles';
import utils from 'resources/utils';
import ConfirmClose from 'modules/modals/ConfirmClose';
import 'react-datepicker/dist/react-datepicker.css';
import { not_allow_special_character } from 'resources/regex_patterns';
import Button from 'modules/Button';
import ActiveInactiveDropdown from 'modules/ActiveInactiveDropdown';
import { UploadS3Document } from 'modules/UploadS3Document';
import ImageCropModal from 'modules/ImageCropModal';

function AddVehicleModal({ close, get_vehicle_list, edit_vehicle = {}, set_show_toast, set_selected_vehicle = () => {} }) {
	const classes = useStyles();

	const [registration_number, set_registration_number] = useState('');
	const [odometer_reading, set_odometer_reading] = useState('');
	const [brand, set_brand] = useState('');
	const [capacity, set_capacity] = useState('');
	const [lease_from, set_lease_from] = useState(moment());
	const [lease_to, set_lease_to] = useState(null);
	const [owner_name, set_owner_name] = useState('');
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [tags, set_tags] = useState([]);
	const [docs, set_docs] = useState([]);
	const [images, set_images] = useState([]);
	const [vehicle_type, set_vehicle_type] = useState(null);
	const [status, set_status] = useState(1);
	const [show_status_menu, set_show_status_menu] = useState(false);
	const [show_confirm_close, set_show_confirm_close] = useState(null);
	const [show_exit_popup, set_show_exit_popup] = useState(false);
	const [edit_vehicle_data, set_edit_vehicle_data] = useState({});
	const [is_image_size_error, set_is_image_size_error] = useState(false);
	const [trace_partner_id, set_trace_partner_id] = useState(null);
	const [device_id, set_device_id] = useState('');
	const [source, set_source] = useState('');
	const [trace_entity_id, set_trace_entity_id] = useState(null);
	const [available_trace_partner, set_available_trace_partner] = useState([]);
	const [rc_error, set_rc_error] = useState(false);
	const [image, set_image] = useState(null);
	const [show_crop_modal, set_show_crop_modal] = useState(false);
	const [upload_type, set_upload_type] = useState(null);
	const status_ref = useRef(null);
	const upload_doc_ref = useRef(null);
	const upload_img_ref = useRef(null);

	const { selected_business_id } = useSelector(({ app }) => app);

	useEffect(() => {
		fetch_trace_partners_list();
	}, []);

	useEffect(() => {
		if (!_.isEmpty(edit_vehicle.id)) {
			get_edit_vehicle_detail();
		}
	}, [edit_vehicle]);

	const fetch_trace_partners_list = async () => {
		try {
			const response = await api_requests.get_trace_partner_list();
			const filtered = _.filter(response.data, (item) => item.name != 'pidge');
			set_available_trace_partner(filtered);
		} catch (error) {}
	};

	const handle_tag_change = (value, index, key) => {
		let _tags = _.cloneDeep(tags);
		_tags[index][key] = value;
		set_tags(_tags);
	};
	const add_tag = () => {
		let _tags = _.cloneDeep(tags);
		_tags.push({
			key: '',
			value: '',
		});
		set_tags(_tags);
	};

	const handle_delete_tag = (index) => {
		let _tags = _.cloneDeep(tags);
		_tags.splice(index, 1);
		set_tags(_tags);
	};

	const handle_doc_change = (value, index, key) => {
		let _docs = _.cloneDeep(docs);
		_docs[index][key] = value;
		set_docs(_docs);
	};

	const get_edit_vehicle_detail = async () => {
		const res = await api_requests.get_fleet_single_vehicle(edit_vehicle.id);
		if (res.success) {
			set_registration_number(res.data.registration_number);
			set_odometer_reading(res.data.odometer_reading);
			set_brand(res.data.brand);
			set_capacity(res.data.capacity);
			set_images(res.data.images ? res.data.images : []);
			set_docs(res.data.docs ? res.data.docs : []);
			set_owner_name(res.data.owner_name ? res.data.owner_name : '');
			set_lease_from(res.data.lease_from ? moment(res.data.lease_from) : moment());
			set_lease_to(res.data.lease_to ? moment(res.data.lease_to) : moment());
			set_vehicle_type(res.data.vehicle_type);
			set_status(res.data.status);
			set_edit_vehicle_data(res.data);
			set_device_id(_.get(res, 'data.device_id', ''));
			set_source(_.get(res, 'data.source', ''));
			set_trace_partner_id(_.get(res, 'data.trace_partner_id', null));
			set_trace_entity_id(_.get(res, 'data.trace_entity_id', null));
			if (res.data.tags) {
				set_tags(res.data.tags);
			}
		}
	};

	const remove_img = (index) => {
		let _images = _.cloneDeep(images);
		_images.splice(index, 1);
		set_images(_images);
	};

	const remove_doc = (index) => {
		let _docs = _.cloneDeep(docs);
		_docs.splice(index, 1);
		set_docs(_docs);
	};

	const handle_doc_upload = async (e) => {
		const file = e.target.files[0];
		if (file) {
			let extension = '';
			extension = _.split(file.name, '.');
			extension = extension[extension.length - 1];
			if (!_.includes(['jpg', 'jpeg', 'png'], extension.toLowerCase())) {
				save_file(file, true);
				return;
			}
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.src = e.target.result;
				img.onload = async () => {
					set_image(reader.result.toString());
					set_show_crop_modal(true);
					set_upload_type('docs');
				};
			};
			reader.readAsDataURL(file);
		}
	};

	const handle_img_upload = async (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.src = e.target.result;
				img.onload = async () => {
					set_image(reader.result.toString());
					set_show_crop_modal(true);
					set_upload_type('image');
				};
			};
			reader.readAsDataURL(file);
		}
	};

	const save_file = async (file, is_doc = false) => {
		set_show_crop_modal(false);
		let extension = '';
		extension = _.split(file.name, '.');
		extension = extension[extension.length - 1];
		if (upload_type == 'docs' || is_doc) {
			let _docs = _.cloneDeep(docs);
			try {
				let path = `/fleet/bussines_${selected_business_id}/fleet_doc_${Date.now()}.${extension}`;
				const res = await UploadS3Document(path, file);
				if (res.success) {
					_docs.push({
						file_name: '',
						upload_name: file.name,
						path: res.data.url,
						size: file.size,
					});
					set_docs(_docs);
				}
			} catch (err) {}
		} else {
			let _images = _.cloneDeep(images);
			if (file.size / 1024 > 3072) {
				set_is_image_size_error(true);
				return;
			}
			try {
				let path = `/fleet/bussines_${selected_business_id}/fleet_image_${Date.now()}.${extension}`;
				const res = await UploadS3Document(path, file);
				if (res.success) {
					_images.push({
						file_name: file.name,
						path: res.data.url,
					});
					set_images(_images);
				}
			} catch (err) {}
		}
	};

	const create_vehicle = async () => {
		const data = utils.remove_null_and_empty_from_obj(
			{
				vehicle_type,
				brand,
				capacity: parseInt(capacity),
				registration_number,
				owner_name: owner_name.trim(),
				...(odometer_reading && { odometer_reading: parseInt(odometer_reading) }),
				lease_from: moment(lease_from).format('YYYY-MM-DD'),
				lease_to: moment(lease_to).format('YYYY-MM-DD'),
				...(tags.length > 0 && {
					tags: _.filter(tags, (tag) => {
						return tag.key != '' && tag.value != '';
					}),
				}),
				...(docs.length > 0 && {
					docs: _.map(docs, (doc) => {
						return {
							file_name: doc.file_name,
							path: doc.path,
						};
					}),
				}),
				...(images.length > 0 && { images: images }),
				...(trace_partner_id && {
					trace: {
						device_id,
						trace_partner_id,
						source,
					},
				}),
			},
			{},
			true,
		);
		try {
			if (_.isEmpty(edit_vehicle)) {
				const res = await api_requests.add_fleet_vehicle(data);
				if (res.success) {
					get_vehicle_list({});
					set_show_toast('vehicle');
					close();
				}
			} else {
				const res = await api_requests.update_fleet_vehicle(
					{
						...(odometer_reading && { odometer_reading: parseInt(odometer_reading) }),
						...(docs.length > 0 && {
							docs: _.map(docs, (doc) => {
								return {
									file_name: doc.file_name,
									path: doc.path,
								};
							}),
						}),
						...(tags.length > 0 && {
							tags: _.filter(tags, (tag) => {
								return tag.key != '' && tag.value != '';
							}),
						}),
						...(images.length > 0 && { images: images }),
						...(trace_partner_id && {
							trace: {
								device_id,
								trace_partner_id,
								source,
								...(edit_vehicle_data.trace_entity_id && { trace_entity_id }),
							},
						}),
						lease_to: moment(lease_to).format('YYYY-MM-DD'),
					},
					edit_vehicle.id,
				);
				if (res.success) {
					get_vehicle_list({});
					set_show_toast('vehicle_update');
					close();
				}
			}
		} catch (err) {}
	};

	const handle_update_vehicle = async (selected_item) => {
		const data = selected_item != 'delete' ? { status: selected_item == 'inactive' ? 2 : selected_item == 'active' ? 1 : null } : { is_deleted: true };
		try {
			const res = await api_requests.update_fleet_vehicle(data, edit_vehicle.id);
			if (res.success) {
				get_vehicle_list({});
				set_selected_vehicle({});
				set_show_toast('vehicle_update');
				close();
			}
		} catch (err) {}
	};

	const handle_close_click = () => {
		if (!_.isEmpty(edit_vehicle)) {
			close();
			return;
		}
		if (registration_number != '' || brand != '' || capacity != '' || docs.length > 0 || images.length > 0 || tags.length > 0 || owner_name.length > 0) {
			set_show_exit_popup(true);
			return;
		}
		close();
	};

	const is_button_disabled = useMemo(() => {
		if (_.isEmpty(edit_vehicle)) return registration_number == '' || brand == '' || capacity == '' || vehicle_type == null || lease_to == null;

		if (!_.isEmpty(edit_vehicle) && !_.isEmpty(edit_vehicle_data)) {
			if (
				edit_vehicle_data.odometer_reading != odometer_reading ||
				edit_vehicle_data.source != source ||
				edit_vehicle_data.trace_partner_id != trace_partner_id ||
				edit_vehicle_data.device_id != device_id ||
				!moment(edit_vehicle_data.lease_to).isSame(moment(lease_to), 'day')
			) {
				return false;
			}
			let flag = true;

			if (edit_vehicle_data.tags) {
				if (!tags) {
					return false;
				}
				if (edit_vehicle_data.tags.length != tags.length) {
					return false;
				}
				_.forEach(edit_vehicle_data.tags, (edit_tags, index) => {
					if (edit_tags.key != tags[index].key || edit_tags.value != tags[index].value) {
						flag = false;
					}
				});
			} else {
				if (tags.length > 0) return false;
			}

			if (edit_vehicle_data.docs) {
				if (!docs) {
					return false;
				}
				if (edit_vehicle_data.docs.length != docs.length) {
					return false;
				}
				_.forEach(edit_vehicle_data.docs, (edit_docs, index) => {
					if (edit_docs.path != docs[index].path || edit_docs.file_name != docs[index].file_name) {
						flag = false;
					}
				});
			} else {
				if (docs.length > 0) return false;
			}

			if (edit_vehicle_data.images) {
				if (!images) {
					return false;
				}
				if (edit_vehicle_data.images.length != images.length) {
					return false;
				}
				_.forEach(edit_vehicle_data.images, (edit_images, index) => {
					if (edit_images.file_name != images[index].file_name || edit_images.path != images[index].path) {
						flag = false;
					}
				});
			} else {
				if (images.length > 0) return false;
			}
			return flag;
		}
		return false;
	}, [
		registration_number,
		brand,
		capacity,
		vehicle_type,
		edit_vehicle_data,
		odometer_reading,
		source,
		trace_partner_id,
		docs,
		images,
		device_id,
		lease_to,
		tags,
	]);

	const handle_update_input_valid_int = (e, updater_function) => {
		const value = e.target.value;
		const pattern = /^[-+]?\d+$/;
		if (value == '' || pattern.test(value)) updater_function(value);
	};

	const handle_update_rc_number = (val, updater_function) => {
		let regex = new RegExp(not_allow_special_character);
		if (!regex.test(val)) updater_function(val);
	};

	return (
		<>
			<div className={classes.close_container} onClick={handle_close_click} />
			<div className={classes.container}>
				<div className='d-flex align-items-center justify-content-between'>
					<div className='d-flex align-items-center'>
						<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
							{_.isEmpty(edit_vehicle) ? 'Create Vehicle' : 'Edit Vehicle'}
						</Text>
						{!_.isEmpty(edit_vehicle) && (
							<div
								className={status == 1 ? classes.status_pill_green : classes.status_pill_yellow}
								style={{ backgroundColor: status == 1 ? theme.colors.green : theme.colors.darkYellow }}
								ref={status_ref}
								onClick={() => set_show_status_menu(true)}>
								<img src={status == 1 ? ImageLinks.play_white_circle : ImageLinks.pause_white_circle} height={12} width={12} />
								<Text semi style={{ fontSize: 16, color: theme.colors.white }}>
									{status == 1 ? 'Active' : 'Inactive'}
								</Text>
								<img src={ImageLinks.chevron_left_white} style={{ transform: 'rotate(270deg)' }}></img>
							</div>
						)}
						<Popover
							anchorEl={status_ref.current}
							open={show_status_menu}
							onClose={() => set_show_status_menu(false)}
							style={{ zIndex: 1300 }}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}>
							{status != 1 && (
								<MenuItem
									onClick={() => {
										set_show_confirm_close('active');
									}}>
									<img src={ImageLinks.play_black_circle} />
									<Text
										semi
										style={{
											color: 'inherit',
											marginLeft: 5,
										}}>
										Active
									</Text>
								</MenuItem>
							)}
							{status != 2 && (
								<MenuItem
									onClick={() => {
										set_show_confirm_close('inactive');
									}}>
									<img src={ImageLinks.pause_black_circle} />
									<Text
										semi
										style={{
											color: 'inherit',
											marginLeft: 5,
										}}>
										Inactive
									</Text>
								</MenuItem>
							)}
							<MenuItem
								onClick={() => {
									set_show_confirm_close('delete');
								}}>
								<img src={ImageLinks.delete_black} />
								<Text
									semi
									style={{
										color: 'inherit',
										marginLeft: 5,
									}}>
									Delete
								</Text>
							</MenuItem>
						</Popover>
					</div>
					<img src={ImageLinks.crossBlack} onClick={handle_close_click} style={{ cursor: 'pointer' }} />
				</div>
				<Divider style={{ margin: '10px 0' }} />
				<div className='d-flex align-items-between'>
					<Text semi className={classes.header_text}>
						Vehicle Details
					</Text>
				</div>
				<div className={clsx('d-flex justify-content-between', classes.vehicle_detail_wrap)}>
					<OutlinedTextField
						disabled={!_.isEmpty(edit_vehicle)}
						value={registration_number}
						onChange={(e) => handle_update_rc_number(e.target.value, set_registration_number)}
						label='RC number*'
						className={classes.inputs}
						error={rc_error}
					/>
					<OutlinedTextField
						label='Odometer Reading'
						className={classes.inputs}
						value={odometer_reading}
						onChange={(e) => handle_update_input_valid_int(e, set_odometer_reading)}
					/>
					<OutlinedTextField
						disabled={!_.isEmpty(edit_vehicle)}
						label='Vehicle Brand*'
						className={classes.inputs}
						value={brand}
						onChange={(e) => set_brand(e.target.value)}
					/>
					<OutlinedTextField
						disabled={!_.isEmpty(edit_vehicle)}
						label='Capacity (m³)*'
						className={classes.inputs}
						value={capacity}
						onChange={(e) => handle_update_input_valid_int(e, set_capacity)}
					/>
					<div>
						<Select
							value={vehicle_type}
							defaultOption={{
								title: 'Vehicle Type*',
								value: null,
							}}
							options={_.map(available_vehicle_types, (vehicle) => {
								return {
									title: vehicle,
									value: vehicle,
								};
							})}
							handleChange={(val) => {
								set_vehicle_type(val);
							}}
							containerStyles={{ height: 35, width: 160 }}
							menu_styles={{ marginTop: 40 }}
							truncate_title={(title) => {
								return _.truncate(title, { length: 16 });
							}}
						/>
					</div>
					<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2, width: '100%' }}>
						Additional/Other
					</Text>
					<OutlinedTextField
						disabled={!_.isEmpty(edit_vehicle)}
						label='Owner/Vendor'
						className={classes.inputs}
						value={owner_name}
						onChange={(e) => set_owner_name(e.target.value)}
					/>

					<div style={{ width: '48%' }}>
						<DatePicker
							// disabled={!_.isEmpty(edit_vehicle)}
							dateFormat='dd MMM `yy'
							selected={lease_to ? lease_to.toDate() : new Date()}
							onChange={(date) => set_lease_to(moment(date))}
							minDate={new Date()}
							value={lease_to ? lease_to.format('DD MMM `YY') : ''}
							customInput={
								<OutlinedTextField
									label='Expiry Date*'
									style={{ color: theme.colors.black, opacity: 1, width: '100%' }}
									className={classes.inputs}
									onChange={(e) => {}}
									value={lease_to ? lease_to.format('DD MMM `YY') : ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<img
													src={ImageLinks.calendar_solid_circle}
													alt='date'
													onClick={() => {
														if (_.isEmpty(edit_vehicle)) set_date_picker_open(true);
													}}
													className='cursor-pointer'
													width={'22px'}
													height={'22px'}
												/>
											</InputAdornment>
										),
									}}
								/>
							}
						/>
					</div>
					<div style={{ width: '100%' }}>
						<Select
							value={trace_partner_id}
							defaultOption={{
								title: 'Trace Partner',
								value: null,
							}}
							options={[
								{
									title: 'No Partner',
									value: null,
								},
								..._.map(available_trace_partner, (partner) => {
									return {
										title: partner.name,
										value: partner.id,
									};
								}),
							]}
							handleChange={(val) => {
								set_trace_partner_id(val);
							}}
							containerStyles={{ height: 35, width: 160 }}
							menu_styles={{ marginTop: 40 }}
							truncate_title={(title) => {
								return _.truncate(title, { length: 16 });
							}}
						/>
					</div>
					{trace_partner_id && (
						<OutlinedTextField label='Device Id' className={classes.inputs} value={device_id} onChange={(e) => set_device_id(e.target.value)} />
					)}
					{trace_partner_id && (
						<OutlinedTextField label='Source*' className={classes.inputs} value={source} onChange={(e) => set_source(e.target.value)} />
					)}
					{tags.length > 0 && (
						<>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2, width: '100%' }}>
								Tags
							</Text>
							{_.map(tags, (tag, index) => {
								return (
									<div className='d-flex' style={{ width: '100%', gap: 10 }}>
										<OutlinedTextField
											label='Title'
											className={classes.inputs}
											value={tag.key}
											onChange={(e) => handle_tag_change(e.target.value, index, 'key')}
										/>
										<OutlinedTextField
											label='Description'
											className={classes.inputs}
											value={tag.value}
											onChange={(e) => handle_tag_change(e.target.value, index, 'value')}
										/>
										<img
											src={ImageLinks.delete}
											width={20}
											height={'auto'}
											style={{ cursor: 'pointer' }}
											onClick={() => handle_delete_tag(index)}
										/>
									</div>
								);
							})}
						</>
					)}
					<Button type='text' size='extra_small' text='Add Tag' onClick={add_tag} left_icon={ImageLinks.add_pruple_no_fill} />
				</div>
				<Text semi className={classes.header_text}>
					Vehicle Documents
				</Text>
				<div className={classes.vehicle_detail_wrap}>
					<input
						style={{ display: 'none' }}
						type='file'
						ref={upload_doc_ref}
						onChange={(e) => {
							handle_doc_upload(e);
						}}
					/>
					{docs.length == 0 && (
						<div className='d-flex align-items-center' style={{ flexDirection: 'column', width: '100%' }}>
							<Text>Record your vehicles important documentations</Text>
							<Button type='text' text='Add Document' left_icon={ImageLinks.add_pruple_no_fill} onClick={() => upload_doc_ref.current.click()} />
						</div>
					)}
					{docs.length > 0 && (
						<>
							{_.map(docs, (doc, index) => {
								return (
									<>
										<OutlinedTextField
											label='Document Name'
											className={classes.inputs}
											value={doc.file_name}
											onChange={(e) => handle_doc_change(e.target.value, index, 'file_name')}
										/>
										<div className={classes.doc_desc}>
											<div onClick={() => window.open(doc.path, '_blank')} style={{ cursor: 'pointer' }}>
												<Text semi>{doc.upload_name ? doc.upload_name : doc.file_name}</Text>
												<Text medium>{isNaN(doc.size / 1024) ? '-' : (doc.size / 1024).toFixed(1) + 'KB'}</Text>
											</div>
											<div className={classes.cross_wrap} onClick={() => remove_doc(index)}>
												<img src={ImageLinks.crossRed} height={10} width={10} />
											</div>
										</div>
									</>
								);
							})}
							<div className='d-flex' style={{ gap: 5, cursor: 'pointer' }} onClick={() => upload_doc_ref.current.click()}>
								<img src={ImageLinks.add_pruple_no_fill} />
								<Text semi className={classes.add_tag_text}>
									Add Document
								</Text>
							</div>
						</>
					)}
				</div>
				<Text semi className={classes.header_text}>
					Upload Vehicle Image (up to 3)
				</Text>
				<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
					Preferred image format: JPEG, PNG, SVG.
				</Text>
				<input
					style={{ display: 'none' }}
					type='file'
					ref={upload_img_ref}
					onChange={(e) => {
						handle_img_upload(e);
					}}
				/>
				<div className={classes.image_wrap}>
					{_.map(images, (image, index) => {
						return (
							<div style={{ position: 'relative' }}>
								<img
									src={image.path}
									height={100}
									width={100}
									onClick={() => window.open(image.path, '_blank')}
									style={{ cursor: 'pointer' }}
								/>
								<div
									className={clsx(classes.cross_button, classes.cross_wrap)}
									onClick={() => {
										remove_img(index);
									}}>
									<img src={ImageLinks.crossRed} height={10} width={10} />
								</div>
							</div>
						);
					})}
					{images.length < 3 && (
						<div className={classes.image_upload_wrap} onClick={() => upload_img_ref.current.click()}>
							<img src={is_image_size_error ? ImageLinks.exclamation_error : ImageLinks.upload_light_purple} />
							<Text>Upload Image</Text>
							<Button type='round' size='extra_small' text={is_image_size_error ? 'Upload Again' : 'Select File'} />
							{/* <ContainedButton style={{ borderRadius: 10, height: 20 }}>
								<Text bold style={{ fontSize: 10 }}>
									{is_image_size_error ? 'Upload Again' : 'Select File'}
								</Text>
							</ContainedButton> */}
						</div>
					)}
				</div>
			</div>
			<div className={classes.create_button_wrap}>
				<Button
					type='round'
					text={_.isEmpty(edit_vehicle) ? 'Create' : 'Update'}
					size='large'
					disabled={is_button_disabled}
					onClick={() => create_vehicle()}
					style={{ width: 110 }}
				/>{' '}
			</div>
			{show_confirm_close && (
				<ConfirmClose
					headerTitle={
						show_confirm_close == 'active' ? 'Activate vehicle' : show_confirm_close == 'inactive' ? 'Inactivate vehicle' : 'Delete Vehicle'
					}
					title={
						<Text>
							{show_confirm_close == 'active' || show_confirm_close == 'inactive'
								? 'Are you sure you want to change the status for vehicle '
								: 'Are you sure you want to delete vehicle '}
							<Text bold component={'span'}>
								{registration_number}
							</Text>{' '}
							<Text component={'span'}>{`${show_confirm_close == 'delete' ? '.Vehicle will delete permanently' : '.'}`}</Text>
							{show_confirm_close == 'delete' && (
								<Text style={{ color: theme.colors.darkGrey2 }}>Disclaimer: All future assignments will also be deleted permanently</Text>
							)}
						</Text>
					}
					show_negative_btn={true}
					negative_btn_text='Cancel'
					on_confirm={() => {
						handle_update_vehicle(show_confirm_close);
					}}
					close={() => {
						set_show_confirm_close(null);
					}}
					is_error_msg={show_confirm_close == 'delete'}
					is_save_change={show_confirm_close == 'active'}
					handle_negative_action={() => set_show_confirm_close(null)}
				/>
			)}
			{show_exit_popup && (
				<ConfirmClose
					headerTitle='Discard Changes'
					title='You have made changes but you have not saved them. Are you sure you want to discard these changes?'
					on_confirm={close}
					show_negative_btn={true}
					handle_negative_action={() => set_show_exit_popup(false)}
				/>
			)}
			{show_crop_modal && (
				<ImageCropModal
					img_src={image}
					close={() => {
						set_show_crop_modal(false);
						set_image('');
					}}
					on_replace={() => {
						set_show_crop_modal(false);
						upload_img_ref.current.click();
					}}
					on_save={save_file}
				/>
			)}
		</>
	);
}

export default AddVehicleModal;
