import React, { useEffect } from 'react';
import qs from 'qs';
import Text from 'modules/Text';
import theme from 'resources/theme';
import _ from 'lodash';
import { ListItem, makeStyles } from '@material-ui/core';

import Overview from './components/Overview';
import ReadyToShip from './components/ReadyToShip/ReadyToShip';
import Terminal from './components/Terminal';
import Routes from 'resources/Routes';
import Pending from './components/Pending/Pending';
import { ALL_ORDERS_MENU_ITEMS } from './helper';
import Shipped from './components/Shipped';
import api_requests from 'resources/api_requests';
import utils from 'resources/utils';
import constant from 'resources/constant';
import Cancelled from './components/Cancelled';

const useStyles = makeStyles(() => ({
	container: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	ordersContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderBottom: `1px solid ${theme.colors.lightPurple}`,
	},
	header_text: { fontSize: 20, textDecoration: `underline 2px ${theme.colors.lightPurple}`, textUnderlineOffset: '5px' },
	list_wrapper: { display: 'flex', paddingBottom: 10 },
}));

const AllOrders = ({ history }) => {
	const query_object = qs.parse(history.location.search.slice(1));
	const [selected_menu, set_selected_menu] = React.useState(query_object.type || ALL_ORDERS_MENU_ITEMS.Overview.id);
	const [report_items, set_report_items] = React.useState([]);
	const cdr_id = query_object?.cdr_id || null;

	useEffect(() => {
		fetch_filter_items();
	}, []);

	const fetch_filter_items = async () => {
		try {
			const response = await api_requests.get_order_export_items();
			if (response.data.items) {
				set_report_items(response.data.items);
			}
		} catch (error) {}
	};

	useEffect(() => {
		if (!query_object.type) {
			set_selected_menu(ALL_ORDERS_MENU_ITEMS.Overview.id);
			return;
		}
		set_selected_menu(query_object.type);
	}, [query_object.type]);

	const classes = useStyles();

	return (
		<div className={`container ${classes.container}`}>
			<div style={{ display: 'flex', marginBottom: 32 }}>
				<Text semi component={'span'} className={'header_active_title'}>
					All Orders
				</Text>
				<Text
					component={'span'}
					className={'global_header_text'}
					style={{ cursor: 'pointer' }}
					onClick={() => {
						utils.track_event_for_analytics(constant.TRACKING_EVENTS.ALL_ORDER_EMPTY_CREATE_ORDER);
						history.push('/powered-with-pidge');
					}}>
					&nbsp;| Pidge Powered
				</Text>
			</div>
			<div className={classes.ordersContainer}>
				<div className={classes.list_wrapper}>
					{_.map(ALL_ORDERS_MENU_ITEMS, (menu_item, key) => {
						const active = selected_menu === menu_item.id;
						return (
							<ListItem
								key={`menuItem${key}`}
								button
								onClick={() => {
									history.push(Routes.ALL_ORDERS.path + `?type=${menu_item.id}`);
								}}
								style={{
									backgroundColor: active ? theme.palette.primary.main : 'transparent',
									borderRadius: 8,
									display: 'flex',
									justifyContent: 'center',
									width: 'auto',
									...(menu_item.id !== ALL_ORDERS_MENU_ITEMS.Overview.id && { marginLeft: 10 }),
								}}>
								<Text semi style={{ color: active ? 'white' : theme.colors.lightPurple2 }}>
									{menu_item.text}
								</Text>
							</ListItem>
						);
					})}
				</div>
			</div>
			{selected_menu === ALL_ORDERS_MENU_ITEMS.Overview.id && (
				<Overview reference_id={_.get(history, 'location.reference_id', '')} report_items={report_items} />
			)}
			{selected_menu === ALL_ORDERS_MENU_ITEMS.Pending.id && <Pending report_items={report_items} cdr_id={cdr_id} />}
			{selected_menu === ALL_ORDERS_MENU_ITEMS.ReadyToShip.id && <ReadyToShip report_items={report_items} />}
			{selected_menu === ALL_ORDERS_MENU_ITEMS.Shipped.id && <Shipped report_items={report_items} />}
			{selected_menu === ALL_ORDERS_MENU_ITEMS.Terminal.id && <Terminal report_items={report_items} />}
			{selected_menu === ALL_ORDERS_MENU_ITEMS.Cancelled.id && <Cancelled report_items={report_items} />}
		</div>
	);
};

export default AllOrders;
