import React, { useMemo } from 'react';
import useStyles from '../styles';
import moment from 'moment';
import theme from 'resources/theme';
import Amount from 'modules/Amount';
import Text from 'modules/Text';
import clsx from 'clsx';

const DayWiseRow = ({ item, on_mcp_click }) => {
	const classes = useStyles();

	const is_item_clickable = useMemo(() => {
		let clickable = false;
		if (
			item.entity === 'mcp' ||
			item.entity === 'recharge' ||
			item.entity === 'dunzo_id' ||
			item.entity === 'manual-debit' ||
			item.entity === 'wefast_id' ||
			item.entity === 'loadshare_id' ||
			item.entity === 'shadowfax_id' ||
			item.entity === 'zomato_id' ||
			item.entity === 'porter_id' ||
			item.entity === 'pidge_id' ||
			item.entity?.includes('SaaS-')
		) {
			clickable = true;
		}
		return clickable;
	}, [item]);

	return (
		<>
			<div className={clsx(classes.row_item_wrap, classes.day_row_wrap)}>
				<div className='align-items-center' style={{ flex: 0.85 }}>
					<Text
						onClick={() => on_mcp_click(item.entity, item.reference_id, item.transaction_id)}
						semi
						style={{
							fontSize: 14,
							paddingRight: 4,
							flex: 1,
							cursor: is_item_clickable ? 'pointer' : 'auto',
							textDecoration: is_item_clickable ? 'underline' : 'none',
						}}>
						{`#${item.description ? item.description : item.reference_id ? item.reference_id : '-'}`}
						<Text component={'span'} bold className={classes.day_wise_time} style={{ display: 'inline-block' }}>{`${moment(item.timestamp).format(
							'HH:mm',
						)}`}</Text>
					</Text>
				</div>
				{/* Will be implemented later */}
				{/* {item.entity != 'mcp' && (
                <ContainedButton className={classes.download_btn} style={{}}>
                    <Text bold style={{ fontSize: 14 }}>
                        Download Receipt
                    </Text>
                </ContainedButton>
            )} */}

				<div className={classes.flex_center} style={{ flex: 0.15, justifyContent: 'right' }}>
					<Text
						bold
						style={{
							fontSize: 16,
							color: item.transaction_type === 200 ? theme.colors.black : theme.colors.green,
							marginRight: 5,
						}}>
						{item.transaction_type === 200 ? '-' : '+'}
					</Text>
					<Amount
						amount={item.amount}
						main_text_styles={{
							fontSize: 16,
							color: item.transaction_type === 200 ? theme.colors.black : theme.colors.green,
						}}
						sub_text_styles={{
							fontSize: 10,
							color: item.transaction_type === 200 ? theme.colors.black : theme.colors.green,
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default DayWiseRow;
