import _ from 'lodash';
import React from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import Text from 'modules/Text';
import ContainedButton from 'modules/ContainedButton';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles(() => ({
	modalContainer: { display: 'flex', outline: 'none' },
	componentContainer: {
		padding: 30,
		background: 'white',
		width: 500,
		height: 250,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		outline: 'none'
	},
	headerTitle: { fontSize: 24, width: '100%', marginBottom: 30 },
	textContainer: { fontSize: 16, color: theme.colors.red, marginLeft: 5, marginTop: -4 },
	containedButtonsContainer: { marginTop: 50, width: 250, display: 'flex', justifyContent: 'space-between' },
	button: { borderRadius: 30, height: 46, width: 125 }
}));

const ConfirmTxnActionModal = ({ close, on_confirm, title = 'Are you sure you want to close', headerTitle }) => {
	const handle_confirm = () => {
		on_confirm();
		close();
	};
	const classes = useStyles();
	return (
		<Modal open={true} onClose={close} className={classes.modalContainer}>
			<div className={classes.componentContainer}>
				<Text bold className={classes.headerTitle}>
					{_.capitalize(headerTitle)} Amount
				</Text>

				<div style={{ display: 'flex' }}>
					<img src={ImageLinks.exclamation_error} width={16} height={16} />
					<Text medium className={classes.textContainer}>
						{title}
					</Text>
				</div>
				<div className={classes.containedButtonsContainer}>
					<ContainedButton style={{ backgroundColor: 'transparent', boxShadow: 'none' }} onClick={close} className={classes.button}>
						<Text semi style={{ color: theme.colors.primary, fontSize: 18 }}>
							Cancel
						</Text>
					</ContainedButton>
					<ContainedButton className={classes.button} onClick={handle_confirm}>
						<Text semi style={{ color: theme.colors.white, fontSize: 18 }}>
							Confirm
						</Text>
					</ContainedButton>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmTxnActionModal;
