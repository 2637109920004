import React from 'react';
import _ from 'lodash';
import useRepeatOrders from './useRepeatOrders';
import RepeatOrdersContext from './context';
import RepeatOrderScreen from './RepeatOrdersScreen';

const RepeatOrders = () => {
	const value = useRepeatOrders();

	return (
		<RepeatOrdersContext.Provider value={value}>
			<RepeatOrderScreen />
		</RepeatOrdersContext.Provider>
	);
};
export default RepeatOrders;
