import { makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _, { join } from 'lodash';
import Button from 'modules/Button';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import ConfirmClose from 'modules/modals/ConfirmClose';
import React, { useEffect, useState } from 'react';
import api_requests from 'resources/api_requests';

const useStyles = makeStyles((theme) => ({
	rider_list_wrap: {
		borderRadius: 8,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		padding: 12,
		minHeight: '70vh',
	},
	rider_list_item: {
		borderRadius: 6,
		boxShadow: `0px 0px 4px 0px rgba(196, 196, 196, 0.30)`,
		padding: 16,
		marginTop: 12,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	rider_id_item: {
		width: '50%',
		padding: 8,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	rider_id_sub_item: {
		display: 'inline-flex',
		alignItems: 'center',
		padding: 4,
		minHeight: 36,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
			borderRadius: 6,
			'& .delete_icon': {
				display: 'inline !important',
			},
		},
	},
}));

function RiderList({ network_id, open_rider_modal, refresh, set_refresh, selected_network }) {
	const classes = useStyles();
	const theme = useTheme();
	const [rider_list, set_rider_list] = useState([]);
	const [selected_index, set_selected_index] = useState(-1);
	const [delete_name, set_delete_name] = useState('');
	const [delete_id, set_delete_id] = useState(null);
	const [selected_delete_data, set_selected_delete_data] = useState({});

	useEffect(() => {
		if (network_id) get_rider_list();
	}, [network_id, refresh]);

	const get_rider_list = async () => {
		try {
			const res = await api_requests.get_network_rider_list(network_id);
			set_rider_list(res.data);
		} catch (err) {}
	};

	const get_unique_riders = () => {
		const joined_riders = _.concat(
			..._.map(rider_list, (rider_item) => {
				return rider_item.rider_ids;
			}),
		);
		return [...new Set(joined_riders)].length;
	};

	const handle_delete_rider = async () => {
		try {
			const data = {
				rider_ids: [delete_id],
				is_permanent: selected_delete_data.is_permanent,
			};
			if (!selected_delete_data.is_permanent) {
				data.slots = [
					{
						dates: selected_delete_data.dates,
						start_time: selected_delete_data.start_time,
						end_time: selected_delete_data.end_time,
					},
				];
			}
			const res = await api_requests.delete_network_rider(network_id, data);
			get_rider_list();
			set_refresh((prev) => !prev);
		} catch (err) {}
	};

	return (
		<div style={{ overflow: 'auto', height: '50vh' }}>
			<div className='d-flex justify-content-between align-items-center'>
				<Text bold style={{ fontSize: 14 }}>
					<Text component={'span'} bold style={{ fontSize: 14, color: theme.colors.primary }}>
						{get_unique_riders()}
					</Text>{' '}
					Riders - Slot
				</Text>
				{rider_list.length != 0 && (selected_network.can_manage || selected_network.can_lend_rider) && (
					<Button type='round' text='Add' left_icon={ImageLinks.addWhite} onClick={open_rider_modal} size='extra_small' />
				)}
			</div>
			{rider_list.length == 0 && (selected_network.can_manage || selected_network.can_lend_rider) && (
				<div className='d-flex justify-content-center align-items-center' style={{ flexDirection: 'column', marginTop: '20vh' }}>
					<img src={ImageLinks.no_riders} height={94} width={94} />
					<Text semi style={{ fontSize: 16 }}>
						Add Riders to Network
					</Text>
					<Button type='round' text='Add Rider' size='extra_small' onClick={open_rider_modal} left_icon={ImageLinks.addWhite} />
				</div>
			)}
			{_.map(rider_list, (rider_item, index) => {
				return (
					<div key={`rider_list_${index}`}>
						<div className={classes.rider_list_item} onClick={() => set_selected_index(selected_index == index ? -1 : index)}>
							<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
								{rider_item.rider_ids.length} Riders
							</Text>
							<Text semi style={{ fontSize: 14 }}>
								{rider_item.is_permanent
									? 'Permanent Availability'
									: `${rider_item.dates.length} Dates | ${rider_item.start_time} - ${rider_item.end_time}`}
							</Text>
							<img src={ImageLinks.chevronLeft} style={{ transform: selected_index === index ? 'rotate(90deg)' : 'rotate(270deg)' }} />
						</div>
						{selected_index === index && (
							<div className='d-flex' style={{ width: 'auto', flexWrap: 'wrap' }}>
								{_.map(rider_item.riders_info, (rider) => {
									return (
										<div className={classes.rider_id_item}>
											<div className={classes.rider_id_sub_item}>
												<Text component={'span'} bold className={classes.rider_text}>
													{`${rider.name} (${rider.id})`}{' '}
												</Text>
												<div
													className='delete_icon'
													style={{ display: 'none', marginTop: 2 }}
													onClick={() => {
														set_selected_delete_data(rider_item);
														set_delete_id(rider.id);
														set_delete_name(rider.name);
													}}>
													<img src={ImageLinks.delete} height={20} width={16} style={{ cursor: 'pointer', marginLeft: 8 }} />
												</div>
											</div>
										</div>
									);
								})}
								{rider_item.rider_ids.length % 2 === 1 && <div className={classes.rider_id_item}></div>}
							</div>
						)}
					</div>
				);
			})}
			{delete_id && (
				<ConfirmClose
					is_error_msg={true}
					negative_btn_text='Cancel'
					positive_btn_text='Remove'
					show_negative_btn={true}
					headerTitle='Remove Rider'
					title={
						<Text>
							Are you sure you want to remove{' '}
							<Text bold component={'span'}>
								{delete_name}
							</Text>{' '}
							{selected_delete_data.is_permanent ? 'from this network' : 'from '}{' '}
							<Text bold component={'span'}>
								{selected_delete_data.dates.length > 0 ? selected_delete_data.dates.length : ''}
							</Text>{' '}
							{selected_delete_data.is_permanent ? '' : 'dates for '}
							{!selected_delete_data.is_permanent && (
								<Text bold component={'span'}>{`${selected_delete_data.start_time} - ${selected_delete_data.end_time}`}</Text>
							)}
						</Text>
					}
					handle_negative_action={() => {
						set_delete_id(null);
						set_selected_delete_data({});
					}}
					close={() => {
						set_delete_id(null);
						set_selected_delete_data({});
					}}
					on_confirm={handle_delete_rider}
				/>
			)}
		</div>
	);
}

export default RiderList;
