import { ADD_NEW_ALERT, REMOVE_ALL_ALERTS } from '../actions/actionConstants';

const initialState = {
	alerts: [],
};

export default function get_app_alerts(state = initialState, action) {
	switch (action.type) {
		case ADD_NEW_ALERT:
			return {
				...state,
				alerts: [...state.alerts, action.data],
			};

		case REMOVE_ALL_ALERTS:
			return { ...initialState };

		default:
			return state;
	}
}
