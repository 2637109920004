import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import Button from 'modules/Button';
import IconButton from 'modules/IconButton';
import ConfirmClose from 'modules/modals/ConfirmClose';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import OutlinedTextField from 'modules/OutlinedTextField';
import Text from 'modules/Text';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import Routes from 'resources/Routes';
import theme from 'resources/theme';
import utils from 'resources/utils';

let map = null;
let marker;

const useStyles = makeStyles((theme) => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 20, overflowY: 'hidden' },
	stats: { display: 'flex', marginRight: 30, minHeight: 70 },
	stats_box: { backgroundColor: theme.colors.white, display: 'flex', flex: 1, borderRadius: 5, overflow: 'hidden' },
	edit_icon: {
		borderColor: 'transparent',
		width: 20,
		height: 20,
		marginLeft: 10,
	},
	bid_text: { fontSize: 12, marginTop: 8 },
	stats_wrap: {
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.colors.lightPurple,
		padding: 12,
	},
	red_dot_small: {
		width: '8px',
		height: '8px',
		backgroundColor: theme.colors.red,
		display: 'block',
		borderRadius: '50%',
		marginLeft: 5,
	},
	stats_label: { color: theme.colors.lightGrey6, fontSize: 12 },
	trip_row: {
		width: '100%',
		padding: '5px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'&:hover': {
			'& > .cross': {
				visibility: 'visible !important',
			},
		},
	},
	optimize_wrapper: {
		borderRadius: 10,
		background: 'linear-gradient(180deg, #492897 0%, #25144C 100%)',
		display: 'flex',
		height: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 20px',
	},
	optimize_text: { color: theme.colors.white, width: 200, margin: '0 auto', textAlign: 'center' },
	edit_wrap: { padding: 16, width: 210 },
	edit_input: { display: 'flex', alignItems: 'center' },
	fix_seq_wrap: { marginLeft: 30, width: 330 },
	fix_seq_Btn: { backgroundColor: theme.colors.white, borderRadius: 20 },
	bundle_list_wrap: { flex: 1, display: 'flex', overflowY: 'hidden', marginTop: 20, marginBottom: 20 },
	table_wrap: { flex: 5, display: 'flex', flexDirection: 'column', overflowY: 'hidden', marginRight: 20 },
	table_header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	update_seq_btn: { height: 30, borderRadius: 20 },
	table_head: {
		background: theme.colors.white,
		border: `1px solid ${theme.colors.lightPurple}`,
		borderRadius: 50,
		width: '100%',
		padding: '10px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
	},
	drag_list: { overflowY: 'auto', flex: 1, marginTop: 10, marginBottom: 10 },
	key_item_wrap: {
		height: 20,
		width: 20,
		backgroundColor: theme.colors.lightPurple,
		borderRadius: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	key_item_text: {
		color: theme.colors.primary,
		fontSize: 12,
	},
	modal_text: { display: 'flex', alignItems: 'center', fontSize: 16 },
	btn_wrap: { display: 'flex', justifyContent: 'flex-end', marginTop: 16, marginBottom: 60, height: 40 },
	map_wrap: { marginRight: 30, flex: 1, borderRadius: 20, overflow: 'hidden' },
	right_section: { flex: 4, display: 'flex', flexDirection: 'column', marginTop: 50 },
	pbid_text_highlighted: {
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
	unlink_rider_text: { fontSize: 12, color: theme.colors.primary, textDecoration: 'underline', textDecorationColor: theme.colors.primary },
	list_item_stats_wrap_2: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	disabled_overlay: {
		display: 'flex',
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.white, 70),
		opacity: 0.9,
		position: 'absolute',
		top: 100,
		width: '100%',
		height: '100%',
		zIndex: 100,
	},
}));

const initial_confirm_remove_squence_details = {
	open: false,
	trip_ids: [],
};

const initial_edit_name_details = {
	open: false,
	name: '',
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	boxShadow: isDragging ? '2px 5px 16px 0px rgba(196, 196, 196, 0.3)' : 'none',
	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({});

const UnallocatedRoutes = ({
	is_simulation_mode,
	bundle_id,
	bundle_label,
	get_bundles,
	set_allocate_rider_details,
	set_refresh_bundles,
	premium_features_blocked,
	handle_show_premium_feature,
	selected_trip_ids,
	handle_select_trip,
	refresh_trips,
	set_drag_trip_id,
	set_active_pickup,
	set_refresh_trips,
	bundles,
	allocate_rider_details,
	simulation_data = [],
	update_simulator_bundle,
	selected_menu,
	set_active_bundle,
}) => {
	const [bundle_data, set_bundle_data] = React.useState({});
	const [edit_name_details, set_edit_name_details] = React.useState({ ...initial_edit_name_details });

	const [confirm_remove_squence_details, set_confirm_remove_squence_details] = React.useState({ ...initial_confirm_remove_squence_details });
	const [confirm_save_for_later, set_confirm_save_for_later] = React.useState({ open: false });

	const [refresh, set_refresh] = React.useState(false);
	const [show_update_sequence, set_show_update_sequence] = React.useState(false);
	const [sequence_fixed, set_sequence_fixed] = React.useState(false);
	const [show_border, set_show_border] = useState(-1);
	const [open_pbid_modal, set_open_pbid_modal] = useState(null);
	const simulation_mcp_data = _.concat(
		..._.map(simulation_data, (item) => {
			return item.mcp;
		}),
	);
	const mcp_list = _.map(simulation_mcp_data, (item) => {
		return item.mcp_id;
	});
	const dragItem = useRef();
	const dragOverItem = useRef();

	const history = useHistory();

	const dragStart = (e, position, trip) => {
		set_drag_trip_id(trip.pbid);
		set_active_pickup(trip.pickup_address);
		dragItem.current = position;
	};

	const dragEnter = (e, position) => {
		dragOverItem.current = position;
		set_show_border(position);
	};

	const drop = (e) => {
		set_active_pickup(null);
		on_drag_end({ source: dragItem.current, destination: dragOverItem.current });
	};

	const on_drag_end = ({ destination, source }) => {
		// dropped outside the list
		if (destination < 0) return;

		const newTripData = _.cloneDeep(bundle_data.tripData);
		const [removed] = newTripData.splice(source, 1);
		newTripData.splice(destination, 0, removed);

		set_bundle_data({
			...bundle_data,
			tripData: newTripData,
		});
		if (destination !== source) set_show_update_sequence(true);
		set_show_border(-1);
	};

	const show_edit_name = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_NAME_EDIT);
		set_edit_name_details({
			open: true,
			name: bundle_label,
		});
	};

	const handle_edit_name = async () => {
		await api_requests.edit_bundle_name(bundle_id, {
			bundleName: edit_name_details.name,
		});

		await get_bundles();

		set_edit_name_details({ ...initial_edit_name_details });
	};

	const handle_fix_sequence = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_SEQUENCE);
		if (premium_features_blocked) {
			handle_show_premium_feature();
			return;
		}
		api_requests.reroute_bundle(bundle_id).then((res) => {
			set_refresh(!refresh);
			set_sequence_fixed(true);
			if (is_simulation_mode) update_simulator_bundle(bundle_id);
		});
	};

	const handle_confirm_close = () => {
		api_requests
			.ungroup_trip({
				trips: confirm_remove_squence_details.trip_ids,
			})
			.then((res) => {
				if (bundle_data.tripData.length === confirm_remove_squence_details.trip_ids.length) {
					set_refresh_bundles((v) => !v);
					set_active_bundle({});
					set_bundle_data({});
					history.replace({ search: '?type=' + selected_menu });
				}
				get_bundles();
				set_refresh(!refresh);
			});
	};

	const handle_update_sequence = () => {
		if (premium_features_blocked) {
			handle_show_premium_feature();
			return;
		}
		api_requests
			.reordering_bundle(bundle_id, {
				trips: _.map(bundle_data.tripData, (value, key) => {
					return { trip_id: value.trip_id, sequence: key + 1 };
				}),
			})
			.then((res) => {
				set_show_update_sequence(false);
				set_sequence_fixed(false);
				set_refresh(!refresh);
				set_refresh_bundles((val) => !val);
				if (is_simulation_mode) update_simulator_bundle(bundle_id);
			});
	};

	const handle_unlink_rider = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_UNLINK);
		try {
			const res = await api_requests.unlink_route_to_rider({ bundleIds: [bundle_id] });
			if (res.success) {
				set_refresh(!refresh);
				set_refresh_trips((prev) => !prev);
			}
		} catch (err) {}
	};

	useEffect(() => {
		set_active_pickup(null);
		if (!bundle_id) {
			return null;
		}
		api_requests.get_bundles_data(bundle_id).then((res) => {
			set_bundle_data(res.data);
		});
	}, [refresh, refresh_trips, allocate_rider_details]);

	useEffect(() => {
		setTimeout(() => {
			if (_.isEmpty(bundle_data.tripData)) {
				map = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
					center: { lat: 28, lng: 77 },
					zoom: 10,
					clickableIcons: false,
					disableDefaultUI: true,
					scaleControl: true,
					zoomControl: true,
					mapId: constant.MAP_DESIGN_ID,
				});
				return;
			}

			map = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
				center: { lat: Number(bundle_data.tripData[0].pickup_address.latitude), lng: Number(bundle_data.tripData[0].pickup_address.longitude) },
				zoom: 10,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});

			const icon = {
				url: ImageLinks.marker_purple, // url
				scaledSize: new window.google.maps.Size(32, 32), // scaled size
			};
			const pick_up_icon = {
				url: ImageLinks.rider_pickup, // url
				scaledSize: new window.google.maps.Size(32, 32), // scaled size
			};

			new window.google.maps.Marker({
				position: { lat: Number(bundle_data.tripData[0].pickup_address.latitude), lng: Number(bundle_data.tripData[0].pickup_address.longitude) },
				icon: pick_up_icon,
				map,
			});

			marker = _.map(bundle_data.tripData, (val, key) => {
				return new window.google.maps.Marker({
					position: { lat: Number(val.drop_address.latitude), lng: Number(val.drop_address.longitude) },
					label: { text: `${key + 1}`, color: 'white' },
					icon: icon,
					map,
				});
			});

			let bounds = new window.google.maps.LatLngBounds();
			let pickup_coord = new window.google.maps.LatLng(
				Number(bundle_data.tripData[0].pickup_address.latitude),
				Number(bundle_data.tripData[0].pickup_address.longitude),
			);
			bounds.extend(pickup_coord);
			_.map(bundle_data.tripData, (val) => {
				let drop_coord = new window.google.maps.LatLng(Number(val.drop_address.latitude), Number(val.drop_address.longitude));
				bounds.extend(drop_coord);
			});
			map.fitBounds(bounds);
		}, 800);
	}, [bundle_data.tripData]);

	const classes = useStyles();

	return (
		<>
			<div className={classes.wrapper}>
				{_.isEmpty(bundle_data) && (
					<div className={classes.disabled_overlay}>
						<div className='align-items-center justify-content-center flex1' style={{ flexDirection: 'column', marginRight: 240 }}>
							<img src={ImageLinks.empty_route} />
							<Text semi style={{ fontSize: 16 }}>
								Select a route to view details
							</Text>
						</div>
					</div>
				)}
				<div className={classes.stats}>
					<div className={`box-shadow ${classes.stats_box}`}>
						<div className={classes.edit_wrap}>
							<div className={classes.edit_input}>
								{edit_name_details.open ? (
									<>
										<OutlinedTextField
											value={edit_name_details.name}
											textStyle={{ padding: 8, border: `2px solid ${theme.colors.primary}` }}
											onChange={(e) => set_edit_name_details({ ...edit_name_details, name: e.target.value })}
										/>
										<IconButton onClick={handle_edit_name} className={classes.edit_icon}>
											<img src={ImageLinks.tickCircleSolid} width={20} height={20} />
										</IconButton>
									</>
								) : (
									<>
										<Text medium style={{ fontSize: 16 }}>
											{bundle_label || '-'}
										</Text>
										{bundle_id && (
											<IconButton
												onClick={show_edit_name}
												className={classes.edit_icon}
												style={{
													backgroundColor: theme.colors.lightPurple,
												}}>
												<img src={ImageLinks.edit} width={8} />
											</IconButton>
										)}
									</>
								)}
							</div>
							{!_.get(bundle_data, 'rider_data.id', null) && bundles.length > 0 && (
								<Text bold className={classes.bid_text}>
									{bundle_id}
								</Text>
							)}
							{_.get(bundle_data, 'rider_data.id', null) && (
								<div className={classes.list_item_stats_wrap_2}>
									<div className='d-flex align-items-center' style={{ gap: 3 }}>
										<img src={ImageLinks.rider_light_purple3} height={14} width={14} />
										<Text medium style={{ color: theme.colors.primary, fontSize: 14 }}>
											{`(${_.get(bundle_data, 'rider_data.id', '')}) ${
												_.get(bundle_data, 'rider_data.first_name', '').length > 8
													? _.get(bundle_data, 'rider_data.first_name', '').slice(0, 8) + '..'
													: _.get(bundle_data, 'rider_data.first_name', '')
											}`}
										</Text>
									</div>
									<Button
										type='text'
										size='extra_small'
										text='Unlink'
										onClick={handle_unlink_rider}
										left_icon={ImageLinks.unlink_icon_primary}
										style={{ width: 100 }}
									/>
								</div>
							)}
						</div>

						<div className={classes.stats_wrap}>
							<div style={{ flex: 0.5 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.trips || '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									Trips
								</Text>
							</div>

							<div style={{ flex: 0.5 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.totalVolume || '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									Vol.
								</Text>
							</div>

							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.distanceInMetres ? Math.round(bundle_data.distanceInMetres / 1000) + ' km' : '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									Distance
								</Text>
							</div>

							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.timeInSeconds
										? bundle_data.timeInSeconds < 3600
											? Math.round(bundle_data.timeInSeconds / 60) + ' mins'
											: Math.round(bundle_data.timeInSeconds / 360) / 10 + ' hrs'
										: '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									Time
								</Text>
							</div>

							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 16 }}>
									{bundle_data.totalAmount ? `₹${bundle_data.totalAmount}` : '-'}
								</Text>
								<Text semi className={classes.stats_label}>
									COD
								</Text>
							</div>
						</div>
					</div>
					<div className={classes.fix_seq_wrap}>
						{!_.isEmpty(bundle_data) && bundles.length > 0 && (
							<div className={classes.optimize_wrapper}>
								{sequence_fixed ? (
									<Text bold className={classes.optimize_text}>
										Your route is completely optimized!
									</Text>
								) : (
									<>
										<div>
											<Text bold style={{ color: theme.colors.white }}>
												Complete orders
											</Text>
											<Text bold style={{ color: theme.colors.white }}>
												faster!
											</Text>
										</div>
										<Button
											type='round'
											text='Fix Sequence'
											size='medium'
											onClick={handle_fix_sequence}
											style={{ background: theme.colors.white, color: theme.colors.primary }}
										/>
									</>
								)}
							</div>
						)}
					</div>
				</div>
				<div className={classes.bundle_list_wrap}>
					<div className={classes.table_wrap}>
						<div className={classes.table_header}>
							<Text semi style={{ fontSize: 20 }}>
								Route Sequence
							</Text>
							{show_update_sequence && <Button type='round' size='extra_small' text='Update Sequence' onClick={handle_update_sequence} />}
						</div>
						<div style={{ flex: 1, overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
							<div className={classes.table_head}>
								<Text bold style={{ flex: 1, color: theme.colors.darkGrey2 }}>
									#
								</Text>

								<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
									PBID
								</Text>

								<Text bold style={{ flex: 3, color: theme.colors.darkGrey2 }}>
									Receiver
								</Text>

								<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
									COD
								</Text>

								<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
									Volume
								</Text>

								<div style={{ flex: 1 }}></div>
							</div>

							<div
								className={classes.drag_list}
								onDrop={(e) => {
									e.preventDefault();
								}}
								onDragOver={(e) => {
									e.preventDefault();
								}}>
								<div>
									{_.map(bundle_data.tripData, (trip, key) => {
										const simulation_delay =
											simulation_mcp_data.length > 0 ? _.filter(simulation_mcp_data, (item) => item.mcp_id == trip.pbid)[0] : {};

										return (
											<div
												className={classes.trip_row}
												style={{
													borderTop: key === show_border && key < dragItem.current ? '2px solid ' + theme.colors.primary : 'none',
													borderBottom:
														key === show_border && key > dragItem.current
															? '2px solid ' + theme.colors.primary
															: '1px solid ' + theme.colors.lightGrey5,
													backgroundColor: _.includes(selected_trip_ids, trip.pbid) ? theme.colors.lightPurple9 : '',
												}}
												draggable={true}
												onClick={() => handle_select_trip(trip.pbid)}
												key={key}
												onDragStart={(e) => {
													dragStart(e, key, trip);
												}}
												onDragEnter={(e) => dragEnter(e, key)}
												onDragEnd={drop}>
												<div style={{ flex: 1 }}>
													{false ? (
														<Text
															bold
															component={'span'}
															style={{
																color: theme.colors.primary,
																fontSize: 28,
															}}>
															=
														</Text>
													) : (
														<div className={classes.key_item_wrap}>
															<Text bold component={'span'} className={classes.key_item_text}>
																{key + 1}
															</Text>
														</div>
													)}
												</div>
												<div className='d-flex align-items-center' style={{ flex: 2 }}>
													<Text
														bold
														className={classes.pbid_text_highlighted}
														onClick={() => set_open_pbid_modal(trip.external_order_id)}>
														{trip.pbid}
													</Text>
													<BootstrapTooltip
														is_white={true}
														position='top'
														title={
															<div>
																<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.red }}>
																	{simulation_delay?.delay_by > 300 ? `>300 mins` : `~${simulation_delay?.delay_by}`}
																</Text>
																<Text
																	component={'span'}
																	bold
																	style={{ fontSize: 12, color: theme.colors.lightGrey2 }}>{` | `}</Text>
																<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.lightPurple7 }}>
																	{simulation_delay?.delivery_slot}
																</Text>
															</div>
														}>
														<div>{_.includes(mcp_list, trip.pbid) && <div className={classes.red_dot_small} />}</div>
													</BootstrapTooltip>
												</div>

												<Text bold style={{ flex: 3, color: theme.colors.darkGrey2 }}>
													{trip.receiver_details.name}
												</Text>

												<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
													{trip.amount}
												</Text>

												<Text bold style={{ flex: 2, color: theme.colors.darkGrey2 }}>
													{trip.volume}
												</Text>

												<div style={{ flex: 1, visibility: 'hidden', position: 'relative' }} className='cross'>
													<IconButton
														style={{ borderColor: 'transparent' }}
														onClick={() => {
															if (premium_features_blocked) {
																handle_show_premium_feature();
																return;
															}
															set_confirm_remove_squence_details({
																open: true,
																trip_ids: [trip.trip_id],
															});
														}}>
														<img src={ImageLinks.crossRed} width='10' style={{ cursor: 'pointer' }} />
													</IconButton>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>

					<div className={classes.right_section}>
						<div className={classes.map_wrap}>
							<div id='map_canvas_address' className='map-canvas-wrapper rider-location card' style={{ height: '100%' }} />
						</div>
						{!_.isEmpty(bundle_data) && bundles.length > 0 && (
							<>
								<div className={classes.btn_wrap}>
									<IconButton
										onClick={() => {
											utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_UNROUTE);
											if (premium_features_blocked) {
												handle_show_premium_feature();
												return;
											}
											set_confirm_remove_squence_details({
												open: true,
												trip_ids: _.map(bundle_data.tripData, 'trip_id'),
											});
										}}
										style={{ backgroundColor: theme.colors.lightRed, borderColor: 'transparent', height: 40, width: 40, marginRight: 20 }}>
										<img height={20} width={20} src={ImageLinks.delete} />
									</IconButton>

									<Button
										type='half_round'
										text='Allocate Rider'
										size='large'
										right_icon={ImageLinks.arrow_right_white_no_fill}
										onClick={() => {
											utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_ALLOCATE_RIDER);
											if (premium_features_blocked) {
												handle_show_premium_feature();
												return;
											}
											set_allocate_rider_details({
												open: true,
												bundle_id,
												tripData: bundle_data.tripData,
											});
										}}
										style={{ color: theme.colors.white }}
									/>

									{open_pbid_modal && (
										<OrderModal
											id={open_pbid_modal}
											close={() => set_open_pbid_modal(null)}
											updater={() => {
												set_refresh((val) => !val);
											}}
											alt=''
										/>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{confirm_remove_squence_details.open && (
				<ConfirmClose
					style={{ width: 600 }}
					close={() => set_confirm_remove_squence_details({ ...initial_confirm_remove_squence_details })}
					on_confirm={handle_confirm_close}
					headerTitle={'Remove Order from Route'}
					title={`Are you sure you want to remove ${
						confirm_remove_squence_details.trip_ids.length > 1 ? confirm_remove_squence_details.trip_ids.length : 'this'
					} order from the sequence?`}
					titleStyles={{ width: '100%' }}
				/>
			)}

			{confirm_save_for_later.open && (
				<ConfirmClose
					style={{ width: 600 }}
					close={() => set_confirm_save_for_later({ open: false })}
					on_confirm={() => history.push(Routes.POWERED_WITH_PIDGE.path)}
					headerTitle={'Save for Later'}
					title={
						<Text medium className={classes.modal_text}>
							Are you sure you want to save route for later?
						</Text>
					}
					titleStyles={{ width: '100%' }}
				/>
			)}
		</>
	);
};

export default UnallocatedRoutes;
