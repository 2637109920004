import React, { useEffect } from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Amount from '../../../modules/Amount';
import moment from 'moment';
import CollectionHistory from './components/CollectionHistory';
import { OVERVIEW_TYPES } from '../helper';
import SettleHistory from './components/SettleHistory';
import DepositHistory from './components/DepositHistory';
import { ListItem } from '@material-ui/core';
import api_requests from 'resources/api_requests';
import TransactionHistory from '../components/TransactionHistory';
import Routes from 'resources/Routes';

const CM_MyOverview = ({ history }) => {
	const [account_info, set_account_info] = React.useState({});

	const [refresh, set_refresh] = React.useState(false);
	const [show_transaction_history, set_show_transaction_history] = React.useState(false);

	useEffect(() => {
		api_requests.get_cm_account_info().then((res) => set_account_info(res.data));
	}, []);

	useEffect(() => {
		set_refresh(!refresh);
	}, [history.location.search]);

	let type = OVERVIEW_TYPES.COLLECT;

	switch (_.split(history.location.search, 'type=')[1]) {
		case OVERVIEW_TYPES.DEPOSIT:
			type = OVERVIEW_TYPES.DEPOSIT;
			break;

		case OVERVIEW_TYPES.SETTLE:
			type = OVERVIEW_TYPES.SETTLE;
			break;

		default:
			break;
	}

	const change_type = (type) => {
		history.push(Routes.CASH_MANAGEMENT_MY_OVERVIEW.path + `?type=${type}`);
	};

	if (_.isEmpty(account_info)) {
		return null;
	}

	return (
		<div className='container' style={{ height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex' }}>
					<Text
						regular
						className={'global_header_text'}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							history.push(Routes.CASH_MANAGEMENT.path);
						}}>
						Cash Management
					</Text>
					<div className='header_separator' style={{ marginTop: 6 }}></div>
					<Text semi className={'header_active_title'}>
						My Overview
					</Text>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 32 }}>
				<div>
					<Amount
						amount={_.get(account_info, 'balance', 0)}
						main_text_styles={{ color: account_info.balance < 0 ? theme.colors.green : theme.colors.red, fontSize: 24 }}
						sub_text_styles={{ color: account_info.balance < 0 ? theme.colors.green : theme.colors.red, fontSize: 12 }}
					/>
					<Text bold style={{ fontSize: 12 }}>
						Total Outstanding
					</Text>
				</div>
				<div style={{ display: 'flex' }}>
					<ListItem
						button
						className='box-shadow'
						onClick={() => change_type(OVERVIEW_TYPES.COLLECT)}
						style={{
							background: type === OVERVIEW_TYPES.COLLECT ? theme.colors.lightPurple : theme.colors.white,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '10px 15px',
							borderRadius: 10,
							marginLeft: 10,
							width: 190,
							alignItems: 'flex-start',
						}}>
						<Amount
							amount={_.get(account_info, 'stats.today.credit', 0)}
							main_text_styles={{ color: theme.colors.darkGrey2, fontSize: 20 }}
							sub_text_styles={{ color: theme.colors.darkGrey2, fontSize: 12 }}
						/>
						<Text semi style={{ fontSize: 10, marginTop: 5 }}>
							Collected {moment().format('DD/MM/YY')}
						</Text>
					</ListItem>
					<ListItem
						button
						className='box-shadow'
						onClick={() => change_type(OVERVIEW_TYPES.DEPOSIT)}
						style={{
							background: type === OVERVIEW_TYPES.DEPOSIT ? theme.colors.lightPurple : theme.colors.white,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '10px 15px',
							borderRadius: 10,
							marginLeft: 10,
							width: 190,
							alignItems: 'flex-start',
						}}>
						<Amount
							amount={_.get(account_info, 'stats.today.debit', 0)}
							main_text_styles={{ color: theme.colors.darkGrey2, fontSize: 20 }}
							sub_text_styles={{ color: theme.colors.darkGrey2, fontSize: 12 }}
						/>
						<Text semi style={{ fontSize: 10, marginTop: 5 }}>
							Deposited {moment().format('DD/MM/YY')}
						</Text>
					</ListItem>
					<ListItem
						button
						className='box-shadow'
						onClick={() => change_type(OVERVIEW_TYPES.SETTLE)}
						style={{
							background: type === OVERVIEW_TYPES.SETTLE ? theme.colors.lightPurple : theme.colors.white,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '10px 15px',
							borderRadius: 10,
							marginLeft: 10,
							width: 190,
							alignItems: 'flex-start',
						}}>
						<Amount
							amount={_.get(account_info, 'stats.today.settle', 0)}
							main_text_styles={{ color: theme.colors.darkGrey2, fontSize: 20 }}
							sub_text_styles={{ color: theme.colors.darkGrey2, fontSize: 12 }}
						/>
						<Text semi style={{ fontSize: 10, marginTop: 5 }}>
							Settled {moment().format('DD/MM/YY')}
						</Text>
					</ListItem>
					<ListItem
						button
						className='box-shadow'
						onClick={() => set_show_transaction_history(true)}
						style={{
							background: theme.colors.white,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '10px 15px',
							borderRadius: 10,
							marginLeft: 10,
							width: 190,
							alignItems: 'flex-start',
						}}>
						<Text>Transaction History</Text>
					</ListItem>
				</div>
			</div>
			{type === OVERVIEW_TYPES.COLLECT && <CollectionHistory />}
			{type === OVERVIEW_TYPES.DEPOSIT && <DepositHistory />}
			{type === OVERVIEW_TYPES.SETTLE && <SettleHistory />}

			{show_transaction_history && <TransactionHistory close={() => set_show_transaction_history(false)} />}
		</div>
	);
};

export default CM_MyOverview;
