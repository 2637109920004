import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import theme from 'resources/theme';
import CreateNetworkUpperSection from './components/CreateNetworkUpperSection';
import Header from './components/Header';
import CreateNetworkLowerSection from './components/CreateNetworkLowerSection';
import SuccessModal from './components/SuccessModal';
import CreateNetworkContext from '../context';
import ConfirmClose from 'modules/modals/ConfirmClose';
import qs from 'qs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
	container: {
		height: '100%',
		paddingBottom: 50,
	},
	separator: { border: `1px solid ${theme.colors.lightGrey2}`, marginTop: 20, marginBottom: 20 },
}));

const CreateNetwork = () => {
	const {
		network_name,
		show_success_modal,
		set_show_success_modal,
		handle_success,
		confirm_close_modal,
		handle_confirm_modal_close,
		create_api_state_status,
		set_edit_network_flow,
		edit_network_id,
		set_edit_network_id,
	} = useContext(CreateNetworkContext);

	const classes = useStyles();
	const history = useHistory();
	const { id } = qs.parse(history.location.search.slice(1));

	useEffect(() => {
		if (id) set_edit_network_flow(id);
		return () => {
			set_edit_network_id(null);
		};
	}, [id]);

	return (
		<div className={`container ${classes.container}`}>
			<Header />
			<div style={{ overflowY: 'auto', height: '100%' }}>
				<CreateNetworkUpperSection />
				<div className={classes.separator}></div>
				<CreateNetworkLowerSection />
			</div>

			{show_success_modal && (
				<SuccessModal
					name={network_name}
					create_api_state_status={create_api_state_status}
					close={() => set_show_success_modal(false)}
					on_sucess={handle_success}
				/>
			)}
			{confirm_close_modal.open && (
				<ConfirmClose
					headerTitle={confirm_close_modal.header_title}
					title={confirm_close_modal.title}
					close={handle_confirm_modal_close}
					positive_btn_text={confirm_close_modal.positive_text}
					negative_btn_text={confirm_close_modal.negative_text}
					titleStyles={{ fontSize: 16, textAlign: 'left' }}
					on_confirm={confirm_close_modal.confirm_acion}
					is_error_msg={confirm_close_modal.is_error_msg}
					modal_icon={confirm_close_modal.modal_icon}
				/>
			)}
		</div>
	);
};

export default CreateNetwork;
