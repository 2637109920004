import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import Text from 'modules/Text';

const initial_confirm_modal_details = {
	open: false,
	header_title: '',
	title: '',
	positive_text: '',
	negative_text: '',
	is_error_msg: false,
	confirm_acion: () => {},
};

const useCreateCommunication = () => {
	const history = useHistory();
	let { id: comm_id, flow: source_flow } = qs.parse(history.location.search.slice(1));
	const [selected_subject, set_selected_subject] = useState(null);
	const [show_usecase, set_show_usecase] = useState(false);
	const [selected_usecase, set_selected_usecase] = useState(null);
	const [hover_usecase, set_hover_usecase] = useState(null);
	const [selected_sub_usecase, set_selected_sub_usecase] = useState(null);
	const [selected_recipient, set_selected_receipient] = useState(-1);
	const [selected_action, set_selected_action] = useState(-1);
	const [selected_template, set_selected_template] = useState('default');
	const [triggers_list, set_triggers_list] = useState([]);
	const [event_detail, set_event_detail] = useState(null);
	const [value_changed, set_value_changed] = useState([]);
	const [teams, set_teams] = useState([]);
	const [confirm_close_modal, set_confirm_close_modal] = useState(_.cloneDeep(initial_confirm_modal_details));
	const _original_event_detail = useRef(null);
	const timer_ref = useRef(null);

	useEffect(() => {
		get_teams();
		if (comm_id) {
			get_configuration();
		}
		return () => {
			clearTimeout(timer_ref.current);
		};
	}, []);

	const get_configuration = async (key) => {
		try {
			const response = await api_requests.get_comm_triggers_event_detail(comm_id);
			if (response.success) {
				const copied_event = _.cloneDeep(response.data);
				const recipients_arr = _.get(response, 'data.recipients', []);
				if (recipients_arr.length > 0 && source_flow === 'create') {
					const old_value = copied_event.recipients[0]?.channels?.[0]?.active;
					if (!old_value && _.get(copied_event, 'recipients.0.channels', []).length > 0) {
						copied_event.recipients[0].channels[0].active = true;
						timer_ref.current = setTimeout(() => {
							update_value_changed(0);
						}, 0);
					}
				}
				set_event_detail(copied_event);
				set_selected_subject(response.data.subject);
				set_selected_usecase({ title: 'Alert' });
				set_selected_sub_usecase({ title: response.data.name });
				_original_event_detail.current = response.data;
				if (recipients_arr.length === 1) {
					set_selected_receipient(0);
					if (recipients_arr[0]?.channels?.length === 1) set_selected_action(0);
				}
			}
		} catch (error) {}
	};

	const get_teams = async () => {
		try {
			const response = await api_requests.get_team_list();
			if (response.success && _.get(response, 'data.data', []).length > 0) set_teams(response.data.data);
		} catch (error) {}
	};

	const handle_selected_subject = (value, is_disabled) => {
		if (!_.isEmpty(comm_id) || is_disabled) return;
		set_selected_subject(value);
		set_show_usecase(false);
		set_hover_usecase(null);
		set_selected_usecase(null);
		set_selected_sub_usecase(null);
		set_selected_receipient(-1);
		set_selected_action(-1);
		set_triggers_list([]);
		set_event_detail(null);
		_original_event_detail.current = null;
		fetch_triggers(value);
	};

	const handle_show_usecase = (value) => {
		set_show_usecase(value);
	};

	const handle_selected_usecase = (value) => {
		set_selected_usecase(value);
	};

	const handle_selected_sub_usecase = (value) => {
		set_selected_sub_usecase(value);
	};

	const handle_value_changed = (value) => {
		set_value_changed(value);
	};

	const handle_hover_usecase = (value) => {
		set_hover_usecase(value);
	};

	const handle_selected_recipient = (value, any_changed_item) => {
		if (any_changed_item) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Discard Changes',
				title: `You have made changes but you have not saved them. Are you sure you want to discard these changes?`,
				positive_text: 'Discard',
				negative_text: 'Cancel',
				is_error_msg: true,
				confirm_acion: () => {
					set_event_detail(_.cloneDeep(_original_event_detail.current));
					set_value_changed(_.map(value_changed, (item) => false));
				},
			});
			return;
		}
		set_selected_receipient(value);
		set_selected_action(-1);
	};

	const handle_action = (value) => {
		set_selected_action(value);
	};

	const handle_selected_template = (value) => {
		set_selected_template(value);
	};

	const handle_confirm_close_modal = () => {
		set_confirm_close_modal(_.cloneDeep(initial_confirm_modal_details));
	};

	const handle_trigger_press = () => {
		if (!_.isEmpty(comm_id) || selected_subject === null) return;
		set_show_usecase((prev) => !prev);
	};

	const handle_action_toggle = () => {
		let current_active = event_detail.recipients[selected_recipient].channels[selected_action].active;
		const is_valued_already_changed = _.includes(value_changed, true);

		if (current_active) {
			if (is_valued_already_changed) {
				handle_channel_deactivate(false);
				return;
			}
			const recipient_name = event_detail.recipients[selected_recipient].name;
			const channel_name = constant.COMM_CHANNEL_NAME_MAP[event_detail.recipients[selected_recipient].channels[selected_action].channel];
			set_confirm_close_modal({
				open: true,
				header_title: 'Deactivate Communication to Selected Recipient',
				title: (
					<Text medium style={{ fontSize: 14 }}>
						Are you sure you want to deactivate
						<Text bold component={'span'} style={{ fontSize: 14 }}>
							{` ${channel_name} `}
						</Text>
						communication for
						<Text bold component={'span'} style={{ fontSize: 14 }}>
							{` ${recipient_name}`}
						</Text>
						?
					</Text>
				),

				positive_text: 'Deactivate',
				negative_text: 'Cancel',
				is_error_msg: true,
				confirm_acion: () => handle_channel_deactivate(false),
			});
			return;
		}
		handle_channel_deactivate(true);
	};

	const handle_channel_deactivate = (value) => {
		const copied_event = _.cloneDeep(event_detail);
		copied_event.recipients[selected_recipient].channels[selected_action].active = value;

		set_event_detail(copied_event);
		update_value_changed();
	};

	const handle_team_change = (v) => {
		const copied_event = _.cloneDeep(event_detail);
		const selected_team = copied_event.recipients[selected_recipient].channels[selected_action].value.to;
		let key_arr = [...(selected_team || [])];
		const index = _.indexOf(key_arr, v);
		if (index >= 0) {
			key_arr.splice(index, 1);
		} else {
			key_arr.push(v);
		}
		if (key_arr.length === 0) key_arr = [];
		copied_event.recipients[selected_recipient].channels[selected_action].value.to = key_arr;

		set_event_detail(copied_event);
		update_value_changed();
	};

	const update_value_changed = (index = -1) => {
		const _value_changed = _.cloneDeep(value_changed);
		_value_changed[index >= 0 ? index : selected_action] = true;
		handle_value_changed(_value_changed);
	};

	const fetch_triggers = async (subject) => {
		try {
			const response = await api_requests.get_comm_triggers_for_subject(subject);
			if (response.success && _.get(response, 'data.items', []).length > 0) set_triggers_list(response.data.items);
			else set_triggers_list([]);
		} catch (error) {}
	};

	const save_recipient_detail = async () => {
		try {
			const payload = {
				...event_detail,
				recipients: event_detail.recipients,
			};
			delete payload.id;
			delete payload.name;
			delete payload.owner;
			const response = await api_requests.update_comm_recipient(event_detail.name, payload);
			if (response.success) {
				_original_event_detail.current = event_detail;
				const copied_value_arr = _.cloneDeep(value_changed);
				for (let i = 0; i < value_changed.length; i++) {
					copied_value_arr[i] = false;
				}
				set_value_changed(copied_value_arr);
			}
		} catch (error) {}
	};

	const on_usecase_select = async (usecase, sub_usecase) => {
		set_show_usecase(false);
		set_selected_usecase(usecase);
		set_selected_sub_usecase(sub_usecase);
		set_event_detail(null);
		_original_event_detail.current = null;
		set_selected_receipient(-1);
		set_selected_action(-1);

		try {
			const response = await api_requests.get_comm_triggers_event_detail(sub_usecase.event);
			if (response.success) {
				const recipients = _.get(response, 'data.recipients', []);
				set_event_detail(response.data);
				_original_event_detail.current = response.data;
				if (recipients.length === 1) {
					set_selected_receipient(0);
					if (recipients[0]?.channels?.length === 1) set_selected_action(0);
				}
			}
		} catch (error) {}
	};

	const on_header_nav = () => {
		if (_.isEmpty(comm_id)) {
			history.goBack();
		} else {
			history.push(Routes.MY_ALERTS.path);
		}
	};

	return {
		selected_subject,
		show_usecase,
		selected_usecase,
		selected_sub_usecase,
		hover_usecase,
		selected_recipient,
		selected_action,
		selected_template,
		triggers_list,
		event_detail,
		_original_event_detail: _original_event_detail.current,
		value_changed,
		confirm_close_modal,
		teams,
		comm_id,
		handle_selected_subject,
		handle_show_usecase,
		handle_selected_usecase,
		handle_selected_sub_usecase,
		on_usecase_select,
		handle_trigger_press,
		handle_hover_usecase,
		handle_selected_recipient,
		handle_action,
		handle_selected_template,
		save_recipient_detail,
		handle_value_changed,
		handle_action_toggle,
		handle_confirm_close_modal,
		handle_team_change,
		on_header_nav,
	};
};

export default useCreateCommunication;
