import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import api_requests from 'resources/api_requests';
import { hide_loader, show_loader } from 'actions/app';
import Routes from 'resources/Routes';
import Text from 'modules/Text';
import { toast } from 'react-toastify';

const initial_confirm_modal_details = {
	open: false,
	header_title: '',
	title: '',
	positive_text: '',
	negative_text: '',
	confirm_acion: () => {},
	is_error_msg: false,
	modal_icon: null,
};

const digit_regex = /^\d+$/;

const useCreateAlert = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	let { id: alert_id } = qs.parse(history.location.search.slice(1));
	const [selected_subject, set_selected_subject] = useState(null);
	const [usecases, set_usecases] = useState([]);
	const [alert_detail, set_alert_detail] = useState(null);
	const [selected_usecase, set_selected_usecase] = useState(null);
	const [show_usecase, set_show_usecase] = useState(false);
	const [selected_trigger, set_selected_trigger] = useState({});
	const [is_active, set_is_active] = useState(true);
	const [is_deleted, set_is_deleted] = useState(false);
	const [alert_frequency, set_alert_frequency] = useState('');
	const [confirm_close_modal, set_confirm_close_modal] = useState(_.cloneDeep(initial_confirm_modal_details));
	const [alias, set_alias] = useState('');
	const [teams, set_teams] = useState([]);
	const [is_saved_press, set_is_saved_press] = useState(false);
	const [timer, set_timer] = useState(3);
	const [is_alias_valid, set_is_alias_valid] = useState(null);
	const [is_updated, set_is_updated] = useState(null);
	const _original_alert_detail = useRef(null);
	const timer_ref = useRef(null);
	const usecase_ref = useRef(null);
	const unblock_ref = useRef(null);
	const saved_press_ref = useRef(false);
	const alias_search_ref = useRef(false);
	const is_updated_ref = useRef(false);

	useEffect(() => {
		if (alert_id) {
			get_configuration();
		}
		unblock_ref.current = history.block(handle_page_nav);
		return () => {
			unblock_ref.current();
		};
	}, []);

	useEffect(() => {
		if (timer === 0) {
			clearInterval(timer_ref.current);
			history.push(alert_detail.communication.enabled ? Routes.CREATE_COMMUNICATION.path + `?id=${alias}&flow=create` : Routes.MY_ALERTS.path);
		}
	}, [timer]);

	useEffect(() => {
		if (alert_detail === null || _original_alert_detail.current === null) return;
		if (alert_frequency != alert_detail.frequency) {
			set_is_updated(true);
			is_updated_ref.current = true;
			return;
		}
		const updated_values = data_diff(alert_detail, _original_alert_detail.current, selected_trigger);
		const value = !_.isEmpty(updated_values);
		set_is_updated(value);
		is_updated_ref.current = value;
	}, [alert_frequency, selected_trigger, alert_detail]);

	const data_diff = (new_value, original_value, selected_trigger) => {
		const only_updated_values = {};
		if (new_value.communication.enabled != original_value.communication.enabled) only_updated_values.communication = new_value.communication;

		let action_updated = false;
		let options_updated = false;
		const new_options = selected_trigger || {};
		const org_options = original_value.options || {};
		for (const key in new_options) {
			if (new_options[key] != org_options[key]) {
				options_updated = true;
				break;
			}
		}
		if (options_updated) only_updated_values.options = { ...new_options };
		for (let i = 0; i < new_value.actions.length; i++) {
			const new_action_item = new_value.actions[i];
			const old_action_item = original_value.actions[i];
			if (new_action_item.trigger != old_action_item.trigger) action_updated = true;
			if (new_action_item.active != old_action_item.active) action_updated = true;
			if (new_action_item.config.to.join(',') != old_action_item.config.to.join(',')) action_updated = true;
		}
		if (action_updated) only_updated_values.actions = [...new_value.actions];
		return only_updated_values;
	};

	const handle_page_nav = (location, action) => {
		const show_confirmation = saved_press_ref.current ? false : _.isEmpty(alert_id) ? usecase_ref.current !== null : is_updated_ref.current;
		if (show_confirmation) {
			set_confirm_close_modal({
				open: true,
				header_title: _.isEmpty(alert_id) ? 'Exit Alert Creation' : 'Discard Changes',
				title: _.isEmpty(alert_id)
					? 'Are you sure you want abandon creating a new alert? No progress will be saved.'
					: 'You have made changes but you have not saved them. Are you sure you want to discard these changes?',
				positive_text: _.isEmpty(alert_id) ? 'Exit' : 'Discard',
				negative_text: 'Cancel',
				is_error_msg: true,
				confirm_acion: () => {
					unblock_ref.current();
					history.push(location.pathname);
					return true;
				},
			});
			return false;
		}
		return true;
	};

	const on_saved_tab_clicked = () => {
		clearInterval(timer_ref.current);
		history.push(alert_detail.communication.enabled ? Routes.CREATE_COMMUNICATION.path + `?id=${alias}&flow=create` : Routes.MY_ALERTS.path);
	};

	const get_teams = async () => {
		try {
			const response = await api_requests.get_team_list();
			if (response.success && _.get(response, 'data.data', []).length > 0) set_teams(response.data.data);
		} catch (error) {}
	};

	const on_usecase_select = (usecase) => {
		// set_usecases([]);
		set_alert_detail(null);
		set_show_usecase(false);
		set_selected_trigger({});
		set_alert_frequency('');
		set_alias('');
		set_is_alias_valid(null);
		_original_alert_detail.current = null;
		set_selected_usecase(usecase);
		usecase_ref.current = usecase;
		fetch_usecase_detail(selected_subject, usecase.type);
		get_teams();
	};

	const get_configuration = async (key) => {
		dispatch(show_loader());
		try {
			const response = await api_requests.get_alert_configuration(alert_id);
			dispatch(hide_loader());
			if (response.success) {
				set_alert_detail(response.data);
				_original_alert_detail.current = response.data;
				set_selected_trigger(response.data.options);
				set_selected_subject(response.data.subject);
				set_selected_usecase({ title: response.data.title, type: response.data.type });
				usecase_ref.current = { title: response.data.title, type: response.data.type };
				set_alert_frequency(response.data.frequency);
				set_alias(response.data.alias);
				set_is_active(response.data.active);
				set_is_alias_valid(true);
				get_teams();
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const on_subject_select = (item) => {
		if (alert_id) return;
		set_usecases([]);
		set_alert_detail(null);
		set_selected_usecase(null);
		set_show_usecase(false);
		set_selected_trigger({});
		set_alert_frequency('');
		set_alias('');
		set_is_alias_valid(null);
		_original_alert_detail.current = null;
		get_usecase(item);
		set_selected_subject(item);
	};

	const get_usecase = async (key) => {
		dispatch(show_loader());
		try {
			const response = await api_requests.get_alert_usecase(key);
			dispatch(hide_loader());
			if (response.success) set_usecases(response.data.items);
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const fetch_usecase_detail = async (subject, type) => {
		dispatch(show_loader());
		try {
			const response = await api_requests.get_usecase_detail(subject, type);
			dispatch(hide_loader());

			if (response.success) {
				set_alert_detail(response.data);
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const handle_action_select = (param, v, is_multi) => {
		if (!is_multi) {
			set_selected_trigger({ ...selected_trigger, [param]: v });
			return;
		}
		let key_arr = [...(selected_trigger[param] || [])];
		const index = _.indexOf(key_arr, v);
		if (index >= 0) {
			key_arr.splice(index, 1);
		} else {
			key_arr.push(v);
		}
		if (key_arr.length === 0) key_arr = null;
		set_selected_trigger({ ...selected_trigger, [param]: key_arr });
	};

	const create_alert = async () => {
		let err_msg = '';

		const input_triggers = _.get(alert_detail, 'triggers', []);
		for (let i = 0; i < input_triggers.length; i++) {
			const input = input_triggers[i];
			if (input.type === 'text') {
				const filled_value = selected_trigger[input.param];
				if (input.validation === 'number') {
					if (!digit_regex.test(filled_value)) err_msg = `${input.title} format incorrect`;
				} else if (input.validation) {
					const new_reg = new RegExp(input.validation);
					if (!new_reg.test(filled_value)) err_msg = `${input.title} format incorrect`;
				}
			}
		}

		if (!_.isEmpty(err_msg)) {
			toast.error(err_msg, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}
		const payload = {
			subject: selected_subject,
			type: selected_usecase.type,
			frequency: alert_frequency,
			options: selected_trigger,
			communication: _.cloneDeep(alert_detail.communication),
			actions: _.cloneDeep(alert_detail.actions),
			alias,
			title: alert_detail.title,
		};
		const _payload = _.cloneDeep(payload);
		if (alert_id) {
			delete _payload.subject;
			delete _payload.alias;
			delete _payload.type;
			delete _payload.title;
			if (_payload.communication.enabled === _original_alert_detail.current.communication.enabled) delete _payload.communication;
			if (_payload.frequency == _original_alert_detail.current.frequency) delete _payload.communication;
			if (_.isEmpty(_payload)) {
				history.goBack();
				return;
			}
		}
		try {
			dispatch(show_loader());
			const response = alert_id ? await api_requests.edit_alert(alert_id, _payload) : await api_requests.configure_alert(payload);
			if (response.success) {
				timer_ref.current = setInterval(() => {
					set_timer((prev) => prev - 1);
				}, 1000);
				set_is_saved_press(true);
				saved_press_ref.current = true;
				dispatch(hide_loader());
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const close_confirm_modal = () => {
		set_confirm_close_modal(_.cloneDeep(initial_confirm_modal_details));
	};

	const handle_status_change = (val) => {
		let confirm_desc =
			val === 'deleted' ? (
				<Text medium style={{ fontSize: 14 }}>
					Are you sure you want to delete
					<Text component={'span'} bold style={{ fontSize: 14 }}>
						{` ${alias}`}
					</Text>
					? This action cannot be undone.
				</Text>
			) : val === true ? (
				<Text medium style={{ fontSize: 14 }}>
					Are you sure you want to active
					<Text component={'span'} bold style={{ fontSize: 14 }}>
						{` ${alias}`}
					</Text>
					? You will start receiving any notifications.
				</Text>
			) : (
				<Text medium style={{ fontSize: 14 }}>
					Are you sure you want to pause
					<Text component={'span'} bold style={{ fontSize: 14 }}>
						{` ${alias}`}
					</Text>
					? You will stop receiving any notifications.
				</Text>
			);
		set_confirm_close_modal({
			open: true,
			header_title: `${val === 'deleted' ? 'Delete' : val === true ? 'Activate' : 'Pause'} Alert`,
			title: confirm_desc,
			positive_text: val === 'deleted' ? 'Delete' : val === true ? 'Activate' : 'Confirm',
			negative_text: 'Cancel',
			is_error_msg: val === 'deleted',
			confirm_acion: () => change_confirmatory_alert_status(val),
			...(val !== 'deleted' && { modal_icon: val === true ? ImageLinks.play_green_ripple : ImageLinks.pause_yellow_ripple }),
		});
	};

	const change_confirmatory_alert_status = async (val) => {
		if (val === 'deleted') {
			try {
				const response = await api_requests.delete_alert(alert_id);
				if (response.success) {
					set_is_deleted(true);
					history.goBack();
				}
			} catch (error) {}
		} else {
			try {
				const response = await api_requests.edit_alert(alert_id, { active: val });
				if (response.success) {
					set_is_active(val);
				}
			} catch (error) {}
		}
	};

	const handle_action_toggle = (key) => {
		const copied_alert = _.cloneDeep(alert_detail);
		const old_value = copied_alert.actions[key].active;
		copied_alert.actions[key].active = !old_value;
		if (old_value) {
			copied_alert.actions[key].trigger = 'Auto';
			copied_alert.actions[key].config.to = [];
		}
		set_alert_detail(copied_alert);
	};

	const handle_action_team_change = (action_type, team, key) => {
		const copied_alert = _.cloneDeep(alert_detail);
		copied_alert.actions[key].trigger = action_type;
		copied_alert.actions[key].config.to = team;
		set_alert_detail(copied_alert);
	};

	const navigate_to_comm = () => {
		history.push(Routes.CREATE_COMMUNICATION.path + `?id=${alert_detail.alias}`);
	};

	const fetch_alises = (value) => {
		if (value.length > 30) return;
		set_alias(value);
		clearTimeout(alias_search_ref.current);
		var pattern = /^[-_a-zA-Z0-9]*$/;
		const is_valid = pattern.test(value);
		if (!is_valid) {
			set_is_alias_valid(false);
			return;
		}

		if (!value) return;

		alias_search_ref.current = setTimeout(async () => {
			const response = await api_requests.get_configured_alerts({ alias: value });

			if (response.success && _.get(response, 'data', []).length === 0) set_is_alias_valid(true);
			else set_is_alias_valid(false);
		}, 500);
	};

	const handle_back = () => {
		history.goBack();
	};

	const handle_alert_freq = (e) => {
		const value = e.target.value;
		const pattern = /^\d+$/;
		if (pattern.test(value) || value == '') set_alert_frequency(value);
	};

	return {
		selected_subject,
		usecases,
		alert_detail,
		selected_usecase,
		show_usecase,
		selected_trigger,
		is_active,
		is_deleted,
		alert_frequency,
		confirm_close_modal,
		alias,
		teams,
		alert_id,
		is_saved_press,
		timer,
		is_alias_valid,
		is_updated,
		set_alias,
		set_alert_frequency,
		set_selected_trigger,
		set_show_usecase,
		set_alert_detail,
		on_usecase_select,
		on_subject_select,
		handle_action_select,
		create_alert,
		close_confirm_modal,
		handle_status_change,
		handle_action_toggle,
		on_saved_tab_clicked,
		navigate_to_comm,
		fetch_alises,
		handle_back,
		handle_alert_freq,
		handle_action_team_change,
	};
};

export default useCreateAlert;
