import React, { useEffect } from 'react';
import _ from 'lodash';
import { Card, ListItem } from '@material-ui/core';

import Text from 'modules/Text';
import theme from 'resources/theme';
import OutlinedButton from 'modules/OutlinedButton';
import Amount from '../../../modules/Amount';
import PendingRequest from './components/PendingRequest';
import ManualEntry from './components/ManualEntry';
import SettleCash from './components/SettleCash';
import TransactionHistory from '../components/TransactionHistory';
import TransferCash from './components/TransferCash';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import api_requests from 'resources/api_requests';
import { OVERVIEW_TYPES } from '../helper';
import Routes from 'resources/Routes';
import utils from 'resources/utils';
import constant from 'resources/constant';
import Button from 'modules/Button';

const CashManagementDashboard = ({ history }) => {
	const [data, set_data] = React.useState({});
	const [account_info, set_account_info] = React.useState({});
	const [requests, set_requests] = React.useState([]);

	const [show_pending_request, set_show_pending_request] = React.useState(false);
	const [pending_request_refresh, set_pending_request_refresh] = React.useState(false);

	const [show_manual_entry, set_show_manual_entry] = React.useState(false);
	const [show_transfer_cash, set_show_transfer_cash] = React.useState(false);
	const [show_settle_cash, set_show_settle_cash] = React.useState(false);
	const [show_transaction_history, set_show_transaction_history] = React.useState(false);

	const [refresh_data, set_refresh_data] = React.useState(false);

	const type_of_owner = utils._retrieveData(constant.TYPE_OF_OWNER);

	useEffect(() => {
		api_requests.get_cm_dashboard_stats().then((res) => {
			set_data(res.data);
		});

		api_requests.get_cm_account_info().then((res) => {
			set_account_info(res.data);
		});
	}, [refresh_data]);

	const refresh_stats = () => {
		set_refresh_data(!refresh_data);
	};

	useEffect(() => {
		api_requests.get_cm_transfer_requests().then((res) => {
			set_requests(res.data.records);
		});
	}, [show_pending_request, pending_request_refresh]);

	if (_.isEmpty(data)) {
		return null;
	}

	return (
		<div
			className='container'
			style={{
				height: '100%',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				overflow: 'auto',
			}}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex' }}>
					<Text semi className={'global_header_text'}>
						Cash Management
					</Text>
				</div>

				<div className='d-flex' style={{ gap: 10 }}>
					<OutlinedButton
						onClick={() => {
							set_show_pending_request(true);
						}}
						style={{ borderRadius: 30, borderWidth: 2, borderColor: theme.colors.primary, height: 36, padding: '8px, 12px' }}>
						<Text semi style={{ fontSize: 16, color: theme.colors.primary }}>
							Pending Request
						</Text>
						<Text
							component={'span'}
							semi
							style={{
								width: 16,
								height: 16,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: theme.colors.lightPurple4,
								color: theme.colors.darkPurple,
								borderRadius: '100%',
								fontSize: 16,
								marginLeft: 8,
							}}>
							{requests.length}
						</Text>
					</OutlinedButton>
					<Button type='round' size='large' text='Send Cash' onClick={() => set_show_transfer_cash(true)} />
				</div>
			</div>
			<div
				style={{
					marginTop: 32,
				}}>
				<div style={{ height: 270 }}>
					<Card style={{ height: '100%', width: '35%', borderRadius: 20 }}>
						<div
							style={{
								flex: 3,
								background: theme.colors.primary,
								padding: 30,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '65%',
							}}>
							<Text bold style={{ color: theme.colors.white, fontSize: 26 }}>
								Cash on Ground
							</Text>
							<div>
								<Amount amount={data.total} />
								<Text bold style={{ color: theme.colors.white, fontSize: 12 }}>
									Total outstanding
								</Text>
							</div>
						</div>

						{/* <div style={{ flex: 2, padding: '20px 30px', height: '35%' }}>
							<Amount
								amount='0'
								main_text_styles={{ color: theme.colors.darkGrey2, fontSize: 24 }}
								sub_text_styles={{ color: theme.colors.darkGrey2, fontSize: 12 }}
							/>
							<Text bold style={{ color: theme.colors.lightGrey2, fontSize: 12 }}>
								Pending Approval from Bank
							</Text>
						</div> */}
					</Card>
				</div>
				<div style={{ display: 'flex', marginTop: 30 }}>
					{_.map(
						[
							{
								title: _.get(data, 'stats.rider'),
								subTitle: 'Rider',
								onClick: () => {
									history.push(Routes.CASH_MANAGEMENT_ALL_RIDERS.path);
								},
							},
							{
								title: _.get(data, 'stats.admin'),
								subTitle: 'Admin',
								onClick: () => {
									history.push(Routes.CASH_MANAGEMENT_ALL_ADMIN.path);
								},
							},
						],
						(data, key) => {
							return (
								<ListItem
									button
									onClick={data.onClick}
									className='box-shadow'
									key={`pending_amounts${key}`}
									style={{
										padding: 18,
										flexDirection: 'column',
										alignItems: 'flex-start',
										background: 'white',
										width: '30%',
										borderRadius: 10,
										...(key !== 0 && { marginLeft: '3%' }),
										boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
									}}>
									<Amount
										amount={data.title}
										main_text_styles={{ color: theme.colors.black, fontSize: 24 }}
										sub_text_styles={{ color: theme.colors.black, fontSize: 12 }}
									/>
									<Text style={{ marginTop: 5 }}>{data.subTitle}</Text>
								</ListItem>
							);
						},
					)}
				</div>
				<div style={{ height: 270, display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
					<div style={{ height: '100%', width: '35%', borderRadius: 20 }}></div>
					<Card
						style={{
							height: '100%',
							width: '60%',
							borderRadius: 20,
							padding: '32px 20px',
							boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
						}}>
						<div style={{ display: 'flex', justifyContent: 'space-between', height: 150 }}>
							<div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Text bold style={{ fontSize: 26 }}>
										My Transactions
									</Text>
									<div
										style={{
											width: 15,
											height: 15,
											borderRadius: '100%',
											backgroundColor: theme.colors.black,
											marginLeft: 5,
											marginTop: 2,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											cursor: 'pointer',
										}}
										onClick={() => set_show_transaction_history(true)}>
										<img src={ImageLinks.chevron_right} width='5' />
									</div>
								</div>

								<ListItem
									style={{
										flexDirection: 'column',
										alignItems: 'flex-start',
										background: 'white',
										borderRadius: 10,
										padding: 0,
										marginTop: 20,
									}}>
									<Amount
										amount={account_info.balance}
										main_text_styles={{
											color: account_info.balance < 0 ? theme.colors.green : theme.colors.red,
											fontSize: 32,
										}}
										sub_text_styles={{
											color: account_info.balance < 0 ? theme.colors.green : theme.colors.red,
											fontSize: 12,
										}}
									/>
									<Text bold style={{ color: '#666666', fontSize: 12 }}>
										Total Outstanding
									</Text>
								</ListItem>
							</div>

							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Button type='outlined' text='Manual Entry' size='large' onClick={() => set_show_manual_entry(true)} />

								{_.get(type_of_owner, 'id', '') !== constant.OWNER_TYPE.VENDOR.id && (
									<Button type='round' text='Settle Cash' size='large' onClick={() => set_show_settle_cash(true)} style={{ marginTop: 12 }} />
								)}
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							{_.map(
								[
									{
										title: _.get(account_info, 'stats.today.credit'),
										subTitle: 'Collected Today',
										color: theme.colors.darkPurple,
										type: OVERVIEW_TYPES.COLLECT,
									},
									{
										title: _.get(account_info, 'stats.today.debit'),
										subTitle: 'Deposited Today',
										color: theme.colors.green,
										type: OVERVIEW_TYPES.DEPOSIT,
									},
									{
										title: _.get(account_info, 'stats.today.settle'),
										subTitle: 'Settled Today',
										color: theme.colors.darkGrey2,
										type: OVERVIEW_TYPES.SETTLE,
									},
								],
								(data, key) => {
									return (
										<ListItem
											button
											key={`pending_amounts${key}`}
											style={{
												flexDirection: 'column',
												alignItems: 'flex-start',
												background: 'white',
												width: '30%',
												borderRadius: 10,
												padding: 0,
											}}
											onClick={() => history.push(Routes.CASH_MANAGEMENT_MY_OVERVIEW.path + `?type=${data.type}`)}>
											<Amount
												amount={data.title}
												main_text_styles={{ color: theme.colors.black, fontSize: 24 }}
												sub_text_styles={{ color: theme.colors.black, fontSize: 12 }}
											/>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Text bold style={{ color: data.color }}>
													{data.subTitle}
												</Text>
												<div
													style={{
														width: 15,
														height: 15,
														borderRadius: '100%',
														backgroundColor: data.color,
														marginLeft: 5,
														marginTop: 2,
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}>
													<img src={ImageLinks.chevron_right} width='5' />
												</div>
											</div>
										</ListItem>
									);
								},
							)}
						</div>
					</Card>
				</div>
			</div>
			{show_pending_request && (
				<PendingRequest
					close={() => {
						set_show_pending_request(false);
					}}
					requests={requests}
					set_pending_request_refresh={set_pending_request_refresh}
				/>
			)}
			{show_manual_entry && (
				<ManualEntry
					close={() => {
						set_show_manual_entry(false);
					}}
					refresh_stats={refresh_stats}
				/>
			)}
			{show_settle_cash && (
				<SettleCash
					close={() => {
						set_show_settle_cash(false);
					}}
					refresh_stats={refresh_stats}
				/>
			)}
			{show_transaction_history && (
				<TransactionHistory
					close={() => {
						set_show_transaction_history(false);
					}}
				/>
			)}
			{show_transfer_cash && (
				<TransferCash
					close={() => {
						set_show_transfer_cash(false);
					}}
					refresh_stats={refresh_stats}
				/>
			)}
		</div>
	);
};

export default CashManagementDashboard;
