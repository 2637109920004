import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import Text from 'modules/Text';
import theme from 'resources/theme';
import CreateNetworkContext from '../../../context';
import Switch from 'modules/Switch';
import ImageLinks from 'assets/images/ImageLinks';
import utils from 'resources/utils';
import SectionCta from '../SectionCta';
import Button from 'modules/Button';
import Select from 'modules/Select';
import { PINCODE_PRICING_SECTIONS, PRICING_OPTIONS, PRICING_RULE } from '../../helper';
import PricingItem from './PricingItem';
import OutlinedTextField from 'modules/OutlinedTextField';
import PincodePricingCalculator from './PincodePricingCalculator';
import PincodePricingInput from './PincodePricingInput';
import useStyles from './styles';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import InfoContent from './InfoContent';

const Pricing = ({ handle_data_updates }) => {
	const {
		set_pricing,
		pricing,
		set_confirm_close_modal,
		invite_network_id,
		invite_network_config,
		set_is_invite_pricing_updated,
		is_invite_pricing_updated,
		handle_pricing_api,
		format_sort_pricing_data,
		edit_network_id,
	} = useContext(CreateNetworkContext);
	const [price_data, set_price_data] = useState([]);
	const [pincode_pricing_data, set_pincode_pricing_data] = useState([]);
	const [selected_group_index, set_selected_group_index] = useState(-1);
	const [pricing_criteria, set_pricing_criteria] = useState('');
	const [selected_pricing_section, set_selected_pricing_section] = useState('');
	const [is_custom, set_is_custom] = useState(_.get(invite_network_config, 'ownership.pricing', '') == 'user');
	const classes = useStyles();
	useEffect(() => {
		set_price_initial_data();
	}, []);

	useEffect(() => {
		if (_.get(invite_network_config, 'ownership.pricing', '') == 'user') {
			set_is_custom(true);
		}
	}, [invite_network_config]);

	const group_by_volume = (arr) => {
		return arr.reduce((result, item) => {
			const { volume, ...rest } = item;
			if (!result[volume]) {
				result[volume] = { volume, slabs: [] };
			}
			result[volume].slabs.push(rest);
			return result;
		}, {});
	};

	const set_price_initial_data = () => {
		if (_.isEmpty(pricing)) return;
		set_pricing_criteria(pricing.rule);
		if (pricing.rule === PRICING_RULE.UNPAID) {
			set_price_data([]);
			set_pincode_pricing_data([]);
		}

		if (pricing.rule === PRICING_RULE.DISTANCE) {
			let resultArray = Object.values(
				group_by_volume(
					invite_network_id && !is_invite_pricing_updated
						? _.get(invite_network_config, 'pricing.groups.0.slabs', [])
						: _.get(pricing, 'groups.0.slabs', []),
				),
			);
			set_price_data(resultArray);
			set_pincode_pricing_data([]);
		}

		if (pricing.rule === PRICING_RULE.PINCODE) {
			set_price_data([]);
			set_pincode_pricing_data(pricing.groups);
			if (pricing.groups.length > 0) {
				set_selected_group_index(0);
			}
		}
	};

	const handle_item_delete = (price_index, slab_index) => {
		if (invite_network_id && !is_custom) return;
		let _price_data = _.cloneDeep(price_data);
		let _slabs = _price_data[price_index].slabs;
		_slabs.splice(slab_index, 1);
		if (_slabs.length === 0) {
			if (_price_data.length > 1) {
				_price_data.splice(price_index, 1);
			} else {
				_price_data = [
					{
						volume: null,
						slabs: [],
					},
				];
			}
		} else _price_data[price_index] = { ..._price_data[price_index], slabs: _slabs };
		set_price_data(_price_data);
	};

	const handle_volume_change = (volume, index) => {
		const _price_data = _.cloneDeep(price_data);
		_price_data[index].volume = volume;
		if (_price_data[index].slabs.length === 0) {
			_price_data[index].slabs.push({
				distance: '',
				price: '',
			});
		}
		set_price_data(_price_data);
	};

	const handle_slab_info = (name, value, item_index, slab_index) => {
		const _price_data = _.cloneDeep(price_data);
		_price_data[item_index].slabs[slab_index][name] = value;
		set_price_data(_price_data);
	};

	const handle_add_new_slot = (item_index) => {
		if (invite_network_id && !is_custom) return;
		const _price_data = _.cloneDeep(price_data);
		_price_data[item_index].slabs.push({
			distance: '',
			price: '',
		});
		set_price_data(_price_data);
	};

	const handle_add_volume = () => {
		const _price_data = _.cloneDeep(price_data);
		_price_data.push({
			volume: null,
			slabs: [],
		});
		set_price_data(_price_data);
		setTimeout(() => {
			const element = document.getElementById('pricing_scroll_wrap');
			element.scrollTop = element.scrollHeight;
		});
	};

	const handle_pricing_criteria = (value) => {
		set_pricing_criteria(value);
		if (value === PRICING_RULE.DISTANCE && pricing.rule !== PRICING_RULE.DISTANCE)
			set_price_data([
				{
					volume: null,
					slabs: [],
				},
			]);
	};
	const handle_save_pricing = () => {
		let payload = {};
		if (pricing_criteria === PRICING_RULE.UNPAID) {
			payload = {
				rule: PRICING_RULE.UNPAID,
				groups: [{ group_name: '', slabs: [], raw_data: {} }],
			};
			set_price_data([]);
			set_pincode_pricing_data([]);
		}
		if (pricing_criteria === PRICING_RULE.DISTANCE) {
			let _pricing_slabs = [];

			_.forEach(price_data, (data) => {
				_.forEach(data.slabs, (slab) => {
					_pricing_slabs.push({ volume: data.volume, distance: slab.distance, price: slab.price });
				});
			});
			payload = {
				rule: PRICING_RULE.DISTANCE,
				groups: [{ group_name: '', raw_data: {}, slabs: _pricing_slabs }],
			};
		}

		if (pricing_criteria === PRICING_RULE.PINCODE) {
			payload = {
				rule: PRICING_RULE.PINCODE,
				groups: pincode_pricing_data,
			};
		}

		if (invite_network_id || edit_network_id) {
			set_is_invite_pricing_updated(true);
		}
		set_pricing(payload);
	};

	const handle_custom_toggel = (e) => {
		if (e) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Set Custom Pricing',
				title: `Are you sure you want to set custom pricing for this participant? Default pricing will not be considered.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				is_error_msg: false,
				show_negative_btn: true,
				confirm_acion: () => {
					set_is_custom(true);
				},
			});
		}
		if (!e) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Exit Custom Rule Setup',
				title: `Are you sure you want to abandon custom pricing setup? Pricing will reset to default.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				is_error_msg: false,
				show_negative_btn: true,
				confirm_acion: () => {
					set_is_custom(false);
					set_price_initial_data();
				},
			});
		}
	};

	const handle_add_group = () => {
		if (invite_network_id && !is_custom) return;
		set_pincode_pricing_data([
			...pincode_pricing_data,
			{
				group_name: '',
				slabs: [{ max: '', slab_size: '', price: '' }],
				raw_data: { pickup: [], drop: [] },
			},
		]);
		set_selected_group_index(pincode_pricing_data.length);
	};

	const handle_group_delete = () => {
		if (invite_network_id && !is_custom) return;
		const _pincode_pricing_data = _.cloneDeep(pincode_pricing_data);
		_pincode_pricing_data.splice(selected_group_index, 1);
		const new_index = _pincode_pricing_data.length === 0 ? -1 : 0;

		set_selected_group_index(new_index);
		set_pincode_pricing_data(_pincode_pricing_data);
		if (new_index === -1) set_selected_pricing_section('');
	};

	const on_save = (pincodes, rates) => {
		if (invite_network_id && !is_custom) return;
		const _pincode_pricing_data = _.cloneDeep(pincode_pricing_data);
		if (selected_pricing_section === 'pickup') {
			_pincode_pricing_data[selected_group_index].raw_data.pickup = pincodes;
		}
		if (selected_pricing_section === 'drop') {
			_pincode_pricing_data[selected_group_index].raw_data.drop = pincodes;
		}
		if (selected_pricing_section === 'rates') {
			_pincode_pricing_data[selected_group_index].slabs = rates;
		}
		set_pincode_pricing_data(_pincode_pricing_data);
	};

	const btn_disabled = useMemo(() => {
		let is_invalid = false;
		if (pricing_criteria === '') is_invalid = true;
		else if (pricing_criteria === PRICING_RULE.UNPAID) is_invalid = false;
		else if (pricing_criteria === PRICING_RULE.DISTANCE) {
			for (let i = 0; i < price_data.length; i++) {
				const price = price_data[i];
				if (price.volume == '' || price.volume == null) {
					is_invalid = true;
					break;
				}
				for (let j = 0; j < price_data[i].slabs.length; j++) {
					const element = price_data[i].slabs[j];
					if (element.distance == '' || element.price == '') {
						is_invalid = true;
						break;
					}
				}
				if (is_invalid) {
					break;
				}
			}
		} else {
			if (pincode_pricing_data.length === 0) is_invalid = true;
			for (let i = 0; i < pincode_pricing_data.length; i++) {
				const price = pincode_pricing_data[i];
				if (price.group_name == '' || price.group_name == null || price.slabs.length === 0) {
					is_invalid = true;
					break;
				}
				const pick_pincode = _.get(price, 'raw_data.pickup', []);
				const drop_pincode = _.get(price, 'raw_data.drop', []);

				if (pick_pincode.length === 0 || drop_pincode.length === 0) {
					is_invalid = true;
					break;
				}

				for (let j = 0; j < price.slabs.length; j++) {
					const element = price.slabs[j];
					if (element.max == '' || element.slab_size == '' || element.price == '') {
						is_invalid = true;
						break;
					}
				}
				if (is_invalid) {
					break;
				}
			}
		}
		return is_invalid;
	}, [pricing_criteria, price_data, pincode_pricing_data]);

	const is_data_updated = useMemo(() => {
		let _pricing_slabs = [];
		let _pricing_data = {
			rule: pricing_criteria ? pricing_criteria : undefined,
		};

		if (pricing_criteria === PRICING_RULE.UNPAID) {
			_pricing_data.groups = [{ group_name: '', slabs: [], raw_data: {} }];
		} else if (pricing_criteria === PRICING_RULE.DISTANCE) {
			_.forEach(price_data, (data) => {
				_.forEach(data.slabs, (slab) => {
					_pricing_slabs.push({ volume: data.volume, distance: slab.distance, price: slab.price });
				});
			});

			_pricing_data.groups = [{ group_name: '', raw_data: {}, slabs: _pricing_slabs }];
		} else if (pricing_criteria === PRICING_RULE.PINCODE) {
			const formtted = _.map(pincode_pricing_data, (item) => ({ group_name: item.group_name, slabs: item.slabs, raw_data: item.raw_data }));
			_pricing_data.groups = formtted;
		} else {
			_pricing_data.groups = [];
		}
		const pricing_raw = {
			rule: pricing.rule,
			groups: _.map(pricing.groups, (item) => ({ group_name: item.group_name, slabs: item.slabs, raw_data: item.raw_data })),
		};

		const sorted_pricing_data = format_sort_pricing_data(_pricing_data);
		const sorted_pricing_raw = format_sort_pricing_data(pricing_raw);
		const changed = !_.isEqual(sorted_pricing_data, utils.remove_null_and_empty_from_obj(sorted_pricing_raw));
		handle_data_updates(1, changed);
		return changed;
	}, [pricing_criteria, price_data, pricing, pincode_pricing_data]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.content_wrap}>
				<div className={classes.input_wrap}>
					{invite_network_id && (
						<div className='d-flex align-items-center' style={{ justifyContent: 'flex-end' }}>
							<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Custom Rule
							</Text>
							<Switch
								disabled={_.get(invite_network_config, 'ownership.pricing', '') == 'user'}
								checked={is_custom}
								onChange={(e) => handle_custom_toggel(e.target.checked)}
							/>
						</div>
					)}
					<div className='align-items-center justify-content-between' style={{ marginLeft: 5 }}>
						<div>
							<Text semi style={{ fontSize: 14 }}>
								Pricing Criteria
							</Text>
							<Text medium className={classes.subtitle_text}>
								Define service rates to be charged for deliveries
							</Text>
						</div>
						<div>
							<Select
								value={pricing_criteria}
								defaultOption={{
									title: `Select Criteria`,
									value: '',
								}}
								options={PRICING_OPTIONS}
								handleChange={handle_pricing_criteria}
								containerStyles={{ height: 40, width: 180 }}
								text_props={{ semi: true }}
								disablePadding
								disabled={invite_network_id && !is_custom}
								menu_styles={{
									width: 190,
									height: 152,
								}}
							/>
						</div>
					</div>
					<div className={classes.input_section}>
						{pricing_criteria === '' && (
							<div className='align-items-center justify-content-center flex1'>
								<div className={classes.purple_strip}>
									<img src={ImageLinks.info_light_purple} width={32} height={32} />
									<Text semi className={classes.section_desc_text}>
										Please select a pricing criteria
									</Text>
								</div>
							</div>
						)}
						{pricing_criteria > 0 && pricing_criteria === PRICING_RULE.UNPAID && (
							<div className='align-items-center justify-content-center flex1'>
								<div className={classes.purple_strip}>
									<img src={ImageLinks.info_light_purple} width={32} height={32} />
									<Text semi className={classes.section_desc_text}>
										No delivery charges will be applicable in this network.
									</Text>
								</div>
							</div>
						)}
						{pricing_criteria > 0 && pricing_criteria === PRICING_RULE.API && (
							<div className='align-items-center justify-content-center flex1'>
								<div className={classes.purple_strip}>
									<img src={ImageLinks.info_light_purple} width={32} height={32} />
									<Text semi className={classes.section_desc_text}>
										Pricing rules are integrated from connected API
									</Text>
								</div>
							</div>
						)}
						{pricing_criteria > 0 && pricing_criteria === PRICING_RULE.DISTANCE && (
							<>
								<div className={classes.pricing_scoll} id='pricing_scroll_wrap'>
									<div className={classes.header_wrap}>
										<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, flex: 0.5 }}>
											Order Size
										</Text>
										<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, flex: 0.3 }}>
											KM Slab
										</Text>
										<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, flex: 0.2 }}>
											Rate/KM
										</Text>
									</div>
									{_.map(price_data, (price_item, price_index) => (
										<PricingItem
											key={`price_item${price_index}`}
											price_index={price_index}
											invite_network_id={invite_network_id}
											is_custom={is_custom}
											price_item={price_item}
											handle_item_delete={handle_item_delete}
											handle_volume_change={(val) => handle_volume_change(val, price_index)}
											handle_slab_info={(name, value, slab_index) => handle_slab_info(name, value, price_index, slab_index)}
											handle_add_new_slot={() => handle_add_new_slot(price_index)}
										/>
									))}
								</div>
								{_.get(price_data, '0.slabs', []).length > 0 && !(invite_network_id && !is_custom) && (
									<Button
										type='outlined'
										text='New Volume'
										size='small'
										disabled={invite_network_id && !is_custom}
										onClick={handle_add_volume}
										left_icon={invite_network_id && !is_custom ? ImageLinks.addGrey : ImageLinks.add_pruple_no_fill}
										style={{ width: 'fit-content', marginTop: 12 }}
									/>
								)}
								{(price_data[0]?.slabs?.length === 0 || price_data[0]?.slabs[0]?.price === '' || price_data[0]?.slabs[0]?.distance === '') && (
									<div className='d-flex align-items-center justify-content-center' style={{ marginTop: 50 }}>
										<div className={classes.empty_pin_content_wrap}>
											<img src={ImageLinks.grab_icon_purple} width={32} height={32} />
											<div style={{ marginLeft: 12 }}>
												<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
													{price_data[0]?.slabs?.length === 0
														? 'Select volume to set different rates as per required distance'
														: 'Enter distance and rate to define criteria'}
												</Text>
											</div>
										</div>
									</div>
								)}
							</>
						)}
						{pricing_criteria > 0 && pricing_criteria === PRICING_RULE.PINCODE && (
							<div className='flex1'>
								<div className={clsx(classes.group_name_wrap, 'd-flex')}>
									{_.map(pincode_pricing_data, (pincode_data, index) => {
										return (
											<div
												key={`pincode_pricing_item_${index}`}
												className={index == selected_group_index ? classes.active_tab : classes.inactive_tab}
												onClick={() => {
													set_selected_group_index(index);
													set_selected_pricing_section('pickup');
												}}>
												<Text bold>{`Group-${index + 1}`}</Text>
											</div>
										);
									})}
									<div onClick={handle_add_group} className='align-items-center cursor-pointer' style={{ marginLeft: 12 }}>
										<img src={ImageLinks.add_light_purple} width={12} height={12} />
										<Text bold style={{ fontSize: 14, color: theme.colors.lightPurple7, marginLeft: 4 }}>
											New
										</Text>
									</div>
								</div>

								{pincode_pricing_data.length > 0 && selected_group_index >= 0 && (
									<div style={{ marginTop: 10 }}>
										<div className='align-items-center justify-content-between'>
											<OutlinedTextField
												value={pincode_pricing_data[selected_group_index].group_name}
												name='group_name'
												label='Group Name*'
												textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
												disabled={invite_network_id && !is_custom}
												onChange={(e) => {
													const _pincode_pricing_data = _.cloneDeep(pincode_pricing_data);
													_pincode_pricing_data[selected_group_index].group_name = e.target.value;
													set_pincode_pricing_data(_pincode_pricing_data);
												}}
												placeholder='Group Name..'
												style={{
													width: 235,
													borderRadius: 4,
												}}
											/>
											<img src={ImageLinks.delete} width={18} height={18} className='cursor-pointer' onClick={handle_group_delete} />
										</div>
										{_.map(PINCODE_PRICING_SECTIONS, (item, index) => (
											<div
												key={`pincode_pricing_section${index}`}
												className={classes.pincode_pricing_section_wrap}
												onClick={() => set_selected_pricing_section(item.key)}
												style={{
													border: `1px solid ${
														selected_pricing_section === item.key ? theme.colors.primary : theme.colors.lightGrey5
													}`,
												}}>
												<Text bold style={{ fontSize: 14 }}>
													{`${index + 1}. ${item.title}`}
												</Text>
												<img src={ImageLinks.arrow_right_purple} width={16} height={16} />
											</div>
										))}
									</div>
								)}
							</div>
						)}
					</div>
				</div>

				<div className={classes.output_wrap}>
					<div className={classes.output_section}>
						{(pricing_criteria == '' || pricing_criteria === PRICING_RULE.UNPAID) && (
							<div className='align-items-center justify-content-center flex1' style={{ flexDirection: 'column' }}>
								<img src={ImageLinks.empty_calculator} width={100} height={100} />
								<Text semi style={{ fontSize: 12, marginTop: 6 }}>
									Your calculator is preparing to assist
								</Text>
							</div>
						)}

						{(pricing_criteria === PRICING_RULE.DISTANCE || (pricing_criteria == PRICING_RULE.PINCODE && _.isEmpty(selected_pricing_section))) && (
							<div className='d-flex flex1' style={{ flexDirection: 'column' }}>
								<div className='align-items-center justify-content-between'>
									<Text bold style={{ fontSize: 16 }}>
										Calculator
									</Text>
									<BootstrapTooltip placement='left-start' title={<InfoContent pricing_criteria={pricing_criteria} />}>
										<img src={ImageLinks.info_purple} width={16} height={16} />
									</BootstrapTooltip>
								</div>
								<div className={'dashed-line'}></div>
								<div className='align-items-center justify-content-center flex1' style={{ flexDirection: 'column' }}>
									<img src={ImageLinks.empty_calculator} width={100} height={100} />
									<Text semi style={{ fontSize: 12, marginTop: 6 }}>
										Coming Soon
									</Text>
								</div>
							</div>
						)}

						{/* {pricing_criteria == 'pincode' && _.isEmpty(selected_pricing_section) && <PincodePricingCalculator />} */}
						{pricing_criteria == PRICING_RULE.PINCODE && !_.isEmpty(selected_pricing_section) && (
							<PincodePricingInput
								selected_pricing_section={selected_pricing_section}
								group_data={pincode_pricing_data[selected_group_index]}
								on_save={on_save}
								is_disabled={invite_network_id && !is_custom}
							/>
						)}
					</div>
				</div>
			</div>

			<SectionCta
				title={`Save ${invite_network_id ? 'Custom Pricing' : 'Pricing'}`}
				is_data_updated={is_data_updated}
				is_disabled={btn_disabled}
				handle_click={handle_save_pricing}
				total_steps={1}
				lower_section_index={2}
				handle_api_save={handle_pricing_api}
				complete_btn_title={'Setup Pricing'}
				handle_data_updates={handle_data_updates}
			/>
		</div>
	);
};

export default Pricing;
