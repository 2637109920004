import { LinearProgress, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';

function DummyProgressBar({ progress, set_progress }) {
	const theme = useTheme();
	useEffect(() => {
		const timer = setInterval(() => {
			set_progress((prevProgress) => prevProgress + 10);
		}, 400);
		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<div>
			<LinearProgress style={{ backgroundColor: theme.colors.lightGrey3, borderRadius: 3 }} value={progress} variant='determinate' />
		</div>
	);
}

export default DummyProgressBar;
