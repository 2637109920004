import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React, { useContext } from 'react';
import CreateCommunicationContext from '../context';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
	back_arrow: {
		backgroundColor: theme.colors.lightPurple,
		width: 20,
		height: 20,
		marginRight: 10,
		borderRadius: 20,
		marginTop: 4,
		padding: 0,
		borderColor: theme.colors.lightPurple,
	},
	header: {
		display: 'flex',
	},
	main_header_text: {
		fontSize: 22,
		color: theme.colors.black,
	},
	screen_text_wrap: { width: 2, height: 30, background: '#B0B0B0', marginLeft: 20 },
	screen_text: {
		fontSize: 22,
		marginLeft: 20,
		borderBottom: '4px solid #E4DCF3',
		color: 'black',
	},
}));

const Header = () => {
	const { comm_id, event_detail, on_header_nav } = useContext(CreateCommunicationContext);
	const classes = useStyles();
	return (
		<div className={classes.header}>
			<div className='page_back_btn' onClick={on_header_nav}>
				<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
			</div>
			<Text regular className={'global_header_text'}>
				Communication
			</Text>
			<div className='header_separator' style={{ marginTop: 8 }}></div>
			<Text semi className={'header_active_title'}>
				{_.isEmpty(comm_id) ? 'Create' : event_detail?.name}
			</Text>
		</div>
	);
};

export default Header;
