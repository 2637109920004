import React from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import useStyles from '../styles';
import ImageLinks from 'assets/images/ImageLinks';
import { useTheme } from '@material-ui/core';
import Routes from 'resources/Routes';
import moment from 'moment';

const MENU = {
	unallocated: 'unallocated',
	active: 'active',
	compare: 'compare',
	add: 'add',
};

function BundleList({
	bundleGroup,
	search,
	search_rider,
	search_rider_type,
	search_date,
	date,
	selected_bundle_id,
	selected_bundle_list,
	on_bundle_select,
	selected_menu,
	history,
	active_pickup,
	set_drag_enter,
	set_drop_bundle,
	last_updated_bundle,
	set_last_updated_bundle,
	set_show_confirmation,
	set_show_pbid_move_confirm,
	bundles = [],
	simulation_data,
}) {
	const classes = useStyles();
	const theme = useTheme();
	const simulator_bundles = _.map(simulation_data, (item) => {
		return item.bundle_id;
	});
	const background_colors = [
		theme.colors.cardBackgroundColor1,
		theme.colors.cardBackgroundColor2,
		theme.colors.cardBackgroundColor3,
		theme.colors.cardBackgroundColor4,
		theme.colors.cardBackgroundColor5,
		theme.colors.cardBackgroundColor6,
		theme.colors.cardBackgroundColor7,
		theme.colors.cardBackgroundColor8,
		theme.colors.cardBackgroundColor9,
		theme.colors.cardBackgroundColor10,
	];
	const in_last_30_seconds = (date) => {
		let time_differece = new Date() - new Date(date);
		if (time_differece > 300000 || time_differece < 0) {
			return false;
		} else {
			return true;
		}
	};
	const check_disabled = (item) => {
		if (
			selected_menu === MENU.compare &&
			_.filter(selected_bundle_list, (value) => {
				return value !== '';
			}).length > 9 &&
			_.indexOf(selected_bundle_list, item.id) < 0
		) {
			return true;
		}
		if (selected_menu === MENU.compare && active_pickup && _.indexOf(selected_bundle_list, item.id) < 0) {
			return true;
		}
		if (!active_pickup) {
			return false;
		}
		if (
			parseFloat(active_pickup.latitude).toFixed(4) === parseFloat(item.task_location.latitude).toFixed(4) &&
			parseFloat(active_pickup.longitude).toFixed(4) === parseFloat(item.task_location.longitude).toFixed(4)
		)
			return false;
		return true;
	};

	const handle_trip_drop = (e, bundle) => {
		e.preventDefault();
		set_drop_bundle(bundle);
		set_last_updated_bundle({});
		if (selected_menu === MENU.add) {
			set_show_pbid_move_confirm(true);
		} else {
			set_show_confirmation(true);
		}
	};

	const apply_filter = (bundle, index) => {
		if (check_disabled(bundle) && selected_menu === MENU.add) {
			return false;
		}
		if (search) {
			if (!bundle.bundle_label.toLowerCase().includes(search.toLowerCase()) && !bundle.id.toString().toLowerCase().includes(search.toLowerCase())) {
				return false;
			}
		}
		if (search_rider) {
			if (bundle.rider_id != search_rider) {
				return false;
			}
		}
		if (search_rider_type) {
			if (search_rider_type === 'unlinked') {
				if (bundle.rider_id) {
					return false;
				}
			}
			if (search_rider_type === 'linked') {
				if (!bundle.rider_id) {
					return false;
				}
			}
		}
		if (search_date) {
			if (
				moment(moment(bundle.created_at).format('DD/MM/YYYY'), 'DD/MM/YYYY').diff(
					moment(moment(search_date).format('DD/MM/YYYY'), 'DD/MM/YYYY'),
					'day',
				) != 0
			) {
				return false;
			}
		}

		return true;
	};
	return (
		<div style={{ marginTop: 15 }}>
			{_.filter(bundleGroup, apply_filter).length > 0 && <Text>{date}</Text>}
			{_.map(_.sortBy(_.filter(bundleGroup, apply_filter), [selected_menu === MENU.add ? 'distance' : '']), (bundle, key2) => {
				const isSelected = Number(selected_bundle_id) === bundle.id;
				const is_same_pickup = Number();
				return (
					<div
						key={`bundle${key2}`}
						className={`box-shadow ${classes.bundle_list_item}`}
						onClick={() => on_bundle_select(bundle)}
						onDoubleClick={() => {
							if (selected_menu === MENU.compare)
								if (selected_bundle_id !== bundle.id && _.includes(selected_bundle_list, bundle.id))
									history.push(Routes.GROUPING_AND_ALLOCATION.path + `/?type=${selected_menu}&bundle_id=${bundle.id}`);
								else history.push(Routes.GROUPING_AND_ALLOCATION.path + `/?type=${selected_menu}`);
						}}
						style={{
							border: `2px solid ${
								_.includes(selected_bundle_list, bundle.id) && (selected_menu === MENU.add || selected_menu === MENU.compare)
									? theme.colors['compareMapColor' + (_.indexOf(selected_bundle_list, bundle.id) + 1)]
									: isSelected
									? theme.colors.primary
									: 'transparent'
							}`,
							backgroundColor: `${
								isSelected
									? _.includes(selected_bundle_list, bundle.id) && (selected_menu === MENU.add || selected_menu === MENU.compare)
										? background_colors[_.indexOf(selected_bundle_list, bundle.id)]
										: theme.colors.lightPurple9
									: check_disabled(bundle)
									? theme.colors.lightGrey5
									: theme.colors.white
							}`,
						}}>
						<div
							className={classes.list_item_wrap}
							onDrop={!check_disabled(bundle) ? (e) => handle_trip_drop(e, bundle) : undefined}
							onDragEnter={() => set_drag_enter(bundle.id)}
							onDragLeave={() => set_drag_enter(null)}
							onDragOver={
								!check_disabled(bundle)
									? (e) => {
											e.preventDefault();
									  }
									: undefined
							}>
							<div className={classes.list_item_stats_wrap}>
								<Text bold style={{ color: theme.colors.black }}>
									{bundle.bundle_label}
								</Text>
								<div style={{ display: 'flex' }}>
									<img src={ImageLinks.box2_purple} width={10} />
									<Text bold style={{ color: theme.colors.black, marginLeft: 5 }}>
										{bundle.trip_count}
									</Text>
								</div>
							</div>
							<div className={classes.list_item_stats_wrap_2}>
								<div className='d-flex align-items-center' style={{ gap: 3 }}>
									<img src={ImageLinks.rider_light_purple3} height={10} width={10} />
									<Text medium className={classes.bid_text} style={{ color: theme.colors.lightPurple7, fontSize: 10 }}>
										{bundle.rider_id ? bundle.rider_id : '-'}
									</Text>
								</div>

								<div style={{ display: 'flex' }}>
									<Text
										semi
										style={{
											marginLeft: 5,
											fontSize: 10,
										}}>
										{bundle.distance !== null ? `${Math.round(bundle.distance / 1000)}` : '-'} kms |
									</Text>
									<Text
										semi
										style={{
											marginLeft: 5,
											fontSize: 10,
										}}>
										{bundle.time_in_second
											? bundle.time_in_second < 3600
												? Math.round(bundle.time_in_second / 60) + ' mins'
												: Math.round(bundle.time_in_second / 360) / 10 + ' hrs'
											: '-'}
									</Text>
								</div>
							</div>
						</div>
						{((last_updated_bundle.count && last_updated_bundle.id === bundle.id) || in_last_30_seconds(bundle.created_at)) && (
							<div className={classes.purple_dot} />
						)}
						{simulator_bundles.includes(bundle.id) && <div className={classes.red_dot} />}
					</div>
				);
			})}
		</div>
	);
}

export default BundleList;
