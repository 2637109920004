import React from 'react';
import _ from 'lodash';
import { MapContainer, Popup, TileLayer, Marker } from 'react-leaflet';
import { DivIcon } from 'leaflet';
import qs from 'qs';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import 'leaflet-providers/leaflet-providers.js'; // Import Leaflet Providers
import 'leaflet-routing-machine';
import Text from 'modules/Text';
import Filters from 'modules/Filters/Filters';
import FilterButton from 'modules/FilterButton';
import VehicleRefSearch from './components/VehicleRefSearch';
import SearchRiderModal from './components/SearchRiderModal';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import TripDetails from 'modules/modals/OrderDetail/components/TripDetails';
import CurrentAndZoneSelectModal from './components/CurrentAndZoneSelectModal';
import ImageLinks from 'assets/images/ImageLinks';
import constant from 'resources/constant';
import Routes from 'resources/Routes';
import theme from 'resources/theme';
import './styles.css';
import useStyles from './styles';
import EmptyNetwork from './components/EmptyNetwork';
import RiderOverviewModal from 'modules/modals/RiderOverviewModal';
import TaskMarkers from './components/TaskMarkers';
import RiderAndTruckMarkers from './components/RiderAndTruckMarkers';
import useTracking from './useTracking';
import { OUTER_FILTER_TYPE } from './helper';
import NetworkRiderModal from './components/NetworkRiderModal';
import Toast from 'modules/Toast';

const Tracking = ({ history }) => {
	const classes = useStyles();
	const {
		filter_object,
		zones_list,
		selected_master_zone_id,
		rider_markers,
		rider_data,
		rider_tasks,
		show_filters,
		all_filters,
		selected_pb_id,
		show_pd_id_modal,
		show_trip_id_modal,
		trip_data,
		has_captive_riders,
		selected_outer_filter,
		show_zones_modal,
		completed_tasks,
		show_completed_tasks,
		show_rider_modal,
		fleet_riders,
		map_ref,
		show_network_modal,
		network_orders,
		show_toast,
		show_fleet_riders,
		set_rider_data,
		set_rider_tasks,
		set_show_filters,
		set_selected_pb_id,
		set_show_pd_id_modal,
		set_selected_trip_id,
		set_show_zones_modal,
		set_show_rider_modal,
		handle_bubble_click,
		handle_search,
		show_rider_tasks_onmap,
		handle_rider_task_modal_close,
		handle_zone_change,
		handle_completed_task,
		get_on_task_riders,
		handle_task_marker_click,
		handle_trip_modal_close,
		fetch_network_riders,
		fetch_three_pl_riders,
		handle_trace_nav,
		set_show_toast,
		handle_rider_marker_click,
		is_api_call_done,
		three_pl_tasks,
	} = useTracking(history);

	const selected_master_zone = _.find(zones_list, { id: selected_master_zone_id });

	return (
		<div style={{ height: '100%' }}>
			{show_toast && (
				<Toast error={true} wrapper_styles={{ zIndex: 1000 }} close_callback={() => set_show_toast(false)}>
					<Text component={'span'} semi style={{ marginLeft: '10px' }}>
						Location Permission Denied. Please enable location permission from settings.
					</Text>
				</Toast>
			)}
			<div className={classes.gps_wrapper} onClick={() => set_show_zones_modal(true)}>
				<img className='gps_light' src={ImageLinks.gps_location_solid_light_purple} />
				<img className='gps_purple' style={{ display: 'none' }} src={ImageLinks.gps_location_solid_purple} />
			</div>

			{(!has_captive_riders || _.isEmpty(network_orders)) && _.isEmpty(selected_outer_filter) && is_api_call_done && (
				<EmptyNetwork has_captive_riders={has_captive_riders} is_network={!_.isEmpty(network_orders)} />
			)}

			<div className={classes.filterContainer}>
				<div></div>
				<div style={{ display: 'flex' }}>
					{fleet_riders.length > 0 && (selected_outer_filter === null || selected_outer_filter === OUTER_FILTER_TYPE.IOT) && (
						<div
							className={classes.filter_bubble}
							onClick={() => handle_bubble_click(OUTER_FILTER_TYPE.IOT)}
							style={{ backgroundColor: selected_outer_filter === OUTER_FILTER_TYPE.IOT ? theme.colors.lightPurple : theme.colors.white }}>
							<img src={ImageLinks.truck_purple} width='12' height='12' alt='truck_purple' style={{ marginRight: 6 }} />
							<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
								IOT Fleet
							</Text>
							{selected_outer_filter === OUTER_FILTER_TYPE.IOT && (
								<img src={ImageLinks.cross} width='12' height='12' alt='cross_purple' style={{ marginLeft: 6 }} />
							)}
						</div>
					)}
					{!_.isEmpty(network_orders) && (selected_outer_filter === null || selected_outer_filter === OUTER_FILTER_TYPE.NETWORK) && (
						<div
							className={classes.filter_bubble}
							onClick={() => handle_bubble_click(OUTER_FILTER_TYPE.NETWORK)}
							style={{ backgroundColor: selected_outer_filter === OUTER_FILTER_TYPE.NETWORK ? theme.colors.lightPurple : theme.colors.white }}>
							<img src={ImageLinks.network_purple} width='12' height='12' alt='network_purple' style={{ marginRight: 6 }} />
							<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
								Network
							</Text>
							{selected_outer_filter === OUTER_FILTER_TYPE.NETWORK && (
								<img src={ImageLinks.cross} width='12' height='12' alt='cross_purple' style={{ marginLeft: 6 }} />
							)}
						</div>
					)}
					{(selected_outer_filter === null || selected_outer_filter === OUTER_FILTER_TYPE.LOGGED) && has_captive_riders && (
						<div
							className={classes.filter_bubble}
							onClick={() => handle_bubble_click(OUTER_FILTER_TYPE.LOGGED)}
							style={{ backgroundColor: selected_outer_filter === OUTER_FILTER_TYPE.LOGGED ? theme.colors.lightPurple : theme.colors.white }}>
							<div className={classes.filter_circle} style={{ backgroundColor: theme.colors.green }}></div>
							<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
								Logged in
							</Text>
							{selected_outer_filter === OUTER_FILTER_TYPE.LOGGED && (
								<img src={ImageLinks.cross} width='12' height='12' alt='cross_purple' style={{ marginLeft: 6 }} />
							)}
						</div>
					)}
					{(selected_outer_filter === null || selected_outer_filter === OUTER_FILTER_TYPE.ONTASK) && has_captive_riders && (
						<div
							className={classes.filter_bubble}
							style={{
								marginRight: 0,
								backgroundColor: selected_outer_filter === OUTER_FILTER_TYPE.ONTASK ? theme.colors.lightPurple : theme.colors.white,
							}}
							onClick={() => handle_bubble_click(OUTER_FILTER_TYPE.ONTASK)}>
							<div className={classes.filter_circle} style={{ backgroundColor: theme.colors.darkYellow }}></div>
							<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
								On Task
							</Text>
							{selected_outer_filter === OUTER_FILTER_TYPE.ONTASK && (
								<img src={ImageLinks.cross} width='12' height='12' alt='cross_purple' style={{ marginLeft: 6 }} />
							)}
						</div>
					)}
					<VehicleRefSearch handle_search={handle_search} />
					<FilterButton handle_click={() => set_show_filters(true)} total_filters={Object.keys(filter_object).length} />
				</div>
			</div>

			<div style={{ height: '100%', width: '100%' }}>
				{selected_master_zone_id != null && (
					<MapContainer
						ref={map_ref}
						key={selected_master_zone_id}
						center={{ lat: selected_master_zone.CenterPoint.latitude, lng: selected_master_zone.CenterPoint.longitude }}
						zoom={10}
						scrollWheelZoom={true}>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
						/>

						{rider_markers &&
							_.map(rider_markers, (marker) => {
								const marker_icon =
									selected_outer_filter === OUTER_FILTER_TYPE.NETWORK
										? ImageLinks.network_pink
										: selected_outer_filter === OUTER_FILTER_TYPE.LOGGED
										? ImageLinks.rider_green
										: ImageLinks.rider_yellow;
								return (
									<RiderAndTruckMarkers
										key={marker.rider_id}
										marker={marker}
										marker_icon={marker_icon}
										inner_circle_color={
											selected_outer_filter === OUTER_FILTER_TYPE.NETWORK
												? theme.colors.darkPurple
												: selected_outer_filter === OUTER_FILTER_TYPE.LOGGED
												? theme.colors.green
												: theme.colors.darkYellow
										}
										outer_circle_color={
											selected_outer_filter === OUTER_FILTER_TYPE.NETWORK
												? theme.colors.lightPink
												: selected_outer_filter === OUTER_FILTER_TYPE.LOGGED
												? '#CEEDE3'
												: '#F8D9A6'
										}
										on_marker_click={(e) => handle_rider_marker_click(e)}>
										<Popup className='rider_popup'>
											<div style={{ height: 20, margin: 0 }}>
												<Text semi style={{ fontSize: 14 }}>
													{marker.rider_name}
													<Text component={'span'} medium style={{ fontSize: 12, marginLeft: 4 }}>
														{`${marker.rider_id ? `(${marker.rider_id})` : marker.mobile ? `(${marker.mobile})` : ''}`}
													</Text>
												</Text>
											</div>
										</Popup>
									</RiderAndTruckMarkers>
								);
							})}

						{show_fleet_riders &&
							fleet_riders.length > 0 &&
							_.map(fleet_riders, (marker) => {
								const rider_location = { latitude: marker.latitude, longitude: marker.longitude };
								return (
									<RiderAndTruckMarkers
										key={marker.trace_entity_id}
										dismiss_popup_on_hover={false}
										marker={{ rider_location }}
										marker_icon={ImageLinks.truck_yellow}
										inner_circle_color={theme.colors.darkYellow}
										outer_circle_color={'#F8D9A6'}
										on_marker_click={(e) => {}}>
										<Popup className='rider_popup'>
											<div className='align-items-center' style={{ height: 20, margin: 0 }}>
												<Text semi style={{ fontSize: 14 }}>
													{marker.entity_name}
												</Text>
												<Text semi style={{ fontSize: 14, marginLeft: 4, marginRight: 4, color: theme.colors.lightGrey2 }}>
													|
												</Text>
												<img src={ImageLinks.track_filled_purple} width={12} height={12} />
												<Text semi onClick={() => handle_trace_nav(marker)} className={classes.trace_text}>
													Trace
												</Text>
											</div>
										</Popup>
									</RiderAndTruckMarkers>
								);
							})}

						{show_completed_tasks &&
							completed_tasks.length > 0 &&
							_.map(completed_tasks, (route, key) => {
								const task_marker = new DivIcon({
									html: `<div>
								<img src="${route.task_type === constant.TASK_TYPES.DROP.id ? ImageLinks.marker_purple : ImageLinks.marker_green}" width="25px" height="25px" />
								<span style="position:absolute;color:white;top:2px;font-size:12px;width:20px;text-align:center">${key + 1}</span>
								</div>`,
									iconSize: [20, 20],
								});
								return (
									<TaskMarkers
										key={`marker_${route.mcp_id}`}
										route={route}
										marker_icon={task_marker}
										on_marker_click={handle_task_marker_click}
									/>
								);
							})}

						{rider_tasks.routes &&
							_.map(rider_tasks.routes, (route, key) => {
								const old_completed_task_length = completed_tasks.length;
								const task_marker = new DivIcon({
									html: `<div>
								<img src="${route.task_type === constant.TASK_TYPES.DROP.id ? ImageLinks.marker_purple : ImageLinks.marker_green}" width="25px" height="25px" />
								<span style="position:absolute;color:white;top:2px;font-size:12px;width:20px;text-align:center">${key + 1 + old_completed_task_length}</span>
								</div>`,
									iconSize: [20, 20],
								});
								return (
									<TaskMarkers
										key={`marker_${route.ror_id}`}
										route={route}
										marker_icon={task_marker}
										on_marker_click={handle_task_marker_click}
									/>
								);
							})}

						{three_pl_tasks.length > 0 &&
							_.map(three_pl_tasks, (route, key) => {
								const task_marker = new DivIcon({
									html: `<div>
								<img src="${route.task_type === constant.TASK_TYPES.DROP.id ? ImageLinks.marker_purple : ImageLinks.marker_green}" width="25px" height="25px" />
								<span style="position:absolute;color:white;top:2px;font-size:12px;width:20px;text-align:center">${key + 1}</span>
								</div>`,
									iconSize: [20, 20],
								});
								return (
									<Marker position={{ lat: route.task_location.latitude, lng: route.task_location.longitude }} icon={task_marker}></Marker>
								);
							})}
					</MapContainer>
				)}
			</div>

			{show_filters && (
				<Filters
					all_filters={all_filters}
					existing_filters={filter_object}
					set_selected_filters={(filters) => {
						history.push(Routes.TRACKING.path + '/?' + qs.stringify({ filter: filters }));
					}}
					close={() => set_show_filters(false)}
					title=''
				/>
			)}

			{!_.isEmpty(rider_data) && !_.isEmpty(rider_tasks) && (
				<SearchRiderModal
					rider_tasks={rider_tasks}
					set_rider_tasks={set_rider_tasks}
					rider_data={rider_data}
					close={handle_rider_task_modal_close}
					set_selected_pb_id={set_selected_pb_id}
					set_show_pd_id_modal={set_show_pd_id_modal}
					set_selected_trip_id={set_selected_trip_id}
					completed_tasks={completed_tasks}
					show_completed_tasks={show_completed_tasks}
					handle_completed_task={handle_completed_task}
					open_rider_modal={() => set_show_rider_modal(true)}
					show_rider_tasks_onmap={show_rider_tasks_onmap}
				/>
			)}

			{show_network_modal && (
				<NetworkRiderModal
					network_orders={network_orders}
					close={handle_rider_task_modal_close}
					fetch_network_riders={fetch_network_riders}
					fetch_three_pl_riders={fetch_three_pl_riders}
				/>
			)}

			{show_pd_id_modal && (
				<OrderModal
					id={selected_pb_id}
					updater={() => get_on_task_riders()}
					close={() => {
						set_show_pd_id_modal(false);
					}}
				/>
			)}
			{show_trip_id_modal && (
				<TripDetails
					on_update_success={() => {}}
					brand_name={''}
					order_id={'Admin Task'}
					trip_data={trip_data}
					close={handle_trip_modal_close}
					wrapper_styles={{ right: 0, zIndex: 1500 }}
				/>
			)}
			{show_zones_modal && (
				<CurrentAndZoneSelectModal
					outside_click={() => set_show_zones_modal(false)}
					handle_zone_change={handle_zone_change}
					set_show_toast={set_show_toast}
				/>
			)}
			{show_rider_modal && !_.isEmpty(rider_data) && (
				<RiderOverviewModal rider_data={rider_data} set_rider_data={set_rider_data} close={() => set_show_rider_modal(false)} />
			)}
		</div>
	);
};

export default Tracking;
