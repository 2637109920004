import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';

const EmptyListItem = ({ is_sub = false }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Text medium style={{ marginRight: 2, fontSize: 16, color: is_sub ? theme.colors.lightGrey6 : theme.colors.black }}>
				-
			</Text>
			<img src={ImageLinks.exclamation_error} width={12} height={12} />
		</div>
	);
};

export default EmptyListItem;
