import React, { useEffect, useState } from 'react';
import theme from 'resources/theme';
import _ from 'lodash';
import IconButton from 'modules/IconButton';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import { ListItem, makeStyles } from '@material-ui/core';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import ContainedButton from 'modules/ContainedButton';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 500,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		zIndex: 1199,
		overflowY: 'auto',
		paddingBottom: 150,
	},
	header_wrap: { paddingBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	header_text: { fontSize: 20, color: theme.colors.primary },
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiInputLabel-outlined': {
			border: 'none',
		},
	},
	footer: {
		height: 100,
		width: 500,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 40,
		paddingRight: 40,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	cta_btn: {
		height: 38,
		borderRadius: 30,
		boxShadow: 'none',
	},

	search_member_wrap: {
		marginTop: 5,
		position: 'absolute',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		width: 300,
		zIndex: 1000,
		border: `1px solid ${theme.colors.lightGrey3}`,
		borderRadius: 10,
		background: 'white',
		overflowY: 'auto',
		maxHeight: 200,
	},
	member_list_wrap: { width: '100%', height: 270, overflowY: 'auto', marginTop: 20 },
	delete_btn: {
		display: 'flex',
		alignItems: 'center',
	},
	user_list_item: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 43,
	},
	team_desc_title_wrap: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
	text_area_wrap: {
		height: 73,
		borderRadius: 6,
		backgroundColor: theme.colors.lightGrey7,
		boxShadow: '0px 0px 4px 0px rgba(102, 102, 102, 0.25)',
		marginTop: 8,
	},
}));

const AddMember = ({ close, users, team_users, add_members_to_team, wrapper_styles = {} }) => {
	const [member_name, set_member_name] = useState('');
	const [search_members, set_search_members] = useState([]);
	const [selected_members, set_selected_members] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		if (!_.isEmpty(member_name)) {
			const added_users_ids = _.map(team_users, (item) => item.user_id);
			const searchable_users = _.filter(users, (item) => !added_users_ids.includes(item.id));
			const result = _.filter(searchable_users, (o) => o.name.toLowerCase().includes(member_name.toLowerCase()));
			set_search_members(result);
		} else {
			set_search_members([]);
		}
	}, [member_name]);

	const handle_select_member = (member) => {
		const index = _.findIndex(selected_members, { id: member.id });
		const _selected_members = [...selected_members];

		if (index === -1) {
			_selected_members.push(member);
		} else {
			_selected_members.splice(index, 1);
		}

		set_selected_members(_selected_members);
		set_member_name('');
		set_search_members([]);
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container} style={wrapper_styles}>
				<div style={{ padding: 30 }}>
					<div className={classes.header_wrap}>
						<Text bold className={classes.header_text}>
							New Member
						</Text>
						<div>
							<IconButton onClick={close} style={{ border: 'none' }}>
								<img src={ImageLinks.crossBlack} width={16} height={16} />
							</IconButton>
						</div>
					</div>
					<div style={{ border: `1px solid ${theme.colors.lightGrey5}` }}></div>

					<div style={{ marginTop: 16 }}>
						<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
							Member Identity
						</Text>

						<div style={{ marginTop: 22 }}>
							<UndlerlineTextField label='User Name/ Id*' value={member_name} onChange={(e) => set_member_name(e.target.value)} />

							{!_.isEmpty(search_members) && (
								<div className={classes.search_member_wrap}>
									{_.map(search_members, (member, index) => {
										return (
											<ListItem
												button
												key={`menu${index}`}
												onClick={() => handle_select_member(member)}
												tabIndex={index}
												style={{ padding: '12px 20px', ...(index !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }) }}>
												<Text medium>{member.id}</Text>
												<Text medium style={{ marginLeft: 20 }}>
													{member.name}
												</Text>
											</ListItem>
										);
									})}
								</div>
							)}

							<div className={classes.member_list_wrap}>
								{_.map(selected_members, (member, key) => {
									return (
										<div style={{ position: 'relative' }} key={`selected_members${key}`}>
											<ListItem
												key={`member${key}`}
												button
												className={classes.user_list_item}
												style={{
													...(key !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }),
												}}
												onClick={() => {}}>
												<Text bold style={{ fontSize: 16 }}>
													{`${member.name} (${member.id})`}
												</Text>
												<div className={classes.delete_btn} onClick={() => handle_select_member(member)}>
													<img src={ImageLinks.delete} style={{ marginRight: 10 }} />
												</div>
											</ListItem>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<div className={classes.footer}>
					<Button
						type='round'
						text='Add to Team'
						size='large'
						disabled={selected_members.length === 0}
						onClick={() => add_members_to_team(selected_members)}
						className={classes.cta_btn}
					/>
				</div>
			</div>
		</>
	);
};

export default AddMember;
