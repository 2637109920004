import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from 'resources/theme';
import Text from 'modules/Text';
import { Divider } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import { nFormatter } from '../helper';
import EmptyState from '../EmptyState';

const useStyles = makeStyles({
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
	analytics_container: {
		padding: 12,
		borderRadius: 12,
		background: theme.colors.white,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
	},
	dot_content: {
		width: 8,
		height: 8,
		borderRadius: '50%',
	},
});

const DeliverySlotPerformance = ({ slot_performance }) => {
	const classes = useStyles();
	const labels = _.map(slot_performance, (item_value) => {
		return item_value._id;
	});
	const data = {
		labels: labels,
		datasets: [
			{
				label: 'Late',
				data: _.map(slot_performance, (item_value) => {
					return item_value.late_time;
				}),
				backgroundColor: theme.colors.lightPurple5,
				hoverBackgroundColor: theme.colors.lightPurple12,
			},
			{
				label: 'On Time',
				data: _.map(slot_performance, (item_value) => {
					return item_value.on_time;
				}),
				backgroundColor: theme.colors.darkGreen1,
			},
			{
				label: 'Early',
				data: _.map(slot_performance, (item_value) => {
					return item_value.early_time;
				}),
				backgroundColor: theme.colors.lightGreen9,
				borderWidth: 2,
				borderRadius: { topLeft: 4, topRight: 4, bottomLeft: 0, bottomRight: 0 },
				borderSkipped: false,
				borderColor: theme.colors.lightGreen9,
			},
		],
	};

	const config = {
		type: 'bar',
		data: data,
		options: {
			plugins: {
				legend: false,
				datalabels: {
					display: false,
				},
			},
			responsive: true,
			scales: {
				x: {
					grid: {
						display: false,
					},
					ticks: {
						color: theme.colors.darkGrey2,
						font: {
							size: 10,
							family: 'HK Grotesk Bold',
						},
					},
					stacked: true,
				},
				y: {
					beginAtZero: true,
					grid: {
						color: theme.colors.lightGrey12,
					},
					ticks: {
						font: {
							color: theme.colors.darkGrey2,
							size: 8,
							family: 'HK Grotesk Bold',
						},
						callback: nFormatter,
					},
					stacked: true,
				},
			},
		},
	};
	return (
		<div className={classes.analytics_container}>
			<div className='align-items-center justify-content-between'>
				<Text bold style={{ fontSize: 14 }}>
					Delivery Slot Performance
				</Text>
				<div className='d-flex'>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.lightGreen9 }} />
						<Text style={{ fontSize: 12 }}>Early</Text>
					</div>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.darkGreen1 }} />
						<Text style={{ fontSize: 12 }}>On Time</Text>
					</div>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.lightPurple5 }} />
						<Text style={{ fontSize: 12 }}>Late</Text>
					</div>
				</div>
			</div>
			<Divider className={classes.divider} />
			<div style={{ width: '100%', height: '100%' }}>{!_.isEmpty(slot_performance) ? <Bar {...config} /> : <EmptyState />}</div>
		</div>
	);
};

export default DeliverySlotPerformance;
