import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import CancellationCahngesInfo from './CancellationChargesInfo';
import SectionCta from '../SectionCta';
import CreateNetworkContext from '../../../context';
import OutlinedTextField from 'modules/OutlinedTextField';
import Button from 'modules/Button';
import Switch from 'modules/Switch';
import utils from 'resources/utils';
import ConfirmClose from 'modules/modals/ConfirmClose';

const useStyles = makeStyles(() => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column' },
	content_wrap: { marginLeft: 16, gap: 20, display: 'flex', flex: 1 },
	input_wrap: {
		display: 'flex',
		flexDirection: 'column',
		flex: 0.55,
		overflowX: 'hidden',
	},
	output_wrap: { display: 'flex', flexDirection: 'column', flex: 0.45, marginTop: -60 },
	input_section: {
		marginTop: 8,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey7}`,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		borderRadius: 14,
	},
	output_section: {
		padding: 16,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 16px rgba(102, 102, 102, 0.1)`,
		borderRadius: 12,
		flex: 1,
	},
	notes_wrapper: {
		backgroundColor: theme.colors.lightPink4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 28,
		borderBottomLeftRadius: 12,
		borderBottomRightRadius: 12,
	},
	new_rate_wrap: { justifyContent: 'flex-end', marginBottom: 14 },
	min_range: { fontSize: 14, color: theme.colors.darkGrey2, flex: 1.6, paddingLeft: 14 },
	menu_item: { display: 'flex', flex: 1, borderRadius: 40, boxShadow: '0px 0px 4px 0px #E4DCF3', padding: '8px 0px' },
	purple_strip: {
		width: 350,
		height: 60,
		borderRadius: 4,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		display: 'flex',
		alignItems: 'center',
		padding: 10,
		paddingLeft: 26,
	},
	section_desc_text: { fontSize: 14, color: theme.colors.primary, marginLeft: 12 },
}));

const CanellationCharges = ({ handle_data_updates }) => {
	const {
		cancellation_charges,
		set_cancellation_charges,
		handle_cancellation_charges_api,
		invite_network_config,
		set_confirm_close_modal,
		invite_network_id,
		is_cancellation_charges_toggle,
		set_is_cancellation_charges_toggle,
		is_invite_cancellation_price,
		set_is_invite_cancellation_price,
		edit_network_id,
	} = useContext(CreateNetworkContext);
	const classes = useStyles();
	const [cancellation_data, set_cancellation_data] = useState([{ time: '', percentage: '', flat_amount: '' }]);
	const [default_cancellation_charges, set_default_cancellation_charges] = useState({ percentage: '', flat_amount: '' });
	const [show_change_modal, set_show_change_modal] = useState(false);
	const [is_custom, set_is_custom] = useState(_.get(invite_network_config, 'ownership.cancellation', '') == 'user');

	useEffect(() => {
		if (_.isEmpty(cancellation_charges) || (!_.isEmpty(cancellation_charges) && cancellation_charges?.rule == 1)) {
			set_is_cancellation_charges_toggle(false);
		} else {
			set_cancel_initial_data();
			set_is_cancellation_charges_toggle(true);
		}
	}, []);

	const handle_custom_support = (e) => {
		if (e) {
			set_show_change_modal(true);
			return;
		}
		if (!e) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Exit Custom Rule Setup',
				title: `Are you sure you want to abandon custom rules setup for cancellation charges? Rules will reset to default.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				is_error_msg: false,
				show_negative_btn: true,
				confirm_acion: () => {
					set_values_to_default();
				},
			});
		}
	};
	const set_values_to_default = () => {
		set_is_custom(false);
		set_cancellation_data([{ time: '', percentage: '', flat_amount: '' }]);
		set_default_cancellation_charges({ percentage: '', flat_amount: '' });
	};
	const set_cancel_initial_data = () => {
		if (_.isEmpty(cancellation_charges)) return;
		const default_charges = _.filter(cancellation_charges.slab, (item) => item.time == -1);
		set_default_cancellation_charges(default_charges[0]);
		const charges = _.map(
			_.filter(cancellation_charges.slab, (item) => item.time != -1),
			(item) => ({
				...item,
				time: item.time / 60,
			}),
		);

		set_cancellation_data(charges);
	};

	const handle_add_new_slot = () => {
		const _cancellation_data = _.cloneDeep(cancellation_data);
		_cancellation_data.push({
			time: '',
			percentage: '',
			flat_amount: '',
		});
		set_cancellation_data(_cancellation_data);
	};

	const handle_remove = (index) => {
		const deleted_row = _.filter(cancellation_data, (_, i) => i !== index);
		set_cancellation_data(deleted_row);
	};

	const handle_change = (index, field, value) => {
		const new_changes = _.map(cancellation_data, (row, i) => (i === index ? { ...row, [field]: value } : row));
		set_cancellation_data(new_changes);
	};

	const handle_save_info = () => {
		if (is_cancellation_charges_toggle) {
			const cancel_pricing_slabs = _.map(
				[
					{ time: '-1', flat_amount: default_cancellation_charges.flat_amount, percentage: default_cancellation_charges.percentage },
					...cancellation_data,
				],
				(item) => {
					return {
						time: item.time == '-1' ? '-1' : item.time * 60,
						flat_amount: item.flat_amount,
						percentage: item.percentage,
					};
				},
			);
			const cancel_pricing = {
				rule: 2,
				slab: cancel_pricing_slabs,
			};
			set_cancellation_charges(cancel_pricing);
		} else {
			const cancel_pricing = {
				rule: 1,
				slab: [],
			};
			set_values_to_default();
			set_cancellation_charges(cancel_pricing);
		}
		if (invite_network_id || edit_network_id) {
			set_is_invite_cancellation_price(true);
		}
	};

	const handle_surge_toggle = (e) => {
		set_is_cancellation_charges_toggle(e.target.checked);
	};

	const but_disabled = useMemo(() => {
		let value = true;
		if (is_cancellation_charges_toggle) {
			if (cancellation_data) {
				for (let i = 0; i < cancellation_data.length; i++) {
					const element = cancellation_data[i];
					const { time, percentage, flat_amount } = element;
					if (time == '' || percentage == '' || flat_amount == '') {
						value = true;
						break;
					} else value = false;
				}
				if (default_cancellation_charges?.percentage == '' || default_cancellation_charges?.flat_amount == '') {
					value = true;
				} else {
					value = false;
				}
			}
		} else {
			value = false;
		}

		return value;
	}, [cancellation_data, default_cancellation_charges, is_cancellation_charges_toggle]);

	const is_data_updated = useMemo(() => {
		let value = false;
		if (is_cancellation_charges_toggle) {
			let local_data = cancellation_data;
			const charges = _.map(
				_.filter(cancellation_charges?.slab, (item) => item.time != -1),
				(item) => ({
					...item,
					time: item.time / 60,
				}),
			);
			let context_data = charges;
			if (local_data.length != context_data.length) {
				value = true;
			}
			if (cancellation_charges) {
				for (let i = 0; i < local_data.length; i++) {
					const element = local_data[i];
					const { time, percentage, flat_amount } = element;
					if (time != context_data[i]?.time || percentage != context_data[i]?.percentage || flat_amount != context_data[i]?.flat_amount) {
						value = true;
						break;
					}
				}
			}
			if (default_cancellation_charges) {
				const default_charges = _.filter(cancellation_charges?.slab, (item) => item.time == -1);
				if (
					default_charges[0]?.percentage != default_cancellation_charges.percentage ||
					default_charges[0]?.flat_amount != default_cancellation_charges.flat_amount ||
					value
				) {
					value = true;
				} else {
					value = false;
				}
			}
		} else {
			if (!_.isEmpty(cancellation_charges) && cancellation_charges?.rule == 1) {
				value = false;
			} else {
				value = true;
			}
		}

		handle_data_updates(3, value);
		return value;
	}, [cancellation_data, default_cancellation_charges, cancellation_charges, is_cancellation_charges_toggle]);
	return (
		<div className={classes.wrapper}>
			<div className={classes.content_wrap}>
				<div className={classes.input_wrap}>
					<div className='d-flex justify-content-between align-items-center'>
						<div style={{ paddingBottom: 8 }}>
							<Text semi style={{ fontSize: 14 }}>
								Cancellation Charges
							</Text>
							<Text medium style={{ fontSize: 12, marginTop: 4, color: theme.colors.darkGrey2 }}>
								Define cancellation costs for allocated orders
							</Text>
						</div>
						<div>
							{invite_network_id && (
								<div className='d-flex align-items-center' style={{ justifyContent: 'flex-end' }}>
									<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Custom Rule
									</Text>
									<Switch
										disabled={_.get(invite_network_config, 'ownership.cancellation', '') == 'user'}
										checked={is_custom}
										onChange={(e) => {
											handle_custom_support(e.target.checked);
										}}
									/>
								</div>
							)}
							<div className='d-flex justify-content-center align-items-center'>
								<Text bold style={{ fontSize: 12, color: !is_cancellation_charges_toggle ? theme.colors.darkGrey7 : theme.colors.darkGrey2 }}>
									No Charges
								</Text>
								<Switch
									checked={is_cancellation_charges_toggle}
									onChange={(e) => handle_surge_toggle(e)}
									disabled={invite_network_id && !is_custom}
								/>
								<Text bold style={{ fontSize: 12, color: is_cancellation_charges_toggle ? theme.colors.darkGrey7 : theme.colors.darkGrey2 }}>
									Cancellation Charges
								</Text>
							</div>
						</div>
					</div>

					<div className={classes.input_section}>
						{!is_cancellation_charges_toggle && (
							<div className='align-items-center justify-content-center flex1'>
								<div className={classes.purple_strip}>
									<img src={ImageLinks.info_light_purple} width={32} height={32} />
									<Text semi className={classes.section_desc_text}>
										No cancellation charges will be applicable in this network.
									</Text>
								</div>
							</div>
						)}
						{is_cancellation_charges_toggle && (
							<>
								<div className='d-flex' style={{ padding: 16, gap: 12, flexDirection: 'column' }}>
									<div className={classes.menu_item}>
										<Text semi style={{ flex: 1.5, paddingLeft: 14, color: theme.colors.darkGrey2, fontSize: 14 }}>
											Time After Allocation
										</Text>
										<Text semi style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
											Min *
										</Text>
										<Text semi style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
											% *
										</Text>
										<Text semi style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
											Flat Rate *
										</Text>
									</div>
									<div className='d-flex' style={{ flexDirection: 'column', gap: 10 }}>
										{_.map(cancellation_data, (val, index) => {
											return (
												<div className='d-flex align-items-center'>
													<Text bold className={classes.min_range}>
														{`${index == 0 ? 0 : cancellation_data[index - 1].time} - ${
															val.time ? `${val.time} min` : index == 0 ? '.. min' : '.. min'
														}`}
													</Text>
													<div style={{ flex: 1 }}>
														<OutlinedTextField
															disabled={invite_network_id && !is_custom}
															value={val.time}
															onChange={(e) => handle_change(index, 'time', e.target.value)}
															name='weight'
															textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
															placeholder='0'
															InputProps={{
																style: { height: 37, width: '90%' },
																endAdornment: (
																	<Text
																		medium
																		className={classes.adornment_text}
																		style={{ fontSize: 14, color: theme.colors.darkGrey2, whiteSpace: 'nowrap' }}>
																		min
																	</Text>
																),
															}}
															type={'number'}
														/>
													</div>
													<div style={{ flex: 1 }}>
														<OutlinedTextField
															disabled={invite_network_id && !is_custom}
															value={val.percentage}
															onChange={(e) => handle_change(index, 'percentage', e.target.value)}
															name='weight'
															textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
															placeholder='0'
															InputProps={{
																style: { height: 37, width: '80%' },
																endAdornment: (
																	<Text
																		medium
																		className={classes.adornment_text}
																		style={{ fontSize: 14, color: theme.colors.darkGrey2, whiteSpace: 'nowrap' }}>
																		%
																	</Text>
																),
															}}
															type={'number'}
														/>
													</div>
													<div style={{ flex: 1 }}>
														<OutlinedTextField
															disabled={invite_network_id && !is_custom}
															value={val.flat_amount}
															onChange={(e) => handle_change(index, 'flat_amount', e.target.value)}
															name='price'
															textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
															placeholder='0.00'
															InputProps={{
																style: { height: 37, width: '80%' },
																startAdornment: (
																	<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2, paddingRight: 4 }}>
																		₹
																	</Text>
																),
															}}
															type={'number'}
														/>
													</div>
													<img
														src={cancellation_data.length < 2 ? ImageLinks.delete_gray : ImageLinks.delete}
														width={18}
														height={18}
														onClick={() => handle_remove(index)}
														style={{
															cursor: 'pointer',
															pointerEvents: cancellation_data.length < 2 || (invite_network_id && !is_custom) ? 'none' : 'auto',
														}}
													/>
												</div>
											);
										})}
										{!_.isEmpty(cancellation_data) && cancellation_data[cancellation_data.length - 1]?.time !== '' && (
											<div className='d-flex align-items-center'>
												<Text bold className={classes.min_range}>
													{`more ${cancellation_data[cancellation_data.length - 1]?.time} min`}
												</Text>
												<div style={{ flex: 1 }}></div>
												<div style={{ flex: 1 }}>
													<OutlinedTextField
														disabled={invite_network_id && !is_custom}
														value={default_cancellation_charges?.percentage}
														onChange={(e) =>
															set_default_cancellation_charges({
																percentage: e.target.value,
																flat_amount: default_cancellation_charges?.flat_amount,
															})
														}
														name='weight'
														textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
														placeholder='0'
														InputProps={{
															style: { height: 37, width: '80%' },
															endAdornment: (
																<Text
																	medium
																	className={classes.adornment_text}
																	style={{ fontSize: 14, color: theme.colors.darkGrey2, whiteSpace: 'nowrap' }}>
																	%
																</Text>
															),
														}}
														type={'number'}
													/>
												</div>
												<div style={{ flex: 1 }}>
													<OutlinedTextField
														disabled={invite_network_id && !is_custom}
														value={default_cancellation_charges?.flat_amount}
														onChange={(e) =>
															set_default_cancellation_charges({
																flat_amount: e.target.value,
																percentage: default_cancellation_charges?.percentage,
															})
														}
														name='price'
														textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
														placeholder='0.00'
														InputProps={{
															style: { height: 37, width: '80%' },
															startAdornment: (
																<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2, paddingRight: 4 }}>
																	₹
																</Text>
															),
														}}
														type={'number'}
													/>
												</div>
												<img
													src={ImageLinks.add_pruple_no_fill}
													width={18}
													height={18}
													onClick={handle_add_new_slot}
													style={{ cursor: 'pointer', pointerEvents: invite_network_id && !is_custom ? 'none' : 'auto' }}
												/>
											</div>
										)}
									</div>
								</div>
								<div className={classes.notes_wrapper}>
									<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Note: Settings can be customized at invite level
									</Text>
								</div>
							</>
						)}
					</div>
				</div>
				<div className={classes.output_wrap}>
					<div className={classes.output_section}>
						<CancellationCahngesInfo />
					</div>
				</div>
			</div>
			{show_change_modal && (
				<ConfirmClose
					headerTitle='Set Custom Cancellation Charges'
					title='Are you sure you want to set custom custom cancellation charges for this participant? All default config will not be considered.'
					show_negative_btn={true}
					close={() => set_show_change_modal(false)}
					on_confirm={() => set_is_custom(true)}
				/>
			)}
			<SectionCta
				title={`Save Charges`}
				is_data_updated={is_data_updated}
				is_disabled={but_disabled}
				handle_click={handle_save_info}
				total_steps={1}
				lower_section_index={4}
				handle_data_updates={handle_data_updates}
				complete_btn_title={'Setup Charges'}
				handle_api_save={handle_cancellation_charges_api}
			/>
		</div>
	);
};
export default CanellationCharges;
