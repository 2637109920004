import Text from 'modules/Text';
import React, { useContext, useEffect, useRef, useState } from 'react';
import SavedAddresses from './modals/SavedAddresses';
import { Divider, ListItem, makeStyles } from '@material-ui/core';
import AddressInput from 'modules/modals/AddressInput';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import CreateOrderContext from 'views/CreateOrderFlow/CreateOrder/context';
import AddressContactDetailsModal from 'modules/modals/AddressContactDetailsModal';
import _ from 'lodash';
import utils from 'resources/utils';
import constant from 'resources/constant';

const ADDRESS_TYPE = {
	saved: 'saved',
	new: 'new',
	recent: 'recent',
};

const useStyles = makeStyles((theme) => ({
	selected_list_item: {
		padding: 12,
		border: `1px solid ${theme.colors.primary}`,
		borderRadius: 6,
		boxShadow: '0px 0px 6px 1px rgba(178, 157, 226, 0.5)',
		backgroundColor: theme.colors.white,
		marginRight: 12,
		width: 360,
		position: 'relative',
	},
	address_text: { fontSize: 12, color: theme.colors.lightGrey9, marginTop: 4 },
	pickup_icon_container: {
		marginTop: 12,
		display: 'flex',
	},
	pickup_icon_box: {
		borderRadius: 6,
		padding: 24,
		minWidth: 140,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
		background: theme.colors.white,
		border: `1px solid ${theme.colors.lightPurple}`,
		'&:hover': {
			boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
			opacity: 0.7,
		},
	},
	tick_icon_alignment: {
		position: 'absolute',
		right: -8,
		top: -8,
		cursor: 'pointer',
		'&:hover': {
			'& .cross-icon': {
				display: 'block !important',
			},
			'& .tick-icon': {
				display: 'none',
			},
		},
	},
	cross_icon: {
		background: theme.colors.lightRed,
		borderRadius: '50%',
		padding: '4px 4px',
		display: 'flex',
		alignItems: 'center',
	},
}));

const PickupDetails = () => {
	const {
		zone_id,
		handle_step,
		selected_pickup_address,
		handle_selected_pickup_address,
		sender_name,
		sender_contact,
		handle_sender_name,
		handle_sender_contact,
		handle_zone_id,
	} = useContext(CreateOrderContext);

	// different modal states
	const [saved_address_modal, set_saved_address_modal] = React.useState(false);
	const [new_pickup_address_modal, set_new_pickup_address_modal] = React.useState(false);
	const [pickup_details_modal, set_pickup_details_modal] = React.useState(false);
	const [is_addr_input_modal_in_edit_mode, set_is_addr_input_modal_in_edit_mode] = useState(false);
	// local pickup data states to hold data till whole pick up info is gathered.
	const [selected_pickup_address_type, set_selected_pickup_address_type] = React.useState('');
	const [local_pickup_address, set_local_pickup_address] = React.useState(null);
	const [local_pickup_contact, set_local_pickup_contact] = React.useState(null);
	const is_new_opened_from_saved = useRef(null);

	const classes = useStyles();

	useEffect(() => {
		if (!saved_address_modal && !new_pickup_address_modal && !pickup_details_modal) clear_local_data();
	}, [saved_address_modal, new_pickup_address_modal, pickup_details_modal]);

	const on_address_select = (address, address_type) => {
		if (address_type === ADDRESS_TYPE.recent) {
			handle_selected_pickup_address(address);
			clear_local_data();
			return;
		}
		let _addr = _.cloneDeep(address);
		if (address_type === ADDRESS_TYPE.saved) {
			_addr = {
				..._addr,
				coordinates: _addr.exact_location,
			};
		}

		set_selected_pickup_address_type(address_type);
		set_local_pickup_address(_addr);
		set_pickup_details_modal(true);
	};

	const handle_pickup_details = (name, contact) => {
		handle_selected_pickup_address(local_pickup_address);
		handle_sender_name(name);
		handle_sender_contact(contact);
		set_pickup_details_modal(false);
		clear_local_data();
		handle_step(2);
	};

	const clear_local_data = () => {
		set_local_pickup_address(null);
		set_local_pickup_contact(null);
	};

	const handle_contact_back_nav = () => {
		set_pickup_details_modal(false);
		selected_pickup_address_type === ADDRESS_TYPE.new ? set_new_pickup_address_modal(true) : set_saved_address_modal(true);
	};

	const handle_addr_back_nav = () => {
		set_new_pickup_address_modal(false);
		if (is_new_opened_from_saved.current) set_saved_address_modal(true);
	};

	const handle_edit_contact = () => {
		set_pickup_details_modal(true);
		set_local_pickup_address(selected_pickup_address);
		set_local_pickup_contact({
			sender_name,
			sender_contact,
		});
	};

	const handle_edit_addr = () => {
		set_is_addr_input_modal_in_edit_mode(true);
		set_new_pickup_address_modal(true);
		set_local_pickup_address({
			selected_place: {
				city: '',
				name: '',
				state: selected_pickup_address.state,
				pincode: selected_pickup_address.pincode,
				formatted_address: selected_pickup_address.google_maps_address
					? selected_pickup_address.google_maps_address
					: selected_pickup_address.address_line1,
				geometry: {
					location: new window.google.maps.LatLng(
						Number(selected_pickup_address.coordinates.latitude),
						Number(selected_pickup_address.coordinates.longitude),
					),
				},
			},
			address_line1: selected_pickup_address.address_line1 ? selected_pickup_address.address_line1 : '',
			instructions: selected_pickup_address.instructions_to_reach ? selected_pickup_address.instructions_to_reach : '',
			address_name: selected_pickup_address.label ? selected_pickup_address.label : '',
		});
	};

	const handle_contact_modal_close = () => {
		clear_local_data();
		set_pickup_details_modal(false);
	};

	return (
		<div className={classes.wrapper}>
			<Text semi style={{ fontSize: 18 }}>
				Pickup Details
			</Text>
			<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
				Select location where packages will be picked up
			</Text>

			<div className={classes.pickup_icon_container}>
				{!_.isEmpty(selected_pickup_address) && (
					<div className={classes.selected_list_item}>
						<div className={classes.tick_icon_alignment}>
							<img className='tick-icon' src={ImageLinks.tickCirclePurpleSolid} alt='tick-icon' width={20} height={20} />
							<div className='cross-icon' style={{ display: 'none' }}>
								<div
									className={classes.cross_icon}
									onClick={() => {
										set_saved_address_modal(true);
									}}>
									<img src={ImageLinks.crossRed} alt='cross' width={'12px'} height={'12px'} />
								</div>
							</div>
						</div>
						<div className='d-flex justify-content-between'>
							<div>
								{!_.isEmpty(_.get(selected_pickup_address, 'label', null)) ? (
									<>
										<Text bold style={{ fontSize: 14 }}>
											({_.get(selected_pickup_address, 'id', '')}) {_.get(selected_pickup_address, 'label', '') || '-'}
										</Text>
										<Text className={classes.address_text}>
											{selected_pickup_address.address_line1} {selected_pickup_address.state} {selected_pickup_address.pincode}
										</Text>
									</>
								) : (
									<>
										<Text bold style={{ fontSize: 14 }}>
											{_.get(selected_pickup_address, 'address_line1', '')}
										</Text>
										<Text className={classes.address_text}>
											{selected_pickup_address.state} {selected_pickup_address.pincode}
										</Text>
									</>
								)}
							</div>
							<div>
								<img src={ImageLinks.edit} alt='edit' width={16} height={16} onClick={handle_edit_addr} className='cursor-pointer' />
							</div>
						</div>
						<Divider style={{ marginTop: 8, marginBottom: 8, backgroundColor: theme.colors.lightPurple }} />
						<div className='d-flex justify-content-between'>
							<div className='d-flex align-items-center'>
								<div className='d-flex align-items-center'>
									<img src={ImageLinks.user} alt='person' width={14} height={14} />
									<Text style={{ fontSize: 12, marginLeft: 4 }}>{sender_name || '-'}</Text>
								</div>
								<div className='d-flex align-items-center' style={{ marginLeft: 30 }}>
									<img src={ImageLinks.phone} width={14} height={14} />
									<Text style={{ fontSize: 12, marginLeft: 4 }}>{sender_contact || '-'}</Text>
								</div>
							</div>
							<div>
								<img src={ImageLinks.edit} className='cursor-pointer' alt='edit' width={16} height={16} onClick={handle_edit_contact} />
							</div>
						</div>
					</div>
				)}
				<div
					className={classes.pickup_icon_box}
					style={{ marginRight: 12 }}
					onClick={() => {
						utils.track_event_for_analytics(constant.TRACKING_EVENTS.CREATE_ORDER_SAVED_CLICKED);
						set_saved_address_modal(true);
					}}>
					<img src={ImageLinks.home_purple} width={'20px'} height={'20px'} style={{ marginBottom: 8 }} />
					<Text bold style={{ fontSize: 14 }}>
						Saved
					</Text>
				</div>
				<div
					className={classes.pickup_icon_box}
					onClick={() => {
						set_is_addr_input_modal_in_edit_mode(false);
						set_new_pickup_address_modal(true);
						is_new_opened_from_saved.current = false;
						utils.track_event_for_analytics(constant.TRACKING_EVENTS.CREATE_ORDER_NEW_CLICKED);
					}}>
					<img src={ImageLinks.plusPurple} width={'20px'} height={'20px'} style={{ marginBottom: 8 }} />
					<Text bold style={{ fontSize: 14 }}>
						New
					</Text>
				</div>
			</div>

			{saved_address_modal && (
				<SavedAddresses
					title='Select Pickup Address'
					on_address_select={on_address_select}
					close={() => set_saved_address_modal(false)}
					address_type={ADDRESS_TYPE.saved}
					clear_local_data={clear_local_data}
					edit_details={local_pickup_address}
					on_new_addr_tab_press={() => {
						set_saved_address_modal(false);
						set_is_addr_input_modal_in_edit_mode(false);
						set_new_pickup_address_modal(true);
						is_new_opened_from_saved.current = true;
					}}
				/>
			)}

			{new_pickup_address_modal && (
				<AddressInput
					zone_id={zone_id}
					marker_img={ImageLinks.rider_pickup}
					on_address_select={on_address_select}
					close={() => set_new_pickup_address_modal(false)}
					address_type={!is_addr_input_modal_in_edit_mode ? ADDRESS_TYPE.new : ADDRESS_TYPE.recent}
					title={is_addr_input_modal_in_edit_mode ? 'Edit Pickup Address' : 'New Pickup Address'}
					clear_local_data={clear_local_data}
					edit_details={local_pickup_address}
					show_save_as={false}
					show_back_button={!is_addr_input_modal_in_edit_mode}
					handle_back_nav={handle_addr_back_nav}
					track_data_changes={is_addr_input_modal_in_edit_mode}
					handle_zone_update={(val) => {
						handle_zone_id(val);
					}}
				/>
			)}

			{pickup_details_modal && (
				<AddressContactDetailsModal
					title={_.isEmpty(local_pickup_contact) ? 'Sender Details' : 'Edit Sender Details'}
					handle_pickup_details={handle_pickup_details}
					close={handle_contact_modal_close}
					pickup_address={local_pickup_address}
					local_pickup_contact={local_pickup_contact}
					clear_local_data={clear_local_data}
					action_on_back_button={handle_contact_back_nav}
					show_back_button={_.isEmpty(local_pickup_contact)}
				/>
			)}
		</div>
	);
};

export default PickupDetails;
