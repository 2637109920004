import { ListItem, makeStyles } from '@material-ui/core';
import React from 'react';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';

import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Routes from 'resources/Routes';

const useStyles = makeStyles({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between', padding: '30px 30px 25px 30px' },
	header_text: { fontSize: 22, marginRight: 50 },
	table_header: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		paddingLeft: 12,
		paddingRight: 12,
		minHeight: 45,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
	},
	table_row: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		paddingLeft: 12,
		paddingRight: 12,
		minHeight: 45,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
		},
	},
	empty_wrap: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '5px 30px 30px 30px',
	},
	empty_title: { fontSize: 24, lineHeight: '30px', width: 470, textAlign: 'center' },
	content_wrap: { flex: 1, display: 'flex', overflowY: 'auto', padding: '5px 30px 30px 30px' },
	rcmd_wrap: {
		width: '100%',
		padding: 16,
		border: `1px solid ${theme.colors.lightPurple6}`,
		borderRadius: 5,
		background: theme.colors.white,
		marginTop: 16,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	rcmd_desc: {
		color: theme.colors.darkGrey2,
		fontSize: 12,
		marginTop: 5,
		width: '100%',
		paddingBottom: 8,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	rcmd_action_wrap: { marginTop: 8, width: '100%', display: 'flex', justifyContent: 'space-between' },
	team_item_wrap: {
		height: 25,
		paddingLeft: 8,
		paddingRight: 8,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		border: `1px solid ${theme.colors.lightPurple7}`,
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		marginRight: 8,
	},
	channel_wrap: { flex: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
	comm_name_text: { flex: 0.3, color: theme.colors.primary, fontSize: 14, textDecoration: 'underline' },
});

const static_date = [
	{
		id: 1,
		name: 'Order, Order Status: Out for Delivery',
		recipient: 'Customer',
		channel: ['SMS', 'Whatsapp'],
	},
	{
		id: 2,
		name: 'Order, Order Status: Out for Delivery',
		recipient: 'Customer',
		channel: ['SMS', 'Whatsapp'],
	},
	{
		id: 3,
		name: 'Order, Order Status: Out for Delivery',
		recipient: 'Customer',
		channel: ['SMS', 'Whatsapp'],
	},
	{
		id: 4,
		name: 'Order, Order Status: Out for Delivery',
		recipient: 'Customer',
		channel: ['SMS', 'Whatsapp'],
	},
];

const CommManagement = ({ history }) => {
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<div className={classes.header}>
				<div>
					<Text semi className={classes.header_text}>
						Communication Automation
					</Text>
				</div>

				{static_date.length > 0 && (
					<div>
						<ContainedButton
							onClick={() => {
								history.push(Routes.CREATE_COMMUNICATION.path);
							}}
							style={{ borderRadius: 50 }}>
							<img src={ImageLinks.addWhite} alt='' style={{ width: 12, height: 12 }} />
							<Text style={{ marginLeft: 10, fontSize: 18 }}>Create Communication</Text>
						</ContainedButton>
					</div>
				)}
			</div>
			{static_date.length === 0 && (
				<div className={classes.empty_wrap}>
					<Text bold className={classes.empty_title}>
						Deliver the right message to the right stakeholder - automatically!
					</Text>
					<div style={{ width: 500 }}>
						<img src={ImageLinks.bubble1} style={{ float: 'right', marginTop: 40, marginBottom: 40 }} width={380} alt='' />
						<img src={ImageLinks.bubble2} style={{ float: 'left', marginBottom: 40 }} width={380} alt='' />
					</div>

					<ContainedButton
						onClick={() => {
							history.push(Routes.CREATE_COMMUNICATION.path);
						}}
						style={{ borderRadius: 50 }}>
						<img src={ImageLinks.addWhite} alt='' style={{ width: 12, height: 12 }} />
						<Text style={{ marginLeft: 10, fontSize: 16 }}>Create Communication</Text>
					</ContainedButton>
				</div>
			)}
			{static_date.length > 0 && (
				<div className={classes.content_wrap}>
					<div style={{ flex: 1 }}>
						<div className={classes.table_header}>
							<Text bold style={{ flex: 0.3, color: theme.colors.darkGrey2, fontSize: 14 }}>
								Communication Name
							</Text>
							<Text bold style={{ flex: 0.2, color: theme.colors.darkGrey2, fontSize: 14 }}>
								Recipients
							</Text>
							<Text bold style={{ flex: 0.5, color: theme.colors.darkGrey2, fontSize: 14 }}>
								Media
							</Text>
						</div>

						<div>
							{_.map(static_date, (order, key) => {
								return (
									<div key={`comm_${key}`} className={classes.table_row}>
										<Text semi className={classes.comm_name_text}>
											{order.name}
										</Text>
										<Text medium style={{ flex: 0.2, fontSize: 14 }}>
											{order.recipient}
										</Text>
										<div className={classes.channel_wrap}>
											<div style={{ flexWrap: 'wrap', display: 'flex' }}>
												{_.map(order.channel, (team, index) => (
													<div key={`comm_team_${index}`} className={classes.team_item_wrap}>
														<Text medium style={{ fontSize: 14, color: theme.colors.lightPurple7 }}>
															{team}
														</Text>
													</div>
												))}
											</div>
											<div>
												<img src={ImageLinks.edit} width={16} height={16} />
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					{/* <div style={{ width: 280, marginLeft: 40 }}>
						<Text bold style={{ fontSize: 18 }}>
							Recommended Communications
						</Text>
						{_.map(
							[
								{
									title: 'Package arriving today',
									subtitle: 'Real time out-for-delivery message for your customers.',
									action_text: 'Reduces Failed Delivery by 50%',
								},
							],
							(recc_comm, key) => {
								return (
									<ListItem key={`recc_comm_${key}`} button className={classes.rcmd_wrap}>
										<Text medium style={{ fontSize: 16, width: '100%' }}>
											{recc_comm.title}
										</Text>
										<Text medium className={classes.rcmd_desc}>
											{recc_comm.subtitle}
										</Text>
										<div className={classes.rcmd_action_wrap}>
											<Text medium style={{ color: theme.colors.primary, fontSize: 12 }}>
												{recc_comm.action_text}
											</Text>
											<img src={ImageLinks.arrow_right_purple} alt='' />
										</div>
									</ListItem>
								);
							},
						)}
					</div> */}
				</div>
			)}
		</div>
	);
};

export default CommManagement;
