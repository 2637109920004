import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';

import Text from 'modules/Text';
import theme from 'resources/theme';
import OrderLabel from 'modules/OrderLabel';
import { ALL_MENU_ITEMS } from 'views/ChildBusinessFlow/helper';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import ContainedButton from 'modules/ContainedButton';
import constant from 'resources/constant';
import api_requests from 'resources/api_requests';
import utils from 'resources/utils';

const useStyles = makeStyles((theme) => ({
	container: {
		borderRadius: 12,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		flex: 1,
		backgroundColor: theme.colors.white,
		width: '100%',
		minHeight: '70vh',
		maxHeight: '80vh',
		overflow: 'hidden',
	},
	action_bar_wrap: {
		backgroundColor: 'rgba(242, 238, 248, 0.30)',
		padding: 12,
		borderBottom: `1px solid ${theme.colors.lightPink4}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	action_text: {
		width: 'auto',
		color: theme.colors.lightGrey2,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	header_text: {
		fontSize: 16,
		color: theme.colors.darkGrey7,
	},
	stat_divider: { width: 1, height: '50px', backgroundColor: theme.colors.lightGrey5 },
	business_order_wrap: {
		display: 'flex',
		padding: '1rem',
		gap: '14px',
		borderRadius: '0.5rem 0.5rem 0rem 0rem',
		background: 'rgba(242, 238, 248, 0.70)',
		margin: '1rem',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	detailsTextContainer: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	header_title_2: {
		flex: 2,
		color: theme.colors.darkGrey2,
		fontSize: 14,
	},
	pickedUpContainer: {
		padding: '2px 2px 0 2px',
		marginTop: 10,
		marginBottom: 10,
		position: 'relative',
		marginRight: '1rem',
		marginLeft: '1rem',
		maxHeight: '80vh',
	},
	pickedUpItem: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	stat_header: { fontSize: '1.375rem', width: '90%' },
	stat_type: { fontSize: 12 },
	table_item_wrap_2: { flex: 2, minWidth: 0 },
	orderDetailsClickabletext: {
		flex: 1,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	truncate: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 14,
	},
	stat_items: { display: 'flex', cursor: 'pointer' },
	order_listing: {
		overflowY: 'auto',
		maxHeight: '50vh',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
}));

function BusinessDetails({ business_stats, handle_dashbord, selected_business }) {
	const classes = useStyles();

	const [show_order_details, set_show_order_details] = useState(false);
	const [selected_pb_id, set_selected_pb_id] = useState('');
	const [parent_api_headers, set_parent_api_headers] = useState({});
	const [selected_item, set_selected_item] = useState('overview');
	const [business_order, set_business_order] = useState([]);
	const [order_list, set_order_list] = useState([]);
	const loading_scroll = useRef(false);

	const slider_ref = useRef(null);

	useEffect(() => {
		set_selected_item('overview');
	}, [business_stats]);

	useEffect(() => {
		if (slider_ref.current) slider_ref.current.scrollTo(0, 0);
		if (!_.isEmpty(selected_business)) get_business_order(selected_business.id, null);
	}, [selected_item, selected_business]);

	const get_business_order = async (id, token = null) => {
		let params = {
			group: selected_item,
			limit: 50,
			...(token && { token }),
		};
		try {
			const res = await api_requests.get_child_business_order(id, params);
			if (res) loading_scroll.current = false;
			if (res.items) {
				set_business_order(res.items);
			}
			if (token) {
				set_order_list(_.concat(order_list, res.items.items));
			} else {
				set_order_list(res.items.items);
			}
		} catch (err) {}
	};

	const on_order_list_scroll_complete = (token) => {
		get_business_order(selected_business.id, token);
	};

	const slider_scrolled = () => {
		if (
			slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight &&
			business_order.next &&
			!loading_scroll.current
		) {
			loading_scroll.current = true;
			on_order_list_scroll_complete(business_order.next);
		}
	};
	return (
		<div style={{ flex: 1 }}>
			<div className={classes.container}>
				<div className={classes.action_bar_wrap}>
					<Text medium style={{ fontSize: 14 }}>
						Quick Actions
					</Text>
					<div className='d-flex' style={{ gap: 10 }}>
						<ContainedButton style={{ height: 26, borderRadius: 30 }} onClick={handle_dashbord}>
							<Text semi style={{ fontSize: 12 }}>
								Dashboard
							</Text>
						</ContainedButton>
					</div>
				</div>
				<div className={classes.business_order_wrap}>
					<div style={{ marginRight: 'auto' }}>
						<div style={{ width: '100%' }}>
							<Text className={classes.stat_type} semi>
								Overview
							</Text>
							<Text className={classes.stat_type} semi>
								Today
							</Text>
						</div>
					</div>
					{_.map(ALL_MENU_ITEMS, (menu_item, key) => {
						return (
							<>
								<div
									key={`order_menu${key}`}
									className={classes.stat_items}
									onClick={() => {
										set_selected_item(menu_item.id);
									}}>
									<div style={{ width: '100%' }}>
										<Text className={classes.stat_header} bold style={{ color: selected_item == menu_item.id ? theme.colors.primary : '' }}>
											{_.get(business_stats, `${menu_item.id == 'overview' ? 'total_order' : menu_item.id}`, '0')}
										</Text>
										<Text className={classes.stat_type} semi>
											{menu_item.text}
										</Text>
									</div>
								</div>
								{key !== ALL_MENU_ITEMS.Completed.text && <div className={classes.stat_divider}></div>}
							</>
						);
					})}
				</div>
				<div className={classes.pickedUpContainer}>
					<div className={classes.detailsTextContainer} style={{ position: 'sticky', top: '0px' }}>
						<Text bold className={classes.header_title_2}>
							Order Date
						</Text>
						<Text bold className={classes.header_title_2}>
							Order Id
						</Text>
						<Text bold className={classes.header_title_2}>
							Order Status
						</Text>
					</div>
					<div className={classes.order_listing} ref={slider_ref} onScroll={slider_scrolled}>
						{order_list.length > 0 ? (
							_.map(order_list, (order, key) => {
								const channel_ref_id_string = order.reference_id || order?.dd_channel?.order_id || order.id || '-';
								return (
									<div key={`order_${key}`} className={classes.pickedUpItem}>
										<div className={classes.table_item_wrap_2}>{moment(order?.created_at).format('DD/MM/YY')}</div>
										<div className={classes.table_item_wrap_2}>
											<Text
												semi
												className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
												onClick={() => {
													if (!order.id) return;
													set_selected_pb_id(order.id);
													set_show_order_details(true);
													const owner_id = utils._retrieveData(constant.OWNER_ID);
													const obj = {
														['owner-id']: selected_business.id,
														['parent-id']: owner_id,
														['type-of-owner']: 4,
													};
													set_parent_api_headers(obj);
												}}>
												{channel_ref_id_string}
											</Text>
										</div>
										<div className={classes.table_item_wrap_2}>
											{order?.status !== 1 ? (
												<OrderLabel
													status={order.status == 0 ? 0 : constant.ORDER_STATUS[_.get(order, 'fulfillment.status', null)]?.id}
												/>
											) : (
												'-'
											)}
										</div>
									</div>
								);
							})
						) : (
							<div key={`order_${0}`} className={classes.pickedUpItem}>
								<div className={classes.table_item_wrap_2}>-</div>
								<div className={classes.table_item_wrap_2}>-</div>
								<div className={classes.table_item_wrap_2}>-</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{show_order_details && (
				<OrderModal
					close={() => {
						set_selected_pb_id('');
						set_show_order_details(false);
					}}
					id={selected_pb_id}
					parent_api_headers={parent_api_headers}
					updater={() => {}}
				/>
			)}
		</div>
	);
}

export default BusinessDetails;
