import ImageLinks from 'assets/images/ImageLinks';

export const subject_options = [
	{
		key: 'rider',
		title: 'Rider',
		icon: ImageLinks.rider_black,
	},
	{
		key: 'business',
		title: 'Brand',
		icon: ImageLinks.bag_black,
	},
	{
		key: 'order',
		title: 'Order',
		icon: ImageLinks.box2_black,
	},
	{
		key: 'pwp',
		title: 'PWP',
		icon: ImageLinks.route_z_black,
	},
	{
		key: 'trace',
		title: 'Trace',
		icon: ImageLinks.rider_marker,
	},
	{
		key: 'customer',
		title: 'Customer',
		icon: ImageLinks.user_black,
		locked: true,
	},
];
