import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import { Pie } from 'react-chartjs-2';
import _ from 'lodash';
import EmptyState from '../EmptyState';

const useStyles = makeStyles({
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
	analytics_container: {
		padding: 12,
		borderRadius: 12,
		background: theme.colors.white,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
	},
	link_performance_content_container: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: 24,
	},
	link_info_content: {
		textAlign: 'center',
		padding: 14,
	},
	link_info_sub_text: {
		color: theme.colors.darkPurple,
		background: theme.colors.lightPink,
		borderRadius: 16,
		marginTop: 4,
		display: 'inline-block',
		padding: '4px 8px',
	},
	link_open_sub_text: {
		color: theme.colors.primary,
		background: theme.colors.lightPurple,
		borderRadius: 16,
		marginTop: 4,
		display: 'inline-block',
		padding: '4px 8px',
	},
	dot_content: {
		width: 8,
		height: 8,
		borderRadius: '50%',
	},
	pie_chart_container: {
		width: 148,
		height: 148,
		margin: 'auto',
		marginTop: 8,
	},
});

const DeliveryFeedback = ({ feedback }) => {
	const classes = useStyles();
	const colors = [theme.colors.lightPink5, theme.colors.lightGreen9, theme.colors.darkGreen1, theme.colors.darkYellow];

	const labels = ['Not Good', 'Ok', 'Great', 'No Response'];

	const data = {
		labels: labels,
		datasets: [
			{
				data: _.map(feedback, (feedback_data) => {
					return feedback_data;
				}),
				backgroundColor: colors,
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: true,
			},
			datalabels: {
				display: false,
			},
		},
		maintainAspectRatio: true,
	};

	return (
		<Grid container className={classes.analytics_container} spacing={2}>
			{!_.isEmpty(feedback) ? (
				<>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<Text bold style={{ fontSize: 14 }}>
							Delivery Feedback
						</Text>
						<Divider className={classes.divider} />
						<div className='justify-content-between d-flex' style={{ marginBottom: 14 }}>
							<div className='align-items-center'>
								<div className={classes.dot_content} style={{ background: theme.colors.lightPink5 }} />
								<img src={ImageLinks.face_emogi} alt='face-emogi' width={'16px'} height={'16px'} style={{ marginLeft: 4, marginRight: 4 }} />
								<Text bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Not Good
								</Text>
							</div>
							<div style={{ paddingRight: 32 }}>
								<Text style={{ fontSize: 12 }}>{_.get(feedback, 'not_good', null)}</Text>
							</div>
						</div>
						<div className='justify-content-between d-flex' style={{ marginBottom: 14 }}>
							<div className='align-items-center'>
								<div className={classes.dot_content} style={{ background: theme.colors.lightGreen9 }} />
								<img src={ImageLinks.smile_emogi} alt='smile-emogi' width={'16px'} height={'16px'} style={{ marginLeft: 4, marginRight: 4 }} />
								<Text bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									OK
								</Text>
							</div>
							<div style={{ paddingRight: 32 }}>
								<Text style={{ fontSize: 12 }}>{_.get(feedback, 'ok', null)}</Text>
							</div>
						</div>
						<div className='justify-content-between d-flex' style={{ marginBottom: 14 }}>
							<div className='align-items-center'>
								<div className={classes.dot_content} style={{ background: theme.colors.darkGreen1 }} />
								<img
									src={ImageLinks.heart_eye_emogi}
									alt='heart-eye-emogi'
									width={'16px'}
									height={'16px'}
									style={{ marginLeft: 4, marginRight: 4 }}
								/>
								<Text bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Great
								</Text>
							</div>
							<div style={{ paddingRight: 32 }}>
								<Text style={{ fontSize: 12 }}>{_.get(feedback, 'great', null)}</Text>
							</div>
						</div>
						<div className='justify-content-between d-flex'>
							<div className='align-items-center'>
								<div className={classes.dot_content} style={{ background: theme.colors.darkYellow }} />
								<img
									src={ImageLinks.grey_circle_with_line}
									alt='grey-circle-with-line'
									width={'16px'}
									height={'16px'}
									style={{ marginLeft: 4, marginRight: 4 }}
								/>
								<Text bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									No Response
								</Text>
							</div>
							<div style={{ paddingRight: 32 }}>
								<Text style={{ fontSize: 12 }}>{_.get(feedback, 'no_response', null)}</Text>
							</div>
						</div>
					</Grid>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<div className={classes.pie_chart_container}>
							<Pie data={data} options={options} />
						</div>
					</Grid>
				</>
			) : (
				<div style={{ width: '100%', height: '100%' }}>
					<Text bold style={{ fontSize: 14 }}>
						Delivery Feedback
					</Text>
					<Divider className={classes.divider} />
					<EmptyState />
				</div>
			)}
		</Grid>
	);
};

export default DeliveryFeedback;
