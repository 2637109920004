import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useContext } from 'react';
import theme from 'resources/theme';
import utils from 'resources/utils';
import CreateNetworkContext from 'views/NetworksFlow/context';

const useStyles = makeStyles(() => ({
	output_section: {
		padding: 16,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 16px rgba(102, 102, 102, 0.1)`,
		borderRadius: 12,
		flex: 1,
		maxHeight: 390,
		marginRight: 4,
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			background: theme.colors.lightGrey13,
			width: 8,
		},

		/* Track */
		'&::-webkit-scrollbar-track': {
			width: 4,
			background: theme.colors.lightGrey13,
			borderRadius: 60,
		},

		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: theme.colors.darkPurple,
			borderRadius: 60,
		},
	},
	pincode_header_delete: {
		'&:hover': {
			'& .pincode_header_delete_text': {
				display: 'block !important',
			},
		},
	},
	pincode_scroll: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: 12,
	},
	valid_pin_wrap: {
		flexBasis: '33.33333%',
		height: 41,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		display: 'flex',
		alignItems: 'center',
	},
	valid_pin_item: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 4,
		height: 25,
		paddingLeft: 4,
		paddingRight: 4,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.colors.lightPink4,
			'& .pincode_item_delete': {
				display: 'block !important',
			},
		},
	},

	empty_pin_content_wrap: {
		borderRadius: 4,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		height: 120,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 10,
		paddingRight: 40,
	},
	empty_wrap: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' },
	empty_desc: {
		fontSize: 10,
		color: theme.colors.primary,
		marginTop: 4,
	},
}));

const PincodesOutput = ({ valid_pincodes, handle_all_pincodes_delete, handle_individual_pincode_delete, disabled = false }) => {
	const { invite_network_id, edit_network_id } = useContext(CreateNetworkContext);
	const classes = useStyles();
	return (
		<div className={classes.output_section}>
			<div className='align-items-center justify-content-between'>
				<Text bold style={{ fontSize: 16 }}>
					{valid_pincodes?.length > 0 ? valid_pincodes?.length : '-'} Pincodes
				</Text>
				{valid_pincodes?.length > 0 && (
					<div onClick={handle_all_pincodes_delete} className={clsx(classes.pincode_header_delete, 'align-items-center', 'cursor-pointer')}>
						<img src={ImageLinks.delete} width={16} height={16} />
						<Text semi className={'pincode_header_delete_text'} style={{ fontSize: 12, color: theme.colors.red, display: 'none' }}>
							Delete All
						</Text>
					</div>
				)}
			</div>
			<div className={'dashed-line'}></div>

			{valid_pincodes?.length === 0 && (
				<div className={classes.empty_wrap}>
					<div className={classes.empty_pin_content_wrap}>
						<img src={ImageLinks.rider_light_purple} width={32} height={32} />
						<div style={{ marginLeft: 12 }}>
							<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
								PINCODES
							</Text>
							<Text medium className={classes.empty_desc}>
								Correct 6 digit pincodes help to define accurate serviceable areas.
							</Text>
						</div>
					</div>
				</div>
			)}
			{valid_pincodes?.length > 0 && (
				<div className={classes.pincode_scroll}>
					{_.map(valid_pincodes, (valid_item, valid_index) => (
						<div key={`valid_pin_${valid_index}`} className={classes.valid_pin_wrap}>
							<div
								className={classes.valid_pin_item}
								onClick={() => {
									if (!disabled) handle_individual_pincode_delete(valid_index);
								}}>
								<Text semi style={{ fontSize: 14, marginRight: 30 }}>
									{valid_item}
								</Text>
								{!disabled && (
									<img className='pincode_item_delete' style={{ display: 'none' }} src={ImageLinks.delete} width={16} height={16} />
								)}
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default PincodesOutput;
