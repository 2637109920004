import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Button from 'modules/Button';
import { ROUTE_POINTS } from '../helper';
import Routes from 'resources/Routes';
import utils from 'resources/utils';
import constant from 'resources/constant';
import { useMemo } from 'react';

const useStyles = makeStyles({
	wrapper: {
		position: 'absolute',
		alignItems: 'center',
		zIndex: 1000,
		left: '23%',
		marginTop: 36,
		background: theme.colors.white,
		borderRadius: 12,
		width: 783,
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		padding: 20,
		justifyContent: 'space-around',
	},
	line_bar: {
		width: 1,
		height: 40,
		background: theme.colors.lightPurple14,
	},
	animatedItem: {
		animation: `$myEffect 1000ms`,
		animationFillMode: 'both',
		animationTimingFunction: 'ease-in-out',
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 1,
			transform: 'translateY(50%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateY(-20%)',
		},
	},

	route_container: {
		borderRadius: 8,
		backgroundColor: theme.colors.white,
		height: 91,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 18,
	},
	title_text: { fontSize: 16, color: theme.colors.darkGrey2, marginLeft: 4 },
	point_item: {
		height: 26,
		borderRadius: 60,
		padding: '4px 12px',
		marginRight: 8,
		cursor: 'pointer',
	},
	route_divider: {
		marginLeft: 40,
		marginRight: 40,
		height: 40,
		width: 2,
		backgroundColor: theme.colors.lightPurple14,
	},
});

const FindMyRoutes = ({
	selected_point,
	set_selected_point,
	selected_rider,
	set_selected_rider,
	generate_random_routes_points,
	create_route,
	handle_create_routes,
	set_create_route,
}) => {
	const classes = useStyles();
	const history = useHistory();

	const { totalDistance, totalTime } =
		!_.isEmpty(create_route) &&
		create_route.reduce(
			(accumulator, route) => {
				accumulator.totalDistance += route.distance;
				accumulator.totalTime += route.time;
				return accumulator;
			},
			{ totalDistance: 0, totalTime: 0 },
		);

	const select_deliveries = (item) => {
		generate_random_routes_points(item.point, item.radius, item.min_distance);
		utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.OPT_DELIVERIES_COUNT}_${item.point}`);
	};

	const select_riders = (item) => {
		set_selected_rider(item);
		utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.OPT_RIDERS_COUNT}_${item}`);
	};

	const sign_up_click = () => {
		history.push(Routes.LOGIN.path);
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.OPT_ROUTES_SINGUP);
	};

	const modify_click = () => {
		set_create_route(null);
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.OPT_MODIY_ROUTES);
	};

	const optimized_route_text = useMemo(() => {
		let text = `${selected_rider} optimized Routes are ready!`;
		if (selected_rider == 1) {
			text = `${selected_rider} optimized Route is ready!`;
		}
		return text;
	});
	return (
		<div className={clsx(classes.wrapper, classes.animatedItem)}>
			<div className={classes.route_container}>
				{!_.isEmpty(create_route) && (
					<>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<div style={{ display: 'flex', gap: 4 }}>
								<img src={ImageLinks.route_z_blue} width={24} height={24} />
								<Text semi style={{ fontSize: 16, color: theme.colors.lightBlue }}>
									{optimized_route_text}
								</Text>
							</div>
							<div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
								<Text semi style={{ fontSize: 16, color: theme.colors.darkGrey8 }}>
									{Math.floor(totalDistance / 1000)}km | {Math.floor(totalTime / 60)}min | {selected_rider} riders
								</Text>
								<Button type='text' text='Modify' size='large' left_icon={ImageLinks.undo} onClick={modify_click} />
							</div>
						</div>
						<div className='d-flex justify-content-center align-items-center' style={{ gap: 12 }}>
							<Text semi style={{ fontSize: 14, color: theme.colors.black }}>
								Unlock Custom Routes{' '}
							</Text>
							<Button
								type='round'
								text='Sign up for Free'
								right_icon={ImageLinks.arrow_right_white_no_fill}
								size='large'
								onClick={sign_up_click}
							/>
						</div>
					</>
				)}
				{_.isEmpty(create_route) && (
					<>
						<div className='align-items-center'>
							<div>
								<div className='d-flex'>
									<img src={ImageLinks.box2_gray} width={24} height={24} />
									<Text semi className={classes.title_text}>
										No. of Deliveries
									</Text>
								</div>

								<div className='d-flex' style={{ marginTop: 4 }}>
									{_.map(ROUTE_POINTS, (item, index) => (
										<div
											key={`point_item_${index}`}
											onClick={() => select_deliveries(item)}
											className={classes.point_item}
											style={{
												backgroundColor: selected_point === item.point ? theme.colors.primary : theme.colors.white,
												border: `${selected_point === item.point ? 0 : 1}px solid ${theme.colors.primary}`,
											}}>
											<Text
												semi
												style={{ fontSize: 12, color: selected_point === item.point ? theme.colors.white : theme.colors.primary }}>
												{item.point}
											</Text>
										</div>
									))}
								</div>
							</div>
							<div className={classes.route_divider}></div>
							<div>
								<div className='d-flex'>
									<img src={ImageLinks.route_z_grey} width={24} height={24} />
									<Text semi className={classes.title_text}>
										No. of Riders
									</Text>
								</div>

								<div className='d-flex' style={{ marginTop: 4 }}>
									{_.map(_.filter(ROUTE_POINTS, (item) => item.point === selected_point)[0]?.riders, (item, index) => (
										<div
											key={`point_item_${index}`}
											onClick={() => select_riders(item)}
											className={classes.point_item}
											style={{
												backgroundColor: selected_rider === item ? theme.colors.primary : theme.colors.white,
												border: `${selected_rider === item ? 0 : 1}px solid ${theme.colors.primary}`,
											}}>
											<Text semi style={{ fontSize: 12, color: selected_rider === item ? theme.colors.white : theme.colors.primary }}>
												{item}
											</Text>
										</div>
									))}
								</div>
							</div>
						</div>

						<Button size='large' type='round' text='Create Routes' onClick={handle_create_routes} />
					</>
				)}
			</div>
		</div>
	);
};

export default FindMyRoutes;
