import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import Text from 'modules/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	outer_wrapper: {
		position: 'absolute',
		zIndex: 999,
		left: '50%',
		transform: `translate(-50%,-50%)`,
	},
	wrapper: {
		padding: 20,
		width: 442,
		height: 130,
		borderRadius: 6,
		border: `1px solid ${theme.colors.lightGrey5}`,
		backgroundColor: theme.colors.white,
		justifyContent: 'space-between',
		opacity: 0.9,
	},

	text_wrap: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	add_rider_text: {
		fontSize: 12,
		color: theme.colors.primary,
		textDecoration: 'underline',
	},
}));

const EmptyNetwork = ({ has_captive_riders, is_network }) => {
	const history = useHistory();
	const classes = useStyles();

	const both_empty = !has_captive_riders && !is_network;
	return (
		<div className={classes.outer_wrapper} style={{ top: both_empty ? '50%' : '90%' }}>
			{!has_captive_riders && both_empty && (
				<div className={clsx('d-flex', classes.wrapper)}>
					<div className={classes.text_wrap}>
						<div>
							<Text semi style={{ fontSize: 16 }}>
								Onboard your Business Riders
							</Text>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 }}>
								Maximize profits and deliveries with your fleet
							</Text>
						</div>
						<div
							className='align-items-center cursor-pointer'
							onClick={() => {
								history.push(Routes.ALL_RIDERS.path);
							}}>
							<Text semi className={classes.add_rider_text}>
								Add Rider
							</Text>
							<img src={ImageLinks.arrow_right_purple} style={{ marginLeft: 6 }} />
						</div>
					</div>
					<img src={ImageLinks.rider_light_purple} width={100} height={100} />
				</div>
			)}

			{!has_captive_riders && !both_empty && (
				<div className={clsx('d-flex align-items-center justify-content-between', classes.wrapper)} style={{ marginTop: 20, height: 69 }}>
					<div className='align-items-center' style={{ flex: 0.7 }}>
						<img src={ImageLinks.rider_light_purple} width={54} height={54} />
						<div style={{ marginLeft: 4 }}>
							<Text semi style={{ fontSize: 16 }}>
								Onboard your Business Riders
							</Text>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Maximize profits and deliveries with your fleet
							</Text>
						</div>
					</div>
					<div
						style={{ flex: 0.3, justifyContent: 'flex-end' }}
						className='align-items-center cursor-pointer'
						onClick={() => {
							history.push(Routes.ALL_RIDERS.path);
						}}>
						<Text semi className={classes.add_rider_text} style={{}}>
							Add Rider
						</Text>
						<img src={ImageLinks.arrow_right_purple} style={{ marginLeft: 6 }} />
					</div>
				</div>
			)}

			{!is_network && both_empty && (
				<div className={clsx('d-flex', classes.wrapper)} style={{ marginTop: 20 }}>
					<div className={classes.text_wrap}>
						<Text semi style={{ fontSize: 18 }}>
							Looking for Pidge-powered local delivery partners?
						</Text>
						<div
							className='align-items-center cursor-pointer'
							onClick={() => {
								window.open('https://docs.google.com/forms/d/e/1FAIpQLSf-hLvM4vVZUQ39tHucBpaTD4ZPf6FgbBk9L-ELWIQh8UDzQg/viewform', '_blank');
							}}>
							<Text semi className={classes.add_rider_text}>
								Discover Now
							</Text>
							<img src={ImageLinks.arrow_right_purple} style={{ marginLeft: 6 }} />
						</div>
					</div>
					<img src={ImageLinks.networks_web_light_purple} width={100} height={100} />
				</div>
			)}

			{!is_network && !both_empty && (
				<div className={clsx('d-flex align-items-center justify-content-between', classes.wrapper)} style={{ marginTop: 20, height: 69 }}>
					<div className='align-items-center' style={{ flex: 0.7 }}>
						<img src={ImageLinks.networks_web_light_purple} width={54} height={54} />
						<Text semi style={{ fontSize: 16, marginLeft: 4 }}>
							Looking for Pidge-powered local delivery partners?
						</Text>
					</div>
					<div
						style={{ flex: 0.3, justifyContent: 'flex-end' }}
						className='align-items-center cursor-pointer'
						onClick={() => {
							window.open('https://docs.google.com/forms/d/e/1FAIpQLSf-hLvM4vVZUQ39tHucBpaTD4ZPf6FgbBk9L-ELWIQh8UDzQg/viewform', '_blank');
						}}>
						<Text semi className={classes.add_rider_text} style={{}}>
							Discover Now
						</Text>
						<img src={ImageLinks.arrow_right_purple} style={{ marginLeft: 6 }} />
					</div>
				</div>
			)}
		</div>
	);
};

export default EmptyNetwork;
