import React, { useEffect } from 'react';
import _ from 'lodash';
import qs from 'qs';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import api_requests from 'resources/api_requests';
import Text from 'modules/Text';
import theme from 'resources/theme';
import IconButton from 'modules/IconButton';
import ImageLinks from 'assets/images/ImageLinks';
import moment from 'moment';
import constant from 'resources/constant';
import clsx from 'clsx';
import Filters from 'modules/Filters/Filters';
import Routes from 'resources/Routes';
import FilterButton from 'modules/FilterButton';
import TripDetails from 'modules/modals/OrderDetail/components/TripDetails';
import OrderModal from 'modules/modals/OrderModal/OrderModal';

const useStyles = makeStyles((theme) => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', height: 40, alignItems: 'center' },
	back_arrow: { backgroundColor: theme.colors.lightPurple, width: 20, height: 20, borderColor: theme.colors.lightPurple },
	header_text: { fontSize: 22, marginLeft: 20 },
	stats_wrap: {
		display: 'flex',
		marginTop: 30,
		paddingBottom: 10,
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	stats_card: { padding: 16, backgroundColor: theme.colors.white, display: 'flex', borderRadius: 10, width: '80%' },
	stats_val: { fontSize: 22, color: theme.colors.darkGrey2 },
	table_header: {
		background: theme.colors.white,
		border: `1px solid ${theme.colors.lightPurple}`,
		borderRadius: 40,
		height: 41,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
	},
	table_head_item: { flex: 2, color: theme.colors.darkGrey2, fontSize: 14 },
	row_wrap: { overflowY: 'auto', flex: 1, marginTop: 10, marginBottom: 10 },
	row_item_wrap: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	page_wrap: {
		height: 60,
		marginBottom: 10,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		borderTop: `1px solid ${theme.colors.lightPurple}`,
	},
	page_text_wrap: {
		marginLeft: 20,
		marginRight: 20,
		width: 24,
		height: 24,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 20,
	},
	page_text: { color: theme.colors.primary, fontSize: 12 },
	page_nav_btn: {
		width: 24,
		height: 24,
		borderRadius: 30,
	},
	enabled_page_nav: { border: `1px solid ${theme.colors.primary}` },
	disabled_page_nav: { backgroundColor: theme.colors.lightGrey5, width: 26, height: 26 },
	filter_btn: {
		backgroundColor: theme.colors.primary,
		width: 40,
		height: 40,
		marginLeft: 10,
		borderRadius: '100%',
	},
}));

const AllOrdersByRider = ({ match, history }) => {
	const query_object = qs.parse(history.location.search.slice(1)) || {};
	const filter_object = query_object.filter || {};

	const rider_id = match.params.id;

	const [rider_details, set_rider_details] = React.useState({});
	const [page, set_page] = React.useState(1);
	const [orders, set_orders] = React.useState({});
	const [modal_trip, set_modal_trip] = React.useState(null);
	const [show_filters, set_show_filters] = React.useState(false);
	const [all_filters, set_all_filters] = React.useState([]);
	const [trip_data, set_trip_data] = React.useState(null);
	const [cdr_id, set_cdr_id] = React.useState(null);

	const handle_trip_details = async (trip_id, is_mcp) => {
		if (is_mcp) {
			set_cdr_id(trip_id);
			return;
		}
		try {
			const response = await api_requests.get_trip_details(trip_id);
			if (response.success) {
				set_trip_data(response.data);
			}
		} catch (err) {}
	};

	const classes = useStyles();

	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);

	useEffect(() => {
		api_requests.get_rider_orders_filters(zones[0].id).then((res) => {
			set_all_filters(res.data.filters);
		});
	}, []);

	const get_orders = ({ token }) =>
		api_requests
			.get_rider_orders(rider_id, {
				...(token && { token }),
				...filter_object,
			})
			.then((res) => {
				set_orders(res.data);
			});

	useEffect(() => {
		set_orders({});
		get_orders({});
	}, [JSON.stringify(filter_object)]);

	useEffect(() => {
		api_requests.get_rider_details(rider_id).then((res) => {
			set_rider_details(res.data);
		});
	}, []);

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<IconButton onClick={() => history.push(Routes.ALL_RIDERS.path)} className={classes.back_arrow}>
					<img src={ImageLinks.chevronLeft} width='6' />
				</IconButton>
				<Text semi className={classes.header_text}>
					All Orders by {rider_details?.details?.rider_name || ''} ({rider_id})
				</Text>
			</div>
			<div className={classes.stats_wrap}>
				<div className={`box-shadow ${classes.stats_card}`}>
					<div>
						<Text semi style={{ fontSize: 12 }}>
							QUICK STATS
						</Text>
						<Text semi style={{ fontSize: 12 }}>
							DD/MM/YY
						</Text>
					</div>

					<div style={{ marginLeft: 45 }}>
						<Text semi className={classes.stats_val}>
							-
						</Text>
						<Text semi style={{ color: theme.colors.lightGrey2 }}>
							Unattempted
						</Text>
					</div>

					<div style={{ marginLeft: 40 }}>
						<Text semi className={classes.stats_val}>
							-
						</Text>
						<Text semi style={{ color: theme.colors.lightGrey2 }}>
							Forward
						</Text>
					</div>

					<div style={{ marginLeft: 40 }}>
						<Text semi className={classes.stats_val}>
							-
						</Text>
						<Text semi style={{ color: theme.colors.lightGrey2 }}>
							Middle Mile
						</Text>
					</div>

					<div style={{ marginLeft: 40 }}>
						<Text semi className={classes.stats_val}>
							-
						</Text>
						<Text semi style={{ color: theme.colors.lightGrey2 }}>
							Delivered
						</Text>
					</div>

					<div style={{ marginLeft: 40 }}>
						<Text semi className={classes.stats_val}>
							-
						</Text>
						<Text semi style={{ color: theme.colors.lightGrey2 }}>
							RTO
						</Text>
					</div>

					<div style={{ marginLeft: 40 }}>
						<Text semi className={classes.stats_val}>
							-
						</Text>
						<Text semi style={{ color: theme.colors.lightGrey2 }}>
							Undelivered
						</Text>
					</div>
				</div>
				<div className='d-flex justify-content-between'>
					<FilterButton handle_click={() => set_show_filters(true)} total_filters={Object.keys(filter_object).length} />
				</div>
			</div>
			<div className={classes.table_header}>
				<Text bold className={classes.table_head_item}>
					Trip Date
				</Text>

				<Text bold className={classes.table_head_item}>
					Trip ID
				</Text>

				<Text bold className={classes.table_head_item}>
					Brand
				</Text>

				<Text bold className={classes.table_head_item}>
					Pickup Time
				</Text>

				<Text bold className={classes.table_head_item}>
					Drop Time
				</Text>

				<Text bold className={classes.table_head_item}>
					Attempt Type
				</Text>

				<Text bold className={classes.table_head_item}>
					Trip Status
				</Text>

				<Text bold className={classes.table_head_item}>
					Remarks
				</Text>
			</div>

			<div className={classes.row_wrap}>
				{_.map(orders.data, (order, key) => {
					return (
						<div key={`rider${key}`} className={classes.row_item_wrap}>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{moment(order.trip_date).format('DD/MM/YY')}
							</Text>
							<Text
								medium
								style={{ flex: 2, fontSize: 16, color: theme.colors.primary, textDecoration: 'underline', cursor: 'pointer' }}
								onClick={() =>
									handle_trip_details(
										order.external_order_id ? order.external_order_id : order.trip_id,
										order.external_order_id ? true : false,
									)
								}>
								{order.trip_id}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{order.brand_name}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{order.pickup_time ? moment(order.pickup_time).format('hh:mm A') : '-'}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{order.drop_time ? moment(order.drop_time).format('hh:mm A') : '-'}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{constant.ATTEMPT_TYPE_TEXT[order.attempt_type]}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{constant.TRIP_STATUS_TEXT[order.status_id]}
							</Text>
							<Text medium style={{ flex: 2, fontSize: 16 }}>
								{order.drop_remarks}
							</Text>
						</div>
					);
				})}
			</div>
			<div className={classes.page_wrap}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<IconButton
						className={clsx(classes.page_nav_btn, orders.prev ? classes.enabled_page_nav : classes.disabled_page_nav)}
						disabled={!orders.prev}
						onClick={() => {
							if (page === 1) {
								return;
							}
							set_page(page - 1);
							get_orders({ token: orders.prev });
						}}>
						<img src={orders.prev ? ImageLinks.chevronLeft : ImageLinks.chevron_left_white} width={10} />
					</IconButton>
					<div className={classes.page_text_wrap}>
						<Text bold className={classes.page_text}>
							{page}
						</Text>
					</div>
					<IconButton
						className={clsx(classes.page_nav_btn, orders.next ? classes.enabled_page_nav : classes.disabled_page_nav)}
						disabled={!orders.next}
						onClick={() => {
							if (orders.next == null) return;
							set_orders({});
							set_page(page + 1);
							get_orders({ token: orders.next });
						}}>
						<img src={orders.next ? ImageLinks.chevronLeft : ImageLinks.chevron_left_white} width={10} style={{ transform: 'rotate(180deg)' }} />
					</IconButton>
				</div>
			</div>
			{show_filters && (
				<Filters
					all_filters={all_filters}
					existing_filters={filter_object}
					set_selected_filters={(filters) => {
						history.push(Routes.ALL_ORDERS_BY_RIDER.path.replace(':id', rider_id) + '/?' + qs.stringify({ filter: filters }));
					}}
					close={() => set_show_filters(false)}
					title=''
				/>
			)}
			{trip_data && (
				<TripDetails
					close={() => set_trip_data(null)}
					on_update_success={() => {}}
					brand_name={''}
					order_id={'Admin Task'}
					trip_data={trip_data}
					wrapper_styles={{ right: 0 }}
				/>
			)}
			{!_.isEmpty(cdr_id) && (
				<OrderModal
					close={() => {
						set_cdr_id(null);
					}}
					id={cdr_id}
					updater={() => {}}
				/>
			)}
		</div>
	);
};

export default AllOrdersByRider;
