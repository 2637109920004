import React, { useEffect, useRef, useState } from 'react';
import Text from './Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import { makeStyles } from '@material-ui/core';
import Lottie from 'react-lottie';
import Animations from 'assets/lottie/Animations';
import Button from './Button';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
	wrapper: { width: '100%', height: '100%' },
	content: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	text_white_center: {
		color: theme.colors.white,
		textAlign: 'center',
	},
	button_style: {
		position: 'absolute',
		left: '50%',
		bottom: '11%',
		transform: 'translate(-50%, -50%)',
	},
}));

const MobileView = () => {
	const classes = useStyles();
	const lottieRef = useRef();
	const img_width = window.innerWidth < 376 ? window.innerWidth - 60 : window.innerWidth;
	const [show_button, set_show_button] = useState(false);

	useEffect(() => {
		if (lottieRef.current) {
			const anim = lottieRef.current;
			const totalFrames = anim?.anim?.totalFrames;
			const fps = anim?.anim?.frameRate;

			const duration = totalFrames / fps;

			setTimeout(() => {
				set_show_button(true);
			}, duration * 1000);
		}
	}, []);

	const handle_button_click = () => {
		const userAgent = navigator.userAgent;

		if (/android/i.test(userAgent)) {
			window.location.href = 'https://play.google.com/store/apps/details?id=com.rindventures.pidgeconsumer&hl=en_IN&gl=US';
		} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			window.location.href = 'https://apps.apple.com/in/app/pidge-on-demand-delivery/id1479218436'; // Replace with your app's Apple App Store URL
		} else {
			toast.error('Unsupported device. Please visit this link on an Android or iOS device.', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		}
	};

	return (
		<div className={classes.wrapper}>
			<div style={{ position: 'relative' }}>
				<Lottie
					ref={lottieRef}
					options={{
						loop: false,
						animationData: Animations.mobile_pre_login_gif,
						rendererSettings: {
							preserveAspectRatio: 'xMidYMid slice',
						},
					}}
					height={'100%'}
					width={img_width}
				/>
				{show_button && (
					<Button
						type='round'
						text='Get Started'
						size='large'
						className={classes.button_style}
						right_icon={ImageLinks.arrow_right_white_no_fill}
						onClick={handle_button_click}
					/>
				)}
			</div>
		</div>
	);
};

export default MobileView;
