import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useContext, useRef, useState } from 'react';
import theme from 'resources/theme';
import SectionHeader from './SectionHeader';
import CreateNetworkContext from '../../context';
import { RULES_SECTION } from '../helper';
import ServicibilityAreas from './ServiciblityAreas/ServicibilityAreas';
import Pricing from './Pricing/Pricing';
import NetworkAllocation from './NetworkAllocation/NetworkAllocation';
import SupportFunctions from './SupportFunctions/SupportFunctions';
import ConfirmClose from 'modules/modals/ConfirmClose';
import UndeliveredOrders from './UndeliveredOrders/UndeliveredOrders';
import CanellationCharges from './CancellationCharges/CancellationCharges';
import SurgePricing from './SurgePricing/SurgePricing';

const useStyles = makeStyles(() => ({
	rules_section: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
	},
	menu_section: { display: 'flex', flex: 0.15, flexDirection: 'column' },
	menu_item_wrap: {
		borderRadius: 4,
		height: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 12,
		paddingLeft: 12,
		paddingRight: 11,
	},
}));

const CreateNetworkLowerSection = () => {
	const { selected_menu, set_selected_menu, show_lower_section } = useContext(CreateNetworkContext);
	const [show_confirm_popup, set_show_confirm_popup] = useState(false);
	const [next_key, set_next_key] = useState(null);
	const data_updated_states = useRef([]);
	const classes = useStyles();

	if (!show_lower_section) return null;

	const handle_selected_menu = (key) => {
		let index = 0;
		switch (selected_menu) {
			case RULES_SECTION[0].key:
				index = 0;
				break;
			case RULES_SECTION[1].key:
				index = 1;
				break;
			case RULES_SECTION[2].key:
				index = 2;
				break;
			case RULES_SECTION[3].key:
				index = 3;
				break;
			case RULES_SECTION[4].key:
				index = 4;
				break;
			case RULES_SECTION[5].key:
				index = 5;
				break;
			case RULES_SECTION[6].key:
				index = 6;
				break;
			default:
				break;
		}
		if (data_updated_states.current[index]) {
			set_show_confirm_popup(true);
			set_next_key(key);
			return;
		}

		set_selected_menu(key);
	};

	const handle_popup_confirm = () => {
		data_updated_states.current = [];
		set_show_confirm_popup(false);
		set_selected_menu(next_key);
	};

	const handle_data_updates = (tab_index, value) => {
		const org_data = _.cloneDeep(data_updated_states.current);
		org_data[tab_index] = value;
		data_updated_states.current = org_data;
	};

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 50 }}>
			<div className={classes.rules_section}>
				<SectionHeader title={'Network Rules'} description={'Select areas where your network will function'} />
				<div style={{ display: 'flex', marginTop: 16, minHeight: 390 }}>
					<div className={classes.menu_section}>
						{_.map(RULES_SECTION, (item, index) => (
							<div
								key={`section_menu_${index}`}
								onClick={() => handle_selected_menu(item.key)}
								className={classes.menu_item_wrap}
								style={{
									pointerEvents: item.is_disabled ? 'none' : 'auto',
									cursor: item.is_disabled ? 'unset' : 'pointer',
									backgroundColor: item.is_disabled
										? theme.colors.darkGrey6
										: selected_menu === item.key
										? theme.colors.lightPurple
										: theme.colors.white,
									border: `1px solid ${selected_menu === item.key ? theme.colors.primary : theme.colors.lightGrey5}`,
								}}>
								<Text bold style={{ fontSize: 12, color: item.is_disabled ? theme.colors.darkGrey2 : theme.colors.black }}>
									{item.title}
								</Text>
								<img
									src={
										item.is_disabled
											? ImageLinks.lock
											: selected_menu === item.key
											? ImageLinks.chevron_right_pink
											: ImageLinks.chevron_right_black
									}
									width={16}
									height={16}
								/>
							</div>
						))}
					</div>

					<div style={{ display: 'flex', flex: 0.84 }}>
						{selected_menu === 'areas' && <ServicibilityAreas handle_data_updates={handle_data_updates} />}
						{selected_menu === 'pricing' && <Pricing handle_data_updates={handle_data_updates} />}
						{selected_menu == 'surge_pricing' && <SurgePricing handle_data_updates={handle_data_updates} />}
						{selected_menu == 'cancelliation_charges' && <CanellationCharges handle_data_updates={handle_data_updates} />}
						{selected_menu === 'allocation' && <NetworkAllocation handle_data_updates={handle_data_updates} />}
						{selected_menu === 'support_functions' && <SupportFunctions handle_data_updates={handle_data_updates} />}
						{selected_menu == 'undelivered_orders' && <UndeliveredOrders handle_data_updates={handle_data_updates} />}
					</div>
				</div>
			</div>
			{show_confirm_popup && (
				<ConfirmClose
					headerTitle='Discard Changes'
					title='You have made changes but you have not saved them. Are you sure you want to discard these changes?'
					close={() => set_show_confirm_popup(false)}
					on_confirm={handle_popup_confirm}
					is_error_msg={true}
				/>
			)}
		</div>
	);
};

export default CreateNetworkLowerSection;
