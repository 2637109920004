import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ContainedButton from 'modules/ContainedButton';
import MasterZones from './components/MasterZones';
import SubZones from './components/SubZones';
import CreateMasterZone from './components/CreateMasterZone/CreateMasterZone';
import api_requests from 'resources/api_requests';
import NoMasterZone from './components/NoMasterZone';
import ImageLinks from 'assets/images/ImageLinks';
import CreateBusinessZone from './components/CreateBusinessZone';
import EditMasterZone from './components/EditMasterZone';
import { FLOW_TYPE, SCREEN } from './helper';
import Pincodes from './components/Pincodes';
import EditBusinessZone from './components/EditBusinessZone';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { master_zones_received, hide_loader, show_loader } from 'actions/app';
import { useState } from 'react';
import Button from 'modules/Button';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between', borderBottom: `1px solid ${theme.colors.lightGrey}` },
	settings_text: {
		fontSize: 22,
		color: theme.colors.lightGrey2,
	},
	screen_text_wrap: { width: 2, height: 30, background: '#B0B0B0', marginLeft: 20 },
	screen_text: {
		fontSize: 22,
		marginLeft: 20,
		borderBottom: '4px solid #E4DCF3',
		color: theme.colors.black,
	},
	header_btn_text: { marginLeft: 10, fontSize: 18 },
	header_btn_icon: { width: 12, height: 12 },
	filter_wrapper: {
		display: 'flex',
		marginTop: 32,
		paddingBottom: 10,
		alignItems: 'flex-end',
	},
	create_info_wrap: {
		borderRadius: 6,
		backgroundColor: theme.colors.lightPurple8,
		display: 'flex',
		width: 500,
		height: 85,
		marginRight: 45,
		padding: 12,
	},
	purple_circle: { width: 16, height: 16, borderRadius: 8, backgroundColor: theme.colors.primary, marginRight: 6, marginTop: 4 },
});

const ZonesDashboard = () => {
	const { app } = useSelector((state) => state, shallowEqual);
	const { is_loading } = app;
	const history = useHistory();
	const dispatch = useDispatch();

	const [screen, set_screen] = useState(SCREEN.no_zone);
	const [selected_master_zone_id, set_selected_master_zone_id] = useState(null);
	const [selected_business_zone, set_selected_business_zone] = useState(null);
	const [master_zones, set_master_zones] = useState(null);
	const [pidge_zones, set_pidge_zones] = useState([]);
	const [flow_type, set_flow_type] = useState(null);
	const [refresh_business_zone, set_refresh_business_zone] = useState(false);
	const [show_master_instruction, set_show_master_instruction] = useState(true);
	const [show_business_instruction, set_show_business_instruction] = useState(true);
	const classes = useStyles();

	useEffect(() => {
		dispatch(show_loader());
		api_requests
			.get_master_zones_geometry({
				pidge: true,
				serviceable: true,
			})
			.then((res) => {
				set_pidge_zones(
					_.map(res.data, (data) => {
						return {
							...data,
							color:
								'#' +
								Math.floor(Math.random() * 16777215)
									.toString(16)
									.padStart(6, '0'),
						};
					}),
				);
				set_screen(SCREEN.master_zones);
				dispatch(hide_loader());
			})
			.catch(() => {
				dispatch(hide_loader());
			});
	}, []);
	useEffect(() => {
		if (screen === null || screen === SCREEN.master_zones) {
			get_master_zones().then((res) => {
				if (!_.isEmpty(res.data)) {
					set_screen(SCREEN.master_zones);
				} else {
					set_screen(SCREEN.no_zone);
				}
			});
		}
	}, [screen]);

	const open_subzones = (master_zone_id) => {
		set_selected_master_zone_id(master_zone_id);
		set_screen(SCREEN.sub_zones);
	};

	const on_master_zone_creation = async (master_zone_id) => {
		set_master_zones([]);
		await get_master_zones();
		refresh_zones_list();
		set_selected_master_zone_id(master_zone_id);
		set_screen(SCREEN.create_business_zone);
	};

	const on_master_zone_edit = async () => {
		set_master_zones([]);
		await get_master_zones();
		refresh_zones_list();
		set_screen(SCREEN.master_zones);
	};

	const on_create_subzone_success = async () => {
		set_screen(SCREEN.sub_zones);
		set_refresh_business_zone((val) => !val);
		refresh_zones_list();
	};

	const refresh_zones_list = async () => {
		try {
			const zones = (await api_requests.get_master_zones(app.selected_business_id)).data;
			dispatch(master_zones_received(zones));
		} catch (error) {}
	};

	const get_master_zones = async () => {
		const master_zones_geometry = await api_requests.get_master_zones_geometry({
			pidge: false,
			serviceable: false,
		});
		set_master_zones(
			_.map(master_zones_geometry.data, (data) => {
				return {
					...data,
					color:
						'#' +
						Math.floor(Math.random() * 16777215)
							.toString(16)
							.padStart(6, '0'),
				};
			}),
		);

		return Promise.resolve(master_zones_geometry);
	};

	const open_subzones_detail = (sub_zone) => {
		set_selected_business_zone(sub_zone);
		set_screen(SCREEN.pincodes);
	};

	const on_edit_master_zone = () => {
		set_screen(SCREEN.edit_master_zone);
	};

	const on_edit_business_zone = () => {
		set_screen(SCREEN.edit_business_zone);
	};

	const open_create_master_zone = () => {
		set_screen(SCREEN.create_master_zone);
		set_flow_type(FLOW_TYPE.TEMPLATE);
		set_selected_master_zone_id(null);
	};

	const open_create_custom_master_zone = () => {
		set_screen(SCREEN.create_master_zone);
		set_flow_type(FLOW_TYPE.CUSTOM);
		set_selected_master_zone_id(null);
	};

	const open_create_business_zone = () => {
		set_screen(SCREEN.create_business_zone);
	};

	const handle_type_change = (key) => {
		if (key === FLOW_TYPE.CUSTOM) open_create_custom_master_zone();
		else open_create_master_zone();
	};

	if (screen === null) {
		return null;
	}

	const selected_master_zone = _.find(master_zones, { id: selected_master_zone_id }) || {};
	const is_master_zone_focussed = screen === SCREEN.master_zones || screen === SCREEN.create_master_zone;
	const isMasterZoneSelected = screen === SCREEN.sub_zones || screen === SCREEN.create_business_zone || screen === SCREEN.edit_master_zone;
	const is_sub_zone_focussed = screen === SCREEN.pincodes || screen === SCREEN.edit_business_zone;
	const is_edit_screens = screen === SCREEN.edit_master_zone || screen === SCREEN.edit_business_zone;

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header} style={screen === SCREEN.no_zone ? { border: 0 } : {}}>
				<div>
					<div className='align-items-center'>
						<div className='page_back_btn' onClick={() => history.goBack()}>
							<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
						</div>
						<Text semi className={'global_header_text'}>
							Zones
						</Text>
					</div>
					{screen !== SCREEN.no_zone && (
						<div className={classes.filter_wrapper}>
							<ContainedButton
								onClick={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.ZONE_NEW_ZONE);
									set_screen(SCREEN.master_zones);
									set_selected_master_zone_id(null);
									set_selected_business_zone(null);
								}}
								disableElevation={screen !== SCREEN.master_zones}
								style={{ borderRadius: 10, ...(!is_master_zone_focussed && { background: 'transparent' }) }}>
								<Text
									bold
									style={{ fontSize: 14, color: theme.colors.white, ...(!is_master_zone_focussed && { color: theme.colors.lightPurple6 }) }}>
									Master Zones
								</Text>
							</ContainedButton>

							{!_.isEmpty(selected_master_zone) && (
								<ContainedButton
									onClick={() => {
										set_screen(SCREEN.sub_zones);
										set_selected_business_zone(null);
									}}
									disableElevation={screen !== SCREEN.sub_zones}
									style={{ borderRadius: 10, marginLeft: 10, ...(!isMasterZoneSelected && { background: 'transparent' }) }}>
									<Text
										bold
										style={{ fontSize: 14, color: theme.colors.white, ...(!isMasterZoneSelected && { color: theme.colors.lightPurple6 }) }}>
										{selected_master_zone.title}
									</Text>
								</ContainedButton>
							)}
							{!_.isEmpty(selected_business_zone) && (
								<ContainedButton
									onClick={() => {}}
									disableElevation={screen !== SCREEN.sub_zones}
									style={{ borderRadius: 10, marginLeft: 10, ...(!is_sub_zone_focussed && { background: 'transparent' }) }}>
									<Text
										bold
										style={{ fontSize: 14, color: theme.colors.white, ...(!is_sub_zone_focussed && { color: theme.colors.lightPurple6 }) }}>
										{selected_business_zone.title}
									</Text>
								</ContainedButton>
							)}
						</div>
					)}
				</div>
				{!_.isEmpty(master_zones) &&
					!is_edit_screens &&
					screen != SCREEN.create_business_zone &&
					screen !== SCREEN.create_master_zone &&
					screen !== SCREEN.pincodes && (
						<div>
							<Button
								type='round'
								text={`New ${screen === SCREEN.master_zones ? 'Master Zone' : 'Business Zone'}`}
								size='large'
								onClick={screen === SCREEN.master_zones ? open_create_master_zone : open_create_business_zone}
								left_icon={ImageLinks.addWhite}
							/>
						</div>
					)}
				{screen === SCREEN.create_master_zone && show_master_instruction && (
					<div className={classes.create_info_wrap}>
						<div className={classes.purple_circle}></div>
						<div style={{ flex: 1 }}>
							<Text bold style={{ fontSize: 16, color: theme.colors.darkGrey2 }}>
								Master Zones are for City Logistics
							</Text>
							<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 5 }}>
								A master zone is the largest boundary in which city logistics can happen. These zones are often multi-city. E.g. Delhi-Gurgaon
								or Mumbai-Navi Mumbai
							</Text>
						</div>
						<img src={ImageLinks.cross_purple} width={16} height={16} onClick={() => set_show_master_instruction(false)} />
					</div>
				)}
				{screen === SCREEN.create_business_zone && show_business_instruction && (
					<div className={classes.create_info_wrap}>
						<div className={classes.purple_circle}></div>
						<div style={{ flex: 1 }}>
							<Text bold style={{ fontSize: 16, color: theme.colors.darkGrey2 }}>
								Tips for Successful Business Zones
							</Text>
							<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 5 }}>
								Business zones help operations teams identify areas that they are used to in day-to-day work. Distinct, non-overlapping zones
								yield best results.
							</Text>
						</div>
						<img src={ImageLinks.cross_purple} width={16} height={16} onClick={() => set_show_business_instruction(false)} />
					</div>
				)}
			</div>
			{screen === SCREEN.no_zone && !is_loading && (
				<NoMasterZone open_create_master_zone={open_create_master_zone} open_create_custom_master_zone={open_create_custom_master_zone} />
			)}
			{screen === SCREEN.master_zones && <MasterZones master_zones={master_zones} pidge_zones={pidge_zones} open_subzones={open_subzones} />}
			{screen === SCREEN.sub_zones && (
				<SubZones
					pidge_zones={pidge_zones}
					selected_master_zone={selected_master_zone}
					refresh_business_zone={refresh_business_zone}
					on_edit_master_zone={on_edit_master_zone}
					open_pincodes={open_subzones_detail}
				/>
			)}
			{screen === SCREEN.pincodes && (
				<Pincodes
					selected_master_zone={selected_master_zone}
					selected_business_zone={selected_business_zone}
					on_edit_business_zone={on_edit_business_zone}
				/>
			)}
			{screen === SCREEN.create_master_zone && (
				<CreateMasterZone
					flow_type={flow_type}
					handle_type_change={handle_type_change}
					pidge_zones={pidge_zones}
					on_success={on_master_zone_creation}
				/>
			)}
			{screen === SCREEN.create_business_zone && (
				<CreateBusinessZone pidge_zones={pidge_zones} selected_master_zone={selected_master_zone} on_success={on_create_subzone_success} />
			)}
			{screen === SCREEN.edit_master_zone && <EditMasterZone selected_master_zone={selected_master_zone} on_success={on_master_zone_edit} />}
			{screen === SCREEN.edit_business_zone && (
				<EditBusinessZone
					selected_master_zone={selected_master_zone}
					selected_business_zone={selected_business_zone}
					on_success={on_master_zone_edit}
				/>
			)}
		</div>
	);
};

export default ZonesDashboard;
