import React, { useEffect, useMemo, useState } from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import Switch from 'modules/Switch';
import { useSelector } from 'react-redux';
import api_requests from 'resources/api_requests';
import ContainedButton from 'modules/ContainedButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import utils from 'resources/utils';
import Button from 'modules/Button';
import constant from 'resources/constant';

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 18, marginBottom: 10 },
	sub_heading: { color: theme.colors.darkGrey2 },
	info_container: { width: 430, backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, minHeight: '70vh', flex: 0.8 },
	tips: {
		backgroundColor: theme.colors.lightPurple,
		marginRight: 0,
		width: 80,
		borderRadius: 5,
		display: 'flex',
		justifyContent: 'center',
		padding: '8px 8px',
	},
	save_button: { position: 'absolute', bottom: 20, right: 30, width: 110 },
}));

const RiderHandover = () => {
	const { app_configs, selected_business_id: business_id_information } = useSelector(({ app }) => app);
	const classes = useStyles();
	const [is_handover, set_is_handover] = useState(false);

	const rider_config = useMemo(() => {
		return { ..._.get(app_configs, `business_configs.${business_id_information}.rider_app_configs`, {}), is_editable: true };
	}, []);

	useEffect(() => {
		if (!_.isEmpty(rider_config)) {
			set_is_handover(_.get(rider_config, 'rider_handover', false));
		}
	}, [rider_config]);

	const handle_save_configs = async () => {
		try {
			const res = await api_requests.update_common_configs({
				rider_app_configs: utils.remove_null_and_empty_from_obj({
					...rider_config,
					rider_handover: is_handover,
					is_editable: null,
				}),
			});
			window.location.reload(true);
		} catch (err) {}
	};

	return (
		<div>
			<div className={classes.heading_container}>
				<Text bold className={classes.heading}>
					Rider Handover
				</Text>
				<ListItem key={`tips`} button onClick={() => {}} className={classes.tips}>
					<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
						Tips & Info
					</Text>
				</ListItem>
			</div>

			<div style={{ display: 'flex', position: 'relative' }}>
				<div style={{ marginRight: 20, flex: '1' }}>
					<Text medium className={classes.sub_heading} style={{ fontSize: 14 }}>
						This feature allows your riders to allocate their existing tasks to other executives through the delivery executive application. Note:
						Admins can handover tasks via live tracking and the order modal.
					</Text>

					<div className='d-flex' style={{ marginTop: 30 }}>
						<div>
							<Text medium>On Ground Handover</Text>
							<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
								Executives will be able to allocate tasks from the application to other executives via list selection or barcode scanning
							</Text>
						</div>
						<div>
							<Switch
								checked={is_handover}
								onChange={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_HANDOVER_TOGGLE);
									set_is_handover((prev) => !prev);
								}}
							/>
						</div>
					</div>
				</div>
				<div className={classes.info_container}>
					<Text bold style={{ fontSize: 16, color: theme.colors.lightPurple7 }}>
						How does Rider Handover help?
					</Text>
					<Text medium style={{ fontSize: 12 }}>
						There are 3 main use cases for on-ground Rider Handover:
					</Text>
					<Text medium style={{ fontSize: 12 }}>
						1. Handing over orders to riders from an executive in a larger vehicle
						<br />
						2. Relay-style models for range-bound executives
						<br />
						3. Exception management such as vehicle- or executive- related emergencies
					</Text>

					<div className='d-flex justify-content-center' style={{ marginTop: 50 }}>
						<img src={ImageLinks.rider_handover_tip} width={274} height={287} style={{ margin: 'auto' }}></img>
					</div>
				</div>
			</div>
			<Button
				disabled={_.get(rider_config, 'rider_handover', false) == is_handover}
				className={classes.save_button}
				onClick={() => handle_save_configs(true)}
				type='round'
				text='Save'
				size='large'
			/>
		</div>
	);
};

export default RiderHandover;
