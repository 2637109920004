import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import qs from 'qs';
import constant from 'resources/constant';
import _ from 'lodash';
import api_requests from 'resources/api_requests';
import L from 'leaflet';
import moment from 'moment';
import utils from 'resources/utils';
import { OUTER_FILTER_TYPE } from './helper';
import theme from 'resources/theme';
import Routes from 'resources/Routes';
import 'leaflet-routing-machine';
import { hide_loader, show_loader } from 'actions/app';

const useTracking = (history) => {
	const query_object = qs.parse(history.location.search.slice(1)) || {};
	const filter_object = query_object.filter || {};
	const dispatch = useDispatch();
	const { zones, app_configs, network_list } = useSelector(
		(state) => ({
			zones: state.app.zones,
			app_configs: state.app.app_configs,
			network_list: state.app.network_list,
		}),
		shallowEqual,
	);

	const [zones_list, set_zones_list] = useState(zones);
	const [selected_master_zone_id, set_selected_master_zone_id] = React.useState(null);

	const [rider_markers, set_rider_markers] = React.useState([]);
	const [rider_data, set_rider_data] = React.useState(null);
	const [rider_tasks, set_rider_tasks] = React.useState({});

	const [show_filters, set_show_filters] = React.useState(false);
	const [all_filters, set_all_filters] = React.useState([]);

	const [selected_pb_id, set_selected_pb_id] = React.useState(null);
	const [show_pd_id_modal, set_show_pd_id_modal] = React.useState(false);

	const [selected_trip_id, set_selected_trip_id] = React.useState(null);
	const [show_trip_id_modal, set_show_trip_id_modal] = React.useState(false);

	const [trip_data, set_trip_data] = useState({});

	// updated design states
	const [has_captive_riders, set_has_captive_riders] = useState(false);
	const [selected_outer_filter, set_selected_outer_filter] = useState(null);
	const [show_zones_modal, set_show_zones_modal] = useState(false);
	const [completed_tasks, set_completed_tasks] = useState([]);
	const [show_completed_tasks, set_show_completed_tasks] = useState(false);
	const [show_rider_modal, set_show_rider_modal] = useState(false);
	const [fleet_riders, set_fleet_riders] = useState([]);
	const [show_network_modal, set_show_network_modal] = useState(false);
	const [show_fleet_riders, set_show_fleet_riders] = useState(false);
	const [network_orders, set_network_orders] = useState(null);
	const [show_toast, set_show_toast] = useState(false);
	const [is_api_call_done, set_is_api_call_done] = useState(false);
	const [three_pl_tasks, set_three_pl_tasks] = useState([]);
	const map_ref = useRef(null);
	const completed_poyline_ref = useRef(null);
	const upcoming_polyline_ref = useRef(null);
	const original_network_orders = useRef(null);

	useEffect(() => {
		dispatch(show_loader());
		fetch_all_initial_states_and_data();
		api_requests.get_captive_riders().then((res) => set_has_captive_riders(res.data.length > 0));
		api_requests.get_tracking_filters().then((res) => set_all_filters(res.data.filters));
	}, []);

	useEffect(() => {
		if (selected_master_zone_id == null) return;
		dispatch(hide_loader());
		handle_rider_task_modal_close();
		fetch_fleet_riders();
		format_networks_data();
	}, [selected_master_zone_id]);

	useEffect(() => {
		if (selected_trip_id) handle_trip_details();
	}, [selected_trip_id]);

	useEffect(() => {
		set_rider_data(null);
		set_rider_markers([]);
		set_rider_tasks([]);
		set_three_pl_tasks([]);
		if (_.isEmpty(filter_object)) {
			return;
		}
		if (selected_master_zone_id == null) return;
		const selected_master_zone = _.find(zones_list, { id: selected_master_zone_id });
		api_requests
			.get_rider_task_api(selected_master_zone.CenterPoint.latitude.toFixed(4), selected_master_zone.CenterPoint.longitude.toFixed(4), filter_object)
			.then((res) => {
				set_rider_markers(res.data);
			});
	}, [JSON.stringify(filter_object), selected_master_zone_id]);

	const fetch_all_initial_states_and_data = async () => {
		try {
			const response = await api_requests.get_network_orders_list();
			original_network_orders.current = response;
		} catch (error) {}

		if (navigator.geolocation) {
			const nav_query_result = await navigator.permissions.query({ name: 'geolocation' });

			if (nav_query_result.state === 'granted') {
				navigator.geolocation.getCurrentPosition(success, errors);
			} else set_selected_master_zone_id(zones[0]?.id);
		} else {
			set_selected_master_zone_id(zones[0]?.id);
		}
	};

	const success = (pos) => {
		const crd = pos.coords;
		const _zones = _.cloneDeep(zones_list);
		_zones.unshift({ id: -1, CenterPoint: { latitude: crd.latitude, longitude: crd.longitude }, name: 'Current Location' });
		set_zones_list(_zones);
		set_selected_master_zone_id(-1);
	};
	const errors = () => {
		dispatch(hide_loader());
	};

	const format_networks_data = () => {
		if (!original_network_orders.current || selected_master_zone_id == null) return;
		const owned_networks = _.map(
			_.filter(network_list, (item) => item.is_owner),
			(filter_item) => Number(filter_item.id),
		);
		const formatted_data = {};
		const res = _.cloneDeep(original_network_orders.current);
		const selected_master_zone = _.find(zones_list, { id: selected_master_zone_id });
		for (let i = 0; i < res.data.length; i++) {
			const element = res.data[i];
			const sender_coords = element.orders[0].sender_detail;
			if (element._id.network_id > 0 && app_configs.network_configs[element._id.network_id]) {
				const distance_bewtween_coords = utils.get_coords_aerial_distance(
					selected_master_zone.CenterPoint.latitude,
					selected_master_zone.CenterPoint.longitude,
					sender_coords.latitude,
					sender_coords.longitude,
				);
				if (distance_bewtween_coords > 150) continue;

				if (formatted_data[element._id.network_id]) formatted_data[element._id.network_id].list.push(element);
				else {
					const obj = {
						is_owner: _.includes(owned_networks, element._id.network_id),
						config: app_configs.network_configs[element._id.network_id],
						list: [element],
					};

					formatted_data[element._id.network_id] = obj;
				}
			}
		}
		set_network_orders(formatted_data);
		set_is_api_call_done(true);
	};

	const handle_trip_details = async () => {
		try {
			const response = await api_requests.get_trip_details(selected_trip_id);
			if (response.success) {
				set_trip_data(response.data);
				set_show_trip_id_modal(true);
			}
		} catch (err) {}
	};

	const get_on_task_riders = async (params = { is_busy: true }) => {
		set_rider_data(null);
		set_rider_markers([]);
		set_rider_tasks([]);
		set_three_pl_tasks([]);
		// set_fleet_riders([]);
		history.push(Routes.TRACKING.path);

		const selected_master_zone = _.find(zones_list, { id: selected_master_zone_id });

		try {
			const response = await api_requests.get_rider_task_api(
				selected_master_zone.CenterPoint.latitude.toFixed(4),
				selected_master_zone.CenterPoint.longitude.toFixed(4),
				params,
			);

			if (response.success) {
				set_rider_markers(response.data);
			}
		} catch (error) {}
	};

	const fetch_fleet_riders = async (params = {}) => {
		set_rider_data(null);
		set_rider_markers([]);
		set_rider_tasks([]);
		set_three_pl_tasks([]);
		set_fleet_riders([]);
		history.push(Routes.TRACKING.path);

		const selected_master_zone = _.find(zones_list, { id: selected_master_zone_id });
		const response = await api_requests.get_fleet_riders_list({
			latitude: selected_master_zone.CenterPoint.latitude.toFixed(4),
			longitude: selected_master_zone.CenterPoint.longitude.toFixed(4),
			...params,
		});
		set_fleet_riders(response.data.locations);
	};

	const show_rider_tasks_onmap = async (rider_id) => {
		if (upcoming_polyline_ref.current && map_ref.current) map_ref.current.removeControl(upcoming_polyline_ref.current);
		if (completed_poyline_ref.current && map_ref.current) map_ref.current.removeControl(completed_poyline_ref.current);
		completed_poyline_ref.current = null;
		upcoming_polyline_ref.current = null;

		const response = await api_requests.get_rider_tasks(rider_id);
		set_rider_tasks(response.data);

		api_requests
			.get_rider_details(rider_id)
			.then((res) => {
				set_rider_data(res.data);

				if (response.data.current_location?.latitude && response.data.current_location?.longitude) {
					const updateRiderIcon = {
						rider_id: res.data.details.rider_id,
						rider_location: response.data.current_location,
						rider_name: res.data.details.rider_name,
					};
					set_rider_markers([updateRiderIcon]);
				}
			})
			.catch((err) => {});

		const task_loc_arr = _.map(response.data.routes, (item) => item.task_location);
		task_loc_arr.unshift(response.data.current_location);

		upcoming_polyline_ref.current = L.Routing.control({
			waypoints: _.map(task_loc_arr, (item) => L.latLng(item.latitude, item.longitude)),
			lineOptions: {
				styles: [{ color: theme.colors.lightPurple6, opacity: 0.8, weight: 4 }],
			},
			show: false,
			addWaypoints: false,
			routeWhileDragging: false,
			draggableWaypoints: false,
			fitSelectedRoutes: false,
			showAlternatives: false,
			createMarker: () => null,
		}).addTo(map_ref.current);
	};

	const handle_bubble_click = (type) => {
		const updated_value = selected_outer_filter === type ? null : type;
		set_selected_outer_filter(updated_value);

		if (!updated_value) {
			set_rider_data(null);
			set_rider_markers([]);
			set_rider_tasks([]);
			set_three_pl_tasks([]);
			// set_fleet_riders([]);
			set_show_network_modal(false);
			set_show_fleet_riders(false);
			set_show_completed_tasks(false);
			if (upcoming_polyline_ref.current && map_ref.current) map_ref.current.removeControl(upcoming_polyline_ref.current);
			if (completed_poyline_ref.current && map_ref.current) map_ref.current.removeControl(completed_poyline_ref.current);
			completed_poyline_ref.current = null;
			upcoming_polyline_ref.current = null;
		}
		switch (type) {
			case OUTER_FILTER_TYPE.IOT:
				if (updated_value) set_show_fleet_riders(true);
				break;
			case OUTER_FILTER_TYPE.NETWORK:
				if (updated_value) {
					utils.track_event_for_analytics(constant.TRACKING_EVENTS.TRACKING_NETWORK);
					set_show_network_modal(true);
				}
				break;
			case OUTER_FILTER_TYPE.LOGGED:
				if (updated_value) {
					utils.track_event_for_analytics(constant.TRACKING_EVENTS.TRACKING_LOGGED_IN);
					get_on_task_riders({ logged_in_free: true });
				}
				break;
			case OUTER_FILTER_TYPE.ONTASK:
				if (updated_value) {
					utils.track_event_for_analytics(constant.TRACKING_EVENTS.TRACKING_ON_TASK);
					get_on_task_riders({ is_busy: true });
				}
				break;
			default:
				break;
		}
	};

	const handle_search = (is_rider, value) => {
		if (is_rider) {
			show_rider_tasks_onmap(value);
		} else if (value.vehicle_number) {
			fetch_fleet_riders({ entity_name: value.vehicle_number });
		} else {
			get_on_task_riders(value);
		}
	};

	const handle_zone_change = (is_current, val) => {
		if (is_current) {
			const _zones = _.cloneDeep(zones_list);
			_zones.unshift({ id: -1, CenterPoint: val, name: 'Current Location' });
			set_zones_list(_zones);
			set_selected_master_zone_id(-1);
		} else {
			set_selected_master_zone_id(val);
		}
	};

	const handle_completed_task = async () => {
		const id = _.get(rider_data, 'details.rider_id', null);
		if (!id) return;
		set_show_completed_tasks((prev) => !prev);
		if (show_completed_tasks) {
			set_completed_tasks([]);
			if (completed_poyline_ref.current && map_ref.current) map_ref.current.removeControl(completed_poyline_ref.current);
			return;
		}
		try {
			const response = await api_requests.get_rider_completed_task_list(id, moment().format('YYYY-MM-DD'));

			const filtered = _.filter(_.get(response, 'data.tasks', []), (item) => item.task_location != null);
			set_completed_tasks(filtered);

			const task_loc_arr = _.map(filtered, (item) => item.task_location);
			task_loc_arr.push(response.data.current_location);

			completed_poyline_ref.current = L.Routing.control({
				waypoints: _.map(task_loc_arr, (item) => L.latLng(item.latitude, item.longitude)),
				lineOptions: {
					styles: [{ color: theme.colors.primary, opacity: 1, weight: 4 }],
				},
				show: false,
				addWaypoints: false,
				routeWhileDragging: false,
				draggableWaypoints: false,
				fitSelectedRoutes: false,
				showAlternatives: false,
				createMarker: () => null,
			}).addTo(map_ref.current);
		} catch (error) {}
	};

	const handle_rider_task_modal_close = () => {
		set_rider_data(null);
		set_rider_tasks({});
		set_rider_markers([]);
		set_three_pl_tasks([]);
		set_selected_outer_filter(null);
		set_show_completed_tasks(false);
		set_completed_tasks([]);
		set_show_network_modal(false);
		set_show_fleet_riders(false);

		if (upcoming_polyline_ref.current && map_ref.current) map_ref.current.removeControl(upcoming_polyline_ref.current);
		if (completed_poyline_ref.current && map_ref.current) map_ref.current.removeControl(completed_poyline_ref.current);
		completed_poyline_ref.current = null;
		upcoming_polyline_ref.current = null;
	};

	const handle_task_marker_click = (e) => {
		if (e.target.options.data.trip_type != 4) {
			set_selected_pb_id(e.target.options.data.external_order_id);
			set_show_pd_id_modal(true);
		} else {
			set_selected_trip_id(e.target.options.data.trip_id);
		}
	};

	const handle_trip_modal_close = () => {
		set_show_trip_id_modal(null);
		set_selected_trip_id(null);
	};

	const handle_trace_nav = (item) => {
		const endpoint = constant.TRACE_FE_URL;
		const owner_id = utils._retrieveData(constant.OWNER_ID);
		const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER).id;
		const token = utils._retrieveData(constant.AUTH_TOKEN);
		const obj = {
			entity_id: item.entity_id,
			entity_type: item.entity_type,
			owner_id,
			owner_type,
			token,
		};
		const url = `${endpoint}?${qs.stringify(obj)}`;
		window.open(url, '_blank');
	};

	const fetch_network_riders = async (location, item) => {
		if (upcoming_polyline_ref.current && map_ref.current) map_ref.current.removeControl(upcoming_polyline_ref.current);
		if (completed_poyline_ref.current && map_ref.current) map_ref.current.removeControl(completed_poyline_ref.current);
		completed_poyline_ref.current = null;
		upcoming_polyline_ref.current = null;
		const id = _.map(item.orders, (order) => order.id);
		set_rider_data(null);
		set_rider_markers([]);
		set_rider_tasks([]);
		set_three_pl_tasks([]);
		// set_fleet_riders([]);
		// set_show_network_modal(false);
		set_show_fleet_riders(false);

		try {
			const response = await api_requests.get_rider_task_api(location.latitude.toFixed(4), location.longitude.toFixed(4), {
				is_busy: true,
				external_order_id: id.join(','),
			});
			if (response.success) {
				set_rider_markers(response.data);
			}
		} catch (error) {}
	};

	const fetch_three_pl_riders = async (item) => {
		if (upcoming_polyline_ref.current && map_ref.current) map_ref.current.removeControl(upcoming_polyline_ref.current);
		if (completed_poyline_ref.current && map_ref.current) map_ref.current.removeControl(completed_poyline_ref.current);
		completed_poyline_ref.current = null;
		upcoming_polyline_ref.current = null;
		const id = _.map(item.orders, (order) => order.id);
		try {
			const response = await api_requests.get_three_pl_riders_list({ id });
			const formatted = [];
			for (let i = 0; i < response.data.length; i++) {
				const element = response.data[i];
				if (element.location.latitude && element.location.longitude) {
					formatted.push({
						rider_location: element.location,
						rider_name: element.rider.name,
						mobile: element.rider.mobile,
						is_three_pl: true,
						three_pl_order_list: item.orders,
					});
				}
			}
			if (formatted.length > 0) {
				set_rider_markers(formatted);
			}
		} catch (error) {}
	};

	const handle_rider_marker_click = (e) => {
		const id = e.target.options.data.rider_id;
		const is_three_pl = e.target.options.data?.is_three_pl;

		if (is_three_pl) {
			if (upcoming_polyline_ref.current && map_ref.current) map_ref.current.removeControl(upcoming_polyline_ref.current);
			if (completed_poyline_ref.current && map_ref.current) map_ref.current.removeControl(completed_poyline_ref.current);
			completed_poyline_ref.current = null;
			upcoming_polyline_ref.current = null;

			const task_loc_arr = _.map(e.target.options.data.three_pl_order_list, (item) => item.customer_detail);
			const task_data = _.map(e.target.options.data.three_pl_order_list, (item) => ({ task_type: 20, task_location: item.customer_detail }));
			task_data.unshift({ task_type: 10, task_location: e.target.options.data.three_pl_order_list[0].sender_detail });
			task_loc_arr.unshift(e.target.options.data.three_pl_order_list[0].sender_detail);
			task_loc_arr.unshift(e.target.options.data.rider_location);
			set_three_pl_tasks(task_data);
			upcoming_polyline_ref.current = L.Routing.control({
				waypoints: _.map(task_loc_arr, (item) => L.latLng(item.latitude, item.longitude)),
				lineOptions: {
					styles: [{ color: theme.colors.lightPurple6, opacity: 0.8, weight: 4 }],
				},
				show: false,
				addWaypoints: false,
				routeWhileDragging: false,
				draggableWaypoints: false,
				fitSelectedRoutes: false,
				showAlternatives: false,
				createMarker: () => null,
			}).addTo(map_ref.current);

			return;
		}

		if (!id) return;
		show_rider_tasks_onmap(id);
	};

	return {
		filter_object,
		zones_list,
		selected_master_zone_id,
		rider_markers,
		rider_data,
		set_rider_data,
		rider_tasks,
		set_rider_tasks,
		show_filters,
		set_show_filters,
		all_filters,
		selected_pb_id,
		set_selected_pb_id,
		show_pd_id_modal,
		set_show_pd_id_modal,
		set_selected_trip_id,
		show_trip_id_modal,
		trip_data,
		has_captive_riders,
		selected_outer_filter,
		show_zones_modal,
		set_show_zones_modal,
		completed_tasks,
		show_completed_tasks,
		show_rider_modal,
		set_show_rider_modal,
		fleet_riders,
		map_ref,
		handle_bubble_click,
		handle_search,
		show_rider_tasks_onmap,
		handle_rider_task_modal_close,
		handle_zone_change,
		handle_completed_task,
		get_on_task_riders,
		handle_task_marker_click,
		handle_trip_modal_close,
		show_network_modal,
		set_show_network_modal,
		network_orders,
		fetch_network_riders,
		fetch_three_pl_riders,
		handle_trace_nav,
		show_toast,
		set_show_toast,
		show_fleet_riders,
		is_api_call_done,
		handle_rider_marker_click,
		three_pl_tasks,
	};
};

export default useTracking;
