import Text from 'modules/Text';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import theme from 'resources/theme';
import { useSelector } from 'react-redux';
import ImageLinks from 'assets/images/ImageLinks';
import { useState } from 'react';
import RadioBox from 'modules/RadioBox';
import { AVATAR_COLOR, AVATER_STYLE_OPTIONS, DISPLAY_STYLE_OPTIONS, ICON_STYLE } from '../helper';
import NetworkAvatar from './NetworkAvatar';
import Upload from 'modules/Upload';
import OutlinedButton from 'modules/OutlinedButton';
import clsx from 'clsx';
import api_requests from 'resources/api_requests';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	container: {
		padding: 20,
		background: 'white',
		width: 580,
		height: 432,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	header_text: { fontSize: 20 },
	cta_btn: {
		width: 110,
		borderRadius: 30,
		margin: '0 auto',
		marginTop: 30,
	},
	monogram_wrap: {
		width: 100,
		height: 100,
		borderRadius: 50,
		marginRight: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	right_wrap: {
		borderRadius: 8,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		padding: 12,
		flex: 1,
		minHeight: 274,
	},
	icon_style_item_wrap: {
		width: 30,
		height: 30,
		borderRadius: 15,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		marginRight: 12,
	},
	avatar_color_item_wrap: {
		width: 20,
		height: 20,
		borderRadius: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		marginRight: 12,
	},
	monogram_error_wrap: {
		marginTop: 8,
		height: 50,
		borderRadius: 8,
		backgroundColor: theme.colors.lightPink,
		justifyContent: 'center',
		alignItems: 'center',
	},
	monogram_error_text: { fontSize: 12, color: theme.colors.darkPurple, marginLeft: 14 },
}));

const NetworkDisplayModal = ({ close, is_name_valid, network_name = '', on_save, display_configs = null, network_image = '' }) => {
	const [display_style, set_display_style] = useState('avatar'); // since upload image is not there so defaulting to avatar.Once image is integrate set default to image
	const [avatar_style, set_avatar_style] = useState('icon');
	const [icon_style, set_icon_style] = useState(ICON_STYLE[0].key);
	const [avatar_color, set_avatar_color] = useState(AVATAR_COLOR[0]);
	const [upload_img, set_upload_img] = useState('');
	const [initials_string, set_initials_string] = useState('');

	const { user_details } = useSelector(({ app }) => app);
	const classes = useStyles();

	useEffect(() => {
		if (_.isEmpty(display_configs)) return;
		set_display_style(display_configs.display_style);
		set_avatar_style(display_configs.avatar_style);
		set_icon_style(display_configs.icon_style);
		set_avatar_color(display_configs.avatar_color);
		set_initials_string(display_configs.initials_string);
		set_upload_img(network_image);
	}, [display_configs]);

	useEffect(() => {
		const upper_case_name = network_name?.toUpperCase();
		const parts = upper_case_name.split(' ');
		let initials = '';
		for (let i = 0; i < parts.length; i++) {
			if (parts[i].length > 0 && parts[i] !== '') {
				initials += parts[i][0];
			}
		}
		set_initials_string(initials.length > 2 ? `${initials[0]}${initials[1]}` : initials);
	}, [network_name]);

	const handle_save = () => {
		const display_configs =
			display_style === 'image'
				? null
				: {
						display_style,
						avatar_style,
						icon_style,
						avatar_color,
						initials_string,
				  };

		on_save(upload_img, display_configs);
		close();
		return;
	};

	const handle_close = () => {
		close();
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		if (display_style === 'image') {
			value = _.isEmpty(upload_img);
		} else {
			if (avatar_style === 'icon') value = false;
			else value = !is_name_valid;
		}
		return value;
	}, [display_style, avatar_style, upload_img, is_name_valid]);

	const handle_upload_image = async (image) => {
		set_upload_img(image);
	};

	const selected_icon_style_val = _.filter(ICON_STYLE, (item) => item.key === icon_style)[0];

	return (
		<Modal open={true} onClose={handle_close} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className={classes.header_wrap}>
					<Text bold style={{ fontSize: 20 }}>
						Network Display
					</Text>
					<img src={ImageLinks.crossBlack} onClick={handle_close} style={{ cursor: 'pointer' }} alt='' />
				</div>

				<div className='align-items-center'>
					{display_style === 'image' ? (
						<NetworkAvatar diameter={100} image={upload_img} />
					) : (
						<div
							className={classes.monogram_wrap}
							style={{
								backgroundColor: !is_name_valid && avatar_style === 'monogram' ? theme.colors.lightGrey5 : avatar_color?.bg,
							}}>
							{avatar_style === 'monogram' ? (
								<Text semi style={{ fontSize: 30, color: is_name_valid ? theme.colors.white : theme.colors.lightGrey2 }}>
									{is_name_valid ? initials_string : '!'}
								</Text>
							) : (
								<img src={selected_icon_style_val?.white_img} width={50} height={50} />
							)}
						</div>
					)}
					<div className={classes.right_wrap}>
						<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
							Display Style
						</Text>
						<RadioBox
							wrapper_styles={{ marginTop: 8 }}
							selected_item={display_style}
							on_item_press={(val) => {
								set_display_style(val);
							}}
							options={DISPLAY_STYLE_OPTIONS}
						/>
						{display_style === 'image' && (
							<Upload
								style={{
									marginTop: 22,
									borderRadius: 10,
									border: `1px dashed ${theme.colors.lightGrey2}`,
									height: 190,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									background: theme.colors.white,
								}}
								path={`/network/logo/network_${Date.now()}_${user_details.user_id}`}
								accepted_extensions='.jpg,.jpeg,.png'
								add_image={(data) => {
									handle_upload_image(data.url);
								}}
								is_image_crop={true}>
								<img width={30} height={30} src={_.isEmpty(upload_img) ? ImageLinks.upload_light_purple : ImageLinks.green_tick} />
								<Text medium style={{ fontSize: 13, marginTop: 20 }}>
									{_.isEmpty(upload_img) ? 'Select a .JPG, .PNG file or drag and drop here' : 'Image uploaded successfully!'}
								</Text>
								{_.isEmpty(upload_img) ? (
									<ContainedButton style={{ borderRadius: 30, marginTop: 20 }}>
										<Text semi style={{ fontSize: 12, color: theme.colors.white }}>
											Select File
										</Text>
									</ContainedButton>
								) : (
									<OutlinedButton style={{ borderRadius: 30, marginTop: 20 }}>
										<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
											Upload Again
										</Text>
									</OutlinedButton>
								)}
							</Upload>
						)}

						{display_style === 'avatar' && (
							<>
								<div style={{ marginTop: 22 }}>
									<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
										Avatar Style
									</Text>
									<RadioBox
										wrapper_styles={{ marginTop: 8 }}
										selected_item={avatar_style}
										on_item_press={(val) => {
											set_avatar_style(val);
										}}
										options={AVATER_STYLE_OPTIONS}
									/>
								</div>
								{avatar_style === 'icon' && (
									<div style={{ marginTop: 22 }}>
										<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
											Icon Style
										</Text>
										<div className='d-flex' style={{ marginTop: 8 }}>
											{_.map(ICON_STYLE, (item, index) => (
												<div
													key={`icon_style_${index}`}
													onClick={() => set_icon_style(item.key)}
													className={classes.icon_style_item_wrap}
													style={{
														backgroundColor: icon_style === item.key ? theme.colors.lightPurple : 'transparent',
													}}>
													<img src={icon_style === item.key ? item.selected_img : item.default_img} width={20} height={20} />
												</div>
											))}
										</div>
									</div>
								)}
								<div
									style={{
										marginTop: 22,
										opacity: avatar_style === 'monogram' && !is_name_valid ? 0.3 : 1,
										pointerEvents: avatar_style === 'monogram' && !is_name_valid ? 'none' : 'auto',
									}}>
									<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
										Avatar Colour
									</Text>
									<div className='d-flex' style={{ marginTop: 8 }}>
										{_.map(AVATAR_COLOR, (item, index) => (
											<div
												key={`avatar_color_${index}`}
												onClick={() => set_avatar_color(item)}
												className={classes.avatar_color_item_wrap}
												style={{
													backgroundColor: item.bg,
												}}>
												{avatar_color?.key === item.key && <img src={ImageLinks.check} width={7.5} height={5} />}
											</div>
										))}
									</div>
								</div>

								{!is_name_valid && avatar_style === 'monogram' && (
									<div style={{ marginTop: 22 }}>
										<div className={clsx('d-flex', classes.monogram_error_wrap)}>
											<img src={ImageLinks.info_light_purple} width={20} height={20} />
											<Text medium className={classes.monogram_error_text}>
												Please claim network name to set avatar as monogram.{' '}
											</Text>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</div>
				<Button type='round' text='Save' size='large' disabled={btn_disabled} onClick={handle_save} className={classes.cta_btn} />
			</div>
		</Modal>
	);
};

export default NetworkDisplayModal;
