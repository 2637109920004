import React, { useMemo, useState } from 'react';
import theme from 'resources/theme';
import _ from 'lodash';
import Text from 'modules/Text';
import IconButton from 'modules/IconButton';
import cross from 'assets/images/icons/cross-black.svg';
import ImageLinks from 'assets/images/ImageLinks';
import RiderAddress from 'modules/modals/RiderAddress';
import api_requests from 'resources/api_requests';
import { InputAdornment, makeStyles } from '@material-ui/core';
import Select from 'modules/Select';
import moment from 'moment';
import { toast } from 'react-toastify';
import Button from 'modules/Button';
import OutlinedTextField from 'modules/OutlinedTextField';
import ContainedButton from 'modules/ContainedButton';

const useStyles = makeStyles({
	wrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	sub_wrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 500,
		backgroundColor: 'white',
		borderTopLeftRadius: 50,
		padding: 32,
		zIndex: 1200,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	header_wrapper: { borderBottom: '1px solid lightgrey', paddingBottom: 10, display: 'flex', justifyContent: 'space-between' },
	create_rider_wrap: { display: 'flex', alignItems: 'center' },
	create_rider_text: { fontSize: 18, color: theme.colors.primary, lineHeight: '20px' },
	heading_text: { fontSize: 12, color: theme.colors.black, margin: '20px 0 4px 0' },
	input_wrapper: { display: 'flex', justifyContent: 'space-between', margin: '0px 2px', marginTop: 30 },
	contact_wrapper: { display: 'flex', justifyContent: 'space-between', marginTop: 40 },
	contact_text: { fontSize: 16, marginTop: 20 },
	width45: { width: '45%' },
	address_text: { fontSize: 16, marginTop: 40 },
	add_rider_btn: { borderRadius: 25, height: 45, width: 110 },
	add_save_btn_wrap: { display: 'flex', height: 20, borderRadius: 17, justifyContent: 'center', alignItems: 'center', padding: '4px 8px', cursor: 'pointer' },
	tags_wrap: {
		marginTop: 2,
		borderRadius: 8,
		backgroundColor: theme.colors.white,
		padding: 16,
	},
	tags_empty_text: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 17, textAlign: 'center' },
	content_wrap: { display: 'flex', flexDirection: 'column', flex: 1, paddingBottom: 100 },
	cta_wrap: {
		width: 500,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		padding: '20px 30px',
		boxShadow: '0px -4px 14px 0px rgba(0, 0, 0, 0.05)',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	inputs: {
		width: '48%',
		'& .MuiFormLabel-root': {
			marginTop: -6,
		},
		'& .MuiInputLabel-shrink': {
			marginTop: 0,
		},
		'& .MuiInputLabel-outlined': {},
		'& .MuiInputBase-root': {
			'& .MuiInputBase-input': {
				height: 40,
				padding: 0,
				paddingLeft: 10,
			},
		},
	},
	details_wrap: {
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		flexWrap: 'wrap',
		display: 'flex',
		justifyContent: 'space-between',
		gap: 10,
		padding: 16,
		borderRadius: 6,
	},
	action_buttons: {
		backgroundColor: theme.colors.lightPink4,
		borderRadius: 20,
		boxShadow: 'none',
		padding: '',
		'&:hover': { backgroundColor: theme.colors.lightPink4 },
	},
	button_text: { fontSize: 12, color: theme.colors.primary, lineHeight: '16px' },
});

const CreateRider = ({ close, zone_id }) => {
	const [address_modal, set_address_modal] = React.useState(false);

	const [rider_details, set_rider_details] = React.useState({
		first_name: '',
		last_name: '',
		mobile: '',
		date_of_birth: '',
		aadhar_number: '',
		rider_category: 1,
		blood_group: null,
		current_location: {
			address_line_1: '',
			pin_code: null,
			city: '',
			state: '',
			latitude: null,
			longitude: null,
		},
	});

	const [rider_tags, set_rider_tags] = useState([]);
	const classes = useStyles();

	const handleChange = (e) => {
		set_rider_details({
			...rider_details,
			[e.target.name]: e.target.value,
		});
	};

	const handle_dob = (e) => {
		const today_date = moment().format('YYYY-MM-DD');

		const years_diff = moment(today_date).diff(moment(e.target.value), 'years');
		if (years_diff < 15) {
			toast.error(`Minimum 16 years of age is required.`, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}
		handleChange(e);
	};

	const on_address_select = (address) => {
		set_rider_details({
			...rider_details,
			current_location: {
				address_line_1: address.address_line1,
				pin_code: address.pincode,
				city: address.city,
				state: address.state,
				latitude: address.coordinates.latitude,
				longitude: address.coordinates.longitude,
			},
		});
	};

	const handleSubmit = () => {
		const _rider_tags = _.map(rider_tags, (item) => ({ key: item.key, value: item.value }));
		api_requests
			.create_rider({
				login_info: rider_details,
				tags: _rider_tags,
			})
			.then((res) => {
				close(true);
			});
	};

	const on_tag_cta_press = () => {
		set_rider_tags([...rider_tags, { key: '', value: '' }]);
	};

	const handle_tags_edit = (e, index) => {
		const _rider_tags = _.cloneDeep(rider_tags);
		_rider_tags[index][e.target.name] = e.target.value;
		set_rider_tags(_rider_tags);
	};
	const handle_delete_tag = (index) => {
		const _rider_tags = _.cloneDeep(rider_tags);
		_rider_tags.splice(index, 1);
		set_rider_tags(_rider_tags);
	};

	const is_tag_empty = useMemo(() => {
		let result = false;
		_.forEach(rider_tags, (item) => {
			if (item.key == '' || item.value == '') result = true;
		});
		return result;
	}, [rider_tags]);

	return (
		<>
			<div className={classes.wrapper} onClick={() => close(false)} />
			<div className={classes.sub_wrapper}>
				<div className={classes.content_wrap}>
					<div className={classes.header_wrapper}>
						<div className={classes.create_rider_wrap}>
							<Text bold className={classes.create_rider_text}>
								Create Rider
							</Text>
						</div>
						<IconButton onClick={() => close(false)} style={{ border: 'none' }}>
							<img src={cross} width={16} height={16} />
						</IconButton>
					</div>
					<Text semi className={classes.heading_text}>
						Rider Identity*
					</Text>

					<div className={classes.details_wrap}>
						<OutlinedTextField
							label='First Name'
							value={rider_details.first_name}
							onChange={handleChange}
							name='first_name'
							className={classes.inputs}
						/>
						<OutlinedTextField
							label='Last Name'
							value={rider_details.last_name}
							onChange={handleChange}
							name='last_name'
							className={classes.inputs}
						/>
						<OutlinedTextField
							type='date'
							label='Date of Birth'
							value={rider_details.date_of_birth}
							className={classes.inputs}
							InputLabelProps={{ shrink: true }}
							onChange={handle_dob}
							name='date_of_birth'
							style={{ marginTop: 15 }}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<img src={ImageLinks.calendar_outlined} alt='date' className='cursor-pointer' width={'18px'} height={'18px'} />
									</InputAdornment>
								),
							}}
							inputProps={{ max: moment().format('YYYY-MM-DD') }}
						/>
						<Select
							value={rider_details.blood_group}
							defaultOption={{
								title: `Select Blood Group`,
								value: null,
							}}
							options={[
								{ title: 'A+', value: 'A+' },
								{ title: 'A-', value: 'A-' },
								{ title: 'B+', value: 'B+' },
								{ title: 'B-', value: 'B-' },
								{ title: 'AB+', value: 'AB+' },
								{ title: 'AB-', value: 'AB-' },
								{ title: 'O+', value: 'O+' },
								{ title: 'O-', value: 'O-' },
							]}
							handleChange={(v) => {
								set_rider_details({
									...rider_details,
									blood_group: v,
								});
							}}
							containerStyles={{
								height: 40,
								width: '48%',
								marginTop: 15,
								border: rider_details.blood_group == null ? `1px solid ${theme.colors.lightPurple6}` : `1px solid ${theme.colors.primary}`,
							}}
							text_props={{ semi: true }}
							menu_styles={{
								width: 180,
								height: 200,
							}}
							selected_title_styles={{ color: rider_details.blood_group == null ? theme.colors.lightPurple6 : theme.colors.primary }}
						/>
					</div>
					<Text semi className={classes.heading_text}>
						Contact & Govt Verification*
					</Text>
					<div className={classes.details_wrap}>
						<OutlinedTextField
							label='Personal Mobile'
							value={rider_details.mobile}
							onChange={handleChange}
							name='mobile'
							className={classes.inputs}
						/>
						<OutlinedTextField
							label='Aadhar Number'
							value={rider_details.aadhar_number}
							onChange={handleChange}
							name='aadhar_number'
							className={classes.inputs}
						/>
					</div>
					<Text semi className={classes.heading_text}>
						Address*
					</Text>
					<div>
						{_.isEmpty(rider_details.current_location.address_line_1) ? (
							<div className={classes.details_wrap} style={{ flexDirection: 'column', alignItems: 'center' }}>
								<Text regular style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Map your rider's home location
								</Text>
								<ContainedButton className={classes.action_buttons} onClick={() => set_address_modal(true)}>
									<Text semi className={classes.button_text}>
										+ Add Address
									</Text>
								</ContainedButton>
							</div>
						) : (
							<div>
								<div className={classes.details_wrap}>
									<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, width: '80%' }}>
										{_.join(
											[
												rider_details.current_location.address_line_1,
												rider_details.current_location.city,
												rider_details.current_location.state,
												rider_details.current_location.pin_code,
											],
											',',
										)}
									</Text>
									<img
										src={ImageLinks.edit_filled_light_purple}
										height={16}
										width={16}
										style={{ cursor: 'pointer' }}
										onClick={() => set_address_modal(true)}
									/>
								</div>
							</div>
						)}
					</div>

					<div>
						<div className='align-items-center justify-content-between'>
							<Text semi className={classes.heading_text}>
								Rider Tags
							</Text>
						</div>

						{rider_tags.length === 0 ? (
							<div className={classes.details_wrap} style={{ flexDirection: 'column', alignItems: 'center' }}>
								<Text regular style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Categorize your fleet of riders for better organisation
								</Text>
								<ContainedButton className={classes.action_buttons} onClick={on_tag_cta_press}>
									<Text semi className={classes.button_text}>
										+ Add Tags
									</Text>
								</ContainedButton>
							</div>
						) : (
							<div className={classes.details_wrap}>
								{_.map(rider_tags, (item, index) => (
									<div
										key={`rider_tags_item_${index}`}
										className='d-flex align-items-center justify-content-between'
										style={{ width: '100%' }}>
										<OutlinedTextField
											value={item.key}
											placeholder='Title (key)'
											onChange={(e) => handle_tags_edit(e, index)}
											name='key'
											className={classes.inputs}
											style={{ width: '30%', marginLeft: 10 }}
										/>
										<OutlinedTextField
											value={item.value}
											className={classes.inputs}
											placeholder='Description (key)'
											onChange={(e) => handle_tags_edit(e, index)}
											name='value'
											style={{ width: '60%', marginLeft: 10 }}
										/>
										<img
											src={ImageLinks.delete}
											height={20}
											width={20}
											style={{ cursor: 'pointer' }}
											onClick={() => handle_delete_tag(index)}
										/>
									</div>
								))}
								<div>
									<Button
										type='text'
										size='extra_small'
										text='New Tag'
										onClick={on_tag_cta_press}
										left_icon={ImageLinks.add_pruple_no_fill}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className={classes.cta_wrap}>
					<Button
						type='round'
						text='Create'
						size='large'
						onClick={handleSubmit}
						disabled={
							_.isEmpty(rider_details.first_name) ||
							_.isEmpty(rider_details.last_name) ||
							_.isEmpty(rider_details.blood_group) ||
							_.isEmpty(rider_details.date_of_birth) ||
							_.isEmpty(rider_details.mobile) ||
							_.isEmpty(rider_details.aadhar_number) ||
							_.isEmpty(rider_details.current_location.address_line_1) ||
							is_tag_empty
						}
						style={{ width: 110 }}
					/>
				</div>
				{address_modal && <RiderAddress zone_id={zone_id} close={() => set_address_modal(false)} on_address_select={on_address_select} />}
			</div>
		</>
	);
};

export default CreateRider;
