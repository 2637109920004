import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';

import Text from './Text';
import Button from './Button';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 12,
	},
	image_wrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const NoResult = ({ image_url = ImageLinks.no_result, text, btn_text = 'Reset', btn_onclick = () => {} }) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div className={classes.image_wrapper}>
				<img src={image_url} />
				<Text semi style={{ fontSize: 16 }}>
					{text}
				</Text>
			</div>
			<Button size='medium' type='round' text={btn_text} onClick={btn_onclick} />
		</div>
	);
};

export default NoResult;
