export const TYPES = {
	manual: 'manual',
	network: 'network',
};

export const AVAILABLE_RIDER_TYPE = {
	available: 'available',
	lowest_cost: 'lowest_cost',
	broadcast: 'broadcast',
};

export const BOTTOM_STATE = {
	start: 'start',
	in_progress: 'in_progress',
	failed: 'failed',
	success: 'success',
};
