import { Divider, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import { DateRangePicker } from 'materialui-daterange-picker';
import ImageLinks from 'assets/images/ImageLinks';
import OrderStatusChart from './OrderStatusChart';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
	overview_container: {
		backgroundColor: 'rgba(242, 238, 248, 0.70)',
		borderRadius: 8,
		padding: 12,
		margin: '10px 0',
	},
	divider: {
		width: 1,
		height: 'auto',
		backgroundColor: theme.colors.lightPurple,
	},
	total_order_wrap: { flex: 1, padding: '5px 16px', backgroundColor: theme.colors.white, borderRadius: 8 },
	date_pick_wrap: { position: 'absolute', right: 0, top: 50, width: 750, zIndex: 1300 },
}));

function NetworkAnalytics({ selected_network }) {
	const classes = useStyles();
	const [network_overview, set_network_overview] = useState({});
	const [network_stats, set_network_stats] = useState({});
	const [network_stats_prev, set_network_stats_prev] = useState({});
	const [start_date, set_start_date] = useState(moment().subtract(7, 'days'));
	const [end_date, set_end_date] = useState(moment());
	const [date_picker_open, set_date_picker_open] = useState(false);

	useEffect(() => {
		if (selected_network.id) {
			get_network_overview();
		}
	}, [selected_network]);

	useEffect(() => {
		get_network_stats();
		get_network_stats_previous_week();
	}, [selected_network, end_date, start_date]);

	const get_network_overview = async () => {
		try {
			const res = await api_requests.get_network_analytics_overview(selected_network.id, {
				created_at: `${moment().format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`,
			});
			set_network_overview(res.data);
		} catch (err) {}
	};

	const get_network_stats = async () => {
		try {
			const res = await api_requests.get_network_analytics_stats(selected_network.id, {
				created_at: `${moment(start_date).format('YYYY-MM-DD')},${moment(end_date).format('YYYY-MM-DD')}`,
			});
			set_network_stats(res.data);
		} catch (err) {}
	};

	const get_network_stats_previous_week = async () => {
		try {
			const diff = moment(end_date).diff(moment(start_date), 'days');
			const res = await api_requests.get_network_analytics_stats(selected_network.id, {
				created_at: `${moment(start_date)
					.subtract(diff + 1, 'days')
					.format('YYYY-MM-DD')},${moment(start_date).subtract(1, 'days').format('YYYY-MM-DD')}`,
			});
			set_network_stats_prev(res.data);
		} catch (err) {}
	};

	const handle_range_change = (range) => {
		const newDateRange = {
			from: moment(range.startDate),
			to: moment(range.endDate),
		};
		if (Math.abs(newDateRange.from.diff(newDateRange.to, 'days')) > 6) {
			toast.error('Only 7 days filter allowed', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}
		set_start_date(moment(range.startDate));
		set_end_date(moment(range.endDate));
		set_date_picker_open(!date_picker_open);
	};

	return (
		<div>
			<div className={classes.overview_container}>
				<div className='d-flex justify-content-center' style={{ width: '100%' }}>
					<div className='d-flex justify-content-center' style={{ flexDirection: 'column', position: 'relative', flex: 1 }}>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Overview Today
						</Text>
					</div>
					<div className={classes.total_order_wrap}>
						<Text semi style={{ fontSize: 20 }}>
							{_.get(network_overview, 'total_order', 0)}
						</Text>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Total Orders
						</Text>
					</div>
					<div style={{ flex: 1, padding: '5px 16px' }}>
						<Text semi style={{ fontSize: 20 }}>
							{_.get(network_overview, 'ready_to_ship', 0)}
						</Text>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Manifested
						</Text>
					</div>
					<div className={classes.divider} />
					<div style={{ flex: 1, padding: '5px 16px' }}>
						<Text semi style={{ fontSize: 20 }}>
							{_.get(network_overview, 'shipped', 0)}
						</Text>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Shipped
						</Text>
					</div>
					<div className={classes.divider} />
					<div style={{ flex: 1, padding: '5px 16px' }}>
						<Text semi style={{ fontSize: 20 }}>
							{_.get(network_overview, 'completed', 0)}
						</Text>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Completed
						</Text>
					</div>
				</div>
			</div>
			<div className='d-flex align-items-center justify-content-between'>
				<Text semi style={{ fontSize: 16 }}>
					Analytics
				</Text>
				<div className='d-flex align-items-center'>
					<Text bold style={{ fontSize: 16, color: theme.colors.primary }}>{`${start_date.format('DD MMM `YY')} -${end_date.format(
						'DD MMM `YY',
					)}`}</Text>
					<img src={ImageLinks.calendar_solid_circle} onClick={() => set_date_picker_open(true)} style={{ marginLeft: 8, cursor: 'pointer' }} />
				</div>
				<div className={classes.date_pick_wrap}>
					<DateRangePicker
						open={date_picker_open}
						toggle={() => set_date_picker_open((prev) => !prev)}
						style={{ width: 150 }}
						initialDateRange={{
							startDate: start_date,
							endDate: end_date,
						}}
						onChange={handle_range_change}
						maxDate={new Date()}
					/>
				</div>
			</div>
			<Divider style={{ margin: '10px 0' }} />
			<OrderStatusChart network_stats={network_stats} network_stats_prev={network_stats_prev} />
		</div>
	);
}

export default NetworkAnalytics;
