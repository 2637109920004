import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Text from 'modules/Text';
import theme from 'resources/theme';
import moment from 'moment';
import ImageLinks from 'assets/images/ImageLinks';
import { DateRangePicker } from 'materialui-daterange-picker';
import { Divider, Grid } from '@material-ui/core';
import _ from 'lodash';
import AttendencePerformance from './components/AttendencePerformance';
import DistancePerDay from './components/DistancePerDay';
import OrdersPerDay from './components/OrdersPerDay';
import { Link } from 'react-router-dom';
import Routes from 'resources/Routes';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
	container: {
		padding: '32px 40px 32px 30px',
	},
	delivery_content: {
		display: 'inline-block',
		background: theme.colors.lightPurple9,
		color: theme.colors.primary,
		padding: '4px 8px',
		borderRadius: '16px',
		marginLeft: 4,
	},
	date_text: { color: theme.colors.primary, fontSize: 16 },
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
	dot_content: {
		width: 8,
		height: 8,
		borderRadius: '50%',
	},
});

const AdvancedRiderAnalytics = ({ history }) => {
	const classes = useStyles();
	const [data, set_data] = useState(null);
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [filters, set_filters] = useState({
		fromDate: moment(moment().subtract('6', 'days').format('MM-DD-YYYY')),
		toDate: moment(moment().format('MM-DD-YYYY')),
	});

	const days_diff = filters.toDate.diff(filters.fromDate, 'days');

	useEffect(() => {
		if (days_diff <= 6) {
			get_analytics_data();
		} else {
			toast.error('You cannot select date more than 7 days', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		}
	}, [filters]);

	const get_analytics_data = async () => {
		try {
			const res = await api_requests.get_advanced_rider_analytics({
				fromDate: moment.utc(filters.fromDate.startOf('day')).format(),
				toDate: moment.utc(filters.toDate.startOf('day')).format(),
			});
			if (!_.isEmpty(res.data)) {
				set_data(res.data);
			}
		} catch (err) {}
	};

	return (
		<div className={classes.container}>
			<div className='align-items-center'>
				<Text className='cursor-pointer' style={{ fontSize: 22 }} onClick={() => history.goBack()}>
					Riders
				</Text>
				<div className='header_separator'></div>
				<Text semi className='header_active_title'>
					Advanced Rider Analytics
				</Text>
			</div>
			<div className='justify-content-between align-items-center'>
				<div className='align-items-center' style={{ paddingTop: 16 }}>
					<Text bold style={{ fontSize: 16 }}>
						{filters.toDate.diff(filters.fromDate, 'days') + 1} Days Overview <span style={{ fontWeight: 'normal', fontSize: 16 }}>as per</span>
					</Text>
					<div className={classes.delivery_content}>
						<Text bold style={{ fontSize: 14 }}>
							Delivery Date
						</Text>
					</div>
				</div>
				<div>
					<div style={{ position: 'relative' }}>
						<div className='d-flex' style={{ gap: 10 }}>
							<div>
								<Text bold className={classes.date_text}>{`${filters.fromDate.format('DD MMM `YY')} - ${filters.toDate.format(
									'DD MMM `YY',
								)}`}</Text>
							</div>
							<img
								src={ImageLinks.calendar_solid_circle}
								alt='date'
								onClick={() => set_date_picker_open(true)}
								className='cursor-pointer'
								width={'22px'}
								height={'22px'}
							/>
						</div>
						<div style={{ position: 'absolute', right: 0, width: 700 }}>
							<DateRangePicker
								open={date_picker_open}
								toggle={() => set_date_picker_open((prev) => !prev)}
								style={{ width: 200 }}
								initialDateRange={{
									startDate: filters.fromDate,
									endDate: filters.toDate,
								}}
								maxDate={new Date()}
								onChange={(range) => {
									const newDateRange = {
										fromDate: moment(range.startDate),
										toDate: moment(range.endDate),
									};
									set_filters(newDateRange);
									set_date_picker_open(!date_picker_open);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<Divider className={classes.divider} />
			<Grid container spacing={3} style={{ marginTop: 8, overflowY: 'auto', maxHeight: '80vh' }}>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<AttendencePerformance analytics_data={data} />
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<DistancePerDay analytics_data={data} />
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<OrdersPerDay analytics_data={data} />
				</Grid>
			</Grid>
		</div>
	);
};
export default AdvancedRiderAnalytics;
