import React from 'react';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';

import theme from 'resources/theme';
import Text from 'modules/Text';
import { Bar } from 'react-chartjs-2';
import { nFormatter } from 'views/AdvancedOrderAnalytics/helper';

const useStyles = makeStyles({
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
	analytics_container: {
		padding: 12,
		borderRadius: 12,
		background: theme.colors.white,
		boxShadow: '0px 1.0728px 3.21839px rgba(13, 10, 44, 0.08)',
		marginTop: 12,
	},
	dot_content: {
		width: 8,
		height: 8,
		borderRadius: '50%',
	},
});

const VehiclePerformceChart = ({ performance_data }) => {
	const classes = useStyles();
	const labels = _.map(performance_data, (item_value) => {
		return moment(item_value.date).format('DD-MMM');
	});

	const data = {
		labels: labels,
		datasets: [
			{
				label: 'Delayed Start',
				data: _.map(performance_data, (item_value) => {
					if (item_value?.start_status == 'delayed') return item_value.cnt;
				}),
				backgroundColor: theme.colors.lightPurple6,
				hoverBackgroundColor: theme.colors.lightPurple12,
			},
			{
				label: 'On Time Start',
				data: _.map(performance_data, (item_value) => {
					if (item_value?.start_status !== 'delayed') return item_value.cnt;
				}),
				backgroundColor: theme.colors.lightPurple5,
				// borderWidth: 4,
				borderRadius: { topLeft: 4, topRight: 4, bottomLeft: 0, bottomRight: 0 },
				borderSkipped: false,
				borderColor: theme.colors.lightPurple6,
			},
		],
	};

	const config = {
		type: 'bar',
		data: data,
		options: {
			hover: { mode: null },
			plugins: {
				legend: false,
				datalabels: {
					display: false,
				},
				tooltip: {
					enabled: true,
				},
			},
			responsive: true,
			scales: {
				x: {
					grid: {
						display: false,
						color: 'red',
					},
					ticks: {
						color: theme.colors.darkGrey2,
						font: {
							size: 10,
							family: 'HK Grotesk Medium',
							weight: 'bold',
						},
					},
					stacked: true,
				},
				y: {
					border: {
						display: false,
					},
					max: 50,
					min: 0,
					ticks: {
						stepSize: 10,
						font: {
							color: theme.colors.darkGrey2,
							size: 10,
							family: 'HK Grotesk Medium',
							weight: 'bold',
						},
						callback: nFormatter,
					},
					stacked: true,
				},
			},
		},
	};

	return (
		<div className={classes.analytics_container}>
			<div className='align-items-center justify-content-between'>
				<Text bold style={{ fontSize: 14 }}>
					Vehicle Performance
				</Text>
				<div className='d-flex'>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.lightPurple5 }} />
						<Text style={{ fontSize: 12 }}>On Time Start</Text>
					</div>
					<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
						<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.lightPurple6 }} />
						<Text style={{ fontSize: 12 }}>Delayed Start</Text>
					</div>
				</div>
			</div>
			<Divider className={classes.divider} />
			<div style={{ width: '100%', height: '100%' }}>{!_.isEmpty(performance_data) ? <Bar {...config} /> : <></>}</div>
		</div>
	);
};

export default VehiclePerformceChart;
