import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { LinearProgress, makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import ContainedButton from 'modules/ContainedButton';
import Amount from 'modules/Amount';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import { BOTTOM_STATE, TYPES } from '../helper';
import moment from 'moment';
import OutlinedButton from 'modules/OutlinedButton';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	info_container: {
		'&:hover > $detailed_info_container': {
			display: 'block',
		},
	},
	detailed_info_container: {
		display: 'none',
		minWidth: 260,
		height: 90,
		background: theme.colors.lightPurple8,
		position: 'absolute',
		top: -110,
		left: -50,
		borderRadius: 10,
		padding: 10,
	},
	schedule_info_wrap: {
		display: 'flex',
		alignItems: 'center',
		background: theme.colors.white,
	},
}));

const AllocationFooter = ({
	selected_type,
	payment_details,
	is_low_balance,
	bottom_bar_state,
	total_orders,
	btn_disabled,
	handle_allocate_rider,
	handle_track,
	set_show_add_funds,
	success_count,
	failed_count,
	selected_three_pl,
	show_schedule,
	selected_date,
	selected_time,
	schedule_slots,
	handle_schedule_get_slots,
	failed_msg,
	selected_network,
	handle_try_again,
	is_schedule_success,
}) => {
	const [progress, setProgress] = useState(0);
	const timer_ref = useRef(null);
	const classes = useStyles();

	useEffect(() => {
		return () => {
			clearInterval(timer_ref.current);
		};
	}, []);

	useEffect(() => {
		if (bottom_bar_state === BOTTOM_STATE.in_progress) {
			let percent = ((success_count + failed_count) / total_orders) * 100;
			setProgress(percent);
		}
	}, [bottom_bar_state, success_count, failed_count]);

	useEffect(() => {
		if (bottom_bar_state === BOTTOM_STATE.in_progress) start_animation();
		else {
			setProgress(0);
			clearInterval(timer_ref.current);
		}
	}, [bottom_bar_state]);

	const start_animation = () => {
		if (selected_type === TYPES.SMART || selected_type === TYPES.AUTO_MANIFEST) return;
		timer_ref.current = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					clearInterval(timer_ref.current);
				}
				return oldProgress + 25;
			});
		}, 1000);
	};

	const selected_date_slots = _.find(schedule_slots, { date: selected_date })?.slots || [];

	return (
		<>
			{bottom_bar_state === BOTTOM_STATE.start && (
				<>
					{(selected_type === TYPES.NETWORK || selected_type === TYPES.THREE_PL) && !is_low_balance ? (
						<div>
							<div className='align-items-center'>
								<Text bold style={{ fontSize: 16 }}>
									₹{payment_details.price}
								</Text>
								<div style={{ position: 'relative' }} className={classes.info_container}>
									<div className={classes.detailed_info_container}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img src={ImageLinks.info_light_purple} alt='' style={{ marginLeft: 5, cursor: 'pointer' }} />
											<Text bold style={{ color: theme.colors.primary, flex: 1, marginLeft: 10 }}>
												Total Shipping Cost
											</Text>
											<Amount
												amount={payment_details.price}
												main_text_styles={{ color: theme.colors.black, fontSize: 16 }}
												sub_text_styles={{ color: theme.colors.black, fontSize: 10 }}
											/>
										</div>
										<div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
											<Text bold style={{ color: theme.colors.darkGrey2, flex: 1, marginLeft: 35 }}>
												Delivery Charges
											</Text>

											<Amount
												amount={_.get(payment_details, 'price_breakup.base_delivery_charge')}
												main_text_styles={{ color: theme.colors.darkGrey2, fontSize: 12 }}
												sub_text_styles={{ color: theme.colors.darkGrey2, fontSize: 10 }}
											/>
										</div>
										<div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
											<Text bold style={{ color: theme.colors.darkGrey2, flex: 1, marginLeft: 35 }}>
												GST
											</Text>

											<Amount
												amount={_.get(payment_details, 'price_breakup.total_gst_amount')}
												main_text_styles={{ color: theme.colors.darkGrey2, fontSize: 12 }}
												sub_text_styles={{ color: theme.colors.darkGrey2, fontSize: 10 }}
											/>
										</div>
										<div style={{ marginLeft: 32, marginTop: 5 }}>
											<div
												style={{
													width: 0,
													height: 0,
													borderLeft: '20px solid transparent',
													borderRight: '20px solid transparent',
													borderTop: `20px solid ${theme.colors.lightPurple8}`,
												}}></div>
										</div>
									</div>
									<img src={ImageLinks.info_purple} width={16} height={16} style={{ marginLeft: 4, cursor: 'pointer', marginTop: 2 }} />
								</div>
							</div>
							<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Total Payable
							</Text>
						</div>
					) : (
						<div>
							{is_low_balance && (
								<div className='align-items-center'>
									<img src={ImageLinks.exclamation_error} width={16} height={16} style={{ marginRight: 8 }} />
									<Text bold style={{ fontSize: 16, color: theme.colors.red }}>
										Insufficient balance in wallet
									</Text>
								</div>
							)}
						</div>
					)}
					{is_low_balance ? (
						<Button type='round' text='Add Funds' size='large' onClick={() => set_show_add_funds(true)} left_icon={ImageLinks.addWhite} />
					) : (
						<div className='align-items-center'>
							{!show_schedule &&
								((selected_type === TYPES.THREE_PL && selected_three_pl.schedule) ||
									(selected_type === TYPES.NETWORK && selected_network.schedule) ||
									selected_type === TYPES.BUSINESS ||
									selected_type === TYPES.SMART ||
									selected_type === TYPES.AUTO_MANIFEST) && (
									<ContainedButton
										disableElevation
										style={{
											borderRadius: 18,
											height: 36,
											width: 36,
											padding: 0,
											marginLeft: 4,
											marginRight: 8,
											backgroundColor: theme.colors.lightPurple,
										}}
										onClick={handle_schedule_get_slots}>
										<img src={ImageLinks.clock_schedule_purple} alt='clock' />
									</ContainedButton>
								)}

							{show_schedule && !_.isEmpty(selected_date) && selected_time && (
								<div className={classes.schedule_info_wrap}>
									<img src={ImageLinks.clock_schedule_purple} alt='' />
									<Text medium style={{ color: theme.colors.primary, margin: '0px 10px' }}>
										Schedule on{' '}
										<Text component={'span'} bold style={{ color: theme.colors.primary }}>
											{selected_date ? moment(selected_date, 'YYYY-MM-DD').format('ddd, DD MMM') : ''}{' '}
										</Text>
										<Text component={'span'} bold style={{ color: theme.colors.primary }}>
											{selected_time ? _.find(selected_date_slots, { value: selected_time })?.title : ''}
										</Text>
									</Text>
								</div>
							)}

							{((selected_type === TYPES.THREE_PL && selected_three_pl.manifest) ||
								(selected_type === TYPES.NETWORK && selected_network.manifest)) &&
								!show_schedule && (
									<OutlinedButton
										disableElevation
										style={{ borderRadius: 30, height: 36, width: 136, padding: 0, marginRight: 4 }}
										onClick={() => {
											handle_allocate_rider(true);
										}}>
										<Text semi style={{ fontSize: 16 }}>
											Manifest
										</Text>
									</OutlinedButton>
								)}

							{(selected_type === TYPES.SMART ||
								selected_type === TYPES.AUTO_MANIFEST ||
								selected_type === TYPES.BUSINESS ||
								(selected_type === TYPES.NETWORK && (selected_network?.pickup_now || (selected_network?.schedule && show_schedule))) ||
								(selected_type === TYPES.THREE_PL && (selected_three_pl?.pickup_now || (selected_three_pl?.schedule && show_schedule)))) && (
								<Button
									type='round'
									text={show_schedule ? 'Schedule' : selected_type === TYPES.AUTO_MANIFEST ? 'Manifest' : 'Allocate Now'}
									size='large'
									disabled={show_schedule ? (!_.isEmpty(selected_date) && selected_time ? false : true) : btn_disabled}
									onClick={() => {
										handle_allocate_rider(false);
									}}
								/>
							)}
						</div>
					)}
				</>
			)}

			{bottom_bar_state === BOTTOM_STATE.in_progress && (
				<div style={{ flex: 1 }}>
					<LinearProgress variant='determinate' style={{ width: '100%' }} color='primary' value={progress} />
					<Text bold style={{ fontSize: 16, marginTop: 10 }}>
						Allocation requested,
						<Text bold component={'span'} style={{ fontSize: 16, color: theme.colors.primary, marginLeft: 2 }}>
							processing orders..
						</Text>
					</Text>
					<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 2 }}>
						Processing will continue in background
					</Text>
				</div>
			)}

			{bottom_bar_state === BOTTOM_STATE.success && (
				<>
					<div className='align-items-center'>
						<img src={ImageLinks.tickCircleSolid} width={16} height={16} />
						<Text semi style={{ fontSize: 16, color: theme.colors.green, marginLeft: 8 }}>
							{`${selected_type === TYPES.SMART || selected_type === TYPES.AUTO_MANIFEST ? success_count : total_orders} Orders ${
								is_schedule_success ? 'Scheduled' : 'Allocated'
							} Successfully!`}
						</Text>
					</div>
					{!is_schedule_success && <Button type='round' text='Track' size='large' left_icon={ImageLinks.track} onClick={handle_track} />}
				</>
			)}

			{bottom_bar_state === BOTTOM_STATE.failed && (
				<>
					<div className='align-items-center'>
						<img src={ImageLinks.exclamation_error} width={16} height={16} />
						<Text semi style={{ fontSize: 16, color: theme.colors.red, marginLeft: 8 }}>
							{_.isEmpty(failed_msg) ? `Unable to allocate ${total_orders} orders` : failed_msg}
						</Text>
					</div>
					<Button type='round' text='Try Again' size='large' onClick={handle_try_again} />
				</>
			)}
		</>
	);
};

export default AllocationFooter;
