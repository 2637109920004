import ImageLinks from 'assets/images/ImageLinks';
import Animations from 'assets/lottie/Animations';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React from 'react';
import Lottie from 'react-lottie';
import theme from 'resources/theme';
import { BOTTOM_STATE } from '../helper';

const InProgress = ({ show_cancel, timeout, set_bottom_bar_state }) => {
	return (
		<div>
			<div
				style={{
					boxShadow: '0px -4px 14px rgba(0, 0, 0, 0.05)',
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
					background: theme.colors.white,
					padding: '20px 35px',
				}}>
				<img src={ImageLinks.scope} alt='' />
				<Text semi style={{ fontSize: 16, marginLeft: 20, width: 300 }}>
					Confirming Allocation
				</Text>
				<div style={{ flex: 1 }}>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							animationData: Animations.progress_loader,
							rendererSettings: {
								preserveAspectRatio: 'xMidYMid slice',
							},
						}}
						height={30}
						width={220}
					/>
				</div>
				{show_cancel && (
					<ContainedButton
						onClick={() => {
							set_bottom_bar_state(BOTTOM_STATE.start);
							clearTimeout(timeout);
						}}
						style={{ background: theme.colors.lightRed, borderRadius: 100, width: 140 }}>
						<img src={ImageLinks.crossRed} width='10' alt='' />
						<Text semi style={{ color: theme.colors.red, marginLeft: 10, fontSize: 12, marginTop: 2 }}>
							Cancel
						</Text>
					</ContainedButton>
				)}
			</div>
		</div>
	);
};

export default InProgress;
