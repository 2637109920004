import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import _ from 'lodash';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import FilterButton from 'modules/FilterButton';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import Text from 'modules/Text';
import React, { useEffect } from 'react';
import { useState } from 'react';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import Filters from 'modules/Filters/Filters';
import Routes from 'resources/Routes';
import utils from 'resources/utils';
import constant from 'resources/constant';
import TraceAnalytics from '../components/TraceAnalytics/TraceAnalytics';
import Button from 'modules/Button';
import Helpcenter from 'modules/HelpCenter';
import { hide_loader, show_loader } from 'actions/app';
import EmptyState from 'modules/EmptyState';
import NoResult from 'modules/NoResult';

const report_filters = [
	{
		multi: true,
		param: 'date',
		title: 'Trace Duration',
		type: 'date',
	},
];

const useStyles = makeStyles({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	empty_content: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	content: {
		display: 'flex',
		flex: 1,
		marginTop: 12,
		maxHeight: '80vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	tab_wrap: {
		paddingBottom: 8,
		borderBottom: `1px solid ${theme.colors.lightPurple}`,
		marginTop: 34,
	},
	subject_item_wrap: {
		minWidth: 93,
		height: 33,
		paddingLeft: 16,
		paddingRight: 16,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		cursor: 'pointer',
		backgroundColor: theme.colors.primary,
	},
	trace_item: {
		height: 46,
		borderRadius: 6,
		border: `1px solid ${theme.colors.lightGrey5}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 16,
		paddingLeft: 12,
		paddingRight: 12,
		cursor: 'pointer',
	},
	trace_item_active: {
		height: 46,
		borderRadius: 6,
		border: `1px solid ${theme.colors.primary}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 16,
		paddingLeft: 12,
		paddingRight: 12,
		cursor: 'pointer',
	},
	trace_text: { fontSize: 12, color: theme.colors.primary, textDecoration: 'underline', cursor: 'pointer', marginLeft: 4 },
	alert_tag_wrap: { backgroundColor: theme.colors.lightRed, display: 'flex', alignItems: 'center', padding: '2px 8px', borderRadius: 16, marginRight: 10 },
	wrapper_empty_state_no_result: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 30 },
});

const TraceListing = ({}) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const filter_object = qs.parse(history.location.search.slice(1)) || {};
	const [show_filters, set_show_filters] = useState(false);
	const [all_filters, set_all_filters] = React.useState([]);
	const [trace, set_trace] = React.useState({});
	const [trace_list, set_trace_list] = React.useState([]);
	const [report_items, set_report_items] = React.useState([]);
	const [selected_trace, set_selected_trace] = useState({});
	const slider_ref = React.useRef();
	const loading_scroll = React.useRef(false);

	const classes = useStyles();

	const { is_loading } = useSelector((state) => state.app, shallowEqual);

	useEffect(() => {
		set_all_filters([]);
		fetch_filter_items();
		api_requests.trace_list_filter().then((res) => set_all_filters(res.data.filters));
	}, []);

	useEffect(() => {
		get_trace_list({});
	}, [JSON.stringify(filter_object)]);

	const fetch_filter_items = async () => {
		try {
			const response = await api_requests.get_trace_export_items();
			if (response.data.items) {
				set_report_items(response.data.items);
			}
		} catch (error) {}
	};

	const handle_export = async (selected_filters, callback) => {
		try {
			const response = await api_requests.export_trace(selected_filters);
			utils.downloadBlob(response, 'trace.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	const get_trace_list = ({ token, limit = 50 }) => {
		dispatch(show_loader());
		set_trace({});
		api_requests
			.trace_list_entities({ ...filter_object, ...(token && { token }), limit: limit })
			.then((res) => {
				set_trace(res.data);
				dispatch(hide_loader());
				loading_scroll.current = false;
				if (token) {
					set_trace_list(_.concat(trace_list, res.data.data));
				} else {
					set_trace_list(res.data.data);
				}
			})
			.catch(() => {
				dispatch(hide_loader());
			});
	};

	const handle_trace_nav = (item) => {
		const endpoint = constant.TRACE_FE_URL;
		const owner_id = utils._retrieveData(constant.OWNER_ID);
		const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER).id;
		const token = utils._retrieveData(constant.AUTH_TOKEN);
		const obj = {
			entity_id: item.entity_id,
			entity_type: item.entity_type,
			owner_id,
			owner_type,
			token,
		};
		const url = `${endpoint}?${qs.stringify(obj)}`;
		window.open(url, '_blank');
	};

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && trace.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_trace_list({ token: trace.next });
		}
	};

	return (
		<div className={`container ${classes.wrapper}`}>
			<Text semi style={{ fontSize: 20 }}>
				Trace Management
			</Text>

			{(trace_list.length != 0 || Object.keys(filter_object).length !== 0) && (
				<div className={clsx('d-flex align-items-center justify-content-between', classes.tab_wrap)}>
					<div className={classes.subject_item_wrap} onClick={() => set_selected_trace({})}>
						<Text bold style={{ fontSize: 14, color: theme.colors.white }}>
							Total ({Object.keys(trace_list).length})
						</Text>
					</div>
					<FilterButton handle_click={() => set_show_filters(true)} total_filters={Object.keys(filter_object).length} />
				</div>
			)}

			{trace_list.length == 0 && !is_loading && Object.keys(filter_object).length == 0 && (
				<div className={classes.empty_content}>
					<EmptyState empty_img={ImageLinks.empty_trace} />
					<Helpcenter text={'Learn about Trace'} url='https://pidge.in/helpcenter-enterprise-trace/' style={{ marginTop: 40 }} />
				</div>
			)}

			{trace_list.length == 0 && !is_loading && Object.keys(filter_object).length != 0 && (
				<div className={classes.wrapper_empty_state_no_result}>
					<NoResult text='No result found' btn_onclick={() => history.replace({ search: '' })} />
				</div>
			)}

			{trace_list.length != 0 && (
				<div className='d-flex justify-content-between' style={{ gap: 10 }}>
					<div className={classes.content} ref={slider_ref} onScroll={slider_scrolled}>
						<div style={{ flex: 1 }}>
							{_.map(trace_list, (trace_item, trace_index) => (
								<div
									key={`trace-item_${trace_index}`}
									className={selected_trace.id == trace_item.id ? classes.trace_item_active : classes.trace_item}
									onClick={() => {
										set_selected_trace(trace_item);
									}}>
									<div className='d-flex align-items-center'>
										<img src={trace_item.entity_type === 'fleet' ? ImageLinks.truck_purple : ImageLinks.rider_purple} />
										<Text bold style={{ fontSize: 14, marginLeft: 4 }}>
											{trace_item.entity_name}
										</Text>
									</div>
									<div className='d-flex align-items-center'>
										{trace_item.today_alerts > 0 && (
											<div className={classes.alert_tag_wrap}>
												<img src={ImageLinks.exclamation_red_triangle} height={12} width={12} />
												<Text medium style={{ color: theme.colors.red, fontSize: 12 }}>
													{trace_item.today_alerts}
												</Text>
											</div>
										)}
										<Button
											type='text'
											size='extra_small'
											text='Trace'
											left_icon={ImageLinks.track_filled_purple}
											onClick={() => handle_trace_nav(trace_item)}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className='d-flex justify-content-center' style={{ flex: 1 }}>
						<TraceAnalytics get_trace_list={get_trace_list} set_selected_trace={set_selected_trace} selected_trace={selected_trace} />
					</div>
				</div>
			)}
			{show_filters && (
				<Filters
					existing_filters={filter_object}
					all_filters={all_filters}
					set_selected_filters={(filters) => {
						history.push(Routes.TRACE_LISTING.path + `?` + qs.stringify(filters));
					}}
					title='Trace'
					close={() => set_show_filters(false)}
					handle_export={handle_export}
					report_items={report_items}
					is_report_enabled={true}
					report_filters={report_filters}
					is_report_item_single_select={true}
					filter_from={'trace'}
					skip_date_check={true}
					date_check_array={['daily', 'hourly', '5mins']}
				/>
			)}
		</div>
	);
};

export default TraceListing;
