import React, { useMemo } from 'react';
import theme from 'resources/theme';
import useStyles from './styles';
import useCdrAllocationModal from './useCdrAllocationModal';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import Switch from 'modules/Switch';
import { AVAILABLE_RIDER_TYPE, BOTTOM_STATE, TYPES, tnc_data } from './helper';
import _ from 'lodash';
import { Divider } from '@material-ui/core';
import NoCaptiveRiders from './components/NoCaptiveRiders';
import RiderType from './components/RiderType';
import NoThreePl from './components/NoThreePl';
import NoNetwork from './components/NoNetwork';
import Amount from 'modules/Amount';
import RechargeWallet from 'views/MyWallet/components/RechargeWallet';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import CustomAccordion from 'modules/CustomAccordion';
import clsx from 'clsx';
import AllocationFooter from './components/AllocationFooter';
import { ICON_STYLE } from 'views/NetworksFlow/CreateNetwork/helper';
import Schedule from './components/Schedule';
import SmartConfigList from './components/SmartConfigList';
import { shallowEqual, useSelector } from 'react-redux';
import NetworkAvatar from 'modules/NetworkAvatar';

const CdrAllocationModal = ({ cdr_ids, cdr_datas, close, on_success, parent_api_headers }) => {
	const {
		selected_type,
		set_selected_type,
		has_captive_riders,
		biz_available_rider_type,
		set_biz_available_rider_type,
		network_partners_list,
		network_available_rider_type,
		set_network_available_rider_type,
		selected_network,
		set_selected_network,
		show_network_all,
		set_show_network_all,
		three_pl_partners_list,
		selected_three_pl,
		set_selected_three_pl,
		show_three_pl_all,
		set_show_three_pl_all,
		show_add_funds,
		set_show_add_funds,
		biz_details,
		set_refresh_details,
		bottom_bar_state,
		set_bottom_bar_state,
		biz_selected_rider,
		set_biz_selected_rider,
		network_selected_rider,
		set_network_selected_rider,
		handle_allocate_rider,
		handle_track,
		handle_all_riders_nav,
		success_count,
		failed_count,
		handle_network_discover,
		handle_schedule_get_slots,
		schedule_slots,
		show_schedule,
		selected_date,
		set_selected_date,
		selected_time,
		set_selected_time,
		handle_schedule_close,
		failed_msg,
		handle_try_again,
		smart_allocate_configs,
		auto_manifest_configs,
		show_smart_config,
		set_show_smart_config,
		show_auto_config,
		set_show_auto_config,
		selected_smart_config,
		selected_auto_config,
		set_selected_smart_config,
		set_selected_auto_config,
		is_schedule_success,
	} = useCdrAllocationModal(cdr_ids, cdr_datas, close, on_success, parent_api_headers);

	const { app } = useSelector((state) => state, shallowEqual);
	const classes = useStyles();

	const LOW_BALANE_LIMIT = -1 * _.get(biz_details, 'business_details.credit_limit', 0);
	const CURRENT_BALANCE = biz_details.wallet_balance;

	const btn_disabled = useMemo(() => {
		let value = true;
		if (selected_type === TYPES.SMART || selected_type === TYPES.AUTO_MANIFEST) value = false;
		if (selected_type === TYPES.BUSINESS) {
			if (_.isEmpty(biz_available_rider_type)) value = _.isEmpty(biz_selected_rider);
			if (
				biz_available_rider_type === AVAILABLE_RIDER_TYPE.LOWEST_COST ||
				biz_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE ||
				biz_available_rider_type === AVAILABLE_RIDER_TYPE.BORADCAST ||
				biz_available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER
			)
				value = false;
		}

		if (selected_type === TYPES.NETWORK) {
			const item_price = _.get(selected_network, 'quote.price', 0);
			const remaing_balnce_if_purchased = CURRENT_BALANCE - item_price;
			const is_network_bal_low = remaing_balnce_if_purchased < LOW_BALANE_LIMIT;

			if (_.isEmpty(network_available_rider_type)) value = _.isEmpty(network_selected_rider) || is_network_bal_low;
			if (
				network_available_rider_type === AVAILABLE_RIDER_TYPE.LOWEST_COST ||
				network_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE ||
				network_available_rider_type === AVAILABLE_RIDER_TYPE.BORADCAST ||
				network_available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER
			)
				value = is_network_bal_low;
		}
		if (selected_type === TYPES.THREE_PL) {
			const item_price = _.get(selected_three_pl, 'quote.price', 0);
			const remaing_balnce_if_purchased = CURRENT_BALANCE - item_price;
			let is_pickup_now = true;
			if (selected_three_pl?.service === 'pidge' && !selected_three_pl?.pickup_now) is_pickup_now = false;
			value = remaing_balnce_if_purchased < LOW_BALANE_LIMIT || !is_pickup_now;
		}
		return value;
	}, [
		selected_type,
		biz_available_rider_type,
		biz_selected_rider,
		network_available_rider_type,
		network_selected_rider,
		biz_details,
		selected_network,
		selected_three_pl,
	]);

	const is_low_balance = useMemo(() => {
		let value = false;
		if (selected_type === TYPES.THREE_PL || selected_type === TYPES.NETWORK) {
			const item = selected_type === TYPES.THREE_PL ? selected_three_pl : selected_network;
			const item_price = _.get(item, 'quote.price', 0);
			const remaing_balnce_if_purchased = CURRENT_BALANCE - item_price;
			value = remaing_balnce_if_purchased < LOW_BALANE_LIMIT;
		} else if (selected_type === TYPES.BUSINESS) value = !has_captive_riders && CURRENT_BALANCE < LOW_BALANE_LIMIT;
		else value = CURRENT_BALANCE < LOW_BALANE_LIMIT;

		return value;
	}, [selected_type, selected_three_pl, selected_network, has_captive_riders, CURRENT_BALANCE, LOW_BALANE_LIMIT]);

	const tnc_array = !_.isEmpty(selected_three_pl?.tnc) ? selected_three_pl?.tnc : !_.isEmpty(selected_network?.tnc) ? selected_network?.tnc : [];
	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div className={classes.content}>
					<div className={clsx(classes.map_wrap)}>
						<div id='map_canvas_address_3' className='map-canvas-wrapper card' style={{ height: '100%', borderRadius: 20 }} />
					</div>

					<div className={classes.right_wrap}>
						<div className={classes.header}>
							<div className={'align-items-center'}>
								<Text bold style={{ fontSize: 18, color: theme.colors.primary }}>
									Allocate {cdr_ids.length} order{cdr_ids.length > 1 ? 's' : ''}
								</Text>
							</div>
							<div className='align-items-center'>
								<BootstrapTooltip title={`Negative Limit Allowed: ₹${LOW_BALANE_LIMIT}`}>
									<div
										className={classes.funds_wrapper}
										style={{
											backgroundColor:
												CURRENT_BALANCE > 0
													? theme.colors.lightPurple
													: CURRENT_BALANCE > LOW_BALANE_LIMIT
													? theme.colors.lightYellow
													: theme.colors.lightRed,
										}}>
										<img
											className={clsx(CURRENT_BALANCE <= 0 && 'funds-wallet')}
											src={
												CURRENT_BALANCE > 0
													? ImageLinks.wallet2_purple
													: CURRENT_BALANCE > LOW_BALANE_LIMIT
													? ImageLinks.wallet2_orange
													: ImageLinks.wallet2_red
											}
											style={{ marginRight: 5 }}
											alt='wallet'
										/>
										{CURRENT_BALANCE <= 0 && (
											<img
												onClick={() => set_show_add_funds(true)}
												className='add-purple cursor-pointer'
												src={ImageLinks.addPurple}
												style={{ marginRight: 5, display: 'none' }}
												alt='add_purple'
											/>
										)}
										<Amount
											amount={CURRENT_BALANCE}
											main_text_styles={{
												color:
													CURRENT_BALANCE > 0
														? theme.colors.primary
														: CURRENT_BALANCE > LOW_BALANE_LIMIT
														? theme.colors.lightOrange2
														: theme.colors.red,
												fontSize: 16,
											}}
											sub_text_styles={{
												color:
													CURRENT_BALANCE > 0
														? theme.colors.primary
														: CURRENT_BALANCE > LOW_BALANE_LIMIT
														? theme.colors.lightOrange2
														: theme.colors.red,
												fontSize: 10,
											}}
										/>
									</div>
								</BootstrapTooltip>

								<div className='cursor-pointer' onClick={close} style={{ marginTop: 3 }}>
									<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
								</div>
							</div>
						</div>

						<div
							className={clsx(classes.scroll_content)}
							style={{
								opacity: bottom_bar_state === BOTTOM_STATE.in_progress ? 0.3 : 1,
								pointerEvents: bottom_bar_state === BOTTOM_STATE.in_progress ? 'none' : 'auto',
							}}>
							<div className='align-items-center' style={{ gap: 10 }}>
								<div
									onClick={() => set_selected_type(TYPES.SMART)}
									className={clsx(
										classes.allocate_card,
										'd-flex justify-content-between',
										CURRENT_BALANCE < LOW_BALANE_LIMIT && classes.disabled_card,
									)}
									style={{
										border: `1px solid ${selected_type === TYPES.SMART ? theme.colors.primary : theme.colors.lightGrey7}`,
										flex: selected_type !== TYPES.AUTO_MANIFEST ? 0.9 : 0.1,
										position: 'relative',
									}}>
									{selected_type === TYPES.AUTO_MANIFEST ? (
										<img src={ImageLinks.smart_allocation} width={40} height={40} style={{ borderRadius: 20 }} />
									) : (
										<>
											<div className='align-items-center'>
												<img src={ImageLinks.smart_allocation} width={40} height={40} style={{ borderRadius: 20 }} />
												<div style={{ marginLeft: 8 }}>
													<Text bold style={{ fontSize: 14 }}>
														Smart Allocation
													</Text>
													<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
														Pidge AI: Efficient Partner Selection
													</Text>
												</div>
											</div>

											<SmartConfigList
												configs={smart_allocate_configs}
												show_config={show_smart_config}
												set_show_usecase={set_show_smart_config}
												selected_config={selected_smart_config}
												onClick={() => {
													set_show_smart_config((prev) => !prev);
												}}
												on_config_select={(item) => {
													set_selected_smart_config(item);
													set_show_smart_config(false);
												}}
											/>
										</>
									)}
								</div>

								<div
									onClick={() => set_selected_type(TYPES.AUTO_MANIFEST)}
									className={clsx(
										classes.allocate_card,
										'd-flex justify-content-between',
										CURRENT_BALANCE < LOW_BALANE_LIMIT && classes.disabled_card,
									)}
									style={{
										border: `1px solid ${selected_type === TYPES.AUTO_MANIFEST ? theme.colors.primary : theme.colors.lightGrey7}`,
										flex: selected_type === TYPES.AUTO_MANIFEST ? 0.9 : 0.1,
										position: 'relative',
									}}>
									{selected_type !== TYPES.AUTO_MANIFEST ? (
										<img src={ImageLinks.auto_manifest} width={40} height={40} style={{ borderRadius: 20 }} />
									) : (
										<>
											<div className='align-items-center'>
												<img src={ImageLinks.auto_manifest} width={40} height={40} style={{ borderRadius: 20 }} />
												<div style={{ marginLeft: 8 }}>
													<Text bold style={{ fontSize: 14 }}>
														Auto Manifest
													</Text>

													<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
														Pidge AI: Efficient Partner Selection
													</Text>
												</div>
											</div>
											<SmartConfigList
												configs={auto_manifest_configs}
												show_config={show_auto_config}
												set_show_usecase={set_show_auto_config}
												selected_config={selected_auto_config}
												onClick={() => {
													set_show_auto_config((prev) => !prev);
												}}
												on_config_select={(item) => {
													set_selected_auto_config(item);
													set_show_auto_config(false);
												}}
												is_manifest={true}
											/>
										</>
									)}
								</div>
							</div>

							{!has_captive_riders && <NoCaptiveRiders handle_all_riders_nav={handle_all_riders_nav} />}

							{has_captive_riders && (
								<div
									onClick={() => set_selected_type(TYPES.BUSINESS)}
									className={classes.allocate_card}
									style={{
										marginTop: 20,
										minHeight: selected_type === TYPES.BUSINESS ? 208 : 64,
										border: `1px solid ${selected_type === TYPES.BUSINESS ? theme.colors.primary : theme.colors.lightGrey7}`,
									}}>
									<div className='align-items-center'>
										<div className={classes.icon_wrap} style={{ border: `0.43px solid ${theme.colors.lightPurple6}` }}>
											<img src={ImageLinks.rider_light_purple3} width={20} height={20} />
										</div>

										<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
											Business Riders
										</Text>
									</div>
									{selected_type === TYPES.BUSINESS && (
										<RiderType
											network_id={-2}
											available_rider_type={biz_available_rider_type}
											set_available_rider_type={set_biz_available_rider_type}
											selected_rider={biz_selected_rider}
											set_selected_rider={set_biz_selected_rider}
											parent_api_headers={parent_api_headers}
										/>
									)}
								</div>
							)}

							<div className='align-items-center justify-content-between' style={{ marginTop: 20, marginBottom: 8 }}>
								<Text semi style={{ fontSize: 12 }}>
									My Network Partners {`${network_partners_list.length > 0 ? `(${network_partners_list.length})` : ''}`}
								</Text>
								{network_partners_list.length > 3 && (
									<Text onClick={() => set_show_network_all((prev) => !prev)} semi className={classes.show_hide_text}>
										{show_network_all ? 'Hide All' : 'Show All'}
									</Text>
								)}
							</div>

							{network_partners_list.length === 0 && <NoNetwork has_partners={false} handle_network_nav={handle_network_discover} />}
							<div className={clsx(CURRENT_BALANCE < LOW_BALANE_LIMIT && classes.disabled_card)}>
								{network_partners_list.length > 0 &&
									_.map(show_network_all ? network_partners_list : _.slice(network_partners_list, 0, 3), (network_item, network_index) => {
										return (
											<div
												key={`network_item_${network_index}`}
												onClick={() => {
													set_selected_type(TYPES.NETWORK);
													set_network_available_rider_type(AVAILABLE_RIDER_TYPE.LOWEST_COST);
													set_selected_network(network_item);
												}}
												className={classes.allocate_card}
												style={{
													marginTop: 12,
													minHeight:
														selected_type === TYPES.NETWORK &&
														selected_network?.network_id === network_item.network_id &&
														selected_network?.can_choose_algo
															? 208
															: 64,
													border: `1px solid ${
														selected_type === TYPES.NETWORK && selected_network?.network_id === network_item.network_id
															? theme.colors.primary
															: theme.colors.lightGrey7
													}`,
												}}>
												<div className='align-items-center justify-content-between'>
													<div className='align-items-center'>
														<NetworkAvatar
															configs={_.get(network_item, 'avatar.configs')}
															height={40}
															width={40}
															image={_.get(app, `app_configs.network_configs.${network_item.network_id}.avatar.image`, '')}
														/>
														<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
															{`${network_item.network_name} (${network_item.network_id})`}
														</Text>
													</div>
													<div>
														<Text semi style={{ fontSize: 14 }}>
															₹{_.get(network_item, 'quote.price', 0).toFixed(2)}
														</Text>
														{_.get(network_item, 'quote.eta.pickup_min', 0) > 0 && (
															<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
																~{_.get(network_item, 'quote.eta.pickup_min', 0)} min
															</Text>
														)}
													</div>
												</div>
												{selected_type === TYPES.NETWORK &&
													selected_network?.network_id === network_item.network_id &&
													selected_network?.can_choose_algo && (
														<RiderType
															network_id={selected_network?.network_id}
															available_rider_type={network_available_rider_type}
															set_available_rider_type={set_network_available_rider_type}
															selected_rider={network_selected_rider}
															set_selected_rider={set_network_selected_rider}
															parent_api_headers={parent_api_headers}
														/>
													)}
											</div>
										);
									})}
							</div>

							<div className='align-items-center justify-content-between' style={{ marginTop: 20, marginBottom: 8 }}>
								<Text semi style={{ fontSize: 12 }}>
									3PL Partners ({three_pl_partners_list.length})
								</Text>
								{three_pl_partners_list.length > 3 && (
									<Text onClick={() => set_show_three_pl_all((prev) => !prev)} semi className={classes.show_hide_text}>
										{show_three_pl_all ? 'Hide All' : 'Show All'}
									</Text>
								)}
							</div>

							{three_pl_partners_list.length === 0 && <NoThreePl />}

							<div className={clsx(CURRENT_BALANCE < LOW_BALANE_LIMIT && classes.disabled_card)}>
								{three_pl_partners_list.length > 0 &&
									_.map(
										show_three_pl_all ? three_pl_partners_list : _.slice(three_pl_partners_list, 0, 3),
										(three_pl_item, three_pl_index) => {
											let image = _.get(app, `app_configs.network_configs.${three_pl_item.network_id}.avatar.image`, '');
											return (
												<div
													key={`network_item_${three_pl_index}`}
													onClick={() => {
														set_selected_type(TYPES.THREE_PL);
														set_selected_three_pl(three_pl_item);
													}}
													className={clsx(classes.allocate_card, 'align-items-center justify-content-between')}
													style={{
														marginTop: 12,
														border: `1px solid ${
															selected_type === TYPES.THREE_PL && selected_three_pl?.network_id === three_pl_item.network_id
																? theme.colors.primary
																: theme.colors.lightGrey7
														}`,
													}}>
													<div style={{ flex: 1, display: 'flex' }}>
														{_.isEmpty(image) ? (
															<Text semi style={{ fontSize: 16, marginLeft: 10 }}>
																{`${three_pl_item.network_name} (${three_pl_item.network_id})`}
															</Text>
														) : (
															<>
																<img src={image} alt='' width={50} style={{ marginLeft: 10 }} />
																<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
																	({three_pl_item.network_id})
																</Text>
															</>
														)}
													</div>
													<div>
														<Text semi style={{ fontSize: 14 }}>
															₹{_.get(three_pl_item, 'quote.price', 0).toFixed(2)}
														</Text>
														{_.get(three_pl_item, 'quote.eta.pickup_min', 0) > 0 && (
															<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2, textAlign: 'right' }}>
																~{_.get(three_pl_item, 'quote.eta.pickup_min', 0)} min
															</Text>
														)}
													</div>
												</div>
											);
										},
									)}
							</div>
						</div>

						{(selected_type === TYPES.SMART || selected_type === TYPES.AUTO_MANIFEST) &&
							success_count > 0 &&
							failed_count > 0 &&
							success_count + failed_count >= cdr_ids.length && (
								<div className={classes.error_footer_wrap}>
									<img src={ImageLinks.exclamation_outlined} width={16} height={16} style={{ marginRight: 8 }} />
									<Text semi style={{ fontSize: 12, color: theme.colors.red }}>
										Unable to allocate {failed_count} orders.
									</Text>
								</div>
							)}

						{show_schedule && schedule_slots.length > 0 && (
							<div className={classes.schedule_wrap}>
								<Schedule
									schedule_slots={schedule_slots}
									set_bottom_bar_state={set_bottom_bar_state}
									handle_schedule_close={handle_schedule_close}
									selected_date={selected_date}
									selected_time={selected_time}
									set_selected_date={set_selected_date}
									set_selected_time={set_selected_time}
								/>
							</div>
						)}
						<div className={classes.footer}>
							<div className={classes.footer_content}>
								<AllocationFooter
									payment_details={
										selected_type === TYPES.NETWORK
											? _.get(selected_network, 'quote', {})
											: selected_type === TYPES.THREE_PL
											? _.get(selected_three_pl, 'quote', {})
											: {}
									}
									success_count={success_count}
									failed_count={failed_count}
									is_low_balance={is_low_balance}
									total_orders={cdr_ids.length}
									selected_type={selected_type}
									selected_three_pl={selected_three_pl}
									bottom_bar_state={bottom_bar_state}
									btn_disabled={btn_disabled}
									handle_allocate_rider={handle_allocate_rider}
									handle_track={handle_track}
									set_show_add_funds={set_show_add_funds}
									show_schedule={show_schedule}
									handle_schedule_get_slots={handle_schedule_get_slots}
									selected_date={selected_date}
									selected_time={selected_time}
									schedule_slots={schedule_slots}
									has_captive_riders={has_captive_riders}
									failed_msg={failed_msg}
									selected_network={selected_network}
									handle_try_again={handle_try_again}
									is_schedule_success={is_schedule_success}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* {(!_.isEmpty(selected_network) || !_.isEmpty(selected_three_pl)) && ( */}
			{tnc_array.length > 0 && (
				<div className={classes.tnc_wrap}>
					<CustomAccordion
						header_icon_style={{ transform: 'rotate(180deg)' }}
						header={
							<Text semi style={{ fontSize: 14 }}>
								Partner Info
							</Text>
						}
						content={
							<div>
								<Divider style={{ marginTop: 8 }} />
								{_.map(tnc_array, (tnc_item, index) => {
									return (
										<div className={classes.tnc_item_wrap} key={`tnc_item_${index}`}>
											<div className='d-flex' style={{ marginRight: 4 }}>
												<Text medium style={{ fontSize: 12 }}>
													{`${index + 1}`}
												</Text>
												<Text medium style={{ fontSize: 12 }}>
													{`.`}
												</Text>
											</div>
											<div>
												<Text medium style={{ fontSize: 12 }}>
													{tnc_item.heading}
												</Text>
												<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
													{tnc_item.description}
												</Text>
											</div>
										</div>
									);
								})}
							</div>
						}
						classes_override={{
							details_root: classes.ndr_accordion_details_root,
							summary_root: classes.ndr_accordion_summary_root,
						}}
					/>
				</div>
			)}

			{show_add_funds && (
				<RechargeWallet
					wrapper_styles={{ right: 0, zIndex: 1200, width: 550 }}
					refetch_details={() => set_refresh_details((v) => !v)}
					business_details={biz_details.business_details}
					close={() => set_show_add_funds(false)}
				/>
			)}
		</>
	);
};

export default CdrAllocationModal;
