import { makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Text from 'modules/Text';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api_requests from 'resources/api_requests';
import CopyDetailsModal from '../CopyDetailsModal';
import Steps from '../Steps';
import TokenForm from '../TokenForm';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between', overflowY: 'auto', height: '100%' },
	header_title: { fontSize: 22 },
	button_flex: { display: 'flex', justifyContent: 'flex-end', position: 'fixed', bottom: 20, right: 20, zIndex: 2 },
}));

function GenerateToken() {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const [token_data, set_token_data] = useState({});
	const [show_copy_modal, set_show_copy_modal] = useState(false);
	const [show_confirm_saved_details, set_show_confirm_saved_details] = useState(false);
	const [username, set_username] = useState('');
	const [password, set_password] = useState('');

	const generate_token = async () => {
		try {
			const res = await api_requests.generate_token(token_data);
			if (res) {
				set_username(_.get(res, 'data.vendor.username', ''));
				set_password(_.get(res, 'data.vendor.password', ''));
				set_show_copy_modal(true);
				// history.push('/integration-management');
			}
		} catch (err) {}
	};

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className='align-items-center'>
				<div className='page_back_btn' onClick={() => history.goBack()}>
					<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
				</div>
				<Text className='cursor-pointer' style={{ fontSize: 22 }} onClick={() => history.goBack()}>
					Integration Management
				</Text>
				<div className='header_separator'></div>
				<Text semi className='header_active_title'>
					Token Generation
				</Text>
			</div>
			<div className={classes.header} style={{ gap: 250 }}>
				<TokenForm set_values={set_token_data} />
				<div style={{ flex: 5 }}>
					<Steps />
				</div>
			</div>
			<div className={classes.button_flex}>
				<Button type='round' size='large' text='Generate Token' onClick={generate_token} />
			</div>
			{show_confirm_saved_details && (
				<ConfirmClose
					on_confirm={() => {
						set_show_confirm_saved_details(false);
						history.push('/integration-management');
					}}
					headerTitle='Are you sure you have saved credential details?'
					close={() => {
						set_show_copy_modal(true);
						set_show_confirm_saved_details(false);
					}}
					close_on_confirm={false}
					title='Pidge does not save passwords. Please make sure you have saved token password. '
				/>
			)}
			{show_copy_modal && (
				<CopyDetailsModal
					username={username}
					password={password}
					on_confirm={() => {
						set_show_copy_modal(false);
						set_show_confirm_saved_details(true);
					}}
				/>
			)}
		</div>
	);
}

export default GenerateToken;
