import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import { ALLOCATION_CRITERIAL_OPTIONS, ALLOCATION_TABS, ALLOW_CRITERIA_OPTION, PKG_VOLUME_OPTIONS } from '../../helper';
import ContainedButton from 'modules/ContainedButton';

import CreateNetworkContext from '../../../context';
import Select from 'modules/Select';
import Switch from 'modules/Switch';
import OutlinedTextField from 'modules/OutlinedTextField';
import ImageLinks from 'assets/images/ImageLinks';
import { integer_or_decimal_with_two_places_regex } from 'resources/regex_patterns';
import SectionCta from '../SectionCta';
import ConfirmClose from 'modules/modals/ConfirmClose';
import RadioBox from 'modules/RadioBox';

const useStyles = makeStyles(() => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column' },
	content_wrap: { marginLeft: 16, gap: 20, display: 'flex', flex: 1 },
	tab_item: {
		borderRadius: 8,
		minWidth: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		height: 33,
	},
	input_wrap: { display: 'flex', flexDirection: 'column', flex: 0.45, overflowX: 'hidden' },
	output_wrap: { display: 'flex', flexDirection: 'column', flex: 0.55, marginTop: -60 },
	input_section: {
		marginTop: 8,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey7}`,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		borderRadius: 14,
	},
	separator: { border: `1px solid ${theme.colors.lightGrey5}`, marginTop: 4, marginBottom: 16 },
	menu_item_class: {
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		width: 190,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
		},
	},
	label_subtitle: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 },
	contact_text: { fontSize: 14, color: theme.colors.lightPink5, textAlign: 'right' },
	adornment_text: { fontSize: 14, color: theme.colors.darkGrey2, whiteSpace: 'nowrap' },
	notes_wrapper: {
		backgroundColor: theme.colors.lightPink4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 28,
		borderBottomLeftRadius: 12,
		borderBottomRightRadius: 12,
	},
}));

const NetworkAllocation = ({ handle_data_updates }) => {
	const {
		selected_allocation_tab,
		set_selected_allocation_tab,
		allocation_data,
		set_allocation_data,
		set_confirm_close_modal,
		invite_network_id,
		invite_network_config,
		edit_network_id,
		can_choose_algo,
		set_can_choose_algo,
		set_is_invite_allocation_updated,
		handle_allocation_api,
	} = useContext(CreateNetworkContext);
	const [is_immediate, set_is_immediate] = useState(false);
	const [is_manifest, set_is_manifest] = useState(false);
	const [allocation_criteria, set_allocation_criteria] = useState('');
	const [is_multi_drop, set_is_multi_drop] = useState(false);
	const [max_points, set_max_points] = useState('1');
	const [order_size, set_order_size] = useState(null);
	const [order_weight, set_order_weight] = useState(null);
	const [max_cod, set_max_cod] = useState(null);
	const [max_bill_amount, set_max_bill_amount] = useState(null);
	const [max_order_distance, set_max_order_distance] = useState(null);
	const [can_change_allocation, set_can_change_allocation] = useState(_.get(invite_network_config, 'ownership.allocation', '') == 'user');
	const [show_change_modal, set_show_change_modal] = useState(false);
	const [refresh, set_refresh] = useState(false);
	const [allow_criterion, set_allow_criterion] = useState(false);
	const classes = useStyles();

	const handle_update_input_two_decimal = (e, updater_function) => {
		const value = e.target.value;
		const pattern = /^\d+(\.\d{0,2})?$/;
		if (value == '' || pattern.test(value)) updater_function(value);
	};

	useEffect(() => {
		if (selected_allocation_tab === 0) {
			set_allocation_criteria(allocation_data.algo);
			set_is_immediate(allocation_data.allow_on_demand);
			set_is_manifest(allocation_data.allow_manifest);
		}
		if (selected_allocation_tab === 1) {
			set_is_multi_drop(allocation_data.max_drop_tasks > 1);
			set_max_points(allocation_data.max_drop_tasks);
		}
		if (selected_allocation_tab === 2) {
			set_order_size(allocation_data.max_volume_per_order ? allocation_data.max_volume_per_order + '' : allocation_data.max_volume_per_order);
			set_order_weight(allocation_data.max_weight_per_order ? allocation_data.max_weight_per_order + '' : allocation_data.max_weight_per_order);
			set_max_cod(allocation_data.max_cod_limit ? allocation_data.max_cod_limit + '' : allocation_data.max_cod_limit);
			set_max_order_distance(
				allocation_data.max_distance_per_order ? allocation_data.max_distance_per_order + '' : allocation_data.max_distance_per_order,
			);
			set_max_bill_amount(allocation_data.max_bill_amount ? allocation_data.max_bill_amount + '' : allocation_data.max_bill_amount);
		}
		set_refresh((prev) => !prev);
	}, [selected_allocation_tab]);

	const on_save_allocation = () => {
		const _allocation_data = _.cloneDeep(allocation_data);
		if (selected_allocation_tab === 0) {
			_allocation_data.allow_on_demand = is_immediate;
			_allocation_data.allow_manifest = is_manifest;
			_allocation_data.algo = allocation_criteria;
			if (invite_network_id) {
				set_can_choose_algo(allow_criterion);
			}
		}
		if (selected_allocation_tab === 1) {
			_allocation_data.max_drop_tasks = is_multi_drop ? max_points : '1';
		}
		if (selected_allocation_tab === 2) {
			_allocation_data.max_volume_per_order = order_size;
			_allocation_data.max_weight_per_order = order_weight;
			_allocation_data.max_cod_limit = max_cod;
			_allocation_data.max_distance_per_order = max_order_distance;
			_allocation_data.max_bill_amount = max_bill_amount;
		}
		set_allocation_data(_allocation_data);
		if (invite_network_id || edit_network_id) {
			set_is_invite_allocation_updated(true);
		}
	};

	const set_values_to_default = () => {
		set_can_change_allocation(false);
		set_allocation_criteria(invite_network_config.allocation.algo);
		set_is_immediate(invite_network_config.allocation.allow_on_demand);
		set_is_manifest(invite_network_config.allocation.allow_manifest);
		set_is_multi_drop(invite_network_config.allocation.max_drop_tasks > 1);
		set_max_points(invite_network_config.allocation.max_drop_tasks);
		set_order_size(invite_network_config.allocation.max_volume_per_order + '');
		set_order_weight(
			invite_network_config.allocation.max_weight_per_order
				? invite_network_config.allocation.max_weight_per_order / 1000
				: invite_network_config.allocation.max_weight_per_order,
		);
		set_max_cod(invite_network_config.allocation.max_cod_limit);
		set_max_order_distance(
			invite_network_config.allocation.max_distance_per_order
				? invite_network_config.allocation.max_distance_per_order / 1000
				: invite_network_config.allocation.max_distance_per_order,
		);
	};

	const handle_custom_allocation = (e) => {
		if (e) {
			set_show_change_modal(true);
			return;
		}
		if (!e) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Exit Custom Rule Setup',
				title: `Are you sure you want to abandon custom rules setup for allocation? Rules will reset to default.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				is_error_msg: false,
				show_negative_btn: true,
				confirm_acion: () => {
					set_values_to_default();
				},
			});
		}
	};

	const handle_allocate_tab_switch = (index) => {
		let is_data_empty = false;
		if (selected_allocation_tab === 0) is_data_empty = _.isEmpty(allocation_data.algo);
		if (selected_allocation_tab === 1) is_data_empty = _.isEmpty(allocation_data.max_drop_tasks);
		if (selected_allocation_tab === 2) is_data_empty = false;

		const show_alert = is_data_empty ? !btn_disabled : is_data_updated;
		if (!show_alert) {
			set_selected_allocation_tab(index);
			return;
		}
		set_confirm_close_modal({
			open: true,
			header_title: 'Discard Changes',
			title: `You have made changes but you have not saved them. Are you sure you want to discard these changes?`,
			positive_text: 'Discard',
			negative_text: 'Cancel',
			is_error_msg: true,
			confirm_acion: () => {
				set_selected_allocation_tab(index);
			},
		});
	};

	const is_data_updated = useMemo(() => {
		if (invite_network_id && allow_criterion != can_choose_algo) {
			return true;
		}
		let value = false;
		if (
			selected_allocation_tab === 0 &&
			(is_immediate != allocation_data.allow_on_demand ||
				allocation_criteria != allocation_data.algo ||
				_.isEmpty(allocation_data.algo) ||
				is_manifest != allocation_data.allow_manifest)
		)
			value = true;
		if (selected_allocation_tab === 1 && max_points != allocation_data.max_drop_tasks) value = true;
		if (
			selected_allocation_tab === 2 &&
			(order_size + '' != allocation_data.max_volume_per_order + '' ||
				order_weight + '' != allocation_data.max_weight_per_order + '' ||
				max_cod + '' != allocation_data.max_cod_limit + '' ||
				max_order_distance + '' != allocation_data.max_distance_per_order + '' ||
				max_bill_amount + '' != allocation_data.max_bill_amount + '')
		)
			value = true;

		handle_data_updates(4, value);
		return value;
	}, [
		allow_criterion,
		selected_allocation_tab,
		is_immediate,
		is_manifest,
		allocation_criteria,
		is_multi_drop,
		max_points,
		order_size,
		order_weight,
		allocation_data,
		max_cod,
		max_order_distance,
		max_bill_amount,
	]);

	const btn_disabled = useMemo(() => {
		if (is_data_updated && invite_network_id) return false;
		let value = true;
		if (selected_allocation_tab === 0) {
			value = _.isEmpty(allocation_criteria);
		}
		if (selected_allocation_tab === 1) {
			value = is_multi_drop ? _.isEmpty(max_points) : false;
		}
		if (selected_allocation_tab === 2) {
			let regex = new RegExp(integer_or_decimal_with_two_places_regex);
			if (order_size) value = !(order_size > 0);
			if (!_.isEmpty(order_weight)) value = !regex.test(order_weight);
			if (!_.isEmpty(max_cod)) value = !regex.test(max_cod);
			if (!_.isEmpty(max_order_distance)) value = !regex.test(max_order_distance);
			if (!_.isEmpty(max_bill_amount)) value = !regex.test(max_bill_amount);
		}

		return value;
	}, [selected_allocation_tab, allocation_criteria, is_multi_drop, max_points, order_size, order_weight, max_cod, is_data_updated, max_bill_amount]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.content_wrap}>
				<div className={classes.input_wrap}>
					<div className='d-flex justify-content-between'>
						<div className='d-flex'>
							{_.map(ALLOCATION_TABS, (tab, index) => (
								<div
									key={`servicibility_tab_${index}`}
									onClick={() => handle_allocate_tab_switch(index)}
									className={classes.tab_item}
									style={{
										backgroundColor: selected_allocation_tab === index ? theme.colors.primary : 'transparent',
									}}>
									<Text
										bold
										style={{ fontSize: 14, color: selected_allocation_tab === index ? theme.colors.white : theme.colors.lightPurple6 }}>
										{tab}
									</Text>
									{((index === 0 && !_.isEmpty(allocation_data.algo)) ||
										(index === 1 && !_.isEmpty(allocation_data.max_drop_tasks)) ||
										index === 2) && (
										<img
											src={selected_allocation_tab === index ? ImageLinks.tick_circle_white_solid : ImageLinks.tick_circle_light_pink}
											width={16}
											height={16}
											style={{ marginLeft: 9 }}
										/>
									)}
								</div>
							))}
						</div>
						<div>
							{invite_network_id && (
								<div className='d-flex align-items-center' style={{ justifyContent: 'flex-end' }}>
									<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Custom Rule
									</Text>
									<Switch
										disabled={_.get(invite_network_config, 'ownership.allocation', '') == 'user'}
										checked={can_change_allocation}
										onChange={(e) => {
											handle_custom_allocation(e.target.checked);
										}}
									/>
								</div>
							)}
						</div>
					</div>

					<div className={classes.input_section}>
						<div style={{ padding: 16 }}>
							<div className='d-flex justify-content-between align-items-center'>
								<Text semi style={{ fontSize: 14 }}>
									{selected_allocation_tab === 0 ? 'Allocation' : selected_allocation_tab === 1 ? 'Routing' : 'Order Dimensions'}
								</Text>
							</div>

							<div className={classes.separator}></div>

							{selected_allocation_tab === 0 && (
								<div>
									<div className='align-items-center justify-content-between'>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Immediate Allocation
											</Text>
											<Text medium className={classes.label_subtitle}>
												Assign real-time to available riders
											</Text>
										</div>
										<Switch
											checked={is_immediate}
											disabled={invite_network_id && !can_change_allocation}
											onChange={() => set_is_immediate((prev) => !prev)}
										/>
									</div>

									<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Default Allocation Criteria*
											</Text>
											<Text medium className={classes.label_subtitle}>
												Select default allocation type
											</Text>
										</div>
										<Select
											value={allocation_criteria}
											defaultOption={{
												title: 'Select',
												value: '',
											}}
											disabled={invite_network_id}
											options={ALLOCATION_CRITERIAL_OPTIONS}
											handleChange={(val) => set_allocation_criteria(val)}
											selected_title_styles={{ color: _.isEmpty(allocation_criteria) ? theme.colors.lightPurple : theme.colors.primary }}
											containerStyles={{ height: 35, width: 190, borderColor: theme.colors.lightPurple }}
											menu_item_class={{ className: classes.menu_item_class }}
										/>
									</div>
									<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Manifest Order(s)
											</Text>
											<Text medium className={classes.label_subtitle}>
												Sent to delivery partner
											</Text>
										</div>
										<Switch
											checked={is_manifest}
											disabled={invite_network_id && !can_change_allocation}
											onChange={() => set_is_manifest((prev) => !prev)}
										/>
									</div>
									{invite_network_id && (
										<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
											<div>
												<Text medium style={{ fontSize: 14 }}>
													Allow Criteria Options
												</Text>
												<Text medium className={classes.label_subtitle}>
													Participant can choose between multiple criterias when allocating
												</Text>
											</div>
											<div style={{ minWidth: '50%' }}>
												<RadioBox
													disabled={invite_network_id && !can_change_allocation}
													selected_item={allow_criterion}
													on_item_press={(value) => {
														set_allow_criterion(value);
													}}
													options={ALLOW_CRITERIA_OPTION}
												/>
											</div>
										</div>
									)}
								</div>
							)}
							{selected_allocation_tab === 1 && (
								<div>
									<div className='align-items-center justify-content-between'>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Multi Pickup Allowed
											</Text>
											<Text medium className={classes.label_subtitle}>
												User can request delivery merge across multiple pickups.
											</Text>
											<Text medium className={classes.label_subtitle} style={{ marginTop: 0 }}>
												Note: all pickups happen before delivery can start
											</Text>
										</div>
										<Text medium className={classes.contact_text}>
											Contact Pidge to Configure
										</Text>
									</div>

									<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Multi Drop Allowed
											</Text>
											<Text medium className={classes.label_subtitle}>
												User can request multiple delivery locations to be done together
											</Text>
										</div>
										<Switch
											disabled={invite_network_id && !can_change_allocation}
											checked={is_multi_drop}
											onChange={() => {
												set_max_points('1');
												set_is_multi_drop((prev) => !prev);
											}}
										/>
									</div>
									{is_multi_drop && (
										<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
											<div>
												<Text medium style={{ fontSize: 14 }}>
													Max. Points in Request*
												</Text>
												<Text medium className={classes.label_subtitle}>
													User can request these many deliveries together
												</Text>
											</div>
											<OutlinedTextField
												disabled={invite_network_id && !can_change_allocation}
												value={max_points}
												name='points'
												textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
												onChange={(e) => set_max_points(e.target.value)}
												placeholder='0'
												InputProps={{
													style: { height: 32 },
													endAdornment: (
														<Text medium className={classes.adornment_text}>
															pts
														</Text>
													),
												}}
												style={{ width: 100 }}
											/>
										</div>
									)}
								</div>
							)}
							{selected_allocation_tab === 2 && (
								<div>
									<div className='align-items-center justify-content-between'>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Max. Size per Order
											</Text>
											<Text medium className={classes.label_subtitle}>
												Package size of each order should be less than
											</Text>
										</div>
										<Select
											disabled={invite_network_id && !can_change_allocation}
											value={order_size}
											defaultOption={{
												title: 'Volume in3',
												value: null,
											}}
											options={PKG_VOLUME_OPTIONS}
											handleChange={(val) => set_order_size(val)}
											selected_title_styles={{ color: _.isEmpty(order_size) ? theme.colors.lightPurple : theme.colors.primary }}
											containerStyles={{ height: 35, width: 190, borderColor: theme.colors.lightPurple }}
											menu_item_class={{ className: classes.menu_item_class }}
										/>
									</div>

									<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Max. Weight per Order
											</Text>
											<Text medium className={classes.label_subtitle}>
												Package weight of each order should be less than
											</Text>
										</div>
										<OutlinedTextField
											value={order_weight != null ? order_weight : ''}
											disabled={invite_network_id && !can_change_allocation}
											name='weight'
											textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
											onChange={(e) => set_order_weight(e.target.value)}
											placeholder='10..'
											InputProps={{
												style: { height: 32 },
												endAdornment: (
													<Text medium className={classes.adornment_text}>
														kg
													</Text>
												),
											}}
											style={{ width: 100 }}
										/>
									</div>
									<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Max. COD per Order
											</Text>
											<Text medium className={classes.label_subtitle}>
												Cash to be collected for each order should be less than
											</Text>
										</div>
										<OutlinedTextField
											value={max_cod != null ? max_cod : ''}
											disabled={invite_network_id && !can_change_allocation}
											name='max_cod'
											textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
											onChange={(e) => handle_update_input_two_decimal(e, set_max_cod)}
											placeholder='0.00'
											InputProps={{
												style: { height: 32 },
												startAdornment: (
													<Text medium className={classes.adornment_text}>
														₹
													</Text>
												),
											}}
											style={{ width: 100 }}
										/>
									</div>
									<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Max Billing Amount
											</Text>
											<Text medium className={classes.label_subtitle}>
												Each order amount should be less than
											</Text>
										</div>
										<OutlinedTextField
											value={max_bill_amount != null ? max_bill_amount : ''}
											disabled={invite_network_id && !can_change_allocation}
											name='max_bill_amount'
											textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
											onChange={(e) => handle_update_input_two_decimal(e, set_max_bill_amount)}
											placeholder='0.00'
											InputProps={{
												style: { height: 32 },
												startAdornment: (
													<Text medium className={classes.adornment_text}>
														₹
													</Text>
												),
											}}
											style={{ width: 100 }}
										/>
									</div>
									<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
										<div>
											<Text medium style={{ fontSize: 14 }}>
												Max. Distance per Order
											</Text>
											<Text medium className={classes.label_subtitle}>
												Distance of each order should be less than
											</Text>
										</div>
										<OutlinedTextField
											value={max_order_distance != null ? max_order_distance : ''}
											disabled={invite_network_id && !can_change_allocation}
											name='max_order_distance'
											textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
											onChange={(e) => set_max_order_distance(e.target.value)}
											placeholder='10'
											InputProps={{
												style: { height: 32 },
												endAdornment: (
													<Text medium className={classes.adornment_text}>
														Km
													</Text>
												),
											}}
											style={{ width: 100 }}
										/>
									</div>
								</div>
							)}
						</div>

						{!invite_network_id && (
							<div className={classes.notes_wrapper}>
								<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Note: Settings can be customized at invite level
								</Text>
							</div>
						)}
					</div>
				</div>

				<div className={classes.output_wrap}></div>
			</div>
			<SectionCta
				title={`${edit_network_id ? 'Update' : 'Save'} ${
					selected_allocation_tab === 0 ? 'Allocation' : selected_allocation_tab === 1 ? 'Route' : 'Orders'
				}`}
				is_data_updated={is_data_updated}
				is_disabled={btn_disabled}
				handle_click={on_save_allocation}
				total_steps={3}
				lower_section_index={5}
				handle_api_save={handle_allocation_api}
				complete_btn_title={'Setup Allocation'}
				handle_data_updates={handle_data_updates}
			/>
			{show_change_modal && (
				<ConfirmClose
					headerTitle='Set Custom Allocation Rules'
					title='Are you sure you want to set custom allocation rules for this participant?All default rules will not be considered.'
					show_negative_btn={true}
					close={() => set_show_change_modal(false)}
					on_confirm={() => set_can_change_allocation(true)}
				/>
			)}
		</div>
	);
};

export default NetworkAllocation;
