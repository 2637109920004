import React, { useEffect, useState } from 'react';
import { Modal, makeStyles, useTheme } from '@material-ui/core';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import OutlinedButton from 'modules/OutlinedButton';
import ContainedButton from 'modules/ContainedButton';
import api_requests from 'resources/api_requests';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 20,
		background: 'white',
		width: 580,
		height: 370,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	header_text: { fontSize: 20 },
	action_container: {
		display: 'flex',
		justifyContent: 'center',
		gap: 15,
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		height: 172,
		width: 262,
		borderRadius: 8,
	},
	action_container_active: {
		display: 'flex',
		justifyContent: 'center',
		gap: 15,
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		height: 172,
		width: 262,
		borderRadius: 8,
		border: `1px solid ${theme.colors.primary}`,
		backgroundColor: theme.colors.lightPink4,
	},
	action_container_disabled: {
		display: 'flex',
		justifyContent: 'center',
		gap: 15,
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		height: 172,
		width: 262,
		borderRadius: 8,
		backgroundColor: theme.colors.lightGrey7,
	},
	delete_container: {
		backgroundColor: theme.colors.lightRed,
		cursor: 'pointer',
		height: 33,
		width: 33,
		borderRadius: 16,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	header_title: { fontSize: 18, width: '100%', paddingTop: 5 },
	text_container: { fontSize: 14, marginTop: 8 },
	final_stage_container: {
		backgroundColor: theme.colors.lightGrey7,
		height: 218,
		width: 540,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 15,
		textAlign: 'center',
		marginTop: 16,
	},
	final_button_style: {
		borderRadius: 30,
		width: 130,
	},
}));

const ACTION_TYPE_MENU = {
	delete: 'delete',
	allocate: 'allocate',
	unlink: 'unlink',
};
function BulkActionModal({ handle_close, set_refresh }) {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const [route_type, set_route_type] = useState(null);
	const [action_type, set_action_type] = useState(null);
	const [unlinked_route_count, set_unlinked_route_count] = useState(0);
	const [linked_route_count, set_linked_route_count] = useState(0);
	const [is_final_stage, set_is_final_stage] = useState(false);
	const [success_message, set_success_message] = useState('');

	const get_counts = async () => {
		try {
			const res = await api_requests.get_route_stats();
			if (res.success) {
				if (
					_.filter(res.data, (data) => {
						return data.route === 'unlinked';
					}).length > 0
				)
					set_unlinked_route_count(
						_.filter(res.data, (data) => {
							return data.route === 'unlinked';
						})[0].cnt,
					);
				set_linked_route_count(
					_.filter(res.data, (data) => {
						return data.route === 'linked';
					})[0].cnt,
				);
			}
		} catch (err) {}
	};

	useEffect(() => {
		get_counts();
	}, []);

	const handle_confirm_click = async () => {
		if (action_type === ACTION_TYPE_MENU.allocate) {
			try {
				const res = await api_requests.bulk_allocate_riders({ route_type: route_type });
				if (res.success) {
					set_success_message(`${route_type === 'linked' ? linked_route_count : unlinked_route_count} routes  have been processed for allocation.`);
					set_is_final_stage(true);
					set_refresh((prev) => !prev);
				}
			} catch (err) {}
		}
		if (action_type === ACTION_TYPE_MENU.unlink) {
			try {
				const res = await api_requests.bulk_unlink_rider();
				if (res.success) {
					set_success_message(`${route_type === 'linked' ? linked_route_count : unlinked_route_count} routes have been unlinked`);
					set_is_final_stage(true);
					set_refresh((prev) => !prev);
				}
			} catch (err) {}
		}
		if (action_type === ACTION_TYPE_MENU.delete) {
			try {
				const res = await api_requests.bulk_delete_routes(route_type);
				if (res.success) {
					set_success_message(`${route_type === 'linked' ? linked_route_count : unlinked_route_count} routes have been unrouted`);
					set_is_final_stage(true);
					set_refresh((prev) => !prev);
					history.push('/grouping-and-allocation');
				}
			} catch (err) {}
		}
	};

	const handle_track_order = () => {
		if (action_type === ACTION_TYPE_MENU.allocate) {
			history.push('/powered-with-pidge?type=live');
		} else if (action_type === ACTION_TYPE_MENU.unlink) {
			history.push('/grouping-and-allocation?type=unallocated');
		} else {
			history.push('/powered-with-pidge?type=ready_to_ship');
		}
		handle_close();
	};
	return (
		<Modal open={true} onClose={handle_close} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className={classes.header_wrap}>
					<Text bold className={classes.header_text}>
						Bulk Action
					</Text>
					<img src={ImageLinks.crossBlack} onClick={handle_close} style={{ cursor: 'pointer' }} alt='' />
				</div>
				{action_type === null && !is_final_stage && (
					<div>
						<Text bold style={{ fontSize: 16, textAlign: 'center' }}>
							Select Route type for bulk action
						</Text>
					</div>
				)}
				{action_type === null && !is_final_stage && (
					<div className='d-flex' style={{ gap: 16, marginTop: 20 }}>
						<div
							className={
								unlinked_route_count === 0
									? classes.action_container_disabled
									: route_type === 'unlinked'
									? classes.action_container_active
									: classes.action_container
							}
							onClick={() => {
								set_route_type(unlinked_route_count > 0 ? 'unlinked' : null);
							}}>
							<img src={unlinked_route_count === 0 ? ImageLinks.unlink_icon_disabled : ImageLinks.unlink_icon} width={24} height={24}></img>
							<Text bold style={{ color: theme.colors.darkGrey2, fontSize: 14 }}>
								Unlinked Routes
							</Text>
							<Text medium style={{ fontSize: 50, color: unlinked_route_count === 0 ? theme.colors.darkGrey2 : theme.colors.black }}>
								{unlinked_route_count}
							</Text>
						</div>
						<div
							className={
								linked_route_count === 0
									? classes.action_container_disabled
									: route_type === 'linked'
									? classes.action_container_active
									: classes.action_container
							}
							onClick={() => set_route_type(linked_route_count > 0 ? 'linked' : null)}>
							<img src={linked_route_count === 0 ? ImageLinks.rider_disabled : ImageLinks.rider_selected} width={24} height={24}></img>
							<Text bold style={{ color: theme.colors.darkGrey2, fontSize: 14 }}>
								Linked Routes
							</Text>
							<Text medium style={{ fontSize: 50, color: linked_route_count === 0 ? theme.colors.darkGrey2 : theme.colors.black }}>
								{linked_route_count}
							</Text>
						</div>
					</div>
				)}
				{action_type === null && !is_final_stage && (
					<div className='d-flex justify-content-center align-items-center' style={{ gap: 8, marginTop: 20 }}>
						{route_type !== null && (
							<div className={classes.delete_container}>
								<img src={ImageLinks.delete} height={20} width={16} onClick={() => set_action_type(ACTION_TYPE_MENU.delete)} />
							</div>
						)}
						{route_type === 'linked' && (
							<Button
								size='large'
								type='outlined'
								text='Unlink Route'
								onClick={() => set_action_type(ACTION_TYPE_MENU.unlink)}
								left_icon={ImageLinks.unlink_icon}
							/>
						)}
						{route_type !== null && (
							<Button
								size='large'
								type='round'
								text='Allocate Rider'
								onClick={() => set_action_type(ACTION_TYPE_MENU.allocate)}
								right_icon={ImageLinks.arrow_right_white_no_fill}
							/>
						)}
					</div>
				)}
				{action_type != null && !is_final_stage && (
					<div>
						<div style={{ display: 'flex', margin: '85px 24px' }}>
							<img
								src={action_type === ACTION_TYPE_MENU.delete ? ImageLinks.delete_red_ripple : ImageLinks.excalamation_yellow_ripple}
								style={{ width: 48, height: 48 }}
							/>
							<div style={{ marginLeft: 16, width: '100%' }}>
								<div style={{ display: 'flex' }}>
									<Text semi className={classes.header_title}>
										{action_type === ACTION_TYPE_MENU.allocate
											? 'Confirm Bulk Route Allocation'
											: action_type === ACTION_TYPE_MENU.delete
											? 'Confirm Bulk Unrouting'
											: 'Confirm Bulk Route Unlink'}
									</Text>
								</div>
								<Text medium className={classes.text_container}>
									{action_type === ACTION_TYPE_MENU.allocate ? (
										<Text medium className={classes.text_container}>
											Are you sure you want to allocate{' '}
											<Text bold component={'span'}>
												{route_type === 'linked' ? linked_route_count : unlinked_route_count} routes
											</Text>
											? {route_type === 'unlinked' ? 'Please note, routes will be assigned to Lowest Cost rider.' : ''}
										</Text>
									) : action_type === ACTION_TYPE_MENU.delete ? (
										<Text medium className={classes.text_container}>
											Are you sure you want to unroute{' '}
											<Text bold component={'span'}>
												{route_type === 'linked' ? linked_route_count : unlinked_route_count} routes
											</Text>
											? All route information will be removed.
										</Text>
									) : (
										<Text medium className={classes.text_container}>
											Are you sure you want to unlink{' '}
											<Text bold component={'span'}>
												{route_type === 'linked' ? linked_route_count : unlinked_route_count} routes
											</Text>
											? All rider information will be removed.
										</Text>
									)}
								</Text>
							</div>
						</div>
						<div style={{ marginTop: 'auto', gap: 8 }} className='d-flex justify-content-center'>
							<Button size='large' type='outlined' text='Cancel' onClick={() => set_action_type(null)} />

							<Button
								size='large'
								type='round'
								text={
									action_type === ACTION_TYPE_MENU.allocate
										? `Allocate Rider`
										: action_type === ACTION_TYPE_MENU.delete
										? `Unroute`
										: `Unlink Route`
								}
								onClick={() => handle_confirm_click()}
							/>
						</div>
					</div>
				)}
				{is_final_stage && (
					<div className={classes.final_stage_container}>
						<img src={action_type === ACTION_TYPE_MENU.delete ? ImageLinks.delete_solid_red : ImageLinks.tickCircleSolid}></img>
						<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, textTransform: 'capitalize' }}>{`${route_type} Routes`}</Text>
						<Text medium style={{ fontSize: 20 }}>
							{success_message}
						</Text>
						{action_type === ACTION_TYPE_MENU.allocate && (
							<Text medium style={{ fontSize: 16, width: '50%' }}>
								Please refresh page in 5 minutes and review unallocated routes
							</Text>
						)}
					</div>
				)}
				{is_final_stage && (
					<div className='d-flex justify-content-center' style={{ marginTop: 32 }}>
						<Button
							size='large'
							type='round'
							text={action_type === ACTION_TYPE_MENU.allocate ? 'Track Orders' : 'OK'}
							onClick={handle_track_order}
							style={{ minWidth: 110 }}
						/>
					</div>
				)}
			</div>
		</Modal>
	);
}

export default BulkActionModal;
