import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column' },
	content_wrap: { marginLeft: 16, gap: 20, display: 'flex', flex: 1 },
	subtitle_text: { fontSize: 12, marginTop: 4, color: theme.colors.darkGrey2 },
	input_wrap: { display: 'flex', flexDirection: 'column', flex: 0.55, overflowX: 'hidden' },
	output_wrap: { display: 'flex', flexDirection: 'column', flex: 0.45, marginTop: -60 },
	input_section: {
		padding: 16,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightPink9}`,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.3)`,
		flex: 1,
		marginTop: 8,
		display: 'flex',
		flexDirection: 'column',
	},
	cta_wrap: { display: 'flex', justifyContent: 'flex-end', marginTop: 15 },
	header_wrap: {
		marginTop: 4,
		height: 33,
		borderRadius: 40,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		display: 'flex',
		alignItems: 'center',
		padding: '6px 14px',
	},
	pricing_scoll: {
		margin: 4,
		marginTop: 0,
		minHeight: 50,
		maxHeight: 175,
		overflow: 'auto',
		'&::-webkit-scrollbar': {
			background: theme.colors.lightGrey13,
			width: 8,
		},

		/* Track */
		'&::-webkit-scrollbar-track': {
			width: 4,
			background: theme.colors.lightGrey13,
			borderRadius: 60,
		},

		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: theme.colors.darkPurple,
			borderRadius: 60,
		},
	},
	empty_pin_content_wrap: {
		borderRadius: 4,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		height: 60,
		width: 350,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 26,
		paddingRight: 26,
	},
	output_section: {
		padding: 16,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 16px rgba(102, 102, 102, 0.1)`,
		borderRadius: 12,
		flex: 1,
		display: 'flex',
	},
	purple_strip: {
		width: 350,
		height: 60,
		borderRadius: 4,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		display: 'flex',
		alignItems: 'center',
		padding: 10,
		paddingLeft: 26,
	},
	active_tab: {
		padding: '8px 12px',
		backgroundColor: theme.colors.primary,
		color: theme.colors.white,
		display: 'flex',
		borderRadius: 8,
	},
	inactive_tab: {
		padding: '8px 12px',
		backgroundColor: theme.colors.white,
		color: theme.colors.lightPurple7,
		display: 'flex',
		borderRadius: 8,
		cursor: 'pointer',
	},
	pincode_pricing_section_wrap: {
		marginTop: 12,
		height: 41,
		padding: 12,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		borderRadius: 6,
		cursor: 'pointer',
	},
	section_desc_text: { fontSize: 14, color: theme.colors.primary, marginLeft: 12 },
	group_name_wrap: { flexWrap: 'wrap', paddingBottom: 8, borderBottom: `1px solid ${theme.colors.lightPurple}` },
}));

export default useStyles;
