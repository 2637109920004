import React from 'react';
import ImageLinks from 'assets/images/ImageLinks';
import useStyles from './styles';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import useEditRider from './useEditRider';
import RiderPersonalInfo from './components/RiderPersonalInfo';
import RiderLocation from './components/RiderLocation';
import RiderTags from './components/RiderTags';
import RiderAddress from 'modules/modals/RiderAddress';
import Footer from './components/Footer';
import Routes from 'resources/Routes';
import clsx from 'clsx';
import Toast from 'modules/Toast';
import RiderTrace from './components/RiderTrace';

const sections = [
	{ title: 'Rider Profile', is_locked: false },
	{ title: 'Address', is_locked: false },
	{ title: 'Rider Tags', is_locked: false },
	{ title: 'Enable Trace', is_locked: false },
	{ title: 'Family Information', is_locked: true },
	{ title: 'Educational Qualification', is_locked: true },
	{ title: 'Legal Documents', is_locked: true },
	{ title: 'Vehicle Details', is_locked: true },
	{ title: 'Inventory', is_locked: true },
];

const EditRider = ({ match }) => {
	const rider_id = match.params.id;
	const {
		history,
		rider_data,
		rider_details,
		rider_tags,
		selected_section,
		address_modal,
		set_rider_tags,
		show_success,
		set_selected_section,
		handle_data,
		set_address_modal,
		handle_dob,
		on_address_select,
		edit_addr,
		handle_update,
		data_diff,
		set_show_success,
	} = useEditRider(rider_id);
	const classes = useStyles();

	return (
		<div className={`container ${classes.wrapper}`} style={{ paddingRight: 0 }}>
			<div className='align-items-center'>
				<Text className='cursor-pointer' style={{ fontSize: 22 }} onClick={() => history.push(Routes.ALL_RIDERS.path)}>
					Riders
				</Text>
				<div className='header_separator'></div>
				<Text className='cursor-pointer' style={{ fontSize: 22 }} onClick={() => history.goBack()}>
					{_.get(rider_details, 'details.rider_name', '')}
					<Text component={'span'} style={{ fontSize: 14 }}>
						{` (${_.get(rider_details, 'details.rider_id', '')})`}
					</Text>
				</Text>
				<div className='header_separator'></div>
				<Text semi className='header_active_title'>
					Edit Details
				</Text>
			</div>
			{show_success && (
				<Toast close_callback={() => set_show_success(false)}>
					<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
					<Text medium style={{ marginLeft: 4, fontSize: 14 }}>
						Rider updated successfully.
					</Text>
				</Toast>
			)}

			<div className={classes.content}>
				<div className={classes.left_wrap}>
					<Text bold style={{ fontSize: 18 }}>
						Rider KYC
					</Text>
					<Text medium className={classes.left_section_desc}>
						Store data related to rider
					</Text>
					<div style={{ marginTop: 12 }}>
						{_.map(sections, (item, index) => (
							<div
								key={`section_item_${index}`}
								className={classes.left_section_item}
								style={{
									border: `1px solid ${selected_section === index ? theme.colors.primary : theme.colors.lightGrey5}`,
									backgroundColor: item.is_locked
										? theme.colors.darkGrey6
										: selected_section === index
										? theme.colors.lightPurple
										: theme.colors.white,
									cursor: item.is_locked ? 'unset' : 'pointer',
								}}
								onClick={() => set_selected_section(index)}>
								<Text bold style={{ fontSize: 12, color: item.is_locked ? theme.colors.darkGrey2 : theme.colors.black }}>
									{item.title}
								</Text>
								<img
									src={
										item.is_locked
											? ImageLinks.lock
											: selected_section === index
											? ImageLinks.chevron_right_pink
											: ImageLinks.chevron_right_grey
									}
									width={14}
									height={14}
								/>
							</div>
						))}
					</div>
				</div>
				<div className={classes.right_wrap}>
					<div style={{ paddingLeft: 30, paddingBottom: 80 }}>
						<RiderPersonalInfo rider_data={rider_data} handle_data={handle_data} handle_dob={handle_dob} rider_id={rider_id} />
						<RiderLocation rider_data={rider_data} edit_addr={edit_addr} />
						<RiderTags rider_tags={rider_tags} set_rider_tags={set_rider_tags} show_success={show_success} />
						<RiderTrace rider_details={rider_details} rider_data={rider_data} handle_data={handle_data} />
					</div>
					<Footer rider_data={rider_data} rider_details={rider_details} rider_tags={rider_tags} handle_update={handle_update} data_diff={data_diff} />
				</div>
			</div>
			{address_modal && (
				<RiderAddress
					zone_id={_.get(rider_data, 'current_location.zone.id', 6)}
					close={() => set_address_modal(false)}
					on_address_select={on_address_select}
					edit_details={{
						address_line1: rider_data.current_location.address_line_1,
						selected_place: {
							state: rider_data.current_location.state,
							city: rider_data.current_location.city,
							// name: '',
							pincode: rider_data.current_location.pin_code,
							formatted_address: rider_data.current_location.address_line_1,
							geometry: {
								location: new window.google.maps.LatLng(
									Number(rider_data.current_location.latitude),
									Number(rider_data.current_location.longitude),
								),
							},
						},
					}}
				/>
			)}
		</div>
	);
};

export default EditRider;
