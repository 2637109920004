import React, { useEffect, useRef, useState } from 'react';
import qs from 'qs';
import moment from 'moment';
import Text from 'modules/Text';
import theme from 'resources/theme';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import api_requests from 'resources/api_requests';
import Filters from 'modules/Filters/Filters';
import clsx from 'clsx';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from '../helper';
import Routes from 'resources/Routes';
import { useHistory } from 'react-router-dom';
import OrderLabel from 'modules/OrderLabel';
import utils from 'resources/utils';
import FilterButton from 'modules/FilterButton';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import Helpcenter from 'modules/HelpCenter';
import NoResult from 'modules/NoResult';
import ImageLinks from 'assets/images/ImageLinks';
import NetworkConfigAvatar from 'modules/NetworkConfigAvatar';
import { hide_loader, show_loader } from 'actions/app';

const useStyles = makeStyles(() => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		marginTop: -60,
		paddingTop: 20,
	},
	ongoingOrdersDiv: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: 40,
		gap: 20,
		marginRight: 10,
	},
	filter_btn: {
		backgroundColor: theme.colors.white,
		marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	detailsTextContainer: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
		gap: 10,
	},
	pickedUpContainer: {
		overflowY: 'auto',
		flex: 1,
		marginTop: 10,
		marginBottom: 10,
	},
	pickedUpItem: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
	},
	orderDetailsClickabletext: {
		flex: 1,
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
	pagesContainer: {
		height: 60,
		marginBottom: 10,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		borderTop: `1px solid ${theme.colors.lightPurple}`,
	},
	pageItemDiv: {
		marginLeft: 20,
		marginRight: 20,
		width: 24,
		height: 24,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 20,
	},
	pageText: { color: theme.colors.primary, fontSize: 12 },
	setPageContainer: { display: 'flex', alignItems: 'center' },
	truncate: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 16,
	},
	header_title_2: { flex: 2, color: theme.colors.darkGrey2, fontSize: 14 },
	table_item_wrap_2: { flex: 2, minWidth: 0 },
	page_nav_btn: {
		width: 24,
		height: 24,
		borderRadius: 30,
	},
	enabled_page_nav: { border: `1px solid ${theme.colors.primary}` },
	disabled_page_nav: { backgroundColor: theme.colors.lightGrey5, width: 26, height: 26 },
	wrapper_help_center: { position: 'fixed', bottom: 30, left: '50%', zIndex: 100 },
	subtitle: { fontSize: 12, color: theme.colors.lightGrey6, marginTop: 2 },
}));

const Terminal = ({ selected_master_zone }) => {
	const { app_configs, selected_business_id } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
			selected_business_id: state.app.selected_business_id,
		}),
		shallowEqual,
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const filter_object = qs.parse(history.location.search.slice(1)).filter || { unified_status: '11' };

	const [selected_pb_id, set_selected_pb_id] = React.useState('');
	const [show_order_details, set_show_order_details] = React.useState(false);
	const [orders, set_orders] = React.useState({});
	const [all_filters, set_all_filters] = React.useState([]);
	const [show_filters, set_show_filters] = React.useState(false);
	const [order_list, set_order_list] = React.useState([]);
	const [count, set_count] = useState();
	const slider_ref = React.useRef();
	const loading_scroll = useRef(false);

	useEffect(() => {
		set_all_filters([]);
		api_requests.get_terminal_filters(selected_master_zone).then((res) => set_all_filters(res.data.filters));
	}, [selected_master_zone]);

	useEffect(() => {
		if (_.isEmpty(filter_object)) {
			return;
		}
		get_orders({});
	}, [JSON.stringify(filter_object)]);

	const get_orders = ({ token }) => {
		dispatch(show_loader());
		api_requests.get_terminal_search({ ...filter_object, ...(token && { token }) }).then((res) => {
			loading_scroll.current = false;
			if (token) {
				set_order_list(_.concat(order_list, res.data.data));
			} else {
				set_count(res.data.count);
				set_order_list(res.data.data);
			}
			set_orders(res.data);
			dispatch(hide_loader());
		});
	};

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && orders.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_orders({ token: orders.next });
		}
	};

	const handle_export = async (filters, callback) => {
		try {
			const res = await api_requests.export_all_orders_pwp({ ...filters }, 'terminal');
			utils.downloadBlob(res, 'orders.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	const classes = useStyles();

	return (
		<>
			<div className={classes.container}>
				<div className={classes.ongoingOrdersDiv}>
					<div className='d-flex align-items-center' style={{ gap: 5, marginTop: -10 }}>
						<Text semi component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
							Orders :{' '}
						</Text>
						<Text semi component={'span'} style={{ fontSize: 14 }}>
							{count}
						</Text>
					</div>
					<FilterButton
						handle_click={() => set_show_filters(true)}
						total_filters={Object.keys(filter_object).length}
						wrapper_styles={{ marginBottom: 15 }}
						track_event={{ event: 'PP_terminal_filter', properties: filter_object }}
					/>
				</div>
				<div className={classes.detailsTextContainer}>
					<Text bold style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
						Order ID
					</Text>
					<Text bold className={classes.header_title_2}>
						Customer Details
					</Text>
					<Text bold className={classes.header_title_2}>
						Pickup Details
					</Text>
					<Text bold className={classes.header_title_2}>
						Expected Delivery
					</Text>
					<Text bold className={classes.header_title_2}>
						Delivery Partner
					</Text>
					<Text bold className={classes.header_title_2}>
						Status
					</Text>
				</div>

				<div className={classes.pickedUpContainer} ref={slider_ref} onScroll={slider_scrolled}>
					{_.map(order_list, (order, key) => {
						return (
							<div key={`order${key}`} className={classes.pickedUpItem}>
								<div style={{ flex: 1, minWidth: 0, position: 'relative' }}>
									{order.business_id != selected_business_id && (
										<img src={ImageLinks.network_light_purple} width={20} height={20} style={{ position: 'absolute', left: -25, top: 1 }} />
									)}
									<Text
										semi
										className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
										onClick={() => {
											set_selected_pb_id(order.external_order_id);
											set_show_order_details(true);
										}}>
										{order.mcp_id}
									</Text>
									<Text className={classes.subtitle}>{order.reference_id || order?.dd_channel?.order_id || order.id || '-'}</Text>
								</div>
								<div className={classes.table_item_wrap_2}>
									<Text medium className={classes.truncate}>
										{order.customer_name || '-'}
									</Text>
									<Text className={classes.subtitle}>{`${order.customer_mobile} | ${order?.customer_pincode}`}</Text>
								</div>
								<div className={classes.table_item_wrap_2}>
									<Text medium className={classes.truncate}>
										{order.pickup_time ? moment(order.pickup_time).format('DD-MM-YY') : '-'}
									</Text>
									<Text className={classes.subtitle}>{order.pickup_time ? moment(order.pickup_time).format('hh:mm A') : '-'}</Text>
								</div>
								<div className={classes.table_item_wrap_2}>
									<Text medium className={classes.truncate}>
										{order.drop_time ? moment(order.drop_time).format('DD-MM-YY') : '-'}
									</Text>
									<Text className={classes.subtitle}>{order.drop_time ? moment(order.drop_time).format('hh:mm A') : '-'}</Text>
								</div>
								<div className={classes.table_item_wrap_2}>
									<NetworkConfigAvatar id={order?.fulfillment_network_id} logo_width={120} />
								</div>
								<div className={classes.table_item_wrap_2}>
									<Text medium className={classes.truncate}>
										<OrderLabel status={order.unified_status} />
									</Text>
								</div>
							</div>
						);
					})}
					{/* {order_list.length < 8 && (
						<div className={classes.wrapper_help_center}>
							<Helpcenter
								text='How can I power my orders with Pidge?'
								url='https://pidge.in/helpcenter-order-poweredwithpidge'
								style={{ marginTop: 40 }}
							/>
						</div>
					)} */}
					{order_list.length == 0 && Object.keys(filter_object).length > 1 && (
						<div className='d-flex justify-content-center align-items-center' style={{ marginTop: '15%', flexDirection: 'column', gap: 10 }}>
							<NoResult
								text='No order found'
								btn_onclick={() => history.replace({ search: '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.Terminal.id })}
							/>
						</div>
					)}
				</div>
			</div>
			{show_order_details && <OrderModal id={selected_pb_id} close={() => set_show_order_details(false)} />}
			{show_filters && (
				<Filters
					all_filters={all_filters}
					existing_filters={filter_object}
					set_selected_filters={(filters) => {
						history.push(
							Routes.POWERED_WITH_PIDGE.path + `?type=${POWERED_WITH_PIDGE_MENU_ITEMS.Terminal.id}&` + qs.stringify({ filter: filters }),
						);
					}}
					close={() => set_show_filters(false)}
					title='Terminal'
					handle_export={handle_export}
				/>
			)}
		</>
	);
};

export default Terminal;
