import ImageLinks from 'assets/images/ImageLinks';

export const CREATE_OPTIONS = [
	{
		title: 'Custom',
		key: 'custom',
		// enabled_icon: ImageLinks.marker2,
		// disbled_icon: ImageLinks.gps_light_purple,
	},
	{
		title: 'Template',
		key: 'template',
		// enabled_icon: ImageLinks.marker_purple,
		// disbled_icon: ImageLinks.light_marker_purple,
	},
];

export const FLOW_TYPE = {
	CUSTOM: 'custom',
	TEMPLATE: 'template',
};

export const CUSTOM_FLOW_TYPE = {
	DRAW: 'draw',
	UPLOAD: 'upload',
};

export const SCREEN = {
	no_zone: 'no_zone',
	master_zones: 'master_zones',
	sub_zones: 'sub_zones',
	create_master_zone: 'create_master_zone',
	create_business_zone: 'create_business_zone',
	edit_master_zone: 'edit_master_zone',
	pincodes: 'pincodes',
	edit_business_zone: 'edit_business_zone',
};
