import { makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	map_container: {
		position: 'absolute',
		top: 0,
		right: 480,
		height: '100%',
		width: 500,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		zIndex: 1199,
		overflowY: 'auto',
		paddingBottom: 120,
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	div_layers: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		width: 500,
		zIndex: 1200,
		top: 0,
		left: 0,
	},
	selected_circle: {
		position: 'absolute',
		width: 150,
		height: 150,
		zIndex: 1200,
		transition: 'all 1s',
	},
	purple_circle: {
		width: 190,
		height: 190,
		borderRadius: '50%',
		position: 'absolute',
		backgroundColor: theme.colors.primary + '11',
		border: `2px dashed ${theme.colors.primary}`,
		transition: 'all 1s',
	},
	map_footer: {
		position: 'absolute',
		width: 480,
		height: 50,
		bottom: 0,
		left: 0,
		background: 'rgba(255, 255, 255, 0.8)',
		borderRadius: '8px 8px 0px 0px',
		padding: '10px 16px',
	},
}));

function AutoAddMap({ max_cluster_size, intra_cluster_distance, centroid_to_route_distance }) {
	const classes = useStyles();
	const theme = useTheme();
	return (
		<div className={classes.map_container}>
			<img
				src={
					max_cluster_size == 0
						? ImageLinks.map_purple_unrouted
						: max_cluster_size > 0 && intra_cluster_distance === 0
						? ImageLinks.map_count_of_points
						: ImageLinks.illustration_map
				}
				style={{
					height:
						max_cluster_size === 0 || intra_cluster_distance === 0
							? '120vh'
							: intra_cluster_distance > 3500
							? '150vh'
							: intra_cluster_distance > 1500
							? '250vh'
							: '300vh',
					width: 'auto',
					transition: 'all 1s',
				}}
			/>
			<div className={classes.div_layers}>
				{intra_cluster_distance > 0 && <img src={ImageLinks.illustration_selected_circle} className={classes.selected_circle}></img>}
			</div>
			{centroid_to_route_distance > 0 && intra_cluster_distance > 0 && (
				<div className={classes.div_layers}>
					{max_cluster_size > 0 && (
						<div
							className={classes.purple_circle}
							style={{
								height: 200 + (centroid_to_route_distance / 500) * 5,
								width: 200 + (centroid_to_route_distance / 500) * 5,
							}}></div>
					)}
				</div>
			)}
			<div className={classes.map_footer}>
				<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
					Please note: Points on map are not to scale. They are indicative to help visual representation.
				</Text>
			</div>
		</div>
	);
}

export default AutoAddMap;
