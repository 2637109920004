import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import theme from 'resources/theme';
import moment from 'moment';
import api_requests from 'resources/api_requests';
import OutlinedButton from 'modules/OutlinedButton';
import ContainedButton from 'modules/ContainedButton';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles(() => ({
	paper: {
		position: 'absolute',
		width: 600,
		backgroundColor: theme.palette.background.paper,
		padding: 20,
		borderRadius: 20,
	},
	ok_button_container: {
		marginTop: 32,
		textAlign: 'right',
	},
	ok_button_alignment: {
		boxShadow: 'unset',
		borderRadius: 30,
		paddingLeft: 36,
		paddingRight: 36,
	},
	delivery_container: {
		padding: '48px 48px 60px 48px',
	},

	cancel_button: {
		width: 150,
	},
	ctn_button: {
		borderRadius: 30,
		marginLeft: 12,
		width: 150,
		'&:disabled': {
			backgroundColor: theme.colors.lightGrey5,
			color: theme.colors.darkGrey2,
		},
	},
	date_filter: {
		height: 36,
		borderRadius: 36,
		border: `1px solid ${theme.colors.lightPurple}`,
		backgroundColor: theme.colors.white,
		width: 186,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 14,
		paddingRight: 8,
	},
}));

const MoveOrdersModal = ({ close, information_transfer_to_move_modal, id, on_success }) => {
	const classes = useStyles();
	const [delivery_date, set_delivery_date] = useState('');
	const [delivery_slot, set_delivery_slot] = useState('');
	const [slot_valid, set_slot_valid] = useState(false);

	const handle_confirm_and_move = async () => {
		try {
			const data = {
				...information_transfer_to_move_modal,
				_id: id,
				delivery_slot: delivery_slot,
				delivery_date: moment(delivery_date).format('YYYY-MM-DD'),
			};

			const response = await api_requests.create_repeat_order_copy(data);
			if (_.isEmpty(response.data)) {
				on_success();
			}
		} catch (err) {}
	};

	const handle_delivery_date = (e) => {
		set_delivery_date(e);
	};

	const handle_slot_change = (e) => {
		set_delivery_slot(e.target.value);
		const value = e.target.value;
		if (_.isEmpty(value)) {
			set_slot_valid(true);
			return;
		}
		let regex = new RegExp(/^(([01]\d|2[0-3]):([0-5]\d))-(([01]\d|2[0-3]):([0-5]\d))$/);
		if (regex.test(value)) set_slot_valid(true);
		else set_slot_valid(false);
	};

	return (
		<Modal open={true} onClose={close}>
			<div className={classes.paper} style={{ top: '28%', left: '40%', transform: 'translate(-20%, -30%)' }}>
				<div className='justify-content-between align-items-center'>
					<div>
						<Text bold style={{ fontSize: 20, marginBottom: 4 }}>
							Add Delivery Slot & Date
						</Text>
					</div>
					<div>
						<img src={ImageLinks.crossBlack} width={14} height={14} className='cursor-pointer' alt='cross' onClick={close} />
					</div>
				</div>
				<Text medium style={{ fontSize: 12 }}>
					Please update delivery slot & date for the selected orders to proceed.
				</Text>
				<div className={classes.delivery_container}>
					<div className='justify-content-between align-items-center' style={{ marginBottom: 16 }}>
						<Text semi style={{ fontSize: 16 }}>
							Delivery Date*
						</Text>
						<div>
							<DatePicker
								selected={delivery_date}
								onChange={(date) => handle_delivery_date(date)}
								dateFormat='dd-MM-yyyy'
								minDate={new Date()}
								customInput={
									<div className={classes.date_filter}>
										<Text
											semi
											style={{
												fontSize: 14,
												color: delivery_date == '' ? theme.colors.lightPurple6 : theme.colors.primary,
											}}>
											{delivery_date == '' ? 'DD/MM/YY' : `${moment(delivery_date).format('DD-MM-YYYY')}`}
										</Text>
										<img src={ImageLinks.calendar_solid_circle} alt='calendar_filter' />
									</div>
								}
							/>
						</div>
					</div>
					<div className='justify-content-between align-items-center'>
						<Text bold style={{ fontSize: 16, marginBottom: 4 }}>
							Delivery Slot*
						</Text>
						<UndlerlineTextField
							style={{ width: '45%' }}
							label='Delivery Slot (e.g. 14:00-18:00)'
							value={delivery_slot}
							onChange={handle_slot_change}
						/>
					</div>
				</div>
				<div className='d-flex justify-content-center' style={{ paddingBottom: 12 }}>
					<OutlinedButton className={classes.cancel_button} onClick={close}>
						<Text semi style={{ fontSize: 16 }}>
							Cancel
						</Text>
					</OutlinedButton>
					<ContainedButton className={classes.ctn_button} onClick={handle_confirm_and_move} disabled={!slot_valid || delivery_date == ''}>
						<Text semi style={{ fontSize: 16 }}>
							Confirm & Move
						</Text>
					</ContainedButton>
				</div>
			</div>
		</Modal>
	);
};

export default MoveOrdersModal;
