import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', background: theme.colors.white },
	header: { display: 'flex', alignItems: 'center' },
	back_arrow: { backgroundColor: theme.colors.lightPurple, width: 20, height: 20, borderColor: theme.colors.lightPurple },
	content: { flex: 1, display: 'flex', overflowY: 'hidden' },
	route_text: { fontSize: 22, cursor: 'pointer' },
	bundlelist_wrap: {
		backgroundColor: theme.colors.white,
		width: 250,
		padding: '12px 20px',
		overflowY: 'auto',
		borderRight: `2px solid ${theme.colors.lightPurple}`,
	},
	bundle_list_item: {
		backgroundColor: theme.colors.white,
		marginTop: 10,
		borderRadius: 5,
		padding: '10px 12px',
		position: 'relative',
	},
	purple_dot: {
		width: '12px',
		height: '12px',
		backgroundColor: theme.colors.primary,
		display: 'block',
		borderRadius: '50%',
		position: 'absolute',
		right: '-6px',
		top: '-6px',
	},
	red_dot: {
		width: '12px',
		height: '12px',
		backgroundColor: theme.colors.red,
		display: 'block',
		borderRadius: '50%',
		position: 'absolute',
		right: '-6px',
		top: '-6px',
	},
	red_dot_small: {
		width: '8px',
		height: '8px',
		backgroundColor: theme.colors.red,
		display: 'block',
		borderRadius: '50%',
	},
	simulator_toast_wrap: {
		borderRadius: 4,
		border: `1px solid ${theme.colors.lightGrey5}`,
		background: theme.colors.white,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		padding: 10,
		display: 'flex',
		alignItems: 'center',
		gap: 10,
	},
	list_item_wrap: { display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' },
	list_item_stats_wrap: { display: 'flex', justifyContent: 'space-between', columnGap: 10, alignItems: 'flex-start' },
	list_item_stats_wrap_2: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' },
	bid_text: { fontSize: 10, color: theme.colors.darkGrey },
	compare_btn: {
		backgroundColor: theme.colors.white,
		borderRadius: '30px',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		alignSelf: 'flex-end',
		marginLeft: 'auto',
		'&:hover': {
			color: theme.colors.white,
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	exit_btn: {
		backgroundColor: theme.colors.lightPurple,
		borderRadius: '30px',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		alignSelf: 'flex-end',
		marginLeft: 'auto',
		'&:hover': {
			color: theme.colors.primary,
			backgroundColor: theme.colors.lightPurple,
		},
	},
	search_input: {
		width: '90%',
		height: 32,
		border: '1px solid ' + theme.colors.lightPurple,
		borderRadius: 36,
		padding: '8px 12px',
		outline: 'none',
		'&::focus': {
			border: '2px solid ' + theme.colors.primary,
			outline: 'none',
		},
		'&::placeholder': {
			color: theme.colors.lightPurple,
			fontSize: 14,
			fontWeight: '500',
		},
	},
	add_existing_text: { textDecoration: 'underline', textDecorationColor: theme.colors.lightPurple6, textUnderlineOffset: '5px' },
	bulk_action_text: { color: theme.colors.primary, textDecoration: 'underline', marginRight: 22, cursor: 'pointer' },
	graph_circle: {
		width: 36,
		height: 36,
		backgroundColor: theme.colors.lightPurple,
		borderRadius: 18,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		marginLeft: 8,
		'&:hover': {
			boxShadow: `0px 2px 16px rgba(102, 102, 102, 0.3)`,
			backgroundColor: theme.colors.primary,
			'& .purple-download': {
				display: 'none',
			},
			'& .white-download': {
				display: 'block !important',
			},
		},
	},
	advance_button: {
		padding: '8px 12px',
		borderRadius: 30,
		cursor: 'pointer',
	},
	wrapper_empty_state: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 30 },
}));

export default useStyles;
