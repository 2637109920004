import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import IconButton from 'modules/IconButton';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';
import { ListItem } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import moment from 'moment';
import constant from 'resources/constant';
import api_requests from 'resources/api_requests';
import RiderChangeModal from 'modules/RiderChangeModal';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	container: {
		position: 'absolute',
		top: 10,
		left: 10,
		height: '100%',
		width: 300,
		backgroundColor: 'white',
		borderRadius: 20,
		borderTopLeftRadius: 50,
		zIndex: 999,
		display: 'flex',
		flexDirection: 'column',
		padding: 16,
	},

	task_container: {
		display: 'flex',
		marginTop: 8,
		backgroundColor: theme.colors.lightPurple,
		padding: 12,
		borderRadius: 8,
	},

	update_list_container: {
		marginTop: 12,
		marginBottom: 5,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	task_sequence: {
		fontSize: 18,
	},
	update_btn: {
		borderRadius: 30,
		height: 30,
	},
	task_sub_text: {
		fontSize: 8,
		color: theme.colors.lightGrey6,
	},
	bundle_list_item: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white,
		marginTop: 4,
		borderRadius: 5,
		padding: '12px 12px',
		width: 246,
	},

	serial_number: {
		height: 18,
		width: 18,
		borderRadius: 20,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: theme.colors.primary,
		fontSize: 12,
		marginRight: 4,
	},
	iconsUpDown: {
		alignSelf: 'stretch',
		display: 'flex',
		alignItems: 'center',
	},
	icon_btn: {
		marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
		backgroundColor: 'white',
		'&:hover': {
			backgroundColor: 'white',
		},
	},
	avatar_wrap: {
		width: 50,
		height: 50,
		borderRadius: 28,
		backgroundColor: theme.colors.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const TaskCardListItem = ({ task, handle_reciever_click = () => {}, is_active = false }) => {
	const classes = useStyles();
	return (
		<ListItem
			button
			className={`box-shadow ${classes.bundle_list_item}`}
			style={{
				border: `1px solid ${is_active ? theme.colors.primary : 'transparent'}`,
				backgroundColor: is_active ? theme.colors.lightPink4 : 'transparent',
			}}>
			<div style={{ minWidth: '30%' }}>
				<Text semi style={{ fontSize: 12 }} onClick={handle_reciever_click}>
					{task.task_type == 10
						? _.truncate(_.get(task, 'sender_details.name', '-'), { length: 10 })
						: _.truncate(task.receiver_name ? task.receiver_name : '-', { length: 10 })}
				</Text>

				<Text medium className={classes.task_sub_text}>
					{task.trip_type === 4 ? task.trip_id : task.mcp_id}
				</Text>
			</div>
			<div>
				<div style={{ display: 'flex' }}>
					<Text semi style={{ fontSize: 12 }}>
						{!_.isNull(task.expected_time) && !_.isEmpty(task.expected_time) ? moment(task.expected_time).format('hh:mm A').split(' ')[0] : '-'}
					</Text>
					<Text semi style={{ fontSize: 10, alignSelf: 'center' }}>
						{!_.isNull(task.expected_time) && !_.isEmpty(task.expected_time) ? moment(task.expected_time).format('hh:mm A').split(' ')[1] : '-'}
					</Text>
				</div>
				<Text medium className={classes.task_sub_text}>
					Estimated Time
				</Text>
			</div>
			<div style={{ width: 58, height: 26 }}>
				<Text semi style={{ fontSize: 12, color: theme.colors.lightGrey2 }}>
					{constant.TRIP_STATUS_TEXT[task.status_id]}
				</Text>
			</div>
			<div className={classes.iconsUpDown}>
				{task.task_type === constant.TASK_TYPES.DROP.id ? (
					<img src={ImageLinks.arrow_down_purple} width='16' height='16' alt='' />
				) : (
					<img src={ImageLinks.arrow_up_green} width='16' height='16' alt='' />
				)}
			</div>
		</ListItem>
	);
};

const SearchRiderModal = ({
	rider_tasks,
	set_rider_tasks,
	rider_data,
	close,
	set_selected_pb_id,
	set_show_pd_id_modal,
	set_selected_trip_id,
	completed_tasks,
	show_completed_tasks,
	handle_completed_task,
	open_rider_modal,
	show_rider_tasks_onmap,
}) => {
	const classes = useStyles();
	const [show_update_sequence, set_show_update_sequence] = useState(false);
	const [selected_task_ids, set_selected_task_ids] = useState([]);
	const [show_rider_change_modal, set_show_rider_change_modal] = useState(false);

	const drop_tasks = rider_tasks.routes.filter((item) => item.task_type === constant.TASK_TYPES.DROP.id);

	const on_drag_end = ({ destination, source }) => {
		// dropped outside the list
		if (!destination) return;

		const new_task_list = _.cloneDeep(rider_tasks.routes);
		const [removed] = new_task_list.splice(source.index, 1);
		new_task_list.splice(destination.index, 0, removed);

		//Change priority as per new index
		const priority_task_list = [];
		_.map(new_task_list, (task, index) => {
			task.priority = rider_tasks.routes.length - index;
			priority_task_list.push(task);
		});
		set_rider_tasks({
			...rider_tasks,
			routes: priority_task_list,
		});
		set_show_update_sequence(true);
	};

	const handle_reciever_click = (task) => {
		if (task.trip_type != 4) {
			set_selected_pb_id(task.external_order_id);
			set_show_pd_id_modal(true);
		} else {
			set_selected_trip_id(task.trip_id);
		}
	};

	const handle_update_sequence = () => {
		const ror_priority = _.map(rider_tasks.routes, (task, index) => ({
			ror_id: task.ror_id,
			priority: task.priority,
		}));
		api_requests
			.update_priority_task({
				ror_priority: ror_priority,
				request_by: {
					name: 'BOP USER',
					reason: 'Reprioritized',
				},
				rider_id: rider_data.details.rider_id,
			})
			.then((res) => {
				set_show_update_sequence(false);
			});
	};

	const get_border_color = () => {
		if (_.isEmpty(rider_data)) return;
		const online_flag = rider_data.details.is_online;
		const ongoing_flag = rider_data.delivery_stats.ongoing;
		const shift_flag = rider_data.shift;
		let border_color = theme.colors.lightGrey2;
		if (online_flag && (ongoing_flag == 0 || ongoing_flag === null)) border_color = theme.colors.green;
		if (ongoing_flag >= 1) border_color = theme.colors.darkYellow;
		if (!online_flag && !_.isEmpty(shift_flag)) border_color = theme.colors.red;
		if (_.isEmpty(shift_flag)) border_color = theme.colors.lightGrey2;

		return `2px solid ${border_color}`;
	};

	const handle_card_click = (task_id) => {
		let _selected_task_ids = _.cloneDeep(selected_task_ids);
		if (!_.includes(_selected_task_ids, task_id)) {
			_selected_task_ids.push(task_id);
		} else {
			_selected_task_ids.splice(_.indexOf(_selected_task_ids, task_id), 1);
		}
		set_selected_task_ids(_selected_task_ids);
	};

	const handle_change_rider = async (new_rider) => {
		let data = utils.remove_null_and_empty_from_obj({
			oldRiderId: _.get(rider_data, 'details.rider_id', '-'),
			newRiderId: new_rider?.rider_id,
			tripIds: selected_task_ids,
		});
		try {
			const res = await api_requests.handover_trip_rider(data);
			set_show_rider_change_modal(false);
			show_rider_tasks_onmap(_.get(rider_data, 'details.rider_id', '-'));
			set_selected_task_ids([]);
		} catch (err) {}
	};

	return (
		<>
			<div className={classes.container}>
				<div className={'d-flex align-items-center'}>
					<div className={classes.avatar_wrap} style={{ border: get_border_color() }}>
						<img
							src={_.isEmpty(rider_data?.details?.profile_pic) ? ImageLinks.user_filled_purple : rider_data.details.profile_pic}
							width={_.isEmpty(rider_data?.details?.profile_pic) ? 32 : 50}
							height={_.isEmpty(rider_data?.details?.profile_pic) ? 32 : 50}
							style={!_.isEmpty(rider_data?.details?.profile_pic) ? { borderRadius: 25 } : {}}
						/>
					</div>
					<div style={{ marginLeft: 4, justifyContent: 'space-between', flex: 1 }} className='d-flex '>
						<div>
							<Text onClick={open_rider_modal} className={'cursor-pointer'} semi style={{ fontSize: 16, textDecoration: 'underline' }}>
								{_.get(rider_data, 'details.rider_name', '-')}
								<Text component={'span'} medium style={{ fontSize: 14, marginLeft: 4 }}>
									({_.get(rider_data, 'details.rider_id', '-')})
								</Text>
							</Text>
							<div className='d-flex align-items-center' style={{ marginTop: 4 }}>
								<img src={ImageLinks.call_filled} width={12} height={12} alt='' />
								<Text medium style={{ fontSize: 12, marginLeft: 4, color: theme.colors.darkGrey2 }}>
									{_.get(rider_data, 'details.mobile', '-')}
								</Text>
							</div>
						</div>
						<img className='cursor-pointer' src={ImageLinks.cross_purple} width={16} height={16} style={{ marginTop: 5 }} onClick={close} />
					</div>
				</div>

				{/* <div style={{ position: 'absolute', right: -20, top: 25 }}>
					<OutlinedButton style={{ marginTop: 3, marginLeft: 3 }} className={classes.icon_btn} onClick={close}>
						<img src={ImageLinks.face_icon} width='13' height='14' alt='' />
					</OutlinedButton>
				</div> */}
				<div style={{ overflowY: 'auto' }}>
					<div className={classes.task_container}>
						<div>
							<Text semi style={{ fontSize: 16 }}>
								{rider_tasks.routes.length}
							</Text>
							<Text medium style={{ fontSize: 11, color: theme.colors.darkGrey2 }}>
								Tasks
							</Text>
						</div>
						<div style={{ marginLeft: 24 }}>
							<Text semi style={{ fontSize: 16 }}>
								{!_.isEmpty(drop_tasks) ? moment(drop_tasks[drop_tasks.length - 1].expected_time).format('D MMM `YY hh:mm a') : '-'}
							</Text>
							<Text medium style={{ fontSize: 11, color: theme.colors.darkGrey2 }}>
								Completion Time
							</Text>
						</div>
					</div>
					<div className={classes.update_list_container}>
						<Text bold className={classes.task_sequence}>
							Task Sequence
						</Text>
						{show_update_sequence ? (
							<ContainedButton className={classes.update_btn} onClick={handle_update_sequence}>
								<Text>Update</Text>
							</ContainedButton>
						) : (
							<div className='align-items-center cursor-pointer' onClick={handle_completed_task}>
								<img src={show_completed_tasks ? ImageLinks.eye_close : ImageLinks.eye_open} />
								<Text semi style={{ fontSize: 12, color: theme.colors.primary, textDecoration: 'underline', marginLeft: 4 }}>
									Completed
								</Text>
							</div>
						)}
					</div>

					{show_completed_tasks && (
						<div style={{ marginBottom: 4 }}>
							{_.map(completed_tasks, (task, key) => {
								return (
									<div key={`completed_task_${key}`} className={'align-items-center justify-content-center'}>
										<img src={ImageLinks.tick_circle_solid_gray} width={16} height={16} style={{ marginRight: 4 }} />
										<TaskCardListItem task={task} handle_reciever_click={() => handle_reciever_click(task)} />
									</div>
								);
							})}
						</div>
					)}

					<div style={{ paddingBottom: 10 }}>
						<DragDropContext onDragEnd={on_drag_end}>
							<Droppable droppableId='droppable'>
								{(provided, snapshot) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{_.map(rider_tasks.routes, (task, key) => {
											return (
												<Draggable key={key.toString()} draggableId={key.toString()} index={key}>
													{(provided, snapshot) => (
														<div
															onClick={() => {
																if (task.trip_type != 4) handle_card_click(task.trip_id);
															}}
															className={'align-items-center justify-content-center'}
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}>
															<Text bold className={classes.serial_number}>
																{key + 1}
															</Text>
															<TaskCardListItem
																task={task}
																handle_reciever_click={() => handle_reciever_click(task)}
																is_active={_.includes(selected_task_ids, task.trip_id)}
															/>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>
				</div>
				<div style={{ marginTop: 'auto' }}>
					<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
						Note: Click on order card to select for handover
					</Text>
					{selected_task_ids.length > 0 && (
						<div className='d-flex justify-content-center' style={{ gap: 10 }}>
							<ContainedButton
								style={{ borderRadius: 30 }}
								onClick={() => {
									set_show_rider_change_modal(true);
								}}>
								<Text semi style={{ fontSize: 12 }}>
									Change rider
								</Text>
							</ContainedButton>
							<img
								src={ImageLinks.cross_purple}
								height={32}
								width={32}
								alt='cross'
								style={{ cursor: 'pointer' }}
								onClick={() => set_selected_task_ids([])}
							/>
						</div>
					)}
				</div>
				{show_rider_change_modal && (
					<RiderChangeModal
						close={() => set_show_rider_change_modal(false)}
						on_confirm={handle_change_rider}
						details={{
							rider_id: _.get(rider_data, 'details.rider_id', '-'),
							rider_name: _.get(rider_data, 'details.rider_name', '-'),
							order_count: selected_task_ids.length,
						}}
					/>
				)}
			</div>
		</>
	);
};

export default SearchRiderModal;
