import { makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Text from 'modules/Text';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api_requests from 'resources/api_requests';
import CopyDetailsModal from '../CopyDetailsModal';
import Steps from '../Steps';
import TokenForm from '../TokenForm';
import Button from 'modules/Button';
import Routes from 'resources/Routes';

const useStyles = makeStyles((theme) => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', paddingBottom: 50 },
	header: { display: 'flex', justifyContent: 'space-between', overflow: 'auto', height: '85vh' },
	header_title: { fontSize: 22 },
	token_keys: { marginTop: 50, display: 'flex', flexDirection: 'column', gap: 30 },
	header_text: { fontSize: 22, textDecoration: 'underline', textDecorationColor: theme.colors.lightPurple, textUnderlineOffset: 5 },
	regenerate_password: { borderRadius: 30, height: '22', fontSize: 12, textDecoration: 'underline', color: theme.colors.primary, cursor: 'pointer' },
	button_flex: { display: 'flex', justifyContent: 'flex-end', gap: 8, position: 'fixed', bottom: 20, right: 20, zIndex: 2 },
	revoke_access: {
		'&:hover': {
			background: 'linear-gradient(180deg, #BC0000 0%, #620101 112.5%) !important',
			border: 'none !important',
		},
	},
}));

function EditToken({ match }) {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const { app_configs, selected_business_id: business_id_information } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
			selected_business_id: state.app.selected_business_id,
		}),
		shallowEqual,
	);
	const [token_data, set_token_data] = useState({});
	const [initial_data, set_initial_data] = useState({});
	const [is_read_only, set_is_read_only] = useState(true);
	const [show_confirm_revoke, set_show_confirm_revoke] = useState(false);
	const [show_confirm_regenerate, set_show_confirm_regenerate] = useState(false);
	const [show_confirm_discard, set_show_confirm_discard] = useState(false);
	const [show_confirm_save, set_show_confirm_save] = useState(false);
	const [show_copy_modal, set_show_copy_modal] = useState(false);
	const [show_confirm_saved_details, set_show_confirm_saved_details] = useState(false);
	const [show_confirm_regenerate_password, set_show_confirm_regenerate_password] = useState(false);
	const [username, set_username] = useState('');
	const [password, set_password] = useState('');
	const id = match.params.id;

	const business_configs = _.get(app_configs, `business_configs.${business_id_information}`, {});
	const parent_id = _.get(app_configs, `business_configs.${business_id_information}.parent_id`);
	const parent_config_whitelabel = _.get(app_configs, `parent_configs.${parent_id}.whitelabeling`, {});

	const update_token = async (is_regenerate = false) => {
		try {
			const res = await api_requests.update_token(token_data, id);
			if (res) {
				get_initial_data();
				set_is_read_only(true);
				if (is_regenerate) {
					set_username(_.get(res, 'data.vendor.username', ''));
					set_password(_.get(res, 'data.vendor.password', ''));
					set_show_copy_modal(true);
				}
			}
		} catch (err) {}
	};

	const regenerate_password = async () => {
		try {
			const res = await api_requests.update_token({ ...token_data, regenerate_password: true }, id);
			if (res) {
				get_initial_data();
				set_is_read_only(true);
				set_username(_.get(res, 'data.vendor.username', ''));
				set_password(_.get(res, 'data.vendor.password', ''));
				set_show_copy_modal(true);
			}
		} catch (err) {}
	};

	const revoke_access = async () => {
		try {
			const res = await api_requests.revoke_token_access(id);
			if (res) {
				get_initial_data();
				set_is_read_only(true);
			}
		} catch (err) {}
	};

	const get_initial_data = async () => {
		if (id)
			try {
				const res = await api_requests.get_single_token(id);
				if (res.data) {
					set_initial_data(_.get(res, 'data.vendor[0]', {}));
				}
			} catch (err) {}
	};

	useEffect(() => {
		get_initial_data();
	}, [id]);

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className='align-items-center'>
				<div className='page_back_btn' onClick={() => history.goBack()}>
					<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
				</div>
				<Text className='cursor-pointer' style={{ fontSize: 22 }} onClick={() => history.goBack()}>
					Integration Management
				</Text>
				<div className='header_separator'></div>
				<Text semi className='header_active_title'>
					Token Generation
				</Text>
			</div>

			<div className={classes.header} style={{ gap: 200, paddingBottom: 100 }}>
				<div style={{ flex: 6 }}>
					<div className={classes.token_keys}>
						<Text semi style={{ fontSize: 18 }}>
							Token Keys
						</Text>
						<Text style={{ fontSize: 16 }}>
							Username:
							<Text component={'span'} semi style={{ fontSize: 16 }}>
								{_.get(initial_data, 'username')}
							</Text>
							<img
								src={ImageLinks.copy_icon}
								onClick={() => navigator.clipboard.writeText(_.get(initial_data, 'username'))}
								style={{ height: 14, width: 14, cursor: 'pointer', marginLeft: 10 }}
							/>
						</Text>
						<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
							<Text style={{ fontSize: 16 }}>Password:</Text>
							<Button type='text' text='Regenerate Password' size='small' onClick={() => set_show_confirm_regenerate_password(true)} />

							<Text style={{ fontSize: 12 }}>(regenerating password will revoke existing token)</Text>
						</div>
					</div>
					<TokenForm set_values={set_token_data} data={initial_data} is_read_only={is_read_only} />
				</div>

				<div style={{ flex: 5 }}>
					<Steps />
				</div>
			</div>
			<div className={classes.button_flex}>
				{((parent_id && business_configs?.whitelabeling?.is_editable) || parent_config_whitelabel?.is_editable) && (
					<Button
						type='round'
						size='large'
						text='Add Whitelabelling'
						onClick={() => history.push(Routes.WHITELABELLING_SETTINGS.path + `?type=brand_color_logo&vendor_id=${id}`)}
					/>
				)}
				{is_read_only && !_.get(initial_data, 'is_revoked', false) && (
					<Button type='outlined' size='large' text='Edit Token' onClick={() => set_is_read_only(false)} />
				)}
				{!_.get(initial_data, 'is_revoked', false) && is_read_only && (
					<Button
						type='round'
						size='large'
						text='Revoke Access'
						className={classes.revoke_access}
						onClick={() => set_show_confirm_revoke(true)}
						style={{ borderColor: theme.colors.red, backgroundColor: theme.colors.red }}
					/>
				)}
				{_.get(initial_data, 'is_revoked', false) && (
					<Button type='text' size='small' text='Regenerate Token' onClick={() => set_show_confirm_regenerate(true)} />
				)}
				{!is_read_only && <Button type='outlined' size='large' text='Discard' onClick={() => set_show_confirm_discard(true)} />}
				{!is_read_only && <Button type='round' size='large' text='Save Changes' onClick={() => set_show_confirm_save(true)} />}
			</div>
			{show_confirm_revoke && (
				<ConfirmClose
					is_error_msg={true}
					on_confirm={revoke_access}
					headerTitle='Revoke Token Access'
					positive_btn_text='Revoke'
					close={() => set_show_confirm_revoke(false)}
					title='Token will be removed and blocked but username/password will remain same if needed to be reactivated later.'
				/>
			)}
			{show_confirm_regenerate && (
				<ConfirmClose
					on_confirm={() => update_token(true)}
					headerTitle='Regenerate Token'
					positive_btn_text='Regenerate'
					close={() => set_show_confirm_regenerate(false)}
					title='Regenerating a token will create new username and password. '
				/>
			)}
			{show_confirm_discard && (
				<ConfirmClose
					is_error_msg={true}
					on_confirm={() => {
						get_initial_data();
						set_is_read_only(true);
					}}
					headerTitle='Discard Changes'
					positive_btn_text='Discard'
					close={() => set_show_confirm_discard(false)}
					title='Are you sure you want to discard changes? No changes will be made to channel token.'
				/>
			)}
			{show_confirm_save && (
				<ConfirmClose
					is_save_change={true}
					on_confirm={() => update_token(false)}
					headerTitle='Save Changes'
					positive_btn_text='Save'
					close={() => set_show_confirm_save(false)}
					title='Are you sure you want to update channel configuration? Username and password will not change, only token will be refreshed.'
				/>
			)}
			{show_confirm_saved_details && (
				<ConfirmClose
					on_confirm={() => set_show_confirm_saved_details(false)}
					headerTitle='Are you sure you have saved credential details?'
					close={() => {
						set_show_copy_modal(true);
						set_show_confirm_saved_details(false);
					}}
					close_on_confirm={false}
					title='Pidge does not save passwords. Please make sure you have saved token password. '
				/>
			)}
			{show_copy_modal && (
				<CopyDetailsModal
					username={username}
					password={password}
					on_confirm={() => {
						set_show_copy_modal(false);
						set_show_confirm_saved_details(true);
					}}
				/>
			)}
			{show_confirm_regenerate_password && (
				<ConfirmClose
					on_confirm={() => regenerate_password()}
					headerTitle='Are you sure you want to regenerate password?'
					close={() => {
						set_show_confirm_regenerate_password(false);
					}}
					close_on_confirm={true}
					title='Old password will be removed and new password will have to be inputted with the channel.'
				/>
			)}
		</div>
	);
}

export default EditToken;
