import { ClickAwayListener, FormControl, FormControlLabel, FormLabel, makeStyles, Popover, Radio, RadioGroup, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import OutlinedTextField from 'modules/OutlinedTextField';
import Select from 'modules/Select';
import Text from 'modules/Text';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import api_requests from 'resources/api_requests';

const useStyles = makeStyles((theme) => ({
	main_container: {
		flex: 6,
		marginTop: 50,
		display: 'flex',
		flexDirection: 'column',
		gap: 25,
	},
	text_field: {
		width: '48%',
		marginTop: 20,
	},
	title_text: { color: theme.colors.black, fontSize: 16 },
	sub_heading_text: { color: theme.colors.darkGrey2, fontSize: 12 },
	divider: { height: '1px', width: '100%', backgroundColor: theme.colors.lightGrey2 },
	allocation_box: {
		padding: '8px 12px',
		width: 300,
		borderRadius: 30,
		border: `1px solid ${theme.colors.lightPurple}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	menu_item: {
		padding: 12,
		backgroundColor: theme.colors.white,
		width: '100%',
		cursor: 'pointer',
		width: 300,
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
		},
	},
	allocation_list_wrap: {
		maxHeight: '40vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	highlighted_text: {
		color: theme.colors.primary,
		textDecoration: 'underline',
		textUnderlineOffset: 3,
	},
}));

function TokenForm({ data = null, set_values, is_read_only = false }) {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const [channel, set_channel] = useState('');
	const [webhook_url, set_webhook_url] = useState('');
	const [webhook_pass, set_webhook_pass] = useState('');
	const [weight, set_weight] = useState('');
	const [order_volume, set_order_volume] = useState('');
	const [selected_allocation, set_selected_allocation] = useState(null);
	const [show_menu, set_show_menu] = useState(false);
	const [show_sub_menu, set_show_sub_menu] = useState(false);
	const [smart_allocations, set_smart_allocation] = useState([]);
	const [selected_allocation_id, set_selected_allocation_id] = useState(null);

	const menu_ref = useRef(null);
	const package_volume = [
		{ value: 225, label: 'XS', subtitle: '(Medicine)' },
		{ value: 450, label: 'S', subtitle: '(Tab)' },
		{ value: 900, label: 'M', subtitle: '(Laptop)' },
		{ value: 1800, label: 'L', subtitle: '(2 Shoe Boxes)' },
		{ value: 3600, label: 'XL', subtitle: '(4 Shoe Boxes)' },
	];

	useEffect(() => {
		get_smart_allocations();
	}, []);

	useEffect(() => {
		if (!_.isEmpty(data)) {
			set_channel(data.channel);
			set_webhook_url(_.get(data, 'meta_data.url', ''));
			set_webhook_pass(_.get(data, 'meta_data.token', ''));
			set_weight(data.weight_slab);
			set_order_volume(data.order_volume);
			if (data.smart_allocation_id) set_selected_allocation_id(data.smart_allocation_id);
			else set_selected_allocation('Manual Allocation');
			if (smart_allocations.length > 0) {
				_.forEach(smart_allocations, (allocation, index) => {
					if (allocation.id == data.smart_allocation_id) {
						set_selected_allocation(`Auto Allocation SA - ${index + 1}`);
					}
				});
			}
		}
	}, [data, smart_allocations]);

	useEffect(() => {
		set_values({
			channel,
			order_volume,
			smart_allocation_id: selected_allocation_id,
			...(weight && { weight_slab: weight }),
			...(webhook_pass && { webhook_pass: webhook_pass }),
			...(webhook_url && { webhook_url: webhook_url }),
		});
	}, [channel, order_volume, webhook_url, webhook_pass, weight, selected_allocation_id]);

	const get_smart_allocations = async () => {
		try {
			const res = await api_requests.get_smart_allocation_configs();
			set_smart_allocation(res.data);
		} catch (err) {}
	};

	const handle_manual_click = () => {
		set_selected_allocation_id(null);
		set_selected_allocation('Manual Allocation');
		set_show_menu(false);
		set_show_sub_menu(false);
	};

	return (
		<div className={classes.main_container}>
			<OutlinedTextField
				disabled={is_read_only}
				className={classes.text_field}
				variant='outlined'
				value={channel}
				onChange={(e) => set_channel(e.target.value)}
				label='Channel Name*'
			/>
			<div>
				<Text bold style={{ color: theme.colors.primary, fontSize: 18 }}>
					Configuration
				</Text>
				<Text style={{ color: theme.colors.darkGrey2, fontSize: 14 }}>Set defaults for efficient processing. These can be changed any time.</Text>
			</div>
			<div>
				<Text semi className={classes.title_text}>
					Allocation*
				</Text>
				<Text className={classes.sub_heading_text}>Select how orders are assigned for dispatch</Text>
				<div
					className={classes.allocation_box}
					ref={menu_ref}
					onClick={() => {
						if (!is_read_only) set_show_menu((prev) => !prev);
					}}>
					<Text medium style={{ color: selected_allocation ? theme.colors.primary : theme.colors.lightPurple }}>
						{selected_allocation ? selected_allocation : 'Select configuration'}
					</Text>
					<div>
						<img src={ImageLinks.arrow_up_circle_solid} style={{ transform: 'rotate(180deg)' }} />
					</div>
				</div>
				<Popover
					open={show_menu}
					anchorEl={menu_ref.current}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}>
					<ClickAwayListener
						onClickAway={() => {
							set_show_sub_menu(false);
							set_show_menu(false);
						}}>
						<div style={{ borderRadius: 5, boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)' }}>
							<div className={classes.menu_item} onClick={() => set_show_sub_menu(true)}>
								<div className='d-flex justify-content-between align-items-center'>
									<div>
										<Text medium>Auto Allocation</Text>
										<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
											Use AI to assign orders to delivery partner
										</Text>
									</div>
									<img src={ImageLinks.chevron_right_black} width={16} height={16} />
								</div>
							</div>
							<div className={classes.menu_item} onClick={handle_manual_click}>
								<Text medium>Manual Allocation</Text>
								<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Select delivery partner to fulfill order
								</Text>
							</div>
						</div>
					</ClickAwayListener>
				</Popover>
				<Popover
					open={show_sub_menu}
					anchorEl={menu_ref.current}
					style={{ marginLeft: 300 }}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}>
					<div className={classes.allocation_list_wrap} style={{ borderRadius: 5, boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)' }}>
						{_.map(smart_allocations, (allocation, index) => {
							return (
								<div
									className={classes.menu_item}
									key={`allocation_list_${index}`}
									onClick={() => {
										set_selected_allocation(`Auto Allocation SA - ${index + 1}`);
										set_selected_allocation_id(allocation.id);
									}}>
									<Text medium>SA - {index + 1}</Text>
									<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										{allocation.description}
									</Text>
								</div>
							);
						})}
					</div>
					<div className='d-flex' style={{ padding: '6px 12px', cursor: 'pointer' }} onClick={() => history.push('/settings/smart-allocation')}>
						<img src={ImageLinks.add_pruple_no_fill} />
						<Text semi className={classes.highlighted_text}>
							New Smart Allocation
						</Text>{' '}
					</div>
				</Popover>
			</div>
			<div className={classes.divider} />
			<div>
				<Text semi className={classes.title_text}>
					Minimum Threshold
				</Text>
				<Text className={classes.sub_heading_text}>Define the minimum default values in case of incomplete payloads</Text>
				<div style={{ display: 'flex', gap: 20 }}>
					<OutlinedTextField
						className={classes.text_field}
						disabled={is_read_only}
						variant='outlined'
						type='number'
						value={weight}
						onChange={(e) => set_weight(e.target.value)}
						label='Weight (g)'
					/>
					<div>
						<Text semi style={{ marginTop: 20, fontSize: 12, color: theme.colors.darkGrey }}>
							Package Volume*
						</Text>
						<Select
							containerStyles={{ width: 150, height: 35 }}
							menu_styles={{ width: 150 }}
							disabled={is_read_only}
							value={order_volume}
							options={package_volume.map((value) => {
								return {
									title: value.label,
									value: value.value,
									subtitle: value.subtitle,
								};
							})}
							subtitle_text_direction='row'
							handleChange={(e) => set_order_volume(e)}
						/>
					</div>
				</div>
			</div>

			<div className={classes.divider} />
			<div>
				<Text semi className={classes.title_text}>
					Webhook (Optional)
				</Text>
				<Text className={classes.sub_heading_text}>Input Webhook credentials to receive real-time statuses</Text>
				<div style={{ justifyContent: 'space-between', display: 'flex' }}>
					<OutlinedTextField
						className={classes.text_field}
						disabled={is_read_only}
						variant='outlined'
						value={webhook_url}
						onChange={(e) => set_webhook_url(e.target.value)}
						label='URL'
						color={'primary'}
						autoComplete='new-password'
					/>
					<OutlinedTextField
						className={classes.text_field}
						disabled={is_read_only}
						variant='outlined'
						value={webhook_pass}
						onChange={(e) => set_webhook_pass(e.target.value)}
						label='Auth Token'
						autoComplete='new-password'
						color={'primary'}
					/>
				</div>
			</div>
		</div>
	);
}

export default TokenForm;
