import React, { useEffect, useRef, useState, useMemo } from 'react';
import moment from 'moment';
import qs from 'qs';
import Text from 'modules/Text';
import theme from 'resources/theme';
import _ from 'lodash';
import { Checkbox, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import Filters from 'modules/Filters/Filters';
import constant from 'resources/constant';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from '../helper';
import utils from 'resources/utils';
import { useSelector, shallowEqual } from 'react-redux';
import FilterButton from 'modules/FilterButton';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import NetworkAvatar from 'modules/NetworkAvatar';
import Button from 'modules/Button';
import Helpcenter from 'modules/HelpCenter';
import NoResult from 'modules/NoResult';
import BulksActions from 'modules/BulkActions/BulksActions';
import { OPTIONS_MENU } from 'modules/BulkActions/helpers';
import Toast from 'modules/Toast';
import { hide_loader, show_loader } from 'actions/app';

const useStyles = makeStyles(() => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		marginTop: -60,
		paddingTop: 20,
	},
	ongoingOrdersDiv: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: 40,
		gap: 20,
		alignItems: 'center',
	},
	detailsTextContainer: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
		gap: 10,
	},
	pickedUpContainer: {
		overflowY: 'auto',
		flex: 1,
		marginTop: 10,
		marginBottom: 10,
	},
	pickedUpItem: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
	},
	orderDetailsClickabletext: {
		flex: 1,
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
	pagesContainer: {
		height: 60,
		marginBottom: 10,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		borderTop: `1px solid ${theme.colors.lightPurple}`,
	},
	pageItemDiv: {
		marginLeft: 20,
		marginRight: 20,
		width: 24,
		height: 24,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 12,
	},
	pageText: { color: theme.colors.primary, fontSize: 12 },
	setPageContainer: { display: 'flex', alignItems: 'center' },
	truncate: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 16,
	},
	filter_btn: {
		backgroundColor: theme.colors.white,
		marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	header_title_2: { flex: 2, color: theme.colors.darkGrey2, fontSize: 14 },
	table_item_wrap_2: { flex: 2, minWidth: 0 },
	page_nav_btn: {
		width: 24,
		height: 24,
		borderRadius: 30,
	},
	enabled_page_nav: { border: `1px solid ${theme.colors.primary}` },
	disabled_page_nav: { backgroundColor: theme.colors.lightGrey5, width: 26, height: 26 },
	wrapper_help_center: { position: 'fixed', bottom: 30, left: '50%', zIndex: 100 },
	bulk_action_wrapper: { position: 'absolute', right: 0, zIndex: 1198 },
	subtitle: { fontSize: 12, color: theme.colors.lightGrey6, marginTop: 2 },
}));

const OngoingOrders = ({ selected_master_zone }) => {
	const classes = useStyles();
	const { app_configs, selected_business_id } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
			selected_business_id: state.app.selected_business_id,
		}),
		shallowEqual,
	);
	const type_of_owner = utils._retrieveData(constant.TYPE_OF_OWNER).id;
	const owner_id = utils._retrieveData('owner_id');

	const history = useHistory();
	const dispatch = useDispatch();
	const filter_object = qs.parse(history.location.search.slice(1)).filter || {};

	const [selected_pb_id, set_selected_pb_id] = React.useState('');
	const [show_order_details, set_show_order_details] = React.useState(false);
	const [orders, set_orders] = React.useState({});
	const [all_filters, set_all_filters] = React.useState([]);
	const [show_filters, set_show_filters] = React.useState(false);
	const [refresh, set_refresh] = React.useState(false);
	const [order_list, set_order_list] = React.useState([]);
	const [count, set_count] = useState(0);
	const [selected_trip, set_selected_trip] = React.useState([]);
	const [show_toast, set_show_toast] = React.useState(false);
	const [toast_msg, set_toast_msg] = React.useState('');
	const slider_ref = React.useRef();
	const loading_scroll = useRef(false);

	useEffect(() => {
		set_all_filters([]);
		api_requests.get_ongoing_order_filters(selected_master_zone).then((res) => set_all_filters(res.data.filters));
	}, [selected_master_zone]);

	useEffect(() => {
		set_selected_trip([]);
		get_orders({});
	}, [JSON.stringify(filter_object), refresh]);

	const get_orders = ({ token }) => {
		set_selected_trip([]);
		dispatch(show_loader());
		api_requests.get_ongoing_orders_search({ ...filter_object, ...(token && { token }) }).then((res) => {
			loading_scroll.current = false;
			set_orders(res.data);
			dispatch(hide_loader());
			if (token) {
				set_order_list(_.concat(order_list, res.data.data));
			} else {
				set_count(res.data.count);
				set_order_list(res.data.data);
			}
		});
	};

	const handle_export = async (filters, callback) => {
		try {
			const res = await api_requests.export_all_orders_pwp({ ...filters }, 'ongoing');
			utils.downloadBlob(res, 'orders.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && orders.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_orders({ token: orders.next });
		}
	};

	const handle_select_all_order = () => {
		if (selected_trip.length === order_list.length) {
			set_selected_trip([]);
		} else {
			const select_all = _.map(order_list, (trip) => {
				return { trip_id: trip.trip_id, rider: trip.rider };
			});
			set_selected_trip(select_all);
		}
	};

	const handle_select_order = (order) => {
		const index = _.findIndex(selected_trip, (trip) => trip.trip_id === order.trip_id);
		const _selected_trip = [...selected_trip];

		if (index === -1) {
			_selected_trip.push({ trip_id: order.trip_id, rider: order.rider });
		} else {
			_selected_trip.splice(index, 1);
		}

		set_selected_trip(_selected_trip);
	};

	return (
		<div className={classes.container}>
			<div className={classes.ongoingOrdersDiv}>
				<div style={{ display: 'flex', height: 35, alignItems: 'center', position: 'absolute', marginTop: -150 }}>
					<Button
						type='round'
						text='Track Orders'
						size='large'
						onClick={() => {
							utils.track_event_for_analytics(constant.TRACKING_EVENTS.PP_TRACKORDERS);
							history.push(Routes.TRACKING.path);
						}}
						left_icon={ImageLinks.arrow_icon_white}
					/>
				</div>
				<div className='d-flex align-items-center' style={{ gap: 5, marginTop: -10 }}>
					<Text semi component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						Orders :{' '}
					</Text>
					<Text semi component={'span'} style={{ fontSize: 14 }}>
						{count}
					</Text>
				</div>
				<div style={{ marginRight: 10 }}>
					<FilterButton
						handle_click={() => set_show_filters(true)}
						total_filters={Object.keys(filter_object).length}
						wrapper_styles={{ marginBottom: 15 }}
						track_event={{ event: 'PP_ongoing_filter', properties: filter_object }}
					/>
				</div>
			</div>
			<div className={classes.detailsTextContainer}>
				<div style={{ width: 50 }}>
					{order_list && order_list.length !== 0 && (
						<Checkbox
							size='small'
							checked={selected_trip.length === order_list.length}
							onClick={handle_select_all_order}
							style={{ color: theme.colors.lightPurple2, padding: 0 }}
						/>
					)}
				</div>
				<Text bold style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
					Order ID
				</Text>
				<Text bold className={classes.header_title_2}>
					Customer Details
				</Text>
				<Text bold className={classes.header_title_2}>
					Picked Up on
				</Text>
				<Text bold className={classes.header_title_2}>
					Expected Delivery
				</Text>

				<Text bold className={classes.header_title_2}>
					Allocation Details
				</Text>
				<Text bold className={classes.header_title_2}>
					Status
				</Text>
				{selected_trip.length > 0 && (
					<div className={classes.bulk_action_wrapper}>
						<BulksActions
							on_update={() => get_orders({})}
							option={[OPTIONS_MENU.chnges_rider]}
							selected_order_ids={selected_trip}
							set_selected_order_ids={set_selected_trip}
							set_show_toast={set_show_toast}
							set_toast_msg={set_toast_msg}
						/>
					</div>
				)}
			</div>

			<div className={classes.pickedUpContainer} ref={slider_ref} onScroll={slider_scrolled}>
				{_.map(order_list, (order, key) => {
					const show_network_logo =
						order.supplier_id != null &&
						order.supplier_id != owner_id &&
						order.fulfillment_network_id != '-2' &&
						order.fulfillment_network_id != '-1';

					return (
						<div key={`order${key}`} className={classes.pickedUpItem}>
							<div style={{ width: 50 }}>
								<Checkbox
									size='small'
									checked={_.some(selected_trip, (trip) => trip.trip_id == order.trip_id)}
									onClick={() => handle_select_order(order)}
									style={{ color: theme.colors.lightPurple2, padding: 0 }}
								/>
							</div>
							<div style={{ flex: 1, minWidth: 0, position: 'relative' }}>
								{order.business_id != selected_business_id && (
									<img src={ImageLinks.network_light_purple} width={20} height={20} style={{ position: 'absolute', left: -25, top: 1 }} />
								)}
								<Text
									semi
									className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
									onClick={() => {
										utils.track_event_for_analytics(constant.TRACKING_EVENTS.PP_OG_ORDER_CLICK);
										set_selected_pb_id(order.external_order_id);
										set_show_order_details(true);
									}}>
									{order.mcp_id}
								</Text>
								<Text className={classes.subtitle}>{order.reference_id || order?.dd_channel?.order_id || order.id || '-'}</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{order.customer_name || '-'}
								</Text>
								<Text className={classes.subtitle}>{`${order.customer_mobile} | ${order.customer_pincode}`}</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{order.pickup ? moment(order.pickup).format('DD-MM-YY') : '-'}
								</Text>
								<Text className={classes.subtitle}>{order.pickup ? moment(order.pickup).format('hh:mm A') : '-'}</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{order.drop ? moment(order.drop).format('DD-MM-YY') : '-'}
								</Text>
								<Text className={classes.subtitle}>{order.drop ? moment(order.drop).format('hh:mm A') : '-'}</Text>
							</div>

							<div className={classes.table_item_wrap_2}>
								{show_network_logo ? (
									<div className='d-flex align-items-center' style={{ gap: 5 }}>
										<div style={{ minWidth: 24 }}>
											<NetworkAvatar
												configs={_.get(app_configs, `network_configs.${order.fulfillment_network_id}.avatar.configs`, [])}
												height={24}
												width={24}
												image={_.get(app_configs, `network_configs.${order.fulfillment_network_id}.avatar.image`, '')}
											/>
										</div>
										<div>
											<Text bold>
												{_.get(app_configs, `network_configs.${order.fulfillment_network_id}.name`, [])} ({order.fulfillment_network_id}
												)
											</Text>
										</div>
									</div>
								) : selected_business_id === _.get(order, 'rider.owner_id', '') && type_of_owner === _.get(order, 'rider.owner_type', '') ? (
									order.bundle_label ? (
										<Text
											semi
											onClick={
												order?.status_id < 130
													? () => history.push(Routes.GROUPING_AND_ALLOCATION.path + `?type=active&bundle_id=${order.bundle_id}`)
													: () => {}
											}
											className={clsx(order?.status_id < 130 && classes.orderDetailsClickabletext, classes.truncate)}
											style={order?.status_id < 130 ? { color: theme.colors.primary, cursor: 'pointer' } : {}}>
											{order.bundle_label}
										</Text>
									) : (
										<Text>-</Text>
									)
								) : (
									<img src={ImageLinks.logo} height={20} />
								)}
								<Text className={classes.subtitle}>
									{_.get(order, 'rider.name') ? `${_.get(order, 'rider.name')} (${_.get(order, 'rider.id')})` : '-'}
								</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{constant.TRIP_STATUS_TEXT[order.status_id]}
								</Text>
								<Text className={classes.subtitle}>{constant.ATTEMPT_TYPE_TEXT[order.attempt_type] || '-'}</Text>
							</div>
						</div>
					);
				})}
				{/* {order_list.length < 8 && (
					<div className={classes.wrapper_help_center}>
						<Helpcenter
							text='How can I power my orders with Pidge?'
							url='https://pidge.in/helpcenter-order-poweredwithpidge'
							style={{ marginTop: 40 }}
						/>
					</div>
				)} */}
				{order_list.length == 0 && Object.keys(filter_object).length > 0 && (
					<div className='d-flex justify-content-center align-items-center' style={{ marginTop: '15%', flexDirection: 'column', gap: 10 }}>
						<NoResult text='No order found' btn_onclick={() => history.replace({ search: '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.OnGround.id })} />
					</div>
				)}
			</div>
			{show_order_details && (
				<OrderModal
					updater={() => {
						set_refresh((val) => !val);
					}}
					id={selected_pb_id}
					close={() => set_show_order_details(false)}
				/>
			)}
			{show_filters && (
				<Filters
					all_filters={all_filters}
					existing_filters={filter_object}
					set_selected_filters={(filters) => {
						history.push(
							Routes.POWERED_WITH_PIDGE.path + `?type=${POWERED_WITH_PIDGE_MENU_ITEMS.OnGround.id}&` + qs.stringify({ filter: filters }),
						);
					}}
					close={() => set_show_filters(false)}
					title='On-going'
					handle_export={handle_export}
				/>
			)}
			{show_toast && (
				<Toast close_callback={() => set_show_toast(false)}>
					<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
					<Text component={'span'} semi style={{ marginLeft: '10px', fontSize: 14 }}>
						{toast_msg}
					</Text>
				</Toast>
			)}
		</div>
	);
};

export default OngoingOrders;
