import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import Select from 'modules/Select';
import UndeliveredOrdersInfo from 'views/NetworksFlow/CreateNetwork/components/UndeliveredOrders/UndeliveredOrdersInfo';
import SectionCta from '../SectionCta';
import CreateNetworkContext from 'views/NetworksFlow/context';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Switch from 'modules/Switch';

const useStyles = makeStyles(() => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column' },
	content_wrap: { gap: 20, display: 'flex', flex: 1, paddingLeft: 16 },
	input_wrap: {
		display: 'flex',
		flexDirection: 'column',
		flex: 0.55,
		overflow: 'hidden',
	},
	output_wrap: { display: 'flex', flexDirection: 'column', flex: 0.45, marginTop: -60 },
	input_section: {
		marginTop: 8,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey7}`,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		borderRadius: 14,
	},
	separator: { border: `1px solid ${theme.colors.lightGrey5}`, marginTop: 8 },
	output_section: {
		padding: 16,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 16px rgba(102, 102, 102, 0.1)`,
		borderRadius: 12,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	notes_wrapper: {
		backgroundColor: theme.colors.lightPink4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 28,
		borderBottomLeftRadius: 12,
		borderBottomRightRadius: 12,
	},
	menu_item_class: {
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
		},
	},
	label_subtitle: { fontSize: 12, color: theme.colors.darkGrey2 },
	reattempt_button: {
		display: 'flex',
		width: '87.5px',
		padding: '6.667px 6.667px 6.667px 10px',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 30,
		border: `0.833px solid ${theme.colors.lightPurple6}`,
	},
	reattempt_info: { display: 'flex', padding: '0px 20px', justifyContent: 'space-between', alignItems: 'flex-start' },
	label_subtitle: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 },
	output_section_info_1: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 8, marginBottom: 30 },
	output_section_info_box: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 4 },
	info_box: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: 4,
		padding: 10,
		border: `1px solid ${theme.colors.lightPink9}`,
		borderRadius: 6,
		background: theme.colors.white,
		textAlign: 'center',
	},
}));

const ACTION_ON_FAILED_DELIVERY = [
	{ title: 'Return to Sender', subtitle: 'Package will be sent to the sender', value: true },
	{ title: 'Reattempt', subtitle: 'If you or your supplier do not have a warehouse then order will return to sender', value: false },
];

const UndeliveredOrders = ({ handle_data_updates }) => {
	const classes = useStyles();
	const {
		handle_reattempt_config_api,
		reattempt_config_data,
		set_reattempt_config_data,
		invite_network_config,
		set_confirm_close_modal,
		invite_network_id,
		is_invite_reattempt_config,
		set_is_invite_reattempt_config,
		edit_network_id,
	} = useContext(CreateNetworkContext);
	const [is_custom, set_is_custom] = useState(_.get(invite_network_config, 'ownership.reattempt_config', '') == 'user');
	const [show_change_modal, set_show_change_modal] = useState(false);
	const [reattempt_config, set_reattempt_config] = useState({ number_of_reattempts: 0, return_to_sender: true });

	useEffect(() => {
		set_reattempt_config_initial_data();
	}, []);

	const handle_custom_support = (e) => {
		if (e) {
			set_show_change_modal(true);
			return;
		}
		if (!e) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Exit Custom Rule Setup',
				title: `Are you sure you want to abandon custom rules setup for reattempt config? Rules will reset to default.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				is_error_msg: false,
				show_negative_btn: true,
				confirm_acion: () => {
					set_values_to_default();
				},
			});
		}
	};

	const set_values_to_default = () => {
		set_is_custom(false);
		set_reattempt_config({ number_of_reattempts: 0, return_to_sender: true });
	};

	const set_reattempt_config_initial_data = () => {
		if (_.isEmpty(reattempt_config_data)) return;

		set_reattempt_config(reattempt_config_data);
	};
	const handle_save_info = () => {
		if (invite_network_id || edit_network_id) {
			set_is_invite_reattempt_config(true);
		}
		set_reattempt_config_data(reattempt_config);
	};

	const handle_select_action = (val) => {
		const _reattempt_config = _.cloneDeep(reattempt_config);
		if (val) {
			_reattempt_config.return_to_sender = val;
			_reattempt_config.number_of_reattempts = 0;
		} else {
			_reattempt_config.return_to_sender = val;
			_reattempt_config.number_of_reattempts = 1;
		}
		set_reattempt_config(_reattempt_config);
	};

	const is_data_updated = useMemo(() => {
		let value = false;
		if (
			reattempt_config?.return_to_sender != reattempt_config_data?.return_to_sender ||
			reattempt_config?.number_of_reattempts != reattempt_config_data?.number_of_reattempts
		) {
			value = true;
		}
		handle_data_updates(6, value);
		return value;
	}, [reattempt_config, reattempt_config_data]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.content_wrap}>
				<div className={classes.input_wrap}>
					<div className='d-flex justify-content-between'>
						<div className='d-flex' style={{ flexDirection: 'column', width: invite_network_id ? '70%' : '' }}>
							<Text semi style={{ fontSize: 16, color: theme.colors.darkGrey7 }}>
								Undelivered Orders
							</Text>
							<Text medium style={{ fontSize: 12, marginTop: 4, color: theme.colors.darkGrey2 }}>
								Manage what happens when orders are not delivered to the end customer.
							</Text>
						</div>
						<div>
							{invite_network_id && (
								<div className='d-flex align-items-center' style={{ justifyContent: 'flex-end' }}>
									<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Custom Rule
									</Text>
									<Switch
										disabled={_.get(invite_network_config, 'ownership.reattempt_config', '') == 'user'}
										checked={is_custom}
										onChange={(e) => {
											handle_custom_support(e.target.checked);
										}}
									/>
								</div>
							)}
						</div>
					</div>

					<div className={classes.input_section}>
						<div style={{ padding: 16 }}>
							<div className='d-flex align-items-center' style={{ marginTop: 10, gap: 55 }}>
								<div>
									<Text medium style={{ fontSize: 14 }}>
										Action on Failed Deliveries
									</Text>
									<Text medium className={classes.label_subtitle}>
										Select what happens when an order is not delivered to the customer
									</Text>
								</div>
								<Select
									disabled={invite_network_id && !is_custom}
									value={reattempt_config?.return_to_sender}
									defaultOption={{
										title: 'Select Action',
										value: '',
									}}
									options={ACTION_ON_FAILED_DELIVERY}
									handleChange={(val) => handle_select_action(val)}
									selected_title_styles={{ color: theme.colors.primary }}
									containerStyles={{ height: 35, width: '63%', borderColor: theme.colors.lightPurple }}
									menu_item_class={{ className: classes.menu_item_class }}
									menu_styles={{ width: '16%' }}
								/>
							</div>
							{!reattempt_config?.return_to_sender && (
								<div className='align-items-center justify-content-between' style={{ marginTop: 30 }}>
									<div>
										<Text medium style={{ fontSize: 14 }}>
											No. of re-attempts allowed
										</Text>
										<Text medium className={classes.label_subtitle}>
											Define how many times delivery attempts can be made
										</Text>
									</div>
									<div className={classes.reattempt_button}>
										<img
											src={ImageLinks.minus_simple_primary}
											style={{ cursor: 'pointer', pointerEvents: invite_network_id && !is_custom ? 'none' : 'auto' }}
											onClick={() =>
												set_reattempt_config({
													...reattempt_config,
													number_of_reattempts:
														reattempt_config?.number_of_reattempts > 1
															? reattempt_config?.number_of_reattempts - 1
															: reattempt_config?.number_of_reattempts,
												})
											}
										/>
										<Text semi style={{ color: theme.colors.primary, fontSize: 14 }}>
											{reattempt_config?.number_of_reattempts}
										</Text>
										<img
											src={ImageLinks.plus_simple_primary}
											style={{ cursor: 'pointer', pointerEvents: invite_network_id && !is_custom ? 'none' : 'auto' }}
											onClick={() =>
												set_reattempt_config({
													...reattempt_config,
													number_of_reattempts: reattempt_config?.number_of_reattempts + 1,
												})
											}
										/>
									</div>
								</div>
							)}
						</div>
						<div className={classes.notes_wrapper}>
							<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Note: Settings can be customized at invite level
							</Text>
						</div>
					</div>
				</div>
				<div className={classes.output_wrap}>
					<div className={classes.output_section}>
						<div className={classes.output_section_info_1}>
							<Text semi style={{ fontSize: 14, color: theme.colors.lightPurple5 }}>
								What can happen to undelivered orders?
							</Text>
							<div className={classes.output_section_info_box}>
								<Text medium className={classes.label_subtitle}>
									The delivery executive can do one of the following 2options:
								</Text>
								<div className='d-flex align-items-start' style={{ gap: 10 }}>
									<div className={classes.info_box}>
										<Text semi style={{ fontSize: 14, color: theme.colors.lightPurple7 }}>
											Return to Sender
										</Text>
										<Text medium className={classes.label_subtitle}>
											Package is sent back to the pickup location.
										</Text>
									</div>
									<div className={classes.info_box}>
										<Text semi style={{ fontSize: 14, color: theme.colors.lightPurple7 }}>
											Reattempt Order
										</Text>
										<Text medium className={classes.label_subtitle}>
											Packages is attempted again for delivery before final RTO.
										</Text>
									</div>
								</div>
							</div>
						</div>
						<UndeliveredOrdersInfo />
					</div>
				</div>
			</div>
			{show_change_modal && (
				<ConfirmClose
					headerTitle='Set Custom Reattempt Config'
					title='Are you sure you want to set custom custom reattempt config for this participant? All default config will not be considered.'
					show_negative_btn={true}
					close={() => set_show_change_modal(false)}
					on_confirm={() => set_is_custom(true)}
				/>
			)}
			<SectionCta
				title={`Save Undelivered Orders`}
				is_data_updated={is_data_updated}
				is_disabled={false}
				handle_click={handle_save_info}
				total_steps={1}
				lower_section_index={7}
				handle_data_updates={handle_data_updates}
				complete_btn_title={'Setup Undelivered Orders'}
				handle_api_save={handle_reattempt_config_api}
			/>
		</div>
	);
};
export default UndeliveredOrders;
