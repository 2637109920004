import React, { useContext } from 'react';
import Text from 'modules/Text';
import _ from 'lodash';
import OutlinedButton from 'modules/OutlinedButton';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/core';
import CreateOrderContext from '../context';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	title: {
		fontSize: 22,
		paddingBottom: 5,
		color: theme.colors.black,
	},
	repeeat_order_btn: {
		borderRadius: 30,
		border: `1px solid ${theme.colors.primary}`,
		height: 38,
		padding: '8px 12px',
		backgroundColor: theme.colors.white,
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
		},
	},
}));

const Header = () => {
	const { handle_repeat_order_press } = useContext(CreateOrderContext);
	const classes = useStyles();

	return (
		<div className='d-flex justify-content-between' style={{ marginBottom: 32 }}>
			<Text semi className='global_header_text'>
				Create Order
			</Text>
			<Button type='outlined' text='Repeat Orders' size='large' onClick={handle_repeat_order_press} />
		</div>
	);
};

export default Header;
