import React, { useEffect, useMemo, useRef } from 'react';
import Text from 'modules/Text';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import ContainedButton from 'modules/ContainedButton';
import api_requests from 'resources/api_requests';
import { useDispatch } from 'react-redux';
import Register from './components/Register';
import { ListItem, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import _ from 'lodash';
import { get_user_details } from 'actions/app';
import utils from 'resources/utils';
import RegisterBusiness from './components/RegisterBusiness';
import PendingInvites from './components/PendingInvites';
import constant from 'resources/constant';
import OutlinedTextField from 'modules/OutlinedTextField';
import Footer from './components/Footer';
import BusinessList from './components/BusinessList';
import SelectType from './components/SelectType';
import Button from 'modules/Button';
import { toast } from 'react-toastify';
import Routes from 'resources/Routes';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.darkGrey2,
		},

		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.primary,
		},

		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			color: theme.colors.black,
		},

		'& .MuiInputLabel-outlined': {
			color: theme.colors.darkGrey2,
		},

		'& .MuiInputLabel-outlined.Mui-focused': {
			color: theme.colors.primary,
		},

		'& .MuiInputLabel-outlined.Mui-error': {
			color: theme.colors.red2,
		},
		'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.red2,
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: `translate(20px, -6px) scale(0.75)`,
		},
	},

	helper_text: {
		color: `${theme.colors.red2} !important`,
		fontSize: 12,
	},
	wrapper: { height: '100%', width: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden' },
	login_form: { width: '50%', height: '100%' },

	forgot_pass_text: { marginLeft: 20, color: '#766F83', fontSize: 18, cursor: 'pointer' },
	cta_btn: { width: 101, display: 'flex', alignItems: 'center' },
	cta_btn_wrapper: { marginTop: 30, display: 'flex', alignItems: 'center' },

	left_wrapper: {
		flex: 1,
		height: '100%',
		paddingTop: '4%',
		paddingLeft: '7%',
	},
	img_wrapper: {
		display: 'flex',
		// justifyContent: 'center',
		// alignItems: 'center',
		marginTop: 30,
		overflow: 'auto',
		height: '80vh',
	},
	scroll_imgs: {
		// animation: `$myEffectExit 20000ms`,
		// animationIterationCount: 'infinite',
	},
	'@keyframes myEffectExit': {
		'0% 100%': {
			transform: 'translateY(0)',
		},
		'50%': {
			transform: 'translateY(-100%)',
		},
	},
}));

const SCREENS = {
	login: 'login',
	register: 'register',
	forgot_password: 'forgot_password',
	select_type: 'select_type',
	business_enquiry: 'business_enquiry',
	pending_invites: 'pending_invites',
	business_list: 'business_list',
	select_type: 'select_type',
};

const Login = () => {
	const history = useHistory();
	const query_object = qs.parse(history.location.search.slice(1));
	const [mobile, set_mobile] = React.useState('');
	const [password, set_password] = React.useState('');
	const [otp, set_otp] = React.useState('');

	const [is_login, set_is_login] = React.useState(false);
	const [is_signup, set_is_signup] = React.useState(false);
	const [is_forgot_password, set_is_forgot_password] = React.useState(false);

	const [login_details, set_login_details] = React.useState({});

	const [register_data, set_register_data] = React.useState({});
	const [timer, set_timer] = React.useState(null);

	const [screen, set_screen] = React.useState(SCREENS.login);
	const [pending_invites, set_pending_invites] = React.useState([]);
	const [login_failed, set_login_failed] = React.useState(false);
	const [otp_failed, set_otp_failed] = React.useState(false);
	const [business_type_list, set_business_type_list] = React.useState([]);
	const [show_pass, set_show_pass] = React.useState(false);
	const timer_ref = useRef(null);
	const classes = useStyles();

	const dispatch = useDispatch();

	const _onEnterMobile = (e) => {
		let val = e.target.value;
		if (val.length <= 10) {
			set_mobile(val);
			set_is_login(false);
			set_is_signup(false);
			if (val.length === 10) {
				api_requests.auth_status({ mobile: val }).then((res) => {
					if (res.data.is_password_login_enabled) {
						set_is_login(true);
					} else {
						set_is_signup(true);
						_sendOTP(val);
						set_register_data({
							mobile: val,
							email: '',
							first_name: '',
							last_name: '',
						});
						set_screen(SCREENS.register);
					}
				});
			}
		}
	};

	const _sendOTP = (val = mobile) => {
		api_requests
			.request_otp({
				mobile: val,
			})
			.then(() => {
				set_otp('');
				set_timer(20);
			});
	};

	useEffect(() => {
		const auth_token = utils._retrieveData(constant.AUTH_TOKEN);
		if (auth_token) {
			history.push('/');
		}
	}, []);

	useEffect(() => {
		return () => {
			clearTimeout(timer_ref.current);
		};
	}, []);

	useEffect(() => {
		if (timer > 0) {
			timer_ref.current = setTimeout(() => {
				set_timer(timer - 1);
			}, 1000);
		}
	}, [timer]);

	const set_login = (mobile) => {
		set_mobile(mobile);
		set_password('');
		set_otp('');
		set_is_login(true);
		set_screen(SCREENS.login);
	};

	const on_footer_auth_nav = () => {
		clearTimeout(timer_ref.current);
		set_is_login(false);
		set_is_signup(false);
		set_login_failed(false);
		set_otp_failed(false);
		set_is_forgot_password(false);
		set_mobile('');
		set_password('');
		set_otp('');
		set_login_details({});
		set_register_data({});
		set_screen(SCREENS.login);
	};

	const on_accept_all_invites = async () => {
		set_pending_invites([]);
		const existing_mobile = is_login ? login_details.info.mobile : register_data.mobile;
		const existing_password = is_login ? password : register_data.password;
		try {
			if (!is_login) localStorage.setItem('new_user', true);
			const res = await api_requests.login({ mobile: existing_mobile, password: existing_password });
			set_is_login(true);
			set_is_signup(false);
			set_login_failed(false);
			utils._storeData(constant.NAME, res.data.info.first_name + ' ' + res.data.info.last_name);
			utils._storeData(constant.MOBILE, res.data.info.mobile);
			utils._storeData(constant.USER_ID, res.data.info.id);
			utils._storeData(constant.USER_EMAIL, res.data.info.meta_info.email);
			set_login_details(res.data);
			if (
				_.get(res.data, 'info.is_aggregator') ||
				_.get(res.data, 'info.is_business') ||
				_.get(res.data, 'info.is_manpower') ||
				_.get(res.data, 'info.is_vendor')
			) {
				check_if_show_user_type_screen(res.data);
			} else set_screen(SCREENS.business_enquiry);
		} catch (error) {
			set_login_failed(true);
		}
	};

	const check_if_show_user_type_screen = (data) => {
		let count = 0;
		let business_list = [];
		const type = {};
		if (_.get(data, 'info.is_aggregator')) {
			count += 1;
			type['title'] = 'Aggregator';
			type['id'] = 6;
			business_list = _.get(data, 'info.aggregator_info', []);
		}
		if (_.get(data, 'info.is_business')) {
			count += 1;
			type['title'] = 'Business';
			type['id'] = 4;
			business_list = _.get(data, 'info.business_info', []);
		}
		if (_.get(data, 'info.is_manpower')) {
			count += 1;
			type['title'] = 'Manpower';
			type['id'] = 2;
			business_list = _.get(data, 'info.supply_manpower_info', []);
		}
		if (_.get(data, 'info.is_vendor')) {
			count += 1;
			type['title'] = 'Vendor';
			type['id'] = 3;
			business_list = _.get(data, 'info.supply_vendor_info', []);
		}

		if (count > 1 || business_list.length > 1) set_screen(SCREENS.select_type);
		else on_select_business(business_list[0], data.token);
	};

	const get_invites_list = async (token) => {
		try {
			const invites_response = await api_requests.get_pending_invites(token);
			return invites_response;
		} catch (error) {}
	};

	const _proceed = async (e) => {
		e.preventDefault();
		if (mobile.length != 10) {
			toast.error('10 digit number accepted only, if testing, please use testing credentials provided', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}
		if (is_login) {
			try {
				const res = await api_requests.login({ mobile, password });
				set_login_failed(false);
				utils._storeData(constant.NAME, res.data.info.first_name + ' ' + res.data.info.last_name);
				utils._storeData(constant.MOBILE, res.data.info.mobile);
				utils._storeData(constant.USER_ID, res.data.info.id);
				utils._storeData(constant.USER_EMAIL, res.data.info.meta_info.email);
				utils._storeData(constant.TYPE_OF_USER, res.data.info.user_type);
				localStorage.setItem(constant.LOCAL_STORAGE_KEYS.AGGREGATOR_INFO, JSON.stringify(_.get(res, 'data.info.aggregator_info', [])));
				set_login_details(res.data);

				// const invites_response = await api_requests.get_pending_invites(res.data.token);
				const invites_response = await get_invites_list(res.data.token);
				if (invites_response.success && _.get(invites_response, 'data.items', []).length > 0) {
					set_screen(SCREENS.pending_invites);
					set_pending_invites(invites_response.data.items);
					return;
				}
				if (
					_.get(res.data, 'info.is_aggregator') ||
					_.get(res.data, 'info.is_business') ||
					_.get(res.data, 'info.is_manpower') ||
					_.get(res.data, 'info.is_vendor')
				) {
					check_if_show_user_type_screen(res.data);
				} else set_screen(SCREENS.business_enquiry);
			} catch (error) {
				set_login_failed(true);
			}
		} else {
			set_otp_failed(false);
			api_requests
				.verify_otp({
					mobile: mobile,
					otp: otp,
					above18_fair_use: true,
				})
				.then((res) => {
					set_register_data({
						mobile: mobile,
						token: res.access_token,
						email: res.email,
						first_name: res.first_name,
						last_name: res.last_name,
						signup: res.signup,
						...(is_forgot_password && { step: 2 }),
					});
					set_screen(SCREENS.register);
				})
				.catch((err) => {
					set_otp_failed(true);
				});
		}
	};

	const handle_forgot_password = () => {
		set_is_forgot_password(true);
		set_is_login(false);
		_sendOTP();
	};

	const handle_signup_success = async (data) => {
		set_otp('');
		if (is_forgot_password) {
			set_screen(SCREENS.login);
			set_is_login(true);
			set_is_forgot_password(false);
			set_password('');
			return;
		}
		utils._storeData(constant.USER_ID, data.id);
		utils._storeData(constant.USER_EMAIL, data.email);
		set_register_data((prev) => ({ ...prev, ...data }));
		const invites_response = await get_invites_list(data.token);
		if (invites_response.success && _.get(invites_response, 'data.items', []).length > 0) {
			set_screen(SCREENS.pending_invites);
			set_pending_invites(invites_response.data.items);
			return;
		}
		set_screen(SCREENS.business_enquiry);
	};

	const handle_select_type = (type, data = null) => {
		utils.setRequestAuthHeader(data && data.token ? data.token : login_details.token, type);
		dispatch(get_user_details()).then(() => {
			if (!_.isEmpty(query_object.shopify_token)) {
				history.push(Routes.SHOPIFY_AUTH.path + `?shopify_token=${query_object.shopify_token}&is_login=${query_object.is_login}`);
			} else {
				history.replace('/');
				window.history.pushState(null, null, '/');
			}
		});
	};

	const handle_type_options = (type) => {
		let list = null;
		localStorage.removeItem(constant.LOCAL_STORAGE_KEYS.AGG_BIZ_LIST);
		if (type.id === 2) list = login_details.info.supply_manpower_info;
		if (type.id === 3) list = login_details.info.supply_vendor_info;
		if (type.id === 4) {
			list = login_details.info.business_info;
			localStorage.setItem(constant.LOCAL_STORAGE_KEYS.AGG_BIZ_LIST, JSON.stringify(login_details.info.business_info));
		}
		if (type.id === 6) {
			list = login_details.info.aggregator_info;
			localStorage.setItem(constant.LOCAL_STORAGE_KEYS.AGG_BIZ_LIST, JSON.stringify(login_details.info.business_info));
		}
		set_business_type_list(list);
		set_screen(SCREENS.business_list);
	};

	const on_select_business = (business, token = null) => {
		utils._storeData(constant.SELECTED_BUSINESS, business);
		const owner_text = constant.OWNER_TYPE_TEXT[business.type_of_owner];
		handle_select_type({ title: owner_text, id: business.type_of_owner }, { token });
	};

	const on_request_success = (business) => {
		utils._storeData(constant.SELECTED_BUSINESS, business);
		const owner_text = constant.OWNER_TYPE_TEXT[business.type_of_owner];
		const token = is_login ? login_details.token : register_data.token;
		if (is_signup) {
			utils._storeData(constant.NAME, register_data.first_name + ' ' + register_data.last_name);
			utils._storeData(constant.MOBILE, register_data.mobile);
		}
		localStorage.setItem('new_user', true);
		localStorage.removeItem(constant.LOCAL_STORAGE_KEYS.AGG_BIZ_LIST);
		handle_select_type({ title: owner_text, id: business.type_of_owner }, { token });
	};

	const login_btn_disabled = useMemo(() => {
		let value = true;
		if (!_.isEmpty(mobile) && !_.isEmpty(password)) value = false;
		return value;
	}, [mobile, password]);

	const verify_btn_disabled = useMemo(() => {
		let value = true;
		if (!_.isEmpty(mobile) && !_.isEmpty(otp)) value = false;
		return value;
	}, [mobile, otp]);

	const whitelabell_name = useMemo(() => {
		let name = null;
		const is_login_flage = _.includes(constant.ALLOWED_ORIGINS, window?.location?.origin);
		if (!is_login_flage) {
			const pattern = /https:\/\/([^.]+)\./;
			const match = window?.location?.origin.match(pattern);
			if (match) {
				name = match[1].toUpperCase();
			} else {
				name = null;
			}
		}
		return name;
	}, []);

	return (
		<div className={classes.wrapper}>
			<div className={classes.left_wrapper}>
				<div>
					<div className='align-items-center'>
						<Text semi style={{ fontSize: 50 }}>
							{is_signup ? `Don't guess. Try` : 'Welcome to'}
						</Text>
						{!_.isEmpty(whitelabell_name) ? (
							<Text bold style={{ marginLeft: 11, fontSize: 35, paddingTop: 6 }}>
								{whitelabell_name}
							</Text>
						) : (
							<img src={ImageLinks.logo} width={132} height={54} style={{ marginLeft: 11 }} />
						)}
					</div>
					<Text medium style={{ fontSize: 20, marginTop: 8 }}>
						Experience deliveries like no other!
					</Text>
				</div>
				<div className={classes.img_wrapper}>
					<div className={classes.scroll_imgs} style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
						<img src={ImageLinks.login_1} />
						<img src={ImageLinks.login_5} />
						<img src={ImageLinks.login_2} />
					</div>
					<div className={classes.scroll_imgs} style={{ display: 'flex', flexDirection: 'column' }}>
						<img src={ImageLinks.login_6} />
						<img src={ImageLinks.login_3} />
						<img src={ImageLinks.login_4} />
					</div>
				</div>
			</div>

			{screen === SCREENS.register && (
				<Register
					set_login={set_login}
					user_data={register_data}
					handle_signup_success={handle_signup_success}
					is_forgot_password={is_forgot_password}
					on_footer_auth_nav={on_footer_auth_nav}
				/>
			)}

			{screen === SCREENS.business_enquiry && (
				<RegisterBusiness token={is_login ? login_details.token : register_data.token} on_request_success={on_request_success} />
			)}

			{screen === SCREENS.pending_invites && (
				<PendingInvites
					on_accept_all_invites={on_accept_all_invites}
					login_details={
						is_login
							? login_details
							: {
									token: register_data.token,
									info: {
										first_name: register_data.first_name,
										last_name: register_data.last_name,
										mobile: register_data.mobile,
										id: register_data.id,
										meta_info: {
											email: register_data.email,
										},
									},
							  }
					}
					pending_invites={pending_invites}
				/>
			)}

			{screen === SCREENS.business_list && <BusinessList business_type_list={business_type_list} on_select_business={on_select_business} />}

			{screen === SCREENS.select_type && <SelectType login_details={login_details} on_type_select={handle_type_options} />}

			{screen === SCREENS.login && (
				<form onSubmit={_proceed} className={classes.login_form}>
					<div style={{ paddingLeft: '15%', paddingTop: '15%' }}>
						<Text semi style={{ fontSize: 20 }}>
							{!is_login && !is_signup ? 'Verify your Mobile Number' : is_login ? 'Enter Password' : 'Create Account'}
						</Text>

						<OutlinedTextField
							type='number'
							value={mobile}
							notched
							name='mobile'
							textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
							onChange={_onEnterMobile}
							label='Mobile Number'
							className={classes.root}
							style={{ marginTop: 50, width: 235 }}
							InputProps={{
								startAdornment: (
									<Text medium style={{ width: 40, fontSize: 16, color: theme.colors.lightGrey2 }}>
										+91
									</Text>
								),
							}}
						/>
						{is_login && (
							<div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
								<OutlinedTextField
									type={show_pass ? 'text' : 'password'}
									value={password}
									textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
									name='password'
									error={login_failed}
									helperText={
										login_failed ? (
											<div className='d-flex'>
												<img width={16} height={16} src={ImageLinks.exclamation_error} style={{ marginRight: 10, marginTop: 5 }} />
												<Text style={{ fontSize: 12, letterSpacing: 'unset', lineHeight: 1.3 }}>
													Wrong password. Try again or click Forgot password to reset.
												</Text>
											</div>
										) : null
									}
									InputProps={{
										endAdornment: (
											<img
												className='cursor-pointer'
												onClick={() => set_show_pass((prev) => !prev)}
												width={24}
												height={24}
												src={show_pass ? ImageLinks.eye_open : ImageLinks.eye_close}
											/>
										),
									}}
									onChange={(e) => set_password(e.target.value)}
									inputProps={{ style: { letterSpacing: 6 } }}
									label='Password*'
									className={classes.root}
									style={{ width: 235 }}
									FormHelperTextProps={{
										classes: {
											root: classes.helper_text,
										},
									}}
								/>
							</div>
						)}
						<div style={{ marginTop: 30 }}>
							{is_forgot_password && (
								<OutlinedTextField
									type='number'
									value={otp}
									name='otp'
									onChange={(e) => set_otp(e.target.value)}
									inputProps={{ style: { letterSpacing: 6 } }}
									label='OTP*'
									className={classes.root}
									style={{ width: 235 }}
									error={otp_failed}
									helperText={otp_failed ? 'Incorrect OTP.' : ''}
									InputProps={{
										endAdornment: otp_failed ? <img width={24} height={24} src={ImageLinks.exclamation_error} /> : null,
									}}
									FormHelperTextProps={{
										classes: {
											root: classes.helper_text,
										},
									}}
								/>
							)}
						</div>
						{is_forgot_password && (
							<div className={classes.cta_btn_wrapper}>
								<Button type='round' text='Verify' size='large' disabled={verify_btn_disabled} style={{ width: 110 }} onClick={_proceed} />

								<ContainedButton
									onClick={timer > 0 ? () => {} : _sendOTP}
									disableElevation
									style={{
										backgroundColor: 'transparent',
									}}>
									<Text
										semi
										className={classes.forgot_pass_text}
										style={timer === 0 ? { textDecoration: 'underline', color: theme.colors.primary } : {}}>
										Resend OTP
										<Text component={'span'} style={{ fontSize: 12, marginLeft: 19 }}>
											{timer > 0 && `in ${timer} sec`}
										</Text>
									</Text>
								</ContainedButton>
							</div>
						)}

						{is_login && (
							<div style={{ marginTop: 70 }}>
								<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2, marginTop: 20, marginBottom: 10 }}>
									{`By logging in I accept to Pidge’s `}
									<Text
										bold
										component={'span'}
										style={{ textDecoration: 'underline', cursor: 'pointer' }}
										onClick={() => {
											window.open('https://pidge.in/terms-of-use/', '_blank');
										}}>
										Terms & Conditions.
									</Text>
								</Text>
								<div className='align-items-center'>
									<Button
										type='round'
										text='Login'
										size='large'
										right_icon={login_btn_disabled ? ImageLinks.arrow_right_grey : ImageLinks.arrow_right_white_no_fill}
										disabled={login_btn_disabled}
										className={classes.cta_btn}
										onClick={_proceed}
									/>
									<Button type='text' text='Forgot Password?' onClick={handle_forgot_password} style={{ marginLeft: 2 }} />
								</div>
							</div>
						)}
						<Footer
							show_auth_nav={is_login || is_signup || is_forgot_password}
							show_help={is_signup || is_forgot_password || !_.isEmpty(password)}
							show_login={!is_login}
							on_auth_nav={on_footer_auth_nav}
						/>
					</div>
				</form>
			)}
		</div>
	);
};

export default Login;
