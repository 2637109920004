import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Amount from 'modules/Amount';
import IconButton from 'modules/IconButton';
import Text from 'modules/Text';
import RechargeWallet from './components/RechargeWallet';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import api_requests from 'resources/api_requests';
import useStyles from './styles';
import DayWiseRow from './components/DayWiseRow';
import TransactionDetails from './components/TransactionDetails';
import PricingSectionHeader from './components/PricingSectionHeader';
import CommPricingItem from './components/CommPricingItem';
import OrderPricingItem from './components/OrderPricingItem';
import DayWiseRowHeader from './components/DayWiseRowHeader';
import FilterReportModal from 'modules/modals/FilterReportModal';
import utils from 'resources/utils';
import Button from 'modules/Button';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import OrderModal from 'modules/modals/OrderModal/OrderModal';

const tabs = ['Transactions', 'Invoice'];
const filters = ['All', 'Deductions', 'Funds Added'];
const dummy_comm_price_data = [
	{
		title: 'SMS',
		amount: 5,
		unit: 'SMS',
	},
	{
		title: 'WhatsApp',
		amount: 3,
		unit: 'Message',
	},
];

const report_filters = [
	{
		multi: true,
		param: 'date',
		title: 'Transaction Date',
		type: 'date',
	},
	{
		multi: false,
		param: 'type',
		title: 'Transaction Type',
		type: 'radio',
		values: [
			{ title: 'Debit', value: 200 },
			{ title: 'Credit', value: 100 },
		],
	},
];

const MyWallet = () => {
	const [show_add_funds, set_show_add_funds] = useState(false);
	const [history_data, set_history_data] = useState([]);
	const [business_details, set_business_details] = useState(null);
	const [selected_month, set_selected_month] = useState(new Date());
	const [selected_tab, set_selected_tab] = useState(0);
	const [selected_row, set_selected_row] = useState(null);
	const [show_transaction_details, set_show_transaction_details] = useState(false);
	const [detailed_list, set_detailed_list] = useState([]);
	const [selected_header_tab, set_selected_header_tab] = useState(0);
	const [transaction_details_data, set_transaction_details_data] = useState({});
	const [show_hover, set_show_hover] = useState(false);
	const [report_items, set_report_items] = useState([]);
	const [show_report_modal, set_show_report_modal] = useState(false);
	const [selected_cdr_id, set_selected_cdr_id] = useState(null);
	const [pricing_data, set_pricing_data] = useState(null);
	const original_list = useRef([]);
	const classes = useStyles();

	useEffect(() => {
		get_history_data();
		get_business_details();
		get_pricing_data();
	}, []);

	const refetch_details = () => {
		get_business_details();
		get_history_data();
	};

	const on_mcp_click = (entity, mcp, transaction_id) => {
		if (
			entity === 'mcp' ||
			entity === 'dunzo_id' ||
			entity === 'recharge' ||
			entity === 'manual-debit' ||
			entity === 'wefast_id' ||
			entity === 'loadshare_id' ||
			entity === 'shadowfax_id' ||
			entity === 'zomato_id' ||
			entity === 'porter_id' ||
			entity === 'pidge_id' ||
			entity.includes('SaaS-')
		) {
			if (entity === 'mcp') {
				// set_selected_pb_id(mcp);
				// set_show_order_details(true);
			} else {
				const filtered_feature_data = detailed_list.filter(
					(detail_information) => detail_information.reference_id === mcp && detail_information.transaction_id === transaction_id,
				);
				set_show_transaction_details(true);
				set_transaction_details_data(filtered_feature_data?.[0]);
			}
		} else {
			return;
		}
	};

	const get_filter_items = async () => {
		try {
			const response = await api_requests.get_wallet_export_items();
			if (response.success) {
				set_report_items(response.data);
				set_show_report_modal(true);
			}
		} catch (error) {}
	};

	const handle_export = async (selected_filters, callback) => {
		try {
			const response = await api_requests.export_wallet(selected_filters);
			utils.downloadBlob(response, 'transactions.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	const on_row_select = async (key) => {
		set_selected_row(key);
		try {
			const response = await api_requests.get_day_wise_txn_history(history_data[key].date);
			if (response.success) {
				set_detailed_list(response.data.records);
				original_list.current = response.data.records;
			}
		} catch (error) {}
	};

	const on_select_filter = (index) => {
		set_selected_tab(index);
		if (index === 0) set_detailed_list(original_list.current);
		if (index === 1) set_detailed_list(_.filter(original_list.current, (item) => item.transaction_type === 200));
		if (index === 2) set_detailed_list(_.filter(original_list.current, (item) => item.transaction_type === 100));
	};

	const get_history_data = async (filtered_data = null) => {
		const start_date = moment(filtered_data || selected_month)
			.startOf('month')
			.format('YYYY-MM-DD');
		const end_date = moment(filtered_data || selected_month)
			.endOf('month')
			.format('YYYY-MM-DD');
		try {
			const response = await api_requests.get_wallet_txn_history({
				start: start_date,
				end: end_date,
			});
			if (response.success) set_history_data(response.data.records);
		} catch (error) {
			console.log(error);
		}
	};

	const get_business_details = async () => {
		try {
			const response = await api_requests.get_business_details();
			if (response.success) set_business_details(response.data);
		} catch (error) {
			console.log(error);
		}
	};

	const on_date_change = (val) => {
		get_history_data(val);
		set_selected_month(val);
		set_selected_row(null);
	};

	const get_pricing_data = async () => {
		try {
			const response = await api_requests.get_business_pricing();
			if (response.success) {
				const items = _.get(response, 'data.items', []);
				const result = _.map(
					_.filter(items, (item) => item.feature.startsWith('fulfillment:')),
					(item) => {
						const min_plan = _.minBy(item.plans, 'from');
						const max_plan = _.find(item.plans, { to: -1 });
						const min_price = min_plan === max_plan ? 0 : min_plan.price;
						return {
							title: item.feature.split(':')[1][0].toUpperCase() + item.feature.split(':')[1].slice(1),
							amount: `₹${min_price}-${max_plan.price}`,
							unit: 'Txn',
						};
					},
				);

				set_pricing_data(result);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<Text bold className={'global_header_text'}>
					Billing
				</Text>
				<Button type='round' text='Recharge Wallet' size='large' onClick={() => set_show_add_funds(true)} />
			</div>
			<div className={classes.filter_wrapper}>
				<div className={classes.header_left_wrap}>
					<div className={classes.flex_center}>
						{_.map(tabs, (item, index) => (
							<div
								key={`header_tab_${index}`}
								className={classes.header_tab_item}
								onClick={() => set_selected_header_tab(index)}
								style={{
									backgroundColor: selected_header_tab === index ? theme.colors.primary : 'transparent',
								}}>
								<Text bold style={{ fontSize: 14, color: selected_header_tab === index ? theme.colors.white : theme.colors.lightPurple6 }}>
									{item}
								</Text>
							</div>
						))}
					</div>

					{selected_header_tab === 0 && (
						<div className={classes.flex_center}>
							<div style={{ marginRight: 12 }}>
								<DatePicker
									selected={selected_month}
									onChange={(date) => on_date_change(date)}
									dateFormat='MM/yyyy'
									showMonthYearPicker
									customInput={
										<div className={clsx('box-shadow', classes.date_filter)}>
											<Text medium className={classes.date_filter_text}>{`${moment(selected_month).format('MMM')}' ${moment(
												selected_month,
											).format('YY')}`}</Text>
											<img src={ImageLinks.calendar_solid_circle} alt='calendar_filter' />
										</div>
									}
								/>
							</div>
							<BootstrapTooltip title={'Get Report'}>
								<div className={classes.graph_circle} onClick={get_filter_items}>
									<img className='purple-download' src={ImageLinks.download} />
									<img className='white-download' style={{ display: 'none' }} width={20} height={20} src={ImageLinks.download_white} alt='' />
								</div>
							</BootstrapTooltip>
						</div>
					)}
				</div>
				<div className={classes.availabel_balance_wrap}>
					<Text semi className={classes.balance_text}>
						Available Balance:
					</Text>
					<Amount
						amount={_.get(business_details, 'balance', 0)}
						main_text_styles={{ color: theme.colors.black, fontSize: 28 }}
						sub_text_styles={{ color: theme.colors.black, fontSize: 14 }}
					/>
					{/* <div onMouseEnter={() => set_show_hover(true)} onMouseLeave={() => set_show_hover(false)}>
						<img src={ImageLinks.info_filled_purple} width={16} height={16} style={{ marginLeft: 4 }} />
					</div>
					{show_hover && (
						<div className={clsx(classes.switch_hover_wrap)}>
							<Text bold style={{ fontSize: 12, color: theme.colors.lightPurple7 }}>
								Negative Limit Allowed: - ₹5,000
							</Text>
							<div className={classes.triangel_wrap}>
								<div className={classes.triangle}></div>
							</div>
						</div>
					)} */}
				</div>
			</div>

			<div className={classes.content}>
				<div className={classes.history_wrap}>
					{selected_header_tab === 0 && (
						<div className={classes.table_wrap}>
							<div className={classes.table_header_wrap}>
								<Text bold style={{ flex: 1 }} className={classes.table_header_text}>
									DD/MM/YY
								</Text>
								<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
									<Text bold className={classes.table_header_text}>
										Debit
									</Text>
									<img src={ImageLinks.minus_solid_circle_purple} />
								</div>
								<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
									<Text bold className={classes.table_header_text}>
										Credit
									</Text>
									<img src={ImageLinks.plus_solid_circle_green} />
								</div>
							</div>

							<div className={classes.row_wrap}>
								{_.map(history_data, (item, key) => {
									return (
										<div onClick={() => on_row_select(key)} key={`history_data${key}`} className={classes.row_item_wrap}>
											<Text medium className={classes.row_item_text}>
												{moment(item.date).format('DD/MM/YY')}
											</Text>
											<Text semi className={classes.row_item_text}>
												<Amount
													amount={item.debit}
													main_text_styles={{ fontSize: 14, color: theme.colors.black }}
													sub_text_styles={{ fontSize: 10, color: theme.colors.black }}
												/>
											</Text>
											<div className={classes.credit_wrap}>
												<Text semi>
													<Amount
														amount={item.credit}
														main_text_styles={{ fontSize: 14, color: theme.colors.black }}
														sub_text_styles={{ fontSize: 10, color: theme.colors.black }}
													/>
												</Text>
												<IconButton>
													<img src={ImageLinks.chevron_right_hollow_purple} width={22} height={22} />
												</IconButton>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
					{selected_header_tab === 1 && (
						<div className={classes.table_wrap}>
							<div className={classes.table_header_wrap}>
								<Text bold style={{ flex: 1 }} className={classes.table_header_text}>
									Invoice Name
								</Text>
							</div>

							<div className={classes.row_wrap} style={{ display: 'flex' }}>
								<div className='align-items-center justify-content-center' style={{ flexDirection: 'column', flex: 1 }}>
									<img src={ImageLinks.empty_invoice} />
									<Text bold style={{ fontSize: 24, color: theme.colors.primary, marginTop: 12 }}>
										Pricing Managed by Admin
									</Text>
									<Text medium style={{ fontSize: 20, color: theme.colors.darkGrey2, marginTop: 14 }}>
										Pricing structures are visible and managed by your business admin.
									</Text>
								</div>
								{/* {_.map([1, 2, 3, 4, 5], (item, key) => {
									return (
										<div onClick={() => on_row_select(key)} key={`invoice_data${key}`} className={classes.row_item_wrap}>
											<Text semi className={classes.row_item_text}>
												invoice-april-2023
											</Text>
											<img src={ImageLinks.download} width={16} height={16} />
										</div>
									);
								})} */}
							</div>
						</div>
					)}
				</div>
				<div className={classes.right_wrapper} style={selected_header_tab === 1 ? { background: 'transparent' } : {}}>
					{selected_row !== null && selected_header_tab === 0 && (
						<>
							<div className={classes.right_wrapper_header}>
								<Text bold style={{ fontSize: 16 }}>
									{moment(history_data[selected_row].date).format('DD/MM/YY')}
								</Text>
								<DayWiseRowHeader
									total_orders={detailed_list.length}
									credit={history_data[selected_row].credit}
									debit={history_data[selected_row].debit}
								/>
							</div>

							<div className={classes.day_filter_wrap}>
								{_.map(filters, (item, index) => (
									<div
										key={`day_filter_${index}`}
										className={classes.day_filter_item}
										onClick={() => on_select_filter(index)}
										style={{
											border: `1px solid ${selected_tab === index ? theme.colors.primary : theme.colors.lightGrey2}`,
										}}>
										<Text bold style={{ fontSize: 12, color: `${selected_tab === index ? theme.colors.primary : theme.colors.lightGrey}` }}>
											{item}
										</Text>
									</div>
								))}
							</div>

							<div className={classes.separator}> </div>

							<div className={classes.detail_wrap}>
								{_.map(detailed_list, (item, key) => {
									return <DayWiseRow key={`day_wise_txn_${key}`} item={item} on_mcp_click={on_mcp_click} />;
								})}
							</div>
						</>
					)}

					{selected_header_tab === 1 && (
						<div>
							<div className={classes.pricing_detail_header}>
								<div className={classes.pricing_header_text_wrap}>
									<Text semi style={{ fontSize: 22 }}>
										Pricing Details
									</Text>
									<div className={classes.active_text_wrap}>
										<Text medium style={{ fontSize: 14, color: theme.colors.green }}>
											Active
										</Text>
									</div>
								</div>
								<img src={ImageLinks.pricing_bg} style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }} />
							</div>

							<div style={{ padding: 15 }}>
								<div className={classes.pricing_section}>
									<PricingSectionHeader
										title={'Order Fulfillment'}
										desctiption={'Charged per order'}
										deduct_desc={'Deducted on fulfillment'}
									/>
									<div className='align-items-center justify-content-between' style={{ marginTop: 9, marginLeft: 20, marginRight: 20 }}>
										<Text medium className={classes.order_price_header}>
											Saas
										</Text>
										<Text medium className={classes.order_price_header}>
											PRICE RANGE
										</Text>
									</div>
									{_.map(pricing_data, (item, index) => (
										<OrderPricingItem key={`price_item_order_${index}`} item={item} />
									))}
								</div>

								{/* <div className={classes.pricing_section} style={{ marginTop: 10, paddingTop: 15 }}>
									<PricingSectionHeader
										title={'Communications'}
										desctiption={'Orders that are some content'}
										deduct_desc={'Deducted on every event'}
									/>

									{_.map(dummy_comm_price_data, (item, index) => (
										<CommPricingItem key={`price_item_comm_${index}`} item={item} />
									))}
								</div> */}
							</div>
						</div>
					)}
				</div>
			</div>
			{show_transaction_details && (
				<TransactionDetails
					close={() => set_show_transaction_details(false)}
					transaction_details_data={transaction_details_data}
					handle_cdr_click={(val) => set_selected_cdr_id(val)}
				/>
			)}
			{show_add_funds && <RechargeWallet refetch_details={refetch_details} business_details={business_details} close={() => set_show_add_funds(false)} />}
			{show_report_modal && (
				<FilterReportModal
					report_filters={report_filters}
					report_items={report_items}
					handle_export={handle_export}
					close={() => set_show_report_modal(false)}
					filter_from={'wallet'}
					wrapper_styles={{ paddingRight: 30 }}
				/>
			)}
			{!_.isEmpty(selected_cdr_id) && (
				<OrderModal
					close={() => {
						set_selected_cdr_id(null);
					}}
					id={selected_cdr_id}
					updater={() => {}}
				/>
			)}
		</div>
	);
};

export default MyWallet;
