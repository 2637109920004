import React, { useContext, useEffect, useMemo, useState } from 'react';
import Text from 'modules/Text';
import _ from 'lodash';
import Papa from 'papaparse';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Button from 'modules/Button';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import CreateNetworkContext from 'views/NetworksFlow/context';
import Upload from 'modules/Upload';
import OutlinedTextField from 'modules/OutlinedTextField';
import InfoContent from './InfoContent';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import { PINCODE_PRICING_SECTIONS, PRICING_RULE } from '../../helper';
import { only_alphabets_regex, six_digit_colon_regex, six_digit_regex } from 'resources/regex_patterns';

const useStyles = makeStyles(() => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column' },
	tags_wrap: {
		marginTop: 7,
		marginRight: 4,
		whiteSpace: 'nowrap',
		height: 24,
		borderRadius: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '2px 8px',
		'&:hover': {
			'& .pincode_delete': {
				display: 'block !important',
			},
		},
	},
	input_scroll_wrap: { marginTop: 12, flex: 1, overflowY: 'hidden', display: 'flex' },
	search_pincode_wrap: {
		display: 'flex',
		alignItems: 'flex-start',
		flex: 1,
		maxHeight: 290,
		border: `1px solid ${theme.colors.black}`,
		borderRadius: 4,
		overflow: 'auto',
		'&::-webkit-scrollbar': {
			background: theme.colors.lightGrey13,
			width: 8,
		},

		/* Track */
		'&::-webkit-scrollbar-track': {
			width: 4,
			background: theme.colors.lightGrey13,
			borderRadius: 60,
		},

		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: theme.colors.darkPurple,
			borderRadius: 60,
		},
	},

	input_container: {
		display: 'flex',
		minHeight: 50,
		flexWrap: 'wrap',
		width: '100%',
		maxWidth: '100%',
	},

	contain_inpit: {
		width: '30%',
		borderRadius: 4,
		padding: 7,
		marginTop: -2,
		border: 'none',
		outline: 'none',
	},
	circle_dot: {
		width: 10,
		height: 6,
		backgroundColor: theme.colors.primary,
		borderRadius: 30,
		marginRight: 10,
		marginTop: 5,
	},
	header_wrap: {
		marginTop: 4,
		height: 33,
		borderRadius: 40,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		display: 'flex',
		alignItems: 'center',
		padding: '6px 14px',
		width: '100%',
	},
}));

const PincodePricingInput = ({ selected_pricing_section, group_data, on_save, is_disabled }) => {
	const { serviciblity_config } = useContext(CreateNetworkContext);
	const [pincode_search, set_pincode_search] = useState('');
	const [added_pincodes, set_added_pincodes] = useState([]);
	const [rates_data, set_rates_data] = useState([]);
	const [is_checked, set_is_checked] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		set_is_checked(false);
		if (_.isEmpty(group_data)) return;
		set_pincode_search('');
		if (selected_pricing_section === 'pickup') {
			const formatted = _.map(_.get(group_data, 'raw_data.pickup', []), (item) => ({ pin: item, is_valid: true }));
			set_added_pincodes(formatted);
		}
		if (selected_pricing_section === 'drop') {
			const formatted = _.map(_.get(group_data, 'raw_data.drop', []), (item) => ({ pin: item, is_valid: true }));
			set_added_pincodes(formatted);
		}
		if (selected_pricing_section === 'rates') {
			set_rates_data(group_data.slabs);
		}
	}, [selected_pricing_section, group_data]);

	const on_change_pincode = (e) => {
		const value = e.target.value;
		if (_.includes(value, ',')) {
			const comma_trimmed = value.slice(0, -1).trim();
			if (_.isEmpty(comma_trimmed)) return;

			const asterix_check =
				_.includes(comma_trimmed, '*') && comma_trimmed.length === 6 && comma_trimmed[0] != '*' && !only_alphabets_regex.test(comma_trimmed);

			const obj = {
				pin: comma_trimmed,
				is_valid: six_digit_regex.test(comma_trimmed) || six_digit_colon_regex.test(comma_trimmed) || asterix_check,
			};

			const _added_pincodes = _.cloneDeep(added_pincodes);
			_added_pincodes.push(obj);
			set_added_pincodes(_added_pincodes);
			set_pincode_search('');
		} else {
			set_pincode_search(value);
		}
	};

	const handleDelete = (value) => {
		const _pincodes = _.cloneDeep(added_pincodes);
		const filtered_pin = _pincodes.filter((val) => val.pin !== value.pin);
		set_added_pincodes(filtered_pin);
	};

	const handle_csv = (fileInfo, inputRef) => {
		Papa.parse(fileInfo.file, {
			header: false,
			skipEmptyLines: true,
			complete: (results) => {
				const data = results.data;
				let valid_pincodes_from_csv = [];
				_.map(data, (pincodes, key) => {
					if (key === 0) return;
					const pincode = pincodes[0];
					let regex = new RegExp(/^\d{6}$/);
					if (regex.test(pincode)) valid_pincodes_from_csv.push({ pin: pincode, is_valid: true });
				});
				set_added_pincodes(Array.from(new Set(valid_pincodes_from_csv)));
			},
		});
	};

	const handle_updte_input = (e, index) => {
		const name = e.target.name;
		const value = e.target.value;
		if (value == '0') return;
		const _rates_data = _.cloneDeep(rates_data);
		_rates_data[index][name] = value;
		if (name === 'max' && index === _rates_data.length - 1) {
			_rates_data.push({
				min: '',
				max: -1,
				slab_size: '',
				price: '',
			});
		}
		set_rates_data(_rates_data);
	};

	const handle_delete = (index) => {
		if (is_disabled) return;
		let _rates_data = _.cloneDeep(rates_data);
		_rates_data.splice(index, 1);
		set_rates_data(_rates_data);
	};

	const handle_save = () => {
		const _rates = _.cloneDeep(rates_data);
		for (let i = 0; i < _rates.length; i++) {
			_rates[i].min = i === 0 ? 0 : _rates[i - 1].max;
		}
		const formatted = _.map(
			_.filter(added_pincodes, (val) => val.is_valid),
			(item) => item.pin,
		);

		on_save(formatted, _rates);
	};

	const handle_service_check = () => {
		const key_name = selected_pricing_section === 'pickup' ? 'pickup' : 'delivery';
		const pincodes = serviciblity_config[key_name].pincodes;
		const formatted = _.map(pincodes, (item) => ({ pin: item, is_valid: true }));
		set_added_pincodes(is_checked ? [] : formatted);
		set_is_checked((prev) => !prev);
	};

	const btn_disabled = useMemo(() => {
		if (is_disabled) return true;

		let value = false;

		if (selected_pricing_section === 'pickup' || selected_pricing_section === 'drop') {
			value = _.filter(added_pincodes, (item) => item.is_valid).length === 0;
			return value;
		}

		for (let i = 0; i < rates_data.length; i++) {
			const element = rates_data[i];
			if (element.max == '' || element.max == '-' || element.slab_size == '' || element.price == '') {
				value = true;
				break;
			}
		}
		return value;
	}, [selected_pricing_section, added_pincodes, rates_data, is_disabled]);

	const is_data_updated = useMemo(() => {
		if (_.isEmpty(group_data)) return;

		let org_data =
			selected_pricing_section === 'pickup'
				? group_data.raw_data.pickup
				: selected_pricing_section === 'drop'
				? group_data.raw_data.drop
				: group_data.slabs;
		const new_data = selected_pricing_section === 'rates' ? rates_data : _.map(added_pincodes, (item) => item.pin);
		return !_.isEqual(org_data, new_data);
	}, [selected_pricing_section, added_pincodes, rates_data, group_data]);

	return (
		<div className={classes.wrapper}>
			<div className='align-items-center justify-content-between'>
				<Text bold style={{ fontSize: 16 }}>
					{_.filter(PINCODE_PRICING_SECTIONS, (item) => item.key === selected_pricing_section)[0].title}
				</Text>
				<BootstrapTooltip
					placement='left-start'
					title={<InfoContent pricing_criteria={PRICING_RULE.PINCODE} selected_pricing_section={selected_pricing_section} />}>
					<img src={ImageLinks.info_purple} width={16} height={16} />
				</BootstrapTooltip>
			</div>
			<div className={'dashed-line'}></div>

			{((selected_pricing_section === 'pickup' && _.get(serviciblity_config, 'pickup.pincodes', []).length > 0) ||
				(selected_pricing_section === 'drop' && _.get(serviciblity_config, 'delivery.pincodes', []).length > 0)) && (
				<div className='align-items-center' style={{ marginTop: 9 }}>
					<Checkbox
						disabled={is_disabled}
						checked={is_checked}
						onChange={handle_service_check}
						name='match'
						style={{ color: theme.colors.lightPurple2, padding: 0 }}
					/>
					<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2, marginLeft: 8 }}>
						Match with serviceable area (if areas are defined as polygon it will not be computed)
					</Text>
				</div>
			)}
			{
				<div className={classes.input_scroll_wrap}>
					{(selected_pricing_section === 'pickup' || selected_pricing_section === 'drop') && (
						<div className={classes.search_pincode_wrap}>
							<div className={classes.input_container}>
								{_.map(added_pincodes, (data, index) => (
									<div
										key={`input_tags_${index}`}
										className={classes.tags_wrap}
										style={{ backgroundColor: data.is_valid ? theme.colors.lightPink4 : theme.colors.lightRed }}>
										<Text medium style={{ fontSize: 14, marginRight: 4, color: data.is_valid ? theme.colors.black : theme.colors.red }}>
											{data.pin}
										</Text>
										{!is_disabled && (
											<img
												src={ImageLinks.delete}
												width={16}
												height={16}
												style={{ cursor: 'pointer', display: 'none' }}
												className='pincode_delete'
												onClick={() => {
													handleDelete(data);
												}}
											/>
										)}
									</div>
								))}

								<input
									value={pincode_search}
									onChange={on_change_pincode}
									disabled={is_disabled}
									className={clsx(classes.contain_inpit, 'purple_placeholder')}
									placeholder={'Enter pincodes separated by a comma'}
									style={{ paddingLeft: added_pincodes.length === 0 ? 10 : 1, height: 35, width: 260 }}
								/>
							</div>
						</div>
					)}
					{selected_pricing_section === 'rates' && (
						<div className={classes.search_pincode_wrap} style={{ flexDirection: 'column' }}>
							<div className={classes.header_wrap}>
								<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, flex: 1 }}>
									Slab Start
								</Text>
								<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, flex: 1 }}>
									Slab End
								</Text>
								<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, flex: 1 }}>
									Slab Size
								</Text>
								<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, flex: 1 }}>
									Rate/Slab
								</Text>
							</div>

							<div style={{ flex: 1, width: '100%' }}>
								{_.map(rates_data, (item, index) => (
									<div key={`rate_item_${index}`} className='d-flex' style={{ marginTop: 10, marginLeft: 10 }}>
										<div style={{ flex: 1 }}>
											<OutlinedTextField
												value={index === 0 ? 0 : rates_data[index - 1].max}
												name='min'
												textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
												placeholder='0'
												disabled={true}
												InputProps={{
													style: { height: 32 },
													endAdornment: (
														<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
															g
														</Text>
													),
												}}
												style={{ width: 80 }}
											/>
										</div>
										<div style={{ flex: 1 }}>
											<OutlinedTextField
												value={item.max}
												name='max'
												textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
												onChange={(e) => handle_updte_input(e, index)}
												placeholder='0'
												disabled={is_disabled}
												InputProps={{
													style: { height: 32 },
													endAdornment: (
														<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
															g
														</Text>
													),
												}}
												style={{ width: 80 }}
											/>
										</div>
										<div style={{ flex: 1 }}>
											<OutlinedTextField
												value={item.slab_size}
												name='slab_size'
												textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
												onChange={(e) => handle_updte_input(e, index)}
												placeholder='0'
												disabled={is_disabled}
												InputProps={{
													style: { height: 32 },
													endAdornment: (
														<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
															g
														</Text>
													),
												}}
												style={{ width: 80 }}
											/>
										</div>
										<div className='align-items-center' style={{ flex: 1 }}>
											<OutlinedTextField
												value={item.price}
												name='price'
												textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
												onChange={(e) => handle_updte_input(e, index)}
												placeholder='0.00'
												disabled={is_disabled}
												InputProps={{
													style: { height: 32 },
													startAdornment: (
														<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
															₹
														</Text>
													),
												}}
												style={{ width: 80 }}
											/>
											{rates_data.length > 1 && (
												<img
													src={ImageLinks.delete}
													width={16}
													height={16}
													className='cursor-pointer'
													style={{ marginLeft: 8 }}
													onClick={() => {
														handle_delete(index);
													}}
												/>
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			}
			<div className='align-items-center justify-content-between' style={{ marginTop: 12 }}>
				{selected_pricing_section === 'pickup' || selected_pricing_section === 'drop' ? (
					<Upload accepted_extensions='.csv' add_image={handle_csv} is_disabled={is_disabled}>
						<Button
							disabled={is_disabled}
							type='outlined'
							text='Upload CSV'
							size='extra_small'
							onClick={() => {}}
							left_icon={is_disabled ? ImageLinks.upload_light_grey : ImageLinks.upload_purple}
						/>
					</Upload>
				) : (
					<div>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Note: To set new slab rate, enter new ‘Slab End’ value
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Note: -1 represents no limit
							</Text>
						</Text>
					</div>
				)}

				{is_data_updated && <Button type='round' disabled={btn_disabled} text='Save' size='extra_small' onClick={handle_save} />}
			</div>
		</div>
	);
};

export default PincodePricingInput;
