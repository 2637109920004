import { IconButton, ListItem, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import OutlinedButton from 'modules/OutlinedButton';
import Papa from 'papaparse';
import Text from 'modules/Text';
import Upload from 'modules/Upload';
import React, { useState } from 'react';
import resource from './sample_rider_upload.csv';
import _ from 'lodash';
import api_requests from 'resources/api_requests';
import moment from 'moment';
import utils from 'resources/utils';
import DummyProgressBar from 'modules/DummyProgressBar';
import useStyles from './styles';
import Lottie from 'react-lottie';
import Button from 'modules/Button';

function BulkRiderUploadModal({ close, set_refresh_riders }) {
	const [uploaded_csv_file, set_uploaded_csv_file] = useState(null);
	const [csv_good_data, set_csv_good_data] = useState([]);
	const [csv_bad_data, set_csv_bad_data] = useState([]);
	const [process_started, set_process_started] = useState(false);
	const [progress, set_progress] = useState(0);
	const classes = useStyles();
	const theme = useTheme();

	const check_good_data = (item) => {
		if (
			!item.first_name ||
			!item.last_name ||
			!item.mobile ||
			!item.blood_group ||
			!item.aadhar_number ||
			!item.date_of_birth ||
			!item.current_location.address_line_1 ||
			!item.current_location.pincode ||
			!item.current_location.city ||
			!item.current_location.state
		) {
			return false;
		}
		const blood_groups = ['o+', 'o-', 'a+', 'a-', 'b+', 'b-', 'ab+', 'ab-'];
		if (!_.includes(blood_groups, item.blood_group.toLowerCase())) {
			return false;
		}
		const mobile_pattern = /^\d{10}$/;
		if (!mobile_pattern.test(item.mobile)) {
			return false;
		}
		const adhaar_pattern = /^\d{12}$/;
		if (!adhaar_pattern.test(item.aadhar_number)) {
			return false;
		}
		const date_pattern = /^\d{4}-\d{2}-\d{2}$/;
		if (!date_pattern.test(item.date_of_birth)) {
			return false;
		}
		const pin_pattern = /^\d{6}$/;
		if (!pin_pattern.test(item.current_location.pincode)) {
			return false;
		}
		if (!moment(item.date_of_birth, 'YYYY-MM-DD').isValid()) {
			return false;
		}
		return true;
	};

	const handle_csv = (fileInfo, inputRef) => {
		set_process_started(true);
		set_uploaded_csv_file(fileInfo);
		set_progress(20);
		Papa.parse(fileInfo.file, {
			header: true,
			skipEmptyLines: true,
			complete: (results) => {
				const data = results.data;
				const _good_data = [];
				const _bad_data = [];
				_.forEach(data, (item) => {
					const _item = {
						first_name: item['first_name*'],
						last_name: item['last_name*'],
						mobile: item['mobile*'],
						date_of_birth: item['date_of_birth*'],
						aadhar_number: item['aadhar_number*'],
						rider_category: 1,
						blood_group: item['blood_group*'],
						current_location: {
							address_line_1: item['address_line_1*'],
							pincode: parseInt(item['pincode*']),
							city: item['city*'],
							state: item['state*'],
						},
					};
					if (check_good_data(_item)) {
						_good_data.push(_item);
					} else {
						_bad_data.push(_item);
					}
				});
				set_csv_bad_data(_bad_data);
				set_csv_good_data(_good_data);
			},
		});
	};

	const download_file = () => {
		const link = document.createElement('a');
		link.href = resource;
		let fileName = `sample-csv.csv`;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
	};

	const upload_rider_data = async () => {
		try {
			const res = await api_requests.bulk_upload_rider({ items: csv_good_data });
			if (res.success) {
				set_refresh_riders((prev) => !prev);
				close();
			}
		} catch (err) {}
	};

	const remove_from_list = (index) => {
		const _new_list = [...csv_good_data];
		_new_list.splice(index, 1);
		set_csv_good_data(_new_list);
	};

	const download_error_data = () => {
		let data = 'first_name*,last_name*,mobile*,date_of_birth*,aadhar_number*,blood_group*,address_line_1*,pincode*,city*,state*';

		_.forEach(csv_bad_data, (item) => {
			data +=
				'\n' +
				_.get(item, 'first_name', '-') +
				', ' +
				_.get(item, 'last_name', '-') +
				', ' +
				_.get(item, 'mobile', '-') +
				', ' +
				_.get(item, 'date_of_birth', '-') +
				', ' +
				_.get(item, 'aadhar_number', '-') +
				', ' +
				_.get(item, 'blood_group', '-') +
				', ' +
				_.get(item, 'current_location.address_line_1', '-') +
				', ' +
				_.get(item, 'current_location.pincode', '-') +
				', ' +
				_.get(item, 'current_location.city', '-') +
				', ' +
				_.get(item, 'current_location.state', '-');
		});
		utils.downloadBlob(data, 'rider.csv', 'text/csv');
	};

	return (
		<>
			<div className={classes.close_container} onClick={close} />
			<div className={classes.container}>
				<div className='d-flex justify-content-between'>
					<Text semi style={{ fontSize: 20, color: theme.colors.primary }}>
						Bulk Upload Riders
					</Text>
					<IconButton onClick={close}>
						<img src={ImageLinks.crossBlack} width={16} height={16} />
					</IconButton>
				</div>
				<div className={classes.divider}></div>
				{!process_started && (
					<Upload accepted_extensions='.csv' add_image={handle_csv}>
						<ListItem button className={classes.upload_csv_content_container}>
							<img src={ImageLinks.upload_light_purple} width={52} height={52} alt='upload-image' />
							<Text style={{ fontSize: 12, marginTop: 30, marginBottom: 12 }}>Select a CSV file or drag and drop here</Text>
							<Button type='round' size='small' text='Select File' />
						</ListItem>
					</Upload>
				)}
				{progress > 0 && progress < 100 && (
					<div className={classes.upload_csv_content_container}>
						<img src={ImageLinks.cricle_with_dashed_light_purple} width={52} height={52} alt='upload-image' />
						<Text style={{ fontSize: 12, marginTop: 30, marginBottom: 12 }}>Uploading CSV...</Text>
						<div className={classes.file_desc_box} style={{ backgroundColor: theme.colors.white }}>
							<div className='d-flex align-items-center' style={{ gap: 20 }}>
								<img src={ImageLinks.folder_light_purple} />
								<div style={{ width: 250 }}>
									<Text semi style={{ fontSize: 12, marginBottom: 10 }}>
										{uploaded_csv_file.name}
									</Text>
									<DummyProgressBar progress={progress} set_progress={set_progress} />
								</div>
							</div>
							<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>{`${
								uploaded_csv_file.size > 1024 ? (uploaded_csv_file.size / 1024).toFixed(2) + ' MB' : uploaded_csv_file.size + ' KB'
							}`}</Text>
						</div>
					</div>
				)}
				{csv_bad_data.length > 0 && progress === 100 && (
					<Upload accepted_extensions='.csv' add_image={handle_csv}>
						<ListItem button className={classes.upload_csv_content_container}>
							<img
								src={csv_good_data.length > 0 ? ImageLinks.exclamation_yellow_filled : ImageLinks.exclamation_error}
								width={52}
								height={52}
								alt='upload-image'
							/>
							<Text style={{ fontSize: 12, marginTop: 30, marginBottom: 12 }}>
								{csv_good_data.length > 0
									? 'Uploaded partially - all entries were not processed.'
									: 'Unable to upload CSV. Please make sure all fields are filled.'}
							</Text>
							<Button type='round' size='small' text='Upload Again' />
						</ListItem>
					</Upload>
				)}
				{csv_bad_data.length === 0 && progress === 100 && csv_good_data.length > 0 && (
					<Upload accepted_extensions='.csv' add_image={handle_csv}>
						<ListItem button className={classes.upload_csv_content_container}>
							<img src={ImageLinks.green_tick} width={52} height={52} alt='upload-image' />
							<Text semi style={{ fontSize: 12, marginTop: 30, marginBottom: 12 }}>
								CSV uploaded successfully!
							</Text>
							<div className={classes.file_desc_box}>
								<div className='d-flex align-items-center' style={{ gap: 5 }}>
									<img src={ImageLinks.folder_light_purple} />
									<Text semi style={{ fontSize: 12 }}>
										{uploaded_csv_file.name}
									</Text>
								</div>
								<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>{`${
									uploaded_csv_file.size > 1024 ? (uploaded_csv_file.size / 1024).toFixed(2) + ' MB' : uploaded_csv_file.size + ' KB'
								}`}</Text>
							</div>
						</ListItem>
					</Upload>
				)}
				{!process_started && (
					<div className='d-flex justify-content-between align-items-center' style={{ marginTop: 10 }}>
						<Text medium style={{ fontSize: 13, color: theme.colors.darkGrey2 }}>
							Please fill all mandatory fields to avoid error
						</Text>
						<Button type='outlined' text='CSV Format' onClick={() => download_file()} left_icon={ImageLinks.download} />
					</div>
				)}
				{csv_bad_data.length > 0 && progress === 100 && (
					<div className={classes.error_box}>
						<div className='d-flex align-items-center' style={{ gap: 8 }}>
							<img src={ImageLinks.exclamation_light_red_filled} height={24} width={24}></img>
							<Text bold style={{ fontSize: 18, color: theme.colors.red }}>
								{csv_bad_data.length}
							</Text>
							<Text semi style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
								Entries have incorrect data
							</Text>
						</div>
						<img src={ImageLinks.download_filled_purple} height={32} width={32} style={{ cursor: 'pointer' }} onClick={download_error_data}></img>
					</div>
				)}

				{csv_good_data.length > 0 && progress === 100 && (
					<div className={classes.list_container}>
						{_.map(csv_good_data, (item, index) => {
							return (
								<div className={classes.rider_row} key={`rider_row_${index}`}>
									<Text semi className={classes.name_text}>{`${item.first_name} ${item.last_name}`}</Text>
									<Text semi style={{ fontSize: 16, flex: 2 }}>
										{item.mobile}
									</Text>
									<img
										src={ImageLinks.delete}
										style={{ cursor: 'pointer' }}
										height={20}
										width={20}
										onClick={() => remove_from_list(index)}></img>
								</div>
							);
						})}
					</div>
				)}

				<div className={classes.footer} style={{ boxShadow: csv_good_data.length > 0 ? '0px -4px 14px rgba(0, 0, 0, 0.05)' : 'unset' }}>
					<div>
						{csv_good_data.length > 0 && (
							<Text bold style={{ fontSize: 16, color: theme.colors.darkGrey2 }}>{`${csv_good_data.length} Riders`}</Text>
						)}
					</div>
					<Button
						type='round'
						text='Create'
						size='large'
						disabled={csv_good_data.length === 0}
						onClick={() => upload_rider_data()}
						style={{ width: 110 }}
					/>
				</div>
			</div>
		</>
	);
}

export default BulkRiderUploadModal;
