import React, { useContext, useState } from 'react';
import { Divider, ListItem, makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ContainedButton from 'modules/ContainedButton';
import _ from 'lodash';
import MoveOrdersModal from './MoveOrdersModal';
import RepeatOrdersContext from '../context';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Routes from 'resources/Routes';
import { ALL_ORDERS_MENU_ITEMS } from 'views/AllOrders/helper';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from 'views/PoweredWithPidge/helper';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	list_item_container: {
		padding: 16,
		background: theme.colors.white,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: '6px',
		marginTop: 12,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	count_value: {
		fontSize: 18,
		paddingRight: 4,
		minWidth: 47,
	},
	download_container: {
		padding: '6px 8px',
		borderRadius: '50%',
		cursor: 'pointer',
		background: theme.colors.lightPurple,
	},
	download_container_disabled: {
		padding: '6px 8px',
		borderRadius: '50%',
		cursor: 'pointer',
		background: theme.colors.lightGrey5,
	},
	formControl: {
		display: 'flex',
		flexDirection: 'row',
	},
	grey_box: {
		width: 16,
		height: 16,
		borderRadius: 2,
		background: theme.colors.lightGrey2,
		marginRight: 8,
	},
	disable_replicate_value: {
		fontSize: 14,
		color: theme.colors.darkGrey2,
		marginRight: 16,
	},
	ctn_button: {
		'&:disabled': {
			backgroundColor: theme.colors.lightGrey5,
			color: theme.colors.darkGrey2,
		},
	},
	view_order_link: { fontSize: 18, color: theme.colors.primary, marginLeft: 6, textDecoration: 'underline' },
}));

const RepeatOrdersResult = () => {
	const {
		history,
		repeat_order_result_data,
		steps,
		handle_download,
		copy_set_a_values,
		set_copy_set_a_values,
		copy_set_b_values,
		set_copy_set_b_values,
		handle_order_move,
		is_set_a_moved,
		is_set_b_moved,
	} = useContext(RepeatOrdersContext);
	const [show_move_orders_modal, set_show_move_orders_modal] = useState(false);
	const [show_status_modal, set_show_status_modal] = useState(false);

	const [information_transfer_to_move_modal, set_information_transfer_to_move_modal] = useState({});
	const classes = useStyles();

	const handle_delivered_change = (event) => {
		const keys_order = ['cdr_id', 'pbid', 'route', 'rider'];
		const event_value = event.target.name;
		const index = _.indexOf(keys_order, event_value);
		const existing_index = _.indexOf(copy_set_a_values, event_value);

		let copied = [];
		if (existing_index >= 0) {
			copied = _.slice(keys_order, 0, index);
		} else {
			copied = _.slice(keys_order, 0, index + 1);
		}

		set_copy_set_a_values(copied);
	};

	const handle_outer_delivered_change = (event) => {
		const selected_set_value = copy_set_b_values.length === 0 ? ['cdr_id'] : [];
		set_copy_set_b_values(selected_set_value);
	};

	const handle_move_orders = (set_information) => {
		set_show_move_orders_modal(true);
		if (set_information === 'set_a') {
			set_information_transfer_to_move_modal({ copy_set_a: copy_set_a_values });
		} else {
			set_information_transfer_to_move_modal({ copy_set_b: copy_set_b_values });
		}
	};

	const step_value = steps < 5;
	const style_disabled_on_text = step_value ? { opacity: 0.6 } : { opacity: 'unset' };
	const fresh_order_count = !_.isEmpty(repeat_order_result_data)
		? _.get(repeat_order_result_data, 'total_cnt', 0) - (_.get(repeat_order_result_data, 'set_a_cnt', 0) + _.get(repeat_order_result_data, 'set_b_cnt', 0))
		: 0;

	return (
		<div>
			<Text bold style={{ fontSize: 18 }}>
				Result
			</Text>
			<ListItem className={classes.list_item_container}>
				<div className='align-items-center' style={style_disabled_on_text}>
					<img src={ImageLinks.green_tick_with_light_green_background} width={24} height={24} alt='tick' style={{ marginRight: 6 }} />
					<Text semi className={'align-items-center'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						<Text bold component={'span'} className={classes.count_value} style={{ color: theme.colors.green }}>
							{_.get(repeat_order_result_data, 'set_a_cnt', 0)}
						</Text>
						Delivered | “Delivery Date + Time Slot” + “Self”
					</Text>
				</div>
				<div className='align-items-center'>
					<div
						className={
							step_value || _.get(repeat_order_result_data, 'set_a_cnt', 0) === 0
								? classes.download_container_disabled
								: classes.download_container
						}
						onClick={() => handle_download('set_a')}>
						<img
							src={step_value || _.get(repeat_order_result_data, 'set_a_cnt', 0) === 0 ? ImageLinks.download_gray : ImageLinks.download}
							width={16}
							height={16}
							alt='download'
						/>
					</div>{' '}
					<div style={{ marginLeft: 20, marginRight: 20, height: 20 }}>
						<Divider orientation='vertical' style={{ backgroundColor: theme.colors.lightGrey2 }} />
					</div>
					{is_set_a_moved ? (
						<div
							className='align-items-center cursor-pointer'
							onClick={() => history.push(Routes.POWERED_WITH_PIDGE.path + '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.ReadyToShip.id)}>
							<Text semi className={classes.view_order_link}>
								View Orders
							</Text>
						</div>
					) : (
						<div className='align-items-center'>
							<Text bold style={{ fontSize: 14, marginRight: 14, ...style_disabled_on_text }}>
								Replicate:
							</Text>
							{step_value || _.get(repeat_order_result_data, 'set_a_cnt', 0) === 0 ? (
								<div className='align-items-center'>
									<div className={classes.grey_box} />
									<Text className={classes.disable_replicate_value}>CDRID</Text>
									<div className={classes.grey_box} />
									<Text className={classes.disable_replicate_value}>PBID</Text>
									<div className={classes.grey_box} />
									<Text className={classes.disable_replicate_value}>Route</Text>
									<div className={classes.grey_box} />
									<Text className={classes.disable_replicate_value}>Rider</Text>
								</div>
							) : (
								<FormControl>
									<FormGroup className={classes.formControl}>
										<FormControlLabel
											control={
												<Checkbox checked={_.includes(copy_set_a_values, 'cdr_id')} onChange={handle_delivered_change} name='cdr_id' />
											}
											label={
												<Text semi style={{ fontSize: 14 }}>
													CDRID
												</Text>
											}
										/>
										<FormControlLabel
											control={
												<Checkbox checked={_.includes(copy_set_a_values, 'pbid')} onChange={handle_delivered_change} name='pbid' />
											}
											label={
												<Text semi style={{ fontSize: 14 }}>
													PBID
												</Text>
											}
										/>
										<FormControlLabel
											control={
												<Checkbox checked={_.includes(copy_set_a_values, 'route')} onChange={handle_delivered_change} name='route' />
											}
											label={
												<Text semi style={{ fontSize: 14 }}>
													Route
												</Text>
											}
										/>
										<FormControlLabel
											control={
												<Checkbox checked={_.includes(copy_set_a_values, 'rider')} onChange={handle_delivered_change} name='rider' />
											}
											label={
												<Text semi style={{ fontSize: 14 }}>
													Rider
												</Text>
											}
										/>
									</FormGroup>
								</FormControl>
							)}
							<Button
								type='round'
								size='large'
								text='Move Orders'
								onClick={() => handle_move_orders('set_a')}
								disabled={copy_set_a_values.length === 0 || _.get(repeat_order_result_data, 'set_a_cnt', 0) === 0}
								right_icon={
									copy_set_a_values.length === 0 || _.get(repeat_order_result_data, 'set_a_cnt', 0) === 0
										? ImageLinks.right_arrow_gray
										: ImageLinks.arrow_right_white_no_fill
								}
							/>
						</div>
					)}
				</div>
			</ListItem>
			<ListItem className={classes.list_item_container}>
				<div className='align-items-center' style={style_disabled_on_text}>
					<img src={ImageLinks.calender_solid_circle_pink} width={24} height={24} alt='tick' style={{ marginRight: 6 }} />
					<Text semi className={'align-items-center'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						<Text bold component={'span'} className={classes.count_value} style={{ color: theme.colors.darkPurple }}>
							{_.get(repeat_order_result_data, 'set_b_cnt', 0)}
						</Text>
						Delivered | “Delivery Slot” match in last 15 days. Note: Only last order is fetched
					</Text>
				</div>
				<div className='align-items-center'>
					<div
						className={
							step_value || _.get(repeat_order_result_data, 'set_b_cnt', 0) === 0
								? classes.download_container_disabled
								: classes.download_container
						}
						onClick={() => handle_download('set_b')}>
						<img
							src={step_value || _.get(repeat_order_result_data, 'set_b_cnt', 0) === 0 ? ImageLinks.download_gray : ImageLinks.download}
							width={16}
							height={16}
							alt='download'
						/>
					</div>
					<div style={{ marginLeft: 20, marginRight: 20, height: 20 }}>
						<Divider orientation='vertical' style={{ backgroundColor: theme.colors.lightGrey2 }} />
					</div>
					{is_set_b_moved ? (
						<div
							className='align-items-center cursor-pointer'
							onClick={() => history.push(Routes.ALL_ORDERS.path + '?type=' + ALL_ORDERS_MENU_ITEMS.Pending.id)}>
							<Text semi className={classes.view_order_link}>
								View Orders
							</Text>
						</div>
					) : (
						<div className='align-items-center'>
							<Text bold style={{ fontSize: 14, marginRight: 14, ...style_disabled_on_text }}>
								Replicate:
							</Text>
							{step_value || _.get(repeat_order_result_data, 'set_b_cnt', 0) === 0 ? (
								<div className='align-items-center'>
									<div className={classes.grey_box} />
									<Text className={classes.disable_replicate_value}>CDRID</Text>
								</div>
							) : (
								<FormControl>
									<FormGroup className={classes.formControl}>
										<FormControlLabel
											control={
												<Checkbox
													checked={_.includes(copy_set_b_values, 'cdr_id')}
													onChange={(e) => handle_outer_delivered_change(e, 'copy_set_b')}
													name='cdr_id'
												/>
											}
											label={
												<Text semi style={{ fontSize: 14 }}>
													CDRID
												</Text>
											}
										/>
									</FormGroup>
								</FormControl>
							)}
							<Button
								type='round'
								size='large'
								text='Move Orders'
								onClick={() => handle_move_orders('set_b')}
								disabled={copy_set_b_values.length === 0 || _.get(repeat_order_result_data, 'set_b_cnt', 0) === 0}
								right_icon={
									copy_set_a_values.length === 0 || _.get(repeat_order_result_data, 'set_a_cnt', 0) === 0
										? ImageLinks.right_arrow_gray
										: ImageLinks.arrow_right_white_no_fill
								}
							/>
						</div>
					)}
				</div>
			</ListItem>
			<ListItem className={classes.list_item_container}>
				<div className='align-items-center' style={style_disabled_on_text}>
					<img src={ImageLinks.plusPurple} width={24} height={24} alt='tick' style={{ marginRight: 6 }} />
					<Text semi className={'align-items-center'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						<Text bold component={'span'} className={classes.count_value} style={{ color: theme.colors.primary }}>
							{fresh_order_count}
						</Text>
						Fresh Orders{' '}
						<Text semi style={{ paddingLeft: 8 }}>
							(Download the CSV, and upload with other details in Create Order)
						</Text>
					</Text>
				</div>
				<div
					className={step_value || fresh_order_count === 0 ? classes.download_container_disabled : classes.download_container}
					onClick={() => handle_download('set_c')}>
					<img src={step_value || fresh_order_count === 0 ? ImageLinks.download_gray : ImageLinks.download} width={16} height={16} alt='download' />
				</div>
			</ListItem>
			<ListItem className={classes.list_item_container}>
				<div className='align-items-center' style={style_disabled_on_text}>
					<img
						src={ImageLinks.information_reverse_red}
						width={24}
						height={24}
						alt='tick'
						style={{ marginRight: 6, background: theme.colors.lightRed, borderRadius: '50%' }}
					/>
					<Text semi className={'align-items-center'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						<Text bold component={'span'} className={classes.count_value} style={{ color: theme.colors.red }}>
							{_.get(repeat_order_result_data, 'error_data_cnt', 0)}
						</Text>
						Error
						<Text semi style={{ paddingLeft: 8 }}>
							(Numbers formatted incorrectly. Please rectify.)
						</Text>
					</Text>
				</div>
				<div
					className={
						step_value || _.get(repeat_order_result_data, 'error_data_cnt', 0) === 0
							? classes.download_container_disabled
							: classes.download_container
					}
					onClick={() => handle_download('error_data')}>
					<img
						src={step_value || _.get(repeat_order_result_data, 'error_data_cnt', 0) === 0 ? ImageLinks.download_gray : ImageLinks.download}
						width={16}
						height={16}
						alt='download'
					/>
				</div>
			</ListItem>
			{show_move_orders_modal && (
				<MoveOrdersModal
					close={() => set_show_move_orders_modal(false)}
					id={_.get(repeat_order_result_data, '_id', '')}
					information_transfer_to_move_modal={information_transfer_to_move_modal}
					on_success={() => {
						set_show_move_orders_modal(false);
						set_show_status_modal(true);
					}}
				/>
			)}
			{show_status_modal && (
				<ConfirmClose
					close={() => {
						set_show_status_modal(false);
					}}
					headerTitle={`Orders Moved to ${_.isEmpty(information_transfer_to_move_modal?.copy_set_a) ? 'Pending' : 'Powered with Pidge'}`}
					title={
						<Text bold style={{ fontSize: 14 }}>
							{_.isEmpty(information_transfer_to_move_modal?.copy_set_a)
								? _.get(repeat_order_result_data, 'set_b_cnt', 0)
								: _.get(repeat_order_result_data, 'set_a_cnt', 0)}{' '}
							orders
							<Text component={'span'} medium style={{ fontSize: 14 }}>
								{' '}
								were successfully created and moved to{' '}
								{_.isEmpty(information_transfer_to_move_modal?.copy_set_a) ? 'All Order - Pending.' : 'Powered with Pidge - Ready to Ship.'}
							</Text>
						</Text>
					}
					positive_btn_text={'Ok'}
					negative_btn_text={'View Orders'}
					handle_negative_action={() => {
						history.push(
							_.isEmpty(information_transfer_to_move_modal?.copy_set_a)
								? Routes.ALL_ORDERS.path + '?type=' + ALL_ORDERS_MENU_ITEMS.Pending.id
								: Routes.POWERED_WITH_PIDGE.path + '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.ReadyToShip.id,
						);
					}}
					on_confirm={() => {
						handle_order_move(information_transfer_to_move_modal);
					}}
					is_save_change={true}
				/>
			)}
		</div>
	);
};

export default RepeatOrdersResult;
