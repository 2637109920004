import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import PremiumFeatureModal from 'modules/PremiumFeatureModal';
import Text from 'modules/Text';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { hide_loader, show_loader } from 'actions/app';
import api_requests from 'resources/api_requests';
import Routes from 'resources/Routes';
import theme from 'resources/theme';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from 'views/PoweredWithPidge/helper';
import ActiveRoutes from './components/ActiveRoutes';
import AllocateRider from './components/AllocateRider/AllocateRider';
import CompareRoutes from './components/CompareRoutes/CompareRoutes';
import ConfirmationBox from './components/ConfirmationBox';
import UnallocatedRoutes from './components/UnallocatedRoutes';
import useStyles from './styles';
import Toast from 'modules/Toast';
import IconButton from 'modules/IconButton';
import ConfirmClose from 'modules/modals/ConfirmClose';
import BundleList from './components/BundleList';
import IdList from './components/AddOrderToBundle/IdList';
import AddRouteMap from './components/AddOrderToBundle/AddRouteMap';
import BulkActionModal from './components/BulkActionModal';
import BundleListFilter from './components/BundleListFilter';
import FilterReportModal from 'modules/modals/FilterReportModal';
import utils from 'resources/utils';
import { Menu, MenuItem, Popover, Select } from '@material-ui/core';
import DeliverySimulator from './components/DeliverySimulator';
import Button from 'modules/Button';
import Helpcenter from 'modules/HelpCenter';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import EmptyState from 'modules/EmptyState';
import constant from 'resources/constant';

const MENU = {
	unallocated: 'unallocated',
	active: 'active',
	compare: 'compare',
	add: 'add',
};

const initial_allocate_rider_details = {
	open: false,
	bundle_id: null,
	tripData: [],
};

const initial_confirm_remove_squence_details = {
	open: false,
	trip_ids: [],
};

const SEARCH_BY_OPTIONS = [
	{
		title: 'Rider',
		value: 'rider',
	},
	{
		title: 'Group',
		value: 'group',
	},
];
const report_filters = [
	{
		multi: true,
		param: 'date',
		title: 'Created on',
		type: 'date',
	},
	{
		multi: false,
		param: 'status_id',
		title: 'Route Status',
		type: 'radio',
		is_mandatory: true,
		values: [
			{ title: 'Unallocated', value: 20 },
			{ title: 'Active', value: 80 },
			{ title: 'Completed', value: 190 },
		],
	},
];

const GroupingAndAllocation = ({ history }) => {
	const from_sidebar = history.location.state?.from_sidebar;
	let { bundle_id: selected_bundle_id, type: selected_menu = MENU.unallocated } = qs.parse(history.location.search.slice(1));
	if (selected_bundle_id) {
		selected_bundle_id = Number(selected_bundle_id);
	}
	const dispatch = useDispatch();

	const [allocate_rider_details, set_allocate_rider_details] = React.useState(_.cloneDeep(initial_allocate_rider_details));
	const [captive_riders, set_captive_riders] = React.useState(null);
	const [show_premium_feature, set_show_premium_feature] = React.useState(false);
	const [bundles, set_bundles] = React.useState([]);
	const [refresh, set_refresh] = React.useState(false);
	const [selected_trip_ids, set_selected_trip_ids] = useState([]);
	const [active_bundle, set_active_bundle] = useState({});
	const [refresh_trips, set_refresh_trips] = useState(false);
	const [selected_bundle_list, set_selected_bundle_list] = useState(['', '', '', '', '', '', '', '', '', '']);
	const [drag_trip_id, set_drag_trip_id] = useState(null);
	const [active_pickup, set_active_pickup] = useState(null);
	const [drag_enter, set_drag_enter] = useState(null);
	const [drop_bundle, set_drop_bundle] = useState(null);
	const [show_confirmation, set_show_confirmation] = useState(false);
	const [search, set_search] = useState('');
	const [search_rider, set_search_rider] = useState('');
	const [search_rider_type, set_search_rider_type] = useState(null);
	const [search_date, set_search_date] = useState(null);
	const [last_updated_bundle, set_last_updated_bundle] = useState({});
	const [active_bundle_data, set_active_bundle_data] = useState(null);
	const [selected_ids_list_data, set_selected_ids_list_data] = useState(_.get(history, 'location.state.selected_ids_list_data', []));
	const [active_pbid_list, set_active_pbid_list] = useState([]);
	const [count, set_count] = useState(0);
	const [show_pbid_move_confirm, set_show_pbid_move_confirm] = useState(false);
	const [confirm_remove_squence_details, set_confirm_remove_squence_details] = React.useState({ ...initial_confirm_remove_squence_details });
	const [report_items, set_report_items] = useState([]);
	const [show_report_modal, set_show_report_modal] = useState(false);
	const interval_id = useRef();
	const [show_bulk_Action_modal, set_show_bulk_Action_modal] = useState(false);
	const classes = useStyles();
	const premium_features_blocked = _.isEmpty(captive_riders);
	const menu_anchor_ref = useRef(null);
	const [show_advanced_menu, set_show_advanced_menu] = useState(false);
	const [show_delivery_simulator, set_show_delivery_simulator] = useState(false);
	const [simulator_configs, set_simulator_configs] = useState({});
	const [simulation_data, set_simulation_data] = useState([]);
	const [is_simulation_mode, set_is_simulation_mode] = useState(false);
	const [show_simulator_exit_confirm, set_show_simulator_exit_confirm] = useState(false);
	const [show_empty_state, set_show_empty_state] = useState(false);

	const { is_loading } = useSelector((state) => state.app, shallowEqual);

	const handle_pbid_select = (pbid) => {
		const index = _.indexOf(active_pbid_list, pbid);
		const _active_pbid_list = [...active_pbid_list];

		if (index === -1) {
			_active_pbid_list.push(pbid);
		} else {
			_active_pbid_list.splice(index, 1);
		}
		set_active_pbid_list(_active_pbid_list);
	};

	const on_bundle_select = (bundle) => {
		set_active_bundle(bundle);
		set_selected_trip_ids([]);
		if (selected_menu === MENU.compare || selected_menu === MENU.add) {
			if (!_.includes(selected_bundle_list, bundle.id)) {
				let new_bundle_list = [...selected_bundle_list];
				let index = _.indexOf(selected_bundle_list, '');
				new_bundle_list[index] = bundle.id;
				if (_.indexOf(selected_bundle_list, '') === -1) {
					return;
				}
				set_selected_bundle_list(new_bundle_list);
			} else {
				if (selected_bundle_id === bundle.id) {
					history.push(Routes.GROUPING_AND_ALLOCATION.path + `/?type=${selected_menu}`);
				} else {
					let new_bundle_list = [...selected_bundle_list];
					let index = _.indexOf(selected_bundle_list, bundle.id);
					new_bundle_list[index] = '';
					set_selected_bundle_list(new_bundle_list);
				}
			}
		} else {
			history.push(Routes.GROUPING_AND_ALLOCATION.path + `/?type=${selected_menu}&bundle_id=${bundle.id}`);
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			set_show_empty_state(true);
		}, 100);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	useEffect(async () => {
		if (
			_.filter(selected_bundle_list, (value) => {
				return value !== '';
			}).length === 1
		) {
			try {
				const res = await api_requests.get_bundles_data(selected_bundle_list.join(''));
				set_active_bundle_data(res.data);
			} catch (error) {}
		}
	}, selected_bundle_list);

	useEffect(() => {
		set_active_bundle(
			_.filter(bundles, (bundle, index) => {
				return bundle.id === selected_bundle_id;
			})[0],
		);
		return () => clearTimeout(interval_id.current);
	}, [bundles]);

	useEffect(() => {
		if (!_.isEmpty(simulator_configs)) handle_simulate_delivery();
	}, [simulator_configs]);

	const handle_simulate_delivery = async () => {
		try {
			const res = await api_requests.get_delivery_simulation(simulator_configs);
			set_simulation_data(res.data);
			set_show_delivery_simulator(false);
			set_is_simulation_mode(true);
		} catch (err) {}
	};

	const handle_exit_simulator = () => {
		set_simulation_data([]);
		set_is_simulation_mode(false);
	};

	const update_simulator_bundle = async (selected_bundle_id, drop_bundle_id) => {
		try {
			const res = await api_requests.get_delivery_simulation({ ...simulator_configs, bundles: [selected_bundle_id, drop_bundle_id].join(',') });
			let _simulation_data = _.filter(simulation_data, (data) => {
				return !(data.bundle_id == selected_bundle_id || data.bundle_id == drop_bundle_id);
			});
			set_simulation_data([..._simulation_data, ...res.data]);
		} catch (err) {}
	};

	const update_bundle_trips = async () => {
		if (selected_menu !== MENU.add) {
			let data = {
				bundle_id: drop_bundle.id,
				pbids: selected_trip_ids.length > 0 ? selected_trip_ids : [drag_trip_id],
			};
			try {
				const res = await api_requests.update_bundle_trips(data);
				if (res.success) {
					set_last_updated_bundle({ id: drop_bundle.id, count: selected_trip_ids.length > 0 ? selected_trip_ids.length : 1 });
					interval_id.current = setTimeout(() => {
						set_last_updated_bundle({});
					}, 60000);
					set_selected_trip_ids([]);
					set_refresh_trips(!refresh_trips);
					set_show_confirmation(false);
					set_show_pbid_move_confirm(false);
					if (is_simulation_mode) update_simulator_bundle(selected_bundle_id, drop_bundle.id);
				}
			} catch (err) {}
		} else {
			let data = {
				bundle_id: drop_bundle.id,
				pbids: active_pbid_list,
			};
			try {
				const res = await api_requests.update_bundle_trips(data);
				if (res.success) {
					set_last_updated_bundle({ id: drop_bundle.id, count: active_pbid_list.length > 0 ? active_pbid_list.length : 1 });
					interval_id.current = setTimeout(() => {
						set_last_updated_bundle({});
					}, 60000);
					let _selected_ids_list_data = [];
					_.forEach(selected_ids_list_data, (data) => {
						if (!_.includes(active_pbid_list, data.mcp_id)) {
							_selected_ids_list_data.push(data);
						}
					});
					set_active_pbid_list([]);
					set_show_pbid_move_confirm(false);
					set_refresh_trips(!refresh_trips);
					set_show_confirmation(false);
					set_selected_ids_list_data(_selected_ids_list_data);
					history.push(`${Routes.GROUPING_AND_ALLOCATION.path}?type=add`, { selected_ids_list_data: _selected_ids_list_data });
				}
			} catch (err) {}
		}
	};

	const check_disabled = (item) => {
		if (
			selected_menu === MENU.compare &&
			_.filter(selected_bundle_list, (value) => {
				return value !== '';
			}).length > 4 &&
			_.indexOf(selected_bundle_list, item.id) < 0
		) {
			return true;
		}
		if (selected_menu === MENU.compare && active_pickup && _.indexOf(selected_bundle_list, item.id) < 0) {
			return true;
		}
		if (!active_pickup) {
			return false;
		}
		if (
			parseFloat(active_pickup.latitude).toFixed(4) === parseFloat(item.task_location.latitude).toFixed(4) &&
			parseFloat(active_pickup.longitude).toFixed(4) === parseFloat(item.task_location.longitude).toFixed(4)
		)
			return false;
		return true;
	};

	const handle_delete_click = () => {
		if (premium_features_blocked) {
			set_show_premium_feature(true);
			return;
		}
		set_confirm_remove_squence_details({
			open: true,
			trip_ids: _.map(active_bundle_data.tripData, 'trip_id'),
		});
	};

	const handle_select_trip = (trip_id) => {
		if (!trip_id) return;
		const index = _.indexOf(selected_trip_ids, trip_id);
		const _selected_trip_ids = [...selected_trip_ids];

		if (index === -1) {
			_selected_trip_ids.push(trip_id);
		} else {
			_selected_trip_ids.splice(index, 1);
		}

		set_selected_trip_ids(_selected_trip_ids);
	};

	const handle_confirm_close = async () => {
		try {
			const res = await api_requests.ungroup_trip({
				trips: confirm_remove_squence_details.trip_ids,
			});
			if (res.success) {
				if (active_bundle_data.tripData.length === confirm_remove_squence_details.trip_ids.length) {
					set_selected_bundle_list(['', '', '', '', '']);
				}
				set_refresh(!refresh);
				history.push(Routes.GROUPING_AND_ALLOCATION.path + `/?type=${selected_menu}`);
			}
		} catch (err) {}
	};

	const get_bundles = async () => {
		dispatch(show_loader());
		await api_requests
			.get_bundles({
				status_id: selected_menu === MENU.unallocated || selected_menu === MENU.compare || selected_menu === MENU.add ? 20 : 80,
			})
			.then((res) => {
				set_bundles(res.data);
				dispatch(hide_loader());
			});
		return Promise.resolve();
	};

	useEffect(() => {
		const available_bundle_list = _.map(bundles, (bundle) => {
			return bundle.id;
		});
		let new_selected_bundle_list = [...selected_bundle_list];
		_.forEach(new_selected_bundle_list, (bundle_id, index) => {
			if (!_.includes(available_bundle_list, bundle_id)) {
				new_selected_bundle_list[index] = '';
			}
		});
		set_selected_bundle_list(new_selected_bundle_list);
	}, [bundles]);

	useEffect(() => {
		api_requests.get_captive_riders().then((res) => set_captive_riders(res.data));
	}, []);

	useEffect(() => {
		get_bundles();
		if (selected_menu != MENU.unallocated) {
			handle_exit_simulator();
		}
	}, [selected_menu, refresh, refresh_trips]);

	const bundle_grouped = _.groupBy(_.orderBy(bundles, 'created_at', 'desc'), (v) => {
		return moment(v.created_at).format('DD/MM/YYYY');
	});

	const handle_back = () => {
		if (selected_menu === 'active') {
			history.push(Routes.POWERED_WITH_PIDGE.path + `?type=` + POWERED_WITH_PIDGE_MENU_ITEMS.OnGround.id);
		} else {
			history.push(Routes.POWERED_WITH_PIDGE.path + `?type=` + POWERED_WITH_PIDGE_MENU_ITEMS.ReadyToShip.id);
		}
	};

	const apply_filter = (bundle, index) => {
		if (check_disabled(bundle) && selected_menu === MENU.add) {
			return false;
		}
		if (search) {
			if (!bundle.bundle_label.toLowerCase().includes(search.toLowerCase()) && !bundle.id.toString().toLowerCase().includes(search.toLowerCase())) {
				return false;
			}
		}
		if (search_rider) {
			if (bundle.rider_id != search_rider) {
				return false;
			}
		}
		if (search_rider_type) {
			if (search_rider_type === 'unlinked') {
				if (bundle.rider_id) {
					return false;
				}
			}
			if (search_rider_type === 'linked') {
				if (!bundle.rider_id) {
					return false;
				}
			}
		}
		if (search_date) {
			if (
				moment(moment(bundle.created_at).format('DD/MM/YYYY'), 'DD/MM/YYYY').diff(
					moment(moment(search_date).format('DD/MM/YYYY'), 'DD/MM/YYYY'),
					'day',
				) != 0
			) {
				return false;
			}
		}

		return true;
	};

	const handle_allocate_rider_click = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_COMP_ALLOCATE);
		if (premium_features_blocked) {
			set_show_premium_feature(true);
			return;
		}
		set_allocate_rider_details({
			open: true,
			bundle_id: Number(selected_bundle_list.join('')),
			tripData: active_bundle_data.tripData,
		});
	};

	const get_filter_items = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_REPORT);
		try {
			const response = await api_requests.get_route_export_items();
			if (response.success) {
				set_report_items(response.data);
				set_show_report_modal(true);
			}
		} catch (error) {}
	};

	const handle_export = async (selected_filters, callback) => {
		try {
			const response = await api_requests.export_route(selected_filters);
			utils.downloadBlob(response, 'routes.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	return (
		<div className={classes.wrapper}>
			<div style={{ padding: 32 }}>
				<div className={classes.header}>
					{!from_sidebar && (
						<div className='page_back_btn' onClick={handle_back}>
							<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
						</div>
					)}
					<Text
						semi
						className='global_header_text'
						style={{ marginBottom: 0 }}
						onClick={() => {
							set_active_pickup(null);
							history.push('/grouping-and-allocation?type=unallocated');
						}}>
						Routes{' '}
					</Text>
					{selected_menu === MENU.add && (
						<Text bold component={'span'} style={{ fontSize: 22 }}>
							{' '}
							|{' '}
						</Text>
					)}
					{selected_menu === MENU.add && (
						<Text bold component={'span'} className={classes.add_existing_text} style={{ fontSize: 22 }}>
							Add to Existing
						</Text>
					)}

					{selected_menu !== MENU.add && (
						<ContainedButton
							onClick={() => {
								set_drag_trip_id(null);
								set_selected_bundle_list(['', '', '', '', '']);
								history.push(Routes.GROUPING_AND_ALLOCATION.path + `/?type=${MENU.unallocated}`, {
									from_sidebar,
								});
							}}
							style={{
								height: 35,
								borderRadius: 8,
								marginLeft: 20,
								...(selected_menu !== MENU.unallocated &&
									selected_menu !== MENU.compare && { backgroundColor: 'transparent', boxShadow: 'none' }),
							}}>
							<Text style={{ ...(selected_menu !== MENU.unallocated && selected_menu !== MENU.compare && { color: theme.colors.lightPurple6 }) }}>
								Unallocated
							</Text>
						</ContainedButton>
					)}

					{selected_menu !== MENU.compare && selected_menu !== MENU.add && (
						<ContainedButton
							onClick={() =>
								history.push(Routes.GROUPING_AND_ALLOCATION.path + `/?type=${MENU.active}`, {
									from_sidebar,
								})
							}
							style={{
								height: 35,
								borderRadius: 8,
								marginLeft: 10,
								...(selected_menu !== MENU.active && { backgroundColor: 'transparent', boxShadow: 'none' }),
							}}>
							<Text style={{ ...(selected_menu !== MENU.active && { color: theme.colors.lightPurple6 }) }}>Active</Text>
						</ContainedButton>
					)}
					<div style={{ margin: 'auto' }}>
						{selected_menu === MENU.add && (
							<IdList
								history={history}
								set_active_pickup={set_active_pickup}
								selected_ids_list_data={selected_ids_list_data}
								handle_pbid_select={handle_pbid_select}
								set_active_pbid_list={set_active_pbid_list}
								active_pbid_list={active_pbid_list}
								count={count}
								set_count={set_count}
							/>
						)}
					</div>
					{is_simulation_mode && (
						<div className={classes.simulator_toast_wrap}>
							<div className={classes.red_dot_small}></div>
							<Text medium>
								{
									_.concat(
										..._.map(simulation_data, (item) => {
											return item.mcp;
										}),
									).length
								}{' '}
								orders breaching{' '}
								<Text bold component={'span'}>
									{moment(simulator_configs.start_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YY hh:mmA')}
								</Text>{' '}
								dispatch.
							</Text>
							<Text
								semi
								onClick={() => set_show_simulator_exit_confirm(true)}
								style={{ textDecoration: 'underline', color: theme.colors.primary, cursor: 'pointer' }}>
								Exit Simulator
							</Text>
							<img src={ImageLinks.arrow_right_purple} />
						</div>
					)}
					{!_.isEmpty(bundle_grouped) && (
						<div className='d-flex align-items-center' style={{ marginLeft: 'auto' }}>
							{selected_menu == MENU.unallocated && !is_simulation_mode && (
								<div ref={menu_anchor_ref}>
									<Button
										type='text'
										text='Advanced'
										size='large'
										right_icon={ImageLinks.chevronLeft}
										onClick={() => set_show_advanced_menu(true)}
										right_image_styles={{ transform: 'rotate(270deg)' }}
										style={{ marginRight: 10 }}
									/>
								</div>
							)}
							<Popover
								anchorEl={menu_anchor_ref.current}
								open={show_advanced_menu}
								onClose={() => set_show_advanced_menu(false)}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}>
								<MenuItem
									onClick={() => {
										utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_ADV_COMPARE);
										set_show_advanced_menu(false);
										history.push(Routes.GROUPING_AND_ALLOCATION.path + `/?type=${MENU.compare}`);
									}}>
									<Text
										semi
										style={{
											color: 'inherit',
											marginLeft: 5,
										}}>
										Compare Routes
									</Text>
								</MenuItem>
								<MenuItem
									onClick={() => {
										utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_ADV_SIMUL);
										set_show_delivery_simulator(true);
										set_show_advanced_menu(false);
									}}>
									<Text
										semi
										style={{
											color: 'inherit',
											marginLeft: 5,
										}}>
										Delivery Simulator
									</Text>
								</MenuItem>
							</Popover>

							{selected_menu === MENU.unallocated && bundles.length > 0 && (
								<Button
									type='round'
									size='large'
									text='Bulk Action'
									onClick={() => {
										utils.track_event_for_analytics(constant.TRACKING_EVENTS.ROUTE_BULK);
										set_show_bulk_Action_modal(true);
									}}
								/>
							)}
							{selected_menu === MENU.compare &&
								_.filter(selected_bundle_list, (value) => {
									return value !== '';
								}).length === 1 && (
									<>
										<div style={{ marginRight: 10 }}>
											<IconButton
												onClick={handle_delete_click}
												style={{
													backgroundColor: theme.colors.lightRed,
													borderColor: 'transparent',
													height: 40,
													width: 40,
													cursor: 'pointer',
												}}>
												<img
													src={ImageLinks.delete}
													width={18}
													height={18}
													style={{ cursor: 'pointer' }}
													onClick={handle_delete_click}
												/>
											</IconButton>
										</div>
										<Button
											type='round'
											text='Allocate Rider'
											size='large'
											onClick={handle_allocate_rider_click}
											right_icon={ImageLinks.arrow_right_white_no_fill}
											style={{ marginRight: 10 }}
										/>
									</>
								)}
							{selected_menu === MENU.compare && (
								<Button
									type='round'
									text='Exit Comparison'
									size='large'
									onClick={() => {
										history.push(
											Routes.GROUPING_AND_ALLOCATION.path +
												`/?type=${MENU.unallocated}${selected_bundle_id ? '&bundle_id=' + selected_bundle_id : ''}`,
										);
										set_selected_bundle_list(['', '', '', '', '']);
									}}
									left_icon={ImageLinks.cross_white}
								/>
							)}
						</div>
					)}

					<BootstrapTooltip title={'Get Report'}>
						<div className={classes.graph_circle} onClick={get_filter_items}>
							<img className='purple-download' src={ImageLinks.download} />
							<img className='white-download' style={{ display: 'none' }} width={20} height={20} src={ImageLinks.download_white} alt='' />
						</div>
					</BootstrapTooltip>
				</div>
			</div>
			<div className={classes.content}>
				<div className={classes.bundlelist_wrap}>
					<BundleListFilter
						search={search}
						search_rider={search_rider}
						set_search_rider={set_search_rider}
						set_search={set_search}
						search_rider_type={search_rider_type}
						set_search_rider_type={set_search_rider_type}
						search_date={search_date}
						set_search_date={set_search_date}
						SEARCH_BY_OPTIONS={SEARCH_BY_OPTIONS}
						check_disabled={check_disabled}
						bundles={bundles}
						selected_menu={selected_menu}
						MENU={MENU}
					/>
					{_.filter(bundles, apply_filter).length === 0 && !is_loading && show_empty_state && (
						<div className='d-flex align-items-center justify-content-center' style={{ flexDirection: 'column', height: '60vh' }}>
							<img src={ImageLinks.no_routes_circle} width={94} height={94} />
							<div style={{ textAlign: 'center' }}>
								<Text bold style={{ color: theme.colors.primary, fontSize: 16 }}>
									No existing routes found
								</Text>
							</div>
							<Helpcenter text='Explore routes' url='https://pidge.in/helpcenter-routes-createroutes' style={{ marginTop: 30 }} />
						</div>
					)}
					{_.map(bundle_grouped, (bundleGroup, date) => {
						return (
							<BundleList
								key={`bundleGroup${bundleGroup[0].id}`}
								bundleGroup={bundleGroup}
								search={search}
								search_rider={search_rider}
								search_rider_type={search_rider_type}
								search_date={search_date}
								date={date}
								selected_bundle_id={selected_bundle_id}
								selected_bundle_list={selected_bundle_list}
								on_bundle_select={on_bundle_select}
								selected_menu={selected_menu}
								history={history}
								active_pickup={active_pickup}
								set_drag_enter={set_drag_enter}
								set_drop_bundle={set_drop_bundle}
								last_updated_bundle={last_updated_bundle}
								set_last_updated_bundle={set_last_updated_bundle}
								set_show_confirmation={set_show_confirmation}
								set_show_pbid_move_confirm={set_show_pbid_move_confirm}
								bundles={bundles}
								simulation_data={simulation_data}
							/>
						);
					})}
				</div>

				{_.isEmpty(bundle_grouped) && (
					<div className={classes.wrapper_empty_state}>
						<EmptyState empty_img={ImageLinks.empty_allocate} />
						<Helpcenter text='Explore routes' url='https://pidge.in/helpcenter-routes-createroutes' />
					</div>
				)}

				{selected_menu === MENU.unallocated && !_.isEmpty(bundle_grouped) && (
					<UnallocatedRoutes
						is_simulation_mode={is_simulation_mode}
						update_simulator_bundle={update_simulator_bundle}
						key={selected_bundle_id}
						selected_trip_ids={selected_trip_ids}
						handle_select_trip={handle_select_trip}
						set_active_pickup={set_active_pickup}
						bundle_id={selected_bundle_id}
						bundle_label={(_.find(bundles, { id: selected_bundle_id }) || {}).bundle_label || '-'}
						get_bundles={get_bundles}
						set_allocate_rider_details={set_allocate_rider_details}
						allocate_rider_details={allocate_rider_details}
						set_refresh_bundles={set_refresh}
						premium_features_blocked={premium_features_blocked}
						handle_show_premium_feature={() => set_show_premium_feature(true)}
						refresh_trips={refresh_trips}
						set_drag_trip_id={set_drag_trip_id}
						set_refresh_trips={set_refresh_trips}
						bundles={bundles}
						simulation_data={simulation_data}
						selected_menu={selected_menu}
						set_active_bundle={set_active_bundle}
					/>
				)}
				{selected_menu === MENU.compare && !_.isEmpty(bundle_grouped) && (
					<CompareRoutes
						bundle_id={selected_bundle_id}
						selected_bundle_list={selected_bundle_list}
						set_drag_trip_id={set_drag_trip_id}
						refresh_trips={refresh_trips}
						set_active_pickup={set_active_pickup}
						history={history}
						premium_features_blocked={premium_features_blocked}
						handle_show_premium_feature={() => set_show_premium_feature(true)}
						refresh={refresh}
						set_refresh={set_refresh}
						bundle_label={(_.find(bundles, { id: selected_bundle_id }) || {}).bundle_label || '-'}
					/>
				)}
				{selected_menu === MENU.add && !_.isEmpty(bundle_grouped) && (
					<AddRouteMap
						selected_ids_list_data={selected_ids_list_data}
						active_pbid_list={active_pbid_list}
						selected_bundle_list={selected_bundle_list}
						refresh_trips={refresh_trips}
						count={count}
						last_updated_bundle={last_updated_bundle}
						selected_menu={selected_menu}
						history={history}
					/>
				)}

				{selected_menu === MENU.active && !_.isEmpty(bundle_grouped) && (
					<ActiveRoutes
						key={selected_bundle_id}
						bundle_id={selected_bundle_id}
						bundle_label={(_.find(bundles, { id: selected_bundle_id }) || {}).bundle_label || '-'}
						get_bundles={get_bundles}
						set_allocate_rider_details={set_allocate_rider_details}
						set_refresh_bundles={set_refresh}
						selected_menu={selected_menu}
						premium_features_blocked={premium_features_blocked}
						handle_show_premium_feature={() => set_show_premium_feature(true)}
						simulation_data={simulation_data}
						set_active_bundle={set_active_bundle}
					/>
				)}
			</div>
			{allocate_rider_details.open && bundles.length > 0 && (
				<AllocateRider
					close={() => set_allocate_rider_details(_.cloneDeep(initial_allocate_rider_details))}
					bundle_id={allocate_rider_details.bundle_id}
					tripData={allocate_rider_details.tripData}
					bundles={bundles}
					set_refresh={set_refresh}
					selected_menu={selected_menu}
					get_bundles={get_bundles}
				/>
			)}
			{confirm_remove_squence_details.open && (
				<ConfirmClose
					style={{ width: 600 }}
					close={() => set_confirm_remove_squence_details({ ...initial_confirm_remove_squence_details })}
					on_confirm={handle_confirm_close}
					headerTitle={'Delete Route'}
					title={
						<Text medium className={classes.modal_text}>
							<img src={ImageLinks.exclamation_error} width='20' style={{ marginRight: 10 }} />
							Are you sure you want to remove{' '}
							{confirm_remove_squence_details.trip_ids.length > 1 ? confirm_remove_squence_details.trip_ids.length : 'this'} order from the
							sequence?
						</Text>
					}
					titleStyles={{ width: '100%' }}
				/>
			)}
			{last_updated_bundle.count && selected_menu != MENU.add && (
				<Toast>
					<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
					<Text component={'span'} semi style={{ marginLeft: '10px' }}>
						<Text component={'span'} semi style={{ color: theme.colors.primary }}>
							{last_updated_bundle.count} orders
						</Text>{' '}
						moved successfully.{' '}
						<Text
							component={'span'}
							semi
							style={{ color: theme.colors.primary, textDecoration: 'underline', cursor: 'pointer' }}
							onClick={() =>
								history.push(
									Routes.GROUPING_AND_ALLOCATION.path +
										`/?type=${selected_menu !== MENU.add ? selected_menu : MENU.unallocated}&bundle_id=${last_updated_bundle.id}`,
								)
							}>
							View
						</Text>
					</Text>
				</Toast>
			)}
			{show_bulk_Action_modal && (
				<BulkActionModal
					handle_close={() => {
						set_refresh_trips((prev) => !prev);
						set_show_bulk_Action_modal(false);
					}}
					set_refresh={set_refresh}
				/>
			)}
			{show_confirmation && (
				<ConfirmationBox
					on_confirm={() => update_bundle_trips()}
					on_close={() => set_show_confirmation(false)}
					taget_order={drop_bundle.bundle_label}
					orders={selected_trip_ids.length > 0 ? selected_trip_ids : [drag_trip_id]}
					from_order={_.get(active_bundle, 'bundle_label')}
				/>
			)}
			{show_pbid_move_confirm && (
				<ConfirmationBox
					on_confirm={() => update_bundle_trips()}
					on_close={() => set_show_pbid_move_confirm(false)}
					taget_order={drop_bundle.bundle_label}
					orders={active_pbid_list}
					from_order={_.get(active_bundle, 'bundle_label')}
					is_add_only={true}
				/>
			)}
			{show_simulator_exit_confirm && (
				<ConfirmClose
					style={{ width: 600 }}
					close={() => set_show_simulator_exit_confirm(false)}
					on_confirm={handle_exit_simulator}
					headerTitle={'Exit Delivery Simulator'}
					title={
						<Text medium className={classes.modal_text}>
							Are you sure you want to exit delivery simulation for{' '}
							<Text bold component={'span'}>
								{moment(simulator_configs.start_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YY hh:mm A')}
							</Text>
							?
						</Text>
					}
					handle_negative_action={() => {
						set_show_simulator_exit_confirm(false);
						set_show_delivery_simulator(true);
					}}
					show_negative_btn={true}
					negative_btn_text='Simulate Again'
					positive_btn_text='Exit'
					titleStyles={{ width: '100%' }}
				/>
			)}
			{show_premium_feature && <PremiumFeatureModal close={() => set_show_premium_feature(false)} />}
			{show_report_modal && (
				<FilterReportModal
					report_filters={report_filters}
					report_items={report_items}
					handle_export={handle_export}
					close={() => set_show_report_modal(false)}
					filter_from={'routes'}
					wrapper_styles={{ paddingRight: 30 }}
				/>
			)}
			{show_delivery_simulator && (
				<DeliverySimulator
					simulator_configs={simulator_configs}
					close={() => set_show_delivery_simulator(false)}
					set_simulator_configs={set_simulator_configs}
				/>
			)}
		</div>
	);
};

export default GroupingAndAllocation;
