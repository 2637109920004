import React from 'react';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import Header from './components/Header';
import Recipients from './components/Recipients';
import Trigger from './components/Trigger';
import SubjectList from './components/SubjectList';
import useCreateCommunication from './useCreateCommunication';
import CreateCommunicationContext from './context';

const useStyles = makeStyles(() => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
}));

const CreateCommunication = ({ history }) => {
	const value = useCreateCommunication();
	const classes = useStyles();

	return (
		<CreateCommunicationContext.Provider value={value}>
			<div className={`container ${classes.wrapper}`}>
				<Header />
				<div style={{ marginTop: 32, overflow: 'auto', height: '85vh' }}>
					<SubjectList />
					<Trigger />
					<Recipients />
				</div>
			</div>
		</CreateCommunicationContext.Provider>
	);
};

export default CreateCommunication;
