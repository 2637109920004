import React, { useEffect } from 'react';
import moment from 'moment';
import Text from 'modules/Text';
import theme from 'resources/theme';
import _ from 'lodash';
import { ListItem, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { hide_loader, show_loader } from 'actions/app';
import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import Filters from 'modules/Filters/Filters';
import ContainedButton from 'modules/ContainedButton';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import { ALL_ORDERS_MENU_ITEMS } from '../helper';
import OrderLabel from 'modules/OrderLabel';
import constant from 'resources/constant';
import utils from 'resources/utils';
import FilterButton from 'modules/FilterButton';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import Button from 'modules/Button';
import Helpcenter from 'modules/HelpCenter';
import NoResult from 'modules/NoResult';

const useStyles = makeStyles(() => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'hidden',
	},
	ongoingOrdersDiv: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 40,
	},
	onGoingTextContainer: {
		fontSize: 20,
	},
	filter_btn: {
		backgroundColor: theme.colors.white,
		marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		flex: 1,
		marginTop: 10,
		marginBottom: 30,
		overflowY: 'hidden',
	},
	box: {
		background: 'white',
		height: '100%',
		padding: 10,
		borderRadius: 8,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
	},
	all_orders_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		background: theme.colors.lightPurple,
		alignItems: 'center',
		borderRadius: 5,
		padding: '6px 12px',
	},
	header_wrapper: {
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		marginTop: 25,
		padding: '5px 8px',
		marginBottom: 5,
	},
	item_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 5,
		padding: '6px 12px',
	},
	item_key_text: { color: theme.colors.darkGrey2 },
	filter_wrap: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: 40,
		marginTop: -40,
	},
	detailsTextContainer: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	header_title_2: {
		flex: 2,
		color: theme.colors.darkGrey2,
		fontSize: 14,
	},
	pickedUpContainer: {
		flex: 1,
		overflowY: 'auto',
		marginTop: 10,
		marginBottom: 10,
		position: 'relative',
	},
	pickedUpItem: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	table_item_wrap_2: { flex: 2, minWidth: 0 },
	orderDetailsClickabletext: {
		flex: 1,
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
	truncate: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 14,
	},
	order_analytics_text: {
		fontSize: 18,
		textDecoration: 'underline',
		color: theme.colors.primary,
	},
}));

const Overview = ({ reference_id = '', report_items = [] }) => {
	const { is_loading } = useSelector((state) => state.app, shallowEqual);
	const dispatch = useDispatch();

	const [all_filters, set_all_filters] = React.useState([]);
	const [selected_filters, set_selected_filters] = React.useState({});
	const [show_filters, set_show_filters] = React.useState(false);
	const [order_stats, set_order_stats] = React.useState({});
	const [orders, set_orders] = React.useState({});
	const [show_order_details, set_show_order_details] = React.useState(false);
	const [selected_pb_id, set_selected_pb_id] = React.useState('');
	const [order_list, set_order_list] = React.useState([]);
	const slider_ref = React.useRef();
	const loading_scroll = React.useRef(false);
	const [show_empty_state, set_show_empty_state] = React.useState(false);

	useEffect(() => {
		fetch_filters();
		get_orders_stats();
		const timer = setTimeout(() => {
			set_show_empty_state(true);
		}, 100);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	const fetch_filters = () => {
		try {
			api_requests.all_orders_filter('overview').then((res) => set_all_filters(res.data.filters));
		} catch (error) {}
	};

	const get_orders_stats = () => {
		set_order_stats({});
		api_requests
			.get_all_orders_overview_orders_stats({
				created_at: moment().subtract('15', 'days').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'),
			})
			.then((res) => {
				set_order_stats(res.data);
			});
	};

	const get_orders = ({ token }) => {
		dispatch(show_loader());
		set_orders({});
		let created_at_date_filter = moment().subtract('15', 'days').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
		if (!_.isEmpty(_.get(selected_filters, 'created_at', null))) created_at_date_filter = selected_filters.created_at;

		api_requests
			.get_pending_orders_search({
				...(reference_id && { ref_id: reference_id }),
				...selected_filters,
				...(!reference_id && { limit: 50 }),
				...(token && { token }),
				...(!reference_id && selected_filters.length == 0 && { created_at: created_at_date_filter }),
				...(_.isEmpty(selected_filters) && { group: 'overview' }),
			})
			.then((res) => {
				set_orders(res.data);
				dispatch(hide_loader());
				loading_scroll.current = false;
				if (token) {
					set_order_list(_.concat(order_list, res.data.items));
				} else {
					set_order_list(res.data.items);
				}
			});
	};

	const handle_export = (filters, callback) => {
		api_requests
			.export_all_orders({ ...filters })
			.then((res) => {
				utils.downloadBlob(res, 'orders.csv', 'text/csv');
				callback(true);
			})
			.catch((err) => {
				callback(false);
			});
	};

	useEffect(() => {
		get_orders({});
	}, [selected_filters, reference_id]);

	const on_update = () => {
		get_orders({});
	};

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && orders.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_orders({ token: orders.next });
		}
	};

	const classes = useStyles();

	const history = useHistory();

	return (
		<>
			<div className={classes.filter_wrap}>
				<FilterButton
					handle_click={() => set_show_filters(true)}
					total_filters={Object.keys(selected_filters).length}
					track_event={{ event: 'all_order_overview_filter', properties: selected_filters }}
				/>
			</div>

			<div className={classes.container}>
				<div className={classes.wrapper}>
					<div style={{ width: 280, marginRight: 20, marginLeft: 4, marginBottom: 50 }}>
						<div className={classes.ongoingOrdersDiv}>
							<Text semi style={{ fontSize: 18, color: theme.colors.lightPurple7 }}>
								Last 15 day Order Overview
							</Text>
						</div>

						<div className={classes.box}>
							<div className={classes.all_orders_wrapper}>
								<Text semi style={{ color: theme.colors.darkGrey2 }}>
									Total Orders
								</Text>
								<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
									{_.get(order_stats, 'stats.total_order', 0)}
								</Text>
							</div>

							<div className={classes.header_wrapper}>
								<Text semi>TO BE PROCESSED</Text>
							</div>
							<div className='d-flex justify-content-between' style={{ flexDirection: 'column', height: '74%' }}>
								<div>
									<ListItem
										button
										onClick={() => history.push(Routes.ALL_ORDERS.path + '?type=' + ALL_ORDERS_MENU_ITEMS.Pending.id)}
										className={classes.item_wrapper}>
										<Text semi className={classes.item_key_text}>
											Pending
										</Text>
										<div className='align-items-center'>
											<Text bold style={{ fontSize: 20, marginRight: 5 }}>
												{_.get(order_stats, 'stats.pending', 0)}
											</Text>
											<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
										</div>
									</ListItem>
									<ListItem
										button
										onClick={() => history.push(Routes.ALL_ORDERS.path + '?type=' + ALL_ORDERS_MENU_ITEMS.Pending.id)}
										className={classes.item_wrapper}>
										<Text semi className={classes.item_key_text}>
											Processing
										</Text>
										<div className='align-items-center'>
											<Text bold style={{ fontSize: 20, marginRight: 5 }}>
												{_.get(order_stats, 'stats.processing', 0)}
											</Text>
											<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
										</div>
									</ListItem>
									<ListItem
										button
										onClick={() => history.push(Routes.ALL_ORDERS.path + '?type=' + ALL_ORDERS_MENU_ITEMS.ReadyToShip.id)}
										className={classes.item_wrapper}>
										<Text semi className={classes.item_key_text}>
											AWB Manifested
										</Text>
										<div className='align-items-center'>
											<Text bold style={{ fontSize: 20, marginRight: 5 }}>
												{_.get(order_stats, 'stats.ready_to_ship', 0)}
											</Text>
											<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
										</div>
									</ListItem>

									<ListItem
										button
										onClick={() => history.push(Routes.ALL_ORDERS.path + '?type=' + ALL_ORDERS_MENU_ITEMS.Shipped.id)}
										className={classes.item_wrapper}>
										<Text semi>SHIPPED</Text>
										<div className='align-items-center'>
											<Text bold style={{ fontSize: 20, marginRight: 5 }}>
												{_.get(order_stats, 'stats.shipped', 0)}
											</Text>
											<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
										</div>
									</ListItem>

									<ListItem
										button
										onClick={() => history.push(Routes.ALL_ORDERS.path + '?type=' + ALL_ORDERS_MENU_ITEMS.Terminal.id)}
										className={classes.item_wrapper}>
										<Text semi>COMPLETED</Text>
										<div className='align-items-center'>
											<Text bold style={{ fontSize: 20, marginRight: 5 }}>
												{_.get(order_stats, 'stats.completed', 0)}
											</Text>
											<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
										</div>
									</ListItem>
								</div>
								<div
									className='d-flex justify-content-center align-items-center'
									onClick={() => utils.track_event_for_analytics(constant.TRACKING_EVENTS.ORDER_ANALYTICS)}>
									<Link to={Routes.ADVANCED_ORDER_ANALYTICS.path} style={{ textDecoration: 'none' }}>
										<Button type='text' text='Order Analytics' size='large' right_icon={ImageLinks.arrow_right_purple} disabled={false} />
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div style={{ flex: 1, overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
						<div className={classes.ongoingOrdersDiv}>
							<Text semi className={classes.onGoingTextContainer}>
								{/* Total Orders | {_.get(orders, 'count', '0')} */}
								Total Orders | {order_list.length} {orders.next ? ` of more` : ''}
							</Text>
						</div>
						<div className={classes.pickedUpContainer} ref={slider_ref} onScroll={slider_scrolled}>
							<div className={classes.detailsTextContainer} style={{ position: 'sticky', top: 0 }}>
								<Text bold className={classes.header_title_2}>
									Order Date
								</Text>
								<Text bold className={classes.header_title_2}>
									Channel/Ref ID
								</Text>
								<Text bold className={classes.header_title_2}>
									Pickup Date
								</Text>
								<Text bold className={classes.header_title_2}>
									Terminal Date
								</Text>
								<Text bold className={classes.header_title_2}>
									Status
								</Text>
							</div>

							{_.map(order_list, (order, key) => {
								return (
									<div key={`order${key}`} className={classes.pickedUpItem}>
										<div className={classes.table_item_wrap_2}>{moment(order.created_at).format('DD/MM/YY')}</div>
										<div className={classes.table_item_wrap_2}>
											<Text
												semi
												className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
												onClick={() => {
													if (!order.id) return;
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.OVERVIEW_ORDER_CLICK, { order_id: order.id });
													set_selected_pb_id(order.id);
													set_show_order_details(true);
												}}>
												{order.reference_id || order?.dd_channel?.order_id || order.id || '-'}
											</Text>
										</div>
										<div className={classes.table_item_wrap_2}>
											{!_.isEmpty(_.get(order, 'fulfillment.pickup.eta', null)) ? (
												<Text medium className={classes.truncate}>
													{moment(order.fulfillment.pickup.eta).format('DD/MM/YY')}
												</Text>
											) : (
												'-'
											)}
										</div>
										<div className={classes.table_item_wrap_2}>
											{!_.isEmpty(_.get(order, 'fulfillment.drop.eta', null)) ? (
												<Text medium className={classes.truncate}>
													{moment(order.fulfillment.drop.eta).format('DD/MM/YY')}
												</Text>
											) : (
												'-'
											)}
										</div>

										<div className={classes.table_item_wrap_2}>
											{order.fulfillment?.status ? <OrderLabel status={constant.ORDER_STATUS[order.fulfillment?.status]?.id} /> : '-'}
										</div>
									</div>
								);
							})}
							{order_list.length == 0 && !is_loading && show_empty_state && Object.keys(selected_filters).length == 0 && (
								<div
									className='d-flex justify-content-center align-items-center'
									style={{ position: 'absolute', left: '35%', top: '30%', flexDirection: 'column', gap: 10 }}>
									<img src={ImageLinks.empty_state_order} width={94} height={94} />
									<Text semi style={{ fontSize: 16 }}>
										No orders in last 15 days
									</Text>
									<Button
										type='round'
										text='Create Order'
										size='small'
										left_icon={ImageLinks.addWhite}
										onClick={() => {
											utils.track_event_for_analytics(constant.TRACKING_EVENTS.ALL_ORDER_EMPTY_CREATE_ORDER);
											history.push(Routes.CREATE_ORDER.path);
										}}
									/>
									<Helpcenter text='How do I manage orders?' url='https://pidge.in/helpcenter-order-allorders' style={{ marginTop: 40 }} />
								</div>
							)}
							{order_list.length == 0 && !is_loading && show_empty_state && Object.keys(selected_filters).length != 0 && (
								<div
									className='d-flex justify-content-center align-items-center'
									style={{ position: 'absolute', left: '35%', top: '30%', flexDirection: 'column', gap: 10 }}>
									<NoResult text='No order found' btn_onclick={() => set_selected_filters({})} />
								</div>
							)}
						</div>
					</div>
				</div>
				{show_filters && (
					<Filters
						source='all_orders'
						all_filters={all_filters}
						existing_filters={selected_filters}
						set_selected_filters={set_selected_filters}
						close={() => set_show_filters(false)}
						title='Overview'
						handle_export={handle_export}
						report_items={report_items}
					/>
				)}
				{show_order_details && <OrderModal id={selected_pb_id} updater={on_update} close={() => set_show_order_details(false)} />}
			</div>
		</>
	);
};

export default Overview;
