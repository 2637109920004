import React, { useEffect, useRef } from 'react';
import { useStyles } from '../styles';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import UsecaseListItem from './UsecaseListItem';
import _ from 'lodash';

const UseCase = ({ is_edit_mode, selected_usecase, show_usecase, usecases, onClick, on_usecase_select, set_show_usecase }) => {
	const component_ref = useRef(null);
	const classes = useStyles();

	useEffect(() => {
		const handle_click_outside = (event) => {
			if (component_ref.current && !component_ref.current.contains(event.target)) {
				set_show_usecase(false);
			}
		};
		document.addEventListener('click', handle_click_outside, true);
		return () => {
			document.removeEventListener('click', handle_click_outside, true);
		};
	}, []);

	return (
		<div ref={component_ref}>
			<div
				onClick={onClick}
				className={classes.use_case_wrap}
				style={
					is_edit_mode
						? {
								backgroundColor: theme.colors.lightPink4,
								border: `1px solid ${theme.colors.lightPurple6}`,
								cursor: 'auto',
						  }
						: {}
				}>
				<Text
					medium={selected_usecase === null}
					semi={selected_usecase !== null}
					style={{ color: selected_usecase === null ? theme.colors.lightPurple : theme.colors.primary, fontSize: 14 }}>
					{selected_usecase ? selected_usecase.title : 'Select'}
				</Text>
				<img
					src={ImageLinks.arrow_up_circle_solid}
					width={20}
					height={20}
					style={!show_usecase ? { transform: 'rotate(180deg)' } : {}}
					alt='drop_down'
				/>
			</div>
			{show_usecase && (
				<div className={classes.use_case_item_wrap} style={{ background: theme.colors.white }}>
					{_.map(usecases, (usecase, key) => (
						<UsecaseListItem
							key={`usecase_item_${key}`}
							is_recommended={usecase.recommanded}
							handle_usecase_select={() => on_usecase_select(usecase)}
							title={usecase.title}
							description={usecase.description}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default UseCase;
