import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import _ from 'lodash';
import EmptyState from 'modules/EmptyState';
import Helpcenter from 'modules/HelpCenter';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';

const useStyles = makeStyles({
	wrapper: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		gridGap: 30,
	},
	team_item_wrap: {
		minHeight: 107,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		padding: 16,
		paddingBottom: 18,
		marginBottom: 12,
	},
	user_icon_wrap: {
		width: 28,
		height: 28,
		borderRadius: 14,
		backgroundColor: theme.colors.lightPink,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	member_wrap: {
		height: 24,
		border: `1px solid ${theme.colors.darkPurple}`,
		borderRadius: 4,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 8,
		paddingRight: 8,
		cursor: 'pointer',
	},
	member_count_wrap: {
		width: 16,
		height: 16,
		borderRadius: 8,
		backgroundColor: theme.colors.lightPink2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	members_text: { fontSize: 12, color: theme.colors.darkPurple, marginLeft: 4, marginRight: 4 },
	empty_content: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const TeamListing = ({ teams, handle_member_press, set_show_create_team }) => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.wrapper}>
				{_.map(teams, (item, index) => (
					<div key={`team_item_${index}`} className={clsx('box-shadow', classes.team_item_wrap)}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div className={classes.user_icon_wrap}>
									<img src={ImageLinks.user_accent} width={16} height={16} />
								</div>
								<Text bold style={{ fontSize: 14, marginLeft: 6 }}>
									{item.title}
								</Text>
							</div>
							<div onClick={() => handle_member_press(item.id)} className={classes.member_wrap}>
								<div className={classes.member_count_wrap}>
									<Text bold style={{ fontSize: 10, color: theme.colors.darkPurple }}>
										{item.members}
									</Text>
								</div>
								<Text bold className={classes.members_text}>
									Members
								</Text>
								<img src={ImageLinks.chevron_right_dark_pink} width={14} height={14} />
							</div>
						</div>

						<div style={{ marginTop: 18, marginBottom: 18 }}>
							<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								{item.description}
							</Text>
						</div>

						{/* <div style={{ border: `1px solid ${theme.colors.lightGrey5}` }}></div>

					<div style={{ marginTop: 9, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Text bold style={{ fontSize: 12, color: theme.colors.primary, textDecoration: 'underline' }}>
							Settings
						</Text>

						<div className='box-shadow' style={{ width: 32, height: 32, borderRadius: 16, backgroundColor: 'red' }}></div>
					</div> */}
					</div>
				))}
			</div>
			{teams.length == 0 && (
				<div className={classes.empty_content}>
					<EmptyState
						button_text='New Team'
						left_icon={ImageLinks.addWhite}
						empty_img={ImageLinks.empty_state_teams}
						onClick={() => set_show_create_team(true)}
						button_style={{ left: 30 }}
					/>
					{/* <Helpcenter text={'Why do I need teams?'} style={{ marginTop: 40 }} /> */}
				</div>
			)}
		</>
	);
};

export default TeamListing;
