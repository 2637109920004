import { makeStyles } from '@material-ui/core';
import React from 'react';
import constant from 'resources/constant';
import Text from './Text';
const useStyles = makeStyles((theme) => ({
	container: {
		borderRadius: 20,
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '10px 20px',
		width: 'auto',
	},
}));
const OrderLabel = ({ status, paid = false }) => {
	let text = status == 0 && paid ? 'Paid & Cancelled' : constant.ORDER_STATUS_TEXT[status];
	let text_color = 'white';
	let container_color = 'lightGrey';

	switch (status) {
		case constant.ORDER_STATUS.AWB_Registered.id:
			text_color = '#492897';
			container_color = '#E4DCF3';
			break;

		case constant.ORDER_STATUS.Out_For_Pickup.id:
			text_color = '#8D027D';
			container_color = '#F8F0F7';
			break;
		case constant.ORDER_STATUS.REACHED_PICKUP.id:
			text_color = '#8D027D';
			container_color = '#F3E5F2';
			break;
		case constant.ORDER_STATUS.Picked_Up.id:
			text_color = '#8D027D';
			container_color = '#E8CCE5';
			break;

		case constant.ORDER_STATUS.In_Transit.id:
			text_color = '#0F8560';
			container_color = '#E4F5F0';
			break;

		case constant.ORDER_STATUS.REACHED_DELIVERY.id:
			text_color = '#0F8560';
			container_color = '#CEEDE3';
			break;
		case constant.ORDER_STATUS.Out_For_Delivery.id:
			text_color = '#0F8560';
			container_color = '#B1DFD1';
			break;

		case constant.ORDER_STATUS.Delivered.id:
			text_color = '#0F8560';
			container_color = '#CEEDE3';
			break;

		case constant.ORDER_STATUS.RTO_OFD.id:
		case constant.ORDER_STATUS.Undelivered.id:
		case constant.ORDER_STATUS.RTO_Initiated.id:
		case constant.ORDER_STATUS.RTO_Delivered.id:
			text_color = '#D75800';
			container_color = '#FFE3CF';
			break;

		case constant.ORDER_STATUS.RTO_Undelivered.id:
			break;

		case constant.ORDER_STATUS.LOST.id:
		case constant.ORDER_STATUS.DAMAGED.id:
			text = 'Lost/Damaged';
			text_color = '#BC0000';
			container_color = '#FFEAEC';
			break;

		case constant.ORDER_STATUS.Cancelled.id:
			text_color = '#BC0000';
			container_color = '#FFEAEC';
			break;

		case constant.ORDER_STATUS.Disposed_off.id:
			break;

		case constant.ORDER_STATUS.Pick_Up_Exception.id:
			break;

		default:
			break;
	}
	const classes = useStyles();
	return (
		<div
			className={classes.container}
			style={{
				background: container_color,
			}}>
			<Text bold style={{ color: text_color, fontSize: 12 }}>
				{text}
			</Text>
		</div>
	);
};

export default OrderLabel;
