import React, { useMemo, useState } from 'react';
import { makeStyles, Modal, ListItem } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import _ from 'lodash';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import { v4 as uuidv4 } from 'uuid';
import constant from 'resources/constant';
import moment from 'moment';
import OutlinedButton from 'modules/OutlinedButton';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 20,
		background: theme.colors.white,
		width: 580,
		height: 460,
		margin: 'auto auto',
		borderRadius: 20,
	},
	selected_drop_wrapper: {
		padding: 12,
		border: `1px solid ${theme.colors.lightGrey7}`,
		borderRadius: 6,
		marginTop: 16,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
	},
	address_text: {
		color: theme.colors.lightGrey9,
		fontSize: 12,
		marginTop: 4,
	},
	list_wrap: { overflowY: 'auto', height: 280 },
	cta_btn: {
		borderRadius: 30,
		marginTop: 20,
		height: 40,
		padding: '8px 32px',
		'&:disabled': {
			color: theme.colors.darkGrey2,
		},
	},
	volume_wrap: {
		height: 22,
		borderRadius: 4,
		backgroundColor: theme.colors.lightPink,
		width: 110,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	item_label: { fontSize: 12, marginLeft: 2, color: theme.colors.darkGrey2 },
	info_wrap: { display: 'flex', justifyContent: 'space-between', width: '100%' },
	separator: { border: `1px solid ${theme.colors.lightPurple}`, marginTop: 8, marginBottom: 8, width: '100%' },
}));

const DeliverySavedAddresses = ({
	close,
	search_address,
	contact_number,
	on_address_select,
	set_local_receiver_name,
	set_local_receiver_contact,
	on_place_order,
	is_item_added,
}) => {
	const classes = useStyles();
	const [selected_address, set_selected_address] = useState({});

	const handleClose = () => {
		close();
	};

	const handle_saved_delivery_address = () => {
		const customer_addr = _.get(selected_address, 'customer_detail.address', {});
		delete customer_addr.business_zone;
		delete customer_addr.master_zone;
		delete customer_addr.network_business_zone;
		delete customer_addr.network_master_zone;

		on_address_select({
			address_line1: _.get(selected_address, 'customer_detail.address.address_line_1', ''),
			coordinates: {
				latitude: _.get(selected_address, 'customer_detail.address.latitude', ''),
				longitude: _.get(selected_address, 'customer_detail.address.longitude', ''),
			},
			...customer_addr,
			selected_place: {
				state: _.get(selected_address, 'customer_detail.address.state', 'Delhi'),
				city: _.get(selected_address, 'customer_detail.address.city', ''),
				name: '',
				pincode: _.get(selected_address, 'customer_detail.address.pincode', ''),
				formatted_address: !_.isEmpty(_.get(selected_address, 'customer_detail.address.google_map_address', ''))
					? selected_address.customer_detail.address.google_maps_address
					: selected_address.customer_detail.address.address_line_1,
				geometry: {
					location: new window.google.maps.LatLng(
						Number(selected_address.customer_detail.address.latitude),
						Number(selected_address.customer_detail.address.longitude),
					),
				},
			},
		});
		set_local_receiver_name(selected_address.customer_detail.name);
		set_local_receiver_contact(selected_address.customer_detail.mobile);
	};

	const handle_place_order = () => {
		const sender_detail = {
			name: selected_address.sender_detail.name,
			mobile: selected_address.sender_detail.mobile,
			address: selected_address.sender_detail.address,
		};
		const poc_detail = {
			name: selected_address.poc_detail.name,
			mobile: selected_address.poc_detail.mobile,
		};

		const trips = [
			{
				bill_amount: selected_address.bill_amount,
				cod_amount: selected_address.cod_amount,
				...(selected_address.reference_id && { reference_id: selected_address.reference_id }),
				...(selected_address.delivery_date && { delivery_date: selected_address.delivery_date }),
				...(selected_address.delivery_slot && { delivery_slot: selected_address.delivery_slot }),
				source_order_id: uuidv4(),
				receiver_detail: {
					name: selected_address.customer_detail.name,
					mobile: selected_address.customer_detail.mobile,
					address: selected_address.customer_detail.address,
				},
				packages: selected_address.packages,
			},
		];

		const payload = {
			sender_detail,
			poc_detail,
			trips,
		};
		on_place_order(payload);
	};

	const place_order_disabled = useMemo(() => {
		let value = true;
		if (_.isEmpty(selected_address)) return value;
		let id_present = true;
		for (let i = 0; i < selected_address?.packages?.length; i++) {
			const element = selected_address?.packages[i];
			if (!element.id) {
				id_present = false;
				break;
			}
		}
		value = !id_present;

		return value;
	}, [selected_address]);

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.wrapper}>
				<div className='justify-content-between align-items-center'>
					<Text bold style={{ fontSize: 20 }}>
						Search Frequent Customer
					</Text>
					<img src={ImageLinks.crossBlack} width={20} height={20} onClick={handleClose} style={{ cursor: 'pointer' }} />
				</div>
				<div className='align-items-center justify-content-between' style={{ marginTop: 16 }}>
					<Text semi style={{ fontSize: 16, color: theme.colors.primary }}>
						{contact_number}
					</Text>
					<Text medium style={{ fontSize: 16, color: theme.colors.darkGrey2 }}>
						Last {search_address.length} addresses
					</Text>
				</div>
				<div className={classes.list_wrap}>
					{!_.isEmpty(search_address) && (
						<>
							{_.map(search_address, (address, key) => {
								return (
									<ListItem
										button
										key={`search_address_${key}`}
										className={classes.selected_drop_wrapper}
										style={{
											...(selected_address.id === address.id
												? { borderColor: theme.colors.primary }
												: { borderColor: theme.colors.lightGrey7 }),
										}}
										onClick={() => {
											set_selected_address(address);
										}}>
										<div>
											<Text semi style={{ fontSize: 14 }}>
												{address.customer_detail.name}
											</Text>
											<Text medium className={classes.address_text}>
												{address.customer_detail.address.address_line_1}
											</Text>
										</div>
										<div className={classes.separator}></div>

										<div className={classes.info_wrap}>
											<div>
												{!_.isEmpty(address.reference_id) && (
													<div style={{ marginBottom: 8 }} className='align-items-center'>
														<img src={ImageLinks.box2_pink} width={16} height={16} />
														<Text semi style={{ fontSize: 12, marginLeft: 3 }}>
															{address.reference_id}
														</Text>
													</div>
												)}

												<div className={classes.volume_wrap}>
													<Text semi style={{ fontSize: 12, color: theme.colors.darkPurple }}>
														Volume:
														{_.reduce(_.get(address, 'packages', []), (prev, curr) => prev + curr.volumetric_weight, 0)} (in3)
													</Text>
												</div>

												{_.map(_.get(address, 'packages', []), (pkg_item, pkg_index) => (
													<div key={`pkg_item_${pkg_index}`} style={{ marginTop: 8 }}>
														<Text style={{ fontSize: 12 }}>
															{pkg_item.quantity} {constant.PKG_VOLUME[pkg_item.volumetric_weight]} Bag
														</Text>
													</div>
												))}
											</div>

											<div>
												<div className='align-items-center'>
													<img src={ImageLinks.inr_filled} width={16} height={16} />
													<Text medium style={{ fontSize: 12, marginLeft: 2 }}>
														{_.get(address, 'cod_amount', '')}
													</Text>
												</div>

												<div className='align-items-center'>
													<Text className={classes.item_label}>Delivery Date: </Text>
													<Text medium style={{ fontSize: 12, marginLeft: 2 }}>
														{_.isEmpty(address.delivery_date) ? '' : moment(address.delivery_date).format('DD-MM-YYYY')}
													</Text>
												</div>

												<div className='align-items-center'>
													<Text className={classes.item_label}>Delivery Slot: </Text>
													<Text medium style={{ fontSize: 12, marginLeft: 2 }}>
														{_.get(address, 'delivery_slot', '')}
													</Text>
												</div>
											</div>
										</div>
									</ListItem>
								);
							})}
						</>
					)}
				</div>
				<div className='d-flex justify-content-center align-items-center text-align-center' style={{ gap: 8, marginTop: 20 }}>
					<Button type='outlined' text='Use Details' size='large' disabled={_.isEmpty(selected_address)} onClick={handle_saved_delivery_address} />
					{!is_item_added && (
						<Button
							type='round'
							text='Place Order'
							size='large'
							disabled={place_order_disabled}
							onClick={handle_place_order}
							right_icon={place_order_disabled ? ImageLinks.right_arrow_gray : ImageLinks.right_arrow}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default DeliverySavedAddresses;
