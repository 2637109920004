const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
	container: {
		borderRadius: 12,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		flex: 1,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		width: '100%',
		minHeight: '70vh',
		marginTop: 12,
	},
	action_bar_wrap: {
		backgroundColor: 'rgba(242, 238, 248, 0.30)',
		padding: 12,
		borderBottom: `1px solid ${theme.colors.lightPink4}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	action_text: {
		width: 'auto',
		color: theme.colors.lightGrey2,
		textDecoration: 'underline',
		// cursor: 'pointer',
	},
	overview_container: {
		backgroundColor: 'rgba(242, 238, 248, 0.70)',
		borderRadius: 8,
		padding: 12,
		margin: '10px 0 0 0',
	},
	divider: {
		width: 1,
		height: 'auto',
		backgroundColor: theme.colors.lightPurple,
	},
	alerts_wrap: {
		backgroundColor: theme.colors.white,
		borderRadius: 10,
		padding: 12,
		position: 'relative',
	},
	tags_wrap: {
		margin: '7px 4px',
		marginRight: 2,
		whiteSpace: 'nowrap',
		height: 24,
		borderRadius: 4,
		display: 'flex',
		alignItems: 'center',
		padding: '2px 8px',
		cursor: 'pointer',
		width: 'fit-content',
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
			'& .delete-button': {
				display: 'block !important',
			},
		},
	},
	input_container: {
		display: 'flex',
		overflow: 'scroll',
		width: '90%',
		maxWidth: '100%',
		border: `1px solid ${theme.colors.lightPurple6}`,
		fontSize: 14,
		color: theme.colors.black,
		height: 40,
		borderRadius: 46,
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},

	contain_input: {
		width: '100%',
		minWidth: '20%',
		borderRadius: 46,
		padding: 14,
		paddingLeft: 5,
		border: 'none',
		outline: 'none',
	},
	search_alert_wrap: {
		width: '50%',
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		zIndex: 1000,
	},
	alert_list_item: {
		padding: 8,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		backgroundColor: theme.colors.white,
		marginTop: 5,
		'&:hover': {
			backgroundColor: theme.colors.white,
		},
	},
	selected_alert_list_wrap: {
		height: 100,
		borderTop: `1px dashed ${theme.colors.lightPurple2}`,
		marginTop: 10,
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	green_button: {
		backgroundColor: theme.colors.green,
		minWidth: '100px !important',
		borderRadius: 30,
		'&:hover': {
			backgroundColor: theme.colors.green,
		},
	},
	alert_count_wrap: {
		padding: '4px 12px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderRadius: '0px 0px 8px 8px',
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		marginBottom: 20,
		position: 'relative',
	},
	alert_count: {
		cursor: 'pointer',
		backgroundColor: theme.colors.lightRed,
		padding: '2px 8px',
		borderRadius: 11,
		display: 'flex',
		gap: 10,
	},
}));

export default useStyles;
