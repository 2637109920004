import { Divider, ListItem, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import theme from 'resources/theme';
import { DateRangePicker } from 'materialui-daterange-picker';
import KmChart from './components/KmChart';
import api_requests from 'resources/api_requests';
import _ from 'lodash';
import clsx from 'clsx';
import ContainedButton from 'modules/ContainedButton';
import { toast } from 'react-toastify';
import useStyles from './styles';
import TraceAlertLogs from '../TraceAlertLogs';

function TraceAnalytics({ selected_trace = {}, get_trace_list, set_selected_trace }) {
	const classes = useStyles();
	const [start_date, set_start_date] = useState(moment().subtract(7, 'days'));
	const [end_date, set_end_date] = useState(moment());
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [overview_data, set_overview_data] = useState({});
	const [km_chart_data, set_km_chart_data] = useState([]);
	const [overall_km_chart_data, set_overall_km_chart_data] = useState([]);
	const [show_alerts, set_show_alerts] = useState(false);
	const [alert_alias, set_alert_alias] = useState([]);
	const [alert_search, set_alert_search] = useState('');
	const [alert_list, set_alert_list] = useState([]);
	const [show_green_button, set_show_green_button] = useState(false);
	const [total_entity_count, set_total_entity_count] = useState('-');
	const [show_trace_logs, set_show_trace_logs] = useState(false);
	const [logs, set_logs] = useState([]);
	const [start_date_logs, set_start_date_logs] = useState(new Date());
	const [search_key, set_search_key] = useState('');

	useEffect(() => {
		if (!_.isEmpty(selected_trace)) {
			if (selected_trace.alerts) {
				set_alert_alias(selected_trace.alerts);
			} else {
				set_alert_alias([]);
			}
			get_analytics_single(start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD'), false);
			get_analytics_single(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), true);
		} else {
			get_analytics_trace(start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD'), false);
			get_analytics_trace(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), true);
		}
	}, [selected_trace, start_date, end_date]);

	useEffect(() => {
		get_alert_list();
	}, []);

	useEffect(() => {
		get_trace_logs();
	}, [selected_trace, start_date_logs]);

	const get_trace_logs = async () => {
		try {
			const res = await api_requests.get_trace_alert_logs({
				from: moment(start_date_logs).startOf('day').toDate(),
				to: moment(start_date_logs).endOf('day').toDate(),
				identifier: selected_trace.id,
			});
			if (res.success) set_logs(res.data);
		} catch (err) {}
	};

	const get_alert_list = async () => {
		const res = await api_requests.get_configured_alerts({ subject: 'trace' });
		set_alert_list(res.data);
	};

	const get_analytics_trace = async (from, to, is_overview) => {
		try {
			const res = await api_requests.get_trace_analytics({
				from,
				to,
			});
			if (is_overview) {
				if (res.data.distance.length > 0) {
					set_overview_data(res.data.distance[0]);
				} else {
					set_overview_data({});
				}
			} else {
				set_overall_km_chart_data(res.data.distance);
				set_km_chart_data(res.data.distance);
			}
			set_total_entity_count(res.data.totalEntityCount);
		} catch (err) {}
	};

	const get_analytics_single = async (from, to, is_overview = false) => {
		try {
			const res = await api_requests.get_trace_analytics_single(
				{
					from,
					to,
				},
				selected_trace.id,
			);
			if (is_overview) {
				if (res.data.distance.length > 0) {
					set_overview_data(res.data.distance[0]);
				} else {
					set_overview_data({});
				}
			} else {
				set_km_chart_data(res.data.distance);
			}
			set_total_entity_count(res.data.totalEntityCount);
		} catch (err) {}
	};

	const on_change_alert_search = (e) => {
		set_alert_search(e.target.value);
	};
	const handle_delete = (index) => {
		const _alert_alias = _.cloneDeep(alert_alias);
		_alert_alias.splice(index, 1);
		set_alert_alias(_alert_alias);
		set_alert_search('');
	};

	const handle_select_alert = (alert) => {
		if (_.includes(alert_alias, alert.alias)) {
			set_alert_search('');
			return;
		}
		const _alert_alias = _.cloneDeep(alert_alias);
		_alert_alias.push(alert.alias);
		set_alert_alias(_alert_alias);
		set_alert_search('');
	};

	const handle_save_alias = async () => {
		try {
			const res = await api_requests.update_trace_alias(selected_trace.id, { alerts: alert_alias });
			set_show_green_button(true);
			setTimeout(() => set_show_green_button(false), 3000);
			get_trace_list({});
			set_selected_trace({ ...selected_trace, alerts: alert_alias });
		} catch (err) {}
	};

	const is_alias_updated = useMemo(() => {
		const initial_alert = selected_trace.alerts ? _.get(selected_trace, 'alerts', []) : [];
		if (initial_alert.length != alert_alias.length) {
			return true;
		}
		return !_.every(initial_alert, (item) => alert_alias.includes(item));
	}, [selected_trace, alert_alias]);

	const trace_logs_search_function = (item) => {
		return _.includes(item.alias.toLowerCase(), search_key.toLowerCase());
	};

	return (
		<div className={classes.container}>
			<div style={{ padding: 12 }}>
				<div className={classes.overview_container}>
					<div className='d-flex justify-content-center' style={{ width: '100%' }}>
						<div className='d-flex justify-content-center' style={{ flexDirection: 'column', position: 'relative', flex: 1 }}>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Overview Today
							</Text>
						</div>
						{_.isEmpty(selected_trace) && (
							<>
								<div className={classes.divider} />
								<div style={{ flex: 1, padding: '5px 16px' }}>
									<Text semi style={{ fontSize: 20 }}>
										{_.get(overview_data, 'active_entity_cnt', '0')}/{total_entity_count}
									</Text>
									<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Active Entity
									</Text>
								</div>
							</>
						)}
						<div className={classes.divider} />
						<div style={{ flex: 1, padding: '5px 16px' }}>
							<Text semi style={{ fontSize: 20 }}>
								{_.get(overview_data, 'total_distance', '-') != '-'
									? (overview_data.total_distance / 1000)?.toFixed(overview_data.total_distance < 1000 ? 2 : 1)
									: '-'}
							</Text>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Total KMs
							</Text>
						</div>
						<div className={classes.divider} />
						<div style={{ flex: 1, padding: '5px 16px' }}>
							<Text semi style={{ fontSize: 20 }}>
								{_.get(overview_data, 'avg_speed', '-') != '-' ? overview_data.avg_speed?.toFixed(2) : '-'}
							</Text>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Avg. Speed
							</Text>
						</div>
						<div className={classes.divider} />
						<div style={{ flex: 1, padding: '5px 16px' }}>
							<Text semi style={{ fontSize: 20 }}>
								{_.get(overview_data, 'total_idle_time', '-')}
							</Text>
							<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								Idling
							</Text>
						</div>
						{!_.isEmpty(selected_trace) && (
							<>
								<div className={classes.divider} />
								<div style={{ flex: 1, padding: '5px 16px' }}>
									<div className='d-flex align-items-center' style={{ gap: 10 }} onClick={() => set_show_alerts((prev) => !prev)}>
										<Text semi style={{ fontSize: 20 }}>
											{selected_trace.alerts ? selected_trace.alerts.length : '-'}
										</Text>
										<img
											src={ImageLinks.chevron_right_black}
											width={10}
											style={{ transform: show_alerts ? 'rotate(270deg)' : 'rotate(90deg)' }}
										/>
									</div>

									<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Tags
									</Text>
								</div>
							</>
						)}
					</div>
					{show_alerts && (
						<div className={classes.alerts_wrap}>
							<div className='d-flex' style={{ gap: 10 }}>
								<div className={classes.input_container}>
									<input
										value={alert_search}
										onChange={on_change_alert_search}
										className={clsx(classes.contain_input, 'purple_placeholder')}
										placeholder={'Enter Alert Alias Name'}
										style={{ paddingLeft: 10 }}
									/>
								</div>
								{show_green_button ? (
									<ContainedButton className={classes.green_button}>
										<img src={ImageLinks.tick_circle_white} height={16} width={16} style={{ marginRight: 10 }} />
										<Text semi>Save</Text>{' '}
									</ContainedButton>
								) : (
									<ContainedButton
										disabled={!is_alias_updated}
										style={{ minWidth: 100, borderRadius: 30 }}
										onClick={() => handle_save_alias()}>
										<Text semi>Save</Text>
									</ContainedButton>
								)}
							</div>
							{alert_search.length > 0 && (
								<div className={classes.search_alert_wrap}>
									{_.map(
										_.filter(alert_list, (item) => {
											return _.includes(item.alias.toLowerCase(), alert_search.toLowerCase()) && !_.includes(alert_alias, item.alias);
										}),
										(item, index) => {
											return (
												<ListItem
													button
													key={`menu${index}`}
													onClick={() => handle_select_alert(item)}
													tabIndex={index}
													className={classes.alert_list_item}>
													<Text>{item.alias}</Text>
													<Text style={{ marginLeft: 20 }}>{item.rider_name}</Text>
												</ListItem>
											);
										},
									)}
								</div>
							)}
							<div className={classes.selected_alert_list_wrap}>
								{alert_alias.map((data, index) => (
									<div style={{ width: '48%' }}>
										<div key={`input_tags_${index}`} className={classes.tags_wrap}>
											<Text semi style={{ fontSize: 14, marginRight: 4, color: theme.colors.black }}>
												{data}
											</Text>
											<img
												src={ImageLinks.delete}
												width={16}
												height={16}
												style={{ display: 'none' }}
												className='delete-button'
												onClick={() => {
													handle_delete(index);
												}}
											/>
										</div>
									</div>
								))}
								{alert_alias.length == 0 && (
									<div className='d-felx align-items-center justify-content-center' style={{ height: '100%', width: '100%' }}>
										<Text semi style={{ color: theme.colors.darkGrey2 }}>
											Link alerts for this vehicle
										</Text>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
				{!_.isEmpty(selected_trace) && (
					<div className={classes.alert_count_wrap}>
						<div className='d-flex align-items-center'>
							<img src={ImageLinks.alert_triangle_red_filled} height={16} width={16} />
							<Text semi>Alerts Today</Text>
						</div>
						<div className={classes.alert_count} onClick={() => set_show_trace_logs(true)}>
							<Text semi style={{ color: theme.colors.red }}>
								{selected_trace.today_alerts}
							</Text>
							<img src={ImageLinks.chevron_right_red} />
						</div>
					</div>
				)}
				<div className='d-flex align-items-center justify-content-between'>
					<Text semi style={{ fontSize: 16 }}>
						Analytics
					</Text>
					<div className='d-flex align-items-center'>
						<Text bold style={{ fontSize: 16, color: theme.colors.primary }}>{`${start_date.format('DD MMM `YY')} -${end_date.format(
							'DD MMM `YY',
						)}`}</Text>
						<img src={ImageLinks.calendar_solid_circle} onClick={() => set_date_picker_open(true)} style={{ marginLeft: 8 }} />
					</div>
					<div style={{ position: 'absolute', right: 0, top: 50, width: 750, zIndex: 1300 }}>
						<DateRangePicker
							open={date_picker_open}
							toggle={() => set_date_picker_open((prev) => !prev)}
							style={{ width: 150 }}
							initialDateRange={{
								startDate: start_date,
								endDate: end_date,
							}}
							onChange={(range) => {
								const newDateRange = {
									from: moment(range.startDate),
									to: moment(range.endDate),
								};
								if (Math.abs(newDateRange.from.diff(newDateRange.to, 'days')) > 6) {
									toast.error('Only 7 days filter allowed', {
										position: toast.POSITION.BOTTOM_RIGHT,
									});
									return;
								}
								set_start_date(moment(range.startDate));
								set_end_date(moment(range.endDate));
								set_date_picker_open(!date_picker_open);
							}}
						/>
					</div>
				</div>
				<Divider style={{ margin: '8px 0' }} />
				<KmChart km_data={km_chart_data} overall_km_chart_data={overall_km_chart_data} />
				{/* <AlertChart /> */}
				{show_trace_logs && !_.isEmpty(selected_trace) && (
					<TraceAlertLogs
						close={() => set_show_trace_logs(false)}
						title={`${selected_trace.entity_name} Logs`}
						logs={logs}
						set_start_date={set_start_date_logs}
						start_date={start_date_logs}
						search_function={trace_logs_search_function}
						search_key={search_key}
						set_search_key={set_search_key}
						search_placeholder={'Search Alias'}
						query={{ identifier: selected_trace.id }}
					/>
				)}
			</div>
		</div>
	);
}

export default TraceAnalytics;
