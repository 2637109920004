import React, { useEffect, useRef, useState } from 'react';
import qs from 'qs';
import moment from 'moment';
import Text from 'modules/Text';
import theme from 'resources/theme';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Checkbox, makeStyles } from '@material-ui/core';
import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import Filters from 'modules/Filters/Filters';
import constant from 'resources/constant';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from '../helper';
import Routes from 'resources/Routes';
import utils from 'resources/utils';
import FilterButton from 'modules/FilterButton';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import Select from 'modules/Select';
import { shallowEqual, useSelector } from 'react-redux';
import PremiumFeatureModal from 'modules/PremiumFeatureModal';
import Toast from 'modules/Toast';
import RouteCriteriaModal from './ReadyToShip/components/RouteCriterialModal';
import BulksActions from 'modules/BulkActions/BulksActions';
import { OPTIONS_MENU } from 'modules/BulkActions/helpers';
import Helpcenter from 'modules/HelpCenter';
import NoResult from 'modules/NoResult';
import { hide_loader, show_loader } from 'actions/app';

const useStyles = makeStyles(() => ({
	ongoingOrdersDiv: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: 40,
		marginTop: 20,
		gap: 20,
		marginRight: 10,
	},
	header_btn_wrapper: {
		position: 'absolute',
		marginTop: -100,
		right: 20,
	},
	menu_item_class: {
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
			'& .menu_item_text': {
				color: theme.colors.primary,
			},
			'& .black-arrow': {
				display: 'none',
			},
			'& .purple-arrow': {
				display: 'block !important',
			},
		},
	},
	detailsTextContainer: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
		gap: 10,
	},
	pickedUpContainer: {
		overflowY: 'auto',
		flex: 1,
		marginTop: 10,
		marginBottom: 10,
	},
	pickedUpItem: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
	},
	orderDetailsClickabletext: {
		flex: 1,
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
	pagesContainer: {
		height: 60,
		marginBottom: 10,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		borderTop: `1px solid ${theme.colors.lightPurple}`,
	},
	pageItemDiv: {
		marginLeft: 20,
		marginRight: 20,
		width: 24,
		height: 24,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 12,
	},
	pageText: { color: theme.colors.primary, fontSize: 12 },
	setPageContainer: { display: 'flex', alignItems: 'center' },
	truncate: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 16,
	},
	wrapper: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	filter_btn: {
		backgroundColor: theme.colors.white,
		marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	header_title_2: { flex: 2, color: theme.colors.darkGrey2, fontSize: 14 },
	table_item_wrap_2: { flex: 2, minWidth: 0 },
	page_nav_btn: {
		width: 24,
		height: 24,
		borderRadius: 30,
	},
	enabled_page_nav: { border: `1px solid ${theme.colors.primary}` },
	disabled_page_nav: { backgroundColor: theme.colors.lightGrey5, width: 26, height: 26 },
	bulk_action_wrapper: {
		position: 'absolute',
		right: 0,
		zIndex: 1198,
	},
	wrapper_help_center: { position: 'fixed', bottom: 30, left: '50%', zIndex: 100 },
	subtitle: { fontSize: 12, color: theme.colors.lightGrey6, marginTop: 2 },
}));

const CREATE_ROUTE_OPTIONS = [
	{
		is_heading: true,
		disabled: true,
		title: 'Create New',
		value: '',
	},
	{
		option_left_icon: (
			<>
				<img className='black-arrow' src={ImageLinks.arrow_up_black} alt='manual_route_icon' width={16} height={16} style={{ marginRight: 10 }} />
				<img
					className='purple-arrow'
					src={ImageLinks.arrow_up}
					alt='manual_route_icon'
					width={16}
					height={16}
					style={{ marginRight: 10, display: 'none' }}
				/>
			</>
		),
		title: 'Single Route',
		value: 'manual_route',
	},
	{
		option_left_icon: (
			<>
				<img className='black-arrow' src={ImageLinks.multi_arrow_up_black} alt='auto_route_icon' width={16} height={16} style={{ marginRight: 10 }} />
				<img
					className='purple-arrow'
					src={ImageLinks.multi_arrow_up}
					alt='auto_route_icon'
					width={16}
					height={16}
					style={{ marginRight: 10, display: 'none' }}
				/>
			</>
		),
		title: 'Auto Route',
		value: 'auto_route',
	},
	{
		option_left_icon: (
			<>
				<img className='black-arrow' src={ImageLinks.up_down_black} alt='custom_route_icon' width={16} height={16} style={{ marginRight: 10 }} />
				<img
					className='purple-arrow'
					src={ImageLinks.up_down_purple}
					alt='custom_route_icon'
					width={16}
					height={16}
					style={{ marginRight: 10, display: 'none' }}
				/>
			</>
		),
		title: 'Custom Route',
		value: 'custom_route',
	},
];

const AtWarehouse = ({ selected_master_zone }) => {
	const { app_configs, selected_business_id } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
			selected_business_id: state.app.selected_business_id,
		}),
		shallowEqual,
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const filter_object = qs.parse(history.location.search.slice(1)).filter || { attempt_type: '10' };
	const classes = useStyles();

	const [selected_pb_id, set_selected_pb_id] = useState('');
	const [show_order_details, set_show_order_details] = useState(false);
	const [orders, set_orders] = useState({});
	const [all_filters, set_all_filters] = useState([]);
	const [show_filters, set_show_filters] = useState(false);
	const [order_list, set_order_list] = useState([]);
	const [count, set_count] = useState(0);
	const [selected_order_ids, set_selected_order_ids] = useState([]);
	const [captive_riders, set_captive_riders] = useState(null);
	const [show_toast, set_show_toast] = useState(false);
	const [show_highlight, set_show_highlight] = useState(false);
	const [show_premium_feature, set_show_premium_feature] = useState(false);
	const [show_custom_group_modal, set_show_custom_group_modal] = React.useState(false);
	const slider_ref = React.useRef();
	const loading_scroll = useRef(false);

	useEffect(() => {
		set_all_filters([]);
		api_requests.get_atwarehouse_filters(selected_master_zone).then((res) => set_all_filters(res.data.filters));
		api_requests.get_captive_riders().then((res) => set_captive_riders(res.data));
	}, [selected_master_zone]);

	const premium_features_blocked = _.isEmpty(captive_riders);

	useEffect(() => {
		if (_.isEmpty(filter_object)) {
			return;
		}
		get_orders({});
	}, [JSON.stringify(filter_object)]);

	const get_orders = ({ token }) => {
		set_orders({});
		dispatch(show_loader());
		api_requests.get_atwarehouse_search({ ...filter_object, ...(token && { token }) }).then((res) => {
			loading_scroll.current = false;

			if (token) {
				set_order_list(_.concat(order_list, res.data.data));
			} else {
				set_count(res.data.count);
				set_order_list(res.data.data);
			}
			set_orders(res.data);
			dispatch(hide_loader());
		});
	};
	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && orders.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_orders({ token: orders.next });
		}
	};

	const handle_export = async (filters, callback) => {
		try {
			const res = await api_requests.export_all_orders_pwp({ ...filters }, 'from-warehouse');
			utils.downloadBlob(res, 'orders.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	const handle_select_all_order = () => {
		if (selected_order_ids.length === order_list.length) {
			set_selected_order_ids([]);
		} else {
			set_selected_order_ids(_.map(order_list, 'mcp_id'));
		}
	};

	const handle_select_order = (order_id) => {
		const index = _.indexOf(selected_order_ids, order_id);
		const _selected_order_ids = [...selected_order_ids];

		if (index === -1) {
			_selected_order_ids.push(order_id);
		} else {
			_selected_order_ids.splice(index, 1);
		}

		set_selected_order_ids(_selected_order_ids);
	};

	const check_grouping_conditions = () => {
		if (premium_features_blocked) {
			set_show_premium_feature(true);
			return true;
		}
		if (_.isEmpty(selected_order_ids)) {
			return true;
		}
		if (
			_.filter(order_list, (order, key) => {
				return (
					_.includes(selected_order_ids, order.mcp_id) &&
					(!_.isNull(_.get(order, 'schedule_time', null)) ||
						!_.isNull(_.get(order, 'bundle_label', null)) ||
						(!_.isNull(_.get(order, 'supplier_id', null)) && _.get(order, 'supplier_id', null) != selected_business_id))
				);
			}).length > 0
		) {
			set_show_highlight(true);
			set_show_toast(true);
			return true;
		} else {
			set_show_toast(false);
			set_show_highlight(false);
		}
		return false;
	};

	const handle_single_grouping = async () => {
		if (check_grouping_conditions()) {
			return;
		}
		try {
			const res = await api_requests.create_manual_group({
				pbids: selected_order_ids,
			});
			history.push(Routes.GROUPING_AND_ALLOCATION.path + `?type=unallocated&bundle_id=${res.data.bundles[0]}`);
		} catch (err) {}
	};

	const handle_multi_grouping = async (config = null) => {
		if (check_grouping_conditions()) {
			return;
		}
		try {
			const res = await api_requests.create_group_auto({
				pbids: selected_order_ids,
				numberOfRidersBig: 0,
				riderType: 1,
				...(config && { config }),
			});
			history.push(Routes.GROUPING_AND_ALLOCATION.path + `?type=unallocated&bundle_id=${res.data.bundles[0]}`);
		} catch (err) {}
	};

	const handle_custom_routing_click = () => {
		if (
			_.filter(order_list, (order, key) => {
				return (
					_.includes(selected_order_ids, order.mcp_id) &&
					(!_.isNull(_.get(order, 'schedule_time', null)) ||
						!_.isNull(_.get(order, 'bundle_label', null)) ||
						(!_.isNull(_.get(order, 'supplier_id', null)) && _.get(order, 'supplier_id', null) != selected_business_id))
				);
			}).length > 0
		) {
			set_show_highlight(true);
			set_show_toast(true);
			return;
		} else {
			set_show_toast(false);
			set_show_highlight(false);
		}
		set_show_custom_group_modal(true);
	};

	return (
		<div className={classes.wrapper}>
			<div className={classes.header_btn_wrapper}>
				<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
					{show_toast && (
						<Toast error={true} close_callback={() => set_show_toast(false)} wrapper_styles={{ width: 'auto', left: '-98%' }}>
							<img src={ImageLinks.exclamation_error} width={16} height={16} alt='' />
							<Text component={'span'} semi style={{ marginLeft: 10 }}>
								Please unselect routed orders to proceed
							</Text>
						</Toast>
					)}
					<div style={{ marginLeft: 10 }}>
						<Select
							menu_item_class={{ className: classes.menu_item_class }}
							value={null}
							defaultOption={{
								title: 'Create Route',
								value: null,
							}}
							className={classes.selec_btn}
							options={CREATE_ROUTE_OPTIONS}
							handleChange={(val) => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.PP_WAREHOUSE + `_${val}`);
								switch (val) {
									case CREATE_ROUTE_OPTIONS[1].value:
										handle_single_grouping();
										break;

									case CREATE_ROUTE_OPTIONS[2].value:
										handle_multi_grouping();
										break;
									case CREATE_ROUTE_OPTIONS[3].value:
										handle_custom_routing_click(true);
										break;
									default:
										break;
								}
							}}
							right_icon={selected_order_ids.length === 0 ? ImageLinks.chevron_up_gray : ImageLinks.chevron_up_white}
							disabled={selected_order_ids.length === 0}
							containerStyles={{
								background: selected_order_ids.length === 0 ? theme.colors.lightGrey5 : theme.colors.primary,
								borderColor: selected_order_ids.length === 0 ? theme.colors.lightGrey5 : theme.colors.primary,
								height: 36,
								borderRadius: 30,
								justifyContent: 'center',
								padding: '8px 12px 8px 16px',
								gap: 6,
							}}
							image_styles={{
								height: 16,
								width: 16,
							}}
							selected_title_styles={{ color: selected_order_ids.length === 0 ? theme.colors.darkGrey2 : theme.colors.white, fontSize: 16 }}
							selected_title_props={{ medium: true }}
							text_props={{ medium: true, className: 'menu_item_text' }}
							menu_styles={{ marginTop: 55, width: 150 }}
							truncate_title={(title) => {
								return _.truncate(title, { length: 16 });
							}}
						/>
					</div>
				</div>
				<div style={{ display: 'flex' }}>
					<div className={classes.ongoingOrdersDiv}>
						<div className='d-flex align-items-center' style={{ gap: 5, marginTop: -10 }}>
							<Text semi component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
								Orders :{' '}
							</Text>
							<Text semi component={'span'} style={{ fontSize: 14 }}>
								{count}
							</Text>
						</div>
						<FilterButton
							handle_click={() => set_show_filters(true)}
							total_filters={Object.keys(filter_object).length}
							wrapper_styles={{ marginBottom: 15 }}
							track_event={{ event: 'PP_warehouse_filter', properties: filter_object }}
						/>
					</div>
				</div>
			</div>

			<div className={classes.detailsTextContainer}>
				<div style={{ width: 50 }}>
					{order_list && order_list.length !== 0 && (
						<Checkbox
							size='small'
							checked={selected_order_ids.length === order_list.length}
							onClick={handle_select_all_order}
							style={{ color: theme.colors.lightPurple2, padding: 0 }}
						/>
					)}
				</div>
				<Text bold style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
					Order ID
				</Text>
				<Text bold className={classes.header_title_2}>
					Customer Details
				</Text>
				<Text bold className={classes.header_title_2}>
					Warehouse
				</Text>
				<Text bold className={classes.header_title_2}>
					Inward
				</Text>

				<Text bold className={classes.header_title_2}>
					Allocation Details
				</Text>
				{selected_order_ids.length > 0 && (
					<div className={classes.bulk_action_wrapper}>
						<BulksActions
							external_order_id_list={_.map(
								_.filter(order_list, (order) => {
									return _.includes(selected_order_ids, order.mcp_id) && utils._retrieveData('owner_id') == order.business_id;
								}),
								(order) => {
									return order.external_order_id;
								},
							)}
							on_update={() => get_orders({})}
							option={[OPTIONS_MENU.unroute]}
							selected_order_ids={selected_order_ids}
							set_selected_order_ids={set_selected_order_ids}
						/>
					</div>
				)}
			</div>

			<div className={classes.pickedUpContainer} ref={slider_ref} onScroll={slider_scrolled}>
				{_.map(order_list, (order, key) => {
					return (
						<div
							key={`order${key}`}
							className={classes.pickedUpItem}
							style={
								(order.schedule_time ||
									order.bundle_label ||
									(!_.isNull(_.get(order, 'supplier_id', null)) && _.get(order, 'supplier_id', null) != selected_business_id)) &&
								show_highlight &&
								_.includes(selected_order_ids, order.mcp_id)
									? { backgroundColor: theme.colors.lightPurple }
									: {}
							}>
							<div style={{ width: 50 }}>
								<Checkbox
									size='small'
									checked={_.includes(selected_order_ids, order.mcp_id)}
									onClick={() => handle_select_order(order.mcp_id)}
									style={{ color: theme.colors.lightPurple2, padding: 0 }}
								/>
							</div>
							<div style={{ flex: 1, minWidth: 0, position: 'relative' }}>
								{order.business_id != selected_business_id && (
									<img src={ImageLinks.network_light_purple} width={20} height={20} style={{ position: 'absolute', left: -25, top: 1 }} />
								)}
								<Text
									semi
									className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
									onClick={() => {
										utils.track_event_for_analytics(constant.TRACKING_EVENTS.PP_WAREHOUSE_ORDER);
										set_selected_pb_id(order.external_order_id);
										set_show_order_details(true);
									}}>
									{order.mcp_id}
								</Text>
								<Text className={classes.subtitle}>{order.reference_id || order?.dd_channel?.order_id || order.id || '-'}</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{order.customer_name || '-'}
								</Text>
								<Text className={classes.subtitle}>{`${order.customer_mobile} | ${order.customer_pincode}`}</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{order.from_warehouse.name || '-'}
								</Text>
								<Text className={classes.subtitle}>{`${order?.warehouse_pincode} | ${
									order.from_warehouse.id !== -1 ? _.get(order, 'from_warehouse.receiver_details.name', '-') : '-'
								}`}</Text>
							</div>
							<div className={classes.table_item_wrap_2}>
								<Text medium className={classes.truncate}>
									{order.inward_date ? moment(order.inward_date).format('DD-MM-YY') : '-'}
								</Text>
								<Text className={classes.subtitle}>{order.inward_date ? moment(order.inward_date).format('hh:mm A') : '-'}</Text>
							</div>

							<div className={classes.table_item_wrap_2}>
								{order.bundle_label ? (
									<Text
										semi
										onClick={() => history.push(Routes.GROUPING_AND_ALLOCATION.path + `?type=unallocated&bundle_id=${order.bundle_id}`)}
										className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
										style={{ color: theme.colors.primary, cursor: 'pointer' }}>
										{order.bundle_label}
									</Text>
								) : order.schedule_time ? (
									<div style={{ display: 'flex' }}>
										<img src={ImageLinks.clock_schedule_purple} alt='' />
										<Text semi style={{ color: theme.colors.primary, cursor: 'pointer', marginLeft: 5 }}>
											{moment(order.schedule_time).format('ddd, DD MMM, hh-')}{' '}
											{moment(order.schedule_time).add('60', 'minutes').format('hh a')}
										</Text>
									</div>
								) : (
									<Text>-</Text>
								)}
								<Text className={classes.subtitle}>
									{_.get(order, 'rider.name') && order.rider.id ? `${_.get(order, 'rider.name')} (${_.get(order, 'rider.id')})` : '-'}
								</Text>
							</div>
						</div>
					);
				})}
				{/* {order_list.length < 8 && (
					<div className={classes.wrapper_help_center}>
						<Helpcenter
							text='How can I power my orders with Pidge?'
							url='https://pidge.in/helpcenter-order-poweredwithpidge'
							style={{ marginTop: 40 }}
						/>
					</div>
				)} */}
				{order_list.length == 0 && Object.keys(filter_object).length > 1 && (
					<div className='d-flex justify-content-center align-items-center' style={{ marginTop: '15%', flexDirection: 'column', gap: 10 }}>
						<NoResult
							text='No order found'
							btn_onclick={() => history.replace({ search: '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.AtWarehouse.id })}
						/>
					</div>
				)}
			</div>
			{show_order_details && <OrderModal id={selected_pb_id} close={() => set_show_order_details(false)} updater={() => get_orders({})} />}
			{show_filters && (
				<Filters
					all_filters={all_filters}
					existing_filters={filter_object}
					set_selected_filters={(filters) => {
						history.push(
							Routes.POWERED_WITH_PIDGE.path + `?type=${POWERED_WITH_PIDGE_MENU_ITEMS.AtWarehouse.id}&` + qs.stringify({ filter: filters }),
						);
					}}
					close={() => set_show_filters(false)}
					title='At Warehouse'
					handle_export={handle_export}
				/>
			)}
			{show_premium_feature && <PremiumFeatureModal close={() => set_show_premium_feature(false)} />}
			{show_custom_group_modal && <RouteCriteriaModal close={() => set_show_custom_group_modal(false)} handle_custom_grouping={handle_multi_grouping} />}
		</div>
	);
};

export default AtWarehouse;
