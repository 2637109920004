import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import RecommendedAlerts from './components/RecommendedAlerts';
import Routes from 'resources/Routes';
import { useHistory } from 'react-router-dom';
import api_requests from 'resources/api_requests';
import { subject_options } from '../helper';
import AlertItem from './components/AlertItem';
import moment from 'moment';
import TraceAlertLogs from 'views/TraceManagementFlow/components/TraceAlertLogs';
import Button from 'modules/Button';
import EmptyState from 'modules/EmptyState';
import Helpcenter from 'modules/HelpCenter';
import { hide_loader, show_loader } from 'actions/app';
import { useDispatch } from 'react-redux';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 46 },
	header_title: {
		fontSize: 22,
		color: 'black',
	},
	header_btn_text: { marginLeft: 10, fontSize: 18 },
	header_btn_icon: { width: 12, height: 12 },
	tab_wrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingBottom: 8,
		borderBottom: `1px solid ${theme.colors.lightPurple}`,
		marginTop: 32,
	},
	table_row_wrapper: {
		flex: 1,
		marginTop: 10,
		marginBottom: 10,
		maxHeight: '75vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	content: { display: 'flex', flex: 1, marginTop: 12 },
	empty_content: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	subject_wrap: {
		display: 'flex',
	},
	subject_item_wrap: {
		minWidth: 93,
		height: 33,
		paddingLeft: 16,
		paddingRight: 16,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		cursor: 'pointer',
	},
	autoIcon: {
		width: 35,
		height: 35,
		marginLeft: 10,
		borderRadius: '100%',
	},
});

const AlertsListing = () => {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();

	const [alerts, set_alerts] = useState([]);
	const [selected_subject, set_selected_subject] = useState(subject_options[0].key);
	const [logs, set_logs] = useState([]);
	const [start_date, set_start_date] = useState(new Date());
	const [search_key, set_search_key] = useState('');
	const [selected_rider_id, set_selected_rider_id] = useState('');
	const [show_filters, set_show_filters] = useState(null);
	const [selected_alert, set_selected_alert] = useState({});
	const is_alert_fetched = useRef(false);

	useEffect(() => {
		get_alerts();
	}, []);

	useEffect(() => {
		set_logs([]);
	}, [selected_subject]);

	useEffect(() => {
		if (!_.isEmpty(selected_alert)) {
			get_trace_logs();
		} else {
			set_selected_rider_id('');
		}
	}, [selected_alert, start_date, selected_rider_id]);

	const get_trace_logs = async () => {
		try {
			const res = await api_requests.get_trace_alert_logs({
				from: moment(start_date).startOf('day').toDate(),
				to: moment(start_date).endOf('day').toDate(),
				alias: selected_alert.alias,
				...(selected_rider_id != '' && { rider_id: selected_rider_id }),
			});
			if (res.success) set_logs(res.data);
		} catch (err) {}
	};

	const get_alerts = async (subject = selected_subject) => {
		dispatch(show_loader());
		try {
			const response = await api_requests.get_configured_alerts({
				subject,
			});
			is_alert_fetched.current = true;
			if (response.success) {
				set_alerts(response.data);
				dispatch(hide_loader());
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const on_subject_select = (item) => {
		is_alert_fetched.current = false;
		set_selected_subject(item);
		get_alerts(item);
	};

	const on_alert_edit = (id) => {
		history.push(Routes.CREATE_ALERT.path + `?id=${id}`);
	};

	const on_comm_click = (id) => {
		history.push(Routes.CREATE_COMMUNICATION.path + `?id=${id}`);
	};

	const handle_item_click = (item) => {
		set_selected_alert(item);
	};

	const logs_search_function = (item) => {
		return _.includes(_.get(item, 'data.entity_name', '').toLowerCase(), search_key.toLowerCase());
	};

	const get_placehoder = useMemo(() => {
		switch (selected_subject) {
			case 'rider':
				return 'Search rider name...';
			case 'business':
				return 'Search brand name...';
			case 'order':
				return 'Search RefID...';
			case 'pwp':
				return 'Search PBID...';
			case 'trace':
				return 'Search vehicle number...';
			case 'customer':
				return 'Search customer name...';
		}
	}, [selected_subject]);

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<div className='align-items-center'>
					<div className='page_back_btn' onClick={() => history.goBack()}>
						<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
					</div>
					<Text semi className={'global_header_text'}>
						Alerts
					</Text>
				</div>

				{alerts.length > 0 && (
					<Button
						type='round'
						text='Create Alert'
						size='large'
						onClick={() => {
							utils.track_event_for_analytics(constant.TRACKING_EVENTS.CREATE_ALERT);
							history.push(Routes.CREATE_ALERT.path);
						}}
						left_icon={ImageLinks.addWhite}
					/>
				)}
			</div>

			<div className={classes.tab_wrap}>
				<div className={classes.subject_wrap}>
					{_.map(subject_options, (subject, key) => (
						<div
							onClick={() => {
								utils.track_event_for_analytics(`alert_${subject.key}`);
								on_subject_select(subject.key);
							}}
							className={classes.subject_item_wrap}
							style={{
								backgroundColor: selected_subject === subject.key ? theme.colors.primary : 'transparent',
							}}>
							<Text bold style={{ fontSize: 14, color: selected_subject === subject.key ? theme.colors.white : theme.colors.lightPurple6 }}>
								{`${subject.title}`}
							</Text>
						</div>
					))}
				</div>

				{/* <ContainedButton
					disableElevation
					className={classes.autoIcon}
					style={{ backgroundColor: theme.colors.lightPurple }}
					onClick={() => set_show_filters(true)}>
					<img width={17} height={12} src={ImageLinks.filter_pink} alt='alerts_filter' />
				</ContainedButton> */}
			</div>

			{alerts.length === 0 && is_alert_fetched.current ? (
				<div className={classes.empty_content}>
					<EmptyState
						button_text='Create Alerts'
						left_icon={ImageLinks.addWhite}
						empty_img={ImageLinks.empty_alerts}
						onClick={() => history.push(Routes.CREATE_ALERT.path)}
					/>
					{/* <Helpcenter text={'What alerts can I create?'} style={{ marginTop: 40 }} /> */}
				</div>
			) : (
				<div className={classes.content}>
					<div style={{ flex: 0.5, marginRight: 16 }}>
						<div className={classes.table_row_wrapper}>
							{_.map(alerts, (item, key) => {
								return (
									<AlertItem
										handle_click={() => handle_item_click(item)}
										item={item}
										key={`alert_item_${key}`}
										on_alert_edit={on_alert_edit}
										on_comm_click={on_comm_click}
									/>
								);
							})}
						</div>
					</div>
					{/* This recommended feature is a part of v2 */}
					<div style={{ flex: 0.5, marginLeft: 16 }}>
						{/* <Text bold style={{ fontSize: 18 }}>
							Recommended Alerts
						</Text>
						{_.map(rcmd_dummy_data, (rcmd_alerts, key) => (
							<RecommendedAlerts
								key={`rcmd_alerts_${key}`}
								title={rcmd_alerts.title}
								description={rcmd_alerts.description}
								link={rcmd_alerts.link}
								onClick={() => {}}
							/>
						))} */}
					</div>
				</div>
			)}
			{!_.isEmpty(selected_alert) && (
				<TraceAlertLogs
					close={() => set_selected_alert({})}
					title={`${selected_alert.alias} Logs`}
					logs={logs}
					set_start_date={set_start_date}
					start_date={start_date}
					search_key={search_key}
					set_search_key={set_search_key}
					search_function={logs_search_function}
					search_placeholder={get_placehoder}
					query={{ alias: selected_alert.alias }}
					show_rider_list={selected_subject == 'rider'}
					set_selected_rider_id={set_selected_rider_id}
				/>
			)}
		</div>
	);
};

export default AlertsListing;
