import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';

const useStyles = makeStyles({
	alert_item_wrap: {
		width: '100%',
		minHeight: 91,
		borderRadius: 6,
		padding: 12,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey5}`,
		flex: 0.7,
		marginBottom: 12,
		'&:hover': {
			'& .alert_action_btns': {
				visibility: 'visible !important',
			},
		},
	},
	alert_item_upper_half: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		paddingBottom: 8,
	},
	alert_item_lower_half: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 8,
	},
	alert_subtitle: { fontSize: 12, marginTop: 4, color: theme.colors.lightGrey9, marginLeft: 20 },
	download_wrap: {
		position: 'relative',
		display: 'flex',
		'&:hover': {
			'& > $download_hover': {
				right: 10,
				opacity: 1,
			},
			'& > .download-purple': {
				visibility: 'hidden',
			},
		},
	},
	edit_icon_wrap: {
		'&:hover': {
			'& .edit_filled_purple': {
				backgroundColor: theme.colors.lightPurple,
			},
		},
	},
	download_hover: {
		borderRadius: 30,
		marginTop: -5,
		backgroundColor: theme.colors.lightPurple,
		width: 140,
		height: 26,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 12,
		paddingRight: 12,
		transition: 'all 0.55s ease-in-out',
		right: 0,
		opacity: 0,
		position: 'absolute',
		cursor: 'pointer',
	},
	download_text: { fontSize: 12, color: theme.colors.primary, marginLeft: 6 },
	view_comm_text: { fontSize: 12, color: theme.colors.darkPurple, marginLeft: 2, marginRight: 2 },
	right_wrap: { display: 'flex', flex: 0.3, alignItems: 'center' },
	notif_stats_text: { fontSize: 24, color: theme.colors.lightPurple7, marginLeft: 2 },
	stats_text: { fontSize: 14, color: theme.colors.darkGrey2, marginLeft: 4 },
	stats_separator: { border: `1.2px solid ${theme.colors.lightGrey2}`, height: 30, marginLeft: 26, marginRight: 26 },
});

const AlertItem = ({ handle_click, item, on_alert_edit, on_comm_click = () => {} }) => {
	const classes = useStyles();
	return (
		<div className='align-items-center'>
			<div className={classes.alert_item_wrap} onClick={handle_click}>
				<div className={classes.alert_item_upper_half}>
					<div>
						<div className='align-items-center'>
							<img src={item.active ? ImageLinks.play_green_circle : ImageLinks.pause_yellow_circle} width={16} height={16} />
							<Text bold style={{ fontSize: 14, marginLeft: 4 }}>
								{item.alias}
							</Text>
						</div>
						<Text medium className={classes.alert_subtitle}>
							{item.title}
						</Text>
					</div>
					<div className='alert_action_btns' style={{ visibility: 'hidden', display: 'flex' }}>
						{/* <div className={classes.download_wrap}>
							<img className='download-purple' src={ImageLinks.download} width={16} height={16} style={{ marginRight: 20, cursor: 'pointer' }} />
							<div className={classes.download_hover} onClick={() => {}}>
								<img src={ImageLinks.download} width={14} height={14} />
								<Text semi className={classes.download_text}>
									Download Report
								</Text>
							</div>
						</div> */}

						<div className={classes.edit_icon_wrap} onClick={() => {}}>
							<div
								onClick={() => on_alert_edit(item.id)}
								className='edit_filled_purple cursor-pointer'
								style={{
									width: 22,
									height: 22,
									borderRadius: 10,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<img src={ImageLinks.edit_purple} width={14} height={14} />
							</div>
						</div>
					</div>
				</div>
				<div style={{ border: `1px solid ${theme.colors.lightPurple}` }} />
				<div className={classes.alert_item_lower_half}>
					<Text style={{ fontSize: 12 }}>{/* Last Alert on: DD/MM/YY at HH:MM */}</Text>

					{item.communication.enabled && (
						<Button
							type='text'
							size='small'
							text='View Comm'
							onClick={() => on_comm_click(item.alias)}
							right_icon={ImageLinks.chevron_right_purple}
							className='align-items-center cursor-pointer'
						/>
					)}
				</div>
			</div>

			<div className={classes.right_wrap}>
				{/* <div className='align-items-center' style={{ paddingLeft: 32 }}>
					<img src={ImageLinks.bell_light_purple} width={24} height={24} />
					<Text className={classes.notif_stats_text}>23</Text>
				</div>

				<div className={classes.stats_separator}></div>
				<div className='align-items-center'>
					<img src={ImageLinks.tick_circle_light_green_solid} width={16} height={16} />
					<Text className={classes.stats_text}>23</Text>
				</div>
				<div className='align-items-center' style={{ marginLeft: 14 }}>
					<img src={ImageLinks.close_circle_light_pink_solid} width={16} height={16} />
					<Text className={classes.stats_text}>23</Text>
				</div> */}
			</div>
		</div>
	);
};

export default AlertItem;
