import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Checkbox, Divider, ListItem, makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Text from 'modules/Text';
import OutlinedTextField from 'modules/OutlinedTextField';
import NetworkBox from './components/NetworkBox';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';
import utils from 'resources/utils';
import { priorities } from './helper';
import Button from 'modules/Button';
import NetworkConfigAvatar from 'modules/NetworkConfigAvatar';
import constant from 'resources/constant';
import Routes from 'resources/Routes';

const useStyles = makeStyles((theme) => ({
	heading_container: { display: 'flex', justifyContent: 'space-between' },
	heading: { color: theme.colors.primary, fontSize: 18 },
	sub_heading: { color: theme.colors.darkGrey2, fontSize: 14 },
	info_container: { backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, height: 540, flex: 0.8, position: 'relative', marginTop: 10 },
	priority_info_box: {
		width: '48%',
		backgroundColor: theme.colors.white,
		borderRadius: 6,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		padding: 12,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: 5,
		textAlign: 'center',
	},
	smart_allocation_tips_box: {
		display: 'flex',
		backgroundColor: theme.colors.white,
		padding: 12,
		boxShadow: '0px 1.76471px 3.52941px 0px rgba(196, 196, 196, 0.30)',
		borderRadius: 6,
		marginTop: 13,
	},
	active_tab: {
		padding: '8px 12px',
		backgroundColor: theme.colors.primary,
		color: theme.colors.white,
		display: 'flex',
		borderRadius: 8,
	},
	inactive_tab: {
		padding: '8px 12px',
		backgroundColor: theme.colors.white,
		color: theme.colors.lightPurple7,
		display: 'flex',
		borderRadius: 8,
		cursor: 'pointer',
	},
	network_category_text: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 10 },
	tips_wrap: {
		backgroundColor: theme.colors.lightPurple,
		marginRight: 0,
		width: 'fit-content',
		borderRadius: 5,
		display: 'flex',
		justifyContent: 'center',
		padding: '8px 8px',
		gap: 5,
	},
}));

function AutoManifestSettings() {
	const { network_configs } = useSelector(
		(state) => ({
			network_configs: state.app.app_configs.network_configs,
		}),
		shallowEqual,
	);
	const networks_list = _.map(network_configs, (value, index) => {
		return { ...value, id: index };
	});
	const history = useHistory();
	const [allocation_settings, set_allocation_settings] = useState([]);
	const [selected_allocation_index, set_selected_allocation_index] = useState(null);
	const [selected_card_id, set_selected_card_id] = useState(null);
	const classes = useStyles();

	useEffect(() => {
		get_initial_default_configs();
	}, []);

	const get_initial_default_configs = async () => {
		try {
			const res = await api_requests.get_smart_allocation_configs();
			const filtered = _.filter(res.data, (item) => item.order_process_type === 2);
			set_allocation_settings(filtered);
			if (filtered.length > 0) {
				set_selected_allocation_index(0);
			}
		} catch (err) {}
	};

	const handle_update_allocation_setting = (new_setting) => {
		let _allocations_settings = _.cloneDeep(allocation_settings);
		_allocations_settings[selected_allocation_index] = new_setting;
		set_allocation_settings(_allocations_settings);
	};

	const handle_add_allocation = () => {
		set_allocation_settings([
			...allocation_settings,
			{
				config: [[], [], [], [], [], []],
				description: '',
				default: false,
				order_process_type: 2,
				owner_id: parseInt(utils._retrieveData('owner_id')),
				owner_type: utils._retrieveData('type_of_owner').id,
			},
		]);
		set_selected_allocation_index(allocation_settings.length);
	};

	const handle_desc_change = (e) => {
		let _allocations = _.cloneDeep(allocation_settings);
		_allocations[selected_allocation_index].description = e.target.value;
		set_allocation_settings(_.cloneDeep(_allocations));
	};

	const handle_check_click = () => {
		let _allocations = _.cloneDeep(allocation_settings);
		if (_allocations[selected_allocation_index].default == true) {
			_allocations[selected_allocation_index].default = false;
		} else {
			for (let i = 0; i < _allocations.length; i++) {
				_allocations[i].default = false;
			}
			_allocations[selected_allocation_index].default = true;
		}
		set_allocation_settings(_allocations);
	};

	const handle_save_allocation = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.AUTO_MANIFEST_CREATED);
		try {
			let data = _.map(allocation_settings, (setting) => {
				const _config = _.filter(setting.config, (config) => {
					return config.length > 0;
				});
				return { ..._.omit(setting, 'updated_at'), config: _config };
			});
			const res = await api_requests.set_smart_allocation_configs({ configs: data });
			toast.success('Settings updated successfully.', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			get_initial_default_configs();
		} catch (err) {}
	};

	const save_disabled = useMemo(() => {
		let _result = false;
		_.forEach(allocation_settings, (setting) => {
			let _config = _.filter(setting.config, (config) => {
				return config.length > 0;
			});
			if (_config.length == 0 || setting.description.length == 0) {
				_result = true;
			}
		});
		return _result;
	}, [allocation_settings]);

	return (
		<>
			<div style={{ position: 'relative', padding: '25px 30px' }}>
				<div className={classes.heading_container}>
					<div className='align-items-center'>
						<div className='page_back_btn' onClick={() => history.push(Routes.SETTINGS.path)}>
							<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
						</div>
						<Text semi className={'global_header_text'}>
							Auto Manifest
						</Text>
					</div>
					<ListItem key={`tips`} className={classes.tips_wrap}>
						<img src={ImageLinks.eye_open} />
						<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
							Tips & Info
						</Text>
					</ListItem>
				</div>
				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: 20, flex: '1' }}>
						<Text medium className={classes.sub_heading}>
							Prioritize optimal fulfillment partner selection by setting preferences for Pidge AI.
						</Text>
						<div className='d-flex' style={{ marginTop: 30, flexWrap: 'wrap' }}>
							{_.map(allocation_settings, (allocation, index) => {
								return (
									<div
										key={`manifest_item_${index}`}
										className={index == selected_allocation_index ? classes.active_tab : classes.inactive_tab}
										onClick={() => set_selected_allocation_index(index)}>
										<Text bold>{`AM-${index + 1}`}</Text>
									</div>
								);
							})}
							<div className={classes.inactive_tab}>
								<Text bold onClick={handle_add_allocation}>
									+ New
								</Text>
							</div>
						</div>
						<Divider style={{ margin: '10px 0' }} />
						{selected_allocation_index != null && (
							<div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
								<OutlinedTextField
									inputProps={{ maxLength: 30 }}
									InputProps={{
										endAdornment: (
											<Text medium style={{ fontSize: 10, width: 40, color: theme.colors.lightGrey2 }}>
												{allocation_settings[selected_allocation_index].description.length}/30
											</Text>
										),
									}}
									label='Description*'
									value={allocation_settings[selected_allocation_index].description}
									onChange={handle_desc_change}
									style={{ width: '100%', marginTop: 20 }}
									maxRows={2}
									placeholder='Describe how and when this smart allocation will be used'
								/>
								<div className='d-flex align-items-center'>
									<Checkbox
										size='small'
										checked={allocation_settings[selected_allocation_index].default == true}
										onClick={handle_check_click}
									/>
									<Text semi style={{ fontSize: 14 }}>
										Set as default smart allocation
									</Text>
								</div>

								<Text semi style={{ marginTop: 10 }}>
									Priority Setup
								</Text>
								<Divider style={{ margin: '10px 0' }} />

								<Text semi className={classes.network_category_text}>
									Private Pidge Powered Networks
								</Text>
								{_.map(
									_.filter(networks_list, (network) => {
										return network.implementation == 'ppn-private' && network.type == 1 && _.get(network, 'config.allow_manifest', false);
									}),
									(network) => {
										return (
											<NetworkBox
												handle_update_allocation_setting={handle_update_allocation_setting}
												allocation_settings={_.cloneDeep(allocation_settings[selected_allocation_index])}
												network={network}
												selected_card_id={selected_card_id}
												set_selected_card_id={set_selected_card_id}
												title={``}
												image={
													<NetworkConfigAvatar
														name={network.name}
														height={30}
														width={30}
														logo_width={network.name === 'captive' ? 100 : 60}
														id={network.id}
													/>
												}
											/>
										);
									},
								)}
								<Text semi className={classes.network_category_text}>
									Other Private Networks
								</Text>
								{_.map(
									_.filter(networks_list, (network) => {
										return network.implementation != 'ppn-private' && network.type == 1 && _.get(network, 'config.allow_manifest', false);
									}),
									(network) => {
										return (
											<NetworkBox
												handle_update_allocation_setting={handle_update_allocation_setting}
												allocation_settings={_.cloneDeep(allocation_settings[selected_allocation_index])}
												network={network}
												selected_card_id={selected_card_id}
												set_selected_card_id={set_selected_card_id}
												title={``}
												image={
													<NetworkConfigAvatar
														name={network.name}
														height={30}
														width={30}
														logo_width={network.name === 'captive' ? 100 : 60}
														id={network.id}
													/>
												}
											/>
										);
									},
								)}
								<Text semi className={classes.network_category_text}>
									Public Pidge Powered Networks
								</Text>
								{_.map(
									_.filter(networks_list, (network) => {
										return network.type == '2' && network.implementation == 'ppn-private';
									}),
									(network) => {
										return (
											<NetworkBox
												handle_update_allocation_setting={handle_update_allocation_setting}
												allocation_settings={_.cloneDeep(allocation_settings[selected_allocation_index])}
												selected_card_id={selected_card_id}
												set_selected_card_id={set_selected_card_id}
												network={network}
												title={''}
												image={
													<NetworkConfigAvatar
														name={network.name}
														height={30}
														width={30}
														logo_width={network.name === 'captive' ? 100 : 60}
														id={network.id}
													/>
												}
											/>
										);
									},
								)}

								<Text semi className={classes.network_category_text}>
									Other Public Networks
								</Text>
								{_.map(
									_.filter(networks_list, (network) => {
										return network.implementation != 'ppn-private' && _.get(network, 'config.allow_manifest', false);
									}),
									(network) => {
										return (
											<NetworkBox
												handle_update_allocation_setting={handle_update_allocation_setting}
												allocation_settings={_.cloneDeep(allocation_settings[selected_allocation_index])}
												selected_card_id={selected_card_id}
												network={network}
												set_selected_card_id={set_selected_card_id}
												title={''}
												image={
													<div>
														{network.avatar.image ? (
															<img src={network.avatar.image} width={'auto'} height={16} />
														) : (
															<Text bold>{network.name}</Text>
														)}
													</div>
												}
											/>
										);
									},
								)}
							</div>
						)}
					</div>

					<div className={classes.info_container}>
						<Text bold style={{ fontSize: 16, color: theme.colors.lightPurple7 }}>
							Types of Priorities
						</Text>
						<div className='d-flex justify-content-between' style={{ flexWrap: 'wrap', gap: 10, marginTop: 8 }}>
							{_.map(priorities, (priority) => {
								return (
									<div className={classes.priority_info_box}>
										<img src={priority.img} />
										<Text semi style={{ fontSize: 14 }}>
											{priority.title}
										</Text>
										<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
											{priority.sub_title}
										</Text>
									</div>
								);
							})}
						</div>
						<div style={{ marginTop: 30 }}>
							<Text bold style={{ fontSize: 16, color: theme.colors.lightPurple7 }}>
								Auto manifesting for hassle free planning
							</Text>
							<Text medium style={{ fontSize: 12, marginTop: 8 }}>
								Auto Manifest takes your preferences, limits, and partner constraints to create an optimized fulfillment selection. These
								configurations can be used on dashboard and in all integrations as well.
							</Text>
							<div className={clsx(classes.smart_allocation_tips_box, 'align-items-center')}>
								<img src={ImageLinks.smart_allocation} width={40} height={40} style={{ borderRadius: 20 }} />
								<div style={{ marginLeft: 8 }}>
									<Text bold style={{ fontSize: 14 }}>
										Auto Manifest
									</Text>

									<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Efficient partner selection with Pidge AI
									</Text>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ position: 'absolute', bottom: 30, right: 30 }}>
				<Button disabled={save_disabled} onClick={handle_save_allocation} text='Save' type='round' size='large' style={{ width: 110 }} />
			</div>
		</>
	);
}

export default AutoManifestSettings;
