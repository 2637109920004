import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
		width: '100%',
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 500,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		zIndex: 1200,
		overflowY: 'hidden',
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 30,
		paddingRight: 30,
	},

	header: {
		paddingBottom: 5,
		height: 62,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},

	footer: {
		height: 100,
		width: 500,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 20,
		paddingRight: 20,
		display: 'flex',
		justifyContent: 'space-between',
	},
	footer_content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flex: 1,
	},

	allocate_card: {
		padding: 12,
		minHeight: 64,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		cursor: 'pointer',
		display: 'flex',
		marginTop: 8,
	},
	scroll_content: {
		overflowY: 'auto',
		paddingBottom: 170,
		height: '100%',
		paddingRight: 30,
		marginTop: 14,
	},
	disabled_card: {
		opacity: 0.3,
		pointerEvents: 'none',
	},

	supplier_wrapper: {
		width: 460,
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
		position: 'relative',
		overflow: 'hidden',
		maxHeight: 30,
		lineHeight: '1em',
	},
	supplier_wrap_expanded: {
		maxHeight: 500,
		WebkitLineClamp: 'unset',
		overflow: 'scroll',
	},
	supplier_wrap: {
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
		border: `1px solid ${theme.colors.lightPink4}`,
		backgroundColor: theme.colors.lightPink9,
		width: 460,
		position: 'fixed',
		bottom: 100,
		right: 20,
	},
	supplier_header: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 30,
		borderBottom: `1px solid ${theme.colors.lightPink4}`,
		marginLeft: 20,
		marginRight: 20,
	},
	show_hide_text: { fontSize: 12, color: theme.colors.primary, cursor: 'pointer', textDecoration: 'underline' },
	section_wrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: 30,
	},
	section_item_wrap: {
		flex: 1,
		display: 'flex',
	},
	revert_btn: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 75,
		height: 26,
		borderRadius: 30,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.lightRed,
		},
	},
	revert_text: { fontSize: 12, color: theme.colors.red, textDecoration: 'underline' },
}));

export default useStyles;
