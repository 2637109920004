import React, { useState } from 'react';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import Text from 'modules/Text';
import IconButton from 'modules/IconButton';

import cross from 'assets/images/icons/cross-black.svg';
import OutlinedTextField from 'modules/OutlinedTextField';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import api_requests from 'resources/api_requests';
import _ from 'lodash';
import Upload from 'modules/Upload';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 600,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 50,
		zIndex: 1200,
		overflowY: 'auto',
	},
	pbidContainer: {
		fontSize: 12,
		color: theme.colors.primary,
	},
}));

const SettleCash = ({ close, refresh_stats }) => {
	const { user_details } = useSelector(({ app }) => app);
	const [form_data, set_form_data] = React.useState({
		amount: '',
		remark: '',
		bank_txn_id: '',
		proof: '',
	});
	const [is_api_going, set_is_api_going] = useState(false);

	const classes = useStyles();

	const handle_settle_amount = () => {
		set_is_api_going(true);
		api_requests
			.direct_settle(form_data)
			.then((res) => {
				refresh_stats();
				set_is_api_going(false);
				close();
			})
			.catch((err) => set_is_api_going(false));
	};

	const handle_change = (name, value) => {
		set_form_data({
			...form_data,
			[name]: value,
		});
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div
					style={{
						borderBottom: '1px solid lightgrey',
						paddingBottom: 10,
						display: 'flex',
						justifyContent: 'space-between',
					}}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Text semi style={{ fontSize: 20, color: theme.colors.primary }}>
							Settle Cash
						</Text>
					</div>
					<IconButton onClick={close} style={{ border: 'none' }}>
						<img src={cross} width={16} height={16} />
					</IconButton>
				</div>
				<div>
					<div>
						<Text semi style={{ fontSize: 12, marginTop: 20 }}>
							Enter Amount*
						</Text>
						<div style={{ position: 'relative' }}>
							<Text style={{ position: 'absolute', left: 15, top: 18, fontSize: 20, color: theme.colors.black }}>₹</Text>
							<OutlinedTextField
								InputProps={{ style: { borderRadius: 50, height: 45, paddingLeft: 20, fontSize: 18 } }}
								style={{ width: 200, marginTop: 10 }}
								value={form_data.amount}
								type='number'
								onChange={(e) => handle_change('amount', e.target.value)}
							/>
						</div>
					</div>
					<div>
						<Text semi style={{ fontSize: 12, marginTop: 32 }}>
							Enter Bank Transaction ID*
						</Text>
						<OutlinedTextField
							InputProps={{ style: { borderRadius: 50, height: 45 } }}
							style={{ width: 200, marginTop: 10 }}
							value={form_data.bank_txn_id}
							onChange={(e) => handle_change('bank_txn_id', e.target.value)}
						/>
					</div>
					<div>
						<Text semi style={{ fontSize: 12, marginTop: 32 }}>
							Enter Remarks
						</Text>
						<textarea
							value={form_data.remark}
							onChange={(e) => handle_change('remark', e.target.value)}
							style={{
								width: '100%',
								marginTop: 10,
								borderRadius: 10,
								borderColor: theme.colors.lightGrey2,
								padding: 10,
								resize: 'none',
							}}
							rows='8'></textarea>
					</div>

					<div>
						<Text semi style={{ fontSize: 12, marginTop: 32 }}>
							Upload Proof
						</Text>
						<Upload
							style={{
								width: 300,
								marginTop: 10,
								borderRadius: 10,
								border: `2px dashed ${theme.colors.lightGrey2}`,
								height: 190,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								background: theme.colors.lightGrey4,
							}}
							path={`/cash/settlement/user_${user_details.user_id}/${Date.now()}`}
							accepted_extensions='.jpg,.jpeg,.png'
							add_image={(data) => {
								handle_change('proof', data.url);
							}}>
							<img src={ImageLinks.upload} />
							<Text style={{ marginTop: 10 }}>Photograph</Text>
							<ContainedButton style={{ borderRadius: 20, marginTop: 20 }}>
								<Text>Upload</Text>
							</ContainedButton>
							<Text style={{ marginTop: 5 }}>{form_data.proof !== '' && 'File added'}</Text>
						</Upload>
					</div>
					<div style={{ textAlign: 'right' }}>
						<Button
							type='round'
							text='Settle Amount'
							size='large'
							onClick={handle_settle_amount}
							disabled={_.isEmpty(form_data.amount) || form_data.bank_txn_id === '' || form_data.proof === '' || is_api_going}
							style={{ width: 170, marginTop: 50 }}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default SettleCash;
