import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import { DateRangePicker } from 'materialui-daterange-picker';
import Text from 'modules/Text';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import moment from 'moment';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import Amount from '../../../modules/Amount';

const DetailedTransactionHistory = ({ user_type, id }) => {
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);
	const [filters, set_filters] = React.useState({
		from: moment(moment().subtract('15', 'days').format('MM-DD-YYYY')),
		to: moment(moment().format('MM-DD-YYYY')),
	});

	const [transactions, set_transactions] = React.useState([]);

	useEffect(() => {
		if (!id) {
			return;
		}
		set_transactions([]);
		api_requests
			.get_transactions_per_day({
				from: filters.from.format(),
				to: filters.to.endOf('day').format(),
				user_type,
				id,
			})
			.then((res) => {
				set_transactions(res.data.records);
			});
	}, [filters, id, user_type]);

	return (
		<div>
			<div style={{ padding: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Text bold style={{ fontSize: 16, color: theme.colors.lightGrey2 }}>
					Transaction History
				</Text>
				<div style={{ position: 'relative' }}>
					<UndlerlineTextField
						value={`${filters.from.format('DD/MM/YY')} - ${filters.to.format('DD/MM/YY')}`}
						label=''
						onClick={() => setDatePickerOpen(true)}
						style={{ width: 150, alignItems: 'center' }}
					/>
					<div style={{ position: 'absolute', right: 0, width: 700 }}>
						<DateRangePicker
							open={datePickerOpen}
							toggle={() => setDatePickerOpen(!datePickerOpen)}
							style={{ width: 200 }}
							initialDateRange={{
								startDate: filters.from,
								endDate: filters.to,
							}}
							maxDate={new Date()}
							onChange={(range) => {
								const newDateRange = {
									from: moment(range.startDate),
									to: moment(range.endDate),
								};

								if (Math.abs(newDateRange.from.diff(newDateRange.to, 'days')) > 15) {
									toast.error('Only 15 days filter allowed', {
										position: toast.POSITION.BOTTOM_RIGHT,
									});
									return;
								}

								set_filters(newDateRange);
								setDatePickerOpen(!datePickerOpen);
							}}
						/>
					</div>
				</div>
			</div>
			{id && transactions.length === 0 && (
				<div className='align-items-center justify-content-center' style={{ height: 400 }}>
					<Text semi style={{ fontSize: 16 }}>
						No Data
					</Text>
				</div>
			)}
			{transactions.length > 0 && (
				<div style={{ padding: '0px 20px 20px 20px' }}>
					{_.map(transactions, (data, key) => (
						<div
							key={`transaction${key}`}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								borderBottom: `1px solid ${theme.colors.lightGrey5}`,
								height: 60,
							}}>
							<div style={{ width: 90 }}>
								<Text semi>{moment(data.date).format('DD MMM ‘YY')}</Text>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
								<div
									style={{
										height: 15,
										width: 15,
										borderRadius: '100%',
										background: theme.colors.darkPurple,
										marginRight: 10,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<img src={ImageLinks.down_arrow} />
								</div>
								<Amount
									amount={data.credit}
									main_text_styles={{ color: theme.colors.black, fontSize: 14 }}
									sub_text_styles={{ color: theme.colors.black, fontSize: 8 }}
								/>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
								<div
									style={{
										height: 15,
										width: 15,
										borderRadius: '100%',
										background: theme.colors.green,
										marginRight: 10,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<img src={ImageLinks.arrow_up_white} />
								</div>
								<Amount
									amount={data.debit}
									main_text_styles={{ color: theme.colors.black, fontSize: 14 }}
									sub_text_styles={{ color: theme.colors.black, fontSize: 8 }}
								/>
							</div>
							{/* <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
							<div style={{ height: 15, width: 15, borderRadius: '100%', background: theme.colors.lightGrey5, marginRight: 10 }}></div>
							<Amount
								amount={data.outstanding}
								main_text_styles={{ color: theme.colors.black, fontSize: 14 }}
								sub_text_styles={{ color: theme.colors.black, fontSize: 8 }}
							/>
						</div> */}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default DetailedTransactionHistory;
