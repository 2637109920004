import React, { useContext, useState } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Select from 'modules/Select';
import _ from 'lodash';
import moment from 'moment';
import { formatBytes } from './helper';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import Papa from 'papaparse';
import Upload from 'modules/Upload';
import RepeatOrdersContext from '../context';
import utils from 'resources/utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import file_process from 'assets/lottie/file_process.json';
import Lottie from 'react-lottie';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '20px 0px',
	},
	search_key_button_text: {
		fontSize: 14,
		lineHeight: 'unset',
		color: theme.colors.lightPurple6,
	},
	search_key: {
		padding: '6px 20px',
		border: `1px solid ${theme.colors.lightPurple6}`,
		cursor: 'pointer',
		borderRadius: '30px',
		lineHeight: 'unset',
		color: theme.colors.lightPurple6,
	},
	search_key_selected: {
		padding: '6px 20px',
		border: `1px solid ${theme.colors.primary}`,
		cursor: 'pointer',
		borderRadius: '30px',
		lineHeight: 'unset',
		color: theme.colors.primary,
		backgroundColor: theme.colors.lightPink4,
	},
	delivery_slots_content: {
		'&:disabled': {
			border: '1px solid grey',
			cursor: 'unset',
		},
	},
	upload_csv_content_container: {
		width: '100%',
		minHeight: 250,
		borderRadius: 10,
		border: `1px dashed ${theme.colors.lightGrey2}`,
		marginTop: 20,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	upload_csv_file_information: {
		padding: '20px 16px',
		borderRadius: 4,
		backgroundColor: theme.colors.lightGrey8,
		marginTop: 24,
		width: '100%',
	},
	upload_pending_file_information: {
		padding: '20px 16px',
		borderRadius: 4,
		marginTop: 24,
		width: '100%',
	},
	cross_button: {
		background: theme.colors.lightRed,
		padding: '2px 8px',
		borderRadius: '50%',
		position: 'absolute',
		top: '-8px',
		right: '-8px',
		cursor: 'pointer',
	},
	disabled_upload_text: {
		fontSize: 12,
		marginTop: 28,
		marginBottom: 12,
		color: theme.colors.darkGrey2,
	},
	date_filter: {
		height: 36,
		borderRadius: 36,
		border: `1px solid ${theme.colors.lightPurple}`,
		backgroundColor: theme.colors.white,
		width: 186,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 14,
		paddingRight: 8,
	},
}));

const RepeatOrdersUploadForm = () => {
	const {
		data_search_key,
		get_delivery_slots,
		delivery_slot,
		delivery_date,
		set_delivery_date,
		selected_delivery_slot_value,
		set_selected_delivery_slot_value,
		set_uploaded_csv_file,
		uploaded_csv_file,
		handle_repeat_order_csv,
		progress,
		upload_csv_status,
		steps,
		set_steps,
		in_locked_state,
		remove_csv,
		no_of_orders,
		set_no_of_orders,
	} = useContext(RepeatOrdersContext);
	const classes = useStyles();

	const handle_csv = (fileInfo, inputRef) => {
		set_uploaded_csv_file(fileInfo);
		Papa.parse(fileInfo.file, {
			header: false,
			skipEmptyLines: true,
			complete: (results) => {
				inputRef.current = null;
				const data = results.data;
				let is_format_invalid = false;
				let correct_contact_number = new Set();
				let incorrect_contact_number = new Set();
				_.map(data, (contact_details, key) => {
					if (key === 0 || is_format_invalid) return;
					if (contact_details.length > 1) is_format_invalid = true;
					const customer_mobile = contact_details[0];
					if (_.isEmpty(customer_mobile)) {
						return;
					}
					let regex = new RegExp(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?\d{10}$/);
					if (regex.test(customer_mobile)) {
						correct_contact_number.add(utils.mobile_slice(customer_mobile));
					} else {
						incorrect_contact_number.add(customer_mobile);
					}
				});
				set_no_of_orders(is_format_invalid ? 0 : data.length - 1);
				handle_repeat_order_csv(Array.from(correct_contact_number), Array.from(incorrect_contact_number), fileInfo.name, is_format_invalid);
			},
		});
	};

	// const handle_data_search_key = (key) => {
	// 	if (key === 'customer_detail.mobile') {
	// 		set_data_search_key('customer_detail.mobile');
	// 	} else if (key === 'customer_detail.custom') {
	// 		set_data_search_key('customer_detail.custom');
	// 	}
	// 	set_steps(2);
	// };

	const handle_delivery_date = (date) => {
		set_delivery_date(date);
		set_selected_delivery_slot_value(null);
		get_delivery_slots(date);
	};

	return (
		<div className={classes.container}>
			<div className='d-flex'>
				<div style={{ width: '44%' }}>
					<div className='justify-content-between align-items-center'>
						<div>
							<Text bold style={{ fontSize: 16, marginBottom: 4 }}>
								Data Search key*
							</Text>
							<Text style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>Primary column to search in CSV</Text>
						</div>
						<div className='d-flex'>
							<div
								//onClick={() => handle_data_search_key('customer_detail.mobile')}
								className={data_search_key === 'customer_detail.mobile' ? classes.search_key_selected : classes.search_key}>
								<Text style={{ fontSize: 14 }}>Mobile</Text>
							</div>
							{/* <div
								style={{ marginLeft: 10 }}
								className={data_search_key === 'customer_detail.custom' ? classes.search_key_selected : classes.search_key}
								onClick={() => handle_data_search_key('customer_detail.custom')}>
								<Text style={{ fontSize: 14 }}>Custom</Text>
							</div> */}
						</div>
					</div>
					<div className='justify-content-between align-items-center' style={{ marginTop: 20 }}>
						<Text bold style={{ fontSize: 16, marginBottom: 4 }}>
							Delivery Date*
						</Text>
						<div>
							<DatePicker
								disabled={in_locked_state}
								selected={delivery_date}
								onChange={(date) => handle_delivery_date(date)}
								dateFormat='dd-MM-yyyy'
								customInput={
									<div className={classes.date_filter} style={in_locked_state ? { backgroundColor: theme.colors.lightPink4 } : {}}>
										<Text
											semi
											style={{
												fontSize: 14,
												color: delivery_date == '' ? theme.colors.lightPurple6 : theme.colors.primary,
											}}>
											{delivery_date == '' ? 'DD/MM/YY' : `${moment(delivery_date).format('DD-MM-YYYY')}`}
										</Text>
										<img src={ImageLinks.calendar_solid_circle} alt='calendar_filter' />
									</div>
								}
							/>
						</div>
					</div>
					<div className='justify-content-between align-items-center' style={{ marginTop: 20 }}>
						<Text bold style={{ fontSize: 16, marginBottom: 4 }}>
							Delivery Slot*
						</Text>
						<Select
							disabled={steps < 3 || in_locked_state}
							value={selected_delivery_slot_value}
							defaultOption={{
								title: 'HH:MM-HH:MM',
								value: null,
							}}
							className={classes.delivery_slots_content}
							placeholder='Select Delivery Slots'
							options={_.map(delivery_slot, (delivery_slot_value) => {
								return {
									title: delivery_slot_value.title,
									value: delivery_slot_value.value,
								};
							})}
							handleChange={(val) => {
								set_selected_delivery_slot_value(val);
								set_steps(4);
							}}
							containerStyles={{
								...(in_locked_state && { cursor: 'unset' }),
								height: 35,
								width: 186,
								backgroundColor: in_locked_state ? theme.colors.lightPink4 : theme.colors.white,
								border: `1px solid ${theme.colors.lightPurple}`,
							}}
							menu_styles={{ marginTop: 40, width: 186 }}
							text_props={{ semi: true }}
							selected_title_styles={{ color: _.isEmpty(selected_delivery_slot_value) ? theme.colors.lightPurple6 : theme.colors.primary }}
							truncate_title={(title) => {
								return _.truncate(title, { length: 16 });
							}}
						/>
					</div>
				</div>
				<div style={{ width: '45%' }}>
					<div className='d-flex'>
						<div style={{ marginRight: 20, marginLeft: 20 }}>
							<Divider orientation='vertical' />
						</div>
						<div className='w-100'>
							<div>
								{steps < 4 ? (
									<div className={classes.upload_csv_content_container}>
										<img src={ImageLinks.upload_light_grey} width={52} height={52} alt='upload-image' />
										<Text className={classes.disabled_upload_text}>Upload CSV file from computer</Text>
										<Button type='round' text='Select File' size='medium' disabled={true} />
									</div>
								) : (
									<div>
										{upload_csv_status === 'success' && progress >= 100 ? (
											<div className={clsx(classes.upload_csv_content_container, 'text-align-center')} style={{ padding: 32 }}>
												<img src={ImageLinks.tickCircleSolid} width={52} height={52} alt='green-tick' />
												<Text medium style={{ marginTop: 24, fontSize: 14 }}>
													CSV uploaded successfully!
												</Text>
												<div className={classes.upload_csv_file_information} style={{ position: 'relative' }}>
													<div className={classes.cross_button} onClick={remove_csv}>
														<img src={ImageLinks.crossRed} alt='cross' width={8} height={8} />
													</div>
													<div className='justify-content-between align-items-center'>
														<div className='d-flex align-items-center'>
															<img src={ImageLinks.folder_light_purple} width={24} height={24} />
															<Text component={'div'} style={{ fontSize: 12, paddingLeft: 20, paddingRight: 8 }}>
																{_.get(uploaded_csv_file, 'name', '')}
															</Text>
															<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
																{no_of_orders} Entries
															</Text>
														</div>
														{!_.isEmpty(_.get(uploaded_csv_file, 'file.size', null)) && (
															<Text bold component={'div'} style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
																{formatBytes(_.get(uploaded_csv_file, 'file.size', ''))}
															</Text>
														)}
													</div>
												</div>
											</div>
										) : upload_csv_status === 'failed' && progress >= 100 ? (
											<Upload accepted_extensions='.csv' add_image={handle_csv}>
												<div className={clsx(classes.upload_csv_content_container, 'text-align-center')} style={{ padding: 32 }}>
													<img
														src={ImageLinks.information_reverse}
														width={52}
														height={52}
														style={{ background: theme.colors.red, borderRadius: '50%' }}
													/>
													<Text medium style={{ marginTop: 24, fontSize: 14 }}>
														Only one column - mobile - is allowed.
													</Text>
													<Button type='round' text='Upload Again' size='medium' disabled={true} />
												</div>
											</Upload>
										) : progress < 100 && progress > 0 ? (
											<div className={clsx(classes.upload_csv_content_container, 'text-align-center')} style={{ padding: 32 }}>
												<Lottie
													options={{
														loop: true,
														autoplay: true,
														animationData: file_process,
														rendererSettings: {
															preserveAspectRatio: 'xMidYMid slice',
														},
													}}
													height={82}
													width={82}
												/>
												<Text medium style={{ marginTop: 24, fontSize: 14 }}>
													Starting the process
												</Text>
												<div className={classes.upload_pending_file_information}>
													<div className='d-flex justify-content-between'>
														<img src={ImageLinks.folder_light_purple} width={24} height={24} />
														<Text style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>{progress}%</Text>
													</div>
													<LinearProgress variant='determinate' value={progress} />
												</div>
											</div>
										) : (
											<Upload accepted_extensions='.csv' add_image={handle_csv} disabled>
												<div button className={classes.upload_csv_content_container}>
													<img src={ImageLinks.upload_light_purple} width={47} height={47} alt='upload-image' />
													<Text style={{ fontSize: 12, marginTop: 28, marginBottom: 12 }}>Upload CSV file from computer</Text>
													<ContainedButton style={{ borderRadius: 30 }}>
														<Text bold style={{ fontSize: 14 }}>
															Select File
														</Text>
													</ContainedButton>
												</div>
											</Upload>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RepeatOrdersUploadForm;
