import React, { useEffect } from 'react';
import moment from 'moment';
import Text from 'modules/Text';
import theme from 'resources/theme';
import _, { create } from 'lodash';
import { ListItem, makeStyles } from '@material-ui/core';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { hide_loader, show_loader } from 'actions/app';
import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import Filters from 'modules/Filters/Filters';
import clsx from 'clsx';
import Routes from 'resources/Routes';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from '../helper';
import { useHistory } from 'react-router-dom';
import OrderLabel from 'modules/OrderLabel';
import utils from 'resources/utils';
import FilterButton from 'modules/FilterButton';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import Button from 'modules/Button';
import Helpcenter from 'modules/HelpCenter';
import NoResult from 'modules/NoResult';
import NetworkConfigAvatar from 'modules/NetworkConfigAvatar';
import constant from 'resources/constant';

const useStyles = makeStyles(() => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'hidden',
	},
	ongoingOrdersDiv: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 40 },
	onGoingTextContainer: { fontSize: 20, fontWeight: 'bold', marginRight: 50 },
	filter_btn: {
		backgroundColor: theme.colors.white,
		marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		flex: 1,
		marginTop: 10,
		marginBottom: 30,
		overflowY: 'hidden',
	},
	box: {
		background: theme.colors.white,
		height: '100%',
		padding: 10,
		borderRadius: 8,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
	},
	POWERED_WITH_PIDGE_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		background: theme.colors.lightPurple,
		alignItems: 'center',
		borderRadius: 5,
		padding: '6px 12px',
	},
	header_wrapper: { borderBottom: `1px solid ${theme.colors.lightGrey5}`, marginTop: 25, padding: '5px 8px', marginBottom: 5 },
	item_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 5,
		padding: '6px 12px',
	},
	item_key_text: { color: theme.colors.darkGrey2 },
	filter_wrap: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: 40,
		marginTop: -40,
	},
	detailsTextContainer: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
	},
	header_title_2: {
		flex: 2,
		color: theme.colors.darkGrey2,
		fontSize: 14,
	},
	pickedUpContainer: {
		flex: 1,
		overflowY: 'auto',
		marginBottom: 10,
	},
	pickedUpItem: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
	},
	table_item_wrap_2: { flex: 2, minWidth: 0 },
	truncate: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 16,
	},
	orderDetailsClickabletext: {
		flex: 1,
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
}));

const Overview = () => {
	const dispatch = useDispatch();
	const { app } = useSelector((state) => state, shallowEqual);

	const [all_filters, set_all_filters] = React.useState([]);
	const [selected_filters, set_selected_filters] = React.useState({});
	const [show_filters, set_show_filters] = React.useState(false);
	const [order_stats, set_order_stats] = React.useState({});
	const [orders, set_orders] = React.useState({});
	const [show_order_details, set_show_order_details] = React.useState(false);
	const [selected_pb_id, set_selected_pb_id] = React.useState('');
	const [order_list, set_order_list] = React.useState([]);
	const [show_empty_state, set_show_empty_state] = React.useState(false);
	const slider_ref = React.useRef();
	const loading_scroll = React.useRef(false);

	useEffect(() => {
		api_requests.get_overview_filters().then((res) => set_all_filters(res.data.filters));
		get_orders_stats();
		const timer = setTimeout(() => {
			set_show_empty_state(true);
		}, 100);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	const get_orders_stats = () => {
		set_order_stats({});
		api_requests
			.get_pwp_overview_orders_stats({
				created_at: moment().subtract('15', 'days').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'),
			})
			.then((res) => {
				set_order_stats(res.data);
			});
	};

	const get_orders = ({ token }) => {
		dispatch(show_loader());
		set_orders({});
		const _selected_filters = _.cloneDeep(selected_filters);
		if (_.isEmpty(_selected_filters)) {
			const created_at_date_filter = moment().subtract('15', 'days').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
			_selected_filters.created_at = created_at_date_filter;
		}

		api_requests
			.get_overview_orders({
				..._selected_filters,
				...(token && { token }),
			})
			.then((res) => {
				loading_scroll.current = false;
				set_orders(res.data);
				dispatch(hide_loader());
				if (token) {
					set_order_list(_.concat(order_list, res.data.data));
				} else {
					set_order_list(res.data.data);
				}
			})
			.catch(() => dispatch(hide_loader()));
	};
	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && orders.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_orders({ token: orders.next });
		}
	};

	const handle_export = async (filters, callback) => {
		try {
			const res = await api_requests.export_all_orders_pwp({ ...filters }, 'overview');
			utils.downloadBlob(res, 'orders.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	useEffect(() => {
		get_orders({});
	}, [selected_filters]);

	const classes = useStyles();

	const history = useHistory();

	return (
		<>
			<div className={classes.filter_wrap}>
				<FilterButton
					handle_click={() => set_show_filters(true)}
					total_filters={Object.keys(selected_filters).length}
					track_event={{ event: 'PP_overview_filter', properties: { selected_filters } }}
				/>
			</div>
			<div className={classes.container}>
				<div className={classes.wrapper}>
					<div style={{ width: 280, marginRight: 20, marginLeft: 4, marginBottom: 50 }}>
						<div className={classes.ongoingOrdersDiv}>
							<Text semi style={{ fontSize: 18, color: theme.colors.lightPurple7 }}>
								Your 15 day Order Overview
							</Text>
						</div>
						<div className={classes.box} style={{ height: 280 }}>
							<div className={classes.POWERED_WITH_PIDGE_wrapper}>
								<Text semi style={{ color: theme.colors.darkGrey2 }}>
									Total Orders
								</Text>
								<Text bold style={{ fontSize: 20 }}>
									{order_stats.total || 0}
								</Text>
							</div>

							<ListItem
								button
								onClick={() => history.push(Routes.POWERED_WITH_PIDGE.path + '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.ReadyToShip.id)}
								className={classes.POWERED_WITH_PIDGE_wrapper}
								style={{ background: 'transparent', marginTop: 10 }}>
								<Text semi>READY TO SHIP</Text>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Text bold style={{ fontSize: 20, marginRight: 5 }}>
										{order_stats.registered || 0}
									</Text>
									<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
								</div>
							</ListItem>

							<div className={classes.header_wrapper}>
								<Text semi>ACTIVE ORDERS</Text>
							</div>

							<ListItem
								button
								onClick={() => history.push(Routes.POWERED_WITH_PIDGE.path + '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.OnGround.id)}
								className={classes.item_wrapper}>
								<Text semi className={classes.item_key_text}>
									On Ground
								</Text>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Text bold style={{ fontSize: 20, marginRight: 5 }}>
										{order_stats.onground || 0}
									</Text>
									<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
								</div>
							</ListItem>

							<ListItem
								button
								onClick={() => history.push(Routes.POWERED_WITH_PIDGE.path + '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.AtWarehouse.id)}
								className={classes.item_wrapper}>
								<Text semi className={classes.item_key_text}>
									At Warehouse
								</Text>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Text bold style={{ fontSize: 20, marginRight: 5 }}>
										{order_stats.warehouse || 0}
									</Text>
									<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
								</div>
							</ListItem>
							<ListItem
								button
								onClick={() => history.push(Routes.POWERED_WITH_PIDGE.path + '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.Terminal.id)}
								className={classes.item_wrapper}>
								<Text semi>COMPLETED</Text>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Text bold style={{ fontSize: 20, marginRight: 5 }}>
										{Number(order_stats.delivered || 0) + Number(order_stats.rto_delivered || 0) + Number(order_stats.lost_damaged || 0)}
									</Text>
									<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
								</div>
							</ListItem>
						</div>
						<div className={classes.ongoingOrdersDiv}>
							<Text semi style={{ fontSize: 18, color: theme.colors.lightPurple7 }}>
								My Routes
							</Text>
						</div>
						<div className={classes.box} style={{ height: 'auto' }}>
							<ListItem
								button
								onClick={() => history.push(`${Routes.GROUPING_AND_ALLOCATION.path}`)}
								className={classes.POWERED_WITH_PIDGE_wrapper}
								style={{ background: 'transparent', marginTop: 10 }}>
								<Text semi>Unallocated Routes</Text>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Text bold style={{ fontSize: 20, marginRight: 5 }}>
										{order_stats.unallocated_bundle_count || 0}
									</Text>
									<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
								</div>
							</ListItem>

							<ListItem
								button
								onClick={() => history.push(`${Routes.GROUPING_AND_ALLOCATION.path}?type=active`)}
								className={classes.POWERED_WITH_PIDGE_wrapper}
								style={{ background: 'transparent', marginTop: 10 }}>
								<Text semi>Active Routes</Text>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Text bold style={{ fontSize: 20, marginRight: 5 }}>
										{order_stats.allocated_bundle_count || 0}
									</Text>
									<img src={ImageLinks.chevron_right_black} width={10} height={10} alt='' />
								</div>
							</ListItem>
						</div>
					</div>
					<div style={{ flex: 1, overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
						<div className={classes.ongoingOrdersDiv}>
							<Text semi className={classes.onGoingTextContainer}>
								Total Orders | {orders.count ? orders.count : order_list.length}
							</Text>
						</div>
						<div className={classes.pickedUpContainer} ref={slider_ref} onScroll={slider_scrolled}>
							<div className={classes.detailsTextContainer} style={{ position: 'sticky', top: 0 }}>
								<Text bold className={classes.header_title_2}>
									Order Date
								</Text>
								<Text bold className={classes.header_title_2}>
									Order ID
								</Text>
								<Text bold className={classes.header_title_2}>
									Pickup Date
								</Text>
								<Text bold className={classes.header_title_2}>
									Terminal Date
								</Text>
								<Text bold className={classes.header_title_2}>
									Delivery Partner
								</Text>
								<Text bold className={classes.header_title_2}>
									Status
								</Text>
							</div>

							{_.map(order_list, (order, key) => {
								return (
									<div key={`order${key}`} className={classes.pickedUpItem}>
										<div className={classes.table_item_wrap_2}>{moment(order.created_at).format('DD/MM/YY')}</div>
										<div className={classes.table_item_wrap_2}>
											<Text
												semi
												className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
												onClick={() => {
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.PP_ORDERID);
													set_selected_pb_id(order.external_order_id);
													set_show_order_details(true);
												}}>
												{order.mcp_id || '-'}
											</Text>
										</div>

										<div className={classes.table_item_wrap_2}>
											{order.pickup_time ? moment(order.pickup_time).format('DD/MM/YYYY') : '-'}
										</div>
										<div className={classes.table_item_wrap_2}>{order.drop_time ? moment(order.drop_time).format('DD/MM/YYYY') : '-'}</div>

										<div className={classes.table_item_wrap_2}>
											<NetworkConfigAvatar id={order?.fulfillment_network_id} logo_width={120} />
										</div>
										<div className={classes.table_item_wrap_2}>
											<OrderLabel status={order.unified_status} />
										</div>
									</div>
								);
							})}
							{orders.count == 0 && !app.is_loading && show_empty_state && Object.keys(selected_filters).length == 0 && (
								<div
									className='d-flex justify-content-center align-items-center'
									style={{ position: 'absolute', left: '50%', top: '45%', flexDirection: 'column', gap: 10 }}>
									<img src={ImageLinks.empty_state_order} width={94} height={94} />
									<Text semi style={{ fontSize: 16 }}>
										No Orders in last 15 days
									</Text>
									<Button
										type='round'
										text='Create Order'
										size='small'
										left_icon={ImageLinks.addWhite}
										onClick={() => {
											utils.track_event_for_analytics(constant.TRACKING_EVENTS.PP_EMPTY_CREATE_ORDER);
											history.push(Routes.CREATE_ORDER.path);
										}}
									/>
									<Helpcenter
										text='How can I power my orders with Pidge?'
										url='https://pidge.in/helpcenter-order-poweredwithpidge'
										style={{ marginTop: 40 }}
									/>
								</div>
							)}
							{orders.count == 0 && !app.is_loading && show_empty_state && Object.keys(selected_filters).length != 0 && (
								<div
									className='d-flex justify-content-center align-items-center'
									style={{ position: 'absolute', left: '50%', top: '45%', flexDirection: 'column', gap: 10 }}>
									<NoResult text='No order found' btn_onclick={() => set_selected_filters({})} />
								</div>
							)}
						</div>
					</div>
				</div>
				{show_filters && (
					<Filters
						all_filters={all_filters}
						existing_filters={selected_filters}
						set_selected_filters={set_selected_filters}
						close={() => set_show_filters(false)}
						title='Overview'
						handle_export={handle_export}
					/>
				)}
				{show_order_details && <OrderModal id={selected_pb_id} updater={() => get_orders({})} close={() => set_show_order_details(false)} />}
			</div>
		</>
	);
};

export default Overview;
