import React, { useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import UsecaseListItem from 'views/Alerts/CreateAlert/components/UsecaseListItem';
import CreateCommunicationContext from '../context';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	use_case_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: `1px solid ${theme.colors.lightPurple}`,
		padding: 10,
		borderRadius: 36,
		height: 36,
		width: '28%',
		cursor: 'pointer',
		marginTop: 12,
		backgroundColor: theme.colors.white,
	},
	use_case_item_wrap: {
		position: 'relative',
		maxHeight: 285,
		width: '28%',
		borderRadius: 6,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.3)',
		overflowY: 'scroll',
		marginTop: 5,
	},
	sub_usecase_item_wrap: {
		cursor: 'pointer',
		left: '28%',
		background: theme.colors.white,
		position: 'absolute',
		top: -6,
		borderLeft: `1px solid ${theme.colors.primary}`,
	},
	new_alert_wrap: { display: 'flex', alignItems: 'center', backgroundColor: theme.colors.lightGrey7, height: 22, paddingLeft: 8 },
	create_new_alert_text: { fontSize: 12, color: theme.colors.primary, marginLeft: 5 },
	disabled_trigger: { backgroundColor: theme.colors.lightGrey5, border: `1px solid ${theme.colors.lightGrey2}`, cursor: 'auto' },
}));

const Trigger = () => {
	const {
		selected_subject,
		triggers_list,
		show_usecase,
		hover_usecase,
		selected_usecase,
		selected_sub_usecase,
		handle_hover_usecase,
		handle_selected_sub_usecase,
		on_usecase_select,
		handle_trigger_press,
		handle_show_usecase,
		comm_id,
	} = useContext(CreateCommunicationContext);

	const classes = useStyles();
	const [sub_menu, set_sub_menu] = useState([]);
	const [show_sub_menu, set_show_sub_menu] = useState(false);
	const [is_default, set_is_default] = useState(false);
	const component_ref = useRef(null);

	useEffect(() => {
		const handle_click_outside = (event) => {
			if (component_ref.current && !component_ref.current.contains(event.target)) {
				handle_show_usecase(false);
			}
		};
		document.addEventListener('click', handle_click_outside, true);
		return () => {
			document.removeEventListener('click', handle_click_outside, true);
		};
	}, []);

	const handle_usecase_hover = (usecase) => {
		handle_hover_usecase(usecase);
		if (_.get(usecase, 'values', []).length > 0) {
			set_show_sub_menu(true);
			set_sub_menu(usecase.values);
			set_is_default(usecase.default);
		}
	};

	const handle_sub_usecase_select = (usecase) => {
		handle_selected_sub_usecase(usecase);
		set_show_sub_menu(false);
		on_usecase_select(hover_usecase, usecase);
	};

	return (
		<div style={{ position: 'relative' }}>
			<Text bold style={{ fontSize: 18 }}>
				Trigger
			</Text>
			<Text medium style={{ color: theme.colors.darkGrey2, fontSize: 12 }}>
				Select the scenario that initiates the communication
			</Text>

			<div
				onClick={handle_trigger_press}
				className={clsx(classes.use_case_wrap, selected_subject === null && classes.disabled_trigger)}
				style={
					!_.isEmpty(comm_id)
						? {
								backgroundColor: theme.colors.lightPink4,
								border: `1px solid ${theme.colors.lightPurple6}`,
								cursor: 'auto',
						  }
						: {}
				}>
				<Text
					medium
					style={{
						color:
							selected_subject === null
								? theme.colors.lightGrey2
								: selected_usecase === null || selected_sub_usecase == null
								? theme.colors.darkGrey2
								: theme.colors.black,
						fontSize: 14,
					}}>
					{selected_usecase !== null && selected_sub_usecase !== null
						? `${selected_usecase.title}: ${selected_sub_usecase.title}`
						: 'Select trigger action'}
				</Text>
				<img
					src={selected_subject !== null ? ImageLinks.arrow_up_circle_solid : ImageLinks.arrow_up_circle_solid_gray}
					width={20}
					height={20}
					style={!show_usecase ? { transform: 'rotate(180deg)' } : {}}
					alt='drop_down'
				/>
			</div>

			{show_usecase && (
				<div
					ref={component_ref}
					style={{ position: 'absolute', width: '100%', zIndex: 100 }}
					onMouseLeave={() => {
						set_show_sub_menu(false);
						set_sub_menu([]);
						set_is_default(false);
					}}>
					<div className={classes.use_case_item_wrap} style={{ background: theme.colors.white }}>
						{_.map(triggers_list, (usecase, key) => (
							<div
								onMouseEnter={() => {
									handle_usecase_hover(usecase);
								}}>
								<UsecaseListItem key={`usecase${key}`} show_forward={true} title={usecase.title} description={usecase.description} />
							</div>
						))}
					</div>
					<div className={clsx(classes.use_case_item_wrap, classes.sub_usecase_item_wrap)}>
						{show_sub_menu &&
							sub_menu.length > 0 &&
							_.map(sub_menu, (usecase, sub_key) => (
								<div>
									<UsecaseListItem
										key={`sub_usecase${sub_key}`}
										handle_usecase_select={() => handle_sub_usecase_select(usecase)}
										title={is_default ? usecase.title : usecase.event}
										description={is_default ? usecase.description : usecase.title}
									/>
								</div>
							))}
						{show_sub_menu && hover_usecase?.type === 'alert' && (
							<div className={classes.new_alert_wrap}>
								<img src={ImageLinks.addPurple} width={12} height={12} />
								<Text semi className={classes.create_new_alert_text}>
									Create New Alert
								</Text>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default Trigger;
