import React, { useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';
import UsecaseListItem from './UsecaseListItem';

const useStyles = makeStyles((theme) => ({
	use_case_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: `1px solid ${theme.colors.lightPurple}`,
		padding: 10,
		borderRadius: 36,
		height: 36,
		width: '100%',
		cursor: 'pointer',
		backgroundColor: theme.colors.white,
	},
	use_case_item_wrap: {
		position: 'relative',
		maxHeight: 285,
		width: '100%',
		borderRadius: 6,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.3)',
		overflowY: 'scroll',
		marginTop: 5,
	},
	sub_usecase_item_wrap: {
		cursor: 'pointer',
		background: theme.colors.white,
		position: 'absolute',
		top: 0,
		right: 200,
		width: '75%',
	},
	new_alert_wrap: { display: 'flex', alignItems: 'center', backgroundColor: theme.colors.lightGrey7, height: 22, paddingLeft: 8 },
	create_new_alert_text: { fontSize: 12, color: theme.colors.primary, marginLeft: 5 },
	disabled_trigger: { backgroundColor: theme.colors.lightGrey5, border: `1px solid ${theme.colors.lightGrey2}`, cursor: 'auto' },
}));

const NestedSelect = ({ zones, selected_master_zone, set_selected_master_zone, selected_business_zone, set_selected_business_zone }) => {
	const classes = useStyles();

	const [show_menu, set_show_menu] = useState(false);
	const [sub_menu, set_sub_menu] = useState([]);
	const [show_sub_menu, set_show_sub_menu] = useState(false);

	const handle_usecase_hover = (usecase) => {
		if (usecase.business_master_zones) {
			set_sub_menu(usecase.business_master_zones);
			set_show_sub_menu(true);
		} else {
			set_sub_menu([]);
			set_show_sub_menu(false);
		}
	};

	const handle_sub_usecase_select = (usecase) => {
		const master_zone = _.filter(zones, (zone) => {
			return zone.id == usecase.master_zone_id;
		});
		set_selected_master_zone(master_zone[0]);
		set_selected_business_zone(usecase);
		set_show_menu(false);
	};

	const handle_usecase_select = (usecase) => {
		set_selected_master_zone(usecase);
		set_selected_business_zone(null);
		set_show_menu(false);
	};

	return (
		<div style={{ position: 'relative', width: '100%' }}>
			<div
				onClick={() => {
					set_show_menu((prev) => !prev);
				}}
				className={clsx(classes.use_case_wrap)}>
				<Text medium>
					{!_.isEmpty(selected_business_zone)
						? selected_business_zone.title
						: !_.isEmpty(selected_master_zone)
						? selected_master_zone.name
						: 'select zone'}
				</Text>
				<img src={ImageLinks.arrow_up_circle_solid} width={20} height={20} style={!show_menu ? { transform: 'rotate(180deg)' } : {}} alt='drop_down' />
			</div>

			{show_menu && (
				<div style={{ position: 'absolute', width: '100%', zIndex: 100 }}>
					<div className={classes.use_case_item_wrap} style={{ background: theme.colors.white }}>
						{_.map([...zones, { name: 'No Zone', id: '0' }], (usecase, key) => (
							<div
								onMouseEnter={() => {
									handle_usecase_hover(usecase);
								}}>
								<UsecaseListItem
									key={`usecase${key}`}
									show_forward={usecase?.business_master_zones && usecase?.business_master_zones.length > 0}
									title={usecase.name}
									handle_usecase_select={() => handle_usecase_select(usecase)}
								/>
							</div>
						))}
					</div>
					<div className={clsx(classes.use_case_item_wrap, classes.sub_usecase_item_wrap)}>
						{show_sub_menu &&
							sub_menu.length > 0 &&
							_.map(sub_menu, (usecase, sub_key) => (
								<div>
									<UsecaseListItem
										key={`sub_usecase${sub_key}`}
										handle_usecase_select={() => handle_sub_usecase_select(usecase)}
										title={usecase.title}
									/>
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	);
};

export default NestedSelect;
