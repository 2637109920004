import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', height: 40, alignItems: 'center' },
	normal_heading: {
		fontSize: 22,
		color: theme.colors.lightGrey2,
	},
	main_heading: {
		fontSize: 22,
		color: theme.colors.black,
	},
	screen_text_wrap: { width: 1, height: 16, background: theme.colors.darkGrey2, marginLeft: 8, marginRight: 8 },
	content: { display: 'flex', flex: 1, overflow: 'hidden', marginTop: 26 },
	back_arrow: { backgroundColor: theme.colors.lightPurple, width: 20, height: 20, borderColor: theme.colors.lightPurple, marginRight: 8 },
	left_wrap: { display: 'flex', flexDirection: 'column', flex: 0.15, paddingTop: 20, paddingRight: 18 },
	right_wrap: {
		display: 'flex',
		flexDirection: 'column',
		flex: 0.85,
		border: `1px solid ${theme.colors.lightPurple6}`,
		borderTopLeftRadius: 50,
		boxShadow: `0px 0px 16px rgba(102, 102, 102, 0.1)`,
		backgroundColor: theme.colors.white,
		padding: 30,
		paddingLeft: 0,
		overflowY: 'auto',
	},

	add_save_btn_wrap: {
		display: 'flex',
		height: 20,
		borderRadius: 17,
		justifyContent: 'center',
		alignItems: 'center',
		padding: '4px 8px',
		cursor: 'pointer',
		marginLeft: 14,
		marginTop: 10,
	},
	tags_wrap: {
		marginTop: 2,
		borderRadius: 8,
		backgroundColor: theme.colors.white,
		padding: 16,
		width: 440,
	},
	tags_empty_text: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 17, textAlign: 'center' },
	input_wrapper: { display: 'flex', justifyContent: 'space-between', margin: '0px 2px' },
	cta_btn: {
		marginTop: 18,
		marginLeft: 30,
		width: 'fit-content',
	},
	left_section_desc: { fontSize: 14, color: theme.colors.darkGrey2, marginTop: 2 },
	left_section_item: {
		height: 41,
		borderRadius: 8,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 12,
		paddingRight: 12,
		marginBottom: 12,
	},
	section_header_line: { marginLeft: 8, height: 1, backgroundColor: theme.colors.lightGrey2, flex: 1 },
	avatar_wrap: {
		width: 100,
		height: 100,
		borderRadius: 50,
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	avatar_edit: { position: 'absolute', bottom: 0, right: 0, cursor: 'pointer' },
	mobiel_91_text: { width: 40, fontSize: 13, color: theme.colors.lightGrey2 },
	address_card: {
		minHeight: 91,
		borderRadius: 6,
		padding: 12,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey7}`,
		width: 600,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		marginLeft: 65,
	},
	addr_separator: { border: `1px solid ${theme.colors.lightPurple}`, marginTop: 8, marginBottom: 8 },
	footer: {
		width: '100%',
		height: 78,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 16px rgba(102, 102, 102, 0.1)`,
		position: 'fixed',
		bottom: 0,
	},
	trace_content_wrap: {
		gap: 100,
		paddingLeft: 6,
		marginTop: 18,
	},
	trace_desc_text: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4, marginBottom: 9, width: '85%' },
	trace_strip: {
		height: 34,
		borderRadius: 6,
		backgroundColor: theme.colors.lightPink4,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 10,
	},
	strip_desc: {
		fontSize: 12,
		color: theme.colors.primary,
		marginLeft: 8,
	},
}));

export default useStyles;
