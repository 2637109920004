import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import useStyles from '../styles';

const PricingSectionHeader = ({ title, desctiption, deduct_desc }) => {
	const classes = useStyles();
	return (
		<div className='align-items-center justify-content-between' style={{ borderBottom: `1px solid ${theme.colors.lightGrey2}`, paddingBottom: 8 }}>
			<div>
				<div className='align-items-center'>
					<img src={ImageLinks.tick_circle_purple_solid} width={16} height={16} />
					<Text medium style={{ fontSize: 14, marginLeft: 3 }}>
						{title}
					</Text>
				</div>
				<Text medium className={classes.price_section_header_desc_text}>
					{desctiption}
				</Text>
			</div>
			<div className={classes.price_section_deduct_desc_text}>
				<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
					{deduct_desc}
				</Text>
			</div>
		</div>
	);
};

export default PricingSectionHeader;
