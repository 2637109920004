import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';

import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: 'rgba(242, 238, 248, 0.70)',
		borderRadius: 8,
		padding: 12,
		margin: '10px 0',
		display: 'flex',
	},
	divider: {
		width: 1,
		height: 'auto',
		backgroundColor: theme.colors.lightPurple,
	},
}));

function VehicleOverview({ selected_vehicle }) {
	const classes = useStyles();
	const [start_date, set_start_date] = useState(moment().subtract(7, 'days'));
	const [end_date, set_end_date] = useState(moment());
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [distance, set_distance] = useState('0');
	const [assignments, set_assignments] = useState('1');

	useEffect(() => {
		get_stats();
	}, [start_date, end_date]);

	useEffect(() => {
		set_start_date(moment().subtract(7, 'days'));
		set_end_date(moment());
	}, [selected_vehicle]);

	const get_stats = async () => {
		try {
			const res = await api_requests.get_fleet_stats(selected_vehicle.id, {
				from: start_date.format('YYYY-MM-DD'),
				to: end_date.format('YYYY-MM-DD'),
			});
			if (res.success) {
				set_distance(res.data[0].distance);
				set_assignments(res.data[0].total);
			}
		} catch (err) {}
	};

	return (
		<div className={classes.container}>
			<div className='d-flex justify-content-center' style={{ flexDirection: 'column', position: 'relative', flex: 1 }}>
				<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
					Vehicle Overview
				</Text>
				<div className='d-flex align-items-center'>
					<Text semi style={{ fontSize: 10, color: theme.colors.primary }}>{`${start_date.format('DD MMM `YY')} -${end_date.format(
						'DD MMM `YY',
					)}`}</Text>
					<img src={ImageLinks.calendar_solid_circle} onClick={() => set_date_picker_open(true)} />
				</div>
				<div style={{ position: 'absolute', right: 0, top: 50, width: 750, zIndex: 1300 }}>
					<DateRangePicker
						open={date_picker_open}
						toggle={() => set_date_picker_open((prev) => !prev)}
						style={{ width: 150 }}
						initialDateRange={{
							startDate: start_date,
							endDate: end_date,
						}}
						onChange={(range) => {
							set_start_date(moment(range.startDate));
							set_end_date(moment(range.endDate));
							set_date_picker_open(!date_picker_open);
						}}
					/>
				</div>
			</div>
			<div className={classes.divider} />
			<div style={{ flex: 1, padding: '5px 16px' }}>
				<Text semi style={{ fontSize: 20 }}>
					{distance} KM
				</Text>
				<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
					Total Distance Travelled
				</Text>
			</div>
			<div className={classes.divider} />
			<div style={{ flex: 1, padding: '5px 16px' }}>
				<Text semi style={{ fontSize: 20 }}>
					{assignments}
				</Text>
				<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
					Total Assignments
				</Text>
			</div>
		</div>
	);
}

export default VehicleOverview;
