import ImageLinks from 'assets/images/ImageLinks';

export const priorities = [
	{ title: 'Ranks', sub_title: 'Rank your partners through rank 1 to 6. Multiple partners can share the same rank.', img: ImageLinks.manifest_rank },
	{ title: 'Exclude', sub_title: 'Where you would rather not deliver...', img: ImageLinks.cross_circle_solid_red },
];

export const setting_option = [
	{ title: '1', value: 1 },
	{ title: '2', value: 2 },
	{ title: '3', value: 3 },
	{ title: '4', value: 4 },
	{ title: '5', value: 5 },
	{ title: '6', value: 6 },
	{ title: 'Exclude', value: -1 },
];
