import Text from 'modules/Text';
import _ from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import cross from 'assets/images/icons/cross.svg';
import { Grid, makeStyles, Modal } from '@material-ui/core';
import { shallowEqual, useSelector } from 'react-redux';

import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import Amount from 'modules/Amount';
import moment from 'moment';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import IconButton from 'modules/IconButton';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import QueryString from 'qs';
import { ALL_ORDERS_MENU_ITEMS } from 'views/AllOrders/helper';
import AddressInput from 'modules/modals/AddressInput';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TripDetails from './OrderDetail/components/TripDetails';
import Switch from 'modules/Switch';
import clsx from 'clsx';
import useDidMountEffect from 'resources/hooks/useDidMountEffect';
import Button from 'modules/Button';
import utils from 'resources/utils';

const useStyles = makeStyles((theme) => ({
	taskDetailsContent: { paddingBottom: 4, fontSize: 12, color: theme.colors.black },
	tastDetailsSubheading: { color: theme.colors.lightGrey6, fontSize: 10 },
	speed_content: {
		background: theme.colors.lightPurple,
		padding: '6px 4px',
		borderRadius: 4,
		position: 'absolute',
		bottom: '28px',
		left: '12px',
		display: 'flex',
	},
	attendance_wrap: {
		borderRadius: 10,
		overflow: 'hidden',
		marginTop: 20,
		padding: '16px 12px',
		display: 'flex',
		justifyContent: 'space-between',
		position: 'relative',
	},
	section_header_wrap: { display: 'flex', alignItems: 'center', marginRight: 10 },
	switch_hover_wrap: {
		height: 22,
		backgroundColor: theme.colors.lightPurple8,
		position: 'absolute',
		right: 10,
		top: 0,
		// zIndex: 10000,
		borderRadius: 4,
		paddingLeft: 4,
		paddingRight: 4,
		display: 'flex',
		alignItems: 'center',
	},
	triangel_wrap: {
		marginLeft: 0,
		marginTop: 0,
		position: 'absolute',
		top: 20,
		right: 20,
	},
	triangle: {
		width: 0,
		height: 0,
		borderLeft: '8px solid transparent',
		borderRight: '8px solid transparent',
		borderTop: `8px solid ${theme.colors.lightPurple8}`,
	},
	action_items: { display: 'flex', alignItems: 'center', height: 30 },
}));

const ADDRESS_TYPE = {
	saved: 'saved',
	new: 'new',
	recent: 'recent',
};

let map = null;

const RiderOverviewModal = ({ close, rider_data, set_rider_data = () => {}, source = 'default' }) => {
	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);
	const [task_details, set_task_details] = useState({});
	const [show_address_modal, set_show_address_modal] = useState(false);
	const [saved_hub_flow, set_saved_hub_flow] = useState(false);
	const [new_location_flow, set_new_location_flow] = useState(false);
	const [selected_master_zone, set_selected_master_zone] = useState(zones[0].id);
	const [task_data_points, set_task_data_points] = useState([]);
	const [refresh, set_refresh] = useState(false);
	const [trip_data, set_trip_data] = useState(null);
	const [show_hover, set_show_hover] = useState(false);

	const on_address_select = async () => {
		try {
			const response = await api_requests.get_rider_details(rider_data.details.rider_id);
			if (response.success) {
				set_rider_data(response.data);
				set_show_address_modal(false);
			}
		} catch (error) {
			set_show_address_modal(false);
		}
	};

	const handle_trip_details = async (trip_id) => {
		try {
			const response = await api_requests.get_trip_details(trip_id);
			if (response.success) {
				set_trip_data(response.data);
			}
		} catch (err) {}
	};

	const clear_local_data = () => {
		set_saved_hub_flow(false);
		set_new_location_flow(false);
	};

	const classes = useStyles();

	const history = useHistory();

	const fetch_rider_tasks = async (trip_id) => {
		try {
			const response = await api_requests.get_rider_task_details(_.get(rider_data, 'details.rider_id', ''), trip_id);
			if (response.success) {
				set_task_details(response.data);
				const task_points =
					_.get(response, 'data.mcp_id', null) === null
						? [
								{
									name: 'Trip ID',
									val: response.data.trip_id,
									is_link: true,
								},
								{
									name: 'ETA',
									val: moment(response.data.drop_time_real).format('hh:mm A'),
								},
								{
									name: 'Status',
									val: constant.ADMIN_TRIP_TYPE_TEXT[response.data.status_id],
								},
								{
									name: 'Location',
									val: response.data.receiver_name,
								},
						  ]
						: [
								{
									name: 'Trip ID',
									val: response.data.trip_id,
								},
								{
									name: 'PBID',
									val: response.data.mcp_id,
								},
								{
									name: 'ETP',
									val: moment(response.data.pickup_time_real).format('hh:mm A'),
								},
								{
									name: 'ETD',
									val: moment(response.data.drop_time_real).format('hh:mm A'),
								},
								{
									name: 'Status',
									val: constant.TRIP_STATUS_TEXT[response.data.status_id],
								},
						  ];

				set_task_data_points(task_points);
			}
		} catch (error) {}
	};

	const handle_get_task_details = (trip_id) => {
		fetch_rider_tasks(trip_id);
	};

	useEffect(() => {
		if (_.get(rider_data, 'tasks', []).length) {
			fetch_rider_tasks(_.get(rider_data, 'tasks.[0].trip_id', ''));
		}
	}, [rider_data.tasks]);

	useDidMountEffect(() => {
		refresh_rider_data();
	}, [refresh]);

	const refresh_rider_data = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_LOCATION_REFRESH);
		try {
			const response = await api_requests.get_rider_details(rider_data.details.rider_id);
			if (response.success) {
				set_rider_data(response.data);
			}
		} catch (error) {}
	};

	const handleClose = () => {
		close();
	};

	const initialize = () => {
		map = new window.google.maps.Map(document.getElementById('map_canvas_rider_modal'), {
			center: { lat: Number(rider_data.current_location.latitude), lng: Number(rider_data.current_location.longitude) },
			zoom: 9,
			clickableIcons: false,
			disableDefaultUI: true,
			scaleControl: true,
			zoomControl: true,
			mapId: constant.MAP_DESIGN_ID,
		});

		const icon_home = {
			url: ImageLinks.rider_home, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};

		new window.google.maps.Marker({
			position: { lat: Number(rider_data.home_location?.latitude), lng: Number(rider_data.home_location?.longitude) },
			icon: icon_home,
			map,
		});

		const icon_rider = {
			url: ImageLinks.rider_marker, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};

		new window.google.maps.Marker({
			position: { lat: Number(rider_data.current_location.latitude), lng: Number(rider_data.current_location.longitude) },
			icon: icon_rider,
			map,
		});

		const icon = {
			url: ImageLinks.marker_purple, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};

		let tripNumber = 1;
		_.map(rider_data.tasks, (val) => {
			if (val.task_type !== 20) {
				return;
			}

			const infowindow = new window.google.maps.InfoWindow({
				content: val.trip_id.toString(),
			});

			let drop_marker = new window.google.maps.Marker({
				position: { lat: Number(val.location.latitude), lng: Number(val.location.longitude) },
				label: { text: `${tripNumber}`, color: 'white' },
				icon: icon,
				map,
			});

			window.google.maps.event.addListener(drop_marker, 'mouseover', function () {
				infowindow.open(map, drop_marker);
			});

			window.google.maps.event.addListener(drop_marker, 'mouseout', function () {
				infowindow.close(map, drop_marker);
			});

			window.google.maps.event.addListener(drop_marker, 'click', function () {
				handle_get_task_details(val.trip_id);
			});

			tripNumber++;
		});

		const icon_pickup = {
			url: ImageLinks.rider_pickup, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};

		_.map(rider_data.tasks, (val) => {
			if (val.task_type !== 10) {
				return;
			}

			new window.google.maps.Marker({
				position: { lat: Number(val.location.latitude), lng: Number(val.location.longitude) },
				icon: icon_pickup,
				map,
			});
		});

		let bounds = new window.google.maps.LatLngBounds();
		let home_coord = new window.google.maps.LatLng(Number(rider_data.home_location?.latitude), Number(rider_data.home_location?.longitude));
		let rider_coord = new window.google.maps.LatLng(Number(rider_data.current_location.latitude), Number(rider_data.current_location.longitude));
		if (rider_data.home_location) bounds.extend(home_coord);
		bounds.extend(rider_coord);
		_.map(rider_data.tasks, (val) => {
			let pick_drop_coord = new window.google.maps.LatLng(Number(val.location.latitude), Number(val.location.longitude));
			bounds.extend(pick_drop_coord);
		});
		map.fitBounds(bounds);
	};

	const handle_saved_hub = () => {
		set_show_address_modal(true);
		set_saved_hub_flow(true);
		set_new_location_flow(false);
	};

	const handle_new_location = () => {
		set_show_address_modal(true);
		set_new_location_flow(true);
		set_saved_hub_flow(false);
	};

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, []);

	const toggle_rider_login = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_LOGIN_TOGGLE);
		const rider_id = _.get(rider_data, 'details.rider_id', null);
		const current_status = _.get(rider_data, 'shift.is_login', null);
		if (!rider_id) return;
		try {
			const response = await api_requests.rider_login_logout(rider_id, {
				is_login: !current_status,
			});
			if (!current_status) {
				const rider_response = await api_requests.get_rider_details(rider_data.details.rider_id);
				if (rider_response.success) {
					set_rider_data(rider_response.data);
				}
			}
			if (response.success && current_status) {
				const _rider_data = _.cloneDeep(rider_data);
				_rider_data.shift.is_login = !current_status;
				set_rider_data(_rider_data);
			}
		} catch (error) {}
	};

	return (
		<>
			<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
				<div
					style={{
						padding: 30,
						background: 'white',
						width: 620,
						minHeight: 620,
						margin: 'auto auto',
						borderRadius: 20,
						display: 'flex',
						flexDirection: 'column',
						outline: 'none',
					}}>
					<div style={{ display: 'flex' }} className='justify-content-between align-items-center'>
						<div style={{ display: 'flex', alignItems: 'center', height: 30 }}>
							<Text bold style={{ fontSize: 24 }}>
								Rider Overview
							</Text>
						</div>
						<div className={classes.action_items}>
							<img
								src={ImageLinks.refresh_dark_purple}
								style={{ cursor: 'pointer' }}
								width='20'
								height='20'
								onClick={() => set_refresh((prev) => !prev)}
							/>
							<IconButton onClick={handleClose}>
								<img src={cross} style={{ cursor: 'pointer' }} />
							</IconButton>
						</div>
					</div>
					<div style={{ display: 'flex', height: 400, marginTop: 20, gap: 20 }}>
						<div style={{ flex: 1 }}>
							<div className='box-shadow' style={{ borderRadius: 10 }}>
								<div style={{ padding: 15, display: 'flex', justifyContent: 'space-between' }}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<img src={ImageLinks.rider_light_purple} />
										<div style={{ marginLeft: 20 }}>
											<Text bold style={{ fontSize: 12 }}>
												{rider_data.details.rider_id} - {rider_data.details.rider_category}
											</Text>
											<div
												className='d-flex justify-content-center align-items-center'
												style={{ gap: 2, cursor: 'pointer' }}
												onClick={() => {
													handleClose();
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_OVERVIEW_NAME);
													history.push(Routes.RIDER_PROFILE.path.replace(':id', rider_data.details.rider_id));
												}}>
												<Text style={{ fontSize: 16, textDecoration: 'underline' }}>{rider_data.details.rider_name}</Text>
												<img src={ImageLinks.chevron_right_black} width={12} height={12} />
											</div>
										</div>
									</div>
									<IconButton
										onClick={() => {
											window.open(`tel:${rider_data.details.mobile}`);
										}}>
										<img src={ImageLinks.phone} />
									</IconButton>
								</div>
								<div
									style={{
										padding: 15,
										paddingTop: 5,
										display: 'flex',
										justifyContent: 'space-between',
										background: theme.colors.lightPurple,
										flexWrap: 'wrap',
									}}>
									{_.map(
										[
											{
												title: rider_data.shift.time_slot_from_real
													? `${moment(rider_data.shift.time_slot_from_real).format('hh:mm A')} - ${moment(
															rider_data.shift.time_slot_to_real,
													  ).format('hh:mm A')}`
													: '-',
												subTitle: 'Shift Time',
												width: 40,
											},
											{
												title: rider_data.shift.hub_title || '-',
												subTitle: 'Shift Hub',
											},
											{
												title: 'Bike',
												subTitle: 'Vehicle Type',
											},
											{
												title: rider_data.details.bag_volume || '-',
												subTitle: 'Volume',
												width: 25,
											},
											{
												title: rider_data.details.bag_title || '-',
												subTitle: 'Rider Bag',
												width: 25,
											},
										],
										(val, key) => {
											return (
												<div key={`val${key}`} style={{ width: `${val.width || 50}%`, marginTop: 10 }}>
													<Text semi style={{ fontSize: 12, height: 25, display: 'flex', alignItems: 'center' }}>
														{val.title}
													</Text>
													<Text style={{ fontSize: 8, color: theme.colors.lightGrey6, marginTop: 5 }}>{val.subTitle}</Text>
												</div>
											);
										},
									)}
								</div>
							</div>

							<div
								className='box-shadow'
								style={{
									borderRadius: 10,
									marginTop: 20,
									padding: 10,
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}>
								<Text bold style={{}}>
									Today’s Deliveries{' '}
								</Text>
								<div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
									<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
										<Text semi>{rider_data.delivery_stats.ongoing || '-'}</Text>
										<div
											style={{
												backgroundColor: theme.colors.darkYellow,
												display: 'flex',
												width: 60,
												height: 18,
												alignItems: 'center',
												justifyContent: 'center',
												borderRadius: 10,
												marginTop: 10,
											}}>
											<Text
												bold
												style={{ color: theme.colors.white, fontSize: 9, cursor: 'pointer' }}
												onClick={() => {
													close();
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_OVERVIEW_ONGOING);
													history.push(
														Routes.ALL_ORDERS.path +
															`?type=${ALL_ORDERS_MENU_ITEMS.Shipped.id}&` +
															QueryString.stringify({ filter: { rider_id: rider_data.details.rider_id } }),
													);
												}}>
												Ongoing
											</Text>
											<img src={ImageLinks.chevron_right} style={{ marginLeft: 5, marginTop: 2 }} />
										</div>
									</div>
									<div style={{ width: 1, height: 30, backgroundColor: theme.colors.lightGrey3 }} />
									<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
										<Text semi>{rider_data.delivery_stats.completed || '-'}</Text>
										<div
											style={{
												backgroundColor: theme.colors.green,
												display: 'flex',
												width: 60,
												height: 18,
												alignItems: 'center',
												justifyContent: 'center',
												borderRadius: 10,
												marginTop: 10,
											}}>
											<Text
												bold
												style={{ color: theme.colors.white, fontSize: 9, cursor: 'pointer' }}
												onClick={() => {
													close();
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_OVERVIEW_DELIVERED);
													history.push(Routes.ALL_ORDERS_BY_RIDER.path.replace(':id', rider_data.details.rider_id));
												}}>
												Delivered
											</Text>
											<img src={ImageLinks.chevron_right} style={{ marginLeft: 5 }} />
										</div>
									</div>
									<div style={{ width: 1, height: 30, backgroundColor: theme.colors.lightGrey3 }} />
									<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
										<Text semi>{rider_data.delivery_stats.failed || '-'}</Text>
										<div
											style={{
												backgroundColor: theme.colors.red,
												display: 'flex',
												width: 60,
												height: 18,
												alignItems: 'center',
												justifyContent: 'center',
												borderRadius: 10,
												marginTop: 10,
											}}>
											<Text
												bold
												style={{ color: theme.colors.white, fontSize: 9, cursor: 'pointer' }}
												onClick={() => {
													close();
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_OVERVIEW_FAILED);
													history.push(Routes.ALL_ORDERS_BY_RIDER.path.replace(':id', rider_data.details.rider_id));
												}}>
												Failed
											</Text>
											<img src={ImageLinks.chevron_right} style={{ marginLeft: 5 }} />
										</div>
									</div>
								</div>
							</div>

							<div className={clsx(classes.attendance_wrap, 'box-shadow')}>
								<div className={classes.section_header_wrap}>
									<Text bold style={{ width: 75, textAlign: 'center', marginRight: 4 }}>
										Roster & Attendance{' '}
									</Text>
									<img src={ImageLinks.chevron_right_hollow_purple} />
								</div>
								<div style={{ marginLeft: 10 }}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img src={ImageLinks.clock_check} />
											<Text medium style={{ color: theme.colors.darkPurple, width: 80, marginLeft: 2 }}>
												{rider_data.shift.login_time_real ? moment(rider_data.shift.login_time_real).format('hh:mm A') : '-'}
											</Text>
										</div>

										{show_hover && (
											<div className={clsx(classes.switch_hover_wrap)}>
												<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
													{`Toggle to ${_.get(rider_data, 'shift.is_login', false) ? 'logout' : 'login'} rider`}
												</Text>
												<div className={classes.triangel_wrap}>
													<div className={classes.triangle}></div>
												</div>
											</div>
										)}

										<div
											onMouseEnter={() => {
												set_show_hover(true);
											}}
											onMouseLeave={() => {
												set_show_hover(false);
											}}>
											<Switch checked={_.get(rider_data, 'shift.is_login', false)} onChange={toggle_rider_login} />
										</div>
									</div>
									<Text semi style={{ fontSize: 12 }}>
										{rider_data.shift.time_slot_from_real
											? `${moment(rider_data.shift.time_slot_from_real).format('hh:mm A')} - ${moment(
													rider_data.shift.time_slot_to_real,
											  ).format('hh:mm A')}`
											: '-'}
									</Text>
								</div>
							</div>
						</div>
						<div style={{ flex: 1, overflow: 'hidden', borderRadius: 10, position: 'relative' }}>
							<div id='map_canvas_rider_modal' className='map-canvas-wrapper rider-location card' style={{ height: '100%' }} />
							{!_.isEmpty(_.get(rider_data, 'current_location', null)) && (
								<div className={classes.speed_content}>
									<img src={ImageLinks.speed_meter} alt='speedometer' width={16} height={16} style={{ marginRight: 4 }} />
									<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
										{_.get(rider_data, 'current_location.speed') <= 0 ? 0 : _.get(rider_data, 'current_location.speed')} kmph
									</Text>
								</div>
							)}
						</div>
					</div>
					<div className='d-flex' style={{ gap: 20, paddingTop: 4 }}>
						<div style={{ flex: 1 }} />
						{!_.isEmpty(_.get(rider_data, 'current_location', null)) && (
							<div style={{ flex: 1, paddingTop: 4 }}>
								<Text style={{ color: theme.colors.darkGrey2, fontSize: 11 }}>
									Last Location Update: {moment(_.get(rider_data, 'current_location.updated_at')).format('h:mmA, Do MMMM YYYY')}
								</Text>
							</div>
						)}
					</div>
					<div style={{ display: 'flex', marginTop: 10 }}>
						<div
							className='box-shadow'
							style={{ borderRadius: 10, padding: 16, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
							<Text bold style={{ textAlign: 'center' }}>
								<Amount
									amount={rider_data.outstanding_cash}
									main_text_styles={{ color: theme.colors.black, fontSize: 16 }}
									sub_text_styles={{ color: theme.colors.black, fontSize: 10 }}
								/>
							</Text>
							<div
								onClick={() => {
									close();
									history.push(Routes.CASH_MANAGEMENT_RIDER_DETAIL.path.replace(':id', rider_data.details.rider_id));
								}}
								style={{
									backgroundColor: theme.colors.red,
									display: 'flex',
									width: 70,
									height: 18,
									alignItems: 'center',
									justifyContent: 'center',
									borderRadius: 10,
									marginTop: 10,
									cursor: 'pointer',
								}}>
								<Text bold style={{ color: theme.colors.white, fontSize: 9 }}>
									Outstanding
								</Text>
								<img src={ImageLinks.chevron_right} style={{ marginLeft: 5 }} />
							</div>
						</div>
						<div className='box-shadow' style={{ borderRadius: 10, padding: 16, flex: 1 }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Text bold>Task Details</Text>
								{!_.isEmpty(task_details) && (
									<div style={{ marginLeft: 10, borderRadius: 20, padding: '2px 8px', border: `1px solid ${theme.colors.lightGrey2}` }}>
										<Text style={{ color: theme.colors.lightGrey2, fontSize: 10 }}>
											{rider_data.tasks.length === 1 && task_details.mcp_id === null
												? 'Admin Task'
												: constant.ATTEMPT_TYPE_TEXT[task_details.attempt_type]}
										</Text>
									</div>
								)}
								{_.isEmpty(_.get(rider_data, 'tasks', [])) && (
									<div style={{ marginLeft: 10, borderRadius: 20, padding: '2px 8px', border: `1px solid ${theme.colors.lightGrey2}` }}>
										<Text style={{ color: theme.colors.lightGrey2, fontSize: 10 }}>No Task</Text>
									</div>
								)}
							</div>
							{_.isEmpty(_.get(rider_data, 'tasks', [])) ? (
								<>
									<div className='justify-content-between align-items-center'>
										<div>
											<Text semi style={{ fontSize: 12 }}>
												Assign admin task at
											</Text>
										</div>
										<div className='align-items-center'>
											<div>
												<Button type='outlined' text='New Location' onClick={handle_new_location} />
											</div>
											<div>
												<Button
													type='round'
													text='Saved Hub'
													onClick={handle_saved_hub}
													disabled={_.isEmpty(_.get(rider_data, 'shift', {}))}
													style={{ marginLeft: 10 }}
												/>
											</div>
										</div>
									</div>
								</>
							) : _.isEmpty(task_details) ? (
								<Text style={{ color: theme.colors.lightGrey2, marginTop: 10 }}>Click on a location pin to get details</Text>
							) : (
								<div style={{ display: 'flex', alignItems: 'flex-end' }}>
									{_.map(task_data_points, (details, key) => {
										return (
											<div key={`task_detail-${key}`} style={{ flex: 1 }}>
												<Text
													semi
													style={
														details.is_link ? { fontSize: 12, cursor: 'pointer', textDecoration: 'underline' } : { fontSize: 12 }
													}
													onClick={() => {
														if (details.is_link) {
															handle_trip_details(details.val);
														}
													}}>
													{details.val}
												</Text>
												<Text medium style={{ fontSize: 8, color: theme.colors.lightGrey6 }}>
													{details.name}
												</Text>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>
			</Modal>
			{show_address_modal && (
				<AddressInput
					zone_id={selected_master_zone}
					on_address_select={on_address_select}
					close={() => set_show_address_modal(false)}
					saved_hub_flow={saved_hub_flow}
					new_location_flow={new_location_flow}
					address_type={ADDRESS_TYPE.new}
					title={
						<Text bold style={{ fontSize: 24 }}>
							<ArrowBackIcon style={{ cursor: 'pointer' }} onClick={() => set_show_address_modal(false)} /> Admin Task at{' '}
							{new_location_flow ? 'Custom Location' : 'Saved Hub'}
						</Text>
					}
					clear_local_data={clear_local_data}
					show_save_as={false}
					saved_hub_details={rider_data?.shift}
					rider_ids={_.get(rider_data, 'details.rider_id', null)}
				/>
			)}
			{!_.isEmpty(trip_data) && (
				<TripDetails
					on_update_success={() => {}}
					brand_name={''}
					order_id={'Admin Task'}
					trip_data={trip_data}
					close={() => {
						set_trip_data(null);
						close();
					}}
					wrapper_styles={{ right: 0, zIndex: 1500 }}
				/>
			)}
		</>
	);
};

export default RiderOverviewModal;
