import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _, { values } from 'lodash';
import Text from 'modules/Text';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import { SUPPORT_FUNC_TABS } from '../../helper';
import ContainedButton from 'modules/ContainedButton';

import CreateNetworkContext from '../../../context';
import ImageLinks from 'assets/images/ImageLinks';
import OfficerForm from './OfficerForm';
import SectionCta from '../SectionCta';
import { ten_digit_regex, valid_email_regex } from 'resources/regex_patterns';
import Button from 'modules/Button';
import Switch from 'modules/Switch';
import ConfirmClose from 'modules/modals/ConfirmClose';

const useStyles = makeStyles(() => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column' },
	content_wrap: { marginLeft: 16, gap: 20, display: 'flex', flex: 1 },
	tab_item: {
		borderRadius: 8,
		minWidth: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		height: 33,
	},
	input_wrap: { display: 'flex', flexDirection: 'column', flex: 0.6, overflowX: 'hidden' },
	input_section: {
		marginTop: 8,
		padding: 16,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey7}`,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		flex: 1,
	},
	separator: { border: `1px solid ${theme.colors.lightGrey5}`, marginTop: 4, marginBottom: 16 },
	menu_item_class: {
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
		},
	},
	label_subtitle: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 },
	adornment_text: { fontSize: 14, color: theme.colors.darkGrey2, whiteSpace: 'nowrap' },
	saved_wrap: {
		width: 102,
		height: 38,
		borderRadius: 30,
		backgroundColor: theme.colors.green,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	saved_text: {
		fontSize: 18,
		color: theme.colors.white,
		marginLeft: 6,
	},
	remove_text: {
		color: theme.colors.red,
		marginLeft: 4,
		textDecorationColor: theme.colors.red,
		'&:hover': {
			background: `${theme.colors.lightRed} !important`,
			boxShadow: '0px 4px 20px 0px rgba(102, 102, 102, 0.20) !important',
		},
	},
}));

const SupportFunctions = ({ handle_data_updates }) => {
	const {
		selected_func_tab,
		set_selected_func_tab,
		grievance_officer_info,
		customer_officer_info,
		rider_officer_info,
		set_grievance_officer_info,
		set_customer_officer_info,
		set_rider_officer_info,
		set_confirm_close_modal,
		invite_network_id,
		set_is_invite_support_updated,
		invite_network_config,
		get_support_officer,
		handle_support_api,
	} = useContext(CreateNetworkContext);
	const [grievance_info, set_grievance_info] = useState([{ name: '', mobile: '', email: '', type: 'grievance', priority: 1 }]);
	const [customer_info, set_customer_info] = useState([{ name: '', mobile: '', email: '', type: 'consumer', priority: 1 }]);
	const [rider_info, set_rider_info] = useState([{ name: '', mobile: '', email: '', type: 'rider', priority: 1 }]);
	const [can_change_support, set_can_change_support] = useState(_.get(invite_network_config, 'ownership.support_officers', '') == 'user');
	const [show_change_modal, set_show_change_modal] = useState(false);

	const classes = useStyles();

	useEffect(() => {
		if (selected_func_tab === 0 && grievance_officer_info.length > 0) set_grievance_info(grievance_officer_info);
		if (selected_func_tab === 1 && customer_officer_info.length > 0) set_customer_info(customer_officer_info);
		if (selected_func_tab === 2 && rider_officer_info.length > 0) set_rider_info(rider_officer_info);
	}, [selected_func_tab]);

	const fields_invalid = (data) => {
		let result = true;
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			const { name, mobile, email } = element;
			const email_valid = new RegExp(valid_email_regex).test(email);
			const mobile_valid = new RegExp(ten_digit_regex).test(mobile);
			if (_.isEmpty(name) || _.isEmpty(mobile) || _.isEmpty(email) || !mobile_valid || !email_valid) {
				result = true;
				break;
			} else result = false;
		}

		return result;
	};

	const handle_add_customer = () => {
		const _customer_info = _.cloneDeep(customer_info);
		const is_invalid = fields_invalid(_customer_info);
		if (!is_invalid) {
			_customer_info.push({ name: '', mobile: '', email: '', type: 'consumer', priority: 2 });
			set_customer_info(_customer_info);
		}
	};

	const handle_add_rider = () => {
		const _rider_info = _.cloneDeep(rider_info);
		const is_invalid = fields_invalid(_rider_info);
		if (!is_invalid) {
			_rider_info.push({ name: '', mobile: '', email: '', type: 'rider', priority: 2 });
			set_rider_info(_rider_info);
		}
	};

	const handle_grievance_info = (event) => {
		const _grievance_info = _.cloneDeep(grievance_info);
		_grievance_info[0][event.target.name] = event.target.value;
		set_grievance_info(_grievance_info);
	};

	const handle_cus_info = (event, index) => {
		const _customer_info = _.cloneDeep(customer_info);
		_customer_info[index][event.target.name] = event.target.value;
		set_customer_info(_customer_info);
	};

	const handle_rider_info = (event, index) => {
		const _rider_info = _.cloneDeep(rider_info);
		_rider_info[index][event.target.name] = event.target.value;
		set_rider_info(_rider_info);
	};

	const handle_remove_customer = () => {
		const _customer_info = _.cloneDeep(customer_info);
		_customer_info.pop();
		set_customer_info(_customer_info);
	};

	const handle_remove_rider = () => {
		const _rider_info = _.cloneDeep(rider_info);
		_rider_info.pop();
		set_rider_info(_rider_info);
	};

	const save_officer_info = () => {
		if (selected_func_tab === 0) set_grievance_officer_info(grievance_info);
		if (selected_func_tab === 1) set_customer_officer_info(customer_info);
		if (selected_func_tab === 2) set_rider_officer_info(rider_info);
		set_is_invite_support_updated(true);
	};

	const handle_tab_switch = (index) => {
		let is_data_empty = false;
		if (selected_func_tab === 0) is_data_empty = _.isEmpty(grievance_officer_info);
		if (selected_func_tab === 1) is_data_empty = _.isEmpty(customer_officer_info);
		if (selected_func_tab === 2) is_data_empty = _.isEmpty(rider_officer_info);

		const show_alert = is_data_empty ? !btn_disabled : is_data_updated;
		if (!show_alert) {
			set_selected_func_tab(index);
			return;
		}
		set_confirm_close_modal({
			open: true,
			header_title: 'Discard Changes',
			title: `You have made changes but you have not saved them. Are you sure you want to discard these changes?`,
			positive_text: 'Discard',
			negative_text: 'Cancel',
			is_error_msg: true,
			confirm_acion: () => {
				set_selected_func_tab(index);
			},
		});
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		let data = [];
		if (selected_func_tab === 0) data = grievance_info;
		if (selected_func_tab === 1) data = customer_info;
		if (selected_func_tab === 2) data = rider_info;

		value = fields_invalid(data);
		return value;
	}, [selected_func_tab, grievance_info, customer_info, rider_info]);

	const is_data_updated = useMemo(() => {
		let value = false;
		let local_data = [];
		let context_data = [];
		if (selected_func_tab === 0) {
			local_data = grievance_info;
			context_data = grievance_officer_info;
		}
		if (selected_func_tab === 1) {
			local_data = customer_info;
			context_data = customer_officer_info;
		}
		if (selected_func_tab === 2) {
			local_data = rider_info;
			context_data = rider_officer_info;
		}
		if (context_data.length === 0) return true;

		if (local_data.length != context_data.length) return true;

		for (let i = 0; i < local_data.length; i++) {
			const element = local_data[i];
			const { name, mobile, email } = element;
			if (name != context_data[i].name || mobile != context_data[i].mobile || email != context_data[i].email) {
				value = true;
				break;
			}
		}
		handle_data_updates(5, value);
		return value;
	}, [selected_func_tab, grievance_info, customer_info, rider_info, grievance_officer_info, customer_officer_info, rider_officer_info]);

	const handle_custom_support = (e) => {
		if (e) {
			set_show_change_modal(true);
			return;
		}
		if (!e) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Exit Custom Rule Setup',
				title: `Are you sure you want to abandon custom rules setup for support officers? Rules will reset to default.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				is_error_msg: false,
				show_negative_btn: true,
				confirm_acion: () => {
					set_values_to_default();
				},
			});
		}
	};

	const set_values_to_default = () => {
		set_can_change_support(false);
		set_grievance_officer_info([get_support_officer(invite_network_config.support_officers, 'grievance')]);
		set_customer_officer_info([get_support_officer(invite_network_config.support_officers, 'consumer')]);
		set_rider_officer_info([get_support_officer(invite_network_config.support_officers, 'rider')]);
		set_grievance_info([get_support_officer(invite_network_config.support_officers, 'grievance')]);
		set_customer_info([get_support_officer(invite_network_config.support_officers, 'consumer')]);
		set_rider_info([get_support_officer(invite_network_config.support_officers, 'rider')]);
	};

	return (
		<div className={classes.wrapper}>
			<div className={classes.content_wrap}>
				<div className={classes.input_wrap}>
					<div className='d-flex justify-content-between'>
						<div className='d-flex'>
							{_.map(SUPPORT_FUNC_TABS, (tab, index) => (
								<div
									key={`servicibility_tab_${index}`}
									onClick={() => handle_tab_switch(index)}
									className={classes.tab_item}
									style={{
										backgroundColor: selected_func_tab === index ? theme.colors.primary : 'transparent',
									}}>
									<Text bold style={{ fontSize: 14, color: selected_func_tab === index ? theme.colors.white : theme.colors.lightPurple6 }}>
										{tab}
									</Text>
									{((index === 0 && !_.isEmpty(grievance_officer_info)) ||
										(index === 1 && !_.isEmpty(customer_officer_info)) ||
										(index === 2 && !_.isEmpty(rider_officer_info))) && (
										<img
											src={selected_func_tab === index ? ImageLinks.tick_circle_white_solid : ImageLinks.tick_circle_light_pink}
											width={16}
											height={16}
											style={{ marginLeft: 9 }}
										/>
									)}
								</div>
							))}
						</div>
						<div>
							{invite_network_id && (
								<div className='d-flex align-items-center' style={{ justifyContent: 'flex-end' }}>
									<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Custom Rule
									</Text>
									<Switch
										disabled={_.get(invite_network_config, 'ownership.support_officers', '') == 'user'}
										checked={can_change_support}
										onChange={(e) => {
											handle_custom_support(e.target.checked);
										}}
									/>
								</div>
							)}
						</div>
					</div>

					<div className={classes.input_section}>
						<Text semi style={{ fontSize: 14 }}>
							{selected_func_tab === 0 ? 'Grievance Officer' : selected_func_tab === 1 ? 'Customer Support' : 'Rider Support'}
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 2 }}>
							{selected_func_tab === 0
								? 'Official who addresses participant complaints related to the network'
								: selected_func_tab === 1
								? 'Official who addresses customer complaints for orders being fulfilled by the network'
								: 'Official who addresses rider complaints for orders being fulfilled by the network'}
						</Text>

						<div className={classes.separator}></div>

						{selected_func_tab === 0 && (
							<div>
								<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, marginBottom: 16 }}>
									Primary Officer
								</Text>
								<OfficerForm
									disabled={invite_network_id && !can_change_support}
									item={grievance_info[0]}
									handle_item_change={handle_grievance_info}
								/>
							</div>
						)}
						{selected_func_tab === 1 && (
							<div>
								{_.map(customer_info, (customer, cus_index) => (
									<>
										<div className='align-items-center' style={{ marginBottom: 10, marginTop: 8 }}>
											<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
												{`${cus_index === 0 ? 'Primary' : 'Secondary'} Officer`}
											</Text>
											{cus_index === 1 && (
												<Button
													type='text'
													text='Remove'
													size='extra_small'
													onClick={handle_remove_customer}
													style={{ width: 'fit-content', marginLeft: 4 }}
													className={classes.remove_text}
												/>
											)}
										</div>

										<OfficerForm
											disabled={invite_network_id && !can_change_support}
											item={customer}
											handle_item_change={(e) => handle_cus_info(e, cus_index)}
										/>
									</>
								))}
								{customer_info.length === 1 && !btn_disabled && !invite_network_id && (
									<Button
										type='text'
										text='Add Another'
										size='extra_small'
										onClick={handle_add_customer}
										style={{ marginTop: 12, width: 110 }}
										left_icon={ImageLinks.add_pruple_no_fill}
									/>
								)}
							</div>
						)}
						{selected_func_tab === 2 && (
							<div>
								{_.map(rider_info, (rider, rider_index) => (
									<>
										<div className='align-items-center' style={{ marginBottom: 10, marginTop: 8 }}>
											<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
												{`${rider_index === 0 ? 'Primary' : 'Secondary'} Officer`}
											</Text>
											{rider_index === 1 && (
												<Button
													type='text'
													text='Remove'
													size='extra_small'
													onClick={handle_remove_rider}
													style={{ width: 'fit-content', marginLeft: 4 }}
													className={classes.remove_text}
												/>
											)}
										</div>
										<OfficerForm
											disabled={invite_network_id && !can_change_support}
											item={rider}
											handle_item_change={(e) => handle_rider_info(e, rider_index)}
										/>
									</>
								))}
								{rider_info.length === 1 && !btn_disabled && !invite_network_id && (
									<Button
										type='text'
										text='Add Another'
										size='extra_small'
										onClick={handle_add_rider}
										style={{ marginTop: 12, width: 110 }}
										left_icon={ImageLinks.add_pruple_no_fill}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			{show_change_modal && (
				<ConfirmClose
					headerTitle='Set Custom Support Officers'
					title='Are you sure you want to set custom custom support officers for this participant? All default contacts will not be considered.'
					show_negative_btn={true}
					close={() => set_show_change_modal(false)}
					on_confirm={() => set_can_change_support(true)}
				/>
			)}

			<SectionCta
				title={`Save ${selected_func_tab === 0 ? 'Grievance' : selected_func_tab === 1 ? 'Customer' : 'Rider'} Support`}
				is_data_updated={is_data_updated}
				is_disabled={btn_disabled}
				handle_click={save_officer_info}
				total_steps={3}
				lower_section_index={6}
				handle_api_save={handle_support_api}
				complete_btn_title={'Setup Support'}
				handle_data_updates={handle_data_updates}
			/>
		</div>
	);
};

export default SupportFunctions;
