import { IconButton, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useEffect } from 'react';
import constant from 'resources/constant';
import theme from 'resources/theme';
import HubItem from './HubItem';
import FilterButton from 'modules/FilterButton';
import MiniFilter from 'modules/MiniFilter';

const useStyles = makeStyles({
	filter_wrapper: {
		display: 'flex',
		marginTop: 32,
		borderBottom: `1px solid ${theme.colors.lightPurple}`,
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		paddingBottom: 10,
	},
	hub_list_item: {
		borderTopRightRadius: 20,
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	select_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	page_wrapper: {
		marginLeft: 20,
		marginRight: 20,
		width: 24,
		height: 24,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 20,
	},
	page_text: { color: theme.colors.primary, fontSize: 12 },
	footer_wrapper: {
		paddingTop: 20,
		marginTop: 10,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		borderTop: `1px solid ${theme.colors.lightPurple}`,
	},
	footer: { display: 'flex', alignItems: 'center' },
	page_nav_btn: {
		width: 24,
		height: 24,
		borderRadius: 30,
	},
	enabled_page_nav: { border: `1px solid ${theme.colors.primary}` },
	disabled_page_nav: { backgroundColor: theme.colors.lightGrey5, width: 26, height: 26 },
});

let markers = [];

const ListView = ({ existing_filters, set_selected_filters, hubs, handle_delete_hub, page, set_page }) => {
	const classes = useStyles();
	const map = React.useRef(null);

	const [show_filters, set_show_filters] = React.useState();

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, [hubs]);

	const initialize = () => {
		if (!map.current) {
			map.current = new window.google.maps.Map(document.getElementById('map_canvas'), {
				center: { lat: Number(20.5937), lng: Number(78.9629) },
				zoom: 5,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});
		}

		_.map(markers, (marker) => {
			marker.setMap(null);
		});

		const icon = {
			url: ImageLinks.marker_purple, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};

		markers = _.map(hubs.data, (hub) => {
			const marker = new window.google.maps.Marker({
				zoom: 9,
				position: { lat: Number(hub.location.latitude), lng: Number(hub.location.longitude) },
				map,
				icon,
			});

			marker.setMap(map.current);

			return marker;
		});
	};

	const on_hub_click = (hub) => {
		map.current.setOptions({
			center: { lat: Number(hub.location.latitude), lng: Number(hub.location.longitude) },
			zoom: 15,
		});
	};
	return (
		<>
			<div className={classes.filter_wrapper}>
				<div style={{ display: 'flex' }}>
					{_.map(
						[
							{
								text: 'Total',
								details: hubs.count || '-',
							},
						],
						(filter, key) => {
							return (
								<div key={`status${key}`} className={classes.hub_list_item}>
									<Text bold style={{ color: theme.colors.primary, fontSize: 28 }}>
										{filter.details}
									</Text>
									<Text semi style={{ color: theme.colors.black, marginTop: 2, fontSize: 14 }}>
										{filter.text}
									</Text>
								</div>
							);
						},
					)}
				</div>
				<FilterButton
					handle_click={() => set_show_filters(true)}
					total_filters={Object.keys(existing_filters).length}
					track_event={{ event: constant.TRACKING_EVENTS.SAVED_ADDRESS_FILTER, properties: existing_filters }}
				/>
			</div>
			<div style={{ display: !_.isEmpty(hubs) ? 'flex' : 'none', flex: 1, columnGap: '5%', overflow: 'hidden', marginTop: 20, marginBottom: 40 }}>
				<div style={{ flex: 4, display: 'flex', flexDirection: 'column', paddingRight: 20, overflowY: 'hidden' }}>
					<div style={{ flex: 1, overflowY: 'auto', paddingRight: 20 }}>
						{_.map(hubs.data, (hub, key) => {
							return (
								<div style={{ ...(key !== 0 && { marginTop: 20 }) }}>
									<HubItem
										title={hub.title}
										id={hub.id}
										latitude={hub.location.latitude.toFixed(5) || '-'}
										longitude={hub.location.longitude.toFixed(5) || '-'}
										master_zone_id={hub.master_zone_id}
										handle_delete_hub={handle_delete_hub}
										allowable_login_distance={hub.allowable_login_distance}
										onClick={() => on_hub_click(hub)}
									/>
								</div>
							);
						})}
					</div>
					<div className={classes.footer_wrapper}>
						<div className={classes.footer}>
							<IconButton
								className={clsx(classes.page_nav_btn, page > 1 ? classes.enabled_page_nav : classes.disabled_page_nav)}
								onClick={() => {
									if (page > 1) set_page(page - 1);
								}}>
								<img src={page > 1 ? ImageLinks.chevronLeft : ImageLinks.chevron_left_white} width={10} alt='' />
							</IconButton>
							<div className={classes.page_wrapper}>
								<Text bold className={classes.page_text}>
									{page}
								</Text>
							</div>
							<IconButton
								className={clsx(classes.page_nav_btn, page < hubs?.pages ? classes.enabled_page_nav : classes.disabled_page_nav)}
								onClick={() => {
									if (page < hubs?.pages) {
										set_page(page + 1);
									}
								}}>
								<img
									src={page < hubs?.pages ? ImageLinks.chevronLeft : ImageLinks.chevron_left_white}
									width={10}
									style={{ transform: 'rotate(180deg)' }}
									alt=''
								/>
							</IconButton>
						</div>
					</div>
				</div>
				<div style={{ flex: 3 }}>
					<div
						id='map_canvas'
						className='map-canvas-wrapper rider-location card'
						style={{ height: '100%', borderRadius: 20, background: theme.colors.lightGrey3 }}></div>
				</div>
			</div>
			{show_filters && (
				<div style={{ position: 'absolute', top: 142, right: 30 }}>
					<MiniFilter
						show_searh_bar={false}
						close={() => set_show_filters(false)}
						existing_filters={existing_filters}
						set_selected_filters={set_selected_filters}
						container_style={{ height: 130 }}
					/>
				</div>
			)}
		</>
	);
};

export default ListView;
