import React from 'react';
import Text from 'modules/Text';
import useStyles from '../styles';
import _ from 'lodash';

const OrderPricingItem = ({ item }) => {
	const classes = useStyles();
	return (
		<div className={classes.comm_price_item}>
			<Text medium style={{ fontSize: 12 }}>
				{item.title}
			</Text>

			<div className={'align-items-center'}>
				<Text medium style={{ fontSize: 12 }}>
					{item.amount}
				</Text>
				<Text semi className={classes.price_unit_text}>
					{` /${item.unit}`}
				</Text>
			</div>
		</div>
	);
};

export default OrderPricingItem;
