import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import qs from 'qs';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Text from 'modules/Text';
import theme from 'resources/theme';
import EmptyListItem from './components/EmptyListItem';
import { Checkbox } from '@material-ui/core';
import useStyles from './styles';
import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import { toast } from 'react-toastify';
import Routes from 'resources/Routes';
import { useHistory } from 'react-router-dom';
import Filters from 'modules/Filters/Filters';
import { ALL_ORDERS_MENU_ITEMS, LOAD_ALL_STATES } from 'views/AllOrders/helper';
import ConfirmClose from 'modules/modals/ConfirmClose';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from 'views/PoweredWithPidge/helper';
import utils from 'resources/utils';
import Toast from 'modules/Toast';
import BulksActions from 'modules/BulkActions/BulksActions';
import { OPTIONS_MENU, REPORT_MENU } from 'modules/BulkActions/helpers';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import constant from 'resources/constant';
import FilterButton from 'modules/FilterButton';
import CdrAllocationModal from 'modules/modals/CdrAllocationModal/CdrAllocationModal';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import Button from 'modules/Button';
import Helpcenter from 'modules/HelpCenter';
import NoResult from 'modules/NoResult';
import { hide_loader, show_loader } from 'actions/app';
import NetworkConfigAvatar from 'modules/NetworkConfigAvatar';

const initial_confirm_ship_self_route = {
	open: false,
	order_ids: [],
};

const initial_allocation_modal_details = {
	open: false,
	order_ids: [],
};

let selected_order_data = [];

const Pending = ({ report_items = [], cdr_id = null }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const route_state = qs.parse(history.location.state) || {};
	const filter_object = qs.parse(history.location.search.slice(1)).filter || {};
	const [selected_pb_id, set_selected_pb_id] = React.useState('');
	const [show_order_details, set_show_order_details] = React.useState(false);
	const [refresh, set_refresh] = React.useState(false);
	const [orders, set_orders] = React.useState({});
	const [all_filters, set_all_filters] = React.useState([]);
	const [selected_filters, set_selected_filters] = React.useState({});
	const [show_filters, set_show_filters] = React.useState(false);
	const [selected_order_ids, set_selected_order_ids] = React.useState([]);
	const [allocation_modal_details, set_allocation_modal_details] = React.useState(_.cloneDeep(initial_allocation_modal_details));
	const [captive_riders, set_captive_riders] = React.useState([]);
	const [order_list, set_order_list] = React.useState([]);
	const [moved_orders, set_moved_orders] = React.useState(0);
	const [in_process_orders, set_in_process_orders] = React.useState(0);
	const slider_ref = React.useRef();
	const loading_scroll = React.useRef(false);
	const [load_all_stage, set_load_all_stage] = useState(LOAD_ALL_STATES.INITIAL);

	const [confirm_ship_self_route, set_confirm_ship_self_route] = React.useState(_.cloneDeep(initial_confirm_ship_self_route));

	useEffect(() => {
		get_orders({});
		api_requests.get_captive_riders().then((res) => set_captive_riders(res.data));
	}, []);

	useEffect(() => {
		set_all_filters([]);
		set_selected_filters({});
		api_requests.all_orders_filter('pending').then((res) => set_all_filters(res.data.filters));
	}, []);

	useEffect(() => {
		if (_.isEmpty(selected_filters)) {
			return;
		}
		get_orders({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected_filters]);

	useEffect(() => {
		get_orders({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(filter_object)]);

	useEffect(() => {
		if (refresh) {
			toast.success('See updates in 15 seconds', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 10000,
				pauseOnHover: false,
				closeButton: false,
				closeOnClick: false,
				onClose: () => {
					set_refresh(false);
					get_orders({});
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh]);

	useEffect(() => {
		set_moved_orders(
			_.filter(order_list, (order, key) => {
				return in_last_5_minutes(_.get(order, 'created_at')) && !_.isNull(_.get(order, 'customer_detail.address.business_zone.id', null));
			}).length,
		);
		set_in_process_orders(
			_.filter(order_list, (order, key) => {
				return in_last_5_minutes(_.get(order, 'created_at')) && _.isNull(_.get(order, 'customer_detail.address.business_zone.id', null));
			}).length,
		);
	}, [order_list]);

	useEffect(() => {
		if (cdr_id) {
			fetch_cdr_detail_and_open_modal();
		}
	}, [cdr_id]);

	const fetch_cdr_detail_and_open_modal = async () => {
		try {
			const response = await api_requests.get_pending_order_details(cdr_id);
			selected_order_data = [response.data];
			set_allocation_modal_details({
				open: true,
				order_ids: [cdr_id],
			});
		} catch (error) {}
	};

	const on_update = () => {
		get_orders({});
	};

	const get_orders = ({ token, limit = 50 }) => {
		dispatch(show_loader());
		set_orders({ next: true });
		if (!token) {
			set_selected_order_ids([]);
		}
		api_requests.get_pending_orders_search({ ...filter_object, limit: limit, ...(token && { token }), group: 'pending' }).then((res) => {
			set_orders(res.data);
			loading_scroll.current = false;
			dispatch(hide_loader());
			if (token) {
				set_order_list(_.concat(order_list, res.data.items));
			} else {
				set_order_list(res.data.items);
			}
		});
	};

	const in_last_5_minutes = (date) => {
		let time_differece = new Date() - new Date(date);
		if (time_differece > 300000 || time_differece < 0) {
			return false;
		} else {
			return true;
		}
	};

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && orders.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_orders({ token: orders.next });
		}
	};

	const handle_select_all_order = () => {
		if (selected_order_ids.length === order_list.length) {
			set_selected_order_ids([]);
		} else {
			set_selected_order_ids(_.map(order_list, 'id'));
		}
	};

	const handle_select_order = (order_id) => {
		if (!order_id) return;
		const index = _.indexOf(selected_order_ids, order_id);
		const _selected_order_ids = [...selected_order_ids];

		if (index === -1) {
			_selected_order_ids.push(order_id);
		} else {
			_selected_order_ids.splice(index, 1);
		}

		set_selected_order_ids(_selected_order_ids);
	};

	const ship_orders = (_order_ids = []) => {
		const filtered_ids = _.filter(_order_ids, (item) => item);

		api_requests
			.ship_pending_orders({
				ids: filtered_ids,
				service: 'captive',
				pickup_now: false,
				manifest: true,
				network_id: '-2',
				fulfillment_type: 0,
			})
			.then(() => {
				toast.success('Updated Successfully', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				history.push(Routes.POWERED_WITH_PIDGE.path + '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.ReadyToShip.id);
			});
	};

	const handle_export = (filters, callback) => {
		api_requests
			.export_all_orders({ ...filters, group: 'pending' })
			.then((res) => {
				utils.downloadBlob(res, 'orders.csv', 'text/csv');
				callback(true);
			})
			.catch((err) => {
				callback(false);
			});
	};

	const handle_partner_click = async (_order_ids = []) => {
		const orders = _order_ids.length > 0 ? _order_ids : selected_order_ids;
		selected_order_data = order_list.filter(({ id }) => orders.includes(id));
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.PARTNER_CLICK);
		set_allocation_modal_details({
			open: true,
			order_ids: orders,
		});
	};

	const handle_product_list_download = () => {
		const filtered_list = _.filter(order_list, (item) => _.includes(selected_order_ids, item.id));

		const formatted_list = [];
		for (let i = 0; i < filtered_list.length; i++) {
			const element = filtered_list[i];
			if (element.products?.length > 0) {
				for (let j = 0; j < element.products.length; j++) {
					const product_element = element.products[j];
					formatted_list.push({ ...element, product: product_element });
				}
			} else {
				formatted_list.push({ ...element, product: null });
			}
		}
		let data =
			'demand channel, channel order id, reference id, bill amount, cod amount, customer name, customer mobile, customer address, delivery date, delivery slot, product item, product sku, product quantity, note';

		_.map(formatted_list, (order) => {
			data +=
				'\n' +
				_.get(order, 'dd_channel.name', '-') +
				', ' +
				_.get(order, 'dd_channel.order_id', '-') +
				', ' +
				_.get(order, 'reference_id', '-') +
				', ' +
				_.get(order, 'bill_amount', '-') +
				', ' +
				_.get(order, 'cod_amount', '-') +
				', ' +
				_.get(order, 'customer_detail.name', '-') +
				', ' +
				_.get(order, 'customer_detail.mobile', '-') +
				', ' +
				_.get(order, 'customer_detail.address.address_line_1', '-').replaceAll(',', ' ') +
				', ' +
				_.get(order, 'delivery_date', '-') +
				', ' +
				_.get(order, 'delivery_slot', '-') +
				', ' +
				_.get(order, 'product.name', '-') +
				', ' +
				_.get(order, 'product.sku', '-') +
				', ' +
				_.get(order, 'product.quantity', '-') +
				', ' +
				_.map(
					_.filter(order.notes, (note) => note.name === 'note'),
					(filter_note) => filter_note.value,
				).join(', ');
		});

		utils.downloadBlob(data, 'orders.csv', 'text/csv');
	};

	const handle_load_all = async () => {
		set_load_all_stage(LOAD_ALL_STATES.STARTED);
		let token = null;
		let _order_list = [];
		let next = true;
		while (next) {
			try {
				const res = await api_requests.get_pending_orders_search({ ...filter_object, limit: 50, ...(token && { token }), group: 'pending' });
				if (token) {
					_order_list = _.concat(_order_list, res.data.items);
				} else {
					_order_list = res.data.items;
				}
				if (res.data.next) {
					token = res.data.next;
				} else {
					next = false;
					set_order_list(_order_list);
					set_load_all_stage(LOAD_ALL_STATES.COMPLETED);
					set_orders(res.data);
				}
			} catch (err) {}
		}
	};

	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<div className={classes.header_btn_wrapper}>
				<div style={{ display: 'flex', height: 35 }}>
					<Text medium style={{ fontSize: 18, marginRight: 10 }}>
						Ship with:
					</Text>
					{captive_riders.length > 0 && (
						<Button
							type='outlined'
							size='large'
							text='Self Route'
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.SELF_ROUTE, { order_ids: selected_order_ids });
								set_confirm_ship_self_route({ open: true, order_ids: selected_order_ids });
							}}
							disabled={selected_order_ids.length === 0}
							className={clsx(classes.ship_order_btn)}
							left_icon={selected_order_ids.length === 0 ? ImageLinks.route_z_grey : ImageLinks.route_z_purple}
						/>
					)}
					<Button
						type='round'
						size='large'
						text='Partner'
						onClick={handle_partner_click}
						disabled={selected_order_ids.length === 0}
						className={clsx(classes.add_btn)}
						left_icon={selected_order_ids.length === 0 ? ImageLinks.location_grey : ImageLinks.marker_with_check}
					/>
				</div>
				<div className={classes.ongoingOrdersDiv}>
					<div className='d-flex align-items-center' style={{ gap: 5 }}>
						<Text semi component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
							Orders :{' '}
						</Text>
						<Text semi component={'span'} style={{ fontSize: 14 }}>
							{order_list.length} {orders.next ? ` of more` : ''}
						</Text>
						<BootstrapTooltip title='Load all Orders'>
							<div style={{ marginTop: 5 }}>
								{load_all_stage != LOAD_ALL_STATES.COMPLETED && (
									<img
										src={ImageLinks.refresh_purple}
										width={16}
										height={16}
										style={{
											cursor: 'pointer',
											...(load_all_stage === LOAD_ALL_STATES.STARTED && { animation: 'spin 4s linear infinite' }),
										}}
										onClick={() => handle_load_all()}
									/>
								)}
								{load_all_stage === LOAD_ALL_STATES.COMPLETED && <img src={ImageLinks.tickCirclePurpleSolid} width={16} height={16} />}
							</div>
						</BootstrapTooltip>
					</div>

					<FilterButton
						handle_click={() => set_show_filters(true)}
						total_filters={Object.keys(filter_object).length}
						wrapper_styles={{ marginBottom: 0, marginLeft: 10 }}
						track_event={{ event: 'all_order_pending_filter', properties: { filter_object } }}
					/>
				</div>
			</div>
			{selected_order_ids.length > 0 && (
				<div className={classes.bulk_action_wrapper}>
					<BulksActions
						option={[OPTIONS_MENU.update, OPTIONS_MENU.report, OPTIONS_MENU.cancel]}
						selected_order_ids={selected_order_ids}
						set_selected_order_ids={set_selected_order_ids}
						report_menu={[REPORT_MENU.product]}
						handle_product_list_download={handle_product_list_download}
						on_update={on_update}
					/>
				</div>
			)}
			<div className={classes.detailsTextContainer}>
				<div style={{ width: 50 }}>
					{order_list && order_list?.length !== 0 && (
						<Checkbox
							size='small'
							checked={selected_order_ids.length === order_list.length}
							onClick={handle_select_all_order}
							style={{ color: theme.colors.lightPurple2, padding: 0 }}
						/>
					)}
				</div>
				<Text bold className={classes.header_title}>
					Channel/Ref ID
				</Text>
				<Text bold className={classes.header_title}>
					Customer Details
				</Text>
				<Text bold className={classes.header_title}>
					Vol (in3) | Wt (g)
				</Text>
				<Text bold className={classes.header_title}>
					Payment
				</Text>
				<Text bold className={classes.header_title}>
					Pickup Address
				</Text>
				<Text bold className={classes.header_title}>
					Notes
				</Text>
			</div>

			<div className={classes.pickedUpContainer} ref={slider_ref} onScroll={slider_scrolled}>
				{_.map(order_list, (order, key) => {
					const total_wt = _.reduce(order.packages, (prev, curr) => prev + _.get(curr, 'dead_weight', 0) * curr.quantity, 0);
					const total_volume = _.reduce(order.packages, (prev, curr) => prev + _.get(curr, 'volumetric_weight', 0) * curr.quantity, 0);
					const channel_ref_id_string = order.reference_id || order?.dd_channel?.order_id || order.id || '-';
					return (
						<>
							<div key={`order${key}`} className={classes.pickedUpItem}>
								<div style={{ width: 50 }}>
									<Checkbox
										size='small'
										checked={_.includes(selected_order_ids, order.id)}
										onClick={() => handle_select_order(order.id)}
										style={{ color: theme.colors.lightPurple2, padding: 0 }}
									/>
								</div>
								<div className={classes.table_item_wrap_2}>
									{_.get(order, 'dd_channel.order_id') ? (
										<>
											<Text
												semi
												className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
												onClick={() => {
													if (!order.id) return;
													utils.track_event_for_analytics(constant.TRACKING_EVENTS.OVERVIEW_ORDER_CLICK);
													set_selected_pb_id(order.id);
													set_show_order_details(true);
												}}>
												{channel_ref_id_string}
											</Text>
											{in_last_5_minutes(_.get(order, 'created_at')) && (
												<div
													className={
														!_.isNull(_.get(order, 'customer_detail.address.business_zone.id', null))
															? classes.purple_dot
															: classes.yellow_dot
													}
												/>
											)}
										</>
									) : (
										<EmptyListItem />
									)}
									{_.get(order, 'created_at') ? (
										<Text className={classes.subtitle}>
											{`${moment(order.created_at).format('DD MMM YY')} | ${moment(order.created_at).format('hh:mmA')}`}
										</Text>
									) : (
										<EmptyListItem is_sub={true} />
									)}
								</div>
								<div className={classes.table_item_wrap_2}>
									{_.get(order, 'customer_detail.name') ? (
										<Text medium className={classes.truncate}>
											{`${_.get(order, 'customer_detail.name')}`}
										</Text>
									) : (
										<EmptyListItem is_sub={true} />
									)}
									<div style={{ display: 'flex' }}>
										{_.get(order, 'customer_detail.mobile') ? (
											<Text className={classes.subtitle}>{`${_.get(order, 'customer_detail.mobile', '')}`}</Text>
										) : (
											<EmptyListItem is_sub={true} />
										)}
										<Text style={{ marginLeft: 3, marginRight: 3 }} className={classes.subtitle}>
											|
										</Text>
										{_.get(order, 'customer_detail.address.pincode') ? (
											<Text className={classes.subtitle}>{`${_.get(order, 'customer_detail.address.pincode', '')}`}</Text>
										) : (
											<EmptyListItem is_sub={true} />
										)}
									</div>
								</div>

								<div style={{ display: 'flex', alignItems: 'center' }} className={classes.table_item_wrap_2}>
									{_.get(order, 'packages[0].volumetric_weight') ? (
										<Text medium className={classes.truncate}>
											{total_volume}{' '}
											<Text medium component={'span'} style={{ fontSize: 12 }}>
												in<sup style={{ fontSize: 8 }}>3</sup>
											</Text>
										</Text>
									) : (
										<EmptyListItem />
									)}
									<Text medium style={{ marginLeft: 4, marginRight: 4 }} className={classes.truncate}>
										|
									</Text>
									<Text medium className={classes.truncate}>
										{total_wt}
										<Text medium component={'span'} style={{ fontSize: 12 }}>
											g
										</Text>
									</Text>
								</div>
								<div style={{ display: 'flex', alignItems: 'center' }} className={classes.table_item_wrap_2}>
									{order?.cod_amount > 0 && <img src={ImageLinks.inr_filled} width={16} height={16} />}
									{_.get(order, 'cod_amount') > 0 || _.get(order, 'bill_amount') >= 0 ? (
										<Text medium style={{ marginLeft: 4 }} className={classes.truncate}>
											₹{`${_.get(order, 'cod_amount') > 0 ? _.get(order, 'cod_amount') : _.get(order, 'bill_amount')} `}
										</Text>
									) : (
										<EmptyListItem />
									)}
								</div>
								<div className={classes.table_item_wrap_2}>
									{_.get(order, 'sender_detail.address.address_line_1') ? (
										<Text medium className={classes.truncate}>
											{_.get(order, 'sender_detail.address.address_line_1')}
										</Text>
									) : (
										<EmptyListItem />
									)}
									{_.get(order, 'sender_detail.address.pincode') ? (
										<Text className={classes.subtitle}>{`${_.get(order, 'sender_detail.address.pincode')}`}</Text>
									) : (
										<EmptyListItem is_sub={true} />
									)}
								</div>
								<div className={classes.table_item_wrap_2} style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
									{order.planned_fulfillment && (
										<BootstrapTooltip
											is_white={true}
											placement='top'
											title={
												<div className='d-flex align-items-center' style={{ gap: 5, padding: 8 }}>
													{_.get(order, 'planned_fulfillment.option.smart_allocation_id', '') ? (
														<Text
															component={'span'}
															semi
															style={{
																fontSize: 12,
																color: theme.colors.black,
															}}>{`Smart_id: ${order.planned_fulfillment.option.smart_allocation_id}`}</Text>
													) : (
														<NetworkConfigAvatar id={order?.planned_fulfillment?.option?.network_id} logo_width={70} />
													)}

													<Text component={'span'} semi style={{ fontSize: 12, color: theme.colors.black }}>{` | `}</Text>
													<Text component={'span'} semi style={{ fontSize: 12, color: theme.colors.black }}>
														{order.planned_fulfillment?.scheduled_at
															? moment(order.planned_fulfillment?.scheduled_at).format('DD-MMM-YY, hh:mm:A')
															: '-'}
													</Text>
												</div>
											}>
											<img src={ImageLinks.clock_dark_pink} height={16} width={16} />
										</BootstrapTooltip>
									)}
									<Text medium className={classes.truncate}>
										{`${_.get(order, 'delivery_date', '-') || '-'}${
											_.isEmpty(_.get(order, 'delivery_slot', '')) ? '' : `;${order.delivery_slot}`
										}`}
										{`${_.get(order, 'notes', []).length === 1 ? ` ${_.get(order, 'notes.0.value')}` : ''}`}
									</Text>
								</div>
							</div>
						</>
					);
				})}
				{/* {order_list.length < 8 && (
					<div className={classes.wrapper_help_center}>
						<Helpcenter text='How do I manage orders?' url='https://pidge.in/helpcenter-order-allorders' style={{ marginTop: 40 }} />
					</div>
				)} */}
				{order_list.length == 0 && !_.isEmpty(filter_object) && (
					<div className='d-flex justify-content-center align-items-center' style={{ marginTop: '15%', flexDirection: 'column', gap: 10 }}>
						<NoResult text='No order found' btn_onclick={() => history.replace({ search: '?type=' + ALL_ORDERS_MENU_ITEMS.Pending.id })} />
					</div>
				)}
			</div>

			{(moved_orders > 0 || in_process_orders > 0) && (
				<Toast>
					<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
					{moved_orders > 0 && (
						<Text component={'span'} semi style={{ marginLeft: '10px' }}>
							<Text component={'span'} semi style={{ color: theme.colors.primary }}>
								{moved_orders} orders
							</Text>{' '}
							added successfully.{' '}
						</Text>
					)}
					{in_process_orders > 0 && (
						<Text component={'span'} semi>
							<Text component={'span'} semi style={{ color: theme.colors.darkYellow }}>
								{in_process_orders} orders
							</Text>{' '}
							in processing{' '}
						</Text>
					)}
				</Toast>
			)}
			{show_order_details && (
				<OrderModal
					close={() => {
						set_selected_pb_id('');
						set_show_order_details(false);
					}}
					id={selected_pb_id}
					updater={on_update}
				/>
			)}
			{show_filters && (
				<Filters
					source='all_orders'
					existing_filters={filter_object}
					all_filters={all_filters}
					set_selected_filters={(filters) => {
						history.push(Routes.ALL_ORDERS.path + `?type=${ALL_ORDERS_MENU_ITEMS.Pending.id}&` + qs.stringify({ filter: filters }));
						set_selected_order_ids([]);
					}}
					close={() => set_show_filters(false)}
					title='Pending'
					handle_export={handle_export}
					report_items={report_items}
				/>
			)}
			{confirm_ship_self_route.open && (
				<ConfirmClose
					style={{ width: 600 }}
					close={() => set_confirm_ship_self_route(_.cloneDeep(initial_confirm_ship_self_route))}
					on_confirm={() => {
						utils.track_event_for_analytics(constant.TRACKING_EVENTS.CONFIRM_SELF_ROUTE_MOVE);
						ship_orders(confirm_ship_self_route.order_ids);
					}}
					headerTitle={'Ship with Self Route'}
					title={
						<div style={{ display: 'flex', alignItems: 'flex-start' }}>
							<Text medium className={classes.modal_text}>
								Shipping with self route will move {confirm_ship_self_route.order_ids.length} order
								{confirm_ship_self_route.order_ids.length > 1 && 's'} to Pidge Powered. Are you sure?
							</Text>
						</div>
					}
					titleStyles={{ width: '100%' }}
					negative_btn_text='No, keep orders'
					positive_btn_text='Yes, move orders'
				/>
			)}

			{allocation_modal_details.open && (
				<CdrAllocationModal
					close={() => {
						set_allocation_modal_details(_.cloneDeep(initial_allocation_modal_details));
					}}
					cdr_ids={allocation_modal_details.order_ids}
					cdr_datas={selected_order_data}
					on_success={() => get_orders({})}
				/>
			)}
		</div>
	);
};

export default Pending;
