import React, { useMemo } from 'react';
import theme from 'resources/theme';
import useStyles from './styles';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import { BOTTOM_STATE, TYPES } from './helper';
import _ from 'lodash';
import RiderType from './components/RiderType';
import clsx from 'clsx';
import AllocationFooter from './components/AllocationFooter';
import useAllocationModal from './useAllocationModal';
import NetworkAvatar from 'modules/NetworkAvatar';

const AllocationModal = ({ close, pickup_coords, drop_coords, mcp_ids, is_multiple_network, network_id, on_success }) => {
	const {
		app_configs,
		selected_type,
		biz_available_rider_type,
		network_available_rider_type,
		bottom_bar_state,
		biz_selected_rider,
		network_selected_rider,
		set_network_available_rider_type,
		set_selected_type,
		set_biz_available_rider_type,
		set_biz_selected_rider,
		set_network_selected_rider,
		handle_allocate_rider,
		handle_track,
		handle_try_again,
	} = useAllocationModal(close, pickup_coords, drop_coords, mcp_ids, is_multiple_network, network_id, on_success);

	const classes = useStyles();

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div className={classes.content}>
					<div className={clsx(classes.map_wrap)}>
						<div id='map_canvas_address_3' className='map-canvas-wrapper card' style={{ height: '100%', borderRadius: 20 }} />
					</div>

					<div className={classes.right_wrap}>
						<div className={classes.header}>
							<Text bold style={{ fontSize: 18, color: theme.colors.primary }}>
								{is_multiple_network ? 'Quick Ship multi-network orders' : 'Quick Ship'}
							</Text>
							<div className='cursor-pointer' onClick={close} style={{ marginTop: 3 }}>
								<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
							</div>
						</div>

						<div
							style={{
								opacity: bottom_bar_state === BOTTOM_STATE.in_progress ? 0.3 : 1,
								pointerEvents: bottom_bar_state === BOTTOM_STATE.in_progress ? 'none' : 'auto',
							}}>
							<div
								onClick={() => set_selected_type(TYPES.BUSINESS)}
								className={classes.allocate_card}
								style={{
									marginTop: 20,
									minHeight: selected_type === TYPES.BUSINESS ? 208 : 64,
									border: `1px solid ${selected_type === TYPES.BUSINESS ? theme.colors.primary : theme.colors.lightGrey7}`,
								}}>
								<div className='align-items-center'>
									<div className={classes.icon_wrap} style={{ border: `0.43px solid ${theme.colors.lightPurple6}` }}>
										<img src={ImageLinks.rider_light_purple3} width={20} height={20} />
									</div>

									<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
										Business Riders
									</Text>
								</div>
								{selected_type === TYPES.BUSINESS && (
									<RiderType
										network_id={-2}
										available_rider_type={biz_available_rider_type}
										set_available_rider_type={set_biz_available_rider_type}
										selected_rider={biz_selected_rider}
										set_selected_rider={set_biz_selected_rider}
									/>
								)}
							</div>

							{!is_multiple_network && network_id && (
								<div
									onClick={() => set_selected_type(TYPES.NETWORK)}
									className={classes.allocate_card}
									style={{
										marginTop: 12,
										minHeight: selected_type === TYPES.NETWORK ? 208 : 64,
										border: `1px solid ${selected_type === TYPES.NETWORK ? theme.colors.primary : theme.colors.lightGrey7}`,
									}}>
									<div className='align-items-center'>
										<NetworkAvatar
											configs={_.get(app_configs, `network_configs.${network_id}.avatar.configs`, [])}
											height={40}
											width={40}
											image={_.get(app_configs, `network_configs.${network_id}.avatar.image`, [])}
										/>

										<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
											{`${_.get(app_configs, `network_configs.${network_id}.name`, null)} (${network_id})`}
										</Text>
									</div>
									{selected_type === TYPES.NETWORK && (
										<RiderType
											network_id={network_id}
											available_rider_type={network_available_rider_type}
											set_available_rider_type={set_network_available_rider_type}
											selected_rider={network_selected_rider}
											set_selected_rider={set_network_selected_rider}
										/>
									)}
								</div>
							)}
						</div>

						<div className={classes.footer}>
							<div className={classes.footer_content}>
								<AllocationFooter
									total_orders={mcp_ids.length}
									selected_type={selected_type}
									bottom_bar_state={bottom_bar_state}
									handle_allocate_rider={handle_allocate_rider}
									handle_track={handle_track}
									handle_try_again={handle_try_again}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AllocationModal;
