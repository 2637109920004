import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import theme from 'resources/theme';
import CreateNetworkContext from '../context';
import Header from '../CreateNetwork/components/Header';
import InviteParticipantUpperSection from './components/InviteParticipantUpperSection';
import CreateNetworkLowerSection from '../CreateNetwork/components/CreateNetworkLowerSection';
import ConfirmClose from 'modules/modals/ConfirmClose';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import QueryString from 'qs';

const useStyles = makeStyles(() => ({
	container: {
		height: '100%',
		paddingBottom: 50,
	},
	separator: { border: `1px solid ${theme.colors.lightGrey2}`, marginTop: 20, marginBottom: 20 },
}));

const InviteParticipant = ({ match }) => {
	const classes = useStyles();
	const history = useHistory();
	const id = match.params.id;
	const query_object = QueryString.parse(history.location.search.slice(1));
	const {
		set_invite_network_id,
		set_invite_flow,
		invite_network_config,
		confirm_close_modal,
		handle_confirm_modal_close,
		set_is_invite_allocation_updated,
		set_is_invite_pricing_updated,
	} = useContext(CreateNetworkContext);

	useEffect(() => {
		set_is_invite_allocation_updated(false);
		set_is_invite_pricing_updated(false);
		if (id) {
			if (query_object.id) {
				set_invite_flow(query_object.network_id);
			} else {
				set_invite_flow(id);
			}
		}
		return () => {
			set_invite_network_id(null);
		};
	}, [id]);

	return (
		<div className={`container ${classes.container}`}>
			<Header />
			<div style={{ overflow: 'auto', height: '100%' }}>
				<InviteParticipantUpperSection />
				<div className={classes.separator}></div>
				{invite_network_config != null && <CreateNetworkLowerSection />}
				{confirm_close_modal.open && (
					<ConfirmClose
						headerTitle={confirm_close_modal.header_title}
						title={confirm_close_modal.title}
						close={handle_confirm_modal_close}
						positive_btn_text={confirm_close_modal.positive_text}
						negative_btn_text={confirm_close_modal.negative_text}
						show_negative_btn={confirm_close_modal.show_negative_btn}
						titleStyles={{ fontSize: 16, textAlign: 'left' }}
						on_confirm={confirm_close_modal.confirm_acion}
						is_error_msg={confirm_close_modal.is_error_msg}
						modal_icon={confirm_close_modal.modal_icon}
					/>
				)}
			</div>
		</div>
	);
};

export default InviteParticipant;
