import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';

import Text from 'modules/Text';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import ContainedButton from 'modules/ContainedButton';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';
import OutlinedTextField from 'modules/OutlinedTextField';
import ImageLinks from 'assets/images/ImageLinks';
import Footer from './Footer';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.darkGrey2,
		},

		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.primary,
		},

		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			color: theme.colors.black,
		},

		'& .MuiInputLabel-outlined': {
			color: theme.colors.darkGrey2,
		},

		'& .MuiInputLabel-outlined.Mui-focused': {
			color: theme.colors.primary,
		},

		'& .MuiInputLabel-outlined.Mui-error': {
			color: theme.colors.red2,
		},
		'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.red2,
		},
	},

	helper_text: {
		color: `${theme.colors.red2} !important`,
		fontSize: 12,
	},
	underlinedTextFiled: {
		width: 300,
		marginTop: 40,
	},
	verify_text: { color: theme.colors.primary, fontSize: 18, marginTop: 10 },
	cta_btn_wrapper: { marginTop: 70, display: 'flex', alignItems: 'center' },
	cta_btn: { borderRadius: 25, height: 39, width: 150 },
	set_pass_text: { color: theme.colors.primary, fontSize: 18, marginTop: 10 },
	error_wrapper: {
		width: 16,
		height: 16,
		backgroundColor: '#B90000',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 20,
	},
	pass_err_text: { color: '#B90000', fontSize: 12, marginLeft: 5 },
	form_style: { width: '50%', height: '100%' },
	forgot_pass_text: { marginLeft: 20, color: '#766F83', fontSize: 14, cursor: 'pointer' },
}));

const SCREENS = {
	register_step_1: 'register_step_1',
	register_step_2: 'register_step_2',
};

const Register = ({ user_data, handle_signup_success, is_forgot_password, set_login, on_footer_auth_nav }) => {
	const { token, first_name, last_name, step, email, mobile } = user_data;
	const [is_checked, set_is_checked] = React.useState(false);
	const [register_data, set_register_data] = React.useState({
		first_name: step === 2 ? `${first_name}` : '',
		last_name: step === 2 ? `${last_name}` : '',
		email: email,
		password: '',
		confirm_password: '',
		mobile,
	});
	const [show_error, set_show_error] = React.useState(false);
	const [email_error, set_email_error] = React.useState(false);
	const [screen, set_screen] = React.useState(step === 2 ? SCREENS.register_step_2 : SCREENS.register_step_1);

	const [otp, set_otp] = useState('');
	const [timer, set_timer] = useState(null);
	const [otp_failed, set_otp_failed] = useState(false);
	const [is_otp_verified, set_is_otp_verified] = useState(false);
	const timer_ref = useRef(null);

	useEffect(() => {
		if (timer > 0) {
			timer_ref.current = setTimeout(() => {
				set_timer(timer - 1);
			}, 1000);
		}
	}, [timer]);

	const handle_data = (name, value) => {
		set_show_error(false);
		set_register_data({
			...register_data,
			[name]: value,
		});
	};

	const _sendOTP = (val = mobile) => {
		api_requests
			.request_otp({
				mobile: val,
			})
			.then(() => {
				set_otp('');
				set_timer(20);
			});
	};

	const on_enter_otp = (e) => {
		let val = e.target.value;
		if (val.length <= 4) {
			set_otp(val);
		}
	};

	const on_pass_blur = (val) => {
		const letter = /[a-z]/;
		const number = /[0-9]/;
		if (_.isEmpty(val)) {
			set_show_error(false);
			return;
		}
		if (!letter.test(val.toLowerCase()) || !number.test(val) || val.legnth < 4) {
			set_show_error(true);
			return;
		}
	};

	const on_email_blur = (email) => {
		if (_.isEmpty(email.trim())) {
			set_email_error(false);
			return;
		}
		const is_matched = email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
		if (is_matched) set_email_error(false);
		else set_email_error(true);
	};

	const _proceed = (e) => {
		e.preventDefault();

		const letter = /[a-z]/;
		const number = /[0-9]/;

		if (!letter.test(register_data.password.toLowerCase()) || !number.test(register_data.password) || register_data.password.legnth < 4) {
			set_show_error(true);
			return;
		}

		if (register_data.password === register_data.confirm_password) {
			const data = {
				first_name: register_data.first_name,
				last_name: register_data.last_name,
				email: register_data.email,
				above18_fair_use: true,
				password: register_data.password,
			};
			const access_token = token ? token : register_data.token;
			api_requests.update_profile(data, access_token).then((res) => {
				if (res.success) handle_signup_success({ ...data, id: res.data.id, token: access_token });
			});
		} else {
			toast.error('Password and Confirm Password should be same', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		}
	};

	const _onEnterMobile = (e) => {
		let val = e.target.value;
		if (val.length <= 10) {
			handle_data('mobile', val);
			if (val.length === 10) {
				api_requests.auth_status({ mobile: val }).then((res) => {
					if (res.data.is_password_login_enabled) {
						set_login(val);
					} else {
						_sendOTP(val);
						set_register_data({
							mobile: val,
							email: '',
							first_name: '',
							last_name: '',
						});
					}
				});
			}
		}
	};
	const confirm_details = () => {
		set_is_otp_verified(false);
		set_otp_failed(false);
		try {
			api_requests
				.verify_otp({
					mobile: mobile,
					otp,
					above18_fair_use: true,
				})
				.then((res) => {
					// set_token(res.access_token);
					set_register_data((prev) => ({ ...prev, token: res.access_token }));
					set_otp_failed(false);
					set_is_otp_verified(true);
					set_screen(SCREENS.register_step_2);
				})
				.catch((err) => {
					set_otp_failed(true);
				});
		} catch (err) {}
	};
	const classes = useStyles();

	const confirm_btn_disabled = useMemo(() => {
		let value = true;

		if (!_.isEmpty(register_data.password) && !_.isEmpty(register_data.confirm_password) && register_data.password === register_data.confirm_password)
			value = false;

		if (!is_forgot_password && !value) value = !is_checked;

		return value;
	}, [is_checked, register_data]);

	const confirm_details_btn_disabled = useMemo(() => {
		let value = true;
		if (
			!_.isEmpty(register_data.first_name) &&
			!_.isEmpty(register_data.last_name) &&
			!_.isEmpty(register_data.email) &&
			!_.isEmpty(register_data.mobile) &&
			!_.isEmpty(otp) &&
			otp.length == 4 &&
			!email_error
		)
			value = false;

		return value;
	}, [register_data, otp, email_error]);

	return (
		<>
			{screen === SCREENS.register_step_1 && (
				<form className={classes.form_style}>
					<div style={{ paddingLeft: '15%', paddingTop: '15%' }}>
						<Text medium style={{ fontSize: 40 }}>
							Create Account
						</Text>
						<Text semi className={classes.verify_text}>
							to continue to Pidge Dashboard
						</Text>

						<div style={{ display: 'flex', alignItems: 'center' }}>
							<OutlinedTextField
								value={register_data.first_name}
								name='first_name'
								onChange={(e) => {
									handle_data('first_name', e.target.value);
								}}
								label='First Name*'
								className={classes.root}
								style={{ width: 235, marginTop: 30, marginRight: 20 }}
							/>
							<OutlinedTextField
								value={register_data.last_name}
								name='last_name'
								onChange={(e) => {
									handle_data('last_name', e.target.value);
								}}
								label='Last Name*'
								className={classes.root}
								style={{ width: 235, marginTop: 30, marginRight: 20 }}
							/>
						</div>

						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div>
								<OutlinedTextField
									value={register_data.email}
									name='email'
									onBlur={(e) => {
										on_email_blur(e.target.value);
									}}
									onChange={(e) => {
										handle_data('email', e.target.value);
									}}
									label='Email ID*'
									placeholder='yourname@email.com'
									className={classes.root}
									style={{ width: 235, marginTop: 30, marginRight: 20 }}
									error={email_error}
									helperText={email_error ? 'Please enter a valid email id' : ''}
									FormHelperTextProps={{
										classes: {
											root: classes.helper_text,
										},
									}}
									InputProps={{
										endAdornment: email_error ? <img width={24} height={24} src={ImageLinks.exclamation_error} /> : null,
									}}
								/>
							</div>
							<div>
								<OutlinedTextField
									value={register_data.mobile}
									name='mobile'
									onChange={_onEnterMobile}
									label='Mobile Number*'
									className={classes.root}
									style={{ width: 235, marginTop: 30 }}
									InputProps={{
										startAdornment: (
											<Text medium style={{ width: 40, fontSize: 16, color: theme.colors.lightGrey2 }}>
												+91
											</Text>
										),
									}}
								/>
							</div>
						</div>

						<div style={{ marginTop: 30 }}>
							<OutlinedTextField
								type='number'
								value={otp}
								name='otp'
								onChange={on_enter_otp}
								inputProps={{ style: { letterSpacing: 6 } }}
								label='OTP*'
								className={classes.root}
								style={{ width: 235 }}
								error={otp_failed}
								helperText={
									<div className='align-items-center'>
										<Text style={{ fontSize: 12, color: theme.colors.darkGrey5 }}>
											{otp_failed ? 'Incorrect OTP.' : 'Didn’t get code?'}
										</Text>

										<Text
											component={'span'}
											onClick={timer > 0 ? () => {} : _sendOTP}
											semi
											className={classes.forgot_pass_text}
											style={timer === 0 ? { textDecoration: 'underline', color: theme.colors.primary } : {}}>
											Resend
										</Text>
										<Text
											component={'span'}
											className={classes.forgot_pass_text}
											style={timer === 0 ? { textDecoration: 'underline', color: theme.colors.primary } : {}}>
											{timer > 0 && `in ${timer} sec`}
										</Text>
									</div>
								}
								InputProps={{
									endAdornment: otp_failed ? (
										<img width={24} height={24} src={ImageLinks.exclamation_error} />
									) : is_otp_verified ? (
										<img width={24} height={24} src={ImageLinks.tickCircleSolid} />
									) : null,
								}}
								FormHelperTextProps={{
									classes: {
										root: classes.helper_text,
									},
								}}
							/>
						</div>

						<div className={classes.cta_btn_wrapper} style={{ marginTop: 20 }}>
							<Button
								text='Confirm'
								onClick={confirm_details}
								type='round'
								size='large'
								disabled={confirm_details_btn_disabled}
								right_icon={confirm_details_btn_disabled ? ImageLinks.right_arrow_gray : ImageLinks.arrow_up_white}
								right_image_styles={{ transform: `rotate(${confirm_details_btn_disabled ? 0 : 90}deg)` }}
							/>
						</div>
						<Footer show_help show_auth_nav={true} show_login={true} on_auth_nav={on_footer_auth_nav} />
					</div>
				</form>
			)}

			{screen === SCREENS.register_step_2 && (
				<form onSubmit={_proceed} className={classes.form_style}>
					<div style={{ paddingLeft: '15%', paddingTop: '15%' }}>
						<Text semi style={{ fontSize: 20 }}>
							{is_forgot_password ? 'Reset Password' : 'Create Password'}
						</Text>
						<div className='align-items-center' style={{ marginTop: 30 }}>
							<OutlinedTextField
								type='password'
								value={register_data.password}
								name='password'
								onBlur={(e) => {
									on_pass_blur(e.target.value);
								}}
								error={show_error}
								helperText={show_error ? 'Password must have 1 letter, 1 number and min 4 characters' : ''}
								InputProps={{
									endAdornment: show_error ? <img width={24} height={24} src={ImageLinks.exclamation_error} /> : null,
								}}
								onChange={(e) => {
									handle_data('password', e.target.value);
								}}
								inputProps={{ style: { letterSpacing: 6 } }}
								label='Create Password*'
								className={classes.root}
								style={{ width: 235, marginRight: 20 }}
								FormHelperTextProps={{
									classes: {
										root: classes.helper_text,
									},
								}}
							/>

							<OutlinedTextField
								type='password'
								value={register_data.confirm_password}
								name='confirm_password'
								onChange={(e) => {
									handle_data('confirm_password', e.target.value);
								}}
								inputProps={{ style: { letterSpacing: 6 } }}
								label='Confirm Password*'
								className={classes.root}
								style={{ width: 235 }}
							/>
						</div>

						{!is_forgot_password && (
							<div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
								<img
									onClick={() => {
										set_is_checked((prev) => !prev);
									}}
									src={is_checked ? ImageLinks.filledCheckbox : ImageLinks.emptyCheckbox}
									width='10'
									height='10'
									style={{ marginRight: 10 }}
								/>
								<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
									{`I accept to Pidge’s `}
									<Text
										component={'span'}
										bold
										style={{ fontSize: 10, textDecoration: 'underline', cursor: 'poineter' }}
										onClick={() => {
											window.open('https://pidge.in/terms-of-use/', '_blank');
										}}>
										Terms & Conditions
									</Text>
									<Text style={{ fontSize: 10 }} component={'span'}>{` and acknowledge that I have read the `}</Text>
									<Text
										component={'span'}
										bold
										style={{ fontSize: 10, textDecoration: 'underline', cursor: 'pointer' }}
										onClick={() => {
											window.open('https://pidge.in/privacy-policy/', '_blank');
										}}>
										Privacy Policy
									</Text>
								</Text>
							</div>
						)}
						<div className={classes.cta_btn_wrapper}>
							<ContainedButton type='submit' disabled={confirm_btn_disabled} className={classes.cta_btn}>
								<Text semi style={{ fontSize: 18, color: confirm_btn_disabled ? theme.colors.darkGrey2 : theme.colors.white }}>
									{is_forgot_password ? 'Confirm' : 'Get Started'}
								</Text>
								<img
									src={confirm_btn_disabled ? ImageLinks.right_arrow_gray : ImageLinks.arrow_up_white}
									width={16}
									height={16}
									style={{ marginLeft: 6, transform: `rotate(${confirm_btn_disabled ? 0 : 90}deg)` }}
								/>
							</ContainedButton>
						</div>
					</div>
				</form>
			)}
		</>
	);
};

export default Register;
