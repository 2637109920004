import ImageLinks from 'assets/images/ImageLinks';

export const subject_options = [
	{
		key: 'order',
		title: 'Order',
		icon: ImageLinks.box2_black,
		is_disabled: false,
	},
	{
		key: 'rider',
		title: 'Rider',
		icon: ImageLinks.rider_black,
		// is_disabled: true,
	},
	{
		key: 'business',
		title: 'Brand',
		icon: ImageLinks.bag_black,
		// is_disabled: true,
	},
	{
		key: 'pwp',
		title: 'PWP',
		icon: ImageLinks.route_z_black,
		// is_disabled: true,
	},
	{
		key: 'trace',
		title: 'Trace',
		icon: ImageLinks.rider_marker,
	},
];

export const template_options = [
	{
		title: 'Pidge Default',
		value: 'default',
	},
	{
		title: 'Custom',
		value: 'custom',
		disabled: true,
	},
];
