import { makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Routes from 'resources/Routes';
import api_requests from 'resources/api_requests';
import { useSelector } from 'react-redux';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	participant_list_item: {
		borderRadius: 6,
		boxShadow: `0px 0px 4px 0px rgba(196, 196, 196, 0.30)`,
		padding: 16,
		marginTop: 12,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	participant_id_item: {
		width: '50%',
		padding: 8,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	participant_id_sub_item: {
		display: 'inline-flex',
		alignItems: 'center',
		padding: 4,
		minHeight: 36,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
			borderRadius: 6,
			'& .delete_icon': {
				display: 'inline !important',
			},
		},
	},
	role_pill: {
		padding: '2px 8px',
		color: theme.colors.primary,
		marginLeft: 5,
		backgroundColor: theme.colors.lightPurple,
		borderRadius: 4,
	},
}));

function ParticipantList({ network_id, refresh, set_refresh, network_name, selected_network }) {
	const { business_accounts, selected_business_id: business_id_information } = useSelector(({ app }) => app);
	const selected_business_information = business_accounts.filter((business_account_info) => business_account_info.id === business_id_information)[0];
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();

	const [participant_list, set_participant_list] = useState([]);

	const get_participants_list = async () => {
		try {
			const res = await api_requests.get_participants(network_id);
			set_participant_list(res.data);
		} catch (err) {}
	};

	const handle_edit_click = (participant_item) => {
		history.push(
			Routes.INVITE_NETWORK.path.replace(':id', network_id) +
				`?name=${network_name}&brand_name=${participant_item.brand_name}&id=${participant_item.id}&business_id=${participant_item.business_id}&network_id=${selected_network.id}`,
			{
				can_lend_rider: participant_item.can_lend_rider,
				can_manage: participant_item.can_manage,
				can_place_order: participant_item.can_place_order,
			},
		);
	};
	useEffect(() => {
		get_participants_list();
	}, [network_id]);

	return (
		<div style={{ overflow: 'auto', height: '50vh' }}>
			<div className='d-flex justify-content-between align-items-center'>
				<Text bold style={{ fontSize: 14 }}>
					<Text component={'span'} bold style={{ fontSize: 14, color: theme.colors.primary }}>
						{participant_list.length}
					</Text>{' '}
					Participants
				</Text>
				{participant_list.length != 0 && selected_network.can_manage && (
					<Button
						text='Invite'
						type='round'
						size='extra_small'
						onClick={() => history.push(Routes.INVITE_NETWORK.path.replace(':id', network_id) + `?name=${network_name}`)}
						left_icon={ImageLinks.addWhite}
					/>
				)}
			</div>
			{participant_list.length == 0 && selected_network.can_manage && (
				<div className='d-flex justify-content-center align-items-center' style={{ flexDirection: 'column', marginTop: '20vh' }}>
					<img src={ImageLinks.no_riders} height={94} width={94} />
					<Text semi style={{ fontSize: 16 }}>
						Invite Participants to Network
					</Text>
					<Button
						text='Invite'
						type='round'
						size='extra_small'
						onClick={() => history.push(Routes.INVITE_NETWORK.path.replace(':id', network_id)) + `?name=${network_name}`}
						left_icon={ImageLinks.addWhite}
					/>
				</div>
			)}
			{_.map(participant_list, (participant_item, index) => {
				return (
					<div key={`participant_list_${index}`}>
						<div className={classes.participant_list_item}>
							<Text semi style={{ fontSize: 14, flex: 3 }}>
								{`${participant_item.brand_name} (${participant_item.business_id})`}
							</Text>
							{!participant_item.is_owner && (
								<div style={{ flex: 6 }}>
									{participant_item.can_manage && (
										<Text component={'span'} className={classes.role_pill} semi>
											Manage
										</Text>
									)}
									{participant_item.can_lend_rider && (
										<Text component={'span'} className={classes.role_pill} semi>
											Supply
										</Text>
									)}
									{participant_item.can_place_order && (
										<Text component={'span'} className={classes.role_pill} semi>
											Orders
										</Text>
									)}
								</div>
							)}
							{participant_item.is_owner && (
								<div style={{ flex: 6 }}>
									<Text
										component={'span'}
										className={classes.role_pill}
										semi
										style={{ color: theme.colors.white, backgroundColor: theme.colors.primary }}>
										Owner
									</Text>
								</div>
							)}
							<div style={{ flex: 1, cursor: 'pointer' }}>
								{((selected_network.is_owner && !participant_item.is_owner) ||
									(selected_network.can_manage &&
										participant_item.business_id == selected_business_information.id &&
										!participant_item.is_owner)) && (
									<img
										src={ImageLinks.edit}
										height={12}
										width={12}
										style={{ float: 'right' }}
										onClick={() => handle_edit_click(participant_item)}
									/>
								)}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default ParticipantList;
