import ImageLinks from 'assets/images/ImageLinks';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import utils from 'resources/utils';
import api_requests from 'resources/api_requests';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	wrapper: { padding: 12, borderRadius: 8, backgroundColor: theme.colors.lightPink4, display: 'flex', marginTop: 20 },
	stats_title: { fontSize: 12, colors: theme.colors.darkGrey3, marginTop: 2 },
	tooltip_title: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 10 },
}));

const RouteStats = ({ trace_entity_id = null, route_data, show_delete = false, handle_route_delete = () => {} }) => {
	const classes = useStyles();

	const handle_route_points_download = async () => {
		let routes = _.cloneDeep(route_data.points);
		if (_.isEmpty(routes)) {
			try {
				const response = await api_requests.get_route_item_list(trace_entity_id, route_data.route_id);
				if (_.get(response, 'data', []).length > 0) {
					routes = response.data[0]?.routes;
				}
			} catch (error) {}
		}
		let data = '"ref id", "poc name", "poc number", "address", "lat/long", "point type", "geofence", "sequence", "eta"';
		_.map(routes, (item) => {
			data +=
				'\n' +
				`"${_.get(item, 'ref_id', '')}"` +
				', ' +
				`"${_.get(item, 'poc.name', '')}"` +
				', ' +
				`"${_.get(item, 'poc.mobile', '')}"` +
				', ' +
				`"${_.get(item, 'address', '')}"` +
				', ' +
				`"${_.get(item, 'latitude', '')}, ${_.get(item, 'longitude', '')}"` +
				', ' +
				`"${_.get(item, 'point_type', '') === 1 ? 'pickup' : 'drop'}"` +
				', ' +
				`"${_.get(item, 'geofence', '')}"` +
				', ' +
				`"${_.get(item, 'sequence', '') ? item.sequence : ''}"` +
				', ' +
				`${_.get(item, 'eta', '') ? `"${moment(item.eta).format('YYYY-MM-DD HH:mm:ss')}"` : ''}`;
		});

		utils.downloadBlob(data, 'route.csv', 'text/csv');
	};

	const distance = _.get(route_data, 'distance', 0) / 1000;
	const buffer = _.get(route_data, 'buffer', 0);
	const wait_time = _.get(route_data, 'wait_time', 0);
	const time = _.get(route_data, 'time', 0) / 60;
	const total_points = _.get(route_data, 'pickup_cnt', 0) + _.get(route_data, 'drop_cnt', 0);
	const distance_with_buffer = buffer * distance * 0.01 + distance;
	const total_wait_time = total_points * wait_time;
	const time_with_wait = time + total_wait_time;

	return (
		<div className={classes.wrapper}>
			<div style={{ flex: 0.4 }}>
				<Text semi style={{ fontSize: 12 }}>
					Route Summary
				</Text>
				<div className='align-items-center' style={{ marginTop: 9, gap: 5 }}>
					<Text semi style={{ fontSize: 12 }}>
						filename.csv
					</Text>
					{show_delete && <img className='cursor-pointer' onClick={handle_route_delete} src={ImageLinks.delete} width={16} height={16} />}
					<img className='cursor-pointer' onClick={handle_route_points_download} src={ImageLinks.download_solid_purple} width={16} height={16} />
				</div>
			</div>
			<div className='align-items-center' style={{ flex: 0.6 }}>
				<div style={{ flex: 0.2 }}>
					<Text bold style={{ fontSize: 16 }}>
						{_.get(route_data, 'pickup_cnt', 0)}
					</Text>
					<Text medium className={classes.stats_title}>
						Pickup
					</Text>
				</div>
				<div style={{ flex: 0.2 }}>
					<Text bold style={{ fontSize: 16 }}>
						{_.get(route_data, 'drop_cnt', 0)}
					</Text>
					<Text medium className={classes.stats_title}>
						Drop
					</Text>
				</div>
				<div style={{ flex: 0.3 }}>
					<BootstrapTooltip
						is_white={true}
						placement='top'
						title={
							<div>
								<Text semi style={{ fontSize: 12, color: theme.colors.black }}>
									{(distance * buffer * 0.01).toFixed(1)}Km ({buffer}%)
								</Text>
								<Text medium className={classes.tooltip_title}>
									Buffer
								</Text>
							</div>
						}>
						<div>
							<Text bold style={{ fontSize: 16 }}>
								{distance_with_buffer.toFixed(1)} Km
							</Text>
							<Text medium className={classes.stats_title}>
								Distance
							</Text>
						</div>
					</BootstrapTooltip>
				</div>
				<div style={{ flex: 0.3 }}>
					<BootstrapTooltip
						is_white={true}
						placement='top'
						title={
							<div>
								<Text semi style={{ fontSize: 12, color: theme.colors.black }}>
									{total_wait_time.toFixed(1)}min ({((total_wait_time * 100) / time_with_wait).toFixed(1)}%)
								</Text>
								<Text medium className={classes.tooltip_title}>
									Wait Time
								</Text>
							</div>
						}>
						<div>
							<Text bold style={{ fontSize: 16 }}>
								{time_with_wait.toFixed(1)}min
							</Text>
							<Text medium className={classes.stats_title}>
								Time
							</Text>
						</div>
					</BootstrapTooltip>
				</div>
			</div>
		</div>
	);
};

export default RouteStats;
