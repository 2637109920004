import React from 'react';
import { FormControl, FormControlLabel, ListItem, Radio, RadioGroup, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 18, marginBottom: 10 },
	sub_heading: { color: theme.colors.darkGrey2 },
	info_container: { width: 340, backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, minHeight: '70vh', flex: 0.8 },
	geo_fence: { marginTop: 30, fontSize: 16, marginBottom: 5 },
	tab_section: { marginTop: 20, marginBottom: 20, display: 'flex' },
	mapContainer: { height: '360px', width: '100%', marginTop: 30, paddingBottom: 10 },
	width_url: { width: 100, marginLeft: 10, marginTop: 5 },
	update: { position: 'absolute', bottom: 20, right: 30, width: 110 },
	switch_flex: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	list_item: { border: '1px solid #492897', marginRight: 10, width: 90, borderRadius: 30, display: 'flex', justifyContent: 'center' },
	tips: {
		backgroundColor: theme.colors.lightPurple,
		marginRight: 0,
		width: 80,
		borderRadius: 5,
		display: 'flex',
		justifyContent: 'center',
		padding: '8px 8px',
	},
	radio_subtitle: { fontSize: 12, marginLeft: 28, marginTop: -10, color: theme.colors.darkGrey2 },
}));

const Reprioritising = ({ reprioritization = null, handle_update_settings }) => {
	const classes = useStyles();
	const [selected_menu_value, set_selected_menu_value] = React.useState(reprioritization ? `${reprioritization}` : null);

	return (
		<div>
			<div className={classes.heading_container}>
				<Text bold className={classes.heading}>
					Task Reprioritizing
				</Text>
				<ListItem key={`tips`} button onClick={() => {}} className={classes.tips}>
					<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
						Tips & Info
					</Text>
				</ListItem>
			</div>

			<div style={{ display: 'flex', position: 'relative' }}>
				<div style={{ marginRight: 20, flex: '1' }}>
					<Text semi className={classes.sub_heading} style={{ fontSize: 14 }}>
						This feature allows your riders to reorganize their drop and pickup tasks themselves through the delivery executive application. Use
						this feature to give your riders the ability to choose the sequence of their tasks.
					</Text>

					<FormControl sx={{ marginTop: 20 }}>
						<RadioGroup
							value={selected_menu_value}
							onChange={(e) => {
								utils.track_event_for_analytics(`reprioritising_${e.target.value}`);
								set_selected_menu_value(e.target.value);
							}}>
							<FormControlLabel
								value={'3'}
								style={{ marginTop: 20 }}
								control={<Radio />}
								label={
									<Text style={{ fontSize: 14 }} semi>
										Prioritize Single Order
									</Text>
								}
							/>
							<Text className={classes.radio_subtitle}>Rider can make any order highest priority at any point</Text>
							<FormControlLabel
								value={'2'}
								style={{ marginTop: 20 }}
								control={<Radio />}
								label={
									<Text style={{ fontSize: 14 }} semi>
										Prioritize within Range
									</Text>
								}
							/>
							<Text className={classes.radio_subtitle}>Rider can prioritize any order if they are within 3km of address geolocation</Text>
							<FormControlLabel
								value={'1'}
								style={{ marginTop: 20 }}
								control={<Radio />}
								label={
									<Text style={{ fontSize: 14 }} semi>
										No Reprioritization
									</Text>
								}
							/>
							<Text className={classes.radio_subtitle}>Rider cannot rearrange any orders</Text>
						</RadioGroup>
					</FormControl>
				</div>
				<div className={classes.info_container}>
					<Text bold style={{ fontSize: 18, color: theme.colors.lightPurple7 }}>
						What is reprioritisation of tasks?
					</Text>
					<Text regular style={{ fontSize: 12 }}>
						Reprioritization allows delivery executives to use their on-ground knowledge and circumstances (such as road blockages or weather
						related issues) to optimally deliver. Usage logs will be tracked if needed later.
					</Text>

					<Text bold style={{ fontSize: 18, color: theme.colors.lightPurple7, marginTop: 20 }}>
						How can the rider access this feature?
					</Text>
					<Text regular style={{ fontSize: 12 }}>
						In the executive app, the rider must go to all tasks page, at the bottom of the screen, Arrange Drop button activates the
						reprioritization feature. If feature is disabled, rider will not see the arrange drop button.
					</Text>
					<div className='d-flex justify-content-center' style={{ marginTop: 50 }}>
						<img src={ImageLinks.reprior_tips} width={274} height={287} style={{ margin: 'auto' }}></img>
					</div>
				</div>
			</div>
			<Button
				disabled={selected_menu_value === null || selected_menu_value == reprioritization}
				className={classes.update}
				onClick={() => {
					handle_update_settings({ reprioritization: Number(selected_menu_value) });
				}}
				type='round'
				text='Save'
				size='large'
			/>
		</div>
	);
};

export default Reprioritising;
