import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api_requests from 'resources/api_requests';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { hide_loader, show_loader } from 'actions/app';

const notification_types = {
	ORDER_STATUS: 'ORDER_STATUS',
};

const useNotifListing = (user_teams, notif_tab) => {
	const { is_loading } = useSelector((state) => state.app, shallowEqual);
	const dispatch = useDispatch();

	const [selected_tab, set_selected_tab] = useState(notif_tab !== null && notif_tab >= 0 ? notif_tab : 0);
	const [show_cdr_modal, set_show_cdr_modal] = useState(false);
	const [cdr_order_id, set_cdr_order_id] = useState(false);
	const [export_loading, set_export_loading] = useState(false);
	const [export_status, set_export_status] = useState(null);
	const [notif_list, set_notif_list] = useState([]);
	const [alert_list, set_alert_list] = useState([]);
	const [show_rider_modal, set_show_rider_modal] = useState(false);
	const [rider_details, set_rider_details] = useState(null);
	const [list_data, set_list_data] = useState({});
	const slider_ref = useRef();
	const loading_scroll = useRef(false);

	const history = useHistory();

	useEffect(() => {
		get_notif_list({});
	}, [selected_tab]);

	const get_notif_list = async ({ token }) => {
		dispatch(show_loader());
		try {
			const team_ids = _.map(user_teams, (item) => `team_ch_${item.team_id}`);
			let channel = team_ids.join(',');
			const response = await api_requests.get_notification_list({
				rooms: channel,
				event: selected_tab === 0 ? 'alert' : 'notification',
				...(token && { token }),
			});
			if (_.get(response, 'items', [])) {
				loading_scroll.current = false;
				set_list_data(response);
				dispatch(hide_loader());
				if (!token) {
					selected_tab === 0 ? set_alert_list(response.items) : set_notif_list(response.items);
				} else {
					selected_tab === 0 ? set_alert_list(_.concat(alert_list, response.items)) : set_notif_list(_.concat(notif_list, response.items));
				}
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	useEffect(() => {
		if (!_.isEmpty(export_status)) {
			setTimeout(() => {
				set_export_status(null);
			}, 3000);
		}
	}, [export_status]);

	const notification_config = {
		ORDER_STATUS: {
			awb: {
				is_clickable_handler: () => true,
				handler: (notify_item, key) => awb_handler(notify_item, key),
			},
			status: {
				is_clickable_handler: () => false,
			},
			originator: {
				is_clickable_handler: () => false,
			},
			tracking_link: {
				is_clickable_handler: () => true,
				handler: (notify_item, key) => tracking_link_handler(notify_item, key),
			},
			reference_id: {
				is_clickable_handler: (notify_item) => order_ref_click_handler(notify_item),
				handler: (notify_item, key) => cdr_handler(notify_item),
			},
			rider_name: {
				is_clickable_handler: (notify_item) => rider_ref_click_handler(notify_item),
				handler: (notify_item, key) => rider_handler(notify_item),
			},
		},
		RIDER_LOGIN: {
			rider_name: {
				is_clickable_handler: (notify_item) => rider_ref_click_handler(notify_item),
				handler: (notify_item, key) => rider_handler(notify_item),
			},
			rider_id: {
				is_clickable_handler: () => false,
			},
			late_login_by: {
				is_clickable_handler: () => false,
			},
		},
		WALLET_BALANCE: {
			brand_name: {
				is_clickable_handler: () => false,
			},
			business_id: {
				is_clickable_handler: () => false,
			},
			limit: {
				is_clickable_handler: () => false,
			},
			balance: {
				is_clickable_handler: () => false,
			},
		},
		ORDER_NOT_DELIVERED_IN_SLOT: {
			reference_id: {
				is_clickable_handler: (notify_item) => order_ref_click_handler(notify_item),
				handler: (notify_item, key) => cdr_handler(notify_item),
			},
		},
		RIDER_TASK_REPRIORITIZATION: {
			rider_name: {
				is_clickable_handler: (notify_item) => rider_ref_click_handler(notify_item),
				handler: (notify_item, key) => rider_handler(notify_item),
			},
		},
		RIDER_NO_REMAINING_TASK: {
			rider_name: {
				is_clickable_handler: (notify_item) => rider_ref_click_handler(notify_item),
				handler: (notify_item, key) => rider_handler(notify_item),
			},
		},
		TASK_NOT_ACCEPTED: {
			rider_name: {
				is_clickable_handler: (notify_item) => rider_ref_click_handler(notify_item),
				handler: (notify_item, key) => rider_handler(notify_item),
			},
		},
	};

	const order_ref_click_handler = (notify_item) => {
		const ref_id = _.get(notify_item, 'variables.reference_id', null);
		const cdr_id = _.get(notify_item, 'variables.cdr_id', null);
		return !_.isEmpty(ref_id) && !_.isEmpty(cdr_id);
	};

	const rider_ref_click_handler = (notify_item) => {
		const rider_id = _.get(notify_item, 'variables.rider_id', null);
		const rider_name = _.get(notify_item, 'variables.rider_name', null);
		return !_.isEmpty(rider_name) && rider_id > 0;
	};

	const awb_handler = (notify_item, key) => {
		const service = _.get(notify_item, 'variables.service', null);
		if (service === null) return;
		set_show_cdr_modal(false);
		if (service === 'captive' || service === 'pidge') {
			// set_show_cdr_modal(false);
			// set_show_order_details(true);
			// set_selected_pbid(notify_item.variables[key]);
		} else {
			// set_show_order_details(false);
			// set_show_cdr_modal(true);
			// set_cdr_order_id(notify_item.variables[key]);
		}
		return;
	};

	const cdr_handler = (notify_item) => {
		const cdr_id = _.get(notify_item, 'variables.cdr_id', null);
		// set_show_order_details(false);
		if (cdr_id) {
			set_show_cdr_modal(true);
			set_cdr_order_id(cdr_id);
		}
		return;
	};

	const rider_handler = async (notify_item) => {
		const rider_id = _.get(notify_item, 'variables.rider_id', null);
		if (rider_id) {
			const response = await api_requests.get_rider_details(rider_id);
			if (response.success) {
				set_rider_details(response.data);
				set_show_rider_modal(true);
			}
		}
		return;
	};

	const tracking_link_handler = (notify_item, key) => {
		window.open(notify_item.variables[key], '_blank');
	};

	const handle_report_download = () => {
		set_export_loading(true);
		setTimeout(() => {
			set_export_loading(false);
			set_export_status('success');
		}, 3000);
	};

	const trigger_manual_action = async (alertId, action) => {
		try {
			const response = await api_requests.alert_notif_action(alertId, action);
			if (response.success) {
				const filtered = _.filter(alert_list, (item) => item.data.id != alertId);
				set_alert_list(filtered);
			}
		} catch (error) {}
	};

	const handle_rider_close = () => {
		set_show_rider_modal(false);
		set_rider_details(null);
	};

	const slider_scrolled = () => {
		if (
			slider_ref.current.scrollTop + slider_ref.current.clientHeight + 150 >= slider_ref.current.scrollHeight &&
			list_data.next &&
			!loading_scroll.current
		) {
			loading_scroll.current = true;
			get_notif_list({ token: list_data.next });
		}
	};

	return {
		awb_handler,
		tracking_link_handler,
		notification_config,
		show_cdr_modal,
		set_show_cdr_modal,
		cdr_order_id,
		selected_tab,
		set_selected_tab,
		handle_report_download,
		export_loading,
		export_status,
		alert_list,
		notif_list,
		trigger_manual_action,
		show_rider_modal,
		set_show_rider_modal,
		rider_details,
		set_rider_details,
		handle_rider_close,
		slider_ref,
		slider_scrolled,
		is_loading,
	};
};

export default useNotifListing;
