import { Divider, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import { hide_loader, show_loader } from 'actions/app';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Text from 'modules/Text';
import React, { useEffect, useRef, useState } from 'react';
import AddVehicleModal from './components/AddVehicleModal/AddVehicleModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import qs from 'qs';
import _ from 'lodash';
import moment from 'moment';

import api_requests from 'resources/api_requests';
import FleetListing from './components/FleetListing';
import AssignmentModal from './components/AssignmentModal/AssignmentModal';
import FleetDetails from './components/FleetDetails';
import Toast from 'modules/Toast';
import FilterButton from 'modules/FilterButton';
import utils from 'resources/utils';
import Filters from 'modules/Filters/Filters';
import Routes from 'resources/Routes';
import FleetOverviewAnalytics from './components/FleetOverviewAnalytics/FleetOverviewAnalytics';
import Button from 'modules/Button';
import EmptyState from 'modules/EmptyState';
import Helpcenter from 'modules/HelpCenter';
import NoResult from 'modules/NoResult';

const report_filters = [
	{
		multi: true,
		param: 'date',
		title: 'Assignment Start Date',
		type: 'date',
	},
];

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 32,
		minHeight: '100vh',
		overflow: 'hidden',
	},
	new_button: {
		borderRadius: 30,
	},
	img_btn_wrap: {
		position: 'absolute',
		bottom: 20,
		left: 20,
		zIndex: 1000,
	},
	active_button: {
		padding: '8px 16px',
		backgroundColor: theme.colors.primary,
		color: theme.colors.white,
		borderRadius: 8,
		cursor: 'pointer',
	},
	inactive_button: {
		padding: '8px 16px',
		color: theme.colors.lightPurple6,
		borderRadius: 8,
		cursor: 'pointer',
	},
	list_wrap: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		width: '48%',
		maxHeight: '80vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	empty_content: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '18%',
	},
	wrapper_empty_state_no_result: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 30,
		marginTop: '18%',
	},
}));

function FleetManagement() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const filter_object = qs.parse(history.location.search.slice(1)) || {};
	const [show_filters, set_show_filters] = useState(false);
	const [all_filters, set_all_filters] = useState([]);
	const [report_items, set_report_items] = React.useState([]);
	const [show_add_vehicle, set_show_add_vehicle] = useState(false);
	const [active_tab, set_active_tab] = useState('total');
	const [vehicle_list, set_vehicle_list] = useState([]);
	const [count, set_count] = useState(0);
	const [assignment_data, set_assignment_data] = useState({});
	const [selected_vehicle, set_selected_vehicle] = useState({});
	const [edit_vehicle, set_edit_vehicle] = useState({});
	const [edit_assignment_detail, set_edit_assignment_detail] = useState({});
	const [show_toast, set_show_toast] = useState(false);
	const [fleet, set_fleet] = useState({});
	const [fleet_status, set_fleet_status] = useState('');
	const slider_ref = useRef(null);
	const loading_scroll = useRef(false);

	const { is_loading } = useSelector((state) => state.app, shallowEqual);

	useEffect(() => {
		set_selected_vehicle({});
		get_vehicle_list({});
	}, [active_tab, JSON.stringify(filter_object)]);

	useEffect(() => {
		get_filter_list();
		get_filter_export_items();
	}, []);

	const get_vehicle_list = async ({ token, limit = 50 }) => {
		dispatch(show_loader());
		const params = active_tab == 'active' ? { active: true } : active_tab == 'inactive' ? { active: false } : {};
		const res = await api_requests.get_fleet_vehicle_list({ ...params, ...filter_object, ...(token && { token }) });
		if (res.data.result) {
			let fleet_data = res.data.result.data;
			set_fleet(res.data.result);
			loading_scroll.current = false;
			if (token) {
				set_vehicle_list(_.concat(vehicle_list, fleet_data));
			} else {
				set_vehicle_list(fleet_data);
				set_count(res.data.result.count);
			}
			dispatch(hide_loader());
		}
	};

	const get_filter_list = async () => {
		try {
			const res = await api_requests.get_fleet_filter_items();
			set_all_filters(res.data);
		} catch (err) {}
	};

	const get_filter_export_items = async () => {
		try {
			const res = await api_requests.get_fleet_export_items();
			if (res.data.length) {
				set_report_items(res.data);
			}
		} catch (err) {}
	};

	const handle_export = async (selected_filters, callback) => {
		try {
			const response = await api_requests.export_fleet(selected_filters);
			utils.downloadBlob(response, 'fleet.csv', 'text/csv');
			callback(true);
		} catch (err) {
			callback(false);
		}
	};

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && fleet.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_vehicle_list({ token: fleet.next });
		}
	};

	const handle_click_fleet = (vehicle, title) => {
		set_selected_vehicle(vehicle);
		set_fleet_status(title);
	};

	return (
		<div className={classes.container}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Text semi className='global_header_text'>
					Fleet Management
				</Text>
				<div className='d-flex align-items-center justify-content-center' style={{ gap: 10 }}>
					{/* {count > 0 && (
						<OutlinedButton>
							<img src={ImageLinks.track_filled_purple} style={{ marginRight: 5 }} />
							<Text semi>Track</Text>
						</OutlinedButton>
					)} */}
					{(count > 0 || Object.keys(filter_object).length !== 0) && (
						<Button type='round' text='New Vehicle' left_icon={ImageLinks.addWhite} size='large' onClick={() => set_show_add_vehicle(true)} />
					)}
				</div>
			</div>
			{(count > 0 || Object.keys(filter_object).length !== 0) && (
				<div style={{ marginTop: 32 }}>
					<div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
						<div className='d-flex' style={{ height: 35 }}>
							<div className={active_tab == 'total' ? classes.active_button : classes.inactive_button} onClick={() => set_selected_vehicle({})}>
								<Text bold>Total ({count})</Text>
							</div>
						</div>
						<FilterButton handle_click={() => set_show_filters(true)} total_filters={Object.keys(filter_object).length} />
					</div>

					<Divider style={{ margin: '0px 0 10px 0' }} />
					<div className='d-flex' style={{ gap: 30 }}>
						{vehicle_list.length > 0 && (
							<div className={classes.list_wrap} ref={slider_ref} onScroll={slider_scrolled}>
								<FleetListing
									set_edit_assignment_detail={set_edit_assignment_detail}
									selected_vehicle={selected_vehicle}
									set_assignment_data={set_assignment_data}
									vehicle_list={vehicle_list}
									handle_click_fleet={handle_click_fleet}
								/>
							</div>
						)}
						{!_.isEmpty(selected_vehicle) && (
							<FleetDetails
								set_edit_vehicle={set_edit_vehicle}
								set_assignment_data={set_assignment_data}
								selected_vehicle={selected_vehicle}
								set_edit_assignment_detail={set_edit_assignment_detail}
								vehicle_list={vehicle_list}
								fleet_status={fleet_status}
								get_vehicle_list={get_vehicle_list}
							/>
						)}
						{_.isEmpty(selected_vehicle) && vehicle_list.length > 0 && (
							<FleetOverviewAnalytics selected_vehicle={selected_vehicle} total_vehicle={count} />
						)}
					</div>
				</div>
			)}
			{count == 0 && !is_loading && Object.keys(filter_object).length == 0 && (
				<div className={classes.empty_content}>
					<EmptyState
						button_text='New Vehicle'
						left_icon={ImageLinks.addWhite}
						empty_img={ImageLinks.fleet_empty_state}
						onClick={() => set_show_add_vehicle(true)}
						button_style={{ left: 28 }}
					/>
					{/* <Helpcenter text={'How do I create a Vehicle ?'} style={{ marginTop: 40 }} /> */}
				</div>
			)}
			{count == 0 && !is_loading && Object.keys(filter_object).length != 0 && (
				<div className={classes.wrapper_empty_state_no_result}>
					<NoResult text='No vehicle found' btn_onclick={() => history.replace({ search: '' })} />
				</div>
			)}
			{(show_add_vehicle || !_.isEmpty(edit_vehicle)) && (
				<AddVehicleModal
					set_show_toast={set_show_toast}
					edit_vehicle={edit_vehicle}
					close={() => {
						set_show_add_vehicle(false);
						set_edit_vehicle({});
					}}
					get_vehicle_list={get_vehicle_list}
					set_selected_vehicle={set_selected_vehicle}
				/>
			)}
			{!_.isEmpty(assignment_data) && (
				<AssignmentModal
					get_vehicle_list={get_vehicle_list}
					close={() => set_assignment_data({})}
					assignment_data={assignment_data}
					edit_assignment_detail={edit_assignment_detail}
					set_show_toast={set_show_toast}
				/>
			)}
			{show_toast && (
				<Toast close_callback={() => set_show_toast(false)}>
					<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
					<Text component={'span'} semi style={{ marginLeft: '10px', fontSize: 14 }}>
						{show_toast == 'vehicle'
							? 'New Vehicle created successfully!'
							: show_toast == 'assignment'
							? 'Vehicle has been updated successfully!'
							: ' Vehicle details has been update successfully!'}
					</Text>
				</Toast>
			)}
			{show_filters && (
				<Filters
					existing_filters={filter_object}
					all_filters={all_filters}
					set_selected_filters={(filters) => {
						history.push(Routes.FLEET_MANAGEMENT.path + `?` + qs.stringify(filters));
					}}
					close={() => set_show_filters(false)}
					handle_export={handle_export}
					report_items={report_items}
					is_report_enabled={true}
					report_filters={report_filters}
					title='Fleet'
					is_report_item_single_select={true}
					filter_from='fleet'
					skip_date_check={true}
					date_check_array={['vehicle_performance']}
				/>
			)}
		</div>
	);
}

export default FleetManagement;
