import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import constant from 'resources/constant';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import { makeStyles } from '@material-ui/core';
import MapPolygonUtils from 'resources/MapPolygonUtils';
import { useMemo } from 'react';

let active_zone_markers;
const useStyles = makeStyles({
	wrapper: { flex: 1, display: 'flex', columnGap: '3%' },
	left_half_wrap: { flex: 1, display: 'flex', flexDirection: 'column', padding: '25px 0px' },

	right_half_wrap: { flex: 1, padding: 40, position: 'relative' },
	map_btn_wrap: { position: 'absolute', zIndex: 100, top: 50, left: '40%', alignSelf: 'center' },
	edit_coord_btn: {
		border: `1px solid ${theme.colors.primary}`,
		borderRadius: 30,
		padding: '5px 7px',
		boxShadow: '0px 0px 16px rgba(102, 102, 102, 0.1)',
		minWidth: 149,
	},
	save_coord_btn: {
		border: `1px solid ${theme.colors.green}`,
		borderRadius: 30,
		padding: '5px 7px',
		boxShadow: '0px 0px 16px rgba(102, 102, 102, 0.1)',
		marginLeft: 12,
		width: 80,
	},
});

const EditBusinessZone = ({ selected_master_zone, selected_business_zone, on_success }) => {
	const [buffer_distance, set_buffer_distance] = useState(selected_business_zone.buffer_distance);
	const [name, set_name] = useState(selected_business_zone.title);
	const [in_edit_mode, set_in_edit_mode] = useState(false);
	const [is_poly_updated, set_is_poly_updated] = useState(false);
	const [active_zones, set_active_zones] = useState([]);
	const map = useRef(null);
	const polygon_util_ref = useRef(null);
	const [saved_press, set_saved_press] = useState(false);
	const [show_active_zones, set_show_active_zones] = useState(false);
	const classes = useStyles();

	const initialize = () => {
		if (!map.current) {
			map.current = new window.google.maps.Map(document.getElementById('map_canvas'), {
				center: { lat: selected_business_zone.focal.coordinates[0], lng: selected_business_zone.focal.coordinates[1] },
				zoom: 10,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});

			const master_zone_coords = _.map(_.get(selected_master_zone, 'geometry.coordinates[0]', []), (coord) => {
				return { lat: coord[0], lng: coord[1] };
			});

			// Construct the polygon.
			const polygon = new window.google.maps.Polygon({
				paths: master_zone_coords,
				strokeColor: theme.colors.primary,
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillOpacity: 0,
			});

			polygon.setMap(map.current);

			polygon_util_ref.current = new MapPolygonUtils(
				map.current,
				() => {
					set_is_poly_updated(true);
				},
				() => {},
			);
			const polygon_coords = _.map(_.get(selected_business_zone, 'geometry.coordinates[0]', []), (coord) => {
				return { lat: coord[0], lng: coord[1] };
			});
			polygon_util_ref.current.draw_polygon_from_existing_coords(polygon_coords, selected_business_zone);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
		get_active_zones();
		return () => {
			polygon_util_ref.current.deallocate_data_and_listeners();
		};
	}, []);

	const get_active_zones = async () => {
		try {
			const response = await api_requests.get_sub_zones_geometry(selected_master_zone.id);
			if (response.success) {
				const _active_zones = _.map(response.data, (data) => {
					return {
						...data,
						color: theme.colors.darkGrey2,
					};
				});
				const filtered = _.filter(_active_zones, (item) => item.id != selected_business_zone.id);
				set_active_zones(filtered);
			}
		} catch (error) {}
	};

	useEffect(() => {
		if (saved_press) {
			setTimeout(() => {
				set_saved_press(false);
				set_is_poly_updated(false);
			}, 1500);
		}
	}, [saved_press]);

	const enable_edit_map = () => {
		set_in_edit_mode((prev) => !prev);
		polygon_util_ref.current.update_polygon_options({
			editable: !in_edit_mode,
		});
	};

	const save_updated_poly = () => {
		if (saved_press) return;
		set_in_edit_mode(false);
		set_saved_press(true);
		polygon_util_ref.current.handle_save_polygon(selected_business_zone);
		return;
	};

	const handle_update = async () => {
		const coordinates = [];
		const lat_lngs = polygon_util_ref.current.get_polygon_path();
		for (let i = 0; i < lat_lngs.length; i++) {
			coordinates.push([lat_lngs.getAt(i).lat(), lat_lngs.getAt(i).lng()]);
		}
		const first_coordinate = coordinates[0];
		const last_coordinate = coordinates[coordinates.length - 1];
		if (first_coordinate[0] != last_coordinate[0] || first_coordinate[1] != last_coordinate[1]) coordinates.push(first_coordinate);

		try {
			const response = await api_requests.edit_business_zone(selected_master_zone.id, selected_business_zone.id, {
				title: name,
				geometry: {
					type: 'Polygon',
					coordinates: [coordinates],
				},
				buffer_distance: Number(buffer_distance),
			});
			if (response.success) {
				on_success();
				toast.success(response.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			}
		} catch (error) {}
	};

	const show_hide_active_zones = () => {
		if (!map.current) {
			return;
		}

		if (!show_active_zones) {
			active_zone_markers = _.map(active_zones, (zone) => {
				const polygon_coords = _.map(_.get(zone, 'geometry.coordinates[0]', []), (coord) => {
					return { lat: coord[0], lng: coord[1] };
				});

				// Construct the polygon.
				const polygon = new window.google.maps.Polygon({
					paths: polygon_coords,
					strokeColor: zone.color,
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: zone.color,
					fillOpacity: 0.35,
				});

				polygon.setMap(map.current);

				return polygon;
			});
		} else {
			_.map(active_zone_markers, (marker) => {
				marker.setMap(null);
			});
		}

		set_show_active_zones(!show_active_zones);
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		if (!_.isEmpty(name) && buffer_distance != '') value = false;
		return value;
	}, [name, buffer_distance]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.left_half_wrap}>
				<div style={{ flex: 1 }}>
					<Text bold style={{ fontSize: 18 }}>
						Edit {selected_business_zone.title} in {selected_business_zone.title}
					</Text>

					<div style={{ marginTop: 16, display: 'flex' }}>
						<div>
							<Text bold style={{ fontSize: 18 }}>
								Master Zone Name
							</Text>
							<UndlerlineTextField
								style={{ width: 200, marginTop: 10 }}
								placeholder='Enter Name'
								value={name}
								label='Master Zone Name*'
								onChange={(e) => set_name(e.target.value)}
								// InputProps={{
								// 	endAdornment: <img width={16} height={20} src={ImageLinks.edit_filled_light_purple} />,
								// }}
							/>
						</div>
						<div style={{ marginLeft: 12 }}>
							<UndlerlineTextField
								style={{ width: 200, marginTop: 35.5 }}
								type='number'
								placeholder='Enter distance'
								value={buffer_distance}
								label='Buffer Distance (m)*'
								onChange={(e) => set_buffer_distance(e.target.value)}
								// InputProps={{
								// 	endAdornment: <img width={16} height={20} src={ImageLinks.edit_filled_light_purple} />,
								// }}
								helperText={
									<div className='d-flex'>
										<img src={ImageLinks.info_light_purple} width={10} height={10} style={{ marginTop: 4, marginRight: 4 }} />
										<Text medium style={{ fontSize: 10, color: theme.colors.lightGrey2 }}>
											Area that can be define outside the zone boundary
										</Text>
									</div>
								}
							/>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<ContainedButton disabled={btn_disabled} onClick={handle_update} style={{ width: 260, borderRadius: 30, height: 39, marginRight: 12 }}>
						<Text semi style={{ fontSize: 18, color: btn_disabled ? theme.colors.darkGrey2 : theme.colors.white }}>
							Update Zone
						</Text>
					</ContainedButton>

					<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
						{`Please note: `}
						<Text medium component='span' style={{ fontSize: 12 }}>
							Any past orders will not be updated for new zones. All assets (riders, saved add, warehouses etc) will be re-run against updated
							zone boundaries
						</Text>
					</Text>
				</div>
			</div>
			<div className={classes.right_half_wrap}>
				<div className={classes.map_btn_wrap}>
					<ContainedButton
						onClick={enable_edit_map}
						className={classes.edit_coord_btn}
						style={{
							background: in_edit_mode ? theme.colors.white : theme.colors.lightPurple,
						}}>
						<img src={ImageLinks.edit_purple} width={16} height={16} alt='edit_map_pencil' />
						<Text semi style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 14 }}>
							Edit Coordinates
						</Text>
						{in_edit_mode && <img src={ImageLinks.crossBlack} width={12} height={12} style={{ marginLeft: 4 }} />}
					</ContainedButton>

					{is_poly_updated && (
						<ContainedButton
							onClick={save_updated_poly}
							className={classes.save_coord_btn}
							style={{ backgroundColor: saved_press ? theme.colors.green : theme.colors.lightGreen6 }}>
							<img src={saved_press ? ImageLinks.check : ImageLinks.tickCircleSolid} width={16} height={16} alt='tick_solid_green' />
							<Text semi style={{ marginLeft: 4, fontSize: 14, color: saved_press ? theme.colors.white : theme.colors.black }}>
								{saved_press ? 'Saved' : 'Save'}
							</Text>
						</ContainedButton>
					)}
				</div>
				<ContainedButton
					onClick={show_hide_active_zones}
					style={{
						background: theme.colors.lightPurple,
						position: 'absolute',
						right: 50,
						top: 50,
						zIndex: 100,
						padding: '5px 7px',
						borderRadius: 8,
					}}>
					<img src={show_active_zones ? ImageLinks.eye_open : ImageLinks.eye_close} width='14' alt='' />
					<Text bold style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 10 }}>
						Active Zones
					</Text>
				</ContainedButton>
				<div
					id='map_canvas'
					className='map-canvas-wrapper rider-location card'
					style={{ height: '100%', borderRadius: 20, background: theme.colors.lightGrey3 }}></div>
			</div>
		</div>
	);
};

export default EditBusinessZone;
