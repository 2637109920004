import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import SectionCta from '../SectionCta';
import CreateNetworkContext from 'views/NetworksFlow/context';
import { SURGE_PRICING_DAYS_OF_WEEK, SURGE_PRICING_TAB } from '../../helper';
import SurgePricingInfo from './SurgePricingInfo';
import OutlinedTextField from 'modules/OutlinedTextField';
import TimeInput from 'modules/TimeInput';
import Button from 'modules/Button';
import Switch from 'modules/Switch';
import utils from 'resources/utils';
import ConfirmClose from 'modules/modals/ConfirmClose';

const useStyles = makeStyles(() => ({
	wrapper: { flex: 1, display: 'flex', flexDirection: 'column' },
	content_wrap: { gap: 20, display: 'flex', flex: 1, paddingLeft: 16 },
	input_wrap: {
		display: 'flex',
		flexDirection: 'column',
		flex: 0.55,
		overflow: 'hidden',
	},
	output_wrap: { display: 'flex', flexDirection: 'column', flex: 0.45, marginTop: -60 },
	input_section: {
		marginTop: 8,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey7}`,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		borderRadius: 14,
	},
	output_section: {
		padding: 16,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 16px rgba(102, 102, 102, 0.1)`,
		borderRadius: 12,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	notes_wrapper: {
		backgroundColor: theme.colors.lightPink4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 28,
		borderBottomLeftRadius: 12,
		borderBottomRightRadius: 12,
	},

	label_subtitle: { fontSize: 12, color: theme.colors.darkGrey2 },

	label_subtitle: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 },

	tab_item: {
		borderRadius: 8,
		minWidth: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		height: 33,
	},
	new_rate_wrap: { justifyContent: 'flex-end', marginBottom: 14 },
	menu_item: { display: 'flex', flex: 1, borderRadius: 40, boxShadow: '0px 0px 4px 0px #E4DCF3', padding: '8px 0px' },
	purple_strip: {
		width: 350,
		height: 60,
		borderRadius: 4,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		display: 'flex',
		alignItems: 'center',
		padding: 10,
		paddingLeft: 26,
	},
	section_desc_text: { fontSize: 14, color: theme.colors.primary, marginLeft: 12 },
}));

const SurgePricing = ({ handle_data_updates }) => {
	const classes = useStyles();

	const {
		invite_network_config,
		handle_surge_pricing_api,
		selected_surge_pricing_tab,
		set_selected_surge_pricing_tab,
		surge_pricing,
		set_surge_pricing,
		set_confirm_close_modal,
		invite_network_id,
		is_surge_pricing_toggle,
		set_is_surge_pricing_toggle,
		is_invite_surge_price,
		set_is_invite_surge_price,
		edit_network_id,
	} = useContext(CreateNetworkContext);
	const [surge_time, set_surge_time] = useState([{ start_time: '11:00', end_time: '12:00', percentage: '', flat: '' }]);
	const [is_custom, set_is_custom] = useState(_.get(invite_network_config, 'ownership.surge', '') == 'user');
	const [show_change_modal, set_show_change_modal] = useState(false);
	const [surge_day_rates, set_surge_day_rates] = useState(
		_.reduce(
			SURGE_PRICING_DAYS_OF_WEEK,
			(acc, day) => {
				acc[day.value] = { percentage: '', flat: '' };
				return acc;
			},
			{},
		),
	);
	useEffect(() => {
		if (_.isEmpty(surge_pricing) || (!_.isEmpty(surge_pricing) && surge_pricing?.rule == 1)) {
			set_is_surge_pricing_toggle(false);
		} else {
			set_price_initial_data();
			set_is_surge_pricing_toggle(true);
		}
	}, []);

	const handle_custom_support = (e) => {
		if (e) {
			set_show_change_modal(true);
			return;
		}
		if (!e) {
			set_confirm_close_modal({
				open: true,
				header_title: 'Exit Custom Rule Setup',
				title: `Are you sure you want to abandon custom rules setup for surge pricing? Rules will reset to default.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				is_error_msg: false,
				show_negative_btn: true,
				confirm_acion: () => {
					set_values_to_default();
				},
			});
		}
	};

	const set_values_to_default = () => {
		set_is_custom(false);
		set_surge_time([{ start_time: '11:00', end_time: '12:00', percentage: '', flat: '' }]);
		set_surge_day_rates(
			_.reduce(
				SURGE_PRICING_DAYS_OF_WEEK,
				(acc, day) => {
					acc[day.value] = { percentage: '', flat: '' };
					return acc;
				},
				{},
			),
		);
	};
	const set_price_initial_data = () => {
		if (_.isEmpty(surge_pricing)) return;

		const _surge_day_rates = _.cloneDeep(surge_day_rates);
		_.map(surge_pricing?.slab?.day_of_week, (day) => {
			_surge_day_rates[Object.keys(day)[0]].percentage = day[Object.keys(day)].amount.percentage != null ? day[Object.keys(day)].amount.percentage : '';
			_surge_day_rates[Object.keys(day)[0]].flat = day[Object.keys(day)].amount.flat != null ? day[Object.keys(day)].amount.flat : '';
		});
		set_surge_day_rates(_surge_day_rates);

		if (!_.isEmpty(surge_pricing?.slab?.slot_of_day) && !_.isEmpty(surge_pricing?.slab?.slot_of_day[0])) {
			const surge_time_data = _.map(surge_pricing?.slab?.slot_of_day, (item) => {
				const timeRange = Object.keys(item)[0];
				const [start_time, end_time] = timeRange.split('-');
				const { flat, percentage } = item[timeRange].amount;
				return {
					start_time,
					end_time,
					flat,
					percentage,
				};
			});
			set_surge_time(surge_time_data);
		}
	};
	const handle_surge_tab_switch = (index) => {
		set_selected_surge_pricing_tab(index);
	};

	const handle_add_new_slot = (item_index) => {
		const _surge_time = _.cloneDeep(surge_time);
		_surge_time.push({
			start_time: '11:00',
			end_time: '12:00',
			percentage: '',
			flat: '',
		});
		set_surge_time(_surge_time);
	};

	const handle_remove = (index) => {
		const deleted_row = _.filter(surge_time, (_, i) => i !== index);
		set_surge_time(deleted_row);
	};

	const handle_change_surge_time = (index, field, value) => {
		const new_changes = _.map(surge_time, (row, i) => (i === index ? { ...row, [field]: value } : row));
		set_surge_time(new_changes);
	};

	const handle_change_surge_days = (day, field, value) => {
		set_surge_day_rates({
			...surge_day_rates,
			[day]: {
				...surge_day_rates[day],
				[field]: value,
			},
		});
	};

	const handle_save_surge_pricing = () => {
		if (is_surge_pricing_toggle) {
			const save_data = {
				slab: {
					day_of_week: surge_pricing?.slab?.day_of_week ? surge_pricing?.slab?.day_of_week : [],
					slot_of_day: surge_pricing?.slab?.slot_of_day ? surge_pricing?.slab?.slot_of_day : [],
				},
			};
			if (selected_surge_pricing_tab == 0) {
				const filtered_rates = _.chain(surge_day_rates)
					.pickBy((value) => value.percentage !== '' || value.flat !== '')
					.value();

				const day_of_week_payload = _.map(Object.keys(filtered_rates), (day) => ({
					[day]: {
						amount: {
							flat: surge_day_rates[day].flat,
							percentage: surge_day_rates[day].percentage,
						},
					},
				}));
				save_data.slab.day_of_week = day_of_week_payload;
			}
			if (selected_surge_pricing_tab == 1) {
				const time_of_week_payload = _.map(surge_time, (time) => {
					let key = `${time.start_time}-${time.end_time}`;
					if (time.flat !== '' && time.percentage !== '') {
						return {
							[key]: {
								amount: {
									flat: parseFloat(time.flat),
									percentage: parseFloat(time.percentage),
								},
							},
						};
					}
					return [];
				});
				save_data.slab.slot_of_day = time_of_week_payload;
			}
			set_surge_pricing(save_data);
		} else {
			set_surge_pricing({ rule: 1 });
			set_values_to_default();
		}
		if (invite_network_id || edit_network_id) {
			set_is_invite_surge_price(true);
		}
	};
	const handle_surge_toggle = (e) => {
		set_is_surge_pricing_toggle(e.target.checked);
	};

	const but_disabled = useMemo(() => {
		let value = true;
		if (is_surge_pricing_toggle) {
			if (selected_surge_pricing_tab == 0) {
				const filtered_rates = _.chain(surge_day_rates)
					.pickBy((value) => value.percentage != '' && value.flat != '')
					.value();
				_.map(Object.keys(filtered_rates), (day) => {
					if (filtered_rates[day].percentage == '' || filtered_rates[day].flat == '') {
						value = true;
					} else {
						value = false;
					}
				});
			}
			if (selected_surge_pricing_tab == 1) {
				for (let i = 0; i < surge_time.length; i++) {
					const { end_time, start_time, percentage, flat } = surge_time[i];
					if (end_time == '' || start_time == '' || percentage == '' || flat == '') {
						value = true;
						break;
					} else {
						value = false;
					}
				}
			}
		} else {
			value = false;
		}
		return value;
	}, [surge_time, surge_day_rates, selected_surge_pricing_tab, is_surge_pricing_toggle, surge_pricing]);

	const is_data_updated = useMemo(() => {
		let is_invalid = false;
		if (is_surge_pricing_toggle) {
			if (selected_surge_pricing_tab == 0) {
				if (surge_pricing?.slab?.day_of_week?.length > 0) {
					const filtered_rates = _.chain(surge_day_rates)
						.pickBy((value) => value.percentage != 0 || value.flat != 0)
						.value();

					for (let key in filtered_rates) {
						let correspondingItem = surge_pricing?.slab?.day_of_week?.find((item) => item.hasOwnProperty(key));
						if (!correspondingItem) {
							is_invalid = true;
							break;
						}
						let listAmount = correspondingItem[key].amount;
						if (filtered_rates[key].flat != listAmount.flat || filtered_rates[key].percentage != listAmount.percentage) {
							is_invalid = true;
							break;
						}
					}
				} else {
					is_invalid = true;
				}
			}
			if (selected_surge_pricing_tab == 1) {
				if (!_.isEmpty(surge_pricing?.slab?.slot_of_day) && !_.isEmpty(surge_pricing?.slab?.slot_of_day[0])) {
					if (surge_pricing?.slab?.slot_of_day.length !== surge_time.length) return true;
					const surge_time_data = _.map(surge_pricing?.slab?.slot_of_day, (item, key) => {
						const timeRange = Object.keys(item)[0];
						const [start_time, end_time] = timeRange.split('-');
						const { flat, percentage } = item[timeRange].amount;
						if (
							start_time != surge_time[key].start_time ||
							end_time != surge_time[key].end_time ||
							flat != surge_time[key].flat ||
							percentage != surge_time[key].percentage
						) {
							is_invalid = true;
						}
					});
				} else {
					is_invalid = true;
				}
			}
		} else {
			if (!_.isEmpty(surge_pricing) && surge_pricing.rule == 1) {
				is_invalid = false;
			} else {
				is_invalid = true;
			}
		}
		handle_data_updates(2, is_invalid);
		return is_invalid;
	}, [surge_day_rates, surge_time, is_surge_pricing_toggle, selected_surge_pricing_tab, surge_pricing]);
	return (
		<div className={classes.wrapper}>
			<div className={classes.content_wrap}>
				<div className={classes.input_wrap}>
					<div className='d-flex justify-content-between align-items-center'>
						<div>
							<Text semi style={{ fontSize: 14 }}>
								Surge Pricing
							</Text>
							<Text medium style={{ fontSize: 12, marginTop: 4, color: theme.colors.darkGrey2 }}>
								Define Surge Charges for orders
							</Text>
						</div>
						<div>
							{invite_network_id && (
								<div className='d-flex align-items-center' style={{ justifyContent: 'flex-end' }}>
									<Text component={'span'} bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Custom Rule
									</Text>
									<Switch
										disabled={_.get(invite_network_config, 'ownership.surge', '') == 'user'}
										checked={is_custom}
										onChange={(e) => {
											handle_custom_support(e.target.checked);
										}}
									/>
								</div>
							)}
							<div className='d-flex justify-content-center align-items-center'>
								<Text bold style={{ fontSize: 12, color: !is_surge_pricing_toggle ? theme.colors.darkGrey7 : theme.colors.darkGrey2 }}>
									No Surge
								</Text>
								<Switch disabled={invite_network_id && !is_custom} checked={is_surge_pricing_toggle} onChange={(e) => handle_surge_toggle(e)} />
								<Text bold style={{ fontSize: 12, color: is_surge_pricing_toggle ? theme.colors.darkGrey7 : theme.colors.darkGrey2 }}>
									Surge
								</Text>
							</div>
						</div>
					</div>

					{is_surge_pricing_toggle && (
						<div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
							<div className='d-flex'>
								{_.map(SURGE_PRICING_TAB, (tab, index) => (
									<div
										key={`surgepriceing_tab_${index}`}
										onClick={() => handle_surge_tab_switch(index)}
										className={classes.tab_item}
										style={{
											backgroundColor: selected_surge_pricing_tab === index ? theme.colors.primary : 'transparent',
										}}>
										<Text
											bold
											style={{
												fontSize: 14,
												color: selected_surge_pricing_tab === index ? theme.colors.white : theme.colors.lightPurple6,
											}}>
											{tab}
										</Text>
									</div>
								))}
							</div>
						</div>
					)}
					<div className={classes.input_section}>
						{!is_surge_pricing_toggle && (
							<div className='align-items-center justify-content-center flex1'>
								<div className={classes.purple_strip}>
									<img src={ImageLinks.info_light_purple} width={32} height={32} />
									<Text semi className={classes.section_desc_text}>
										No surge charges will be applicable in this network.
									</Text>
								</div>
							</div>
						)}
						{is_surge_pricing_toggle && (
							<>
								<div className='d-flex' style={{ padding: 16, gap: 12, flexDirection: 'column' }}>
									{selected_surge_pricing_tab == 0 && (
										<>
											<div className={classes.menu_item}>
												<Text semi style={{ flex: 3, paddingLeft: 14, color: theme.colors.darkGrey2, fontSize: 14 }}>
													Day of Week
												</Text>
												<Text semi style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
													%
												</Text>
												<Text semi style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
													Flat Rate
												</Text>
											</div>
											{_.map(SURGE_PRICING_DAYS_OF_WEEK, (day) => {
												return (
													<div className='d-flex align-items-center'>
														<Text semi style={{ flex: 3, paddingLeft: 14, color: theme.colors.darkGrey2, fontSize: 14 }}>
															{day.name}
														</Text>
														<div style={{ flex: 1 }}>
															<OutlinedTextField
																disabled={invite_network_id && !is_custom}
																value={surge_day_rates[day.value]?.percentage}
																onChange={(e) => handle_change_surge_days(day.value, 'percentage', e.target.value)}
																name='weight'
																textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
																placeholder='0'
																InputProps={{
																	style: { height: 37, width: '80%' },
																	endAdornment: (
																		<Text
																			medium
																			className={classes.adornment_text}
																			style={{ fontSize: 14, color: theme.colors.darkGrey2, whiteSpace: 'nowrap' }}>
																			%
																		</Text>
																	),
																}}
																type={'number'}
															/>
														</div>
														<div style={{ flex: 1 }}>
															<OutlinedTextField
																disabled={invite_network_id && !is_custom}
																value={surge_day_rates[day.value]?.flat}
																onChange={(e) => handle_change_surge_days(day.value, 'flat', e.target.value)}
																name='price'
																textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
																placeholder='0.00'
																InputProps={{
																	style: { height: 37, width: '80%' },
																	startAdornment: (
																		<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2, paddingRight: 4 }}>
																			₹
																		</Text>
																	),
																}}
																type={'number'}
															/>
														</div>
													</div>
												);
											})}
										</>
									)}
									{selected_surge_pricing_tab == 1 && (
										<>
											<div className={classes.menu_item}>
												<Text semi style={{ flex: 2, paddingLeft: 14, color: theme.colors.darkGrey2, fontSize: 14 }}>
													Start Time *
												</Text>
												<Text semi style={{ flex: 2, color: theme.colors.darkGrey2, fontSize: 14 }}>
													End Time *
												</Text>
												<Text semi style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
													% *
												</Text>
												<Text semi style={{ flex: 1, color: theme.colors.darkGrey2, fontSize: 14 }}>
													Flat Rate *
												</Text>
											</div>
											<div className='d-flex' style={{ flexDirection: 'column', gap: 10 }}>
												{_.map(surge_time, (val, index) => {
													return (
														<div className='d-flex align-items-center'>
															<div style={{ flex: 2, paddingLeft: 14 }}>
																<TimeInput
																	disabled={invite_network_id && !is_custom}
																	time_box_style={{ padding: '8px 12px', width: '29%', textAlign: 'center' }}
																	time_stamp_style={{ marginLeft: 2 }}
																	time_stamp_style_am_pm={{ padding: '3px 7px' }}
																	value={val.start_time}
																	handleChange={(val) => handle_change_surge_time(index, 'start_time', val)}
																/>
															</div>
															<Text semi style={{ flex: 0.2 }}>
																&#x2013;
															</Text>
															<div style={{ flex: 2 }}>
																<TimeInput
																	disabled={invite_network_id && !is_custom}
																	time_box_style={{ padding: '8px 12px', width: '29%', textAlign: 'center' }}
																	time_stamp_style={{ marginLeft: 2 }}
																	time_stamp_style_am_pm={{ padding: '3px 7px' }}
																	value={val?.end_time}
																	handleChange={(val) => handle_change_surge_time(index, 'end_time', val)}
																/>
															</div>
															<div style={{ flex: 1 }}>
																<OutlinedTextField
																	disabled={invite_network_id && !is_custom}
																	value={val.percentage}
																	onChange={(e) => handle_change_surge_time(index, 'percentage', e.target.value)}
																	name='weight'
																	textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
																	placeholder='0'
																	InputProps={{
																		style: { height: 37, width: '90%' },
																		endAdornment: (
																			<Text
																				medium
																				className={classes.adornment_text}
																				style={{ fontSize: 14, color: theme.colors.darkGrey2, whiteSpace: 'nowrap' }}>
																				%
																			</Text>
																		),
																	}}
																	type={'number'}
																/>
															</div>
															<div style={{ flex: 0.8 }}>
																<OutlinedTextField
																	disabled={invite_network_id && !is_custom}
																	value={val.flat}
																	onChange={(e) => handle_change_surge_time(index, 'flat', e.target.value)}
																	name='price'
																	textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
																	placeholder='0.00'
																	InputProps={{
																		style: { height: 37, width: '100%' },
																		startAdornment: (
																			<Text
																				medium
																				style={{ fontSize: 14, color: theme.colors.darkGrey2, paddingRight: 4 }}>
																				₹
																			</Text>
																		),
																	}}
																	type={'number'}
																/>
															</div>
															<img
																src={surge_time.length < 2 ? ImageLinks.delete_gray : ImageLinks.delete}
																width={18}
																height={18}
																onClick={() => handle_remove(index)}
																style={{
																	cursor: 'pointer',
																	pointerEvents: surge_time.length < 2 || (invite_network_id && !is_custom) ? 'none' : 'auto',
																}}
															/>
														</div>
													);
												})}
												{surge_time.length > 0 && (
													<div className={clsx(classes.new_rate_wrap, 'align-items-center')}>
														<Button
															disabled={invite_network_id && !is_custom}
															type='text'
															text='New Rate'
															left_icon={invite_network_id && !is_custom ? ImageLinks.addGrey : ImageLinks.add_pruple_no_fill}
															onClick={handle_add_new_slot}
															size='extra_small'
														/>
													</div>
												)}
											</div>
										</>
									)}
								</div>
								<div className={classes.notes_wrapper}>
									<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										Note: Settings can be customized at invite level
									</Text>
								</div>
							</>
						)}
					</div>
				</div>
				<div className={classes.output_wrap}>
					<div className={classes.output_section}>
						<SurgePricingInfo />
					</div>
				</div>
			</div>
			{show_change_modal && (
				<ConfirmClose
					headerTitle='Set Custom Surge Pricing'
					title='Are you sure you want to set custom custom surge pricing for this participant? All default config will not be considered.'
					show_negative_btn={true}
					close={() => set_show_change_modal(false)}
					on_confirm={() => set_is_custom(true)}
				/>
			)}
			<SectionCta
				title={`Save Surge Charges`}
				is_data_updated={is_data_updated}
				is_disabled={but_disabled}
				handle_click={handle_save_surge_pricing}
				total_steps={2}
				lower_section_index={3}
				handle_data_updates={handle_data_updates}
				complete_btn_title={'Setup Surge Charges'}
				handle_api_save={handle_surge_pricing_api}
			/>
		</div>
	);
};
export default SurgePricing;
