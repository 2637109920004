import React, { useEffect, useState } from 'react';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import constant from 'resources/constant';
import BusinessRiders from './components/BusinessRiders';
import { AVAILABLE_RIDER_TYPE, BOTTOM_STATE, TYPES } from './helper';
import Success from './components/Success';
import Start from './components/Start';
import InProgress from './components/InProgress';
import Failed from './components/Failed';
import useStyles from './style';
import { shallowEqual, useSelector } from 'react-redux';
import RiderType from 'modules/modals/CdrAllocationModal/components/RiderType';
import NetworkAvatar from 'modules/NetworkAvatar';
import utils from 'resources/utils';
import { useHistory } from 'react-router-dom';

let map = null;
let marker;

let timeout;

const AllocateRider = ({ close, bundle_id, tripData, set_key, bundles, set_refresh, selected_menu, get_bundles = () => {} }) => {
	const { app_configs } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
		}),
		shallowEqual,
	);

	const history = useHistory();
	const [type, set_type] = React.useState(TYPES.manual);
	const [search_rider_text, set_search_rider_text] = React.useState('');
	const [available_rider_type, set_available_rider_type] = React.useState(AVAILABLE_RIDER_TYPE.lowest_cost);

	const [network_available_rider_type, set_network_available_rider_type] = useState(AVAILABLE_RIDER_TYPE.lowest_cost);
	const [network_selected_rider, set_network_selected_rider] = useState(null);
	const [network_id, set_network_id] = useState(null);
	const [is_multiple_network, set_is_multiple_network] = useState(false);

	const [search_riders_captive, set_search_riders_captive] = React.useState([]);
	const [selected_rider_captive, set_selected_rider_captive] = React.useState(null);

	const [bottom_bar_state, set_bottom_bar_state] = React.useState(BOTTOM_STATE.start);
	const [show_cancel, set_show_cancel] = React.useState(false);

	const [is_allocated, set_is_allocated] = useState(false);

	const classes = useStyles();
	const initialize = () => {
		map = new window.google.maps.Map(document.getElementById('map_canvas_address_2'), {
			center: { lat: Number(tripData[0].drop_address.latitude), lng: Number(tripData[0].drop_address.longitude) },
			zoom: 8,
			clickableIcons: false,
			disableDefaultUI: true,
			scaleControl: true,
			zoomControl: true,
			mapId: constant.MAP_DESIGN_ID,
		});

		const icon = {
			url: ImageLinks.marker_purple, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};

		const pick_up_icon = {
			url: ImageLinks.rider_pickup, // url
			scaledSize: new window.google.maps.Size(32, 32), // scaled size
		};

		new window.google.maps.Marker({
			position: { lat: Number(tripData[0].pickup_address.latitude), lng: Number(tripData[0].pickup_address.longitude) },
			icon: pick_up_icon,
			map,
		});

		marker = _.map(tripData, (val, key) => {
			return new window.google.maps.Marker({
				position: { lat: Number(val.drop_address.latitude), lng: Number(val.drop_address.longitude) },
				label: { text: `${key + 1}`, color: 'white' },
				icon: icon,
				map,
			});
		});

		let bounds = new window.google.maps.LatLngBounds();
		let pickup_coord = new window.google.maps.LatLng(Number(tripData[0].pickup_address.latitude), Number(tripData[0].pickup_address.longitude));
		bounds.extend(pickup_coord);
		_.map(tripData, (val) => {
			let drop_coord = new window.google.maps.LatLng(Number(val.drop_address.latitude), Number(val.drop_address.longitude));
			bounds.extend(drop_coord);
		});
		map.fitBounds(bounds);
	};

	const handle_set_type = (_type) => {
		const rider_id = _.filter(bundles, (bundle) => {
			return bundle.id === bundle_id;
		})[0].rider_id;
		if (!rider_id) {
			set_search_rider_text('');
			set_search_riders_captive([]);
		}
		set_selected_rider_captive(null);
		set_type(_type);
	};

	const handle_link_rider = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.LINK_RIDER, { riderId: selected_rider_captive, bundleIds: [bundle_id] });
		try {
			const res = await api_requests.link_route_to_rider({ riderId: selected_rider_captive, bundleIds: [bundle_id] });
			if (res.success) {
				set_refresh((prev) => !prev);
			}
		} catch (err) {}
	};
	const handle_unlink_rider = async (unlink_bundle_id = bundle_id) => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.LINK_RIDER, { bundleIds: [unlink_bundle_id] });
		const res = await api_requests.unlink_route_to_rider({ bundleIds: [unlink_bundle_id] });
		if (res.success) {
			set_refresh((prev) => !prev);
			set_search_rider_text('');
			set_selected_rider_captive(null);
			set_available_rider_type(AVAILABLE_RIDER_TYPE.lowest_cost);
		}
	};
	const handle_search_riders = () => {
		if (_.isEmpty(search_rider_text)) {
			set_search_riders_captive([]);
			return;
		}
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.SEARCH_RIDERS, search_rider_text);
		api_requests
			.search_rider_without_zone({
				search: search_rider_text,
				is_active: true,
			})
			.then((res) => {
				set_search_riders_captive(res.data);
			});
	};

	useEffect(() => {
		handle_search_riders();
	}, [search_rider_text]);

	useEffect(() => {
		set_search_riders_captive([]);
		set_selected_rider_captive(null);

		if (available_rider_type !== AVAILABLE_RIDER_TYPE.available) {
			return;
		}

		api_requests.search_top_riders(bundle_id, { isAvailable: true, pool: 2 }).then((res) => {
			set_search_riders_captive(res.data);
		});
	}, [available_rider_type, bundle_id]);

	const handle_allocate_rider = () => {
		set_bottom_bar_state(BOTTOM_STATE.in_progress);
		set_show_cancel(true);

		let payload;

		switch (type) {
			case TYPES.manual:
				payload = {
					bundleIds: [bundle_id],
					allocationPoolType: 2,
					costParam: available_rider_type === AVAILABLE_RIDER_TYPE.available ? 3 : 1,
					...(selected_rider_captive && { riderId: selected_rider_captive }),
				};
				break;

			case TYPES.network:
				payload = {
					bundleIds: [bundle_id],
					allocationPoolType: 2,
					network_id,
					network_name: _.get(app_configs, `network_configs.${network_id}.name`, null),
					costParam: network_available_rider_type === AVAILABLE_RIDER_TYPE.available ? 3 : 1,
					...(network_selected_rider && { riderId: network_selected_rider?.rider_id }),
				};
				break;

			default:
				break;
		}

		timeout = setTimeout(() => {
			set_show_cancel(false);
			api_requests.allocate_rider(payload, false).then(
				(res) => {
					set_bottom_bar_state(BOTTOM_STATE.success);
					set_is_allocated(true);
				},
				(err) => {
					set_bottom_bar_state(BOTTOM_STATE.failed);
				},
			);
		}, 4000);
	};

	useEffect(() => {
		const _rider_id = _.filter(bundles, (bundle) => {
			return bundle.id === bundle_id;
		})[0].rider_id;
		if (_rider_id) {
			set_search_rider_text(_rider_id + '');
			set_selected_rider_captive(_rider_id);
			set_available_rider_type(null);
		}

		const network_ids_arr = _.map(tripData, (item) => item.fulfillment_network_id);
		const all_equal = network_ids_arr.every((val) => val === network_ids_arr[0]);

		set_is_multiple_network(!all_equal);
		set_network_id(all_equal && network_ids_arr[0] > 0 ? network_ids_arr[0] : null);

		setTimeout(() => {
			initialize();
		}, 800);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const on_close = () => {
		if (is_allocated) {
			get_bundles();
			history.replace({ search: '?type=' + selected_menu });
		}
		close();
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={on_close} />
			<div className={clsx(classes.container, 'box-shadow')}>
				<div className={classes.content}>
					<div className={clsx(classes.map_wrap)}>
						<div id='map_canvas_address_2' className='map-canvas-wrapper rider-location card' style={{ height: '100%' }} />
					</div>

					<div className={classes.right_wrap}>
						<div className={classes.header}>
							<Text bold style={{ fontSize: 18, color: theme.colors.primary }}>
								{true ? 'Quick Ship multi-network orders' : 'Quick Ship'}
							</Text>
							<div className='cursor-pointer' onClick={on_close} style={{ marginTop: 3 }}>
								<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
							</div>
						</div>
						<div className={classes.options_Wrap}>
							<BusinessRiders
								type={type}
								handle_set_type={handle_set_type}
								search_rider_text={search_rider_text}
								set_search_rider_text={set_search_rider_text}
								set_available_rider_type={set_available_rider_type}
								search_riders_captive={search_riders_captive}
								set_search_riders_captive={set_search_riders_captive}
								selected_rider_captive={selected_rider_captive}
								set_selected_rider_captive={set_selected_rider_captive}
								available_rider_type={available_rider_type}
								selected_menu={selected_menu}
								rider_id={
									_.filter(bundles, (bundle) => {
										return bundle.id === bundle_id;
									})[0].rider_id
								}
							/>

							{!is_multiple_network && network_id && (
								<div
									onClick={() => handle_set_type(TYPES.network)}
									className={classes.allocate_card}
									style={{
										marginTop: 12,
										minHeight: type === TYPES.network ? 208 : 64,
										border: `1px solid ${type === TYPES.network ? theme.colors.primary : theme.colors.lightGrey7}`,
									}}>
									<div className='align-items-center'>
										<NetworkAvatar
											configs={_.get(app_configs, `network_configs.${network_id}.avatar.configs`, [])}
											height={40}
											width={40}
										/>

										<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
											{`${_.get(app_configs, `network_configs.${network_id}.name`, [])} (${network_id})`}
										</Text>
									</div>
									{type === TYPES.network && (
										<RiderType
											network_id={network_id}
											available_rider_type={network_available_rider_type}
											set_available_rider_type={set_network_available_rider_type}
											selected_rider={network_selected_rider}
											set_selected_rider={set_network_selected_rider}
										/>
									)}
								</div>
							)}
							{_.filter(bundles, (bundle) => {
								if (selected_rider_captive) {
									return bundle.rider_id === selected_rider_captive && bundle.id !== bundle_id;
								}
							}).length > 0 && (
								<div>
									<Text semi style={{ fontSize: 10, marginTop: 10 }}>
										Routes Assigned
									</Text>
									{_.map(
										_.filter(bundles, (bundle) => {
											return bundle.rider_id === selected_rider_captive && bundle.id !== bundle_id;
										}),
										(bundle, key) => {
											return (
												<div key={`bundle${key}`} className={`box-shadow ${classes.bundle_list_item}`}>
													<div className={classes.list_item_wrap}>
														<div className={classes.list_item_stats_wrap}>
															<Text bold style={{ color: theme.colors.black }}>
																{bundle.bundle_label}
															</Text>
															<div className='d-flex align-items-center' style={{ gap: 3 }}>
																<img src={ImageLinks.rider_light_purple3} height={10} width={10} />
																<Text medium style={{ color: theme.colors.primary, fontSize: 10 }}>
																	{bundle.rider_id ? bundle.rider_id : '-'}
																</Text>
															</div>
														</div>
														<div
															className='d-flex align-items-center'
															style={{ gap: 8 }}
															onClick={() => handle_unlink_rider(bundle.id)}>
															<img src={ImageLinks.unlink_icon_primary} width={12} height={12}></img>
															<Text semi className={classes.unlink_text}>
																Unlink Rider
															</Text>
														</div>
														<div className={classes.list_item_stats_wrap_2}>
															<div className='d-flex'>
																<img src={ImageLinks.box2_purple} width={10} />
																<Text bold style={{ color: theme.colors.black, marginLeft: 5 }}>
																	{bundle.trip_count}
																</Text>
															</div>

															<div className='d-flex'>
																<Text
																	semi
																	style={{
																		marginLeft: 5,
																		fontSize: 10,
																	}}>
																	{bundle.distance !== null ? `${Math.round(bundle.distance / 1000)}` : '-'} kms |
																</Text>
																<Text
																	semi
																	style={{
																		marginLeft: 5,
																		fontSize: 10,
																	}}>
																	{bundle.time_in_second
																		? bundle.time_in_second < 3600
																			? Math.round(bundle.time_in_second / 60) + ' mins'
																			: Math.round(bundle.time_in_second / 360) / 10 + ' hrs'
																		: '-'}
																</Text>
															</div>
														</div>
													</div>
												</div>
											);
										},
									)}
								</div>
							)}
						</div>
						<div className={classes.footer}>
							{bottom_bar_state === BOTTOM_STATE.start && (
								<Start
									selected_menu={selected_menu}
									is_allocate_now_enabled={
										(type === TYPES.network && (!_.isEmpty(network_available_rider_type) || network_selected_rider != null)) ||
										(type === TYPES.manual && (!_.isEmpty(available_rider_type) || selected_rider_captive != null))
									}
									rider_id={
										_.filter(bundles, (bundle) => {
											return bundle.id === bundle_id;
										})[0].rider_id
									}
									type={type}
									handle_allocate_rider={handle_allocate_rider}
									handle_link_rider={handle_link_rider}
									handle_unlink_rider={handle_unlink_rider}
									selected_rider_captive={selected_rider_captive}
								/>
							)}
							{bottom_bar_state === BOTTOM_STATE.in_progress && (
								<InProgress show_cancel={show_cancel} timeout={timeout} set_bottom_bar_state={set_bottom_bar_state} />
							)}
							{bottom_bar_state === BOTTOM_STATE.failed && <Failed set_key={set_key} />}
							{bottom_bar_state === BOTTOM_STATE.success && <Success />}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const AllocateRiderCont = (props) => {
	const [key, set_key] = React.useState(_.uniqueId());
	return <AllocateRider key={key} set_key={set_key} {...props} />;
};

export default AllocateRiderCont;
