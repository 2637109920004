import React, { useEffect } from 'react';
import _ from 'lodash';
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';
import { Dialog, DialogContent } from '@material-ui/core';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/styles';

import Text from 'modules/Text';
import IconButton from 'modules/IconButton';
import cross from 'assets/images/icons/cross.svg';
import api_requests from 'resources/api_requests';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import Amount from '../../../modules/Amount';
import ImageLinks from 'assets/images/ImageLinks';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 600,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		zIndex: 1200,
	},
	pbidContainer: {
		fontSize: 12,
		color: theme.colors.primary,
	},
}));

const TransactionHistory = ({ close, user_type, id }) => {
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);
	const [filters, set_filters] = React.useState({
		from: moment(moment().subtract('15', 'days').format('MM-DD-YYYY')),
		to: moment(moment().format('MM-DD-YYYY')),
	});

	const [transactions, set_transactions] = React.useState([]);

	const classes = useStyles();

	useEffect(() => {
		api_requests
			.get_transactions_per_day({
				from: filters.from.format(),
				to: filters.to.endOf('day').format(),
				...(user_type && { user_type, id }),
			})
			.then((res) => {
				set_transactions(res.data.records);
			});
	}, [filters]);

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div
					style={{
						borderBottom: '1px solid lightgrey',
						padding: '50px 50px 10px 50px',
						display: 'flex',
						justifyContent: 'space-between',
					}}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Text bold style={{ fontSize: 30, color: theme.colors.primary }}>
							Transaction History
						</Text>
					</div>
					<IconButton onClick={close} style={{ border: 'none' }}>
						<img src={cross} width={25} height={25} />
					</IconButton>
				</div>
				<UndlerlineTextField
					value={`${filters.from.format('DD/MM/YY')} - ${filters.to.format('DD/MM/YY')}`}
					label='Date Range'
					onClick={() => setDatePickerOpen(true)}
					style={{ width: 150, marginTop: 20, alignItems: 'center', margin: '15px auto' }}
				/>
				<div style={{ flex: 1, overflowY: 'auto', paddingLeft: '10%' }}>
					{_.map(transactions, (data, key) => (
						<div
							key={`transaction${key}`}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								borderBottom: `1px solid ${theme.colors.lightGrey5}`,
								height: 60,
							}}>
							<div style={{ width: 90 }}>
								<Text semi>{moment(data.date).format('DD MMM ‘YY')}</Text>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
								<div
									style={{
										height: 15,
										width: 15,
										borderRadius: '100%',
										background: theme.colors.darkPurple,
										marginRight: 10,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<img src={ImageLinks.down_arrow} />
								</div>
								<Amount
									amount={data.credit}
									main_text_styles={{ color: theme.colors.black, fontSize: 14 }}
									sub_text_styles={{ color: theme.colors.black, fontSize: 8 }}
								/>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
								<div
									style={{
										height: 15,
										width: 15,
										borderRadius: '100%',
										background: theme.colors.green,
										marginRight: 10,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<img src={ImageLinks.arrow_up_white} />
								</div>
								<Amount
									amount={data.debit}
									main_text_styles={{ color: theme.colors.black, fontSize: 14 }}
									sub_text_styles={{ color: theme.colors.black, fontSize: 8 }}
								/>
							</div>
							{/* <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
								<div
									style={{
										height: 15,
										width: 15,
										borderRadius: '100%',
										background: theme.colors.lightGrey5,
										marginRight: 10
									}}></div>
								<Amount
									amount={data.outstanding}
									main_text_styles={{ color: theme.colors.black, fontSize: 14 }}
									sub_text_styles={{ color: theme.colors.black, fontSize: 8 }}
								/>
							</div> */}
						</div>
					))}
				</div>
			</div>
			<Dialog open={datePickerOpen} PaperProps={{ style: { background: 'transparent', maxWidth: 900 }, elevation: 0 }}>
				<DialogContent style={{}}>
					<DateRangePicker
						open={datePickerOpen}
						toggle={() => setDatePickerOpen(!datePickerOpen)}
						style={{ width: 200 }}
						initialDateRange={{
							startDate: filters.from,
							endDate: filters.to,
						}}
						maxDate={new Date()}
						onChange={(range) => {
							const newDateRange = {
								from: moment(range.startDate),
								to: moment(range.endDate),
							};

							if (Math.abs(newDateRange.from.diff(newDateRange.to, 'days')) > 15) {
								toast.error('Only 15 days filter allowed', {
									position: toast.POSITION.BOTTOM_RIGHT,
								});
								return;
							}
							set_filters(newDateRange);
							setDatePickerOpen(!datePickerOpen);
						}}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};
export default TransactionHistory;
