const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
	close_container: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		overflowY: 'scroll',
		width: 570,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 50,
		zIndex: 1200,
		overflowY: 'auto',
		paddingBottom: 120,
	},
	divider: { widht: '100%', height: 1, backgroundColor: theme.colors.lightGrey5 },
	upload_csv_content_container: {
		width: '100%',
		minHeight: 250,
		borderRadius: 10,
		border: `1px dashed ${theme.colors.lightGrey2}`,
		marginTop: 20,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	rider_row: { display: 'flex', padding: 12, borderBottom: `1px solid ${theme.colors.lightGrey3}`, alignItems: 'center' },
	error_box: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '16px 12px',
		boxShadow: ' 0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 6,
		margin: '10px 0',
	},
	file_desc_box: {
		height: 55,
		width: 390,
		backgroundColor: theme.colors.lightGrey7,
		borderRadius: 5,
		padding: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	footer: {
		display: 'flex',
		position: 'absolute',
		bottom: 0,
		left: 0,
		width: 570,
		padding: '16px 50px',
		backgroundColor: theme.colors.white,
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	name_text: {
		fontSize: 16,
		flex: 1,
		borderRight: `1px solid ${theme.colors.lightGrey2}`,
		marginRight: 10,
	},
	list_container: {
		maxHeight: '30vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
}));

export default useStyles;
