import { makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, TimeScale, LineElement, Filler);

const useStyles = makeStyles((theme) => ({
	main_container: {
		backgroundColor: theme.colors.white,
		padding: 12,
		boxShadow: '0px 1.0728px 3.21839px rgba(13, 10, 44, 0.08)',
		borderRadius: 12,
		height: '100%',
		position: 'relative',
	},
	divider: { height: 1, width: '100%', backgroundColor: theme.colors.lightGrey5 },
	flex_box: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginTop: 5 },
	heading_text: { color: theme.colors.darkGrey4, fontSize: 14 },
	purple_dot: { height: 5, width: 5, borderRadius: 3, backgroundColor: theme.colors.lightPurple5, marginLeft: 20 },
	orange_dot: { height: 5, width: 5, borderRadius: 3, backgroundColor: theme.colors.orange },
	legends_box: { display: 'flex', alignItems: 'center', gap: 5 },
}));

function KmChart({ km_data, overall_km_chart_data }) {
	const classes = useStyles();
	const theme = useTheme();

	const available_dates = _.map(km_data, (item) => {
		return item.date;
	});

	function nFormatter(num, digits) {
		const lookup = [
			{ value: 1, symbol: '' },
			{ value: 1e3, symbol: 'k' },
			{ value: 1e6, symbol: 'M' },
			{ value: 1e9, symbol: 'G' },
			{ value: 1e12, symbol: 'T' },
			{ value: 1e15, symbol: 'P' },
			{ value: 1e18, symbol: 'E' },
		];
		const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		var item = lookup
			.slice()
			.reverse()
			.find(function (item) {
				return num >= item.value;
			});
		return item ? (num / item.value).toFixed(2).replace(rx, '$1') + item.symbol : num;
	}

	const data = {
		labels: _.map(km_data, (data, index) => {
			return moment(data.date).format('DD-MMM');
		}),
		datasets: [
			{
				data: _.map(
					_.filter(overall_km_chart_data, (item) => _.includes(available_dates, item.date)),
					(data) => {
						return data.total_distance / (1000 * data.active_entity_cnt);
					},
				),
				fill: false,
				borderColor: theme.colors.lightPurple5,
				tension: 0.5,
				borderWidth: 1,
				pointRadius: 4,
				yAxisID: 'y',
			},
			{
				data: _.map(km_data, (data) => {
					return data.total_distance / 1000;
				}),
				fill: false,
				borderColor: theme.colors.orange,
				tension: 0.5,
				borderWidth: 1,
				pointRadius: 4,
				yAxisID: 'y1',
			},
		],
	};
	const options = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: true,
			},
			datalabels: {
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
				ticks: {
					color: theme.colors.darkGrey2,
					font: {
						size: 10,
						family: 'HK Grotesk Medium',
					},
				},
			},
			y: {
				beginAtZero: true,
				ticks: {
					color: theme.colors.lightPurple5,
					font: {
						family: 'HK Grotesk Regular',
					},
					callback: nFormatter,
				},
				grid: {
					display: false,
				},
			},
			y1: {
				type: 'linear',
				display: true,
				position: 'right',
				beginAtZero: true,
				ticks: {
					color: theme.colors.orange,
				},
				grid: {
					display: false,
				},
			},
		},
	};
	return (
		<div className={classes.main_container}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12 }}>
				<Text bold>KM per Day</Text>
				<div className={classes.legends_box}>
					<div className={classes.purple_dot} />
					<div>
						<Text medium style={{ fontSize: 10 }}>
							Average/ Vehicle
						</Text>
					</div>
					<div className={classes.orange_dot} />
					<div>
						<Text medium style={{ fontSize: 10 }}>
							Total KM
						</Text>
					</div>
				</div>
			</div>
			<div className={classes.divider}></div>
			<Line data={data} options={options} />
			{/* {_.get(orders_data[0], 'total_order', 0) === 0 && <EmptyState />} */}
		</div>
	);
}

export default KmChart;
