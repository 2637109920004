import { ListItem } from '@material-ui/core';
import React from 'react';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import { makeStyles } from '@material-ui/styles';
import theme from 'resources/theme';
import { shallowEqual, useSelector } from 'react-redux';
import _ from 'lodash';
import IconButton from 'modules/IconButton';

const useStyles = makeStyles({
	wrapper: {
		padding: 16,
		border: '1px solid transparent',
		borderRadius: 5,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		background: theme.colors.white,
		marginTop: 15,
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
	},
	title_wrapper: { display: 'flex', justifyContent: 'space-between', width: '100%' },
	subtitle_text: { fontSize: 12, color: '#9C9C9C', marginTop: 5 },
	content_wrapper: {
		borderTop: '1px solid #E0E0E0',
		width: '100%',
		marginTop: 12,
		display: 'flex',
		justifyContent: 'space-between',
		paddingTop: 12,
	},
	content_text: {
		marginLeft: 10,
		fontSize: 12,
	},
	content_text_wrap: { display: 'flex', alignItems: 'center', marginLeft: 20 },
	flex_center: { display: 'flex', alignItems: 'center' },
});

const HubItem = ({ id, title, latitude, longitude, master_zone_id, handle_delete_hub, onClick, allowable_login_distance }) => {
	const classes = useStyles();

	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);

	return (
		<ListItem button onClick={onClick} className={classes.wrapper}>
			<div className={classes.title_wrapper}>
				<div style={{ display: 'flex' }}>
					<img width='16' height='16' src={ImageLinks.marker} style={{ marginTop: 2 }} alt='' />
					<div style={{ marginLeft: 10 }}>
						<Text bold style={{ fontSize: 16 }}>
							{title}
						</Text>
						<Text medium className={classes.subtitle_text}>
							{latitude}, {longitude}
						</Text>
					</div>
				</div>
				<Text semi>ID: {id}</Text>
			</div>
			<div className={classes.content_wrapper}>
				<div style={{ display: 'flex' }}>
					<div className={classes.flex_center}>
						<img src={ImageLinks.zone} width='16' height='16' alt='' />
						<Text className={classes.content_text}>{_.get(_.find(zones, { id: master_zone_id }), 'name', '-')}</Text>
					</div>
					<div className={classes.content_text_wrap}>
						<img src={ImageLinks.marker_distance} width='16' height='16' alt='' />
						<Text className={classes.content_text}>{allowable_login_distance}</Text>
					</div>
				</div>
				<div style={{}}>
					<IconButton style={{ width: 25, height: 25 }} onClick={() => handle_delete_hub(id)}>
						<img src={ImageLinks.delete} alt='' width={16} height={16} />
					</IconButton>
				</div>
			</div>
		</ListItem>
	);
};

export default HubItem;
