import React, { useEffect } from 'react';
import qs from 'qs';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Text from 'modules/Text';
import theme from 'resources/theme';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import api_requests from 'resources/api_requests';
import Filters from 'modules/Filters/Filters';
import clsx from 'clsx';
import { ALL_ORDERS_MENU_ITEMS } from '../helper';
import Routes from 'resources/Routes';
import { useHistory } from 'react-router-dom';
import utils from 'resources/utils';
import OrderLabel from 'modules/OrderLabel';
import constant from 'resources/constant';
import FilterButton from 'modules/FilterButton';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import { shallowEqual, useSelector } from 'react-redux';
import NoResult from 'modules/NoResult';
import NetworkConfigAvatar from 'modules/NetworkConfigAvatar';
import { hide_loader, show_loader } from 'actions/app';

const useStyles = makeStyles(() => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'hidden',
	},
	ongoingOrdersDiv: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: 40,
		marginTop: -40,
	},
	filter_btn: {
		backgroundColor: theme.colors.white,
		marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	detailsTextContainer: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
		gap: 10,
	},
	pickedUpContainer: {
		overflowY: 'auto',
		flex: 1,
		marginTop: 10,
		marginBottom: 10,
	},
	pickedUpItem: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 10,
	},
	orderDetailsClickabletext: {
		flex: 1,
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
	pagesContainer: {
		height: 60,
		marginBottom: 10,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		borderTop: `1px solid ${theme.colors.lightPurple}`,
	},
	pageItemDiv: {
		marginLeft: 20,
		marginRight: 20,
		width: 24,
		height: 24,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 20,
	},
	pageText: { color: theme.colors.primary, fontSize: 12 },
	setPageContainer: { display: 'flex', alignItems: 'center' },
	truncate: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 16,
	},
	header_title_2: {
		flex: 2,
		color: theme.colors.darkGrey2,
		fontSize: 14,
	},
	table_item_wrap_2: { flex: 2, minWidth: 0 },
	page_nav_btn: {
		width: 24,
		height: 24,
		borderRadius: 30,
	},
	enabled_page_nav: { border: `1px solid ${theme.colors.primary}` },
	disabled_page_nav: { backgroundColor: theme.colors.lightGrey5, width: 26, height: 26 },
	subtitle: { fontSize: 12, color: theme.colors.lightGrey6, marginTop: 2 },
	wrapper_help_center: { position: 'fixed', bottom: 30, left: '50%', zIndex: 100 },
}));

const Terminal = ({ report_items = [] }) => {
	const { app_configs } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
		}),
		shallowEqual,
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const filter_object = qs.parse(history.location.search.slice(1)).filter || {};
	const network_configs = _.get(app_configs, 'network_configs', {});
	const [selected_pb_id, set_selected_pb_id] = React.useState('');
	const [show_order_details, set_show_order_details] = React.useState(false);
	const [orders, set_orders] = React.useState({});
	const [page, set_page] = React.useState(1);
	const [all_filters, set_all_filters] = React.useState([]);
	const [show_filters, set_show_filters] = React.useState(false);
	const [order_list, set_order_list] = React.useState([]);
	const slider_ref = React.useRef();
	const loading_scroll = React.useRef(false);

	useEffect(() => {
		set_all_filters([]);
		api_requests.all_orders_filter('completed').then((res) => set_all_filters(res.data.filters));
	}, []);

	useEffect(() => {
		set_page(1);
		get_orders({});
	}, [JSON.stringify(filter_object)]);

	const get_orders = ({ token }) => {
		set_orders({ next: true });
		dispatch(show_loader());
		api_requests.get_pending_orders_search({ ...filter_object, ...(token && { token }), limit: 50, group: 'completed' }).then((res) => {
			set_orders(res.data);
			dispatch(hide_loader());
			loading_scroll.current = false;
			if (token) {
				set_order_list(_.concat(order_list, res.data.items));
			} else {
				set_order_list(res.data.items);
			}
		});
	};

	const handle_export = (filters, callback) => {
		api_requests
			.export_all_orders({ ...filters, group: 'completed' })
			.then((res) => {
				utils.downloadBlob(res, 'orders.csv', 'text/csv');
				callback(true);
			})
			.catch((err) => {
				callback(false);
			});
	};

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && orders.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_orders({ token: orders.next });
		}
	};

	const classes = useStyles();

	return (
		<>
			<div className={classes.ongoingOrdersDiv}>
				<div className='d-flex align-items-center' style={{ gap: 5, marginRight: 10, marginTop: -10 }}>
					<Text semi component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						Orders :{' '}
					</Text>
					<Text semi component={'span'} style={{ fontSize: 14 }}>
						{order_list.length} {orders.next ? ` of more` : ''}
					</Text>
				</div>
				<FilterButton
					handle_click={() => set_show_filters(true)}
					total_filters={Object.keys(filter_object).length}
					track_event={{ event: 'completed_filter', properties: { filter_object } }}
				/>
			</div>
			<div className={classes.container}>
				<div className={classes.detailsTextContainer}>
					<Text bold className={classes.header_title_2}>
						Channel/Ref ID
					</Text>
					<Text bold className={classes.header_title_2}>
						Pickup Details
					</Text>
					<Text bold className={classes.header_title_2}>
						Delivery Details
					</Text>
					<Text bold className={classes.header_title_2}>
						Delivery Partner
					</Text>
					<Text bold className={classes.header_title_2}>
						Status
					</Text>
				</div>

				<div className={classes.pickedUpContainer} ref={slider_ref} onScroll={slider_scrolled}>
					{_.map(order_list, (order, key) => {
						const partner_img = network_configs[_.get(order, 'fulfillment.channel.id', '')]?.avatar?.image;
						return (
							<div key={`order${key}`} className={classes.pickedUpItem}>
								<div className={classes.table_item_wrap_2}>
									<Text
										semi
										className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
										onClick={() => {
											if (!order.id) return;
											set_selected_pb_id(order.id);
											set_show_order_details(true);
										}}>
										{order.reference_id || order?.dd_channel?.order_id || order.id || '-'}
									</Text>
									<Text className={classes.subtitle}>
										{`${moment(order.created_at).format('DD MMM YY')} | ${moment(order.created_at).format('hh:mmA')}`}
									</Text>
								</div>
								<div className={classes.table_item_wrap_2}>
									<Text medium className={classes.truncate}>
										{`${
											!_.isEmpty(_.get(order, 'fulfillment.pickup.timestamp', null))
												? moment(order.fulfillment.pickup.timestamp).format('DD-MM-YY')
												: '-'
										}`}
									</Text>
									<Text className={classes.subtitle}>{`${
										!_.isEmpty(_.get(order, 'fulfillment.pickup.timestamp', null))
											? moment(order.fulfillment.pickup.timestamp).format('hh:mm A')
											: ''
									}`}</Text>
								</div>
								<div className={classes.table_item_wrap_2}>
									<Text medium className={classes.truncate}>
										{`${
											!_.isEmpty(_.get(order, 'fulfillment.drop.timestamp', null))
												? moment(order.fulfillment.drop.timestamp).format('DD-MM-YY')
												: '-'
										}`}
									</Text>
									<Text className={classes.subtitle}>{`${
										!_.isEmpty(_.get(order, 'fulfillment.drop.timestamp', null))
											? moment(order.fulfillment.drop.timestamp).format('hh:mm A')
											: ''
									}`}</Text>
								</div>
								<div className={classes.table_item_wrap_2}>
									<NetworkConfigAvatar
										name={_.get(order, 'fulfillment.channel.name', '-')}
										logo_width={_.get(order, 'fulfillment.channel.name', '-') === 'captive' ? 100 : 60}
										id={_.get(order, 'fulfillment.channel.id', '')}
									/>
									<Text medium className={classes.subtitle}>{`${utils.dot_in_between_string(
										_.get(order, 'fulfillment.channel.order_id', '-'),
									)}`}</Text>
								</div>
								<div className={classes.table_item_wrap_2}>
									{order.fulfillment?.status ? <OrderLabel status={constant.ORDER_STATUS[order.fulfillment?.status]?.id} /> : '-'}
								</div>
							</div>
						);
					})}
					{/* {order_list.length < 8 && (
						<div className={classes.wrapper_help_center}>
							<Helpcenter text='How do I manage orders?' url='https://pidge.in/helpcenter-order-allorders' style={{ marginTop: 40 }} />
						</div>
					)} */}
					{order_list.length == 0 && !_.isEmpty(filter_object) && (
						<div className='d-flex justify-content-center align-items-center' style={{ marginTop: '15%', flexDirection: 'column', gap: 10 }}>
							<NoResult text='No order found' btn_onclick={() => history.replace({ search: '?type=' + ALL_ORDERS_MENU_ITEMS.Terminal.id })} />
						</div>
					)}
				</div>
			</div>
			{show_order_details && <OrderModal id={selected_pb_id} close={() => set_show_order_details(false)} />}
			{show_filters && (
				<Filters
					source='all_orders'
					all_filters={all_filters}
					existing_filters={filter_object}
					set_selected_filters={(filters) => {
						history.push(Routes.ALL_ORDERS.path + `?type=${ALL_ORDERS_MENU_ITEMS.Terminal.id}&` + qs.stringify({ filter: filters }));
					}}
					close={() => set_show_filters(false)}
					title='Completed'
					handle_export={handle_export}
					report_items={report_items}
				/>
			)}
		</>
	);
};

export default Terminal;
