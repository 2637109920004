import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';

const useStyles = makeStyles({
	wrapper: { flex: 1, display: 'flex', columnGap: '3%' },
	left_half_wrap: { flex: 1, display: 'flex', flexDirection: 'column', padding: '25px 0px' },
	step_number_wrap: {
		width: 30,
		height: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.colors.lightPink2,
		borderRadius: 20,
	},
	type_btn: { borderRadius: 20, marginRight: 8, height: 34 },
	right_half_wrap: { flex: 1, padding: 40, position: 'relative' },
	pidge_ntwk_btn: {
		position: 'absolute',
		right: 50,
		top: 50,
		zIndex: 100,
		padding: '5px 7px',
		borderRadius: 8,
	},

	zone_menu_item: {
		height: 56,
	},
});

export default useStyles;
