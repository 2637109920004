import React, { useEffect } from 'react';
import qs from 'qs';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import clsx from 'clsx';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import Routes from 'resources/Routes';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';
import Reprioritizing from './components/Reprioritising';
import utils from 'resources/utils';
import AppClick from './components/AppClick';
import RiderHandover from './components/RiderHandover';
import AppConfigurations from './components/AppConfigurations';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '32px 32px 0 32px',
		display: 'flex',
		height: '100%',
		position: 'relative',
		flexDirection: 'column',
	},
	header: {
		display: 'flex',
		height: 40,
		alignItems: 'center',
	},
	heading_text: {
		fontSize: 22,
		color: theme.colors.black,
		marginRight: 15,
	},
	all_settings: {
		color: theme.colors.primary,
		fontSize: 14,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	body_section: { display: 'flex', marginTop: 32, flex: 1, overflowY: 'hidden' },
	content_section: {
		backgroundColor: theme.colors.white,
		borderTopLeftRadius: 50,
		flex: 1,
		marginLeft: 20,
		padding: 30,
		overflowY: 'auto',
	},
	sidebar: { width: '20%' },
	sub_container: { paddingBottom: 20, marginTop: 10, borderRight: `2px solid ${theme.colors.lightPurple}`, height: '100%' },
	bundle_list_item: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white,
		borderRadius: 8,
		marginTop: 10,
		marginRight: 16,
		border: `1px solid transparent`,
		cursor: 'pointer',
		'&:hover': { backgroundColor: theme.colors.lightPurple, border: `1px solid ${theme.colors.primary}` },
	},
	bundle_list_item_inactive: {
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 8,
		marginTop: 10,
		marginRight: 16,
		display: 'flex',
		justifyContent: 'space-between',
	},
	list_item: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: 10,
		height: 40,
	},
	sub_title: { marginLeft: 8, fontSize: 14 },
	active_class: { backgroundColor: theme.colors.lightPurple, border: `1px solid ${theme.colors.primary}` },
}));

export const SETTINGS_MENU = {
	reprioritize: {
		id: 'reprioritize',
		name: 'Reprioritize',
	},
	rider_app_click: {
		id: 'rider_app_click',
		name: 'App Click-Automate',
	},
	rider_handover: {
		id: 'rider_handover',
		name: 'Rider Handover',
	},
	app_configurations: {
		id: 'app_configurations',
		name: 'App Configurations',
	},
	inventory: {
		id: 'inventory',
		name: 'Inventory',
		locked: true,
	},
};

const RiderSettings = ({ history }) => {
	const query_object = qs.parse(history.location.search.slice(1));
	const classes = useStyles();

	const [business_settings, set_business_settings] = React.useState({});
	const [active_card_id, set_active_card_id] = React.useState(query_object.type || SETTINGS_MENU.geo_location.id);

	const navigate = (id) => {
		utils.track_event_for_analytics(`rider_setting_${id}`);
		history.push(Routes.RIDER_SETTINGS.path + `?type=${id}`);
	};

	useEffect(async () => {
		try {
			const res = await api_requests.get_business_account_details(utils._retrieveData('owner_id'));
			if (res.success) {
				set_business_settings(res.data);
			}
		} catch (err) {}
	}, []);

	useEffect(() => {
		if (!query_object.type) {
			return;
		}
		set_active_card_id(query_object.type);
	}, [query_object.type]);

	const handle_update_settings = async (new_setting) => {
		try {
			const res = await api_requests.update_business_account_details(utils._retrieveData('owner_id'), new_setting);
			if (res.success) {
				set_business_settings({
					...business_settings,
					...new_setting,
				});
				toast.success(res.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			}
		} catch (err) {}
	};

	if (_.isEmpty(business_settings)) {
		return null;
	}

	let active_comp = null;

	switch (active_card_id) {
		case SETTINGS_MENU.reprioritize.id:
			active_comp = <Reprioritizing reprioritization={business_settings.reprioritization} handle_update_settings={handle_update_settings} />;
			break;
		case SETTINGS_MENU.rider_app_click.id:
			active_comp = <AppClick />;
			break;
		case SETTINGS_MENU.rider_handover.id:
			active_comp = <RiderHandover />;
			break;
		case SETTINGS_MENU.app_configurations.id:
			active_comp = <AppConfigurations />;
			break;
		default:
			break;
	}

	return (
		<div className={classes.container}>
			<div className='align-items-center'>
				<div className='page_back_btn' onClick={() => history.push(Routes.SETTINGS.path)}>
					<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
				</div>
				<Text semi className={'global_header_text'}>
					Delivery Executive Settings
				</Text>
			</div>
			<div className={classes.body_section}>
				<div className={classes.sidebar}>
					<div className={classes.sub_container}>
						{_.map(SETTINGS_MENU, (item, key) => {
							const is_selected = active_card_id === item.id;
							return (
								<div
									className={clsx(
										`box-shadow ${item.locked ? classes.bundle_list_item_inactive : classes.bundle_list_item}`,
										is_selected && classes.active_class,
									)}
									key={`menu${key}`}
									onClick={(e) => {
										if (!item.locked) navigate(item.id);
									}}>
									<div className={classes.list_item}>
										<Text bold className={classes.sub_title} style={{ color: item.locked ? theme.colors.darkGrey2 : theme.colors.black }}>
											{item.name}
										</Text>
										<img
											style={{ alignSelf: 'center' }}
											src={is_selected ? ImageLinks.chevron_right_pink : item.locked ? ImageLinks.lock : ImageLinks.chevron_right_grey}
											width={item.locked ? 16 : 8}
											height={item.locked ? 16 : 8}
											alt='arrow'
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className={classes.content_section}>{active_comp}</div>
			</div>
		</div>
	);
};

export default RiderSettings;
