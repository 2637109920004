import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		position: 'absolute',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(102, 102, 102, 0.75)',
		zIndex: 3,
		display: 'flex',
		flexDirection: 'column',
	},
	confirm_box: {
		width: '50%',
		backgroundColor: theme.colors.white,
		height: 'auto',
		borderRadius: '28px',
		padding: 24,
		display: 'flex',
		gap: 32,
		flexDirection: 'column',
	},
	cancel_btn: {
		backgroundColor: theme.colors.white,
		borderRadius: '30px',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		alignSelf: 'flex-end',
		marginLeft: 'auto',
		'&:hover': {
			color: theme.colors.white,
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	confirm_box_text: { display: 'flex', gap: 10, justifyContent: 'space-between' },
	confirm_button_box: { display: 'flex', gap: 10, justifyContent: 'flex-end' },
	update_seq_btn: { height: 40, borderRadius: 20 },
}));

function ConfirmationBox({ on_close, on_confirm, taget_order, from_order, orders = [], is_add_only = false }) {
	const classes = useStyles();
	return (
		<div className={classes.backdrop}>
			<div className={classes.confirm_box}>
				<div className={classes.confirm_box_text}>
					<img src={ImageLinks.move_order} style={{ width: 48, height: 48 }}></img>
					<div>
						<Text semi style={{ fontSize: 18 }}>
							Move Order to New Route
						</Text>
						<Text>
							Are you sure you want to move{' '}
							<Text semi component={'span'}>
								{orders.length > 1 ? `${orders.length} orders` : orders[0]}
							</Text>{' '}
							{!is_add_only && (
								<>
									{' '}
									<Text component={'span'}>from</Text>{' '}
									<Text semi component={'span'}>
										{from_order}
									</Text>
								</>
							)}{' '}
							to{' '}
							<Text semi component={'span'}>
								{taget_order}
							</Text>
							? Group sequence will be auto-adjusted.
						</Text>
					</div>
					<img src={ImageLinks.crossBlack} onClick={on_close} style={{ width: 12, height: 12, cursor: 'pointer' }}></img>
				</div>
				<div className={classes.confirm_button_box}>
					<ContainedButton className={classes.cancel_btn} onClick={on_close}>
						<Text
							semi
							style={{
								color: 'inherit',
								marginLeft: 5,
							}}>
							Cancel
						</Text>
					</ContainedButton>
					<ContainedButton className={classes.update_seq_btn} onClick={on_confirm}>
						<Text semi>Move Order</Text>
					</ContainedButton>
				</div>
			</div>
		</div>
	);
}

export default ConfirmationBox;
