import Text from 'modules/Text';
import _ from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import Switch from 'modules/Switch';
import CreateNetworkContext from 'views/NetworksFlow/context';
import OutlinedButton from 'modules/OutlinedButton';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	container: {
		padding: 20,
		background: 'white',
		width: 580,
		height: 323,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_text: { fontSize: 20 },
	cta_btn: {
		width: 100,
		borderRadius: 30,
		height: 40,
	},
}));

const AddRoleModal = ({ close, show_remove_access = false, on_revoke_click = () => {} }) => {
	const classes = useStyles();

	const { can_lend_rider, set_can_lend_rider, can_place_order, set_can_place_order, can_manage, set_can_manage, handle_remove_access } =
		useContext(CreateNetworkContext);
	const [can_lend_rider_local, set_can_lend_rider_local] = useState(can_lend_rider);
	const [can_place_order_local, set_can_place_order_local] = useState(can_place_order);
	const [can_manage_local, set_can_manage_local] = useState(can_manage);

	const handle_update = () => {
		set_can_lend_rider(can_lend_rider_local);
		set_can_place_order(can_place_order_local);
		set_can_manage(can_manage_local);
		close();
	};

	const btn_disabled = useMemo(() => {
		let value = true;

		if (
			(can_lend_rider != can_lend_rider_local || can_place_order != can_place_order_local || can_manage != can_manage_local) &&
			(can_lend_rider_local || can_place_order_local || can_manage_local)
		)
			value = false;

		return value;
	}, [can_lend_rider, can_place_order, can_manage, can_lend_rider_local, can_place_order_local, can_manage_local]);

	return (
		<Modal open={true} onClose={close} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className='d-flex justify-content-between'>
					<Text bold className={classes.header_text}>
						Participant Role
					</Text>
					<img src={ImageLinks.crossBlack} onClick={close} style={{ cursor: 'pointer' }} alt='' />
				</div>

				<div className='align-items-center justify-content-between' style={{ marginTop: 16 }}>
					<div>
						<Text bold style={{ fontSize: 14 }}>
							Provide Supply
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Authorize participant's delivery executives to do network orders
						</Text>
					</div>
					<Switch
						checked={can_lend_rider_local}
						onChange={() => {
							set_can_lend_rider_local((prev) => !prev);
						}}
					/>
				</div>

				<div className='align-items-center justify-content-between' style={{ marginTop: 20 }}>
					<div>
						<Text bold style={{ fontSize: 14 }}>
							Place Orders
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Participant can place orders with this network
						</Text>
					</div>
					<Switch
						checked={can_place_order_local}
						onChange={() => {
							set_can_place_order_local((prev) => !prev);
						}}
					/>
				</div>

				<div className='align-items-center justify-content-between' style={{ marginTop: 20 }}>
					<div>
						<Text bold style={{ fontSize: 14 }}>
							Manage
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Authorize participant's to manage network activities
						</Text>
					</div>
					<Switch
						checked={can_manage_local}
						onChange={() => {
							set_can_manage_local((prev) => !prev);
						}}
					/>
				</div>
				<div className='d-flex justify-content-center align_items-center' style={{ marginTop: 40, gap: 10 }}>
					{show_remove_access && (
						<Button
							type='outlined'
							text='Remove Access'
							onClick={on_revoke_click}
							size='large'
							style={{ width: 'fit-content', color: theme.colors.red, borderColor: theme.colors.red }}
						/>
					)}
					<Button disabled={btn_disabled} type='round' text='Update' size='large' onClick={handle_update} style={{ width: 110 }} />
				</div>
			</div>
		</Modal>
	);
};

export default AddRoleModal;
