import { makeStyles } from '@material-ui/core';
import utils from 'resources/utils';

const useStyles = makeStyles((theme) => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', background: theme.colors.white, overflow: 'hidden' },
	header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 32 },
	header_btn_text: { marginLeft: 10, fontSize: 18 },
	header_btn_icon: { width: 12, height: 12 },
	tab_wrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingBottom: 8,
		borderBottom: `1px solid ${theme.colors.lightPurple}`,
	},
	list_wrap: {
		flex: 1,
		maxHeight: '80vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	details_wrap: {
		borderRadius: 8,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		minHeight: '78vh',
		overflow: 'hidden',
		height: '78vh',
	},
	content: { display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' },
	empty_content: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: -20,
	},
	subject_item_wrap: {
		minWidth: 93,
		height: 33,
		paddingLeft: 16,
		paddingRight: 16,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		cursor: 'pointer',
	},
	autoIcon: {
		width: 35,
		height: 35,
		marginLeft: 10,
		borderRadius: '100%',
	},

	left_wrap: {
		display: 'flex',
		flexDirection: 'column',
	},
	network_button: {
		position: 'absolute',
		zIndex: 1000,
		bottom: 33,
		left: 21,
		height: 36,
		width: 136,
		padding: '5px 10px',
		borderRadius: 50,
	},
	network_item_wrap: {
		borderRadius: 6,
		border: `1px solid ${theme.colors.lightGrey5}`,
		marginBottom: 16,
		padding: 12,
		cursor: 'pointer',
	},
	monogram_wrap: {
		width: 40,
		height: 40,
		borderRadius: 40,
		marginRight: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	edit_icon: {
		position: 'absolute',
		bottom: 0,
		right: 25,
		height: 16,
		width: 16,
		cursor: 'pointer',
		backgroundColor: theme.colors.white,
		borderRadius: 8,
		border: `1px solid ${theme.colors.lightGrey5}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	divider: {
		height: 1,
		width: '100%',
		backgroundColor: theme.colors.lightPurple,
		marginTop: 10,
		marginBottom: 10,
	},
	rider_text: {
		display: 'inline-flex',
		cursor: 'pointer',
		alignItems: 'center',
		padding: 2,
		gap: 6,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
			borderRadius: 2,
		},
	},
	invite_box: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 20,
		boxShadow: '0px 1.0727969408035278px 3.218390703201294px 0px rgba(13, 10, 44, 0.08)',
		borderRadius: 12,
		width: 380,
		marginTop: 40,
		marginBottom: 100,
	},
	role_pill: {
		padding: '2px 8px',
		color: theme.colors.primary,
		marginLeft: 5,
		backgroundColor: theme.colors.lightPurple,
		borderRadius: 4,
	},
	exit_img: {
		padding: 4,
		width: 24,
		height: 24,
		borderRadius: 12,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.lightPink,
		},
	},
	action_bar_wrap: {
		backgroundColor: 'rgba(242, 238, 248, 0.30)',
		padding: 12,
		borderBottom: `1px solid ${theme.colors.lightPink4}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	action_item: {
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 8,
		padding: '8px 12px',
		display: 'flex',
		justifyContent: 'space-between',
		flex: 1,
		cursor: 'pointer',
	},
	action_item_active: {
		border: `1px solid ${theme.colors.primary}`,
		borderRadius: 8,
		padding: '8px 12px',
		display: 'flex',
		justifyContent: 'space-between',
		flex: 1,
		backgroundColor: theme.colors.lightPurple,
		cursor: 'pointer',
	},
	bar_chart_icon_wrap: {
		height: 26,
		width: 26,
		borderRadius: 13,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			boxShadow: `0px 2px 16px rgba(102, 102, 102, 0.3)`,
			backgroundColor: theme.colors.primary,
			'& .purple-download': {
				display: 'none',
			},
			'& .white-download': {
				display: 'block !important',
			},
		},
	},
}));

export default useStyles;
