import React, { useState } from 'react';
import _ from 'lodash';
import utils from 'resources/utils';
import { UploadS3Document } from './UploadS3Document';
import ImageCropModal from './ImageCropModal';

const Upload = ({
	style = {},
	accepted_extensions,
	add_image,
	children,
	is_disabled = false,
	path = '/',
	aspect = 1,
	is_image_crop = false,
	track_event = '',
}) => {
	const inputRef = React.useRef();
	const [show_crop_modal, set_show_crop_modal] = useState(false);
	const [image, set_image] = useState(null);

	const handleChange = (e) => {
		let files = _.get(e, 'target.files') || e;

		_.each(files, (file) => {
			if (!is_image_crop) {
				on_save_image(file);
				return;
			}
			let reader = new FileReader();
			reader.onload = async (event) => {
				const img = new Image();
				img.src = event.target.result;
				img.onload = async () => {
					set_image(reader.result.toString());
					set_show_crop_modal(true);
				};
			};
			reader.readAsDataURL(file);
		});
	};

	const on_save_image = (file) => {
		let extension = '';
		if (file) {
			const reader = new FileReader();
			reader.onload = async (e) => {
				extension = _.split(file.name, '.');
				extension = extension[extension.length - 1];
				let fileInfo = {
					name: file.name,
					type: file.type,
					size: Math.round(file.size / 1000),
					base64: reader.result,
					file: file,
					extension: extension,
				};
				let file_path = `${path}.${extension}`;
				const res = await UploadS3Document(file_path, file);

				if (_.includes(accepted_extensions, fileInfo.type.split('/')[1].toLowerCase())) {
					if (_.isFunction(add_image)) add_image(fileInfo.extension.toLowerCase() == 'csv' ? fileInfo : res.data, inputRef);
					set_show_crop_modal(false);
				} else {
					alert('File should be of jpg, jpeg or png format');
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const open_file_selection = () => {
		if (track_event != '') {
			utils.track_event_for_analytics(track_event);
		}
		if (is_disabled || show_crop_modal) return;
		inputRef.current.value = null;
		inputRef.current.click();
	};

	return (
		<div
			style={{
				cursor: is_disabled ? 'auto' : 'pointer',
				...style,
			}}
			onClick={open_file_selection}>
			{children}
			<input type='file' ref={inputRef} onChange={handleChange} accept={accepted_extensions} style={{ display: 'none' }} />
			{show_crop_modal && (
				<ImageCropModal
					img_src={image}
					close={() => {
						set_show_crop_modal(false);
						set_image('');
					}}
					aspect={aspect}
					on_replace={() => {
						set_show_crop_modal(false);
						inputRef.current.click();
					}}
					on_save={on_save_image}
				/>
			)}
		</div>
	);
};

export default Upload;
