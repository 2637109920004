import React from 'react';
import Text from 'modules/Text';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles({
	table_header: {
		background: theme.colors.white,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		height: 41,
		borderRadius: 40,
		width: '100%',
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 16,
	},
	table_row_wrapper: {
		overflowY: 'auto',
		flex: 1,
		marginTop: 10,
		marginBottom: 10,
	},
	table_row_item: {
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		height: 58,
		padding: '12px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
			'& .team-delete': {
				display: 'block !important',
			},
		},
	},
	table_header_item: { fontSize: 14, color: theme.colors.darkGrey2 },
	user_icon_wrap: {
		width: 28,
		height: 28,
		borderRadius: 14,
		backgroundColor: theme.colors.lightPink,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	member_wrap: {
		height: 24,
		border: `1px solid ${theme.colors.darkPurple}`,
		borderRadius: 4,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 8,
		paddingRight: 8,
	},
	member_count_wrap: {
		minWidth: 16,
		minHeight: 16,
		borderRadius: 500,
		backgroundColor: theme.colors.lightPink2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	members_text: { fontSize: 12, color: theme.colors.darkPurple, marginLeft: 4, marginRight: 4 },
	detail_wrap: {
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		height: '95%',
		marginTop: 16,
		padding: 16,
	},
	role_row_wrap: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 0.6 },
});

const TeamDetail = ({ team_detail, handle_member_delete }) => {
	const classes = useStyles();
	return (
		<div style={{ display: 'flex', height: '95%' }}>
			<div style={{ flex: 0.8, marginRight: 43 }}>
				<div className={classes.table_header}>
					<Text bold className={classes.table_header_item} style={{ flex: 0.4 }}>
						User Name (ID)
					</Text>
					{/* <Text bold className={classes.table_header_item} style={{ flex: 0.2 }}>
						Last Active
					</Text> */}
					<Text bold className={classes.table_header_item} style={{ flex: 0.6 }}>
						Role
					</Text>
				</div>

				<div className={classes.table_row_wrapper}>
					{_.map(_.get(team_detail, 'data', []), (item, key) => {
						return (
							<div key={`rider${key}`} className={classes.table_row_item}>
								<div style={{ flex: 0.4 }}>
									<div className='d-flex'>
										<Text medium style={{ fontSize: 16 }}>{`${item.user_name} (${item.user_id})`}</Text>
									</div>
									<div style={{ display: 'flex', marginTop: 2 }}>
										<Text medium style={{ fontSize: 12, color: theme.colors.lightGrey6 }}>{`${
											item.user_email ? item.user_email + ' |' : ''
										} ${item.user_mobile}`}</Text>
									</div>
								</div>
								{/* <div style={{ flex: 0.2 }}>
									<Text medium style={{ fontSize: 14 }}>
										-
									</Text>
									<Text medium style={{ fontSize: 12, color: theme.colors.lightGrey6, marginTop: 2 }}></Text>
								</div> */}
								<div className={classes.role_row_wrap}>
									<Text medium style={{ fontSize: 16 }}>
										{item.role}
									</Text>
									<div style={{ cursor: 'ponter' }} onClick={() => handle_member_delete(key)}>
										<img src={ImageLinks.delete} style={{ display: 'none' }} className='team-delete' width={16} height={16} />
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div style={{ flex: 0.2, marginTop: 16, height: '95%' }}>
				<Text bold style={{ fontSize: 18, color: theme.colors.darkGrey2 }}>
					About Team
				</Text>
				<div className={classes.detail_wrap}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div>
								<div className={classes.user_icon_wrap}>
									<img src={ImageLinks.user_accent} width={16} height={16} />
								</div>
							</div>
							<Text bold style={{ fontSize: 14, marginLeft: 6 }}>
								{_.get(team_detail, 'team.title', '')}
							</Text>
						</div>
						<div>
							<div className={classes.member_wrap}>
								<div className={classes.member_count_wrap}>
									<Text bold style={{ fontSize: 10, color: theme.colors.darkPurple }}>
										{_.get(team_detail, 'data', []).length}
									</Text>
								</div>
								<Text bold className={classes.members_text}>
									Members
								</Text>
							</div>
						</div>
					</div>

					<div style={{ marginTop: 14, marginBottom: 9 }}>
						<Text medium style={{ fontSize: 12, collor: theme.colors.darkGrey2 }}>
							{_.get(team_detail, 'team.description', '')}
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TeamDetail;
