import React, { useMemo } from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import { Calendar } from 'react-multi-date-picker';
import IconButton from 'modules/IconButton';
import ImageLinks from 'assets/images/ImageLinks';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import theme from 'resources/theme';
import TimeInput from 'modules/TimeInput';
import ContainedButton from 'modules/ContainedButton';
import utils from 'resources/utils';
import './Datepicker.css';
import moment from 'moment';
import { useEffect } from 'react';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 460,
		height: '100%',
		width: 550,
		backgroundColor: 'white',
		borderTopLeftRadius: 50,
		paddingLeft: 30,
		paddingTop: 36,
		paddingRight: 60,
		zIndex: 1199,
		overflowY: 'auto',
		paddingBottom: 36,
	},
	header: {
		paddingBottom: 10,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	permanent_text: { fontSize: 14, marginLeft: 8, color: theme.colors.darkGrey2 },
	slot_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		flex: 1,
		gap: 20,
	},
	footer: {
		height: 100,
		width: 550,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 460,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingRight: 75,
		paddingLeft: 35,
	},
	permanent_wrap: {
		width: 350,
		marginLeft: 25,
		marginBottom: 20,
		borderRadius: 4,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		height: 120,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 10,
		paddingRight: 40,
	},
	permanent_desc: {
		fontSize: 10,
		color: theme.colors.primary,
	},
	slot_calendar_wrap: { flex: 0.5, display: 'flex', flexDirection: 'column' },
	slot_drop_wrap: { flex: 0.5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
	slot_section_title: { fontSize: 14, color: theme.colors.darkGrey2, marginBottom: 12 },
	calendar_btn_wrap: {
		height: 31,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderTop: `1px solid ${theme.colors.lightGrey2}`,
		marginLeft: 20,
		marginRight: 20,
	},
	plus_days_text: { fontSize: 12, color: theme.colors.lightPurple7, textDecoration: 'underline', cursor: 'pointer' },
	slot_label: { fontSize: 12, color: theme.colors.darkGrey2, marginBottom: 6 },
	added_slot_item_wrap: {
		height: 48,
		borderRadius: 6,
		marginTop: 12,
		padding: 16,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
	},
	clear_wrap: {
		width: 49,
		height: 22,
		borderRadius: 22,
		backgroundColor: theme.colors.lightPink4,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const AddRiderNetworkSlotsModal = ({ close, handlle_slots_save, permanent, slots }) => {
	const [is_permanent, set_is_permanent] = useState(permanent);
	const [selected_dates, set_selected_dates] = useState([]);
	const [added_slots, set_added_slots] = useState(slots);
	const [shift_from, set_shift_from] = useState('08:00');
	const [shift_to, set_shift_to] = useState('20:00');
	const [edit_index, set_edit_index] = useState(-1);
	const classes = useStyles();

	useEffect(() => {
		if (is_permanent) {
			set_selected_dates([]);
			set_added_slots([]);
			set_shift_from('08:00');
			set_shift_to('20:00');
		} else {
			set_added_slots(slots);
		}
	}, [is_permanent]);

	const handle_slot_item_add = () => {
		if (edit_index > -1) {
			const item = {
				dates: _.cloneDeep(selected_dates),
				start: shift_from,
				end: shift_to,
			};
			let _added_slots = _.cloneDeep(added_slots);
			_added_slots[edit_index] = item;
			set_added_slots(_added_slots);
			set_selected_dates([]);
			set_shift_from('08:00');
			set_shift_to('20:00');
			set_edit_index(-1);
			return;
		}
		const item = {
			dates: _.cloneDeep(selected_dates),
			start: shift_from,
			end: shift_to,
		};
		const _added_slots = _.cloneDeep(added_slots);
		_added_slots.push(item);
		set_added_slots(_added_slots);
		set_selected_dates([]);
		set_shift_from('08:00');
		set_shift_to('20:00');
	};

	const handle_slot_edit = (index) => {
		set_edit_index(index);
		const item = added_slots[index];
		set_selected_dates(item.dates);
		set_shift_from(item.start);
		set_shift_to(item.end);
	};

	const handle_slot_delete = (index) => {
		const _added_slots = _.cloneDeep(added_slots);
		_added_slots.splice(index, 1);
		set_added_slots(_added_slots);
	};

	const add_rider_slots = () => {
		close();
		handlle_slots_save({ is_permanent, added_slots });
	};

	const handle_change = (values) => {
		const formatted_date = _.map(values, (item) => item.toDate());
		set_selected_dates(formatted_date);
	};

	const handle_days_add = (days) => {
		const last_item = selected_dates[selected_dates.length - 1];
		const next_dates_arr = [];
		for (let i = 1; i <= days; i++) {
			let next_date = new Date(last_item.getTime() + i * 24 * 60 * 60 * 1000);
			next_dates_arr.push(next_date);
		}
		let _selecte_dates = _.cloneDeep(selected_dates);
		_selecte_dates = [..._selecte_dates, ...next_dates_arr];
		_selecte_dates = [...new Set(_selecte_dates)];
		_selecte_dates = Array.from(new Set(_selecte_dates.map((date) => date.getTime()))).map((time) => new Date(time));
		set_selected_dates(_selecte_dates);
	};

	const clear_all_dates = () => {
		set_selected_dates([]);
	};

	const btn_disabled = useMemo(() => {
		if (is_permanent) return false;
		return added_slots.length === 0;
	}, [is_permanent, added_slots]);

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={clsx(classes.container, 'box-shadow')}>
				<div className={classes.header}>
					<Text semi style={{ fontSize: 20 }}>
						Date & Time Slots
					</Text>

					<IconButton onClick={close} style={{ border: 'none' }}>
						<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
					</IconButton>
				</div>

				<div style={{ marginTop: 24 }}>
					<div className='align-items-center' style={{ marginBottom: 12 }}>
						<img
							onClick={() => set_is_permanent((prev) => !prev)}
							src={is_permanent ? ImageLinks.filledCheckbox : ImageLinks.emptyCheckbox}
							width={16}
							height={16}
						/>
						<Text bold className={classes.permanent_text}>
							Permanent Availability
							<Text component={'span'} semi style={{ fontSize: 12, marginLeft: 4 }}>
								(riders will be available on all days at all times)
							</Text>
						</Text>
					</div>

					{is_permanent && (
						<div className={classes.permanent_wrap}>
							<img src={ImageLinks.rider_light_purple} width={32} height={32} />
							<div style={{ marginLeft: 12 }}>
								<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
									Riders available on all days at all times
								</Text>
								<Text medium className={classes.permanent_desc}>
									Riders selected will be available for delivery throughout your business hours always.
								</Text>
							</div>
						</div>
					)}

					<div
						className={classes.slot_wrap}
						style={{
							opacity: is_permanent ? 0.15 : 1,
							pointerEvents: is_permanent ? 'none' : 'auto',
						}}>
						<div className={classes.slot_calendar_wrap}>
							<Text bold className={classes.slot_section_title}>
								Select Dates
							</Text>
							<Calendar
								multiple
								className={`closeer`}
								minDate={new Date()}
								value={selected_dates}
								onChange={handle_change}
								style={{
									border: `1px solid ${theme.colors.lightGrey7}`,
									backgroundColor: theme.colors.white,
									borderRadius: 8,
									boxShadow: `0px 1.9697px 3.93939px rgba(196, 196, 196, 0.3)`,
								}}
								shadow={false}
								renderButton={(direction, handleClick) => (
									<img
										className='cursor-pointer'
										onClick={handleClick}
										src={ImageLinks.chevronLeft}
										width={16}
										height={16}
										style={{
											transform: `rotate(${direction === 'right' ? 180 : 0}deg)`,
											marginRight: 20,
										}}
									/>
								)}
								mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => {
									let props = {};

									props.style = {
										borderRadius: '15px',
										fontSize: 14,
										fontFamily: 'Hk Grotesk Medium',
									};
									return props;
								}}>
								<div className={classes.calendar_btn_wrap}>
									<div className='align-items-center'>
										<img src={ImageLinks.add_light_purple} width={12} height={12} style={{ marginRight: 1.5 }} />
										<Text onClick={() => handle_days_add(3)} semi className={classes.plus_days_text} style={{ marginRight: 15 }}>
											3 days
										</Text>
										<img src={ImageLinks.add_light_purple} width={12} height={12} style={{ marginRight: 1.5 }} />
										<Text onClick={() => handle_days_add(7)} semi className={classes.plus_days_text}>
											7 days
										</Text>
									</div>

									<div className={classes.clear_wrap} onClick={clear_all_dates}>
										<Text semi style={{ fontSize: 12, color: theme.colors.lightPurple7 }}>
											Clear
										</Text>
									</div>
								</div>
							</Calendar>
						</div>

						<div className={classes.slot_drop_wrap}>
							<div>
								<Text bold className={classes.slot_section_title}>
									Time Slot
								</Text>
								<Text semi className={classes.slot_label}>
									Start Time*
								</Text>
								<TimeInput value={shift_from} handleChange={(val) => set_shift_from(val)} />
								<Text semi className={classes.slot_label} style={{ marginTop: 20 }}>
									End Time*
								</Text>
								<TimeInput value={shift_to} handleChange={(val) => set_shift_to(val)} />
							</div>
							<Button
								type='round'
								text={edit_index > -1 ? 'Update' : 'Add'}
								size='small'
								onClick={handle_slot_item_add}
								disabled={selected_dates.length === 0 || moment(shift_from, 'HH:mm').diff(moment(shift_to, 'HH:mm')) > 0}
								left_icon={selected_dates.length === 0 ? ImageLinks.addGrey : ImageLinks.addWhite}
								style={{ width: 'fit-content' }}
							/>
						</div>
					</div>
					<div style={{ paddingBottom: 20 }}>
						{_.map(added_slots, (slot_item, slot_index) => (
							<div key={`slot_item_${slot_index}`} className={classes.added_slot_item_wrap}>
								<Text semi style={{ fontSize: 16, color: theme.colors.black }}>
									{slot_item.dates.length} Dates
									<Text component={'span'} style={{ color: theme.colors.lightGrey2, marginLeft: 4, marginRight: 4 }}>
										|
									</Text>
									{`${slot_item.start} - ${slot_item.end}`}
								</Text>

								<div className='align-items-center'>
									<img src={ImageLinks.edit} className='cursor-pointer' width={16} height={16} onClick={() => handle_slot_edit(slot_index)} />
									<img
										src={ImageLinks.delete}
										className='cursor-pointer'
										width={18}
										height={18}
										style={{ marginLeft: 16 }}
										onClick={() => handle_slot_delete(slot_index)}
									/>
								</div>
							</div>
						))}
					</div>
					<div className={classes.footer}>
						<div className='align-items-center'>
							<img src={ImageLinks.exclamation_purple} width={14} height={14} style={{ marginRight: 6 }} />
							<Text medium style={{ fontSize: 12 }}>
								Rider Roster implication disclaimer statement
							</Text>
						</div>
						<Button type='round' text='Save' size='large' disabled={btn_disabled} onClick={add_rider_slots} style={{ width: 110 }} />
					</div>
				</div>
			</div>
		</>
	);
};

export default AddRiderNetworkSlotsModal;
