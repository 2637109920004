import { makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useEffect, useRef, useState } from 'react';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import utils from 'resources/utils';
import EmptyState from './EmptyState';

const useStyles = makeStyles((theme) => ({
	main_container: {
		backgroundColor: theme.colors.white,
		padding: 12,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		borderRadius: 12,
		position: 'relative',
	},
	divider: { height: 1, width: '100%', backgroundColor: theme.colors.lightGrey5 },
	flex_box: { display: 'flex', minHeight: '50vh', gap: 20, marginTop: 20 },
	flex_map: { width: '40%', height: '50vh', flex: '1' },
	list_flex: { display: 'flex', justifyContent: 'space-between', padding: '12px 10px' },
	header_text: { textAlign: 'left', flex: '1' },
	zone_detail_box: {
		flex: 1.5,
		position: 'relative',
	},
	zone_list_box: {
		height: '50vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple4,
			borderRadius: 5,
		},
	},
	heading_text: { color: theme.colors.darkGrey4, fontSize: 14 },
}));

function OrderZones({ master_zone_data, business_zone_data }) {
	const classes = useStyles();
	const theme = useTheme();
	const map = useRef(null);
	const business_map = useRef(null);
	const [active, set_active] = useState(null);
	const [master_zones, set_master_zones] = useState({});
	const [business_zones_coordinates, set_business_zones_coordinates] = useState(null);

	const initialize = () => {
		if (!map.current) {
			map.current = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
				center: { lat: Number(20.5937), lng: Number(78.9629) },
				zoom: 4.6,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});
		}

		_.map(master_zones, (zone) => {
			const polygon_coords = _.map(_.get(zone, 'geometry.coordinates[0]', []), (coord) => {
				return { lat: coord[0], lng: coord[1] };
			});

			const polygon = new window.google.maps.Polygon({
				paths: polygon_coords,
				strokeColor: theme.colors.primary,
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: theme.colors.primary,
				fillOpacity: 0.35,
			});

			polygon.setMap(map.current);
		});
	};

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, [master_zones]);

	const handle_master_zone_click = (id) => {
		if (active === id) {
			set_active(null);
		} else {
			set_active(id);
		}
	};

	const get_master_zones = async () => {
		try {
			const master_zones = await api_requests.get_master_zones(utils._retrieveData('owner_id'));
			if (master_zones.success) {
				set_master_zones(master_zones.data);
			}
			return Promise.resolve(master_zones);
		} catch (err) {}
	};

	const get_sub_zones_coordinates = async () => {
		try {
			const sub_zones = await api_requests.get_sub_zones_geometry(active);
			if (sub_zones.success) {
				set_business_zones_coordinates(sub_zones.data);
			}
		} catch (err) {}
	};

	const initialize_businesszones_map = () => {
		if (business_zones_coordinates.length === 0) {
			business_map.current = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
				center: { lat: Number(20.5937), lng: Number(78.9629) },
				zoom: 4.6,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});
		} else {
			business_map.current = new window.google.maps.Map(document.getElementById('map_canvas_address'), {
				center: {
					lat: Number(_.get(business_zones_coordinates[0], 'focal.coordinates[0]')),
					lng: Number(_.get(business_zones_coordinates[0], 'focal.coordinates[1]')),
				},
				zoom: 9,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});

			_.forEach(
				_.map(business_zones_coordinates, (data) => {
					return {
						...data,
						color: '#' + Math.floor(Math.random() * 16777215).toString(16),
					};
				}),
				(zone) => {
					const polygon_coords = _.map(_.get(zone, 'geometry.coordinates[0]', []), (coord) => {
						return { lat: coord[0], lng: coord[1] };
					});

					const polygon = new window.google.maps.Polygon({
						paths: polygon_coords,
						strokeColor: zone.color,
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillColor: zone.color,
						fillOpacity: 0.35,
					});
					polygon.setMap(business_map.current);
				},
			);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			if (business_zones_coordinates) initialize_businesszones_map();
		}, 800);
	}, [business_zones_coordinates]);

	useEffect(() => {
		get_master_zones();
	}, []);

	useEffect(() => {
		if (active) {
			get_sub_zones_coordinates();
		}
	}, [active]);

	return (
		<div className={classes.main_container}>
			<Text style={{ marginBottom: 12 }} bold className={classes.heading_text}>
				Orders by Zone
			</Text>
			<div className={classes.divider}></div>
			<div className={classes.flex_box}>
				<div className={classes.flex_map}>
					<div id='map_canvas_address' className='map-canvas-wrapper rider-location card' style={{ height: '100%', borderRadius: 12 }}></div>
				</div>
				<div className={classes.zone_detail_box}>
					<div className={classes.list_flex}>
						<Text className={classes.header_text} style={{ textAlign: 'left' }}>
							Zone
						</Text>
						<Text className={classes.header_text}>Orders</Text>
						<Text className={classes.header_text}>Order Value</Text>
					</div>
					<div className={classes.zone_list_box}>
						{_.map(
							_.filter(master_zone_data, (master_zone) => {
								return !_.isEmpty(master_zone._id);
							}),
							(master_zone, index) => {
								return (
									<div style={{ borderBottom: '1px solid ' + theme.colors.lightGrey5 }} key={index}>
										<div
											className={classes.list_flex}
											onClick={() => handle_master_zone_click(master_zone._id.id)}
											key={index}
											style={{ backgroundColor: active === master_zone._id.id ? theme.colors.lightPurple9 : '' }}>
											<Text className={classes.header_text} style={{ textAlign: 'left', textDecoration: 'underline' }}>
												{_.get(master_zone, '_id.id') === -1 ? 'No-Zone' : master_zone._id.title}
											</Text>
											<Text className={classes.header_text}>{master_zone.total_orders}</Text>
											<Text className={classes.header_text}>{utils.format_currency(master_zone.billed_amount)}</Text>
										</div>
										{active === master_zone._id.id &&
											active &&
											_.map(
												_.filter(master_zone.business_zone, (business_zone) => {
													return !_.isEmpty(business_zone);
												}),
												(business_zone, index) => {
													return (
														<div className={classes.list_flex} key={index}>
															<Text className={classes.header_text} style={{ textAlign: 'left' }}>
																{_.get(business_zone, 'id') === -1 ? 'No-Zone' : business_zone.title}
															</Text>
															<Text className={classes.header_text}>
																{_.get(
																	_.filter(business_zone_data, (business_zone_data) => {
																		return (
																			business_zone_data._id.id === business_zone.id &&
																			business_zone_data._id.master_zone_id === active
																		);
																	})[0],
																	'total_orders',
																	'-',
																)}
															</Text>
															<Text className={classes.header_text}>
																{utils.format_currency(
																	_.get(
																		_.filter(business_zone_data, (business_zone_data) => {
																			return (
																				business_zone_data._id.id === business_zone.id &&
																				business_zone_data._id.master_zone_id === active
																			);
																		})[0],
																		'billed_amount',
																		'-',
																	),
																)}
															</Text>
														</div>
													);
												},
											)}
									</div>
								);
							},
						)}
					</div>
					{_.filter(master_zone_data, (master_zone) => {
						return !_.isEmpty(master_zone._id);
					}).length === 0 && <EmptyState />}
				</div>
			</div>
		</div>
	);
}

export default OrderZones;
