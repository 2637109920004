import { ClickAwayListener, Divider, Popper, makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React, { useContext, useEffect, useRef, useState } from 'react';
import OrderModalContext from '../OrderModalContext';
import _ from 'lodash';
import 'animate.css';
import clsx from 'clsx';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		borderRadius: 8,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		padding: 12,
		marginTop: 20,
	},
	menu_icon: {
		'&:hover': {
			'& .light-purple': {
				display: 'none !important',
			},
			'& .primary-purple': {
				display: 'block !important',
			},
		},
	},
	menu_wrap: {
		backgroundColor: theme.colors.white,
		boxShadow: '0px 1px 10px 1px rgba(202, 202, 202, 1)',
		borderRadius: 6,
	},
}));

function ShippingDetails() {
	const { data, set_show_address_modal, set_edit_address_type, set_show_delivery_modal, active_step, send_tracking_link, link_sent_to } =
		useContext(OrderModalContext);
	const classes = useStyles();
	const theme = useTheme();
	const menu_ref = useRef(null);
	const menu_ref_2 = useRef(null);

	const [show_menu, set_show_menu] = useState(false);
	const [menu_type, set_menu_type] = useState('');

	const handle_address_update = () => {
		utils.track_event_for_analytics(`${menu_type}_` + constant.TRACKING_EVENTS.ADDRESS_UPDATE);
		set_show_address_modal(true);
		set_edit_address_type(menu_type);
		set_show_menu(false);
	};

	const handle_contact_update = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.CONTACT_UPDATE + `_${menu_type}`);
		set_show_delivery_modal(true);
		set_edit_address_type(menu_type);
		set_show_menu(false);
	};

	const _statuses = data.fulfillment
		? _.map(data.fulfillment.logs, (logs) => {
				return logs.status;
		  })
		: [];

	return (
		<div className={classes.wrapper}>
			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center' style={{ gap: 5 }}>
					<img src={ImageLinks.rider_light_purple} height={16} width={16} />
					<Text bold component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						Shipping Details
					</Text>
				</div>
			</div>
			<Divider style={{ margin: '10px 0' }} />
			<div className='d-flex justify-content-between' style={{ alignItems: 'flex-start' }}>
				<div className='d-flex' style={{ alignItems: 'flex-start', gap: 10 }}>
					<img src={ImageLinks.rider_pickup} height={20} width={20}></img>
					<div>
						<Text bold style={{ fontSize: 16 }}>
							{_.get(data, 'sender_detail.name', '-')}
						</Text>
						<Text bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							{_.get(data, 'sender_detail.mobile', '-')}
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							{_.get(data, 'sender_detail.address.address_line_1', '-')} {_.get(data, 'sender_detail.address.address_line_2', '')}
						</Text>
					</div>
				</div>
				<div
					ref={menu_ref}
					style={{ cursor: 'pointer' }}
					className={classes.menu_icon}
					onClick={(e) => {
						set_show_menu((prev) => !prev);
						set_menu_type('sender');
					}}>
					<img className='light-purple' style={{ display: 'block' }} src={ImageLinks.three_dots_light_purple} />
					<img className='primary-purple' style={{ display: 'none' }} src={ImageLinks.three_dots_primary} />
				</div>
				<Popper
					anchorEl={menu_ref.current}
					open={show_menu && menu_type === 'sender'}
					style={{ zIndex: 1202, marginRight: 30, cursor: 'pointer' }}
					onClose={() => set_show_menu(false)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}>
					<ClickAwayListener onClickAway={() => set_show_menu(false)}>
						<div className={classes.menu_wrap} style={{ backgroundColor: theme.colors.white }}>
							{data.status === 1 && (
								<div
									className='d-flex'
									style={{ padding: '8px 10px', gap: 5, borderBottom: `1px solid ${theme.colors.lightGrey5}` }}
									onClick={handle_contact_update}>
									<img src={ImageLinks.edit_filled_light_purple}></img>
									<Text medium>Contact Detail</Text>
								</div>
							)}
							{data.status === 1 && (
								<div
									className='d-flex'
									style={{ padding: '8px 10px', gap: 5, borderBottom: `1px solid ${theme.colors.lightGrey5}` }}
									onClick={handle_address_update}>
									<img src={ImageLinks.edit_filled_light_purple}></img>
									<Text medium>Address Detail</Text>
								</div>
							)}
							{(data.status == 3 || data.status == 4) && (
								<div
									className='d-flex'
									style={{ padding: '8px 10px', gap: 5 }}
									onClick={() => {
										send_tracking_link('sender');
									}}>
									<img
										src={_.includes(link_sent_to, 'sender') ? ImageLinks.tickCirclePurpleSolid : ImageLinks.share_filled}
										height={18}
										width={18}></img>

									<Text medium>{_.includes(link_sent_to, 'sender') ? 'Link Shared' : 'Tracking link'}</Text>
								</div>
							)}
						</div>
					</ClickAwayListener>
				</Popper>
			</div>
			<div className='d-flex justify-content-between' style={{ alignItems: 'flex-start', marginTop: 30 }}>
				<div className='d-flex' style={{ alignItems: 'flex-start', gap: 10 }}>
					<img src={ImageLinks.marker_down_arrow} height={20} width={20}></img>
					<div>
						<Text bold style={{ fontSize: 16 }}>
							{_.get(data, 'customer_detail.name', '-')}
						</Text>
						<Text bold style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							{_.get(data, 'customer_detail.mobile', '-')}
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							{_.get(data, 'customer_detail.address.address_line_1', '-')} {_.get(data, 'customer_detail.address.address_line_2', '')}
						</Text>
					</div>
				</div>
				<div
					ref={menu_ref_2}
					style={{ cursor: 'pointer' }}
					className={classes.menu_icon}
					onClick={(e) => {
						set_show_menu((prev) => !prev);
						set_menu_type('reciever');
					}}>
					<img className='light-purple' style={{ display: 'block' }} src={ImageLinks.three_dots_light_purple} />
					<img className='primary-purple' style={{ display: 'none' }} src={ImageLinks.three_dots_primary} />
				</div>
				<Popper
					anchorEl={menu_ref_2.current}
					open={show_menu && menu_type === 'reciever'}
					style={{ zIndex: 1202, marginRight: 30, cursor: 'pointer' }}
					onClose={() => set_show_menu(false)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}>
					<ClickAwayListener onClickAway={() => set_show_menu(false)}>
						<div className={classes.menu_wrap} style={{ backgroundColor: theme.colors.white }}>
							{(data.status === 1 || _.get(data, 'fulfillment.channel.id') == '-2') &&
								active_step != 6 &&
								_.get(data, 'owner.id', '') == utils._retrieveData('owner_id') && (
									<div
										className='d-flex'
										style={{ padding: '8px 10px', gap: 5, borderBottom: `1px solid ${theme.colors.lightGrey5}` }}
										onClick={handle_contact_update}>
										<img src={ImageLinks.edit_filled_light_purple}></img>
										<Text medium>Contact Detail</Text>
									</div>
								)}
							{(data.status === 1 || _.get(data, 'fulfillment.channel.id') == '-2') &&
								active_step != 6 &&
								_.get(data, 'owner.id', '') == utils._retrieveData('owner_id') && (
									<div
										className='d-flex'
										style={{ padding: '8px 10px', gap: 5, borderBottom: `1px solid ${theme.colors.lightGrey5}` }}
										onClick={handle_address_update}>
										<img src={ImageLinks.edit_filled_light_purple}></img>
										<Text medium>Address Detail</Text>
									</div>
								)}
							{(data.status == 3 || data.status == 4) && (
								<div
									className='d-flex'
									style={{ padding: '8px 10px', gap: 5 }}
									onClick={() => {
										send_tracking_link('customer');
									}}>
									<img
										src={_.includes(link_sent_to, 'customer') ? ImageLinks.tickCirclePurpleSolid : ImageLinks.share_filled}
										height={18}
										width={18}></img>
									<Text medium>{_.includes(link_sent_to, 'customer') ? 'Link Shared' : 'Tracking link'}</Text>
								</div>
							)}
						</div>
					</ClickAwayListener>
				</Popper>
			</div>
		</div>
	);
}

export default ShippingDetails;
