import { Divider, FormControl, FormControlLabel, Popover, Radio, RadioGroup, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import ColorPicker from 'modules/ColorPicker';
import OutlinedTextField from 'modules/OutlinedTextField';
import _ from 'lodash';
import ConfirmClose from 'modules/modals/ConfirmClose';
import { useSelector } from 'react-redux';
import EndUserPreview from './EndUserPreview';
import utils from 'resources/utils';
import Switch from 'modules/Switch';
import Upload from 'modules/Upload';
import Button from 'modules/Button';
import { UploadS3Document } from 'modules/UploadS3Document';
import ImageCropModal from 'modules/ImageCropModal';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	bottom_banner_container: {
		display: 'flex',
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 5,
		marginTop: 20,
	},
	save_button: { position: 'absolute', bottom: 20, right: 30, width: 110 },
	banner_item_box: {
		border: `1px solid ${theme.colors.lightGrey5}`,
		padding: 12,
		marginTop: 8,
		width: 'fit-content',
		borderRadius: 8,
		display: 'flex',
		gap: 8,
		alignItems: 'center',
		cursor: 'pointer',
	},
	banner_item_box_active: {
		border: `1px solid ${theme.colors.primary}`,
		backgroundColor: theme.colors.lightPurple,
		padding: 12,
		marginTop: 8,
		width: 'fit-content',
		borderRadius: 8,
		display: 'flex',
		gap: 8,
		alignItems: 'center',
		cursor: 'pointer',
	},
	image_upload_wrap: {
		border: `1px dashed ${theme.colors.lightGrey2}`,
		minHeight: 140,
		width: 300,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		gap: 10,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 8,
		cursor: 'pointer',
	},
	image_wrap: {
		flex: 1,
		cursor: 'pointer',
		position: 'relative',
		minHeight: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 8,
	},
}));

function EndUserPage({ app_configs, query_object }) {
	const { selected_business_id: business_id_information } = useSelector(({ app }) => app);
	const classes = useStyles();
	const [logo_image, set_logo_image] = useState(null);
	const [is_banner, set_is_banner] = useState('0');
	const [banner_color, set_banner_color] = useState(theme.colors.primary);
	const [banner_text_color, set_banner_text_color] = useState(theme.colors.white);
	const [banner_content, set_banner_content] = useState('');
	const [website, set_website] = useState('');
	const [carousel_images, set_carousel_images] = useState([
		{ cta_link: '', image: '' },
		{ cta_link: '', image: '' },
		{ cta_link: '', image: '' },
	]);
	const [selected_banner_index, set_selected_banner_index] = useState(0);
	const [show_confirm_close, set_show_confirm_close] = useState(false);
	const [show_product_detail, set_show_product_detail] = useState(false);
	const [show_order_amount, set_show_order_amount] = useState(false);
	const [show_rider_route, set_show_rider_route] = useState(false);
	const [tracking_markers, set_tracking_markers] = useState([
		{ label: 'Rider Icon', image: '' },
		{ label: 'Pickup Icon', image: '' },
		{ label: 'Drop Icon', image: '' },
	]);
	const [selected_marker_index, set_selected_marker_index] = useState(0);

	const [marketing_image, set_marketing_image] = useState(null);
	const [show_crop_modal, set_show_crop_modal] = useState(false);

	const upload_img_ref = useRef(null);
	const white_label_configs = _.get(app_configs, `whitelabeling.tracking`, {});

	useEffect(() => {
		set_logo_image(_.get(app_configs, `whitelabeling.brand_logo`, null));
		if (_.get(app_configs, `whitelabeling.tracking.top_banner.content`, null)) {
			set_is_banner('1');
		}
		set_banner_color(_.get(app_configs, `whitelabeling.tracking.top_banner.color`, theme.colors.primary));
		set_banner_text_color(_.get(app_configs, `whitelabeling.tracking.top_banner.text_color`, theme.colors.white));
		set_show_product_detail(_.get(app_configs, `whitelabeling.tracking.show_product_detail`, false));
		set_show_order_amount(_.get(app_configs, `whitelabeling.tracking.show_order_amount`, false));
		set_show_rider_route(_.get(app_configs, `whitelabeling.tracking.show_rider_route`, false));
		set_banner_content(_.get(app_configs, `whitelabeling.tracking.top_banner.content`, ''));
		set_website(_.get(app_configs, `whitelabeling.tracking.website_link`, ''));
		let marker_img = _.get(app_configs, `whitelabeling.tracking.rider_marker_image`, null);
		let pickup_img = _.get(app_configs, `whitelabeling.tracking.pickup_marker_image`, null);
		let drop_img = _.get(app_configs, `whitelabeling.tracking.drop_marker_image`, null);
		const _tracking_markers = [
			{ label: 'Rider Icon', image: marker_img },
			{ label: 'Pickup Icon', image: pickup_img },
			{ label: 'Drop Icon', image: drop_img },
		];
		set_tracking_markers(_tracking_markers);
		let _carousel_images = _.get(app_configs, `whitelabeling.tracking.marketing_content`, [
			{ cta_link: '', image: '' },
			{ cta_link: '', image: '' },
			{ cta_link: '', image: '' },
		]);
		while (_carousel_images.length < 3) {
			_carousel_images.push({ cta_link: '', image: '' });
		}
		set_carousel_images(_carousel_images);
	}, [app_configs]);

	const handle_img_upload = async (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.src = e.target.result;
				img.onload = async () => {
					set_marketing_image(reader.result.toString());
					set_show_crop_modal(true);
				};
			};
			reader.readAsDataURL(file);
		}
	};

	const on_marketing_banner_save = async (file) => {
		const _carousel_images = _.cloneDeep(carousel_images);
		let extension = '';
		extension = _.split(file.name, '.');
		extension = extension[extension.length - 1];
		try {
			let path = `/bussines/${business_id_information}/whitelabelling/marketing/banner_image_${selected_banner_index}_${Date.now()}.${extension}`;
			const res = await UploadS3Document(path, file);
			if (res.success) {
				_carousel_images[selected_banner_index].image = res.data.url;
				set_carousel_images(_carousel_images);
				set_show_crop_modal(false);
			}
		} catch (err) {}
	};

	const handle_marker_img_upload = async (data) => {
		const _marker_images = _.cloneDeep(tracking_markers);
		_marker_images[selected_marker_index].image = data.url;
		set_tracking_markers(_marker_images);
	};

	const remove_marker_image = () => {
		const _marker_images = _.cloneDeep(tracking_markers);
		_marker_images[selected_marker_index].image = '';
		set_tracking_markers(_marker_images);
	};

	const handle_link_change = (e) => {
		const _carousel_images = _.cloneDeep(carousel_images);
		_carousel_images[selected_banner_index].cta_link = e.target.value;
		set_carousel_images(_carousel_images);
	};

	const remove_carousel_image = () => {
		const _carousel_images = _.cloneDeep(carousel_images);
		_carousel_images[selected_banner_index] = { cta_link: '', image: '' };
		set_carousel_images(_carousel_images);
	};

	const handle_update_config_event = () => {
		if (query_object.vendor_id) {
			handle_save_configs();
		} else {
			set_show_confirm_close(true);
		}
	};

	const handle_save_configs = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.WHITELABEL_SAVE_END_USER);
		try {
			const res = await api_requests.update_common_configs(
				{
					whitelabeling: utils.remove_null_and_empty_from_obj({
						..._.get(app_configs, `whitelabeling`, {}),
						tracking: utils.remove_null_and_empty_from_obj({
							show_order_amount,
							show_rider_route,
							show_product_detail,
							...(tracking_markers[0]?.image && { rider_marker_image: tracking_markers[0]?.image }),
							...(tracking_markers[1]?.image && { pickup_marker_image: tracking_markers[1]?.image }),
							...(tracking_markers[2]?.image && { drop_marker_image: tracking_markers[2]?.image }),
							...(logo_image && { business_logo: logo_image }),
							...(website && { website_link: website }),
							...(is_banner != '0' && { top_banner: { color: banner_color, content: banner_content, text_color: banner_text_color } }),
							marketing_content: _.filter(carousel_images, (item) => {
								return item.image != '';
							}),
							is_editable: null,
						}),
						is_editable: null,
					}),
				},
				{ ...(query_object?.vendor_id && { vendor_id: query_object?.vendor_id }) },
			);
			window.location.reload(true);
		} catch (err) {}
	};

	const is_button_disable = useMemo(() => {
		if (!white_label_configs.is_editable) {
			return true;
		}
		let is_disable = false;
		if (is_banner == '1') {
			if (banner_content == '') {
				is_disable = true;
			}
		}
		return is_disable;
	}, [is_banner, banner_content, white_label_configs]);

	const is_whitelabelling = useMemo(() => {
		if (white_label_configs.is_editable || query_object?.vendor_id) {
			return true;
		} else {
			return false;
		}
	}, []);

	return (
		<div className='d-flex' style={{ gap: 20 }}>
			<div style={{ flex: 1.2, opacity: is_whitelabelling ? 1 : 0.4, overflowY: 'auto', height: '85vh' }}>
				<Text bold style={{ color: theme.colors.primary, fontSize: 16 }}>
					End User Tracking
				</Text>
				<Text medium style={{ color: theme.colors.darkGrey2 }}>
					Improve user tracking, increase brand visibility, and strategically communicate your brand's marketing.
				</Text>
				<div className='d-flex' style={{ marginTop: 20 }}>
					<div style={{ flex: 1.5 }}>
						<Text semi>Business Logo </Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							Highlight your brand with the logo
						</Text>
					</div>
					<div style={{ flex: 1 }}>
						<img src={logo_image} style={{ width: '100%', height: 'auto' }} />
					</div>
				</div>
				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1 }}>
						<Text semi>Website Link</Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							Easily re-direct customers to your website
						</Text>
					</div>
					<OutlinedTextField
						size='small'
						label='Website URL'
						disabled={!is_whitelabelling}
						value={website}
						onChange={(e) => set_website(e.target.value)}></OutlinedTextField>
				</div>
				<Divider style={{ margin: '20px 0' }} />
				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1 }}>
						<Text semi>Top Banner</Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							Communicate information at the top of the page
						</Text>
					</div>
					<div>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby='demo-row-radio-buttons-group-label'
								name='row-radio-buttons-group'
								value={is_banner}
								onChange={(e) => {
									set_is_banner(e.target.value);
								}}>
								<FormControlLabel value={'1'} control={<Radio disabled={!is_whitelabelling} />} label='Active' />
								<FormControlLabel value={'0'} control={<Radio disabled={!is_whitelabelling} />} label='Inactive' />
							</RadioGroup>
						</FormControl>
					</div>
				</div>
				{is_banner == '1' && (
					<>
						<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
							<div style={{ flex: 1 }}>
								<Text semi>Banner Colour</Text>
								<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Colour for the banner background
								</Text>
							</div>
							<div>
								<ColorPicker disabled={!is_whitelabelling} color={banner_color} set_color={set_banner_color} />
							</div>
						</div>
						<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
							<div style={{ flex: 1 }}>
								<Text semi>Banner Text Colour</Text>
								<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Colour for content on banner
								</Text>
							</div>
							<div>
								<ColorPicker disabled={!is_whitelabelling} color={banner_text_color} set_color={set_banner_text_color} />
							</div>
						</div>
						<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
							<div style={{ flex: 1 }}>
								<Text semi>Banner content</Text>
								<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Content for the banner
								</Text>
							</div>
						</div>
						<OutlinedTextField
							placeholder={'Use this banner to communicate any offers or discounts'}
							disabled={!is_whitelabelling}
							size={'small'}
							style={{ width: '100%', marginTop: 20 }}
							value={banner_content}
							onChange={(e) => set_banner_content(e.target.value)}></OutlinedTextField>
					</>
				)}
				<Divider style={{ margin: '20px 0' }} />
				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1 }}>
						<Text semi>Marketing Content</Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							Upload up to 3 JPEG/PNG of with width 300px. Max. image size 100KB.
						</Text>
					</div>
				</div>
				<div className='d-flex justify-content-between' style={{ marginTop: 20, width: '100%' }}>
					<div>
						{_.map(carousel_images, (item, index) => {
							return (
								<div
									className={selected_banner_index == index ? classes.banner_item_box_active : classes.banner_item_box}
									onClick={() => {
										if (is_whitelabelling) set_selected_banner_index(index);
									}}
									key={`banner_item_${index}`}>
									<Text semi>{`Banner ${index + 1}`}</Text>
									<img src={ImageLinks.chevron_right_black} height={14} width={'auto'} />
								</div>
							);
						})}
					</div>
					<div className='d-flex' style={{ flexDirection: 'column', width: 300, marginRight: 10 }}>
						{!carousel_images[selected_banner_index].image && (
							<div className={classes.image_upload_wrap} onClick={() => upload_img_ref.current.click()}>
								<img src={ImageLinks.upload_light_purple} />
								<Text>Upload Image</Text>
								<ContainedButton style={{ borderRadius: 10, height: 20 }} disabled={!is_whitelabelling}>
									<Text semi style={{ fontSize: 10 }}>
										Select File
									</Text>
								</ContainedButton>
							</div>
						)}
						{carousel_images[selected_banner_index].image && (
							<div
								className='d-flex align-items-center justify-content-center'
								style={{ position: 'relative', backgroundColor: theme.colors.lightGrey5 }}>
								<img src={carousel_images[selected_banner_index].image} width={300} height={140} style={{ height: 140, width: 'auto' }} />
								<img
									src={ImageLinks.cross_filled}
									style={{ position: 'absolute', top: 2, right: 2, cursor: 'pointer' }}
									height={16}
									width={16}
									onClick={remove_carousel_image}
								/>
							</div>
						)}
						<OutlinedTextField
							label='CTA Link'
							disabled={!is_whitelabelling}
							size='small'
							style={{ marginTop: 20 }}
							value={carousel_images[selected_banner_index].cta_link}
							onChange={handle_link_change}></OutlinedTextField>
						<input
							disabled={!is_whitelabelling}
							style={{ display: 'none' }}
							type='file'
							ref={upload_img_ref}
							onChange={(e) => {
								handle_img_upload(e);
							}}
						/>
					</div>
				</div>

				<Divider style={{ margin: '20px 0' }} />
				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1 }}>
						<Text semi style={{ fontSize: 16 }}>
							Map Markers
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Upload JPEG/PNG of min. height 30px. Image size should no exceed 10MB.
						</Text>
					</div>
				</div>
				<div className='d-flex justify-content-between' style={{ marginTop: 20, width: '100%' }}>
					<div>
						{_.map(tracking_markers, (item, index) => {
							return (
								<div
									className={selected_marker_index == index ? classes.banner_item_box_active : classes.banner_item_box}
									style={{ width: 125, justifyContent: 'space-between' }}
									onClick={() => {
										if (is_whitelabelling) set_selected_marker_index(index);
									}}
									key={`marker_item_${index}`}>
									<Text semi style={{ fontSize: 14 }}>
										{item.label}
									</Text>
									<img src={ImageLinks.chevron_right_black} height={14} width={'auto'} />
								</div>
							);
						})}
					</div>
					<div className='d-flex' style={{ flexDirection: 'column', width: 300 }}>
						{_.isEmpty(tracking_markers[selected_marker_index].image) && (
							<Upload
								style={{
									flex: 1,
									borderRadius: 8,
									border: `1px dashed ${theme.colors.lightGrey2}`,
									height: 102,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								path={`/bussines/${business_id_information}/whitelabelling/map_marker/logo_image_${selected_marker_index}_${Date.now()}`}
								accepted_extensions='.jpg,.jpeg,.png'
								add_image={handle_marker_img_upload}
								component={tracking_markers[selected_marker_index].label.split(' ').join('_')}
								is_image_crop={true}>
								<img src={ImageLinks.upload_light_purple} width={32} height={32} />
								<Text medium style={{ marginTop: 4 }}>
									Upload Image
								</Text>
								<ContainedButton style={{ borderRadius: 30, marginTop: 4, height: 20 }}>
									<Text semi style={{ fontSize: 10 }}>
										Select File
									</Text>
								</ContainedButton>
							</Upload>
						)}

						{!_.isEmpty(tracking_markers[selected_marker_index].image) && (
							<div className={classes.image_wrap}>
								<img src={tracking_markers[selected_marker_index].image} style={{ width: 50, height: 50 }} />
								{is_whitelabelling && (
									<div style={{ position: 'absolute', top: 10, right: 10 }} onClick={remove_marker_image}>
										<img src={ImageLinks.red_cross_filled_circle} height={16} width={16} />
									</div>
								)}
							</div>
						)}
					</div>
				</div>

				<Divider style={{ margin: '20px 0' }} />
				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1 }}>
						<Text semi style={{ fontSize: 14 }}>
							Show Order Amount
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Display the order amount to the customer
						</Text>
					</div>

					<Switch checked={show_order_amount} onChange={() => set_show_order_amount((prev) => !prev)} />
				</div>
				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1 }}>
						<Text semi>Show Product Details</Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							Display all the items in the package being delivered to the customer
						</Text>
					</div>

					<Switch checked={show_product_detail} onChange={() => set_show_product_detail((prev) => !prev)} />
				</div>
				<div className='d-flex align-items-center' style={{ marginTop: 20 }}>
					<div style={{ flex: 1 }}>
						<Text semi>Show Rider Route</Text>
						<Text medium style={{ color: theme.colors.darkGrey2 }}>
							Display route when riders is out for delivery
						</Text>
					</div>

					<Switch checked={show_rider_route} onChange={() => set_show_rider_route((prev) => !prev)} />
				</div>
			</div>
			<div style={{ flex: 1, position: 'sticky', top: 20 }}>
				<EndUserPreview
					is_banner={is_banner}
					logo_image={logo_image}
					banner_color={banner_color}
					banner_text_color={banner_text_color}
					banner_content={banner_content}
					carousel_images={carousel_images}
					is_editable={is_whitelabelling}
				/>
			</div>
			{show_confirm_close && (
				<ConfirmClose
					headerTitle='Enhance User Tracking Page'
					modal_icon={ImageLinks.exclamation_purple_ripple}
					title={'All changes will be visible to consumers immediately. '}
					close={() => set_show_confirm_close(false)}
					show_negative_btn={true}
					negative_btn_text='Cancel'
					handle_negative_action={() => set_show_confirm_close(false)}
					on_confirm={handle_save_configs}
				/>
			)}
			<Button disabled={is_button_disable} className={classes.save_button} onClick={handle_update_config_event} type='round' text='Save' size='large' />
			{show_crop_modal && (
				<ImageCropModal
					img_src={marketing_image}
					close={() => {
						set_show_crop_modal(false);
						set_marketing_image('');
					}}
					aspect={2}
					on_replace={() => {
						set_show_crop_modal(false);
						upload_img_ref.current.click();
					}}
					on_save={on_marketing_banner_save}
				/>
			)}
		</div>
	);
}

export default EndUserPage;
