import Text from 'modules/Text';
import React from 'react';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import useStyles from '../styles';
import clsx from 'clsx';
import Switch from 'modules/Switch';

const RiderTrace = ({ rider_details, rider_data, handle_data }) => {
	const classes = useStyles();

	return (
		<div style={{ marginTop: 40 }}>
			<div className='align-items-center'>
				<img src={ImageLinks.marker_purple} width={16} height={16} />
				<Text semi style={{ fontSize: 16, marginLeft: 8 }}>
					Trace
				</Text>
				<div className={classes.section_header_line}></div>
			</div>
			<div className={clsx(classes.trace_content_wrap, 'd-flex')}>
				<div style={{ flex: 0.5 }}>
					<div className='align-items-center justify-content-between'>
						<Text semi style={{ fontSize: 16 }}>
							Enable Trace
						</Text>
						<div className='align-items-center'>
							<Text semi style={{ fontSize: 14 }}>
								{rider_data.enable_trace ? 'Active' : 'Inactive'}
							</Text>
							<Switch
								checked={rider_data.enable_trace}
								onChange={() => {
									handle_data('enable_trace', !rider_data.enable_trace);
								}}
							/>
						</div>
					</div>
					<Text medium className={classes.trace_desc_text}>
						Rider location from mobile app is used to create complete map of all rider movement and advanced alerts such as idling, unexpected
						movement, & more.
					</Text>
					<div className={classes.trace_strip}>
						<img src={ImageLinks.info_purple} width={14} height={15} />
						<Text semi className={classes.strip_desc}>
							Trace is at ₹1500 per vehicle per month & may incur a setup cost.
						</Text>
					</div>
				</div>
				<div style={{ flex: 0.5 }}>
					<div className='cursor-pointer' onClick={() => window.open('https://pidge.in/helpcenter-enterprise-trace/', '_blank')}>
						<img src={ImageLinks.enable_rider_trace} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default RiderTrace;
