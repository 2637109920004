import ImageLinks from 'assets/images/ImageLinks';
import OutlinedTextField from 'modules/OutlinedTextField';
import Select from 'modules/Select';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import { PKG_VOLUME_OPTIONS } from '../../helper';
import { useState } from 'react';
import _ from 'lodash';
import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import utils from 'resources/utils';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	vol_desc: { fontSize: 12, color: theme.colors.darkGrey2 },
	slab_rate_text: { fontSize: 12, color: theme.colors.primary, textDecoration: 'underline', marginRight: 6 },
	km_range: { fontSize: 14, color: theme.colors.darkGrey2, flex: 0.5 },
	new_rate_wrap: { marginTop: 8, justifyContent: 'flex-end', marginRight: 50, marginBottom: 14 },
	new_rate_text: { fontSize: 12, marginLeft: 7, textDecoration: 'underline' },
	tooltip: {
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40),
		color: theme.colors.lightPurple7,
		fontWeight: 500,
	},
	arrow: { color: utils.convert_hex_to_rgba(theme.colors.lightPurple, 40) },
	info_bubble_wrap: { borderRadius: 4, height: 80, display: 'flex', alignItems: 'center', paddingLeft: 26, paddingRight: 26 },
	tick_delete: {
		'&:hover': {
			'& .delete': {
				display: 'block !important',
			},
			'& .tick-circle': {
				display: 'none !important',
			},
		},
	},
}));

const PricingItem = ({
	invite_network_id,
	is_custom,
	price_item,
	handle_volume_change,
	handle_slab_info,
	handle_add_new_slot,
	price_index,
	handle_item_delete,
}) => {
	const [slab_expanded, set_slab_expanded] = useState(true);
	const classes = useStyles();
	const slabs = _.get(price_item, 'slabs', []);

	const handle_update_input_two_decimal = (e, slab_index) => {
		const value = e.target.value;
		const pattern = /^\d+(\.\d{0,2})?$/;
		if (value == '' || pattern.test(value)) handle_slab_info('price', value, slab_index);
	};

	const handle_update_input_valid_int = (e, slab_index) => {
		const value = e.target.value;
		const pattern = /^[-+]?\d+$/;
		if (value == '' || pattern.test(value)) handle_slab_info('distance', value, slab_index);
	};

	const btn_disabled = useMemo(() => {
		let value = false;

		for (let i = 0; i < slabs.length; i++) {
			const element = slabs[i];
			if (element.distance == '' || element.price == '') {
				value = true;
				break;
			}
		}
		return value;
	}, [slabs]);

	return (
		<div style={{ borderBottom: `1px solid ${theme.colors.lightGrey5}` }}>
			<div className='align-items-center justify-content-between' style={{ padding: '12px 14px' }}>
				<Select
					disabled={invite_network_id && !is_custom}
					value={price_item.volume + ''}
					defaultOption={{
						title: 'Volume',
						value: 'null',
					}}
					options={PKG_VOLUME_OPTIONS}
					handleChange={handle_volume_change}
					containerStyles={{ height: 35, width: 120, borderColor: theme.colors.lightPurple }}
					text_styles={{ width: 130 }}
				/>

				{slabs.length > 0 && (
					<div onClick={() => set_slab_expanded((prev) => !prev)} className='align-items-center' style={{ cursor: 'pointer', marginRight: 55 }}>
						<Text semi className={classes.slab_rate_text}>
							{slabs.length} rate slabs
						</Text>
						<img src={ImageLinks.chevron_right_pink} width={12} height={12} style={{ transform: `rotate(${slab_expanded ? 270 : 90}deg)` }} />
					</div>
				)}
			</div>
			{slab_expanded && (
				<div>
					{_.map(slabs, (slab_item, slab_index) => (
						<div key={`slab-item-${slab_index}`} className='align-items-center' style={{ padding: '6px 14px' }}>
							<Text bold className={classes.km_range}>
								{`${slab_index === 0 ? 0 : slabs[slab_index - 1].distance} - ${
									slab_item.distance ? slab_item.distance : slab_index === 0 ? '{KM1}' : '{KM2}'
								}`}
							</Text>

							<div style={{ flex: 0.25 }}>
								<OutlinedTextField
									value={slab_item.distance}
									name='distance'
									textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
									onChange={(e) => handle_update_input_valid_int(e, slab_index)}
									placeholder='00'
									disabled={invite_network_id && !is_custom}
									InputProps={{
										style: { height: 32 },
										endAdornment: (
											<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2, width: 30, whiteSpace: 'nowrap' }}>
												KM
											</Text>
										),
									}}
									style={{ width: 100 }}
								/>
							</div>
							<div className='align-items-center' style={{ flex: 0.25 }}>
								<OutlinedTextField
									value={slab_item.price}
									name='price'
									textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
									onChange={(e) => handle_update_input_two_decimal(e, slab_index)}
									placeholder='0.00'
									disabled={invite_network_id && !is_custom}
									InputProps={{
										style: { height: 32 },
										startAdornment: (
											<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
												₹
											</Text>
										),
									}}
									style={{ width: 100 }}
								/>
								{
									<div onClick={() => handle_item_delete(price_index, slab_index)} className={classes.tick_delete}>
										<img
											className='tick-circle'
											src={ImageLinks.tickCircleSolid}
											width={16}
											height={16}
											style={{ marginLeft: 8, display: 'block', opacity: slab_item.distance == '' || slab_item.price == '' ? 0.3 : 1 }}
										/>
										<img
											className='delete'
											src={ImageLinks.delete}
											width={16}
											height={16}
											style={{ marginLeft: 8, display: 'none', opacity: slab_item.distance == '' || slab_item.price == '' ? 0.3 : 1 }}
										/>
									</div>
								}
							</div>
						</div>
					))}
					{slabs.length > 0 && (
						<div
							className={clsx(classes.new_rate_wrap, 'align-items-center')}
							style={{
								pointerEvents: btn_disabled ? 'none' : 'auto',
							}}>
							{!(invite_network_id && !is_custom) && (
								<Button
									type='text'
									text='New Rate'
									left_icon={btn_disabled ? ImageLinks.addGrey : ImageLinks.add_pruple_no_fill}
									onClick={handle_add_new_slot}
									disabled={btn_disabled}
									size='extra_small'
								/>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default PricingItem;
