import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React, { useContext } from 'react';
import RepeatOrdersResult from './components/RepeatOrdersResult';
import RepeatOrdersUploadForm from './components/RepeatOrdersUploadForm';
import _ from 'lodash';
import RepeatOrdersContext from './context';

const useStyles = makeStyles((theme) => ({
	container: { padding: '32px 32px', background: theme.colors.white },
	containedButton: {
		position: 'fixed',
		right: 0,
		bottom: 30,
		borderRadius: '20px 0px 0px 20px',
		width: 200,
		height: 46,
		zIndex: 1,
	},
	contained_button_icon: {
		position: 'fixed',
		right: 0,
		bottom: 30,
		borderRadius: '20px 0px 0px 20px',
		width: 40,
		height: 46,
		zIndex: 1,
	},
	file_show_content: {
		width: '100%',
		maxWidth: 200,
		position: 'fixed',
		right: 0,
		bottom: 76,
		zIndex: 1,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: '6px 0px 0px 0px',
		backgroundColor: theme.colors.white,
		padding: 12,
		border: `1px solid ${theme.colors.lightGrey11}`,
		maxHeight: 250,
		overflow: 'auto',
	},
	textContainer: { color: theme.colors.white, fontSize: 12, lineHeight: 'unset' },
	image: { marginRight: 10 },
	file_name_content: {
		paddingBottom: 4,
		marginTop: 4,
		display: 'flex',
		justifyContent: 'space-between',
		cursor: 'pointer',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		'&:last-child': {
			borderBottom: `unset`,
		},
	},
}));

const RepeatOrderScreen = () => {
	const classes = useStyles();

	const {
		show_items_in_processing,
		csv_files_details,
		handle_selected_csv_file,
		handle_item_processing_details,
		handle_processing_button,
		show_full_button,
		on_back_press,
	} = useContext(RepeatOrdersContext);

	return (
		<div className={classes.container}>
			<div className='d-flex' style={{ marginBottom: 32 }}>
				<Text className='cursor-pointer global_header_text' medium onClick={on_back_press}>
					Create Order
				</Text>
				<div className='header_separator' style={{ marginTop: 6 }}></div>
				<Text semi className='header_active_title'>
					Repeat Orders
				</Text>
			</div>
			<div style={{ overflowY: 'auto', maxHeight: '80vh' }}>
				<Text semi style={{ fontSize: 18 }}>
					Define Criteria & Upload CSV
				</Text>
				<RepeatOrdersUploadForm />
				<RepeatOrdersResult />
				{show_items_in_processing && !_.isEmpty(csv_files_details) && (
					<div className={classes.file_show_content}>
						{_.map(csv_files_details, (file_details, index) => (
							<div key={index} className={classes.file_name_content} onClick={() => handle_selected_csv_file(file_details)}>
								<Text semi>{file_details.filename}</Text>
								<div>
									<img
										src={
											file_details.status === 1
												? ImageLinks.cricle_with_dashed_light_purple
												: file_details.status === 2
												? ImageLinks.green_loader
												: file_details.status === 3
												? ImageLinks.green_tick
												: ImageLinks.information_reverse_outline_red
										}
										width={20}
										height={20}
										alt='information'
									/>
								</div>
							</div>
						))}
					</div>
				)}
				{show_full_button ? (
					<ContainedButton className={classes.containedButton} onClick={handle_item_processing_details}>
						<img src={ImageLinks.refresh_double_arrow_white} width={16} height={16} className={classes.image} alt='refersh' />
						<Text semi className={classes.textContainer}>
							Items in Processing
						</Text>
					</ContainedButton>
				) : (
					<ContainedButton className={classes.contained_button_icon} onClick={handle_processing_button}>
						<img src={ImageLinks.refresh_double_arrow_white} width={16} height={16} alt='refersh' />
					</ContainedButton>
				)}
			</div>
		</div>
	);
};
export default RepeatOrderScreen;
