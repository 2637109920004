import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from 'resources/theme';
import Text from 'modules/Text';
import { Divider } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import { nFormatter } from 'views/AdvancedOrderAnalytics/helper';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles({
	divider: {
		marginTop: 12,
		marginBottom: 12,
		backgroundColor: theme.colors.lightGrey5,
	},
	analytics_container: {
		padding: 12,
		borderRadius: 12,
		background: theme.colors.white,
		boxShadow: '0px 1.0728px 3.21839px rgba(13, 10, 44, 0.08)',
		marginTop: 10,
	},
	dot_content: {
		width: 8,
		height: 8,
		borderRadius: '50%',
	},
	order_stat_box_wrap: {
		border: `1px solid ${theme.colors.lightGrey5}`,
		padding: 10,
		flex: 1,
		borderRadius: 12,
	},
});

const OrderStatusChart = ({ network_stats, network_stats_prev }) => {
	const classes = useStyles();
	const order_stats = network_stats.orderStats ? network_stats.orderStats : [];
	const order_stats_prev = network_stats_prev.orderStats ? network_stats_prev.orderStats : [];
	const grouped_business = _.groupBy(order_stats, 'business_name');
	const grouped_business_prev = _.groupBy(order_stats_prev, 'business_name');
	const labels = _.map(grouped_business, (item_value) => {
		return item_value[0].brand_name;
	});
	const grouped_business_data = _.map(grouped_business, (value, key) => {
		let completed = 0;
		let manifested = 0;
		_.forEach(value, (business_item) => {
			if (business_item.unified_status == 11) {
				completed = completed + parseInt(business_item.cnt);
			} else {
				manifested = manifested + parseInt(business_item.cnt);
			}
		});
		return {
			title: value[0].brand_name,
			completed,
			manifested,
		};
	});
	const grouped_business_data_prev = _.map(grouped_business_prev, (value, key) => {
		let completed = 0;
		let manifested = 0;
		_.forEach(value, (business_item) => {
			if (business_item.unified_status == 11) {
				completed = completed + parseInt(business_item.cnt);
			} else {
				manifested = manifested + parseInt(business_item.cnt);
			}
		});
		return {
			title: key,
			completed,
			manifested,
		};
	});
	const data = {
		labels: labels,
		datasets: [
			{
				data: _.map(grouped_business_data, (item_value) => {
					return item_value.manifested;
				}),
				backgroundColor: theme.colors.lightGreen9,
				hoverBackgroundColor: theme.colors.lightGreen9,
				barPercentage: 0.4,
			},
			{
				data: _.map(grouped_business_data, (item_value) => {
					return item_value.completed;
				}),
				backgroundColor: theme.colors.darkGreen1,
				hoverBackgroundColor: theme.colors.darkGreen1,
				barPercentage: 0.4,
			},
		],
	};

	const config = {
		type: 'bar',
		data: data,
		options: {
			indexAxis: 'y',
			plugins: {
				legend: false,
				datalabels: {
					display: false,
				},
				tooltip: {
					enabled: true,
				},
			},
			responsive: true,
			scales: {
				x: {
					beginAtZero: true,
					grid: {
						color: theme.colors.lightGrey12,
					},
					ticks: {
						font: {
							color: theme.colors.darkGrey2,
							size: 8,
							family: 'HK Grotesk Medium',
							weight: 'bold',
						},
						callback: nFormatter,
					},
					stacked: true,
				},
				y: {
					grid: {
						display: false,
						color: 'red',
					},
					ticks: {
						color: theme.colors.darkGrey2,
						font: {
							size: 10,
							family: 'HK Grotesk Medium',
							weight: 'bold',
						},
					},
					stacked: true,
				},
			},
		},
	};

	const delivered_order_diffrence =
		parseInt(_.get(network_stats_prev, 'highestDeliveredOrderBySupplier.0.cnt', 0)) != 0
			? ((parseInt(_.get(network_stats, 'highestDeliveredOrderBySupplier.0.cnt', 0)) -
					parseInt(_.get(network_stats_prev, 'highestDeliveredOrderBySupplier.0.cnt', 0))) *
					100) /
			  parseInt(_.get(network_stats_prev, 'highestDeliveredOrderBySupplier.0.cnt', 0))
			: 0;

	const max_place_order =
		grouped_business_data.length > 0
			? _.maxBy(
					_.map(grouped_business_data, (item) => {
						return { title: item.title, value: item.completed + item.manifested };
					}),
					'value',
			  ).value
			: 0;

	const max_place_order_prev =
		grouped_business_data_prev.length > 0
			? _.maxBy(
					_.map(grouped_business_data_prev, (item) => {
						return { title: item.title, value: item.completed + item.manifested };
					}),
					'value',
			  ).value
			: 0;

	const place_order_diffrence = grouped_business_data_prev.length > 0 ? ((max_place_order - max_place_order_prev) * 100) / max_place_order_prev : 0;

	const yielding_diffrence =
		parseInt(_.get(network_stats_prev, 'highestYieldingBusiness.0.total_earnings', 0)) != 0
			? ((parseInt(_.get(network_stats, 'highestYieldingBusiness.0.total_earnings', 0)) -
					parseInt(_.get(network_stats_prev, 'highestYieldingBusiness.0.total_earnings', 0))) *
					100) /
			  parseInt(_.get(network_stats_prev, 'highestYieldingBusiness.0.total_earnings', 0))
			: 0;

	return (
		<div style={{ overflow: 'auto', height: '50vh' }}>
			<div className='d-flex' style={{ gap: 8 }}>
				<div className={classes.order_stat_box_wrap}>
					<div className='d-flex align-items-center'>
						<img src={ImageLinks.box_tick} height={20} width={20} />
						<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
							Highest Delivered Orders
						</Text>
					</div>
					<Text semi style={{ fontSize: 16, marginTop: 20 }}>
						{_.get(network_stats, 'highestDeliveredOrderBySupplier.0.brand_name', '-')}
					</Text>
					<Text medium style={{ color: theme.colors.darkGrey2 }}>
						{parseInt(_.get(network_stats, 'highestDeliveredOrderBySupplier.0.cnt', 0))}
						<Text
							component={'span'}
							medium
							style={{ color: delivered_order_diffrence >= 0 ? theme.colors.darkGreen1 : theme.colors.red, marginLeft: 5 }}>
							{delivered_order_diffrence >= 0 ? '+' : '-'}
							{delivered_order_diffrence.toFixed(2) + '%'}
							<img src={delivered_order_diffrence >= 0 ? ImageLinks.green_growth_arrow : ImageLinks.red_downfall_arrow} width={12} />
						</Text>
					</Text>
				</div>

				<div className={classes.order_stat_box_wrap}>
					<div className='d-flex align-items-center'>
						<img src={ImageLinks.box_reverse_arrow} height={20} width={20} />
						<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
							Highest Placed Orders
						</Text>
					</div>
					<Text semi style={{ fontSize: 16, marginTop: 20 }}>
						{grouped_business_data.length > 0
							? _.maxBy(
									_.map(grouped_business_data, (item) => {
										return { title: item.title, value: item.completed + item.manifested };
									}),
									'value',
							  ).title
							: '-'}
					</Text>
					<Text medium style={{ color: theme.colors.darkGrey2 }}>
						{grouped_business_data.length > 0
							? _.maxBy(
									_.map(grouped_business_data, (item) => {
										return { title: item.title, value: item.completed + item.manifested };
									}),
									'value',
							  ).value
							: '-'}
						<Text
							component={'span'}
							medium
							style={{ color: place_order_diffrence >= 0 ? theme.colors.darkGreen1 : theme.colors.red, marginLeft: 5 }}>
							{place_order_diffrence >= 0 ? '+' : ''}
							{place_order_diffrence.toFixed(2) + '%'}
							<img src={place_order_diffrence >= 0 ? ImageLinks.green_growth_arrow : ImageLinks.red_downfall_arrow} width={12} />
						</Text>
					</Text>
				</div>

				<div className={classes.order_stat_box_wrap}>
					<div className='d-flex align-items-center'>
						<img src={ImageLinks.box_rupee} height={20} width={20} />
						<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
							Highest Yielding
						</Text>
					</div>
					<Text semi style={{ fontSize: 16, marginTop: 20 }}>
						{_.get(network_stats, 'highestYieldingBusiness.0.brand_name', '-')}
					</Text>
					<Text medium style={{ color: theme.colors.darkGrey2 }}>
						{_.get(network_stats, 'highestYieldingBusiness.0.total_earnings', 0).toFixed(2)}
						<Text component={'span'} medium style={{ color: yielding_diffrence >= 0 ? theme.colors.darkGreen1 : theme.colors.red, marginLeft: 5 }}>
							{yielding_diffrence >= 0 ? '+' : '-'}
							{yielding_diffrence.toFixed(2) + '%'}
							<img src={yielding_diffrence >= 0 ? ImageLinks.green_growth_arrow : ImageLinks.red_downfall_arrow} width={12} />
						</Text>
					</Text>
				</div>
			</div>
			<div className={classes.analytics_container}>
				<div className='align-items-center justify-content-between'>
					<Text bold style={{ fontSize: 14 }}>
						Order Status
					</Text>
					<div className='d-flex'>
						<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
							<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.lightGreen9 }} />
							<Text style={{ fontSize: 12 }}>Manifested</Text>
						</div>
						<div className='d-flex align-items-center' style={{ paddingRight: 8 }}>
							<div className={classes.dot_content} style={{ marginRight: 4, background: theme.colors.darkGreen1 }} />
							<Text style={{ fontSize: 12 }}>Completed</Text>
						</div>
					</div>
				</div>
				<Divider className={classes.divider} />
				<div style={{ width: '100%', height: '100%' }}>
					<Bar {...config} />{' '}
				</div>
			</div>
		</div>
	);
};

export default OrderStatusChart;
