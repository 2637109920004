import React, { useEffect, useState } from 'react';
import qs from 'qs';
import clsx from 'clsx';
import moment from 'moment';
import _, { update } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import IconButton from 'modules/IconButton';
import Text from 'modules/Text';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';

import ImageLinks from 'assets/images/ImageLinks';
import Filters from 'modules/Filters/Filters';
import Routes from 'resources/Routes';
import PremiumFeatureModal from 'modules/PremiumFeatureModal';
import { ALL_ORDERS_MENU_ITEMS, LOAD_ALL_STATES } from '../../helper';
import useStyles from './styles';

import ConfirmClose from 'modules/modals/ConfirmClose';
import ContainedButton from 'modules/ContainedButton';
import Select from 'modules/Select';
import { Checkbox } from '@material-ui/core';
import utils from 'resources/utils';
import OrderLabel from 'modules/OrderLabel';
import constant from 'resources/constant';
import BulksActions from 'modules/BulkActions/BulksActions';
import { OPTIONS_MENU, REPORT_MENU } from 'modules/BulkActions/helpers';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import FilterButton from 'modules/FilterButton';
import OrderModal from 'modules/modals/OrderModal/OrderModal';
import NetworkConfigAvatar from 'modules/NetworkConfigAvatar';
import { shallowEqual, useSelector } from 'react-redux';
import Helpcenter from 'modules/HelpCenter';
import NoResult from 'modules/NoResult';
import { hide_loader, show_loader } from 'actions/app';

const DOWNLOAD_OPTIONS = [
	{
		title: 'Product List',
		value: 'product_list',
	},
	// {
	// 	title: 'Shipping Label',
	// 	value: 'shipping_label',
	// },
	// {
	// 	title: 'Manifest',
	// 	value: 'manifest',
	// },
];

const ReadyToShip = ({ report_items = [] }) => {
	const { app_configs } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
		}),
		shallowEqual,
	);
	const dispatch = useDispatch();
	const history = useHistory();
	const filter_object = qs.parse(history.location.search.slice(1)).filter || {};
	const network_configs = _.get(app_configs, 'network_configs', {});
	const [selected_pb_id, set_selected_pb_id] = React.useState('');
	const [show_order_details, set_show_order_details] = React.useState(false);
	const [orders, set_orders] = React.useState({});
	const [page, set_page] = React.useState(1);
	const [all_filters, set_all_filters] = React.useState([]);
	const [show_filters, set_show_filters] = React.useState(false);
	const [captive_riders, set_captive_riders] = React.useState(null);
	const [show_multiple_pickup_error, set_show_multiple_pickup_error] = React.useState(false);
	const [selected_order_ids, set_selected_order_ids] = React.useState([]);
	const [order_list, set_order_list] = React.useState([]);
	const slider_ref = React.useRef();
	const loading_scroll = React.useRef(false);
	const [show_premium_feature, set_show_premium_feature] = React.useState(false);
	const [load_all_stage, set_load_all_stage] = useState(LOAD_ALL_STATES.INITIAL);

	const classes = useStyles();

	const handle_unallocate_orders = () => {};

	useEffect(() => {
		set_all_filters([]);
		api_requests.all_orders_filter('ready_to_ship').then((res) => set_all_filters(res.data.filters));
		api_requests.get_captive_riders().then((res) => set_captive_riders(res.data));
	}, []);

	useEffect(() => {
		get_orders({});
	}, [JSON.stringify(filter_object)]);

	const get_orders = ({ token, limit = 50 }) => {
		set_orders({ next: true });
		dispatch(show_loader());
		api_requests.get_pending_orders_search({ ...filter_object, ...(token && { token }), limit: limit, group: 'ready_to_ship' }).then((res) => {
			set_orders(res.data);
			dispatch(hide_loader());
			loading_scroll.current = false;
			if (token) {
				set_order_list(_.concat(order_list, res.data.items));
			} else {
				set_order_list(res.data.items);
			}
		});
	};

	const slider_scrolled = () => {
		if (slider_ref.current.scrollTop + slider_ref.current.clientHeight + 100 >= slider_ref.current.scrollHeight && orders.next && !loading_scroll.current) {
			loading_scroll.current = true;
			get_orders({ token: orders.next });
		}
	};

	const handle_close_multiple_pickup = () => set_show_multiple_pickup_error(false);

	const handle_select_all_order = () => {
		if (selected_order_ids.length === order_list.length) {
			set_selected_order_ids([]);
		} else {
			set_selected_order_ids(_.map(order_list, 'id'));
		}
	};

	const handle_select_order = (order_id) => {
		const index = _.indexOf(selected_order_ids, order_id);
		const _selected_order_ids = [...selected_order_ids];

		if (index === -1) {
			_selected_order_ids.push(order_id);
		} else {
			_selected_order_ids.splice(index, 1);
		}

		set_selected_order_ids(_selected_order_ids);
	};

	const handle_export = (filters, callback) => {
		api_requests
			.export_all_orders({ ...filters, group: 'ready_to_ship' })
			.then((res) => {
				utils.downloadBlob(res, 'orders.csv', 'text/csv');
				callback(true);
			})
			.catch((err) => {
				callback(false);
			});
	};

	const on_update = () => {
		history.push(Routes.ALL_ORDERS.path + `?type=${ALL_ORDERS_MENU_ITEMS.Pending.id}`);
	};

	const handle_product_list_download = () => {
		const filtered_list = _.filter(order_list, (item) => _.includes(selected_order_ids, item.id));

		const formatted_list = [];
		for (let i = 0; i < filtered_list.length; i++) {
			const element = filtered_list[i];
			if (element.products?.length > 0) {
				for (let j = 0; j < element.products.length; j++) {
					const product_element = element.products[j];
					formatted_list.push({ ...element, product: product_element });
				}
			} else {
				formatted_list.push({ ...element, product: null });
			}
		}
		let data =
			'demand channel, channel order id, reference id, bill amount, cod amount, customer name, customer mobile, customer address, delivery date, delivery slot, product item, product sku, product quantity';

		_.map(formatted_list, (order) => {
			data +=
				'\n' +
				_.get(order, 'dd_channel.name', '-') +
				', ' +
				_.get(order, 'dd_channel.order_id', '-') +
				', ' +
				_.get(order, 'reference_id', '-') +
				', ' +
				_.get(order, 'bill_amount', '-') +
				', ' +
				_.get(order, 'cod_amount', '-') +
				', ' +
				_.get(order, 'customer_detail.name', '-') +
				', ' +
				_.get(order, 'customer_detail.mobile', '-') +
				', ' +
				_.get(order, 'customer_detail.address.address_line_1', '-').replaceAll(',', ' ') +
				', ' +
				_.get(order, 'delivery_date', '-') +
				', ' +
				_.get(order, 'delivery_slot', '-') +
				', ' +
				_.get(order, 'product.name', '-') +
				', ' +
				_.get(order, 'product.sku', '-') +
				', ' +
				_.get(order, 'product.quantity', '-');
		});

		utils.downloadBlob(data, 'orders.csv', 'text/csv');
	};

	const handle_manifest_download = () => {
		const filtered_list = _.filter(order_list, (item) => _.includes(selected_order_ids, item.id));
		let data = 'fulfilment partner, partner id, cdr id, unified status, fulfilment time';
		_.map(filtered_list, (order) => {
			data +=
				'\n' +
				_.get(order, 'fulfillment.channel.name', '-') +
				', ' +
				_.get(order, 'fulfillment.channel.order_id', '-') +
				', ' +
				_.get(order, 'id', '-') +
				', ' +
				(_.get(order, 'fulfillment.status', null) ? constant.ORDER_STATUS[order.fulfillment?.status]?.text : '-') +
				', ' +
				(_.get(order, 'fulfillment.drop.eta', null) ? moment(order.fulfillment.drop.eta).format('YYYY-MM-DD hh:mm:ss') : '-');
		});

		utils.downloadBlob(data, 'orders.csv', 'text/csv');
	};

	if (captive_riders === null) {
		return null;
	}

	const handle_load_all = async () => {
		set_load_all_stage(LOAD_ALL_STATES.STARTED);
		let token = null;
		let _order_list = [];
		let next = true;
		while (next) {
			try {
				const res = await api_requests.get_pending_orders_search({ ...filter_object, ...(token && { token }), limit: 50, group: 'ready_to_ship' });
				if (token) {
					_order_list = _.concat(_order_list, res.data.items);
				} else {
					_order_list = res.data.items;
				}
				if (res.data.next) {
					token = res.data.next;
				} else {
					next = false;
					set_order_list(_order_list);
					set_load_all_stage(LOAD_ALL_STATES.COMPLETED);
					set_orders(res.data);
				}
			} catch (err) {}
		}
	};

	return (
		<>
			<div className={classes.ongoingOrdersDiv}>
				<div className='d-flex align-items-center' style={{ gap: 5 }}>
					<Text semi component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						Orders :{' '}
					</Text>
					<Text semi component={'span'} style={{ fontSize: 14 }}>
						{order_list.length} {orders.next ? ` of more` : ''}
					</Text>
					<BootstrapTooltip title='Load all Orders'>
						<div style={{ marginTop: 5 }}>
							{load_all_stage != LOAD_ALL_STATES.COMPLETED && (
								<img
									src={ImageLinks.refresh_purple}
									width={16}
									height={16}
									style={{
										cursor: 'pointer',
										...(load_all_stage === LOAD_ALL_STATES.STARTED && { animation: 'spin 4s linear infinite' }),
									}}
									onClick={() => handle_load_all()}
								/>
							)}
							{load_all_stage === LOAD_ALL_STATES.COMPLETED && <img src={ImageLinks.tickCirclePurpleSolid} width={16} height={16} />}
						</div>
					</BootstrapTooltip>
				</div>
				<FilterButton
					handle_click={() => set_show_filters(true)}
					total_filters={Object.keys(filter_object).length}
					wrapper_styles={{ marginBottom: 0 }}
					track_event={{ event: 'manifest_filter', properties: { filter_object } }}
				/>
			</div>

			<div className={classes.container}>
				<div className={classes.detailsTextContainer}>
					<div style={{ width: 50 }}>
						{order_list.length !== 0 && (
							<Checkbox
								size='small'
								checked={selected_order_ids.length === order_list.length}
								onClick={handle_select_all_order}
								style={{ color: theme.colors.lightPurple2, padding: 0 }}
							/>
						)}
					</div>
					<Text bold className={classes.header_title_2}>
						Channel/Ref ID
					</Text>
					<Text bold className={classes.header_title_2}>
						Customer Details
					</Text>
					<Text bold className={classes.header_title_2}>
						Pickup Address
					</Text>
					<Text bold className={classes.header_title_2}>
						Expected Pickup
					</Text>
					<Text bold className={classes.header_title_2}>
						Delivery Partner
					</Text>
					<Text bold className={classes.header_title_2}>
						Notes
					</Text>
					<Text bold className={classes.header_title_2}>
						Status
					</Text>
					{selected_order_ids.length > 0 && (
						<div className={classes.bulk_action_wrapper}>
							<BulksActions
								option={[OPTIONS_MENU.unallocate, OPTIONS_MENU.report]}
								selected_order_ids={selected_order_ids}
								set_selected_order_ids={set_selected_order_ids}
								report_menu={[REPORT_MENU.product, REPORT_MENU.manifest]}
								handle_product_list_download={handle_product_list_download}
								handle_manifest_download={handle_manifest_download}
								on_update={() => get_orders({})}
								external_order_id_list={_.map(
									_.filter(order_list, (order) => {
										return _.includes(selected_order_ids, order.id);
									}),
									(order) => {
										return order.id;
									},
								)}
							/>
						</div>
					)}
				</div>

				<div className={classes.pickedUpContainer} ref={slider_ref} onScroll={slider_scrolled}>
					{_.map(order_list, (order, key) => {
						return (
							<div key={`order${key}`} className={classes.pickedUpItem}>
								<div style={{ width: 50 }}>
									<Checkbox
										size='small'
										checked={_.includes(selected_order_ids, order.id)}
										onClick={() => handle_select_order(order.id)}
										style={{ color: theme.colors.lightPurple2, padding: 0 }}
									/>
								</div>
								<div className={classes.table_item_wrap_2}>
									<Text
										semi
										className={clsx(classes.orderDetailsClickabletext, classes.truncate)}
										onClick={() => {
											if (!order.id) return;
											set_selected_pb_id(order.id);
											set_show_order_details(true);
										}}>
										{order.reference_id || order?.dd_channel?.order_id || order.id || '-'}
									</Text>
									<Text className={classes.subtitle}>
										{`${moment(order.created_at).format('DD MMM YY')} | ${moment(order.created_at).format('hh:mmA')}`}
									</Text>
								</div>
								<div className={classes.table_item_wrap_2}>
									<Text medium className={classes.truncate}>
										{_.get(order, 'customer_detail.name', '-')}
									</Text>
									<div style={{ display: 'flex' }}>
										<Text className={classes.subtitle}>{_.get(order, 'customer_detail.mobile', '-')}</Text>
										<Text style={{ marginLeft: 3, marginRight: 3 }} className={classes.subtitle}>
											|
										</Text>
										<Text className={classes.subtitle}>{_.get(order, 'customer_detail.address.pincode', '-')}</Text>
									</div>
								</div>
								<div className={classes.table_item_wrap_2}>
									<Text medium className={classes.truncate}>
										{`${_.get(order, 'sender_detail.address.address_line_1', '-')}`}
									</Text>
									<Text className={classes.subtitle}>{`${_.get(order, 'sender_detail.address.pincode', '-')}`}</Text>
								</div>

								<div className={classes.table_item_wrap_2}>
									{!_.isEmpty(_.get(order, 'fulfillment.pickup.eta', null)) ? (
										<>
											<Text medium className={classes.truncate}>
												{moment(order.fulfillment.pickup.eta).format('DD-MM-YY')}
											</Text>
											<Text className={classes.subtitle}>{moment(order.fulfillment.pickup.eta).format('hh:mm A')}</Text>
										</>
									) : (
										'-'
									)}
								</div>

								<div className={classes.table_item_wrap_2}>
									<NetworkConfigAvatar
										name={_.get(order, 'fulfillment.channel.name', '-')}
										logo_width={_.get(order, 'fulfillment.channel.name', '-') === 'captive' ? 100 : 60}
										id={_.get(order, 'fulfillment.channel.id', '')}
									/>

									<Text medium className={classes.subtitle}>{`${utils.dot_in_between_string(
										_.get(order, 'fulfillment.channel.order_id', '-'),
									)}`}</Text>
								</div>

								<div className={classes.table_item_wrap_2} style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
									{order.schedule_fulfillment && (
										<BootstrapTooltip
											is_white={true}
											placement='top'
											title={
												<div className='d-flex align-items-center' style={{ gap: 5, padding: 8 }}>
													<img src={ImageLinks.self_fulfilled_logo} />
													<Text component={'span'} semi style={{ fontSize: 12, color: theme.colors.black }}>{` | `}</Text>
													<Text component={'span'} semi style={{ fontSize: 12, color: theme.colors.black }}>
														{order.schedule_fulfillment?.timestamp
															? moment(order.schedule_fulfillment?.timestamp).format('DD-MMM-YY, hh:mm:A')
															: '-'}
													</Text>
												</div>
											}>
											<img src={ImageLinks.clock_dark_pink} height={16} width={16} />
										</BootstrapTooltip>
									)}
									<Text medium className={classes.truncate}>
										{`${_.get(order, 'delivery_date', '-') || '-'}${
											_.isEmpty(_.get(order, 'delivery_slot', '')) ? '' : `;${order.delivery_slot}`
										}`}
										{`${_.get(order, 'notes', []).length === 1 ? ` ${_.get(order, 'notes.0.value')}` : ''}`}
									</Text>
								</div>

								<div className={classes.table_item_wrap_2}>
									{order.fulfillment?.status ? <OrderLabel status={constant.ORDER_STATUS[order.fulfillment?.status]?.id} /> : '-'}
								</div>
							</div>
						);
					})}
					{/* {order_list.length < 8 && (
						<div className={classes.wrapper_help_center}>
							<Helpcenter text='How do I manage orders?' url='https://pidge.in/helpcenter-order-allorders' style={{ marginTop: 40 }} />
						</div>
					)} */}
					{order_list.length == 0 && !_.isEmpty(filter_object) && (
						<div className='d-flex justify-content-center align-items-center' style={{ marginTop: '15%', flexDirection: 'column', gap: 10 }}>
							<NoResult text='No order found' btn_onclick={() => history.replace({ search: '?type=' + ALL_ORDERS_MENU_ITEMS.ReadyToShip.id })} />
						</div>
					)}
				</div>
				{show_order_details && <OrderModal id={selected_pb_id} close={() => set_show_order_details(false)} updater={() => get_orders({})} />}
				{show_filters && (
					<Filters
						source='all_orders'
						all_filters={all_filters}
						existing_filters={filter_object}
						set_selected_filters={(filters) => {
							history.push(Routes.ALL_ORDERS.path + `?type=${ALL_ORDERS_MENU_ITEMS.ReadyToShip.id}&` + qs.stringify({ filter: filters }));
							set_selected_order_ids([]);
						}}
						close={() => set_show_filters(false)}
						title='Ready to Ship'
						handle_export={handle_export}
						report_items={report_items}
					/>
				)}
				{show_premium_feature && <PremiumFeatureModal close={() => set_show_premium_feature(false)} />}
				{show_multiple_pickup_error && (
					<ConfirmClose
						headerTitle={'Multiple Pickups Not Allowed'}
						close={handle_close_multiple_pickup}
						on_confirm={handle_close_multiple_pickup}
						title={`Please selecte AWBs with the same Pickup Addresses to continue shipping orders.`}
						negative_btn_text={'Cancel'}
						positive_btn_text={'Ok'}
					/>
				)}
			</div>
		</>
	);
};

export default ReadyToShip;
