import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import qs from 'qs';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/styles';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import CreateUser from './components/CreateUser';
import SentInvitesModal from './components/SentInvitesModal';
import api_requests from 'resources/api_requests';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { hide_loader, show_loader } from 'actions/app';
import OutlinedButton from 'modules/OutlinedButton';
import TeamListing from './components/TeamListing';
import UserListing from './components/UserListing';
import TeamDetail from './components/TeamDetail';
import CreateTeam from './components/CreateTeam';
import ConfirmClose from 'modules/modals/ConfirmClose';
import AddMember from './components/AddMember';
import IconButton from 'modules/IconButton';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Button from 'modules/Button';
import utils from 'resources/utils';
import constant from 'resources/constant';

const filters = ['Users', 'Teams'];

const useStyles = makeStyles({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	header_title: {
		fontSize: 22,
		color: 'black',
	},
	screen_text_wrap: { width: 1, height: 30, background: theme.colors.darkGrey2, marginLeft: 8 },
	screen_text: {
		fontSize: 22,
		marginLeft: 8,
		borderBottom: '4px solid #E4DCF3',
		color: 'black',
	},
	header_btn_text: { marginLeft: 10, fontSize: 18 },
	header_btn_icon: { width: 12, height: 12 },
	filters: {
		marginTop: 32,
		display: 'flex',
		paddingBottom: 8,
		marginBottom: 10,
		borderBottom: `1px solid ${theme.colors.lightPurple}`,
	},
	filter_item: {
		height: 33,
		borderRadius: 8,
		padding: '8px 16px',
		cursor: 'pointer',
		gap: 8,
	},
	peding_invite_btn: {
		marginRight: 11,
	},
	back_arrow: { backgroundColor: theme.colors.lightPurple, width: 20, height: 20, borderColor: theme.colors.lightPurple, marginRight: 9 },
});

const Home = () => {
	const history = useHistory();
	const tab_search = qs.parse(history.location.search.slice(1)) || {};
	const dispatch = useDispatch();

	const [users, set_users] = useState([]);
	const [selected_tab, set_selected_tab] = useState(0);
	const [show_create_user, set_show_create_user] = useState(false);
	const [show_create_team, set_show_create_team] = useState(false);
	const [show_invites_modal, set_show_invites_modal] = useState(false);
	const [show_team_detail, set_show_team_detail] = useState(false);
	const [sent_invites, set_sent_invites] = useState([]);
	const [team_list, set_team_list] = useState([]);
	const [team_detail, set_team_detail] = useState(null);
	const [show_delete_confirm, set_delete_confirm] = useState(false);
	const [show_add_member, set_show_add_member] = useState(false);
	const selected_member_index = useRef(-1);
	const classes = useStyles();

	const { is_loading } = useSelector((state) => state.app, shallowEqual);

	useEffect(() => {
		get_sent_requests();
		get_users();
		if (tab_search?.tab === 'teams') {
			handle_tab_select(1);
		}
	}, []);

	const handle_tab_select = async (key) => {
		set_selected_tab(key);
		set_show_team_detail(false);
		set_team_detail(null);
		if (key === 1) {
			get_teams_list();
		}
	};

	const get_teams_list = async () => {
		dispatch(show_loader());
		try {
			const response = await api_requests.get_team_list();
			if (response.success && _.get(response, 'data.data', []).length > 0) {
				set_team_list(response.data.data);
			}
			dispatch(hide_loader());
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const on_back_press = () => {
		if (selected_tab === 0) {
			history.goBack();
			return;
		}
		if (show_team_detail) set_show_team_detail(false);
		else handle_tab_select(0);
	};

	const get_sent_requests = async () => {
		try {
			const response = await api_requests.get_sent_invites();
			if (response.success && _.get(response, 'data.items', []).length > 0) {
				set_sent_invites(response.data.items);
				return;
			}
			set_sent_invites([]);
		} catch (error) {}
	};

	const get_users = async () => {
		try {
			const response = await api_requests.get_users_list();
			if (response.success && _.get(response, 'data.items', []).length > 0) {
				set_users(response.data.items);
				return;
			}
			set_users([]);
		} catch (error) {}
	};

	const revoke_invite = async (invite) => {
		try {
			dispatch(show_loader());
			const response = await api_requests.cancel_user_invite(invite.id);
			if (response.success) {
				dispatch(hide_loader());
				const remaining_invites = _.filter(sent_invites, (item) => item.id != invite.id);
				set_sent_invites(remaining_invites);
				toast.success('Revoked Succesfully', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				if (remaining_invites.length === 0) set_show_invites_modal(false);
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const handle_member_press = async (team_id) => {
		try {
			const response = await api_requests.get_team_detail(team_id);
			if (response.success) {
				set_team_detail(response.data);
			}
		} catch (error) {}
		set_show_team_detail(true);
	};

	const handle_member_delete = (member_index) => {
		selected_member_index.current = member_index;
		set_delete_confirm(true);
	};

	const on_member_delete = async () => {
		try {
			const member_id = team_detail.data[selected_member_index.current].id;
			const response = await api_requests.delete_member_from_team(team_detail.team.id, { members: [member_id] });
			if (response.success) {
				const _team_detail = _.cloneDeep(team_detail);
				const filtered_users = _.filter(_team_detail.data, (item) => item.id != member_id);
				_team_detail.data = filtered_users;
				set_team_detail(_team_detail);
			}
		} catch (error) {}
	};

	const add_members_to_team = async (members) => {
		const data = _.map(members, (item) => ({ id: item.id }));
		try {
			const response = await api_requests.update_members_team(team_detail.team.id, { members: data });
			if (response.success) {
				handle_member_press(team_detail.team.id);
				set_show_add_member(false);
			}
		} catch (error) {}
	};

	const handle_team_create = async (data) => {
		try {
			const response = await api_requests.create_new_team(data);
			if (response.success) {
				toast.success('Team Created Succesfully', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				get_teams_list();
				set_show_create_team(false);
			}
		} catch (error) {}
	};

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<div className='align-items-center'>
					<div className='page_back_btn' onClick={on_back_press}>
						<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
					</div>
					<Text
						onClick={() => handle_tab_select(0)}
						semi={selected_tab === 0}
						regular={selected_tab != 0}
						className={clsx('global_header_text', selected_tab === 1 && 'cursor-pointer')}>
						User Management
					</Text>
					{selected_tab === 1 && (
						<>
							<div className='header_separator'></div>
							<Text
								semi={selected_tab === 1 && !show_team_detail}
								regular={show_team_detail}
								onClick={() => set_show_team_detail(false)}
								className={clsx(show_team_detail ? 'global_header_text' : 'header_active_title', show_team_detail && 'cursor-pointer')}
								style={{ borderBottomWidth: selected_tab === 1 && !show_team_detail ? 3 : 0 }}>
								Teams
							</Text>
						</>
					)}
					{selected_tab === 1 && show_team_detail && (
						<>
							<div className='header_separator'></div>
							<Text semi className='header_active_title'>
								{_.get(team_detail, 'team.title', '')}
							</Text>
						</>
					)}
				</div>
				<div className='d-flex'>
					{selected_tab === 0 && (
						<Button
							type='outlined'
							size='large'
							text='Pending Invites'
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.USER_PENDING_INVITES);
								set_show_invites_modal(true);
							}}
							className={classes.peding_invite_btn}
						/>
					)}
					<Button
						type='round'
						text={selected_tab === 0 ? 'Invite User' : show_team_detail ? 'Add Member' : 'New Team'}
						size='large'
						onClick={() => {
							if (selected_tab === 0) {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.USER_INVITE_USER);
								set_show_create_user(true);
							}
							if (selected_tab === 1) {
								if (show_team_detail) set_show_add_member(true);
								else {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.USER_NEW_TEAM);
									set_show_create_team(true);
								}
							}
						}}
						left_icon={ImageLinks.addWhite}
					/>
				</div>
			</div>

			<div className={classes.filters}>
				{_.map(filters, (filter, key) => (
					<div
						key={`filter_item_${key}`}
						className={classes.filter_item}
						style={{
							background: selected_tab === key ? theme.colors.primary : 'transparent',
						}}
						onClick={() => handle_tab_select(key)}>
						<Text
							bold
							style={{
								fontSize: 14,
								color: selected_tab === key ? theme.colors.white : theme.colors.lightPurple6,
							}}>
							{filter}
						</Text>
					</div>
				))}
			</div>

			{selected_tab === 0 && <UserListing users={users} />}

			{selected_tab === 1 && !show_team_detail && !is_loading && (
				<TeamListing teams={team_list} handle_member_press={handle_member_press} set_show_create_team={set_show_create_team} />
			)}

			{selected_tab === 1 && show_team_detail && <TeamDetail team_detail={team_detail} handle_member_delete={handle_member_delete} />}

			{show_create_user && (
				<CreateUser
					accepted_users={users}
					close={(isCreated) => {
						if (isCreated) {
							get_sent_requests();
							get_users();
						}
						set_show_create_user(false);
					}}
				/>
			)}
			{show_create_team && (
				<CreateTeam
					users={users}
					handle_team_create={handle_team_create}
					close={() => {
						set_show_create_team(false);
					}}
				/>
			)}
			{show_add_member && (
				<AddMember
					users={users}
					team_users={team_detail.data}
					close={() => {
						set_show_add_member(false);
					}}
					add_members_to_team={add_members_to_team}
				/>
			)}
			{show_delete_confirm && (
				<ConfirmClose
					headerTitle='Remove User from Team'
					title={
						<Text medium style={{ fontSize: 14 }}>
							Are you sure you want to remove
							<Text component={'span'} bold>{` ${team_detail.data[selected_member_index.current].user_name} (${
								team_detail.data[selected_member_index.current].user_id
							})`}</Text>
							<Text component={'span'}> from </Text>
							<Text component={'span'} bold>{`${team_detail.team.title}`}</Text>
							<Text component={'span'}>?</Text>
						</Text>
					}
					close={() => {
						set_delete_confirm(false);
						selected_member_index.current = -1;
					}}
					is_error_msg
					on_confirm={on_member_delete}
				/>
			)}
			{show_invites_modal && <SentInvitesModal revoke_invite={revoke_invite} sent_invites={sent_invites} close={() => set_show_invites_modal(false)} />}
		</div>
	);
};

export default Home;
