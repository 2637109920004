import { ListItem, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import React, { useEffect } from 'react';

import Text from 'modules/Text';
import constant from 'resources/constant';
import Marker from './Marker';
import theme from 'resources/theme';
import ContainedButton from 'modules/ContainedButton';

let pidge_zone_markers;

const useStyles = makeStyles({
	wrapper: { flex: 1, display: 'flex', overflowY: 'hidden' },
	left_wrap: { flex: 1, margin: '28px 0px', paddingRight: 20, paddingBottom: 10, display: 'flex', flexDirection: 'column' },
	right_wrap: { flex: 1, padding: 40, position: 'relative' },
	create_info_wrap: {
		borderRadius: 6,
		backgroundColor: theme.colors.lightPurple8,
		display: 'flex',
		width: '100%',
		minHeight: 85,
		marginRight: 45,
		padding: 12,
	},
	purple_circle: { width: 16, height: 16, borderRadius: 8, backgroundColor: theme.colors.primary, marginRight: 6, marginTop: 4 },
	pidge_nrwrk_btn: {
		position: 'absolute',
		right: 50,
		top: 50,
		zIndex: 100,
		padding: '5px 7px',
		borderRadius: 8,
	},
});

const MasterZones = ({ open_subzones, master_zones, pidge_zones }) => {
	const map = React.useRef(null);
	const [show_pidge_network, set_show_pidge_network] = React.useState(false);
	const classes = useStyles();

	const initialize = () => {
		if (!map.current) {
			map.current = new window.google.maps.Map(document.getElementById('map_canvas'), {
				center: constant.INDIA_MAP_CENTER,
				zoom: 4.6,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});
		}

		_.map(master_zones, (zone) => {
			const polygon_coords = _.map(_.get(zone, 'geometry.coordinates[0]', []), (coord) => {
				return { lat: coord[0], lng: coord[1] };
			});

			// Construct the polygon.
			const polygon = new window.google.maps.Polygon({
				paths: polygon_coords,
				strokeColor: zone.color,
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: zone.color,
				fillOpacity: 0.35,
			});

			polygon.setMap(map.current);
		});
	};

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, [master_zones]);

	const show_hide_pidge_zones = () => {
		if (!map.current) {
			return;
		}

		if (!show_pidge_network) {
			pidge_zone_markers = _.map(pidge_zones, (zone) => {
				const polygon_coords = _.map(_.get(zone, 'geometry.coordinates[0]', []), (coord) => {
					return { lat: coord[0], lng: coord[1] };
				});

				// Construct the polygon.
				const polygon = new window.google.maps.Polygon({
					paths: polygon_coords,
					strokeColor: theme.colors.primary,
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: theme.colors.primary,
					fillOpacity: 0.35,
				});

				polygon.setMap(map.current);

				return polygon;
			});
		} else {
			_.map(pidge_zone_markers, (marker) => {
				marker.setMap(null);
			});
		}

		set_show_pidge_network(!show_pidge_network);
	};

	return (
		<div className={classes.wrapper}>
			<div className={classes.left_wrap}>
				<div style={{ flex: 1, overflowY: 'auto', paddingBottom: 10 }}>
					{_.map(master_zones, (zone, key) => {
						return (
							<ListItem
								key={`zone${key}`}
								button
								className='box-shadow'
								onClick={() => {
									open_subzones(zone.id);
								}}
								style={{
									justifyContent: 'space-between',
									padding: '12px 15px',
									borderRadius: 6,
									height: 60,
									...(key !== 0 && { marginTop: 10 }),
								}}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Marker color={zone.color} />
									<Text bold style={{ marginLeft: 10, fontSize: 18 }}>
										{zone.title}
									</Text>
								</div>
								<div style={{ display: 'flex' }}>
									<div>
										<Text style={{ textAlign: 'right' }} bold>
											{zone.business_zone}
										</Text>
										<Text medium style={{ textAlign: 'right', color: theme.colors.darkGrey2, fontSize: 12 }}>
											Business Zones
										</Text>
									</div>
									<img src={ImageLinks.chevron_right_black} width='8' style={{ marginLeft: 15 }} alt='' />
								</div>
							</ListItem>
						);
					})}
				</div>
				<div className={classes.create_info_wrap}>
					<div className={classes.purple_circle}></div>
					<div style={{ flex: 1 }}>
						<Text bold style={{ fontSize: 16, color: theme.colors.primary }}>
							Pidge Delivery Network
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 5 }}>
							Pidge has integrations with all major delivery partners across the country, and continues to add more. The Pidge-Powered Network in
							Major cities helps ensure reduced costs and increased revenue.
						</Text>
					</div>
				</div>
			</div>
			<div className={classes.right_wrap}>
				<ContainedButton
					onClick={show_hide_pidge_zones}
					className={classes.pidge_nrwrk_btn}
					style={{
						background: theme.colors.lightPurple,
					}}>
					<img src={show_pidge_network ? ImageLinks.eye_open : ImageLinks.eye_close} width='14' alt='' />
					<Text bold style={{ color: theme.colors.primary, marginLeft: 5, fontSize: 10 }}>
						Pidge Network
					</Text>
				</ContainedButton>
				<div
					id='map_canvas'
					className='map-canvas-wrapper rider-location card'
					style={{ height: '100%', borderRadius: 20, background: theme.colors.lightGrey3 }}></div>
			</div>
		</div>
	);
};

export default MasterZones;
