import React from 'react';
import { Divider, IconButton, makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import ContainedButton from 'modules/ContainedButton';
import _ from 'lodash';
import Amount from 'modules/Amount';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
		cursor: 'pointer',
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 600,
		backgroundColor: `${theme.colors.white}`,
		borderTopLeftRadius: 20,
		padding: 30,
		paddingBottom: 0,
		zIndex: 1199,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	header: {
		paddingBottom: 10,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	header_text: { fontSize: 20, color: theme.colors.black },
	content_container: {
		textAlign: 'center',
		paddingTop: 30,
		alignItems: 'baseline',
		justifyContent: 'center',
		display: 'flex',
	},
	transaction_text: {
		fontSize: 16,
		textAlign: 'center',
	},
	feature_container: {
		margin: '20px 60px',
	},
	feature_information: {
		padding: '8px 16px',
		background: theme.colors.lightPurple,
		textAlign: 'center',
	},
	transaction_information_container: {
		marginTop: 50,
	},
	download_button: {
		borderRadius: 30,
		height: 47,
		padding: '8px 20px',
	},
	download_button_cntainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		background: theme.colors.white,
		padding: '28px 0px',
	},
	cdr_link: {
		fontSize: 14,
		textDecoration: 'underline',
		marginTop: 16,
	},
}));

const TransactionDetails = ({ close, transaction_details_data, handle_cdr_click }) => {
	const classes = useStyles();

	const txn_data_items = _.get(transaction_details_data, 'transaction_data.items', []);
	const txn_data_entities = _.get(transaction_details_data, 'transaction_data.entities', []);
	const cdr_ids = [...txn_data_items, ...txn_data_entities];

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div>
					<div className={classes.header}>
						<div className='align-items-center'>
							<Text bold className={classes.header_text}>
								Transaction Details
							</Text>
						</div>
						<div>
							<IconButton onClick={close} style={{ border: 'none' }}>
								<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
							</IconButton>
						</div>
					</div>
					<div className={classes.content_container}>
						<Amount
							amount={transaction_details_data.amount}
							main_text_styles={{
								fontSize: 22,
								color: transaction_details_data.transaction_type === 200 ? theme.colors.red : theme.colors.green,
							}}
							sub_text_styles={{
								fontSize: 18,
								color: transaction_details_data.transaction_type === 200 ? theme.colors.red : theme.colors.green,
							}}
						/>
					</div>
					<Text bold className={classes.transaction_text}>
						Transaction ID: {_.get(transaction_details_data, 'transaction_id')}
					</Text>
					<div className={classes.feature_container}>
						<div className={classes.feature_information}>
							<Text bold style={{ fontSize: 14, marginBottom: 4 }}>
								{_.get(transaction_details_data, 'entity', '') === 'recharge'
									? 'Wallet Recharge'
									: _.get(transaction_details_data, 'entity', '') === 'dunzo_id'
									? 'Fullfilled with Dunzo'
									: _.get(transaction_details_data, 'entity', '') === 'wefast_id'
									? 'Fullfilled with Wefast'
									: _.get(transaction_details_data, 'entity', '') === 'loadshare_id'
									? 'Fullfilled with Loadshare'
									: _.get(transaction_details_data, 'entity', '') === 'shadowfax_id'
									? 'Fullfilled with Shadowfax'
									: _.get(transaction_details_data, 'entity', '') === 'zomato_id'
									? 'Fullfilled with Zomato'
									: _.get(transaction_details_data, 'entity', '') === 'porter_id'
									? 'Fullfilled with Porter'
									: _.get(transaction_details_data, 'entity', '') === 'manual-debit'
									? 'Manual debit'
									: _.get(transaction_details_data, 'entity', '')?.includes('SaaS-')
									? 'Processing Charges'
									: ''}
							</Text>
							<Text style={{ fontSize: 12 }}>Closing balance: ₹{_.get(transaction_details_data, 'balance', 0)}</Text>
						</div>
						<div
							className={clsx(
								`align-items-center ${
									_.get(transaction_details_data, 'entity', '') === 'recharge'
										? 'justify-content-between'
										: 'justify-content-center text-align-center'
								}`,
							)}
							style={{ paddingTop: 20 }}>
							{_.get(transaction_details_data, 'entity', '') === 'recharge' ? (
								<>
									<div>
										<Text bold style={{ fontSize: 14, marginBottom: 4 }}>
											{_.get(transaction_details_data, 'payment_method', '')}
										</Text>
										<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
											Payment Method
										</Text>
									</div>
									<div style={{ textAlign: 'right' }}>
										<Text bold style={{ fontSize: 14, marginBottom: 4 }}>
											{_.get(transaction_details_data, 'payment_id', '')}
										</Text>
										<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
											Payment ID
										</Text>
									</div>
								</>
							) : (
								<>
									<div>
										<Text bold style={{ fontSize: 14, marginBottom: 4 }}>
											#{transaction_details_data.reference_id}
										</Text>
										<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
											Reference Id
										</Text>
									</div>
									{/* <div style={{ textAlign: 'right' }}>
										<Text bold style={{ fontSize: 14, marginBottom: 4 }}>
											12
										</Text>
										<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
											Feature Usage
										</Text>
									</div> */}
								</>
							)}
						</div>

						<div style={{ marginTop: 20, flexDirection: 'column' }} className={clsx(`align-items-center justify-content-center cursor-pointer`)}>
							{_.map(cdr_ids, (item, index) => (
								<Text semi key={`cdr_id${index}`} className={classes.cdr_link} onClick={() => handle_cdr_click(item)}>
									{item}
								</Text>
							))}
						</div>
					</div>
					{/* {_.get(transaction_details_data, 'entity', '') !== 'recharge' && (
						<div className={classes.transaction_information_container}>
							{[1, 2, 3, 4, 5, 6].map((details, index) => {
								return (
									<div key={index}>
										<div className='align-items-center justify-content-between'>
											<div>
												<Text bold style={{ fontSize: 14, marginBottom: 4 }}>
													Entry details
												</Text>
												<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
													HH:MM
												</Text>
											</div>
											<div>
												<Text bold style={{ fontSize: 16 }}>
													- ₹800<span style={{ fontSize: 12, fontWeight: 'normal' }}>.00</span>
												</Text>
											</div>
										</div>
										<Divider style={{ marginTop: 8, marginBottom: 8 }} />
									</div>
								);
							})}
						</div>
					)} */}
				</div>
				{/* <div>
					<div className={classes.download_button_cntainer}>
						<ContainedButton className={classes.download_button}>
							<Text semi style={{ fontSize: 18 }}>
								Download Invoice
							</Text>
						</ContainedButton>
					</div>
				</div> */}
			</div>
		</>
	);
};

export default TransactionDetails;
