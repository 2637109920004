import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'materialui-daterange-picker';
import { Divider, makeStyles } from '@material-ui/core';
import _ from 'lodash';

import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';
import VehiclePerformceChart from './VehiclePerformanceChart';
import VehicleKmPerDayChart from './VehicleKmPerDayChart';

const useStyles = makeStyles((theme) => ({
	container: {
		borderRadius: 12,
		boxShadow: `0px 0px 4px 0px ${theme.colors.lightPurple}`,
		flex: 1,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		width: '100%',
		minHeight: '70vh',
		padding: 20,
	},
	divider: {
		width: 1,
		height: 'auto',
		backgroundColor: theme.colors.lightPurple,
	},
	overview_wrap: {
		backgroundColor: 'rgba(242, 238, 248, 0.70)',
		borderRadius: 8,
		padding: 12,
		display: 'flex',
	},
}));

function FleetOverviewAnalytics({ selected_vehicle, total_vehicle }) {
	const classes = useStyles();
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [fleet_analytics_data, set_fleet_analytics_data] = useState({});
	const [filters, set_filters] = useState({
		from: moment().subtract(7, 'days'),
		to: moment(),
	});
	const [total_assignments, set_total_assignments] = useState(0);
	const [total_distance, set_total_distance] = useState(0);

	useEffect(() => {
		get_fleet_analytics_data();
	}, []);

	const get_fleet_analytics_data = async (range = null) => {
		let param = {
			from: !_.isEmpty(range) ? range.from.format('YYYY-MM-DD') : filters.from.format('YYYY-MM-DD'),
			to: !_.isEmpty(range) ? range.to.format('YYYY-MM-DD') : filters.to.format('YYYY-MM-DD'),
		};
		try {
			const res = await api_requests.get_fleet_analytics_data(param);
			if (res.success) {
				set_fleet_analytics_data(res.data);
				let total_assignment = _.reduce(res.data.distance, (acc, curr) => acc + parseInt(curr.assignment_cnt), 0);
				let total_distance = _.reduce(res.data.distance, (acc, curr) => acc + parseInt(curr.total_distance !== null ? curr.total_distance : 0), 0);
				set_total_assignments(total_assignment);
				set_total_distance(total_distance);
			}
		} catch (error) {}
	};

	const handle_date_change = (range) => {
		const newDateRange = {
			from: moment(range.startDate),
			to: moment(range.endDate),
		};
		set_filters(newDateRange);
		set_date_picker_open(!date_picker_open);
		get_fleet_analytics_data(newDateRange);
	};
	return (
		<div style={{ flex: 1 }}>
			<div className={classes.container}>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='d-flex align-items-center'>
						<Text semi style={{ fontSize: 16, color: theme.colors.primary }}>{`${filters.from.format('DD MMM `YY')} -${filters.to.format(
							'DD MMM `YY',
						)}`}</Text>
						<img src={ImageLinks.calendar_solid_circle} onClick={() => set_date_picker_open(true)} />
					</div>
					{/* <img src={ImageLinks.download_circle}></img> */}
				</div>
				<div style={{ position: 'absolute', right: 0, top: 50, width: 750, zIndex: 1300 }}>
					<DateRangePicker
						open={date_picker_open}
						toggle={() => set_date_picker_open((prev) => !prev)}
						style={{ width: 150 }}
						initialDateRange={{
							startDate: filters.from,
							endDate: filters.to,
						}}
						onChange={(range) => handle_date_change(range)}
					/>
				</div>
				<Divider style={{ margin: '10px 0' }} />
				<div className={classes.overview_wrap}>
					<div style={{ flex: 1, padding: '5px 16px' }}>
						<Text semi style={{ fontSize: 20 }}>
							{_.get(fleet_analytics_data, 'active_vehicle_cnt', 0)}
							<Text component={'span'} style={{ fontSize: 12 }}>
								/{total_vehicle}
							</Text>
						</Text>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							No. of Vehicles Used
						</Text>
					</div>

					<div className={classes.divider} />
					<div style={{ flex: 1, padding: '5px 16px' }}>
						<Text semi style={{ fontSize: 20 }}>
							{total_distance} km
						</Text>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Total KM Covered
						</Text>
					</div>
					<div className={classes.divider} />
					<div style={{ flex: 1, padding: '5px 16px' }}>
						<Text semi style={{ fontSize: 20 }}>
							{total_assignments}
						</Text>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Total Assignments
						</Text>
					</div>
				</div>
				<div style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
					<VehiclePerformceChart performance_data={fleet_analytics_data?.start_time} />
					<VehicleKmPerDayChart km_per_data={fleet_analytics_data?.distance} />
				</div>
			</div>
		</div>
	);
}

export default FleetOverviewAnalytics;
