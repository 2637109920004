import React from 'react';

const Marker = ({ color }) => {
	return (
		<svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.66655 0C2.99038 0 -8.2016e-05 2.78962 -8.2016e-05 6.21875C-8.2016e-05 10.6667 4.11418 13.8056 6.3237 15.8667C6.51423 16.0444 6.8189 16.0444 6.99043 15.8667C9.21885 13.8057 13.3333 10.6667 13.3333 6.21875C13.3333 2.78955 10.3427 0 6.66655 0Z'
				fill={color}
			/>
		</svg>
	);
};

export default Marker;
