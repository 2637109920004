import React, { useEffect, useMemo, useState } from 'react';
import theme from 'resources/theme';
import _ from 'lodash';
import IconButton from 'modules/IconButton';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import { ListItem, makeStyles } from '@material-ui/core';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import OutlinedTextField from 'modules/OutlinedTextField';
import ContainedButton from 'modules/ContainedButton';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 500,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		zIndex: 1199,
		overflowY: 'auto',
		paddingBottom: 150,
	},
	header_wrap: { paddingBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	header_text: { fontSize: 20, color: theme.colors.primary },
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiInputLabel-outlined': {
			border: 'none',
		},
	},

	add_member_wrap: { display: 'flex', justifyContent: 'space-between', marginTop: 20 },
	add_member_input_wrap: {
		border: `1px solid ${theme.colors.lightPurple3}`,
		width: '100%',
		padding: '10px 20px',
		height: 40,
		borderRadius: 20,
		display: 'flex',
		backgroundColor: theme.colors.white,
	},
	add_member_input: {
		outline: 'none',
		border: 'none',
		marginLeft: 10,
		fontSize: 16,
		width: '100%',
		color: theme.colors.lightGrey2,
	},
	search_member_wrap: {
		marginTop: 5,
		position: 'absolute',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		marginLeft: 30,
		width: 300,
		zIndex: 1000,
		border: `1px solid ${theme.colors.lightGrey3}`,
		borderRadius: 10,
		background: 'white',
		overflowY: 'auto',
		maxHeight: 200,
	},
	member_list_wrap: { width: '100%', height: 270, overflowY: 'auto', marginTop: 20 },
	delete_btn: {
		display: 'flex',
		alignItems: 'center',
	},
	user_list_item: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 43,
	},
	team_desc_title_wrap: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
	text_area_wrap: {
		height: 73,
		borderRadius: 6,
		backgroundColor: theme.colors.lightGrey7,
		boxShadow: '0px 0px 4px 0px rgba(102, 102, 102, 0.25)',
		marginTop: 8,
	},
	footer: {
		height: 100,
		width: 500,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 40,
		paddingRight: 40,
		// boxShadow: '0px -4px 14px 0px rgba(0, 0, 0, 0.05)',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	cta_btn: {
		height: 38,
		borderRadius: 30,
		// marginRight: 10,
		boxShadow: 'none',
	},
}));

const CreateTeam = ({ close, users, handle_team_create, wrapper_styles = {} }) => {
	const [team_name, set_team_name] = useState('');
	const [team_goal, set_team_goal] = useState('');
	const [selected_members, set_selected_members] = useState([]);
	const [search_member_id, set_search_member_id] = useState('');
	const [search_members, set_search_members] = useState([]);
	const [team_goal_text_size, set_team_goal_text_size] = useState(0);

	const classes = useStyles();

	useEffect(() => {
		if (!_.isEmpty(search_member_id)) {
			const result = _.filter(users, (o) => o.name.toLowerCase().includes(search_member_id.toLowerCase()));
			set_search_members(result);
		} else {
			set_search_members([]);
		}
	}, [search_member_id]);

	const handle_select_member = (member) => {
		const index = _.findIndex(selected_members, { id: member.id });
		const _selected_members = [...selected_members];

		if (index === -1) {
			_selected_members.push(member);
		} else {
			_selected_members.splice(index, 1);
		}

		set_selected_members(_selected_members);
		set_search_member_id('');
		set_search_members([]);
	};

	const handle_team_goal_change = (e) => {
		const value = e.target.value;
		if (value.length > 100) return;
		set_team_goal_text_size(value.length);
		set_team_goal(value);
	};

	const handle_cta = () => {
		const data = {
			title: team_name,
			description: team_goal,
			members: _.map(selected_members, (item) => ({ id: item.id })),
		};
		handle_team_create(data);
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		if (!_.isEmpty(team_name.trim()) && selected_members.length > 0) value = false;
		return value;
	}, [team_name, selected_members]);

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container} style={wrapper_styles}>
				<div style={{ padding: 30 }}>
					<div className={classes.header_wrap}>
						<Text bold className={classes.header_text}>
							New Team
						</Text>
						<div>
							<IconButton onClick={close} style={{ border: 'none' }}>
								<img src={ImageLinks.crossBlack} width={16} height={16} />
							</IconButton>
						</div>
					</div>
					<div style={{ border: `1px solid ${theme.colors.lightGrey5}` }}></div>

					<div style={{ marginTop: 16 }}>
						<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
							Team Identity
						</Text>

						<UndlerlineTextField style={{ marginTop: 20 }} label='Team Name*' value={team_name} onChange={(e) => set_team_name(e.target.value)} />

						<div style={{ marginTop: 32 }}>
							<div className={classes.team_desc_title_wrap}>
								<Text medium style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
									Team Description
								</Text>
								<Text bold style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
									{team_goal_text_size}/100 characters
								</Text>
							</div>

							<div className={classes.text_area_wrap}>
								<OutlinedTextField
									multiline
									rows={4}
									InputProps={{
										style: { height: 28, padding: 12, fontSize: 12, height: 73, color: theme.colors.darkGrey2 },
									}}
									placeholder={'What is the goal of this team'}
									style={{ height: 73, width: '100%' }}
									value={team_goal}
									onChange={handle_team_goal_change}
									className={classes.root}
								/>
							</div>

							<div style={{ marginTop: 33 }}>
								<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
									Add Member
								</Text>
								<div className={classes.add_member_wrap}>
									<div className={classes.add_member_input_wrap}>
										<img src={ImageLinks.search_light_pink} alt='member_search' />
										<input
											placeholder='Search User'
											className={classes.add_member_input}
											value={search_member_id}
											onChange={(e) => set_search_member_id(e.target.value)}
										/>
									</div>
								</div>
								{!_.isEmpty(search_members) && (
									<div className={classes.search_member_wrap}>
										{_.map(search_members, (member, index) => {
											return (
												<ListItem
													button
													key={`menu${index}`}
													onClick={() => handle_select_member(member)}
													tabIndex={index}
													style={{ padding: '12px 20px', ...(index !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }) }}>
													<Text medium>{member.id}</Text>
													<Text medium style={{ marginLeft: 20 }}>
														{member.name}
													</Text>
												</ListItem>
											);
										})}
									</div>
								)}

								<div className={classes.member_list_wrap}>
									{_.map(selected_members, (member, key) => {
										return (
											<div style={{ position: 'relative' }} key={`selected_members${key}`}>
												<ListItem
													key={`member${key}`}
													button
													className={classes.user_list_item}
													style={{
														...(key !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }),
													}}
													onClick={() => {}}>
													<Text bold style={{ fontSize: 16 }}>
														{`${member.name} (${member.id})`}
													</Text>
													<div className={classes.delete_btn} onClick={() => handle_select_member(member)}>
														<img src={ImageLinks.delete} style={{ marginRight: 10 }} />
													</div>
												</ListItem>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={classes.footer}>
					<Button type='round' text='Create' size='large' disabled={btn_disabled} onClick={handle_cta} style={{ width: 110 }} />
				</div>
			</div>
		</>
	);
};

export default CreateTeam;
