import React, { useEffect, useRef } from 'react';
import theme from 'resources/theme';
import clsx from 'clsx';
import _ from 'lodash';
import Text from 'modules/Text';
import { makeStyles, ListItem } from '@material-ui/core';
import AddRiderNetworkSlotsModal from './AddRiderNetworkSlotsModal';
import { useState } from 'react';
import ImageLinks from 'assets/images/ImageLinks';
import IconButton from 'modules/IconButton';
import ContainedButton from 'modules/ContainedButton';
import api_requests from 'resources/api_requests';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { useMemo } from 'react';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 500,
		zIndex: 1200,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	main_body_container: {
		backgroundColor: 'white',
		borderTopLeftRadius: 50,
		padding: '36px 30px 10px 30px',
		flex: 1,
		overflowY: 'hidden',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		borderBottom: '1px solid lightgrey',
		paddingBottom: 5,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	header_text: { fontSize: 20, color: theme.colors.primary },
	empty_slots_wrap: {
		marginTop: 10,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	add_slots_wrap: {
		borderRadius: 8,
		boxShadow: `0px 2px 4px rgba(196, 196, 196, 0.3)`,
		backgroundColor: theme.colors.white,
		padding: 12,
		marginTop: 12,
	},
	add_btn: { height: 26, borderRadius: 30, width: 86, marginTop: 15 },
	slot_stats_wrap: {
		borderRadius: 8,
		backgroundColor: theme.colors.lightPurple,
		padding: 16,
		marginTop: 24,
		marginBottom: 9,
		display: 'flex',
		alignItems: 'center',
	},
	bar_text: {
		fontSize: 16,
		color: theme.colors.lightGrey2,
		marginLeft: 4,
		marginRight: 4,
	},
	add_rider_wrap: { display: 'flex', justifyContent: 'space-between', marginTop: 20 },
	add_rider_input_wrap: {
		border: `1px solid ${theme.colors.lightPurple3}`,
		width: '100%',
		padding: '10px 20px',
		height: 40,
		borderRadius: 20,
		display: 'flex',
	},
	add_rider_input: {
		outline: 'none',
		border: 'none',
		marginLeft: 10,
		fontSize: 16,
		width: '100%',
	},
	search_rider_wrap: {
		marginTop: 5,
		position: 'absolute',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		marginLeft: 30,
		width: 300,
		zIndex: 1000,
		border: `1px solid ${theme.colors.lightGrey3}`,
		borderRadius: 10,
		background: 'white',
		overflowY: 'auto',
		maxHeight: 200,
	},
	rider_list_wrap: { width: '100%', height: 'auto', overflowY: 'auto', marginTop: 20, marginBottom: '100px' },

	rider_list_item: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 12,
	},
	upload_button: {
		height: 36,
		width: 36,
		borderRadius: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 20,
		cursor: 'pointer',
		'&:hover': {
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	footer: {
		height: 100,
		width: 500,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingRight: 40,
		paddingLeft: 40,
	},
}));

const AddRidersToNetworkModal = ({ close, handle_add_rider }) => {
	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);
	const [show_add_slots_modal, set_show_add_slots_modal] = useState(false);
	const [is_permanent, set_is_permanent] = useState(false);
	const [added_slots, set_added_slots] = useState([]);
	const [selected_riders, set_selected_riders] = useState([]);
	const [search_rider_id, set_search_rider_id] = useState('');
	const [search_riders, set_search_riders] = useState([]);
	const input_ref = useRef();
	const classes = useStyles();

	useEffect(() => {
		if (!_.isEmpty(search_rider_id)) {
			api_requests
				.search_rider_without_zone({
					search: search_rider_id,
					is_active: true,
				})
				.then((res) => {
					set_search_riders(res.data);
				});
		} else {
			set_search_riders([]);
		}
	}, [search_rider_id]);

	const handle_select_rider = (rider, is_delete) => {
		const index = _.findIndex(selected_riders, { rider_id: rider.rider_id });
		const _selected_riders = [...selected_riders];

		if (index === -1) {
			_selected_riders.push(rider);
		} else {
			if (is_delete) _selected_riders.splice(index, 1);
		}

		set_selected_riders(_selected_riders);
		set_search_rider_id('');
		set_search_riders([]);
	};

	const handle_upload_file = async (e) => {
		const file = e.target.files[0];
		const form_data = new FormData();
		form_data.append('file', file);
		try {
			const res = await api_requests.upload_rider_csv(form_data);
			if (res.success) {
				set_selected_riders(res.data);
				e.target.value = '';
			}
		} catch (err) {
			e.target.value = '';
		}
	};

	const handlle_slots_save = ({ is_permanent, added_slots }) => {
		set_is_permanent(is_permanent);
		set_added_slots(added_slots);
	};

	const get_unique_slots = () => {
		const slots = _.map(added_slots, (slot) => {
			return {
				start: slot.start,
				end: slot.end,
			};
		});
		const unique_Array = _.filter(slots, (obj, index, self) => {
			return index === self.findIndex((o) => o.start === obj.start && o.end === obj.end);
		});
		return unique_Array.length;
	};

	const add_rider_to_network = () => {
		let formatted_slots = [];
		for (let i = 0; i < added_slots.length; i++) {
			const element = added_slots[i];
			const obj = {
				start_time: element.start,
				end_time: element.end,
			};
			const dates = _.map(element.dates, (item) => moment(item).format('YYYY-MM-DD'));
			obj.dates = dates;
			formatted_slots.push(obj);
		}
		const rider_ids = _.map(selected_riders, (item) => item.rider_id);
		const payload = {
			rider_ids,
			is_permanent,
			...(!is_permanent && { slots: formatted_slots }),
		};
		handle_add_rider(payload);
	};

	const btn_disabled = useMemo(() => {
		let value = true;
		let slots_valid = false;
		if (is_permanent) slots_valid = true;
		else slots_valid = added_slots.length > 0;
		if (slots_valid && selected_riders.length > 0) value = false;
		return value;
	}, [is_permanent, added_slots, selected_riders]);

	const all_dates = [];
	for (let i = 0; i < added_slots.length; i++) {
		const slot = added_slots[i];
		const dates = slot.dates;
		all_dates.push(..._.map(dates, (item) => moment(item).format('YYYY-MM-DD')));
	}

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={clsx(classes.container, 'box-shadow')} style={{ borderTopLeftRadius: 20 }}>
				<div className={classes.main_body_container}>
					<div className={classes.header}>
						<Text bold className={classes.header_text}>
							Add Riders
						</Text>
						<IconButton onClick={close} style={{ border: 'none' }}>
							<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
						</IconButton>
					</div>

					<div className={classes.add_slots_wrap}>
						<div className='align-items-center justify-content-between'>
							<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
								Rider Availability
							</Text>
							{(is_permanent || added_slots.length > 0) && (
								<Button
									type='round'
									text='Edit'
									size='extra_small'
									onClick={() => set_show_add_slots_modal(true)}
									left_icon={ImageLinks.edit_white}
								/>
							)}
						</div>
						<div style={{ border: `1px solid ${theme.colors.lightGrey5}`, marginTop: 8 }}></div>

						{added_slots.length === 0 && !is_permanent && (
							<div className={classes.empty_slots_wrap}>
								<Text semi style={{ fontSize: 16 }}>
									Add date & time slots for rider availability
								</Text>
								<Button
									type='round'
									text='Add'
									size='extra_small'
									onClick={() => set_show_add_slots_modal(true)}
									left_icon={ImageLinks.addWhite}
								/>
							</div>
						)}

						{(added_slots.length > 0 || is_permanent) && (
							<div className={classes.slot_stats_wrap}>
								{is_permanent ? (
									<Text semi className='text-align-center' style={{ fontSize: 16, color: theme.colors.primary, flex: 1 }}>
										Permanent availability of riders
									</Text>
								) : (
									<>
										<Text semi style={{ fontSize: 16, color: theme.colors.primary }}>
											{Array.from(new Set(all_dates)).length} Dates
										</Text>
										<Text semi className={classes.bar_text}>
											|
										</Text>
										<Text semi style={{ fontSize: 16, color: theme.colors.primary }}>
											{get_unique_slots()} Slots
										</Text>
									</>
								)}
							</div>
						)}
					</div>

					<div style={{ marginTop: 20 }}>
						<Text semi style={{ fontSize: 14 }}>
							Add Riders
						</Text>

						<div className={classes.add_rider_wrap}>
							<div className={classes.add_rider_input_wrap}>
								<img src={ImageLinks.search_light_pink} alt='' />
								<input
									placeholder='Search Rider Name or ID'
									className={classes.add_rider_input}
									value={search_rider_id}
									onChange={(e) => set_search_rider_id(e.target.value)}
								/>
							</div>

							<div className={classes.upload_button}>
								{selected_riders.length === 0 && (
									<>
										<img
											src={ImageLinks.upload}
											className='purple-arrow'
											height={20}
											width={20}
											style={{ width: '100%', height: 'auto' }}></img>
										<img
											src={ImageLinks.upload_white}
											onClick={() => input_ref.current.click()}
											className='white-arrow'
											height={20}
											width={20}
											style={{ width: '100%', height: 'auto', display: 'none' }}></img>
									</>
								)}
								{selected_riders.length > 0 && (
									<div
										className={classes.upload_button}
										onClick={() => set_selected_riders([])}
										style={{ backgroundColor: theme.colors.lightPurple, margin: 0 }}>
										<img src={ImageLinks.refresh_single_arrow_purple}></img>
									</div>
								)}
								<input type='file' ref={input_ref} onChange={handle_upload_file} accept='.csv' style={{ display: 'none' }} />
							</div>
						</div>
						{!_.isEmpty(search_riders) && (
							<div className={classes.search_rider_wrap}>
								{_.map(search_riders, (rider, index) => {
									return (
										<ListItem
											button
											key={`search_rider_${index}`}
											onClick={() => handle_select_rider(rider)}
											tabIndex={index}
											style={{ padding: '12px 20px', ...(index !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }) }}>
											<Text>{rider.rider_id}</Text>
											<Text style={{ marginLeft: 20 }}>{rider.rider_name}</Text>
										</ListItem>
									);
								})}
							</div>
						)}

						<div className={classes.rider_list_wrap}>
							{_.map(selected_riders, (rider, key) => {
								return (
									<ListItem
										key={`selected_riders${key}`}
										button
										className={classes.rider_list_item}
										style={{
											...(key !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }),
										}}>
										<Text semi style={{ fontSize: 16 }}>
											{`${_.capitalize(rider.rider_name)} (${rider.rider_id})`}
										</Text>
										<img
											src={ImageLinks.delete}
											width={20}
											height={20}
											style={{ marginRight: 10 }}
											onClick={() => handle_select_rider(rider, true)}
										/>
									</ListItem>
								);
							})}
						</div>
					</div>
					<div className={classes.footer}>
						<Text bold style={{ fontSize: 16, color: theme.colors.darkGrey2 }}>
							{`${selected_riders.length > 0 ? selected_riders.length + ' Riders' : ''}`}
						</Text>
						<Button type='round' text='Add' size='large' onClick={add_rider_to_network} disabled={btn_disabled} style={{ width: 110 }} />
					</div>
				</div>
			</div>

			{show_add_slots_modal && (
				<AddRiderNetworkSlotsModal
					permanent={is_permanent}
					slots={added_slots}
					close={() => set_show_add_slots_modal(false)}
					handlle_slots_save={handlle_slots_save}
				/>
			)}
		</>
	);
};

export default AddRidersToNetworkModal;
