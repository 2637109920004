import Text from 'modules/Text';
import React, { useContext, useEffect } from 'react';
import { ListItem, makeStyles, Modal, withStyles, Tab, Tabs } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import theme from 'resources/theme';
import ConfirmClose from 'modules/modals/ConfirmClose';
import CreateOrderContext from 'views/CreateOrderFlow/CreateOrder/context';
import api_requests from 'resources/api_requests';

import { useSelector } from 'react-redux';
import Select from 'modules/Select';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import Routes from 'resources/Routes';

const CustomTab = withStyles({
	root: {
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightPurple}`,
	},
	selected: {
		backgroundColor: theme.colors.lightPurple11,
		border: `1px solid ${theme.colors.primary}`,
		color: theme.colors.primary,
		boxShadow: '0px 0px 6px 1px rgba(178, 157, 226, 0.5)',
	},
})(Tab);

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 20,
		background: theme.colors.white,
		width: 580,
		height: 600,
		margin: 'auto auto',
		borderRadius: 20,
	},
	list_wrap: { flex: 1, overflowY: 'auto', height: 310 },
	list_item: {
		padding: 12,
		borderRadius: 5,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		marginTop: 16,
		display: 'flex',
		alignItems: 'flex-start',
		border: `1px solid ${theme.colors.lightGrey7}`,
	},
	addr_text: { fontSize: 12, color: theme.colors.lightGrey9, marginTop: 4 },
	cta_btn: {
		borderRadius: 30,
		marginTop: 20,
		padding: '8px 32px',
		'&:disabled': {
			color: theme.colors.darkGrey2,
		},
	},
	tab_content: {
		borderRadius: '6px',
	},
	tab_text: {
		fontSize: 14,
		textTransform: 'capitalize',
	},
}));

const SavedAddresses = ({ close, title, on_address_select, address_type, clear_local_data, on_new_addr_tab_press, edit_details }) => {
	const { zones } = useSelector(({ app }) => app);
	const history = useHistory();
	const { zone_id, handle_zone_id } = useContext(CreateOrderContext);
	const [selected_address, set_selected_address] = React.useState(_.isEmpty(edit_details) ? {} : edit_details);
	const [address_list, set_address_list] = React.useState([]);
	const [tab_value, set_tab_value] = React.useState(0);
	const [confirm_close_modal, set_confirm_close_modal] = React.useState(false);
	const classes = useStyles();

	const handleClose = () => {
		if (_.isEmpty(selected_address)) {
			close();
			clear_local_data();
			return;
		}
		set_confirm_close_modal(true);
	};

	const confirm_address = () => {
		on_address_select(
			{
				...selected_address,
				latitude: selected_address.exact_location.latitude,
				longitude: selected_address.exact_location.longitude,
			},
			address_type,
		);
		close();
	};

	const handle_confirm_close = () => {
		close();
		clear_local_data();
	};

	const handle_tab_change = (event, newValue) => {
		set_tab_value(newValue);
		if (newValue === 1) {
			set_selected_address({});
		}
	};

	useEffect(() => {
		get_saved_address_data();
	}, [zone_id]);

	const get_saved_address_data = async () => {
		try {
			const response = await api_requests.get_saved_address({
				recent_count: 0,
				...(zone_id !== 'no_zone' && { master_zone_id: zone_id }),
				saved_count: 20,
			});
			if (response.success) {
				set_address_list(response.data.saved);
			}
		} catch {}
	};

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.wrapper}>
				<div className='justify-content-between align-items-center'>
					<Text component={'div'} bold style={{ fontSize: 20 }}>
						{title}
					</Text>

					<img src={ImageLinks.crossBlack} width={20} height={20} onClick={handleClose} style={{ cursor: 'pointer' }} />
				</div>

				<Tabs
					value={tab_value}
					onChange={handle_tab_change}
					variant='fullWidth'
					style={{ marginTop: '16px' }}
					TabIndicatorProps={{
						style: { display: 'none' },
					}}>
					<CustomTab
						className={classes.tab_content}
						style={{ marginRight: 20 }}
						icon={<img src={ImageLinks.home_purple} width={'16px'} height={'16px'} />}
						label={
							<Text semi className={classes.tab_text}>
								Saved
							</Text>
						}
					/>
					<CustomTab
						onClick={() => {
							on_new_addr_tab_press();
							set_tab_value(0);
						}}
						className={classes.tab_content}
						icon={<img src={ImageLinks.plusPurple} width={'16px'} height={'16px'} />}
						label={
							<Text semi className={classes.tab_text} style={{ color: theme.colors.black }}>
								New
							</Text>
						}
					/>
				</Tabs>
				{tab_value === 0 && (
					<>
						<div className='align-items-center justify-content-between' style={{ marginTop: 20 }}>
							<Select
								value={zone_id}
								defaultOption={{
									title: 'Select Master Zone',
									value: null,
								}}
								options={_.map([...zones, { name: 'No Zone', id: 'no_zone' }], (zone) => {
									return {
										title: zone.name,
										value: zone.id,
									};
								})}
								handleChange={(val) => {
									handle_zone_id(val);
									set_selected_address({});
								}}
								containerStyles={{ height: 35, width: 160 }}
								menu_styles={{ marginTop: 40 }}
								truncate_title={(title) => {
									return _.truncate(title, { length: 16 });
								}}
							/>
							<Text medium component={'div'} style={{ fontSize: 16, color: theme.colors.darkGrey2 }}>
								{address_list.length} addresses
							</Text>
						</div>
						<div className={classes.list_wrap}>
							{_.map(address_list, (address, index) => {
								return (
									<ListItem
										button
										key={`address${index}`}
										className={classes.list_item}
										style={{
											...(selected_address.id === address.id ? { borderColor: theme.colors.primary } : { borderColor: 'transparent' }),
										}}
										onClick={() => set_selected_address(address)}>
										<img src={ImageLinks.marker} style={{ marginTop: 2 }} width={14} height={14} alt='marker' />
										<div className='w-100' style={{ marginLeft: 8 }}>
											<Text semi style={{ fontSize: 14 }}>
												{address.label || '-'}
												<Text semi component={'span'} style={{ float: 'right', fontSize: 14 }}>
													{address.id}
												</Text>
											</Text>

											<Text medium className={classes.addr_text}>
												{address.address_line1} {address.state} {address.pincode}
											</Text>
										</div>
									</ListItem>
								);
							})}
							{address_list.length == 0 && (
								<div
									className='d-flex justify-content-center align-items-center'
									style={{ flexDirection: 'column', gap: 10, marginTop: '15%' }}>
									<img src={ImageLinks.empty_state_create_order_saveaddress} />
									<Text>Uh-oh! No saved addresses found.</Text>
									<Button
										type='round'
										text='New Pickup'
										left_icon={ImageLinks.addWhite}
										onClick={() => history.push(Routes.MANGE_PICKUP_ADDRESS.path)}
									/>
								</div>
							)}
						</div>
						<div className='d-flex justify-content-center align-items-center text-align-center'>
							<Button
								style={{ marginTop: 20, width: 110 }}
								type='round'
								text='Next'
								size='large'
								disabled={_.isEmpty(selected_address)}
								onClick={confirm_address}
							/>
						</div>
						{confirm_close_modal && (
							<ConfirmClose
								headerTitle='Exit Save Address Location'
								close={() => set_confirm_close_modal(false)}
								on_confirm={handle_confirm_close}
							/>
						)}
					</>
				)}
			</div>
		</Modal>
	);
};

export default SavedAddresses;
