import { Divider, makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React, { useContext, useEffect } from 'react';
import OrderModalContext from '../OrderModalContext';
import _ from 'lodash';
import moment from 'moment';
import ContainedButton from 'modules/ContainedButton';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		borderRadius: 8,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		padding: 12,
		marginTop: 20,
	},
	add_button: {
		backgroundColor: theme.colors.lightPurple,
		borderRadius: 18,
		height: 20,
		width: 46,
		padding: 0,
		marginLeft: 10,
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
		},
	},
}));

function Notes() {
	const classes = useStyles();
	const theme = useTheme();
	const { data, set_show_edit_delivery_date_slot, show_edit_delivery_date_slot } = useContext(OrderModalContext);
	useEffect(() => {
		if (show_edit_delivery_date_slot) {
			utils.track_event_for_analytics(constant.TRACKING_EVENTS.EDIT_ORDER_NOTES);
		}
	}, []);

	return (
		<div className={classes.wrapper}>
			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center' style={{ gap: 5 }}>
					<img src={ImageLinks.list_icon} height={16} width={16} />
					<Text bold component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						Notes
					</Text>
				</div>
				{_.get(data, 'status', null) === 1 && (
					<img src={ImageLinks.edit_filled_light_purple} style={{ cursor: 'pointer' }} onClick={() => set_show_edit_delivery_date_slot(true)} />
				)}
			</div>
			<Divider style={{ margin: '10px 0' }} />
			<div className='d-flex'>
				<Text style={{ fontSize: 14, color: theme.colors.darkGrey2, flex: 1 }}>
					Delivery Date:
					{data.delivery_date ? (
						<Text component={'span'} medium style={{ fontSize: 14, color: theme.colors.black }}>
							{moment(data.delivery_date).format('DD-MM-YYYY')}
						</Text>
					) : _.get(data, 'status', null) === 1 ? (
						<ContainedButton
							className={classes.add_button}
							onClick={() => {
								set_show_edit_delivery_date_slot(true);
							}}>
							<img src={ImageLinks.add_pruple_no_fill} style={{ marginRight: 2 }} height={7} width={7} />
							<Text bold style={{ fontSize: 10, color: theme.colors.primary }}>
								Add
							</Text>
						</ContainedButton>
					) : (
						<>-</>
					)}
				</Text>
				<Text style={{ fontSize: 14, color: theme.colors.darkGrey2, flex: 1 }}>
					Delivery Slot:
					{data.delivery_slot ? (
						<Text component={'span'} medium style={{ fontSize: 14, color: theme.colors.black }}>
							{data.delivery_slot}
						</Text>
					) : _.get(data, 'status', null) === 1 ? (
						<ContainedButton className={classes.add_button} onClick={() => set_show_edit_delivery_date_slot(true)}>
							<img src={ImageLinks.add_pruple_no_fill} style={{ marginRight: 2 }} height={7} width={7} />
							<Text bold style={{ fontSize: 10, color: theme.colors.primary }}>
								Add
							</Text>
						</ContainedButton>
					) : (
						<>-</>
					)}
				</Text>
			</div>
		</div>
	);
}

export default Notes;
