import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Button from 'modules/Button';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';

const Failed = ({ set_key }) => {
	return (
		<div>
			<div
				style={{
					boxShadow: '0px -4px 14px rgba(0, 0, 0, 0.05)',
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
					background: theme.colors.white,
					padding: '20px 35px',
				}}>
				<img src={ImageLinks.exclamation_error} alt='' />
				<Text semi style={{ fontSize: 16, marginLeft: 20, color: theme.colors.red }}>
					Allocation Unsuccessful!
				</Text>
				<div style={{ flex: 1 }}>
					<Button
						type='round'
						text='Try Again'
						size='large'
						onClick={() => set_key(_.uniqueId())}
						style={{ marginLeft: 15, float: 'right', with: 110 }}
					/>
				</div>
			</div>
		</div>
	);
};

export default Failed;
