import React, { useContext, useEffect } from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import { useState } from 'react';
import { useRef } from 'react';
import Text from 'modules/Text';
import Button from 'modules/Button';
import _ from 'lodash';
import CreateNetworkContext from 'views/NetworksFlow/context';

const useStyles = makeStyles(() => ({
	saved_wrap: {
		width: 102,
		height: 38,
		borderRadius: 30,
		backgroundColor: theme.colors.green,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	saved_text: {
		fontSize: 18,
		color: theme.colors.white,
		marginLeft: 6,
	},
	cta_wrap: {
		marginLeft: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 15,
	},
	track_color: {
		backgroundColor: `${theme.colors.lightGreen12}`,
	},
	filled_track_color: {
		backgroundColor: theme.colors.green,
	},
}));

const SectionCta = ({
	title,
	is_data_updated,
	handle_click,
	is_disabled,
	total_steps = 2,
	lower_section_index,
	handle_api_save,
	complete_btn_title,
	handle_data_updates,
}) => {
	const { get_task_progress } = useContext(CreateNetworkContext);
	const [show_save, set_show_save] = useState(false);
	const [show_api_save_btn, set_show_api_save_btn] = useState(false);
	const timer_ref = useRef(null);
	const classes = useStyles();

	useEffect(() => {
		return () => {
			clearTimeout(timer_ref.current);
		};
	}, []);

	useEffect(() => {
		timer_ref.current = setTimeout(() => {
			if (show_save) {
				set_show_api_save_btn(true);
				handle_data_updates(lower_section_index - 1, true);
			}
			set_show_save(false);
		}, 1000);
	}, [show_save]);

	const on_cta_click = () => {
		set_show_save(true);
		handle_click();
	};

	const on_save_api = () => {
		set_show_api_save_btn(false);
		handle_data_updates(lower_section_index - 1, false);
		handle_api_save();
	};

	const arr = Array.from({ length: total_steps }, (_, i) => i + 1);
	const progress = get_task_progress(lower_section_index);
	const section_completed = Math.round((progress * total_steps) / 100);

	return (
		<div className={classes.cta_wrap}>
			<div
				style={{
					width: '85%',
					display: 'flex',
					alignItems: 'center',
					flex: 1,
				}}>
				{_.map(arr, (item, index) => (
					<LinearProgress
						key={`progress_item_${index}`}
						style={{ borderRadius: 4, height: 8, flex: 1, marginRight: 20 }}
						value={section_completed >= item ? 100 : 0}
						variant='determinate'
						classes={{ root: classes.track_color, barColorPrimary: classes.filled_track_color }}
					/>
				))}
				<Text bold style={{ fontSize: 14, marginRight: 10 }}>{`${Math.round(progress)}%`}</Text>
			</div>
			{is_data_updated && !show_save && <Button type='round' text={title} size='large' onClick={on_cta_click} disabled={is_disabled} />}
			{show_api_save_btn && progress === 100 && (
				<Button
					type='round'
					text={complete_btn_title}
					left_icon={ImageLinks.tick_circle_white}
					size='large'
					onClick={on_save_api}
					disabled={is_disabled}
				/>
			)}
			{show_save && (
				<div className={classes.saved_wrap}>
					<img src={ImageLinks.tick_circle_white} width={16} height={16} />
					<Text semi className={classes.saved_text}>
						Saved
					</Text>
				</div>
			)}
		</div>
	);
};

export default SectionCta;
