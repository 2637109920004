import theme from './theme';

/* We only want one reference of the polygon at the given time
 	during edit or draw mode
 */

class MapPolygonUtils {
	constructor(current_map_ref, on_update_cb, on_poly_drawn) {
		/* Object will expect 3 thigs
			1. Reference of the map
			2. on_update_cb it will be called when points are updated during edit mode
			3. on_poly_drawn it will be called when points are drawn by mouse during drawing mode mode
		*/
		this.map_ref = current_map_ref;
		this.poly_coords_update = on_update_cb;
		this.poly_coords_drawn = on_poly_drawn;

		this.selected_zone_polygon = null; // This will hold the refernce to the polyline
		this.my_drawing_manager = null; // This will hold the refernce to the drawing manager
		this.updated_poly_coords = []; // This will contain all the polygon coordinated in edit and draw flow
		this.self_created_zone = null; // In draw mode this will to bounds created by polygon
	}

	load_drawing_tools = () => {
		this.my_drawing_manager = new window.google.maps.drawing.DrawingManager({
			drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
			drawingControl: false,
			polygonOptions: {
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillOpacity: 0.35,
				strokeColor: theme.colors.primary,
				fillColor: theme.colors.primary,
				// editable: true,
			},
		});
		this.my_drawing_manager.setMap(this.map_ref);

		// call back will be fired when user will join last point to first point in draw mode
		window.google.maps.event.addListener(this.my_drawing_manager, 'polygoncomplete', (polygon) => {
			this.my_drawing_manager.setMap(null);
			const all_poly_coords = polygon.getPath();

			//inserting first element at last to close the loop
			//Since it is not returned by google maps javascript API.
			all_poly_coords.push(all_poly_coords.getAt(0));

			const poly_bound = new window.google.maps.LatLngBounds();
			for (let i = 0; i < all_poly_coords.length; i++) {
				const data = {
					lat: all_poly_coords.getAt(i).lat(),
					lng: all_poly_coords.getAt(i).lng(),
				};
				const BoundLatLng = new window.google.maps.LatLng({
					lat: parseFloat(all_poly_coords.getAt(i).lat()),
					lng: parseFloat(all_poly_coords.getAt(i).lng()),
				});
				poly_bound.extend(BoundLatLng);
				this.updated_poly_coords.push(data);
			}

			// this loop will get all the coodinates of drawn polygon and will format the data and create the bound
			const centroid = poly_bound.getCenter();
			const centroid_lat = centroid.lat();
			const centroid_long = centroid.lng();
			this.self_created_zone = { color: theme.colors.primary, focal: { coordinates: [centroid_lat, centroid_long] } };
			this.poly_coords_drawn();
			polygon.setMap(null);

			/* Since We only want one reference of the polygon at the given time
				so here once user has drawn polygon we are clearing drawing manager
				storing all the coordinates in array
				clearing polygon drawn in draw mode(Drawing manager)
				and generating the new polygon of same coordinates
				to attach edit functionality to it

				We have done this way to ensure the edit listeners are attached in generate_new_polyline funciton only.
 			*/

			this.generate_new_polyline(this.updated_poly_coords, this.self_created_zone);
		});
	};

	generate_new_polyline = (polygon_coords, selected_zone, map_options = {}) => {
		this.selected_zone_polygon = new window.google.maps.Polygon({
			paths: polygon_coords,
			strokeColor: selected_zone.color,
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: selected_zone.color,
			fillOpacity: 0.35,
		});

		this.selected_zone_polygon.setMap(this.map_ref);
		this.map_ref.setOptions({
			center: { lat: selected_zone.focal.coordinates[0], lng: selected_zone.focal.coordinates[1] },
			zoom: 10,
			...map_options,
		});

		// these will fire when any point of polygon dragged
		window.google.maps.event.addListener(this.selected_zone_polygon.getPath(), 'set_at', this.handle_updated_poly_coords);
		window.google.maps.event.addListener(this.selected_zone_polygon.getPath(), 'insert_at', this.handle_updated_poly_coords);
		window.google.maps.event.addListener(this.selected_zone_polygon.getPath(), 'remove_at', this.handle_updated_poly_coords);
	};

	handle_updated_poly_coords = () => {
		const all_poly_coords = this.selected_zone_polygon.getPath();
		this.poly_coords_update();
		this.updated_poly_coords = [];
		// we are getting updated poly coords and stroting in our global context.
		for (let i = 0; i < all_poly_coords.length; i++) {
			const data = {
				lat: all_poly_coords.getAt(i).lat(),
				lng: all_poly_coords.getAt(i).lng(),
			};
			this.updated_poly_coords.push(data);
		}
	};

	/* this function is called in upload csv flow.
	it will accept coordinates and generate polygon and will attach listeners through our common function.
	*/
	upload_poly_cooords = (coords) => {
		if (this.selected_zone_polygon) this.selected_zone_polygon.setMap(null);
		const poly_bound = new window.google.maps.LatLngBounds();
		for (let i = 0; i < coords.length; i++) {
			const data = {
				lat: parseFloat(coords[i][0]),
				lng: parseFloat(coords[i][1]),
			};
			const BoundLatLng = new window.google.maps.LatLng({
				lat: parseFloat(coords[i][0]),
				lng: parseFloat(coords[i][1]),
			});
			poly_bound.extend(BoundLatLng);
			this.updated_poly_coords.push(data);
		}
		const centroid = poly_bound.getCenter();
		const centroid_lat = centroid.lat();
		const centroid_long = centroid.lng();
		this.self_created_zone = { color: theme.colors.primary, focal: { coordinates: [centroid_lat, centroid_long] } };
		// this.poly_coords_drawn();
		this.generate_new_polyline(this.updated_poly_coords, this.self_created_zone);
	};

	update_polygon_options = (options) => {
		this.selected_zone_polygon.setOptions(options);
	};

	// when save btn is pressed.It will generated new poly gon on updated coords
	handle_save_polygon = (selected_zone = this.self_created_zone) => {
		this.selected_zone_polygon.setMap(null);
		this.generate_new_polyline(this.updated_poly_coords, selected_zone);
		return;
	};

	draw_polygon_from_existing_coords = (polygon_coords, selected_zone, map_options = {}) => {
		if (this.selected_zone_polygon) {
			this.selected_zone_polygon.setMap(null);
		}
		this.generate_new_polyline(polygon_coords, selected_zone, map_options);
	};

	clear_drawing_manager = () => {
		this.my_drawing_manager.setMap(null);
	};

	clear_polygon = () => {
		if (this.selected_zone_polygon) this.selected_zone_polygon.setMap(null);
	};

	get_polygon_path = () => {
		return this.selected_zone_polygon.getPath();
	};

	deallocate_data_and_listeners = () => {
		if (this.map_ref) {
			window.google.maps.event.clearListeners(this.map_ref, 'set_at');
			window.google.maps.event.clearListeners(this.map_ref, 'insert_at');
			window.google.maps.event.clearListeners(this.map_ref, 'remove_at');
			window.google.maps.event.clearListeners(this.map_ref, 'polygoncomplete');
			// this.poly_coords_update = null;
			if (this.selected_zone_polygon) this.selected_zone_polygon.setMap(null);
			this.selected_zone_polygon = null;
			this.updated_poly_coords = [];
		}
	};
}

export default MapPolygonUtils;
