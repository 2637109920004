import React, { useEffect, useRef, useState } from 'react';
import theme from 'resources/theme';
import _, { assign } from 'lodash';
import Text from 'modules/Text';
import IconButton from 'modules/IconButton';
import cross from 'assets/images/icons/cross-black.svg';
import Select from 'modules/Select';
import { useStyles } from './styles';
import { ListItem, useTheme } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ImageLinks from 'assets/images/ImageLinks';
import moment from 'moment';
import api_requests from 'resources/api_requests';
import { shallowEqual, useSelector } from 'react-redux';
import ContainedButton from 'modules/ContainedButton';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Button from 'modules/Button';

const AssignLeave = ({ show_close_modal, is_rider_editable = true, rider_info = null, edit_date = null }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [date, set_date] = useState(edit_date ? moment(edit_date).toDate() : new Date());
	const [leave_type, set_leave_type] = useState(null);
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [search_rider_id, set_search_rider_id] = useState('');
	const [search_riders, set_search_riders] = useState([]);
	const [selected_riders, set_selected_riders] = useState([]);
	const [show_confim_modal, set_show_confirm_modal] = useState(false);
	const [roster, set_roster] = useState([]);
	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);
	const [selected_master_zone_id, set_selected_master_zone_id] = useState(zones[0].id);
	const leave_types = [
		{ title: 'Other Leave', value: 'casual_leave' },
		{ title: 'Week Off', value: 'earn_leave' },
		{ title: 'Sick Leave', value: 'casual_sick_leave' },
	];

	const get_roster = async () => {
		try {
			const res = await api_requests.get_bop_shift_by_date({
				start_date: moment.utc(moment(date).startOf('day')).format(),
				end_date: moment.utc(moment(date).endOf('day')).format(),
			});
			if (res.success) {
				set_roster(res.data.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (date != 0) get_roster();
	}, [date]);

	const handle_select_rider = (rider) => {
		const index = _.findIndex(selected_riders, { rider_id: rider.rider_id });
		const _selected_riders = [...selected_riders];

		if (index === -1) {
			_selected_riders.push(rider);
		} else {
			_selected_riders.splice(index, 1);
		}

		set_selected_riders(_selected_riders);
		set_search_rider_id('');
		set_search_riders([]);
	};

	const assign_leave = async () => {
		try {
			const res = await api_requests.apply_leave({
				rider_ids: _.map(selected_riders, 'rider_id'),
				dates: [moment(date).format('YYYY-MM-DD')],
				leave_type: leave_type.toUpperCase(),
			});
			if (res.success) {
				show_close_modal();
			}
		} catch (err) {}
	};

	const search_rider = async () => {
		try {
			const res = await api_requests.search_rider_without_zone({
				search: search_rider_id,
				is_active: true,
			});
			if (res.success) {
				set_search_riders(res.data);
			}
		} catch (err) {}
	};

	useEffect(() => {
		if (!_.isEmpty(search_rider_id)) {
			search_rider();
		} else {
			set_search_riders([]);
		}
	}, [search_rider_id]);

	useEffect(() => {
		if (_.isEmpty(rider_info)) return;
		const selected_rider_info = {
			mobile: rider_info.mobile,
			rider_category: rider_info.rider_category,
			rider_id: rider_info.id,
			rider_name: rider_info.first_name,
		};
		set_selected_riders([selected_rider_info]);
	}, [rider_info]);

	return (
		<>
			<div className={classes.wrapper} onClick={show_close_modal} />
			<div className={classes.sub_wrapper}>
				<div className={classes.header}>
					<div className={classes.header_text_wrap}>
						<Text semi className={classes.header_text}>
							Assign Leave
						</Text>
					</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<IconButton onClick={show_close_modal} style={{ border: 'none' }}>
							<img src={cross} width={16} height={16} />
						</IconButton>
					</div>
				</div>
				<div className={classes.leaves_box}>
					<Text bold className={classes.date_label}>
						Leave Details
					</Text>
					<div className={classes.divider}></div>
					<div className={classes.input_row}>
						<div>
							<Text className={classes.date_label}>Type of Leave*</Text>
							<Select
								defaultOption={{
									title: 'Select',
									value: null,
								}}
								value={leave_type}
								options={_.map(leave_types, (leave) => {
									return {
										title: leave.title,
										value: leave.value,
									};
								})}
								handleChange={(val) => set_leave_type(val)}
								containerStyles={{ height: 40, width: 210, marginRight: 20 }}
							/>
						</div>
						<div>
							<Text className={classes.date_label}>Date of Leave*</Text>

							<DatePicker
								dateFormat='yyyy-MM-dd'
								selected={date}
								onChange={(date) => set_date(date)}
								customInput={
									<div className={classes.date_pick_input}>
										<Text style={{ color: theme.colors.primary }}>{moment(date).format('DD/MM/YYYY')}</Text>
										<img src={ImageLinks.calendar_solid_circle} alt='date' onClick={() => set_date_picker_open(!date_picker_open)}></img>
									</div>
								}
							/>
						</div>
					</div>
				</div>
				<div style={{ marginTop: 30, padding: 12 }}>
					{is_rider_editable && (
						<Text bold className={classes.date_label}>
							Select Riders
						</Text>
					)}
					{is_rider_editable && (
						<div className={classes.add_rider_wrap}>
							<div className={classes.add_rider_input_wrap}>
								<img src={ImageLinks.search_light_pink} alt='' />
								<input
									disabled={!is_rider_editable}
									placeholder='Search Rider Name or ID'
									className={classes.add_rider_input}
									value={search_rider_id}
									onChange={(e) => set_search_rider_id(e.target.value)}
								/>
							</div>
						</div>
					)}
					{!_.isEmpty(search_riders) && (
						<div className={classes.search_rider_wrap}>
							{_.map(search_riders, (rider, index) => {
								return (
									<ListItem
										button
										key={`menu${index}`}
										onClick={() => handle_select_rider(rider)}
										tabIndex={index}
										style={{ padding: '12px 20px', ...(index !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }) }}>
										<Text>{rider.rider_id}</Text>
										<Text style={{ marginLeft: 20 }}>{rider.rider_name}</Text>
									</ListItem>
								);
							})}
						</div>
					)}

					<div className={classes.rider_list_wrap}>
						{_.map(selected_riders, (rider, key) => {
							const shift_id = _.get(
								_.filter(roster, (roster) => {
									return roster.id === rider.rider_id;
								})[0],
								'shift_id',
								null,
							);

							return (
								<div style={{ position: 'relative' }} key={`selected_riders${key}`}>
									<ListItem
										key={`rider${key}`}
										button
										className={classes.rider_list_item}
										style={{
											...(key !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }),
										}}
										onClick={() => {}}>
										<div className={classes.rider_info}>
											<Text bold className={classes.rider_id_text}>
												{rider.rider_id}
											</Text>
											<Text bold className={classes.rider_name_text}>
												{_.capitalize(rider.rider_name)}
											</Text>
											{shift_id && (
												<div style={{ backgroundColor: theme.colors.lightPink, padding: '4px 10px', borderRadius: 4, marginLeft: 20 }}>
													<Text semi style={{ color: theme.colors.darkPurple, fontSize: 12 }}>
														Shift exists, please remove roster
													</Text>
												</div>
											)}
										</div>
									</ListItem>
									{is_rider_editable && (
										<div className={classes.delete_btn} onClick={() => handle_select_rider(rider)}>
											<img src={ImageLinks.delete} alt='delete' width='12' height='15' style={{ marginRight: 10 }} />
										</div>
									)}
								</div>
							);
						})}
					</div>
					<div className={classes.create_btn_wrap}>
						<Button
							type='round'
							text='Assign'
							size='large'
							onClick={() => set_show_confirm_modal(true)}
							disabled={_.isEmpty(selected_riders)}
							style={{ width: 110 }}
						/>
					</div>
					{show_confim_modal && (
						<ConfirmClose
							headerTitle='Assign Leaves'
							title={
								<Text>
									Are you sure you want to assign leave to{' '}
									<Text component={'span'} bold>
										"{selected_riders.length > 1 ? selected_riders.length + ' riders' : selected_riders[0].rider_name}"
									</Text>
									.
								</Text>
							}
							on_confirm={assign_leave}
							close={() => set_show_confirm_modal(false)}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default AssignLeave;
