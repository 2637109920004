import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import RadioBox from 'modules/RadioBox';
import Select from 'modules/Select';
import Switch from 'modules/Switch';
import Text from 'modules/Text';
import React, { useEffect } from 'react';
import { useState } from 'react';
import theme from 'resources/theme';

export const useStyles = makeStyles(() => ({
	wrapper: {
		borderRadius: 6,
		minHeight: 96,
		padding: 12,
		paddingTop: 4,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey7}`,
		marginTop: 12,
	},
	separator: { border: `1px solid ${theme.colors.lightPurple}`, marginTop: 8, marginBottom: 8 },
	bottom_content: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' },
	auto_desc_text: { fontSize: 10, color: theme.colors.darkGrey2, marginTop: 8 },
	save_text: { fontSize: 12, color: theme.colors.green, textDecoration: 'underline' },
}));

const ConfigureAction = ({ item, teams, handle_action_toggle, is_last_item, handle_action_team_change }) => {
	const [in_edit_mode, set_in_edit_mode] = useState(false);
	const [local_trigger, set_local_trigger] = useState(item.trigger);
	const [local_team, set_local_team] = useState(item.config.to);
	const classes = useStyles();

	useEffect(() => {
		set_local_trigger(item.trigger);
		set_local_team(item.config.to);
	}, [item]);

	const on_team_change = (v) => {
		let key_arr = [...(local_team || [])];
		const index = _.indexOf(key_arr, v);
		if (index >= 0) {
			key_arr.splice(index, 1);
		} else {
			key_arr.push(v);
		}
		if (key_arr.length === 0) key_arr = [];
		set_local_team(key_arr);
		// copied_alert.actions[key].config.to = key_arr;
		// set_alert_detail(copied_alert);
	};

	const handle_save = () => {
		set_in_edit_mode((prev) => !prev);
		handle_action_team_change(local_trigger, local_team);
	};

	return (
		<div className={clsx('box-shadow', classes.wrapper)}>
			<div className='align-items-center justify-content-between'>
				<Text bold style={{ fontSize: 14 }}>
					{item.title}
				</Text>
				<Switch
					checked={item.active}
					onChange={() => {
						set_in_edit_mode(!item.active);
						handle_action_toggle();
					}}
				/>
			</div>
			<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
				{item.description}
			</Text>

			<div className={classes.separator}></div>

			<div className={classes.bottom_content}>
				{in_edit_mode ? (
					<div>
						<div>
							<Text medium style={{ fontSize: 12 }}>
								How do you want the action to be triggered?
							</Text>
							<RadioBox
								wrapper_styles={{ marginTop: 8 }}
								// selected_item={item.trigger}
								selected_item={local_trigger}
								on_item_press={(val) => {
									set_local_trigger(val);
									set_local_team([]);
								}}
								options={[
									{
										label: 'Auto Action',
										value: 'Auto',
									},
									{
										label: 'Manual Action',
										value: 'Manual',
									},
								]}
							/>
						</div>

						<Text className={classes.auto_desc_text}>
							{local_trigger == 'Auto'
								? '*Auto-action will Accept the action by default.'
								: '*Manual Action will be Timed-out in 5 minutes. Action will be Rejected by default.'}
						</Text>

						{local_trigger === 'Manual' && (
							<div style={{ marginTop: 20 }}>
								<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Assign to Team(s)*
								</Text>
								<Select
									show_search
									multiple
									// value={item.config.to}
									value={local_team}
									selected_title_props={{ semi: true }}
									defaultOption={{
										title: 'Select Team',
										value: null,
									}}
									options={_.map(teams, (item) => ({ title: item.title, value: `team_ch_${item.id}` }))}
									handleChange={on_team_change}
									containerStyles={{ height: 33, width: 210, marginTop: 4 }}
									menu_styles={{ width: 210, height: 180, marginTop: is_last_item ? -20 : 37 }}
								/>
							</div>
						)}
					</div>
				) : (
					<div className='align-items-center'>
						<div>
							<Text style={{ fontSize: 12 }}>
								{`Trigger: `}
								<Text component={'span'} medium style={{ fontSize: 12 }}>
									{item.active ? local_trigger : '-'}
								</Text>
							</Text>
						</div>
						<div style={{ marginLeft: 32 }}>
							<Text style={{ fontSize: 12 }}>
								{`Team: `}
								<Text component={'span'} medium style={{ fontSize: 12 }}>
									{!item.active
										? '-'
										: local_trigger === 'Auto'
										? 'N/A'
										: _.map(
												_.filter(teams, (team) => local_team.includes(`team_ch_${team.id}`)),
												(item) => item.title,
										  ).join(', ')}
								</Text>
							</Text>
						</div>
					</div>
				)}

				{item.active && (
					<ContainedButton
						disabled={in_edit_mode && local_trigger === 'Manual' && local_team.length === 0}
						disableElevation
						onClick={handle_save}
						style={{ backgroundColor: 'transparent' }}>
						{in_edit_mode ? (
							<Text semi className={classes.save_text} style={local_trigger === 'Manual' && local_team.length === 0 ? { opacity: 0.5 } : {}}>
								Save
							</Text>
						) : (
							<img src={ImageLinks.edit_purple} width={16} height={16} />
						)}
					</ContainedButton>
				)}
			</div>
		</div>
	);
};

export default ConfigureAction;
