import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { Divider, Grid, makeStyles } from '@material-ui/core';

import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import DeliveryDetails from './components/DeliveryDetails';
import PickupDetails from './components/PickupDetails';
import CreateOrderContext from '../../context';
import UploadCSV from './components/UploadCSV/UploadCSV';
import Successful from './components/Successful';
import ConfirmClose from 'modules/modals/ConfirmClose';
import theme from 'resources/theme';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	container: { marginBottom: 50, overflow: 'auto', height: '85vh' },
	containedButton: {
		position: 'fixed',
		right: 0,
		bottom: 50,
		width: 'fit-content',
	},
	textContainer: {
		fontSize: 18,
	},
	image: { marginLeft: 10 },
	delivery_details_container: {
		marginTop: 30,
	},
}));

const CreateOrderSection = () => {
	const { selected_pickup_address, selected_drop_details, step, handle_create, order_created_details, close_success_modal, handle_create_csv_orders } =
		useContext(CreateOrderContext);

	const isSummaryDisabled = _.isEmpty(selected_pickup_address) || _.isEmpty(selected_drop_details);
	const isUploadCsvModule = _.isEmpty(selected_drop_details);
	const classes = useStyles();

	const [uploaded_csv_file, set_uploaded_csv_file] = useState({});
	const [orders_count, set_orders_count] = useState(0);
	const [upload_list_item, set_upload_list_item] = useState([]);

	const handle_order_unsuccessful = () => {
		close_success_modal();
		set_uploaded_csv_file({});
		// handle_step(3);
	};

	return (
		<div className={classes.container}>
			<PickupDetails />
			{order_created_details.show ? (
				<Successful
					is_success_change={true}
					headerTitle={order_created_details.title}
					title={
						<>
							<Text bold component={'span'}>
								{_.get(order_created_details, 'total_order_creation_count', '')}
							</Text>{' '}
							were successfully created
						</>
					}
					type={order_created_details.type}
					close={() => close_success_modal()}
				/>
			) : order_created_details.show_error_modal ? (
				<ConfirmClose
					headerTitle={order_created_details.title}
					title={order_created_details.show_error_message}
					positive_btn_text={'OK'}
					show_negative_btn={false}
					on_confirm={() => handle_order_unsuccessful()}
					close={() => handle_order_unsuccessful()}
				/>
			) : (
				<div style={{ marginTop: 30 }}>
					<Grid container>
						<Grid item xl={isUploadCsvModule ? 4 : 12} lg={isUploadCsvModule ? 4 : 12} md={isUploadCsvModule ? 5 : 12} sm={12} xs={12}>
							<DeliveryDetails active={step > 1} />
						</Grid>
						{isUploadCsvModule && (
							<>
								<Grid item xl={1} lg={1} md={1}>
									<div
										className='align-items-center justify-content-center'
										style={{ flexDirection: 'column', color: theme.colors.lightGrey5 }}>
										<Divider
											orientation='vertical'
											style={{ minHeight: '110px', backgroundColor: theme.colors.lightGrey5, marginTop: 48 }}
										/>
										<Text style={{ fontSize: 12, marginTop: 4, marginBottom: 4 }}>OR</Text>
										<Divider orientation='vertical' style={{ minHeight: '110px', backgroundColor: theme.colors.lightGrey5 }} />
									</div>
								</Grid>
								<Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
									<UploadCSV
										active={step >= 2}
										uploaded_csv_file={uploaded_csv_file}
										set_uploaded_csv_file={set_uploaded_csv_file}
										orders_count={orders_count}
										set_orders_count={set_orders_count}
										upload_list_item={upload_list_item}
										set_upload_list_item={set_upload_list_item}
									/>
								</Grid>
							</>
						)}
					</Grid>
					<Button
						size='large'
						type='half_round'
						text='Create Order'
						right_icon={
							(isUploadCsvModule && _.isEmpty(uploaded_csv_file)) || (!isUploadCsvModule && isSummaryDisabled)
								? ImageLinks.right_arrow_gray
								: ImageLinks.arrow_right_white_no_fill
						}
						className={classes.containedButton}
						disabled={isUploadCsvModule ? _.isEmpty(uploaded_csv_file) : isSummaryDisabled}
						onClick={isUploadCsvModule ? () => handle_create_csv_orders(upload_list_item) : handle_create}
					/>
				</div>
			)}
		</div>
	);
};

export default CreateOrderSection;
