import React from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	subject_item_wrap: {
		minWidth: 97,
		minHeight: 66,
		borderRadius: 6,
		background: theme.colors.white,
		marginRight: 12,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		paddingLeft: 4,
		paddingRight: 4,
	},
});

const SubjectItem = ({ is_disabled, is_selected, title, icon, onClick }) => {
	const classes = useStyles();
	return (
		<div
			onClick={is_disabled ? () => {} : onClick}
			className={classes.subject_item_wrap}
			style={{
				border: `1px solid ${is_selected ? theme.colors.primary : is_disabled ? theme.colors.lightGrey2 : theme.colors.lightPurple}`,
				boxShadow: is_selected ? '0px 0px 6px 1px rgba(178, 157, 226, 0.5)' : 'none',
				backgroundColor: is_selected && is_disabled ? theme.colors.lightPink4 : is_disabled ? theme.colors.lightGrey5 : theme.colors.white,
				cursor: is_disabled ? 'auto' : 'pointer',
			}}>
			<img src={icon} />
			<Text semi style={{ fontSize: 14, marginTop: 7 }}>
				{title}
			</Text>
		</div>
	);
};

export default SubjectItem;
