import React, { useEffect, useRef, useState } from 'react';
import { Divider, MenuItem, Popover, makeStyles } from '@material-ui/core';
import qs from 'qs';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import ConfirmClose from 'modules/modals/ConfirmClose';
import api_requests from 'resources/api_requests';
import theme from 'resources/theme';
import OdometerModels from './OdometerModels';
import utils from 'resources/utils';
import ContainedButton from 'modules/ContainedButton';
import constant from 'resources/constant';
import Button from 'modules/Button';
import RouteStats from './RouteStats';

const useStyles = makeStyles((theme) => ({
	close_container: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
		width: '100%',
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 580,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 30,
		zIndex: 1200,
		overflowY: 'auto',
		paddingBottom: 120,
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
		overflowX: 'hidden',
	},
	active_button: {
		padding: '8px 16px',
		backgroundColor: theme.colors.primary,
		color: theme.colors.white,
		borderRadius: 8,
		cursor: 'pointer',
	},
	inactive_button: {
		padding: '8px 16px',
		color: theme.colors.lightPurple6,
		borderRadius: 8,
		cursor: 'pointer',
	},
	detail_container: {
		width: '100%',
		backgroundColor: 'rgba(228, 220, 243, 0.40)',
		borderRadius: 4,
		marginTop: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',

		gap: 5,
	},
	log_container: {
		borderRadius: 8,
		padding: 12,
		display: 'flex',
		border: `1px solid ${theme.colors.lightGrey5}`,
		marginTop: 10,
		justifyContent: 'space-between',
		cursor: 'pointer',
	},
	log_container_active: {
		borderRadius: 8,
		padding: 12,
		display: 'flex',
		border: `1px solid ${theme.colors.primary}`,
		marginTop: 10,
		justifyContent: 'space-between',
		cursor: 'pointer',
	},
	details_wrap: {
		borderRadius: 7,
		backgroundColor: theme.colors.white,
		width: '95%',
		padding: 10,
	},
	action_text: {
		width: 'auto',
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	arrow: {
		'&:before': {
			border: '1px solid #E6E8ED',
		},
		color: theme.palette.common.white,
	},
	tooltip: {
		backgroundColor: theme.palette.common.white,
		border: '1px solid #E6E8ED',
		color: '#4A4A4A',
		boxShadow: '0px 0px 4px 0px rgba(102, 102, 102, 0.30)',
		padding: 6,
	},
}));

function VehicleLogsModal({
	initial_tab = 'upcoming',
	selected_vehicle,
	close = () => {},
	set_assignment_data,
	set_edit_assignment_detail,
	vehicle_list,
	get_vehicle_details = () => {},
	get_vehicle_list,
}) {
	const classes = useStyles();
	const [active_tab, set_active_tab] = useState(initial_tab);
	const [selected_log, set_selected_log] = useState({});
	const [logs, set_logs] = useState([]);
	const [show_menu, set_show_menu] = useState(false);
	const [show_delete_popup, set_show_delete_popup] = useState(false);
	const [show_odometer, set_show_odometer] = useState(false);
	const [start_km, set_start_km] = useState(true);
	const [assignment_distance, set_assignment_distance] = useState(0);

	const menu_ref = useRef(null);

	useEffect(() => {
		set_selected_log({});
		get_logs();
	}, [active_tab, vehicle_list]);

	useEffect(() => {
		if (
			active_tab == 'past' &&
			!_.isEmpty(selected_log) &&
			selected_vehicle.trace_entity_id !== null &&
			!_.isEmpty(selected_log.checkin_time) &&
			!_.isEmpty(selected_log.checkout_time) &&
			selected_log.trace_distance == null
		) {
			get_fleet_assignment_total_distance();
		}
	}, [selected_log]);

	const get_logs = async () => {
		try {
			const res = await api_requests.get_fleet_logs(selected_vehicle.id, { key: active_tab.toUpperCase() });
			if (res.success) {
				set_logs(res.data.data);
				if (!_.isEmpty(selected_log)) {
					let select_log = _.filter(res.data.data, (item) => {
						return item.id == selected_log.id;
					});
					set_selected_log(select_log[0]);
				}
			}
		} catch (err) {}
	};

	const handle_delete_assignment = async () => {
		try {
			const res = await api_requests.delete_fleet_assignment(selected_log.id);
			get_logs();
			set_selected_log({});
			set_show_menu(false);
			get_vehicle_details();
			get_vehicle_list({});
		} catch (err) {}
	};

	const get_fleet_assignment_total_distance = async () => {
		try {
			const params = {
				start_time: selected_log.start_time,
				end_time: selected_log.end_time,
			};
			const res = await api_requests.get_fleet_assignment_total_distance(selected_vehicle.trace_entity_id, params);
			if (res.data) {
				set_assignment_distance(res.data.total_distance);
			}
		} catch (error) {}
	};

	const handle_trace_nav = (checkin_time, checkout_time) => {
		const endpoint = constant.TRACE_FE_URL;
		const owner_id = utils._retrieveData(constant.OWNER_ID);
		const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER).id;
		const token = utils._retrieveData(constant.AUTH_TOKEN);
		let start_date_time = !_.isEmpty(checkin_time) ? moment(checkin_time).toISOString() : undefined;
		let end_date_time = !_.isEmpty(checkout_time) ? moment(checkout_time).toISOString() : undefined;
		const obj = {
			entity_id: selected_vehicle.id,
			entity_type: 'fleet',
			owner_id,
			owner_type,
			start_date_time,
			end_date_time,
			token,
		};
		const url = `${endpoint}?${qs.stringify(obj)}`;
		window.open(url, '_blank');
	};

	return (
		<>
			<div className={classes.close_container} onClick={close} />
			<div className={classes.container}>
				<div className='d-flex align-items-center justify-content-between'>
					<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
						{`${selected_vehicle.registration_number} Logs`}
					</Text>
					<img src={ImageLinks.crossBlack} onClick={close} style={{ cursor: 'pointer' }} />
				</div>
				<Divider style={{ margin: '8px 0' }} />
				<div className='d-flex'>
					<div className={active_tab == 'upcoming' ? classes.active_button : classes.inactive_button} onClick={() => set_active_tab('upcoming')}>
						<Text bold>Upcoming{active_tab == 'upcoming' ? ` (${logs.length})` : ''}</Text>
					</div>
					<div className={active_tab == 'scheduled' ? classes.active_button : classes.inactive_button} onClick={() => set_active_tab('scheduled')}>
						<Text bold>Scheduled{active_tab == 'scheduled' ? ` (${logs.length})` : ''}</Text>
					</div>
					<div className={active_tab == 'past' ? classes.active_button : classes.inactive_button} onClick={() => set_active_tab('past')}>
						<Text bold>Previous{active_tab == 'past' ? ` (${logs.length})` : ''}</Text>
					</div>
				</div>
				{_.isEmpty(logs) && (active_tab == 'scheduled' || active_tab == 'upcoming') ? (
					<div className='d-flex justify-content-center align-items-center' style={{ height: '66vh', display: 'flex', flexDirection: 'column' }}>
						<img
							height={94}
							width={94}
							src={active_tab == 'scheduled' ? ImageLinks.empty_scheduled_assignment : ImageLinks.empty_upcoming_assignment}
						/>
						<Text semi style={{ fontSize: 16 }}>
							Create assignments for your vehicle
						</Text>
						<Button
							style={{ marginTop: 15 }}
							size='small'
							text='Assignment'
							onClick={() => {
								set_edit_assignment_detail({});
								set_assignment_data(selected_vehicle);
							}}
							left_icon={ImageLinks.addWhite}
						/>
					</div>
				) : (
					<div className={classes.detail_container} style={{ padding: _.isEmpty(selected_log) ? '30px 0' : '10px 0' }}>
						{_.isEmpty(selected_log) && !_.isEmpty(logs) && (
							<>
								<Text semi style={{ color: theme.colors.primary }}>
									Select Log for Preview
								</Text>
								<div style={{ position: 'relative' }}>
									<img src={ImageLinks.empty_logs} height={32} width={138} />
									<img src={ImageLinks.finger_purple} height={22} width={16} style={{ position: 'absolute', top: 25, right: -5 }} />
								</div>
							</>
						)}
						{!_.isEmpty(selected_log) && (
							<div className={classes.details_wrap}>
								<div className='d-flex align-items-center justify-content-between'>
									<div className='d-flex align-items-center' style={{ gap: 5 }}>
										<Text semi style={{ fontSize: 12, textTransform: 'capitalize' }}>
											{active_tab}
										</Text>
									</div>
									<div className='d-flex align-items-center' style={{ gap: 4 }}>
										{active_tab == 'past' && selected_vehicle.trace_entity_id !== null && (
											<div
												className='d-flex align-items-center'
												style={{ marginRight: 9 }}
												onClick={() => handle_trace_nav(selected_log.checkin_time, selected_log.checkout_time)}>
												<img src={ImageLinks.track_filled_purple} height={14} width={14} />
												<Text bold className={classes.action_text}>
													{`${
														selected_log.trace_distance
															? (selected_log.trace_distance / 1000).toFixed(2)
															: (assignment_distance / 1000).toFixed(2)
													} KM`}
												</Text>
											</div>
										)}
										{active_tab == 'past' && selected_vehicle.trace_entity_id == null && (
											<div className='d-flex align-items-center' style={{ marginRight: 9 }}>
												<img src={ImageLinks.wheel} height={14} width={14} />
												<Text bold className={classes.action_text} style={{ cursor: 'default' }}>
													{selected_log.odometer_end !== null && selected_log?.odometer_start !== null
														? selected_log?.odometer_end - selected_log?.odometer_start
														: '0'}{' '}
													KM
												</Text>
											</div>
										)}
										<img
											src={selected_log.rider_id == '-1' ? ImageLinks.no_rider_icon : ImageLinks.rider_light_purple2}
											height={13}
											width={13}
										/>
										<Text semi style={{ textDecoration: 'underline', fontSize: 12 }}>
											{selected_log.rider_name}
										</Text>
										<Text semi style={{ fontSize: 12 }}>
											{selected_log.rider_id == '-1' ? 'No Rider' : selected_log.rider_id}
										</Text>
										{active_tab != 'past' && (
											<div ref={menu_ref} onClick={() => set_show_menu(true)}>
												<img src={ImageLinks.three_dots_primary} style={{ cursor: 'pointer' }} />
											</div>
										)}
										<Popover
											anchorEl={menu_ref.current}
											open={show_menu}
											onClose={() => set_show_menu(false)}
											style={{ zIndex: 1300, minWidth: 144 }}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}>
											<MenuItem
												onClick={() => {
													set_show_menu(false);
													set_assignment_data(selected_vehicle);
													set_edit_assignment_detail({ ...selected_log, assingment_id: selected_log.id });
												}}
												style={{ padding: '8px 12px', minWidth: 144 }}>
												<img src={ImageLinks.edit_filled_light_purple} width={16} height={16} />
												<Text
													semi
													style={{
														color: 'inherit',
														marginLeft: 5,
													}}>
													Edit
												</Text>
											</MenuItem>
											<MenuItem
												onClick={() => {
													set_show_delete_popup(true);
												}}
												style={{ padding: '8px 12px', minWidth: 144 }}>
												<img src={ImageLinks.delete_red_filled} width={16} height={16} />
												<Text
													semi
													style={{
														color: 'inherit',
														marginLeft: 5,
													}}>
													Delete
												</Text>
											</MenuItem>
										</Popover>
									</div>
								</div>
								<Divider style={{ margin: '10px 0' }} />
								<div>
									<div className='d-flex align-items-center justify-content-between'>
										<div className='d-flex align-items-center' style={{ flex: 3, gap: 5 }}>
											<img src={ImageLinks.rider_pickup} height={14} width={14} />
											<Text semi>{selected_log.pickup_address}</Text>
										</div>
										{active_tab == 'upcoming' && (
											<div className='d-flex align-items-center' style={{ gap: 6 }}>
												<img src={ImageLinks.wheel} height={14} width={14} />
												<Text
													bold
													className={classes.action_text}
													onClick={() => {
														set_show_odometer(true);
														set_start_km(true);
													}}>
													{_.isEmpty(selected_log?.checkin_time)
														? 'Check In'
														: selected_log?.odometer_start == null
														? 'Add KM'
														: selected_log?.odometer_start}
												</Text>
											</div>
										)}
										{!_.isEmpty(selected_log.checkin_time) && (
											<Text medium style={{ marginLeft: 6 }}>
												{moment(selected_log.checkin_time).format("DD MMM 'YY, hh:mmA")}
											</Text>
										)}
									</div>
									<div className='d-flex align-items-center justify-content-between' style={{ marginTop: 10 }}>
										<div className='d-flex align-items-center' style={{ flex: 3, gap: 5 }}>
											<img src={ImageLinks.marker_down_arrow} height={14} width={14} />
											<Text semi>{selected_log.drop_address}</Text>
										</div>
										{active_tab == 'upcoming' && (
											<div className='d-flex align-items-center' style={{ gap: 4 }}>
												<img src={ImageLinks.wheel} height={14} width={14} />
												<Text
													bold
													className={classes.action_text}
													onClick={() => {
														set_show_odometer(true);
														set_start_km(false);
													}}>
													{_.isEmpty(selected_log?.checkout_time)
														? 'Check Out'
														: selected_log?.odometer_end == null
														? 'Add KM'
														: selected_log?.odometer_end}
												</Text>
											</div>
										)}
										{!_.isEmpty(selected_log.checkout_time) && (
											<Text medium style={{ marginLeft: 6 }}>
												{moment(selected_log.checkout_time).format("DD MMM 'YY, hh:mmA")}
											</Text>
										)}
									</div>
								</div>

								{!_.isEmpty(_.get(selected_log, 'route_meta', {})) && (
									<RouteStats
										trace_entity_id={selected_vehicle.trace_entity_id}
										route_data={{ ...selected_log.route_meta, route_id: selected_log.route_id }}
									/>
								)}
							</div>
						)}
					</div>
				)}

				<div style={{ maxHeight: '66vh', overflowY: 'scroll' }}>
					{_.map(logs, (log) => {
						let title = '';
						if (log.start_time && log.end_time) {
							title =
								_.isEmpty(log.checkin_time) &&
								moment(moment().format('YYYY-MM-DD HH:mm')).isBefore(moment(log.start_time).format('YYYY-MM-DD HH:mm')) &&
								(moment(moment().format('YYYY-MM-DD')).add(1, 'day').isSame(moment(log.start_time).format('YYYY-MM-DD')) ||
									moment(moment().format('YYYY-MM-DD HH:mm')).add(1, 'day').isAfter(moment(log.start_time).format('YYYY-MM-DD HH:mm')))
									? 'Upcoming'
									: _.isEmpty(log.checkin_time) &&
									  moment(moment().format('YYYY-MM-DD HH:mm')).isAfter(moment(log.start_time).format('YYYY-MM-DD HH:mm'))
									? 'Not Started'
									: !_.isEmpty(log.checkin_time) &&
									  moment(moment(log.start_time).format('YYYY-MM-DD HH:mm'))
											.add(15, 'minutes')
											.isSameOrAfter(moment(log.checkin_time).format('YYYY-MM-DD HH:mm'))
									? 'Started Ontime'
									: !_.isEmpty(log.checkin_time) &&
									  moment(moment(log.start_time).format('YYYY-MM-DD HH:mm'))
											.add(15, 'minutes')
											.isSameOrBefore(moment(log.checkin_time).format('YYYY-MM-DD HH:mm'))
									? 'Delayed'
									: '';
						}
						return (
							<div
								className={selected_log?.id == log?.id ? classes.log_container_active : classes.log_container}
								onClick={() => set_selected_log(log)}>
								{log.start_time && log.end_time && (
									<div>
										<Text
											medium
											style={{
												fontSize: 12,
												color: theme.colors.darkGrey7,
											}}>{`${moment(log?.start_time).format(`DD MMM 'YY, h:mmA`)} - ${moment(log?.end_time).format(
											`DD MMM 'YY, h:mmA`,
										)}`}</Text>
									</div>
								)}
								<div className='d-flex' style={{ gap: 5 }}>
									<>
										<img src={log.rider_id == '-1' ? ImageLinks.no_rider_icon : ImageLinks.rider_light_purple2} height={16} width={16} />
										<Text
											medium
											style={{
												fontSize: 12,
												color: theme.colors.darkGrey7,
											}}>
											{log.rider_id == '-1' ? 'No Rider' : `${log?.rider_name} (${log?.rider_id})`}
										</Text>
									</>

									{active_tab == 'past' && (
										<Tooltip title={title} arrow={true} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} placement='top'>
											<img
												src={
													title == 'Not Started'
														? ImageLinks.exclamation_error
														: title == 'Started Ontime'
														? ImageLinks.green_tick
														: title == 'Delayed'
														? ImageLinks.tick_circle_solid_yellow
														: title == 'Upcoming'
														? ImageLinks.clock
														: ''
												}
											/>
										</Tooltip>
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{show_delete_popup && (
				<ConfirmClose
					headerTitle={`Delete ${active_tab} assignment`}
					title={`Are you sure you want to remove vehicle assignment for ${moment(selected_log.start_time).format("DD MMM 'YY, hh:mmA")} - ${moment(
						selected_log.end_time,
					).format("DD MMM 'YY, hh:mmA")}?`}
					on_confirm={handle_delete_assignment}
					is_error_msg={true}
					show_negative_btn={true}
					negative_btn_text='Cancel'
					handle_negative_action={() => set_show_delete_popup(false)}
					close={() => set_show_delete_popup(false)}
				/>
			)}
			{show_odometer && (
				<OdometerModels
					close={() => set_show_odometer(null)}
					start_km={start_km}
					assingment_id={_.get(selected_log, 'id')}
					odometer_distance={start_km ? _.get(selected_log, 'odometer_start') : _.get(selected_log, 'odometer_end')}
					odometer_image={start_km ? _.get(selected_log, 'checkin_images.0') : _.get(selected_log, 'checkout_images.0')}
					updated_at={_.get(selected_log, 'updated_at')}
					updated_by={_.get(selected_log, 'updated_by')}
					update_data={get_logs}
					rider_name={_.get(selected_log, 'rider_name')}
					checkin_time={_.get(selected_log, `checkin_time`)}
					odometer_start={_.get(selected_log, `odometer_start`)}
					checkout_time={_.get(selected_log, `checkout_time`)}
					odometer_end={_.get(selected_log, `odometer_end`)}
					get_vehicle_list={get_vehicle_list}
				/>
			)}
		</>
	);
}

export default VehicleLogsModal;
