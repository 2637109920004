import React, { useEffect } from 'react';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import constant from 'resources/constant';
import theme from 'resources/theme';
import ContainedButton from 'modules/ContainedButton';

const Pincodes = ({ selected_master_zone, selected_business_zone, on_edit_business_zone }) => {
	const map = React.useRef(null);

	const initialize = () => {
		if (!map.current) {
			map.current = new window.google.maps.Map(document.getElementById('map_canvas'), {
				center: { lat: selected_business_zone.focal.coordinates[0], lng: selected_business_zone.focal.coordinates[1] },
				zoom: 10,
				clickableIcons: false,
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				mapId: constant.MAP_DESIGN_ID,
			});
		}

		const master_zone_coords = _.map(_.get(selected_master_zone, 'geometry.coordinates[0]', []), (coord) => {
			return { lat: coord[0], lng: coord[1] };
		});

		// Construct the polygon.
		const master_polygon = new window.google.maps.Polygon({
			paths: master_zone_coords,
			strokeColor: theme.colors.primary,
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillOpacity: 0,
		});

		master_polygon.setMap(map.current);

		const polygon_coords = _.map(_.get(selected_business_zone, 'geometry.coordinates[0]', []), (coord) => {
			return { lat: coord[0], lng: coord[1] };
		});
		// Construct the polygon.
		const polygon = new window.google.maps.Polygon({
			paths: polygon_coords,
			strokeColor: selected_business_zone.color,
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: selected_business_zone.color,
			fillOpacity: 0.35,
		});
		polygon.setMap(map.current);
	};

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, []);

	return (
		<div style={{ flex: 1, display: 'flex', overflowY: 'hidden' }}>
			<div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '17px 0px' }}>
				<div style={{ flex: 1, overflowY: 'auto', paddingBottom: 10 }}>
					<Text bold style={{ fontSize: 24 }}>
						{selected_business_zone.title}
					</Text>
				</div>
				<ContainedButton
					onClick={on_edit_business_zone}
					disableElevation
					style={{ width: 215, borderRadius: 50, height: 44, backgroundColor: theme.colors.lightPurple }}>
					<img src={ImageLinks.edit_purple} width={16} height={16} />
					<Text semi style={{ fontSize: 18, color: theme.colors.black, marginLeft: 5 }}>
						Edit Business Zone
					</Text>
				</ContainedButton>
			</div>
			<div style={{ flex: 1, padding: 40 }}>
				<div
					id='map_canvas'
					className='map-canvas-wrapper rider-location card'
					style={{ height: '100%', borderRadius: 20, background: theme.colors.lightGrey3 }}></div>
			</div>
		</div>
	);
};

export default Pincodes;
