import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import api_requests from 'resources/api_requests';
import { hide_loader, show_loader } from 'actions/app';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import CreateHub from './components/CreateHub';
import ListView from './components/ListView';
import NoHubs from './components/NoHubs';
import { toast } from 'react-toastify';
import ConfirmClose from 'modules/modals/ConfirmClose';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Button from 'modules/Button';
import NoResult from 'modules/NoResult';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between' },
	settings_text: {
		fontSize: 22,
		color: 'black',
	},
	screen_text_wrap: { width: 2, height: 30, background: '#B0B0B0', marginLeft: 20 },
	screen_text: {
		fontSize: 22,
		marginLeft: 20,
		borderBottom: '4px solid #E4DCF3',
		color: 'black',
	},
	header_btn_text: { marginLeft: 10, fontSize: 16 },
	header_btn_icon: { width: 12, height: 12 },
	wrapper_empty_state_no_result: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 30 },
});

const SCREENS = {
	list: 'list',
	no_hubs: 'no_hubs',
	no_hub_filter: 'no_hub_filter',
};

const initial_confirm_delete_hub_details = {
	open: false,
	hub_id: null,
};

const ManageHub = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [screen, set_screen] = React.useState(SCREENS.no_hubs);
	const [page, set_page] = React.useState(1);
	const [show_create_hub, set_show_create_hub] = React.useState(false);
	const [hubs, set_hubs] = React.useState([]);
	const [refresh_hubs, set_refresh_hubs] = React.useState(false);
	const [confirm_delete_hub_details, set_confirm_delete_hub_details] = React.useState(_.cloneDeep(initial_confirm_delete_hub_details));
	const [selected_filters, set_selected_filters] = React.useState({});

	const classes = useStyles();

	const { is_loading } = useSelector((state) => state.app, shallowEqual);

	const [selected_master_zone, set_selected_master_zone] = React.useState(null);

	const get_hubs = () => {
		dispatch(show_loader());
		set_hubs([]);
		api_requests
			.get_bop_hubs({
				page,
				...(selected_filters.selected_master_zone && { master_zone_id: selected_filters.selected_master_zone?.id }),
				...(selected_filters.selected_business_zone && { zone_id: selected_filters.selected_business_zone?.id }),
			})
			.then((res) => {
				if (!_.isEmpty(_.get(res.data, 'data'))) {
					set_screen(SCREENS.list);
					set_hubs(res.data);
				} else {
					if (page === 1 && !selected_filters.selected_master_zone && !selected_filters.selected_business_zone) {
						set_screen(SCREENS.no_hubs);
					} else if (Object.keys(selected_filters).length > 0) {
						set_screen(SCREENS.no_hub_filter);
					}
				}
				dispatch(hide_loader());
			});
	};

	useEffect(() => {
		get_hubs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, refresh_hubs, selected_filters]);

	const open_create_hub = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.HUB_NEW_HUB);
		set_show_create_hub(true);
	};

	const confirm_delete_hub = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.HUB_DELETE_HUB);
		const hub_id = confirm_delete_hub_details.hub_id;
		const hub_index = _.findIndex(hubs.data, { id: Number(hub_id) });
		const hub = hubs.data[hub_index] || {};
		api_requests
			.delete_hub(hub_id, {
				title: hub.title,
				location: hub.location,
				is_deleted: true,
			})
			.then((res) => {
				const all_hubs_data = [...hubs.data];
				all_hubs_data.splice(hub_index, 1);

				set_hubs({
					...hubs,
					data: all_hubs_data,
				});
				toast.success(res.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			});
	};

	const handle_delete_hub = (hub_id) => {
		set_confirm_delete_hub_details({ open: true, hub_id });
	};

	if (screen === null) {
		return null;
	}
	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<div className='align-items-center'>
					<div className='page_back_btn' onClick={() => history.goBack()}>
						<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
					</div>
					<Text semi className={'global_header_text'}>
						Hub Management
					</Text>
				</div>

				{(screen === SCREENS.list || screen === SCREENS.no_hub_filter) && (
					<div>
						<Button type='round' text='New Hub' size='large' onClick={open_create_hub} left_icon={ImageLinks.addWhite} />
					</div>
				)}
			</div>
			{(screen === SCREENS.list || screen === SCREENS.no_hub_filter) && (
				<ListView
					existing_filters={selected_filters}
					set_selected_filters={set_selected_filters}
					hubs={hubs}
					get_hubs={get_hubs}
					page={page}
					set_page={set_page}
					handle_delete_hub={handle_delete_hub}
				/>
			)}
			{screen === SCREENS.no_hubs && !is_loading && <NoHubs open_create_hub={open_create_hub} />}
			{screen === SCREENS.no_hub_filter && !is_loading && (
				<div className={classes.wrapper_empty_state_no_result}>
					<NoResult text='No hub found' btn_onclick={() => set_selected_filters({})} />
				</div>
			)}
			{show_create_hub && (
				<CreateHub
					zone_id={selected_master_zone}
					close={(isCreated) => {
						if (isCreated) {
							set_refresh_hubs(!refresh_hubs);
						}
						set_show_create_hub(false);
					}}
				/>
			)}
			{confirm_delete_hub_details.open && (
				<ConfirmClose
					headerTitle={'Delete Hub'}
					title={`Are you sure you want to delete this hub? This action cannot  be undone.`}
					close={() => set_confirm_delete_hub_details(_.cloneDeep(initial_confirm_delete_hub_details))}
					positive_btn_text={'Delete'}
					negative_btn_text={'Cancel'}
					on_confirm={confirm_delete_hub}
					is_error_msg
				/>
			)}
		</div>
	);
};

export default ManageHub;
