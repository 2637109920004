import React, { useEffect } from 'react';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/styles';

import Text from 'modules/Text';
import IconButton from 'modules/IconButton';

import cross from 'assets/images/icons/cross.svg';
import cross_black from 'assets/images/icons/cross-black.svg';
import Select from 'modules/Select';
import _ from 'lodash';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import OutlinedTextField from 'modules/OutlinedTextField';
import ContainedButton from 'modules/ContainedButton';
import Amount from '../../../../modules/Amount';
import api_requests from 'resources/api_requests';
import { Card, MenuItem } from '@material-ui/core';
import moment from 'moment';
import utils from 'resources/utils';
import constant from 'resources/constant';
import { shallowEqual, useSelector } from 'react-redux';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 600,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 50,
		zIndex: 1200,
		overflowY: 'auto',
	},
	pbidContainer: {
		fontSize: 12,
		color: theme.colors.primary,
	},
}));

const TransferCash = ({ close, refresh_stats }) => {
	const { selected_business_id, business_accounts } = useSelector(
		(state) => ({
			selected_business_id: state.app.selected_business_id,
			business_accounts: state.app.business_accounts,
		}),
		shallowEqual,
	);

	const selected_business = _.find(business_accounts, { id: selected_business_id });
	const default_owner_type = utils._retrieveData(constant.TYPE_OF_OWNER);

	const [form_data, set_form_data] = React.useState({
		amount: '',
		receiver: {
			id: null,
			type: null,
			name: '',
			owner_id: selected_business.id,
			owner_type: default_owner_type.id,
		},
		remark: '',
		additional_info: {},
	});

	const [search_account_text, set_search_account_text] = React.useState('');
	const [search_accounts, set_search_accounts] = React.useState([]);
	const [account_info, set_account_info] = React.useState({});

	const [otp, set_otp] = React.useState('');
	const [txn_id, set_txn_id] = React.useState(null);

	const classes = useStyles();

	const handle_send_cash = () => {
		set_otp('');
		api_requests
			.init_offline_cash_transfer({
				...form_data,
				...(_.isEmpty(form_data.remark) && { remark: 'Cash Transfer' }),
			})
			.then((res) => {
				set_txn_id(res.data.txnId);
			});
	};

	const handle_confirm = () => {
		api_requests.process_cash_txn(txn_id, { otp }).then((res) => {
			refresh_stats();
			close();
		});
	};

	const handle_change = (name, value) => {
		const _form_data = _.cloneDeep(form_data);
		_.set(_form_data, name, value);

		switch (name) {
			case 'receiver.owner_type':
				_form_data.receiver = {
					..._form_data.receiver,
					id: null,
					type: null,
					name: '',
					owner_id: null,
				};

				break;

			case 'receiver.owner_id':
				_form_data.receiver = {
					..._form_data.receiver,
					id: null,
					type: null,
					name: '',
				};
				break;

			default:
				break;
		}

		set_txn_id(null);
		set_form_data(_form_data);
	};

	const clear_account_data = () => {
		set_txn_id(null);
		set_search_account_text('');
		set_search_accounts([]);
		set_account_info({});
	};

	useEffect(() => {
		if (form_data.receiver.owner_id === null || form_data.receiver.type === null) {
			return;
		}

		clear_account_data();
	}, [form_data.receiver.owner_id, form_data.receiver.type]);

	useEffect(() => {
		if (form_data.receiver.id === null) {
			return;
		}

		api_requests
			.get_cm_account_info({
				id: form_data.receiver.id,
				user_type: form_data.receiver.type,
			})
			.then((res) => {
				set_account_info(res.data);
			});
	}, [form_data.receiver.id]);

	useEffect(() => {
		if (
			form_data.receiver.owner_type === null ||
			form_data.receiver.owner_id === null ||
			form_data.receiver.type === null ||
			_.isEmpty(search_account_text)
		) {
			clear_account_data();
			return;
		}

		api_requests
			.search_rider_by_account({
				user_type: form_data.receiver.type,
				params: {
					q: search_account_text,
				},
			})
			.then((res) => {
				set_search_accounts(res.data.items);
			});
	}, [search_account_text]);

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div
					style={{
						borderBottom: '1px solid lightgrey',
						paddingBottom: 10,
						display: 'flex',
						justifyContent: 'space-between',
					}}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Text semi style={{ fontSize: 20, color: theme.colors.primary }}>
							Send Cash
						</Text>
					</div>
					<IconButton onClick={close} style={{ border: 'none' }}>
						<img src={cross_black} width={16} height={16} />
					</IconButton>
				</div>
				<div style={{ display: 'flex', marginTop: 20 }}>
					<div>
						<Text semi style={{ fontSize: 12 }}>
							Select Owner type
						</Text>
						<Select
							value={form_data.receiver.owner_type}
							defaultOption={{
								title: default_owner_type.title,
								value: default_owner_type.id,
							}}
							handleChange={(val) => handle_change('receiver.owner_type', val)}
							containerStyles={{ height: 40, width: 180, marginTop: 5 }}
							text_props={{ semi: true }}
						/>
					</div>
					<div style={{ marginLeft: 40 }}>
						<Text semi style={{ fontSize: 12 }}>
							Select Owner Name
						</Text>

						<Select
							value={form_data.receiver.owner_id}
							defaultOption={{
								title: selected_business.brand_name,
								value: selected_business.id,
							}}
							options={[]}
							truncate_length={18}
							handleChange={(val) => handle_change('receiver.owner_id', val)}
							containerStyles={{ height: 40, width: 180, marginTop: 5 }}
							text_props={{ semi: true }}
						/>
					</div>
				</div>
				<div style={{ marginTop: 30, display: 'flex', alignItems: 'flex-end' }}>
					<div>
						<Text semi style={{ fontSize: 12 }}>
							Select User type
						</Text>
						<Select
							value={form_data.receiver.type}
							defaultOption={{
								title: 'Select User type',
								value: null,
							}}
							options={_.map(
								[
									{
										value: 'User',
										key: 'user',
									},
									{
										value: 'Rider',
										key: 'rider',
									},
								],
								(user_type) => {
									return {
										title: user_type.value,
										value: user_type.key,
									};
								},
							)}
							handleChange={(val) =>
								handle_change('receiver', {
									...form_data.receiver,
									id: null,
									type: val,
									name: '',
								})
							}
							containerStyles={{ height: 40, width: 180, marginTop: 5 }}
							text_props={{ semi: true }}
						/>
					</div>
					<div style={{ position: 'relative', marginLeft: 40, height: 50 }}>
						<Text style={{ fontSize: 12 }}>Name/ ID</Text>
						{!_.isEmpty(form_data.receiver.name) ? (
							<div style={{ marginTop: 10, display: 'flex' }}>
								<Text>{`${form_data.receiver.name} (${form_data.receiver.id})`}</Text>
								<IconButton
									style={{ width: 15, height: 15, padding: 8, marginLeft: 10, marginTop: 2 }}
									onClick={() => {
										set_account_info({});
										handle_change('receiver', {
											...form_data.receiver,
											id: null,
											name: '',
										});
									}}>
									<img src={cross} width='10' height='10' />
								</IconButton>
							</div>
						) : (
							<UndlerlineTextField
								label=''
								value={search_account_text}
								onChange={(e) => {
									set_search_account_text(e.target.value);
								}}
								style={{ width: 200 }}
							/>
						)}

						{search_accounts.length > 0 && (
							<Card
								style={{
									position: 'absolute',
									top: 50,
									background: 'white',
									left: 0,
									width: 200,
									maxHeight: 200,
									overflowY: 'auto',
								}}>
								{_.map(search_accounts, (account, index) => {
									const id = account.id;
									return (
										<MenuItem
											key={`search_rider_${index}`}
											onClick={() => {
												set_search_account_text('');
												set_search_accounts([]);
												handle_change('receiver', {
													...form_data.receiver,
													id: id,
													name: account.name,
												});
											}}
											tabIndex={index}
											style={{ padding: '8px 14px' }}>
											<Text>
												{account.name} ({id})
											</Text>
										</MenuItem>
									);
								})}
							</Card>
						)}
					</div>
				</div>

				<Text semi style={{ fontSize: 12, marginTop: 32 }}>
					Details
				</Text>

				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
					<div>
						<Amount
							amount={account_info.balance}
							main_text_styles={{
								color: account_info.balance < 0 ? theme.colors.green : theme.colors.red,
								fontSize: 24,
							}}
							sub_text_styles={{
								color: account_info.balance < 0 ? theme.colors.green : theme.colors.red,
								fontSize: 12,
							}}
						/>
						<Text bold style={{ fontSize: 12 }}>
							Total Outstanding
						</Text>
					</div>
					<div style={{ display: 'flex' }}>
						<div
							className='box-shadow'
							style={{
								background: theme.colors.lightPurple,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								padding: '10px 15px',
								borderRadius: 10,
								marginLeft: 10,
							}}>
							<Amount
								amount={_.get(account_info, 'stats.today.credit', '')}
								main_text_styles={{ color: theme.colors.darkGrey2, fontSize: 20 }}
								sub_text_styles={{ color: theme.colors.darkGrey2, fontSize: 12 }}
							/>
							<Text semi style={{ fontSize: 10, marginTop: 5 }}>
								Collected {moment().format('DD/MM/YY')}
							</Text>
						</div>
						<div
							className='box-shadow'
							style={{
								background: theme.colors.white,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								padding: '10px 15px',
								borderRadius: 10,
								marginLeft: 10,
							}}>
							<Amount
								amount={_.get(account_info, 'stats.today.debit', '')}
								main_text_styles={{ color: theme.colors.darkGrey2, fontSize: 20 }}
								sub_text_styles={{ color: theme.colors.darkGrey2, fontSize: 12 }}
							/>
							<Text semi style={{ fontSize: 10, marginTop: 5 }}>
								Deposited {moment().format('DD/MM/YY')}
							</Text>
						</div>
					</div>
				</div>

				<Text semi style={{ fontSize: 12, marginTop: 25 }}>
					Enter Amount
				</Text>
				<div style={{ position: 'relative', marginTop: 10 }}>
					<Text style={{ position: 'absolute', left: 15, top: 7, fontSize: 20, color: theme.colors.black }}>₹</Text>
					<OutlinedTextField
						InputProps={{ style: { borderRadius: 50, height: 45, paddingLeft: 20 } }}
						style={{ width: 200 }}
						value={form_data.amount}
						onChange={(e) => handle_change('amount', e.target.value)}
					/>
				</div>

				<div>
					<Text semi style={{ fontSize: 12, marginTop: 25 }}>
						Enter Remarks
					</Text>
					<textarea
						value={form_data.remark}
						onChange={(e) => handle_change('remark', e.target.value)}
						style={{
							width: '100%',
							marginTop: 10,
							borderRadius: 10,
							borderColor: theme.colors.lightGrey2,
							padding: 10,
							resize: 'none',
						}}
						rows='3'></textarea>
				</div>

				{txn_id && (
					<div>
						<Text semi style={{ fontSize: 12, marginTop: 25 }}>
							Enter 4-digit OTP sent to User
						</Text>
						<div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
							<OutlinedTextField
								InputProps={{ style: { borderRadius: 50, height: 45 } }}
								style={{ width: 200 }}
								onChange={(e) => set_otp(e.target.value)}
							/>
							<Text bold style={{ textDecoration: 'underline', marginLeft: 20, fontSize: 12, cursor: 'pointer' }} onClick={handle_send_cash}>
								Regenerate OTP
							</Text>
						</div>
					</div>
				)}

				<div style={{ marginTop: 50, textAlign: 'right' }}>
					<Button
						type='round'
						text='Send Cash'
						size='large'
						onClick={handle_send_cash}
						disabled={!_.isEmpty(txn_id) || _.isEmpty(form_data.amount) || _.isEmpty(form_data.receiver.name)}
						style={{ width: 115 }}
					/>

					{txn_id && (
						<ContainedButton
							style={{ borderRadius: 40, height: 45, width: 145, marginLeft: 40 }}
							onClick={handle_confirm}
							disabled={otp.length !== 4}>
							<Text semi style={{ fontSize: 18 }}>
								Confirm
							</Text>
						</ContainedButton>
					)}
				</div>
			</div>
		</>
	);
};
export default TransferCash;
