import Text from 'modules/Text';
import React from 'react';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import useStyles from '../styles';
import theme from 'resources/theme';

const RiderLocation = ({ rider_data, edit_addr }) => {
	const classes = useStyles();
	return (
		<div style={{ marginTop: 40 }}>
			<div className='align-items-center'>
				<img src={ImageLinks.marker_purple} width={16} height={16} />
				<Text semi style={{ fontSize: 16, marginLeft: 8 }}>
					Address
				</Text>
				<div className={classes.section_header_line}></div>
			</div>
			<div className='align-items-center' style={{ marginTop: 27 }}>
				<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey2, width: 70 }}>
					Permanent Address*
				</Text>
				<div className={classes.address_card}>
					<div className='d-flex'>
						<img src={ImageLinks.light_marker_purple} width={16} height={16} />
						<div style={{ marginLeft: 6 }}>
							<Text bold style={{ fontSize: 14 }}>
								{_.get(rider_data, 'current_location.address_line_1', 'Address Line 1 (from input field)')}
							</Text>
							{/* <Text medium style={{ fontSize: 12, color: theme.colors.lightGrey9, marginTop: 4 }}>
								Area Name (which is fetch from Google Map)
							</Text> */}
						</div>
					</div>

					<div className={classes.addr_separator}></div>

					<div className='align-items-center justify-content-between'>
						<div className='align-items-center'>
							<div className='align-items-center'>
								<img src={ImageLinks.zone} width={16} height={16} />
								<Text style={{ fontSize: 12, marginLeft: 2 }}>{_.get(rider_data, 'current_location.zone.title', 'Zone')}</Text>
							</div>
							<div className='align-items-center' style={{ marginLeft: 32, marginRight: 32 }}>
								<img src={ImageLinks.zone} width={16} height={16} />
								<Text style={{ fontSize: 12, marginLeft: 2 }}>{`${_.get(rider_data, 'current_location.city', 'City')}, ${_.get(
									rider_data,
									'current_location.state',
									'State',
								)}`}</Text>
							</div>
							<div className='align-items-center'>
								<img src={ImageLinks.zone} width={16} height={16} />
								<Text style={{ fontSize: 12, marginLeft: 2 }}>{_.get(rider_data, 'current_location.pin_code', 'Pincode')}</Text>
							</div>
						</div>

						<div className='align-items-center'>
							<img className='cursor-pointer' src={ImageLinks.edit} width={14} height={14} onClick={edit_addr} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RiderLocation;
