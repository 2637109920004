import React, { useEffect, useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import _ from 'lodash';

import ImageLinks from 'assets/images/ImageLinks';
import SettingsCard from './components/SettingsCard';
import Text from 'modules/Text';
import { cardDataCol1, cardDataCol2, cardDataCol3 } from './constant';
import api_requests from 'resources/api_requests';
import utils from 'resources/utils';
import { useDispatch, useSelector } from 'react-redux';
import { toggle_support_modal } from 'actions/uiUpdates';
import FirstTimeOverlay from 'modules/FirstTimeOverlay';
import constant from 'resources/constant';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '32px 32px 0 32px',
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		backgroundColor: theme.colors.white,
		height: 'fit-content',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		height: 40,
	},
	heading_text: {
		fontSize: 22,
		color: 'black',
	},
	search_settings: {
		backgroundColor: theme.colors.white,
		borderRadius: 40,
		display: 'flex',
		height: 40,
		width: 280,
		paddingLeft: 16,
		paddingRight: 16,
		border: '1px solid #C4C4C4',
	},
	textBox: { flex: 1, border: 'none', outline: 'none' },
	banner: {
		width: 200,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 6,
		position: 'relative',
		height: '85vh',
	},
	body_section: { display: 'flex', marginTop: 32 },
	card_container: { display: 'flex', height: '85vh', width: 'calc( 100% - 200px )', overflowY: 'auto' },
	banner_heading: {
		position: 'relative',
		flex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
		padding: 17,
		gap: 15,
	},
	font_size16: { fontSize: 16, color: theme.colors.primary },
	columnMargin: { marginRight: 15, width: '33%' },
	web_app_link_container: { height: 70, width: 70, backgroundColor: theme.colors.primary, padding: '20px 12px', borderRadius: 14, cursor: 'pointer' },
	wrapper_container: {
		border: `1px solid ${theme.colors.lightPurple}`,
		borderRadius: 8,
		marginBottom: 15,
		padding: '18px 24px',
		flexDirection: 'column',
		position: 'relative',
	},
	kyc_container: {
		backgroundColor: theme.colors.lightPurple,
		padding: 20,
		borderRadius: 6,
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
		marginBottom: 50,
	},
	pidge_puple_background_box: {
		backgroundColor: theme.colors.primary,
		width: 70,
		height: 70,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 12,
		justifyContent: 'center',
	},
	divider: { height: 1, width: '100%', backgroundColor: theme.colors.lightPurple6 },
	underline_text: { textDecoration: 'underline', color: theme.colors.primary },
	avatar: { position: 'absolute', top: 8, left: 15, height: 50, width: 50, border: `5px solid ${theme.colors.white}` },
}));

const Settings = () => {
	const { business_accounts } = useSelector(({ app }) => app);
	const [business_details, set_business_details] = useState(null);
	const [show_overlay, set_show_overlay] = useState(false);
	const [show_invite_code, set_show_invite_code] = useState(false);
	const [business_list, set_business_list] = useState([]);
	const selected_business = utils._retrieveData(constant.SELECTED_BUSINESS);
	const selected_account_info = _.filter(business_accounts, (account) => {
		return account.id == selected_business.business_id;
	})[0];
	const dispatch = useDispatch();
	const classes = useStyles();

	const start_kyc = (is_kyc = false) => {
		const support_data = {
			show_modal: true,
			...(is_kyc && { mode: 'kyc' }),
		};
		dispatch(toggle_support_modal(support_data));
	};

	useEffect(() => {
		api_requests.get_business_details().then((res) => {
			set_business_details(res.data);
		});
		api_requests.get_child_business().then((res) => {
			set_business_list(res.data);
		});
	}, []);

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<Text semi className={'global_header_text'}>
					Settings
				</Text>
			</div>

			<div className={classes.body_section}>
				{show_overlay && (
					<FirstTimeOverlay
						onClose={() => {
							localStorage.setItem('new_user', false);
							set_show_overlay(false);
						}}
					/>
				)}
				<div className={classes.card_container}>
					<div className={classes.columnMargin}>
						<div className={classes.wrapper_container} style={{ padding: 0, backgroundColor: theme.colors.lightPurple, paddingTop: 8 }}>
							<div className='d-flex align-items-center' style={{ gap: 20, padding: '10px 20px', height: 50 }}>
								<Avatar className={classes.avatar} src={ImageLinks.user_profile}></Avatar>
								<div style={{ marginLeft: 50 }}>
									<Text semi style={{ fontSize: 16, color: theme.colors.primary }}>
										{utils._retrieveData('name')}
										<Text semi component={'span'} style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
											({utils._retrieveData('user_id')})
										</Text>
									</Text>
								</div>
							</div>
							<div
								className='d-flex align-items-center justify-content-between'
								style={{ backgroundColor: theme.colors.white, margin: '12px 8px', padding: 10, borderRadius: 6 }}>
								<Text semi style={{ fontSize: 14 }}>
									{utils._retrieveData('selected_business').brand_name}
									<Text semi component={'span'} style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										({selected_account_info.id})
									</Text>
								</Text>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										set_show_invite_code(true);
									}}>
									<Text semi className={classes.underline_text}>
										{show_invite_code ? selected_account_info.invite_code : 'Invite Code'}
									</Text>
								</div>
							</div>
						</div>
						{_.map(cardDataCol1, (item, index) => {
							return <SettingsCard item={item} key={index} business_list={business_list} />;
						})}
					</div>
					<div className={classes.columnMargin}>
						{_.map(cardDataCol2, (item, index) => {
							return <SettingsCard item={item} key={index} />;
						})}
					</div>
					<div className={classes.columnMargin}>
						{_.map(cardDataCol3, (item, index) => {
							let _item = _.cloneDeep(item);

							if (_item.id === 'wallet') {
								_item.name += ` ( ₹${_.get(business_details, 'balance', '-')} )`;
							}

							return <SettingsCard item={_item} key={index} />;
						})}
						<div className={classes.wrapper_container}>
							<div className='d-flex align-items-center' style={{ gap: 30 }}>
								<img src={ImageLinks.document_sample} width={40} height={40}></img>
								<div>
									<Text medium style={{ fontSize: 14 }}>
										Explore features in depth
									</Text>
									<Button
										type='outlined'
										text='Onboarding Guide'
										size='small'
										onClick={() => {
											utils.track_event_for_analytics(constant.TRACKING_EVENTS.ON_BORDING_GUIDE);
											set_show_overlay(true);
										}}
										right_icon={ImageLinks.arrow_right_purple}
										style={{ marginTop: 10 }}
									/>
								</div>
							</div>
							<div className='d-flex align-items-center' style={{ gap: 30, marginTop: 25 }}>
								<img src={ImageLinks.overlay_chat} width={40} height={40}></img>
								<div>
									<Text medium style={{ fontSize: 14 }}>
										Have a question?
									</Text>
									<Button
										type='outlined'
										text='Request Support'
										size='small'
										onClick={() => {
											utils.track_event_for_analytics(constant.TRACKING_EVENTS.REQUEST_SUPPORT);
											start_kyc(false);
										}}
										style={{ marginTop: 10 }}
									/>
								</div>
							</div>
						</div>
						<div className={classes.kyc_container}>
							<Text bold style={{ color: theme.colors.primary, fontSize: 20 }}>
								Give more power to your dashboard{' '}
							</Text>
							<Text semi style={{ color: theme.colors.primary, fontSize: 14 }}>
								Access features seamlessly across product
							</Text>
							<Button
								type='round'
								text='Start KYC'
								size='large'
								onClick={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.START_KYC);
									start_kyc(true);
								}}
								style={{ width: 'fit-content' }}
							/>
						</div>
					</div>
				</div>
				<div className={classes.banner}>
					<div className={classes.banner_heading}>
						<div className={classes.pidge_puple_background_box}>
							<img src={ImageLinks.logoFullWhite} style={{ width: '90%' }}></img>
						</div>
						<Text semi className={classes.font_size16}>
							Access the dashboard on your mobile!
						</Text>
						<img
							src={ImageLinks.play_store_logo}
							style={{ cursor: 'pointer' }}
							onClick={() =>
								window.open('https://play.google.com/store/apps/details?id=com.rindventures.pidgeconsumer&hl=en_IN&gl=US', '_blank')
							}></img>
						<img
							src={ImageLinks.app_store_logo}
							style={{ cursor: 'pointer' }}
							onClick={() => window.open('https://apps.apple.com/in/app/pidge-on-demand-delivery/id1479218436', '_blank')}></img>

						<div className={classes.divider}></div>
						<Text semi className={classes.font_size16}>
							Track riders & manage logistics with
						</Text>
						<div className='d-flex' style={{ gap: 20 }}>
							<div
								className={classes.web_app_link_container}
								onClick={() => window.open('https://play.google.com/store/apps/details?id=com.rindventures.pidge&hl=en_IN&gl=US', '_blank')}>
								<img src={ImageLinks.logoFullWhite} style={{ width: '80%' }} />
								<div style={{ background: theme.colors.white }}>
									<Text style={{ fontSize: 9 }}>Rider</Text>
								</div>
							</div>
							<div
								className={classes.web_app_link_container}
								onClick={() => window.open('https://play.google.com/store/apps/details?id=com.rindventures.pidgeadminapp', '_blank')}>
								<img src={ImageLinks.logoFullWhite} style={{ width: '80%' }} />
								<div style={{ background: theme.colors.white }}>
									<Text style={{ fontSize: 9 }}>Admin</Text>
								</div>
							</div>
						</div>
					</div>
					<div style={{ position: 'relative' }}>
						<img style={{ marginBottom: -4, borderRadius: '0 0 6px 6px' }} src={ImageLinks.semi_circles_four} alt='' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Settings;
